// @flow

import axios from 'axios';
import type { Dispatch as ReduxDispatch } from 'redux';


export const createZone = (
  regions,
  first_name,
  last_name,
  email,
  password,
  all_permissions,
) => axios
  .post('api/managers', {
    regions,
    first_name,
    last_name,
    email,
    password,
    all_permissions,
  })
  .then(response => (response))
  .catch(err => (err));

export const createZoneCustom = (
  regions,
  first_name,
  last_name,
  email,
  password,
  permissions,
) => axios
  .post('api/managers', {
    regions,
    first_name,
    last_name,
    email,
    password,
    permissions,
  })
  .then(response => (response))
  .catch(err => (err));

export const updateZone = (
  id,
  regions,
  first_name,
  last_name,
  email,
  all_permissions,
) => axios
  .put(`api/managers/${id}`, {
    regions,
    first_name,
    last_name,
    email,
    all_permissions,
  })
  .then(response => (response))
  .catch(err => (err));
  
export const updateZoneCustom = (
  id,
  regions,
  first_name,
  last_name,
  email,
  permissions,
) => axios
  .put(`api/managers/${id}`, {
    regions,
    first_name,
    last_name,
    email,
    permissions,
  })
  .then(response => (response))
  .catch(err => (err));
export const getZones = () => axios
  .get('api/managers')
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));


export const getSingleZone = id => axios
  .get(`api/managers/${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));

export const deleteZone = id => axios
  .delete(`api/managers/${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));
