import React from 'react';
import styled from 'styled-components';

import shipments from './shipments.svg';
import marketplaces from './marketplaces.svg';
import packages from './packages.svg';
import invoices from './invoices.svg';
import addresses from './addresses.svg';
import payment from './payment.svg';
import rates from './quotation.svg';
import completedShipments from './completedShipments.svg';
import accounts from './accountsActive.svg';
import quotation from './quotation.svg';
import region from './region.svg';
import roles from './roles.svg';
import zone from './zone.svg';


const src = {
  shipments,
  marketplaces,
  packages,
  invoices,
  addresses,
  payment,
  rates,
  completedShipments,
  accounts,
  quotation,
  region,
  roles,
  zone,
};

const StyledDiv = styled.div`
  display: inline-block;
`;

const StyledImg = styled.img`
  display: inline-block;
  line-height: 1.5;
  vertical-align: middle;
`;

const State = props => (
  <StyledDiv className={props.className} style={props.style}>
    <StyledImg src={src[props.name]} alt={props.name} />
  </StyledDiv>
);

export default State;
