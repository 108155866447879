import React from "react";
import axios from "axios";
import Clipboard from "react-clipboard.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import { push } from "react-router-redux";
import moment from "moment/min/moment-with-locales";
import styled from "styled-components";
import isEmail from "validator/lib/isEmail";
import ReactTooltip from "react-tooltip";
import colors from "../../utils/colors";
import { BASEURL } from "../../constants/index";

import {
  deleteShipments,
  getUserShipments,
  getUserShipmentsPurchase,
  getAllUserShipments,
  editShipmentRate,
  editShipmentDescription,
  editShipmentDimensions,
  editShipmentInsurance,
} from "../../modules/shipments/actions";
import { getUserAllPackages } from "../../modules/packages";
import { createRates } from "../../modules/rates";
import {
  getUserAllAddresses,
  editAddress,
} from "../../modules/addresses/actions";
import { hideMessage } from "../../modules/message";
import { showLoader, hideLoader } from "../../modules/loader";

import Header from "../Header";
import Text, { Title } from "../Text";
import Button from "../Button";
import Card, { Divider } from "../Card";
import Tracking, { Step } from "../Tracking";
import { Action, Nav } from "../Modal";
import Create from "../Create";
import Dropdown from "../Dropdown";
import Input, { Label, HelpText } from "../Input";
import { CheckboxGroup, Checkbox } from "../Checkbox";
import { RadioGroup, Radio } from "../Radio";
import Message from "../Message";
import Alert from "../Alert";

import shipment from "./shipment.svg";
import shopify from "../ShipmentsList/shopify.svg";
import prestashop from "../ShipmentsList/prestashop.svg";
import magento from "../ShipmentsList/magento.svg";
import woocommerce from "../ShipmentsList/woocommerce.svg";
import { formatPhone } from "../../utils/global_functions";

const NavExtended = Nav.extend`
  box-shadow: none;
  height: auto;
`;

const ActionExtended = Action.extend`
  left: 0;
  height: auto;
  line-height: 0;
  h1 {
    line-height: 40px;
  }
`;

const StyledRate = styled.div`
  padding: 16px 12px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${colors.gray.earth_stone};
  margin-bottom: 16px;
`;

const StyledEmpty = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 5px;
  background: #e4e4e4;
  margin-bottom: 8px;
`;

const I = styled.i`
  cursor: pointer;
  width: 10px;
  height: 5px;
`;

const Collapsible = styled.div`
  cursor: pointer;
  border-left: 4px solid ${colors.gray.creamy_gray};
  padding-left: 37px;
`;

const PackageOption = (props) => (
  <Row className="card-dropdown-option">
    <Column xs={6} fluid>
      <Text type="label" style={{ fontWeight: "bold" }}>
        {props.pack.alias}
      </Text>
    </Column>
    <Column xs={12} fluid>
      <Text type="label" style={{ fontWeight: "400", color: "#686868" }}>
        {props.pack.object_type_code === "box" ? "Caja" : "Sobre"}
      </Text>
    </Column>
    <Column xs={12} fluid>
      <Text type="label" style={{ fontWeight: "400" }}>
        {props.pack.length} x{props.pack.width} x{props.pack.height} cm
      </Text>
    </Column>
  </Row>
);

class ShipmentDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = props.match.params;
    this.deleteShipment = this.deleteShipment.bind(this);
    this.editShipment = this.editShipment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleProvidersChange = this.handleProvidersChange.bind(this);
    this.handleSpeedChange = this.handleSpeedChange.bind(this);
    this.handleRateChange = this.handleRateChange.bind(this);
    this.handleInsuranceChange = this.handleInsuranceChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.saveAddress = this.saveAddress.bind(this);
    this.saveDimensions = this.saveDimensions.bind(this);
    this.saveDescription = this.saveDescription.bind(this);
    this.saveInsurance = this.saveInsurance.bind(this);
    this.generateRatesProviders = this.generateRatesProviders.bind(this);
    this.generateRates = this.generateRates.bind(this);
    this.extraTrackingList = this.extraTrackingList.bind(this);
    this.getShipment = this.getShipment.bind(this);
    this.getShipment(id);
    this.props.getUserAllPackages();
    this.state = {
      next: null,
      id,
      previous: null,
      shipment: null,
      step: null,
      renderEdit: null,
      selectedAddressFrom: null,
      selectedAddressTo: null,
      selectedAddressFromTitle: null,
      selectedAddressToTitle: null,
      selectedPackage: null,
      selectedPackageTitle: null,
      order: null,
      traking: null,
      filterTo: "",
      filterFrom: "",
      filterPackage: "",
      from: {
        name: "",
        nameError: "",
        email: "",
        emailError: "",
        phone: "",
        phoneError: "",
        street: "",
        streetError: "",
        street2: "",
        street2Error: "",
        reference: "",
        referenceError: "",
        zipcode: "",
        zipcodeError: "",
        location: "",
      },
      to: {
        name: "",
        nameError: "",
        email: "",
        emailError: "",
        phone: "",
        phoneError: "",
        street: "",
        streetError: "",
        street2: "",
        street2Error: "",
        reference: "",
        referenceError: "",
        zipcode: "",
        zipcodeError: "",
        location: "",
      },
      dimensions: {
        length: "",
        width: "",
        height: "",
      },
      dimensionsError: "",
      volumetricWeightError: "",
      weight: "",
      weightError: "",
      providersFilter: [],
      speedFilter: [],
      rate: {},
      description: "",
      descriptionError: "",
      insuredAmount: "",
      insuredAmountError: "",
      holder: "",
      holderError: "",
      email: "",
      emailError: "",
      insuranceSelected: null,
      showDetail: false,
      hasBeenPicked: false,
      showTrackingModal: false,
    };
  }

  updates() {
    return this.state.showDetail ? (
      <Collapsible
        style={{ paddingBottom: "24px" }}
        onClick={() => this.handleShowTrackingDetail()}
      >
        <Text type="labelEdit">
          Esconder las actualizaciones{" "}
          <I className="ion-android-arrow-dropup" />
        </Text>
      </Collapsible>
    ) : (
      <div>
        <Collapsible
          style={{
            borderLeft: `4px dotted ${colors.gray.creamy_gray}`,
            paddingBottom: "8px",
          }}
          onClick={() => this.handleShowTrackingDetail()}
        >
          <Text type="labelEdit">
            Ver todas las actualizaciones{" "}
            <I className="ion-android-arrow-dropdown" />
          </Text>
        </Collapsible>
        <div
          style={{
            borderLeft: `4px solid ${colors.gray.creamy_gray}`,
            height: "16px",
          }}
        />
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    let { id } = this.props.match.params;
    if (id !== nextProps.match.params.id) {
      id = nextProps.match.params.id;
      this.setState({ id });
      this.getShipment(id);
    }
  }

  handlePackageChange = (pack) => {
    const packages = this.props.packages.results;
    this.setState({
      selectedPackage: pack,
      dimensions: {
        height: packages[pack].height,
        width: packages[pack].width,
        length: packages[pack].length,
      },
      selectedPackageTitle: (
        <Text className="address-dropdown-title">
          <b>{packages[pack].alias}</b>
        </Text>
      ),
    });
  };

  getShipment(id) {
    this.props.showLoader("basic");
    axios
      .get(`/api/shipments/${id}`)
      .then((response) => {
        const { shipment } = response.data;
        const previous = response.data.previous_shipment_id
          ? response.data.previous_shipment_id.id
          : null;
        const next = response.data.next_shipment_id
          ? response.data.next_shipment_id.id
          : null;
        this.setState({
          id,
          shipment,
          previous,
          next,
          step: null,
          selectedAddressFrom: null,
          renderEdit: null,
          selectedAddressTo: null,
          selectedAddressFromTitle: null,
          selectedAddressToTitle: null,
          order: null,
          filterTo: "",
          filterFrom: "",
          from: {
            name: shipment.address_from.name,
            nameError: "",
            email: shipment.address_from.email,
            emailError: "",
            phone: shipment.address_from.phone,
            phoneError: "",
            street: shipment.address_from.street,
            streetError: "",
            street2: shipment.address_from.street2,
            street2Error: "",
            reference: shipment.address_from.reference,
            referenceError: "",
            zipcode: shipment.address_from.zipcode,
            zipcodeError: "",
            location: "",
          },
          to: {
            name: shipment.address_to.name,
            nameError: "",
            email: shipment.address_to.email,
            emailError: "",
            phone: shipment.address_to.phone,
            phoneError: "",
            street: shipment.address_to.street,
            streetError: "",
            street2: shipment.address_to.street2,
            street2Error: "",
            reference: shipment.address_to.reference,
            referenceError: "",
            zipcode: shipment.address_to.zipcode,
            zipcodeError: "",
            location: "",
          },
          dimensions: {
            length: shipment.length,
            width: shipment.width,
            height: shipment.height,
          },
          dimensionsError: "",
          volumetricWeightError: "",
          weight: shipment.weight,
          weightError: "",
          providersFilter: [],
          speedFilter: [],
          rate: shipment.rate,
          description: shipment.description,
          descriptionError: "",
          insuredAmount: shipment.insurance
            ? shipment.insurance.insured_amount
            : "",
          insuredAmountError: "",
          holder: shipment.insurance ? shipment.insurance.holder : "",
          holderError: "",
          email: shipment.insurance ? shipment.insurance.email : "",
          emailError: "",
          insuranceSelected: shipment.insurance ? "2" : "1",
        });
        if (shipment.label && shipment.rate && shipment.label.tracking_number) {
          this.getTracking(
            shipment.label.tracking_number,
            shipment.rate.provider.toLowerCase()
          );
        } else {
          this.props.hideLoader();
        }
      })
      .catch((error) => {
        this.props.hideLoader();
        console.error(error);
      });
  }

  getTracking(tracking_number, carrier) {
    this.props.showLoader("basic");
    axios
      .get(`/api/tracking/${tracking_number}/${carrier}`)
      .then((response) => {
        let trackingState = "";
        this.props.hideLoader();
        response.data.events.some((event) => {
          if (event.code !== "incident") {
            trackingState = event.code;
            return true;
          }
          return false;
        });
        this.setState({ tracking: response.data.events, trackingState });

        // const events = response.data.events;
        // if (events.length > 0) {
        //   tracking.push({status: 1});
        // }
        // let trackingIndex = 0;
        // events.forEach(event => {
        //   if (trackingIndex === 0 && carrier !== 'dhl') {
        //     trackingIndex++;
        //   }
        //   if (trackingIndex === 1 && event.code === 'picked_up') {
        //     tracking.push({status: 1});
        //     tracking.push({status: 1, text: event.description});
        //     trackingIndex++;
        //   }
        // });
        // if (events[events.length - 1].code === 'checkpoint') {
        //   tracking.push({
        //     status: 1,
        //     text: events[events.length - 1].description,
        //   });
        // }
        // if (events[events.length - 1].code === 'delivered') {
        //   tracking.push({status: 1, text: ''});
        //   tracking.push({
        //     status: 1,
        //     text: events[events.length - 1].description,
        //   });
        // }
        // while (tracking.length < 5) {
        //   tracking.push({status: 2, text: ''});
        // }
        // this.setState({tracking});
        // console.log(response);
      })
      .catch(() => {
        this.props.hideLoader();
      });
  }

  handleShowTrackingDetail() {
    const showDetail = !this.state.showDetail;
    this.setState({ showDetail });
  }

  handleFilterChange(value) {
    if (this.state.step === 1) {
      return this.setState({
        filterTo: value,
      });
    }
    return this.setState({
      filterFrom: value,
    });
  }

  handlePackageFilterChange = (value) =>
    this.setState({
      filterPackage: value,
    });

  handleProvidersChange(providersFilter) {
    this.setState({ providersFilter });
  }

  handleSpeedChange(speedFilter) {
    this.setState({ speedFilter });
  }

  handleRateChange(value) {
    this.props.rates.results.forEach((rate) => {
      if (Number(value) === Number(rate.object_id)) {
        this.setState({ rate });
      }
    });
  }

  generateRatesProviders() {
    const seen = [];
    const checkboxes = [];
    this.props.rates.results.forEach((rate) => {
      let found = false;
      seen.forEach((str) => {
        if (str === rate.provider) {
          found = true;
        }
      });
      if (found) return;
      seen.push(rate.provider);
      checkboxes.push(
        <Checkbox
          key={rate.provider}
          value={rate.provider}
          text={rate.provider}
          checkboxClass="rates-checkbox"
        />
      );
    });
    return (
      <CheckboxGroup
        type="form"
        defaultChecked={this.state.providersFilter}
        onChange={this.handleProvidersChange}
      >
        {checkboxes}
      </CheckboxGroup>
    );
  }

  generateRatesSpeed() {
    const seen = [];
    const checkboxes = [];
    this.props.rates.results.forEach((rate) => {
      let found = false;
      seen.forEach((str) => {
        if (str === rate.duration_terms) {
          found = true;
        }
      });
      if (found) return;
      seen.push(rate.duration_terms);
      checkboxes.push(
        <Checkbox
          key={rate.duration_terms}
          value={rate.duration_terms}
          text={rate.duration_terms}
          checkboxClass="rates-checkbox"
        />
      );
    });
    return (
      <CheckboxGroup
        type="form"
        defaultChecked={this.state.speedFilter}
        onChange={this.handleSpeedChange}
      >
        {checkboxes}
      </CheckboxGroup>
    );
  }

  generateRates() {
    let rates = this.props.rates.results;
    const ratesProviderFiltered = rates.filter((rate) => {
      if (this.state.providersFilter.length === 0) return true;
      let found = false;
      this.state.providersFilter.forEach((provider) => {
        if (provider === rate.provider) found = true;
      });
      return found;
    });
    const ratesSpeedFiltered = ratesProviderFiltered.filter((rate) => {
      if (this.state.speedFilter.length === 0) return true;
      let found = false;
      this.state.speedFilter.forEach((speed) => {
        if (speed === rate.duration_terms) found = true;
      });
      return found;
    });
    rates = ratesSpeedFiltered;
    return rates.map((rate) => (
      <StyledRate key={rate.object_id}>
        <Row>
          <Column xs={1} fluid>
            <RadioGroup
              style={{ position: "relative", height: "100%" }}
              selected={this.state.rate.object_id}
              onChange={this.handleRateChange}
            >
              <Radio
                value={rate.object_id}
                containerClass="rate-radio-container"
                className="rate-radio"
              />
            </RadioGroup>
          </Column>
          <Column xs={2} fluid style={{ position: "relative" }}>
            <img
              alt="provider"
              className="rate-img"
              src={`https://production.mienvio.mx/${rate.provider_img}`}
            />
          </Column>
          <Column xs={6} fluid className="rate-content">
            <Row>
              <Column fluid>
                <Text>{rate.provider}</Text>
              </Column>
            </Row>
            <Row>
              <Column fluid>
                <Text>
                  {rate.servicelevel} {rate.duration_terms}
                </Text>
              </Column>
            </Row>
            <Row>
              <Column fluid>
                <Text type="helpTextModal">
                  {rate.servicelevel === "priority" &&
                    "sin recoleccion, se entrega en sucursal antes 2 p.m."}
                </Text>
              </Column>
            </Row>
          </Column>
          <Column xs={3} fluid style={{ textAlign: "center" }}>
            <Text>${parseFloat(rate.amount).toFixed(2)}</Text>
          </Column>
        </Row>
      </StyledRate>
    ));
  }

  handleInsuranceChange(insuranceSelected) {
    this.setState({ insuranceSelected });
  }

  handleChange(value, key) {
    const decimalRegex = /^\d+(\.\d{1,2})?$/;
    if (key === "zipcode" && !isNaN(value) && value.length === 5) {
      axios
        .get(`/api/zipcodes/${value}`)
        .then((response) => {
          const address = {
            ...this.state[this.state.step === 1 ? "to" : "from"],
          };
          address.location = `${
            response.data.municipality
              ? response.data.municipality
              : response.data.neighborhood
          }, ${response.data.state.name}`;
          address.zipcodeError = "";
          this.setState({ [this.state.step === 1 ? "to" : "from"]: address });
        })
        .catch(() => {
          const address = {
            ...this.state[this.state.step === 1 ? "to" : "from"],
          };
          address.location = "";
          address.zipcodeError = "Código postal inválido";
          this.setState({ [this.state.step === 1 ? "to" : "from"]: address });
        });
    } else if (key === "zipcode") {
      this.setState({ location: "" });
    } else if (key === "length" || key === "width" || key === "height") {
      const dimensions = { ...this.state.dimensions };
      dimensions[key] = value;
      return this.setState({ dimensions });
    } else if (key === "phone") {
      value = value.replace(/\D/g, "");
      if (value.length > 10) return;
    }
    if (this.state.step < 2) {
      const address = { ...this.state[this.state.step === 1 ? "to" : "from"] };
      address[key] = value;
      return this.setState({
        [this.state.step === 1 ? "to" : "from"]: address,
      });
    }
    if (key === "insuredAmount" && decimalRegex.test(value)) {
      /* let cost = 58;
      let mult = value * 0.0174;
      if (mult >= 58) {
        cost = mult;
      }
      cost = parseFloat(cost).toFixed(2);
      this.setState({cost}); */
      this.setState({ insuredAmountError: "" });
      this.calculateInsuranceCost(
        this.state.rate.provider,
        value,
        this.state.rate.object_id
      );
    } else if (key === "insuredAmount" && !decimalRegex.test(value)) {
      this.setState({ insuredAmountError: "El núnero es incorrecto" });
    }
    this.setState({ [key]: value });
  }

  calculateInsuranceCost(provider, insured_amount, rate_id) {
    let cost;
    rate_id = rate_id ? `/${rate_id}` : null;
    if (insured_amount == "") {
      cost = "";
      this.setState({ cost });
    } else {
      axios
        .get(`/api/shipments/insurances/${insured_amount}/${provider}`)
        .then((response) => {
          cost = response.data.insurance_cost;
          cost = parseFloat(cost).toFixed(2);
          this.setState({ cost });
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
    }
  }

  formatPhone(phone) {
    const mask = "(XXX) XXX XX XX";
    const s = `${phone}`;
    let r = "";
    for (let i = 0, is = 0; i < mask.length && is < s.length; i++) {
      r += mask.charAt(i) === "X" ? s.charAt(is++) : mask.charAt(i);
    }
    return r;
  }

  handleAddressChange(index) {
    let addresses = this.props.addresses.results;
    if (this.state.step === 1 && this.state.filterTo) {
      addresses = addresses.filter((address) => {
        const re = new RegExp(this.state.filterTo, "i");
        return re.test(address.alias);
      });
    } else if (this.state.step === 0 && this.state.filterFrom) {
      addresses = addresses.filter((address) => {
        const re = new RegExp(this.state.filterFrom, "i");
        return re.test(address.alias);
      });
    }
    const { alias } = addresses[index];
    const address = null;
    axios
      .get(`/api/zipcodes/${addresses[index].zipcode}`)
      .then((response) => {
        const address = {
          ...this.state[this.state.step === 1 ? "to" : "from"],
        };
        address.location = `${
          response.data.municipality
            ? response.data.municipality
            : response.data.neighborhood
        }, ${response.data.state.name}`;
        address.zipcodeError = "";
        this.setState({ [this.state.step === 1 ? "to" : "from"]: address });
      })
      .catch(() => {
        const address = {
          ...this.state[this.state.step === 1 ? "to" : "from"],
        };
        address.location = "";
        this.setState({ [this.state.step === 1 ? "to" : "from"]: address });
      });
    if (this.state.step === 1) {
      return this.setState({
        selectedAddressTo: index,
        selectedAddressToTitle: (
          <Text className="address-dropdown-title">
            <b>{alias}</b>
          </Text>
        ),
        to: addresses[index],
      });
    }
    this.setState({
      selectedAddressFrom: index,
      selectedAddressFromTitle: (
        <Text className="address-dropdown-title">
          <b>{alias}</b>
        </Text>
      ),
      from: addresses[index],
    });
  }

  beautifyPhone(phone) {
    const mask = "(XXX) XXX XX XX";
    const s = `${phone}`;
    let r = "";
    for (let i = 0, is = 0; i < mask.length && is < s.length; i++) {
      r += mask.charAt(i) === "X" ? s.charAt(is++) : mask.charAt(i);
    }
    return r;
  }

  compareDimensions() {
    return (
      this.state.dimensions.height !== this.state.shipment.height ||
      this.state.dimensions.length !== this.state.shipment.length ||
      this.state.dimensions.width !== this.state.shipment.width ||
      this.state.weight !== this.state.shipment.weight
    );
  }

  saveDimensions() {
    if (this.compareDimensions()) {
      let errors = 0;
      let dimensionsError = "";
      let weightError = "";
      let volumetricWeightError = "";
      if (
        !this.state.dimensions.height ||
        !this.state.dimensions.length ||
        !this.state.dimensions.width
      ) {
        errors++;
        dimensionsError = "Todas las dimensiones son requeridas";
      } else if (
        isNaN(this.state.dimensions.height) ||
        isNaN(this.state.dimensions.length) ||
        isNaN(this.state.dimensions.width)
      ) {
        errors++;
        dimensionsError = "Todas las dimensiones deben tener valores númericos";
      } else if (
        this.state.dimensions.height > 170 ||
        this.state.dimensions.length > 170 ||
        this.state.dimensions.width > 170
      ) {
        errors++;
        dimensionsError = "Todas las dimensiones deben ser menores a 170cm";
      } else if (
        Number(this.state.dimensions.height) +
          Number(this.state.dimensions.length) +
          Number(this.state.dimensions.width) >=
        360
      ) {
        errors++;
        dimensionsError =
          "La suma de las dimensiones debe de ser menor a 360cm";
      }

      if (!this.state.weight) {
        errors++;
        weightError = "Este campo es requerido";
      } else if (this.state.weight > 190) {
        errors++;
        weightError = "El peso no puede ser mayor a 190kg";
      }

      if (
        this.state.dimensions.length &&
        this.state.dimensions.height &&
        this.state.dimensions.width &&
        (1.0 *
          parseFloat(this.state.dimensions.length) *
          parseFloat(this.state.dimensions.width) *
          parseFloat(this.state.dimensions.height)) /
          5000.0 >
          190
      ) {
        errors++;
        volumetricWeightError =
          "El peso volumétrico no puede ser mayor a 190kg";
      }
      this.setState({
        dimensionsError,
        weightError,
        volumetricWeightError,
      });
      if (errors > 0) {
        return;
      }
      this.changeRenderEdit(3);
    }
  }

  saveDescription() {
    if (!this.state.description) {
      return this.setState({
        descriptionError: "Este campo es requerido",
      });
    }
    if (this.state.description.length > 30) {
      return this.setState({
        descriptionError: "Máximo 30 caracteres",
      });
    }
    this.props.editShipmentDescription(
      this.state.shipment.object_id,
      this.state.description
    );
    const { shipment } = this.state;
    shipment.description = this.state.description;
    this.setState({
      step: null,
      renderEdit: null,
      shipment,
    });
  }

  saveInsurance() {
    if (this.state.insuranceSelected === "2") {
      let errors = 0;
      let insuredAmountError = "";
      let holderError = "";
      let emailError = "";
      if (!this.state.insuredAmount) {
        errors++;
        insuredAmountError = "Este campo es requerido";
      } else if (isNaN(this.state.insuredAmount)) {
        errors++;
        insuredAmountError = "Este campo debe de ser numérico";
      }

      if (!this.state.holder) {
        errors++;
        holderError = "Este campo es requerido";
      }

      if (!this.state.email) {
        errors++;
        emailError = "Este campo es requerido";
      } else if (!isEmail(this.state.email)) {
        errors++;
        emailError = "Correo electrónico inválido";
      }

      if (errors > 0) {
        return this.setState({
          insuredAmountError,
          holderError,
          emailError,
        });
      }
      const insurance = {
        holder: this.state.holder,
        email: this.state.email,
        product: this.state.description,
        insured_amount: this.state.insuredAmount,
        amount: this.state.insuredAmount,
        cost: this.state.cost,
      };
      this.props
        .editShipmentInsurance(this.state.shipment.object_id, insurance)
        .then(() => {
          const { shipment } = this.state;
          shipment.insurance = insurance;
          this.setState({
            step: null,
            renderEdit: null,
            shipment,
          });
        });
    } else {
      this.props
        .editShipmentInsurance(this.state.shipment.object_id, [])
        .then(() => {
          const { shipment } = this.state;
          shipment.insurance = null;
          this.setState({
            step: null,
            renderEdit: null,
            shipment,
          });
        });
    }
  }

  saveAddress() {
    const key = this.state.step === 0 ? "from" : "to";
    let errors = 0;
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let streetError = "";
    let street2Error = "";
    let referenceError = "";
    let zipcodeError = "";

    if (!this.state[key].name) {
      errors++;
      nameError = "Este campo es requerido";
    }

    if (!this.state[key].email) {
      errors++;
      emailError = "Este campo es requerido";
    } else if (!isEmail(this.state[key].email)) {
      errors++;
      emailError = "Correo electrónico inválido";
    }

    if (isNaN(this.state[key].phone) || this.state[key].phone.length !== 10) {
      errors++;
      phoneError = "Número de teléfono inválido";
    }

    if (!this.state[key].street) {
      errors++;
      streetError = "Este campo es requerido";
    } else if (this.state[key].street.length > 35) {
      errors++;
      streetError = "Máximo 35 caracteres";
    }

    if (!this.state[key].street2) {
      errors++;
      street2Error = "Este campo es requerido";
    }

    if (this.state[key].reference && this.state[key].reference.length > 32) {
      errors++;
      referenceError = "Máximo 32 caracteres";
    }

    if (
      isNaN(this.state[key].zipcode) ||
      this.state[key].zipcode.length !== 5 ||
      this.state[key].location === ""
    ) {
      errors++;
      zipcodeError = "Código postal inválido";
    }
    const address = this.state[key];
    address.nameError = nameError;
    address.emailError = emailError;
    address.phoneError = phoneError;
    address.streetError = streetError;
    address.referenceError = referenceError;
    address.street2Error = street2Error;
    address.zipcodeError = zipcodeError;
    this.setState({
      address,
    });
    if (errors > 0) {
      return;
    }
    const newAddress = this.state[key];
    newAddress.object_type = this.state.shipment[`address_${key}`].object_type;
    if (
      this.state.shipment[`address_${key}`].zipcode != this.state[key].zipcode
    ) {
      this.changeRenderEdit(3);
    } else {
      this.props
        .editAddress(
          newAddress,
          this.state.shipment[`address_${key}`].object_id
        )
        .then(() => {
          const { shipment } = this.state;
          shipment[`address_${key}`] = newAddress;
          this.setState({
            step: null,
            renderEdit: null,
            shipment,
          });
        });
    }
  }

  saveRate() {
    this.props.editShipmentRate(
      this.state.shipment.object_id,
      this.state.rate.object_id
    );
    const { shipment } = this.state;
    shipment.rate = this.state.rate;
    this.setState(
      {
        shipment,
      },
      () => {
        if (
          this.state.shipment.address_from.zipcode != this.state.from.zipcode
        ) {
          const newAddress = this.state.from;
          newAddress.object_type = this.state.shipment.address_from.object_type;
          this.props
            .editAddress(newAddress, this.state.shipment.address_from.object_id)
            .then(() => {
              this.setState({ step: null, renderEdit: null });
              const { shipment } = this.state;
              shipment.address_from = newAddress;
              this.setState({
                step: null,
                renderEdit: null,
                shipment,
              });
            });
        } else if (
          this.state.shipment.address_to.zipcode != this.state.to.zipcode
        ) {
          const newAddress = this.state.to;
          newAddress.object_type = this.state.shipment.address_to.object_type;
          this.props
            .editAddress(newAddress, this.state.shipment.address_to.object_id)
            .then(() => {
              const { shipment } = this.state;
              shipment.address_to = newAddress;
              this.setState({
                step: null,
                renderEdit: null,
                shipment,
              });
            });
        } else if (this.compareDimensions()) {
          this.props
            .editShipmentDimensions(
              this.state.shipment.object_id,
              this.state.dimensions.length,
              this.state.dimensions.width,
              this.state.dimensions.height,
              this.state.weight
            )
            .then(() => {
              const { shipment } = this.state;
              shipment.height = this.state.dimensions.height;
              shipment.length = this.state.dimensions.length;
              shipment.width = this.state.dimensions.width;
              shipment.weight = this.state.weight;
              this.setState({
                step: null,
                renderEdit: null,
                shipment,
              });
            });
        } else {
          this.setState({
            step: null,
            renderEdit: null,
          });
        }
      }
    );
  }

  deleteShipment() {
    this.props.deleteShipments([this.state.id]).then(() => {});
  }

  changeRenderEdit(renderEdit) {
    this.setState({
      renderEdit,
    });
  }

  editShipment(step) {
    if (this.state.step !== step) {
      if (step === 3) {
        this.props.createRates(
          this.state.from.zipcode,
          this.state.to.zipcode,
          this.state.weight,
          this.state.dimensions.length,
          this.state.dimensions.width,
          this.state.dimensions.height
        );
      }
      this.setState({ step }, () => {
        if (step === 0) {
          this.handleChange(
            this.state.shipment.address_from.zipcode,
            "zipcode"
          );
        } else if (step === 1) {
          this.handleChange(this.state.shipment.address_to.zipcode, "zipcode");
        }
      });
    }
    let options = [];
    let packageOptions = [];
    if (this.props.packages.results) {
      let packages = this.props.packages.results;
      packages = packages.filter((p) => {
        const re = new RegExp(this.state.filterPackage, "i");
        return re.test(p.alias);
      });
      packageOptions = packages.map((pack) => (
        <PackageOption pack={pack} key={pack.object_id} />
      ));
    }
    if (this.props.addresses.results) {
      let addresses = this.props.addresses.results;
      if (step === 1 && this.state.filterTo) {
        addresses = addresses.filter((address) => {
          const re = new RegExp(this.state.filterTo, "i");
          return re.test(address.alias);
        });
      } else if (step === 0 && this.state.filterFrom) {
        addresses = addresses.filter((address) => {
          const re = new RegExp(this.state.filterFrom, "i");
          return re.test(address.alias);
        });
      }
      options = addresses.map((address) => (
        <Row key={address.object_id} className="card-dropdown-option">
          <Column fluid>
            <Text>
              <b>{address.alias}</b>
            </Text>
          </Column>
          <Column fluid>
            <Text>{address.name}</Text>
          </Column>
          <Column fluid>
            <Text>
              {address.email}.{this.beautifyPhone(address.phone)}.
            </Text>
          </Column>
          <Column fluid>
            <Text>{address.street}</Text>
          </Column>
          {address.reference ? (
            <Column fluid>
              <Text>{address.reference}</Text>
            </Column>
          ) : null}
          <Column fluid>
            <Text>{address.street2}</Text>
          </Column>
          <Column fluid>
            <Text>
              C.P.
              {address.zipcode}
            </Text>
          </Column>
        </Row>
      ));
    }
    const mainProps = {
      generate: (name) => ({
        onChange: (value, key) => {
          this.handleChange(value, key || name);
        },
        value:
          step >= 2
            ? this.state[name]
            : name === "phone"
            ? formatPhone(this.state[step === 1 ? "to" : "from"][name])
            : this.state[step === 1 ? "to" : "from"][name],
        error:
          step >= 2
            ? this.state[`${name}Error`]
            : this.state[step === 1 ? "to" : "from"][`${name}Error`],
      }),
    };
    const createTitles = [
      "Edita la información de origen",
      "Edita la información de destino",
      "Editar información del paquete",
      "Seleccionar servicio de paquetería",
      "Editar contenido de orden",
      "Editar seguro de envío",
    ];
    const shipmentFields = [
      // Step 1
      <div>
        {this.props.addresses.results &&
        this.props.addresses.results.length > 0 ? (
          <div>
            <Text type="helpTextModal" style={{ marginBottom: "8px" }}>
              Elige una direccion de tu libreta de direcciones
            </Text>
            <Dropdown
              options={options}
              placeholder={
                <Text className="dropdown-placeholder">Buscar por alias</Text>
              }
              selected={this.state.selectedAddressFrom}
              selectedTitle={this.state.selectedAddressFromTitle}
              filter
              filterValue={this.state.filterFrom}
              filterPlaceholder="Buscar por alias"
              handleChange={this.handleAddressChange}
              handleFilterChange={this.handleFilterChange}
            />
            <Divider className="divider-address" />
            <Text type="helpTextModal">O llena la siguiente información</Text>
          </div>
        ) : null}
        <Input
          type="text"
          {...mainProps.generate("name")}
          required
          placeholder="Pedro Gómez"
          label="Nombre de quien envía"
        />
        <Input
          type="text"
          {...mainProps.generate("email")}
          required
          placeholder="pedro@tucorreo.com"
          label="Correo electrónico de quien envía"
        />
        <Input
          type="text"
          {...mainProps.generate("phone")}
          required
          placeholder="(442) 123 12 45"
          label="Teléfono de contacto"
        />
        <Input
          type="text"
          {...mainProps.generate("street")}
          maxLength="35"
          required
          placeholder="Av. Josefa Ortiz, 309. Int. J-45."
          label="Calle y número"
        />
        <Input
          type="text"
          {...mainProps.generate("reference")}
          maxLength="35"
          placeholder="Casa amarilla"
          label="Referencias origen (opcional)"
        />
        <Input
          type="text"
          {...mainProps.generate("street2")}
          required
          placeholder="Independencia"
          label="Colonia"
        />
        <Row>
          <Column xs={4} fluid>
            <Input
              type="text"
              {...mainProps.generate("zipcode")}
              required
              placeholder="76158"
              label="Código Postal"
            />
          </Column>
          <Column xs={8} fluid style={{ paddingLeft: "16px" }}>
            <Input
              type="text"
              disabled
              onChange={() => {}}
              required
              label="Estado y Ciudad"
              value={this.state.from.location}
            />
          </Column>
        </Row>
        <Button onClick={() => this.saveAddress()} className="create-btn">
          {this.state.from.zipcode === this.state.shipment.address_from.zipcode
            ? "Aplicar cambios"
            : "Seleccionar paquetería"}
        </Button>
        <Button
          onClick={() => this.setState({ step: null, renderEdit: null })}
          type="link"
          className="create-btn"
        >
          Cancelar
        </Button>
      </div>,
      // Step 2
      <div>
        {this.props.addresses.results &&
          this.props.addresses.results.length > 0 && (
            <div>
              <Text type="helpTextModal" style={{ marginBottom: "8px" }}>
                Elige una direccion de tu libreta de direcciones
              </Text>
              <Dropdown
                options={options}
                placeholder={
                  <Text className="dropdown-placeholder">
                    Seleccionar dirección
                  </Text>
                }
                selected={this.state.selectedAddressTo}
                selectedTitle={this.state.selectedAddressToTitle}
                filter
                filterValue={this.state.filterTo}
                filterPlaceholder="Buscar por alias"
                handleChange={this.handleAddressChange}
                handleFilterChange={this.handleFilterChange}
              />
              <Divider className="divider-address" />
              <Text type="helpTextModal">O llena la siguiente información</Text>
            </div>
          )}
        <Input
          type="text"
          {...mainProps.generate("name")}
          required
          placeholder="Juan Pérez"
          label="Nombre completo de quien recibe"
        />
        <Input
          type="text"
          {...mainProps.generate("email")}
          required
          placeholder="tunombre@tucorreo.com"
          label="Correo electrónico de quien recibe"
        />
        <Input
          type="text"
          {...mainProps.generate("phone")}
          required
          placeholder="(442) 123 45 67"
          label="Teléfono de contacto"
        />
        <Input
          type="text"
          {...mainProps.generate("street")}
          maxLength="35"
          required
          placeholder="Av. Morelos, 1511. Int. 21"
          label="Dirección de destino"
        />
        <Input
          type="text"
          {...mainProps.generate("reference")}
          maxLength="35"
          placeholder="Casa rosa con portón café"
          label="Referencias (opcional)"
        />
        <Input
          type="text"
          {...mainProps.generate("street2")}
          required
          placeholder="Independencia"
          label="Colonia"
        />
        <Row>
          <Column xs={4} fluid>
            <Input
              type="text"
              {...mainProps.generate("zipcode")}
              required
              placeholder="76158"
              label="Código Postal"
            />
          </Column>
          <Column xs={8} fluid style={{ paddingLeft: "16px" }}>
            <Input
              type="text"
              disabled
              onChange={() => {}}
              required
              label="Estado y Ciudad"
              value={this.state.to.location}
            />
          </Column>
        </Row>
        <Button onClick={() => this.saveAddress()} className="create-btn">
          {this.state.to.zipcode === this.state.shipment.address_to.zipcode
            ? "Aplicar cambios"
            : "Seleccionar paquetería"}
        </Button>
        <Button
          onClick={() => this.setState({ step: null, renderEdit: null })}
          type="link"
          className="create-btn"
        >
          Cancelar
        </Button>
      </div>,
      // Step 2
      <div>
        {this.props.packages.results &&
        this.props.packages.results.length > 0 ? (
          <div>
            <Text type="helpTextModal" style={{ marginBottom: "8px" }}>
              Elige un empaque de tus registros
            </Text>
            <Dropdown
              options={packageOptions}
              placeholder={
                <Text className="dropdown-placeholder">
                  Seleccionar empaque
                </Text>
              }
              selected={this.state.selectedPackage}
              handleChange={this.handlePackageChange}
              filterValue={this.state.filterPackage}
              filterPlaceholder="Buscar por alias"
              handleFilterChange={this.handlePackageFilterChange}
              selectedTitle={this.state.selectedPackageTitle}
            />
            <Divider className="divider-address" />
            <Text type="helpTextModal">O llena la siguiente información</Text>
          </div>
        ) : null}
        <Input
          type="text"
          {...mainProps.generate("dimensions")}
          required
          placeholder="30.0"
          label="Dimensiones (largo x ancho x altura)"
          dimensions
        />
        <Row>
          <Column xs={3} fluid>
            <Input
              type="text"
              {...mainProps.generate("weight")}
              required
              placeholder="10.0"
              label="Peso"
              weight
            />
          </Column>
          <Column xs={1} fluid style={{ height: "95px", position: "relative" }}>
            <Text className="volumetric-weight-helper">kg.</Text>
          </Column>
          <Column
            xs={12}
            fluid
            style={{
              paddingBottom: "12px",
              marginTop: "-12px",
              marginBottom: "12px",
            }}
          >
            <HelpText style={{ marginTop: "0px" }}>
              {this.state.weightError}
            </HelpText>
          </Column>
        </Row>
        <Row className="volumetric-weight-text">
          <Label>Peso volumétrico</Label>
          <Text type="helpTextModal">
            Si tu paquete tiene un mayor peso volumetrico al peso real tomaremos
            este segundo para calcular tu tarifa
          </Text>
        </Row>
        <Row className="volumetric-weight">
          <Column xs={3} fluid>
            <Input
              type="text"
              onChange={() => {}}
              value={
                this.state.dimensions.length &&
                this.state.dimensions.height &&
                this.state.dimensions.width
                  ? (
                      (1.0 *
                        parseFloat(this.state.dimensions.length) *
                        parseFloat(this.state.dimensions.width) *
                        parseFloat(this.state.dimensions.height)) /
                      5000.0
                    ).toString()
                  : ""
              }
              error={this.state.volumetricWeightError}
              disabled
              placeholder="100.0"
              weight
            />
          </Column>
          <Column xs={1} fluid style={{ height: "95px", position: "relative" }}>
            <Text className="weight-helper">kg.</Text>
          </Column>
          <Column
            xs={12}
            fluid
            style={{
              paddingBottom: "12px",
              marginTop: "-12px",
              marginBottom: "12px",
            }}
          >
            <HelpText style={{ marginTop: "0px" }}>
              {this.state.volumetricWeightError}
            </HelpText>
          </Column>
        </Row>
        <Button onClick={() => this.saveDimensions()} className="create-btn">
          Aplicar cambios
        </Button>
        <Button
          onClick={() => this.setState({ step: null, renderEdit: null })}
          type="link"
          className="create-btn"
        >
          Cancelar
        </Button>
      </div>,
      // Step 3
      <div>
        <Text type="helpTextModal" className="rates-help">
          Asegurate de revisar los horarios de servicio de cada proveedor, para
          que asi puedas hacer llegar tu envío a tiempo y no tengas
          inconvenientes. *Todas las guias requieren impresion
        </Text>
        <Row divisions={20}>
          <Column xs={6} fluid>
            <Label>Proveedores</Label>
            {this.props.rates ? this.generateRatesProviders() : null}
            <Label>Velocidad de envío</Label>
            {this.props.rates ? this.generateRatesSpeed() : null}
          </Column>
          <Column xs={14} fluid>
            {this.props.rates ? this.generateRates() : null}
          </Column>
        </Row>
        <Button onClick={() => this.saveRate()} className="create-btn">
          Aplicar cambios
        </Button>
        <Button
          onClick={() => this.setState({ step: null, renderEdit: null })}
          type="link"
          className="create-btn"
        >
          Cancelar
        </Button>
      </div>,
      // Step 4
      <div>
        <Text type="helpTextModal">
          Al proveer esta informacion nos ayudas a ofrecerte un mejor servicio:
          por ejemplo en caso de un extravio esta informacion ayuda al proveedor
          a localizar de forma mas pronta tu paquete.
        </Text>
        <Input
          type="text"
          {...mainProps.generate("description")}
          maxLength={30}
          required
          placeholder="Artículos deportivos"
          label="Descripción del contenido"
        />
        <Button onClick={() => this.saveDescription()} className="create-btn">
          Aplicar cambios
        </Button>
        <Button
          onClick={() => this.setState({ step: null, renderEdit: null })}
          type="link"
          className="create-btn"
        >
          Cancelar
        </Button>
      </div>,
      // Step 5
      <div>
        <Text type="helpTextModal">
          Las paqueterías cubren hasta $2,000.00 MXN, su garantía es limitada (
          no cubre robos ni riegos de tránsito). Por eso razon te recomendamos
          adquirir nuestro seguro adicional. Puedes calcular su costo a
          continuacion:
        </Text>
        <p style={{ fontSize: "11px", marginBottom: "0px" }}>
          *Las guías aseguradas pueden tardar en generarse hasta 12 hrs.
          laborales
        </p>
        <Row>
          <Column xs={5} fluid>
            <Input
              type="text"
              {...mainProps.generate("insuredAmount")}
              required
              placeholder="1500.50"
              label="Valor del paquete"
            />
          </Column>
          <Column xs={7} fluid />
          <Column fluid>
            <Input
              type="text"
              {...mainProps.generate("holder")}
              required
              placeholder="Juan Pérez"
              label="Responsable del paquete"
            />
          </Column>
          <Column fluid>
            <Input
              type="text"
              {...mainProps.generate("email")}
              required
              placeholder="tunombre@tucorreo.com"
              label="Correo de contacto"
            />
          </Column>
          <Column xs={5} fluid>
            <Input
              type="text"
              {...mainProps.generate("cost")}
              required
              placeholder="0.00"
              label="Costo del seguro"
              disabled
            />
          </Column>
          <Column xs={7} fluid />
          <StyledRate style={{ marginTop: "24px" }}>
            <RadioGroup
              selected={this.state.insuranceSelected}
              onChange={this.handleInsuranceChange}
            >
              <Radio
                type="form"
                value="1"
                text="No deseo adquirir el seguro adicional"
                containerClass="rate-radio-container"
                className="insurance-radio"
              />
            </RadioGroup>
          </StyledRate>
          <StyledRate>
            <RadioGroup
              selected={this.state.insuranceSelected}
              onChange={this.handleInsuranceChange}
            >
              <Radio
                type="form"
                value="2"
                text="Adquirir seguro"
                containerClass="rate-radio-container"
                className="insurance-radio"
              />
            </RadioGroup>
          </StyledRate>
        </Row>
        <Button onClick={() => this.saveInsurance()} className="create-btn">
          Aplicar cambios
        </Button>
        <Button
          onClick={() => this.setState({ step: null, renderEdit: null })}
          type="link"
          className="create-btn"
        >
          Cancelar
        </Button>
      </div>,
    ];

    return (
      <Create
        goBack={() => this.setState({ step: null, renderEdit: null })}
        classContent={this.state.renderEdit === 3 ? "rates-content" : ""}
      >
        <Title>{createTitles[step]}</Title>
        {shipmentFields[step]}
      </Create>
    );
  }

  getTotal() {
    let total = 0;
    total += parseFloat(this.state.shipment.rate.amount);
    if (this.state.shipment.insurance) {
      total += parseFloat(this.state.shipment.insurance.cost);
    }
    return parseFloat(total).toFixed(2);
  }

  trackingList() {
    return this.state.tracking.map((event, index) => {
      // console.log("CODE", code);
      const date = `${moment(event.date_time)
        .format("D-MMM")
        .toUpperCase()
        .replace(".", "")}, ${moment(event.date_time).format("hh:mma")}`;
      if (index === 0) {
        return (
          <div>
            <Step key={index} title={`Guía generada - ${date}`} done>
              <Text>#{this.state.shipment.label.tracking_number}</Text>
            </Step>
            {this.state.hasBeenPicked ? null : this.updates()}
          </div>
        );
      }
      if (event.code === "picked_up") {
        if (!this.state.hasBeenPicked) {
          this.setState({ hasBeenPicked: true });
        }
        return (
          <div>
            <Step
              title={`Recolectado/Escaneado - ${date}`}
              done
              key={index}
              style={{ paddingBottom: "12px" }}
            >
              <Text style={{ marginBottom: "0px" }}>{event.description}</Text>
            </Step>
            {this.updates()}
          </div>
        );
      }
      if (event.code === "incident" && this.state.showDetail) {
        return (
          <Step title={`Incidencia - ${date}`} incident key={index}>
            <Text>{event.exception_description}</Text>
          </Step>
        );
      }
      if (event.code === "delivered") {
        return (
          <Step title={`Entregado - ${date}`} done last key={index}>
            <Text>{event.description}</Text>
          </Step>
        );
      }
      if (this.state.showDetail) {
        return (
          <Step title={`En camino - ${date}`} done key={index}>
            <Text>{event.description}</Text>
          </Step>
        );
      }
    });
  }

  extraTrackingList() {
    const items = [
      <div>
        <Step
          title="Recolectado/Escaneado"
          key="recolectado"
          style={{ paddingBottom: "12px" }}
        >
          <div
            style={{ display: "inline-block", cursor: "pointer" }}
            onClick={() =>
              window.open(
                this.state.shipment.rate.provider.toLowerCase() === "estafeta"
                  ? "https://blogger.mienvio.mx/2021/04/como-se-recolecta-mi-envio-con-estafeta/"
                  : this.state.shipment.rate.provider.toLowerCase() === "dhl"
                  ? "https://blogger.mienvio.mx/2021/04/como-se-recolecta-mi-envio-con-dhl/"
                  : this.state.shipment.rate.provider.toLowerCase() ===
                    "redpack"
                  ? "https://blogger.mienvio.mx/2021/04/como-se-recolecta-mi-envio-con-redpack/"
                  : this.state.shipment.rate.provider.toLowerCase() === "fedex"
                  ? "https://blogger.mienvio.mx/2021/04/como-se-recolecta-mi-envio-con-fedex/"
                  : "https://blogger.mienvio.mx/centro-ayuda/recoleccion-mienvio/",
                "blank"
              )
            }
          >
            <Text type="bodyDetail" style={{ color: colors.secondary.cobalt }}>
              {" "}
              Agendar recolección
            </Text>
          </div>
          <Text type="bodyDetail" style={{ display: "inline-block" }}>
            {" "}
            &nbsp;o llevar a sucursal
          </Text>
        </Step>
        {this.updates()}
      </div>,
      this.state.showDetail ? (
        <Step title="En camino" key="camino">
          <Text>Aún no hay información</Text>
        </Step>
      ) : null,
      <Step title="Entregado" key="entregado" last>
        <Text>Aún no hay información</Text>
      </Step>,
    ];
    if (
      this.state.tracking.length === 1 &&
      this.state.shipment.rate.provider.toLowerCase() !== "dhl"
    ) {
      if (!this.state.hasBeenPicked) {
        this.setState({ hasBeenPicked: true });
      }
      return items.slice(1);
    }
    if (this.state.tracking.length === 1) {
      if (!this.state.hasBeenPicked) {
        this.setState({ hasBeenPicked: true });
      }
      return items;
    }
  }

  toggleTrackingModal = () =>
    this.setState({ showTrackingModal: !this.state.showTrackingModal });

  render() {
    moment.locale("es");
    if (
      this.props.message.show &&
      (this.props.message.messageType === "error" ||
        this.props.message.messageType === "success")
    ) {
      setTimeout(this.props.hideMessage, 5000);
    }
    return (
      <div>
        {this.state.showTrackingModal && (
          <Alert
            onClose={this.toggleTrackingModal}
            title="Comparte tracking en tiempo real"
            titleMargin="small"
          >
            <Text>
              Sólo copia el link y hazlo llegar a tu cliente, así podrá conocer
              en tiempo real el estatus de su paquete.
            </Text>
            <Input
              disabled
              style={{ float: "left", maxWidth: "calc(100% - 130px)" }}
              type="text"
              onChange={() => {}}
              value={`app.mienvio.mx/tracking/${
                this.state.shipment.label.tracking_number
              }/${this.state.shipment.rate.provider.toLowerCase()}`}
            />
            <Clipboard
              component="span"
              style={{ float: "left" }}
              data-clipboard-text={`${BASEURL}tracking/${
                this.state.shipment.label.tracking_number
              }/${this.state.shipment.rate.provider.toLowerCase()}`}
            >
              <Button
                type="link"
                className="copy-btn"
                style={{ margin: 0, float: "left", marginLeft: "24px" }}
              >
                <i
                  className="ion-link"
                  style={{
                    float: "left",
                    margin: 0,
                    marginRight: "5px",
                    color: "#4A90E2",
                  }}
                />{" "}
                <span style={{ float: "left", lineHeight: "23px" }}>
                  Copiar link
                </span>
              </Button>
            </Clipboard>
          </Alert>
        )}
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Header>
              <NavExtended>
                <ActionExtended onClick={() => this.props.goToShipmentList()}>
                  <i className="ion-arrow-left-b" />
                  <Title>Volver a órdenes</Title>
                </ActionExtended>
                {this.state.next ? (
                  <Button
                    onClick={() => {
                      this.props.goToShipment(this.state.next);
                    }}
                    type="nav"
                    className="details-arrow"
                  >
                    <i className="ion-ios-arrow-forward" />
                  </Button>
                ) : null}
                {this.state.previous ? (
                  <Button
                    onClick={() => {
                      this.props.goToShipment(this.state.previous);
                    }}
                    type="nav"
                    className="details-arrow"
                  >
                    <i className="ion-ios-arrow-back" />
                  </Button>
                ) : null}
              </NavExtended>
            </Header>
          </Column>
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            {this.props.message.show ? (
              <Message
                color={this.props.message.messageType}
                onClose={this.props.hideMessage}
              >
                {this.props.message.text}
              </Message>
            ) : null}
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} md={1} fluid />
          <Column xs={18} md={12} fluid>
            <Card size="full" className="detail-card">
              {this.state.shipment ? (
                <Row>
                  <Column
                    fluid
                    className={
                      this.state.shipment.status === "PURCHASE"
                        ? "order-detail-title"
                        : ""
                    }
                  >
                    {this.state.shipment.status === "PURCHASE" ? (
                      <Button
                        className="shipment-delete"
                        onClick={() => this.deleteShipment()}
                        type="icon"
                      >
                        <i className="ion-trash-b" />
                      </Button>
                    ) : null}
                    <Text type="helpTextModal" style={{ fontWeight: "800" }}>
                      Orden
                    </Text>
                  </Column>
                  <Column fluid>
                    <Title
                      type="titleCard"
                      style={{ color: "#4a4a4a", fontWeight: "300" }}
                    >
                      {this.state.shipment.order ? (
                        <img
                          src={
                            this.state.shipment.order.marketplace === "shopify"
                              ? shopify
                              : this.state.shipment.order.marketplace ===
                                "magento"
                              ? magento
                              : this.state.shipment.order.marketplace ===
                                "woocommerce"
                              ? woocommerce
                              : prestashop
                          }
                          style={{
                            marginTop: "5px",
                            height: "24px",
                            marginRight: "11px",
                            float: "left",
                          }}
                        />
                      ) : this.state.shipment.status !== "PURCHASE" ? (
                        <img
                          src={shipment}
                          style={{
                            marginTop: "5px",
                            height: "24px",
                            marginRight: "11px",
                            float: "left",
                          }}
                        />
                      ) : null}
                      #{this.state.shipment.object_id}
                    </Title>
                    <Divider className="divider-detail" />
                  </Column>
                  {this.state.shipment.status !== "PURCHASE" &&
                  this.state.shipment.label &&
                  !this.state.tracking ? (
                    <Column fluid>
                      <Column
                        fluid
                        className={
                          this.state.shipment.status === "PURCHASE"
                            ? "order-detail-label"
                            : "order-detail-label-payed"
                        }
                      >
                        <Text type="label">Rastreo</Text>{" "}
                      </Column>
                      <Column fluid>
                        <Label className="detail-text">Número de guía</Label>
                        <br />
                        <Text className="detail-text">
                          {this.state.shipment.label.tracking_number}
                        </Text>
                      </Column>
                      <Column fluid>
                        <Label className="detail-text">Link de rastreo</Label>
                        <br />
                        <Button
                          className="detail-btn"
                          type="link"
                          onClick={() =>
                            window.open(
                              this.state.shipment.label.tracking_url,
                              "blank"
                            )
                          }
                        >
                          Da click aquí <i className="ion-share" />
                        </Button>
                      </Column>
                      <Column fluid>
                        <Divider className="divider-detail" />
                      </Column>
                    </Column>
                  ) : this.state.shipment.status !== "PURCHASE" &&
                    this.state.shipment.label ? (
                    <Column fluid>
                      <Column fluid>
                        <Text type="label">Estatus </Text>
                        <Button
                          className="detail-btn"
                          type="link"
                          onClick={() =>
                            window.open(
                              this.state.shipment.label.tracking_url,
                              "blank"
                            )
                          }
                        >
                          &nbsp;
                          <a data-tip data-for="provider" data-place="right">
                            <i className="ion-link" />
                          </a>
                          <ReactTooltip id="provider">
                            <span>Consultar desde proveedor</span>
                          </ReactTooltip>
                        </Button>
                        <Button
                          type="link"
                          style={{ float: "right", marginTop: "2px" }}
                          onClick={() => this.toggleTrackingModal()}
                        >
                          Compartir información
                        </Button>
                        <Tracking
                          style={{ margin: "10px", marginLeft: "15px" }}
                        >
                          {this.trackingList()}
                          {this.trackingState === "delivered"
                            ? null
                            : this.extraTrackingList()}
                        </Tracking>
                      </Column>
                      <Column fluid>
                        <Divider className="divider-detail" />
                      </Column>
                    </Column>
                  ) : null}
                  {this.state.shipment.status !== "PURCHASE" &&
                  this.state.shipment.label &&
                  this.state.shipment.status !== "ENTREGADO" &&
                  this.state.shipment.status !== "CANCELADO" &&
                  !this.state.tracking ? (
                    <Column fluid>
                      <Column
                        fluid
                        className={
                          this.state.shipment.status === "PURCHASE"
                            ? "order-detail-label"
                            : "order-detail-label-payed"
                        }
                      >
                        <Text type="label">Recolección</Text>{" "}
                      </Column>
                      <Column fluid>
                        <Label className="detail-text">
                          Agendar una recolección
                        </Label>
                        <br />
                        <Button
                          className="detail-btn"
                          type="link"
                          onClick={() =>
                            window.open(
                              this.state.shipment.rate.provider.toLowerCase() ===
                                "estafeta"
                                ? "https://blogger.mienvio.mx/2021/04/como-se-recolecta-mi-envio-con-estafeta/"
                                : this.state.shipment.rate.provider.toLowerCase() ===
                                  "dhl"
                                ? "https://blogger.mienvio.mx/2021/04/como-se-recolecta-mi-envio-con-dhl/"
                                : this.state.shipment.rate.provider.toLowerCase() ===
                                  "redpack"
                                ? "https://blogger.mienvio.mx/2021/04/como-se-recolecta-mi-envio-con-redpack/"
                                : "https://blogger.mienvio.mx/2021/04/como-se-recolecta-mi-envio-con-fedex/",
                              "blank"
                            )
                          }
                        >
                          Ver más <i className="ion-share" />
                        </Button>
                      </Column>
                      <Column fluid>
                        <Divider className="divider-detail" />
                      </Column>
                    </Column>
                  ) : null}
                  <Column
                    fluid
                    className={
                      this.state.shipment.status === "PURCHASE"
                        ? "order-detail-label"
                        : "order-detail-label-payed"
                    }
                  >
                    <Text type="label">Destino</Text>
                    {this.state.shipment.status === "PURCHASE" ? (
                      <Button
                        type="link"
                        className="shipment-delete"
                        onClick={() => this.changeRenderEdit(1)}
                      >
                        Editar
                      </Button>
                    ) : null}
                  </Column>
                  <Column fluid>
                    <Text className="detail-text">
                      {this.state.shipment.address_to.name}
                    </Text>
                  </Column>
                  <Column fluid>
                    <Text className="detail-text">
                      {this.state.shipment.address_to.email}
                    </Text>
                  </Column>
                  <Column fluid>
                    <Text className="detail-text">
                      {this.formatPhone(this.state.shipment.address_to.phone)}
                    </Text>
                  </Column>
                  <Column fluid>
                    <Text className="detail-text">
                      {this.state.shipment.address_to.street}
                    </Text>
                  </Column>
                  {this.state.shipment.address_to.reference ? (
                    <Column fluid>
                      <Text className="detail-text">
                        {this.state.shipment.address_to.reference}
                      </Text>
                    </Column>
                  ) : null}
                  <Column fluid>
                    <Text className="detail-text">
                      Col. {this.state.shipment.address_to.street2}
                    </Text>
                  </Column>
                  <Column fluid>
                    <Text className="detail-text">
                      C.P. {this.state.shipment.address_to.zipcode}
                    </Text>
                  </Column>
                  <Column fluid>
                    <Divider className="divider-detail" />
                  </Column>
                  <Column
                    fluid
                    className={
                      this.state.shipment.status === "PURCHASE"
                        ? "order-detail-label"
                        : "order-detail-label-payed"
                    }
                  >
                    <Text type="label">Origen</Text>
                    {this.state.shipment.status === "PURCHASE" ? (
                      <Button
                        type="link"
                        className="shipment-delete"
                        onClick={() => this.changeRenderEdit(0)}
                      >
                        Editar
                      </Button>
                    ) : null}
                  </Column>
                  <Column fluid>
                    <Text className="detail-text">
                      {this.state.shipment.address_from.name}
                    </Text>
                  </Column>
                  <Column fluid>
                    <Text className="detail-text">
                      {this.state.shipment.address_from.email}
                    </Text>
                  </Column>
                  <Column fluid>
                    <Text className="detail-text">
                      {this.formatPhone(this.state.shipment.address_from.phone)}
                    </Text>
                  </Column>
                  <Column fluid>
                    <Text className="detail-text">
                      {this.state.shipment.address_from.street}
                    </Text>
                  </Column>
                  {this.state.shipment.address_from.reference ? (
                    <Column fluid>
                      <Text className="detail-text">
                        {this.state.shipment.address_from.reference}
                      </Text>
                    </Column>
                  ) : null}
                  <Column fluid>
                    <Text className="detail-text">
                      Col. {this.state.shipment.address_from.street2}
                    </Text>
                  </Column>
                  <Column fluid>
                    <Text className="detail-text">
                      C.P. {this.state.shipment.address_from.zipcode}
                    </Text>
                  </Column>
                  <Column fluid>
                    <Divider className="divider-detail" />
                  </Column>
                  <Column
                    fluid
                    className={
                      this.state.shipment.status === "PURCHASE"
                        ? "order-detail-label"
                        : "order-detail-label-payed"
                    }
                  >
                    <Text type="label">Contenido de la orden</Text>
                  </Column>
                  <Column fluid>
                    <Text className="detail-text">
                      {this.state.shipment.description}
                    </Text>
                    {this.state.shipment.status === "PURCHASE" ? (
                      <Button
                        type="link"
                        className="shipment-delete"
                        onClick={() => this.changeRenderEdit(4)}
                      >
                        Editar
                      </Button>
                    ) : null}
                  </Column>
                </Row>
              ) : null}
            </Card>
          </Column>
          <Column xs={1} className="show-for-small-only" fluid />
          <Column xs={1} md={1} fluid />
          <Column xs={18} md={5} fluid>
            <Card size="full" className="detail-card">
              {this.state.shipment && this.state.shipment.rate ? (
                <Row>
                  <Column
                    fluid
                    className={
                      this.state.shipment.status === "PURCHASE"
                        ? "order-detail-label"
                        : "order-detail-label-payed"
                    }
                  >
                    <Text type="label">Servicio</Text>
                    {this.state.shipment.status === "PURCHASE" ? (
                      <Button
                        type="link"
                        className="shipment-edit"
                        onClick={() => this.changeRenderEdit(3)}
                      >
                        Editar
                      </Button>
                    ) : this.state.shipment.label &&
                      this.state.shipment.status !== "ENTREGADO" &&
                      this.state.shipment.status !== "CANCELADO" ? (
                      <Button
                        type="link"
                        className="shipment-edit"
                        onClick={() =>
                          window.open(
                            this.state.shipment.label.label_url,
                            "blank"
                          )
                        }
                      >
                        Descargar guía
                      </Button>
                    ) : null}
                  </Column>
                  <Column xs={3} fluid className="provider-img-container">
                    <img
                      src={`https://production.mienvio.mx/${this.state.shipment.rate.provider_img}`}
                      alt=""
                    />
                  </Column>
                  <Column xs={9} fluid>
                    <Text className="detail-text">
                      {this.state.shipment.rate.provider}
                    </Text>
                    <br />
                    <Text className="detail-text">
                      {this.state.shipment.rate.servicelevel}{" "}
                      {this.state.shipment.rate.duration_terms}
                      <br />
                    </Text>
                    <Text className="detail-text">
                      <b>
                        $
                        {parseFloat(this.state.shipment.rate.amount).toFixed(2)}
                      </b>
                    </Text>
                  </Column>
                  <Column fluid>
                    {this.state.shipment.status !== "ENTREGADO" ? (
                      <Text type="helpTextModal">
                        Llega el{" "}
                        {moment()
                          .add(this.state.shipment.rate.days + 1, "days")
                          .format("DD-MM-YYYY")}{" "}
                        si se envía el{" "}
                        {moment().add(1, "days").format("DD-MM-YYYY")}
                      </Text>
                    ) : (
                      ""
                    )}
                    <Divider className="divider-detail" />
                  </Column>
                  <Column
                    fluid
                    className={
                      this.state.shipment.status === "PURCHASE"
                        ? "order-detail-label"
                        : "order-detail-label-payed"
                    }
                  >
                    <Text type="label">Información del paquete</Text>
                    {this.state.shipment.status === "PURCHASE" ? (
                      <Button
                        type="link"
                        className="shipment-edit"
                        onClick={() => this.changeRenderEdit(2)}
                      >
                        Editar
                      </Button>
                    ) : null}
                  </Column>
                  <Column fluid>
                    <Label className="detail-text">Dimensiones</Label>
                    <br />
                    <Text className="detail-text">
                      {this.state.shipment.length} x{this.state.shipment.width}{" "}
                      x {this.state.shipment.height} cm.
                    </Text>
                    <br />
                    <Label className="detail-text">Peso</Label>
                    <br />
                    <Text className="detail-text">
                      {this.state.shipment.weight} kg
                    </Text>
                    <br />
                    <Label className="detail-text">Peso volumétrico</Label>
                    <br />
                    <Text className="detail-text">
                      {(1.0 *
                        this.state.shipment.length *
                        this.state.shipment.width *
                        this.state.shipment.height) /
                        5000.0}{" "}
                      kg
                    </Text>
                    <br />
                    <Divider className="divider-detail" />
                  </Column>
                  {this.state.shipment.insurance ? (
                    <Column fluid>
                      <Column
                        fluid
                        className={
                          this.state.shipment.status === "PURCHASE"
                            ? "order-detail-label"
                            : "order-detail-label-payed"
                        }
                      >
                        <Text type="label">Seguro de envío</Text>
                        {this.state.shipment.status === "PURCHASE" ? (
                          <Button
                            type="link"
                            className="shipment-edit"
                            onClick={() => this.changeRenderEdit(5)}
                          >
                            Editar
                          </Button>
                        ) : null}
                      </Column>
                      <Column fluid>
                        <Label className="detail-text">Valor del paquete</Label>
                        <br />
                        <Text className="detail-text">
                          $
                          {parseFloat(
                            this.state.shipment.insurance.insured_amount
                          ).toFixed(2)}
                        </Text>
                        <br />
                        <Label className="detail-text">Responsable</Label>
                        <br />
                        <Text className="detail-text">
                          {this.state.shipment.insurance.holder}
                        </Text>
                        <br />
                        <Text>{this.state.shipment.insurance.email}</Text>
                        <br />
                        <Label className="detail-text">Costo del seguro</Label>
                        <br />
                        <Text className="detail-text">
                          $
                          {parseFloat(
                            this.state.shipment.insurance.cost
                          ).toFixed(2)}
                        </Text>
                        <Divider className="divider-detail" />
                      </Column>
                    </Column>
                  ) : (
                    <Column fluid>
                      <Column
                        fluid
                        className={
                          this.state.shipment.status === "PURCHASE"
                            ? "order-detail-label"
                            : "order-detail-label-payed"
                        }
                      >
                        <Text type="label">Seguro de envío</Text>
                        {this.state.shipment.status === "PURCHASE" ? (
                          <Button
                            type="link"
                            className="shipment-edit"
                            onClick={() => this.changeRenderEdit(5)}
                          >
                            Editar
                          </Button>
                        ) : null}
                      </Column>
                      <Column fluid>
                        <Label className="detail-text">Valor del paquete</Label>
                        <br />
                        <StyledEmpty />
                        <Label className="detail-text">Responsable</Label>
                        <br />
                        <StyledEmpty />
                        <StyledEmpty />
                        <Label className="detail-text">Costo del seguro</Label>
                        <br />
                        <StyledEmpty />
                        <Divider className="divider-detail" />
                      </Column>
                    </Column>
                  )}
                  <Column
                    fluid
                    className={
                      this.state.shipment.status === "PURCHASE"
                        ? "order-detail-label"
                        : "order-detail-label-payed"
                    }
                  >
                    <Label className="detail-text">Total a pagar</Label>
                  </Column>
                  <Column fluid>
                    <Text className="detail-text">${this.getTotal()}</Text>
                    {!this.props.user.methodPayments.includes("none") &&
                      this.state.shipment.status === "PURCHASE" && (
                        <Button
                          size="block"
                          className="block"
                          onClick={() =>
                            this.props.goToPayment([
                              this.state.shipment.object_id,
                            ])
                          }
                        >
                          Pagar
                        </Button>
                      )}
                  </Column>
                </Row>
              ) : null}
            </Card>
          </Column>
          <Column xs={1} fluid />
        </Row>
        {this.state.renderEdit !== null
          ? this.editShipment(this.state.renderEdit)
          : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  shipments: state.shipments,
  addresses: state.addresses,
  rates: state.rates,
  message: state.message,
  packages: state.packages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteShipments,
      getUserShipments,
      getUserShipmentsPurchase,
      getAllUserShipments,
      goToShipmentList: () => push("/shipments"),
      goToShipment: (id) => push(`/envios/${id}`),
      getUserAllAddresses,
      getUserAllPackages,
      createRates,
      editAddress,
      hideMessage,
      hideLoader,
      showLoader,
      editShipmentRate,
      editShipmentDescription,
      editShipmentDimensions,
      editShipmentInsurance,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDetail);
