import ReactPixel from "react-facebook-pixel";
import { FACEBOOK_PIXEL_ID } from "./constants";

export const hexToRGBA = (hex, alpha = 0) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join("")}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ","
    )}, ${alpha})`;
  }
  throw new Error("Bad Hex");
};

export const getVolumetricWeight = (ht, wt, lt) =>
  Math.ceil((parseFloat(ht) * parseFloat(wt) * parseFloat(lt)) / 5000);

export const randomIntFromInterval = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const formatPhone = (phone) => {
  if (!phone && phone.length === 0) {
    return "";
  }

  phone = clearPhone(phone);

  const mask = "(XXX) XXX XX XX";
  const s = `${phone}`;
  let r = "";
  for (let i = 0, is = 0; i < mask.length && is < s.length; i++) {
    r += mask.charAt(i) === "X" ? s.charAt(is++) : mask.charAt(i);
  }
  return r;
};

export const clearPhone = (phone) =>
  phone
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .split(" ")
    .join("");

export const jsUcfirst = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const randomString = (length = 8) => {
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < length; i++) {
    randomString += possible.charAt(
      Math.floor(Math.random() * possible.length)
    );
  }

  return randomString;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

export const RemoveAccents = (str) => {
  const accents =
    "ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
  const accentsOut =
    "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  return str
    .split("")
    .map((letter, index) => {
      const accentIndex = accents.indexOf(letter);
      return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
    })
    .join("");
};

export const csvJSON = (csv) => {
  const lines = csv.split("\n");
  const result = [];
  const headers = lines[0]
    .split(",")
    .map((result, index) => result.replace(/(\r\n|\n|\r)/gm, ""));
  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentline = lines[i].split(",");
    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }
    result.push(obj);
  }
  return JSON.stringify(result); // JSON
};

export const convertFileToString = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (e: any) => {
      reject(e);
    };
    // reader.readAsBinaryString(file);
    reader.readAsText(file);
  });

export const isJsonEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};
export const shorten = (str, len) => {
  if (!str) return str;
  if (str.length <= len) return str;
  return `${str.substr(0, len)}...`;
};
export const isArrayEmpty = (arr) => {
  if (typeof arr !== "undefined" && arr.length > 0) {
    return false;
  }
  return true;
};

export const getFirstElement = (array) => {
  if (array.length) {
    return array[0];
  }

  return 0;
};

export const sendFBEvent = (event, data = {}) => {
  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(FACEBOOK_PIXEL_ID, advancedMatching, options);

  ReactPixel.trackCustom(event, data);
};

export const numberWithCommas = (number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const isTodayBetweenDates = (startDate, endDate) => {
  const today = new Date();
  return today >= startDate && today <= endDate;
};

export const existInJsonArray = (name, value, array) =>
  array.filter((data) => data.name === name && data.value === value).length > 0;

export const searchPermission = (
  namePermission,
  actionPermission,
  Permissions
) => {
  if (Permissions) {
    for (let i = 0; i < Permissions.length; i++) {
      //console.log({ namePermission, permiso: Permissions[i].name });
      if (
        Permissions[i].name === namePermission &&
        Permissions[i].value === actionPermission
      ) {
        return Permissions[i];
      }
    }
  }
};

export const removeDuplicates = (arr, prop) => {
  const obj = {};
  return Object.keys(
    arr.reduce((prev, next) => {
      if (!obj[next[prop]]) obj[next[prop]] = next;
      return obj;
    }, obj)
  ).map((i) => obj[i]);
};

const phoneRegex = /^[0-9]{10}/;

export const validPhone = (p) => phoneRegex.test(Number(p));

export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const validEmail = (e) => emailRegex.test(e);

const textRegex = /^[A-Za-z]{1-254}/;

export const validText = (e) => textRegex.test(e);

const urlRegex =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const validUrl = (e) => urlRegex.test(e);
