import axios from 'axios';

export const STRIPE_PUBLIC = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  ? 'pk_test_lroL1tij4uijiCpr4hhwBZzh'
  : 'pk_live_nQ7gEHv9CqpG9P80cww0vvrR';

export const getCards = id => axios
  .get(`api/cards/marketplace/${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));

export const addCard = (id, token) => axios
  .post(`api/cards/marketplace/${id}`, {
    token,
    alias: 'card',
  })
  .then(res => ({ type: 'Success', res }))
  .catch((err) => {
    if (err.response.data.error.type === 'external_provider_error') {
      const errorMessages = {
        incorrect_number: 'El número de la tarjeta es incorrecto',
        invalid_number: 'El número de la tarjeta es inválido',
        invalid_expiry_month: 'El mes de expiración de la tarjeta es inválido',
        invalid_expiry_year: 'El año de expiración de la tarjeta es inválido',
        invalid_cvc: 'El código de seguridad es inválido',
        expired_card: 'La tarjeta ha expirado',
        incorrect_cvc: 'El código de seguridad es incorrecto',
        incorrect_zip: 'El código postal de la tarjeta es inválido',
        card_declined: 'La tarjeta fue declinada',
        processing_error: 'Ocurrió un error procesando su tarjeta',
        rate_limit: 'Espere un momento y vuelva intentarlo de nuevo',
        generic_decline: 'La tarjeta fue declinada, contacta a tu banco',
        insufficient_funds:
                  'Tarjeta con fondos insuficientes, intenta con otra tarjeta',
        fraudulent: 'La transacción fue declinada por tu banco',
      };
      return (errorMessages[err.response.data.error.subtype]);
    }
    return (err.response.data.error);
  });
