import React from "react";
import SelectableBox from "../../SelectableBox";
import { Column, Row } from "hedron";
import Text from "../../Text";
import Button from "../../Button";

const formatPhone = (phone) => {
  phone = Number(phone);

  const mask = "(XXX) XXX XX XX";
  let s = `${phone}`;
  let r = "";
  for (let i = 0, is = 0; i < mask.length && is < s.length; i++) {
    r += mask.charAt(i) === "X" ? s.charAt(is++) : mask.charAt(i);
  }
  return r;
};

const LessAddresses = (props) => (
  <div>
    {Object.values(props.addresses).map((address, index) => (
      <SelectableBox
        key={address.object_id}
        value={address.object_id}
        type="radio"
        name={index}
        onChange={props.onChangefn}
        isChecked={props.isChecked(address.object_id)}
      >
        <Row>
          <Column xs={12} md={12} className="text-left">
            <Text type="P" className="title">
              {address.alias}
            </Text>
            <Text type="P">{address.name}</Text>
            <Text type="P">{address.email}</Text>
            <Text type="P">
              {(address.phone || "").charAt(0) == "(" &&
              address.phone.charAt(4) == ")"
                ? address.phone
                : formatPhone(address.phone)}
            </Text>
            <Text type="P">{address.street}</Text>
            <Text type="P">{address.reference}</Text>
            <Text type="P">Col. {address.street2}</Text>
            <Text type="P">{`${address.city}, ${address.state}, C.P. ${address.zipcode}`}</Text>
          </Column>
        </Row>
      </SelectableBox>
    ))}

    <Button type="link" className="edit-btn-left" onClick={props.newAddressFn}>
      Ingresar una dirección manualmente
    </Button>
  </div>
);

export default LessAddresses;
