import { initialState } from './initialState';
import {
  TOGGLE,
  SET_STEP_TITLES,
  SET_STEP,
  PREVIOUS_STEP,
  NEXT_STEP,
  SET_VALUE,
  SET_ERROR,
  UNSET_ERROR,
  SET_ICON,
  GET_ADDRESSES_SIDEBAR,
  INIT_LOAD_ADDRESSES,
  FINISH_LOAD_ADDRESSES,
  SELECT_ADDRESS_FROM,
  CLEAR_ADDRESS_FROM,
  SELECT_ADDRESS_TO,
  CLEAR_ADDRESS_TO,
  GET_PACKAGES_SIDEBAR,
  SELECT_PACKAGE,
  CLEAR_PACKAGE,
  CLEAR_SIDEBAR,
  ERROR_GETTING_RATES,
  INIT_GETTING_RATES,
  FINISH_GETTING_RATES,
  CHANGE_LOADING_COST,
  SEND_INFO_DUPLICATE_ORDER,
  SEND_INFO_EDIT_ORDER,
  SET_OLD_INFO,
  SET_OLD_RATE,
  GENERATE_LABEL_MARKETPLACE,
  SET_ICON_ANY,
  IS_LOADING_ZIPCODE,
  CHANGE_MANUAL_INFO,
  CHANGE_LOADING_SIDEBAR,
  CHANGE_STEP_ERROR,
  GET_COUNTRIES_SIDEBAR_ORDER,
  OPEN_COMMERCIAL_INVOICE,
  CLOSE_COMMERCIAL_INVOICE,
  COMMERCIAL_INVOICE,
  SEND_INFO_DEVOLUTION,
} from './actionsTypes';
import { getVolumetricWeight } from '../../utils/global_functions';
import { SEND_INFO_NEW_ORDER } from '../QuickQuote/actionsTypes';

export default (state = initialState, action: Action) => {
  let belongsTo; let key; let open; let title; let name; let value; let field; let
    error;
  switch (action.type) {
  case TOGGLE:
    open = action.payload.open;
    title = action.payload.title;
    return {
      ...state,
      open,
      title,
    };
  case SET_STEP_TITLES:
    return {
      ...state,
      stepTitles: action.payload,
    };
  case NEXT_STEP:
    return {
      ...state,
      step: state.step + 1,
    };
  case PREVIOUS_STEP:
    return {
      ...state,
      step: state.step - 1,
    };
  case SET_STEP:
    return {
      ...state,
      step: action.payload,
    };
  case SET_VALUE:
    name = action.payload.name;
    value = action.payload.value;
    belongsTo = name.split('_')[0];
    key = name.split('_')[1];
    return {
      ...state,
      data: {
        ...state.data,
        [belongsTo]: {
          ...state.data[belongsTo],
          [key]: value,
        },
      },
    };
  case SET_ERROR:
    field = action.payload.field;
    error = action.payload.error;
    belongsTo = field.split('_')[0];
    key = field.split('_')[1];
    return {
      ...state,
      data: {
        ...state.data,
        [belongsTo]: {
          ...state.data[belongsTo],
          [`${key}Error`]: error,
        },
      },
    };
  case UNSET_ERROR:
    field = action.payload.field;
    belongsTo = field.split('_')[0];
    key = field.split('_')[1];
    return {
      ...state,
      data: {
        ...state.data,
        [belongsTo]: {
          ...state.data[belongsTo],
          [`${key}Error`]: '',
        },
      },
    };
  case SET_ICON:
    const currentTitles = state.stepTitles;
    currentTitles[state.step].status = action.payload.icon;
    return {
      ...state,
      stepTitles: currentTitles,
    };
  case SET_ICON_ANY:
    const curTitle = state.stepTitles;
    curTitle[action.payload.step].status = action.payload.icon;
    return {
      ...state,
      stepTitles: curTitle,
    };
  case INIT_LOAD_ADDRESSES:
    return {
      ...state,
      loading: true,
    };
  case GET_ADDRESSES_SIDEBAR:
    return {
      ...state,
      addresses: action.payload,
    };
  case FINISH_LOAD_ADDRESSES:
    return {
      ...state,
      loading: false,
    };
  case SELECT_ADDRESS_FROM:
    return {
      ...state,
      fromSelectIdAddress: action.payload.id,
      fromAddress: action.payload.address,
    };
  case CLEAR_ADDRESS_FROM:
    return {
      ...state,
      fromSelectIdAddress: null,
      fromAddress: null,
      data: {
        ...state.data,
        from: {
          alias: '',
          aliasError: '',
          name: '',
          nameError: '',
          email: '',
          emailError: '',
          phone: '',
          phoneError: '',
          country: null,
          countryError: '',
          neighborhood: '',
          neighborhoodError: '',
          zipcode: '',
          zipcodeError: '',
          city: '',
          cityError: '',
          street: '',
          streetError: '',
          reference: '',
          referenceError: '',
          saveAddress: false,
        },
      },
    };
  case SELECT_ADDRESS_TO:
    return {
      ...state,
      toSelectIdAddress: action.payload.id,
      toAddress: action.payload.address,
    };
  case CLEAR_ADDRESS_TO:
    return {
      ...state,
      toSelectIdAddress: null,
      toAddress: null,
      data: {
        ...state.data,
        to: {
          alias: '',
          aliasError: '',
          name: '',
          nameError: '',
          email: '',
          emailError: '',
          phone: '',
          phoneError: '',
          country: null,
          countryError: '',
          neighborhood: '',
          neighborhoodError: '',
          zipcode: '',
          zipcodeError: '',
          city: '',
          cityError: '',
          street: '',
          streetError: '',
          reference: '',
          referenceError: '',
          saveAddress: false,
        },
      },
    };
  case GET_PACKAGES_SIDEBAR:
    return {
      ...state,
      packages: action.payload,
    };
  case SELECT_PACKAGE:
    return {
      ...state,
      selectedPackage: action.payload.pack,
      selectedIdPackage: action.payload.id,
    };
  case CLEAR_PACKAGE:
    return {
      ...state,
      selectedPackage: null,
      selectedIdPackage: null,
      data: {
        ...state.data,
        package: {
          ...state.data.package,
          alias: '',
          aliasError: '',
          packageType: null,
          packageTypeError: '',
          dimensions: {
            length: '',
            width: '',
            height: '',
          },
          volumetricWeight: '',
          dimensionsError: '',
        },
      },
    };
  case CLEAR_SIDEBAR:
    return { ...initialState };
  case INIT_GETTING_RATES:
    return {
      ...state,
      loading: true,
    };
  case FINISH_GETTING_RATES:
    return {
      ...state,
      loading: false,
      errorRates: false,
    };
  case ERROR_GETTING_RATES:
    return {
      ...state,
      loading: false,
      errorRates: true,
    };
  case CHANGE_LOADING_COST:
    return {
      ...state,
      loadingCost: action.payload,
    };
  case SEND_INFO_NEW_ORDER:
    return {
      ...state,
      manualFrom: true,
      manualTo: true,
      isQuickQuote: true,
      data: {
        ...state.data,
        from: {
          ...state.data.from,
          country: action.payload.country,
          zipcode: action.payload.origin,
        },
        to: {
          ...state.data.to,
          zipcode: action.payload.destiny,
        },
        package: {
          ...state.data.package,
          dimensions: action.payload.dimensions,
          weight: action.payload.weight,
        },
      },
    };
  case SEND_INFO_DUPLICATE_ORDER:
    let volErrordup = '';
    const voldup = getVolumetricWeight(
      action.payload.details.length,
      action.payload.details.width,
      action.payload.details.height,
    );
    if (voldup > 190) volErrordup = 'El valor del peso volumétrico debe ser menor que 190kg.';
    return {
      ...state,
      manualFrom: true,
      manualTo: true,
      isQuickQuote: true,
      data: {
        ...state.data,
        from: {
          ...state.data.from,
          name: action.payload.details.address_from.name,
          email: action.payload.details.address_from.email,
          phone: action.payload.details.address_from.phone,
          street: action.payload.details.address_from.street,
          neighborhood: action.payload.details.address_from.street2,
          city: `${action.payload.details.address_from.city}, ${
            action.payload.details.address_from.state
          }`,
          country: action.payload.details.address_from.country,
          zipcode: action.payload.details.address_from.zipcode,
          reference: action.payload.details.address_from.reference,
        },
        to: {
          ...state.data.to,
          name: action.payload.details.address_to.name,
          email: action.payload.details.address_to.email,
          phone: action.payload.details.address_to.phone,
          street: action.payload.details.address_to.street,
          neighborhood: action.payload.details.address_to.street2,
          city: `${action.payload.details.address_to.city}, ${
            action.payload.details.address_to.state
          }`,
          country: action.payload.details.address_to.country === 'Mexico' ? 0 : 1,
          zipcode: action.payload.details.address_to.zipcode,
          reference: action.payload.details.address_to.reference,
        },
        package: {
          ...state.data.package,
          dimensions: {
            ...state.data.package.dimensions,
            length: action.payload.details.length.toString(),
            width: action.payload.details.width.toString(),
            height: action.payload.details.height.toString(),
          },
          weight: action.payload.details.weight.toString(),
          weightError: volErrordup,
          volumetricWeight: voldup,
          description: action.payload.details.description,
          insuredAmount: action.payload.details.declared_value.toString(),
        },
      },
    };
  case SEND_INFO_DEVOLUTION:
    let volErrorDev = '';
    const volDev = getVolumetricWeight(
      action.payload.details.length,
      action.payload.details.width,
      action.payload.details.height,
    );
    if (volDev > 190) volErrorDev = 'El valor del peso volumétrico debe ser menor que 190kg.';
    return {
      ...state,
      manualFrom: true,
      manualTo: true,
      isQuickQuote: true,
      isDevolution: true,
      data: {
        ...state.data,
        to: {
          ...state.data.from,
          name: action.payload.details.address_from.name,
          email: action.payload.details.address_from.email,
          phone: action.payload.details.address_from.phone,
          street: action.payload.details.address_from.street,
          neighborhood: action.payload.details.address_from.street2,
          city: `${action.payload.details.address_from.city}, ${
            action.payload.details.address_from.state
          }`,
          country: action.payload.details.address_from.country,
          zipcode: action.payload.details.address_from.zipcode,
          reference: action.payload.details.address_from.reference,
        },
        from: {
          ...state.data.to,
          name: action.payload.details.address_to.name,
          email: action.payload.details.address_to.email,
          phone: action.payload.details.address_to.phone,
          street: action.payload.details.address_to.street,
          neighborhood: action.payload.details.address_to.street2,
          city: `${action.payload.details.address_to.city}, ${
            action.payload.details.address_to.state
          }`,
          country: action.payload.details.address_to.country,
          zipcode: action.payload.details.address_to.zipcode,
          reference: action.payload.details.address_to.reference,
        },
        package: {
          ...state.data.package,
          dimensions: {
            ...state.data.package.dimensions,
            length: action.payload.details.length.toString(),
            width: action.payload.details.width.toString(),
            height: action.payload.details.height.toString(),
          },
          weight: action.payload.details.weight.toString(),
          weightError: volErrorDev,
          volumetricWeight: volDev,
          description: action.payload.details.description,
          insuredAmount: action.payload.details.declared_value.toString(),
        },
      },
    };
  case SEND_INFO_EDIT_ORDER:
    let volError = '';
    const vol = getVolumetricWeight(
      action.payload.details.length,
      action.payload.details.width,
      action.payload.details.height,
    );
    if (vol > 190) volError = 'El valor del peso volumétrico debe ser menor que 190kg.';
    return {
      ...state,
      isEditing: true,
      isQuickQuote: true,
      editing: action.payload.details.object_id,
      data: {
        ...state.data,
        from: {
          ...state.data.from,
          name: action.payload.details.address_from.name,
          email: action.payload.details.address_from.email,
          phone: action.payload.details.address_from.phone,
          street: action.payload.details.address_from.street,
          neighborhood: action.payload.details.address_from.street2,
          city: `${action.payload.details.address_from.city}, ${
            action.payload.details.address_from.state
          }`,
          country: action.payload.details.address_from.country,
          zipcode: action.payload.details.address_from.zipcode,
          reference: action.payload.details.address_from.reference,
        },
        to: {
          ...state.data.to,
          name: action.payload.details.address_to.name,
          email: action.payload.details.address_to.email,
          phone: action.payload.details.address_to.phone,
          street: action.payload.details.address_to.street,
          neighborhood: action.payload.details.address_to.street2,
          city: `${action.payload.details.address_to.city}, ${
            action.payload.details.address_to.state
          }`,
          country: action.payload.details.address_to.country,
          zipcode: action.payload.details.address_to.zipcode,
          reference: action.payload.details.address_to.reference,
        },
        package: {
          ...state.data.package,
          dimensions: {
            ...state.data.package.dimensions,
            length: action.payload.details.length.toString(),
            width: action.payload.details.width.toString(),
            height: action.payload.details.height.toString(),
          },
          weight: action.payload.details.weight.toString(),
          weightError: volError,
          volumetricWeight: vol,
          description: action.payload.details.description,
          insuredAmount: action.payload.details.declared_value.toString(),
        },
      },
    };
  case SET_OLD_INFO:
    return {
      ...state,
      oldOrigin: action.payload.origin,
      oldDestiny: action.payload.destiny,
      oldDimensions: action.payload.dimensions,
      oldWeight: action.payload.weight,
    };
  case SET_OLD_RATE:
    return {
      ...state,
      oldRate: action.id,
    };
  case GENERATE_LABEL_MARKETPLACE:
    const { model } = action.payload;
    const { isManualEditing } = action.payload;
    const shipment = action.payload.shipment ? action.payload.shipment : null;
    return {
      ...state,
      object_id: model.object_id,
      marketplace: model.marketplace,
      shop_id: model.shop_id,
      isQuickQuote: true,
      isEditing: true,
      manualTo: isManualEditing,
      data: {
        ...state.data,
        to: {
          ...state.data.to,
          name: shipment ? shipment.name : state.data.to.name,
          country: 0,
          email: shipment ? shipment.email : state.data.to.email,
          phone: shipment ? shipment.phone : state.data.to.phone,
          street: shipment ? shipment.street : state.data.to.street,
          neighborhood: shipment ? shipment.street2 : state.data.to.neighborhood,
          zipcode: shipment ? shipment.zipcode : state.data.to.zipcode,
          reference: shipment ? shipment.reference : state.data.to.reference,
        },
      },
    };
  case IS_LOADING_ZIPCODE:
    return {
      ...state,
      loadingZipCode: action.payload,
    };
  case CHANGE_MANUAL_INFO:
    return {
      ...state,
      [action.payload.type]: action.payload.status,
    };
  case CHANGE_LOADING_SIDEBAR:
    return {
      ...state,
      loading: action.loading,
    };
  case CHANGE_STEP_ERROR:
    return {
      ...state,
      stepError: {
        ...state.stepError,
        [action.payload.step]: action.payload.error,
      },
    };
  case GET_COUNTRIES_SIDEBAR_ORDER:
    return {
      ...state,
      countries: action.payload,
    };
  case OPEN_COMMERCIAL_INVOICE:
    return {
      ...state,
      openInvoice: true,
    };
  case CLOSE_COMMERCIAL_INVOICE:
    return {
      ...state,
      openInvoice: false,
    };
  case COMMERCIAL_INVOICE:
    return {
      ...state,
      commercialInvoice: {
        ...state.commercialInvoice,
        invoice_no: action.com_invoice.invoice_no,
        purchase_order: action.com_invoice.purchase_order,
        payment_terms: action.com_invoice.payment_terms,
        incoterm: action.com_invoice.incoterm,
        incoterm_example: action.com_invoice.incoterm_example,
        export_reason: action.com_invoice.export_reason,
        special_instructions: action.com_invoice.special_instructions,
        declaration_statements: action.com_invoice.declaration_statements,
        packages: action.com_invoice.packages,
        from_tax: action.com_invoice.from_tax,
        to_tax: action.com_invoice.to_tax,
      },
    };
  default:
    return state;
  }
};
