
import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { boundMethod } from 'autobind-decorator';
import { Row, Column } from 'hedron';
import Skeleton from 'react-loading-skeleton';
import close_icon from '../../WrapperSideBar/img/close.svg';
import invoiceIcon from './icons/Invoices.svg';
import NewTable from '../../NewTable';
import wallet from '../../CardsList/icons/wallet.svg';
import { getInvoices } from '../../../modules/marketplaceAccount/invoices';

const columns = [
  {
    name: 'ID de compra',
    selector: 'object_id',
  },
  {
    name: 'Fecha',
    selector: 'created_at',
  },
  {
    name: 'Concepto',
    selector: 'concept',
    cell: (row) => {
      if (row.type) {
        return (
          <p>
            Deposito
          </p>
        );
      }
      return (
        <p>
          Paquete
        </p>
      );
    },
  },
  {
    name: 'Metodo de Pago',
    selector: 'alias',
    cell: row => (
      <p>
        <img height="12px" width="10px" src={wallet} />
        {' '}
        Wallet
      </p>
    ),
  },
  {
    name: 'IVA 16%',
    selector: 'total',
    cell: row => (
      `$${((row.amount / 1.16) * 0.16).toFixed(2)}`
    ),
  },
  {
    name: 'Total',
    selector: 'amount',
  },
];

const data = [
  {
    purchase_id: '18876',
    created_at: '4442',
    concept: 'Paquete',
    iva: '$31.72',
    total: '$229.97',
  },
  {
    purchase_id: '18876',
    created_at: '4442',
    concept: 'Paquete',
    iva: '$31.72',
    total: '$229.97',
  },
  {
    purchase_id: '18876',
    created_at: '4442',
    concept: 'Paquete',
    iva: '$31.72',
    total: '$229.97',
  },
  {
    purchase_id: '18876',
    created_at: '4442',
    concept: 'Paquete',
    iva: '$31.72',
    total: '$229.97',
  },
  {
    purchase_id: '18876',
    created_at: '4442',
    concept: 'Paquete',
    iva: '$31.72',
    total: '$229.97',
  },
  {
    purchase_id: '18876',
    created_at: '4442',
    concept: 'Paquete',
    iva: '$31.72',
    total: '$229.97',
  },
  {
    purchase_id: '18876',
    created_at: '4442',
    concept: 'Paquete',
    iva: '$31.72',
    total: '$229.97',
  },
  {
    purchase_id: '18876',
    created_at: '4442',
    concept: 'Paquete',
    iva: '$31.72',
    total: '$229.97',
  },
];

export default class Invoices extends React.Component {
  state = {
    data: [],
    loading: false,
  }

  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.id) {
      this.setState({ loading: true });
      getInvoices(this.props.id).then((response) => {
        this.setState({ loading: false });
        if (response.type === 'Success') {
          this.setState({ data: response.response.data });
        } else {
          this.props.notify('Error al obtener la información', 'error');
        }
      });
    } else {
      this.props.closeElement();
    }
  }


  render() {
    return (
      <div>
        <div className="header-data-visualization">
          <img src={invoiceIcon} alt="" className="selected-icon" />
          <p className="title-header-data-visualization">Facturas</p>
          <img onClick={() => this.props.closeElement()} src={close_icon} alt="" className="close-icon" />
        </div>
        {this.state.loading
          ? (
            <div className="container-loading">
              <Skeleton height={30} count={6} />
            </div>
          )
          : (
            <NewTable
              // hideForSmall={true}
              selectableRows
              shipping={false}
              columns={columns}
              data={this.state.data}
              onRowClicked={this.viewPackageDetails}
              noDataComponent={<div />}
            />
          )
        }
      </div>
    );
  }
}
