import React from "react";
import { push } from "react-router-redux";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { Row, Column } from "hedron";

import Intercom from "react-intercom";

import { INTERCOM_APP_ID } from "../../utils/constants";
import Shipments from "../shipments";
import Reports from "../reports";
import masterShipments from "../masterShipments";
import accountsAdmin from "../accountsAdmin";
import Dashboard from "../dashboard";
import DashboardPayment from "../dashboard/payment.js";
import Integrations from "../integrations";
import Invoices from "../invoices";
import Addresses from "../addresses";
import Packages from "../packages";
import Payment from "../payment";
import Profile from "../profile";
//import PaymentPlan from '../PaymentPlan';
//import { InvoicePlan } from '../../components/InvoicePlan';
import Rates from "../rates";
import Accounts from "../subaccounts";
import Quotations from "../quotations";
import StatusReport from "../statusReport";
import ShipmentsReady from "../shipmentsReady";
import Sandbox from "../sandbox";
import Sidebar from "../../components/Sidebar";
import Button from "../../components/Button";
import NotFound from "../../components/NotFound";
import { Login } from "../../components/Login";
import { getUser } from "../../modules/user";
import manageShipments from "../manageShipments";
import { searchPermission } from "../../utils/global_functions";
// import { SelectPlan } from "../../components/SelectPlan";
import { setTiendaNubeIntegration } from "../../modules/integrations";

import "./style.css";

const translateRoute = (route) => {
  if (route.indexOf("integraciones") > -1) {
    return "integrations";
  }
  if (route.indexOf("empaques") > -1) {
    return "packages";
  }
  if (route.indexOf("rates") > -1) {
    return "rates";
  }
  if (route.indexOf("refered") > -1) {
    return "refered";
  }
  if (route.indexOf("accounts") > -1) {
    return "accounts";
  }
  if (route.indexOf("quotations") > -1) {
    return "quotations";
  }
  if (route.indexOf("status-report") > -1) {
    return "status-report";
  }
  if (route.indexOf("ajustes-envios") > -1) {
    return "automatizations";
  }
  if (route.indexOf("envios") > -1) {
    return "shipments";
  }
  if (route.indexOf("reportes") > -1) {
    return "reports";
  }
  if (route.indexOf("facturas") > -1) {
    return "invoices";
  }
  if (route.indexOf("direcciones") > -1) {
    return "addresses";
  }
  if (route.indexOf("metodos-pago") > -1) {
    return "payment";
  }
  if (route.indexOf("admin-accounts") > -1) {
    return "admin";
  }
  if (route.indexOf("shipments-ready") > -1) {
    return "shipmentsReady";
  }
  if (route.indexOf("perfil") > -1) {
    return "settings";
  }
  return "dashboard";
};

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuMobileOpen: false,
    };
  }

  componentWillMount() {
    const urlParams = new URLSearchParams(window.location.search);
    if (this.isLoggedIn() && urlParams.get("shop") === "tienda_nube") {
      const code = urlParams.get("code");
      this.props.setTiendaNubeIntegration(code);
    }
  }

  toggleMobileMenu() {
    this.setState({ isMenuMobileOpen: !this.state.isMenuMobileOpen });
  }

  getUrlParameter(name) {
    const aux = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${aux}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  checkURLSearch() {
    if (
      this.getUrlParameter("shop") &&
      window.location.pathname === "/integraciones"
    ) {
      return true;
    }
    return false;
  }

  isLoggedIn() {
    if (localStorage.getItem("token")) {
      const token = `Bearer ${localStorage.getItem("token")}`;
      axios.defaults.headers.common.Authorization = token;
      axios.defaults.headers.common.source = "web";
      return true;
    }
    return false;
  }

  _handleOverFlow = (path: string) => {
    const currPath = path.length > 8 ? path.substr(path.length - 8) : path;
    return currPath == "checkout";
  };

  render() {
    if (!this.isLoggedIn() && this.checkURLSearch()) {
      let url;
      if (this.getUrlParameter("shop") === "tienda_nube") {
        url = `/login?url=integraciones&shop=${this.getUrlParameter(
          "shop"
        )}&code=${this.getUrlParameter("code")}`;
      } else {
        url = `/login?url=integraciones&shop=${this.getUrlParameter("shop")}`;
      }
      return <Redirect to={url} />;
    } else if (!this.isLoggedIn()) {
      return <Redirect to="/login" />;
    }

    return (
      <Row divisions={24}>
        <Column xs={0} md={4} className="menu-sidebar-container">
          <Sidebar
            {...this.props}
            onClose={() => {
              this.toggleMobileMenu();
            }}
            isMenuMobileOpen={!!this.state.isMenuMobileOpen}
            selected={translateRoute(this.props.routing.location.pathname)}
          />
        </Column>
        <Column
          xs={24}
          md={20}
          fluid
          className
          className={`main-content ${this._handleOverFlow(this.props.routing.location.pathname) &&
            "no-overflow"
            }`}
        >
          <button
            className="menu-mobile-button"
            onClick={() => {
              this.toggleMobileMenu();
            }}
          >
            <i className="ion-android-menu" />
          </button>

          <Switch>
            {this.props.user.role === "manager" ||
              this.props.user.role === "master" ? (
              <Route exact path="/" component={accountsAdmin} />
            ) : (
              <Route exact path="/" component={Dashboard} />
            )}
            <Route exact path="/verified" component={Dashboard} />
            <Route exact path="/verificationFailed" component={Login} />
            <Route
              exact
              path="/dashboard/checkout"
              component={DashboardPayment}
              redire
            />
            <Route path="/envios" component={Shipments} />
            <Route path="/reportes" component={Reports} />
            <Route path="/master-envios" component={masterShipments} />
            <Route path="/admin-accounts" component={accountsAdmin} />
            <Route path="/checkout" component={Shipments} />
            <Route path="/integraciones" component={Integrations} />
            <Route path="/facturas" component={Invoices} />
            <Route path="/direcciones" component={Addresses} />
            <Route path="/empaques" component={Packages} />
            <Route path="/metodos-pago" component={Payment} />
            <Route path="/perfil" component={Profile} />
            <Route path="/rates" component={Rates} />
            <Route path="/accounts" component={Accounts} />
            <Route path="/quotations" component={Quotations} />
            <Route path="/automatizaciones" component={Integrations} />
            <Route path="/sandbox" component={Sandbox} />
            <Route path="/shipments-ready" component={ShipmentsReady} />
            <Route path="/manage-shipments" component={manageShipments} />
            {/* <Route path="/select-plan/:action" component={SelectPlan} /> */}
            <Route path="/status-report/:token" component={StatusReport} />
            <Route component={NotFound} />
          </Switch>
        </Column>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  routing: state.routing,
  loader: state.loader,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToShipments: () => push("/envios"),
      goToReports: () => push("/reportes"),
      goToIntegrations: () => push("/integraciones"),
      goToPackages: () => push("/empaques"),
      goToInvoices: () => push("/facturas"),
      goToAddresses: () => push("/direcciones"),
      goToPaymentMethods: () => push("/metodos-pago"),
      goToRates: () => push("/rates"),
      goToRefered: () => push("/refered"),
      goToAccounts: () => push("/accounts"),
      goToShipmentsReady: () => push("/shipments-ready"),
      goToQuotations: () => push("/quotations"),
      goToStatusReport: () => push("/status-report"),
      goToAccountsAdmin: () => push("/admin-accounts"),
      goToAutomatizations: () => push("/perfil/ajustes-envios"),
      goToPlan: () => push("/select-plan/edit"),
      goToDashboard: () => push("/"),
      goToProfile: () => push("/perfil"),
      getUser,
      setTiendaNubeIntegration,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
