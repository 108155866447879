export const GET = '/shipments';
export const GET_PURCHASE = '/shipments?status=PURCHASE';
export const GET_ORDER = '/orders';
export const GET_ALL = '/shipmentsPurchasesOrders';
export const GET_ALL_QUOTES = '/shipmentsQuotes';
export const APPEND_ALL = '/shipmentsPurchasesOrders?page=1&limit=10';
export const NEXT_STEP = 'NEXT_STEP';
export const PREV_STEP = 'PREV_STEP';
export const SET_CREATED = 'SET_CREATED';
export const CLEAR_CREATED = 'CLEAR_CREATED';
export const SET_FROM_ADDRESS = 'SET_FROM_ADDRESS';
export const SET_TO_ADDRESS = 'SET_TO_ADDRESS';
export const APPEND_FILTER = 'APPEND_FILTER';
export const DELETE_FILTER = 'DELETE_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SHOW_SHIPMENTS_MODAL = 'SHOW_SHIPMENTS_MODAL';