// @flow

import axios from 'axios';


export const saveCalendarProviders = (
    lu_i,
    lu_f,
    ma_i,
    ma_f,
    mi_i,
    mi_f,
    ju_i,
    ju_f,
    vi_i,
    vi_f,
    sa_i,
    sa_f,
    do_i,
    do_f,
    provider_id,
    service_id
) => axios
    .post('api/calendarProviders', {
        lu_i,
        lu_f,
        ma_i,
        ma_f,
        mi_i,
        mi_f,
        ju_i,
        ju_f,
        vi_i,
        vi_f,
        sa_i,
        sa_f,
        do_i,
        do_f,
        provider_id,
        service_id
    })
    .then(response => ({ type: 'Success', response }))
    .catch(err => ({ type: 'Error', response: err }));

export const updateAccount = (
    id,
    first_name,
    email,
    countries,
) => axios
    .put(`api/marketplaces/${id}`, {
        first_name,
        email,
        countries,
    })
    .then(response => (response))
    .catch(err => (err));

export const getCalendar = region => axios
    .get(`api/countries/${region}/marketplaces`)
    .then(response => ({ type: 'Success', response }))
    .catch(err => ({ type: 'Error', response: err }));
