import React from "react";
import { connect } from "react-redux";
import { Column, Row } from "hedron";
import { bindActionCreators } from "redux";
import axios from "axios";
import Text from "../../Text";
import EmptyServices from "./emptyServices";
import {
  createRates,
  setIcon,
  setValue,
  updateCostLoading,
} from "../../../modules/sidebarOrder/actions";
import Service from "./service";

import Dropdown from "../../Dropdown";
import { EmptyView } from "./loadingViews";
import { Img } from "../../Checkout/styled-components";
import questionIcon from "../../QuickQuote/img/question-icon.svg";

import "../style.css";
import Message from "../../Message";
class Services extends React.Component {
  state = {
    services: [],
    providers: [],
    serviceSelected: [],
    providerSelected: [],
    loadingCost: false,
    cost: "",
    haveInsurance: false,
    results: [],
  };

  componentDidMount(): void {
    const zipFrom = this.props.sidebar.fromAddress
      ? this.props.sidebar.fromAddress.zipcode
      : this.props.sidebar.data.from.zipcode;
    const countryFrom = "MX";
    const countryTo = this.props.sidebar.toAddress
      ? this.props.sidebar.toAddress.country == "USA"
        ? "US"
        : "MX"
      : this.props.sidebar.data.to.country == 0
      ? "MX"
      : "US";
    const zipTo = this.props.sidebar.toAddress
      ? this.props.sidebar.toAddress.zipcode
      : this.props.sidebar.data.to.zipcode;
    const len = this.props.sidebar.selectedPackage
      ? this.props.sidebar.selectedPackage.length
      : this.props.sidebar.data.package.dimensions.length;
    const wid = this.props.sidebar.selectedPackage
      ? this.props.sidebar.selectedPackage.width
      : this.props.sidebar.data.package.dimensions.width;
    const hei = this.props.sidebar.selectedPackage
      ? this.props.sidebar.selectedPackage.height
      : this.props.sidebar.data.package.dimensions.height;
    const weight =
      this.props.sidebar.data.package.weight <
      this.props.sidebar.data.package.volumetricWeight
        ? this.props.sidebar.data.package.volumetricWeight
        : this.props.sidebar.data.package.weight;
    const currentDimensions = {
      length: len,
      width: wid,
      height: hei,
    };
    if (
      !this._validOldInfo(
        zipFrom,
        zipTo,
        currentDimensions,
        this.props.sidebar.data.package.weight
      )
    ) {
      this.props.createRates(
        countryFrom,
        countryTo,
        zipFrom,
        zipTo,
        weight,
        len,
        wid,
        hei
      );
      this.props.setValue("package_cost", "");
      this.props.setValue("carrier_shipment", null);
      this.props.setIcon("alert");
    } else if (this.props.sidebar.data.carrier.shipment) {
      this.calculateInsuranceCost(
        this.props.sidebar.data.carrier.shipment.provider,
        this.props.sidebar.data.carrier.shipment.object_id
      );
    }
  }

  _validText = (value) => value.length > 0;

  _validNumber = (value) => !isNaN(value);

  _validOldInfo = (from, to, dimensions, weight) =>
    from == this.props.sidebar.oldOrigin &&
    to == this.props.sidebar.oldDestiny &&
    dimensions.length == this.props.sidebar.oldDimensions.length &&
    dimensions.width == this.props.sidebar.oldDimensions.width &&
    dimensions.height == this.props.sidebar.oldDimensions.height &&
    weight == this.props.sidebar.oldWeight;

  handleRadioChange = async (e) => {
    const selected = this.props.rates.results.filter(
      (service) => service.object_id == e.target.value
    )[0];
    const applyZoneAmount = selected.extended_zone_amount ? true : false;
    this.calculateInsuranceCost(
      selected.provider,
      selected.object_id,
      applyZoneAmount
    );
    await this.props.setValue("carrier_shipment", selected);
    if (this.props.sidebar.data.carrier.shipment)
      this.props.setIcon("completed");
  };

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
    if (nextProps.rates) {
      if (nextProps.rates.results) {
        const prov = [
          ...new Set(nextProps.rates.results.map((rate) => rate.provider)),
        ];
        const serv = [
          ...new Set(nextProps.rates.results.map((rate) => rate.servicelevel)),
        ];
        this.setState({
          providers: prov,
          services: serv,
        });
      }
    }
  }

  getProviders = () =>
    this.state.providers.map((val, index) => <Text key={index}>{val}</Text>);

  getServices = () =>
    this.state.services.map((val, index) => <Text key={index}>{val}</Text>);

  handleServiceChange = (serviceSelected) => {
    this.setState({
      serviceSelected,
    });
  };

  handleProviderChange = (providerSelected) => {
    this.setState({
      providerSelected,
    });
  };

  calculateInsuranceCost = (provider, rate_id, applyZoneAmount = false) => {
    rate_id = applyZoneAmount ? (rate_id ? `/${rate_id}` : null) : null;
    let cost;
    const insured_amount = this.props.sidebar.data.package.insuredAmount;
    this.props.setValue("package_cost", "");
    this.props.updateCostLoading(true);
    if (insured_amount == "") {
      cost = "";
      this.setState({ cost });
    } else {
      axios
        .get(`/api/shipments/insurances/${insured_amount}/${provider}`)
        .then((response) => {
          cost = response.data.insurance_cost;
          cost = parseFloat(cost).toFixed(2);
          this.props.setValue("package_cost", cost);
          this.props.updateCostLoading(false);
        })
        .catch((err) => {
          this.props.updateCostLoading(false);
        });
    }
  };

  sortByPrice = (a, b) => a.amount - b.amount;

  filterByService = (service) => {
    let result = false;
    const servicesList = this.state.services;
    this.state.serviceSelected.map((serviceId) => {
      if (servicesList[serviceId] == service.servicelevel) {
        result = true;
        return true;
      }
    });
    return result;
  };

  filterByProvider = (service) => {
    let result = false;
    const providersList = this.state.providers;
    this.state.providerSelected.map((providerId) => {
      if (providersList[providerId] == service.provider) {
        result = true;
        return result;
      }
    });
    return result;
  };

  _getShipmentsResults = () => {
    let { results } = this.props.rates;
    results =
      this.state.providerSelected.length > 0
        ? results.filter(this.filterByProvider.bind(this))
        : results;
    results =
      this.state.serviceSelected.length > 0
        ? results.filter(this.filterByService.bind(this))
        : results;
    return results.sort(this.sortByPrice);
  };

  _showValidateMsg = () => {
    if (this.props.sidebar.selectedPackage != null) {
      if (
        this._validText(this.props.sidebar.data.package.weight) &&
        this._validNumber(this.props.sidebar.data.package.weight)
      ) {
        return (
          this.props.sidebar.data.package.volumetricWeight >
          this.props.sidebar.data.package.weight
        );
      }
      false;
    } else {
      if (
        this._validText(this.props.sidebar.data.package.dimensions.length) &&
        this._validText(this.props.sidebar.data.package.dimensions.width) &&
        this._validText(this.props.sidebar.data.package.dimensions.height) &&
        this._validText(this.props.sidebar.data.package.weight) &&
        this._validNumber(this.props.sidebar.data.package.dimensions.length) &&
        this._validNumber(this.props.sidebar.data.package.dimensions.width) &&
        this._validNumber(this.props.sidebar.data.package.dimensions.height) &&
        this._validNumber(this.props.sidebar.data.package.weight)
      ) {
        return (
          this.props.sidebar.data.package.volumetricWeight >
          this.props.sidebar.data.package.weight
        );
      }
      false;
    }
  };

  render() {
    return this.props.sidebar.loading ? (
      <EmptyView />
    ) : (
      <div>
        {this.props.rates ? (
          <div>
            <Message color="warning" showClose={false}>
              Fecha de entrega aproximada y sujeta a cambios. Los tiempos de
              entrega dependen totalmente de la paquetería seleccionada.
            </Message>
            {this._showValidateMsg() && (
              <div className="helper-vol-text">
                {`Basaremos tu cotización en el peso volumétrico (${this.props.sidebar.data.package.volumetricWeight} kg)`}
                <a
                  className="question-icon"
                  href="https://blogger.mienvio.mx/2021/04/que-es-el-peso-real-y-el-peso-volumetrico/"
                  target="_blank"
                >
                  <Img src={questionIcon} />
                </a>
              </div>
            )}
            <div className="filter-wrapper">
              <Text type="microHeader" className="min-padding">
                Filtros
              </Text>
              <Row>
                <Column xs={5} className="drop-service" fluid>
                  <Dropdown
                    multiple
                    listClassName="filter-drop"
                    placeholder={
                      <Text className="dropdown-placeholder dp-service">
                        Servicio
                      </Text>
                    }
                    options={this.getServices()}
                    handleChange={this.handleServiceChange}
                    selected={this.state.serviceSelected}
                  />
                </Column>
                <Column xs={1} fluid />
                <Column xs={5} className="drop-service" fluid>
                  <Dropdown
                    multiple
                    listClassName="filter-drop"
                    placeholder={
                      <Text className="dropdown-placeholder dp-service">
                        Paqueteria
                      </Text>
                    }
                    options={this.getProviders()}
                    handleChange={this.handleProviderChange}
                    selected={this.state.providerSelected}
                  />
                </Column>
              </Row>
            </div>
            <Service
              rates={this._getShipmentsResults()}
              handleRadioChange={this.handleRadioChange}
              isChecked={(id) =>
                this.props.sidebar.data.carrier.shipment
                  ? id == this.props.sidebar.data.carrier.shipment.object_id
                  : false
              }
              insuranceCost={this.props.sidebar.data.package.cost}
              haveInsurance={this.props.sidebar.data.package.haveInsurance}
              handleInsurance={(val) => {
                this.props.setValue("package_haveInsurance", val);
              }}
            />
          </div>
        ) : (
          <EmptyServices />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.newShipment,
  rates: state.rates,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createRates,
      setIcon,
      setValue,
      updateCostLoading,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Services);
