import React from "react";
import ReactDOM from "react-dom";
import { boundMethod } from "autobind-decorator";
import axios from "axios";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import moment from "moment";
import styled from "styled-components";
import MapGL, { GeolocateControl, Layer, Marker } from "react-map-gl";

import Text, { Title } from "../../components/Text";
import Button from "../../components/Button";
import Card, { Divider } from "../../components/Card";
import Tracking, { Step } from "../../components/Tracking";
import colors from "../../utils/colors";
import { getUser } from "../../modules/user";

import logo from "./logo.svg";
import poweredBy from "../../utils/icons/powered.svg";
import shipment from "../../components/ShipmentDetail/shipment.svg";

import example_events from "./exampleEvents.json";
import house from "./house.svg";
import warehouse from "./warehouse.svg";
import delivery from "./delivery.svg";
import arrow from "./down-arrow.svg";

import "./style.css";
import Badge from "../../components/Badge/insuranceBadge"

const geolocateStyle = {
  float: "left",
  margin: "50px",
  padding: "10px",
};
const style = {
  color: "#fff",
  cursor: "pointer",
  background: "transparent",
  borderRadius: "6px",
  width: "30px",
  height: "30px",
};
const I = styled.i`
  cursor: pointer;
  width: 10px;
  height: 5px;
`;

const Collapsible = styled.div`
  cursor: pointer;
  border-left: 4px solid ${colors.gray.creamy_gray};
  padding-left: 37px;
`;

const Img = styled.img`
  margin: 42px 0px 32px 0px;
`;
class PublicTracking extends React.Component {
  state = {
    shipment: null,
    // address_to: null,
    tracking: null,
    trackingState: null,
    hasBeenPicked: false,
    showDetail: false,
    storefront_preview: false,
    loading: true,
    map: false,
    viewport: {
      width: "100vw",
      height: "100vh",
      latitude: 20.621711,
      longitude: -100.420083,
      zoom: 13,
    },
    latitude: 20.617625,
    longitude: -100.486645,
    latitudeWarehouse: 20.611243,
    longitudeWarehouse: -100.37894,
    latitudeDelivery: 20.621711,
    longitudeDelivery: -100.420083,
    status: null,
    close: true,
    notBegin: false,
  };

  componentDidMount() {
    const { trackingNumber, carrier } = this.props.match.params;
    if (trackingNumber && carrier) {
      if (carrier === "99 Minutos") {
        this.setState({
          loading: true,
          showError: false,
        });
        this.getTracking(trackingNumber, carrier);
      } else {
        this.getTracking(trackingNumber, carrier);
      }
    } else if (window.location.href.indexOf("/tracking/storefront") > -1) {
      this.setState({
        ...this.state,
        storefront_preview: true,
        tracking: example_events,
        storefront: this.props.user.default_storefront,
      });
    } else {
      this.props.goToAutomatizations();
    }
  }

  handleShowTrackingDetail = () => {
    this.setState({ showDetail: !this.state.showDetail });
  };

  getTracking = (tracking_number, carrier) => {
    // this.props.showLoader('basic');
    axios
      .get(`/api/tracking-histories/${tracking_number}/${carrier}`)
      .then((response) => {
        if (carrier === "99 Minutos") {
          if (response.data.message[0]) {
            if (
              parseFloat(response.data.message[0].driver.driver_lat) === 0 &&
              parseFloat(response.data.message[0].driver.driver_long) === 0
            ) {
              this.setState({
                latitude: parseFloat(
                  response.data.message[0].delivery.destination_lat
                ),
                longitude: parseFloat(
                  response.data.message[0].delivery.destination_long
                ),
                latitudeWarehouse: parseFloat(
                  response.data.message[0].pickup.sender_lat
                ),
                longitudeWarehouse: parseFloat(
                  response.data.message[0].pickup.sender_long
                ),
                latitudeDelivery: parseFloat(
                  response.data.message[0].driver.driver_lat
                ),
                longitudeDelivery: parseFloat(
                  response.data.message[0].driver.driver_long
                ),
                viewport: {
                  width: "100vw",
                  height: "100vh",
                  latitude: parseFloat(
                    response.data.message[0].pickup.sender_lat
                  ),
                  longitude: parseFloat(
                    response.data.message[0].pickup.sender_long
                  ),
                  zoom: 12,
                },
                map: true,
                shipment: response.data.shipment,
                // address_to: response.data.address_to,
                storefront: response.data.storefront,
                tracking: response.data.message,
                loading: false,
                notBegin: true,
                status:
                  response.data.message[0].logs[
                    response.data.message[0].logs.length - 1
                  ],
              });
            } else {
              this.setState(
                {
                  latitude: parseFloat(
                    response.data.message[0].delivery.destination_lat
                  ),
                  longitude: parseFloat(
                    response.data.message[0].delivery.destination_long
                  ),
                  latitudeWarehouse: parseFloat(
                    response.data.message[0].pickup.sender_lat
                  ),
                  longitudeWarehouse: parseFloat(
                    response.data.message[0].pickup.sender_long
                  ),
                  latitudeDelivery: parseFloat(
                    response.data.message[0].driver.driver_lat
                  ),
                  longitudeDelivery: parseFloat(
                    response.data.message[0].driver.driver_long
                  ),
                  viewport: {
                    width: "100vw",
                    height: "100vh",
                    latitude: parseFloat(
                      response.data.message[0].driver.driver_lat
                    ),
                    longitude: parseFloat(
                      response.data.message[0].driver.driver_long
                    ),
                    zoom: 12,
                  },
                  map: true,
                  shipment: response.data.shipment,
                  // address_to: response.data.address_to,
                  storefront: response.data.storefront,
                  tracking: response.data.message,
                  loading: false,
                  status:
                    response.data.message[0].logs[
                      response.data.message[0].logs.length - 1
                    ],
                },
                () => this.startProcess(tracking_number, carrier)
              );
            }
          }
        } else {
          let trackingState = "";
          // this.props.hideLoader();
          response.data.events.some((event) => {
            if (event.code !== "incident") {
              trackingState = event.code;
              return true;
            }
            return false;
          });
          this.setState({
            loading: false,
            shipment: response.data.shipment,
            // address_to: response.data.address_to,
            tracking: response.data.events,
            storefront: response.data.storefront,
            trackingState,
          });
        }
      })
      .catch((error) => {
        this.setState({ showError: true, loading: false });
      });
  };

  startProcess(tracking_number, carrier) {
    setTimeout(() => this.update99min(tracking_number, carrier), 15000);
  }

  @boundMethod
  update99min(tracking_number, carrier) {
    axios
      .get(`/api/tracking/${tracking_number}/${carrier}`)
      .then((response) => {
        if (carrier === "99 Minutos") {
          if (response.data.message[0]) {
            this.setState(
              {
                latitudeDelivery: parseFloat(
                  response.data.message[0].driver.driver_lat
                ),
                longitudeDelivery: parseFloat(
                  response.data.message[0].driver.driver_long
                ),
                viewport: {
                  width: "100vw",
                  height: "100vh",
                  latitude: parseFloat(
                    response.data.message[0].driver.driver_lat
                  ),
                  longitude: parseFloat(
                    response.data.message[0].driver.driver_long
                  ),
                  zoom: 12,
                },
                status:
                  response.data.message[0].logs[
                    response.data.message[0].logs.length - 1
                  ],
              },
              () =>
                setTimeout(
                  () => this.update99min(tracking_number, carrier),
                  15000
                )
            );
          }
        } else {
          let trackingState = "";
          // this.props.hideLoader();
          response.data.events.some((event) => {
            if (event.code !== "incident") {
              trackingState = event.code;
              return true;
            }
            return false;
          });
          this.setState({
            loading: false,
            shipment: response.data.shipment,
            // address_to: response.data.address_to,
            tracking: response.data.events,
            storefront: response.data.storefront,
            trackingState,
          });
        }
      })
      .catch((error) => {
        this.setState({ showError: true, loading: false });
      });
  }

  trackingList = () =>
    this.state.tracking.map((event, index) => {
      // console.log("EVENT", event);

      const date = `${moment(event.date_time)
        .format("D-MMM")
        .toUpperCase()
        .replace(".", "")}, ${moment(event.date_time).format("hh:mma")}`;

      const createdByWebhook = event.from_webhook? 'Webhook' : 'API';
      return (
        <Step title={`${event.status} - ${date}`} done key={index}>
          <Text>{event.sub_status}</Text> <Badge type="correct">{createdByWebhook}</Badge>
        </Step>
      );

      // if (event.code === "picked_up") {
      //   if (!this.state.hasBeenPicked) {
      //     this.setState({ hasBeenPicked: true });
      //   }
      //   return (
      //     <div key={index}>
      //       <Step
      //         title={`Recolectado/Escaneado - ${date}`}
      //         done
      //         style={{ paddingBottom: "12px" }}
      //       >
      //         <Text style={{ marginBottom: "0px" }}>{event.description}</Text>
      //       </Step>
      //       {this.updates()}
      //     </div>
      //   );
      // }

      // if (
      //   (event.code === "incident" || event.code === "INCIDENCIA") &&
      //   this.state.showDetail
      // ) {
      //   return (
      //     <div key={index}>
      //       <Step title={`Incidencia - ${date}`} incident key={index}>
      //         <Text>{event.exception_description}</Text>
      //       </Step>
      //       {this.state.hasBeenPicked ? null : this.updates()}
      //     </div>
      //   );
      // }

      // if (event.code === "picked_up") {
      //   if (!this.state.hasBeenPicked) {
      //     this.setState({ hasBeenPicked: true });
      //   }
      //   return (
      //     <div key={index}>
      //       <Step
      //         title={`Recolectado/Escaneado - ${date}`}
      //         done
      //         style={{ paddingBottom: "12px" }}
      //       >
      //         <Text style={{ marginBottom: "0px" }}>{event.description}</Text>
      //       </Step>
      //       {this.updates()}
      //     </div>
      //   );
      // }
      // if (event.code === "incident" && this.state.showDetail) {
      //   return (
      //     <Step title={`Incidencia - ${date}`} incident key={index}>
      //       <Text>{event.exception_description}</Text>
      //     </Step>
      //   );
      // }
      // if (event.status === "En ruta") {
      //   return (
      //     <Step title={`En ruta - ${date}`} done last key={index}>
      //       <Text>{event.sub_status}</Text>
      //     </Step>
      //   );
      // }

      // if (event.status === "Entregado") {
      //   return (
      //     <Step title={`Entregado - ${date}`} done last key={index}>
      //       <Text>{event.sub_status}</Text>
      //     </Step>
      //   );
      // }
      // if (event.status == 'Proximo a Entregar') {
      //   return (
      //     <Step title={`Proximo a Entregar - ${date}`} done key={index}>
      //       <Text>{event.sub_status}</Text>
      //     </Step>
      //   );
      // }
    });

  extraTrackingList = () => {
    const carrier = this.props.match.params.carrier.toLowerCase();
    const withURLS = ["estafeta", "dhl", "redpack", "fedex"];
    const url = withURLS.includes(carrier)
      ? `https://blogger.mienvio.mx/2021/04/como-se-recolecta-mi-envio-con-${carrier}`
      : "https://blogger.mienvio.mx/centro-ayuda/recoleccion-mienvio/";
    const items = [
      <div>
        <Step
          title="Recolectado/Escaneado"
          key="recolectado"
          style={{ paddingBottom: "12px" }}
        >
          <div
            style={{ display: "inline-block", cursor: "pointer" }}
            onClick={() => window.open(url, "blank")}
          >
            <Text type="bodyDetail" style={{ color: colors.secondary.cobalt }}>
              {" "}
              Agendar recolección
            </Text>
          </div>
          <Text type="bodyDetail" style={{ display: "inline-block" }}>
            {" "}
            &nbsp;o llevar a sucursal
          </Text>
        </Step>
        {this.updates()}
      </div>,
      this.state.showDetail ? (
        <Step title="En camino" key="camino">
          <Text>Aún no hay información</Text>
        </Step>
      ) : null,
      <Step title="Entregado" key="entregado" last>
        <Text>Aún no hay información</Text>
      </Step>,
    ];
    if (this.state.tracking.length === 1 && carrier !== "dhl") {
      if (!this.state.hasBeenPicked) {
        this.setState({ hasBeenPicked: true });
      }
      return items.slice(1);
    }
    if (this.state.tracking.length === 1) {
      if (!this.state.hasBeenPicked) {
        this.setState({ hasBeenPicked: true });
      }
      return items;
    }
  };

  updates = () =>
    this.state.showDetail ? (
      <Collapsible
        style={{ paddingBottom: "24px" }}
        onClick={() => this.handleShowTrackingDetail()}
      >
        <Text type="labelEdit">
          Esconder las actualizaciones{" "}
          <I className="ion-android-arrow-dropup" />
        </Text>
      </Collapsible>
    ) : (
      <div>
        <Collapsible
          style={{
            borderLeft: `4px dotted ${colors.gray.creamy_gray}`,
            paddingBottom: "8px",
          }}
          onClick={() => this.handleShowTrackingDetail()}
        >
          <Text type="labelEdit">
            Ver todas las actualizaciones{" "}
            <I className="ion-android-arrow-dropdown" />
          </Text>
        </Collapsible>
        <div
          style={{
            borderLeft: `4px solid ${colors.gray.creamy_gray}`,
            height: "16px",
          }}
        />
      </div>
    );

  formatPhone = (phone) => {
    phone = Number(phone);
    if (!phone || phone === "null" || isNaN(phone)) {
      return "";
    }

    const mask = "(XXX) XXX XX XX";
    const s = `${phone}`;

    let r = "";
    for (let i = 0, is = 0; i < mask.length && is < s.length; i++) {
      r += mask.charAt(i) === "X" ? s.charAt(is++) : mask.charAt(i);
    }
    return r;
  };

  _onViewportChange = (viewport) =>
    this.setState({ ...viewport, transitionDuration: 3000 });

  onDragEnd = (lngLat) => {
    setState({ longitude: lngLat.lng, latitude: lngLat.lat });
  };

  render = () => {
    if (!this.state.tracking && !this.state.storefront) {
      if (this.state.loading) {
        return (
          <div className="sorry-container">
            <p className="sorry-txt">Cargando...</p>
          </div>
        );
      }
      return (
        <div className="sorry-container">
          <p className="sorry-txt">
            Tu guía aún no tiene información de rastreo
          </p>
        </div>
      );
    }
    const has_custom_logo = this.state.storefront
      ? !!this.state.storefront.logo_url
      : false;
    return this.state.showError ? (
      <div className="sorry-container">
        <p className="sorry-txt">
          Lo sentimos la pagina que buscas no esta disponible
        </p>
      </div>
    ) : this.state.map ? (
      <div>
        <div className="container-logo-tracking">
          <Img
            style={{ marginBottom: "0", marginTop: "0", width: "120px" }}
            src={
              has_custom_logo
                ? this.state.storefront.logo_url
                : "https://www.mienvio.mx/img/mienvio-dark.svg"
            }
          />
          {has_custom_logo && (
            <Img
              className="pt-logo"
              style={{ marginTop: "6px", marginBottom: "2px" }}
              src={poweredBy}
            />
          )}
        </div>
        <MapGL
          {...this.state.viewport}
          mapStyle="mapbox://styles/mienviomx/ck991nfvz0e9f1jmo5jg5c119"
          mapboxApiAccessToken="pk.eyJ1IjoibWllbnZpb214IiwiYSI6ImNrOThuaTNqcDAwZmYza3Jra24wMHpsdDMifQ.rzE_hUr-hoLm9xn8Ke4Kow"
          onViewportChange={(viewport) => this.setState({ viewport })}
        >
          <GeolocateControl
            style={geolocateStyle}
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation
          />
          <Marker
            longitude={this.state.longitude}
            latitude={this.state.latitude}
          >
            <img src={house} style={style} />
          </Marker>
          <Marker
            longitude={this.state.longitudeWarehouse}
            latitude={this.state.latitudeWarehouse}
          >
            <img src={warehouse} style={style} />
          </Marker>
          {!this.state.notBegin && (
            <Marker
              longitude={this.state.longitudeDelivery}
              latitude={this.state.latitudeDelivery}
            >
              <img src={delivery} style={style} />
            </Marker>
          )}
        </MapGL>
        <div
          className={`container-description-tracking ${
            this.state.close && "close"
          }`}
        >
          <div className="container-logo-tracking estatus">
            Estatus del Envío
          </div>
          <img
            onClick={() => this.setState({ close: !this.state.close })}
            src={arrow}
            alt=""
            className={`${this.state.close && "close"}`}
          />
          <Row className="full-width">
            <Column xs={12} md={6}>
              <Text type="helpTextModal" style={{ fontWeight: "800" }}>
                ID del envío
              </Text>
              <Title
                type="titleCard"
                style={{
                  color: "#4a4a4a",
                  fontWeight: "300",
                  letterSpacing: 0.9,
                }}
              >
                {/* <img
                  src="https://www.mienvio.mx/img/favicon.png"
                  style={{
                    marginTop: "5px",
                    height: "24px",
                    marginRight: "11px",
                    float: "left",
                  }}
                /> */}
                #
                {this.state.storefront_preview
                  ? "000000"
                  : this.state.shipment && this.state.shipment.object_id}
              </Title>
              {/* <Text type="label" style={{ marginBottom: "20px" }}>
                Tu paquete será enviado a
              </Text>
              <br />
              <Row>
                <Column fluid>
                  <Text className="detail-text">
                    {!this.state.storefront_preview
                      ? this.state.address_to && this.state.address_to.name
                      : "Nombre del destinatario"}
                  </Text>
                </Column>
                <Column fluid>
                  <Text className="detail-text">
                    {!this.state.storefront_preview
                      ? this.state.address_to && this.state.address_to.email
                      : "correo@destinatario.mx"}
                  </Text>
                </Column>
                <Column fluid>
                  <Text className="detail-text">
                    {!this.state.storefront_preview
                      ? this.state.address_to && this.state.address_to.phone
                      : "1234567890"}
                  </Text>
                </Column>
                <Column fluid>
                  <Text className="detail-text">
                    {!this.state.storefront_preview
                      ? this.state.address_to && this.state.address_to.street
                      : "Calle y número de Destino"}
                  </Text>
                </Column>
                <Column fluid>
                  <Text className="detail-text">
                    {this.state.storefront_preview
                      ? "Referencia del destino"
                      : this.state.address_to && this.state.address_to.reference
                      ? this.state.address_to.reference
                      : ""}
                  </Text>
                </Column>
                <Column fluid>
                  <Text className="detail-text">
                    Col.{" "}
                    {!this.state.storefront_preview
                      ? this.state.address_to && this.state.address_to.street2
                      : "Colonia de destino"}
                  </Text>
                </Column>
                <Column fluid>
                  <Text className="detail-text">
                    C.P.{" "}
                    {!this.state.storefront_preview
                      ? this.state.address_to && this.state.address_to.zipcode
                      : "12345"}
                  </Text>
                </Column>
              </Row> */}
            </Column>
            <Column xs={12} md={6}>
              {this.state.status && (
                <div>
                  <Text type="label" style={{ marginBottom: "20px" }}>
                    Estatus
                  </Text>
                  <Row>
                    <Column fluid>
                      <Text
                        className="detail-text"
                        style={{
                          fontSize: "14px",
                          color: "#FF288C",
                          fontWeight: "bold",
                        }}
                      >
                        {this.state.status.date} - {this.state.status.time}
                      </Text>
                      <br />
                      <Text
                        className="detail-text"
                        style={{ fontSize: "15px", color: "#101646" }}
                      >
                        {this.state.notBegin
                          ? "Hemos recibido tu orden de envío"
                          : this.state.status.comment}
                      </Text>
                    </Column>
                  </Row>
                </div>
              )}
              <Text type="label" style={{ marginBottom: "20px" }}>
                Información del Envío
              </Text>
              <Row>
                <Column fluid>
                  <Text className="detail-text">Medidas:</Text>
                </Column>
                <Column fluid>
                  <Text className="detail-text">
                    {`${
                      this.state.shipment && this.state.shipment.height
                    }cm x ${
                      this.state.shipment && this.state.shipment.length
                    }cm x ${
                      this.state.shipment && this.state.shipment.width
                    }cm`}
                  </Text>
                </Column>
                <Column fluid>
                  <Row>
                    <Column xs={6} fluid>
                      <Row>
                        <Column fluid>
                          <Text className="detail-text">Peso:</Text>
                        </Column>
                        <Column fluid>
                          <Text className="detail-text">
                            {`${
                              this.state.shipment && this.state.shipment.weight
                            }kg`}
                          </Text>
                        </Column>
                      </Row>
                    </Column>
                    <Column xs={6} fluid>
                      <Row>
                        <Column fluid>
                          <Text className="detail-text">Descripción:</Text>
                        </Column>
                        <Column fluid>
                          <Text className="detail-text">
                            {`${
                              this.state.shipment &&
                              this.state.shipment.description
                            }`}
                          </Text>
                        </Column>
                      </Row>
                    </Column>
                  </Row>
                </Column>
                <Column fluid>
                  {!this.state.storefront ? (
                    <Text type="labelAuxiliar" style={{ marginTop: "15px" }}>
                      ¿Algún error en tu dirección? Comunícate con el vendedor
                      directamente
                    </Text>
                  ) : !this.state.storefront.email ? (
                    <Text type="labelAuxiliar" style={{ marginTop: "15px" }}>
                      ¿Algún error en tu dirección? Comunícate con el vendedor
                      directamente
                    </Text>
                  ) : (
                    <Text type="labelAuxiliar" style={{ marginTop: "15px" }}>
                      ¿Hay un error en tu dirección? Envianos un correo a{" "}
                      {
                        <a
                          style={{ color: "#2576DA" }}
                          href={`mailto:${this.state.storefront.email}`}
                        >
                          {this.state.storefront.email}
                        </a>
                      }
                    </Text>
                  )}
                </Column>
              </Row>
            </Column>
          </Row>
        </div>
      </div>
    ) : (
      <Row divisions={24} className="public-tracking">
        <Column xs={1} md={6} fluid />
        <Column xs={11} md={6} fluid>
          <Img
            className="pt-logo"
            style={{ marginBottom: "0" }}
            src={has_custom_logo ? this.state.storefront.logo_url : logo}
          />
          {has_custom_logo && (
            <Img
              className="pt-logo"
              style={{ marginTop: "4px" }}
              src={poweredBy}
            />
          )}
        </Column>
        <Column
          xs={11}
          md={6}
          fluid
          style={{ margin: "42px 0px 32px 0px", textAlign: "right" }}
        >
          {!this.props.user && !this.state.storefront ? (
            <Button
              type="link"
              style={{ marginRight: 0 }}
              onClick={() => (window.location = "/login")}
            >
              Ingresa a tu cuenta
            </Button>
          ) : this.state.storefront ? (
            <Button
              type="link"
              style={{ marginRight: 0 }}
              onClick={() => this.props.goToAutomatizations()}
            >
              Volver a "Automatizaciones"
            </Button>
          ) : (
            <Button
              type="link"
              style={{ marginRight: 0 }}
              onClick={() => (window.location = "/")}
            >
              Ir a tu cuenta
            </Button>
          )}
        </Column>
        <Column xs={1} md={6} fluid />
        <Column xs={1} md={6} fluid />
        <Column xs={22} md={12} fluid>
          <Card size="full">
            <Text type="helpTextModal" style={{ fontWeight: "800" }}>
              ID del envío
            </Text>
            <Title
              type="titleCard"
              style={{
                color: "#4a4a4a",
                fontWeight: "300",
                letterSpacing: 0.9,
              }}
            >
              {/* <img
                src="https://www.mienvio.mx/img/favicon.png"
                style={{
                  marginTop: "5px",
                  height: "24px",
                  marginRight: "11px",
                  float: "left",
                }}
              /> */}
              #
              {this.state.storefront_preview
                ? "000000"
                : this.state.shipment.object_id}
            </Title>
            <Divider className="divider-detail" />
            <Text type="label" style={{ marginBottom: "20px" }}>
              Estatus de tu paquete{" "}
            </Text>
            <Tracking style={{ margin: "10px", marginLeft: "15px" }}>
              {this.trackingList()}
              {/* {this.trackingState !== "delivered" &&
                !this.state.storefront_preview &&
                this.extraTrackingList()} */}
            </Tracking>
            <Divider className="divider-detail" />
            {/* <Text type="label" style={{ marginBottom: "20px" }}>
              Tu paquete será enviado a
            </Text>
            <Column fluid>
              <Text className="detail-text">
                {!this.state.storefront_preview
                  ? this.state.address_to.name
                  : "Nombre del destinatario"}
              </Text>
            </Column>
            <Column fluid>
              <Text className="detail-text">
                {!this.state.storefront_preview
                  ? this.state.address_to.email
                  : "correo@destinatario.mx"}
              </Text>
            </Column>
            <Column fluid>
              <Text className="detail-text">
                {!this.state.storefront_preview
                  ? this.state.address_to.phone
                  : "1234567890"}
              </Text>
            </Column>
            <Column fluid>
              <Text className="detail-text">
                {!this.state.storefront_preview
                  ? this.state.address_to.street
                  : "Calle y número de Destino"}
              </Text>
            </Column>
            <Column fluid>
              <Text className="detail-text">
                {this.state.storefront_preview
                  ? "Referencia del destino"
                  : this.state.address_to.reference
                  ? this.state.address_to.reference
                  : ""}
              </Text>
            </Column>
            <Column fluid>
              <Text className="detail-text">
                Col.{" "}
                {!this.state.storefront_preview
                  ? this.state.address_to.street2
                  : "Colonia de destino"}
              </Text>
            </Column>
            <Column fluid>
              <Text className="detail-text">
                C.P.{" "}
                {!this.state.storefront_preview
                  ? this.state.address_to.zipcode
                  : "12345"}
              </Text>
            </Column> */}

            {!this.state.storefront ? (
              <Text type="labelAuxiliar" style={{ marginTop: "15px" }}>
                ¿Algún error en el proceso? Comunícate con el vendedor
                directamente
              </Text>
            ) : !this.state.storefront.email ? (
              <Text type="labelAuxiliar" style={{ marginTop: "15px" }}>
                ¿Algún error en el proceso? Comunícate con el vendedor
                directamente
              </Text>
            ) : (
              <Text type="labelAuxiliar" style={{ marginTop: "15px" }}>
                ¿Hay un error en el proceso? Envianos un correo a{" "}
                {
                  <a
                    style={{ color: "#2576DA" }}
                    href={`mailto:${this.state.storefront.email}`}
                  >
                    {this.state.storefront.email}
                  </a>
                }
              </Text>
            )}
          </Card>
        </Column>
      </Row>
    );
  };
}

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser,
      goToAutomatizations: () => push("/perfil/ajustes-envios"),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PublicTracking);
