import axios from 'axios';

export const getShipments = id => axios
  .get(`api/shipmentsPurchases/marketplace/${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));

  export const getShipmentsFilter = (id, filter) => axios
  .get(`api/shipmentsPurchases/marketplace/${id}${filter}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));


export const getShipmentsDetails = id => axios
  .get(`api/shipments/${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));

