import React from 'react';
import Card from '../../../components/Card';
import ThreeDotsDropdown from '../../../components/Dropdown/ThreeDotsDropdown/index';

import edit_icon from '../../../utils/icons/edit.svg';
import delete_icon from '../../../utils/icons/delete.svg';
import downArrow from '../../../utils/icons/downArrow.svg';
import Region from './region';
import Permission from './permission';
import { searchPermission } from '../../../utils/global_functions';

import './style.css';


const renderRegionsName = data => (data.map(region => (<Region name={region.name} />)));
const Zone = ({
  onClick, data, toggleEditZone, toggleDeleteZone, permissions, user
}) => {
  const {
    customer_type, plan, actions, role, operational_user
  } = user;

  const permisos = [];
  if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
    operational_user.operational_role.actions.map(element => permisos.push(element));
  }

  if (plan && plan.features) {
    plan.features.map(element => permisos.push(element));
  }
  if (actions) {
    actions.map(element => permisos.push(element));
  }
  const renderPermissions = (actions) => {
    if (permissions.length === actions.length) {
      return (<Permission name="Todos los Permisos" />);
    }
    return (actions.map(action => (<Permission name={`${action.name} - ${action.value}`} />)));
  };
  let options = [];

  if(searchPermission('gerentes', 'editar', permisos)){
   options = [
    {
      function: () => toggleEditZone(data.id),
      label: 'Editar gerente',
      image: edit_icon,
    },
    {
      function: () => toggleDeleteZone(data.id),
      label: 'Eliminar gerente',
      image: delete_icon,
    },
  ];
}
  return (
    <Card size="full" className="zone-card-container">
      <div className="options">
        <ThreeDotsDropdown
          linksToRender={options}
        />
      </div>
      <div className="separator">
        <p className="title-zone">Nombre</p>
        <p>
          {data.first_name}
          {' '}
          {data.last_name}
        </p>
        <p className="title-zone">Correo</p>
        <p>{data.email}</p>
      </div>
      <div className="separator">
        <p className="title-zone">Regiones</p>
        {renderRegionsName(data.regions)}
      </div>
      <div className="separator-big">
        <p className="title-zone">Permisos</p>
        {renderPermissions(data.actions)}
      </div>
    </Card>
  );
};

export default Zone;
