// @flow

import axios from "axios";

export const createAccount = (
  region_id,
  first_name,
  last_name,
  email,
  phone,
  password,
  password_confirmation
) =>
  axios
    .post("api/marketplaces", {
      region_id,
      first_name,
      last_name,
      email,
      phone,
      password,
      password_confirmation,
    })
    .then((response) => ({ type: "Success", response }))
    .catch((err) => ({ type: "Error", response: err }));

export const updateAccount = (id, first_name, email, countries) =>
  axios
    .put(`api/marketplaces/${id}`, {
      first_name,
      email,
      countries,
    })
    .then((response) => response)
    .catch((err) => err);

export const getAccounts = (region) =>
  axios
    .get(`api/countries/${region}/marketplaces`)
    .then((response) => ({ type: "Success", response }))
    .catch((err) => ({ type: "Error", response: err }));

export const getMasterCountries = () =>
  axios
    .get("api/master/countries")
    .then((response) => ({ type: "Success", response }))
    .catch((err) => ({ type: "Error", response: err }));

export const getManagerCountries = (id) =>
  axios
    .get(`api/manager/${id}/countries`)
    .then((response) => ({ type: "Success", response }))
    .catch((err) => ({ type: "Error", response: err }));

export const getSingleAccount = (region, id) =>
  axios
    .get(`api/regional/${region}/marketplaces/${id}`)
    .then((response) => ({ type: "Success", response }))
    .catch((err) => ({ type: "Error", response: err }));
