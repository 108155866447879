// @flow
import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { boundMethod } from "autobind-decorator";
import { Row, Column } from "hedron";
import "./index.css";

import Menu from "../../components/Menu";
import Tabs, { Tab } from "../../components/Tabs";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Text, { Title, P } from "../../components/Text";

import MarketplaceAccount from "../MarketplaceAccount";
import Multiprocess from "../Multiprocess";

import Summary from "../../components/Summary";
import Regions from "../regions";
import Zones from "../zonesManager";
import RolesList from "../../components/RolesList";
import SubAccountsList from "../../components/SubAccountsList";
import Config from "../masterConfig";
import MasterConfigCalendar from "../masterConfigCalendar";
import {
  existInJsonArray,
  searchPermission,
} from "../../utils/global_functions";

const NEW_ZONE = "NEW_ZONE";
const NEW_REGION = "NEW_REGION";
const EDIT_REGION = "EDIT_REGION";
const NEW_ACCOUNT = "NEW_ACCOUNT";
const NEW_SUBACCOUNT = "NEW_SUBACCOUNT";
const NEW_ROL = "NEW_ROL";
const SHOW_SELECTED_QUOTES = "SHOW_SELECTED_QUOTES"

class AccountsAdmin extends React.Component<Props, State> {
  state = {
    currentTab: 0,
    totalSubAccounts: 0,
  };

  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;
    this.props.toggleSideBar(false);

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    let regiones = false;
    let gerentes = false;
    let cuentas = false;
    let multiprocess = false;
    let configuracion = false;
    let roles = false;
    let subcuentas = false;
    let wallet = false;

    if (searchPermission("regiones", "leer", permissions)) {
      regiones = true;
    }
    if (searchPermission("gerentes", "leer", permissions)) {
      gerentes = true;
    }
    if (searchPermission("cuentas", "leer", permissions)) {
      cuentas = true;
    }
    if (searchPermission("multiprocess", "leer", permissions)) {

      multiprocess = true;
    }
    if ((plan.name === "enterprise" || plan.name === "operativo") && role === "master") {
      configuracion = true;
    }
    if (
      plan.name !== "enterprise" &&
      searchPermission("roles", "leer", permissions)
    ) {
      roles = true;
    }
    if (
      plan.name !== "enterprise" &&
      searchPermission("subcuentas", "leer", permissions)
    ) {
      subcuentas = true;
    }

    if (plan.name === "enterprise" || plan.name === "operativo") {
      wallet = true;
    }

    if (gerentes && !regiones) {
      if (this.state.currentTab === 0) {
        this.setState({ currentTab: 1 });
      }
    }

    if (cuentas && !gerentes && !regiones) {
      if (this.state.currentTab === 0) {
        this.setState({ currentTab: 2 });
      }
    }

    if (configuracion && !cuentas && !gerentes && !regiones) {
      if (this.state.currentTab === 0) {
        this.setState({ currentTab: 3 });
      }
    }

    if (roles && !configuracion && !cuentas && !gerentes && !regiones) {
      if (this.state.currentTab === 0) {
        this.setState({ currentTab: 4 });
      }
    }

    if (
      subcuentas &&
      !roles &&
      !configuracion &&
      !cuentas &&
      !gerentes &&
      !regiones
    ) {
      if (this.state.currentTab === 0) {
        this.setState({ currentTab: 5 });
      }
    }

    if (
      wallet &&
      !subcuentas &&
      !roles &&
      !configuracion &&
      !cuentas &&
      !gerentes &&
      !regiones
    ) {
      if (this.state.currentTab === 0) {
        this.setState({ currentTab: 6 });
      }
    }

    // if (roles && subcuentas && !regiones && !gerentes && !cuentas) {
    //this.setState({ currentTab: 3 });
    // }
    //if (role === 'manager') {
    //  this.setState({ currentTab: 2 });
    //}
  }

  @boundMethod
  totalSubAccounts(totalSubAccounts) {
    this.setState({ totalSubAccounts });
  }

  renderTab(index) {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    switch (index) {
      case 0:
        if (searchPermission("regiones", "leer", permissions)) {
          return (
            <div>
              <Regions />
            </div>
          );
        }
        break;
      case 1:
        if (searchPermission("gerentes", "leer", permissions)) {
          return (
            <div>
              <Zones />
            </div>
          );
        }
        break;
      case 2:
        if (searchPermission("cuentas", "leer", permissions)) {
          return (
            <div>
              <MarketplaceAccount />
            </div>
          );
        }
        break;
      case 3:
        if ((plan.name === "enterprise" || plan.name === "operativo") && role === "master") {
          return (
            <div>
              <Config />
            </div>
          );
        }
        break;
      case 4:
        if (searchPermission("roles", "leer", permissions)) {
          return (
            <div>
              <RolesList />
            </div>
          );
        }

        break;
      case 5:
        if (searchPermission("subcuentas", "leer", permissions)) {
          return (
            <div>
              <SubAccountsList totalSubAccounts={this.totalSubAccounts} />
            </div>
          );
        }
        break;
      case 6:
        return (
          <div>
            <Summary />
          </div>
        );
      case 7:
        return (
          <div>
            <MasterConfigCalendar />
          </div>
        );
      case 8:
        if (searchPermission("multiprocess", "leer", permissions)) {
          return (
            <div>
              <Multiprocess />
            </div>
          );
        }
        break;
      default:
        return <div />;
    }
  }

  renderRightButton(index) {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    switch (index) {
      case 0:
        if (searchPermission("regiones", "editar", permissions)) {
          return (
            <Button
              className="header-btn last"
              onClick={() => {
                this.props.toggleSideBar(true, "Añadir Región", NEW_REGION);
              }}
            >
              Añadir Region
            </Button>
          );
        }
        break;
      case 1:
        if (searchPermission("gerentes", "editar", permissions)) {
          return (
            <Button
              className="header-btn last"
              onClick={() => {
                this.props.toggleSideBar(
                  true,
                  "Añadir Gerente de Zona",
                  NEW_ZONE
                );
              }}
            >
              Añadir Gerente de Zona
            </Button>
          );
        }
        break;
      case 2:
        if (searchPermission("cuentas", "editar", permissions)) {
          return (
            <Button
              className="header-btn last"
              onClick={() => {
                this.props.toggleSideBar(true, "Añadir Cuenta", NEW_ACCOUNT);
              }}
            >
              Añadir Cuenta
            </Button>
          );
        }
        break;
      case 3:
        if ((plan.name === "enterprise" || plan.name === "operativo") && role === "master") {
          return (
            <Button
              className="header-btn last"
              onClick={() => {
                this.props.toggleSideBar(true, "Añadir Configuración", NEW_ROL);
              }}
            >
              Añadir Configuración
            </Button>
          );
        }
        break;
      case 4:
        if (searchPermission("roles", "editar", permissions)) {
          return (
            <Button
              className="header-btn last"
              onClick={() => {
                this.props.toggleSideBar(true, "Añadir Rol", NEW_ROL);
              }}
            >
              Añadir Rol
            </Button>
          );
        }
        break;
      case 5:
        if (searchPermission("subcuentas", "editar", permissions)) {
          return (
            <Button
              className="header-btn last"
              onClick={() => {
                if (this.props.user.plan.operational_user === -1) {
                  this.props.toggleSideBar(
                    true,
                    "Añadir Subcuenta",
                    NEW_SUBACCOUNT
                  );
                } else if (
                  this.props.user.plan.operational_user +
                  this.props.user.extra_extra_operation_users >
                  this.state.totalSubAccounts
                ) {
                  this.props.toggleSideBar(
                    true,
                    "Añadir Subcuenta",
                    NEW_SUBACCOUNT
                  );
                } else {
                  toast.success(
                    "Para agregar mas cuentas es necesario que actualices tu plan.",
                    {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                    }
                  );
                }
              }}
            >
              Añadir Subcuenta
            </Button>
          );
        }
        break;
      case 8:
        if (searchPermission("multiprocess", "editar", permissions)) {
          return (
            <Button
              className="header-btn last"
              onClick={() => {
                this.props.toggleSideBar(true, "Cotizaciones seleccionadas", SHOW_SELECTED_QUOTES);
              }}
            >
              Ver Seleccionados
            </Button>
          );
        }
        break;
      default:
        break;
    }
  }

  renderTabs() {
    const { currentTab } = this.state;
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }

    return (
      <Tabs
        id="integrations"
        right
        selected={currentTab}
        onChange={(selected) => this.setState({ currentTab: selected })}
      >
        {searchPermission("regiones", "leer", permissions) ? (
          <Tab>Regiones</Tab>
        ) : (
          <Tab hide="yes">Regiones</Tab>
        )}
        {searchPermission("gerentes", "leer", permissions) ? (
          <Tab>Gerentes de Zona</Tab>
        ) : (
          <Tab hide="yes">Gerentes de Zona</Tab>
        )}
        {searchPermission("cuentas", "leer", permissions) ? (
          <Tab>Cuentas</Tab>
        ) : (
          <Tab hide="yes">Cuentas</Tab>
        )}
        {(plan.name === "enterprise" || plan.name === "operativo") && role === "master" ? (
          <Tab>Configuración</Tab>
        ) : (
          <Tab hide="yes">Configuración</Tab>
        )}
        {plan.name !== "enterprise" &&
          searchPermission("roles", "leer", permissions) ? (
          <Tab>Roles</Tab>
        ) : (
          <Tab hide="yes">Roles</Tab>
        )}
        {plan.name !== "enterprise" &&
          searchPermission("subcuentas", "leer", permissions) ? (
          <Tab>Subcuentas</Tab>
        ) : (
          <Tab hide="yes">Subcuentas</Tab>
        )}
        {(plan.name === "enterprise" || plan.name === "operativo") ? (
          <Tab>Wallet</Tab>
        ) : (
          <Tab hide="yes">Wallet</Tab>
        )}
        {(plan.name === "enterprise" || plan.name === "operativo") ? (
          <Tab>Activación Automática</Tab>
        ) : (
          <Tab hide="yes">Activación Automática</Tab>
        )}
        {/*searchPermission("multiprocess", "leer", permissions) ? (
          <Tab>Procesamiento masivo</Tab>
        ) : (
          <Tab hide="yes">Procesamiento masivo</Tab>
        )*/}
      </Tabs>
    );
  }

  render() {
    const { currentTab } = this.state;
    return (
      <div>
        <ToastContainer />
        <Row divisions={20} className="margin-bottom-accounts">
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: "right" }} fluid>
            <Menu />
          </Column>
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Row divisions={18}>
              <Column xs={7} fluid>
                <Title type="titleCard">Administración de cuentas</Title>
              </Column>
              <Column
                xs={10}
                xsShift={0}
                style={{
                  textAlign: "right",
                }}
                fluid
              >
                {!this.props.user.only_read &&
                  this.renderRightButton(currentTab)}
              </Column>
            </Row>
            <Row divisions={20}>
              <Column xs={19} fluid>
                {this.renderTabs()}
                {this.renderTab(currentTab)}
              </Column>
              <Column xs={1} fluid />
            </Row>
          </Column>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsAdmin);
