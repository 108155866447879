import React from 'react';
import { Row, Column } from 'hedron';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { boundMethod } from 'autobind-decorator';
import clock from './img/clock.png';
import Time from './Options/time.js';
import './style.css';

class masterConfig extends React.Component {
  render() {
    return (
      <Row divisions={20}>
        <Column xs={6} fluid>
          <div className="card-container-accounts">
            <div className="title-container">
              <div className="title-container-row">
                <p className="title-card">Configuración</p>
              </div>
            </div>
            <div className="card-container-divisor" />
            <div className="item-master-config">
              <img alt="" src={clock} />
              <p>Tiempo de llegada</p>
            </div>
          </div>
        </Column>
        <Column xs={1} fluid />
        <Column xs={13} fluid>
          <Time />
        </Column>
      </Row>
    );
  }
}
const mapStateToProps = state => ({
  sidebar: state.sidebar,
  countries: state.newShipment.countries,
  user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(masterConfig);
