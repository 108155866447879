// @flow

import axios from "axios";
import type { Dispatch as ReduxDispatch } from "redux";
import { push } from "react-router-redux";
import Cookies from "universal-cookie";
import { showError } from "./error";
import { showLoader, hideLoader } from "./loader";
import { showMessage, hideMessage } from "./message";
import { sendFBEvent } from "../utils/global_functions";
import setTiendaNubeIntegration from "./integrations";

export const GET_ROLE = "GET_ROLE";
export const GET_USER = "GET_USER";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const SET_DATA_PRINT = "SET_DATA_PRINT";
export const GET_DEFAULT_ADDRESS = "GET_DEFAULT_ADDRESS";
export const EDIT_USER_GENERAL_INFO = "EDIT_USER_GENERAL_INFO";
export const EDIT_USER_FISCAL_ADDRESS = "EDIT_USER_FISCAL_ADDRESS";

const successMessage =
  "Se te ha enviado un correo con las instrucciones para reestablecer tu contraseña";

type FiscalAddress = {
  name: string,
  street: string,
  street2: string,
  zipcode: string,
  phone: string,
  rfc: string,
};

type User = {
  object_id: number,
  first_name: string,
  last_name: string,
  email: string,
  api_token: string,
  monthly_invoice: boolean,
  fiscal_address: ?any,
};

type State = {
  +user: User | null,
};

type Action_GET_USER = { type: "GET_USER", user: User };

type Action_GET_DEFAULT_ADDRESS = { type: "GET_DEFAULT_ADDRESS", from: any };

type Action = Action_GET_USER | Action_GET_DEFAULT_ADDRESS;

type Dispatch = ReduxDispatch<Action>;

const initialState: State = {
  user: null,
  dataPrint: null,
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case GET_USER:
      return action.user;
    case ADD_USER_DATA:
      return { ...state, ...action.payload };
    case SET_DATA_PRINT:
      return {
        ...state,
        dataPrint: action.dataPrint,
      };
    case GET_DEFAULT_ADDRESS:
      return action.from;
    default:
      return state;
  }
};

export const getUser = () => {
  let path = "";
  let operational = false;

  if (localStorage.getItem("type_user") === "operational") {
    path =
      "/api/operational_user/profile/" +
      localStorage.getItem("operational_user_id");
    operational = true;
  } else {
    path = "/api/profile";
  }
  let user = "";

  return (dispatch: Dispatch) => {
    return axios
      .get(path)
      .then(({ data }) => {
        if (!operational) {
          dispatch(setUser(data));
          dispatch(getProfileFinance());
          dispatch(getProfileExtra());
          dispatch(getProfileSubscription());
          return data;
        } else {
          // OPERATIONAL USER
          dispatch(setUser(data.user));
          return data.user;
        }
      })
      .then((data) => data)
      .catch((err) => {
        if (err.response) dispatch(showError(err.response.data));
        else dispatch(showError("Error al obtener el perfil"));
        return null;
      });
  };
};

export const getProfileSubscription = () => (dispatch: Dispatch) => {
  axios.get("/api/profile/subscription").then(({ data }) =>
    dispatch({
      type: ADD_USER_DATA,
      payload: data,
    })
  );
};

export const getProfileExtra = () => (dispatch: Dispatch) => {
  return axios
    .get("/api/profile/extra")
    .then(({ data }) => {
      const formatData = {
        ...data,
        default_package: fillObjectOrNull(data.default_package),
        default_storefront: fillObjectOrNull(data.default_storefront),
        default_to_address: fillObjectOrNull(data.default_to_address),
        primary_address: fillObjectOrNull(data.primary_address),
      };

      dispatch({
        type: ADD_USER_DATA,
        payload: formatData,
      });
      return formatData;
    })
    .catch(() => {
      return null;
    });
};

export const getProfileFinance = () => (dispatch: Dispatch) => {
  return axios.get("/api/profile/finance").then(({ data }) => {
    const financeData = {
      ...data,
      fiscal_address: fillObjectOrNull(data.fiscal_address),
    };
    dispatch({
      type: ADD_USER_DATA,
      payload: financeData,
    });
    return financeData;
  });
};

const fillObjectOrNull = (item) => {
  return Object.keys(item ?? {}).length === 0 ? null : item;
};

export const getDataPrint = () => (dispatch: Dispatch) =>
  axios
    .get("/api/users/config/data-print")
    .then((response) => {
      dispatch(setDataPrint(response.data));
      return response.data;
    })
    .catch((err) => {
      if (err.response) dispatch(showError(err.response.data.error));
    });

export const getUserWithLoader = () => (dispatch: Dispatch) => {
  dispatch(showLoader("basic"));
  return axios
    .get("/api/profile")
    .then(({ data }) => {
      dispatch(setUser(data));
      dispatch(hideLoader());
    })
    .catch((err) => {
      if (err.response) dispatch(showError(err.response.data.error));
    });
};

export const setUser = (user: User): Action_GET_USER => ({
  type: GET_USER,
  user,
});

export const setDataPrint = (dataPrint) => ({
  type: SET_DATA_PRINT,
  dataPrint,
});

export const setDefaultFrom = (from: any): Action_GET_DEFAULT_ADDRESS => ({
  type: GET_DEFAULT_ADDRESS,
  from,
});

export const getUrlParameter = (name: string) => {
  const aux = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${aux}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const loginUserOperational = (
  email: string,
  password: string,
  pin: string
) => {
  const cookies = new Cookies();
  cookies.set("active_session", "true", { path: "/" });

  return (dispatch: Dispatch) =>
    axios
      .post("/api/auth", {
        email,
        password,
        pin,
      })
      .then((response) => {
        dispatch(hideMessage());
        const token: string =
          response && response.headers ? response.headers["access-token"] : "";
        localStorage.setItem("token", token);
        localStorage.setItem("type_user", "operational");
        localStorage.setItem(
          "operational_user_id",
          response.data.data.operational_user_id
        );
        if (getUrlParameter("shop")) {
          localStorage.setItem("shopify_store", getUrlParameter("shop"));
          window.location = "/integraciones";
        } else {
          window.location = "/";
        }
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.type &&
          err.response.data.errors.type === "Disabled"
        ) {
          dispatch(
            showError(
              "Por favor, comprueba tu correo electrónico y haz clic en el vínculo del mensaje para completar la verificación de la dirección de correo electrónico."
            )
          );
        } else {
          dispatch(showError("El correo o la contraseña no coinciden"));
        }
      });
};

export const loginFirst = (email: string, password: string) => {
  const cookies = new Cookies();
  cookies.set("active_session", "true", { path: "/" });

  return (dispatch: Dispatch) =>
    axios
      .post("/api/auth", {
        email,
        password,
      })
      .then((response) => {
        dispatch(hideMessage());
        dispatch(hideLoader());
        const token: string =
          response && response.headers ? response.headers["access-token"] : "";
        localStorage.setItem("token", token);
        if (getUrlParameter("shop")) {
          localStorage.setItem("shopify_store", getUrlParameter("shop"));
          window.location = "/integraciones";
        } else {
          window.location = "/";
        }
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.type &&
          err.response.data.errors.type === "Disabled"
        ) {
          dispatch(
            showError(
              "Por favor, comprueba tu correo electrónico y haz clic en el vínculo del mensaje para completar la verificación de la dirección de correo electrónico."
            )
          );
        } else {
          dispatch(showError("El correo o la contraseña no coinciden"));
        }
      });
};

export const login = (email: string, password: string) => {
  const cookies = new Cookies();
  cookies.set("active_session", "true", { path: "/" });

  return (dispatch: Dispatch) =>
    axios
      .post("/api/auth", {
        email,
        password,
      })
      .then((response) => {
        dispatch(hideMessage());
        const token: string =
          response && response.headers ? response.headers["access-token"] : "";
        localStorage.setItem("token", token);
        if (
          getUrlParameter("shop") === "tienda_nube" &&
          getUrlParameter("code")
        ) {
          window.location = `/integraciones?shop=tienda_nube&code=${getUrlParameter(
            "code"
          )}`;
        } else if (getUrlParameter("shop")) {
          localStorage.setItem("shopify_store", getUrlParameter("shop"));
          window.location = "/integraciones";
        } else {
          if (
            process.env.NODE_ENV === "production" &&
            window.location.protocol == "http:"
          ) {
            //window.location.replace("https://app2.mienvio.mx/");
            window.location = "/";
          } else {
            window.location = "/";
          }
        }
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.type &&
          err.response.data.errors.type === "Disabled"
        ) {
          dispatch(
            showError(
              "Por favor, comprueba tu correo electrónico y haz clic en el vínculo del mensaje para completar la verificación de la dirección de correo electrónico."
            )
          );
        } else {
          dispatch(showError("El correo o la contraseña no coinciden"));
        }
      });
};

export const loginGoogle = (email: string, google_auth_id: string) => {
  axios
    .post("/api/auth", {
      email,
      google_auth_id,
    })
    .then((response) => response)
    .catch((err) => err);
};

export const logout = (token: string, uid: number) => (dispatch: Dispatch) =>
  axios
    .delete("/api/auth", {
      headers: {
        "access-token": token,
        uid,
        client: token,
      },
    })
    .then((response) => {
      localStorage.clear();
      window.location = "/";
    })
    .catch((err) => {
      dispatch(showError(err.response.data.error));
    });

export const changePassword =
  (oldPassword: string, newPassword: string, newPasswordConfirm: string) =>
  (dispatch: Dispatch) => {
    dispatch(showLoader("basic"));
    return axios
      .post("/api/profile/password/reset", {
        old_password: oldPassword,
        password: newPassword,
        password_confirmation: newPasswordConfirm,
      })
      .then((response) => {
        dispatch(hideLoader());
        dispatch(showMessage("success", "Contraseña cambiada con éxito"));
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(showError("La contraseña actual es incorrecta"));
      });
  };

export const editUserInfo =
  (
    first_name: string,
    last_name: string,
    phone,
    email: ?string,
    password: ?string
  ) =>
  (dispatch: Dispatch) => {
    dispatch(showLoader("basic"));
    let params = {};
    if (email) {
      params = {
        first_name,
        last_name,
        phone,
        email,
        password,
      };
    } else {
      params = {
        first_name,
        last_name,
        phone,
      };
    }
    return axios
      .put("/api/profile", params)
      .then((response) => {
        dispatch(getUser());
        dispatch(showMessage("success", "Información cambiada con éxito"));
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        let message = "";
        Object.keys(err.response.data.error.params).forEach((k, i) => {
          if (k === "password") {
            message = "La contraseña es incorrecta";
          }
        });
        if (err.response && message) dispatch(showError(message));
        else dispatch(showError(err.response.data.error));
      });
  };

export const editMonthlyInvoice =
  (monthly_invoice: boolean) => (dispatch: Dispatch) =>
    axios
      .put("/api/profile", {
        monthly_invoice,
      })
      .then((response) => {
        dispatch(getUser());
      })
      .catch((err) => {
        if (err.response) dispatch(showError(err.response.data.err));
      });

export const authorizeRFC = (data) => async (dispatch: Dispatch) => {
  let formdata = new FormData();
  formdata.append("cedulaFiscal", data.cedulaFiscal);
  formdata.append("name", data.name);
  formdata.append("phone", data.phone);
  formdata.append("email", data.email);
  formdata.append("reasonToDuplicate", data.reasonToDuplicate);
  formdata.append("rfc", data.rfc);
  await axios
    .post("/api/rfcs/request/validate-other-account", formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      dispatch(
        showMessage(
          "success",
          "Recibimos tu solicitud, pronto tendrás una respuesta"
        )
      );
    })
    .catch((err) => {
      if (err.response) dispatch(showError(err.response.data.err));
    });
};

export const editFiscalInfo =
  (address: FiscalAddress, id?: number) => (dispatch: Dispatch) => {
    dispatch(showLoader("basic"));
    if (id) {
      return axios
        .put(`/api/addresses/${id}`, {
          object_type: "FISCAL",
          name: address.name,
          street: address.street,
          street2: address.street2,
          zipcode: address.zipcode,
          phone: address.phone,
          rfc: address.rfc,
        })
        .then((response) => {
          dispatch(getUser());
          dispatch(
            showMessage("success", "Información fiscal cambiada con éxito")
          );
          dispatch(hideLoader());
        })
        .catch((err) => {
          dispatch(showError(err.response.data.err));
        });
    }
    return axios
      .post("/api/addresses", {
        object_type: "FISCAL",
        name: address.name,
        street: address.street,
        street2: address.street2,
        zipcode: address.zipcode,
        phone: address.phone,
        rfc: address.rfc,
      })
      .then((response) => {
        dispatch(getUser());
        dispatch(
          showMessage("success", "Información fiscal cambiada con éxito")
        );
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(showError(err.response.data.err));
      });
  };

export const signUp =
  (
    first_name: string,
    last_name: string,
    business_name: string,
    email: string,
    phone: number,
    password: string,
    site_url: string,
    referred_from: string,
    google_auth_id: string,
    register_source: string,
    position_id: number,
    shipments_id: number,
    challenge_id: number,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(showLoader("basic"));
    return axios
      .post("/api/auth/create", {
        first_name,
        last_name,
        business_name,
        email,
        phone,
        password,
        password_confirmation: password,
        site_url,
        google_auth_id,
        register_source,
        position_id,
        shipments_id,
        challenge_id,
        register_source: "app"
      })
      .then((response) => {
        if (process.env.NODE_ENV === "production") {
          sendFBEvent("SignUp");
        }

        //dispatch(showMessage(
        //                      'success',
        //                      'Te has Reigstrado correctamente',
        //                    ));
        dispatch(loginFirst(email, password));
        //dispatch(push('/select-plan'));
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        let msg = "";
        if (err.response.status === 422) {
          Object.keys(err.response.data.errors).forEach((key) => {
            msg += `${err.response.data.errors[key]}\n`;
          });
        } else if (err.response.status === 500) {
          msg += "Error interno en el sistema, intenta mas tarde.";
        }
        dispatch(showError(msg));
      });
  };

export const resendMail = (email: string) =>
  axios
    .get(`/api/auth/resendVerificationEmail?email=${email}`)
    .then((response) => response)
    .catch((err) => err);

export const requestPasswordChange =
  (email: string) => (dispatch: Dispatch) => {
    dispatch(showLoader("basic"));
    return axios
      .post("/api/auth/password/email", {
        email,
        redirect_url: "http://google.com",
      })
      .then((response) => {
        dispatch(hideLoader());
        dispatch(showMessage("success", successMessage));
        dispatch(push(`/contrasena?email=${email}`));
      })
      .catch((err) => {
        dispatch(hideLoader());
        let msg = "";
        if (err.response.status === 422) {
          Object.keys(err.response.data.errors).forEach((key) => {
            msg += `${err.response.data.errors[key]}\n`;
          });
        } else if (err.response.status === 500) {
          msg += "Error interno en el sistema, intenta mas tarde.";
        }
        dispatch(showError(msg));
      });
  };

export const resetPassword =
  (
    token: string,
    email: string,
    password: string,
    password_confirmation: string
  ) =>
  (dispatch: Dispatch) => {
    dispatch(showLoader("basic"));
    return axios
      .post("/api/auth/password/reset", {
        token,
        email,
        password,
        password_confirmation,
      })
      .then((response) => {
        dispatch(hideLoader());
        dispatch(
          showMessage(
            "success",
            "Contraseña cambiada con éxito. Ya puedes iniciar sesión con la nueva contraseña."
          )
        );
      })
      .catch((err) => {
        dispatch(hideLoader());
        let msg = "";
        if (err.response.status === 422) {
          Object.keys(err.response.data.errors).forEach((key) => {
            msg += `${err.response.data.errors[key]}\n`;
          });
        } else if (err.response.status === 500) {
          msg += "Error interno en el sistema, intenta mas tarde.";
        }
        dispatch(showError(msg));
      });
  };

export const storeShipmentResponsable = (name, email, phone) => (dispatch) => {
  dispatch(showLoader("basic"));
  return axios
    .post("/api/shipmentsResponsable", {
      name: name,
      email: email,
      phone: phone,
    })
    .then((response) => {
      dispatch(getUser());
      dispatch(showMessage("success", "Información cambiada con éxito"));
      dispatch(hideLoader());
    })
    .catch((err) => {
      try {
        dispatch(showError(err.response.data.errors));
      } catch (e) {
        dispatch(showError("Error al editar la información"));
      }
      dispatch(hideLoader());
    });
};
