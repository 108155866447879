import React from 'react';
import { Row, Column } from 'hedron';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { boundMethod } from 'autobind-decorator';
import Skeleton from 'react-loading-skeleton';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import AccountSetupConfig from '../../components/AccountSetupConfig';
import AccountsManagment from '../../components/AccountsManagment';
import Input, { Label, Required, HelpText } from '../../components/Input';
import Text, { Title, P } from '../../components/Text';
import WrapperSideBar from '../../components/WrapperSideBar';
import { ContentAdapter, BottomContainer } from '../../components/WrapperSideBar/styledObjects';
import Button from '../../components/Button';
import EmpyState from './emptyState';
import Zone from './zone';
import 'react-toastify/dist/ReactToastify.css';
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from '../../modules/CollapsibleBar/actions';
import Dropdown from '../../components/Dropdown';
import {
  getRegions,
} from '../../modules/regions';
import {
  createZone,
  createZoneCustom,
  getZones,
  deleteZone,
  getSingleZone,
  updateZone,
  updateZoneCustom,
} from '../../modules/zones';
import {
  getActions,
} from '../../modules/actions';
import { Checkbox, CheckboxGroup } from '../../components/Checkbox';

import { RadioGroup, Radio } from '../../components/Radio';

import './style.css';

const NEW_ZONE = 'NEW_ZONE';
const EDIT_ZONE = 'EDIT_ZONE';
const optionsPermisos = [
  { value: 'shipments-create', label: 'Contador' },
  { value: 'shipments-read', label: 'Operador de Piso' },
];
class Zones extends React.Component {
  state = {
    empty: false,
    selectedCountry: [],
    selectedPermissions: [],
    countryError: false,
    permissionsError: false,
    name: '',
    nameError: '',
    last_name: '',
    last_nameError: '',
    mail: '',
    mailError: '',
    password: '',
    passwordError: '',
    passwordConfirm: '',
    passwordConfirmError: '',
    loading: false,
    regions: [],
    regionEdit: null,
    selectedChecked: [],
    actions: [],
    regions: [],
    data: [],
    emptyRegions: false,
    selectedPermisionType: 0,
  }

  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
    this.props.toggleSideBar(false);
  }

  componentDidMount() {
    this.getZonesData();
    this.getRegionsData();
    this.getActionsData();
  }

  clearSb() {
    this.setState({
      name: '', last_name: '', mail: '', password: '', passwordConfirm: '', selectedCountry: [], selectedPermissions: [], selectedPermisionType: 0, nameError: '', last_nameError: '', mailError: '', passwordError: '', passwordConfirmError: '', selectedCountryError: [],
    });
  }

  select = (value) => {
    this.setState({ selectedChecked: value });
  };

  getRegionsData() {
    getRegions().then((response) => {
      if (response.type === 'Success' && response.response.status === 200) {
        if (response.response.data.length === 0) {
          this.setState({ emptyRegions: true });
        } else {
          this.setState({ emptyRegions: false, regions: response.response.data });
        }
      } else {
        this.notify('Error al obtener la información', 'error');
      }
    });
  }

  getZonesData() {
    this.setState({ loading: true });
    getZones().then((response) => {
      if (response.type === 'Success' && response.response.status === 200) {
        if (response.response.data.length === 0) {
          this.setState({ loading: false, empty: true });
        } else {
          this.setState({ loading: false, empty: false, data: response.response.data });
        }
      } else {
        this.setState({ loading: false });
        this.notify('Error al obtener la información', 'error');
      }
    });
  }

  getActionsData() {
    getActions().then((response) => {
      if (response.type === 'Success' && response.response.status === 200) {
        if (response.response.data.length === 0) {
        } else {
          this.setState({ actions: response.response.data });
        }
      } else {
        this.notify('Error al obtener la información', 'error');
      }
    });
  }

  @boundMethod
  toggleEditZone(id) {
    getSingleZone(id).then((response) => {
      if (response.type === 'Success' && response.response.status === 200) {
        const selectedCountryData = response.response.data.regions.map(element => ({ label: element.name, value: element.id }));
        const selectedPermissionsData = response.response.data.actions.map(element => ({ label: `${element.name} - ${element.value}`, value: element.id }));
        this.setState({
          name: response.response.data.first_name,
          last_name: response.response.data.last_name,
          mail: response.response.data.email,
          selectedCountry: selectedCountryData,
          selectedPermissions: selectedPermissionsData,
          selectedPermisionType: 2,
          regionEdit: id,
        });
      } else {
        this.notify('Error al obtener la información', 'error');
        this.setState({ loading: false });
        this.props.toggleSideBar(false);
      }
    });
    this.props.toggleSideBar(true, `Editar Gerente ${id}`, EDIT_ZONE);
  }

  @boundMethod
  toggleDeleteZone(id) {
    deleteZone(id).then((response) => {
      if (response.type === 'Success' && response.response.status === 200) {
        this.notify('Gerente eliminado exitosamente', 'success');
        this.props.toggleSideBar(false);
        this.clearSb();
        this.getZonesData();
      } else {
        this.notify('Error al eliminar gerente', 'error');
        this.props.toggleSideBar(false);
      }
    });
  }

  renderZones = data => (
    data.map(element => (
      <Zone user={this.props.user} data={element} toggleEditZone={this.toggleEditZone} toggleDeleteZone={this.toggleDeleteZone} permissions={this.state.actions} />
    ))
  )

  _renderRegions = () => (this.state.regions
    ? this.state.regions.map(region => ({ value: region.id, label: region.name }))
    : null);

  _renderActions = () => (this.state.actions
    ? this.state.actions.map(action => ({ value: action.id, label: `${action.name} - ${action.value}` }))
    : null);

  handleChangeDrop = (selectedCountry) => {
    this.setState({ selectedCountry });
  };

  HandleChangeDropPermissions = (selectedPermissions) => {
    this.setState({ selectedPermissions });
  };

  @boundMethod
  openAccount(id, regionId, email) {
  }

  onChangePermissionType = (value) => {
    this.setState({
      selectedPermisionType: value,
    });
  };

  _renderSidebarContent = () => {
    const { type } = this.props.sidebar;
    const {
      name, nameError, last_name, last_nameError, mail, mailError, password, passwordError, passwordConfirm, passwordConfirmError, selectedChecked,
    } = this.state;
    switch (type) {
    case NEW_ZONE:
      return (
        <ContentAdapter className="top-space">
          <Label style={{ marginTop: '16px' }}>
            Selecciona una Región(es)
            <Required />
          </Label>
          <Select
            isMulti
            value={this.state.selectedCountry}
            onChange={this.handleChangeDrop}
            noOptionsMessage={() => 'Sin Opciones'}
            isSearchable
            placeholder="Región(es)"
            closeMenuOnSelect={false}
            options={this._renderRegions()}
            className={`search-select accounts ${this.state.countryError
              != '' && 'error'}`}
          />
          <br />
          <HelpText>
          Las regiones corresponden a las cuentas que tendra a cargo el gerente de zona
          </HelpText>
          <Input
            onChange={value => this.handleOnChange('name', value)}
            type="text"
            required
            value={name}
            error={nameError}
            placeholder="Juan"
            label="Nombre"
          />
          <Input
            onChange={value => this.handleOnChange('last_name', value)}
            type="text"
            required
            value={last_name}
            error={last_nameError}
            placeholder="Peréz"
            label="Apellidos"
          />
          <Input
            onChange={value => this.handleOnChange('mail', value)}
            type="mail"
            required
            value={mail}
            error={mailError}
            placeholder="gerenteregion@mienvio.mx"
            label="Correo"
          />
          <HelpText>
          Crea un correo y contraseña, para poder acceder a la cuenta Gerente, gestionar configuraciones y visualizar datos de las subcuentas asociadas.
          </HelpText>
          <Input
            onChange={value => this.handleOnChange('password', value)}
            type="password"
            required
            value={password}
            error={passwordError}
            placeholder="****************"
            label="Contraseña"
          />
          <Input
            onChange={value => this.handleOnChange('passwordConfirm', value)}
            type="password"
            required
            value={passwordConfirm}
            error={passwordConfirmError}
            placeholder="****************"
            label="Confirmar Contraseña"
          />
          <RadioGroup
            selected={this.state.selectedPermisionType}
            onChange={this.onChangePermissionType}
          >
            <Radio
              value={0}
              text="Todos los permisos"
              className="no-margin-left"
            />
            <Radio
              value={1}
              text="Solo lectura"
              className="no-margin-left"
            />
            <Radio
              value={2}
              text="Seleccionar Permisos"
              className="no-margin-left"
            />
          </RadioGroup>
          {this.state.selectedPermisionType === 2
            && (
              <div className="margin-bottom-select">
                <Select
                  isMulti
                  value={this.state.selectedPermissions}
                  onChange={this.HandleChangeDropPermissions}
                  noOptionsMessage={() => 'Sin Opciones'}
                  isSearchable
                  placeholder="Permisos"
                  closeMenuOnSelect={false}
                  options={this._renderActions()}
                  className={`search-select accounts ${this.state.permissionsError
              != '' && 'error'}`}
                />
              </div>
            )
          }

          <BottomContainer>
            <Row divisions={20}>
              <Column style={{ textAlign: 'right' }} fluid>
                <Button
                  loading={this.state.setLoading}
                  onClick={() => this.addZone()}
                  className=""
                >
                  Guardar
                </Button>
              </Column>
              <Column xs={1} xsShift={1} style={{ textAlign: 'right' }} fluid />
            </Row>
          </BottomContainer>
        </ContentAdapter>
      );
    case EDIT_ZONE:
      return (
        <ContentAdapter className="top-space">
          <Label style={{ marginTop: '16px' }}>
            Selecciona una Región(es)
            <Required />
          </Label>
          <Select
            isMulti
            value={this.state.selectedCountry}
            onChange={this.handleChangeDrop}
            noOptionsMessage={() => 'Sin Opciones'}
            isSearchable
            placeholder="Región(es)"
            closeMenuOnSelect={false}
            options={this._renderRegions()}
            className={`search-select accounts ${this.state.countryError
              != '' && 'error'}`}
          />
          <br />
          <HelpText>
          Las regiones corresponden a las cuentas que tendra a cargo el gerente de zona
          </HelpText>
          <Input
            onChange={value => this.handleOnChange('name', value)}
            type="text"
            required
            value={name}
            error={nameError}
            placeholder="Juan"
            label="Nombre"
          />
          <Input
            onChange={value => this.handleOnChange('last_name', value)}
            type="text"
            required
            value={last_name}
            error={last_nameError}
            placeholder="Peréz"
            label="Apellidos"
          />
          <Input
            onChange={value => this.handleOnChange('mail', value)}
            type="mail"
            required
            value={mail}
            error={mailError}
            placeholder="gerenteregion@mienvio.mx"
            label="Correo"
          />
          <RadioGroup
            selected={this.state.selectedPermisionType}
            onChange={this.onChangePermissionType}
          >
            <Radio
              value={0}
              text="Todos los permisos"
              className="no-margin-left"
            />
            <Radio
              value={1}
              text="Solo lectura"
              className="no-margin-left"
            />
            <Radio
              value={2}
              text="Seleccionar Permisos"
              className="no-margin-left"
            />
          </RadioGroup>
          {this.state.selectedPermisionType === 2
            && (
              <div className="margin-bottom-select">
                <Select
                  isMulti
                  value={this.state.selectedPermissions}
                  onChange={this.HandleChangeDropPermissions}
                  noOptionsMessage={() => 'Sin Opciones'}
                  isSearchable
                  placeholder="Permisos"
                  closeMenuOnSelect={false}
                  options={this._renderActions()}
                  className={`search-select accounts ${this.state.permissionsError
              != '' && 'error'}`}
                />
              </div>
            )
          }
          <BottomContainer>
            <Row divisions={20}>
              <Column style={{ textAlign: 'right' }} fluid>
                <Button
                  loading={this.state.setLoading}
                  onClick={() => this.zoneUpdate()}
                  className=""
                >
                  Guardar
                </Button>
              </Column>
              <Column xs={1} xsShift={1} style={{ textAlign: 'right' }} fluid />
            </Row>
          </BottomContainer>
        </ContentAdapter>
      );
    default:
      return <div />;
    }
  };

  handleOnChange(type, value) {
    switch (type) {
    case 'name':
      this.setState({ name: value });
      break;
    case 'last_name':
      this.setState({ last_name: value });
      break;
    case 'mail':
      this.setState({ mail: value });
      break;
    case 'password':
      this.setState({ password: value });
      break;
    case 'passwordConfirm':
      this.setState({ passwordConfirm: value });
      break;

    default:
      break;
    }
  }

  addZone() {
    const {
      name, last_name, mail, password, passwordConfirm, selectedCountry, selectedPermissions, selectedPermisionType,
    } = this.state;
    if (this.validateFields(false)) {
      const regions = selectedCountry.map(element => (element.value));
      if (selectedPermisionType === 2) {
        const permissions = selectedPermissions.map(element => (element.value));
        createZoneCustom(regions, name, last_name, mail, password, permissions).then((res) => {
          if (res.status === 200) {
            this.notify('Zona creada exitosamente', 'success');
            this.props.toggleSideBar(false);
            this.clearSb();
            this.getZonesData();
          } else {
            this.notify('Error al crear región', 'error');
            this.props.toggleSideBar(false);
            this.clearSb();
          }
        });
      } else {
        const allpermissions = selectedPermisionType === 0;
        createZone(regions, name, last_name, mail, password, allpermissions).then((res) => {
          if (res.status === 200) {
            this.notify('Zona creada exitosamente', 'success');
            this.props.toggleSideBar(false);
            this.clearSb();
            this.getZonesData();
          } else {
            this.notify('Error al crear región', 'error');
            this.props.toggleSideBar(false);
            this.clearSb();
          }
        });
      }
    }
  }

  zoneUpdate() {
    const {
      name, last_name, mail, selectedCountry, regionEdit, selectedPermissions, selectedPermisionType
    } = this.state;
    if (this.validateFields(true)) {
      const countries = selectedCountry.map(element => (element.value));
      const permissions = selectedPermissions.map(element => (element.value));
      if (selectedPermisionType === 2) {
        updateZoneCustom(regionEdit, countries, name, last_name, mail, permissions).then((res) => {
          if (res.status === 200) {
            this.notify('Gerente actualizada exitosamente', 'success');
            this.props.toggleSideBar(false);
            this.clearSb();
            this.getZonesData();
          } else {
            this.notify('Error al actualizar gerente', 'error');
            this.props.toggleSideBar(false);
            this.clearSb();
          }
        });
      }else{
        const allpermissions = selectedPermisionType === 0;
        updateZone(regionEdit, countries, name, last_name, mail, allpermissions).then((res) => {
          if (res.status === 200) {
            this.notify('Gerente actualizada exitosamente', 'success');
            this.props.toggleSideBar(false);
            this.clearSb();
            this.getZonesData();
          } else {
            this.notify('Error al actualizar gerente', 'error');
            this.props.toggleSideBar(false);
            this.clearSb();
          }
        });
      }

    }
  }

  notify = (message, type) => {
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  validateFields(edit) {
    let flag = true;
    const {
      name, last_name, mail, password, passwordConfirm, selectedCountry, selectedPermissions, selectedPermisionType,
    } = this.state;
    if (name === '') {
      flag = false;
      this.setState({ nameError: 'Campo obligatorio' });
    } else {
      this.setState({ nameError: '' });
    }
    if (last_name === '') {
      flag = false;
      this.setState({ last_nameError: 'Campo obligatorio' });
    } else {
      this.setState({ last_nameError: '' });
    }
    if (mail === '') {
      flag = false;
      this.setState({ mailError: 'Campo obligatorio' });
    } else {
      this.setState({ mailError: '' });
    }
    if (!edit) {
      if (password === '') {
        flag = false;
        this.setState({ passwordError: 'Campo obligatorio' });
      } else {
        this.setState({ passwordError: '' });
      }
      if (passwordConfirm === '') {
        flag = false;
        this.setState({ passwordConfirmError: 'Campo obligatorio' });
      } else {
        this.setState({ passwordConfirmError: '' });
      }
      if (passwordConfirm !== password) {
        flag = false;
        this.setState({ passwordConfirmError: 'Las constraseñas no coinciden' });
      } else {
        this.setState({ passwordConfirmError: '' });
      }
    }
    if (selectedCountry.length === 0) {
      flag = false;
      this.setState({ countryError: true });
    } else {
      this.setState({ countryError: false });
    }
    if (selectedPermisionType === 2) {
      if (selectedPermissions.length === 0) {
        flag = false;
        this.setState({ permissionsError: true });
      } else {
        this.setState({ permissionsError: false });
      }
    }
    return flag;
  }

  renderLoader() {
    return (
      <div>
        <Skeleton height={70} />
        <br />
        <br />
        <Skeleton height={70} />
        <br />
        <br />
        <Skeleton height={70} />
        <br />
        <br />
        <Skeleton height={70} />
        <br />
        <br />
        <Skeleton height={70} />
        <br />
        <br />
        <Skeleton height={70} />
        <br />
        <br />
        <Skeleton height={70} />
      </div>
    );
  }

  render() {
    const { empty, loading } = this.state;
    const { toggleSideBar } = this.props;
    return (
      <div>
        <ToastContainer />
        <WrapperSideBar
          handleClose={() => this.clearSb()}
        >
          {this._renderSidebarContent()}
        </WrapperSideBar>
        {loading ? this.renderLoader() : empty ? <EmpyState user={this.props.user} onClick={() => { toggleSideBar(true, 'Añadir Gerente de Zona', NEW_ZONE); }} name="region" /> : (
          this.renderZones(this.state.data)
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  sidebar: state.sidebar,
  countries: state.newShipment.countries,
  user: state.user
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    toggleSideBar,
    changeSidebarTitle,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Zones);
