import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import Text from "../Text";
import {
  setErrorQuickQ,
  setValueQuickQ,
  unsetErrorQuickQ,
  clearQuickQuote,
  createRates,
  sendInfoOrder,
} from "../../modules/QuickQuote/actions";
import { clearRates } from "../../modules/rates";
import {
  setValue,
  isLoadingZipcode,
  getCountries,
  setOldRate,
} from "../../modules/sidebarOrder/actions";
import QuoteForm from "./form";
import "./quickQuote.css";
import Button from "../Button";
import { Column, Row } from "hedron";
import { NotFillView, LoadingView } from "./emptyView";
import Service from "../CreateLabel/common/service";
import infoIcon from "./img/warehouse-package-search.svg";
import servicesIcon from "./img/delivery-man-head.svg";
import { Img } from "../Checkout/styled-components";
import { toggle } from "../../modules/CollapsibleBar/actions";
import { NEW_SHIPMENT } from "../../modules/CollapsibleBar/sideBarTypes";
import { BottomContainer } from "../WrapperSideBar/styledObjects";

import { sendFBEvent } from "../../utils/global_functions";
import ReactGA from "react-ga";
import Message from "../Message";

const toggleTop = "top";
const toggleBottom = "bottom";
const noZipcode = "No existe el código";

class QuickQuote extends React.Component {
  constructor(props) {
    super(props);
    this.gtag = this.gtag.bind(this);
    this.gtag_report_conversion = this.gtag_report_conversion.bind(this);
  }

  state = {
    upClass: "ion-android-arrow-dropup",
    downClass: "ion-android-arrow-dropdown",
    shakeClass: "",
    isTopOpen: true,
    isBottomOpen: true,
    isEditing: false,
    errorZipCodeTo: false,
  };

  componentDidMount() {
    this.props.getCountries();
    const script = document.createElement("script");

    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-827928603";
    script.async = true;

    document.body.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    this.gtag("js", new Date());

    this.gtag("config", "AW-827928603");
    this.props.clearRates();
  }

  gtag() {
    window.dataLayer.push(arguments);
  }

  gtag_report_conversion() {
    this.gtag("event", "conversion", {
      send_to: "AW-827928603/AwGqCJ649qEBEJvg5IoD",
    });
  }

  setStateAsync = (state) =>
    new Promise((resolve) => {
      this.setState(state, resolve);
    });

  handleChange = (value, name) => {
    const belongsTo = name.split("_")[0];
    const key = name.split("_")[1];
    this.props.setValueQuickQ(name, value);
  };

  componentWillUnmount(): void {
    this.props.clearQuickQuote();
  }

  handleBlur = (value, name, required) => {
    const belongsTo = name.split("_")[0];
    const key = name.split("_")[1];

    if (required && !value)
      this.props.setErrorQuickQ(name, "Este campo es requerido");
    else if (key === "weight" && !this._validNumber(value)) {
      this.props.setErrorQuickQ(name, "El peso es invalido");
    } else this.props.unsetErrorQuickQ(name);
    if (key == "origin" || key == "destiny") {
      if (key == "destiny") {
        if (!this.state.errorZipCodeTo) {
          if (!this._validZipCode(value)) {
            this.props.setErrorQuickQ(name, "El codigo postal es invalido");
          } else this.props.unsetErrorQuickQ(name);
        } else {
          this.props.setErrorQuickQ(name, "El codigo postal es invalido");
        }
      }
    }
    if (key === "weight" && this._validNumber(value)) {
      if (value > 190) {
        this.props.setErrorQuickQ(
          "main_weight",
          "El valor del peso debe ser menor que 190kg."
        );
      }
    }
    this._validForm();
    this.setState({ isEditing: false });
  };

  onChangeDimensions = async (value, key) => {
    const newObject = {
      ...this.props.data.main.dimensions,
      [key]: value,
    };
    await this.props.setValueQuickQ("main_dimensions", newObject);
    if (
      newObject.length.length > 0 &&
      newObject.width.length > 0 &&
      newObject.height.length > 0
    ) {
      if (
        !isNaN(newObject.length) &&
        !isNaN(newObject.width) &&
        !isNaN(newObject.height)
      ) {
        this.props.unsetErrorQuickQ("main_dimensions");
        const vol =
          (1.0 *
            parseFloat(this.props.data.main.dimensions.length) *
            parseFloat(this.props.data.main.dimensions.width) *
            parseFloat(this.props.data.main.dimensions.height)) /
          5000.0;
        this.props.setValueQuickQ("main_volumetricWeight", vol);
        if (vol > 190) {
          this.props.setErrorQuickQ(
            "main_dimensions",
            "El valor del peso volumétrico debe ser menor que 190kg."
          );
        }
      } else {
        this.props.setErrorQuickQ(
          "main_dimensions",
          "El valor de las dimensiones no es correcto"
        );
      }
    }
  };

  _validText = (value) => value.length > 0;

  _validZipCode = (value) => !isNaN(value) && value.length == 5;

  _validNumber = (value) => !isNaN(value);

  _validWeight = () =>
    this.props.data.main.weight < 190 &&
    this.props.data.main.volumetricWeight < 190;

  sortByPrice = (a, b) => a.amount - b.amount;

  _isFormValid = () =>
    this._validText(this.props.data.main.country) &&
    this._validText(this.props.data.main.origin) &&
    this._validText(this.props.data.main.destiny) &&
    this._validText(this.props.data.main.weight) &&
    this._validText(this.props.data.main.dimensions.length) &&
    this._validText(this.props.data.main.dimensions.width) &&
    this._validText(this.props.data.main.dimensions.height) &&
    this._validZipCode(this.props.data.main.origin) &&
    this._validZipCode(this.props.data.main.destiny) &&
    this._validNumber(this.props.data.main.dimensions.length) &&
    this._validNumber(this.props.data.main.dimensions.width) &&
    this._validNumber(this.props.data.main.dimensions.height) &&
    this._validNumber(this.props.data.main.weight) &&
    this._validWeight();

  _validForm = () => {
    const { sidebar, data, createRates } = this.props;
    if (this._getCountryByIndex(sidebar.data.to.country)) {
      if (this._isFormValid() && !data.loading) {
        createRates(
          data.main.origin,
          data.main.destiny,
          data.main.weight,
          data.main.dimensions.length,
          data.main.dimensions.width,
          data.main.dimensions.height,
          "MX",
          this._getCountryByIndex(sidebar.data.to.country).code
        );
      }
    }
  };

  _changeCountry = (country, type) => {
    this.props.setValue(`${type}_country`, country);
  };

  _renderCountries = () =>
    this.props.sidebar.countries
      ? this.props.sidebar.countries.map((country) => (
          <Text key={country.object_id}>{country.name}</Text>
        ))
      : null;

  _getCountryByIndex = (index) => this.props.sidebar.countries[index];

  generateProps = (name, params) => ({
    onChange: (value) => {
      const val =
        params.type === "radio" || params.type === "checkbox"
          ? value.target.value
          : value;
      this.handleChange(val, name);
      if (name === "main_destiny" && value.length === 5) {
        this.updateZipcode("to", value);
      }
    },
    onBlur: (value) => {
      const val =
        params.type === "radio" || params.type === "checkbox"
          ? value.target.value
          : value;
      this.handleBlur(val, name, params.required);
    },
    onFocus: () => {
      this.setState({ isEditing: true });
    },
    name: name || "",
    value: params.value
      ? params.value
      : this.props.data[name.split("_")[0]]
      ? this.props.data[name.split("_")[0]][name.split("_")[1]]
      : "",
    error: this.props.data[name.split("_")[0]][`${name.split("_")[1]}Error`],
    type: params.type ? params.type : "text",
    required: params.required ? params.required : false,
    placeholder: params.placeholder ? params.placeholder : "",
    label: params.label ? params.label : "",
    help: params.help ? params.help : "",
    maxLength: params.maxLength ? params.maxLength : null,
    disabled: name == "main_country" ? true : this.props.data.loading,
    cornerNote: params.cornerNote ? params.cornerNote : null,
  });

  updateZipcode = (belongsTo, value) => {
    this.props.isLoadingZipcode(true);
    const country_code =
      belongsTo === "to" && this.props.sidebar.data.to.country
        ? this._getCountryByIndex(this.props.sidebar.data.to.country).code
        : "MX";
    axios
      .get(`/api/zipcodes/${value}?country=${country_code}`)
      .then(async (response) => {
        await this.props.setValue(
          `${belongsTo}_city`,
          `${
            response.data.municipality
              ? response.data.municipality
              : response.data.neighborhood
          }, ${response.data.state.name}`
        );
        this.setState({
          errorZipCodeTo: false,
        });
        await this.props.isLoadingZipcode(false);
      })
      .catch(async () => {
        await this.props.setValue(`${belongsTo}_city`, noZipcode);
        await this.props.isLoadingZipcode(false);
        this.setState({
          errorZipCodeTo: true,
        });
        this.props.setErrorQuickQ(
          "main_destiny",
          "El codigo postal es invalido"
        );
      });
  };

  _generateObject = () => ({
    country: {
      ...this.generateProps("main_country", {
        required: true,
        placeholder: "País Origen",
        label: "País Origen",
      }),
    },
    countryTo: {
      type: "to",
      disable: false,
      data: this._renderCountries(),
      selected: this.props.sidebar.data.to.country,
      handleChange: async (country) => {
        if (!this.props.sidebar.data.saveAddress) {
          if (this._getCountryByIndex(country).code !== "MX") {
            await this.props.setValue("to_saveAddress", true);
            await this.props.setValue("to_alias", "Dirección Internacional");
          } else {
            await this.props.setValue("to_saveAddress", false);
            await this.props.setValue("to_alias", "");
          }
        }
        await this._changeCountry(country, "to");
        if (
          this.props.data.main.destiny &&
          this.props.data.main.destiny.length === 5
        ) {
          await this.updateZipcode("to", this.props.data.main.destiny);
        }
      },
    },
    Origin: {
      ...this.generateProps("main_origin", {
        required: true,
        placeholder: "Origen",
        label: "C.P. Origen",
        maxLength: 5,
        type: "number",
      }),
    },
    Destiny: {
      ...this.generateProps("main_destiny", {
        required: true,
        placeholder: "Destino",
        label: "C.P. Destino",
        maxLength: 5,
        type: "number",
      }),
    },
    dimensions: {
      value: this.props.data.main.dimensions,
      error: this.props.data.main.dimensionsError,
      disabled: this.props.data.loading,
      onChange: this.onChangeDimensions,
      onFocus: async () => {
        await this.setStateAsync({ isEditing: true });
      },
      onBlur: async () => {
        await this.setStateAsync({ isEditing: false });
        this._validForm();
      },
    },
    weight: {
      ...this.generateProps("main_weight", {
        required: true,
        placeholder: "Peso",
        label: "Peso",
        maxLength: 5,
        type: "number",
      }),
    },
    volumetricWeight: this.props.data.main.volumetricWeight,
  });

  _toggle = (toggle) => {
    if (toggle == toggleTop)
      this.setState({ isTopOpen: !this.state.isTopOpen });
    else this.setState({ isBottomOpen: !this.state.isBottomOpen });
  };

  handleRadioChange = (e) => {
    const selected = this.props.rates.results.filter(
      (service) => service.object_id == e.target.value
    )[0];
    this.props.setOldRate(selected.object_id);
    this.props.setValueQuickQ("services_service", selected);
    this.props.setValue("carrier_shipment", selected);
  };

  _getServices = () => this.props.rates.results.sort(this.sortByPrice);

  _renderService = () => {
    if (this._isFormValid() && !this.state.isEditing) {
      return this.props.data.loading ? (
        <LoadingView />
      ) : (
        <div>
          {this.props.rates && (
            <Service
              rates={this._getServices()}
              handleRadioChange={this.handleRadioChange}
              isChecked={(id) =>
                this.props.data.services.service
                  ? id == this.props.data.services.service.object_id
                  : false
              }
              isQuickQuote
            />
          )}
        </div>
      );
    }
    return <NotFillView />;
  };

  _createModelShipment = () => ({
    country: this.props.data.main.country,
    origin: this.props.data.main.origin,
    destiny: this.props.data.main.destiny,
    dimensions: { ...this.props.data.main.dimensions },
    weight: this.props.data.main.weight,
  });

  _DoShipment = async () => {
    if (this._isFormValid() && this.props.data.services.service) {
      this.props.sendInfoOrder(this._createModelShipment());
      this.props.toggle(true, "Creación de guías", NEW_SHIPMENT);
      if (process.env.NODE_ENV === "production") {
        sendFBEvent("Cotizacion Realizada");
        ReactGA.event({
          category: "Envios",
          action: "Cotizacion Realizada",
        });
        this.gtag_report_conversion();
      }
    } else {
      await this.setStateAsync({ shakeClass: "animated shake" });
      setTimeout(() => {
        this.setStateAsync({ shakeClass: "" });
      }, 300);
    }
  };

  _showValidateMsg = () => {
    if (
      this._validText(this.props.data.main.dimensions.length) &&
      this._validText(this.props.data.main.dimensions.width) &&
      this._validText(this.props.data.main.dimensions.height) &&
      this._validText(this.props.data.main.weight) &&
      this._validNumber(this.props.data.main.dimensions.length) &&
      this._validNumber(this.props.data.main.dimensions.width) &&
      this._validNumber(this.props.data.main.dimensions.height) &&
      this._validNumber(this.props.data.main.weight)
    ) {
      return (
        this.props.data.main.volumetricWeight > this.props.data.main.weight
      );
    }
  };

  render() {
    return (
      <div className="qq-form">
        <div>
          <div
            className={`section-separator adjust-top ${
              !this.state.isTopOpen && "quick-container-top"
            }`}
          >
            <Img src={infoIcon} className="icon-left" /> Información básica
            <a
              onClick={() => {
                this._toggle(toggleTop);
              }}
            >
              <i
                className={`${
                  !this.state.isTopOpen
                    ? this.state.downClass
                    : this.state.upClass
                } icon-right icon-first`}
              />
            </a>
          </div>
          {this.state.isTopOpen && (
            <QuoteForm
              model={this._generateObject()}
              showMsg={this._showValidateMsg()}
            />
          )}
        </div>

        {this._isFormValid() && !this.state.isEditing && this.props.rates && (
          <Message color="warning" showClose={false}>
            Fecha de entrega aproximada y sujeta a cambios. Los tiempos de
            entrega dependen totalmente de la paquetería seleccionada.
          </Message>
        )}

        <div
          className={`section-separator ${
            !this.state.isTopOpen && "quick-container-bottom"
          }`}
        >
          <Img src={servicesIcon} className="icon-left" /> Servicios disponibles
          <a
            onClick={() => {
              this._toggle(toggleBottom);
            }}
          >
            <i
              className={`${
                !this.state.isBottomOpen
                  ? this.state.downClass
                  : this.state.upClass
              } icon-right `}
            />
          </a>
        </div>

        {this.state.isBottomOpen && (
          <div className="serviceContainer">{this._renderService()}</div>
        )}
        <BottomContainer>
          <Row divisions={20} className="nl_bottom-btns">
            <Column xs={9} xsShift={1} style={{ textAlign: "left" }} fluid />
            <Column xs={8} xsShift={1} style={{ textAlign: "right" }} fluid>
              <div>
                {this._isFormValid() && (
                  <Button
                    onClick={this._DoShipment}
                    disabled={this.props.data.loading}
                    className={this.state.shakeClass}
                  >
                    Crear orden
                  </Button>
                )}
              </div>
            </Column>
            <Column xs={1} xsShift={1} style={{ textAlign: "right" }} fluid />
          </Row>
        </BottomContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.quickQuote,
  rates: state.rates,
  sidebar: state.newShipment,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setValueQuickQ,
      setErrorQuickQ,
      unsetErrorQuickQ,
      clearQuickQuote,
      createRates,
      sendInfoOrder,
      toggle,
      setValue,
      isLoadingZipcode,
      clearRates,
      getCountries,
      setOldRate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(QuickQuote);
