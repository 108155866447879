import React from 'react';
import axios from 'axios';
import { Row, Column } from 'hedron';
import Button from '../../components/Button';
import Text, { Title, P } from '../../components/Text';
import Input, { Label, Required, HelpText } from '../../components/Input';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import EmpyState from '../../components/EmptyState';
import './style.scss';
import RatesList from '../../components/RatesList';
import Dropdown from '../../components/Dropdown';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import WrapperSideBar from '../../components/WrapperSideBar';
import { ContentAdapter, BottomContainer } from '../../components/WrapperSideBar/styledObjects';
import Skeleton from 'react-loading-skeleton';
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from '../../modules/CollapsibleBar/actions';
import {
  NEW_PACKAGE,
  PACKAGE_DETAILS,
  EDIT_ADDRESS,
} from '../../modules/CollapsibleBar/sideBarTypes';
import colors from '../../utils/colors';
import Select from 'react-select';
import './style.css';
import ThreeDotsDropdown from '../../components/Dropdown/ThreeDotsDropdown/index';
import edit_icon from '../../utils/icons/edit.svg';
import delete_icon from '../../utils/icons/delete.svg';
import { showLoader, hideLoader } from '../../modules/loader';
import { showMessage, hideMessage } from '../../modules/message';
import Message from '../../components/Message';
import Dropzone from '../../components/Dropzone';
import { searchPermission } from '../../utils/global_functions';

const NEW_RATE = 'NEW_RATE';
const RATE_DETAILS = 'RATE_DETAILS';
const RATE_EDIT = 'RATE_EDIT';
const IMPORT_CSV = 'IMPORT_CSV';

const loadingSideBarContent = () => (
  <div>
    <ContentAdapter>
      <Row>
        <Column fluid>
          <Text type="sidebarInfoTitle">Paquetería</Text>
          <Skeleton height={20} />
        </Column>
        <Column fluid>
          <Text type="sidebarInfoTitle">Nivel de Servicio</Text>
          <Skeleton height={20} />
        </Column>
        <Column fluid>
          <Text type="sidebarInfoTitle">Términos de duración</Text>
          <Skeleton height={20} />
        </Column>
        <Column xs={12} lg={3} fluid>
          <Text type="sidebarInfoTitle">Peso mínimo</Text>
          <Skeleton height={20} />
        </Column>
        <Column xs={1} fluid />
        <Column xs={12} lg={3} fluid>
          <Text type="sidebarInfoTitle">Peso máximo</Text>
          <Skeleton height={20} />
        </Column>
        <Column xs={1} fluid />

        <Column xs={12} lg={3} fluid>
          <Text type="sidebarInfoTitle">Monto</Text>
          <Skeleton height={20} />
        </Column>
      </Row>
    </ContentAdapter>
  </div>
);

class Rates extends React.Component {
  state = {
    selectedCountryError: '',
    selectedService: [],
    selectedServiceError: '',
    selectedParcel: [],
    selectedParcelError: '',
    selectedCountry: [],
    coberturaValue: '',
    singleDetail: '',
    services: [],
    countrys: ['México', 'Peru', 'Chile'],
    serviceId: 0,
    maxWeight: '',
    maxWeightError: '',
    minWeight: '',
    minWeightError: '',
    amount: '',
    amountError: '',
    setLoading: false,
    selectedCobertura: [],
    selectedCoberturaError: '',
    detailOpen: false,
    isEditing: false,
    importFile: '',
    disabledDrops: false,
    displayBtns: false,
  };

  validateFields() {
    const errorMsgs = {
      required: 'Este campo es requerido',
      max: 'No puede ser mayor al peso máximo',
    };

    let flag = true;
    if (this.state.selectedCountry.length != undefined) {
      flag = false;
      this.setState({ selectedCountryError: errorMsgs.required });
    } else {
      this.setState({ selectedCountryError: '' });
    }
    if (this.state.selectedParcel.length != undefined) {
      flag = false;
      this.setState({ selectedParcelError: errorMsgs.required });
    } else {
      this.setState({ selectedParcelError: '' });
    }
    if (this.state.selectedService.length != undefined) {
      flag = false;
      this.setState({ selectedServiceError: errorMsgs.required });
    } else {
      this.setState({ selectedServiceError: '' });
    }
    if (this.state.selectedCobertura.length != undefined) {
      flag = false;
      this.setState({ selectedCoberturaError: errorMsgs.required });
    } else {
      this.setState({ selectedCoberturaError: '' });
    }
    if (this.state.minWeight == '') {
      flag = false;
      this.setState({ minWeightError: errorMsgs.required });
    } else {
      this.setState({ minWeightError: '' });
    }
    if (this.state.maxWeight == '') {
      flag = false;
      this.setState({ maxWeightError: errorMsgs.required });
    } else {
      this.setState({ maxWeightError: '' });
    }
    if (this.state.amount == '') {
      flag = false;
      this.setState({ amountError: errorMsgs.required });
    } else {
      this.setState({ amountError: '' });
    }
    if (this.state.minWeight != '' && this.state.maxWeight != '') {
      if (parseFloat(this.state.minWeight) > parseFloat(this.state.maxWeight)) {
        flag = false;
        this.setState({ minWeightError: errorMsgs.max });
      } else {
        this.setState({ minWeightError: '' });
      }
    }
    return flag;
  }

  setNewRate() {
    if (this.validateFields()) {
      this.setState({ setLoading: true });
      axios
        .post('api/rates', {
          min_weight: parseFloat(this.state.minWeight),
          max_weight: parseFloat(this.state.maxWeight),
          amount: parseFloat(this.state.amount),
          internal_cost: parseFloat(this.state.amount),
          service_id: this.state.serviceId,
          to_level1_id: this.state.selectedCobertura,
        })
        .then((res) => {
          switch (res.status) {
          case 200:
            this.setState({
              detailOpen: false,
              selectedService: '',
              selectedCobertura: '',
              carriers: '',
              servicesContent: '',
              coberturaValue: '',
              selectedCobertura: [],
              optionsCoverage: '',
              selectedParcel: '',
              selectedCountry: '',
              serviceId: '',
              maxWeight: '',
              minWeight: '',
              amount: '',
            });
            this.setState({ reload: true, setLoading: false });
            this.props.toggleSideBar(false);
            this.setState({ reload: false });
            break;
          default:
            break;
          }
        });
    }
  }

  setUpdateRate() {
    if (this.validateFields()) {
      this.setState({ setLoading: true });
      axios
        .put(`/api/rates/${this.state.singleDetail.rate.object_id}`, {
          min_weight: parseFloat(this.state.minWeight),
          max_weight: parseFloat(this.state.maxWeight),
          amount: parseFloat(this.state.amount),
          internal_cost: parseFloat(this.state.amount),
          service_id: this.state.serviceId,
          to_level1_id: this.state.selectedCobertura,
        })
        .then((res) => {
          switch (res.status) {
          case 200:
            this.setState({
              detailOpen: false,
              selectedService: '',
              selectedCobertura: '',
              carriers: '',
              servicesContent: '',
              optionsCoverage: '',
              selectedParcel: '',
              selectedCountry: '',
              serviceId: '',
              maxWeight: '',
              minWeight: '',
              amount: '',
            });
            this.setState({ reload: true, setLoading: false, isEditing: false });
            this.props.toggleSideBar(false);
            this.setState({ reload: false });
            break;
          default:
            break;
          }
        });
    }
  }

  handleImportButton() {
    this.props.toggleSideBar(true, 'Importar CSV', IMPORT_CSV);
  }

  generate(name) {
    return {
      onChange: (value) => {
        this.handleChange(value, name);
      },
      value: this.state.newCard[name],
      error: this.state.newCard[`${name}Error`],
    };
  }

  renderParcel = () => this.state.carriers.map((type, index) => (
    <Row key={type} className="card-dropdown-option">
      <Column xs={6} fluid>
        <Text>{type}</Text>
      </Column>
    </Row>
  ));

  renderCountrys = () => this.state.countrys.map((type, index) => (
    <Row key={type} className="card-dropdown-option">
      <Column xs={6} fluid>
        <Text>{type}</Text>
      </Column>
    </Row>
  ));

  renderServiceLevel = () => this.state.servicesContent.map((type, index) => (
    <Row key={type} className="card-dropdown-option">
      <Column xs={6} fluid>
        <Text>{type}</Text>
      </Column>
    </Row>
  ));

  handleServiceDrop = (selected) => {
    this.setState({
      selectedService: selected,
      coberturaValue: '',
      selectedCobertura: [],
    });
    const { carriers } = this.state;
    const { servicesContent } = this.state;
    const { selectedParcel } = this.state;
    this.state.services.forEach((element) => {
      if (
        element.level == servicesContent[selected]
        && element.provider == carriers[selectedParcel]
      ) {
        this.setState({ serviceId: element.id });
      }
    });
  };

  handleCoberturaDrop = (selectedOption) => {
    this.setState({ selectedCobertura: selectedOption.value, coberturaValue: selectedOption });
  };

  handleParcelDrop = (selected) => {
    const { carriers } = this.state;
    switch (this.state.selectedCountry) {
    case 0:
      this.getCoverage(carriers[selected], 'MX');
      break;
    case 1:
      this.getCoverage(carriers[selected], 'PE');
      break;
    case 2:
      this.getCoverage(carriers[selected], 'CL');
      break;
    default:
      break;
    }

    const tempServicesContent = [];
    this.state.services.forEach((element) => {
      if (element.provider == carriers[selected]) {
        tempServicesContent.push(element.level);
      }
    });
    this.setState({
      selectedService: '',
      coberturaValue: '',
      selectedCobertura: [],
      selectedParcel: selected,
      servicesContent: tempServicesContent,
    });
  };

  handleCountryDrop = (selected) => {
    let tempContent = [];
    switch (selected) {
    case 0:
      tempContent = ['Chazki', 'Shippify'];
      break;
    case 1:
      tempContent = ['Chazki'];
      break;
    case 2:
      tempContent = ['BlueExpress', 'Shippify'];
      break;
    default:
      break;
    }
    this.setState({
      selectedParcel: '',
      selectedService: '',
      coberturaValue: '',
      selectedCobertura: [],
      selectedCountry: selected,
      carriers: tempContent,
    });
  };

  _onChangeMaxWeight = (value, key) => {
    this.setState({
      maxWeight: value,
    });
  };

  _onChangeMinWeight = (value, key) => {
    this.setState({
      minWeight: value,
    });
  };

  ç;

  _onChangeAmount = (value, key) => {
    this.setState({
      amount: value,
    });
  };

  componentWillMount() {
    this.props.hideMessage();
    this.props.toggleSideBar(false);
    this.getServices();
    if (this.props.user.customer_type == 'enterprise' || this.props.user.customer_type == 'basic' || this.props.user.customer_type == 'subsided_customer') {
      this.setState({ canCreate: true, canDelete: true, canUpdate: true });
    } else {
      this.props.user.permissions.forEach((element) => {
        if (element.resource == 'rates') {
          switch (element.action) {
          case 'create':
            this.setState({ canCreate: true });
            break;
          case 'edit':
            this.setState({ canUpdate: true });
            break;
          case 'delete':
            this.setState({ canDelete: true });
            break;
          default:
            break;
          }
        }
      });
    }
  }

  setValues() {}

  onCloseSidebar = () => {
    this.setState({
      isEditing: false,
      detailOpen: false,
      selectedService: '',
      selectedCobertura: '',
      carriers: '',
      servicesContent: '',
      optionsCoverage: '',
      selectedParcel: '',
      selectedCountry: '',
      serviceId: '',
      maxWeight: '',
      minWeight: '',
      coberturaValue: '',
      selectedCobertura: [],
      amount: '',
    });
  };

  getCoverage(carrier, country) {
    axios.get(`/api/carriers/${carrier}/countries/${country}/coverage`).then((r) => {
      const tempOptions = [];
      r.data.forEach((element) => {
        tempOptions.push({
          value: element.level_1_id,
          label: `${element.level_1} - ${element.level_2}`,
        });
      });
      this.setState({
        optionsCoverage: tempOptions,
      });
    });
  }

  getCoverageEdit(carrier, country, context) {
    axios.get(`/api/carriers/${carrier}/countries/${country}/coverage`).then((r) => {
      const tempOptions = [];
      r.data.forEach((element) => {
        tempOptions.push({
          value: element.level_1_id,
          label: `${element.level_1} - ${element.level_2}`,
        });
      });
      let tempSelectedCobertura;
      const filteredObj = tempOptions.find((item, i) => {
        if (item.value === context.state.singleDetail.rate.to_level_1_id) {
          tempSelectedCobertura = item;
        }
      });
      this.setState({
        optionsCoverage: tempOptions,
        coberturaValue: tempSelectedCobertura,
        disabledDrops: false,
      });
    });
  }

  getServices() {
    axios.get('/api/services').then((r) => {
      const tempServices = [];
      r.data.forEach((element) => {
        if (tempServices.indexOf(element.provider) == -1) {
          tempServices.push(element.provider);
        }
      });
      this.setState({
        singleDetail: r.data,
        sidebarLoading: false,
        services: r.data,
      });
    });
  }

  toggleExportCsv = () => {
    try {
      axios
        .post('/api/rates/export')
        .then((r) => {
          switch (r.status) {
          case 200:
            if (r.statusText == 'Created') {
              this.props.showMessage(
                'success',
                'El archivo esta siendo procesado, se enviara a tu correo cuando este listo',
              );
            } else {
              this.props.showMessage('error', 'Error al procesar archivo, intentalo mas tarde');
            }
            break;
          default:
            this.props.showMessage('error', 'Error al procesar archivo, intentalo mas tarde');
            break;
          }
        })
        .catch((err) => {
          this.props.showMessage('error', 'Error al procesar archivo, intentalo mas tarde');
        });
    } catch (error) {
      this.props.showMessage('error', 'Error al procesar archivo, intentalo mas tarde');
    }
  };

  toggleDeleteRate(context) {
    context.props.showLoader('basic');
    axios.delete(`/api/rates/${context.state.singleDetail.rate.object_id}`).then((r) => {
      context.setState({
        detailOpen: false,
        selectedService: '',
        selectedCobertura: '',
        carriers: '',
        servicesContent: '',
        optionsCoverage: '',
        coberturaValue: '',
        selectedCobertura: [],
        selectedParcel: '',
        selectedCountry: '',
        serviceId: '',
        maxWeight: '',
        minWeight: '',
        amount: '',
      });
      context.setState({ reload: true, setLoading: false });
      context.props.toggleSideBar(false);
      context.setState({ reload: false });
      context.props.hideLoader();
    });
  }

  toggleEditRate(context) {
    this.setState({ detailOpen: false, disabledDrops: true });
    const mapCountries = ['MX', 'PE', 'CL'];
    const mapCurrencies = {
      MXN: 0,
      PEN: 1,
      CLP: 2,
    };
    const tempSelectedCountry = mapCurrencies[this.state.singleDetail.rate.currency];

    const mapContent = [
      ['Chazki', 'Shippify'], // MX
      ['Chazki'], // PE
      ['BlueExpress', 'Shippify'], // CL
    ];
    const tempContent = mapContent[tempSelectedCountry];

    const tempSelectedParcel = tempContent.indexOf(this.state.singleDetail.rate.provider);

    this.setState({
      carriers: tempContent,
    });

    this.getCoverageEdit(tempContent[tempSelectedParcel], mapCountries[tempSelectedCountry], this);

    const tempServicesContent = [];
    this.state.services.forEach((element) => {
      if (element.provider == tempContent[tempSelectedParcel]) {
        tempServicesContent.push(element.level);
      }
    });
    this.setState({
      servicesContent: tempServicesContent,
    });
    const tempSelectedService = tempServicesContent.indexOf(
      this.state.singleDetail.rate.servicelevel,
    );
    this.state.services.forEach((element) => {
      if (
        element.level == tempServicesContent[tempSelectedService]
        && element.provider == tempContent[tempSelectedParcel]
      ) {
        this.setState({ serviceId: element.id });
      }
    });
    this.setState({
      isEditing: true,
      selectedService: tempSelectedService,
      selectedCobertura: this.state.singleDetail.rate.to_level_1_id,
      selectedParcel: tempSelectedParcel,
      selectedCountry: tempSelectedCountry,
      maxWeight: this.state.singleDetail.rate.max_weight,
      minWeight: this.state.singleDetail.rate.min_weight,
      amount: this.state.singleDetail.rate.amount,
    });
    this.props.toggleSideBar(true, `Tarifa ${this.state.singleDetail.rate.object_id}`, RATE_EDIT);
  }

  toggleImportFile() {
    if (this.state.importFile != '') {
      this.setState({ setLoading: true });
      let context;
      const url = '/api/rates/import';
      const formData = new FormData();
      formData.append('file', this.state.importFile);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios.post(url, formData, config).then((r) => {
        if (r.statusText == 'Created') {
          this.props.showMessage(
            'success',
            `Creadas ${r.data.created} Fallidas ${r.data.failed} Procesadas ${r.data.processed} `,
          );
          this.props.toggleSideBar(false);
          this.setState({ setLoading: false, importFile: '', reload: true });

          this.setState({ reload: false });
        } else {
          this.props.showMessage('error', 'Error al procesar el archivo');
        }
      });
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      this.setState({ importFile: acceptedFiles[0] });
    }
  };

  _renderSidebarTitleExtraContent = () => {
    const {
      customer_type, plan, actions, role, operational_user,
    } = this.props.user;
  
    const permissions = [];
    if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
      operational_user.operational_role.actions.map(element => permissions.push(element));
    }
  
    if (plan && plan.features) {
      plan.features.map(element => permissions.push(element));
    }
    if (actions) {
      actions.map(element => permissions.push(element));
    }
    const addressOptions = [];
    if (searchPermission('tarifas', 'editar', permissions)) {
      addressOptions.push({
        function: () => this.toggleEditRate(this),
        label: 'Editar tarifa',
        image: edit_icon,
      });
    }
    if (searchPermission('tarifas', 'eliminar', permissions)) {
      addressOptions.push({
        function: () => this.toggleDeleteRate(this),
        label: 'Eliminar tarifa',
        image: delete_icon,
      });
    }
    if (this.state.detailOpen) {
      return <ThreeDotsDropdown linksToRender={addressOptions} />;
    }
    return <div />;
  };

  _renderSidebarDetailsContent = data => (
    <div>
      <ContentAdapter className="detailed-package">
        <Row>
          <Column fluid>
            <Text type="sidebarInfoTitle">Paquetería</Text>
            <P>{this.state.singleDetail.rate.provider}</P>
          </Column>
          <Column fluid>
            <Text type="sidebarInfoTitle">Nivel de Servicio</Text>
            <P>{this.state.singleDetail.rate.servicelevel}</P>
          </Column>
          <Column fluid>
            <Text type="sidebarInfoTitle">Términos de duración</Text>
            <P>{this.state.singleDetail.rate.duration_terms}</P>
          </Column>
          <Column xs={12} lg={3} fluid>
            <Text type="sidebarInfoTitle">Peso Mínimo</Text>
            <P>
              {this.state.singleDetail.rate.min_weight}
              {' '}
kg
            </P>
          </Column>
          <Column xs={12} lg={1} fluid />
          <Column xs={12} lg={3} fluid>
            <Text type="sidebarInfoTitle">Peso Máximo</Text>
            <P>
              {this.state.singleDetail.rate.max_weight}
              {' '}
kg
            </P>
          </Column>
          <Column xs={12} lg={1} fluid />
          <Column xs={12} lg={3} fluid>
            <Text type="sidebarInfoTitle">Monto</Text>
            <P>{`$${parseFloat(this.state.singleDetail.rate.amount).toFixed(2)}`}</P>
          </Column>
        </Row>
      </ContentAdapter>
    </div>
  );

  renderSideBarImport = () => (
    <div>
      <ContentAdapter className="top-space">
        <Dropzone
          className="drop"
          required
          label="Selecciona tu archivo"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          imageSrc={this.state.logo_url}
          onDrop={this.onDrop}
          onDelete={this.onDelete}
        />

        <BottomContainer>
          <Row divisions={20}>
            <Column style={{ textAlign: 'right' }} fluid>
              <Button
                loading={this.state.setLoading}
                onClick={() => this.toggleImportFile()}
                className=""
              >
                  Guardar
              </Button>
            </Column>
            <Column xs={1} xsShift={1} style={{ textAlign: 'right' }} fluid />
          </Row>
        </BottomContainer>
      </ContentAdapter>
    </div>
  );

  renderSidebarContent = () => {
    const mainProps = {
      generate: name => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value: this.state[name],
        error: this.state[`${name}Error`],
      }),
    };
    const ratesFields = (
      <div>
        <Dropdown
          disabled={this.state.disabledDrops}
          label="País"
          required
          error={this.state.selectedCountryError}
          options={this.renderCountrys()}
          style={{ marginTop: '12px' }}
          selected={this.state.selectedCountry}
          handleChange={this.handleCountryDrop}
          placeholder={(
            <Text type="p" style={{ color: colors.gray.placeholder, fontSize: '14px' }}>
              País
            </Text>
          )}
        />
        {this.state.selectedCountry >= 0 && this.state.carriers && (
          <div>
            <Dropdown
              disabled={this.state.disabledDrops}
              label="Paquetería"
              error={this.state.selectedParcelError}
              required
              down
              options={this.renderParcel()}
              style={{ marginTop: '12px' }}
              selected={this.state.selectedParcel}
              handleChange={this.handleParcelDrop}
              placeholder={(
                <Text type="p" style={{ color: colors.gray.placeholder, fontSize: '14px' }}>
                  Paquetería
                </Text>
              )}
            />
          </div>
        )}
        {this.state.selectedParcel >= 0 && this.state.servicesContent && (
          <div>
            <Dropdown
              disabled={this.state.disabledDrops}
              label="Nivel de Servicio"
              error={this.state.selectedServiceError}
              required
              down
              options={this.renderServiceLevel()}
              style={{ marginTop: '12px' }}
              selected={this.state.selectedService}
              handleChange={this.handleServiceDrop}
              placeholder={(
                <Text type="p" style={{ color: colors.gray.placeholder, fontSize: '14px' }}>
                  Nivel de Servicio
                </Text>
              )}
            />
          </div>
        )}

        <Row>
          <Column xs={4} fluid>
            <Input
              error={this.state.minWeightError}
              value={this.state.minWeight}
              onChange={this._onChangeMinWeight}
              suffix="kg"
              type="text"
              required
              placeholder="100.0"
              label="Peso Mínimo"
            />
          </Column>
          <Column xs={4} fluid>
            <Input
              error={this.state.maxWeightError}
              value={this.state.maxWeight}
              onChange={this._onChangeMaxWeight}
              suffix="kg"
              type="text"
              required
              placeholder="100.0"
              label="Peso Máximo"
            />
          </Column>
          <Column xs={4} fluid style={{ paddingLeft: '16px' }}>
            <Input
              error={this.state.amountError}
              value={this.state.amount}
              onChange={this._onChangeAmount}
              prefix="$"
              type="text"
              required
              placeholder="200.15"
              label="Monto"
            />
          </Column>
        </Row>
        {this.state.selectedParcel >= 0 && this.state.optionsCoverage ? (
          <div>
            <Label style={{ marginTop: '16px' }}>
              Cobertura
              {' '}
              <Required />
            </Label>
            <Select
              value={this.state.coberturaValue}
              onChange={this.handleCoberturaDrop}
              noOptionsMessage={() => 'Sin Opciones'}
              isSearchable
              placeholder="Selecciona la Cobertura"
              options={this.state.optionsCoverage}
              className="search-select"
            />
            {this.state.selectedCoberturaError != '' && (
              <HelpText>Este campo es requerido</HelpText>
            )}
          </div>
        ) : (
          <div>
            <Label style={{ marginTop: '16px' }}>
              Cobertura
              {' '}
              <Required />
            </Label>
            <Skeleton height={20} />
          </div>
        )}
      </div>
    );
    return (
      <div>
        <ContentAdapter>
          {ratesFields}

          <BottomContainer>
            <Row divisions={20}>
              <Column style={{ textAlign: 'right' }} fluid>
                <Button
                  loading={this.state.setLoading}
                  onClick={() => {
                    this.state.isEditing ? this.setUpdateRate() : this.setNewRate();
                  }}
                  className=""
                >
                  Guardar
                </Button>
              </Column>
              <Column xs={1} xsShift={1} style={{ textAlign: 'right' }} fluid />
            </Row>
          </BottomContainer>
        </ContentAdapter>
      </div>
    );
  };

  openSideBar() {
    this.props.toggleSideBar(true, 'Crear nueva tarifa', NEW_RATE);
  }

  changeStatusBtns() {
    this.setState({ displayBtns: true });
  }

  openSideBarDetails(id, context) {
    context.setState({
      sidebarLoading: true,
    });
    axios.get(`/api/rates/${id}`).then((r) => {
      context.setState({
        singleDetail: r.data,
        sidebarLoading: false,
        detailOpen: true,
      });
    });
    context.props.toggleSideBar(true, `Tarifa ${id}`, RATE_DETAILS);
  }

  _renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
    case NEW_RATE:
      return <div>{this.renderSidebarContent()}</div>;
    case RATE_DETAILS:
      return (
        <div>
          {this.state.sidebarLoading ? (
            <div>{loadingSideBarContent()}</div>
          ) : (
            <div>{this._renderSidebarDetailsContent()}</div>
          )}
        </div>
      );
    case RATE_EDIT:
      return <div>{this.renderSidebarContent()}</div>;
    case IMPORT_CSV:
      return <div>{this.renderSideBarImport()}</div>;
    default:
      return <div />;
    }
  };

  render() {
    const {
      customer_type, plan, actions, role, operational_user,
    } = this.props.user;
  
    const permissions = [];
    if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
      operational_user.operational_role.actions.map(element => permissions.push(element));
    }
  
    if (plan && plan.features) {
      plan.features.map(element => permissions.push(element));
    }
    if (actions) {
      actions.map(element => permissions.push(element));
    }
    return (
      <div>
        <WrapperSideBar
          handleClose={this.onCloseSidebar}
          title_more={this._renderSidebarTitleExtraContent()}
        >
          {this.state.sideBarContent}
          {this._renderSidebarContent()}
        </WrapperSideBar>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: 'right' }} fluid>
            <Menu />
          </Column>
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Header>
              <Row divisions={18}>
                <Column xs={4} fluid>
                  <Title type="titleCard">Tarifas</Title>
                </Column>
                {this.state.displayBtns && (
                  <Column
                    xs={14}
                    xsShift={0}
                    style={{
                      textAlign: 'right',
                    }}
                    fluid
                  >
                    {searchPermission('tarifas', 'editar', permissions) && (
                      <Button
                        type="secondary"
                        className="quote-btn"
                        onClick={() => this.handleImportButton()}
                        initialLoader
                      >
                        Importar CSV
                      </Button>
                    )}

                    <Button
                      type="secondary"
                      className="quote-btn"
                      onClick={() => this.toggleExportCsv()}
                      initialLoader
                    >
                      Exportar CSV
                    </Button>
                    {searchPermission('tarifas', 'editar', permissions) && (
                      <Button className="header-btn last" onClick={() => this.openSideBar()}>
                        Agregar tarifas
                      </Button>
                    )}
                  </Column>
                )}
              </Row>
            </Header>
            <Row divisions={20}>
              <Column xs={1} fluid />
              <Column xs={18} fluid>
                {this.props.message.show && (
                  <Message color={this.props.message.messageType} onClose={this.props.hideMessage}>
                    {this.props.message.text}
                  </Message>
                )}
              </Column>
              <Column xs={1} fluid />
            </Row>
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid />
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <RatesList
              changeStatusBtns={() => this.changeStatusBtns()}
              addRate={() => this.openSideBar()}
              reload={this.state.reload}
              context={this}
              openSideBar={this.openSideBarDetails}
            />
          </Column>
          <Column xs={1} fluid />
        </Row>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  sidebar: state.sidebar,
  message: state.message,
  user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    toggleSideBar,
    changeSidebarTitle,
    showMessage,
    hideMessage,
    showLoader,
    hideLoader,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Rates);
