import React from "react";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styledProps from "styled-props";
// import PropTypes from 'prop-types';
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import colors from "../../utils/colors";
import Text from "../Text";
import Card from "../Card";
import Logo from "../static/Logo";
import Icon from "../static/Icon";
import "./index.scss";
import {
  searchPermission,
  removeDuplicates,
} from "../../utils/global_functions";

const Background = styled.div`
  @media screen and (max-width: 39.9375em) {
    background: ${colors.gray.solid_black};
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    max-width: 1440px;
    margin: 0 auto;
    right: 0;
    min-height: 100%;
    z-index: -10;
    transition: 200ms;

    &.mobile-menu-open {
      z-index: 1000;
      opacity: 0.5;
    }
  }
`;

const CloseIcon = styled.i`
  position: absolute;
  margin: 12px;
  right: 0;
  top: 0;
`;

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  img {
    margin-right: 16px;
    margin-bottom: -5px;
  }
`;

const background = {
  default: colors.secondary.white,
  selected: colors.state.light_pink,
};

const Li = styled.li`
  position: relative;
  background: ${styledProps(background, "type")};
  padding: 9px;
  box-sizing: border-box;
  height: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  border-radius: 4px
  &:hover {
    background: ${colors.state.light_pink};
  }
  p {
    margin: 0;
  }
`;

Li.defaultProps = {
  type: "default",
};

const Sync = styled.div`
  background: ${colors.primary.mienvio_pink};
  height: 9px;
  width: 9px;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 100px;
`;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.getList = this.getList.bind(this);
  }

  handleChange(e) { }

  navigate(name) {
    switch (name) {
      case "integrations":
        this.props.goToIntegrations();
        break;
      case "shipments":
        this.props.goToShipments();
        break;
      case "reports":
        this.props.goToReports();
        break;
      case "shipmentsReady":
        this.props.goToShipmentsReady();
        break;
      case "packages":
        this.props.goToPackages();
        break;
      case "invoices":
        this.props.goToInvoices();
        break;
      case "addresses":
        this.props.goToAddresses();
        break;
      case "rates":
        this.props.goToRates();
        break;
      case "refered":
        this.props.goToRefered();
        break;
      case "accounts":
        this.props.goToAccounts();
        break;
      case "quotations":
        this.props.goToQuotations();
        break;
      case "status-report":
        this.props.goToStatusReport();
        break;
      case "payment":
        this.props.goToPaymentMethods();
        break;
      case "admin":
        this.props.goToAccountsAdmin();
        break;
      case "automatizations":
        this.props.goToAutomatizations();
        break;
      case "plan":
        this.props.goToPlan();
        break;
      case "settings":
        this.props.goToProfile();
        break;
      default:
        this.props.goToDashboard();
        break;
    }
  }

  search(namePermission, actionPermission, Permissions) {
    for (let i = 0; i < Permissions.length; i++) {
      if (
        Permissions[i].name === namePermission &&
        Permissions[i].value === actionPermission
      ) {
        return Permissions[i];
      }
    }
  }

  getList() {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }
    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    let elementNames = [];

    if (role === "master" || role === "manager") {
      elementNames.push({
        name: "Admin de Cuentas",
        icon: "admin",
      });
    } else if (permissions) {
      permissions.forEach((element) => {
        switch (element.name) {
          case "panel_indicadores":
            if (element.value === "leer") {
              elementNames.push({
                name: "Dashboard",
                icon: "dashboard",
              });
            }
            break;
          case "tarifas":
            if (element.value === "leer") {
              elementNames.push({
                name: "Tarifas",
                icon: "rates",
              });
            }
            break;
          case "automatizaciones":
            if (element.value === "leer") {
              elementNames.push({
                name: "Automatizaciones",
                icon: "automatizations",
              });
            }
            break;
          case "envios":
            if (element.value === "leer") {
              elementNames.push({
                name: "Envíos",
                icon: "shipments",
              });

              elementNames.push({
                name: "Reportes",
                icon: "reports",
              });
            }
            break;
          case "marketplaces":
            if (element.value === "leer") {
              elementNames.push({
                name: "Integraciones",
                icon: "integrations",
              });
            }
            break;
          case "empaques":
            if (element.value === "leer") {
              elementNames.push({
                name: "Empaques",
                icon: "packages",
              });
            }
            break;
          case "facturas":
            if (element.value === "leer") {
              elementNames.push({
                name: "Facturas",
                icon: "invoices",
              });
            }
            break;
          case "direcciones":
            if (element.value === "leer") {
              elementNames.push({
                name: "Direcciones",
                icon: "addresses",
              });
            }
            break;
          case "tarjetas":
            if (element.value === "leer") {
              try {
                if (this.props.user && this.props.user.methodPayments) {
                  if (this.props.user.methodPayments.indexOf("none") > -1) {
                  } else {
                    elementNames.push({
                      name: "Métodos de pago",
                      icon: "payment",
                    });
                  }
                }
              } catch (error) {
                elementNames.push({
                  name: "Métodos de pago",
                  icon: "payment",
                });
              }
            }
            break;
          case "cotizaciones":
            if (element.value === "leer") {
              elementNames.push({
                name: "Cotizaciones",
                icon: "quotations",
              });
            }
            break;
          case "cuentas_roles" || "cuentas_cuentas":
            if (element.value === "leer") {
              elementNames.push({
                name: "Cuentas",
                icon: "accounts",
              });
            }
            break;
          case "regiones":
            if (element.value === "leer") {
              elementNames.push({
                name: "Admin de Cuentas",
                icon: "admin",
              });
            }
            break;
          case "gerentes":
            if (element.value === "leer") {
              elementNames.push({
                name: "Admin de Cuentas",
                icon: "admin",
              });
            }
            break;
          case "cuentas":
            if (element.value === "leer") {
              elementNames.push({
                name: "Admin de Cuentas",
                icon: "admin",
              });
            }
            break;
          case "roles":
            if (element.value === "leer") {
              elementNames.push({
                name: "Admin de Cuentas",
                icon: "admin",
              });
            }
            break;
          case "subcuentas":
            if (element.value === "leer") {
              elementNames.push({
                name: "Admin de Cuentas",
                icon: "admin",
              });
            }
            break;
          default:
            break;
        }
      });

      // elementNames.push({
      //   name: "Plan",
      //   icon: "plan",
      // });
      elementNames.push({
        name: "Configuración",
        icon: "settings",
      });
    }

    elementNames = removeDuplicates(elementNames, "name");
    // Referidos comentado temporalmente
    /* elementNames.push({
      name: 'Referidos',
      icon: 'refered',
    }); */

    if (customer_type) {
      return elementNames.map((el, index) => (
        <Li
          onClick={() => {
            this.navigate(el.icon);
            this.props.onClose();
          }}
          key={index}
          type={this.props.selected === el.icon ? "selected" : "default"}
        >
          <Icon
            name={
              this.props.selected === el.icon ? `${el.icon}Active` : el.icon
            }
          />
          <Text
            type={
              this.props.selected === el.icon
                ? "textSidebarLinkSelected"
                : "textSidebarLink"
            }
          >
            {el.name}
          </Text>
          {this.props.sync && el.icon === "dashboard" ? <Sync /> : null}
        </Li>
      ));
    }
    return (
      <div>
        <Li>
          <Skeleton />
        </Li>
        <Li>
          <Skeleton />
        </Li>
        <Li>
          <Skeleton />
        </Li>
        <Li>
          <Skeleton />
        </Li>
        <Li>
          <Skeleton />
        </Li>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Background
          onClick={this.props.onClose}
          className={`${this.props.isMenuMobileOpen && "mobile-menu-open"} `}
        />
        <div
          className={`${this.props.isMenuMobileOpen && "mobile-menu-open"
            } sidebar`}
        >
          <CloseIcon
            onClick={this.props.onClose}
            className="ion-android-close"
          />
          <Logo
            className="sidebar-logo-container"
            plan={this.props.user.ratesbracket}
          />
          <Ul>
            {/* <Li>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: colors.primary.mienvio_pink,
                  userSelect: "none",
                }}
              >
                MODE: SANDBOX
              </p>
            </Li> */}
            {this.getList()}
          </Ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  // isPrime: state.user.ratesbrackets_id,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
