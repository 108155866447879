import { initialState } from './initialState';
import {
  TOGGLE_QUICK_QUOTE,
  SET_ERROR_QUICK_QUOTE,
  SET_VALUE_QUICK_QUOTE,
  UNSET_ERROR_QUICK_QUOTE,
  CLEAR_QUICK_QUOTE,
  CHANGE_LOADING_QUICK_QUOTE
} from './actionsTypes';

export default (state = initialState, action) => {
  let belongsTo, key, open, title, name, value, field, error;
  switch (action.type) {
    case TOGGLE_QUICK_QUOTE:
      open = action.payload.open;
      title = action.payload.title;
      return {
        ...state,
        open,
        title
      };
    case SET_VALUE_QUICK_QUOTE:
      name = action.payload.name;
      value = action.payload.value;
      belongsTo = name.split('_')[0];
      key = name.split('_')[1];
      return {
        ...state,
        [belongsTo]: {
          ...state[belongsTo],
          [key]: value
        }
      };
    case SET_ERROR_QUICK_QUOTE:
      field = action.payload.field;
      error = action.payload.error;
      belongsTo = field.split('_')[0];
      key = field.split('_')[1];
      return {
        ...state,
        [belongsTo]: {
          ...state[belongsTo],
          [`${key}Error`]: error
        }
      };
    case UNSET_ERROR_QUICK_QUOTE:
      field = action.payload.field;
      belongsTo = field.split('_')[0];
      key = field.split('_')[1];
      return {
        ...state,
        [belongsTo]: {
          ...state[belongsTo],
          [`${key}Error`]: ''
        }
      };
    case CLEAR_QUICK_QUOTE:
      return initialState;
    case CHANGE_LOADING_QUICK_QUOTE:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};
