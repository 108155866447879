import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import { push } from "react-router-redux";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import { formatPhone } from "../../utils/global_functions";
import Header from "../Header";
import Text, { Title } from "../Text";
import Message from "../Message";
import Button from "../Button";
import Card, { Divider } from "../Card";
import Input from "../Input";
import Dropdown from "../Dropdown";
import { Checkbox, CheckboxGroup } from "../Checkbox";
import { editMonthlyInvoice, authorizeRFC } from "../../modules/user";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { cancelPlan } from "../../modules/plans";
import { toggle as toggleSideBar } from "../../modules/CollapsibleBar/actions";
import {
  ACCOUNT_CONFIGURATION,
  PASSWORD_CONFIGURATION,
  INVOICE_CONFIGURATION,
  SHIPMENTS_RESPONSABLE,
} from "../../modules/CollapsibleBar/sideBarTypes";
import WrapperSideBar from "../WrapperSideBar";
import { ContentAdapter } from "../WrapperSideBar/styledObjects";
import AccountInformation from "./accountInformation";
import { hideMessage } from "../../modules/message";
import Modal2 from "../Modal2";
import DuplicateRfcForm from "./DuplicateRfcForm";

class ProfileInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      passwordNew: "",
      passwordConfirmation: "",
      company_name: "",
      rfc: "",
      street: "",
      street2: "",
      zipcode: "",
      phone: "",
      isModalOpen: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentWillMount() {
    this.props.toggleSideBar(false);
  }

  prettyPhone(phone) {
    const mask = "(XXX) XXX XX XX";
    const s = `${phone}`;
    let r = "";
    for (let i = 0, is = 0; i < mask.length && is < s.length; i++) {
      r += mask.charAt(i) === "X" ? s.charAt(is++) : mask.charAt(i);
    }
    return r;
  }

  toggleSideBar = (selected) => {
    let title;
    switch (selected) {
      case ACCOUNT_CONFIGURATION:
        title = "Cuenta";
        break;
      case PASSWORD_CONFIGURATION:
        title = "Contraseña";
        break;
      case INVOICE_CONFIGURATION:
        title = "Información fiscal";
        break;
      default:
        break;
    }
    this.props.toggleSideBar(true, title, selected);
  };

  cancelPlan() {
    Swal.fire({
      title: "Cancelar Plan!",
      text: "Tu plan Cambiara a Free hasta que Termine tu plan Actual",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.props.cancelPlan();
      }
    });
  }

  toggleModal() {
    this.setState((state) => {
      return { isModalOpen: !state.isModalOpen };
    });
  }

  render() {
    if (
      this.props.message.show &&
      (this.props.message.messageType === "error" ||
        this.props.message.messageType === "success")
    ) {
      setTimeout(this.props.hideMessage, 5000);
    }

    return (
      <div>
        <WrapperSideBar>
          <ContentAdapter className="create-package-form">
            <AccountInformation
              user={this.props.user}
              toggleModal={this.toggleModal}
            />
          </ContentAdapter>
        </WrapperSideBar>
        <Modal2
          isOpen={this.state.isModalOpen}
          title={"Aprobación de RFC"}
          toggleModal={this.toggleModal}
        >
          <DuplicateRfcForm
            user={{
              name: this.props.user.fiscal_address
                ? this.props.user.fiscal_address.name
                : "",
              phone: this.props.user.fiscal_address
                ? this.props.user.fiscal_address.phone
                : "",
              email: this.props.user.email,
            }}
            authorizeRfc={this.props.authorizeRFC}
            toogleModal={this.toggleModal}
          />
        </Modal2>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={13} fluid>
            <Header>
              <Row divisions={13}>
                <Column fluid>
                  <Title type="titleCard">Configuración</Title>
                </Column>
              </Row>
            </Header>
          </Column>
          <Column xs={6} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={9} fluid>
            {this.props.message.show ? (
              <Message
                color={this.props.message.messageType}
                onClose={this.props.hideMessage}
              >
                {this.props.message.text}
              </Message>
            ) : null}
          </Column>
          <Column xs={10} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={9} fluid>
            <Card size="full" className="table-card">
              <Row>
                <Column fluid>
                  <Text type="label">Cuenta</Text>
                  <Button
                    type="link"
                    className="edit-btn-profile"
                    onClick={() => this.toggleSideBar(ACCOUNT_CONFIGURATION)}
                  >
                    Editar
                  </Button>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid>
                  <Text type="labelAuxiliar">Nombre completo</Text>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid>
                  <Text>
                    {this.props.user.object_id
                      ? `${this.props.user.first_name} ${this.props.user.last_name}`
                      : ""}
                  </Text>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid>
                  <Text type="labelAuxiliar">Correo electrónico</Text>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid>
                  <Text>
                    {this.props.user.object_id
                      ? `${this.props.user.email}`
                      : ""}
                  </Text>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid>
                  <Text type="labelAuxiliar">Teléfono</Text>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid>
                  <Text>
                    {this.props.user.object_id
                      ? `${formatPhone(this.props.user.phone)}`
                      : ""}
                  </Text>
                </Column>
              </Row>
              <Divider />
              {/* <Row className="detail-row">
                <Column fluid>
                  <Text type="labelAuxiliar">Plan</Text>

                  {this.props.user.plan &&
                  this.props.user.plan.name != "free" ? (
                    <Button
                      type="link"
                      className="edit-btn-profile"
                      onClick={() => this.cancelPlan()}
                    >
                      Dar de Baja Plan
                    </Button>
                  ) : null}
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid>
                  <Text>
                    {this.props.user.plan
                      ? this.props.user.plan.name === "free"
                        ? "Free"
                        : this.props.user.plan.name === "advance"
                        ? "Advance"
                        : this.props.user.plan.name === "professional"
                        ? "Professional"
                        : this.props.user.plan.name === "enterprise"
                        ? "Enterprise"
                        : "Entry"
                      : this.props.user.plan_type}
                  </Text>

                  this.props.user.plan.name != 'free' ? (



                           <div>
                            <Row className="detail-row">
                              <Column fluid>
                                <Text type="labelAuxiliar">Proxima fecha de Facturacion: </Text>
                              </Column>
                            </Row>
                            <Row className="detail-row">
                              <Column fluid>
                                <Moment date={this.props.user.currentSubscription[0] ? this.props.user.currentSubscription[0].end_date : null} local="es" format="DD-MM-YYYY" />
                              </Column>
                            </Row>
                          </div>




                    ) : null

                 
                </Column>
              </Row>
              <Divider /> */}
              <Row className="detail-row">
                <Column fluid>
                  <Text type="labelAuxiliar">Contraseña</Text>
                  <Button
                    type="link"
                    className="edit-btn-profile"
                    onClick={() => this.toggleSideBar(PASSWORD_CONFIGURATION)}
                  >
                    Editar
                  </Button>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid>
                  <Text>••••••••••</Text>
                </Column>
              </Row>
              <Divider />
              <Row>
                <Column fluid>
                  <Text type="label">Información fiscal</Text>
                  <Button
                    type="link"
                    className="edit-btn-profile"
                    onClick={() => this.toggleSideBar(INVOICE_CONFIGURATION)}
                  >
                    {this.props.user.fiscal_address ? "Editar" : "Agregar"}
                  </Button>
                </Column>
              </Row>
              {this.props.user.fiscal_address ? (
                <div>
                  <Row className="detail-row">
                    <Column fluid>
                      <Text type="labelAuxiliar">Titular</Text>
                    </Column>
                  </Row>
                  <Row className="detail-row">
                    <Column fluid>
                      <Text>{this.props.user.fiscal_address.name}</Text>
                    </Column>
                  </Row>
                  <Row className="detail-row">
                    <Column fluid>
                      <Text type="labelAuxiliar">RFC</Text>
                    </Column>
                  </Row>
                  <Row className="detail-row">
                    <Column fluid>
                      <Text>{this.props.user.fiscal_address.rfc}</Text>
                    </Column>
                  </Row>
                  <Row className="detail-row">
                    <Column fluid>
                      <Text type="labelAuxiliar">Dirección fiscal</Text>
                    </Column>
                  </Row>
                  <Row className="detail-row">
                    <Column fluid>
                      <div>
                        <Text>{this.props.user.fiscal_address.street}</Text>
                      </div>
                      <div>
                        <Text>{this.props.user.fiscal_address.street2}</Text>
                      </div>
                      <div>
                        <Text>
                          {" "}
                          {this.props.user.fiscal_address.city},{" "}
                          {this.props.user.fiscal_address.state}
                        </Text>
                      </div>
                      <div>
                        <Text>
                          C.P.
                          {this.props.user.fiscal_address.zipcode}
                        </Text>
                      </div>
                    </Column>
                  </Row>
                  <Row className="detail-row">
                    <Column fluid>
                      <Text type="labelAuxiliar">Teléfono</Text>
                    </Column>
                  </Row>
                  <Row className="detail-row">
                    <Column fluid>
                      <Text>{this.props.user.fiscal_address.phone}</Text>
                    </Column>
                  </Row>
                </div>
              ) : null}
              <Divider />
              <Row className="detail-row">
                <Column fluid>
                  <Text type="label">Responsable de envíos</Text>
                  <Button
                    type="link"
                    className="edit-btn-profile"
                    onClick={() => this.toggleSideBar(SHIPMENTS_RESPONSABLE)}
                  >
                    {this.props.user.shipments_responsable
                      ? "Editar"
                      : "Agregar"}
                  </Button>
                  {this.props.user.shipments_responsable && (
                    <Column fluid>
                      <Row className="detail-row">
                        <Column fluid>
                          <Text type="labelAuxiliar">Nombre completo</Text>
                        </Column>
                      </Row>
                      <Row className="detail-row">
                        <Column fluid>
                          <Text>
                            {this.props.user.shipments_responsable.name}
                          </Text>
                        </Column>
                      </Row>
                      <Row className="detail-row">
                        <Column fluid>
                          <Text type="labelAuxiliar">Correo electrónico</Text>
                        </Column>
                      </Row>
                      <Row className="detail-row">
                        <Column fluid>
                          <Text>
                            {this.props.user.shipments_responsable.email}
                          </Text>
                        </Column>
                      </Row>
                      <Row className="detail-row">
                        <Column fluid>
                          <Text type="labelAuxiliar">Teléfono</Text>
                        </Column>
                      </Row>
                      <Row className="detail-row">
                        <Column fluid>
                          <Text>
                            {formatPhone(
                              this.props.user.shipments_responsable.phone
                            )}
                          </Text>
                        </Column>
                      </Row>
                    </Column>
                  )}
                </Column>
              </Row>
            </Card>
          </Column>
          <Column xs={10} fluid />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  message: state.message,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToEditProfile: () => push("/perfil/editar/contacto"),
      goToEditPassword: () => push("/perfil/editar/contrasena"),
      goToEditFiscal: () => push("/perfil/editar/fiscal"),
      // goToCard: id => push(`/metodos-pago/${id}`)
      editMonthlyInvoice,
      cancelPlan,
      toggleSideBar,
      hideMessage,
      authorizeRFC,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
