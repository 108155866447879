import React, { useEffect, useRef, useState } from "react";
import Input from "../Input";
import Button from "../Button";
import Text from "../Text";
import Dropzone from "../Dropzone";

import { getBase64 } from "../../utils/global_functions";

const DuplicateRfcForm = (props) => {
  const didMountRef = useRef(false);
  const [name, setName] = useState(props.user.name);
  const [phone, setPhone] = useState(props.user.phone);
  const [email, setEmail] = useState(props.user.email);
  const [rfc, setRfc] = useState("");
  const [reasonToDuplicate, setReasonToDuplicate] = useState("");
  const [cedula, setCedula] = useState(null);

  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [rfcError, setRfcError] = useState("");
  const [reasonToDuplicateError, setReasonToDuplicateError] = useState("");

  let canSubmit = false;

  useEffect(() => {
    if (didMountRef.current) {
      if (
        nameError === "" &&
        phoneError === "" &&
        emailError === "" &&
        rfcError === "" &&
        reasonToDuplicateError === "" &&
        cedula !== null
      ) {
        canSubmit = true;
      } else {
        canSubmit = false;
      }
    } else {
      didMountRef.current = true;
    }
  }, [
    nameError,
    phoneError,
    emailError,
    rfcError,
    reasonToDuplicateError,
    cedula,
  ]);

  /* Vslidators */
  const isRequired = (value) => value !== "";
  const shouldBeLetter = (value) =>
    !/[~`¡!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\¿?]/g.test(value) &&
    !/\d/g.test(value);

  const isEmail = (value) =>
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    );

  const isUrl = (value) =>
    /^((https|http)(:\/\/))?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(
      value
    );

  const isMax = (value, max) => value.length > max;

  const hasLength = (value, number) => value.length === number;

  const isSameOrLessThan = (value, number) => value.length <= number;

  const isSameOrMoreThan = (value, number) => value.length >= number;

  const nameIsValid = (firstName) => {
    if (!isRequired(firstName)) {
      return "Favor de llenar campo";
    }
    return "";
  };

  const emailIsValid = (email) => {
    if (!isRequired(email)) {
      return "Favor de llenar campo";
    } else if (!isEmail(email)) {
      return "Correo electrónico inválido";
    }
    return "";
  };

  const rfcIsValid = (rfc) => {
    if (!isRequired(rfc)) {
      return "Favor de llenar campo";
    } else if (!hasLength(rfc, 13)) {
      return "Deben ser 13 caracteres";
    }

    return "";
  };

  const phoneIsValid = (phone) => {
    if (!isRequired(phone)) {
      return "Favor de llenar campo";
    } else if (!isSameOrMoreThan(phone, 10)) {
      return "Número de teléfono inválido";
    }

    return "";
  };

  const reasonToDuplicateIsValid = (reasonTo) => {
    if (!isRequired(reasonTo)) {
      return "Favor de llenar campo";
    }

    return "";
  };

  const handleNameChange = (value) => {
    setName(value);
    setNameError(nameIsValid(value));
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    setPhoneError(phoneIsValid(value));
  };

  const handleEmailchange = (value) => {
    setEmail(value);
    setEmailError(emailIsValid(value));
  };

  const handleRfcChange = (value) => {
    setRfc(value);
    setRfcError(rfcIsValid(value));
  };

  const handleReasonToDuplicateChange = (value) => {
    setReasonToDuplicate(value);
    setReasonToDuplicateError(reasonToDuplicateIsValid(value));
  };

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length === 1) {
      await setCedula(acceptedFiles[0]);
    }
  };

  const getData = () => {
    return {
      name,
      phone,
      email,
      rfc,
      reasonToDuplicate,
      cedulaFiscal: cedula,
    };
  };

  const dataIsValid = () => {
    setNameError(nameIsValid(name));
    setPhoneError(phoneIsValid(phone));
    setEmailError(emailIsValid(email));
    setRfcError(rfcIsValid(rfc));
    setReasonToDuplicateError(reasonToDuplicateIsValid(reasonToDuplicate));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dataIsValid();
    if (canSubmit) {
      const data = getData();
      props.authorizeRfc(data).then(() => props.toogleModal());
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Text classname="copy" style={{ fontSize: "12px", marginTop: "5px" }}>
        Bríndanos la información solicitada, nuestro equipo la revisará y
        posteriormente aprobará el uso de tu RFC.
      </Text>
      <div style={{ display: "flex", flexDirection: "row", width: "65vw" }}>
        <div style={{ width: "45%" }}>
          <Input
            type="text"
            value={name}
            onChange={handleNameChange}
            error={nameError}
            required
            placeholder="nombre"
            label="Nombre"
          />
          <Input
            type="text"
            value={phone}
            onChange={handlePhoneChange}
            error={phoneError}
            required
            placeholder="teléfono"
            label="Teléfono"
          />
          <Input
            type="text"
            value={email}
            error={emailError}
            onChange={handleEmailchange}
            required
            placeholder="email"
            label="Correo"
          />
          <Input
            type="text"
            value={rfc}
            onChange={handleRfcChange}
            error={rfcError}
            required
            placeholder="rfc"
            label="RFC"
          />
        </div>
        <div style={{ width: "45%", marginLeft: "5%" }}>
          <Input
            type="text"
            value={reasonToDuplicate}
            onChange={handleReasonToDuplicateChange}
            error={reasonToDuplicateError}
            required
            placeholder=""
            label="¿Porque desea ingresar el mismo RFC en tus cuentas Mienvio?"
          />
          <Dropzone
            required
            label="Cedula de identificacion fiscal"
            accept=".pdf"
            maxSize={10000}
            onDrop={onDrop}
            onDelete={() => {
              setCedula("");
            }}
          />
        </div>
      </div>

      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button style={{ marginRight: 0 }}>Enviar</Button>
      </div>
    </form>
  );
};

DuplicateRfcForm.defaultProps = {
  user: {
    name: "",
    phone: "",
    email: "",
  },
};

export default DuplicateRfcForm;
