export const initialState = {
  steps: [
    {
      name: 'upload',
      label: 'Subir archivo',
      status: 'unfinished'
    },
    {
      name: 'process',
      label: 'Procesar ordenes',
      status: 'unfinished'
    },
    {
      name: 'confirm',
      label: 'Confirmacion',
      status: 'unfinished'
    }
  ],
  step: 0,
  percentage: 0,
  loading: false,
  shipments: []
};
