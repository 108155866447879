import React from "react";
import shipments from "./shipments.svg";
import reports from "./reports.svg";
import reportsActive from "./reportsActive.svg";
import shipmentsReady from "./shipments.svg";
import rates from "./rates.svg";
import integrations from "./integrations.svg";
import packages from "./packages.svg";
import invoices from "./invoices.svg";
import addresses from "./addresses.svg";
import payment from "./payment.svg";
import automatizations from "./automation.svg"; //TODO: Add correct icon for automatization
import shipmentsActive from "./shipmentsActive.svg";
import shipmentsReadyActive from "./shipmentsActive.svg";
import ratesActive from "./ratesActive.svg";
import integrationsActive from "./integrationsActive.svg";
import packagesActive from "./packagesActive.svg";
import invoicesActive from "./invoicesActive.svg";
import addressesActive from "./addressesActive.svg";
import paymentActive from "./paymentActive.svg";
import automatizationsActive from "./automationActive.svg";
import dashboard from "./dashboard.svg";
import dashboardActive from "./dashboardActive.svg";
import accounts from "./accounts.svg";
import accountsActive from "./accountsActive.svg";
import quotations from "./quotations.svg";
import quotationsActive from "./quotationsActive.svg";
import refered from "./refered.svg";
import referedActive from "./referedActive.svg";
import admin from "./admin.svg";
import adminActive from "./adminActive.svg";
import plan from "./refered.svg";
import settings from "./settings.svg";
import settingsActive from "./settingsActive.svg";

const src = {
  shipments,
  reports,
  reportsActive,
  rates,
  integrations,
  packages,
  invoices,
  addresses,
  payment,
  shipmentsActive,
  integrationsActive,
  packagesActive,
  invoicesActive,
  addressesActive,
  paymentActive,
  automatizations,
  automatizationsActive,
  dashboard,
  dashboardActive,
  ratesActive,
  accounts,
  accountsActive,
  quotations,
  quotationsActive,
  refered,
  referedActive,
  admin,
  adminActive,
  shipmentsReady,
  shipmentsReadyActive,
  plan,
  settings,
  settingsActive,
};

const Icon = (props) => (
  <img
    className={props.className}
    src={src[props.name + (props.active ? "Active" : "")]}
    alt={props.name}
  />
);

export default Icon;
