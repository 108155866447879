import React from 'react';
import { StyledTd } from './styledObjects';
import Input from '../Input';
import { Img } from '../Checkout/styled-components';
import removeIcon from './img/app-window-remove.svg';

const TdInput = ({ model, last, onDelete, disabled }) =>
  last ? (
    <StyledTd>
      <a className="remove-icon" onClick={onDelete}>
        <Img src={removeIcon} />
      </a>
    </StyledTd>
  ) : (
    <StyledTd>
      <Input {...model} parentClassName="no-margin-top" customInput disabled={disabled ? true : false}/>
    </StyledTd>
  );
export default TdInput;
