// @flow

import axios from 'axios';
import type { Dispatch as ReduxDispatch } from 'redux';

export const zipCodesUrl = '/api/zipcodes/';
export const SET_ZIPCODE_VALUE = 'SET_ZIPCODE_VALUE';
export const SET_WRONG_ZIPCODE = 'SET_WRONG_ZIPCODE';
export const SET_WRONG_ZIPCODES = 'SET_WRONG_ZIPCODES';
export const SET_ZIPCODE_PLACEHOLDER = 'SET_ZIPCODE_PLACEHOLDER';
export const RESET_ZIPCODES = 'RESET_ZIPCODES';
export const ZIPCODE_VALUE = 'ZIPCODE_VALUE';

export type ZipCode = {
  code: string,
  found: ?boolean,
  municipality: string,
  stateCode: string,
  placeholder: string
};

type ZipCodes = {
  to: ZipCode,
  from: ZipCode
};

type State = {
  +zipCodes: ZipCodes
};

type StateObject = {
  id: number,
  name: string,
  code: string,
  created_at: ?string,
  updated_at: ?string
};

type ZipCodeResponse = {
  id: number,
  code: string,
  municipality: string,
  neighborhood: string,
  created_at: ?string,
  updated_at: ?string,
  state_id: number,
  state: StateObject
};

type Action_ZIPCODE_VALUE = { type: 'ZIPCODE_VALUE', kind: string, code: string };
type Action_SET_ZIPCODE_VALUE = { type: 'SET_ZIPCODE_VALUE', data: ZipCodeResponse, kind: string };
type Action_SET_WRONG_ZIPCODE = { type: 'SET_WRONG_ZIPCODE', kind: string };
type Action_SET_WRONG_ZIPCODES = { type: 'SET_WRONG_ZIPCODES', kind: string };
type Action_SET_ZIPCODE_PLACEHOLDER = { type: 'SET_ZIPCODE_PLACEHOLDER', kind: string };

type Action =
  | Action_ZIPCODE_VALUE
  | Action_SET_ZIPCODE_VALUE
  | Action_SET_WRONG_ZIPCODES
  | Action_SET_WRONG_ZIPCODE
  | Action_SET_ZIPCODE_PLACEHOLDER;

type Dispatch = ReduxDispatch<Action>;

const initialState: State = {
  zipCodes: {
    to: {
      code: '',
      found: null,
      municipality: '',
      stateCode: '',
      placeholder: ''
    },
    from: {
      code: '',
      found: null,
      municipality: '',
      stateCode: '',
      placeholder: ''
    }
  }
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ZIPCODE_VALUE:
      return {
        ...state,
        [action.kind]: {
          code: action.code,
          ...state[action.kind]
        }
      };

    case SET_ZIPCODE_VALUE:
      return {
        ...state,
        [action.kind]: {
          code: action.data.code,
          found: true,
          municipality: action.data.municipality,
          stateCode: action.data.state.code,
          placeholder: action.data.municipality + ' (' + action.data.state.code + ')'
        }
      };

    case SET_WRONG_ZIPCODE:
      return {
        ...state,
        [action.kind]: {
          ...state[action.kind],
          found: false,
          placeholder: 'Código postal invalido'
        }
      };

    case SET_WRONG_ZIPCODES:
      return {
        ...state,
        [action.kind]: {
          ...state[action.kind],
          found: false,
          placeholder: 'Ingresa un código postal válido'
        }
      };
    case SET_ZIPCODE_PLACEHOLDER:
      return {
        ...state,
        [action.kind]: {
          ...state[action.kind],
          found: null,
          placeholder: ''
        }
      };

    case RESET_ZIPCODES:
      return {
        to: {
          code: '',
          found: null,
          municipality: '',
          stateCode: '',
          placeholder: ''
        },
        from: {
          code: '',
          found: null,
          municipality: '',
          stateCode: '',
          placeholder: ''
        }
      };

    default:
      return state;
  }
};

export const zipCodeChange = (kind: 'to' | 'from', code: string): Action_ZIPCODE_VALUE => {
  return {
    type: ZIPCODE_VALUE,
    kind,
    code
  };
};

export const fetchZipCode = (code: string, kind: 'to' | 'from') => {
  return (dispatch: Dispatch) => {
    return axios
      .get(zipCodesUrl + code)
      .then(response => {
        dispatch(setZipCode(response.data, kind));
      })
      .catch(err => {
        dispatch(setWrongZipCode(kind));
      });
  };
};

export const setZipCode = (
  data: ZipCodeResponse,
  kind: 'to' | 'from'
): Action_SET_ZIPCODE_VALUE => {
  return {
    type: SET_ZIPCODE_VALUE,
    data,
    kind
  };
};

export const setWrongZipCode = (kind: 'to' | 'from'): Action_SET_WRONG_ZIPCODE => {
  return {
    type: SET_WRONG_ZIPCODE,
    kind
  };
};

export const setIncompleteZipCode = (kind: 'to' | 'from'): Action_SET_WRONG_ZIPCODES => {
  return {
    type: SET_WRONG_ZIPCODES,
    kind
  };
};

export const setZipCodePlaceholder = (kind: 'to' | 'from'): Action_SET_ZIPCODE_PLACEHOLDER => {
  return {
    type: SET_ZIPCODE_PLACEHOLDER,
    kind
  };
};
