import React from 'react';
import { Column, Row } from 'hedron';
import Text from '../../Text';
import emptyBox from '../images/box_no_service.svg';
// TODO: Add empty image
const EmptyServices = () => (
  <div className="empty-services">
    <Row>
      <Column xs={12} md={12} className="title" style={{ padding: '5px' }}>
        <img alt="Loader" src={emptyBox} className="image-empty" />
        <Text type="P">😔 Oh no</Text>
      </Column>

      <Column xs={12} md={12} style={{ padding: '5px' }}>
        <Text type="P">Parece que ninguna paquetería envía a los C.P. que ingresaste.</Text>
      </Column>
      <Column xs={12} md={12} style={{ padding: '5px' }}>
        <Text type="P">Comunícate con nosotros a través del chat para ayudarte.</Text>
      </Column>
    </Row>
  </div>
);

export default EmptyServices;
