import { initialState } from "./initialState";
import {
  INIT_CSV_STEPS,
  SET_STEP_CSV,
  SET_ICON_CSV,
  PREVIOUS_STEP_CSV,
  NEXT_STEP_CSV,
  FILL_DATA_CSV,
  SET_STEP_SEND_DATA,
} from "./actionsTypes";

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_CSV_STEPS:
      return {
        ...state,
        steps: [
          {
            name: "upload",
            label: "Subir archivo",
            status: "unfinished",
          },
          {
            name: "process",
            label: "Procesar ordenes",
            status: "unfinished",
          },
          {
            name: "confirm",
            label: "Confirmacion",
            status: "unfinished",
          },
        ],
        step: 0,
        percentage: 0,
        shipments: [],
        loading: false,
      };
    case SET_STEP_CSV:
      return {
        ...state,
        step: action.step,
      };
    case SET_ICON_CSV:
      let currentTitles = state.steps;
      const step = action.payload.step ? action.payload.step : state.step;
      currentTitles[step].status = action.payload.icon;
      return {
        ...state,
        steps: currentTitles,
      };
    case PREVIOUS_STEP_CSV:
      return {
        ...state,
        step: state.step - 1,
      };
    case NEXT_STEP_CSV:
      return {
        ...state,
        step: state.step == 2 ? 2 : state.step + 1,
      };
    case SET_STEP_SEND_DATA:
      return {
        ...state,
        countOrders: action.countOrders,
        maxOrders: action.maxOrders,
      };
    case FILL_DATA_CSV:
      return {
        ...state,
        csvResponse: action.payload,
      };
    default:
      return state;
  }
};
