import axios from "axios";
import React, { Component } from "react";
import {
  Background,
  Box,
  Content,
  CloseIcon,
  Title,
  TitleLine,
  ContentAdapter,
  BottomContainer,
  Separator,
} from "../WrapperSideBar/styledObjects";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../WrapperSideBar/style.css";
import { Row, Column } from "hedron";
import { closeInvoice } from "../../modules/sidebarOrder/actions";
import Button from "../Button";
import "./index.css";
import { RadioGroup, Radio } from "../Radio";
import Dropdown from "../Dropdown";
import Input, { Label, Required } from "../Input";
import Text from "../Text";
import moment from "moment";
import Address from "./address";
import { Checkbox, CheckboxGroup } from "../Checkbox";
import Table, { Tr } from "../Table";
import { ThPack } from "./styledObjects";
import Package from "./package";
import isEmail from "validator/lib/isEmail";
import { commercialInvoice } from "../../modules/sidebarOrder/actions";
const packageModel = {
  no: "1",
  noError: "",
  units: "",
  unitsError: "",
  unitMeasure: "",
  unitMeasureError: "",
  description: "",
  descriptionError: "",
  tariff: "",
  tariffError: "",
  country: "",
  countryError: "",
  value: "",
  valueError: "",
  total: "",
  totalError: "",
  isDeleted: false,
};
const initialState = {
  data: {
    date: moment(new Date()).format("DD/MM/YYYY"),
    invoice: "",
    invoiceError: "",
    purchase: "",
    purchaseError: "",
    payment: "",
    paymentError: "",
    incoterm: "",
    incotermError: "",
    incoterm_example: "",
    incoterm_exampleError: "",
    reason: 0,
    reasonError: "",
    fromId: "",
    fromIdError: "",
    transaction: 0,
    toId: "",
    toIdError: "",
    soldId: "",
    soldIdError: "",
    sameAsToCheck: ["same"],
    soldModel: {
      name: "",
      nameError: "",
      phone: "",
      phoneError: "",
      email: "",
      emailError: "",
      address: "",
      addressError: "",
      country: "",
      countryError: "",
    },
    instructions: "",
    declarations: "",
  },
  packages: [packageModel],
  shakeClass: "",
};

class Invoice extends Component {
  state = initialState;
  _onClose = () => {
    if (this.props.handleClose != null) this.props.handleClose();
    this.setState({ initialState });
    this.props.closeInvoice();
  };

  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  };

  handleChange = (value, name) => {
    let newModel = { ...this.state.data, [name]: value };
    this.setState({ data: newModel });
  };

  _validNumber = (value) => !isNaN(value);

  setError = (name, error) => {
    let fieldError = `${name}Error`;
    this.setState({ data: { ...this.state.data, [fieldError]: error } });
  };

  setAddressError = (name, error) => {
    let fieldError = `${name}Error`;
    let newSoldModel = { ...this.state.data.soldModel, [fieldError]: error };
    this.setState({ data: { ...this.state.data, soldModel: newSoldModel } });
  };

  handleBlur = (value, name, required) => {
    if (required && !value) this.setError(name, "Este campo es requerido");
    else if (name == "invoice" || name == "purchase") {
      if (!this._validNumber(value))
        this.setError(name, "Este campo es requerido");
    }
  };

  generateProps = (name, params) => ({
    onChange: (value) => {
      let val =
        params.type === "radio" || params.type === "checkbox"
          ? value.target.value
          : value;
      this.handleChange(val, name);
    },
    onBlur: (value) => {
      let val =
        params.type === "radio" || params.type === "checkbox"
          ? value.target.value
          : value;
      this.handleBlur(val, name, params.required);
    },
    name: name ? name : "",
    value: this.state.data[name],
    error: this.state.data[`${name}Error`],
    type: params.type ? params.type : "text",
    required: params.required ? params.required : false,
    placeholder: params.placeholder ? params.placeholder : "",
    label: params.label ? params.label : "",
    help: params.help ? params.help : "",
    maxLength: params.maxLength ? params.maxLength : null,
    disabled: params.disabled ? params.disabled : null,
    cornerNote: params.cornerNote ? params.cornerNote : null,
    icon: params.icon ? params.icon : null,
    prefix: params.prefix ? params.prefix : null,
    className: name ? `cl-${name}` : "",
    parentClassName: params.parentClassName ? params.parentClassName : null,
  });

  _renderIncoterms = () =>
    ["Incoterm"].map((incoterm, index) => <Text key={index}>{incoterm}</Text>);

  handleChangeIncoTerm = (incoterm) => {
    this.handleChange(incoterm, "incoterm");
  };

  onchangeCheckBox = (value) => {
    this.handleChange(value, "reason");
  };

  _changeInputAddress = (value, name, type) => {
    if (type == "from" || type == "to") return;
    if (type == "sold" && this.state.data.sameAsToCheck.length == 1) return;
    let newModel = { ...this.state.data.soldModel, [name]: value };
    this.setState({
      data: {
        ...this.state.data,
        soldModel: newModel,
      },
    });
  };

  _getAddress = (type) => {
    let name, phone, email, address, country;
    let transaction = null;
    switch (type) {
      case "from":
        name = this.props.newShipment.fromAddress
          ? this.props.newShipment.fromAddress.name
          : this.props.newShipment.data.from.name;
        phone = this.props.newShipment.fromAddress
          ? this.props.newShipment.fromAddress.phone
          : this.props.newShipment.data.from.phone;
        email = this.props.newShipment.fromAddress
          ? this.props.newShipment.fromAddress.email
          : this.props.newShipment.data.from.email;
        address = this.props.newShipment.fromAddress
          ? `${this.props.newShipment.fromAddress.street} Col. ${this.props.newShipment.fromAddress.street2}, C.P. ${this.props.newShipment.fromAddress.zipcode}`
          : `${this.props.newShipment.data.from.street} Col. ${this.props.newShipment.data.from.neighborhood}, C.P. ${this.props.newShipment.data.from.zipcode}`;
        country = this.props.newShipment.fromAddress
          ? this.props.newShipment.fromAddress.country
          : this.props.newShipment.data.from.country == 0
          ? "México"
          : this.props.newShipment.data.from.country == 1
          ? "USA"
          : "México";
        transaction = {
          value: this.state.data.transaction,
          onChange: (value) => {
            this.handleChange(value, "transaction");
          },
        };
        break;
      case "to":
        name = this.props.newShipment.toAddress
          ? this.props.newShipment.toAddress.name
          : this.props.newShipment.data.to.name;
        phone = this.props.newShipment.toAddress
          ? this.props.newShipment.toAddress.phone
          : this.props.newShipment.data.to.phone;
        email = this.props.newShipment.toAddress
          ? this.props.newShipment.toAddress.email
          : this.props.newShipment.data.to.email;
        address = this.props.newShipment.toAddress
          ? `${this.props.newShipment.toAddress.street} Col. ${this.props.newShipment.toAddress.street2}, C.P. ${this.props.newShipment.toAddress.zipcode}`
          : `${this.props.newShipment.data.to.street} Col. ${this.props.newShipment.data.to.neighborhood}, C.P. ${this.props.newShipment.data.to.zipcode}`;
        country = this.props.newShipment.toAddress
          ? this.props.newShipment.toAddress.country
          : this.props.newShipment.data.to.country;
        break;
      case "sold":
        if (this.state.data.sameAsToCheck.length == 1) {
          name = this.props.newShipment.toAddress
            ? this.props.newShipment.toAddress.name
            : this.props.newShipment.data.to.name;
          phone = this.props.newShipment.toAddress
            ? this.props.newShipment.toAddress.phone
            : this.props.newShipment.data.to.phone;
          email = this.props.newShipment.toAddress
            ? this.props.newShipment.toAddress.email
            : this.props.newShipment.data.to.email;
          address = this.props.newShipment.toAddress
            ? `${this.props.newShipment.toAddress.street} Col. ${this.props.newShipment.toAddress.street2}, C.P. ${this.props.newShipment.toAddress.zipcode}`
            : `${this.props.newShipment.data.to.street} Col. ${this.props.newShipment.data.to.neighborhood}, C.P. ${this.props.newShipment.data.to.zipcode}`;
          country = this.props.newShipment.toAddress
            ? this.props.newShipment.toAddress.country
            : this.props.newShipment.data.to.country;
        } else {
          name = this.state.data.soldModel.name;
          phone = this.state.data.soldModel.phone;
          email = this.state.data.soldModel.email;
          address = this.state.data.soldModel.address;
          country = this.state.data.soldModel.country;
        }
        break;
      default:
        break;
    }
    return {
      name: {
        value: name,
        name: `${type}name`,
        error: type == "sold" ? this.state.data.soldModel.nameError : null,
        type: "text",
        required: true,
        label: "Contact Name (Nombre)",
        maxLength: 255,
        disabled:
          type != "sold" ? true : this.state.data.sameAsToCheck.length == 1,
        placeholder: "nombre",
        onChange: (value) => {
          this._changeInputAddress(value, "name", type);
        },
        onBlur: (value) => {
          if (value.length == 0)
            this.setAddressError("name", "Este campo es requerido");
          else this.setAddressError("name", "");
        },
      },
      phone: {
        value: phone,
        name: `${type}Id`,
        error: type == "sold" ? this.state.data.soldModel.phoneError : null,
        type: "text",
        required: true,
        label: "Telephone No. (Teléfono)",
        maxLength: 15,
        disabled:
          type !== "sold" ? true : this.state.data.sameAsToCheck.length == 1,
        placeholder: "teléfono",
        onChange: (value) => {
          this._changeInputAddress(value, "phone", type);
        },
        onBlur: (value) => {
          if (value.length == 0)
            this.setAddressError("phone", "Este campo es requerido");
          else if (!this._validNumber(value) || value.length !== 10)
            this.setAddressError(
              "phone",
              "El formato de teléfono no es correcto"
            );
          else this.setAddressError("phone", "");
        },
      },
      email: {
        value: email,
        name: `${type}Id`,
        error: type == "sold" ? this.state.data.soldModel.emailError : null,
        type: "text",
        required: true,
        label: "E-mail (Correo electronico)",
        maxLength: 255,
        disabled:
          type != "sold" ? true : this.state.data.sameAsToCheck.length == 1,
        placeholder: "correo electronico",
        onChange: (value) => {
          this._changeInputAddress(value, "email", type);
        },
        onBlur: (value) => {
          if (!isEmail(value))
            this.setAddressError("email", "El correo no es correcto");
          else this.setAddressError("email", "");
        },
      },
      address: {
        value: address,
        name: `${type}Id`,
        error: type === "sold" ? this.state.data.soldModel.addressError : null,
        type: "text",
        required: true,
        label: "Company name/Address (Compañia/Direccion)",
        maxLength: 255,
        disabled:
          type !== "sold" ? true : this.state.data.sameAsToCheck.length === 1,
        placeholder: "Direccion",
        onChange: (value) => {
          this._changeInputAddress(value, "address", type);
        },
        onBlur: (value) => {
          if (value.length === 0)
            this.setAddressError("address", "Este campo es requerido");
          else this.setAddressError("address", "");
        },
      },
      country: {
        value: country,
        name: `${type}Id`,
        error: type === "sold" ? this.state.data.soldModel.countryError : null,
        type: "text",
        required: true,
        label: "Country (Pais)",
        maxLength: 200,
        disabled:
          type !== "sold" ? true : this.state.data.sameAsToCheck.length === 1,
        placeholder: "Pais",
        onChange: (value) => {
          this._changeInputAddress(value, "country", type);
        },
        onBlur: (value) => {
          if (value.length === 0)
            this.setAddressError("country", "Este campo es requerido");
          else this.setAddressError("country", "");
        },
      },
    };
  };

  handleSoldCheck = (values) => {
    this.setState({
      data: {
        ...this.state.data,
        sameAsToCheck: values,
      },
    });
  };

  _buildHeaders = () => (
    <thead className="package-head">
      <Tr noBorder>
        {[
          "# of packages",
          "# of Units",
          "Unit of measure",
          "Description ",
          "Harmonized tariff",
          "Country of origin",
          "Value unit",
          "Total value",
        ].map((val, index) => (
          <ThPack key={index} pClassName="pack-th">
            {val}
          </ThPack>
        ))}
      </Tr>
    </thead>
  );

  isNotDeleted = (pack) => !pack.isDeleted;

  _changePackageInput = async (name, index, value) => {
    let packages = [...this.state.packages];
    let pack = { ...packages[index] };
    pack[name] = value;
    if (name == "units" || name == "value") {
      const valueunits = name == "units" ? pack["value"] : pack["units"];
      const total =
        !isNaN(valueunits) && !isNaN(value) ? value * valueunits : 0;
      pack["total"] = total;
    }
    packages[index] = pack;
    await this.setStateAsync({
      packages: packages,
    });
  };

  _setErrorPackage = (name, index, error) => {
    let fieldError = `${name}Error`;
    this._changePackageInput(fieldError, index, error);
  };

  _handleBlurOnPackage = (name, index, value) => {
    if (value.length == 0)
      this._setErrorPackage(name, index, "Este Campo es requerido");
    else if (name == "no" && !this._validNumber(value))
      this._setErrorPackage(name, index, "El numero no es valido");
    else if (name == "units" && !this._validNumber(value))
      this._setErrorPackage(name, index, "El numero no es valido");
    else if (name == "value" && !this._validNumber(value))
      this._setErrorPackage(name, index, "El numero no es valido");
    else this._setErrorPackage(name, index, "");
  };

  getPackageModel = (index) => ({
    no: {
      value: this.state.packages[index].no,
      error: this.state.packages[index].noError,
      type: "number",
      placeholder: "0",
      onChange: (value) => {
        this._changePackageInput("no", index, value);
      },
      onBlur: (value) => {
        this._handleBlurOnPackage("no", index, value);
      },
    },
    units: {
      value: this.state.packages[index].units,
      error: this.state.packages[index].unitsError,
      type: "number",
      placeholder: "0",
      onChange: (value) => {
        this._changePackageInput("units", index, value);
      },
      onBlur: (value) => {
        this._handleBlurOnPackage("units", index, value);
      },
    },
    unitMeasure: {
      value: this.state.packages[index].unitMeasure,
      error: this.state.packages[index].unitMeasureError,
      type: "text",
      placeholder: "Mililiters",
      onChange: (value) => {
        this._changePackageInput("unitMeasure", index, value);
      },
      onBlur: (value) => {
        this._handleBlurOnPackage("unitMeasure", index, value);
      },
    },
    description: {
      value: this.state.packages[index].description,
      error: this.state.packages[index].descriptionError,
      type: "text",
      placeholder: "Example of goods",
      onChange: (value) => {
        this._changePackageInput("description", index, value);
      },
      onBlur: (value) => {
        this._handleBlurOnPackage("description", index, value);
      },
    },
    tariff: {
      value: this.state.packages[index].tariff,
      error: this.state.packages[index].tariffError,
      type: "text",
      placeholder: "3423411",
      onChange: (value) => {
        this._changePackageInput("tariff", index, value);
      },
      onBlur: (value) => {
        this._handleBlurOnPackage("tariff", index, value);
      },
    },
    country: {
      value: this.state.packages[index].country,
      error: this.state.packages[index].countryError,
      type: "text",
      placeholder: "Mexico",
      onChange: (value) => {
        this._changePackageInput("country", index, value);
      },
      onBlur: (value) => {
        this._handleBlurOnPackage("country", index, value);
      },
    },
    value: {
      value: this.state.packages[index].value,
      error: this.state.packages[index].valueError,
      type: "number",
      placeholder: "0",
      prefix: "$",
      tableInput: true,
      onChange: (value) => {
        this._changePackageInput("value", index, value);
      },
      onBlur: (value) => {
        this._handleBlurOnPackage("value", index, value);
      },
    },
    total: {
      value: this.state.packages[index].total,
      error: this.state.packages[index].totalError,
      type: "number",
      placeholder: "0",
      prefix: "$",
      tableInput: true,
      disabled: true,
      onChange: (value) => {
        //this._changePackageInput('total', index, value);
      },
    },
    totalPackages: this.state.packages.filter(this.isNotDeleted).length,
  });

  _onDeletePackage = (ind) => {
    if (ind == -1) return;
    let newPackages = [...this.state.packages];
    let deletedPackage = { ...newPackages[ind] };
    deletedPackage.isDeleted = true;
    newPackages[ind] = deletedPackage;
    this.setState({
      packages: newPackages,
    });
  };

  renderPackages = () =>
    this.state.packages.map(
      (pack, index) =>
        !pack.isDeleted && (
          <Package
            key={index}
            {...this.getPackageModel(index)}
            onDelete={() => {
              this._onDeletePackage(index);
            }}
          />
        )
    );

  _addNewPackage = () => {
    this.setState({
      packages: [...this.state.packages, packageModel],
    });
  };

  _validPackage = (pack) => {
    let valid = false;
    if (
      pack.no.length > 0 &&
      pack.no.length <= 50 &&
      pack.units.length > 0 &&
      pack.unitMeasure.length > 0 &&
      pack.unitMeasure.length <= 50 &&
      pack.description.length > 0 &&
      pack.description.length <= 50 &&
      pack.tariff.length > 0 &&
      pack.tariff.length <= 50 &&
      pack.country.length > 0 &&
      pack.country.length <= 50 &&
      pack.value.length > 0 &&
      this._validNumber(pack.no) &&
      this._validNumber(pack.units) &&
      this._validNumber(pack.value)
    )
      valid = true;
    return valid;
  };

  validForm = async () => {
    let valid = false;
    let incorrectPackages = 0;
    await this.setStateAsync({ shakeClass: "" });
    if (
      // first validation
      this.state.data.invoice.length > 0 &&
      this.state.data.invoice.length <= 50 &&
      this.state.data.purchase.length > 0 &&
      this.state.data.purchase.length <= 50 &&
      this.state.data.payment.length > 0 &&
      this.state.data.payment.length <= 50 &&
      this.state.data.incoterm.length > 0 &&
      this.state.data.incoterm_example.length > 0 &&
      this.state.data.incoterm_example.length <= 50 &&
      this.state.data.fromId.length > 0 &&
      this.state.data.fromId.length <= 50 &&
      this.state.data.toId.length > 0 &&
      this.state.data.toId.length <= 30 &&
      this.state.data.instructions.length > 0 &&
      this.state.data.instructions.length <= 255 &&
      this.state.data.declarations.length > 0 &&
      this.state.data.declarations.length <= 255 &&
      this.state.packages.filter(this.isNotDeleted).length > 0
    ) {
      if (this.state.data.sameAsToCheck.length == 0) {
        // Valid when in sold the user add new info
        if (
          // validate sold form
          this.state.data.soldId.length > 0 &&
          this.state.data.soldId.length <= 30 &&
          this.state.data.soldModel.name.length > 0 &&
          this.state.data.soldModel.name.length <= 255 &&
          this.state.data.soldModel.phone.length > 0 &&
          this.state.data.soldModel.phone.length <= 15 &&
          this.state.data.soldModel.email.length > 0 &&
          this.state.data.soldModel.email.length <= 255 &&
          this.state.data.soldModel.address.length > 0 &&
          this.state.data.soldModel.address.length <= 255 &&
          this.state.data.soldModel.country.length > 0 &&
          this.state.data.soldModel.country.length <= 200 &&
          this._validNumber(this.state.data.soldId)
        )
          valid = true;
      } else valid = true;
      if (valid) {
        // valid array of packages
        this.state.packages.filter(this.isNotDeleted).map((pack) => {
          incorrectPackages += this._validPackage(pack) ? 0 : 1;
        });
      }
    }
    if (incorrectPackages == 0) return true;
    else {
      await this.setStateAsync({ shakeClass: "animated shake" });
      return false;
    }
  };

  sendData = () => {
    const packages_data = JSON.stringify(this.state.packages);
    const data = {
      label_id: this.props.shipments.created,
      invoice_no: this.state.data.invoice,
      purchase_order: this.state.data.purchase,
      payment_terms: this.state.data.payment,
      incoterm: this.state.data.incoterm,
      incoterm_example: this.state.data.incoterm_example,
      export_reason: this.state.data.reason,
      from_address: this.props.newShipment.fromAddress.object_id,
      to_address: this.props.newShipment.toAddress.object_id,
      sold_address: this.props.newShipment.toAddress.object_id,
      special_instructions: this.state.data.instructions,
      declaration_statements: this.state.data.declarations,
      packages: packages_data,
    };

    axios.post("api/international/invoice", data).then((res) => {
      return res;
    });
    this._onClose();
  };

  update() {
    const { newShipment = { toAddress: { object_id: "" } } } = this.props;
    const { data } = this.state;
    const commercialInvoiceData = {
      invoice_no: data.invoice,
      purchase_order: data.purchase,
      payment_terms: data.payment,
      incoterm: data.incoterm,
      incoterm_example: "EXW = EX WORKS",
      export_reason: data.reason,
      sold_address: newShipment.toAddress.object_id,
      special_instructions: data.instructions,
      declaration_statements: data.declarations,
      packages: this.state.packages,
      from_tax: data.fromId,
      to_tax: data.toId,
    };
    this.props.commercialInvoice(commercialInvoiceData);
    this._onClose();
  }

  render() {
    return this.props.newShipment.openInvoice ? (
      <div className="commercial-invoice-wrapper">
        <Background
          onClick={this._onClose}
          className={this.props.newShipment.openInvoice && "csb-open"}
        />
        <Box
          className={`invoice-bigbox ${
            this.props.newShipment.openInvoice && "csb-open"
          } `}
        >
          <div className="sidebar-title-wrapper">
            <CloseIcon onClick={this._onClose} />
            <Title titleMargin={0}>Factura comercial</Title>
            <TitleLine />
          </div>
          <Content className={`sidebar-content`}>
            <ContentAdapter>
              <Row>
                <Column xs={2} className="min-padding-bottom no-padding-top">
                  <Input
                    {...this.generateProps("date", {
                      required: true,
                      placeholder: "Fecha",
                      label: "Date (Fecha)",
                      disabled: true,
                    })}
                  />
                </Column>
                <Column xs={3} className="min-padding-bottom no-padding-top">
                  <Input
                    {...this.generateProps("invoice", {
                      required: true,
                      placeholder: "4312301011323242",
                      label: "Invoice (No de la factura)",
                      maxLength: 50,
                      type: "number",
                    })}
                  />
                </Column>
                <Column xs={3} className="min-padding-bottom no-padding-top">
                  <Input
                    {...this.generateProps("purchase", {
                      required: true,
                      placeholder: "4312301011323242",
                      label: "Purchase (No de orden)",
                      maxLength: 50,
                      type: "number",
                    })}
                  />
                </Column>
                <Column xs={4} className="min-padding-bottom no-padding-top">
                  <Input
                    {...this.generateProps("payment", {
                      required: true,
                      placeholder: "4312301011323242",
                      label: "Payment (Terminos de pago)",
                      maxLength: 50,
                    })}
                  />
                </Column>
              </Row>
              <Row>
                <Column xs={4} className="no-padding-top no-padding-bottom">
                  <div>
                    <Dropdown
                      required
                      label="Incoterms (Incoterms)"
                      options={this._renderIncoterms()}
                      style={{ marginTop: "12px" }}
                      placeholder={
                        <Text className="dropdown-placeholder">
                          Select an option
                        </Text>
                      }
                      selected={this.state.data.incoterm}
                      handleChange={this.handleChangeIncoTerm}
                    />
                  </div>
                </Column>
                <Column xs={4} className="no-padding-top no-padding-bottom">
                  <Input
                    {...this.generateProps("incoterm_example", {
                      required: true,
                      placeholder: "EX WORKS",
                      label: "example of incoterm",
                      maxLength: 50,
                      value: "EXW = EX WORKS",
                      disabled: true,
                    })}
                  />
                </Column>
              </Row>
              <Row>
                <Column className="no-padding-top">
                  <Label style={{ marginTop: "16px", marginBottom: "15px" }}>
                    Reasons for export
                    <Required
                      status={
                        this.state.data.incoterm == null ? "error" : "default"
                      }
                    />
                  </Label>
                  <RadioGroup
                    className="no-margin-checkbox"
                    selected={this.state.data.reason}
                    onChange={this.onchangeCheckBox}
                  >
                    <Radio
                      value={0}
                      text="Sold (Vendido)"
                      containerClass="custom-size"
                      className="no-margin-left"
                    />
                    <Radio
                      value={1}
                      text="Not sold (No esta vendido)"
                      containerClass="custom-size max"
                    />
                    <Radio
                      value={2}
                      text="Other (Otro)"
                      containerClass="custom-size"
                    />
                  </RadioGroup>
                </Column>
              </Row>
              <Separator />
              <Label className="label-title">Shipped FROM (Origen)</Label>
              <Address {...this._getAddress("from")} />
              <Separator />
              <Label className="label-title">Shipped TO (Destinatario)</Label>
              <Address
                {...this._getAddress("to")}
                wrapperClass="margin-bottom"
              />
              <Separator />
              <Label className="label-title">Sold TO (Vendido a)</Label>
              <CheckboxGroup
                className="no-margin-checkbox"
                defaultChecked={this.state.data.sameAsToCheck}
                onChange={this.handleSoldCheck}
                type="form"
              >
                <Checkbox
                  value={"same"}
                  text="Same as Shipped TO (Igual que el destinatario)"
                />
              </CheckboxGroup>
              {this.state.data.sameAsToCheck.length == 0 && (
                <Address
                  {...this._getAddress("sold")}
                  wrapperClass="margin-bottom"
                />
              )}
              <Separator />
              <Label className="label-title max">List of goods</Label>
              <Table>
                {this._buildHeaders()}
                <tbody>{this.renderPackages()}</tbody>
                <Button
                  type="link"
                  className="edit-btn-left more-btn"
                  onClick={this._addNewPackage}
                >
                  + add package
                </Button>
              </Table>
              <Separator />
              <Row>
                <Column xs={6}>
                  <Input
                    {...this.generateProps("instructions", {
                      required: true,
                      placeholder: "Any information",
                      label: "Special instructions (Instrucciones especiales)",
                      maxLength: 255,
                    })}
                  />
                </Column>
                <Column xs={6}>
                  <Input
                    {...this.generateProps("declarations", {
                      required: true,
                      placeholder: "Any information",
                      label: "Declaration statements (Declaraciones)",
                      maxLength: 255,
                    })}
                  />
                </Column>
              </Row>
              <div className="bottom-spacer" />
              <BottomContainer
                className={`${
                  this.props.newShipment.openInvoice && "csb-open"
                }`}
              >
                <Row>
                  <Column xs={6} />
                  <Column xs={6} className="btn-invoice">
                    <Button
                      className={this.state.shakeClass}
                      onClick={() => this.update()}
                    >
                      Adjuntar factura comercial
                    </Button>
                  </Column>
                </Row>
              </BottomContainer>
            </ContentAdapter>
          </Content>
        </Box>
      </div>
    ) : (
      <div />
    );
  }
}

const mapStateToProps = (state) => ({
  newShipment: state.newShipment, //this.props.newShipment.fromAddress.object_id
  addresses: state.addresses,
  shipments: state.shipments,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeInvoice,
      commercialInvoice,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
