import React from "react";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import {
  ContentAdapter,
  BottomContainer,
} from "../../WrapperSideBar/styledObjects";
import { createPackage, setDefaultPackage } from "../../../modules/packages";
import { toggle as toggleSideBar } from "../../../modules/CollapsibleBar/actions";
import "../style.css";
import Text from "../../Text";
import Button from "../../Button";
import { getBase64 } from "../../../utils/global_functions";
import LessPackage from "../../CreateLabel/common/lessPackages";
import Package from "../../CreateLabel/common/package";
import FormPackage from "../../CreateLabel/common/formPackage";
import MorePackages from "../../CreateLabel/common/morePackages";
import { getVolumetricWeight } from "../../../utils/global_functions";
import Dropdown from "../../Dropdown";
import SelectableBox from "../../SelectableBox";
import Input, { Label, Required, HelpText } from "../../Input";

import box_icon from "../../PackagesList/icons/box.svg";
import packet_icon from "../../PackagesList/icons/envelope.svg";

import { validatePackage, translatePackage } from "../../../utils/validate";

class AutomationPackages extends React.Component {
  state = {
    manualPackage: false,
    packageSelectedIndex: -1,
    packageSelected: null,
    newPackage: {
      id: "",
      alias: "",
      aliasError: "",
      type: "",
      typeError: "",
      dimensions: {
        length: "",
        width: "",
        height: "",
      },
      dimensionsError: "",
      description: "",
      descriptionError: "",
      weight: "",
      weightError: "",
      insuredAmount: "",
      insuredAmountError: "",
      isLoading: false,
    },
  };

  componentWillMount() {
    if (this.props.preset) {
      for (let i = 0; i < this.props.packages.results.length; i++) {
        if (this.props.preset == this.props.packages.results[i].object_id) {
          this.setState({
            packageSelectedIndex: i,
            packageSelected: this.props.packages.results[i],
          });
          this.handleDefaultPackageChange(i);
          break;
        }
      }
    } else {
      this.setState({
        manualPackage: true,
      });
    }
  }

  handleCheckboxChange = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleChange = (value, key) => {
    this.setState({
      newPackage: {
        ...this.state.newPackage,
        [key]: value,
      },
    });
  };
  _onChangeDimensions = (value, key) => {
    this.setState({
      newPackage: {
        ...this.state.newPackage,
        dimensions: {
          ...this.state.newPackage.dimensions,
          [key]: value,
        },
      },
    });
  };

  handleDefaultPackageChange = (value) => {
    this.setState({
      packageSelectedIndex: value,
      packageSelected: this.props.packages.results[value],
      newPackage: {
        alias: this.props.packages.results[value].alias,
        type: this.props.packages.results[value].object_type.toLowerCase(),
        dimensions: {
          length: this.props.packages.results[value].length,
          width: this.props.packages.results[value].width,
          height: this.props.packages.results[value].height,
        },
        description: this.props.packages.results[value].description,
        weight: this.props.packages.results[value].weight,
        insuredAmount:
          this.props.packages.results[value].insured_amount != 0.0
            ? this.props.packages.results[value].insured_amount
            : "",
      },
    });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length == 1)
      getBase64(acceptedFiles[0]).then((image) => {
        this.setState({
          logo: image,
        });
      });
  };

  onDelete = () => {
    this.setState({
      logo: false,
    });
  };

  async createPackage() {
    let validate = validatePackage(this.state.newPackage);

    this.setState({
      newPackage: {
        ...validate[1],
        isLoading: validate[0],
      },
    });

    if (!validate[0]) {
      // Not valid
      return;
    }

    const pack = translatePackage(this.state.newPackage);

    await this.props.createPackage(pack).then((response) => {
      this.setDefaultPackage(response.data.package.object_id);
    });
  }

  setDefaultPackage = (package_id) => {
    this.setState({
      newPackage: {
        ...this.state.newPackage,
        isLoading: true,
      },
    });
    this.props
      .setDefaultPackage(package_id)
      .then((result) => {
        this.props.toggleSideBar(false, "", "", this.props.update);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  save = () => {
    let payload = {};
    if (
      this.state.manualPackage ||
      this.state.newPackage?.description !==
        this.state.packageSelected?.description ||
      this.state.newPackage?.weight !== this.state.packageSelected?.weight ||
      this.state.newPackage?.insuredAmount !==
        this.state.packageSelected?.insured_amount
    ) {
      this.createPackage();
    } else {
      this.setDefaultPackage(this.state.packageSelected.object_id);
    }
  };

  generateDropdownOptions = () => {
    return this.props.packages.results.map((element, index) => (
      <Row key={element.object_id} className="card-dropdown-option">
        <Column fluid>
          <Text className="title-selected">
            <b>{element.alias}</b>
          </Text>
          <br />
          <Text>{element.object_type}</Text>
          <br />
          <Text>
            {element.length} x {element.width} x {element.height} cm
          </Text>
          <br />
        </Column>
      </Row>
    ));
  };

  generate = (name) => {
    return {
      onChange: (value) => {
        this.handleChange(value, name);
      },
      value: this.state.newPackage[name],
      error: this.state.newPackage[`${name}Error`],
    };
  };

  render() {
    return (
      <ContentAdapter className="top-space create-package-form">
        <Text type="microHeader">Empaque</Text>
        <Row>
          {this.state.manualPackage && (
            <div style={{ width: "100%" }}>
              <Column fluid>
                <Input
                  type="text"
                  {...this.generate("alias")}
                  required
                  placeholder="Sobre amarillo"
                  label="Alias"
                />
              </Column>
              <Column fluid>
                <Text type="microHeader">Información del empaque</Text>
              </Column>
              <Column fluid style={{ marginTop: "0.75rem" }}>
                <Label>Tipo</Label>
                <Required
                  status={this.state.newPackage.typeError ? "error" : "default"}
                />
              </Column>
              <Column className="boxes-options" fluid>
                <SelectableBox
                  type="radio"
                  name="type"
                  className="type-select"
                  value="caja"
                  isChecked={this.state.newPackage.type == "caja"}
                  onChange={() => this.handleChange("caja", "type")}
                >
                  <img src={box_icon} />
                  Caja
                </SelectableBox>
                <SelectableBox
                  type="radio"
                  name="type"
                  className="type-select"
                  value="sobre"
                  isChecked={this.state.newPackage.type == "sobre"}
                  onChange={() => this.handleChange("sobre", "type")}
                >
                  <img src={packet_icon} />
                  Sobre
                </SelectableBox>
              </Column>
              <Column fluid>
                <HelpText
                  className={
                    "animated-help-text " +
                    (this.state.newPackage.typeError ? "open" : "")
                  }
                >
                  {this.state.newPackage.typeError}
                </HelpText>
              </Column>
            </div>
          )}
        </Row>
        <Row>
          <Column fluid>
            {
              // Less than 4 packages saved
              this.props.packages?.results?.length < 4 &&
                !this.state.manualPackage &&
                this.props.packages?.results.map((element, index) => (
                  <SelectableBox
                    key={index}
                    type="radio"
                    name="default-package"
                    onChange={() => this.handleDefaultPackageChange(index)}
                    isChecked={this.state.packageSelectedIndex == index}
                  >
                    <Text type="titleCard">{element.alias}</Text>
                    <br />
                    <Text>{element.object_type}</Text>
                    <br />
                    <Text>
                      {element.length} x {element.width} x {element.height} cm
                    </Text>
                    <br />
                  </SelectableBox>
                ))
            }
            {
              // 4 or more packages saved
              this.props.packages?.results?.length >= 4 &&
                !this.state.manualPackage && (
                  <Dropdown
                    handleChange={(value) =>
                      this.handleDefaultPackageChange(value)
                    }
                    options={this.generateDropdownOptions()}
                    multiple={false}
                    selected={this.state.packageSelectedIndex}
                    selectedTitle={
                      this.state.packageSelected
                        ? this.state.packageSelected.alias
                        : "Selecciona..."
                    }
                  />
                )
            }
            {this.props.packages?.results?.length >= 4 &&
              this.state.packageSelected &&
              !this.state.manualPackage && (
                <div>
                  <Label block>Dimensiones</Label>
                  <Text block>
                    {this.state.packageSelected.length} x{" "}
                    {this.state.packageSelected.width} x{" "}
                    {this.state.packageSelected.height} cm
                  </Text>
                </div>
              )}
          </Column>
        </Row>
        {this.state.manualPackage && (
          <Row>
            <Column xs={9} fluid>
              <Input
                type="text"
                textClass="span-text"
                measureClass="measureClass"
                value={this.state.newPackage.dimensions}
                error={this.state.newPackage.dimensionsError}
                required
                onChange={this._onChangeDimensions}
                placeholder="30"
                label="Dimensiones"
                dimensions
              />
            </Column>
          </Row>
        )}
        <Row>
          <Column fluid>
            <Button
              type="link"
              onClick={() =>
                this.setState({
                  manualPackage: !this.state.manualPackage,
                  packageSelectedIndex: -1,
                  packageSelected: null,
                  newPackage: {
                    id: "",
                    alias: "",
                    type: "",
                    dimensions: {
                      length: "",
                      width: "",
                      height: "",
                    },
                    description: "",
                    weight: "",
                    insuredAmount: "",
                  },
                })
              }
            >
              {this.state.manualPackage
                ? "Seleccionar uno de mis empaques guardados"
                : "Ingresar medidas manualmente"}
            </Button>
          </Column>
        </Row>
        <Row>
          <Column xs={3} fluid>
            <Input
              suffix="kg"
              type="text"
              placeholder="100.0"
              label="Peso"
              {...this.generate("weight")}
            />
          </Column>
        </Row>
        <Row>
          <Column fluid>
            <Text type="microHeader">¿Qué estás enviando?</Text>
          </Column>
        </Row>
        <Row>
          <Column xs={6} fluid style={{ paddingRight: "8px" }}>
            <Input
              type="text"
              placeholder="Playeras serigrafiadas"
              label="Descripción del contenido"
              maxLength="30"
              {...this.generate("description")}
            />
          </Column>
          <Column xs={4} fluid>
            <Input
              type="text"
              {...this.generate("insuredAmount")}
              required
              placeholder="1500.50"
              label="Valor del paquete"
            />
          </Column>
        </Row>
        <BottomContainer>
          <Row divisions={20}>
            <Column style={{ textAlign: "right" }} fluid>
              <Button
                className=""
                onClick={() => this.save()}
                loading={this.state.newPackage.isLoading}
              >
                Guardar
              </Button>
            </Column>
          </Row>
        </BottomContainer>
      </ContentAdapter>
    );
  }
}

const mapStateToProps = (state) => ({
  packages: state.packages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createPackage,
      toggleSideBar,
      setDefaultPackage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AutomationPackages);
