import React from "react";
import axios from "axios";
import styled from "styled-components";
import { boundMethod } from "autobind-decorator";
import { Row, Column } from "hedron";

import Button, { ActionButtonGroup } from "../../components/ButtonGroup";
import Text, { Title, P } from "../../components/Text";
import Input, { Label, Required, HelpText } from "../../components/Input";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Tabs, { Tab } from "../../components/Tabs";
import Card, { Divider } from "../../components/Card";
import NewBadge from "../../components/Badge/insuranceBadge.js";
import Badge, { MarketplaceBadge } from "../../components/Badge";
import EmpyState from "../../components/EmptyState";
import "./style.scss";
import NewTable from "../../components/NewTable";

import Dropdown from "../../components/Dropdown";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import WrapperSideBar from "../../components/WrapperSideBar";
import {
  ContentAdapter,
  BottomContainer
} from "../../components/WrapperSideBar/styledObjects";
import Skeleton from "react-loading-skeleton";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle
} from "../../modules/CollapsibleBar/actions";
import {
  NEW_PACKAGE,
  PACKAGE_DETAILS,
  EDIT_ADDRESS
} from "../../modules/CollapsibleBar/sideBarTypes";
import colors from "../../utils/colors";
import Select from "react-select";
import "./style.css";
import ThreeDotsDropdown from "../../components/Dropdown/ThreeDotsDropdown/index";
import edit_icon from "../../utils/icons/edit.svg";
import delete_icon from "../../utils/icons/delete.svg";
import { showLoader, hideLoader } from "../../modules/loader";
import { showMessage, hideMessage } from "../../modules/message";
import Message from "../../components/Message";
import Dropzone from "../../components/Dropzone";
import { getAllServices } from "../../axios/services";
import { CheckboxGroup, Checkbox } from "../../components/Checkbox";
import Switch from "../../components/Switch";
import Alert from "../../components/Alert";
import Filters from "./Filters";

const NEW_ACCOUNT = "NEW_ACCOUNT";
const ACCOUNT_DETAILS = "ACCOUNT_DETAILS";
const ACCOUNT_EDIT = "ACCOUNT_EDIT";
const ROL_EDIT = "ROL_EDIT";

const EDIT_RATE = "EDIT_RATE";

const StyledConnected = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  display: inline-block;
  background: #00ee7a;
  margin-left: 6px;
`;

const StyledDisconnected = StyledConnected.extend`
  background: #d8d8d8;
`;

const loadingSideBarContent = () => (
  <div>
    <ContentAdapter>
      <Row>
        <Column fluid>
          <Text type="sidebarInfoTitle">Pin</Text>
          <Skeleton height={20} />
        </Column>
        <Column fluid>
          <Text type="sidebarInfoTitle">Contraseña</Text>
          <Skeleton height={20} />
        </Column>
        <Column fluid>
          <Text type="sidebarInfoTitle">Correo</Text>
          <Skeleton height={20} />
        </Column>
        <Column fluid>
          <Text type="sidebarInfoTitle">Permisos</Text>
          <Skeleton height={20} />
        </Column>
      </Row>
    </ContentAdapter>
  </div>
);
const optionsPermisos = [
  { value: "shipments-create", label: "Contador" },
  { value: "shipments-read", label: "Operador de Piso" }
];
class manageShipments extends React.Component {
  constructor(props) {
    super(props);
    this.inputOpen = this.inputOpen.bind(this);
    this.filterOpen = this.filterOpen.bind(this);
  }

  inputOpen = () => {
    this.setState({
      filtersOn: 0
    });
  };

  filterOpen = () => {
    this.setState({
      filtersOn: 1
    });
  };

  state = {
    permisosValue: [],
    permisosError: "",
    selectedPermisos: "",
    singleDetail: "",
    services: [],
    countrys: ["México", "Peru", "Chile"],
    name: "",
    nameError: "",
    apellidos: "",
    apellidosError: "",
    mail: "",
    mailError: "",
    pass: "",
    passError: "",
    phone: "",
    phoneError: "",
    passConfirm: "",
    passConfirmError: "",
    setLoading: false,
    detailOpen: false,
    isEditing: false,
    editPass: false,
    importFile: "",
    rateMinPrice: false,
    selectedRateCarrier: "",
    selectedRateService: "",
    rateCarrierError: "",
    rateProviderError: "",
    servicesStrings: [""],
    userToEditConfig: null,
    currentTab: 0,
    filtersOn: 0
  };

  carriers = [
    {
      object_id: 1,
      name: "Estafeta"
    },
    {
      object_id: 2,
      name: "UPS"
    },
    {
      object_id: 4,
      name: "Fedex"
    }
  ];

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateFields() {
    const errorMsgs = {
      required: "Este campo es requerido",
      max: "No puede ser mayor al peso máximo",
      invalidMail: "Correo invalido",
      invalidPass: "Las contraseñas no coinciden",
      invalidPassLenght: "La contraseña debe tener mínimo 6 caracteres"
    };

    let flag = true;
    if (this.state.name == "") {
      flag = false;
      this.setState({ nameError: errorMsgs.required });
    } else {
      this.setState({ nameError: "" });
    }
    if (this.state.apellidos == "") {
      flag = false;
      this.setState({ apellidosError: errorMsgs.required });
    } else {
      this.setState({ apellidosError: "" });
    }
    if (this.state.phone == "") {
      flag = false;
      this.setState({ phoneError: errorMsgs.required });
    } else {
      this.setState({ phoneError: "" });
    }
    if (!this.state.isEditing) {
      if (this.state.pass == "") {
        flag = false;
        this.setState({ passError: errorMsgs.required });
      } else if (this.state.pass.length >= 6) {
        this.setState({ passError: "" });
      } else {
        flag = false;
        this.setState({ passError: errorMsgs.invalidPassLenght });
      }
      if (this.state.passConfirm == "") {
        flag = false;
        this.setState({ passConfirmError: errorMsgs.required });
      } else if (this.state.pass == this.state.passConfirm) {
        this.setState({ passConfirmError: "" });
      } else {
        flag = false;
        this.setState({ passConfirmError: errorMsgs.invalidPass });
      }
    } else if (this.state.isEditing && this.state.editPass) {
      if (this.state.pass == "") {
        flag = false;
        this.setState({ passError: errorMsgs.required });
      } else if (this.state.pass.length >= 6) {
        this.setState({ passError: "" });
      } else {
        flag = false;
        this.setState({ passError: errorMsgs.invalidPassLenght });
      }
      if (this.state.passConfirm == "") {
        flag = false;
        this.setState({ passConfirmError: errorMsgs.required });
      } else if (this.state.pass == this.state.passConfirm) {
        this.setState({ passConfirmError: "" });
      } else {
        flag = false;
        this.setState({ passConfirmError: errorMsgs.invalidPass });
      }
    }

    if (this.state.permisosValue.length == 0) {
      flag = false;
      this.setState({ permisosError: errorMsgs.required });
    } else {
      this.setState({ permisosError: "" });
    }

    if (this.state.mail == "") {
      flag = false;
      this.setState({ mailError: errorMsgs.required });
    } else if (this.validateEmail(this.state.mail)) {
      this.setState({ mailError: "" });
    } else {
      flag = false;
      this.setState({ mailError: errorMsgs.invalidMail });
    }

    return flag;
  }

  componentWillMount() {
    this.props.hideMessage();
    this.props.toggleSideBar(false);
    getAllServices(({ data }) => {
      const { services } = data;
      if (services) {
        this.setState({
          servicesStrings: services
        });
      }
    });
    const array_carriers = [];

    for (const i in this.props.integrations.carriers) {
      array_carriers.push([i, this.props.integrations.carriers[i]]);
    }
    this.setState({ array_carriers });
  }

  clearSb = () => {
    this.setState({
      isEditing: false,
      editPass: false,
      detailOpen: false,
      name: "",
      nameError: "",
      apellidos: "",
      apellidosError: "",
      mail: "",
      mailError: "",
      pass: "",
      passError: "",
      passConfirm: "",
      passConfirmError: "",
      phone: "",
      phoneError: "",
      permisosValue: [],
      permisosError: "",
      userToEditConfig: null,
      rateMinPrice: false,
      selectedRateService: "",
      returnRatesList: 0,
      editing_id: null
    });
  };

  toggleDeleteAccount(context) {
    return (
      <Alert
        title="Comparte tracking en tiempo real"
        titleMargin="small"
      ></Alert>
    );
  }

  toggleEditAccount(context) {
    this.props.toggleSideBar(true, `Editar Cuenta`, ACCOUNT_EDIT);

    this.setState({
      detailOpen: false
    });
  }

  toggleEditRol(context) {
    this.props.toggleSideBar(true, `Editar Rol`, ROL_EDIT);

    this.setState({
      detailOpen: false
    });
  }

  toggleDeleteRol(context) {
    this.props.toggleSideBar(true, `Editar Rol`, ROL_EDIT);

    this.setState({
      detailOpen: false
    });
  }

  rolesOptions = [
    {
      function: () => this.toggleEditRol(this),
      label: "Editar rol",
      image: edit_icon
    },
    {
      label: "Eliminar rol",
      image: delete_icon
    }
  ];

  _renderSidebarTitleExtraContent = () => {
    const addressOptions = [
      {
        function: () => this.toggleEditAccount(this),
        label: "Editar cuenta",
        image: edit_icon
      },
      {
        function: () => this.toggleDeleteAccount(this),
        label: "Eliminar cuenta",
        image: delete_icon
      }
    ];
    if (this.state.detailOpen) {
      return <ThreeDotsDropdown linksToRender={addressOptions} />;
    }
    return <div />;
  };

  _renderSidebarDetailsContent = data => (
    <div>
      <ContentAdapter className="detailed-package">
        <Row>
          <Column fluid>
            <Text type="sidebarInfoTitle">Pin</Text>
            <P>0001</P>
          </Column>
          <Column fluid>
            <Text type="sidebarInfoTitle">Contraseña</Text>
            <P>XMXMXXMXMXM</P>
          </Column>
          <Column fluid>
            <Text type="sidebarInfoTitle">Rol</Text>
            <P>Operador de Piso</P>
          </Column>
          <Column fluid>
            <Text type="sidebarInfoTitle">Permisos</Text>
            <P>
              <Badge type="correct" className="top">
                Envios Crear
              </Badge>
            </P>
          </Column>
        </Row>
      </ContentAdapter>
    </div>
  );

  @boundMethod
  handleRateServiceChange(val) {
    this.setState({
      selectedRateService: val,
      selectedRateServiceText: this.state.servicesStrings[val]
    });
  }

  renderSidebarContent = () => {
    const ratesFields = (
      <div>
        <Input type="text" placeholder="Pin" label="Pin" />
        <Input
          type="text"
          required
          placeholder="Contraseña"
          label="Contraseña (recomendada)"
        />

        <div>
          <Label style={{ marginTop: "16px" }}>
            Roles <Required />
          </Label>
          <Select
            isMulti
            value={this.state.permisosValue}
            onChange={this.handlePermisosDrop}
            noOptionsMessage={() => "Sin Opciones"}
            isSearchable
            placeholder="Seleccionar Rol"
            closeMenuOnSelect={false}
            options={optionsPermisos}
            className={`search-select accounts ${this.state.permisosError !=
              "" && "error"}`}
          />
          {this.state.permisosError != "" && (
            <HelpText>Este campo es requerido</HelpText>
          )}
        </div>
      </div>
    );
    return (
      <div>
        <ContentAdapter>
          {ratesFields}

          <BottomContainer>
            <Row divisions={20}>
              <Column style={{ textAlign: "right" }} fluid>
                <Button
                  loading={this.state.setLoading}
                  onClick={() => {
                    this.state.isEditing
                      ? this.setUpdateAccount()
                      : this.setNewAccount();
                  }}
                  className=""
                >
                  Guardar
                </Button>
              </Column>
              <Column xs={1} xsShift={1} style={{ textAlign: "right" }} fluid />
            </Row>
          </BottomContainer>
        </ContentAdapter>
      </div>
    );
  };

  renderSidebarContentRol = () => {
    const ratesFields = (
      <div>
        <Input type="text" placeholder="Nombre" label="Nombre" />

        <div>
          <Label style={{ marginTop: "16px" }}>
            Permisos <Required />
          </Label>
          <Select
            isMulti
            value={this.state.permisosValue}
            onChange={this.handlePermisosDrop}
            noOptionsMessage={() => "Sin Opciones"}
            isSearchable
            placeholder="Seleccionar Permisos"
            closeMenuOnSelect={false}
            options={optionsPermisos}
            className={`search-select accounts ${this.state.permisosError !=
              "" && "error"}`}
          />
          {this.state.permisosError != "" && (
            <HelpText>Este campo es requerido</HelpText>
          )}
        </div>

        <Input
          type="text"
          required
          placeholder="Descripcion"
          label="Descripcion "
        />
      </div>
    );
    return (
      <div>
        <ContentAdapter>
          {ratesFields}

          <BottomContainer>
            <Row divisions={20}>
              <Column style={{ textAlign: "right" }} fluid>
                <Button
                  loading={this.state.setLoading}
                  onClick={() => {
                    this.state.isEditing
                      ? this.setUpdateAccount()
                      : this.setNewAccount();
                  }}
                  className=""
                >
                  Guardar
                </Button>
              </Column>
              <Column xs={1} xsShift={1} style={{ textAlign: "right" }} fluid />
            </Row>
          </BottomContainer>
        </ContentAdapter>
      </div>
    );
  };

  openSideBar() {
    this.props.toggleSideBar(true, "Añadir Cuenta", NEW_ACCOUNT);
  }

  openSideBarRate(userToEditConfig, context) {
    let rateMinPrice = false;
    let returnRatesList = 0;
    let selectedRateService = null;
    if (
      userToEditConfig.shops &&
      userToEditConfig.shops[0] &&
      userToEditConfig.shops[0]
    ) {
      if (userToEditConfig.shops[0].default_service_level === null) {
        rateMinPrice = true;
      } else {
        context.state.servicesStrings.forEach((element, index) => {
          if (element == userToEditConfig.shops[0].default_service_level) {
            selectedRateService = index;
          }
        });
      }
      if (userToEditConfig.shops[0].retrieve_all_rates) {
        returnRatesList = 0;
      }
    }

    context.setState({
      userToEditConfig,
      rateMinPrice,
      returnRatesList,
      selectedRateService
    });
    context.props.toggleSideBar(
      true,
      `Configuración - ${userToEditConfig.email}`,
      EDIT_RATE
    );
  }

  openSideBarDetails(account, context) {
    context.setState({
      sidebarLoading: true
    });
    context.setState({
      singleDetail: account,
      sidebarLoading: false,
      detailOpen: true
    });
    context.props.toggleSideBar(true, account.email, ACCOUNT_DETAILS);
  }

  _renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
      case NEW_ACCOUNT:
        return <div>{this.renderSidebarContent()}</div>;
      case EDIT_RATE:
        return <div>{this.renderSideBarRate()}</div>;
      case ACCOUNT_DETAILS:
        return (
          <div>
            {this.state.sidebarLoading ? (
              <div>{loadingSideBarContent()}</div>
            ) : (
              <div>{this._renderSidebarDetailsContent()}</div>
            )}
          </div>
        );
      case ACCOUNT_EDIT:
        return <div>{this.renderSidebarContent()}</div>;
      case ROL_EDIT:
        return <div>{this.renderSidebarContentRol()}</div>;
      default:
        return <div />;
    }
  };

  render() {
    const { selected, currentTab, filtersOn } = this.state;

    const data = [
      {
        orden: "001",
        fecha: "20-05-2019",
        destinatario: "David morales ramirez",
        destino: "Yautepec morelos",
        servicio: "Redpack",
        estatus: "Guia sin Pago",
        total: "$125.28 MXN"
      },
      {
        orden: "001",
        fecha: "20-05-2019",
        destinatario: "David morales ramirez",
        destino: "Yautepec morelos",
        servicio: "Redpack",
        estatus: "Guia sin Pago",
        total: "$125.28 MXN"
      },
      {
        orden: "001",
        fecha: "20-05-2019",
        destinatario: "David morales ramirez",
        destino: "Yautepec morelos",
        servicio: "Redpack",
        estatus: "Guia sin Pago",
        total: "$125.28 MXN"
      },
      {
        orden: "001",
        fecha: "20-05-2019",
        destinatario: "David morales ramirez",
        destino: "Yautepec morelos",
        servicio: "Redpack",
        estatus: "Guia sin Pago",
        total: "$125.28 MXN"
      },
      {
        orden: "001",
        fecha: "20-05-2019",
        destinatario: "David morales ramirez",
        destino: "Yautepec morelos",
        servicio: "Redpack",
        estatus: "Guia sin Pago",
        total: "$125.28 MXN"
      },
      {
        orden: "001",
        fecha: "20-05-2019",
        destinatario: "David morales ramirez",
        destino: "Yautepec morelos",
        servicio: "Redpack",
        estatus: "Guia sin Pago",
        total: "$125.28 MXN"
      }
    ];
    const columns = [
      {
        name: "N° Orden",
        selector: "orden",
        sortable: true,
        cell: row => (
          <div>
            <p># {row.orden} </p>
            <MarketplaceBadge color={"default"} />
          </div>
        )
      },
      {
        name: "Fecha Alta",
        selector: "fecha",
        sortable: true,
        cell: row => (
          <div>
            <p> {row.fecha} </p>
            <p className="minitext"> Hace 35 dias </p>
          </div>
        )
      },
      {
        name: "Destinatario",
        selector: "destinatario",
        sortable: true,
        cell: row => row.destinatario
      },
      {
        name: "Destino",
        selector: "destino",
        sortable: true,
        cell: row => (
          <div>
            <p> {row.destino} </p>
            <p className="minitext"> Hace 35 dias </p>
          </div>
        )
      },
      {
        name: "Servicio",
        selector: "servicio",
        sortable: true,
        cell: row => (
          <div>
            <p> {row.servicio} </p>
            <p className="minitext"> Estandar </p>
          </div>
        )
      },
      {
        name: "Estatus",
        selector: "estatus",
        sortable: true,
        cell: row => {
          return <NewBadge type={"warning"}>{row.estatus}</NewBadge>;
        }
      },
      {
        name: "Total",
        selector: "total",
        sortable: true,
        cell: row => row.total
      }
    ];

    return (
      <div>
        <WrapperSideBar
          handleClose={this.clearSb}
          title_more={this._renderSidebarTitleExtraContent()}
        >
          {this.state.sideBarContent}
          {this._renderSidebarContent()}
        </WrapperSideBar>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: "right" }} fluid>
            <Menu />
          </Column>
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Header>
              <Row divisions={18}>
                <Column xs={4} fluid>
                  <Title type="titleCard">Envíos </Title>
                </Column>
                <Column
                  xs={14}
                  xsShift={0}
                  style={{
                    textAlign: "right"
                  }}
                  fluid
                >
                  <ActionButtonGroup
                    className="last new-shipment-btn"
                    currentTab={this.state.currentTab}
                    handler={this.handler}
                    inputOpen={this.inputOpen}
                    filterOpen={this.filterOpen}
                    filtersOn={this.state.filtersOn}
                    initialLoader
                  >
                    Nuevo envío
                  </ActionButtonGroup>
                </Column>
              </Row>
            </Header>

            {filtersOn === 0 && (
              <Row>
                <Column xs={12} md={4} fluid>
                  <Input
                    parentClassName="filter-input"
                    type="text"
                    focus
                    placeholder="No de Orden de Envío"
                  />
                </Column>
                <Column xs={12} md={4} fluid>
                  <Button className="no-margin">Buscar</Button>
                </Column>
              </Row>
            )}

            {filtersOn === 1 && (
              <div className="data-buttons">
                <Filters
                  noMarginFilter
                  filterData={this.filterData}
                  countries={this.carriers}
                />
              </div>
            )}

            <Row divisions={20}>
              <Column xs={1} fluid />
              <Column xs={18} md={18} fluid>
                <Tabs
                  id="integrations"
                  right
                  selected={currentTab}
                  onChange={selected => this.setState({ currentTab: selected })}
                >
                  <Tab>En Camino</Tab>
                  <Tab>Entregados</Tab>
                </Tabs>
                <div>
                  {currentTab === 0 && (
                    <NewTable
                      marginTop={"margin-topHeader"}
                      shipping={false}
                      columns={columns}
                      data={data}
                    />
                  )}

                  {currentTab === 1 && (
                    <NewTable
                      marginTop={"margin-topHeader"}
                      shipping={false}
                      columns={columns}
                      data={data}
                    />
                  )}
                </div>
              </Column>
              <Column xs={1} md={6} fluid />
            </Row>
            <Row divisions={20}>
              <Column xs={1} fluid />
              <Column xs={18} fluid>
                {this.props.message.show && (
                  <Message
                    color={this.props.message.messageType}
                    onClose={this.props.hideMessage}
                  >
                    {this.props.message.text}
                  </Message>
                )}
              </Column>
              <Column xs={1} fluid />
            </Row>
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid />
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid></Column>
          <Column xs={1} fluid />
        </Row>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  sidebar: state.sidebar,
  message: state.message,
  integrations: state.integrations
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleSideBar,
      changeSidebarTitle,
      showMessage,
      hideMessage,
      showLoader,
      hideLoader
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(manageShipments);
