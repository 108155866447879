// @flow

import axios from "axios";
import type { Dispatch as ReduxDispatch } from "redux";
import Scriptly from "scriptly";
import { push } from "react-router-redux";
import { showLoader, hideLoader } from "./loader";
import { showError } from "./error";
import { showMessage } from "./message";
import { getFirstElement } from "../utils/global_functions";

export const shopsUrl = "/api/shops";
export const webhooksURL = "/api/webhooks";
export const carriersURL = "/api/users/carriers";

export const SET_USER_SHOPS = "SET_USER_SHOPS";
export const SET_USER_WEBHOOKS = "SET_USER_WEBHOOKS";
export const SET_CREATED_TYPE = "SET_CREATED_TYPE";
export const SET_USER_CARRIERS = "SET_USER_CARRIERS";
export const SET_ACTIVE_CARRIERS = "SET_ACTIVE_CARRIERS";

type Webhook = {
  object_id: number,
  owner_id: number,
  endpoint: string,
  created_at: string,
};

type Carrier = {
  id: number,
  provider_id: number,
  user_id: number,
  credentials: string,
  created_at: string,
  updated_at: string,
};

type Shop = {
  object_id: number,
  owner_id: number,
  marketplace: string,
  name: string,
  url: string,
  api_key?: string,
  api_secret?: string,
  consumer_key?: string,
  consumer_secret?: string,
};

type State = {
  +shops?: Array<Shop> | null,
  +webhooks?: Array<Webhook> | null,
  +createdType?: string | null,
};

type Action_SET_USER_SHOPS = {
  type: SET_USER_SHOPS,
  shops: ?Array<Shop>,
};

type Action_SET_USER_WEBHOOK = {
  type: SET_USER_WEBHOOKS,
  webhooks: ?Array<Webhook>,
};

type Action = Action_SET_USER_WEBHOOK | Action_SET_USER_SHOPS;

type Dispatch = ReduxDispatch<Action>;

const initialState: State = {
  shops: null,
  webhooks: null,
  createdType: null,
  carriers: null,
  activeCarriers:null
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case SET_USER_SHOPS:
      return {
        ...state,
        shops: action.shops,
      };
    case SET_USER_WEBHOOKS:
      return {
        ...state,
        webhooks: action.webhooks,
      };
    case SET_USER_CARRIERS:
      return {
        ...state,
        carriers: action.carriers,
      };
    case SET_CREATED_TYPE:
      return {
        ...state,
        createdType: action.createdType,
      };
    case SET_ACTIVE_CARRIERS:
      return {
        ...state,
        activeCarriers: action.activeCarriers,
      }; 
    default:
      return state;
  }
};

export const deleteShop = (id: number) => (dispatch: Dispatch) => {
  dispatch(showLoader("basic"));
  return axios
    .delete(`${shopsUrl}/${id}`)
    .then(() => {
      dispatch(getUserShopsDelete());
    })
    .catch((err) => {
      dispatch(hideLoader());
      dispatch(showError("Error al eliminar, la integracion"));
    });
};

export const deleteCarrier = (id: number) => (dispatch: Dispatch) => {
  dispatch(showLoader("basic"));
  return axios
    .delete(`${carriersURL}/${id}`)
    .then(() => {
      dispatch(getUserCarriersOnUpdate());
    })
    .catch((err) => {
      dispatch(hideLoader());
      dispatch(showError("Error al eliminar, la integracion"));
    });
};

export const toggleCarrierStatus = (id, status) => (dispatch: Dispatch) =>
  axios
    .put(`${carriersURL}/${id}`, {
      active: status,
    })
    .then(() => {
      dispatch(getUserCarriersOnUpdate());
    })
    .catch((err) => {
      dispatch(showError("Error al eliminar, la integracion"));
    });
export const getShopifyOauth =
  (store_url: string, store_domain: string) => (dispatch: Dispatch) => {
    dispatch(showLoader("basic"));
    dispatch(setCreatedType("shopify"));
    return axios
      .get(
        `/api/shopify/oauth?store_url=${store_url}&store_domain=${store_domain}`
      )
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(showError(err.response.data.error));
      });
  };

export const getWoocommerceOauth =
  (store_url: string, store_name: string) => (dispatch: Dispatch) => {
    dispatch(showLoader("basic"));
    dispatch(setCreatedType("woocommerce"));
    return axios
      .get(
        `/api/woocommerce/oauth?store_url=${store_url}&store_name=${store_name}&return_url=${window.location.href}`
      )
      .then((response) => {
        window.location.replace(response.data.url);
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(showError(err.response.data.error));
      });
  };

export const createPrestashop =
  (name: string, url: string, api_key: string) => (dispatch: Dispatch) => {
    dispatch(showLoader("basic"));
    dispatch(setCreatedType("prestashop"));
    return axios
      .post("/api/prestashop/auth", {
        name,
        url,
        api_key,
      })
      .then((response) => {
        dispatch(getUserIntegrations());
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(showError(err.response.data.error));
      });
  };

export const createMagento =
  (name: string, url: string, api_key: string) => (dispatch: Dispatch) => {
    dispatch(showLoader("basic"));
    dispatch(setCreatedType("magento"));
    return axios
      .post("/api/magento/oauth", {
        name,
        url,
        api_key,
      })
      .then((response) => {
        dispatch(getUserIntegrations());
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(showError(err.response.data.error));
      });
  };

export const setCreatedType = (createdType: string | null) => ({
  type: SET_CREATED_TYPE,
  createdType,
});

export const setUserShops = (shops: Array<Shop>) => ({
  type: SET_USER_SHOPS,
  shops,
});

function delay(t, v) {
  return new Promise((resolve) => {
    setTimeout(resolve.bind(null, v), t);
  });
}

export const getUserIntegrations =
  (type: string = null) =>
  (dispatch: Dispatch) => {
    if (type) {
      dispatch(showLoader("creating_integrations", type));
      delay(5000).then(() => {
        dispatch(hideLoader());
        dispatch(push("/"));
      });
    } else {
      dispatch(showLoader("integrations"));
    }
    return axios
      .all([getUserWebhooks(), getUserShops(), getUserCarriers()])
      .then(
        axios.spread((webhooksResponse, shopsResponse, carriersResponse) => {
          dispatch(setUserShops(shopsResponse.data));
          dispatch(setUserWebhooks(webhooksResponse.data.webhooks));
          dispatch(setUserCarriers(carriersResponse.data));
          dispatch(getActiveCarriers());
        })
      )
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        if (!type) dispatch(hideLoader());
      });
  };

const setUserWebhooks = (webhooks: Array<Webhook>) => ({
  type: SET_USER_WEBHOOKS,
  webhooks,
});

const setUserCarriers = (carriers: Array<Carriers>) => {
  let translatedCarriers = null;
  if (process.env.NODE_ENV === "development") {
    translatedCarriers = {
      estafeta: getFirstElement(carriers.filter((x) => x.id === 7)),
      fedex: getFirstElement(carriers.filter((x) => x.id === 1)),
      ups: getFirstElement(carriers.filter((x) => x.id === 8)),
      redpack: getFirstElement(carriers.filter((x) => x.id === 4)),
      olva: getFirstElement(carriers.filter((x) => x.id === 12)),
      guatex: getFirstElement(carriers.filter((x) => x.id === 16)),
      servientrega: getFirstElement(carriers.filter((x) => x.id === 15)),
      urbano: getFirstElement(carriers.filter((x) => x.id === 13)),
      chazki: getFirstElement(carriers.filter((x) => x.id === 9)),
      shippify: getFirstElement(carriers.filter((x) => x.id === 10)),
      blueexpress: getFirstElement(carriers.filter((x) => x.id === 11)),
      yovoy: getFirstElement(carriers.filter((x) => x.id === 19)),
      pap: getFirstElement(carriers.filter((x) => x.id === 18)),
      starken: getFirstElement(carriers.filter((x) => x.id === 17)),
      dostavista: getFirstElement(carriers.filter((x) => x.id === 14)),
      kangou: getFirstElement(carriers.filter((x) => x.id === 2)),
      costa_rica: getFirstElement(carriers.filter((x) => x.id === 20)),
      bicimensajero: getFirstElement(carriers.filter((x) => x.id === 5)),
      flechaamarilla: getFirstElement(carriers.filter((x) => x.id === 23)),
      servientregapa: getFirstElement(
        carriers.filter((x) => x.name === "Servientrega PA")
      ),
      urbanochile: getFirstElement(
        carriers.filter((x) => x.name === "Urbano CL")
      ),
      esameday: getFirstElement(carriers.filter((x) => x.name === "eSameDay")),
      minutos: getFirstElement(carriers.filter((x) => x.name === "99 Minutos")),
      paquetexpress: getFirstElement(carriers.filter((x) => x.id === 25)),
      laterminal: getFirstElement(carriers.filter((x) => x.id === 33)),
      savarexpress: getFirstElement(
        carriers.filter((x) => x.name === "Savar Express")
      ),
      uenvios: getFirstElement(carriers.filter((x) => x.name === "U Envios")),
      fruno: getFirstElement(carriers.filter((x) => x.name === "Fruno")),
        distribucionxcb:getFirstElement(carriers.filter((x) => x.name === "Distribución XCB")),
        servientregaconflete:getFirstElement(carriers.filter((x) => x.name === "Servientrega Con Flete")),
        mybox:getFirstElement(carriers.filter((x) => x.name === "My Box")),
        fastlogicsa:getFirstElement(carriers.filter((x) => x.name === "Fast Logic SA")),
    };
  } else {
    translatedCarriers = {
      estafeta: getFirstElement(carriers.filter((x) => x.id === 7)),
      fedex: getFirstElement(carriers.filter((x) => x.id === 1)),
      ups: getFirstElement(carriers.filter((x) => x.id === 8)),
      redpack: getFirstElement(carriers.filter((x) => x.id === 4)),
      paquetexpress: getFirstElement(carriers.filter((x) => x.id === 25)),
      olva: getFirstElement(carriers.filter((x) => x.id === 11)),
      guatex: getFirstElement(carriers.filter((x) => x.id === 15)),
      servientrega: getFirstElement(carriers.filter((x) => x.id === 10)),
      urbano: getFirstElement(carriers.filter((x) => x.id === 12)),
      chazki: getFirstElement(carriers.filter((x) => x.id === 9)),
      shippify: getFirstElement(carriers.filter((x) => x.id === 13)),
      blueexpress: getFirstElement(carriers.filter((x) => x.id === 14)),
      yovoy: getFirstElement(carriers.filter((x) => x.id === 18)),
      pap: getFirstElement(carriers.filter((x) => x.id === 19)),
      starken: getFirstElement(carriers.filter((x) => x.id === 16)),
      kangou: getFirstElement(carriers.filter((x) => x.id === 2)),
      costa_rica: getFirstElement(carriers.filter((x) => x.id === 20)),
      bicimensajero: getFirstElement(carriers.filter((x) => x.id === 5)),
      flechaamarilla: getFirstElement(carriers.filter((x) => x.id === 22)),
      servientregapa: getFirstElement(
        carriers.filter((x) => x.name === "Servientrega PA")
      ),
      urbanochile: getFirstElement(
        carriers.filter((x) => x.name === "Urbano CL")
      ),
      esameday: getFirstElement(carriers.filter((x) => x.name === "eSameDay")),
      minutos: getFirstElement(carriers.filter((x) => x.name === "99 Minutos")),
      laterminal: getFirstElement(carriers.filter((x) => x.id === 33)),
      savarexpress: getFirstElement(
        carriers.filter((x) => x.name === "Savar Express")
      ),
      uenvios: getFirstElement(carriers.filter((x) => x.name === "U Envios")),
      fruno: getFirstElement(carriers.filter((x) => x.name === "Fruno")),
        distribucionxcb:getFirstElement(carriers.filter((x) => x.name === "Distribución XCB")),
        servientregaconflete:getFirstElement(carriers.filter((x) => x.name === "Servientrega Con Flete")),
        mybox:getFirstElement(carriers.filter((x) => x.name === "My Box")),
        fastlogicsa:getFirstElement(carriers.filter((x) => x.name === "Fast Logic SA")),
    };
  }
  return {
    type: SET_USER_CARRIERS,
    carriers: translatedCarriers,
  };
};

export const createWebhook =
  (url: string, newGuides, updates) => (dispatch: Dispatch) =>
    axios
      .post(webhooksURL, {
        endpoint: url,
        notif_label_creation: newGuides,
        notif_shipment_update: updates,
      })
      .then((response) => {
        dispatch(showMessage("success", "Webhook creado exitosamente."));
        dispatch(getUserIntegrations());
      })
      .catch((err) => {
        dispatch(showError(err.response.data.error));
      });

export const deleteWebhook = (id: number) => (dispatch: Dispatch) => {
  dispatch(showLoader("integrations"));
  return axios
    .delete(`${webhooksURL}/${id}`)
    .then(() => {
      dispatch(showMessage("success", "Webhook eliminado exitosamente."));
      dispatch(getUserIntegrations());
    })
    .catch((err) => {
      dispatch(showError(err.response.data.error));
    })
    .then(() => {
      dispatch(hideLoader());
    });
};

const getUserWebhooks = () => axios.get(webhooksURL);

const getUserShops = () => axios.get(shopsUrl);

const getUserCarriers = () => axios.get(carriersURL);

const getUserShopsDelete = () => (dispatch) =>
  axios
    .get(shopsUrl)
    .then((result) => {
      dispatch(setUserShops(result.data));
      dispatch(hideLoader());
    })
    .catch((err) => {
      dispatch(hideLoader());
      dispatch(showError(err, err.message));
    });

const getUserCarriersOnUpdate = () => (dispatch) =>
  axios
    .get(carriersURL)
    .then((result) => {
      dispatch(setUserCarriers(result.data));
      dispatch(hideLoader());
    })
    .catch((err) => {
      dispatch(hideLoader());
      dispatch(showError(err, err.message));
    });

export const storeCarrierCredentials =
  (provider_id, data) => (dispatch: Dispatch) =>
    axios
      .post(`/api/users/carriers/${provider_id}`, data)
      .then((response) => {
        dispatch(showMessage("success", "Datos guardados correctamente."));
        dispatch(getUserCarriersOnUpdate());
      })
      .catch((err) => {
        console.log(err)
        dispatch(showError(err.response.data.error));
      });

export const setTiendaNubeIntegration = (code) => (dispatch) => {
  return axios
    .post(`/api/tiendanube/auth?code=${code}`)
    .then((response) => {
      dispatch(showMessage("success", "Integración realizada con éxito"));
      dispatch(getUserIntegrations());
    })
    .catch((err) => {
      dispatch(showError(err.response.data.error));
    });
};

const setActiveCarriers = (activeCarriers) => ({
  type: SET_ACTIVE_CARRIERS,
  activeCarriers,
});

export const getActiveCarriers = () => (dispatch) => {
  return axios
    .get("/api/providers?active=true")
    .then((response) => {
      dispatch(setActiveCarriers(response.data));
    })
    .catch((err) => {
      console.log(err)
      dispatch(showError(err.response.data.error));
    });
};
