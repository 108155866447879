// @flow
import React, { useState } from 'react';
import './style.css';

export const Button = ({ icon, children, color, onClick }) => (
  <div onClick={onClick} className={`shipment-item-btn ${color} ${children && 'children'}`}>
    <img src={icon} />
    {children && <p>{children}</p>}
  </div>
);
