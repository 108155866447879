import React from 'react';
import { Row, Column } from 'hedron';
import Moment from 'react-moment';
import Text, { Title, P } from '../Text';
import Table, { Th, Tr, Td } from '../Table';
import 'moment-timezone';

import { numberWithCommas } from '../../utils/global_functions';
import paypal_img from './Icons/PayPal_logo.svg';
import card_placeholder from './Icons/mastercard.svg';

const totalIva = (amount, type) => type === 'CLIENT_ADVANCE_PAYMENT' ? numberWithCommas(((amount ) * 0.16).toFixed(2)) :numberWithCommas(((amount / 1.16) * 0.16).toFixed(2));
const subtotal = (amount, type) => type === 'CLIENT_ADVANCE_PAYMENT' ? numberWithCommas(amount - ((amount ) * 0.16)):numberWithCommas((amount / 1.16).toFixed(2));

const InvoiceDetail = props => (
  <div className="invoice-detail-sd">
    {props.invoice && (
      <div>
        {props.invoice.invoice_id && (
          <div>
            <Row className="header-id">
              <Column xs={5} fluid>
                <Text type="sidebarInfoTitle" className="title-display">
                  Factura
                </Text>
                <P>{props.invoice.invoice_id}</P>
                
              </Column>
              <Column xs={3} fluid>
                <Text type="sidebarInfoTitle">Fecha de emisión</Text>
                <Moment date={props.invoice.invoiced_at} format="DD-MMM-YY" local="es" />
              </Column>
              <Column xs={4} fluid>
                <Text type="sidebarInfoTitle">Lugar de emisión</Text>
                <Text>Querétaro, México</Text>
              </Column>
            </Row>
            <Row className="title-id">
              <Column xs={12} fluid>
                <P>Emisor</P>
              </Column>
            </Row>
            <Row className="emisor-id">
              <Column xs={6} fluid className="col-id">
                <P>MIENVIO DE MÉXICO SAPI DE CV</P>
                <P>MME150814130</P>
                <P>Régimen General de Ley Personas Morales</P>
              </Column>
              <Column xs={6} fluid className="col-id">
                <P>Del Chabacano 21, Álamos 1a sección</P>
                <P>Querétaro, Querétaro, México</P>
                <P>CP. 76160</P>
              </Column>
            </Row>
            <Row className="title-id">
              <Column xs={12} fluid>
                <P>Receptor</P>
              </Column>
            </Row>
            {props.user.fiscal_address && (
              <Row className="emisor-id">
                <Column xs={6} fluid className="col-id">
                  <P>{props.user.fiscal_address.name}</P>
                  <P>{props.user.fiscal_address.rfc}</P>
                  <P>Régimen General de Ley Personas Morales</P>
                </Column>
                <Column xs={6} fluid className="col-id">
                  <P>{props.user.fiscal_address.street}</P>
                  <P>{`${props.user.fiscal_address.state}, México`}</P>
                  <P>{`C.P. ${props.user.fiscal_address.zipcode}`}</P>
                </Column>
              </Row>
            )}
          </div>
        )}
        {props.invoice.type === 'DEPOSIT' 
        ? (
            <div>
            <Row className={`title-id ${!props.invoice.invoice_id && 'no-invoice-details'}`}>
                <Column xs={12} fluid>
                  <P>Desglose de depósito</P>
                </Column>
              </Row>
              <Table size="full" className="table-id" />
              <Row divisions={13}>
            <Column fluid xs={2} style={{ textAlign: 'left' }}>
                  <Text type="label">Fecha</Text>
                </Column>

                <Column fluid xs={4} style={{ textAlign: 'right' }}>
                  <Moment date={props.invoice.created_at} local="es" format="DD-MMM-YYYY" />
                </Column>
            <Column fluid xs={10} />
            <Column fluid xs={4} style={{ textAlign: 'left' }}>
                  <Text type="label">Total</Text>
                </Column>
                <Column fluid xs={2} style={{ textAlign: 'right' }}>
                  <Text>
$
{parseFloat(props.invoice.amount).toFixed(2)}
</Text>
                </Column>


              </Row>
            </div>
          )
          : (
            <div>
              <Row className={`title-id ${!props.invoice.invoice_id && 'no-invoice-details'}`}>
                <Column xs={12} fluid>
                  <P>Desglose de compra</P>
                </Column>
              </Row>
              {props.invoice.type === 'CLIENT_ADVANCE_PAYMENT' ? 
              <Column xs={12} fluid>
                <P>Recarga Wallet STP</P>
              </Column>
              :
              <Table size="full" className="table-id">
                <thead>
                  <Tr noBorder>
                    <Th>Cantidad</Th>
                    <Th>ID de envío</Th>
                    <Th>Descripción</Th>
                    <Th>Precio Unitario</Th>
                    <Th>Seguro</Th>
                    <Th>Total</Th>
                  </Tr>
                </thead>
                <tbody>{props.renderTable()}</tbody>
              </Table>}
              <Row divisions={13}>
                <Column fluid xs={9} />
                <Column fluid xs={2} style={{ textAlign: 'left' }}>
                  <Text type="label">Subtotal</Text>
                </Column>
                <Column fluid xs={2} style={{ textAlign: 'right' }}>
                  <Text>
$
{subtotal(props.invoice.amount, props.invoice.type)}
</Text>
                </Column>
                <Column fluid xs={9} />
                <Column fluid xs={2} style={{ textAlign: 'left' }}>
                  <Text type="label">IVA (16%)</Text>
                </Column>
                <Column fluid xs={2} style={{ textAlign: 'right' }}>
                  <Text>
$
{totalIva(props.invoice.amount, props.invoice.type)}
</Text>
                </Column>
                <Column fluid xs={9} />
                <Column fluid xs={2} style={{ textAlign: 'left' }}>
                  <Text type="label">Total</Text>
                </Column>
                <Column fluid xs={2} style={{ textAlign: 'right' }}>
                  <Text>
$
{parseFloat(props.invoice.amount).toFixed(2)}
</Text>
                </Column>
                <Column fluid xs={9} />
                <Column fluid xs={3} style={{ textAlign: 'left' }}>
                  <Text type="label">Método de pago</Text>
                </Column>
                <Column fluid xs={1} style={{ textAlign: 'center' }}>
                  {props.invoice.payment_provider && (
                    <div>
                      {props.invoice.payment_provider === 'stripe' ? (
                        <Text className="less-font">
                          <img height="12px" width="10px" src={card_placeholder} className="img-totals" />
                          {props.invoice.last_4}
                        </Text>
                      ) : (
                        <Text className="less-font">
                          <img height="12px" width="10px" src={paypal_img} className="img-totals" />
                        </Text>
                      )}
                    </div>
                  )}
                </Column>
              </Row>
            </div>
          )
        }

      </div>
    )}
  </div>
);
      

export default InvoiceDetail;
