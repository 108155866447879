import React from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import close_icon from '../../WrapperSideBar/img/close.svg';

import './datefilter.css'
import Calendar from './icons/calendar.svg'

class DateFilter extends React.Component {

  // TODO: ocultar icono cuando no halla fecha seleccionada. Imlementar funcion de borrar filtro
  render() {

    return (<div className="input-group">
      <img
        src={Calendar}
        className="icon"
      />
      <DatePicker
        showIcon
        dateFormat="Y-MM-dd"
        placeholderText="Fecha de cotización"
        className="sc-fjdhpX imdTE input"
        selected={this.props.selectedDate}
        onChange={this.props.filterDates} />
      <img
        src={close_icon}
        className="close-icon"
        onClick={() => this.props.clearFilter()}
        hidden={this.props.selectedDate === null ? true : false} />
    </div>)
  }
}


export default (DateFilter);
