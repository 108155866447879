import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Text, { Title, P } from "../../components/Text";
import styled from "styled-components";
import { Row, Column } from "hedron";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Tabs, { Tab } from "../../components/Tabs";
import Card, { Divider } from "../../components/Card";
import colors from "../../utils/colors";
import Select from "react-select";
import "./style.css";
import SubAccountsList from "../../components/SubAccountsList";
import RolesList from "../../components/RolesList";
import WrapperSideBar from "../../components/WrapperSideBar";
import {
  ContentAdapter,
  BottomContainer
} from "../../components/WrapperSideBar/styledObjects";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle
} from "../../modules/CollapsibleBar/actions";
import { searchPermission } from '../../utils/global_functions';

const StyledConnected = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  display: inline-block;
  background: #00ee7a;
  margin-left: 6px;
`;

const StyledDisconnected = StyledConnected.extend`
  background: #d8d8d8;
`;

class Subaccounts extends React.Component {
  state = {
    currentTab: 0
  };

  toggleAddAccount() {
    this.props.toggleSideBar(true, "Añadir Cuenta", "NEW_ACCOUNT");
  }

  toggleAddRole() {
    this.props.toggleSideBar(true, "Añadir Rol", "NEW_ROL");
  }

  render() {
    const { currentTab } = this.state;
    const {
      customer_type, plan, actions, role, operational_user,
    } = this.props.user;
  
    const permissions = [];
    if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
      operational_user.operational_role.actions.map(element => permissions.push(element));
    }
  
    if (plan && plan.features) {
      plan.features.map(element => permissions.push(element));
    }
    if (actions) {
      actions.map(element => permissions.push(element));
    }
    return (
      <div>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: 'right' }} fluid>
            <Menu />
          </Column>
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Header>
              <Row divisions={18}>
                <Column xs={4} fluid>
                  <Title type="titleCard">Subcuentas</Title>
                </Column>
                <Column
                  xs={14}
                  xsShift={0}
                  style={{
                    textAlign: 'right',
                  }}
                  fluid
                >
                  {currentTab === 0 && searchPermission('cuentas_roles', 'editar', permissions) &&(
                    <Button
                      className="header-btn last"
                      onClick={() => this.toggleAddRole()}
                    >
                      Añadir Rol
                    </Button>
                  )}

                  {currentTab === 1 && searchPermission('cuentas_cuentas', 'editar', permissions) &&(
                    <Button
                      className="header-btn last"
                      onClick={() => this.toggleAddAccount()}
                    >
                      Añadir Cuenta
                    </Button>
                  )}
                </Column>
              </Row>
            </Header>
            <Row divisions={20}>
              <Column xs={1} fluid />
              <Column xs={18} md={13} fluid>
                <Tabs
                  id="integrations"
                  right
                  selected={currentTab}
                  onChange={selected => this.setState({ currentTab: selected })}
                >
                  {searchPermission('cuentas_roles', 'leer', permissions) ? (<Tab>Roles</Tab> ) : (<div />) }
                  {searchPermission('cuentas_cuentas', 'leer', permissions) ? (<Tab>Cuentas</Tab> ) : (<div />) }

                </Tabs>
                <div>
                  {currentTab === 0 && searchPermission('cuentas_roles', 'leer', permissions) && <RolesList />}

                  {currentTab === 1 && searchPermission('cuentas_cuentas', 'leer', permissions) && <SubAccountsList />}
                </div>
              </Column>
              <Column xs={1} md={6} fluid />
            </Row>
          </Column>
          <Column xs={1} fluid />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sidebar: state.sidebar,
  user: state.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleSideBar,
      changeSidebarTitle
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Subaccounts);
