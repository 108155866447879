import React from 'react';
import styled from 'styled-components';
import colors from '../../utils/colors';

const Nav = styled.div`
  width: 100%;
  height: 62px;
  max-height: 52px;
  z-index: 100;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-sizing: border-box;
  text-align: center;
  background-color: #aa1d61;
  color: ${colors.secondary.white};
  line-height: 42px;
  font-size: 11px;
  padding-top: 10px;
`;

const Action = styled.div`
  position: absolute;
  line-height: 42px;
  display: inline-block;
  cursor: pointer;
  right: 24.5px;
  i {
    margin-left: 11px;
  }
`;

const Banner = (props, context) => (
  <Nav>
    {!props.hideIcon
    && (
      <Action onClick={props.action}>
        <i className="ion-close" />
      </Action>
    )
    }
    {props.children}
  </Nav>
);

export default Banner;
export { Nav, Action };
