import axios from 'axios';
import { SET_WALLET_TRANSACTIONS } from './actionTypes';
import type { Dispatch as ReduxDispatch } from 'redux';
import { showError } from '../error';

export const getWalletTransactions = (page = 1) => {
  return (dispatch: Dispatch) => {
    return axios
      .get(`/api/profile/transactions?page=${page}`)
      .then(response => {
        dispatch(setWalletTransactions(response.data.transactions));
      })
      .catch(err => {
        console.log(err);
        if (err.response) dispatch(showError(err.response.data.error));
      });
  };
};

export const setWalletTransactions = (
  transactions: Transactions
): Action_GET_USER_WALLET_TRANSACTIONS => ({
  type: SET_WALLET_TRANSACTIONS,
  transactions
});
