import React from "react";
import { Row, Column, element } from "hedron";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { boundMethod } from "autobind-decorator";
import axios from "axios";

import Skeleton from "react-loading-skeleton";
import { ToastContainer, toast } from "react-toastify";
import AccountsManagment from "../../components/AccountsManagment";
import AccountQuotesVisualization from "../../components/AccountQuotesVisualization";
import ListedQuote from "./ListedQuote";
import Button from "../../components/Button";

import WrapperSideBar from "../../components/WrapperSideBar";
import {
  ContentAdapter,
  BottomContainer,
} from "../../components/WrapperSideBar/styledObjects";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../modules/CollapsibleBar/actions";
import EmptyState from "./EmptyState";
import { getRegions, getManagerRegions } from "../../modules/regions";
import { getManagerCountries } from "../../modules/marketplaces";

import JSZipUtils from 'jszip-utils';

import "./style.css";

const today = new Date();
const tomorrow = new Date();
const ENABLED_COUNTRIES = ['Perú', 'Colombia']

class Multiprocess extends React.Component {
  state = {
    selectedRegion: null,
    selectedRegionIndex: null,
    selectedUser: null,
    loadingSideBar: false,
    regionsData: [],
    countriesData: [],
    reloadAccounts: false,
    selectedCountry: null,
    selectedCountryError: "",
    toProcess: {},
    prossesingQuotes: false,
    successProcess: [],
    errorProcess: []
  };


  constructor(props: Props) {
    super(props);
  }

  // Add or remove a quote to/from toProcess object's lists 
  @boundMethod
  selectQuotes(e, quote) {
    const id = this.state.selectedUser
    const selected = this.state.toProcess

    console.log({ quote })
    if (e.target.checked) {
      if (selected[id] === undefined) {
        selected[id] = [quote]
        this.setState({ toProcess: selected })
      } else {
        selected[id].push(quote)
        this.setState({ toProcess: selected })
      }
    } else {
      const index = selected[id].indexOf(quote)
      selected[id].splice(index, 1)
      this.setState({ toProcess: selected })
    }
  }

  _renderSideBarLoader = () => {
    return (
      <ContentAdapter>
        <Skeleton style={{ marginTop: 20 }} height={250} />
      </ContentAdapter>
    )
  };

  _renderSidebarContent = () => {
    const { toProcess } = this.state;
    const uquotes = Object.values(toProcess).flat();
    const quotes = uquotes.map(quote => quote[0]);

    if (this.state.prossesingQuotes) {
      return this._renderSideBarLoader()
    } else {
      return (<ContentAdapter>
        <ol>
          {quotes.map(quote => {
            const errIndex = this.state.errorProcess.findIndex(q => q.quote_id == quote);
            const succIndex = this.state.successProcess.findIndex(q => q.quote_id == quote);

            const status = errIndex > -1 ? 'error' : succIndex > -1 ? 'success' : 'hidden';
            const message = errIndex > -1 ? `No de guía: ${this.state.errorProcess[errIndex][0]}` :
              succIndex > -1 ? 'Guías creadas con éxito' : ''

            return (<ListedQuote quote={quote} status={status} message={message} />)
          })}
        </ol>

        <BottomContainer>
          <Row divisions={20}>
            <Column style={{ textAlign: "right" }} fluid>
              <Button
                loading={this.state.setLoading}
                onClick={() => this.clearSelectedQuotes()}
                className="clear-btn"
              >
                Borrar selección
              </Button>

              <Button
                loading={this.state.setLoading}
                onClick={() => this.processQuotes()}
                className="prcess-btn"
                style={{ marginLeft: '5px', marginRight: '5px' }}
              >
                Procesar
              </Button>
            </Column>

            <Column
              xs={1}
              xsShift={1}
              style={{ textAlign: "right" }}
              fluid
            />
          </Row>
        </BottomContainer>
      </ContentAdapter>)
    }
  }


  componentWillMount() {
    this.props.toggleSideBar(false);
  }

  componentDidMount() {
    this.getCountriesData();
    this.getRegionsData();
  }

  getCountriesData() {
    if (
      this.props.user.role === "master" ||
      (this.props.user.plan && this.props.user.plan.name === "enterprise") ||
      (this.props.user.plan && this.props.user.plan.name === "operativo")
    ) {
      axios
        .get("api/regions")
        .then(({ data }) => {
          // evitamos regiones que tienen varios paises "Todas las regiones" 
          debugger
          const contries = data
            .filter((countr) => countr.countries.length === 1)
            .map((ele) => ele.countries[0])
            // dejamos pasar unicamente los países permitidos en el env
            .filter(e => ENABLED_COUNTRIES.includes(e.name));

          this.setState({ countriesData: contries });
        })
        .catch((e) => {
          console.log("Cou", e);
          this.notify("Error al obtener la información", "error");
        });
    } else {
      getManagerCountries(this.props.user.object_id).then((response) => {
        if (response.type === "Success" && response.response.status === 200) {
          this.setState({ countriesData: response.response.data });
        } else {
          this.notify("Error al obtener la información", "error");
        }
      });
    }
  }

  getRegionsData() {
    if (
      this.props.user.role === "master" ||
      (this.props.user.plan && this.props.user.plan.name === "enterprise") ||
      (this.props.user.plan && this.props.user.plan.name === "operativo")
    ) {
      getRegions().then((response) => {
        if (response.type === "Success" && response.response.status === 200) {
          this.setState({ regionsData: response.response.data });
        } else {
          this.notify("Error al obtener la información", "error");
        }
      });
    } else {
      getManagerRegions(this.props.user.object_id).then((response) => {
        if (response.type === "Success" && response.response.status === 200) {
          this.setState({ regionsData: response.response.data });
        } else {
          this.notify("Error al obtener la información", "error");
        }
      });
    }
  }

  notify = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  processQuotes() {
    const uquotes = Object.values(this.state.toProcess).flat()
    const quote_id = uquotes.map(quote => quote[0]);

    const body = { quote_id }

    this.setState({ prossesingQuotes: true })
    axios.post('api/quotes/label', body)
      .then(response => {
        const results = response.data.results
        const errorProcess = []
        console.log(quote_id)
        const successProcess = results.filter(e => {
          const track = e[0].toLowerCase()

          if (track.includes('error') || track === 'no existe') {
            errorProcess.push(e)
            return false
          }

          return true
        });

        console.log({ errorProcess, successProcess })
        this.setState({ prossesingQuotes: false, successProcess, errorProcess })

        console.log(successProcess)
      })
      .catch(error => {
        console.error({ error })
        this.notify("Error al procesar las ctotizaciones", "error")
      })
  }

  downloadLabels() {
    const { toProcess, successProcess } = this.state;
    const quotes = Object.values(toProcess).flat()

    const toDownload = quotes.filter(quote => successProcess.find(e => e.quote_id === quote[0]) !== undefined)
    const shipments = toDownload.map(quote => quote[1].map(shipment => shipment.id)).flat()
    axios.post('api/labels/download-multiple-merge', { shipments })
      .then(response => {
        //debugger
        this.downloadGuides(response.data.multi_label)
      })
      .catch(error => {
        console.error(error.response)
        this.notify("Error durante la descarda", "error")
      })
  }

  @boundMethod
  downloadGuides(link) {

    JSZipUtils.getBinaryContent(url, (err, data) => {
      try {
        console.log('before print js')
        printJS({ printable: btoa(String.fromCharCode.apply(null, new Uint8Array(data))), type: 'pdf', base64: true });
      } catch (error) {
        console.error(error)
        this.notify("Ocurrio un error durante la desacarga", "Error")
      }
    });
    /*const config = {
      headers: {
        //"Content-Type": "application/pdf"
        headers: {  },
        responseType: "blob"
      }
    }
    axios.get(link, config)
      //.then(response => response.blob())
      .then(blob => {
        debugger
        const url = window.URL.createObjectURL(
          new Blob([blob.data]),
        )

        const l = document.createElement('a')
        l.href = url
        l.setAttribute('download', 'FileName.pdf')

        document.body.appendChild(l)
        l.click()
        l.parentNode.removeChild(l)
      })
      .catch(error => {
        console.error(error)
        this.notify("Ocurrio un error durante la desacarga", "Error")
      })*/
  }

  @boundMethod
  selectUser(id) {
    this.setState({ selectedUser: id });
  }

  @boundMethod
  clearSelectedUser() {
    this.setState({ selectedUser: null });
  }

  @boundMethod
  clearSelectedQuotes() {
    this.setState({ toProcess: {} })
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <WrapperSideBar handleClose={this.onCloseSidebar}>
          {this.state.loadingSideBar
            ? this._renderSideBarLoader()
            : this._renderSidebarContent()}
          {/* <EmptyState /> */}
        </WrapperSideBar>
        <Row divisions={20}>
          <Column xs={6} fluid>
            <AccountsManagment
              countries={this.state.countriesData}
              reload={this.state.reloadAccounts}
              clearSelectedUser={this.clearSelectedUser}
              clearSelectedQuotes={this.clearSelectedQuotes}
              notify={this.notify}
              selectUser={this.selectUser}
              selectedUser={this.state.selectedUser}
            />
          </Column>
          <Column xs={1} fluid />
          <Column xs={13} fluid>
            {this.state.selectedUser ? (
              <AccountQuotesVisualization
                id={this.state.selectedUser}
                toProcess={this.state.toProcess}
                renderSelectedList={this._renderSidebarContent}
                onClickQuote={this.selectQuotes} />
            ) : (
              <EmptyState props={this.props} />
            )}
          </Column>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  countries: state.newShipment.countries,
  user: state.user,
  toProcess: state.toProcess
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSideBar,
      changeSidebarTitle,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Multiprocess);
