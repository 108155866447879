import React from "react";
import axios from "axios";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import { changeDashboardGlobalStatus } from "../../modules/dashboard/actions";
import Text, { Title } from "../../components/Text";
import { showMessage, hideMessage } from "../../modules/message";
import { showLoader, hideLoader } from "../../modules/loader";
import { isJsonEmpty } from "../../utils/global_functions";

import Menu from "../../components/Menu";
import Checkout from "../../components/Checkout";

class DashboardPayment extends React.Component {
  state = {
    visible: false,
  };

  componentDidMount() {
    this.props.showLoader("basic");
    if (isJsonEmpty(this.props.dashboard.payload)) {
      this.props.goToDashboard();
      return;
    }

    axios
      .post("/api/purchases/total", this.props.dashboard.payload)
      .then((response) => {
        this.setState(
          {
            originalPayload: this.props.dashboard.payload,
            visible: true,
            shipmentsToPay: this.props.dashboard.payload.shipments,
            shipmentsToPayObject: response.data,
          },
          () => this.props.changeDashboardGlobalStatus(false)
        );
      })
      .catch(() => {
        this.props.goToDashboard();
      });
  }

  render() {
    return (
      <div>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: "right" }} fluid>
            <Menu />
          </Column>
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Title type="titleCard" annotation="Beta">
              Dashboard
            </Title>
          </Column>
          <Column xs={1} fluid />
        </Row>
        {this.state.visible && (
          <Checkout
            overweightOnly={
              this.state.originalPayload.charge_type == "OVERWEIGHT"
            }
            removeOverweight={false}
            goBack={this.props.goToDashboard}
            shipmentsObject={this.state.shipmentsToPayObject}
            shipments={this.props.shipments}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  shipments: state.shipments,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideMessage,
      showLoader,
      hideLoader,
      goToDashboard: () => push("/"),
      changeDashboardGlobalStatus,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPayment);
