import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProgressBar from "../ProgressBar";
import Tabs, { Tab, Step } from "../Tabs";
import TabStep from "../WrapperSideBar/tabStep";
import {
  setStepCSV,
  setIconCSV,
  initSteps,
  nextStepCSV,
  createRatesCSV,
  fillData,
} from "../../modules/ShipmentCSV/actions";
import Upload from "./upload";
import "./index.css";
import ReactTooltip from "react-tooltip";
import Button from "../Button";
import { BottomContainer } from "../WrapperSideBar/styledObjects";
import { Column, Row } from "hedron";
import ProcessFile from "./process";
import ConfirmFile from "./confirm";
import { convertFileToString } from "../../utils/global_functions";
import isEmail from "validator/lib/isEmail";

import { BASEURL } from "../../constants";

const headers = [
  "id",
  "origin_name",
  "origin_zipcode",
  "origin_neighborhood",
  "origin_street",
  "origin_reference",
  "origin_email",
  "origin_phone",
  "destiny_name",
  "destiny_zipcode",
  "destiny_neighborhood",
  "destiny_street",
  "destiny_reference",
  "destiny_email",
  "destiny_phone",
  "height",
  "width",
  "length",
  "weight",
  "description",
  "amount",
  "carrier",
  "servicio",
  "product_id",
];

const headersInto = [
  "origin_name",
  "origin_level1",
  "origin_level2",
  "origin_level3",
  "origin_neighborhood",
  "origin_street",
  "origin_reference",
  "origin_email",
  "origin_phone",
  "destiny_name",
  "destiny_level1",
  "destiny_level2",
  "destiny_level3",
  "destiny_neighborhood",
  "destiny_street",
  "destiny_reference",
  "destiny_email",
  "destiny_phone",
  "height",
  "width",
  "length",
  "weight",
  "description",
  "amount",
  "carrier",
  "servicio",
  "id",
];

const errorsList = {
  origin_name: "La columna Nombre es requerida",
  origin_zipcode: "La columna de Codigo Postal de origen no es valida",
  origin_neighborhood: "La columna Colonia de origen es requerida",
  origin_street: "La columna Calle y numero de origen es requerida",
  origin_reference: "",
  origin_email: "El Email no es valido",
  origin_phone: "EL Teléfono no es valido",
  destiny_name: "La columna Nombre es requerida",
  destiny_zipcode: "La columna de Codigo Postal no es valida",
  destiny_neighborhood: "La columna Colonia es requerida",
  destiny_street: "La columna Calle y numero es requerida",
  destiny_reference: "",
  destiny_email: "El Email no es valido",
  destiny_phone: "EL Teléfono no es valido",
  height: "La altura del paquete no es valida",
  width: "La anchura del paquete no es valida",
  length: "El largo del paquete no es valida",
  weight: "El peso del paquete no es valida",
  description: "La columna Nombre es requerida",
  amount: "El valor del paquete no es valido",
  carrier: "La columna Nombre es requerida",
  servicio: "La columna Nombre es requerida",
};
class ShipmentsCSV extends Component {
  state = {
    file: null,
    orders: [],
    ResumeOrders: [],
    passedOrders: [],
    shakeClassProcess: "",
    shakeClassConfirm: "",
    current: 0,
    processInfo: {
      load: false,
      export: false,
      process: false,
      finish: false,
    },
    drop_error: "",
    baseUrl: "",
    typeCSV: "",
  };

  componentDidMount(): void {
    this.setState({
      file: null,
      orders: [],
      ResumeOrders: [],
      passedOrders: [],
      shakeClassProcess: "",
      shakeClassConfirm: "",
      current: 0,
      processInfo: {
        load: false,
        export: false,
        process: false,
        finish: false,
      },
      BASEURL,
      typeCSV: "",
    });
  }

  getProgress = (titles) => {
    let result = 0;
    const total = titles.length;
    if (total == 0) return 0;
    titles.map((title) => {
      if (title.status == "completed") result++;
    });
    return (result / total) * 100;
  };

  renderDisabledButton = (tooltipMessage) => (
    <div
      style={{ backgroundColor: "#f3f3f3", width: "100%", textAlign: "right" }}
    >
      <a data-tip data-for="disabled_button_message">
        <Button
          style={{ float: "right", minHeight: "40px" }}
          type="disabled_table"
        >
          Siguiente
        </Button>
      </a>
      <ReactTooltip id="disabled_button_message">
        <span> {tooltipMessage} </span>
      </ReactTooltip>
    </div>
  );

  setStateAsync = (state) =>
    new Promise((resolve) => {
      this.setState(state, resolve);
    });

  _handleOnChange = async (selected) => {
    if (selected == 1) {
      await this.setStateAsync({ shakeClassProcess: "" });
      if (this.state.orders.length > 0) {
        this._processFile();
        this.props.setStepCSV(selected);
      } else this.setStateAsync({ shakeClassProcess: "animated shake" });
    }
  };

  _onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const result = await convertFileToString(acceptedFiles[0]);

      const rJson = await this.csvJSON(result);

      if (rJson.length > 0) {
        await this.setStateAsync({
          file: acceptedFiles[0],
          orders: rJson,
        });
        if (this.state.file) {
          this.props.setIconCSV("completed");
        }
      } else {
        this.setStateAsync({
          file: null,
          orders: [],
        });
      }
    } else {
      this.setStateAsync({
        file: null,
        orders: [],
      });
    }
  };

  _convertAddressFrom = (order) => ({
    object_type: "PURCHASE",
    name: order.origin_name,
    street: order.origin_street,
    street2: order.origin_neighborhood,
    zipcode: order.origin_zipcode,
    email: order.origin_email,
    phone: order.origin_phone,
    reference: order.origin_reference,
  });

  _convertAddressTo = (order) => ({
    object_type: "PURCHASE",
    name: order.destiny_name,
    street: order.destiny_street,
    street2: order.destiny_neighborhood,
    zipcode: order.destiny_zipcode,
    email: order.destiny_email,
    phone: order.destiny_phone,
    reference: order.destiny_reference,
  });

  _convertToModel = (order) => ({
    row: parseInt(order.id),
    object_purpose: "QUOTE",
    address_from: this._convertAddressFrom(order),
    address_to: this._convertAddressTo(order),
    order_id: order.id,
    weight: order.weight,
    length: order.length,
    source_type: "web_portal",
    height: order.height,
    description: order.description,
    width: order.width,
    declared_value: order.amount,
    carrier: order.carrier,
    servicio: order.servicio,
    user_id: this.props.userId,
    product_id: order.product_id,
  });

  _convertAddressFromPeru = (order) => ({
    object_type: "PURCHASE",
    name: order.origin_name,
    street: order.origin_street,
    street2: order.origin_neighborhood,
    level_1: order.origin_level1,
    level_2: order.origin_level2,
    level_3: order.origin_level3,
    email: order.origin_email,
    phone: order.origin_phone,
    reference: order.origin_reference,
  });

  _convertAddressToPeru = (order) => ({
    object_type: "PURCHASE",
    name: order.destiny_name,
    street: order.destiny_street,
    street2: order.destiny_neighborhood,
    level_1: order.destiny_level1,
    level_2: order.destiny_level2,
    level_3: order.destiny_level3,
    email: order.destiny_email,
    phone: order.destiny_phone,
    reference: order.destiny_reference,
  });

  _convertToModelPeru = (order) => ({
    row: parseInt(order.id),
    object_purpose: "QUOTE",
    address_from: this._convertAddressFromPeru(order),
    address_to: this._convertAddressToPeru(order),
    weight: order.weight,
    length: order.length,
    source_type: "web_portal",
    height: order.height,
    description: order.description,
    width: order.width,
    declared_value: order.amount,
    carrier: order.carrier,
    servicio: order.servicio,
    user_id: this.props.userId,
  });

  _validOrder = (order) => {
    let obj = null;
    let success = false;
    if (
      this._validText(order.origin_name) &&
      this._validText(order.destiny_name)
    ) {
      if (
        this._validZipCode(order.origin_zipcode) &&
        this._validZipCode(order.destiny_zipcode)
      ) {
        if (
          this._validText(order.origin_neighborhood) &&
          this._validText(order.destiny_neighborhood)
        ) {
          if (
            this._validText(order.origin_street) &&
            this._validText(order.destiny_street)
          ) {
            if (isEmail(order.origin_email) && isEmail(order.destiny_email)) {
              if (
                this._validPhone(order.origin_phone) &&
                this._validPhone(order.destiny_phone)
              ) {
                // completed origin and destiny
                if (this._validNumber(order.height)) {
                  if (this._validNumber(order.width)) {
                    if (this._validNumber(order.length)) {
                      if (this._validNumber(order.weight)) {
                        if (this._validText(order.description)) {
                          if (this._validNumber(order.amount)) {
                            if (this._validText(order.carrier)) {
                              if (this._validText(order.servicio)) {
                                // valid success
                                if (this.state.typeCSV == "Carga Peru") {
                                  obj = this._convertToModelPeru(order);
                                } else {
                                  obj = this._convertToModel(order);
                                }

                                success = true;
                              } else {
                                obj = {
                                  id: order.id,
                                  error: errorsList.servicio,
                                  success: false,
                                };
                              } // valid service
                            } else {
                              obj = {
                                id: order.id,
                                error: errorsList.carrier,
                                success: false,
                              };
                            } // valid carrier
                          } else {
                            obj = {
                              id: order.id,
                              error: errorsList.amount,
                              success: false,
                            };
                          } // valid amount package
                        } else {
                          obj = {
                            id: order.id,
                            error: errorsList.description,
                            success: false,
                          };
                        } // valid description
                      } else
                        obj = {
                          id: order.id,
                          error: errorsList.weight,
                          success: false,
                        }; // valid weight
                    } else
                      obj = {
                        id: order.id,
                        error: errorsList.length,
                        success: false,
                      }; // valid length
                  } else
                    obj = {
                      id: order.id,
                      error: errorsList.width,
                      success: false,
                    }; // valid width
                } else
                  obj = {
                    id: order.id,
                    error: errorsList.height,
                    success: false,
                  }; // valid height
              } else
                obj = {
                  id: order.id,
                  error: !this._validPhone(order.origin_phone)
                    ? errorsList.origin_phone
                    : errorsList.destiny_phone,
                  success: false,
                }; // valid from to phone
            } else
              obj = {
                id: order.id,
                error: errorsList.origin_email,
                success: false,
              }; // Valid from to email
          } else
            obj = {
              id: order.id,
              error: errorsList.origin_street,
              success: false,
            }; // valid from to street
        } else
          obj = {
            id: order.id,
            error: errorsList.origin_neighborhood,
            success: false,
          }; // valid from to neighborhood
      } else
        obj = {
          id: order.id,
          error: errorsList.origin_zipcode,
          success: false,
        }; // Valid from to zipcode
    } else
      obj = { id: order.id, error: errorsList.origin_name, success: false }; // Valid from to name
    return { success, obj };
  };

  _processFile = async () => {
    if (this.state.orders.length > 0) {
      await this.setStateAsync({
        processInfo: {
          ...this.state.processInfo,
          load: true,
        },
        ResumeOrders: [],
        passedOrders: [],
        current: 1,
      });
      const Errors = [];
      const completedOrders = [];

      this.state.orders.forEach((order) => {
        const { success, obj } = this._validOrder(order);

        if (success) completedOrders.push(obj);
        else Errors.push({ order_id: obj.id, message: obj.error });
      });

      await this.setStateAsync({
        passedOrders: completedOrders,
        processInfo: {
          ...this.state.processInfo,
          export: true,
        },
        current: 2,
      });

      const completed = [];

      // console.log(this.state.passedOrders);

      if (this.state.passedOrders.length > 0) {
        await this.props.createRatesCSV(
          this.state.passedOrders,
          async (csvResponse) => {
            await this.setStateAsync({
              processInfo: {
                ...this.state.processInfo,
                process: true,
              },
              current: 3,
            });

            this.setState({
              processInfo: {
                ...this.state.processInfo,
                finish: true,
              },
              current: 4,
              csvResponse,
              completed,
            });
            this.props.setIconCSV("completed");
            this.props.nextStepCSV();
          }
        );
        // this.props.nextStepCSV();
      } else {
        await this.props.fillData({});
        this.setState({
          csvResponse: {
            incomplete: true,
            errors: Errors,
          },
        });
        this.props.nextStepCSV();
      }
    }
  };

  _validText = (value) => value.length > 0;

  _validZipCode(value) {
    if (this.state.typeCSV == "Carga Peru") {
      return true;
    }

    if (value.length == 5 && this._validNumber(value)) {
      return true;
    }
  }

  _validPhone(value) {
    if (this.state.typeCSV == "Carga Peru" && this._validNumber(value)) {
      return true;
    }

    if (value.length == 10 && this._validNumber(value)) {
      return true;
    }
  }

  _validNumber = (value) => !isNaN(value);

  _onDeleteFiles = async () => {
    await this.setStateAsync({
      file: null,
      orders: [],
      drop_error: "",
    });
    this.props.setIconCSV("alert");
  };

  _renderSteps = () => {
    switch (this.props.data.step) {
      case 0:
        return (
          <Upload
            onDrop={this._onDrop}
            onDelete={this._onDeleteFiles}
            error={this.state.drop_error}
            baseUrl={this.state.baseUrl}
          />
        );
      case 1:
        return (
          <ProcessFile
            process={this.state.processInfo}
            current={this.state.current}
            countOrders={this.props.data.countOrders}
            maxOrders={this.props.data.maxOrders}
          />
        );
      case 2:
        return (
          <ConfirmFile
            resume={this.state.csvResponse}
            // completed={this.state.completed}
            changeIcon={() => {
              setTimeout(() => {
                this.props.setIconCSV("completed");
              }, 100);
            }}
          />
        );
      default:
        return <div />;
    }
  };

  _closeCSVForm = () => {
    this.props.initSteps();
    this.props.onClose(
      this.state.ResumeOrders.filter((orden) => orden.success).length
    );
  };

  _renderStepsButtons = () => {
    switch (this.props.data.step) {
      case 0:
        return this.state.file ? (
          <Button
            className=""
            onClick={() => {
              this.props.nextStepCSV();
              this._processFile();
            }}
          >
            Siguiente
          </Button>
        ) : (
          this.renderDisabledButton("Selecciona un archivo para continuar")
        );
      case 1:
        return this.state.processInfo.export &&
          this.state.processInfo.load &&
          this.state.processInfo.process &&
          this.state.processInfo.finish ? (
          <Button
            className=""
            onClick={() => {
              this.props.nextStepCSV();
            }}
          >
            Siguiente
          </Button>
        ) : (
          this.renderDisabledButton("Se esta procesando la información")
        );
      case 2:
        return (
          <Button className="" onClick={this._closeCSVForm}>
            Terminar
          </Button>
        );
      default:
        return <div />;
    }
  };

  csvJSON = async (csv) => {
    const lines = csv.split("\n");
    const result = [];
    const checkTypeCSV = this.checkTypeCSV(lines[0].split(",").length);
    await this.setStateAsync({ drop_error: "", typeCSV: checkTypeCSV });

    if (lines[0].split(",").length == 24 || lines[0].split(",").length == 23) {
      const local_headers = lines[0]
        .split(",")
        .map((result) => result.replace(/(\r\n|\n|\r)/gm, ""));
      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        if (lines[i] !== "") {
          let row = this.CSVtoArray(lines[i]);
          const currentline = row;
          if (currentline != null) {
            for (let j = 0; j < local_headers.length; j++) {
              if (j === 2 || j === 9) {
                obj[headers[j]] = this.checkZipCode(currentline[j]);
              } else {
                obj[headers[j]] = currentline[j];
              }
            }
            result.push(obj);
          }
        }
      }
      return result;
    }

    if (lines[0].split(",").length == 27) {
      const local_headers = lines[0]
        .split(",")
        .map((result) => result.replace(/(\r\n|\n|\r)/gm, ""));
      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        if (lines[i] !== "") {
          let row = this.CSVtoArray(lines[i]);
          const currentline = row;
          if (currentline != null) {
            obj[headersInto[26]] = i;
            for (let j = 0; j < local_headers.length; j++) {
              obj[headersInto[j]] = currentline[j];
            }
            result.push(obj);
          }
        }
      }

      return result;
    }

    await this.setStateAsync({
      drop_error: "El formato del archivo no es  correcto",
    });
    return [];
  };

  CSVtoArray(text) {
    var re_valid =
      /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    var re_value =
      /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
    // Return NULL if input string is not well formed CSV string.
    if (!re_valid.test(text)) return null;
    var a = []; // Initialize array to receive values.
    text.replace(
      re_value, // "Walk" the string using replace with callback.
      function (m0, m1, m2, m3) {
        // Remove backslash from \' in single quoted values.
        if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
        // Remove backslash from \" in double quoted values.
        else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
        else if (m3 !== undefined) a.push(m3);
        return ""; // Return empty string.
      }
    );
    // Handle special case of empty last value.
    if (/,\s*$/.test(text)) a.push("");
    return a;
  }

  checkTypeCSV(columns) {
    if (columns == 23) {
      return "Carga Normal";
    }

    if (columns == 26) {
      return "Carga Peru";
    }
  }

  checkZipCode(zipCode) {
    let number = zipCode;
    let width = 5;
    var numberOutput = Math.abs(number);
    var length = number.toString().length;
    var zero = "0";

    if (width <= length) {
      if (number < 0) {
        return "-" + numberOutput.toString();
      } else {
        return numberOutput.toString();
      }
    } else {
      if (number < 0) {
        return "-" + zero.repeat(width - length) + numberOutput.toString();
      } else {
        return zero.repeat(width - length) + numberOutput.toString();
      }
    }
  }
  render() {
    return (
      <div>
        <ProgressBar fill={this.getProgress(this.props.data.steps)} />
        <Tabs
          id="nl_steps"
          className="nl_steps csb-adjust-width"
          sliderClassName="steps_slider"
          selected={this.props.data.step}
          isNewShipment
          right
          onChange={this._handleOnChange}
          shipmentCSV
        >
          {this.props.data.steps.map((step, index) => (
            <TabStep
              key={index}
              selected={this.props.data.step - 1}
              icon_status={step.status}
              shakeClass={
                step.name == "process"
                  ? this.state.shakeClassProcess
                  : step.name == "confirm"
                  ? this.state.shakeClassConfirm
                  : ""
              }
            >
              {step.label}
            </TabStep>
          ))}
        </Tabs>
        {this._renderSteps()}
        <BottomContainer>
          <Row divisions={20}>
            <Column xs={9} xsShift={1} style={{ textAlign: "left" }} fluid />
            <Column xs={8} xsShift={1} style={{ textAlign: "right" }} fluid>
              {this._renderStepsButtons()}
            </Column>
            <Column xs={1} xsShift={1} style={{ textAlign: "right" }} fluid />
          </Row>
        </BottomContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.shipmentsCSV,

  userId: state.user.object_id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setStepCSV,
      setIconCSV,
      initSteps,
      nextStepCSV,
      createRatesCSV,
      fillData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentsCSV);
