import React from "react";
import axios from "axios";
import { boundMethod } from "autobind-decorator";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import { push } from "react-router-redux";
import Loader from "react-loaders";
import "loaders.css/loaders.min.css";
import moment from "moment/min/moment-with-locales";
import JSZip from "jszip";
import { saveAs } from "file-saver/FileSaver";
import Skeleton from "react-loading-skeleton";
import JSZipUtils from "jszip-utils";
import { clearQuickQuote } from "../../modules/QuickQuote/actions";
import AllFilters from "./common/allfilters";
import { CSVDowxnload } from "react-csv";
import { ToastContainer, toast } from "react-toastify";
import ModalResponsive from "react-responsive-modal";
import Dropdown from "../Dropdown";
import { loadInvoice } from "../../modules/Invoices/actions";

import {
  QUICK_QUOTE_TYPE,
  ORDER_DETAILS,
  NEW_SHIPMENT,
  CHECKOUT_DETAILS,
  SHIPMENTS_BY_CSV,
  SHIPMENTS_FILTERS_CSV,
} from "../../modules/CollapsibleBar/sideBarTypes";

import {
  getAndAppendAllUserShipments,
  getUserShipments,
  getUserOrders,
  getUserShipmentsPurchase,
  deleteShipments,
  duplicateShipments,
  resetCreated,
  createShipment,
  editShipment,
  getAllUserShipments,
  clearFilters,
  completeUserOrders,
  setUserMaxShipments,
  mergeShipmentsPDF,
  appendFilter,
  deleteFilter,
  getMarketplaceShipments,
} from "../../modules/shipments/actions";
import * as shipmentsSelectors from "../../modules/shipments/selectors";
import { hideMessage, showMessage } from "../../modules/message";
import {
  previousStep,
  nextStep,
  setValue,
  clearSidebar,
  setStepTitles,
  createAddress,
  createPackage,
  generateLabel as generateLabelNew,
  sendDataInvoice,
  changeManual,
  clearSelectAddressTo,
  getCountries,
  closeInvoice,
  clearSelectAddressFrom,
} from "../../modules/sidebarOrder/actions";
import colors from "../../utils/colors";
import Header from "../Header";
import Tabs, { Tab, Step } from "../Tabs";
import { Label } from "../Input";
import Text, { Title } from "../Text";
import { Tr, Td } from "../Table";
import { Checkbox } from "../Checkbox";
import Button, { ActionButton } from "../Button";
import Card from "../Card";
import { MarketplaceBadge } from "../Badge";
import NewBadge from "../Badge/insuranceBadge";
import EmptyState from "../EmptyState";
import Message from "../Message";
import DetailedOrder from "./details/detailed-order";
import DetailedOrderMenu from "./details/detailed-order-menu";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../modules/CollapsibleBar/actions";
import { getUserCards } from "../../modules/cards";
import CreateLabel from "../CreateLabel";
import WrapperSideBar from "../WrapperSideBar";
import QuickQuote from "../QuickQuote";
import ShipmentWrapper from "../ShipmentWrapper";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import {
  ContentAdapter,
  BottomContainer,
} from "../WrapperSideBar/styledObjects";
import CheckoutDetails from "./details/checkout";
import ShipmentsCSV from "../ShipmentsCSV";
import { initSteps } from "../../modules/ShipmentCSV/actions";
import CommercialInvoice from "../CommercialInvoice";
import NewTable from "../NewTable";
import { jsUcfirst, numberWithCommas } from "../../utils/global_functions";
import "./style.scss";
const shipmentsKeys = ["purchase", "payed", "archived", "orders"];
import { searchPermission } from "../../utils/global_functions";
import ModalFiltersCSV from "./common/ModalFilter";

export const purposesWithTracking = [
  "LABEL_CREATED",
  "ORDER_CREATED",
  "PICKED_UP",
  "TRANSITO",
  "OUT_FOR_DELIVERY",
  "ENTREGADO",
  "RETORNO_EN_PROCESO",
  "CANCELADO",
  "DELIVERY",
  "PURCHASED",
  "GUIA_ENVIADA",
  "LABEL_CREATED|GUIA_ENVIADA",
  "INCIDENCIA",
];

const LoaderContainer = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  margin: 10px 0;
`;

const IMore = styled.i`
  cursor: pointer;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 16px;
  font-size: 2rem;
`;

const ConfigLink = styled.div`
  width: 100%;
  line-height: 42px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 12px;
  padding-right: 12px;
  color: #778092 !important;

  &:first-of-type p:after {
    content: none;
  }

  p {
    color: #778092;
    font-size: 12px;
    font-weight: bold;

    &:after {
      content: "";
      height: 1px;
      background-color: #eceff4;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: inherit;
      margin-top: -13px;
    }
  }

  &:hover {
    background: #e2e2e2;
  }
`;

const DropdownConfig = styled.div`
  position: relative;
  background: #fff;
  border-radius: 3px;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
    0 0 12px 7px rgba(208, 208, 208, 0.48); */
  cursor: pointer;
  z-index: 10;
  width: max-content;

  &.dropdown-tabs {
    position: absolute;
    top: calc(100% - 20px);
    right: 0;
    left: auto;

    > div {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
`;

const DropdownConfigV2 = DropdownConfig.extend`
  max-height: 0;
  overflow: hidden;
  height: auto;
  transition: 200ms;
  box-shadow: none;
  position: absolute;

  &.open {
    max-height: 600px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
      0 0 12px 7px rgba(208, 208, 208, 0.48);
  }
`;

class ShipmentsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      canPayShipments: false,
      selectedPayed: [],
      filter: "",
      filters: [],
      limit: 0,
      serviceSelected: [],
      statusSelected: [],
      page: 1,
      dropdownConfig: false,
      dropdownTabs: false,
      shakeClass: "",
      toolTipOpen: false,
      clearSelectedRows: false,
      loading: false,
      canDelete: true,
      csvData: [],
      loadingCsv: false,
      showContactModal: false,
      selectedPlan: [],
      selectedChallenge: [],
      selectedPlanError: "",
      selectedChallengeError: "",
      selectedShipment: [],
      selectedShipmentError: "",
      marketplaceShipments: "",
      proccesLabelError: false,
      packageDataError: false,
    };
    this.selectAllPurchase = this.selectAllPurchase.bind(this);
    this.select = this.select.bind(this);
    this.renderEmptyState = this.renderEmptyState.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onClose = this.onClose.bind(this);
    this.deleteShipments = this.deleteShipments.bind(this);
    this.duplicateShipments = this.duplicateShipments.bind(this);
    this.downloadGuides = this.downloadGuides.bind(this);
    this.goToPayment = this.goToPayment.bind(this);
    this.generateLabel = this.generateLabel.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.shipmentDetails = this.shipmentDetails.bind(this);
  }

  async componentWillMount() {
    const {
      toggleSideBar,
      closeInvoice,
      getAllUserShipments,
      clearSidebar,
      clearFilters,
      getCountries,
      user,
      getUserCards,
    } = this.props;
    const params = this.parseParams(this.props.location.search);
    const uncollected = params.filters == "sinrecoleccion" ? true : false;
    this.setState({ uncollected: uncollected });

    toggleSideBar(false);
    closeInvoice();
    await this.setStateAsync({ page: 1 });
    // // await getAllUserShipments(this.state.limit, 1, );
    // await getAllUserShipments(
    //   this.state.page,
    //   this.state.filters,
    //   null,
    //   null,
    //   "shipments",
    //   "componentWillMount"
    // );
    clearSidebar();
    clearFilters();
    getCountries();

    this.setState({ uncollected: uncollected });

    if (
      user.customer_type == "enterprise" ||
      user.customer_type == "basic" ||
      user.customer_type == "subsided_customer"
    ) {
      this.setState({
        canCreate: true,
        canDelete: true,
        canUpdate: true,
        canReadCards: true,
      });
    } else {
      if (this.props.user.permissions !== undefined) {
        user.permissions.forEach((element) => {
          if (element.resource === "shipments") {
            switch (element.action) {
              case "create":
                this.setState({ canCreate: true });
                break;
              case "edit":
                this.setState({ canUpdate: true });
                break;
              case "delete":
                this.setState({ canDelete: true });
                break;
              default:
                break;
            }
          }
          if (element.resource == "cards") {
            switch (element.action) {
              case "read":
                this.setState({ canReadCards: true });
                break;
              default:
                break;
            }
          }
        });
      } else {
        this.setState({
          canCreate: true,
          canDelete: true,
          canUpdate: true,
          canReadCards: true,
        });
      }
    }
    if (this.state.canReadCards) {
      getUserCards(true);
    }
  }

  parseParams(params) {
    const rawParams = params.replace("?", "").split("&");
    const extractedParams = {};
    rawParams.forEach((item) => {
      item = item.split("=");
      extractedParams[item[0]] = item[1];
    });
    return extractedParams;
  }

  renderUncollected = () => {
    this.setState({ uncollected: false });
    //clearFilters();
  };

  componentDidMount() {
    const params = this.parseParams(this.props.location.search);
    const uncollected = params.filters == "sinrecoleccion" ? true : false;
    this.setState({ uncollected: uncollected });

    if (this.props.invoiceDetails)
      setTimeout(() => {
        this.props.toggleSideBar(
          true,
          `Gracias por tu compra #${
            this.props.invoice.purchase && this.props.invoice.purchase.object_id
          }`,
          CHECKOUT_DETAILS
        );
      }, 1000);

    if (uncollected) {
      this.updateAllShipments(["status|=|LABEL_CREATED"]);
      this.handleTabChange(1);
    } else {
      this.updateAllShipments();
    }

    this.props
      .getMarketplaceShipments(1, this.state.limit, [])
      .then((response) => {
        this.setState({ marketplaceShipments: response });
      });
  }

  @boundMethod
  executeShipment() {
    setTimeout(() => {
      this.props.toggleSideBar(true, `Gracias por tu compra`, CHECKOUT_DETAILS);
    }, 1000);

    this.updateAllShipments();
  }
  componentWillUnmount() {
    this.props.resetCreated();
  }

  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  };

  toggleDropdownTabs() {
    this.setState({ dropdownTabs: !this.state.dropdownTabs });
  }

  toggleSidebar(title = "") {
    this.setState({ sidebar: { open: !this.state.sidebar.open } });
  }

  shorten(str, len) {
    if (!str) return str;
    if (str.length <= len) return str;
    return str.substr(0, len) + "...";
  }

  getTotal(shipment) {
    if (!shipment.insurance && shipment.rate)
      return parseFloat(shipment.rate.amount).toFixed(2);
    else if (!shipment.rate && shipment.insurance)
      return parseFloat(shipment.insurance.cost).toFixed(2);
    else if (!shipment.rate && !shipment.insurance) return 0.0;
    return parseFloat(shipment.rate.amount + shipment.insurance.cost).toFixed(
      2
    );
  }

  _loadMore = () => {
    this.setState({ loading: true, page: this.state.page + 1 }, () => {
      // marketplace shipments
      if (this.props.selectedType === 3) {
        this.props
          .getMarketplaceShipments(
            this.state.page,
            this.state.limit,
            this.props.shipments.filters.map(
              (e) => `${e.query[0]}|${e.query[1]}|${e.query[2]}`
            )
          )
          .then((response) => {
            const join = this.state.marketplaceShipments.concat(response);
            this.setState({
              marketplaceShipments: join,
              loading: false,
            });
          });
      } else {
        this.props
          .getAndAppendAllUserShipments(
            this.state.limit,
            this.state.page,
            this.props.shipments.filters.map(
              (e) => `${e.query[0]}|${e.query[1]}|${e.query[2]}`
            )
          )
          .then(() => {
            this.setState({ loading: false });
          });
      }
    });
  };

  generateLabel = async (order) => {
    if (!order.shipping) {
      return this.props.generateLabelNew({
        object_id: order.object_id.toString(),
        marketplace: order.marketplace,
        shop_id: order.store_id,
      });
    } else {
      await this.props.clearSidebar();
      this.props.generateLabelNew(
        {
          object_id: order.object_id.toString(),
          marketplace: order.marketplace,
          shop_id: order.store_id,
        },
        {
          name: `${
            order.shipping.first_name ? order.shipping.first_name : ""
          } ${order.shipping.last_name ? order.shipping.last_name : ""}`,
          zipcode: order.shipping.zipcode ? order.shipping.zipcode : "",
          street: order.shipping.street ? order.shipping.street : "",
          street2: order.shipping.street2 ? order.shipping.street2 : "",
          email: order.shipping.email ? order.shipping.email : "",
          phone: order.shipping.phone ? order.shipping.phone : "",
          reference: order.shipping.reference ? order.shipping.reference : "",
        },
        true
      );

      if (order.marketplace == "shopify") {
        const shopifyPackageDesc = order.line_items[0]
          ? order.line_items[0].name.substring(0, 30)
          : "";
        const checkWeight =
          order.weight > 0 ? parseFloat(order.weight) / 1000 : 0;
        const checkStreet = order.shipping.street
          ? order.shipping.street.substring(0, 35)
          : "";
        //Pone por default el valor del primer paquete
        //this.props.setValue('package_insuredAmount', order.subtotal);
        //this.props.setValue('package_description', shopifyPackageDesc);
        //this.props.setValue('package_weight', checkWeight);
        //this.props.setValue('package_weight', checkWeight);
        this.props.setValue("to_street", checkStreet);
      }
    }
    await this.props.setStepTitles([
      {
        label: "Origen",
        status: "unfinished",
      },
      {
        label: "Destino",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Paquete",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Servicio",
        status: "unfinished",
        forwardClickable: false,
      },
    ]);
    this.props.toggleSideBar(true, "Creación de guías", NEW_SHIPMENT);
  };

  getOrderMetadata = (order, name_purchase) => {
    let relatedOrders = {
      nextOrder: order.next_shipment_id ? order.next_shipment_id.id : 0,
      prevOrder: order.previous_shipment_id ? order.previous_shipment_id.id : 0,
    };
    order = order.shipment;
    if (
      purposesWithTracking.indexOf(order.object_purpose) > -1 &&
      order.label
    ) {
      axios
        .get(
          `/api/tracking/${
            order.label.tracking_number
          }/${order.rate.provider.toLowerCase()}`
        )
        .then((response) => {
          this.setState({
            order: {
              name_purchase: name_purchase,
              details: order,
              events: response.data.events ? response.data.events : [],
              nextOrder: relatedOrders.nextOrder,
              prevOrder: relatedOrders.prevOrder,
            },
          });
          this.props.changeSidebarTitle(`Orden #${order.object_id}`);
        })
        .catch(() => {
          this.setState({
            order: {
              name_purchase: name_purchase,
              details: order,
              events: [],
              nextOrder: relatedOrders.nextOrder,
              prevOrder: relatedOrders.prevOrder,
            },
          });
          this.props.changeSidebarTitle(`Orden #${order.object_id}`);
        });
    } else {
      this.setState({
        order: {
          name_purchase: name_purchase,
          details: order,
          events: [],
          nextOrder: relatedOrders.nextOrder,
          prevOrder: relatedOrders.prevOrder,
        },
      });
      this.props.changeSidebarTitle(`Orden #${order.object_id}`);
    }
  };

  handleRowClick = async (shipment) => {
    const isMarketplaceOrder = this.props.selectedType === 3;
    if (isMarketplaceOrder) {
      // if(!shipment.linked) {
      this.generateLabel(shipment);
      //   }
    } else {
      this.shipmentDetails(shipment);
    }
  };

  shipmentDetails(shipment) {
    var shipment_id;
    if (shipment.id) {
      shipment_id = shipment.id;
    } else {
      if (!isNaN(shipment)) {
        shipment_id = shipment;
      }
    }

    this._openSideBar(true, `Consultando órden #${shipment_id}`, ORDER_DETAILS);
    this.setState({ order: { details: "loading", events: [] } });
    axios
      .get(`/api/shipments/${shipment_id}`)
      .then((response) => {
        this.getOrderMetadata(response.data, shipment.name_purchase);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  badgeLabel(devolution, status, hasLabel = false, row = null) {
    if (this.props.selectedType === 3 && row && !row.linked) {
      return {
        label: `${devolution ? "Devolucion - Guía sin pago" : "Guía sin pago"}`,
        type: "warning",
      };
    } else {
      switch (status) {
        case "PURCHASE":
          return {
            label: `${
              devolution ? "Devolucion - Guía sin pago" : "Guía sin pago"
            }`,
            type: "warning",
          };
        case "PICKED_UP":
          return {
            label: `${devolution ? "Devolucion - Recolectado" : "Recolectado"}`,
            type: devolution ? "warning" : "correct",
          };
        case "TRANSITO":
          return {
            label: `${devolution ? "Devolucion - En camino" : "En camino"}`,
            type: devolution ? "warning" : "correct",
          };
        case "OUT_FOR_DELIVERY":
          return {
            label: `${
              devolution ? "Devolucion - En ruta entrega" : "En ruta entrega"
            }`,
            type: devolution ? "warning" : "correct",
          };
        case "ENTREGADO":
          return {
            label: `${devolution ? "Devolucion - Entregado" : "Entregado"}`,
            type: devolution ? "warning" : "correct",
          };
        case "RETORNO_EN_PROCESO":
          return {
            label: `${devolution ? "Devolucion - Retornado" : "Retornado"}`,
            type: "warning",
          };
        case "INCIDENCIA":
          return {
            label: `${devolution ? "Devolucion - Incidencia" : "Incidencia"}`,
            type: "warning",
          };
        case "CANCELADO":
          return {
            label: `${devolution ? "Devolucion - Cancelado" : "Cancelado"}`,
            type: "error",
          };
        case "MANUAL_LABEL":
          return {
            label: `${devolution ? "Devolucion - Manual" : "Manual"}`,
            type: "warning",
          };
        default:
          return hasLabel
            ? { label: "Guía generada", type: "correct" }
            : { label: "Generando guía", type: "warning" };
      }
    }
    /*if (this.props.selectedType === 3 && row) {
      debugger;
      return {
        label: `${devolution ? "Devolucion" : "Completado"}`,
        type: devolution ? "warning" : "correct",
      };
    }*/
    return hasLabel
      ? { label: "Guía generada", type: "correct" }
      : { label: "Generando guía", type: "warning" };
  }

  selectAllPurchase = (value) => {
    if (value.length > 0) {
      let selected = [];
      let paymentDisabled = false;
      let duplicateDisabled = false;
      this.props.completeShipments.forEach((shipment) => {
        if (shipment.marketplace) {
          paymentDisabled = true;
          duplicateDisabled = true;
        }
        selected.push(shipment.object_id.toString());
      });
      this.setState({
        paymentDisabled,
        duplicateDisabled,
      });
      this.setState({ selected });
      return;
    }
    this.clearSelectedRows();
  };

  select = (value) => {
    if (this.props.selectedType === 0) {
      let paymentDisabled = false;
      let duplicateDisabled = false;
      this.props.unpayedShipments.forEach((s) => {
        value.forEach((se) => {
          if (Number(s.object_id) === Number(se) && s.marketplace) {
            paymentDisabled = true;
          }
        });
      });
      this.setState({
        paymentDisabled,
        duplicateDisabled,
      });
      return this.setState({ selected: value });
    } else if (this.props.selectedType === 1) {
      let downloadDisabled = false;
      let shipments = this.props.shipments.payed;
      shipments.forEach((s) => {
        value.forEach((se) => {
          if (Number(s.object_id) === Number(se) && !s.label) {
            downloadDisabled = true;
          }
        });
      });
      return this.setState({
        selectedPayed: value,
        downloadDisabled,
      });
    }
    this.props.changeSelectedType(value);
  };

  renderEmptyState = () => {
    var hasFilters;
    if (this.props.shipments && this.props.shipments.filters) {
      hasFilters = this.props.shipments.filters.length > 0;
    } else {
      hasFilters = false;
    }
    if (
      this.props.selectedType === 0 &&
      this.props.shipments.purchase?.length <= 0
    ) {
      return (
        <EmptyState name="marketplaces">
          <Title className="state-title">
            {hasFilters
              ? "No se han encontrado resultados"
              : "Conecta tus marketplaces"}
          </Title>
          {!hasFilters && (
            <div>
              <Text>
                Estamos para ayudarte, mantén todo tu manejo de logística en
                mienvío. Al conectar tus marketplaces nostros sincronizamos
                automáticamente tus órdenes. Recuerda que también puedes generar
                envíos independientes.
              </Text>

              <Button
                className="state-btn"
                onClick={() => {
                  this.props.goToIntegrations();
                }}
              >
                Conecta un marketplace
              </Button>
            </div>
          )}
        </EmptyState>
      );
    } else if (
      this.props.selectedType === 1 &&
      this.props.shipments.payed.length <= 0
    ) {
      return (
        <EmptyState name="shipments">
          <Title className="state-title">
            {hasFilters
              ? "No se han encontrado resultados"
              : "Aún no generas guías :("}
          </Title>
          {!hasFilters && (
            <Text>
              Cuando pagas tus envíos, nosotros generamos automáticament tu guía
              y te proveemos información de rastreo para que lo compartas con tu
              cliente. Así de fácil.
            </Text>
          )}
        </EmptyState>
      );
    } else if (
      this.props.selectedType === 2 &&
      this.props.shipments.archived.length <= 0
    ) {
      return (
        <EmptyState name="completedShipments">
          <Title className="state-title">
            {hasFilters
              ? "No se han encontrado resultados"
              : "No se han completado envíos"}
          </Title>
          {!hasFilters && (
            <Text>
              Una vez que tus envíos lleguen a tu cliente, archivaremos de forma
              automática todos tus envíos. Así podrás mantener un orden entre lo
              que aún no tiene guía, lo que tiene guía, lo que está en camino y
              lo que ya llegó. Genial, ¿no?
            </Text>
          )}
        </EmptyState>
      );
    } else if (
      this.props.selectedType === 3 &&
      this.props.shipments.orders.length <= 0
    ) {
      return (
        <EmptyState name="marketplaces">
          <Title className="state-title">
            {hasFilters
              ? "No se han encontrado resultados"
              : "Conecta tus marketplaces"}
          </Title>
          {!hasFilters && (
            <div>
              <Text>
                Estamos para ayudarte, mantén todo tu manejo de logística en
                mienvío. Al conectar tus marketplaces nostros sincronizamos
                automáticamente tus órdenes. Recuerda que también puedes generar
                envíos independientes.
              </Text>

              <Button
                className="state-btn"
                onClick={() => {
                  this.props.goToIntegrations();
                }}
              >
                Conecta un marketplace
              </Button>
            </div>
          )}
        </EmptyState>
      );
    }
  };

  handleFilterChange = (value) => {
    this.setState({ filter: value });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      let filters = this.state.filters;
      filters.push(this.state.filter);
      this.setState({
        filters,
        filter: "",
      });
    }
  };

  handleTabChange(selected) {
    this.clearSelectedRows();
    this.setState(
      {
        dropdownTabs: false,
        serviceSelected: [],
        statusSelected: [],
        page: 1,
      },
      () => {
        this.props.changeSelectedType(selected);
      }
    );
  }

  onClose = (index, filterType) => {
    switch (filterType) {
      case "servicesFilter":
        const servicesSelected = this.state.serviceSelected;
        servicesSelected.splice(index, 1);
        this.setState({ servicesSelected });
        break;

      case "statusFilter":
        const statusSelected = this.state.statusSelected;
        statusSelected.splice(index, 1);
        this.setState({ statusSelected });
        break;

      case "filters":
        const filters = this.state.filters;
        filters.splice(index, 1);
        this.setState({ filters });
        break;
    }
  };

  deleteShipments = () => {
    this.props.deleteShipments(this.state.selected);
    this.clearSelectedRows();
  };

  downloadGuidesPDF = () => {
    var links = [];
    this.state.selected.forEach((element) => {
      this.props.shipments.payed.forEach((ship) => {
        if (element == ship.id) {
          links.push(ship.id);
        }
      });
    });
    this.clearSelectedRows();

    if (links && links.length > 0) {
      this.props.mergeShipmentsPDF(links);
    }
  };

  downloadGuidesDocs = () => {
    var links = [];
    this.state.selected.forEach((element) => {
      this.props.shipments.payed.forEach((ship) => {
        if (element == ship.id) {
          links.push(ship.label_url);
        }
      });
    });
    this.clearSelectedRows();

    var zip = new JSZip();
    var contador = 0;
    if (links && links.length > 0) {
      links.forEach(function (url) {
        if (url) {
          var filename = url.substr(url.lastIndexOf("/") + 1);
          // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent(url, function (err, data) {
            if (err) {
              throw err; // or handle the error
            }
            zip.file(filename, data, { binary: true });
            contador++;

            if (contador == links.length) {
              zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, "guias.zip");
              });
            }
          });
        }
      });
    }
  };

  downloadCSV = () => {
    const { selectedType, shipments } = this.props;
    const { selected } = this.state;
    var ships = [];
    switch (selectedType) {
      case 0:
        selected.forEach((element) => {
          shipments.purchase.forEach((ship) => {
            if (element == ship.id) {
              ships.push(ship);
            }
          });
        });
        break;
      case 1:
        selected.forEach((element) => {
          shipments.payed.forEach((ship) => {
            if (element == ship.id) {
              ships.push(ship);
            }
          });
        });
        break;
      case 2:
        selected.forEach((element) => {
          shipments.archived.forEach((ship) => {
            if (element == ship.id) {
              ships.push(ship);
            }
          });
        });
        break;
      case 3:
        selected.forEach((element) => {
          shipments.orders.forEach((ship) => {
            if (element == ship.id) {
              ships.push(ship);
            }
          });
        });
        break;
    }
    const csvData = [
      [
        "N° de Orden",
        "Id Interno",
        "Fecha",
        "Destinatario",
        "Destino",
        "Servicio",
        "Estatus",
        "Total",
      ],
    ];
    ships.forEach((element) => {
      const {
        id,
        date,
        to_name,
        to_street,
        provider_name,
        service_level,
        status,
        amount,
        devolution,
      } = element;
      var data = [
        `${id}`,
        `${moment(date.split(" ")[0]).format("DD-MM-YYYY")}`,
        `${to_name}`,
        `${to_street}`,
        `${provider_name}, ${service_level}`,
        `${this.badgeLabel(devolution, status, false).label}`,
        `$${parseFloat(amount).toFixed(2)}`,
      ];
      csvData.push(data);
    });
    this.clearSelectedRows();
    return <CSVDownload data={csvData} target="_blank" />;
  };

  completeOrdersFunc = () => {
    var ships = [];
    this.state.selected.forEach((element) => {
      this.props.shipments.orders.forEach((ship) => {
        if (element == ship.id) {
          ships.push({ object_id: ship.id, marketplace: ship.marketplace });
        }
      });
    });
    this.props.completeUserOrders({ orders: ships });
    this.setState({
      selected: [],
      clearSelectedRows: !this.state.clearSelectedRows,
    });
  };

  clearSelectedRows = () => {
    this.setState({
      selected: [],
      clearSelectedRows: !this.state.clearSelectedRows,
      csvData: [
        [
          "N° de Orden",
          "Fecha",
          "Destinatario",
          "Destino",
          "Servicio",
          "Estatus",
          "Total",
        ],
      ],
    });
  };

  duplicateShipments = () => {
    this.props.duplicateShipments(this.state.selected);
    this.clearSelectedRows();
  };

  openCSVFilters = () => {
    this.props.toggleSideBar(
      true,
      "Descargar reporte de envíos",
      SHIPMENTS_FILTERS_CSV
    );
  };

  downloadGuides = () => {
    let shipments = this.props.shipments.payed;
    let labels = [];
    shipments.forEach((shipment) => {
      this.state.selectedPayed.forEach((se) => {
        if (Number(shipment.object_id) === Number(se)) {
          labels.push(shipment.label.label_url);
        }
      });
    });

    let url;
    const zip = new JSZip();

    while ((url = labels.pop())) {
      const url_name = url.split("/");
      const filename = url_name[url_name.length - 1];

      const promise = fetch(url)
        .then(function (response) {
          if (response.status === 200 || response.status === 0) {
            return Promise.resolve(response.blob());
          }
          return Promise.reject(new Error(response.statusText));
        })
        .catch(function (e) {
          console.log(e);
        });

      if (filename.includes(".pdf") && url.includes("amazonaws")) {
        /*prevent errors from dropdox and other sources*/
        zip.file(filename, promise, { base64: true });
      } else {
        window.open(url);
        this.clearSelectedRows();
      }
    }

    if (zip.file(/\s*/).length > 0) {
      /*is there at least one file to zip?*/
      zip
        .generateAsync({ type: "blob" })
        .then(function (content) {
          const today = moment(Date.now()).format("DD-MM-YYYY_hh.mm.ss");
          saveAs(content, `guias_${today}.zip`);
        })
        .catch(function (e) {
          console.log(e);
        });
    }
  };

  goToPayment = () => {
    this.props.goToPayment(this.state.selected);
    this.clearSelectedRows();
  };

  goToPaymentUnique = (order_id) => {
    this.props.goToPayment([order_id]);
  };

  handleStatusChange = (statusSelected) => {
    this.setState({ statusSelected });
  };

  renderTable = (table = 0) => {
    const conditionalRowStyles = [
      {
        when: (row) => row.status === "MANUAL_LABEL",
        style: {
          backgroundColor: "rgba(63, 195, 128, 0.9)",
          color: "white",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ];
    const titles = ["Sin guía", "Con guía", "Entregadas", "Marketplaces"];
    const columnsSinGuia = [
      {
        name: "N° Orden",
        selector: "object_id",
        sortable: true,
        cell: (row) =>
          this.props.selectedType === 3 ? (
            <div>
              <p>#{row.object_id}</p>
              <MarketplaceBadge
                color={
                  row.marketplace !== "manual" ? row.marketplace : "default"
                }
              />
            </div>
          ) : (
            <div>
              <p>#{row.id}</p>
              {row.legacy &&
                row.legacy.status &&
                row.legacy.status === "pending" && (
                  <p className="pending-payment">
                    Pago pendiente <br />
                    en marketplace
                  </p>
                )}
              {row.massive_load_id && (
                <p className="minitext">Id Interno: {row.massive_load_id}</p>
              )}
              {row.order_shop_id && (
                <p className="minitext">Id Marketplace: {row.order_shop_id}</p>
              )}

              <div style={{ display: "flex" }}>
                <MarketplaceBadge
                  color={
                    row.massive_load_id
                      ? "massive"
                      : row.marketplace !== "manual"
                      ? row.marketplace
                      : "default"
                  }
                />
              </div>
            </div>
          ),
      },
      {
        name: "Fecha",
        selector: "created_at",
        cell: (row) => {
          const shipment_date = moment(
            this.props.selectedType === 3
              ? row.created_at
              : row.date.split(" ")[0]
          );
          const today = new Date();
          const timeDiff = Math.abs(today - shipment_date);
          const daysDiff = Math.floor(timeDiff / (3600 * 24 * 1000));
          return (
            <div>
              <p>{shipment_date.format("DD-MM-YYYY")}</p>
              <p className="minitext">
                {daysDiff < 1
                  ? "hoy"
                  : `hace ${daysDiff} ${daysDiff > 1 ? "días" : "día"}`}
              </p>
            </div>
          );
        },
      },
      {
        name: "Destinatario",
        selector: "name",
        cell: (row) =>
          this.shorten(
            this.props.selectedType === 3
              ? row.shipping.first_name + " " + row.shipping.last_name
              : row.to_name,
            12
          ),
      },
      {
        name: "Destino",
        selector: "address_to",
        cell: (row) => {
          if (this.props.selectedType === 3) {
            return (
              <div>
                <p>{`${row.shipping.street} ${row.shipping.street2}`}</p>
                <p className="minitext">{`C.P. ${row.shipping.zipcode} ${row.shipping.city} ${row.shipping.state}`}</p>
              </div>
            );
          }
          let street = row.to_street.split("|");
          return (
            <div>
              <p>{street[0]}</p>
              <p className="minitext">{street[1]}</p>
            </div>
          );
        },
      },
      {
        name: "Servicio",
        selector: "service",
        cell: (row) => (
          <div>
            {/* <p>{row.provider_name ? row.provider_name : "-"}</p> */}
            <p>
              {row.provider_name
                ? row.provider_name === "FedEx"
                  ? "FDX"
                  : row.provider_name
                : "-"}
            </p>
            <p className="minitext">
              {row.service_level ? jsUcfirst(row.service_level) : "-"}
            </p>
          </div>
        ),
      },
      {
        name: "Estatus",
        selector: "label",
        width: "220px",
        cell: (row) => {
          const badge = this.badgeLabel(
            row.devolution,
            row.status,
            row.tracking_number,
            row
          );

          if (row.status === "MANUAL_LABEL") {
            return (
              <Tooltip
                title="Nuestros asesores generarán tu guía manualmente"
                position="top"
              >
                <NewBadge type={badge.type}>{badge.label}</NewBadge>
              </Tooltip>
            );
          }

          return <NewBadge type={badge.type}>{badge.label}</NewBadge>;
        },
      },
      {
        name: "Total",
        selector: "amount",
        cell: (row) =>
          row.amount
            ? `$${numberWithCommas(parseFloat(row.amount).toFixed(2))} ${
                row.currency
              }`
            : "-",
      },
    ];

    return (
      <div>
        <div className="show-for-small-only card-shipments-container newtable">
          {this.props.shipments &&
            this.props.shipments[shipmentsKeys[table]] &&
            this.props.shipments[shipmentsKeys[table]].map((shipment) => {
              let to_street = shipment.to_street.split("|");
              let from_street = shipment.from_street.split("|");
              let shipment_date = moment(shipment.date.split(" ")[0]);
              let today = new Date();
              let timeDiff = Math.abs(today - shipment_date);
              let daysDiff = Math.floor(timeDiff / (3600 * 24 * 1000));
              return (
                <Card
                  key={shipment.id}
                  className="card-shipment"
                  onClick={() => this.shipmentDetails(shipment)}
                >
                  <div>
                    <p>#{shipment.id}</p>
                    <MarketplaceBadge
                      color={
                        shipment.marketplace ? shipment.marketplace : "default"
                      }
                    />
                  </div>
                  <div>
                    <Label>Destino</Label>
                    {
                      <div>
                        <p>{to_street[0]}</p>
                        <p className="minitext">{to_street[1]}</p>
                      </div>
                    }
                  </div>
                  <div>
                    <Label>Fecha</Label>
                    {
                      <div>
                        <p>{shipment_date.format("DD-MM-YYYY")}</p>
                        <p className="minitext">
                          {daysDiff < 1
                            ? "hoy"
                            : `hace ${daysDiff} ${
                                daysDiff > 1 ? "días" : "día"
                              }`}
                        </p>
                      </div>
                    }
                  </div>
                  <div className="right-aligned">
                    <p>
                      {shipment.amount
                        ? `$${parseFloat(shipment.amount).toFixed(2)}`
                        : "-"}
                    </p>
                    <p className="minitext">
                      {shipment.provider_name ? shipment.provider_name : "-"}{" "}
                      {shipment.service_level
                        ? jsUcfirst(shipment.service_level)
                        : "-"}
                    </p>
                  </div>
                </Card>
              );
            })}
        </div>

        <Column xs={12} style={{ padding: 0 }}>
          {this.props.selectedType === 1 && (
            <Button
              style={{ float: "right", margin: 0, padding: "0.5em 1em" }}
              type="link"
              onClick={this.openCSVFilters}
            >
              Descargar reporte de envíos
            </Button>
          )}
        </Column>

        <NewTable
          selectableRows={true}
          shipping={true}
          hideForSmall={true}
          title={titles[table]}
          columns={columnsSinGuia}
          data={
            this.props.selectedType === 3
              ? this.state.marketplaceShipments
              : this.props.shipments[shipmentsKeys[table]]
          }
          onRowClicked={this.handleRowClick}
          onTableUpdate={(table) => {
            var canPay = true;
            table.selectedRows.forEach((element) => {
              if (element.owner_contract) {
                canPay = false;
              }
            });
            this.setState({
              loadingCsv: true,
              selected: table.selectedRows.map((shipments) => shipments.id),
              canPayShipments: canPay,
            });
            var data = [];
            if (table.selectedRows.length > 0) {
              axios
                .post("api/shipmentsPurchases/report", {
                  shipments: table.selectedRows.map(
                    (shipments, index) => shipments.id
                  ),
                })
                .then((response) => {
                  data = response.data.map((shipments, index) => [
                    `${shipments.order_id}`,
                    `${shipments.massive_load_id}`,
                    `${moment(shipments.date.split(" ")[0]).format(
                      "DD-MM-YYYY"
                    )}`,
                    `${shipments.receiver}`,
                    `${shipments.to_address.replace(/(\r\n|\n|\r)/gm, "")}`,
                    `${shipments.service}`,
                    `${
                      this.badgeLabel(
                        shipments.devolution,
                        shipments.status,
                        shipments.tracking_number
                      ).label
                    }`,
                    `$${parseFloat(
                      shipments.total ? shipments.total : 0
                    ).toFixed(2)}`,
                    `${shipments.content}`,
                    `${shipments.tracking_number}`,
                    `${shipments.tracking_url}`,
                  ]);
                  data.unshift([
                    "N° de Orden",
                    "Id Interno",
                    "Fecha",
                    "Destinatario",
                    "Destino",
                    "Servicio",
                    "Estatus",
                    "Total",
                    "Contenido",
                    "N° de Guía",
                    "Tracking URL",
                  ]);
                  this.setState({
                    csvData: data,
                    loadingCsv: false,
                  });
                })
                .catch((error) => {});
            }
          }}
          conditionalRowStyles={conditionalRowStyles}
          clearSelectedRows={this.state.clearSelectedRows}
          noDataComponent={this.renderEmptyState()}
          subHeader={
            <div>
              <AllFilters
                loadingCsv={this.state.loadingCsv}
                downloadGuides={table === 1}
                completeOrders={
                  table == 3 &&
                  this.props.user.customer_type === "subsided_customer"
                    ? true
                    : false
                }
                downloadGuidesDocs={this.downloadGuidesDocs}
                downloadGuidesPDF={this.downloadGuidesPDF}
                downloadCSV={this.downloadCSV}
                updateShipments={this.updateAllShipments}
                selected={this.state.selected}
                canPayShipments={this.state.canPayShipments}
                goToPayment={this.goToPayment}
                generateGuides={this.generateGuides}
                canDelete={this.state.canDelete}
                duplicateShipments={this.duplicateShipments}
                deleteShipments={this.deleteShipments}
                csvData={this.state.csvData}
                completeOrdersFunc={this.completeOrdersFunc}
                uncollected={this.state.uncollected}
                renderUncollected={this.renderUncollected}
                selectedType={this.props.selectedType}
              />
              <Dropdown
                options={this.renderLimits()}
                label="Selecciona el número de envíos a desplegar"
                handleChange={this.handleLimitDrop}
                selected={this.state.limit}
                style={{ zIndex: 1 }}
                className="limit-dp"
                style={{ zIndex: 1 }}
              />
            </div>
          }
        />
      </div>
    );
  };

  toggleCollapsibleSidebar = async () => {
    await this.props.setStepTitles([
      {
        label: "Origen",
        status: "unfinished",
      },
      {
        label: "Destino",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Paquete",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Servicio",
        status: "unfinished",
        forwardClickable: false,
      },
    ]);
    this.props.toggleSideBar(true, "Creación de guías", NEW_SHIPMENT);
  };

  _openSideBar = (
    stateSidebar = false,
    sidebarTitle = "",
    sidebarType = ""
  ) => {
    this.props.toggleSideBar(stateSidebar, sidebarTitle, sidebarType);
  };

  getInsuranceModel = (isSaved) => ({
    holder: isSaved
      ? this.props.sidebarOrder.fromAddress.name
      : this.props.sidebarOrder.data.from.name,
    email: isSaved
      ? this.props.sidebarOrder.fromAddress.email
      : this.props.sidebarOrder.data.from.email,
    product: this.props.sidebarOrder.data.package.description,
    insured_amount: this.props.sidebarOrder.data.package.insuredAmount,
    amount: this.props.sidebarOrder.data.package.insuredAmount,
    cost: this.props.sidebarOrder.data.package.cost,
  });

  getPackageModel = (isSaved) => ({
    packageId: isSaved
      ? this.props.sidebarOrder.selectedPackage.object_id
      : null,
    length: isSaved
      ? this.props.sidebarOrder.selectedPackage.length
      : this.props.sidebarOrder.data.package.dimensions.length,
    width: isSaved
      ? this.props.sidebarOrder.selectedPackage.width
      : this.props.sidebarOrder.data.package.dimensions.width,
    height: isSaved
      ? this.props.sidebarOrder.selectedPackage.height
      : this.props.sidebarOrder.data.package.dimensions.height,
    weight:
      this.props.sidebarOrder.data.package.weight <
      this.props.sidebarOrder.data.package.volumetricWeight
        ? this.props.sidebarOrder.data.package.volumetricWeight
        : this.props.sidebarOrder.data.package.weight,
    description: this.props.sidebarOrder.data.package.description,
    insured_amount: this.props.sidebarOrder.data.package.insuredAmount,
  });

  getSavePackageModel = (pack) => ({
    alias: pack.alias,
    height: pack.dimensions.height,
    length: pack.dimensions.length,
    object_type_code: ["box", "envelope"][pack.packageType],
    width: pack.dimensions.width,
    description: pack.description,
    weight: pack.weight,
    insured_amount: pack.insuredAmount,
  });

  convertAddressModel = (address, origin_destiny) => ({
    object_type: "FROMTO",
    object_id: null,
    name: address.name,
    street: address.street,
    street2: address.neighborhood,
    reference: address.reference,
    zipcode: address.zipcode,
    city: address.city.split(",")[0],
    state:
      address.city.split(",").length > 1 &&
      address.city.split(",")[1].split(" ").join(""),
    country: address.country,
    country_code: this._getCountryByIndex(address.country).code,
    email: address.email,
    phone: address.phone,
    bookmark: false,
    alias: address.alias,
    owner_id: this.props.userId,
    origin_destiny: origin_destiny == "origen" ? false : true,
  });

  _handleInvoice = async () => {
    if (
      isNaN(this.props.sidebarOrder?.data?.package?.insuredAmount) ||
      !this.props.sidebarOrder?.data?.package?.description ||
      isNaN(this.props.sidebarOrder?.data?.package?.dimensions?.height) ||
      isNaN(this.props.sidebarOrder?.data?.package?.dimensions?.length) ||
      isNaN(this.props.sidebarOrder?.data?.package?.dimensions?.width) ||
      this.props.sidebarOrder.stepTitles[2].status === "unfinished"
    ) {
      this.setState({
        packageDataError: true,
      });
      return false;
    } else {
      this.setState({
        packageDataError: false,
      });
    }

    if (this.props.sidebarOrder.manualFrom) {
      const isEditing = this.props.sidebarOrdec_invoicediting;

      const from =
        this.props.sidebarOrder.fromAddress && !isEditing
          ? this.props.sidebarOrder.fromAddress
          : this.convertAddressModel(
              this.props.sidebarOrder.data.from,
              "origen"
            );

      if (
        this.props.sidebarOrder.editing &&
        this.props.sidebarOrder.editing > 0
      ) {
        await this.props.editShipment(this.props.sidebarOrder.editing, from);
      } else {
        if (
          this.props.sidebarOrder.data.from.saveAddress &&
          this.props.sidebarOrder.data.from.email &&
          this.props.sidebarOrder.data.from.phone
        ) {
          //this.props.createAddress(this.convertAddressModel(this.props.sidebarOrder.data.from, "origen"));
        }
      }
    }
    this.props.nextStep();
  };

  _handleSendOrder = async () => {
    let insurance = null;
    const isEditing = this.props.sidebarOrder.editing;
    const from = this.props.sidebarOrder.fromAddress
      ? this.props.sidebarOrder.fromAddress
      : this.convertAddressModel(this.props.sidebarOrder.data.from, "origen");
    const to = this.props.sidebarOrder.toAddress
      ? this.props.sidebarOrder.toAddress
      : this.convertAddressModel(this.props.sidebarOrder.data.to, "destino");
    const pack = this.getPackageModel(
      this.props.sidebarOrder.selectedPackage !== null
    );

    if (
      this.props.sidebarOrder.data.package.haveInsurance == 1 &&
      this.props.sidebarOrder.data.carrier.shipment.provider !== "DHL"
    )
      insurance = this.getInsuranceModel(
        this.props.sidebarOrder.fromAddress !== null
      );

    const order = this.props.sidebarOrder.isEditing
      ? {
          object_id: this.props.sidebarOrder.object_id,
          marketplace: this.props.sidebarOrder.marketplace,
          shop_id: this.props.sidebarOrder.shop_id,
        }
      : null;

    if (
      this.props.sidebarOrder.editing &&
      this.props.sidebarOrder.editing > 0
    ) {
      await this.props.editShipment(
        this.props.sidebarOrder.editing,
        from,
        to,
        pack,
        insurance,
        this.props.sidebarOrder.data.carrier.shipment.object_id,
        this.props.sidebarOrder.data.package.insuredAmount
      );
    } else {
      const packages_data = JSON.stringify(
        this.props.sidebarOrder.commercialInvoice.packages
      );
      var fromAddress = null;
      var toAddress = null;
      if (
        this.props.sidebarOrder.toAddress &&
        this.props.sidebarOrder.fromAddress
      ) {
        fromAddress = {
          alias: this.props.sidebarOrder.fromAddress.alias,
          city: this.props.sidebarOrder.fromAddress.city,
          country: this.props.sidebarOrder.fromAddress.country,
          email: this.props.sidebarOrder.fromAddress.email,
          name: this.props.sidebarOrder.fromAddress.name,
          neighborhood: this.props.sidebarOrder.fromAddress.neighborhood,
          phone: this.props.sidebarOrder.fromAddress.phone,
          reference: this.props.sidebarOrder.fromAddress.reference,
          street: this.props.sidebarOrder.fromAddress.street,
          zipcode: this.props.sidebarOrder.fromAddress.zipcode,
        };
        toAddress = {
          alias: this.props.sidebarOrder.toAddress.alias,
          city: this.props.sidebarOrder.toAddress.city,
          country: this.props.sidebarOrder.toAddress.country,
          email: this.props.sidebarOrder.toAddress.email,
          name: this.props.sidebarOrder.toAddress.name,
          neighborhood: this.props.sidebarOrder.toAddress.neighborhood,
          phone: this.props.sidebarOrder.toAddress.phone,
          reference: this.props.sidebarOrder.toAddress.reference,
          street: this.props.sidebarOrder.toAddress.street,
          zipcode: this.props.sidebarOrder.toAddress.zipcode,
        };
      } else {
        if (this.props.sidebarOrder.toAddress) {
          toAddress = {
            alias: this.props.sidebarOrder.toAddress.alias,
            city: this.props.sidebarOrder.toAddress.city,
            country: this.props.sidebarOrder.toAddress.country,
            email: this.props.sidebarOrder.toAddress.email,
            name: this.props.sidebarOrder.toAddress.name,
            neighborhood: this.props.sidebarOrder.toAddress.neighborhood,
            phone: this.props.sidebarOrder.toAddress.phone,
            reference: this.props.sidebarOrder.toAddress.reference,
            street: this.props.sidebarOrder.toAddress.street,
            zipcode: this.props.sidebarOrder.toAddress.zipcode,
          };
          fromAddress = this.props.sidebarOrder.data.from;
        } else {
          if (this.props.sidebarOrder.fromAddress) {
            fromAddress = {
              alias: this.props.sidebarOrder.fromAddress.alias,
              city: this.props.sidebarOrder.fromAddress.city,
              country: this.props.sidebarOrder.fromAddress.country,
              email: this.props.sidebarOrder.fromAddress.email,
              name: this.props.sidebarOrder.fromAddress.name,
              neighborhood: this.props.sidebarOrder.fromAddress.neighborhood,
              phone: this.props.sidebarOrder.fromAddress.phone,
              reference: this.props.sidebarOrder.fromAddress.reference,
              street: this.props.sidebarOrder.fromAddress.street,
              zipcode: this.props.sidebarOrder.fromAddress.zipcode,
            };
            toAddress = this.props.sidebarOrder.data.to;
          } else {
            toAddress = this.props.sidebarOrder.data.to;
            fromAddress = this.props.sidebarOrder.data.from;
          }
        }
      }

      const data = {
        label_id: "",
        invoice_no: this.props.sidebarOrder.commercialInvoice.invoice_no,
        purchase_order:
          this.props.sidebarOrder.commercialInvoice.purchase_order,
        payment_terms: this.props.sidebarOrder.commercialInvoice.payment_terms,
        incoterm: this.props.sidebarOrder.commercialInvoice.incoterm,
        incoterm_example:
          this.props.sidebarOrder.commercialInvoice.incoterm_example,
        export_reason: this.props.sidebarOrder.commercialInvoice.export_reason,
        from_address: fromAddress,
        to_address: toAddress,
        sold_address: toAddress,
        special_instructions:
          this.props.sidebarOrder.commercialInvoice.special_instructions,
        declaration_statements:
          this.props.sidebarOrder.commercialInvoice.declaration_statements,
        packages: packages_data,
        from_tax: this.props.sidebarOrder.commercialInvoice.from_tax,
        to_tax: this.props.sidebarOrder.commercialInvoice.to_tax,
      };
      await this.props.createShipment(
        this.props.sidebarOrder.isDevolution,
        from,
        to,
        pack,
        this.props.sidebarOrder.data.package.insuredAmount,
        this.props.sidebarOrder.data.carrier.shipment.object_id,
        insurance,
        order,
        data,
        this.props.sidebarOrder.data.carrier.shipment.extended_zone
      );
      if (this.props.sidebarOrder.data.from.saveAddress)
        this.props.createAddress(
          this.convertAddressModel(this.props.sidebarOrder.data.from, "origen")
        );

      if (this.props.sidebarOrder.data.to.saveAddress)
        this.props.createAddress(
          this.convertAddressModel(this.props.sidebarOrder.data.to, "destino")
        );

      if (this.props.sidebarOrder.data.package.savePackage)
        this.props.createPackage(
          this.getSavePackageModel(this.props.sidebarOrder.data.package)
        );
    }

    this.props.toggleSideBar(false);
    this.props.clearSidebar();
  };
  _getCountryByIndex = (index) => this.props.sidebarOrder.countries[index];
  _isShipmentCompleted = () =>
    this.props.sidebarOrder.step == 2 &&
    (this.props.sidebarOrder.stepTitles[0].status !== "completed" ||
      this.props.sidebarOrder.stepTitles[1].status !== "completed" ||
      this.props.sidebarOrder.stepTitles[2].status !== "completed");

  _handleNext = async () => {
    if (
      this.props.sidebarOrder.step === 0 &&
      (this.props.sidebarOrder.stepError.from ||
        this.props.sidebarOrder.stepTitles[0].status !== "completed")
    ) {
      this.setState({
        proccesLabelError: true,
      });
      return null;
    }

    if (
      this.props.sidebarOrder.step === 1 &&
      (this.props.sidebarOrder.stepError.to ||
        this.props.sidebarOrder.stepTitles[1].status !== "completed")
    ) {
      this.setState({
        proccesLabelError: true,
      });
      return null;
    }

    if (this.props.sidebarOrder.step === 1) {
      if (
        this.props.sidebarOrder.manualTo &&
        this.props.sidebarOrder.data.to.country &&
        this._getCountryByIndex(this.props.sidebarOrder.data.to.country)
          .code !== "MX" &&
        !this.props.sidebarOrder.toAddress
      ) {
        const to = this.convertAddressModel(
          this.props.sidebarOrder.data.to,
          "destino"
        );
        await this.props.createAddress(to, true);
      }
    }
    const isShipmentCompleted = this._isShipmentCompleted();
    await this.setStateAsync({ shakeClass: "" });
    await this.setStateAsync({
      shakeClass: isShipmentCompleted ? "animated shake" : "",
      toolTipOpen: isShipmentCompleted,
    });
    if (!isShipmentCompleted) this.props.nextStep();
  };

  _getStepError = () => {
    if (this.props.sidebarOrder.stepTitles.length > 0) {
      if (this.props.sidebarOrder.stepTitles[0].status !== "completed")
        return this.props.sidebarOrder.stepError.from !== ""
          ? this.props.sidebarOrder.stepError.from
          : "Completar la información de Origen";
      else if (this.props.sidebarOrder.stepTitles[1].status !== "completed")
        return this.props.sidebarOrder.stepError.to !== ""
          ? this.props.sidebarOrder.stepError.to
          : "Completar la información de Destíno";
      else if (this.props.sidebarOrder.stepTitles[2].status !== "completed")
        return this.props.sidebarOrder.stepError.package !== ""
          ? this.props.sidebarOrder.stepError.package
          : "Completar la información de Paquete";
      else if (this.props.sidebarOrder.stepTitles[3].status !== "completed")
        return "Error al seleccionar el servicio disponible";
    } else return "";
  };

  _closeToolTip = () => {
    setTimeout(() => {
      this.setState({ toolTipOpen: false });
    }, 3000);
  };

  _onCloseCSV = async (total) => {
    this.props.toggleSideBar(false);
    await this.setStateAsync({ page: 1 });
    if (total == 1)
      this.props.showMessage("success", `Se creo una orden con exito`);
    else if (total > 0)
      this.props.showMessage(
        "success",
        `Se crearon ${total} ordenes con exito`
      );
    await this.props.getAllUserShipments(this.state.limit);
  };

  updateAllShipments = async (filters = [], param) => {
    await this.setStateAsync({ filters: filters });
    if (this.props.selectedType === 3) {
      this.props
        .getMarketplaceShipments(
          this.state.page,
          this.state.limit,
          this.state.filters,
          true
        )
        .then((response) => {
          this.setState({
            marketplaceShipments: response
              ? Array.isArray(response[0])
                ? response[0]
                : response
              : [],
          });
        });
    } else {
      await this.props.getAllUserShipments(
        this.state.limit,
        this.state.page,
        this.state.filters,
        param,
        "shipments",
        "updateAllShipments"
      );
    }
  };

  updateAllShipmentsView = async () => {
    if (this.props.selectedType === 3) {
      this.props
        .getMarketplaceShipments(
          this.state.page,
          this.state.limit,
          this.state.filters,
          true
        )
        .then((response) => {
          this.setState({ marketplaceShipments: response });
        });
    } else {
      await this.props.getAllUserShipments(
        this.state.limit,
        this.state.page,
        this.state.filters,
        null,
        "shipments",
        "updateAllShipmentsView"
      );
    }
  };

  _renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
      case ORDER_DETAILS:
        return (
          <ContentAdapter>
            {this.state.order && (
              <DetailedOrder
                executeShipment={this.executeShipment}
                goToShipmentsList={this.props.goToShipmentsList}
                order={this.state.order}
                goToPayment={this.goToPaymentUnique}
                events={this.state.order.events}
              />
            )}
          </ContentAdapter>
        );
      case NEW_SHIPMENT:
        return (
          <ContentAdapter>
            <ShipmentWrapper>
              {this.props.sidebarOrder.step === 0 &&
                this.props.sidebarOrder.stepError.from &&
                this.state.proccesLabelError && (
                  <Text type="label" className="create-label-error">
                    {this.props.sidebarOrder.stepError.from}{" "}
                  </Text>
                )}

              {this.props.sidebarOrder.step === 1 &&
                this.props.sidebarOrder.stepError.to &&
                this.state.proccesLabelError && (
                  <Text type="label" className="create-label-error">
                    {this.props.sidebarOrder.stepError.to}
                  </Text>
                )}

              {this.props.sidebarOrder.step === 2 &&
                this.state.packageDataError && (
                  <Text type="label" className="create-label-error">
                    {this.props.sidebarOrder.stepError.package !== ""
                      ? this.props.sidebarOrder.stepError.package
                      : "Los campos: dimensiones, descripción y valor del paquete son obligatorios"}
                  </Text>
                )}

              <CreateLabel />

              <BottomContainer>
                <Row divisions={20}>
                  <Column
                    xs={9}
                    xsShift={1}
                    style={{ textAlign: "left" }}
                    fluid
                  >
                    {this.props.sidebarOrder.step > 0 && (
                      <div>
                        <Button
                          type="link"
                          className="edit-btn-left more-btn"
                          onClick={() => this.props.previousStep()}
                          loading={this.props.sidebarOrder.loading}
                        >
                          Anterior
                        </Button>
                      </div>
                    )}
                  </Column>
                  <Column
                    xs={8}
                    xsShift={1}
                    style={{ textAlign: "right" }}
                    fluid
                  >
                    {(this.props.sidebarOrder.step < 1 && (
                      <div>
                        <Tooltip
                          title={this._getStepError()}
                          position="top"
                          trigger="manual"
                          distance={20}
                          delay={5}
                          onShown={this._closeToolTip}
                          open={this.state.toolTipOpen}
                        >
                          <Button
                            className={this.state.shakeClass}
                            onClick={this._handleNext}
                            loading={this.props.sidebarOrder.loading}
                          >
                            Siguiente
                          </Button>
                        </Tooltip>
                      </div>
                    )) ||
                      (this.props.sidebarOrder.step == 1 && (
                        <div>
                          <Tooltip
                            title={this._getStepError()}
                            position="top"
                            trigger="manual"
                            distance={20}
                            delay={5}
                            onShown={this._closeToolTip}
                            open={this.state.toolTipOpen}
                          >
                            <Button
                              className={this.state.shakeClass}
                              onClick={this._handleNext}
                              loading={this.props.sidebarOrder.loading}
                            >
                              Siguiente
                            </Button>
                          </Tooltip>
                        </div>
                      )) ||
                      (this.props.sidebarOrder.step == 2 && (
                        <div>
                          <Tooltip
                            title={this._getStepError()}
                            position="top"
                            trigger="manual"
                            distance={20}
                            delay={5}
                            onShown={this._closeToolTip}
                            open={this.state.toolTipOpen}
                          >
                            <Button
                              className={this.state.shakeClass}
                              onClick={this._handleInvoice}
                              loading={this.props.sidebarOrder.loading}
                            >
                              Siguiente
                            </Button>
                          </Tooltip>
                        </div>
                      )) || (
                        <div>
                          {this.props.sidebarOrder.data.carrier.shipment && (
                            <Button
                              className=""
                              onClick={this._handleSendOrder}
                              loading={this.props.sidebarOrder.loadingCost}
                            >
                              Guardar
                            </Button>
                          )}
                        </div>
                      )}
                  </Column>
                  <Column
                    xs={1}
                    xsShift={1}
                    style={{ textAlign: "right" }}
                    fluid
                  />
                </Row>
              </BottomContainer>
            </ShipmentWrapper>
          </ContentAdapter>
        );
      case QUICK_QUOTE_TYPE:
        return (
          <ContentAdapter>
            <QuickQuote />
          </ContentAdapter>
        );
      case CHECKOUT_DETAILS:
        return (
          <div>
            <CheckoutDetails />
          </div>
        );
      case SHIPMENTS_BY_CSV:
        return (
          <ContentAdapter>
            <ShipmentsCSV onClose={this._onCloseCSV} />
          </ContentAdapter>
        );
      case SHIPMENTS_FILTERS_CSV:
        return <ModalFiltersCSV />;
      default:
        return <div />;
    }
  };

  _openQuickQuote = () => {
    this.props.toggleSideBar(true, "Cotizador rápido", QUICK_QUOTE_TYPE);
  };

  _openImportCSV = async () => {
    this.props.initSteps();
    this.props.toggleSideBar(true, "Importar CSV", SHIPMENTS_BY_CSV);
  };

  _getOptions = () => [
    {
      onClick: this._openImportCSV,
      text: "Importar CSV ",
    },
  ];

  _renderSidebarTitleExtraContent = () => {
    switch (this.props.sidebar.type) {
      case ORDER_DETAILS:
        if (this.state.order) {
          return (
            <DetailedOrderMenu
              delete={this.state.canDelete}
              update={this.state.canUpdate}
              order={this.state.order}
              loading={this.state.order.details === "details"}
              changeOrderFunction={this.shipmentDetails}
            />
          );
        } else {
          return null;
        }
      default:
        return null;
    }
  };

  @boundMethod
  onCloseContactModal() {
    this.props.setUserMaxShipments(false);
  }

  renderLimits = () => {
    const plans = ["15", "50", "100"];
    return plans.map((plan) => plan);
  };
  handleLimitDrop = async (selected) => {
    await this.setStateAsync({ limit: selected, page: 1 });
    this.updateAllShipmentsView();
  };

  renderPlans = () => {
    const plans = ["Advance", "Professional", "Enterprise"];
    return plans.map((plan) => <Text>{plan}</Text>);
  };
  handlePlanDrop = (selected) => {
    this.setState({ selectedPlan: selected });
  };

  renderChallenge = () => {
    const challenges = [
      "Automatizar Tareas",
      "Abrir Nuevos Mercados",
      "Centralizar mi logística en un solo lugar",
      "Envíos más economicos",
      "Integrar múltiples paqueterías",
      "Mejorar el tracking de mis envíos",
      "Tercerizar tu resolución de incidencias (Paquetes atrasados o perdidos)",
    ];
    return challenges.map((challenge) => <Text>{challenge}</Text>);
  };

  handleChallengeDrop = (selected) => {
    this.setState({ selectedChallenge: selected });
  };

  renderShipments = () => {
    const ships = [
      "0 - 50",
      "50 - 99",
      "100 - 300",
      "300 - 500",
      "500 - 999",
      "+1000",
    ];
    return ships.map((ship) => <Text>{ship}</Text>);
  };

  handleShipmentsDrop = (selected) => {
    this.setState({ selectedShipment: selected });
  };

  sendPlan() {
    if (this.validatePlan()) {
      const ships = [
        "0 - 50",
        "50 - 99",
        "100 - 300",
        "300 - 500",
        "500 - 999",
        "+1000",
      ];
      const plans = ["Advance", "Professional", "Enterprise"];
      const challenges = [
        "Automatizar Tareas",
        "Abrir Nuevos Mercados",
        "Centralizar mi logística en un solo lugar",
        "Envíos más economicos",
        "Integrar múltiples paqueterías",
        "Mejorar el tracking de mis envíos",
        "Tercerizar tu resolución de incidencias (Paquetes atrasados o perdidos)",
      ];
      const data = {
        name: `${this.props.user.first_name} ${this.props.user.last_name}`,
        phone: this.props.user.phone,
        email: this.props.user.email,
        company_name: this.props.user.company_name
          ? this.props.user.company_name
          : "Sin Compañia",
        position: "Usuario Mienvio",
        store_url: this.props.user.site_url,
        shippings: ships[this.state.selectedShipment],
        challenge: challenges[this.state.selectedChallenge],
      };
      /* axios.post(`https://contacto.mienvio.mx/contacts?type=CambioPlan${plans[this.state.selectedPlan]}`, data).then(res => {
        this.props.setUserMaxShipments(false);
      });*/
      fetch(
        `https://contacto.mienvio.mx/contacts?type=CambioPlan${
          plans[this.state.selectedPlan]
        }`,
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      )
        .then(() => {
          this.props.setUserMaxShipments(false);
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
  validatePlan() {
    var flag = true;
    if (this.state.selectedChallenge.length === 0) {
      flag = false;
      this.setState({ selectedChallengeError: "Campo Obligatorio" });
    } else {
      this.setState({ selectedChallengeError: "" });
    }
    if (this.state.selectedShipment.length === 0) {
      flag = false;
      this.setState({ selectedShipmentError: "Campo Obligatorio" });
    } else {
      this.setState({ selectedShipmentError: "" });
    }
    if (this.state.selectedPlan.length === 0) {
      flag = false;
      this.setState({ selectedPlanError: "Campo Obligatorio" });
    } else {
      this.setState({ selectedPlanError: "" });
    }
    return flag;
  }

  @boundMethod
  generateGuides(ids) {
    const model = {
      shipments: ids,
      source_type: "web_portal",
      charge_type: "SHIPMENTS_AND_OVERWEIGHT",
      overweight_payment: "0.00",
    };
    axios
      .post("/api/purchases", model)
      .then(async (response) => {
        this.props.loadInvoice(response.data);
        this.props.showMessage("success", "Guias generadas exitosamente");

        this.setState({ loadingBtn: false });
        this.clearSelectedRows();
        this.executeShipment();
      })
      .catch((err) => {
        this.setState({ loadingBtn: false });
      });
  }

  render() {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    if (
      this.props.message.show &&
      (this.props.message.messageType === "error" ||
        this.props.message.messageType === "success")
    ) {
      setTimeout(this.props.hideMessage, 5000);
    }
    const CloseButton = ({ YouCanPassAnyProps, closeToast }) => (
      <p
        onClick={() => window.open("https://www.google.com/")}
        style={{ textAlign: "center" }}
      >
        Actualizar
        <br />
        Plan
      </p>
    );

    return (
      <div>
        <ModalResponsive
          classNames={{ modal: "modal-shipments" }}
          open={this.props.shipments.shipmentsModal}
          onClose={this.onCloseContactModal}
        >
          <h2 style={{ width: "80%", marginTop: "10px", fontSize: "20px" }}>
            Actualiza tu plan y realiza envíos sin límite. Un asesor te
            contactará, ayúdanos respondiendo estas preguntas
          </h2>
          <Dropdown
            placeholder={
              <Text className="dropdown-placeholder dp-service">Plan</Text>
            }
            options={this.renderPlans()}
            label="Selecciona el plan que deseas adquirir"
            handleChange={this.handlePlanDrop}
            selected={this.state.selectedPlan}
            error={this.state.selectedPlanError}
          />
          <Dropdown
            down
            placeholder={
              <Text className="dropdown-placeholder dp-service">Reto</Text>
            }
            options={this.renderChallenge()}
            label="¿Cuál es el principal reto que necesitas solucionar?"
            handleChange={this.handleChallengeDrop}
            selected={this.state.selectedChallenge}
            error={this.state.selectedChallengeError}
          />
          <Dropdown
            down2
            placeholder={
              <Text className="dropdown-placeholder dp-service">Envios</Text>
            }
            options={this.renderShipments()}
            label="¿Cuántos envíos tienes en este momento?"
            handleChange={this.handleShipmentsDrop}
            selected={this.state.selectedShipment}
            error={this.state.selectedShipmentError}
          />
          <p style={{ margin: "0px", marginTop: "15px" }}>
            ¿No sabes que plan elegir?
          </p>
          <Button
            style={{ margin: "0px", marginTop: "15px" }}
            type="link"
            onClick={() =>
              window.open("https://www.mienvio.mx/comparativo-planes.html")
            }
          >
            Ver comparativo de planes
          </Button>
          <Button
            style={{ width: "90%", marginTop: "30px" }}
            onClick={() => this.sendPlan()}
          >
            Enviar
          </Button>
        </ModalResponsive>
        <ToastContainer
          closeButton={<CloseButton YouCanPassAnyProps="foo" />}
        />
        <WrapperSideBar
          title_more={this._renderSidebarTitleExtraContent()}
          handleClose={this.props.clearSidebar}
        >
          {this._renderSidebarContent()}
        </WrapperSideBar>
        <CommercialInvoice />
        <Row divisions={20} className="shipments-row">
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Header>
              <Row divisions={12}>
                <Column xs={3} fluid>
                  <Title type="titleCard">Envíos&nbsp;&nbsp;</Title>
                </Column>
                <Column xs={9} className="shipments-buttons-container" fluid>
                  <Button
                    type="secondary"
                    className="quote-btn"
                    onClick={this._openQuickQuote}
                    initialLoader
                  >
                    Cotiza
                  </Button>
                  {/*<Button
                    type="secondary"
                    className="quote-btn"
                    onClick={() =>
                      window.open("https://form.jotform.co/82914873257871")
                    }
                    initialLoader
                  >
                    Envíos Internacionales
                  </Button>*/}
                  {searchPermission("envios", "editar", permissions) && (
                    <ActionButton
                      className="last new-shipment-btn"
                      onClick={this.toggleCollapsibleSidebar}
                      initialLoader
                      options={this._getOptions()}
                    >
                      Nuevo envío
                    </ActionButton>
                  )}
                </Column>
              </Row>
            </Header>
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            {this.props.message.show && (
              <Message
                color={this.props.message.messageType}
                onClose={this.props.hideMessage}
              >
                {this.props.message.text}
              </Message>
            )}
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={0} md={1} fluid />
          <Column xs={20} md={18} fluid>
            <div size="full" padding="nopadding">
              <Row divisions={24} className="shipping-list-tabs-container">
                <Column xs={21} md={24}>
                  <Tabs
                    id="order_type"
                    selected={this.props.selectedType}
                    right
                    onChange={(selected) => this.handleTabChange(selected)}
                  >
                    <Tab>Sin guía</Tab>
                    <Tab>Con guía</Tab>
                    <Tab right className="hide-for-small-only">
                      Entregadas
                    </Tab>
                    <Tab
                      className={`marketplaces-tab ${
                        this.props.user.has_market_place === false &&
                        "display-none"
                      }`}
                    >
                      Marketplaces
                    </Tab>
                  </Tabs>
                </Column>

                <Column xs={3} md={0}>
                  <IMore
                    onClick={() => this.toggleDropdownTabs()}
                    className="ion-android-more-horizontal show-for-small-only"
                  />
                  {this.state.dropdownTabs ? (
                    <DropdownConfig className="dropdown-tabs">
                      <ConfigLink onClick={() => this.handleTabChange(2)}>
                        <Text
                          type="textTable"
                          style={{ verticalAlign: "middle" }}
                        >
                          Entregadas
                        </Text>
                      </ConfigLink>
                    </DropdownConfig>
                  ) : null}
                </Column>
              </Row>
              <br />
              {!this.state.loading && this.renderTable(this.props.selectedType)}
              {this.state.loading ? (
                <LoaderContainer>
                  <Loader
                    color={colors.primary.mienvio_pink}
                    type="ball-pulse"
                    active
                  />
                </LoaderContainer>
              ) : null}
              {!this.state.loading && (
                <Button
                  type="link"
                  style={{ display: "block", margin: "8px auto" }}
                  onClick={this._loadMore}
                >
                  Mostrar más
                </Button>
              )}
            </div>
          </Column>
          <Column xs={0} md={1} fluid />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  shipments: state.shipments,
  unpayedShipments: shipmentsSelectors.unpayedShipmentsSelector(state),
  incompleteShipments: shipmentsSelectors.incompleteShipmentsSelector(state),
  completeShipments: shipmentsSelectors.completeShipmentsSelector(state),
  shipmentsProviders: shipmentsSelectors.shipmentsProvidersSelector(state),
  message: state.message,
  loader: state.loader,
  dashboard: state.dashboard,
  sidebar: state.sidebar,
  step: state.newLabel,
  sidebarOrder: state.newShipment,
  c_invoice: state.c_invoices,
  userId: state.user.object_id,
  user: state.user,
  invoice: state.invoices,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadInvoice,
      getAndAppendAllUserShipments,
      getAllUserShipments,
      previousStep,
      nextStep,
      setValue,
      getUserShipmentsPurchase,
      getUserShipments,
      resetCreated,
      getUserOrders,
      createShipment,
      editShipment,
      goToShipmentsList: () => push("/envios"),
      goToCreateShipment: () => push("/envios/crear"),
      goToIntegrations: () => push("/integraciones"),
      goToShipment: (id) => push(`/ envios / ${id}`),
      deleteShipments,
      mergeShipmentsPDF,
      duplicateShipments,
      clearSidebar,
      setStepTitles,
      createAddress,
      createPackage,
      hideMessage,
      clearQuickQuote,
      toggleSideBar,
      changeSidebarTitle,
      generateLabelNew,
      getUserCards,
      initSteps,
      showMessage,
      closeInvoice,
      sendDataInvoice,
      clearFilters,
      appendFilter,
      deleteFilter,
      changeManual,
      clearSelectAddressTo,
      clearSelectAddressFrom,
      completeUserOrders,
      getCountries,
      setUserMaxShipments,
      getMarketplaceShipments,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentsList);

export { DropdownConfig, DropdownConfigV2, ConfigLink };
