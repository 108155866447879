import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { boundMethod } from 'autobind-decorator';
import { Row, Column } from 'hedron';
import Skeleton from 'react-loading-skeleton';
import valid from 'card-validator';
import Scriptly from 'scriptly';
import {
  toggle as toggleSideBar,
} from '../../../modules/CollapsibleBar/actions';
import close_icon from '../../WrapperSideBar/img/close.svg';
import cardIcon from './icons/cards.svg';
import NewTable from '../../NewTable';
import Button from '../../Button';
import ThreeDotsDropdown from '../../Dropdown/ThreeDotsDropdown/index';
import { getCards, addCard, STRIPE_PUBLIC } from '../../../modules/marketplaceAccount/cards';
import edit_icon from '../../../utils/icons/edit.svg';
import WrapperSideBar from '../../WrapperSideBar';
import card_placeholder from '../../CardsList/icons/card.svg';
import Input from '../../Input';
import { BottomContainer, ContentAdapter } from '../../WrapperSideBar/styledObjects';

const StyledDot = styled.div`
  display: inline-block;
  height: 5px;
  width: 5px;
  margin-bottom: 2px;
  background: #4a4a4a;
  margin-right: 6px;
  border-radius: 100px;
`;

const columns = [
  {
    name: 'Número de tarjeta',
    selector: 'alias',
    cell: row => (
      <div>
        {row.brand === 'Visa' ? (
          <img
            style={{ marginRight: '23px' }}
            src="http://app.mienvio.mx/media/cards/visa.png"
            width="30"
            alt=""
          />
        ) : (
          <img
            style={{ marginRight: '23px' }}
            src="http://app.mienvio.mx/media/cards/mastercard.png"
            width="30"
            alt=""
          />
        )}
        <StyledDot />
        <StyledDot />
        <StyledDot />
        <StyledDot />
        <StyledDot />
        <StyledDot />
        <StyledDot />
        <StyledDot />
        {row.last4}
      </div>
    ),
  },
  {
    name: 'Titular',
    selector: 'name',
  },
  {
    name: 'Vencimiento',
    selector: 'exp_month',
    cell: row => (
      <div>
        {row.exp_month.toString().length === 2 ? row.exp_month : `0${row.exp_month}`}
/
        {row.exp_year}
      </div>
    ),
  },
];

const data = [
  {
    brand: 'Visa',
    last4: '4442',
    name: 'Alan Abundis Canales',
    exp_month: 10,
    exp_year: 22,
  },
  {
    brand: 'Visa',
    last4: '4442',
    name: 'Alan Abundis Canales',
    exp_month: 10,
    exp_year: 22,
  },
  {
    brand: 'Visa',
    last4: '4442',
    name: 'Alan Abundis Canales',
    exp_month: 10,
    exp_year: 22,
  },
  {
    brand: 'Visa',
    last4: '4442',
    name: 'Alan Abundis Canales',
    exp_month: 10,
    exp_year: 22,
  },
  {
    brand: 'Visa',
    last4: '4442',
    name: 'Alan Abundis Canales',
    exp_month: 10,
    exp_year: 22,
  },
  {
    brand: 'Visa',
    last4: '4442',
    name: 'Alan Abundis Canales',
    exp_month: 10,
    exp_year: 22,
  },
  {
    brand: 'Visa',
    last4: '4442',
    name: 'Alan Abundis Canales',
    exp_month: 10,
    exp_year: 22,
  },
];

class Cards extends React.Component {
  state = {
    data: [],
    loading: false,
    selected: [],
    clearSelectedRows: false,
    newCard: {
      number: '',
      numberError: '',
      name: '',
      nameError: '',
      expiration: '',
      expirationError: '',
      cvc: '',
      cvcError: '',
      isSaving: false,
    },
  }

  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.id) {
      this.setState({ loading: true });
      this.getCardsData();
    } else {
      this.props.closeElement();
    }
  }

  deleteCards() {
    this.state.selected.forEach((id, index) => {
      axios
        .delete(`/api/cards/${id}/marketplace/${this.props.id}`)
        .then((response) => {
          this.getCardsData();
        })
        .catch((err) => {
        });
    });
    this.setState({ selected: [] });
  }

  clearSelectedRows = () => {
    this.setState({ selected: [], clearSelectedRows: !this.state.clearSelectedRows });
  };

  getCardsData() {
    getCards(this.props.id).then((response) => {
      this.setState({ loading: false });
      if (response.type === 'Success') {
        this.setState({ data: response.response.data });
      } else {
        this.props.notify('Error al obtener la información', 'error');
      }
    });
  }

  handleChange = (value, name) => {
    switch (name) {
    case 'name':
      this.setState({
        newCard: {
          ...this.state.newCard,
          nameError: value === '' ? 'Este campo es requerido' : '',
        },
      });
      break;
    case 'number':
      if (value.length > 16) return;
      const number = valid.number(value);
      const numberError = !number.isPotentiallyValid
        ? value === ''
          ? 'Este campo es requerido'
          : 'Número de tarjeta inválido'
        : number.card && number.card.isAmex
          ? 'Por el momento no soportamos Amex, intente otra tarjeta'
          : '';
      this.setState({
        newCard: {
          ...this.state.newCard,
          numberError,
        },
      });
      break;
    case 'expiration':
      const expiration = valid.expirationDate(value);
      let val = value.replace('/', '');
      if (!isNaN(val) && val.length <= 4) {
        if (val.length >= 2) {
          val = val.replace(/(\d{2})/, '$1/');
          if (this.state.expiration === val) {
            val = val.replace('/', '');
          }
        }
        value = val;
      } else if (val.length > 4) {
        val = `${val.substring(0, 2)}/${val.substring(2, 4)}`;
        value = val;
      }
      const expirationError = !expiration.isValid || value.length !== 5 || value.indexOf('/') !== 2
        ? value === ''
          ? 'Este campo es requerido'
          : 'Fecha de vencimiento inválida'
        : '';
      this.setState({
        newCard: {
          ...this.state.newCard,
          expirationError,
        },
      });
      break;
    case 'cvc':
      const cvc = valid.cvv(value);
      const cvcError = !cvc.isValid
        ? value === ''
          ? 'Este campo es requerido'
          : 'CVC inválido'
        : '';
      this.setState({
        newCard: {
          ...this.state.newCard,
          cvcError,
        },
      });
      break;
    default:
      break;
    }
    this.setState({
      newCard: {
        ...this.state.newCard,
        [name]: value,
      },
    });
  };

  resetForms = () => {
    this.setState({
      newCard: {
        number: '',
        numberError: '',
        expiration: '',
        expirationError: '',
        name: '',
        nameError: '',
        cvc: '',
        cvcError: '',
        isLoading: false,
      },
    });
  };

  async saveCard(type) {
    const errors = {
      required: 'Este campo es requerido',
    };
    const nameError = this.state.newCard.name === '' ? errors.required : '';
    const number = valid.number(this.state.newCard.number);
    const numberError = !number.isValid
      ? this.state.newCard.number === ''
        ? errors.required
        : 'Número de tarjeta inválido'
      : number.card && number.card.isAmex
        ? 'Por el momento no soportamos Amex, intente otra tarjeta'
        : '';
    const expiration = valid.expirationDate(this.state.newCard.expiration);
    const expirationError = !expiration.isValid
      || this.state.newCard.expiration.length !== 5
      || this.state.newCard.expiration.indexOf('/') !== 2
      ? this.state.newCard.expiration === ''
        ? errors.required
        : 'Fecha de vencimiento inválida'
      : '';
    const cvc = valid.cvv(this.state.newCard.cvc);
    const cvcError = !cvc.isValid
      ? this.state.newCard.cvc === ''
        ? errors.required
        : 'CVC inválido'
      : '';
    if (nameError || numberError || cvcError || expirationError) {
      return this.setState({
        newCard: {
          ...this.state.newCard,
          nameError,
          numberError,
          cvcError,
          expirationError,
        },
      });
    }

    this.setState({
      newCard: {
        ...this.state.newCard,
        isSaving: true,
      },
    });
    const expirationDate = this.state.newCard.expiration.split('/');
    const card = {
      object: 'card',
      exp_month: expirationDate[0],
      exp_year: expirationDate[1],
      number: this.state.newCard.number,
      name: this.state.newCard.name,
      cvc: this.state.newCard.cvc,
    };
    Scriptly.loadJavascript('https://js.stripe.com/v2/')
      .then(() => {
        window.Stripe.setPublishableKey(STRIPE_PUBLIC);
        window.Stripe.card.createToken(card, (status, response) => {
          if (response.error) {
            const errorMessages = {
              incorrect_number: 'El número de la tarjeta es incorrecto',
              invalid_number: 'El número de la tarjeta es inválido',
              invalid_expiry_month: 'El mes de expiración de la tarjeta es inválido',
              invalid_expiry_year: 'El año de expiración de la tarjeta es inválido',
              invalid_cvc: 'El código de seguridad es inválido',
              expired_card: 'La tarjeta ha expirado',
              incorrect_cvc: 'El código de seguridad es incorrecto',
              incorrect_zip: 'El código postal de la tarjeta es inválido',
              card_declined: 'La tarjeta fue declinada',
              processing_error: 'Ocurrió un error procesando su tarjeta',
              rate_limit: 'Espere un momento y vuelva intentarlo de nuevo',
              generic_decline: 'La tarjeta fue declinada, contacta a tu banco',
              insufficient_funds: 'Tarjeta con fondos insuficientes, intenta con otra tarjeta',
              fraudulent: 'La transacción fue declinada por tu banco',
            };
            this.props.notify(errorMessages[response.error.code], 'error');
            this.setState({
              newCard: {
                ...this.state.newCard,
                isSaving: false,
              },
            });
          }

          addCard(this.props.id, response.id).then((res) => {
            if (res) {
              this.resetForms();
              this.getCardsData();
              this.props.toggleSideBar(false);
              this.setState({
                newCard: {
                  ...this.state.newCard,
                  isSaving: false,
                },
              });
              if (res.type !== 'Success') {
                this.props.notify('Error al crear tarjeta', 'error');
              } else {
                this.props.notify('Tarjeta creada existosamente', 'success');
              }
            }
          });
        });
      })
      .catch((err) => {
        this.setState({
          newCard: {
            ...this.state.newCard,
            isSaving: false,
          },
        });
        if (err.response.data.error.type === 'external_provider_error') {
          const errorMessages = {
            incorrect_number: 'El número de la tarjeta es incorrecto',
            invalid_number: 'El número de la tarjeta es inválido',
            invalid_expiry_month: 'El mes de expiración de la tarjeta es inválido',
            invalid_expiry_year: 'El año de expiración de la tarjeta es inválido',
            invalid_cvc: 'El código de seguridad es inválido',
            expired_card: 'La tarjeta ha expirado',
            incorrect_cvc: 'El código de seguridad es incorrecto',
            incorrect_zip: 'El código postal de la tarjeta es inválido',
            card_declined: 'La tarjeta fue declinada',
            processing_error: 'Ocurrió un error procesando su tarjeta',
            rate_limit: 'Espere un momento y vuelva intentarlo de nuevo',
            generic_decline: 'La tarjeta fue declinada, contacta a tu banco',
            insufficient_funds: 'Tarjeta con fondos insuficientes, intenta con otra tarjeta',
            fraudulent: 'La transacción fue declinada por tu banco',
          };
          this.props.notify(errorMessages[err.response.data.error.subtype], 'error');
        }
        this.props.notify(err.response.data.error, 'error');
      });
  }

  _renderSidebarContent = () => {
    const mainProps = {
      generate: name => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value: this.state.newCard[name],
        error: this.state.newCard[`${name}Error`],
      }),
    };
    return (
      <ContentAdapter>
        <Row>
          <Column xs={7} fluid>
            <Input
              type="text"
              {...mainProps.generate('number')}
              required
              placeholder="1234 4567 7891 1234"
              label="Número de tarjeta"
            />
          </Column>
          <Column xs={5} fluid style={{ paddingLeft: '16px' }}>
            <Input
              type="text"
              {...mainProps.generate('expiration')}
              required
              placeholder="09/20"
              label="Fecha de expiración"
            />
          </Column>
          <Column xs={7} fluid>
            <Input
              type="text"
              {...mainProps.generate('name')}
              required
              placeholder="Raul Pérez Pérez"
              label="Nombre del titular"
              help="Tal como aparece en la tarjeta"
            />
          </Column>
          <Column xs={5} fluid style={{ paddingLeft: '16px' }}>
            <Input
              type="password"
              {...mainProps.generate('cvc')}
              required
              placeholder="123"
              label="CVC"
              help="Son los 3 dígitos al reverso"
            />
          </Column>
        </Row>
        <BottomContainer>
          <Row divisions={20}>
            <Column style={{ textAlign: 'right' }} fluid>
              <Button
                className=""
                loading={this.state.newCard.isSaving}
                onClick={() => this.saveCard('create')}
              >
                Guardar
              </Button>
            </Column>
            <Column xs={1} xsShift={1} style={{ textAlign: 'right' }} fluid />
          </Row>
        </BottomContainer>
      </ContentAdapter>
    );
  };

  render() {
    const options = [
      {
        function: () => this.props.toggleSideBar(true, 'Nueva tarjeta'),
        label: 'Añadir Tarjeta',
        image: edit_icon,
      },
    ];
    return (
      <div>
        <WrapperSideBar icon={card_placeholder}>{this._renderSidebarContent()}</WrapperSideBar>
        <div className="header-data-visualization">
          <img src={cardIcon} alt="" className="selected-icon" />
          <p className="title-header-data-visualization">Tarjetas</p>
          <ThreeDotsDropdown
            linksToRender={options}
          />
          <img onClick={() => this.props.closeElement()} src={close_icon} alt="" className="close-icon" />
        </div>
        {this.state.loading
          ? (
            <div className="container-loading">
              <Skeleton height={30} count={6} />
            </div>
          )
          : (
            <NewTable
              // hideForSmall={true}
              selectableRows
              shipping={false}
              columns={columns}
              data={this.state.data}
              onRowClicked={this.viewPackageDetails}
              onTableUpdate={(table) => {
                this.setState({ selected: table.selectedRows.map(cards => cards.object_id) });
              }}
              noDataComponent={<div />}
              subHeader={
                this.state.selected.length === 0
                  ? ''
                  : (
                    <Button
                      className="action-btn btn-subheader-space"
                      type="table"
                      onClick={() => {
                        this.clearSelectedRows();
                        this.deleteCards();
                      }}
                    >
                    Eliminar
                    </Button>
                  )
              }

            />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    toggleSideBar,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cards);
