import React from "react";
import axios from "axios";
import { Row, Column } from "hedron";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import JSZipUtils from "jszip-utils";
import printJs from "print-js";
import convertapiPackage from "convertapi";
import Loader from "react-loaders";
import Modal from "react-responsive-modal";
import Skeleton from "react-loading-skeleton";
import { DateRange } from "react-date-range";
import * as rdrLocales from "react-date-range/src/locale";
import Button from "../../components/Button";
import Text, { Title } from "../../components/Text";
import Input from "../../components/Input";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Package from "./Package";
import DetailedOrder from "./DetailedOrder";
import "./style.scss";
import QuotationList from "../../components/QuotationList";
import QuotationsFullList from "../../components/QuotationsFullList";
import { ToastContainer, toast } from "react-toastify";
import Filters from "./Filters";
import Logo from "../../components/static/Logo";

import WrapperSideBar from "../../components/WrapperSideBar";
import {
  ContentAdapter,
  BottomContainer,
} from "../../components/WrapperSideBar/styledObjects";
import ThreeDotsDropdown from "../../components/Dropdown/ThreeDotsDropdown/index";
import detail_eye_icon from "../../utils/icons/detail-eye.svg";
import NewBadge from "../../components/Badge/insuranceBadge";
import Shipping_label from "../../components/ShipmentsList/details/icons/Shipping label.svg";

import GuideModal from "./GuideModal";
import {
  previousStep,
  nextStep,
  setValue,
  clearSidebar,
  createAddress,
  createPackage,
  selectPackage,
  getUserAllPackages,
  clearPackage,
  getCountries,
} from "../../modules/sidebarOrder/actions";
import {
  createShipment,
  editShipment,
  getAllUserQuotes,
  getAllUserQuotesUnauthorized,
} from "../../modules/shipments/actions";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../modules/CollapsibleBar/actions";
import "./style.css";

import EditIcon from "../../utils/icons/edit.svg";
import { showLoader, hideLoader } from "../../modules/loader";
import { showMessage, hideMessage } from "../../modules/message";
import { showError } from "../../modules/error";
import Message from "../../components/Message";
import { ORDER_DETAILS } from "../../modules/CollapsibleBar/sideBarTypes";
import {
  getVolumetricWeight,
  searchPermission,
} from "../../utils/global_functions";
import { boundMethod } from "autobind-decorator";
import CancelModal from '../../components/ShipmentsList/common/CancelModal';

const ORDER_EDIT = "ACCOUNT_EDIT";
const NEW_ORDER = "NEW_ORDER";
const convertapi = convertapiPackage("Dbuto4HQXRnkLnwV");
const today = new Date();
const tomorrow = new Date();

class Quotations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailOpen: false,
      order: {
        events: [],
        details: {
          weight: "",
          height: "",
          length: "",
          width: "",
          weightError: "",
          heightError: "",
          lengthError: "",
          widthError: "",
          declared_value: "",
          insuredAmount: "",
        },
      },
      selectedId: null,
      searchId: null,
      searchIdError: "",
      dataQuotation: [],
      loadingOrder: true,
      showModal: false,
      filterPack: "",
      manualNew: false,
      manualEdit: false,
      loadingGuide: true,
      selectedIdGuide: "",
      guides: "",
      selectPackage: null,
      selectPackageError: "",
      processed: false,
      errorUser: true,
      fullList: false,
      filter: {
        type: null,
        value: null,
      },
      carriers: [],
      report: false,
      email: "",
      selectionRange: {
        selection: {
          startDate: today,
          endDate: tomorrow,
          key: "selection",
        },
      },
    };

    this.shipmentDetails = this.shipmentDetails.bind(this);
    this.searchPress = this.searchPress.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      hideMessage,
      toggleSideBar,
      getUserAllPackages,
      clearPackage,
      location,
      user,
      getCountries,
      getAllUserQuotesUnauthorized,
      getAllUserQuotes,
    } = this.props;
    hideMessage();
    toggleSideBar(false);
    clearPackage();
    this.getCarriers();
    const token = location.search.slice(1).split("&")[0].split("=")[1];
    if (!user || !user.api_token) {
      if (token) {
        const tokenaxios = `Bearer ${token}`;
        axios.defaults.headers.common.Authorization = tokenaxios;
        axios
          .get("/api/profile")
          .then(({ data }) => {
            this.setState({
              tempLogin: true,
              errorUser: false,
              email: data.email,
              features: data.plan.features,
            });
            getAllUserQuotesUnauthorized(location.pathname.split("/")[2]);
          })
          .catch(() => {
            toast.error("El token de usuario no existe", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            this.setState({ errorUser: true });
          });
      } else {
        /* getUserAllPackages();
        if (location.pathname.split('/').length === 3) {
          this.setState({
            searchId: location.pathname.split('/')[2],
            selectedId: location.pathname.split('/')[2],
            searchIdError: '',
          }, () => {
            this.searchPress();
          });
        } */
      }
    } else {
      this.setState({ tempLogin: false, errorUser: false });
      getCountries();
      getAllUserQuotes();
      getUserAllPackages();
      if (location.pathname.split("/").length === 3) {
        this.setState(
          {
            searchId: location.pathname.split("/")[2],
            selectedId: location.pathname.split("/")[2],
            searchIdError: "",
          },
          () => {
            this.searchPress();
          }
        );
      }
    }
  }

  validateSbFieldsNew() {
    const { order, manualNew, selectPackage } = this.state;
    let flag = true;
    let weightError = "";
    let lengthError = "";
    let heightError = "";
    let widthError = "";
    let selectPackageError = "";

    if (order.details.weight === "") {
      flag = false;
      weightError = "Campo obligatorio";
    }

    if (manualNew) {
      if (order.details.length === "") {
        flag = false;
        lengthError = "Campo obligatorio";
      }

      if (order.details.height === "") {
        flag = false;
        heightError = "Campo obligatorio";
      }

      if (order.details.width === "") {
        flag = false;
        widthError = "Campo obligatorio";
      }
    } else if (!selectPackage) {
      flag = false;
      selectPackageError = "Campo obligatorio";
    }
    this.setState({
      order: {
        details: {
          ...order.details,
          lengthError,
          weightError,
          widthError,
          heightError,
        },
      },
      selectPackageError,
    });
    return flag;
  }

  validateSbFieldsEdit() {
    const { order, manualEdit, selectPackage } = this.state;
    let flag = true;
    let weightError = "";
    let lengthError = "";
    let heightError = "";
    let widthError = "";
    let selectPackageError = "";

    if (order.details.weight === "") {
      flag = false;
      weightError = "Campo obligatorio";
    }

    if (manualEdit) {
      if (order.details.length === "") {
        flag = false;
        lengthError = "Campo obligatorio";
      }

      if (order.details.height === "") {
        flag = false;
        heightError = "Campo obligatorio";
      }

      if (order.details.width === "") {
        flag = false;
        widthError = "Campo obligatorio";
      }
    } else if (!selectPackage) {
      flag = false;
      selectPackageError = "Campo obligatorio";
    }
    this.setState({
      order: {
        details: {
          ...order.details,
          lengthError,
          weightError,
          widthError,
          heightError,
        },
      },
      selectPackageError,
    });
    return flag;
  }

  shipmentDetails(shipment, context) {
    this.setState({ loadingOrder: true });
    context.openSideBar(
      true,
      `Consultando órden #${shipment.id ? shipment.id : shipment.object_id}`,
      ORDER_DETAILS
    );
    axios
      .get(`/api/shipments/${shipment.id ? shipment.id : shipment.object_id}`)
      .then((response) => {
        context.getOrderMetadata(response.data);
      })
      .catch((error) => {
        context.props.showError(
          "Error al obtener información de la cotización."
        );
        context.openSideBar(false);
      });
  }

  getCarriers() {
    const token = location.search.slice(1).split("&")[0].split("=")[1];
    if (!token) return;
    const tokenaxios = `Bearer ${token}`;
    axios.defaults.headers.common.Authorization = tokenaxios;
    axios
      .get("/api/providers")
      .then((response) => {
        if (response.data && response.data.length !== 0) {
          this.setState({ carriers: response.data });
        }
      })
      .catch((error) => {});
  }

  getQuote(id, guide) {
    this.setState({
      loadingGuide: true,
      showModal: true,
    });
    this.props.toggleSideBar(false);
    const context = this;
    if (guide) {
      axios
        .get(`/api/quotes/${id}`)
        .then((r) => {
          switch (r.status) {
            case 200:
              var countLabels = 0;
              var error = false;
              r.data.purchase.shipments.forEach((element) => {
                if (element.status === "PURCHASE") {
                  countLabels++;
                } else if (element.status === "MANUAL_LABEL") {
                  error = true;
                }
              });
              if (!error) {
                if (countLabels === r.data.purchase.shipments.length) {
                  context.getQuote(id, true);
                } else {
                  context.setState({
                    guides: r.data.purchase.shipments,
                    selectedIdGuide: id,
                  });
                  context.downloadGuides(context);
                }
              } else {
                context.setState({
                  loadingGuide: false,
                });
                context.onCloseModal();
                context.props.showError("Error al generar guia");
              }
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          context.props.hideLoader();
          context.props.showError(err);
          context.setState({
            loadingGuide: false,
          });
        });
    } else {
      setTimeout(() => {
        axios
          .get(`/api/quotes/${id}`)
          .then((r) => {
            switch (r.status) {
              case 200:
                var countLabels = 0;
                var error = false;
                r.data.purchase.shipments.forEach((element) => {
                  if (element.status === "PURCHASE") {
                    countLabels++;
                  } else if (element.status === "MANUAL_LABEL") {
                    error = true;
                  }
                });
                if (!error) {
                  if (countLabels === r.data.purchase.shipments.length) {
                    context.getQuote(id, true);
                  } else {
                    context.setState({
                      guides: r.data.purchase.shipments,
                      selectedIdGuide: id,
                    });
                    context.downloadGuides(context);
                  }
                } else {
                  context.setState({
                    loadingGuide: false,
                  });
                  context.onCloseModal();
                  context.props.showError("Error al generar guia");
                }
                break;
              default:
                break;
            }
          })
          .catch((err) => {
            context.props.hideLoader();
            context.props.showError("Error al obtener la(s) ordenes");
            context.setState({
              loadingGuide: false,
            });
          });
      }, 5000);
    }
  }

  openSideBar = (stateSidebar = false, sidebarTitle = "", sidebarType = "") => {
    this.props.toggleSideBar(stateSidebar, sidebarTitle, sidebarType);
  };

  @boundMethod
  singleViewQuote(id) {
    this.setState({ fullList: false, searchId: id }, () => {
      this.searchPress();
    });
  }

  _getPackageClass = (id) =>
    this.props.sidebarOrder.selectedPackage != null
      ? this.props.sidebarOrder.selectedPackage.object_id === id
        ? "more-address-selected"
        : ""
      : "";

  generatePackagesOptions = () =>
    this.props.sidebarOrder.packages.results !== undefined
      ? Object.values(this.getFilteredPackages()).map((pack) => (
          <Row
            key={pack.object_id}
            className={`card-dropdown-option ${this._getPackageClass(
              pack.object_id
            )}`}
          >
            <i className="ion-checkmark-round" />
            <Column fluid>
              <Text className="title-selected">
                <b>{pack.alias}</b>
              </Text>
            </Column>
            <Column fluid>
              <Text>{`${pack.height} x ${pack.length} x ${pack.width} cm`}</Text>
            </Column>
          </Row>
        ))
      : [];

  saveNewPackage(id) {
    const {
      showMessage,
      hideLoader,
      getAllUserQuotes,
      toggleSideBar,
      showError,
    } = this.props;
    const { manualNew, order, selectPackage } = this.state;
    if (this.validateSbFieldsNew()) {
      this.setState({
        btLoading: true,
      });
      let data = null;
      if (manualNew) {
        data = {
          weight: order.details.weight,
          height: order.details.height,
          width: order.details.width,
          length: order.details.length,
        };
      } else {
        data = {
          weight: order.details.weight,
          package_id: selectPackage.object_id,
        };
      }

      axios
        .post(`/api/quotes/${id}/shipments`, data)
        .then((r) => {
          switch (r.status) {
            case 201:
              showMessage("success", "Empaque agregado exitosamente");
              hideLoader();
              getAllUserQuotes();
              toggleSideBar(false);
              this.onCloseSb();
              this.setState({
                btLoading: false,
              });
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          hideLoader();
          showError(err);
          this.setState({
            btLoading: false,
          });
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedId } = this.state;
    if (nextProps.quotes) {
      if (nextProps.quotes.length > 0) {
        if (this.state.tempLogin) {
          if (location.pathname.split("/").length === 3) {
            if (location.pathname.split("/")[2] === "") {
              this.setState({ report: true });
            } else {
              this.setState(
                {
                  searchId: location.pathname.split("/")[2],
                  selectedId: location.pathname.split("/")[2],
                  searchIdError: "",
                },
                () => {
                  if (
                    !this.state.dataQuotation ||
                    this.state.dataQuotation.length === 0
                  ) {
                    if (!this.state.processed) {
                      this.searchPress();
                    }
                  }
                }
              );
            }
          }
        }
      }
      if (selectedId) {
        for (const element of nextProps.quotes) {
          if (selectedId === element[0]) {
            this.setState({ dataQuotation: element[1] });
          }
        }
      }
    }
  }

  editPackage(id) {
    const {
      showMessage,
      hideLoader,
      getAllUserQuotes,
      toggleSideBar,
      showError,
    } = this.props;
    const { manualEdit, order, selectPackage } = this.state;
    if (this.validateSbFieldsEdit()) {
      this.setState({
        btLoading: true,
      });
      let data = null;
      if (manualEdit) {
        data = {
          shipments: [
            {
              id: order.details.object_id,
              weight: parseFloat(order.details.weight),
              height: parseFloat(order.details.height),
              width: parseFloat(order.details.width),
              length: parseFloat(order.details.length),
            },
          ],
        };
      } else {
        data = {
          shipments: [
            {
              id: order.details.object_id,
              weight: parseFloat(order.details.weight),
              package_id: selectPackage.object_id,
            },
          ],
        };
      }
      axios
        .put(`/api/quotes/${id}`, data)
        .then((r) => {
          switch (r.status) {
            case 200:
              showMessage("success", "Empaque editado exitosamente");
              hideLoader();
              getAllUserQuotes();
              toggleSideBar(false);
              this.onCloseSb();
              this.setState({
                btLoading: false,
              });
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          hideLoader();
          showError(err);
          this.setState({
            btLoading: false,
          });
        });
    }
  }

  toggleEditOrder(context) {
    context.setState({ detailOpen: false });
    context.openSideBar(
      true,
      `Orden #${context.state.order.details.object_id}`,
      ORDER_EDIT
    );
  }

  toggleAddOrder(context) {
    this.setState({ loadingOrder: false });
    context.openSideBar(true, "Agregar Paquete", NEW_ORDER);
  }

  handleSearchChange = (value) => {
    if (value === "") {
      this.setState({ searchId: value, searchIdError: "" });
    } else {
      this.setState({ searchId: value });
    }
  };

  handleHeightChange = (value) => {
    this.setState((prevState) => ({
      order: {
        details: {
          ...prevState.order.details,
          height: value,
        },
      },
    }));
  };

  handleLenghthange = (value) => {
    this.setState((prevState) => ({
      order: {
        details: {
          ...prevState.order.details,
          length: value,
        },
      },
    }));
  };

  handleWidthChange = (value) => {
    this.setState((prevState) => ({
      order: {
        details: {
          ...prevState.order.details,
          width: value,
        },
      },
    }));
  };

  handleWeightChange = (value) => {
    this.setState((prevState) => ({
      order: {
        details: {
          ...prevState.order.details,
          weight: value,
        },
      },
    }));
  };

  onCloseSb = () => {
    const { clearPackage } = this.props;
    this.setState({
      order: {
        events: [],
        details: {
          weight: "",
          height: "",
          length: "",
          width: "",
          weightError: "",
          heightError: "",
          lengthError: "",
          widthError: "",
          declared_value: "",
          insuredAmount: "",
        },
      },
      loadingOrder: true,
      detailOpen: false,
      manualNew: false,
      manualEdit: true,
      loadingGuide: true,
      selectedIdGuide: "",
      showModal: false,
      guides: "",
      selectPackage: null,
    });
    clearPackage();
  };

  searchPress() {
    const { searchId } = this.state;
    const context = this;

    this.setState({ selectedId: null, dataQuotation: [] });

    if (searchId && !isNaN(searchId)) {
      let dataQuotation = [];
      let id = null;

      axios
      .get(`/api/quotes/purchases/${searchId}`)
      .then((response) => {
        dataQuotation = response.data
        this.setState({ dataQuotation });
        if (dataQuotation.length === 0) {
          this.setState({
            selectedId: null,
            searchIdError: "Verifica el id ingresado",
          });
        } else {
          this.setState({
            selectedId: searchId,
            searchIdError: "",
          });
  
          this.shipmentDetails(dataQuotation[0], this);
        }
      })
      .catch((error) => {
        context.props.hideLoader();
        context.props.showError("Error al obtener la(s) ordenes");
        context.setState({
          loadingGuide: false,
        });
      });
    } else {
      this.setState({
        selectedId: null,
        searchIdError: "Verifica el id ingresado",
      });
    }
  }

  formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join("-");
  }

  generateReport() {
    const start = this.formatDate(
      this.state.selectionRange.selection.startDate
    );
    const end = this.formatDate(this.state.selectionRange.selection.endDate);
    axios
      .post("/api/report_by_date", {
        fecha_inicial: start,
        fecha_final: end,
      })
      .then((r) => {
        switch (r.status) {
          case 200:
            this.setState({ openDate: !this.state.openDate });
            r.data.files.forEach((element) => {
              const a = document.createElement("a");
              a.href = element.file;
              a.download = element.name;
              document.body.appendChild(a);
              a.click();
              a.remove();
            });
            break;
          default:
            hideLoader();
            showError("Error al procesar cotizacion");
            break;
        }
      })
      .catch((err) => {
        hideLoader();
        showError("Error al procesar cotizacion");
      });
  }

  handleKeyPress = (event) => {
    if (!this.state.tempLogin) {
      const { searchId } = this.state;
      const { quotes } = this.props;
      if (event.key === "Enter") {
        if (searchId && !isNaN(searchId)) {
          let dataQuotation = [];
          let id = null;
          quotes.forEach((element) => {
            if (searchId === element[0]) {
              dataQuotation = element[1];
            } else {
              element[1].forEach((item) => {
                if (searchId === item.id.toString()) {
                  id = element[0];
                  dataQuotation = element[1];
                  return true;
                }
              });
            }
          });
          this.setState({ dataQuotation });
          if (dataQuotation.length === 0) {
            this.setState({
              selectedId: null,
              searchIdError: "Verifica el id ingresado",
            });
          } else {
            if (id) {
              this.setState({
                selectedId: id,
                searchIdError: "",
              });
            } else {
              this.setState({
                selectedId: searchId,
                searchIdError: "",
              });
            }

            this.shipmentDetails(dataQuotation[0], this);
          }
        } else {
          this.setState({
            selectedId: null,
            searchIdError: "Verifica el id ingresado",
          });
        }
      }
    }
  };

  getOrderMetadata = (order) => {
    const relatedOrders = {
      nextOrder: 0,
      prevOrder: 0,
    };
    this.setState({
      order: {
        details: order.shipment,
        events: [],
        nextOrder: relatedOrders.nextOrder,
        prevOrder: relatedOrders.prevOrder,
      },
      detailOpen: true,
      loadingOrder: false,
    });
    const badge = this.badgeLabel(order.shipment.status);
    let urlLabel = "";
    let urlButton = "";

    if (order.shipment.label === null) {
      urlButton = "";
    } else {
      urlLabel = order.shipment.label.label_url;
      urlButton = (
        <a href={urlLabel} target="_blank">
          Link <img src={Shipping_label} alt="Link externo de la guia" />
        </a>
      );
    }

    this.props.changeSidebarTitle(
      <div style={{ display: "flex", alignItems: "center" }}>
        Orden #{order.shipment.object_id} - {urlButton}{" "}
        <NewBadge type={badge.type}>{badge.label}</NewBadge>
      </div>
    );
  };

  handleChange(value, key) {
    this.setState({
      order: {
        details: {
          [key]: value,
        },
      },
    });
  }

  generateProps = (name, params) => ({
    onChange: (value) => {
      const val =
        params.type === "radio" || params.type === "checkbox"
          ? value.target.value
          : value;
      if (name.split("_").length === 3) {
        this.handleChange(val, `${name.split("_")[1]}_${name.split("_")[2]}`);
      } else {
        this.handleChange(val, name.split("_")[1]);
      }
    },
    onBlur: (value) => {
      const val =
        params.type === "radio" || params.type === "checkbox"
          ? value.target.value
          : value;
      this.handleBlur(val, name, params.required);
    },
    name: name || "",
    value: this.state.order.details
      ? name.split("_").length === 3
        ? this.state.order.details[
            `${name.split("_")[1]}_${name.split("_")[2]}`
          ]
        : this.state.order.details[name.split("_")[1]]
      : "",
    type: params.type ? params.type : "text",
    required: params.required ? params.required : false,
    placeholder: params.placeholder ? params.placeholder : "",
    label: params.label ? params.label : "",
    help: params.help ? params.help : "",
    maxLength: params.maxLength ? params.maxLength : null,
    disabled: params.disabled ? params.disabled : null,
    cornerNote: params.cornerNote ? params.cornerNote : null,
    icon: params.icon ? params.icon : null,
    prefix: params.prefix ? params.prefix : null,
    suffix: params.suffix ? params.suffix : null,
    className: name ? `cl-${name}` : "",
    parentClassName: params.parentClassName ? params.parentClassName : null,
  });

  _generatePackageObject = () => ({
    description: {
      ...this.generateProps("package_description", {
        required: true,
        placeholder: "Playeras serigraficas",
        label: "Descripción del contenido",
        maxLength: 30,
      }),
    },
    weight: {
      ...this.generateProps("package_weight", {
        required: true,
        placeholder: "65.0",
        label: "Peso",
        type: "number",
        suffix: "kg",
      }),
    },
    height: {
      ...this.generateProps("package_height", {
        required: true,
        placeholder: "30",
        label: "Alto",
        type: "number",
        suffix: "x",
      }),
    },
    width: {
      ...this.generateProps("package_width", {
        required: true,
        placeholder: "30",
        label: "Ancho",
        type: "number",
        suffix: "x",
      }),
    },
    length: {
      ...this.generateProps("package_length", {
        required: true,
        placeholder: "30",
        label: "Largo",
        type: "number",
        suffix: "cm",
      }),
    },
    insuredAmount: {
      ...this.generateProps("package_declared_value", {
        required: true,
        label: "Valor del paquete",
        type: "number",
        prefix: "$",
        parentClassName: "insurenceAmount-input",
        placeholder: "1500.00",
      }),
    },
  });

  renderSideBarDetails() {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;
    const { features } = this.state;
    const permissions = [];
    if (features) {
      features.map((element) => permissions.push(element));
    }
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    const { dataQuotation, selectedId, btnLoading } = this.state;
    return (
      <div>
        {!this.state.loadingOrder ? (
          <ContentAdapter>
            <CancelModal
              shipmentId={this.state.order.details.object_id}
              showModal={this.state.showCancelModal} 
              onCloseModal={this.onCancelOrder}>
            </CancelModal>
            <DetailedOrder
              order={this.state.order}
              // goToPayment={this.goToPaymentUnique}
              events={[]}
            />
            <BottomContainer>
              <Row divisions={20}>
                {searchPermission("cotizaciones", "editar", permissions) && (
                  <Column style={{ textAlign: "right" }} fluid>
                    {selectedId ? (
                      dataQuotation[0] &&
                      dataQuotation[0].status &&
                      (this.state.order.details.status === "LABEL_CREATED" ||
                        this.state.order.details.status === "TRANSITO" ||
                        this.state.order.details.status === "INCIDENCIA" ||
                        this.state.order.details.status === "GUIA_ENVIADA" ||
                        this.state.order.details.status === "ENTREGADO") ? (
                        <footer>
                          <Button
                            loading={btnLoading}
                            className="no-margin"
                            onClick={() => this.getQuote(selectedId, true)}
                          >
                            Ver Guias
                          </Button>

                          <Button
                            style={{backgroundColor: 'red', marginLeft: '5px'}}
                            onClick={() => this.setState({'showCancelModal': true})}
                            >
                            Cancelar Orden
                          </Button>

                        </footer>

                      ) : this.state.order.details.status === 'CANCELADO'
                      ? (<div></div>)
                      : dataQuotation[0].status === "MANUAL_LABEL" ? (
                        <Button
                          loading={btnLoading}
                          className="no-margin"
                          onClick={() => this.reprocessQuote(selectedId)}
                        >
                          Reprocesar Cotización
                        </Button>
                      ) : (
                        <Button
                          loading={btnLoading}
                          className="no-margin"
                          onClick={() => this.processQuote(selectedId)}
                        >
                          Procesar Cotización
                        </Button>
                      )
                    ) : (
                      <div />
                    )}
                  </Column>
                )}
                <Column
                  xs={1}
                  xsShift={1}
                  style={{ textAlign: "right" }}
                  fluid
                />
              </Row>
            </BottomContainer>
          </ContentAdapter>
        ) : (
          <ContentAdapter>
            <div className="margin-top-title" />
            <Skeleton height={140} />
            <div className="margin-top-title" />
            <Skeleton height={140} />
            <div className="margin-top-title" />
            <Skeleton height={140} />
          </ContentAdapter>
        )}
      </div>
    );
  }

  @boundMethod
  onCancelOrder(){
    this.setState({showCancelModal: false}); 
    this.props.toggleSideBar(false);
  }

  getFilteredPackages = () =>
    this.state.filterPack.length > 0
      ? this.props.sidebarOrder.packages.results.filter((pack) =>
          pack.alias.toLowerCase().includes(this.state.filterPack.toLowerCase())
        )
      : this.props.sidebarOrder.packages.results;

  onChangePackageMore = (id) => {
    const selectedPackage = this.getFilteredPackages()[id];
    this.props.selectPackage(id, selectedPackage);
    this.setState({ selectPackage: selectedPackage });
    const vol = getVolumetricWeight(
      selectedPackage.length,
      selectedPackage.width,
      selectedPackage.height
    );
    this.props.setValue("package_volumetricWeight", vol);
  };

  manualPackage = () => {
    this.props.clearPackage();
  };

  changeManualEdit(context) {
    context.setState({ manualEdit: !context.state.manualEdit });
  }

  changeManualNew(context) {
    context.setState({ manualNew: !context.state.manualNew });
  }

  badgeLabel = (status) => {
    let label = "";
    let type = "";
    if (status) {
      switch (status) {
        case "QUOTE":
          label = "Cotizado";
          type = "correct";
          break;
        case "PURCHASE":
          label = "Cotizado";
          type = "correct";
          break;
        case "ENTREGADO":
          label = "Entregado";
          type = "correct";
          break;
        case "LABEL_CREATED":
          label = "Guía creada";
          type = "correct";
          break;
        case "INCIDENCIA":
          label = "Incidencia";
          type = "error";
          break;
        case "MANUAL_LABEL":
          label = "Manual";
          type = "warning";
          break;
        case "MONTHLY_PAYMENT_PENDING":
          label = "Pago mensual pendiente";
          type = "error";
          break;
        case "GUIA_ENVIADA":
          label = "Guía enviada";
          type = "correct";
          break;
        case "TRANSITO":
          label = "En camino";
          type = "correct";
          break;
        case "CANCELLED":
        case "CANCELADO":
          label = "Cancelado";
          type = "error";
          break;
      }
    }
    return {
      type: type,
      label: label,
    };
  };

  renderSideBarEdit() {
    const {
      filterPack,
      order,
      manualEdit,
      selectPackageError,
      btLoading,
      selectedId,
      loadingOrder,
    } = this.state;
    const { sidebarOrder } = this.props;
    return (
      <div>
        {!loadingOrder ? (
          <ContentAdapter>
            <Package
              changeManual={this.changeManualEdit}
              context={this}
              packages={this.generatePackagesOptions()}
              selectedPack={sidebarOrder.selectedIdPackage}
              filter={filterPack}
              handleChange={this.onChangePackageMore}
              handleFilterChange={(val) => this.setState({ filterPack: val })}
              newPackage={this.manualPackage}
              pack={sidebarOrder.selectedPackage}
              manual={manualEdit}
              height={order.details.height}
              width={order.details.width}
              length={order.details.length}
              weight={order.details.weight}
              heightError={order.details.heightError}
              widthError={order.details.widthError}
              lengthError={order.details.lengthError}
              weightError={order.details.weightError}
              error={selectPackageError}
              onChangeHeight={this.handleHeightChange}
              onChangeWidth={this.handleWidthChange}
              onChangeLenght={this.handleLenghthange}
              onChangeWeight={this.handleWeightChange}
              description={order.details.description}
            >
              {" "}
            </Package>
            <BottomContainer>
              <Row divisions={20}>
                <Column style={{ textAlign: "right" }} fluid>
                  <Button
                    loading={btLoading}
                    onClick={() => this.editPackage(selectedId)}
                    className=""
                  >
                    Guardar
                  </Button>
                </Column>
                <Column
                  xs={1}
                  xsShift={1}
                  style={{ textAlign: "right" }}
                  fluid
                />
              </Row>
            </BottomContainer>
          </ContentAdapter>
        ) : (
          <ContentAdapter>
            <Skeleton height={80} />
          </ContentAdapter>
        )}
      </div>
    );
  }

  renderSideBarNewPackage() {
    const {
      filterPack,
      order,
      manualNew,
      selectPackageError,
      btLoading,
      selectedId,
      loadingOrder,
    } = this.state;
    const { sidebarOrder } = this.props;
    return (
      <div>
        {!loadingOrder ? (
          <ContentAdapter>
            <Package
              packages={this.generatePackagesOptions()}
              selectedPack={sidebarOrder.selectedIdPackage}
              filter={filterPack}
              changeManual={this.changeManualNew}
              context={this}
              handleChange={this.onChangePackageMore}
              handleFilterChange={(val) => this.setState({ filterPack: val })}
              newPackage={this.manualPackage}
              pack={sidebarOrder.selectedPackage}
              error={selectPackageError}
              manual={manualNew}
              height={order.details.height}
              width={order.details.width}
              length={order.details.length}
              weight={order.details.weight}
              heightError={order.details.heightError}
              widthError={order.details.widthError}
              lengthError={order.details.lengthError}
              weightError={order.details.weightError}
              onChangeHeight={this.handleHeightChange}
              onChangeWidth={this.handleWidthChange}
              onChangeLenght={this.handleLenghthange}
              onChangeWeight={this.handleWeightChange}
            >
              {" "}
            </Package>
            <BottomContainer>
              <Row divisions={20}>
                <Column style={{ textAlign: "right" }} fluid>
                  <Button
                    loading={btLoading}
                    onClick={() => this.saveNewPackage(selectedId)}
                    className=""
                  >
                    Guardar
                  </Button>
                </Column>
                <Column
                  xs={1}
                  xsShift={1}
                  style={{ textAlign: "right" }}
                  fluid
                />
              </Row>
            </BottomContainer>
          </ContentAdapter>
        ) : (
          <ContentAdapter>
            <Skeleton height={80} />
          </ContentAdapter>
        )}
      </div>
    );
  }

  renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
      case ORDER_DETAILS:
        return <div>{this.renderSideBarDetails()}</div>;
      case ORDER_EDIT:
        return <div>{this.renderSideBarEdit()}</div>;
      case NEW_ORDER:
        return <div>{this.renderSideBarNewPackage()}</div>;
      default:
        return <div />;
    }
  };

  renderSidebarTitleExtraContent = () => {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    const { detailOpen, selectedId, dataQuotation } = this.state;
    const orderOptions = [];
    if (searchPermission("cotizaciones", "editar", permissions)) {
      orderOptions.push({
        function: () => this.toggleEditOrder(this),
        label: "Editar Paquete",
        image: EditIcon,
      });
    }

    if (searchPermission("cotizaciones", "editar", permissions)) {
      if (detailOpen) {
        if (
          selectedId &&
          dataQuotation[0] &&
          dataQuotation[0].status &&
          dataQuotation[0].status !== "LABEL_CREATED"
        ) {
          const badge = this.badgeLabel(this.state.order.details.status);
          return (
            <div
              className="edit-btn"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button type="link" onClick={() => this.toggleEditOrder(this)}>
                Editar Paquete
              </Button>
            </div>
          );
        }
        return <div />;
      }
      return <div />;
    }
    return <div />;
  };

  processQuote(id) {
    const {
      hideLoader,
      showError,
      getAllUserQuotes,
      showMessage,
      toggleSideBar,
      gotLogIn,
    } = this.props;
    this.setState({
      btLoading: true,
    });
    toggleSideBar(false);
    showLoader(null, null, false);
    axios
      .post(`/api/quotes/${id}/purchases`, {})
      .then((r) => {
        switch (r.status) {
          case 201:
            this.getQuote(id);
            this.setState({
              selectedId: null,
              dataQuotation: [],
              searchId: "",
              searchIdError: "",
              processed: true,
            });
            showMessage("success", "Cotizacón procesada exitosamente");
            hideLoader();
            getAllUserQuotes();
            this.setState({
              btLoading: false,
            });
            break;
          default:
            hideLoader();
            showError("Error al procesar cotizacion");
            break;
        }
      })
      .catch((err) => {
        hideLoader();
        showError("Error al procesar cotizacion");
      });
  }

  reprocessQuote(id) {
    const {
      hideLoader,
      showError,
      getAllUserQuotes,
      showMessage,
      toggleSideBar,
      gotLogIn,
    } = this.props;
    this.setState({
      btLoading: true,
    });
    toggleSideBar(false);
    showLoader(null, null, false);
    axios
      .post(`/api/quotes/${id}/reprocessQuote`, {})
      .then((r) => {
        switch (r.status) {
          case 201:
            this.getQuote(id);
            this.setState({
              selectedId: null,
              dataQuotation: [],
              searchId: "",
              searchIdError: "",
              processed: true,
            });
            showMessage("success", "Cotizacón procesada exitosamente");
            hideLoader();
            getAllUserQuotes();
            this.setState({
              btLoading: false,
            });
            break;
          default:
            hideLoader();
            showError("Error al procesar cotizacion");
            break;
        }
      })
      .catch((err) => {
        hideLoader();
        showError("Error al procesar cotizacion");
      });
  }

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  downloadGuides(context) {
    const { guides } = this.state;
    const links = [];
    guides.forEach((element) => {
      links.push(element.label.label_url);
    });
    JSZipUtils.getBinaryContent(links[0], (err, data) => {
      context.setState({
        loadingGuide: false,
      });
      try {
        printJS({
          printable: btoa(
            String.fromCharCode.apply(null, new Uint8Array(data))
          ),
          type: "pdf",
          base64: true,
        });
      } catch (error) {
        this.props.showError("Error al cargar la(s) guias");
      }
    });
    /* convertapi.convert('merge', { Files: links })
      .then((result) => {
        JSZipUtils.getBinaryContent(result.response.Files[0].Url, (err, data) => {
          context.setState({
            loadingGuide: false,
          });
          try {
            printJS({ printable: btoa(String.fromCharCode.apply(null, new Uint8Array(data))), type: 'pdf', base64: true });
          } catch (error) {
            this.props.showError(null, 'Error al cargar la(s) guias');
          }
        });
      }); */
  }

  @boundMethod
  filterData(filter) {
    this.setState({ filter });
  }

  toogleList() {
    this.setState({ fullList: !this.state.fullList, reportRender: false });
  }

  toogleListForce() {
    this.setState({ fullList: true, reportRender: false });
  }

  toogleSearch() {
    this.setState({ fullList: false, reportRender: false });
  }

  toggleReport() {
    this.setState({ reportRender: true });
  }

  downloadCSV() {}

  renderReport() {
    const options = [
      {
        function: () => this.toogleSearch(),
        label: "Modo Busqueda",
        image: detail_eye_icon,
      },
      {
        function: () => this.toogleListForce(),
        label: "Modo Listado",
        image: detail_eye_icon,
      },
    ];
    return (
      <div className={this.state.tempLogin && "white-bg"}>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Row divisions={18}>
              <Column xs={10} fluid>
                <br />
                <br />
                {this.state.tempLogin && <Logo />}
                <br />
                <br />
              </Column>
            </Row>
            <Row divisions={18}>
              <Column xs={10} fluid>
                <Title type="titleCard">Generar Reporte de Cotizaciones</Title>
              </Column>
              <Column xs={8} xsShift={16} fluid>
                {!this.state.tempLogin && (
                  <ThreeDotsDropdown linksToRender={options} />
                )}
              </Column>
            </Row>
            <Row divisions={18}>
              <Column xs={10} fluid>
                <Title type="titleCardSm">{this.state.email}</Title>
              </Column>
            </Row>
          </Column>
        </Row>
        <br />
        <Row divisions={18}>
          <Column xs={1} fluid />
          <Column xs={4}>
            <div
              className="btn-date"
              onClick={() => this.setState({ openDate: !this.state.openDate })}
            >
              Rango de Fechas
            </div>
            {this.state.openDate && (
              <div className="container-date-range">
                <DateRange
                  ranges={[this.state.selectionRange.selection]}
                  onChange={(value) => this.setState({ selectionRange: value })}
                  color="#2576DA"
                  locale={rdrLocales.es}
                />
              </div>
            )}
          </Column>
          <Column xs={3}>
            <Button
              className="no-margin-button"
              loading={this.state.btnReportLoading}
              onClick={() => this.generateReport()}
            >
              Generar
            </Button>
          </Column>
        </Row>
      </div>
    );
  }

  render() {
    const {
      selectedId,
      selectedIdGuide,
      showModal,
      loadingGuide,
      guides,
      sideBarContent,
      searchId,
      searchIdError,
      dataQuotation,
      btnLoading,
      reload,
      tempLogin,
      errorUser,
      filter,
    } = this.state;
    const { message, hideMessage } = this.props;
    if (errorUser) {
      return (
        <div className="white-bg">
          <ToastContainer />
        </div>
      );
    }
    const options = [
      {
        function: () => this.toogleList(),
        label: `${this.state.fullList ? "Modo Busqueda" : "Modo Listado"}`,
        image: detail_eye_icon,
      },
      {
        function: () => this.toggleReport(),
        label: "Generar Reporte",
        image: detail_eye_icon,
      },
    ];
    return this.state.report ? (
      this.renderReport()
    ) : (
      <div className={this.state.tempLogin ? "white-bg" : ""}>
        <Modal
          classNames={{ modal: "modal" }}
          open={showModal}
          onClose={this.onCloseModal}
        >
          <Title type="titleCard">Cotizacion #{selectedIdGuide}</Title>
          {loadingGuide ? (
            <div className="loader-container-modal">
              <Loader type="ball-pulse" color="#EB2C88" />
            </div>
          ) : (
            <div>
              <GuideModal data={guides} />
            </div>
          )}
        </Modal>
        <WrapperSideBar
          handleClose={this.onCloseSb}
          title_more={this.renderSidebarTitleExtraContent()}
        >
          {sideBarContent}
          {this.renderSidebarContent()}
        </WrapperSideBar>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: "right" }} fluid>
            {!tempLogin && <Menu />}
          </Column>
        </Row>
        {!this.state.reportRender && (
          <Row divisions={20}>
            <Column xs={1} fluid />
            <Column xs={18} fluid>
              <Header>
                <Row divisions={18}>
                  <Column xs={4} fluid>
                    <Title type="titleCard">Cotizaciones</Title>
                  </Column>
                  <Column xs={14} xsShift={16} fluid>
                    {!this.state.tempLogin && (
                      <ThreeDotsDropdown linksToRender={options} />
                    )}
                  </Column>
                </Row>
              </Header>
              {this.state.fullList ? (
                <div className="data-buttons">
                  <Filters
                    carriers={this.state.carriers}
                    noMarginFilter
                    filterData={this.filterData}
                    countries={this.props.sidebarOrder.countries}
                  />
                </div>
              ) : (
                <Row>
                  <Column xs={12} md={4} fluid>
                    <Input
                      parentClassName="filter-input"
                      type="text"
                      focus
                      value={searchId ? searchId : ""}
                      error={
                        selectedId
                          ? dataQuotation.length === 0
                            ? "Verifica el id ingresado"
                            : ""
                          : searchIdError
                      }
                      onKeyPress={this.searchPress}
                      onChange={this.handleSearchChange}
                      placeholder="Identificador de Cotización u Orden"
                    />
                  </Column>
                  <Column xs={12} md={4} fluid>
                    <Button
                      className="no-margin"
                      loading={btnLoading}
                      disabled={this.state.tempLogin}
                      onClick={this.searchPress}
                    >
                      Buscar
                    </Button>
                  </Column>
                  {dataQuotation[0] && (
                    <Column xs={12} md={3} mdShift={1} fluid>
                      {selectedId ? (
                        dataQuotation[0] &&
                        dataQuotation[0].status &&
                        (dataQuotation[0].status === "LABEL_CREATED" ||
                          dataQuotation[0].status === "TRANSITO" ||
                          dataQuotation[0].status === "INCIDENCIA" ||
                          dataQuotation[0].status === "GUIA_ENVIADA" ||
                          dataQuotation[0].status === "ENTREGADO") ? (
                          <Button
                            loading={btnLoading}
                            className="no-margin"
                            onClick={() => this.getQuote(selectedId, true)}
                          >
                            Ver Guias
                          </Button>
                        ) : dataQuotation[0].status === "MANUAL_LABEL" ? (
                          <Button
                            loading={btnLoading}
                            className="no-margin"
                            onClick={() => this.reprocessQuote(selectedId)}
                          >
                            s Reprocesar Cotización
                          </Button>
                        ) : (
                          <Button
                            loading={btnLoading}
                            className="no-margin"
                            onClick={() => this.processQuote(selectedId)}
                          >
                            Procesar Cotización
                          </Button>
                        )
                      ) : (
                        <div />
                      )}
                    </Column>
                  )}
                </Row>
              )}
              <Row divisions={20}>
                <Column xs={1} fluid />
                <Column xs={18} fluid>
                  {message.show && (
                    <Message color={message.messageType} onClose={hideMessage}>
                      {message.text}
                    </Message>
                  )}
                </Column>
                <Column xs={1} fluid />
              </Row>
            </Column>
            <Column xs={1} fluid />
          </Row>
        )}
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid />
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            {this.state.reportRender ? (
              this.renderReport()
            ) : this.state.fullList ? (
              <QuotationsFullList
                filter={filter}
                searchId={selectedId}
                context={this}
                singleViewQuote={this.singleViewQuote}
                dataQuotation={dataQuotation}
                shipmentDetails={this.shipmentDetails}
                addRate={() => this.openSideBar()}
                reload={reload}
                openSideBar={this.openSideBarDetails}
              />
            ) : (
              <QuotationList
                searchId={selectedId}
                context={this}
                dataQuotation={dataQuotation}
                shipmentDetails={this.shipmentDetails}
                addRate={() => this.openSideBar()}
                reload={reload}
                openSideBar={this.openSideBarDetails}
              />
            )}
          </Column>
          <Column xs={1} fluid />
          <Column xs={1} fluid />
          <Column xs={3} fluid>
            {selectedId &&
              dataQuotation[0] &&
              dataQuotation[0].status &&
              dataQuotation[0].status !== "LABEL_CREATED" &&
              !this.state.fullList && (
                <Button
                  type="link"
                  onClick={() => this.toggleAddOrder(this)}
                  className="address-delete"
                >
                  Agregar Paquete
                </Button>
              )}
          </Column>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  message: state.message,
  sidebarOrder: state.newShipment,
  user: state.user,
  quotes: state.shipments.quotes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSideBar,
      getAllUserQuotesUnauthorized,
      changeSidebarTitle,
      showMessage,
      hideMessage,
      showLoader,
      hideLoader,
      previousStep,
      nextStep,
      setValue,
      editShipment,
      createShipment,
      createAddress,
      createPackage,
      clearSidebar,
      showError,
      getAllUserQuotes,
      selectPackage,
      getUserAllPackages,
      clearPackage,
      getCountries,
      gotLogIn: () => push("/login"),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Quotations);
