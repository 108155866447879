import React from "react";

import "./style.css";

class ListedQuote extends React.Component {
    constructor(props: Props) {
        super(props)
    }

    render() {
        return (
            <li className={`selected-quote ${this.props.status}`}>
                #{this.props.quote}<br />

                <label className="msg-label">{this.props.message}</label>
            </li>
        )
    }
}

export default (ListedQuote)