// @flow
import axios from "axios";
import {
  LOAD_INVOICE_INFORMATION,
  SET_USER_INVOICES,
  CHANGE_TAB,
  IS_SENDING_INVOICE,
  IS_TIMING_INVOICE,
  IS_LOADING_INVOICE,
  IS_CREATING_INVOICE,
  HAS_ERROR_INVOICE,
} from "./actionsTypes";
import { hideLoader, showLoader } from "../loader";
import { showError } from "../error";
import { showMessage } from "../message";
import { CreateInvoice, Purchase, Purchases, State, Action } from "./types";

export const loadInvoice = (data) => ({
  type: LOAD_INVOICE_INFORMATION,
  payload: data,
});

export const getUserInvoices = (
  invoiced: "true" | "false",
  order?: "asc" | "desc",
  creating = false,
  email: string = "",
  for_invoice: boolean = true
) => {
  return (dispatch: Dispatch) => {
    if (!order) {
      order = "desc";
    }
    dispatch(showLoader("invoices"));
    return axios
      .get(
        `/api/purchases?order=${order}&limit=10000000&invoiced=${invoiced}&for_invoice=${for_invoice}`
      )
      .then((response) => {
        if (invoiced === "true") dispatch(setTab(1));
        else dispatch(setTab(0));
        return response;
      })
      .then((response) => {
        dispatch(setUserInvoices(response.data));
      })
      .then(() => {
        dispatch(hideLoader());
        if (creating) {
          dispatch(isCreating(false));
          dispatch(
            showMessage("success", `Se envió una factura al correo: ${email}`)
          );
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(showError(err.response.data.error));
      });
  };
};

const setTab = (tab: number) => {
  return {
    type: CHANGE_TAB,
    tab,
  };
};

export const createInvoice = (
  data: CreateInvoice,
  id: number,
  email: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(isCreating(true, id));
    return axios
      .post("/api/purchases/" + id + "/invoice", {
        name: data.name,
        email: data.email,
        phone: data.phone,
        rfc: data.rfc,
      })
      .then((response) => {
        dispatch(getUserInvoices("true", "desc", true, email));
      })
      .catch((err) => {
        dispatch(isCreating(false));
        dispatch(showError("Error al generar factura."));
        dispatch(hasError(id));
        setTimeout(() => {
          dispatch(hasError(null));
        }, 5000);
      });
  };
};

export const createWalletInvoice = (
  data: CreateInvoice,
  id: number,
  email: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(isCreating(true, id));
    return axios
      .post("/api/purchases/" + id + "/walletinvoice", {
        name: data.name,
        email: data.email,
        phone: data.phone,
        rfc: data.rfc,
      })
      .then((response) => {
        dispatch(getUserInvoices("true", "desc", true, email));
      })
      .catch((err) => {
        dispatch(isCreating(false));
        dispatch(showError(err.response.data.error));
        dispatch(hasError(id));
        setTimeout(() => {
          dispatch(hasError(null));
        }, 5000);
      });
  };
};

export const createAddWalletInvoice = (
  data: CreateInvoice,
  id: number,
  email: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(isCreating(true, id));
    return axios
      .post("/api/stp_wallet_transactions/" + id + "/invoice", {
        name: data.name,
        email: data.email,
        phone: data.phone,
        rfc: data.rfc,
      })
      .then((response) => {
        dispatch(getUserInvoices("true", "desc", true, email));
      })
      .catch((err) => {
        dispatch(isCreating(false));
        dispatch(showError(err.response.data.error));
        dispatch(hasError(id));
        setTimeout(() => {
          dispatch(hasError(null));
        }, 5000);
      });
  };
};

export const createInvoices = (shipmentsIds: Array<number>) => {
  return (dispatch: Dispatch) => {
    dispatch(showLoader("basic"));
    return axios
      .post("/api/purchases/invoice", {
        ids: shipmentsIds,
      })
      .then((response) => {
        dispatch(hideLoader());
        console.log("alfa");
        dispatch(getUserInvoices("true"));
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(showError(err.response.data.error));
      });
  };
};

export const resendInvoice = (
  invoiceId: number,
  object_id: number,
  email: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(isSending(true, object_id));
    return axios
      .post("/api/invoices/" + invoiceId + "/mail")
      .then((response) => {
        dispatch(isTiming(true));
        setTimeout(() => {
          dispatch(isTiming(false));
        }, 5000);
        dispatch(isSending(false));
        dispatch(
          showMessage("success", `Se envió una factura al correo: ${email}`)
        );
      })
      .catch((err) => {
        dispatch(isSending(false));
        dispatch(isTiming(false));
        dispatch(hasError(object_id));
        setTimeout(() => {
          dispatch(hasError(null));
        }, 5000);
        dispatch(showError(err.response.data.error));
      });
  };
};

export const setUserInvoices = (invoices: Purchases) => {
  return {
    type: SET_USER_INVOICES,
    invoices,
  };
};

export const isCreating = (creating, id = null) => ({
  type: IS_CREATING_INVOICE,
  creating,
  id,
});

export const isLoading = (loading) => ({
  type: IS_LOADING_INVOICE,
  loading,
});

export const isSending = (sending, id = null) => ({
  type: IS_SENDING_INVOICE,
  sending,
  id,
});

export const isTiming = (timing) => ({
  type: IS_TIMING_INVOICE,
  timing,
});

export const hasError = (id) => ({
  type: HAS_ERROR_INVOICE,
  id,
});
