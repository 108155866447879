import React from "react";
import axios from "axios";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column, element } from "hedron";
import isEmail from "validator/lib/isEmail";
import Text from "../Text";
import Address from "./common/address";
import Less from "./common/lessAddresses";
import More from "./common/moreAddresses";
import LessPackage from "./common/lessPackages";
import Package from "./common/package";
import FormPackage from "./common/formPackage";
import MorePackages from "./common/morePackages";
import Services from "./common/services";
import { HelpText } from "../Input";
import {
  getVolumetricWeight,
  formatPhone,
  clearPhone,
  emailRegex,
} from "../../utils/global_functions";
import { validateDimensions } from "../../utils/validate";

import {
  setStepTitles,
  setValue,
  setError,
  unsetError,
  setIcon,
  getUserAllAddresses,
  selectAddress,
  clearSelectAddressFrom,
  selectAddressTo,
  clearSelectAddressTo,
  getUserAllPackages,
  selectPackage,
  clearPackage,
  setStep,
  setIconAny,
  isLoadingZipcode,
  changeManual,
  changeLoading,
  changeStepError,
  getCountries,
  openInvoice,
} from "../../modules/sidebarOrder/actions";

import { createRates } from "../../modules/rates";

import "./style.css";
import { EmptyView } from "./common/loadingViews";

const noZipcode = "No existe el código";
class CreateLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manualFromEdit: false,
      manualFrom: false,
      manualTo: false,
      manualPackage: false,
      filterFrom: "",
      filterTo: "",
      filterPack: "",
      savePackage: false,
      getRates: false,
      isPreset: false,
      addressesFrom: {
        total_count: 0,
        results: [],
      },
      addressesTo: {
        total_count: 0,
        results: [],
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  async checkPackageDefault() {
    if (this.props.sidebar.isQuickQuote) {
      this.props.setValue(
        "package_weight",
        this.props.sidebar.data.package.weight.toString()
      );

      const newObject = {
        height: this.props.sidebar.data.package.dimensions.height,
        length: this.props.sidebar.data.package.dimensions.length,
        width: this.props.sidebar.data.package.dimensions.width,
      };
      await this.props.setValue("package_dimensions", newObject);
    } else {
      if (
        this.props.user.default_package &&
        this.props.sidebar.packages.results
      ) {
        const indexDropdown = this.props.sidebar.packages.results.findIndex(
          (obj) => obj.object_id == this.props.user.default_package.object_id
        );
        if (
          this.props.sidebar.packages.total_count > 0 &&
          this.props.sidebar.packages.total_count < 4
        ) {
          this._onChangePackageLess(indexDropdown);
        }

        if (this.props.sidebar.packages.total_count >= 4) {
          this._onChangePackageMore(indexDropdown);
        }

        await this.setPackageAttr(
          this.props.user.default_package.weight,
          this.props.user.default_package.description,
          this.props.user.default_package.insured_amount
        );
      }
    }
  }

  setPackageAttr(weight, description, insuredAmount) {
    if (weight) {
      this.props.setValue("package_weight", weight.toString());
    } else {
      this.props.setValue("package_weight", 0);
    }
    if (description) {
      this.props.setValue("package_description", description);
    } else {
      this.props.setValue("package_description", "");
    }
    if (insuredAmount == 0.0) {
      this.props.setValue("package_insuredAmount", "");
    } else {
      this.props.setValue("package_insuredAmount", insuredAmount);
    }
  }

  AddresesUpdate() {
    this.props.getUserAllAddresses().then(() => {
      const tempAddressesFrom = [];
      const tempAddressesTo = [];
      if (this.props.sidebar.addresses) {
        this.props.sidebar.addresses.results?.forEach((element) => {
          if (element.country === "Mexico" && element.origin_destiny == false) {
            tempAddressesFrom.push(element);
          }
        });
        this.setState({
          addressesFrom: {
            total_count: tempAddressesFrom.length,
            results: tempAddressesFrom,
          },
        });
        this.props.sidebar.addresses.results.forEach((element) => {
          if (element.origin_destiny == true) {
            tempAddressesTo.push(element);
          }
        });
        this.setState({
          addressesTo: {
            total_count: tempAddressesTo.length,
            results: tempAddressesTo,
          },
        });
      }
    });
  }

  async componentWillMount() {
    this.props.changeLoading(true);
    await this.props.getUserAllAddresses();
    await this.props.getUserAllPackages();
    await this.props.getCountries();

    // await this.AddresesUpdate();
    this.checkPackageDefault();
    this.props.changeLoading(false);
    this.props.setStepTitles([
      {
        label: "Origen",
        status: "unfinished",
      },
      {
        label: "Destino",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Paquete",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Servicio",
        status: "unfinished",
        forwardClickable: false,
      },
    ]);
    this.getCountry(this.props.sidebar.data.to.country);

    this.setState(
      {
        manualFrom: this.props.sidebar.manualFrom,
        manualTo: this.props.sidebar.manualTo,
        manualPackage: this.props.sidebar.manualPackage,
      },
      () => {
        if (
          this.props.sidebar.addresses.total_count == 0 ||
          this.props.sidebar.manualFrom
        )
          this._validForm("from", 0);
        else if (this.props.sidebar.fromAddress)
          this.props.setIconAny("completed", 0);
        if (
          this.props.sidebar.addresses.total_count == 0 ||
          this.props.sidebar.manualTo
        )
          this._validForm("to", 1);
        else if (this.props.sidebar.toAddress)
          this.props.setIconAny("completed", 1);
        if (
          this.props.sidebar.packages.total_count == 0 ||
          this.props.sidebar.manualPackage
        )
          this._validForm("package", 2);
        else if (this.props.sidebar.selectedPackage)
          this.props.setIconAny("completed", 2);
      }
    );
    if (this.props.sidebar.manualTo) {
      if (this.props.sidebar.data.to) {
        if (this.props.sidebar.data.to.zipcode) {
          this.updateZipcode("to", this.props.sidebar.data.to.zipcode);
        }
      }
    }
    if (this.props.sidebar.isQuickQuote) {
      await this.setStateAsync({
        manualFrom: true,
        manualTo: true,
        manualPackage: true,
      });
      if (this.props.sidebar.oldOrigin && this.props.sidebar.oldDestiny) {
        this.updateZipcode("from", this.props.sidebar.oldOrigin);
        this.updateZipcode("to", this.props.sidebar.oldDestiny);
      }

      if (this.props.sidebar.isEditing) {
        await this.setStateAsync({
          manualFrom: true,
          manualTo: true,
        });
      }
      this._validForm("package", 2);
    }
    if (this.props.sidebar.isEditing) {
      if (this.props.sidebar.fromSelectIdAddress === null) {
        this._validForm("from", 0);
      }
      this._validForm("to", 1);
    }
  }

  setStateAsync = (state) =>
    new Promise((resolve) => {
      this.setState(state, resolve);
    });

  _setAsyncValue = async (belongs, response) => {
    await this.props.setValue(
      `${belongs}_city`,
      `${
        response.data.municipality
          ? response.data.municipality
          : response.data.neighborhood
      }, ${response.data.state.name}`
    );
    this._validForm(belongs, belongs == "from" ? 0 : 1);
  };

  _validText = (value) => value.length > 0;

  _validNumber = (value) => !isNaN(value);

  insertPhoneFormat(phone, name) {
    phone = clearPhone(phone).split(" ").join("");
    if (phone === "") {
      return this.props.setValue(name, "");
    }
    const formattedPhone = formatPhone(phone);
    this.props.setValue(name, formattedPhone);
  }

  handleRadioChange(e) {
    const { name } = e.target;
    const valToSet = e.target.value;
    const belongsTo = name.split("_")[0];
    const key = name.split("_")[1];

    this.props.setValue(name, valToSet);
  }

  updateZipcode = (belongsTo, value) => {
    this.props.isLoadingZipcode(true);
    const country_code =
      belongsTo === "to" && this.props.sidebar.data.to.country
        ? this._getCountryByIndex(this.props.sidebar.data.to.country).code
        : "MX";
    axios
      .get(`/api/zipcodes/${value}?country=${country_code}`)
      .then(async (response) => {
        await this.props.setValue(
          `${belongsTo}_city`,
          `${
            response.data.municipality
              ? response.data.municipality
              : response.data.neighborhood
          }, ${response.data.state.name}`
        );
        await this.props.isLoadingZipcode(false);
        this._validForm(belongsTo);
      })
      .catch(async () => {
        await this.props.setValue(`${belongsTo}_city`, noZipcode);
        await this.props.isLoadingZipcode(false);
        this._validForm(belongsTo);
      });
  };

  async handleChange(value, name) {
    const belongsTo = name.split("_")[0];
    const key = name.split("_")[1];
    if (key === "zipcode" && !isNaN(value) && value.length === 5) {
      this.updateZipcode(belongsTo, value);
    }
    if (key === "phone") {
      this.insertPhoneFormat(value, name);
      return;
    }
    if (key === "country" && this.props.sidebar.data.to.zipcode) {
      this.updateZipcode(belongsTo, this.props.sidebar.data.to.zipcode);
    }
    await this.props.setValue(name, value);
  }

  _validateEmailRegex = (val, name) => {
    const isValid = isEmail(val);
    const car = emailRegex.test(val);
    if (!isValid || !car) {
      this.props.setError(name, "Correo electrónico inválido");
    } else if (car && isValid) {
      this.props.unsetError(name);
    }
    return car && isValid;
  };

  handleBlur(value, name, required) {
    const belongsTo = name.split("_")[0];
    const key = name.split("_")[1];

    if (required && !value)
      this.props.setError(name, "Este campo es requerido");
    else if (key === "email") this._validateEmailRegex(value, name);
    else if (
      key === "phone" &&
      (isNaN(clearPhone(value).split(" ").join("")) ||
        clearPhone(value).split(" ").join("").length != 10)
    )
      this.props.setError(name, "Número de teléfono inválido");
    else if (key === "weight" && isNaN(value))
      this.props.setError(name, "El peso es invalido");
    else if (key === "insuredAmount" && isNaN(value))
      this.props.setError(name, "El valor del paquete es invalido");
    else if (key === "insuredAmount" && value > 100000)
      // Límite de valor asegurado - Insured value limit
      this.props.setError(name, "Valor máximo asegurable $100 MIL MXN");
    else this.props.unsetError(name);
    if (key === "weight" && !isNaN(value)) {
      if (value > 190)
        this.props.setError(
          name,
          "El valor del peso debe ser menor que 190kg."
        );
    }
    if (key === "zipcode") {
      if (this._validSameZip())
        this.props.setError(name, "Los códigos postales deben ser diferentes");
      else this.props.unsetError(name);
    }
    this._validForm(belongsTo);
  }

  _validSameZip = () =>
    this.props.sidebar.data.from.zipcode.length == 5 &&
    this.props.sidebar.data.to.zipcode.length == 5 &&
    this.props.sidebar.data.from.zipcode == this.props.sidebar.data.to.zipcode;

  _validForm = (belongsTo, step = null) => {
    let isValid = false;
    switch (belongsTo) {
      case "from":
      case "to":
        if (
          this.props.sidebar.data[belongsTo].name.length > 0 &&
          this.props.sidebar.data[belongsTo].name.length <= 80 &&
          this.props.sidebar.data[belongsTo].neighborhood.length > 0 &&
          this.props.sidebar.data[belongsTo].neighborhood.length <= 35 &&
          this.props.sidebar.data[belongsTo].email.length <= 255 &&
          this.props.sidebar.data[belongsTo].zipcode.length == 5 &&
          this.props.sidebar.data[belongsTo].street.length > 0 &&
          this.props.sidebar.data[belongsTo].street.length <= 35 &&
          this.props.sidebar.data[belongsTo].city != noZipcode &&
          this.props.sidebar.data[belongsTo].country != null &&
          this.props.sidebar.data.from.zipcode !=
            this.props.sidebar.data.to.zipcode &&
          this.props.sidebar.data[belongsTo].reference?.length <= 32
        ) {
          if (
            !isNaN(
              clearPhone(this.props.sidebar.data[belongsTo].phone)
                .split(" ")
                .join("")
            ) &&
            clearPhone(this.props.sidebar.data[belongsTo].phone)
              .split(" ")
              .join("").length == 10
          ) {
            if (isEmail(this.props.sidebar.data[belongsTo].email)) {
              if (
                this._validateEmailRegex(
                  this.props.sidebar.data[belongsTo].email,
                  `${belongsTo}_email`
                )
              ) {
                if (this.props.sidebar.data[belongsTo].saveAddress) {
                  if (
                    this.props.sidebar.data[belongsTo].alias.length > 0 &&
                    this.props.sidebar.data[belongsTo].alias.length <= 255
                  ) {
                    isValid = true;
                    break;
                  } else {
                    isValid = false;
                    this.props.changeStepError(
                      belongsTo,
                      `Error con el alías en el ${this._getStepName(
                        belongsTo
                      )} del envío `
                    );
                  }
                } else {
                  isValid = true;
                  break;
                }
              } else {
                isValid = false;
                break;
              }
            } else {
              this.props.changeStepError(
                belongsTo,
                `Error con el email en el ${this._getStepName(
                  belongsTo
                ).toLocaleLowerCase()} del envío `
              );
            }
          } else {
            this.props.changeStepError(
              belongsTo,
              `Error con el teléfono en el ${this._getStepName(
                belongsTo
              ).toLocaleLowerCase()} del envío. No utilices caracteres especiales o código de área internacional `
            );
          }
        } else {
          isValid = false;
          this.props.changeStepError(
            belongsTo,
            `Llena los campos obligatorios para seguir creando tu guía`
          );
        }
        break;
      case "package":
        const type =
          this.props.sidebar.data[belongsTo].packageType === 1
            ? "sobre"
            : "caja";
        if (
          this.props.sidebar.packages.total_count == 0 ||
          this.state.manualPackage
        ) {
          if (this.props.sidebar.data.package.savePackage) {
            if (
              this.props.sidebar.data[belongsTo].alias.length > 0 &&
              this.props.sidebar.data[belongsTo].alias.length <= 255 &&
              this.props.sidebar.data[belongsTo].packageType != null &&
              this.props.sidebar.data[belongsTo].description.length > 0 &&
              this.props.sidebar.data[belongsTo].description.length <= 30
            ) {
              if (
                this.props.sidebar.data[belongsTo].dimensions.length.length >
                  0 &&
                this.props.sidebar.data[belongsTo].dimensions.width.length >
                  0 &&
                this.props.sidebar.data[belongsTo].dimensions.height.length >
                  0 &&
                this.props.sidebar.data[belongsTo].weight.length > 0 &&
                this.props.sidebar.data[belongsTo].insuredAmount.length > 0 &&
                !isNaN(this.props.sidebar.data[belongsTo].dimensions.length) &&
                !isNaN(this.props.sidebar.data[belongsTo].dimensions.width) &&
                !isNaN(this.props.sidebar.data[belongsTo].dimensions.height) &&
                !isNaN(this.props.sidebar.data[belongsTo].weight) &&
                !isNaN(this.props.sidebar.data[belongsTo].insuredAmount) &&
                this.props.sidebar.data[belongsTo].insuredAmount <= 100000 &&
                this.props.sidebar.data[belongsTo].weight < 190 &&
                this.props.sidebar.data[belongsTo].volumetricWeight < 190 &&
                !validateDimensions(
                  this.props.sidebar.data[belongsTo].dimensions,
                  type
                )
              )
                isValid = true;
            }
          } else if (
            this.props.sidebar.data[belongsTo].description.length > 0 &&
            this.props.sidebar.data[belongsTo].description.length <= 30
          ) {
            if (
              this.props.sidebar.data[belongsTo].dimensions.length.length > 0 &&
              this.props.sidebar.data[belongsTo].dimensions.width.length > 0 &&
              this.props.sidebar.data[belongsTo].dimensions.height.length > 0 &&
              this.props.sidebar.data[belongsTo].insuredAmount.length > 0 &&
              !isNaN(this.props.sidebar.data[belongsTo].dimensions.length) &&
              !isNaN(this.props.sidebar.data[belongsTo].dimensions.width) &&
              !isNaN(this.props.sidebar.data[belongsTo].dimensions.height) &&
              !isNaN(this.props.sidebar.data[belongsTo].weight) &&
              !isNaN(this.props.sidebar.data[belongsTo].insuredAmount) &&
              this.props.sidebar.data[belongsTo].insuredAmount <= 100000 &&
              this.props.sidebar.data[belongsTo].weight < 190 &&
              this.props.sidebar.data[belongsTo].volumetricWeight < 190 &&
              !validateDimensions(
                this.props.sidebar.data[belongsTo].dimensions,
                type
              )
            )
              isValid = true;
          }
        } else if (
          this.props.sidebar.selectedPackage &&
          this.props.sidebar.data.package.description.length > 0 &&
          this.props.sidebar.data.package.description.length <= 30 &&
          this.props.sidebar.data.package.weight.length > 0 &&
          !isNaN(this.props.sidebar.data.package.weight) &&
          this.props.sidebar.data.package.insuredAmount.length > 0 &&
          this.props.sidebar.data[belongsTo].insuredAmount <= 100000 &&
          !isNaN(this.props.sidebar.data.package.insuredAmount) &&
          this.props.sidebar.data[belongsTo].weight < 190 &&
          this.props.sidebar.data[belongsTo].volumetricWeight < 190 &&
          !validateDimensions(
            this.props.sidebar.data[belongsTo].dimensions,
            type
          )
        )
          isValid = true;
        if (
          this.props.sidebar.data[belongsTo].dimensions.height &&
          this.props.sidebar.data[belongsTo].dimensions.width &&
          this.props.sidebar.data[belongsTo].dimensions.length
        ) {
          if (
            validateDimensions(
              this.props.sidebar.data[belongsTo].dimensions,
              type
            )
          ) {
            this.props.setError(
              "package_dimensions",
              validateDimensions(
                this.props.sidebar.data[belongsTo].dimensions,
                type
              )
            );
          } else {
            if (this.props.sidebar.data[belongsTo].dimensionsError)
              this.props.unsetError("package_dimensions");
          }
        }
        break;
    }

    if (isValid) this.props.changeStepError(belongsTo, "");
    step =
      belongsTo === "to"
        ? 1
        : belongsTo === "from"
        ? 0
        : belongsTo === "package" && 2;
    if (isValid) {
      if (step == null) this.props.setIcon("completed");
      else this.props.setIconAny("completed", step);
    } else if (step == null) this.props.setIcon("alert");
    else this.props.setIconAny("unfinished", step);
  };

  _getStepName = (step) =>
    step == "from" ? "Origen" : step == "to" ? "Destino" : "Paquete";

  generateProps = (name, params) => ({
    onChange: (value) => {
      const val =
        params.type === "radio" || params.type === "checkbox"
          ? value.target.value
          : value;
      this.handleChange(val, name);
    },
    onBlur: (value) => {
      const val =
        params.type === "radio" || params.type === "checkbox"
          ? value.target.value
          : value;
      this.handleBlur(val, name, params.required);
    },
    name: name || "",
    value: params.value
      ? params.value
      : this.props.sidebar.data[name.split("_")[0]]
      ? this.props.sidebar.data[name.split("_")[0]][name.split("_")[1]]
      : "",
    error:
      this.props.sidebar.data[name.split("_")[0]][`${name.split("_")[1]}Error`],
    type: params.type ? params.type : "text",
    required: params.required ? params.required : false,
    placeholder: params.placeholder ? params.placeholder : "",
    label: params.label ? params.label : "",
    help: params.help ? params.help : "",
    maxLength: params.maxLength ? params.maxLength : null,
    disabled: params.disabled ? params.disabled : null,
    cornerNote: params.cornerNote ? params.cornerNote : null,
    icon: params.icon ? params.icon : null,
    prefix: params.prefix ? params.prefix : null,
    className: name ? `cl-${name}` : "",
    parentClassName: params.parentClassName ? params.parentClassName : null,
  });

  _generateFromObject = () => ({
    alias: {
      ...this.generateProps("from_alias", {
        required: true,
        placeholder: "Bodega Huimilpan",
        label: "Alias",
        maxLength: 255,
      }),
    },

    name: {
      ...this.generateProps("from_name", {
        required: true,
        placeholder: "Pedro Gómez",
        label: "Nombre de quien envía",
        maxLength: 80,
      }),
    },
    email: {
      ...this.generateProps("from_email", {
        required: true,
        placeholder: "pedro@tucorreo.com",
        label: "Email de quien envía",
        maxLength: 255,
      }),
    },
    phone: {
      ...this.generateProps("from_phone", {
        required: true,
        placeholder: "(442) 123 12 45",
        label: "Teléfono de contacto",
      }),
    },
    country: {
      type: "from",
      required: true,
      disabled: true,
      placeholder: "México",
      label: "País",
      maxLength: 50,
    },
    neighborhood: {
      ...this.generateProps("from_neighborhood", {
        required: true,
        placeholder: "Peñitas",
        label: "Colonia",
        maxLength: 35,
        disabled:
          this.props.sidebar.isEditing &&
          this.props.sidebar.marketplace == null &&
          this.state.manualFromEdit == false
            ? true
            : false,
      }),
    },
    zipcode: {
      ...this.generateProps("from_zipcode", {
        required: true,
        placeholder: "76158",
        label: "C.P.",
        maxLength: 5,
        disabled:
          this.props.sidebar.isEditing &&
          this.props.sidebar.marketplace == null &&
          this.state.manualFromEdit == false
            ? true
            : false,
      }),
    },
    city: {
      ...this.generateProps("from_city", {
        required: true,
        disabled: true,
        placeholder: "Querétaro, Qro.",
        label: "Ciudad y Estado",
        maxLength: 100,
      }),
    },
    street: {
      ...this.generateProps("from_street", {
        required: true,
        maxLength: 35,
        disabled:
          this.props.sidebar.isEditing &&
          this.props.sidebar.marketplace == null &&
          this.state.manualFromEdit == false
            ? true
            : false,
        placeholder: "Av. Real de Carretas #34",
        label: "Calle y número",
        help: [
          "¿Necesitas ayuda abreviando? Presiona ",
          <a
            key={0}
            href="https://blogger.mienvio.mx/2021/04/necesitas-ayuda-abreviando/"
            target="_blank"
            tabIndex="-1"
          >
            aqui
          </a>,
        ],
      }),
    },
    reference: {
      ...this.generateProps("from_reference", {
        maxLength: 32,
        placeholder: "Frente al mini super",
        label: "Referencias de la dirección de origen",
        help: "No ingreses datos de la dirección",
        disabled:
          this.props.sidebar.isEditing &&
          this.props.sidebar.marketplace == null &&
          this.state.manualFromEdit == false
            ? true
            : false,
      }),
    },
    saveAddress: {
      name: "save_from",
      isChecked: this.props.sidebar.data.from.saveAddress,
      onChange: async (val) => {
        await this.props.setValue("from_saveAddress", val.target.checked);
        this._validForm("from");
      },
      text_info: "Guardar en mis direcciones",
    },
  });

  getCountry(country) {
    if (country) {
      if (country === "Mexico") {
        this._changeCountry(0, "to");
      } else {
        this._changeCountry(1, "to");
      }
    } else {
      this._changeCountry(0, "to");
    }
  }

  _generateToObject = () => ({
    alias: {
      ...this.generateProps("to_alias", {
        required: true,
        placeholder: "Bodega Huimilpan",
        label: "Alias",
        maxLength: 255,
      }),
    },
    name: {
      ...this.generateProps("to_name", {
        required: true,
        placeholder: "Pedro Gómez",
        label: "Nombre de quien recibe",
        maxLength: 80,
      }),
    },
    email: {
      ...this.generateProps("to_email", {
        required: true,
        placeholder: "pedro@tucorreo.com",
        label: "Email de quien recibe",
        maxLength: 255,
      }),
    },
    phone: {
      ...this.generateProps("to_phone", {
        required: true,
        placeholder: "(442) 123 12 45",
        label: "Teléfono de contacto",
      }),
    },
    country: {
      type: "to",
      disable: false,
      data: this._renderCountries(),
      selected: this.props.sidebar.data.to.country,
      handleChange: async (country) => {
        if (!this.props.sidebar.data.saveAddress) {
          if (this._getCountryByIndex(country).code !== "MX") {
            await this.props.setValue("to_saveAddress", true);
            await this.props.setValue("to_alias", "Dirección Internacional");
          } else {
            await this.props.setValue("to_saveAddress", false);
            await this.props.setValue("to_alias", "");
          }
        }
        await this._changeCountry(country, "to");
        await this.updateZipcode("to", this.props.sidebar.data.to.zipcode);
        this._validForm("to");
      },
    },
    neighborhood: {
      ...this.generateProps("to_neighborhood", {
        required: true,
        placeholder: "Peñitas",
        label: "Colonia",
        maxLength: 35,
      }),
    },
    zipcode: {
      ...this.generateProps("to_zipcode", {
        required: true,
        placeholder: "76158",
        label: "C.P.",
        maxLength: 5,
      }),
    },
    city: {
      ...this.generateProps("to_city", {
        required: true,
        disabled: true,
        placeholder: "Querétaro, Qro.",
        label: "Ciudad y Estado",
        maxLength: 100,
      }),
    },
    street: {
      ...this.generateProps("to_street", {
        required: true,
        maxLength: 35,
        placeholder: "Av. Real de Carretas #34",
        label: "Calle y número",
        help: [
          "¿Necesitas ayuda abreviando? Presiona ",
          <a
            key={0}
            href="https://blogger.mienvio.mx/2021/04/necesitas-ayuda-abreviando/"
            target="_blank"
            tabIndex="-1"
          >
            aqui
          </a>,
        ],
      }),
    },
    reference: {
      ...this.generateProps("to_reference", {
        maxLength: 32,
        placeholder: "Frente al mini super",
        label: "Referencias de la dirección de destino",
        help: "No ingreses datos de la dirección",
      }),
    },
    saveAddress: {
      name: "save_to",
      isChecked: this.props.sidebar.data.to.saveAddress,
      onChange: async (val) => {
        await this.props.setValue("to_saveAddress", val.target.checked);
        this._validForm("to");
      },
      text_info: "Guardar en mis direcciones",
    },
  });

  _generatePackageObject = () => ({
    alias: {
      ...this.generateProps("package_alias", {
        required: true,
        placeholder: "Caja 20x20",
        label: "Alias",
        maxLength: 255,
      }),
    },
    objectType: {
      objectTypeCode: this.props.sidebar.data.package.packageType,
      objectTypeCodeError: this.props.sidebar.data.package.packageTypeError,
      handleChange: async (value) => {
        await this.props.setValue("package_packageType", value);
        this._validForm("package");
      },
    },
    dimensions: {
      type: "number",
      value: this.props.sidebar.data.package.dimensions,
      error: this.props.sidebar.data.package.dimensionsError,
      onChange: async (value, key) => {
        const newObject = {
          ...this.props.sidebar.data.package.dimensions,
          [key]: value,
        };
        await this.props.setValue("package_dimensions", newObject);

        if (
          newObject.length.length > 0 &&
          newObject.width.length > 0 &&
          newObject.height.length > 0
        ) {
          if (
            !isNaN(newObject.length) &&
            !isNaN(newObject.width) &&
            !isNaN(newObject.height)
          ) {
            this.props.unsetError("package_dimensions");
            const vol = getVolumetricWeight(
              this.props.sidebar.data.package.dimensions.length,
              this.props.sidebar.data.package.dimensions.width,
              this.props.sidebar.data.package.dimensions.height
            );
            this.props.setValue("package_volumetricWeight", vol);
            if (vol > 190) {
              this.props.setError(
                "package_dimensions",
                "El valor del peso volumétrico debe ser menor que 190kg."
              );
            }
          } else
            this.props.setError(
              "package_dimensions",
              "El valor de las dimensiones no es correcto"
            );
        }
        this._validForm("package");
      },
    },
    weight: {
      ...this.generateProps("package_weight", {
        required: true,
        placeholder: "190.0",
        label: "Peso",
        type: "number",
        onChange: async (val) => {
          await this.props.setValue("package_weight", val.target.value);
          this._validForm("package");
        },
      }),
    },
    volumetricWeight: this.props.sidebar.data.package.volumetricWeight,
    description: {
      ...this.generateProps("package_description", {
        required: true,
        placeholder: "Playeras serigraficas",
        label: "Descripción del contenido",
        maxLength: 30,
      }),
    },
    insuredAmount: {
      ...this.generateProps("package_insuredAmount", {
        required: true,
        label: "Valor del paquete",
        type: "number",
        prefix: "$",
        parentClassName: "insurenceAmount-input",
        placeholder: "1500.00",
      }),
    },
    savePackage: {
      name: "save_package",
      isChecked: this.props.sidebar.data.package.savePackage,
      onChange: async (val) => {
        await this.props.setValue("package_savePackage", val.target.checked);
        this._validForm("package");
      },
      text_info: "Guardar en mis empaques",
    },
  });

  _showValidateMsg = () => {
    if (this.props.sidebar.selectedPackage != null) {
      if (
        this._validText(this.props.sidebar.data.package.weight) &&
        this._validNumber(this.props.sidebar.data.package.weight)
      ) {
        return (
          this.props.sidebar.data.package.volumetricWeight >
          this.props.sidebar.data.package.weight
        );
      }
      false;
    } else {
      if (
        this._validText(this.props.sidebar.data.package.dimensions.length) &&
        this._validText(this.props.sidebar.data.package.dimensions.width) &&
        this._validText(this.props.sidebar.data.package.dimensions.height) &&
        this._validText(this.props.sidebar.data.package.weight) &&
        this._validNumber(this.props.sidebar.data.package.dimensions.length) &&
        this._validNumber(this.props.sidebar.data.package.dimensions.width) &&
        this._validNumber(this.props.sidebar.data.package.dimensions.height) &&
        this._validNumber(this.props.sidebar.data.package.weight)
      ) {
        return (
          this.props.sidebar.data.package.volumetricWeight >
          this.props.sidebar.data.package.weight
        );
      }
      false;
    }
  };

  _onChangeAddressesLessFrom = (e) => {
    this.props.changeStepError("from", null);
    let selected = null;
    this.props.sidebar.addresses.results.forEach((element, index) => {
      if (e.target.value === element.object_id.toString()) {
        selected = index;
      }
    });
    this.props.selectAddress(
      e.target.value,
      this.props.sidebar.addresses.results[selected]
    );
    this.props.setIcon("completed");
  };

  _onChangeAddressesLessTo = (e) => {
    this.props.changeStepError("to", null);
    const index = this.props.sidebar.addresses.results.findIndex(
      (x) => x.object_id.toString() === e.target.value
    );
    this.props.selectAddressTo(
      e.target.value,
      this.props.sidebar.addresses.results[index]
    );
    this.props.setIcon("completed");
  };

  _onChangeAddressesMore = (id) => {
    this.props.changeStepError("from", null);
    const addressFrom = [];
    this.props.sidebar.addresses.results.forEach((element) => {
      if (element.country === "Mexico" && element.origin_destiny == false) {
        addressFrom.push(element);
      }
    });
    this.props.selectAddress(
      id,
      this._getFilterAddressesFrom("from", addressFrom)[id]
    );
    this.props.setIcon("completed");
  };

  _onChangeAddressesMoreTo = (id) => {
    this.props.changeStepError("to", null);
    const addressTo = [];
    this.props.sidebar.addresses.results.forEach((element) => {
      if (element.origin_destiny == true) {
        addressTo.push(element);
      }
    });

    this.props.selectAddressTo(
      id,
      this._getFilterAddressesFrom("to", addressTo)[id]
    );
    this.props.setIcon("completed");
  };

  _manualAddressFrom = () => {
    this.props.clearSelectAddressFrom();
    this.setState({ manualFromEdit: true });
    this.setState({ manualFrom: !this.state.manualFrom }, () => {
      this.props.changeManual("manualFrom", this.state.manualFrom);
      if (this.state.manualFrom) this._setOriginCountry();
    });
    this.props.setIcon("alert");
  };

  _manualAddressTo = () => {
    this.props.clearSelectAddressTo();
    this.setState({ manualTo: !this.state.manualTo }, () => {
      this.props.changeManual("manualTo", this.state.manualTo);
    });
    this.props.setIcon("alert");
  };

  _renderSelectedClass = (belong, id) =>
    belong == "from"
      ? this.props.sidebar.fromAddress != null
        ? id == this.props.sidebar.fromAddress.object_id
          ? "more-address-selected"
          : ""
        : ""
      : this.props.sidebar.toAddress != null
      ? id == this.props.sidebar.toAddress.object_id
        ? "more-address-selected"
        : ""
      : "";

  _getFilterAddressesFrom = (belongs, addreses) =>
    belongs == "from"
      ? this.state.filterFrom.length > 0
        ? addreses.filter((address) =>
            address.alias
              .toLowerCase()
              .includes(this.state.filterFrom.toLowerCase())
          )
        : addreses
      : this.state.filterTo.length > 0
      ? addreses.filter((address) =>
          address.alias
            .toLowerCase()
            .includes(this.state.filterTo.toLowerCase())
        )
      : addreses;

  _generateOptions = (belongs) => {
    if (belongs == "from") {
      const addressFrom = [];
      if (this.props.sidebar.addresses.results) {
        this.props.sidebar.addresses.results.forEach((element) => {
          if (element.country === "Mexico" && element.origin_destiny == false) {
            addressFrom.push(element);
          }
        });
      }
      if (this.props.sidebar.addresses.results) {
        return Object.values(
          this._getFilterAddressesFrom(belongs, addressFrom)
        ).map((address) => (
          <Row
            key={address.object_id}
            className={`card-dropdown-option ${this._renderSelectedClass(
              belongs,
              address.object_id
            )}`}
          >
            <i className="ion-checkmark-round" />
            <Column fluid>
              <Text className="title-selected">
                <b>{address.alias}</b>
              </Text>
            </Column>
            <Column fluid>
              <Text>{address.name}</Text>
            </Column>
            <Column fluid>
              <Text>
                {address.email}.{formatPhone(address.phone)}.
              </Text>
            </Column>
            <Column fluid>
              <Text>{address.street}</Text>
            </Column>
            {address.reference && (
              <Column fluid>
                <Text>{address.reference}</Text>
              </Column>
            )}
            <Column fluid>
              <Text>{address.street2}</Text>
            </Column>
            <Column fluid>
              <Text>
                C.P.
                {address.zipcode}
              </Text>
            </Column>
          </Row>
        ));
      }
      return [];
    }
    const addressTo = [];
    if (this.props.sidebar.addresses.results) {
      this.props.sidebar.addresses.results.forEach((element) => {
        if (element.origin_destiny == true) {
          addressTo.push(element);
        }
      });
    }
    return this.props.sidebar.addresses.results != undefined
      ? Object.values(this._getFilterAddressesFrom(belongs, addressTo)).map(
          (address) => (
            <Row
              key={address.object_id}
              className={`card-dropdown-option ${this._renderSelectedClass(
                belongs,
                address.object_id
              )}`}
            >
              <i className="ion-checkmark-round" />
              <Column fluid>
                <Text className="title-selected">
                  <b>{address.alias}</b>
                </Text>
              </Column>
              <Column fluid>
                <Text>{address.name}</Text>
              </Column>
              <Column fluid>
                <Text>
                  {address.email}.{formatPhone(address.phone)}.
                </Text>
              </Column>
              <Column fluid>
                <Text>{address.street}</Text>
              </Column>
              {address.reference && (
                <Column fluid>
                  <Text>{address.reference}</Text>
                </Column>
              )}
              <Column fluid>
                <Text>{address.street2}</Text>
              </Column>
              <Column fluid>
                <Text>
                  C.P.
                  {address.zipcode}
                </Text>
              </Column>
            </Row>
          )
        )
      : [];
  };

  _getFilteredPackages = () =>
    this.state.filterPack.length > 0
      ? this.props.sidebar.packages.results.filter((pack) =>
          pack.alias.toLowerCase().includes(this.state.filterPack.toLowerCase())
        )
      : this.props.sidebar.packages.results;

  _getPackageClass = (id) =>
    this.props.sidebar.selectedPackage != null
      ? this.props.sidebar.selectedPackage.object_id == id
        ? "more-address-selected"
        : ""
      : "";

  _generatePackagesOptions = () =>
    this.props.sidebar.packages.results != undefined
      ? Object.values(this._getFilteredPackages()).map((pack) => (
          <Row
            key={pack.object_id}
            className={`card-dropdown-option ${this._getPackageClass(
              pack.object_id
            )}`}
          >
            <i className="ion-checkmark-round" />
            <Column fluid>
              <Text className="title-selected">
                <b>{pack.alias}</b>
              </Text>
            </Column>
            <Column fluid>
              <Text>{`${pack.height} x ${pack.length} x ${pack.width} cm`}</Text>
            </Column>
          </Row>
        ))
      : [];

  openInternationalShipments() {
    window.open("https://form.jotform.co/82914873257871");
  }

  _renderFromStep = () => {
    const addressFrom = [];
    let addressFromCounter = 0;

    let defaultOrigin = null;

    if (this.props.sidebar.addresses.results) {
      this.props.sidebar.addresses.results.forEach((element) => {
        if (element.country === "Mexico" && element.origin_destiny == false) {
          addressFrom.push(element);
          addressFromCounter++;
        }
      });

      defaultOrigin = this.props.sidebar.addresses.results.find(
        (ele) => ele.object_type === "PRIMARY"
      );
    }

    addressFrom.sort((a, b) => (a.object_id > b.object_id ? 1 : -1));

    if (addressFromCounter == 0 || this.state.manualFrom) {
      return (
        <Address
          international={() => this.openInternationalShipments()}
          model={this._generateFromObject()}
          hasAddress={addressFromCounter > 0}
          defaultOriginFrom={defaultOrigin}
          cancelManualfn={this._manualAddressFrom}
        />
      );
    }
    return addressFromCounter < 4 ? (
      <Less
        addresses={addressFrom}
        onChangefn={this._onChangeAddressesLessFrom}
        newAddressFn={this._manualAddressFrom}
        isChecked={(id) =>
          this.props.sidebar.fromAddress
            ? id == this.props.sidebar.fromAddress.object_id
            : false
        }
      />
    ) : (
      <More
        addresses={this._generateOptions("from")}
        selectedAddressFrom={this.props.sidebar.fromSelectIdAddress}
        filterFrom={this.state.filterFrom}
        handleAddressChange={this._onChangeAddressesMore}
        handleFilterChange={(value) => {
          this.setState({ filterFrom: value });
        }}
        newAddressFn={this._manualAddressFrom}
        address={this.props.sidebar.fromAddress}
        typeAddress="Origen"
      />
    );
  };
  _renderToStep = () => {
    const addressTo = [];
    let addressToCounter = 0;
    if (this.props.sidebar.addresses.results) {
      this.props.sidebar.addresses.results.forEach((element) => {
        if (element.origin_destiny == true) {
          addressTo.push(element);
          addressToCounter++;
        }
      });
    }

    if (addressToCounter == 0 || this.state.manualTo) {
      return (
        <Address
          international={() => this.openInternationalShipments()}
          model={this._generateToObject()}
          hasAddress={addressToCounter > 0}
          cancelManualfn={this._manualAddressTo}
        />
      );
    }
    return addressToCounter < 4 ? (
      <Less
        addresses={addressTo}
        onChangefn={this._onChangeAddressesLessTo}
        newAddressFn={this._manualAddressTo}
        isChecked={(id) =>
          this.props.sidebar.toAddress
            ? id == this.props.sidebar.toAddress.object_id
            : false
        }
      />
    ) : (
      <More
        addresses={this._generateOptions("to")}
        selectedAddressFrom={this.props.sidebar.toSelectIdAddress}
        filterFrom={this.state.filterTo}
        handleAddressChange={this._onChangeAddressesMoreTo}
        handleFilterChange={(value) => {
          this.setState({
            filterTo: value,
          });
        }}
        newAddressFn={this._manualAddressTo}
        isChecked={(id) =>
          this.props.sidebar.toAddress
            ? id == this.props.sidebar.toAddress.object_id
            : false
        }
        address={this.props.sidebar.toAddress}
        typeAddress="Destino"
      />
    );
  };

  _manualPackage = () => {
    this.props.clearPackage();
    this.setState({ manualPackage: !this.state.manualPackage }, () => {
      this.props.changeManual("manualPackage", this.state.manualPackage);
    });
    this.props.setIcon("alert");
  };

  _onChangePackageLess = async (e) => {
    const indexPackage = e.target ? e.target.name : e;

    const selectedPackage =
      this.props.sidebar.packages.results[indexPackage] ?? {};
    this.props.selectPackage(indexPackage, selectedPackage);

    const vol = selectedPackage
      ? getVolumetricWeight(
          selectedPackage.length,
          selectedPackage.width,
          selectedPackage.height
        )
      : 0;

    this.props.setValue("package_volumetricWeight", vol);
    if (vol > 190) {
      this.props.setError(
        "package_weight",
        "El valor del peso volumétrico debe ser menor que 190kg."
      );
    }

    await this.setPackageAttr(
      selectedPackage?.weight ?? 1,
      selectedPackage?.description ?? "",
      selectedPackage?.insured_amount ?? 0
    );

    if (
      this.props.sidebar.data.package.description.length > 0 &&
      this.props.sidebar.data.package.description.length <= 30 &&
      this.props.sidebar.data.package.weight.length > 0 &&
      this.props.sidebar.data.package.insuredAmount.length > 0 &&
      !isNaN(this.props.sidebar.data.package.weight) &&
      !isNaN(this.props.sidebar.data.package.insuredAmount) &&
      this.props.sidebar.data.package.insuredAmount <= 100000
    )
      this.props.setIconAny("completed", 2);
    else this.props.setIconAny("alert", 2);
  };

  _onChangePackageMore = async (id) => {
    const selectedPackage = this._getFilteredPackages()[id] ?? {};
    this.props.selectPackage(id, selectedPackage);
    const vol = getVolumetricWeight(
      selectedPackage.length,
      selectedPackage.width,
      selectedPackage.height
    );
    this.props.setValue("package_volumetricWeight", vol);
    if (vol > 190) {
      this.props.setError(
        "package_weight",
        "El valor del peso volumétrico debe ser menor que 190kg."
      );
    }

    await this.setPackageAttr(
      selectedPackage.weight,
      selectedPackage.description,
      selectedPackage.insured_amount
    );

    if (
      this.props.sidebar.data.package.description.length > 0 &&
      this.props.sidebar.data.package.description.length <= 30 &&
      this.props.sidebar.data.package.weight.length > 0 &&
      this.props.sidebar.data.package.insuredAmount.length > 0 &&
      !isNaN(this.props.sidebar.data.package.weight) &&
      !isNaN(this.props.sidebar.data.package.insuredAmount) &&
      this.props.sidebar.data.package.insuredAmount <= 100000
    )
      this.props.setIconAny("completed", 2);
    else this.props.setIconAny("alert", 2);
  };

  _getIsInternational = () => {
    if (this._getCountryByIndex(this.props.sidebar.data.to.country)) {
      return (
        (this.props.sidebar.toAddress &&
          this.props.sidebar.toAddress.country != "Mexico") ||
        (this.props.sidebar.data.to.country &&
          this._getCountryByIndex(this.props.sidebar.data.to.country) &&
          this._getCountryByIndex(this.props.sidebar.data.to.country).code !=
            "MX")
      );
    }
    return this.props.sidebar.data.to.country != "Mexico";
  };

  _fillCommercialInvoice = () => {
    this.props.openInvoice();
  };

  _renderPackageStep = () => {
    const pack = this.props.sidebar.selectedPackage
      ? this.props.sidebar.selectedPackage
      : this.props.newShipment.data.package.dimensions.length &&
        this.props.newShipment.data.package.dimensions.width &&
        this.props.newShipment.data.package.dimensions.height
      ? this.props.newShipment.data.package.dimensions
      : null;
    return (
      <Package
        model={this._generatePackageObject()}
        showMsg={this._showValidateMsg()}
        isInternational={this._getIsInternational()}
        commercialInvoice={this.props.sidebar.commercialInvoice}
        fillCommercialInvoice={this._fillCommercialInvoice}
      >
        {this.props.sidebar.packages.total_count == 0 ||
        this.state.manualPackage ? (
          <FormPackage
            model={this._generatePackageObject()}
            hasPackages={this.props.sidebar.packages.total_count > 0}
            cancelManualPg={this._manualPackage}
            saveIt={this.props.sidebar.data.package.savePackage}
          />
        ) : this.props.sidebar.packages.total_count < 4 ? (
          <LessPackage
            packages={this.props.sidebar.packages.results}
            onChangefn={this._onChangePackageLess}
            newPackage={this._manualPackage}
            isChecked={(id) =>
              this.props.sidebar.selectedPackage
                ? id == this.props.sidebar.selectedPackage.object_id
                : false
            }
          />
        ) : (
          <MorePackages
            packages={this._generatePackagesOptions()}
            selectedPack={this.props.sidebar.selectedIdPackage}
            filter={this.state.filterPack}
            handleChange={this._onChangePackageMore}
            handleFilterChange={(val) => this.setState({ filterPack: val })}
            newPackage={this._manualPackage}
            pack={this.props.sidebar.selectedPackage}
          />
        )}
        {pack && (
          <div>
            <Row>
              <Column xs={12} md={12} className="no-padding-left">
                <Text type="microHeader" className="package-title">
                  Dimensiones
                </Text>
                <Text type="P">{`${pack.length ?? 0} x ${pack.width ?? 0} x ${
                  pack.height ?? 0
                } cm`}</Text>
                {pack.object_type && (
                  <HelpText
                    style={{ display: "block" }}
                    className={`${
                      validateDimensions(
                        {
                          length: pack.length,
                          width: pack.width,
                          height: pack.height,
                        },
                        pack.object_type.toLowerCase()
                      ) && "open"
                    }`}
                  >
                    {validateDimensions(
                      {
                        length: pack.length,
                        width: pack.width,
                        height: pack.height,
                      },
                      pack.object_type.toLowerCase()
                    )}
                  </HelpText>
                )}
              </Column>
            </Row>
          </div>
        )}
      </Package>
    );
  };

  _renderIncomplete = () => {
    if (this.props.sidebar.stepTitles[0].status != "completed")
      this.props.setStep(0);
    else if (this.props.sidebar.stepTitles[1].status != "completed")
      this.props.setStep(1);
    else if (this.props.sidebar.stepTitles[2].status != "completed")
      this.props.setStep(2);
    return <EmptyView />;
  };

  _renderCountries = () =>
    this.props.sidebar.countries
      ? this.props.sidebar.countries.map((country) => (
          <Text key={country.object_id}>{country.name}</Text>
        ))
      : null;

  _changeCountry = (country, type) => {
    this.props.setValue(`${type}_country`, country);
  };

  _setOriginCountry = () => {
    const value = this.props.sidebar.countries.findIndex(
      (coun) => coun.code == "MX"
    );
    if (value >= 0) this.props.setValue("from_country", value);
  };

  _getCountryByIndex = (index) => this.props.sidebar.countries[index];

  render() {
    switch (this.props.sidebar.step || 0) {
      case 0:
        return (
          <div>
            {this.props.sidebar.loading ? (
              <EmptyView />
            ) : (
              this._renderFromStep()
            )}
          </div>
        );
      case 1:
        return (
          <div>
            {this.props.sidebar.loading ? <EmptyView /> : this._renderToStep()}
          </div>
        );
      case 2:
        return (
          <div>
            {this.props.sidebar.loading ? (
              <EmptyView />
            ) : (
              this._renderPackageStep()
            )}
          </div>
        );
      case 3:
        return this.props.sidebar.stepTitles[0].status == "completed" &&
          this.props.sidebar.stepTitles[1].status == "completed" &&
          this.props.sidebar.stepTitles[2].status == "completed" ? (
          <Services />
        ) : (
          this._renderPackageStep()
        );

        break;
    }
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.newShipment,
  newShipment: state.newShipment,
  user: state.user,
  sidebarOrder: state.newShipment,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setStepTitles,
      setValue,
      setError,
      unsetError,
      setIcon,
      setStep,
      getUserAllAddresses,
      selectAddress,
      clearSelectAddressFrom,
      selectAddressTo,
      clearSelectAddressTo,
      getUserAllPackages,
      selectPackage,
      clearPackage,
      createRates,
      setIconAny,
      isLoadingZipcode,
      changeManual,
      changeLoading,
      changeStepError,
      getCountries,
      openInvoice,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateLabel);
