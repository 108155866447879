import isEmail from "validator/lib/isEmail";
import { clearPhone } from "./global_functions";

const errors = {
  required: "Este campo es requerido",
  options: "Selecciona una opción",
};

export const validateDimensions = (dimensions, type) => {
  // const height = Math.floor(dimensions.height)
  // const width = Math.floor(dimensions.width)
  // const length = Math.floor(dimensions.length)
  // if(type === 'sobre') {
  //   // considers that if the package is a envelope then this is a real dimension
  //   // 34x1x25
  //   if(height === 25 && width === 1 && length === 34) return ''
  // }
  // const error = 'Las medidas de tu paquete son inválidas'
  // if(height < 9 || length < 9 || width < 9) {
  //   return error;
  // }
  return null;
};

export const validatePackage = (_package) => {
  /*
    _package: {
      alias: '',
      aliasError: '',
      type: '',
      typeError: '',
      dimensions: {
        length: '',
        width: '',
        height: ''
      },
      dimensionsError: '',
      description: '',
      descriptionError: '',
      weight: '',
      weightError: '',
      insuredAmount: '',
      insuredAmountError: '',
    */

  let dimensionsError = "";
  const aliasError = !_package.alias ? errors.required : "";
  const typeError =
    ["caja", "sobre"].indexOf(_package.type) < 0 ? errors.options : "";

  if (
    !_package.dimensions.height ||
    !_package.dimensions.length ||
    !_package.dimensions.width
  ) {
    dimensionsError = "Todas las dimensiones son requeridas";
  } else if (
    isNaN(_package.dimensions.height) ||
    isNaN(_package.dimensions.length) ||
    isNaN(_package.dimensions.width)
  ) {
    dimensionsError = "Todas las dimensiones deben tener valores númericos";
  } else if (
    _package.dimensions.height > 170 ||
    _package.dimensions.length > 170 ||
    _package.dimensions.width > 170
  ) {
    dimensionsError = "Todas las dimensiones deben ser menores a 170cm";
  } else if (
    Number(_package.dimensions.height) +
      Number(_package.dimensions.length) +
      Number(_package.dimensions.width) >=
    360
  ) {
    dimensionsError = "La suma de las dimensiones debe de ser menor a 360cm";
  } else {
    dimensionsError = validateDimensions(_package.dimensions, _package.type);
  }

  const weightError = isNaN(_package.weight)
    ? "Todas las dimensiones deben tener valores númericos"
    : "";

  const insuredAmountError =
    typeof _package.insuredAmount === "undefined"
      ? ""
      : isNaN(_package.insuredAmount)
      ? "El monto debe ser un valor numérico"
      : "";

  const descriptionError =
    _package.description.length > 30 ? "Máximo 30 caracteres" : "";

  const isValid = !(
    aliasError ||
    typeError ||
    dimensionsError ||
    weightError ||
    insuredAmountError ||
    descriptionError
  );

  return [
    isValid,
    (_package = {
      ..._package,
      aliasError,
      typeError,
      dimensionsError,
      weightError,
      insuredAmountError,
    }),
  ];
};

export const translatePackage = (_package) => ({
  object_type_code: _package.type == "caja" ? "box" : "envelope",
  object_status_code: null,
  alias: _package.alias,
  provider: null,
  height: _package.dimensions.height,
  width: _package.dimensions.width,
  length: _package.dimensions.length,
  quantity: null,
  weight: _package.weight ? _package.weight : null,
  description: _package.description,
  insured_amount: _package.insuredAmount ? _package.insuredAmount : null,
});

export const validateAddress = (address) => {
  let errors = 0;
  let aliasError = "";
  let nameError = "";
  let emailError = "";
  let phoneError = "";
  let streetError = "";
  let street2Error = "";
  let referenceError = "";
  let zipcodeError = "";

  if (address) {
    if (!address.alias) {
      errors++;
      aliasError = "Este campo es requerido";
    }

    if (!address.name) {
      errors++;
      nameError = "Este campo es requerido";
    }
    if (!address.email) {
      errors++;
      emailError = "Este campo es requerido";
    } else if (!isEmail(address.email)) {
      errors++;
      emailError = "Correo electrónico inválido";
    }
    if (!phoneValidation(address.phone)) {
      errors++;
      phoneError = "Número de teléfono inválido";
    }
    if (!address.street) {
      errors++;
      streetError = "Este campo es requerido";
    } else if (address.street.length > 35) {
      errors++;
      streetError = "Máximo 35 caracteres";
    }

    if (!address.street2) {
      errors++;
      street2Error = "Este campo es requerido";
    } else if (address.street2.length > 35) {
      errors++;
      street2Error = "Máximo 35 caracteres";
    }

    if (address.reference && address.reference.length > 32) {
      errors++;
      referenceError = "Máximo 32 caracteres";
    }
    if (!address.zipcode) {
      errors++;
      zipcodeError = "Este campo es requerido";
    } else if (!zipcodeValidation(address.zipcode, address.location)) {
      errors++;
      zipcodeError = "C.P. inválido";
    }
  } else {
    errors++;
    aliasError = "Este campo es requerido";
    nameError = "Este campo es requerido";
    emailError = "Este campo es requerido";
    phoneError = "Este campo es requerido";
    streetError = "Este campo es requerido";
    street2Error = "Este campo es requerido";
    referenceError = "Este campo es requerido";
    zipcodeError = "Este campo es requerido";
  }

  const isValid = errors === 0;

  return [
    isValid,
    (address = {
      ...address,
      aliasError,
      nameError,
      emailError,
      phoneError,
      streetError,
      street2Error,
      zipcodeError,
    }),
  ];
};

export const zipcodeValidation = (zipcode, location) =>
  !(isNaN(zipcode) || zipcode.length !== 5 || location === "");

export const phoneValidation = (phone) => {
  phone = clearPhone(phone).split(" ").join("");
  return !(isNaN(phone) || phone.length != 10);
};
