import React from "react";
import { Row, Column } from "hedron";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Text from "../../../components/Text";
import { Divider } from "../../../components/Card";
import Button from "../../../components/Button";
import { searchPermission } from "../../../utils/global_functions";

import { hideMessage, showMessage } from "../../../modules/message";

const hasApiMarketplace = (shops) => {
  let flag = false;
  shops.forEach((element) => {
    if (element.marketplace === "api") {
      flag = true;
    }
  });
  return flag;
};

const getApiMarketplace = (shops) => {
  let api = null;
  shops.forEach((element) => {
    if (element.marketplace === "api") {
      api = element;
    }
  });
  return api;
};

const ApiConnections = ({
  user,
  showMessage,
  handleSelect,
  webhooks,
  showWebhooks,
  showRate,
  shops,
}) => {
  const { customer_type, plan, actions, role, operational_user } = user;
  const permissions = [];

  if (
    operational_user &&
    operational_user.operational_role &&
    operational_user.operational_role.actions
  ) {
    operational_user.operational_role.actions.map((element) =>
      permissions.push(element)
    );
  }

  if (plan && plan.features) {
    plan.features.map((element) => permissions.push(element));
  }
  if (actions) {
    actions.map((element) => permissions.push(element));
  }
  return (
    <div>
      <Row className="detail-row">
        <Column fluid>
          <Text type="label">API key</Text>
        </Column>
      </Row>
      <Row className="detail-row">
        <Column fluid>
          <Text style={{ fontSize: "8px" }}>{user.api_token}</Text>
          <CopyToClipboard
            text={user.api_token}
            onCopy={() =>
              showMessage("success", "API key copiado al portapapeles.")
            }
          >
            <Button type="link" className="edit-btn-profile">
              Copiar al portapapeles <i className="ion-share" />
            </Button>
          </CopyToClipboard>
        </Column>
      </Row>
      {shops && hasApiMarketplace(shops.shops) && (
        <div>
          <Row className="detail-row">
            <Column fluid>
              <Text type="label">Tipo de retorno de tarifa</Text>
            </Column>
          </Row>
          <Row className="detail-row">
            <Column fluid>
              <Text style={{ fontSize: "14px" }}>
                {getApiMarketplace(shops.shops).retrieve_all_rates
                  ? "Regresar Tarifas"
                  : "No Regresar Tarifas"}{" "}
                -{" "}
                {getApiMarketplace(shops.shops).default_service_level
                  ? getApiMarketplace(shops.shops).default_service_level
                  : "Costo mas bajo"}
              </Text>
              <Button
                onClick={() =>
                  showRate({
                    marketplace: "API",
                    name: user.email,
                    object_id: getApiMarketplace(shops.shops).object_id,
                  })
                }
                type="link"
                className="edit-btn-profile"
              >
                Editar Configuración
              </Button>
            </Column>
          </Row>
        </div>
      )}

      <Divider />
      <Row>
        <Column fluid>
          <Text type="label">Webhooks</Text>
          {searchPermission("marketplaces", "editar", permissions) && (
            <Button
              type="link"
              className="edit-btn-profile"
              onClick={() => handleSelect("webhook")}
            >
              Nuevo webhook
            </Button>
          )}
        </Column>
      </Row>
      {webhooks ? showWebhooks() : null}
    </div>
  );
};

export default ApiConnections;
