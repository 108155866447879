import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dropdown from '../Dropdown';
import { Row, Column } from 'hedron';
import Text, { Title } from '../Text';
import EmpyState from '../EmptyState';
import Button from '../Button';
import NewTable from '../NewTable';
import Moment from 'react-moment';
import Skeleton from 'react-loading-skeleton';
import Waypoint from 'react-waypoint';
import Loader from 'react-loaders';
import colors from '../../utils/colors';
import styled from 'styled-components';
import { showError } from '../../modules/error';
import { searchPermission } from '../../utils/global_functions';

const columns = [
  {
    name: 'Paquetería',
    selector: 'paqueteria',
    sortable: true,
    cell: row => {
      if (row.provider == null || row.provider == '') {
        return '-';
      } else {
        return row.provider;
      }
    }
  },
  {
    name: 'Nivel de Servicio',
    selector: 'servicelevel',
    sortable: true,
    cell: row => {
      if (row.servicelevel == null || row.servicelevel == '') {
        return '-';
      } else {
        return row.servicelevel;
      }
    }
  },
  {
    name: 'Duración',
    selector: 'duration_terms',
    sortable: false
  },
  {
    name: 'Peso Mínimo',
    selector: 'min_weight',
    sortable: false,
    cell: row => {
      if (row.min_weight == null || row.min_weight == '') {
        return '-';
      } else {
        return row.min_weight + ' kg';
      }
    }
  },
  {
    name: 'Peso Máximo',
    selector: 'max_weight',
    sortable: false,
    cell: row => {
      if (row.max_weight == null || row.max_weight == '') {
        return '-';
      } else {
        return row.max_weight + ' kg';
      }
    }
  },
  {
    name: 'Monto',
    selector: 'total',
    sortable: false,
    cell: row => `$${parseFloat(row.amount).toFixed(2)}`
  }
];
const LoaderContainer = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  margin: 10px 0;
`;
class RatesList extends React.Component {
  state = {
    selectedConcept: [],
    rates: [],
    parcels: ['Todos', 'Chazki', 'Blueexpress', 'Shippify'],
    page: 1,
    showWaypoint: true
  };
  componentWillReceiveProps(new_Props) {
    if (new_Props.reload) {
      this.loadRates();
    }
  }

  renderParcel = () => {
    return this.state.parcels.map((type, index) => (
      <Row key={type} className="card-dropdown-option">
        <Column xs={6} fluid>
          <Text>{type}</Text>
        </Column>
      </Row>
    ));
  };
  handleConceptDrop = selected => {
    this.setState({ selectedConcept: selected });
    if (selected == 0) {
      this.setState({ showWaypoint: true });
      this.loadRates();
    } else {
      this.loadRatesWFilter(this.state.parcels[selected]);
    }
  };
  componentWillMount() {
    this.loadRates();
    if (this.props.user.customer_type == 'enterprise' || this.props.user.customer_type == 'basic' || this.props.user.customer_type == 'subsided_customer') {
      this.setState({ canCreate: true, canDelete: true, canUpdate: true });
    } else {
      this.props.user.permissions.forEach(element => {
        if (element.resource == 'rates') {
          switch (element.action) {
            case 'create':
              this.setState({ canCreate: true });
              break;
            case 'edit':
              this.setState({ canUpdate: true });
              break;
            case 'delete':
              this.setState({ canDelete: true });
              break;
            default:
              break;
          }
        }
      });
    }
  }
  _loadMore = () => {
    this.setState({ loadingMore: true });
    axios.get(`/api/rates?page=${this.state.page}`).then(r => {
      this.setState({ loadingMore: false });
      switch (r.status) {
        case 200:
          if (this.state.rates.length == r.data.length) {
            this.setState({ showWaypoint: false });
          } else {
            this.setState({ rates: r.data, page: this.state.page++ });
          }
          break;
        default:
          this.setState({
            showEmpty: true,
            showlist: false,
            showLoader: false
          });
          break;
      }
    });
  };
  loadRatesWFilter(filter) {
    this.setState({
      showEmpty: false,
      showlist: false,
      showLoader: true,
      showWaypoint: false
    });
    axios.get(`/api/rates?page=${this.state.page}&provider=${filter}`).then(r => {
      switch (r.status) {
        case 200:
          this.setState({
            showEmpty: false,
            showlist: true,
            showLoader: false
          });
          this.setState({ rates: r.data });
          break;
        default:
          this.setState({
            showEmpty: true,
            showlist: false,
            showLoader: false
          });
          break;
      }
    });
  }

  loadRates() {
    this.setState({
      showEmpty: false,
      showlist: false,
      showLoader: true,
      displaybtns: false
    });
    axios
      .get(`/api/rates?page=${this.state.page}`)
      .then(r => {
        switch (r.status) {
          case 200:
            this.props.changeStatusBtns();
            if (r.data.length > 0) {
              this.setState({
                showEmpty: false,
                showlist: true,
                showLoader: false
              });

              if (this.state.rates.length == r.data) {
                this.setState({ showWaypoint: false });
              } else {
                this.setState({ rates: r.data, page: this.state.page + 1 });
              }
            } else {
              this.setState({
                showEmpty: true,
                showlist: false,
                showLoader: false,
                showWaypoint: false
              });
            }

            break;
          default:
            this.setState({
              showEmpty: true,
              showlist: false,
              showLoader: false,
              showWaypoint: false
            });
            break;
        }
      })
      .catch(error => {
        this.props.showError(error.response.data.error);
        this.setState({
          showEmpty: false,
          showlist: false,
          showLoader: false
        });
      });
  }
  getRateDetails = rate => {
    this.props.openSideBar(rate.object_id, this.props.context);
  };

  render() {
    const {
      customer_type, plan, actions, role, operational_user
    } = this.props.user;

    const permissions = [];
    if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
      operational_user.operational_role.actions.map(element => permissions.push(element));
    }

    if (plan && plan.features) {
      plan.features.map(element => permissions.push(element));
    }
    if (actions) {
      actions.map(element => permissions.push(element));
    }
    return (
      <div>
        {this.state.showlist && (
          <div>
            <Text>Filtrar por</Text>
            <Row>
              <Column xs={12} md={3} fluid>
                <Dropdown
                  listClassName="filter-drop"
                  className="filter-dropdown"
                  placeholder={<Text className="dropdown-placeholder dp-service">Paqueteria</Text>}
                  options={this.renderParcel()}
                  handleChange={this.handleConceptDrop}
                  selected={this.state.selectedConcept}
                />
              </Column>
            </Row>
            <NewTable
              marginTop={'margin-top'}
              onRowClicked={this.getRateDetails}
              shipping={false}
              columns={columns}
              data={this.state.rates}
            />
            {this.state.loadingMore ? (
              <LoaderContainer>
                <Loader color={colors.primary.mienvio_pink} type="ball-pulse" active />
              </LoaderContainer>
            ) : (
              <div>
                {' '}
                {this.state.showWaypoint && (
                  <Waypoint key={this.state.page} onEnter={this._loadMore} />
                )}
              </div>
            )}
          </div>
        )}
        {this.state.showEmpty && (
          <EmpyState name="rates">
            <Title className="state-title">Registra una tarifa</Title>
            <Text>
              Creando tarifas podras tener opciones predefinidas con tus parametros mas usados, para
              de esta manera ahorrar tiempo en la creacion de envíos
            </Text>
            {searchPermission('tarifas', 'editar', permissions) && (
              <Button className="state-btn" onClick={this.props.addRate}>
                Agregar tarifa
              </Button>
            )}
          </EmpyState>
        )}
        {this.state.showLoader && <Skeleton count={7} height={25} />}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => bindActionCreators({ showError }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RatesList);
