import { TOGGLE_SIDEBAR, CHANGE_SIDEBAR_TITLE } from './actionsTypes';

export const toggle = (open, title = '', type = '', _callback = () => {}) => ({
  type: TOGGLE_SIDEBAR,
  payload: {
    open,
    title,
    type,
    _callback
  }
});

export const changeTitle = (title = '') => ({
  type: CHANGE_SIDEBAR_TITLE,
  payload: {
    title
  }
});
