import React from "react";
import axios from "axios";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmail from "validator/lib/isEmail";
import { boundMethod } from "autobind-decorator";
import { Row, Column } from "hedron";
import Skeleton from "react-loading-skeleton";
import origin_icon from "../../../utils/icons/origin_gray.svg";
import destination_icon from "../../../utils/icons/destination_gray.svg";
import edit_icon from "../../../utils/icons/edit.svg";
import delete_icon from "../../../utils/icons/delete.svg";
import detail_eye_icon from "../../../utils/icons/detail-eye.svg";
import addresses_icon from "../../static/Icon/addressesActive.svg";
import addresses_icon_gray from "../../static/Icon/addresses.svg";
import close_icon from "../../WrapperSideBar/img/close.svg";
import addressIcon from "./icons/addresses.svg";
import NewTable from "../../NewTable";
import { formatPhone, shorten } from "../../../utils/global_functions";
import {
  getAddresses,
  createAddress,
  editAddress,
  deleteAddress,
} from "../../../modules/marketplaceAccount/addresses";
import SidebarTitleMenu from "../../WrapperSideBar/extraComponents/SidebarTitleMenu";
import ThreeDotsDropdown from "../../Dropdown/ThreeDotsDropdown/index";
import Button from "../../Button";
import Dropdown from "../../Dropdown";
import Input, { Label, Required, HelpText } from "../../Input";
import WrapperSideBar from "../../WrapperSideBar";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../../modules/CollapsibleBar/actions";
import {
  ContentAdapter,
  BottomContainer,
} from "../../WrapperSideBar/styledObjects";
import {
  clearSidebar,
  getCountries,
} from "../../../modules/sidebarOrder/actions";
import Text, { Title } from "../../Text";
import {
  ADDRESS_DETAILS,
  NEW_ADDRESS,
  EDIT_ADDRESS,
} from "../../../modules/CollapsibleBar/sideBarTypes";

const columns = [
  {
    name: "Alias",
    selector: "alias",
    cell: (row) => shorten(row.alias, 15),
  },
  {
    name: "Nombre",
    selector: "name",
  },
  {
    name: "Correo electrónico",
    selector: "email",
    cell: (row) => shorten(row.email, 15),
  },
  {
    name: "Teléfono",
    selector: "phone",
  },
  {
    name: "Calle y número",
    selector: "street",
    cell: (row) => shorten(row.street, 15),
  },
  {
    name: "Colonia",
    selector: "street2",
    cell: (row) => shorten(row.street2, 15),
  },
  {
    name: "Ciudad y estado",
    selector: "city",
    cell: (row) => shorten(row.state, 15),
  },
  {
    name: "CP",
    selector: "zipcode",
  },
];

class Addresses extends React.Component {
  state: State = {
    selected: [],
    filter: "",
    filters: [],
    clearSelectedRows: false,
    selectedCountry: null,
    selectedCountryTxt: null,
    address: null,
    data: [],
    loading: false,
  };

  constructor(props: Props) {
    super(props);
  }

  renderCountries = () =>
    this.props.sidebarOrder.countries
      ? this.props.sidebarOrder.countries.map((country) => (
          <Text key={country.object_id}>{country.name}</Text>
        ))
      : null;

  componentWillMount() {
    this.loadAddresses();
  }

  loadAddresses() {
    if (this.props.id) {
      this.setState({ loading: true });
      getAddresses(this.props.id).then((response) => {
        // console.log(this.props.id);
        this.setState({ loading: false });
        if (response.type === "Success") {
          this.setState({ data: response.response.data });
        } else {
          this.props.notify("Error al obtener la información", "error");
        }
      });
    } else {
      this.props.closeElement();
    }
  }

  _renderSidebarContent = () => {
    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value:
          this.state[name] && name === "phone"
            ? formatPhone(this.state[name])
            : this.state[name],
        error: this.state[`${name}Error`],
      }),
    };

    const Fields = (
      <div>
        <Input
          type="text"
          {...mainProps.generate("alias")}
          required
          placeholder="Warehouse nte"
          label="Alias"
        />
        <Text type="microHeader" className="microheader">
          Datos de contacto de la dirección
        </Text>
        <Input
          type="text"
          {...mainProps.generate("name")}
          required
          placeholder="María Pérez"
          label="Nombre del titular"
        />
        <Row>
          <Column xs={6} fluid>
            <Input
              type="text"
              {...mainProps.generate("email")}
              required
              placeholder="maria@tucorreo.com"
              label="Email del titular"
            />
          </Column>
          <Column xs={6} fluid style={{ paddingLeft: "16px" }}>
            <Input
              type="text"
              {...mainProps.generate("phone")}
              required
              placeholder="(442) 123 12 45"
              label="Teléfono de contacto"
            />
          </Column>
        </Row>
        <Text type="microHeader" className="microheader">
          Detalles de dirección
        </Text>
        <Dropdown
          required
          label="País"
          options={this.renderCountries()}
          style={{ marginTop: "12px" }}
          placeholder={
            <Text className="dropdown-placeholder">Buscar por alias</Text>
          }
          selected={this.state.selectedCountry}
          handleChange={this.handleChangeDropdown}
        />
        <Row divisions={24}>
          <Column xs={10} fluid>
            <Input
              type="text"
              {...mainProps.generate("street2")}
              maxLength="35"
              required
              placeholder="Independencia"
              label="Colonia"
            />
          </Column>
          <Column xs={5} fluid style={{ paddingLeft: "16px" }}>
            <Input
              type="text"
              {...mainProps.generate("zipcode")}
              required
              disabled={!this.state.country_code}
              placeholder="76158"
              label="C.P."
            />
          </Column>
          <Column xs={9} fluid style={{ paddingLeft: "16px" }}>
            <Input
              type="text"
              disabled
              onChange={() => {}}
              required
              label="Ciudad y Estado"
              value={this.state.location}
            />
          </Column>
        </Row>
        <Input
          type="text"
          {...mainProps.generate("street")}
          maxLength="35"
          required
          placeholder="Av. Miguel Hidalgo, 876. Int. 29"
          label="Calle y número"
          help={
            this.state.streetError ? (
              <p>this.state.streetError</p>
            ) : (
              <p>
                ¿Necesitas ayuda abreviando?
                <a className="link" onClick={this.openAddressReducer}>
                  &nbsp;Presiona aquí
                </a>
              </p>
            )
          }
        />
        <Input
          type="text"
          {...mainProps.generate("reference")}
          maxLength={32}
          required
          placeholder="Casa blanca con portón negro"
          label="Referencias de la dirección"
          help={
            this.state.referenceError ? "" : "No ingreses datos de la dirección"
          }
        />
      </div>
    );
    switch (this.props.sidebar.type) {
      case ADDRESS_DETAILS:
        return (
          <div>
            <div className="gray-row">
              <img alt="" height="12px" src={detail_eye_icon} /> &nbsp;
              <Text type="label"> Detalle </Text>
            </div>
            <ContentAdapter>
              <div className="contentAdapter">
                {this.state.address &&
                  this.addressArray(this.state.address)
                    .filter((x) => x)
                    .map((item) => (
                      <Row className="detail-row">
                        <Column fluid>
                          <Text type="bodyDetail">{item}</Text>
                        </Column>
                      </Row>
                    ))}
              </div>
            </ContentAdapter>
          </div>
        );
      case NEW_ADDRESS:
        return (
          <ContentAdapter>
            {Fields}
            <div className="bottomContainer">
              <BottomContainer>
                <Row divisions={20}>
                  <Column
                    xs={9}
                    xsShift={1}
                    style={{ textAlign: "left" }}
                    fluid
                  />
                  <Column
                    xs={8}
                    xsShift={1}
                    style={{ textAlign: "right" }}
                    fluid
                  >
                    <div>
                      <Button
                        initialLoader
                        className="state-btn"
                        onClick={() => this.saveAddress("create", null)}
                      >
                        Guardar
                      </Button>
                    </div>
                  </Column>
                  <Column
                    xs={1}
                    xsShift={1}
                    style={{ textAlign: "right" }}
                    fluid
                  />
                </Row>
              </BottomContainer>
            </div>
          </ContentAdapter>
        );
      case EDIT_ADDRESS:
        return (
          <ContentAdapter>
            {Fields}
            <div className="bottomContainer">
              <BottomContainer>
                <Row divisions={20}>
                  <Column
                    xs={9}
                    xsShift={1}
                    style={{ textAlign: "left" }}
                    fluid
                  />
                  <Column
                    xs={8}
                    xsShift={1}
                    style={{ textAlign: "right" }}
                    fluid
                  >
                    <div>
                      <Button
                        initialLoader
                        className="state-btn"
                        onClick={() =>
                          this.saveAddress("edit", this.state.address.object_id)
                        }
                      >
                        Guardar
                      </Button>
                    </div>
                  </Column>
                  <Column
                    xs={1}
                    xsShift={1}
                    style={{ textAlign: "right" }}
                    fluid
                  />
                </Row>
              </BottomContainer>
            </div>
          </ContentAdapter>
        );
      default:
        return <div />;
    }
  };

  addressArray(address) {
    return [
      address.name,
      address.email,
      formatPhone(address.phone),
      address.street,
      address.street2,
      address.reference,
      `${address.city}, ${address.state} CP. ${address.zipcode}`,
    ];
  }

  onCloseSidebar = () => {
    this.props.toggleSideBar(false);
    this.setState({ address: null, hideIcon: true });
    this.setState({
      alias: null,
      name: null,
      email: null,
      phone: null,
      street: null,
      street2: null,
      reference: null,
      city: null,
      state: null,
      zipcode: null,
      selectedCountry: null,
      country_code: null,
    });
  };

  handleChangeDropdown = (value) => {
    this.setState({
      selectedCountry: value,
      country_code: this.props.sidebarOrder.countries[value].code,
    });
  };

  handleChange(value, key) {
    if (key === "zipcode" && !isNaN(value) && value.length === 5) {
      axios
        .get(`/api/zipcodes/${value}?country=${this.state.country_code}`)
        .then((response) => {
          this.setState({
            location: `${response.data.municipality}, ${response.data.state.name}`,
            zipcodeError: "",
          });
        })
        .catch(() => {
          this.setState({
            location: "",
            zipcodeError: "C.P. inválido",
          });
        });
    } else if (key === "zipcode") {
      this.setState({ location: "" });
    } else if (key === "phone") {
      value = value.replace(/\D/g, "");
      if (value.length > 10) return;
    } else if (key === "reference") {
      const referenceError =
        value.length >= 32
          ? "No escribas parte de la dirección aquí, esta información no aparecerá en la guía"
          : "";
      this.setState({ referenceError });
    }
    this.setState({ [key]: value });
  }

  saveAddress(type, id) {
    let errors = 0;
    let aliasError = "";
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let streetError = "";
    let street2Error = "";
    let referenceError = "";
    let zipcodeError = "";
    if (!this.state.alias) {
      errors++;
      aliasError = "Este campo es requerido";
    }

    if (!this.state.name) {
      errors++;
      nameError = "Este campo es requerido";
    }

    if (!this.state.email) {
      errors++;
      emailError = "Este campo es requerido";
    } else if (!isEmail(this.state.email)) {
      errors++;
      emailError = "Correo electrónico inválido";
    }

    if (isNaN(this.state.phone) || this.state.phone?.length !== 10) {
      errors++;
      phoneError = "Número de teléfono inválido";
    }

    if (!this.state.street) {
      errors++;
      streetError = "Este campo es requerido";
    } else if (this.state.street.length > 35) {
      errors++;
      streetError = "Máximo 35 caracteres";
    }

    if (!this.state.street2) {
      errors++;
      street2Error = "Este campo es requerido";
    } else if (this.state.street2.length > 35) {
      errors++;
      street2Error = "Máximo 35 caracteres";
    }

    if (this.state.reference?.length > 32) {
      errors++;
      referenceError = "Máximo 32 caracteres";
    }

    if (
      isNaN(this.state.zipcode) ||
      this.state.zipcode.length !== 5 ||
      this.state.location === ""
    ) {
      errors++;
      zipcodeError = "C.P. inválido";
    }
    this.setState({
      aliasError,
      nameError,
      emailError,
      phoneError,
      streetError,
      referenceError,
      street2Error,
      zipcodeError,
    });
    if (errors > 0) {
      return;
    }

    if (type === "create") {
      createAddress(this.state, this.props.id).then((response) => {
        this.loadAddresses();
        this.onCloseSidebar();
      });
      return;
    }

    if (type === "edit") {
      editAddress(this.state, id, this.props.id).then((response) => {
        this.loadAddresses();
        this.onCloseSidebar();
      });
    }
  }

  toggleAddAddress() {
    this.props.toggleSideBar(true, "Agregar Direccion", NEW_ADDRESS);
  }

  @boundMethod
  toggleDetail(address) {
    this.setState({ address, hideIcon: false }, () => {
      this.props.toggleSideBar(true, address.alias, ADDRESS_DETAILS);
    });
  }

  hasNextAddress = () => {
    let currentIndex = this.getCurrentAddressIndex();

    return --currentIndex >= 0;
  };

  hasPrevAddress = () => {
    let currentIndex = this.getCurrentAddressIndex();

    return ++currentIndex < this.state.data.length;
  };

  nextAddress = () => {
    let currentIndex = this.getCurrentAddressIndex();

    if (--currentIndex < 0) {
      return -1;
    }
    this.handleAddressChange(currentIndex);
  };

  prevAddress = () => {
    let currentIndex = this.getCurrentAddressIndex();

    if (++currentIndex >= this.state.data.length) {
      return -1;
    }
    this.handleAddressChange(currentIndex);
  };

  getCurrentAddressIndex = () => {
    const { addresses } = this.state.data;
    const { address } = this.state;
    const currentAddress = address;
    const currentElement = this.state.data.find(
      (element) =>
        element &&
        currentAddress &&
        element.object_id === currentAddress.object_id
    );
    return this.state.data.indexOf(currentElement);
  };

  handleAddressChange = (currentIndex) => {
    const address = this.state.data[currentIndex];

    this.setState({ address, hideIcon: false }, () => {
      this.props.toggleSideBar(true, address.alias, ADDRESS_DETAILS);
    });
  };

  toggleEditAddress = () => {
    const { address } = this.state;
    let country;
    let countryCode;
    this.props.sidebarOrder.countries.forEach((element, index) => {
      if (element.name === address.country) {
        country = index;
        countryCode = element.code;
      }
    });
    axios
      .get(`/api/zipcodes/${address.zipcode}?country=${countryCode}`)
      .then((response) => {
        this.setState({
          location: `${response.data.municipality}, ${response.data.state.name}`,
          zipcodeError: "",
        });
      })
      .catch(() => {
        this.setState({
          location: "",
          zipcodeError: "C.P. inválido",
        });
      });
    this.setState({
      alias: address.alias,
      name: address.name,
      email: address.email,
      phone: address.phone,
      street: address.street,
      street2: address.street2,
      reference: address.reference,
      city: address.city,
      state: address.state,
      zipcode: address.zipcode,
      selectedCountry: country,
      country_code: countryCode,
    });

    this.props.toggleSideBar(true, "Editar dirección", EDIT_ADDRESS);

    this.setState({ hideIcon: true });
  };

  _renderSidebarTitleExtraContent = () => {
    const addressOptions = [];

    if (this.state.canUpdate) {
      addressOptions.push({
        function: this.toggleEditAddress,
        label: "Editar dirección",
        image: edit_icon,
      });
    }
    if (this.state.canDelete) {
      addressOptions.push({
        function: this.deleteAddress,
        label: "Eliminar dirección",
        image: delete_icon,
      });
    }

    switch (this.props.sidebar.type) {
      case ADDRESS_DETAILS:
        return (
          <SidebarTitleMenu
            linksToRender={addressOptions}
            nextButton={{
              trigger: () => this.nextAddress(),
              disabled: !this.hasNextAddress(),
            }}
            prevButton={{
              trigger: () => this.prevAddress(),
              disabled: !this.hasPrevAddress(),
            }}
          />
        );
      default:
        return null;
    }
  };

  deleteAddress = () => {
    deleteAddress(this.state.address.object_id);
    this.loadAddresses();
    this.onCloseSidebar();
  };

  UNSAFE_componentWillMount() {
    this.setState({ canCreate: true, canDelete: true, canUpdate: true });
  }

  render() {
    const options = [
      {
        function: () => this.toggleAddAddress(),
        label: "Añadir Direccion",
        image: edit_icon,
      },
    ];
    return (
      <div>
        <WrapperSideBar
          handleClose={this.onCloseSidebar}
          title_more={this._renderSidebarTitleExtraContent()}
          icon={!this.state.hideIcon && addresses_icon}
        >
          {this._renderSidebarContent()}
        </WrapperSideBar>

        <div className="header-data-visualization">
          <img src={addressIcon} alt="" className="selected-icon" />
          <p className="title-header-data-visualization">Direcciones</p>
          <ThreeDotsDropdown linksToRender={options} />
          <img
            onClick={() => this.props.closeElement()}
            src={close_icon}
            alt=""
            className="close-icon"
          />
        </div>
        {this.state.loading ? (
          <div className="container-loading">
            <Skeleton height={30} count={6} />
          </div>
        ) : (
          <NewTable
            // hideForSmall={true}
            selectableRows
            shipping={false}
            columns={columns}
            data={this.state.data}
            onRowClicked={this.toggleDetail}
            noDataComponent={<div />}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  sidebarOrder: state.newShipment,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSideBar,
      changeSidebarTitle,
      getCountries,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
