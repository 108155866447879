import React, { Children } from 'react';
import PropTypes from 'prop-types';

import styledProps from 'styled-props';
import styled from 'styled-components';
import colors from '../../utils/colors';

const background = {
  default: colors.secondary.white,
  selected: colors.state.blue,
  disabled: colors.state.silver
};
const border = {
  default: colors.gray.earth_stone,
  selected: colors.state.blue,
  disabled: colors.state.silver
};
const backgroundForm = {
  default: colors.secondary.white,
  selected: colors.state.blue,
  selectedDisabled: colors.state.silver,
  disabled: colors.secondary.white
};
const borderForm = {
  default: colors.gray.earth_stone,
  selected: colors.state.blue,
  selectedDisabled: colors.state.silver,
  disabled: colors.state.silver
};
const borderFocus = {
  default: `2px solid ${colors.state.blue}`,
  selected: `2px solid ${colors.state.blue}`,
  selectedDisabled: `2px solid ${colors.state.silver}`,
  disabled: `1px solid ${colors.state.silver}`
};
const lHFocus = {
  default: '12px',
  selected: '12px',
  disabled: '14px'
};
const fontSize = {
  table: '14px',
  form: '16px'
};
const margins = {
  table: '8px 0px',
  form: '12px 0px'
};
const StyledTableCheckbox = styled.div`
  width: 14px;
  height: 14px;
  max-width: 14px;
  border-radius: 2px;
  cursor: pointer;
  max-height: 14px;
  margin: 0 8px;
  margin-bottom: -2px;
  background: ${styledProps(background, 'background')};
  border: 1px solid ${styledProps(border, 'border')};
  display: inline-block;
  box-sizing: border-box;
  color: ${colors.secondary.white};
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  &:focus,
  &:active {
    border: ${styledProps(borderFocus, 'border')};
    line-height: ${styledProps(lHFocus, 'border')};
  }
`;
StyledTableCheckbox.defaultProps = {
  border: 'default',
  background: 'default',
  borderFocus: 'default',
  lHFocus: 'default'
};

const StyledFormCheckbox = styled.div`
  width: 16px;
  height: 16px;
  max-width: 16px;
  border-radius: 1px;
  cursor: pointer;
  max-height: 16px;
  margin: 0 12px;
  margin-bottom: -3.5px;
  background: ${styledProps(backgroundForm, 'background')};
  border: 1px solid ${styledProps(borderForm, 'border')};
  display: inline-block;
  box-sizing: border-box;
  line-height: 18px;
  text-align: center;
  &:focus,
  &:active {
    border: ${styledProps(borderFocus, 'border')};
  }
  i {
    display: none;
  }
`;
StyledFormCheckbox.defaultProps = {
  border: 'default',
  background: 'default',
  borderFocus: 'default'
};

const StyledLabel = styled.label`
  color: ${colors.gray.charcoal};
  font-weight: 400;
  font-size: ${styledProps(fontSize, 'fontSize')};
  line-height: 100%;
  letter-spacing: 2.19%;
  display: inline-block;
  cursor: pointer;

  &.small {
    color: ${colors.newcolors.input} !important;
    font-size: 13px !important;
  }
`;

const CheckboxContainer = styled.div`
  cursor: pointer;
  margin: ${styledProps(margins, 'type')};

  &.no-margin-checkbox {
    margin: 0;
  }
`;

class CheckboxGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.defaultChecked ? props.defaultChecked : []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  getChildContext() {
    return {
      handleChange: this.handleChange,
      selected: this.state.selected,
      type: this.props.type
    };
  }

  handleChange(value) {
    let selected = this.state.selected;
    if (!selected.includes(value)) {
      selected.push(value);
    } else {
      let index = selected.indexOf(value);
      selected.splice(index, 1);
    }
    this.setState({ selected });
    this.props.onChange(selected);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.defaultChecked !== this.state.selected &&
      typeof nextProps.defaultChecked !== 'undefined'
    ) {
      this.setState({ selected: nextProps.defaultChecked });
    }
  }

  render() {
    return (
      <div style={this.props.style} className={this.props.className}>
        {Children.toArray(this.props.children)}
      </div>
    );
  }
}

CheckboxGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  children: (props, propName, componentName) => {
    if (props.children.length) {
      let error = false;
      props.children.forEach(child => {
        if (child.type !== Checkbox) {
          error = true;
          return;
        }
      });
      if (error) {
        return new Error('CheckboxGroup only accepts Checkbox as its children');
      }
    } else {
      if (props.children.type !== Checkbox) {
        return new Error('CheckboxGroup only accepts Checkbox as its children');
      }
    }
  }
};

CheckboxGroup.childContextTypes = {
  handleChange: PropTypes.func,
  selected: PropTypes.any,
  type: PropTypes.string
};

const Checkbox = (props, context) => (
  <CheckboxContainer
    className={props.className}
    style={props.style}
    type={context.type}
    onClick={() => (!props.disabled ? context.handleChange(props.value) : null)}
  >
    {context.type === 'table' ? (
      context.selected.includes(props.value) ? (
        <StyledTableCheckbox
          className={props.checkboxClass}
          background={props.disabled ? 'disabled' : 'selected'}
          border={props.disabled ? 'disabled' : 'selected'}
        >
          <i className="ion-checkmark" />
        </StyledTableCheckbox>
      ) : props.disabled ? (
        <StyledTableCheckbox
          className={props.checkboxClass}
          background="disabled"
          border="disabled"
        />
      ) : (
        <StyledTableCheckbox className={props.checkboxClass} />
      )
    ) : context.selected.includes(props.value) ? (
      <StyledFormCheckbox
        className={props.checkboxClass}
        background={props.disabled ? 'selectedDisabled' : 'selected'}
        border={props.disabled ? 'selectedDisabled' : 'selected'}
      />
    ) : (
      <StyledFormCheckbox
        className={props.checkboxClass}
        background={props.disabled ? 'disabled' : 'default'}
        border={props.disabled ? 'disabled' : 'default'}
      />
    )}
    {props.text && (
      <StyledLabel fontSize={context.type} style={props.styleText} className={props.labelClass}>
        {props.text}
      </StyledLabel>
    )}
  </CheckboxContainer>
);

Checkbox.propTypes = {
  text: PropTypes.string
};

Checkbox.contextTypes = {
  handleChange: PropTypes.func,
  selected: PropTypes.any,
  type: PropTypes.string
};

export { Checkbox, CheckboxGroup };
