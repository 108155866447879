// @flow
import * as React from "react";
import axios from "axios";
import { Row, Column } from "hedron";
import { boundMethod } from "autobind-decorator";
import { ToastContainer, toast } from "react-toastify";
import Menu from "../../components/Menu";
import Header from "../../components/Header";
import Text, { Title, P } from "../../components/Text";
import Button from "../../components/Button";
import Message from "../../components/Message";
import Input from "../../components/Input";
import { BottomContainer } from "../../components/WrapperSideBar/styledObjects";
import Tabs, { Tab } from "../../components/Tabs";
import WrapperSideBar from "../../components/WrapperSideBar";
import Switch from "../../components/Switch";
import ThreeDotsDropdown from "../../components/Dropdown/ThreeDotsDropdown/index";
import { NEW_INTEGRATION } from "../../modules/CollapsibleBar/sideBarTypes";

import book from "./marketplaces/book.svg";
import play from "./marketplaces/play.svg";
import {
  jsUcfirst,
  formatPhone,
  searchPermission,
} from "../../utils/global_functions";
import Marketplaces from "./marketplaces/index";
import ApiConnections from "./apiconnections/index";
import Carriers, { carriersFieldsLabels } from "./carriers/index";

import "./style.css";
import Dropdown from "../../components/Dropdown";
import editIcon from "../../utils/icons/edit.svg";

import { platformsType, carriersType } from "../../constants/customTypes";
import type { MessageType } from "../../constants/customTypes";

import { getAllServices } from "../../axios/services";
import { CheckboxGroup, Checkbox } from "../../components/Checkbox";
import { element } from "prop-types";

type Default = {
  message: MessageType,
  services: string[],
};

type User = {
  api_token: string,
  company_name: string,
  created_at: string,
  customer_type: string,
  default_package: any,
  default_storefront: any,
  default_to_address: any,
  email: string,
  first_name: string,
  fiscal_address: any,
  last_name: string,
  monthly_invoice: boolean,
  object_id: number,
  permissions: any[],
  phone: string,
  primary_address: string,
  site_url: string,
  updated_at: string,
  wallet_balance: number,
};

export type Props = {
  ...Default,
  user: User,

  changePage: () => void,
  getUserIntegrations: (type: string) => void,
  createWebhook: (url: string, newGuides, updates) => void,
  deleteWebhook: (id: number) => void,
  toggleSideBar: (value: boolean) => void,
};

type State = {
  selected: string,
  selectedPlatform: string,
  selectedPlatformItem: string,
  url: string,
  urlError: string,
  webhook: string,
  webhookError: string,
  key: string,
  keyError: string,
  name: string,
  nameError: string,
  secret: string,
  secretError: string,
  consumerKey: string,
  consumerKeyError: string,
  consumerSecret: string,
  consumerSecretError: string,
  currentTab: number,
  isCreating: false,
  carrierToView: string,
  default_address_id: any,
  default_address_idError: string,
  rateMinPrice: false,
  rateType: any,
  carriers: $Values<typeof carriersType>,
  platforms: $Values<typeof platformsType>,
  selectedRateCarrier: string,
  selectedRateService: string,
  selectedRateCarrierText: string,
  selectedRateServiceText: string,
  servicesObjects: any[],
  rateCarrierError: string,
  rateProviderError: string,
  newGuidesState: false,
  updatesState: false,
  detailOpen: false,

  servicesStrings: string[],
};

export default class IntegrationsRender extends React.Component<Props, State> {
  static defaultProps: Default = {
    message: {
      messageType: "",
      show: false,
      text: "",
    },
    servicesStrings: [],
  };

  state: State = {
    selected: null,
    selectedPlatform: "marketplace",
    selectedPlatformItem: "",
    url: "",
    urlError: "",
    webhook: "",
    webhookError: "",
    key: "",
    keyError: "",
    name: "",
    nameError: "",
    secret: "",
    secretError: "",
    consumerKey: "",
    consumerKeyError: "",
    consumerSecret: "",
    consumerSecretError: "",
    currentTab: 0,
    isCreating: false,
    carrierToView: null,
    default_address_id: null,
    default_address_idError: "",
    rateMinPrice: false,
    rateType: null,
    carriers: [
      "Estafeta",
      "RedPack",
      "Ups",
      "Fedex",
      "Chazki",
      "BlueExpress",
      "Shippify",
      "Servientrega",
      "Guatex",
      "Urbano",
      "Olva",
      "Yo Voy",
      "Puerta a Puerta",
      "Dostavista",
      "Starken",
      "Kangou",
    ],
    platforms: ["shopify", "woocommerce", "magento", "tiendanube"],
    selectedRateCarrier: "",
    selectedRateService: "",
    selectedRateCarrierText: null,
    selectedRateServiceText: null,
    servicesObjects: [],
    rateCarrierError: "",
    rateProviderError: "",
    newGuidesState: false,
    updatesState: false,
    detailOpen: false,
    returnRatesList: 1,
    servicesStrings: [],
  };

  constructor(props: Props) {
    super(props);

    const { getUserIntegrations, location, showMessage, hideMessage } =
      this.props;

    getUserIntegrations();

    if (location.search.indexOf("&status=error") > -1) {
      const marketplace = location.search.match(
        /shopify|magento|woocommerce|prestashop/
      );
      const errorMarketplace = marketplace
        ? `No se logró establecer la conexión, revisa tus credenciales de ${marketplace[0]}.`
        : "No se logró establecer la conexión, revisa tus credenciales";
      showMessage("error", errorMarketplace);
      setTimeout(hideMessage, 5000);
    }
  }

  componentWillMount() {
    const { toggleSideBar } = this.props;
    toggleSideBar(false);
    this.getServices();
  }

  componentWillReceiveProps(nextProps: Props) {
    const { message } = nextProps;
    const { getUserIntegrations, hideMessage } = this.props;
    const { selected } = this.state;

    if (message.messageType && message.messageType === "success" && selected) {
      getUserIntegrations();
      this.resetState();
      setTimeout(() => {
        hideMessage();
      }, 5000);
    }
  }

  componentDidMount() {
    getAllServices(({ data }) => {
      const { services } = data;

      if (services) {
        this.setState({
          servicesStrings: services,
        });
      }
    });
  }

  @boundMethod
  goToIntegrationsList() {
    localStorage.removeItem("shopify_store");
    window.location.reload();
  }

  @boundMethod
  cleanURLWebhook() {
    const { webhook } = this.state;
    const newWebhook = webhook.replace(
      /(http?:\/\/|https?:\/\/)?(www\.)?/,
      "https://"
    );
    this.setState({ webhook: newWebhook }, this.validateURLWebhook);
  }

  @boundMethod
  cleanURLShopify() {
    const { url } = this.state;
    let newUrl = url.replace(/(https?:\/\/)?(www\.)?/, "");
    newUrl = newUrl.replace(/\.com.*/, ".com");
    this.setState({ url: newUrl }, this.validateURLShopify);
  }

  @boundMethod
  validateURLShopify() {
    const { url } = this.state;
    const regex = /^(\w+\W*)+\.{1}myshopify.com$/;
    if (!regex.test(url)) {
      return this.setState({
        urlError:
          "La URL debe seguir el formato: mitienda.myshopify.com sin “/” al final",
      });
    }
    localStorage.removeItem("shopify_store");
    this.saveIntegration();
  }

  @boundMethod
  validateURLWebhook() {
    const { webhook } = this.state;
    const regex = new RegExp("^(http|https)://", "i");
    if (!regex.test(webhook)) {
      return this.setState({
        webhookError: "La URL debe seguir el formato: https://miurlvalida.com",
      });
    }
    this.saveIntegration();
  }

  @boundMethod
  saveCarrier() {
    let errors = 0;
    const api_keyError = "";
    let keyError = "";
    let secretError = "";
    let id_tokenError = "";
    let codigo_usuarioError = "";
    let cuenta_usuarioError = "";
    let codigo_personaError = "";
    const default_address_idError = "";
    let estafeta_customer_numberError = "";
    let estafeta_loginError = "";
    let estafeta_passwordError = "";
    let estafeta_suscriber_idError = "";
    let estafeta_frecuencias_useridError = "";
    let estafeta_costo_reexpedicionError = "";
    let estafeta_frecuencias_usernameError = "";
    let estafeta_frecuencias_passwordError = "";
    let estafeta_tracking_suscriber_idError = "";
    let estafeta_tracking_usernameError = "";
    let estafeta_tracking_passwordError = "";
    let redpack_sufixError = "";
    let redpack_prefixError = "";
    let redpack_estandar_service_idError = "";
    let redpack_express_service_idError = "";
    let redpack_userError = "";
    let chazki_api_keyError = "";
    let chazki_branch_idError = "";
    let chazki_store_idError = "";
    let ups_idError = "";
    let ups_passwordError = "";
    let ups_numero_licenciaError = "";
    let ups_numero_cuentaError = "";
    let fedex_api_keyError = "";
    let fedex_passwordError = "";
    let fedex_numero_licenciaError = "";
    let fedex_numero_medidaError = "";
    let olva_ruc_sellerError = "";
    let olva_cod_cliente_ruc_dniError = "";
    let guatex_userError = "";
    let yovoy_tokenError = "";
    let pap_userError = "";
    let pap_passwordError = "";
    let starken_centro_costo_cuenta_clienteError = "";
    let starken_clave_usuario_emisorError = "";
    let starken_dv_no_cuenta_clienteError = "";
    let starken_no_cuenta_clienteError = "";
    let starken_rut_empresa_emisoraError = "";
    let starken_rut_usuario_emisorError = "";
    let guatex_passwordError = "";
    let guatex_codigo_cobroError = "";
    let guatex_user_typeError = "";
    let servientrega_userError = "";
    let servientrega_codigo_facturacionError = "";
    let servientrega_passwordError = "";
    let urbano_userError = "";
    let urbano_passwordError = "";
    let urbano_codigo_clienteError = "";
    const urbano_id_contratoError = "";
    let provider_id = null;
    const default_address_id = null;

    this.setState({ isCreating: true });

    // 9,"Chazki"
    // 10,"Shippify"
    // 11,"BlueExpress"
    // 7,"Estafeta"
    // 1,"FedEx"
    // 8,"UPS"
    // 4,"Redpack"
    // 12,"Olva"
    // 13,"Urbano"
    // 15,"Servientrega"
    // 16,"Guatex"

    const errorMsgs = {
      required: "Este campo es requerido",
    };

    {
      /* } if (this.state.default_address_id === null) {
      errors++;
      default_address_idError = errorMsgs.required;
    } else {
      default_address_id = this.props.addresses.results[this.state.default_address_id].object_id;
    } */
    }

    let data = {};
    switch (this.state.selected) {
      case "estafeta":
        provider_id = 7;
        if (!this.state.estafeta_customer_number) {
          errors++;
          estafeta_customer_numberError = errorMsgs.required;
        } else if (!this.state.estafeta_login) {
          errors++;
          estafeta_loginError = errorMsgs.required;
        } else if (!this.state.estafeta_password) {
          errors++;
          estafeta_passwordError = errorMsgs.required;
        } else if (!this.state.estafeta_suscriber_id) {
          errors++;
          estafeta_suscriber_idError = errorMsgs.required;
        } else if (!this.state.estafeta_frecuencias_userid) {
          errors++;
          estafeta_frecuencias_useridError = errorMsgs.required;
        } else if (!this.state.estafeta_frecuencias_username) {
          errors++;
          estafeta_frecuencias_usernameError = errorMsgs.required;
        } else if (!this.state.estafeta_frecuencias_password) {
          errors++;
          estafeta_frecuencias_passwordError = errorMsgs.required;
        } else if (!this.state.estafeta_costo_reexpedicion) {
          errors++;
          estafeta_costo_reexpedicionError = errorMsgs.required;
        } else if (!this.state.estafeta_tracking_suscriber_id) {
          errors++;
          estafeta_tracking_suscriber_idError = errorMsgs.required;
        } else if (!this.state.estafeta_tracking_username) {
          errors++;
          estafeta_tracking_usernameError = errorMsgs.required;
        } else if (!this.state.estafeta_tracking_password) {
          errors++;
          estafeta_tracking_passwordError = errorMsgs.required;
        }

        data = {
          credentials: {
            estafeta_customer_number: this.state.estafeta_customer_number,
            estafeta_login: this.state.estafeta_login,
            estafeta_password: this.state.estafeta_password,
            estafeta_suscriber_id: this.state.estafeta_suscriber_id,
            estafeta_frecuencias_userid: this.state.estafeta_frecuencias_userid,
            estafeta_frecuencias_username:
              this.state.estafeta_frecuencias_username,
            estafeta_frecuencias_password:
              this.state.estafeta_frecuencias_password,
            estafeta_costo_reexpedicion: this.state.estafeta_costo_reexpedicion,
            estafeta_tracking_suscriber_id:
              this.state.estafeta_tracking_suscriber_id,
            estafeta_tracking_username: this.state.estafeta_tracking_username,
            estafeta_tracking_password: this.state.estafeta_tracking_password,
          },
        };
        break;
      case "redpack":
        provider_id = 4;
        if (!this.state.redpack_user) {
          errors++;
          redpack_userError = errorMsgs.required;
        } else if (!this.state.redpack_sufix) {
          errors++;
          redpack_sufixError = errorMsgs.required;
        } else if (!this.state.redpack_prefix) {
          errors++;
          redpack_prefixError = errorMsgs.required;
        } else if (!this.state.redpack_estandar_service_id) {
          errors++;
          redpack_estandar_service_idError = errorMsgs.required;
        } else if (!this.state.redpack_express_service_id) {
          errors++;
          redpack_express_service_idError = errorMsgs.required;
        }

        data = {
          credentials: {
            redpack_user: this.state.redpack_user,
            redpack_sufix: this.state.redpack_sufix,
            redpack_prefix: this.state.redpack_prefix,
            redpack_estandar_service_id: this.state.redpack_estandar_service_id,
            redpack_express_service_id: this.state.redpack_express_service_id,
          },
        };
        break;
      case "ups":
        provider_id = 8;
        if (!this.state.ups_id) {
          errors++;
          ups_idError = errorMsgs.required;
        } else if (!this.state.ups_password) {
          errors++;
          ups_passwordError = errorMsgs.required;
        } else if (!this.state.ups_numero_licencia) {
          errors++;
          ups_numero_licenciaError = errorMsgs.required;
        } else if (!this.state.ups_numero_cuenta) {
          errors++;
          ups_numero_cuentaError = errorMsgs.required;
        }

        data = {
          credentials: {
            ups_id: this.state.ups_id,
            ups_password: this.state.ups_password,
            ups_numero_licencia: this.state.ups_numero_licencia,
            ups_numero_cuentaError: this.state.ups_numero_cuentaError,
          },
        };
        break;
      case "fedex":
        provider_id = 1;
        if (!this.state.fedex_api_key) {
          errors++;
          fedex_api_keyError = errorMsgs.required;
        } else if (!this.state.fedex_password) {
          errors++;
          fedex_passwordError = errorMsgs.required;
        } else if (!this.state.fedex_numero_licencia) {
          errors++;
          fedex_numero_licenciaError = errorMsgs.required;
        } else if (!this.state.fedex_numero_medida) {
          errors++;
          fedex_numero_medidaError = errorMsgs.required;
        }

        data = {
          credentials: {
            fedex_api_key: this.state.fedex_api_key,
            fedex_password: this.state.fedex_password,
            fedex_numero_licencia: this.state.fedex_numero_licencia,
            fedex_numero_medida: this.state.fedex_numero_medida,
          },
        };
        break;
      case "olva":
        provider_id = 12;
        if (!this.state.olva_ruc_seller) {
          errors++;
          olva_ruc_sellerError = errorMsgs.required;
        } else if (!this.state.olva_cod_cliente_ruc_dni) {
          errors++;
          olva_cod_cliente_ruc_dniError = errorMsgs.required;
        }

        data = {
          credentials: {
            olva_ruc_seller: this.state.olva_ruc_seller,
            olva_cod_cliente_ruc_dni: this.state.olva_cod_cliente_ruc_dni,
          },
        };
        break;
      case "guatex":
        provider_id = 16;
        if (!this.state.guatex_user) {
          errors++;
          guatex_userError = errorMsgs.required;
        } else if (!this.state.guatex_password) {
          errors++;
          guatex_passwordError = errorMsgs.required;
        } else if (!this.state.guatex_codigo_cobro) {
          errors++;
          guatex_codigo_cobroError = errorMsgs.required;
        } else if (!this.state.guatex_user_type) {
          errors++;
          guatex_user_typeError = errorMsgs.required;
        }

        data = {
          credentials: {
            guatex_user: this.state.guatex_user,
            guatex_password: this.state.guatex_password,
            guatex_codigo_cobro: this.state.guatex_codigo_cobro,
            guatex_user_type: this.state.guatex_user_type,
          },
        };
        break;
      case "servientrega":
        provider_id = 15;
        if (!this.state.servientrega_user) {
          errors++;
          servientrega_userError = errorMsgs.required;
        } else if (!this.state.servientrega_password) {
          errors++;
          servientrega_passwordError = errorMsgs.required;
        } else if (!this.state.servientrega_codigo_facturacion) {
          errors++;
          servientrega_codigo_facturacionError = errorMsgs.required;
        }

        data = {
          credentials: {
            servientrega_user: this.state.servientrega_user,
            servientrega_password: this.state.servientrega_password,
            servientrega_codigo_facturacion:
              this.state.servientrega_codigo_facturacion,
          },
        };
        break;
      case "urbano":
        provider_id = 13;
        if (!this.state.urbano_user) {
          errors++;
          urbano_userError = errorMsgs.required;
        } else if (!this.state.urbano_password) {
          errors++;
          urbano_passwordError = errorMsgs.required;
        } else if (!this.state.urbano_id_contrato) {
          errors++;
          urbano_id_contratoError = errorMsgs.required;
        } else if (!this.state.urbano_codigo_cliente) {
          errors++;
          urbano_codigo_clienteError = errorMsgs.required;
        }

        data = {
          credentials: {
            urbano_user: this.state.urbano_user,
            urbano_password: this.state.urbano_password,
            urbano_id_contrato: this.state.urbano_id_contrato,
            urbano_codigo_cliente: this.state.urbano_codigo_cliente,
          },
        };
        break;
      case "chazki":
        provider_id = 9;
        if (!this.state.chazki_api_key) {
          errors++;
          chazki_api_keyError = errorMsgs.required;
        } else if (!this.state.chazki_branch_id) {
          errors++;
          chazki_branch_idError = errorMsgs.required;
        } else if (!this.state.chazki_store_id) {
          errors++;
          chazki_store_idError = errorMsgs.required;
        }

        data = {
          credentials: {
            chazki_api_key: this.state.chazki_api_key,
            chazki_branch_id: this.state.chazki_branch_id,
            chazki_store_id: this.state.chazki_store_id,
          },
        };
        break;
      case "shippify":
        provider_id = 10;
        if (!this.state.key) {
          errors++;
          keyError = errorMsgs.required;
        }
        if (!this.state.secret) {
          errors++;
          secretError = errorMsgs.required;
        }

        data = {
          credentials: {
            key: this.state.key,
            secret: this.state.secret,
          },
        };
        break;
      case "yovoy":
        provider_id = 19;
        if (!this.state.yovoy_token) {
          errors++;
          yovoy_tokenError = errorMsgs.required;
        }

        data = {
          credentials: {
            yovoy_token: this.state.yovoy_token,
          },
        };
        break;
      case "pap":
        provider_id = 18;
        if (!this.state.pap_user) {
          errors++;
          pap_userError = errorMsgs.required;
        } else if (!this.state.pap_password) {
          errors++;
          pap_passwordError = errorMsgs.required;
        }

        data = {
          credentials: {
            pap_user: this.state.pap_user,
            pap_password: this.state.pap_password,
          },
        };
        break;
      case "starken":
        provider_id = 17;
        if (!this.state.starken_centro_costo_cuenta_cliente) {
          errors++;
          starken_centro_costo_cuenta_clienteError = errorMsgs.required;
        } else if (!this.state.starken_clave_usuario_emisor) {
          errors++;
          starken_clave_usuario_emisorError = errorMsgs.required;
        } else if (!this.state.starken_dv_no_cuenta_cliente) {
          errors++;
          starken_dv_no_cuenta_clienteError = errorMsgs.required;
        } else if (!this.state.starken_no_cuenta_cliente) {
          errors++;
          starken_no_cuenta_clienteError = errorMsgs.required;
        } else if (!this.state.starken_rut_empresa_emisora) {
          errors++;
          starken_rut_empresa_emisoraError = errorMsgs.required;
        } else if (!this.state.starken_rut_usuario_emisor) {
          errors++;
          starken_rut_usuario_emisorError = errorMsgs.required;
        }

        data = {
          credentials: {
            starken_centro_costo_cuenta_cliente:
              this.state.starken_centro_costo_cuenta_cliente,
            starken_clave_usuario_emisor:
              this.state.starken_clave_usuario_emisor,
            starken_dv_no_cuenta_cliente:
              this.state.starken_dv_no_cuenta_cliente,
            starken_no_cuenta_cliente: this.state.starken_no_cuenta_cliente,
            starken_rut_empresa_emisora: this.state.starken_rut_empresa_emisora,
            starken_rut_usuario_emisor: this.state.starken_rut_usuario_emisor,
          },
        };
        break;
      case "blueexpress":
        provider_id = 11;
        if (!this.state.id_token) {
          errors++;
          id_tokenError = errorMsgs.required;
        }
        if (!this.state.codigo_usuario) {
          errors++;
          codigo_usuarioError = errorMsgs.required;
        }
        if (!this.state.cuenta_usuario) {
          errors++;
          cuenta_usuarioError = errorMsgs.required;
        }
        if (!this.state.codigo_persona) {
          errors++;
          codigo_personaError = errorMsgs.required;
        }

        data = {
          credentials: {
            id_token: this.state.id_token,
            codigo_usuario: this.state.codigo_usuario,
            cuenta_usuario: this.state.cuenta_usuario,
            codigo_persona: this.state.codigo_persona,
          },
        };
        break;
      default:
        break;
    }

    this.setState({
      api_keyError,
      keyError,
      secretError,
      id_tokenError,
      codigo_usuarioError,
      cuenta_usuarioError,
      codigo_personaError,
      default_address_idError,
      estafeta_customer_numberError,
      estafeta_loginError,
      estafeta_passwordError,
      estafeta_suscriber_idError,
      estafeta_frecuencias_useridError,
      estafeta_frecuencias_usernameError,
      estafeta_frecuencias_passwordError,
      estafeta_costo_reexpedicionError,
      estafeta_tracking_suscriber_idError,
      estafeta_tracking_usernameError,
      estafeta_tracking_passwordError,
      redpack_sufixError,
      redpack_userError,
      ups_idError,
      ups_passwordError,
      ups_numero_licenciaError,
      ups_numero_cuentaError,
      fedex_api_keyError,
      fedex_passwordError,
      fedex_numero_licenciaError,
      fedex_numero_medidaError,
      olva_cod_cliente_ruc_dniError,
      olva_ruc_sellerError,
      guatex_userError,
      guatex_passwordError,
      guatex_codigo_cobroError,
      servientrega_userError,
      servientrega_passwordError,
      servientrega_codigo_facturacionError,
    });
    if (errors > 0) {
      this.setState({ isCreating: false });
      return 0;
    }

    this.props.storeCarrierCredentials(provider_id, data).then(() => {
      this.setState({ isCreating: false });
      this.props.toggleSideBar(false);
    });
  }

  @boundMethod
  saveIntegration() {
    let errors = 0;
    let nameError = "";
    let urlError = "";
    let keyError = "";
    const secretError = "";
    const consumerKeyError = "";
    const consumerSecretError = "";
    let webhookError = "";

    this.setState({ isCreating: true });
    this.resetState();
    if (this.state.selectedPlatform === "shopify") {
      if (!this.state.url) {
        return this.setState({
          isCreating: false,
          urlError: "Este campo es requerido",
        });
      }
      const store_domain = this.state.url;
      const store_url = this.state.url;
      this.props.getShopifyOauth(store_url, store_domain);
      this.props.toggleSideBar(false);
    } else if (this.state.selectedPlatform === "woocommerce") {
      if (!this.state.url) {
        errors++;
        urlError = "Este campo es requerido";
      }
      if (!this.state.name) {
        errors++;
        nameError = "Este campo es requerido";
      }
      if (errors > 0) {
        return this.setState({
          isCreating: false,
          nameError,
          urlError,
        });
      }
      this.props.getWoocommerceOauth(this.state.url, this.state.name);
      this.props.toggleSideBar(false);
    } else if (this.state.selectedPlatform === "prestashop") {
      if (!this.state.url) {
        errors++;
        urlError = "Este campo es requerido";
      }
      if (!this.state.name) {
        errors++;
        nameError = "Este campo es requerido";
      }
      if (!this.state.key) {
        errors++;
        keyError = "Este campo es requerido";
      }
      if (errors > 0) {
        return this.setState({
          isCreating: false,
          nameError,
          urlError,
          keyError,
        });
      }
      this.props.createPrestashop(
        this.state.name,
        this.state.url,
        this.state.key
      );
      this.props.toggleSideBar(false);
    } else if (this.state.selectedPlatform === "magento") {
      if (!this.state.url) {
        errors++;
        urlError = "Este campo es requerido";
      }
      if (!this.state.name) {
        errors++;
        nameError = "Este campo es requerido";
      }
      if (!this.state.key) {
        errors++;
        keyError = "Este campo es requerido";
      }
      if (errors > 0) {
        return this.setState({
          isCreating: false,
          nameError,
          urlError,
          keyError,
        });
      }
      this.props.toggleSideBar(false);
      this.props.createMagento(this.state.name, this.state.url, this.state.key);
    } else if (this.state.selected === "webhook") {
      if (!this.state.webhook) {
        errors++;
        webhookError = "Este campo es requerido";
      }
      if (errors > 0) {
        this.setState({ webhookError });
      } else {
        this.props
          .createWebhook(
            this.state.webhook,
            this.state.newGuidesState,
            this.state.updatesState
          )
          .then(() => {
            this.props.returnIntegrations();
            this.setState({ isCreating: false });
          });
      }
    }
  }

  @boundMethod
  resetState() {
    this.setState({
      selected: null,
      selectedPlatform: "marketplace",
      selectedPlatformItem: "",
      url: "",
      urlError: "",
      name: "",
      nameError: "",
      webhookError: "",
      webhook: "",
      key: "",
      keyError: "",
      api_key: "",
      api_keyError: "",
      secret: "",
      secretError: "",
      consumerKey: "",
      consumerKeyError: "",
      consumerSecret: "",
      consumerSecretError: "",
      id_token: "",
      id_tokenError: "",
      codigo_usuario: "",
      codigo_usuarioError: "",
      codigo_persona: "",
      codigo_personaError: "",
      estafeta_customer_number: "",
      estafeta_customer_numberError: "",
      estafeta_login: "",
      estafeta_loginError: "",
      estafeta_password: "",
      estafeta_passwordError: "",
      estafeta_suscriber_id: "",
      estafeta_suscriber_idError: "",
      estafeta_frecuencias_userid: "",
      estafeta_frecuencias_useridError: "",
      estafeta_frecuencias_username: "",
      estafeta_frecuencias_usernameError: "",
      estafeta_frecuencias_password: "",
      estafeta_frecuencias_passwordError: "",
      estafeta_costo_reexpedicion: "",
      estafeta_costo_reexpedicionError: "",
      estafeta_tracking_suscriber_id: "",
      estafeta_tracking_suscriber_idError: "",
      estafeta_tracking_username: "",
      estafeta_tracking_usernameError: "",
      estafeta_tracking_password: "",
      estafeta_tracking_passwordError: "",
      redpack_user: "",
      redpack_userError: "",
      redpack_sufix: "",
      redpack_sufixError: "",
      ups_id: "",
      ups_idError: "",
      ups_password: "",
      ups_passwordError: "",
      ups_numero_licencia: "",
      ups_numero_licenciaError: "",
      ups_numero_cuenta: "",
      ups_numero_cuentaError: "",
      fedex_api_key: "",
      fedex_api_keyError: "",
      fedex_password: "",
      fedex_passwordError: "",
      fedex_numero_licencia: "",
      fedex_numero_licenciaError: "",
      fedex_numero_medida: "",
      fedex_numero_medidaError: "",
      olva_ruc_seller: "",
      olva_ruc_sellerError: "",
      olva_cod_cliente_ruc_dni: "",
      olva_cod_cliente_ruc_dniError: "",
      guatex_user: "",
      guatex_userError: "",
      guatex_password: "",
      guatex_passwordError: "",
      guatex_codigo_cobro: "",
      guatex_codigo_cobroError: "",
      servientrega_user: "",
      servientrega_userError: "",
      servientrega_password: "",
      servientrega_passwordError: "",
      servientrega_codigo_facturacion: "",
      servientrega_codigo_facturacionError: "",
      urbano_user: "",
      urbano_userError: "",
      urbano_password: "",
      urbano_passwordError: "",
      urbano_id_contrato: "",
      urbano_id_contratoError: "",
      secret: "",
      secretError: "",
      isCreating: false,
      carrierToView: null,
      shopToEditRate: null,
      default_address_id: null,
      default_address_idError: "",
      rateMinPrice: false,
      rateType: null,
      selectedRateCarrier: "",
      selectedRateService: "",
      selectedRateCarrierText: null,
      selectedRateServiceText: null,
      servicesObjects: [],
      rateCarrierError: "",
      rateProviderError: "",
      newGuidesState: false,
      updatesState: false,
      detailOpen: false,
      returnRatesList: 1,
    });
  }

  @boundMethod
  showWebhooks() {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    return this.props.webhooks.map((webhook, index) => (
      <Row key={index} style={{ marginTop: "8px", marginBottom: "8px" }}>
        <Column fluid>
          <Text type="tableText">{webhook.endpoint}</Text>

          {searchPermission("marketplaces", "eliminar", permissions) && (
            <Button
              type="nav"
              style={{ color: "#d0021b" }}
              onClick={() => this.props.deleteWebhook(webhook.object_id)}
              className="edit-btn-profile"
            >
              <i className="ion-android-cancel edit-btn-profile" />
            </Button>
          )}
        </Column>
      </Row>
    ));
  }

  @boundMethod
  renderAddressesOptions() {
    const { addresses } = this.props;
    if (addresses && addresses.results) {
      return addresses.results.map((address) => (
        <Row
          key={address.object_id}
          className="card-dropdown-option "
          style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}
        >
          <Column fluid style={{ marginBottom: "4px" }}>
            <Text className="title-selected">
              <b>{address.alias}</b>
            </Text>
          </Column>
          <Column fluid>
            <Text>{address.name}</Text>
          </Column>
          <Column fluid>
            <Text>
              {address.email}. {formatPhone(address.phone)}.
            </Text>
          </Column>
          <Column fluid>
            <Text>{address.street}</Text>
          </Column>
          {address.reference && (
            <Column fluid>
              <Text>{address.reference}</Text>
            </Column>
          )}
          <Column fluid>
            <Text>{address.street2}</Text>
          </Column>
          <Column fluid>
            <Text>C.P. {address.zipcode}</Text>
          </Column>
        </Row>
      ));
    }
    return <div />;
  }

  @boundMethod
  getServices() {
    axios.get("/api/services").then((r) => {
      const tempServices = [];
      r.data.forEach((element) => {
        if (tempServices.indexOf(element.provider) === -1) {
          tempServices.push(element.provider);
        }
      });
      this.setState({
        services: r.data,
      });
    });
  }

  @boundMethod
  validateRatesFields() {
    let flag = true;
    // if (this.state.selectedRateCarrierText) {
    //   this.setState({
    //     rateCarrierError: '',
    //   });
    // } else {
    //   flag = false;
    //   this.setState({
    //     rateCarrierError: 'Campo Obligatorio',
    //   });
    // }
    if (this.state.selectedRateServiceText) {
      this.setState({
        rateProviderError: "",
      });
    } else {
      flag = false;
      this.setState({
        rateProviderError: "Campo Obligatorio",
      });
    }
    return flag;
  }

  @boundMethod
  setShopRate() {
    if (this.state.rateMinPrice) {
      this.setState({ isCreating: true });
      axios
        .put(`/api/shops/${this.state.shopToEditRate.object_id}`, {
          default_service_level: null,
          default_provider: null,
          retrieve_all_rates: this.state.returnRatesList === 0,
        })
        .then((r) => {
          this.setState({ isCreating: false });
          switch (r.status) {
            case 200:
              this.props.getUserIntegrations();
              this.resetState();
              this.props.toggleSideBar(false);
              this.props.showMessage("success", "Tarifa asignada exitosamente");
              break;
            default:
              this.props.showMessage("error", "Error al asignar tarifa");
              break;
          }
        })
        .catch((error) => {
          this.setState({ isCreating: false });
          this.props.showMessage("error", "Error al asignar tarifa");
        });
    } else if (this.validateRatesFields()) {
      this.setState({ isCreating: true });
      axios
        .put(`/api/shops/${this.state.shopToEditRate.object_id}`, {
          default_service_level:
            this.state.servicesStrings[this.state.selectedRateService],
          default_provider: null,
          retrieve_all_rates: this.state.returnRatesList === 0,
        })
        .then((r) => {
          this.setState({ isCreating: false });
          switch (r.status) {
            case 200:
              this.props.getUserIntegrations();
              this.resetState();
              this.props.toggleSideBar(false);
              this.props.showMessage("success", "Tarifa asignada exitosamente");
              break;
            default:
              this.props.showMessage("error", "Error al asignar tarifa");
              break;
          }
        })
        .catch((error) => {
          this.setState({ isCreating: false });
          this.props.showMessage("error", "Error al asignar tarifa");
        });
    }
  }

  @boundMethod
  handleChange(value: any, key: string) {
    this.setState({ [key]: value });
  }

  @boundMethod
  handleRateOption(selected) {
    this.setState({ rateMinPrice: selected });
  }

  @boundMethod
  renderRateOptions() {
    const { servicesStrings } = this.state;

    return servicesStrings.map<React.Node>((service: string, index: number) => (
      <Row key={`service-${index}`}>
        <Column fluid style={{ marginBottom: "4px" }}>
          <Text>{service}</Text>
        </Column>
      </Row>
    ));
  }

  @boundMethod
  renderCarriersOptions() {
    return this.state.carriers.map((element, index) => (
      <Row key={index}>
        <Column fluid style={{ marginBottom: "4px" }}>
          <Text>{element}</Text>
        </Column>
      </Row>
    ));
  }

  @boundMethod
  renderPlatformsOptions() {
    const { platforms } = this.state;
    return platforms.map((element, index) => (
      <Row key={`platform-${index}`}>
        <Column fluid style={{ marginBottom: "4px" }}>
          <Text>{element}</Text>
        </Column>
      </Row>
    ));
  }

  @boundMethod
  handleAddressChange(val: number) {
    this.setState({ default_address_id: val });
  }

  @boundMethod
  handleRateCarrierChange(val) {
    const { carriers, services } = this.state;
    const servicesObjects = [];

    services.forEach((element, index) => {
      if (carriers[val].toUpperCase() === element.provider.toUpperCase()) {
        if (!servicesObjects.includes(element)) {
          servicesObjects.push(element);
        }
      }
    });

    this.setState({
      selectedRateCarrier: val,
      selectedRateCarrierText: carriers[val],
      selectedRateService: "",
      servicesObjects,
    });
  }

  @boundMethod
  handleRateServiceChange(val) {
    this.setState({
      selectedRateService: val,
      selectedRateServiceText: this.state.servicesStrings[val],
    });
  }

  @boundMethod
  handlePlatformChange(val) {
    this.setState({
      selectedPlatformItem: val,
      selectedPlatform: this.state.platforms[val],
    });
  }

  handleReturnRatesList(returnRatesList) {
    returnRatesList = this.state.returnRatesList === 0 ? 1 : 0;
    this.setState({ returnRatesList });
  }

  @boundMethod
  renderAddressSelector() {
    return (
      <Dropdown
        label="Dirección default"
        required
        handleChange={this.handleAddressChange}
        placeholder={
          <Text className="dropdown-placeholder">
            Selecciona una dirección por default
          </Text>
        }
        selected={this.state.default_address_id}
        selectedTitle={
          this.state.default_address_id !== null
            ? this.props.addresses.results[this.state.default_address_id].alias
            : null
        }
        options={this.renderAddressesOptions()}
        error={this.state.default_address_idError}
      />
    );
  }

  @boundMethod
  handleSwitchWebhookNewGuides() {
    this.setState({ newGuidesState: !this.state.newGuidesState });
  }

  @boundMethod
  handleSwitchWebhookUpdates() {
    this.setState({ updatesState: !this.state.updatesState });
  }

  @boundMethod
  showConnectService() {
    const { toggleSideBar } = this.props;

    const {
      carrierToView,
      selected,
      selectedPlatform,
      shopToEditRate,
      isCreating,
      newGuidesState,
      updatesState,
      rateMinPrice,
      selectedRateCarrier,
      rateCarrierError,
      rateProviderError,
      selectedPlatformItem,
      selectedRateService,
    } = this.state;
    let carrierKeys = [];
    let credentials: {};

    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value: this.state[name],
        error: this.state[`${name}Error`],
      }),
    };

    if (selected === "view_account") {
      credentials = JSON.parse(carrierToView.credentials);
      delete credentials.user_id;
      carrierKeys = Object.keys(credentials);
    }

    const titles = {
      shopify: "URL de tu tienda Shopify",
      woocommerce: "Conectar con Woo Commerce",
      prestashop: "Conectar con Prestashop",
      magento: "Conectar con Magento",
      webhook: "Crear un nuevo webhook",
      estafeta: "Conectar con Estafeta",
      redpack: "Conectar con RedPack",
      ups: "Conectar con UPS",
      fedex: "Conectar con Fedex",
      chazki: "Conectar con Chazki",
      shippify: "Conectar con Shippify",
      yovoy: "Conectar con Yo Voy",
      pap: "Conectar con Puerta a Puerta",
      olva: "Conectar con OLVA",
      guatex: "Conectar con Guatex",
      servientrega: "Conectar con ServiEntrega",
      starken: "Conectar con Starken",
      urbano: "Conectar con Urbano",
      blueexpress: "Conectar con BlueExpress",
      marketplace: `Conectar MarketPlace${
        selectedPlatform !== "marketplace" ? `(${selectedPlatform})` : ""
      }`,
      view_account: `Credenciales - ${
        carrierToView ? jsUcfirst(carrierToView.name) : ""
      }`,
      rate: `Tipo de Tarifa - ${
        shopToEditRate ? jsUcfirst(shopToEditRate.marketplace) : ""
      } - ${
        shopToEditRate
          ? shopToEditRate.marketplace === "API"
            ? shopToEditRate.name
            : jsUcfirst(shopToEditRate.name)
          : ""
      }`,
    };
    const integrationColors = {
      shopify: "#95BE46",
      woocommerce: "#A85693",
      tiendanube: "#249CDC",
      prestashop: "#2C0A47",
      magento: "#FF5700",
      webhook: "#FFFFFF",
      estafeta: "#FFFFFF",
      redpack: "#FFFFFF",
      ups: "#FFFFFF",
      fedex: "#FFFFFF",
      chazki: "#FFFFFF",
      shippify: "#FFFFFF",
      blueexpress: "#FFFFFF",
      view_account: "#FFFFFF",
      marketplace: "",
    };
    const articles = {
      shopify:
        "https://blogger.mienvio.mx/2021/03/como-integrar-tu-tienda-shopify-con-mienvio/",
      woocommerce:
        "https://blogger.mienvio.mx/2021/02/como-integrar-tu-tienda-woocommerce-con-mienvio/",
      tiendanube:
        "https://blogger.mienvio.mx/2021/02/como-integrar-tu-tiendanube-con-mienvio/",
      prestashop:
        "https://blogger.mienvio.mx/2021/02/como-integrar-tu-tienda-prestashop-con-mienvio/",
      magento:
        "https://blogger.mienvio.mx/2021/02/como-integrar-mi-tienda-en-linea/",
      webhook: "",
      estafeta: "",
      redpack: "",
      ups: "",
      fedex: "",
      chazki: "",
      shippify: "",
      blueexpress: "",
      view_account: "",
      rate: "",
      marketplace: "",
    };
    const tutorials = {
      shopify: "",
      woocommerce: "",
      prestashop: "",
      magento: "",
      webhook: "",
      tiendanube: "",
      estafeta: "",
      redpack: "",
      ups: "",
      fedex: "",
      chazki: "",
      shippify: "",
      blueexpress: "",
      view_account: "",
      rate: "",
      marketplace: "",
    };
    const fields = {
      shopify: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("url")}
            placeholder="MI_TIENDA.myshopify.com"
            required
            label="URL de tu tienda Shopify"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.cleanURLShopify}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      woocommerce: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("url")}
            placeholder="http://miTienda.com"
            required
            label="URL de la tienda"
          />
          <Input
            type="text"
            {...mainProps.generate("name")}
            placeholder="El nombre de mi tienda"
            required
            label="Nombre de la tienda"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveIntegration}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      tiendanube: (
        <div>
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={() =>
                window.open(
                  "https://www.tiendanube.com.mx/tienda-aplicaciones-nube/mienvio",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      prestashop: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("name")}
            placeholder="El nombre de mi tienda"
            required
            label="Nombre de la tienda"
          />
          <Input
            type="text"
            {...mainProps.generate("url")}
            placeholder="http://miTienda.com"
            required
            label="URL de la tienda"
          />
          <Input
            type="text"
            {...mainProps.generate("key")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="API key"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveIntegration}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      magento: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("name")}
            placeholder="El nombre de mi tienda"
            required
            label="Nombre de la tienda"
          />
          <Input
            type="text"
            {...mainProps.generate("url")}
            placeholder="http://miTienda.com"
            required
            label="URL de la tienda"
          />
          <Input
            type="text"
            {...mainProps.generate("key")}
            placeholder="09imadiiamsi1202"
            required
            label="API key"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveIntegration}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      webhook: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("webhook")}
            placeholder="https://ejemplowebhook.com"
            required
            label="URL"
          />
          <div className="switch-container">
            <Text>Nuevas Guias</Text>
            <div />
            <Switch
              checked={newGuidesState}
              disabled={false}
              handleChange={this.handleSwitchWebhookNewGuides}
            />
          </div>
          <div className="switch-container">
            <Text>Actualizaciones</Text>
            <div />
            <Switch
              checked={updatesState}
              disabled={false}
              handleChange={this.handleSwitchWebhookUpdates}
            />
          </div>

          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.cleanURLWebhook}
              className="create-btn"
              loading={isCreating}
            >
              Crear
            </Button>
          </BottomContainer>
        </div>
      ),
      estafeta: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("estafeta_customer_number")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="ESTAFETA_CUSTOMER_NUMBER"
          />
          <Input
            type="text"
            {...mainProps.generate("estafeta_login")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="ESTAFETA_LOGIN"
          />
          <Input
            type="text"
            {...mainProps.generate("estafeta_password")}
            placeholder="***********"
            required
            label="ESTAFETA_PASSWORD"
          />
          <Input
            type="text"
            {...mainProps.generate("estafeta_suscriber_id")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="ESTAFETA_SUSCRIBER_ID"
          />
          <Input
            type="text"
            {...mainProps.generate("estafeta_frecuencias_userid")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="ESTAFETA_FRECUENCIAS_USERID"
          />
          <Input
            type="text"
            {...mainProps.generate("estafeta_frecuencias_username")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="ESTAFETA_FRECUENCIAS_USERNAME"
          />
          <Input
            type="text"
            {...mainProps.generate("estafeta_frecuencias_password")}
            placeholder="***********"
            required
            label="ESTAFETA_FRECUENCIAS_PASSWORD"
          />
          <Input
            type="text"
            {...mainProps.generate("estafeta_costo_reexpedicion")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="ESTAFETA_COSTO_REEXPEDICION"
          />
          <Input
            type="text"
            {...mainProps.generate("estafeta_tracking_suscriber_id")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="ESTAFETA_TRACKING_SUSCRIBER_ID"
          />
          <Input
            type="text"
            {...mainProps.generate("estafeta_tracking_username")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="ESTAFETA_TRACKING_USERNAME"
          />
          <Input
            type="text"
            {...mainProps.generate("estafeta_tracking_password")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            style={{ marginBottom: "65px" }}
            label="ESTAFETA_TRACKING_PASSWORD"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      redpack: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("redpack_user")}
            placeholder="usuario"
            required
            label="USER ID"
          />
          <Input
            type="text"
            {...mainProps.generate("redpack_sufix")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="PIN SUFIX"
          />
          <Input
            type="text"
            {...mainProps.generate("redpack_prefix")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="PIN PREFIX"
          />
          <Input
            type="text"
            {...mainProps.generate("redpack_estandar_service_id")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="ESTANDAR SERVICE ID"
          />
          <Input
            type="text"
            {...mainProps.generate("redpack_express_service_id")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="EXPRESS SERVICE ID"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      ups: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("ups_id")}
            placeholder="usuario"
            required
            label="ID"
          />
          <Input
            type="password"
            {...mainProps.generate("ups_password")}
            placeholder="***********"
            required
            label="Password"
          />
          <Input
            type="text"
            {...mainProps.generate("ups_numero_licencia")}
            placeholder="usuario"
            required
            label="Número de licencia"
          />
          <Input
            type="text"
            {...mainProps.generate("ups_numero_cuenta")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Número de Cuenta"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      fedex: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("fedex_api_key")}
            placeholder="usuario"
            required
            label="Api Key"
          />
          <Input
            type="password"
            {...mainProps.generate("fedex_password")}
            placeholder="***********"
            required
            label="Password"
          />
          <Input
            type="text"
            {...mainProps.generate("fedex_numero_licencia")}
            placeholder="usuario"
            required
            label="Número de licencia"
          />
          <Input
            type="text"
            {...mainProps.generate("fedex_numero_medida")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Número de Medida"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      olva: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("olva_ruc_seller")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="OLVA_RUC_SELLER"
          />
          <Input
            type="text"
            {...mainProps.generate("olva_cod_cliente_ruc_dni")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="OLVA_COD_CLIENTE_RUC_DNI"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      guatex: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("guatex_user")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Usuario"
          />
          <Input
            type="text"
            {...mainProps.generate("guatex_user_type")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Tipo Usuario"
          />
          <Input
            type="pasword"
            {...mainProps.generate("guatex_password")}
            placeholder="*********"
            required
            label="Password"
          />
          <Input
            type="text"
            {...mainProps.generate("guatex_codigo_cobro")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Codigo de Cobro"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      servientrega: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("servientrega_user")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Usuario"
          />
          <Input
            type="password"
            {...mainProps.generate("servientrega_password")}
            placeholder="*********"
            required
            label="Password"
          />
          <Input
            type="text"
            {...mainProps.generate("servientrega_codigo_facturacion")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Codigo de Facturacion"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      urbano: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("urbano_user")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Usuario"
          />
          <Input
            type="password"
            {...mainProps.generate("urbano_password")}
            placeholder="*********"
            required
            label="Password"
          />
          <Input
            type="text"
            {...mainProps.generate("urbano_id_contrato")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Id de Contrato"
          />
          <Input
            type="text"
            {...mainProps.generate("urbano_codigo_cliente")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Codigo de Cliente"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      chazki: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("chazki_api_key")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="API key"
          />
          <Input
            type="text"
            {...mainProps.generate("chazki_store_id")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="STORE ID"
          />
          <Input
            type="text"
            {...mainProps.generate("chazki_branch_id")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="BRANCH ID"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      shippify: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("key")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Key"
          />
          <Input
            {...mainProps.generate("secret")}
            placeholder="••••••••"
            required
            label="Secret"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      yovoy: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("yovoy_token")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Key"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      pap: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("pap_user")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Usuario"
          />
          <Input
            type="password"
            {...mainProps.generate("pap_password")}
            placeholder="*********"
            required
            label="Password"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      starken: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("starken_rut_empresa_emisora")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Rut Empresa Emisora"
          />
          <Input
            type="text"
            {...mainProps.generate("starken_rut_usuario_emisor")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Rut Usuario Emisor"
          />
          <Input
            type="text"
            {...mainProps.generate("starken_clave_usuario_emisor")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Clave Usuario Emisor"
          />
          <Input
            type="text"
            {...mainProps.generate("starken_no_cuenta_cliente")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="No Cuenta Cliente"
          />
          <Input
            type="text"
            {...mainProps.generate("starken_dv_no_cuenta_cliente")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="DV No Cuenta Cliente"
          />
          <Input
            type="text"
            {...mainProps.generate("starken_centro_costo_cuenta_cliente")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Centro Costo Cuenta Cliente"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      blueexpress: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("id_token")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="ID Token"
          />
          <Input
            type="text"
            {...mainProps.generate("codigo_usuario")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="Código de usuario"
          />
          <Input
            type="text"
            {...mainProps.generate("cuenta_usuario")}
            placeholder="usuario@blueexpress.com"
            required
            label="Cuenta de Usuario"
          />
          <Input
            type="text"
            {...mainProps.generate("codigo_persona")}
            placeholder="ZXVF4A"
            required
            label="Código de Persona"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveCarrier}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      view_account: (
        <div>
          <Row>
            {carrierKeys.length > 0 &&
              carrierKeys.map((key) => (
                <Column fluid className="credentials-info">
                  <Text type="sidebarInfoTitle">
                    {carriersFieldsLabels[key]}
                  </Text>
                  <P>{credentials[key]}</P>
                </Column>
              ))}
          </Row>
        </div>
      ),
      rate: (
        <div>
          <Row>
            <Column fluid className="credentials-info">
              <div
                onClick={() => this.handleRateOption(false)}
                className="radio-container"
              >
                <div className={`radio-oval ${!rateMinPrice && "selected"}`} />
                <p className="radio-text">
                  Cotización basada en tipo de servicio
                </p>
              </div>
              {!rateMinPrice && (
                <div>
                  <Dropdown
                    style={{ display: "none" }}
                    label="Paqueteria"
                    handleChange={this.handleRateCarrierChange}
                    placeholder={
                      <Text className="dropdown-placeholder">Paqueteria</Text>
                    }
                    selected={selectedRateCarrier}
                    selectedTitle=""
                    options={this.renderCarriersOptions()}
                    error={rateCarrierError}
                  />
                  <Dropdown
                    down
                    label="Tipo de Servicio"
                    handleChange={this.handleRateServiceChange}
                    placeholder={
                      <Text className="dropdown-placeholder">
                        Tipo de Servicio
                      </Text>
                    }
                    selected={selectedRateService}
                    selectedTitle=""
                    options={this.renderRateOptions()}
                    error={rateProviderError}
                  />
                </div>
              )}
              <div
                onClick={() => this.handleRateOption(true)}
                className="radio-container"
              >
                <div className={`radio-oval ${rateMinPrice && "selected"}`} />
                <p className="radio-text">
                  Cotización basada en el precio más bajo
                </p>
              </div>
              <div className="check-container-integrations">
                <CheckboxGroup
                  type="table"
                  onChange={(selected) => this.handleReturnRatesList(selected)}
                >
                  <Checkbox
                    styleText={{
                      fontSize: "13px",
                      fontWeight: "bold",
                      color: "#858D9D",
                    }}
                    style={{ marginTop: "0px" }}
                    checkboxClass="no-margin-left"
                    value="1"
                    text="Devolver lista de tarifas"
                  />
                </CheckboxGroup>
              </div>
            </Column>
          </Row>
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={() => this.setShopRate()}
              className="create-btn"
              loading={isCreating}
            >
              Guardar
            </Button>
          </BottomContainer>
        </div>
      ),
    };

    toggleSideBar(true, titles[selected], NEW_INTEGRATION);
    return (
      <div className="integrations-container">
        <div
          className="marketplace-color"
          style={{ backgroundColor: integrationColors[selectedPlatform] }}
        />
        {selected === "marketplace" && (
          <Column fluid>
            <Dropdown
              label="Plataforma"
              handleChange={this.handlePlatformChange}
              placeholder={
                <Text className="dropdown-placeholder">Plataforma</Text>
              }
              selected={selectedPlatformItem}
              selectedTitle=""
              options={this.renderPlatformsOptions()}
              error={rateCarrierError}
            />
          </Column>
        )}

        {(tutorials[selectedPlatform] !== "" ||
          articles[selectedPlatform] !== "") && (
          <Column fluid>
            <Text type="microHeader">
              ¿Necesitas ayuda para conectar tu tienda?
            </Text>
          </Column>
        )}

        {articles[selectedPlatform] !== "" && (
          <Column fluid>
            <a
              href={articles[selectedPlatform]}
              target="blank"
              className="help-link"
            >
              <Button type="link">
                <img src={book} /> Artículo de ayuda
              </Button>
            </a>
          </Column>
        )}

        {tutorials[selectedPlatform] !== "" && (
          <Column fluid>
            <a
              href={tutorials[selectedPlatform]}
              target="blank"
              className="help-link"
            >
              <Button type="link">
                <img src={play} /> Videotutorial
              </Button>
            </a>
          </Column>
        )}
        <Column fluid className="fields-container">
          {fields[selectedPlatform]}
        </Column>
        <Column fluid className="fields-container">
          {fields[selected]}
        </Column>
      </div>
    );
  }

  @boundMethod
  renderSidebarTitleExtraContent() {
    const { carrierToView, detailOpen } = this.state;
    const opt = [
      {
        function: () => {
          const obj = JSON.parse(carrierToView.credentials);
          switch (carrierToView.name.toLowerCase()) {
            case "chazki":
              this.setState({
                selected: carrierToView.name.toLowerCase(),
                detailOpen: false,
                api_key: obj.api_key,
              });
              break;
            case "shippify":
              this.setState({
                selected: carrierToView.name.toLowerCase(),
                detailOpen: false,
                key: obj.api_key,
              });
              break;
            case "blueexpress":
              this.setState({
                selected: carrierToView.name.toLowerCase(),
                detailOpen: false,
                id_token: obj.api_key,
              });
              break;
            default:
              break;
          }
        },
        label: "Editar paqueteria",
        image: editIcon,
      },
    ];
    if (detailOpen) {
      return <ThreeDotsDropdown linksToRender={opt} />;
    }
    return <div />;
  }

  @boundMethod
  showCarrier(carrier) {
    if (carrier) {
      delete carrier.credentials.user_id;
      this.setState({
        ...this.state,
        carrierToView: carrier,
        selected: "view_account",
        detailOpen: true,
      });
    }
  }

  @boundMethod
  showRate(shop) {
    if (shop) {
      this.setState({
        shopToEditRate: shop,
        selected: "rate",
      });
    }
  }

  @boundMethod
  toggleCarrierStatus(carrier_id, status) {
    const { toggleCarrierStatus } = this.props;
    toggleCarrierStatus(carrier_id, status);
  }

  render() {
    const {
      message,
      hideMessage,
      carriers,
      shops,
      user,

      deleteCarrier,
      deleteShop,
      storeCarrierCredentials
    } = this.props;

    const { selected, currentTab } = this.state;
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }

    // FIXME: This code doesn't do anything. Why?
    // let shops = {};
    // if (propsShops && propsShops.shops) {
    //   propsShops.shops.forEach(shop => {
    //     shops[propsShops.marketplace] = shop;
    //   });
    // }

    if (
      message.show &&
      (message.messageType === "error" || message.messageType === "success")
    ) {
      setTimeout(hideMessage, 5000);
    }

    return (
      <div>
        <ToastContainer />
        <WrapperSideBar
          handleClose={this.resetState}
          isIntegration
          title_more={this.renderSidebarTitleExtraContent()}
        >
          {selected !== null ? this.showConnectService() : null}
        </WrapperSideBar>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: "right" }} fluid>
            <Menu />
          </Column>
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Header style={{ height: "auto" }}>
              <Row divisions={18}>
                <Column xs={18} md={10} fluid>
                  <Title type="titleCard">Integraciones</Title>
                  {message.show ? (
                    <Message
                      style={{ marginTop: "20px" }}
                      color={message.messageType}
                      onClose={hideMessage}
                    >
                      {message.text}
                    </Message>
                  ) : null}
                  <Text style={{ marginTop: "4px" }} type="helpTextModal">
                    Revisa y activa nuestras integraciones con marketplaces
                    específicos. Así puedes exportar todas tus órdenes y comprar
                    tus guías de envío de manera más eficiente y rápida.
                  </Text>
                </Column>
              </Row>
            </Header>
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} md={13} fluid>
            <Tabs
              id="integrations"
              right
              selected={currentTab}
              onChange={(selected) => this.setState({ currentTab: selected })}
            >
              <Tab>Marketplaces</Tab>
              <Tab>API</Tab>
              {searchPermission("paqueterias", "leer", permissions) ? (
                <Tab>Paqueterías</Tab>
              ) : (
                <div />
              )}
            </Tabs>
            <div>
              {currentTab === 0 && shops && (
                <Marketplaces
                  shops={shops.shops}
                  user={this.props.user}
                  handleSelect={(selection) =>
                    this.setState({ selected: selection })
                  }
                  deleteShop={(id: number) => {
                    deleteShop(id);
                  }}
                  showRate={this.showRate}
                />
              )}

              {currentTab === 1 && (
                <ApiConnections
                  showRate={this.showRate}
                  handleSelect={(selection) =>
                    this.setState({ selected: selection })
                  }
                  showWebhooks={this.showWebhooks}
                  {...this.props}
                />
              )}

              {currentTab === 2 && (
                <Carriers
                  carriers={carriers}
                  handleSelect={(selection) =>
                    this.setState({ selected: selection })
                  }
                  deleteCarrier={(id: number) => {
                    deleteCarrier(id);
                  }}
                  showCarrier={this.showCarrier}
                  changeStatus={this.toggleCarrierStatus}
                  storeCarrierCredentials={(provider,data)=>{
                    storeCarrierCredentials(provider,data)
                  }}
                />
              )}
            </div>
          </Column>
          <Column xs={1} md={6} fluid />
        </Row>
      </div>
    );
  }
}
