import React from 'react';
import styledProps from 'styled-props';
import styled from 'styled-components';
import Text from '../Text';

export const StyledTd = styled.td`
  padding-left: 5px;
  border-bottom: none !important;
  input {
    border: 0;
    outline: 0;
    border-bottom: 1px solid #cad2df;
  }
`;

const StyledTh = styled.th`
  padding: 10px 0;
  text-align: left;
`;

export const ThPack = props => (
  <StyledTh textAlign={props.textAlign} colSpan={props.colSpan}>
    {props.checkbox ? (
      props.children
    ) : (
      <Text type="label" className={props.pClassName}>
        {props.children}
      </Text>
    )}
  </StyledTh>
);
