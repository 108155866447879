import React from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import axios from "axios";

import Card, { Divider } from "../../../components/Card";
import Table, { Th, Tr, Td } from "../../../components/Table";
import Button from "../../../components/Button";
import Text from "../../../components/Text";

import fedex from "./fedex.png";
import estafeta from "./estafeta.png";
import ups from "./ups.png";
import redpack from "./redpack.png";
import chazki from "./chazki.png";
import blueexpress from "./blueexpress.png";
import shippify from "./shippify.png";
import olva from "./olva.svg";
import guatex from "./guatex.svg";
import servientrega from "./servientrega.svg";
import yovoy from "./yovoy.svg";
import pap from "./pap.svg";
import costa_rica from "./correosCR.png";
import bicimensajero from "./bicimensajero.png";
import urbano from "./urbano.svg";
import starken from "./starken.svg";
import kangou from "./kangou.png";
import dostavista from "./dostavista.svg";
import flechaamarilla from "./flecha-amarilla.png";
import esameday from "./esameday.jpeg";
import servientregapa from "./servientrega.svg";
import paquetexpress from "./paqueteexpress.svg";
import laterminal from "./laterminal.png";
import savarexpress from "./savarexpress.png";
import uenvios from "./uenvios.png";
import fruno from "./fruno.png";
import urbanochile from "./urbano.svg";
import minutos from "./minutos.png";

import Switch from "../../../components/Switch";
import { Checkbox, CheckboxGroup } from "../../../components/Checkbox";

const carrierIcons = {
  fedex,
  estafeta,
  paquetexpress,
  ups,
  redpack,
  chazki,
  blueexpress,
  shippify,
  olva,
  servientrega,
  urbano,
  guatex,
  yovoy,
  pap,
  dostavista,
  starken,
  kangou,
  bicimensajero,
  costa_rica,
  flechaamarilla,
  esameday,
  servientregapa,
  urbanochile,
  minutos,
  laterminal,
  savarexpress,
  uenvios,
  fruno,
};

const StyledConnected = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  display: inline-block;
  background: #00ee7a;
  margin-left: 6px;
`;

const StyledDisconnected = StyledConnected.extend`
  background: #d8d8d8;
`;

class Carrier extends React.Component {
  state = {
    active: false,
    selectedStandar: [],
    selectedExpress: [],
    loaderEstandar: false,
    loaderExpress: false,
  };

  componentDidMount() {
    this.updateStatus();
  }

  componentWillReceiveProps() {
    this.updateStatus();
  }

  updateStatus = () => {
    if (this.props.carrier) {
      if (this.props.carrier.estandar) {
        this.setState({
          selectedStandar: [""],
        });
      }
      if (this.props.carrier.express) {
        this.setState({
          selectedExpress: [""],
        });
      }
      this.setState({
        active: this.props.carrier.active,
        isLoading: false,
      });
    }
  };

  handleStatusChange = () => {
    this.setState(
      {
        active: !this.state.active,
        isLoading: true,
      },
      () => {
        this.props.functions.changeStatus(
          this.props.carrier.id,
          this.state.active
        );
      }
    );
  };

  updateServiceStatus(id, service, active) {
    axios
      .put(`api/users/carriers/${id}/servicelevel/${service}`, { active })
      .then((response) => {
        if (service === "estandar") {
          this.setState({ loaderEstandar: false });
        } else {
          this.setState({ loaderExpress: false });
        }
      });
  }

  render() {
    return (
      <Tr>
        <Td>
          <img
            src={carrierIcons[this.props.icon]}
            alt={this.props.carrierName}
            style={{ maxHeight: "24px" }}
          />
          {this.props.icon === "servientregapa" && (
            <Text type="microHeader" style={{ marginTop: 0, display: "block" }}>
              Panamá
            </Text>
          )}
          {this.props.icon === "urbanochile" && (
            <Text type="microHeader" style={{ marginTop: 0, display: "block" }}>
              Chile
            </Text>
          )}
        </Td>
        <Td>
          {this.props.carrier
            ? this.props.carrier.name === "FedEx"
              ? "FDX"
              : this.props.carrier.name
            : "-"}
        </Td>
        <Td>
          {this.props.carrier ? (
            <div>
              <StyledConnected /> Conectado
            </div>
          ) : (
            <div>
              <StyledDisconnected /> Sin conexión
            </div>
          )}
        </Td>
        <Td style={{ paddingTop: ".5rem" }}>
          {this.state.isLoading ? (
            <Loader
              className="loader-filters"
              type="Oval"
              color="#2576DA"
              height="16"
              width="16"
            />
          ) : (
            <Switch
              checked={!this.props.carrier ? false : this.state.active}
              disabled={!this.props.carrier}
              handleChange={this.handleStatusChange}
            />
          )}
        </Td>
        <Td>
          {this.props.carrier ? (
            <Button
              type="link"
              onClick={() =>
                this.props.functions.deleteCarrier(this.props.carrier.id)
              }
            >
              Desconectar
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() => this.props.functions.storeCarrierCredentials(this.props.provider.id,{'credentials':{'provider':this.props.provider.name}})}
            >
              Conectar
            </Button>
          )}
        </Td>
        <Td style={{ paddingTop: ".5rem" }}>
          {this.state.loaderEstandar ? (
            <Loader
              className="loader-filters"
              type="Oval"
              color="#2576DA"
              height="16"
              width="16"
            />
          ) : (
            <CheckboxGroup
              defaultChecked={this.state.selectedStandar}
              type="table"
              onChange={(value) => {
                this.setState({ loaderEstandar: true });
                this.updateServiceStatus(
                  this.props.provider.id,
                  "estandar",
                  this.state.selectedStandar.length === 1
                );
                this.setState({ selectedStandar: value });
              }}
            >
              <Checkbox value="" />
            </CheckboxGroup>
          )}
        </Td>
        <Td style={{ paddingTop: ".5rem" }}>
          {this.state.loaderExpress ? (
            <Loader
              className="loader-filters"
              type="Oval"
              color="#2576DA"
              height="16"
              width="16"
            />
          ) : (
            <CheckboxGroup
              defaultChecked={this.state.selectedExpress}
              type="table"
              onChange={(value) => {
                this.setState({ loaderExpress: true });
                this.updateServiceStatus(
                  this.props.provider.id,
                  "express",
                  this.state.selectedExpress.length === 1
                );
                this.setState({ selectedExpress: value });
              }}
            >
              <Checkbox value="" />
            </CheckboxGroup>
          )}
        </Td>
      </Tr>
    );
  }
}
export default Carrier;
