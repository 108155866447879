import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Column } from 'hedron';
import { push } from 'react-router-redux';
import ReactTooltip from 'react-tooltip';

import { getUserInvoices, createInvoice, resendInvoice } from '../../modules/Invoices/actions';

import Header from '../Header';
import Text, { Title } from '../Text';
import Button from '../Button';
import Card, { Divider } from '../Card';
import { Action, Nav } from '../Modal';
import Table, { Th, Tr, Td } from '../Table';
import Moment from 'react-moment';
import 'moment-timezone';

import location from './location.png';

const NavExtended = Nav.extend`
  box-shadow: none;
`;

const ActionExtended = Action.extend`
  left: 0;
`;

class InvoiceDetail extends React.Component {
  constructor(props) {
    super(props);
    let id = props.match.params.id;
    this.downloadInvoice = this.downloadInvoice.bind(this);
    this.formatPhone = this.formatPhone.bind(this);
    this.generateTable = this.generateTable.bind(this);
    this.totalIva = this.totalIva.bind(this);
    this.generateInvoice = this.generateInvoice.bind(this);
    this.subtotal = this.subtotal.bind(this);
    if (!this.props.invoices.results) {
      this.state = {
        next: null,
        id,
        previous: null,
        invoice: null
      };
      this.props.getUserInvoices('false');
      return;
    }
    let results = this.props.invoices.results;
    let invoice = null;
    let index = 0;
    results.forEach(a => {
      if (a.object_id.toString() === id) {
        invoice = a;
        return;
      }
      index++;
    });
    let previous = null;
    let next = null;
    if (index > 0) {
      previous = results[index - 1].object_id;
    }
    if (index < results.length - 1) {
      next = results[index + 1].object_id;
    }
    this.state = {
      previous,
      next,
      id,
      invoice
    };
  }

  formatPhone(phone) {
    const mask = '(XXX) XXX XX XX';
    let s = `${phone}`;
    let r = '';
    for (let i = 0, is = 0; i < mask.length && is < s.length; i++) {
      r += mask.charAt(i) === 'X' ? s.charAt(is++) : mask.charAt(i);
    }
    return r;
  }

  totalIva(invoice) {
    return ((invoice.amount / 1.16) * 0.16).toFixed(2);
  }

  subtotal(invoice) {
    return (invoice.amount / 1.16).toFixed(2);
  }

  generateInvoice(purchaseId) {
    const data = {
      name: this.props.user.fiscal_address.name,
      email: this.props.user.email,
      phone: this.props.user.fiscal_address.phone,
      rfc: this.props.user.fiscal_address.rfc
    };
    this.props.createInvoice(data, purchaseId);
  }

  generateTable(invoices) {
    return invoices.map((invoice, index) => {
      const unitPrice = (invoice.rate.amount / 1.16).toFixed(2);
      return (
        <Tr key={invoice.object_id}>
          <Td>1</Td>
          <Td>No Aplica</Td>
          <Td>#{invoice.object_id}</Td>
          <Td noFormat>
            {invoice.rate.provider +
              ' (' +
              invoice.rate.duration_terms +
              ') ' +
              invoice.weight +
              'kg'}
          </Td>
          <Td>${unitPrice}</Td>
          <Td>${unitPrice}</Td>
        </Tr>
      );
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.invoices.results && !this.props.invoices.results) {
      let results = nextProps.invoices.results;
      let index = 0;
      let invoice = null;
      results.forEach(a => {
        if (a.object_id.toString() === this.state.id) {
          invoice = a;
          return;
        }
        index++;
      });
      let previous = null;
      let next = null;
      if (index > 0) {
        previous = results[index - 1].object_id;
      }
      if (index < results.length - 1) {
        next = results[index + 1].object_id;
      }
      this.setState({
        next,
        previous,
        invoice
      });
    }
  }

  downloadInvoice(invoiceId) {
    this.props.resendInvoice(invoiceId);
  }

  render() {
    const currDate = new Date();
    const invoiceDate = new Date(this.state.invoice.created_at);
    let invoiceText = '';
    // if invoiced
    if (this.state.invoice.invoice_id) {
      invoiceText = (
        <div>
          <Button
            tip={true}
            forr={'no-inoice' + this.state.invoice.object_id}
            className="address-delete"
            type="link"
            onClick={() => this.downloadInvoice(this.state.invoice.invoice_id)}
          >
            Reenviar factura
          </Button>
          <ReactTooltip id={'no-inoice' + this.state.invoice.object_id}>
            <span>La factura será enviada al correo con el que te registraste</span>
          </ReactTooltip>
        </div>
      );
    }
    // if not invoices and date is not valid
    else if (currDate.getMonth() != invoiceDate.getMonth() && !this.state.invoice.invoice_id) {
      invoiceText = (
        <div className="address-delete">
          <a
            data-tip
            data-for={'no-inoice' + this.state.invoice.object_id}
            className="ion-close-circled"
          />
          <ReactTooltip id={'no-inoice' + this.state.invoice.object_id}>
            <span>Período de facturación para esta compra terminado</span>
          </ReactTooltip>
        </div>
      );
    }
    // valid date & not invoiced
    else {
      invoiceText = (
        <div>
          <Button
            tip={true}
            forr={'no-inoice' + this.state.invoice.object_id}
            className="address-delete"
            type="link"
            onClick={() => this.generateInvoice(this.state.invoice.object_id)}
          >
            Generar factura
          </Button>
          <ReactTooltip id={'no-inoice' + this.state.invoice.object_id}>
            <span>La factura será enviada al correo con el que te registraste</span>
          </ReactTooltip>
        </div>
      );
    }

    return (
      <div>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={12} fluid>
            <Header>
              <NavExtended>
                <ActionExtended
                  onClick={() =>
                    this.props.goToInvoiceList(this.state.invoice.invoice_id ? 'true' : 'false')
                  }
                >
                  <i className="ion-arrow-left-b" />
                  <Title>Volver a facturas</Title>
                </ActionExtended>
              </NavExtended>
            </Header>
          </Column>
          <Column xs={7} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={12} fluid>
            <Card size="full" className="table-card">
              <Text type="helpTextModal">Id de compra</Text>
              {invoiceText}
              <Title className="address-alias-title" type="titleCard">
                <img alt="" style={{ marginRight: '8px' }} width="19" src={location} />
                {this.state.invoice ? this.state.invoice.object_id : ''}
              </Title>
              <Row>
                <Column xs={6} fluid>
                  <Text type="labelAuxiliar">Factura</Text>
                  <br />
                  <Text>{this.state.invoice.invoice_id}</Text>
                </Column>
                <Column xs={3} fluid>
                  <Text type="labelAuxiliar">Fecha de emisión</Text>
                  <Text>
                    <Moment date={this.state.invoice.invoiced_at} format="DD-MMM-YY" local={'es'} />
                  </Text>
                </Column>
                <Column xs={3} fluid>
                  <Text type="labelAuxiliar">Lugar de emisión</Text>
                  <Text>Querétaro, México</Text>
                </Column>
              </Row>
              <Divider />
              <Row>
                <Column fluid>
                  <Text type="label">Emisor</Text>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid xs={6}>
                  <Text>MIENVIO DE MÉXICO SAPI DE CV</Text>
                </Column>
                <Column fluid xs={6}>
                  <Text>Del Chabacano 21, Álamos 1a sección</Text>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid xs={6}>
                  <Text>MME150814130</Text>
                </Column>
                <Column fluid xs={6}>
                  <Text>Querétaro, Querétaro, México</Text>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid xs={6}>
                  <Text>Régimen General de Ley Personas Morales</Text>
                </Column>
                <Column fluid xs={6}>
                  <Text>CP. 76160</Text>
                </Column>
              </Row>
              <Divider />
              <Row>
                <Column fluid>
                  <Text type="label">Receptor</Text>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid xs={6}>
                  <Text>{this.state.invoice ? this.props.user.fiscal_address.name : ''}</Text>
                </Column>
                <Column fluid xs={6}>
                  <Text>{this.state.invoice ? this.props.user.fiscal_address.street : ''}</Text>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid xs={6}>
                  <Text>
                    {this.state.invoice
                      ? this.props.user.fiscal_address.rfc !== ''
                        ? this.props.user.fiscal_address.rfc
                        : ''
                      : ''}
                  </Text>
                </Column>
                <Column fluid xs={6}>
                  <Text>{this.state.invoice ? this.props.user.fiscal_address.street2 : ''}</Text>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid xs={6}>
                  <Text>
                    {this.state.invoice
                      ? this.props.user.email !== ''
                        ? this.props.user.email
                        : ''
                      : ''}
                  </Text>
                </Column>
                <Column fluid xs={6}>
                  <Text>
                    {this.state.invoice
                      ? this.props.user.fiscal_address
                        ? this.props.user.fiscal_address.state + ', México'
                        : ''
                      : ''}
                  </Text>
                </Column>
              </Row>
              <Row className="detail-row">
                <Column fluid xs={6}>
                  <Text>
                    {this.state.invoice
                      ? this.formatPhone(this.props.user.fiscal_address.phone)
                      : ''}
                  </Text>
                </Column>
                <Column fluid xs={6}>
                  <Text>
                    C.P. {this.state.invoice ? this.props.user.fiscal_address.zipcode : ''}
                  </Text>
                </Column>
              </Row>
              <Divider />
              <Table size="full">
                <thead>
                  <Tr noBorder>
                    <Th>Cantidad</Th>
                    <Th>Unidad</Th>
                    <Th>ID de envío</Th>
                    <Th>Descripción</Th>
                    <Th>Precio Unitario</Th>
                    <Th>Total</Th>
                  </Tr>
                </thead>
                <tbody>{this.generateTable(this.state.invoice.shipments)}</tbody>
              </Table>
              <Row divisions={13}>
                <Column fluid xs={9} />
                <Column fluid xs={2} style={{ textAlign: 'left' }}>
                  <Text type="label">Subtotal</Text>
                </Column>
                <Column fluid xs={2} style={{ textAlign: 'right' }}>
                  <Text>${this.subtotal(this.state.invoice)}</Text>
                </Column>
                <Column fluid xs={9} />
                <Column fluid xs={2} style={{ textAlign: 'left' }}>
                  <Text type="label">IVA (16%)</Text>
                </Column>
                <Column fluid xs={2} style={{ textAlign: 'right' }}>
                  <Text>${this.totalIva(this.state.invoice)}</Text>
                </Column>
                <Column fluid xs={9} />
                <Column fluid xs={2} style={{ textAlign: 'left' }}>
                  <Text type="label">Total</Text>
                </Column>
                <Column fluid xs={2} style={{ textAlign: 'right' }}>
                  <Text>${this.state.invoice.amount.toFixed(2)}</Text>
                </Column>
                <Column fluid xs={9} />
                <Column fluid xs={3} style={{ textAlign: 'left' }}>
                  <Text type="label">Método de pago</Text>
                </Column>
                <Column fluid xs={1} style={{ textAlign: 'right' }}>
                  <Text>
                    {this.state.invoice.payment_provider
                      ? this.state.invoice.payment_provider === 'stripe'
                        ? 'TC'
                        : 'Paypal'
                      : ''}
                  </Text>
                </Column>
              </Row>
            </Card>
          </Column>
          <Column xs={7} fluid />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  invoices: state.invoices.invoices,
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserInvoices,
      createInvoice,
      resendInvoice,
      goToInvoiceList: () => push('/facturas')
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceDetail);
