import React from "react";
import styled from "styled-components";
import styledProps from "styled-props";
import colors from "../../utils/colors";

const Background = styled.div`
  background: ${colors.gray.solid_black};
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  right: 0;
  min-height: 100%;
  z-index: 1000;
`;
const Box = styled.div`
  z-index: 1001;
  border-radius: 6px;
  background: ${colors.secondary.white};
  position: absolute;
  left: 50%;
  top: 50%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  min-height: 100px;
  max-width: 1440px;
  vertical-align: middle;
  text-align: center;
  padding: 56px;
  i {
    float: right;
    cursor: pointer;
    margin-top: 12px;
    margin-right: 12px;
    font-size: 20px;
    text-align: right;
    color: #9b9b9b;
  }
`;

const Content = styled.div`
  width: 75%;
  max-width: 90%;
  margin: 0 auto;
`;

const titleMargin = {
  large: "44px",
  small: "16px"
};

const Title = styled.div`
  width: 60%;
  margin: 0 auto;
  font-family: Muli;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  color: #4a4a4a;
  margin-bottom: ${styledProps(titleMargin, "titleMargin")};
`;

Title.defaultProps = {
  titleMargin: "large"
};

const CloseIcon = styled.i`
  position: absolute;
  margin: 12px;
  right: 0;
  top: 0;
`;

const Alert = props => (
  <div>
    
    <Background onClick={props.onClose} />
    <Box>
      <CloseIcon onClick={props.onClose} className="ion-android-close" />
      {props.title && (
        <Title titleMargin={props.titleMargin}>{props.title}</Title>
      )}
      <Content className={props.classContent}>{props.children}</Content>
    </Box>
  </div>
);

export default Alert;
