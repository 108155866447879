import React from 'react';
import Text from '../../Text';
import Input, { Label, Required } from '../../Input';
import { Column, Row } from 'hedron';
import colors from '../../../utils/colors';
import Dropdown from '../../Dropdown';
import Button from '../../Button';

const renderType = () => {
  return ['Caja', 'Sobre'].map((type, index) => (
    <Row key={type} className="card-dropdown-option">
      <Column xs={6} fluid>
        <Text>{type}</Text>
      </Column>
    </Row>
  ));
};

const FormPackage = props => (
  <div className="nl-form">
    <Text type="microHeader" className="no-padding display-block">
      Empaques
    </Text>
    <label className="top-save-label">
      <input
        className="top-save-input"
        type="checkbox"
        checked={props.model.savePackage.isChecked}
        onChange={props.model.savePackage.onChange}
        value={props.model.savePackage.name}
      />
      {props.model.savePackage.text_info}
    </label>
    {props.saveIt && (
      <div>
        <Input {...props.model.alias} />
        <Label style={{ marginTop: '16px' }}>
          Tipo{' '}
          <Required
            status={props.model.objectType.objectTypeCodeError != '' ? 'error' : 'default'}
          />
        </Label>
        <Dropdown
          options={renderType()}
          style={{ marginTop: '12px' }}
          placeholder={
            <Text type="p" style={{ color: colors.gray.placeholder, fontSize: '14px' }}>
              Elige un tipo de empaque
            </Text>
          }
          selected={props.model.objectType.objectTypeCode}
          handleChange={props.model.objectType.handleChange}
        />
      </div>
    )}
    <Row>
      <Column xs={10} md={6} fluid style={{ paddingRight: '8px' }}>
        <Input
          type="text"
          textClass="span-text"
          measureClass="measureClass"
          onChange={props.model.dimensions.onChange}
          value={props.model.dimensions.value}
          error={props.model.dimensions.error}
          required
          placeholder="30"
          label="Dimensiones"
          dimensions
        />
      </Column>
    </Row>
    {props.hasPackages && (
      <Button type="link" className="edit-btn-left more-btn" onClick={props.cancelManualPg}>
        Ver empaques guardados
      </Button>
    )}
  </div>
);

export default FormPackage;
