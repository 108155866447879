import styled from 'styled-components';
import colors from '../../utils/colors';

const StyledRate = styled.div`
  padding: 16px 12px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${colors.gray.earth_stone};
  margin-bottom: 16px;
  cursor: pointer;
`;

const StyledDot = styled.div`
  display: inline-block;
  float: right;
  height: 5px;
  width: 5px;
  background: ${colors.primary.mienvio_pink};
  border-radius: 100px;
`;

export { StyledRate, StyledDot };
