// @flow

import axios from 'axios';
import { showLoader, hideLoader } from './loader';
import { showMessage } from './message';
import { showError } from './error';
import type { Dispatch as ReduxDispatch } from 'redux';

export const packagesUrl = '/api/packages';
export const GET = '/packages';

export type Package = {
  object_type_code: string,
  object_type?: string,
  object_id?: number,
  owner_id?: number,
  object_status_code: string,
  object_status?: string,
  provider?: string,
  provider_id?: number,
  height: number,
  width: number,
  length: number,
  quantity?: number,
  alias: string,
  insured_amount?: number
};

type PackageInfo = {
  total_count: number,
  total_pages: number,
  current_page: number,
  results: Array<Package>
};

type State = {
  +packages?: PackageInfo | null
};

type Action = {
  +type: string,
  +packages?: PackageInfo
};

type Dispatch = ReduxDispatch<Action>;

const initialState: State = {
  packages: null
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case GET:
      return action.packages;
    default:
      return state;
  }
};

export const getUserPackages = () => {
  return (dispatch: Dispatch) => {
    dispatch(showLoader('packages'));
    return axios
      .get(packagesUrl + '?page=1&limit=1000000')
      .then(response => {
        dispatch(setUserPackages(response.data));
        dispatch(hideLoader());
      })
      .catch(err => {
        dispatch(hideLoader());
        dispatch(showError(err.response.data.error));
      });
  };
};

export const setUserPackages = (packages: PackageInfo) => {
  return {
    type: GET,
    packages
  };
};

export const getUserAllPackages = (modal?: boolean | true) => {
  return (dispatch: Dispatch) => {
    dispatch(showLoader('packages'));
    //if (!modal) dispatch(showLoader('packages'));
    return axios
      .get(packagesUrl + '?page=1&limit=1000000000')
      .then(response => {
        dispatch(setUserPackages(response.data));
        dispatch(hideLoader());
      })
      .catch(err => {
        dispatch(hideLoader());
        dispatch(showError("Error al obtener los paquetes"));
      });
  };
};

export const createPackage = (pack: Package) => {
  return (dispatch: Dispatch) => {
    return axios
      .post(packagesUrl, {
        ...pack
      })
      .then(response => {
        // message: type, title, text
        dispatch(showMessage('success', 'Empaque creado'));
        dispatch(getUserAllPackages());
        return response;
      })
      .catch(err => {
        dispatch(hideLoader());
        dispatch(showError("Error al crear paquete"));
      });
  };
};

export const editPackage = (newPackage: Package, id: number) => {
  return (dispatch: Dispatch) => {
    return axios
      .put(packagesUrl + '/' + id, {
        ...newPackage
      })
      .then(response => {
        dispatch(showMessage('success', 'Cambios guardados'));
        dispatch(getUserAllPackages());
      })
      .catch(err => {
        dispatch(showError("Error al editar paquete"));
      });
  };
};

export const deletePackage = (id: number) => {
  return (dispatch: Dispatch) => {
    return axios
      .delete(packagesUrl + '/' + id)
      .then(response => {
        dispatch(showMessage('success', 'Empaque eliminado'));
        dispatch(getUserAllPackages());
      })
      .catch(err => {
        dispatch(showError("Error al eliminar paquete"));
      });
  };
};

export const deletePackages = (ids: number[], single: boolean = false) => {
  return (dispatch: Dispatch) => {
    if (single)
      dispatch(
        showMessage(
          'success',
          'Empaque eliminado. Espera un momento estamos obteniendo tus empaques.'
        )
      );
    return axios
      .post(packagesUrl + '/delete', {
        ids
      })
      .then(response => {
        if (!single) dispatch(showMessage('success', 'Empaques eliminados'));
        dispatch(getUserAllPackages());
      })
      .catch(err => {
        dispatch(showError("Error al eliminar paquetes"));
      });
  };
};

export const setDefaultPackage = (id) => {
  return (dispatch: Dispatch) => {
    return axios.put('/api/profile', { package_id: id }).then(response => {
      dispatch(showMessage('success', 'Información actualizada exitosamente'));
    })
    .catch(err => {
      dispatch(showError('Error al elegir el empaque'))
      });
  }
}
