import React from "react";
import { createPortal } from "react-dom";
import Modal from "../Modal";
import styled from "styled-components";
import close_icon from "./img/close.svg";

const modalRoot = document.getElementById("modal-root");

const CloseIcon = styled.div`
  height: 16px;
  width: 16px;
  background-size: cover;
  background-position: center;
  background-image: url(${close_icon});
  margin-left: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
`;

class Modal2 extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }
  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return (
      this.props.isOpen &&
      createPortal(
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "auto",
              background: "white",
              padding: "20px 50px",
              position: "relative",
              borderRadius: ".5rem",
              overflow: "scroll",
            }}
          >
            <div
              className="header"
              style={{ display: "flex", flexFlow: "column" }}
            >
              <div
                className="header__actions"
                style={{ position: "absolute", top: 15, right: 15 }}
              >
                <CloseIcon onClick={this.props.toggleModal} />
              </div>
              <div
                className="header__title"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Muli",
                  fontSize: "20px",
                  fontWeight: "bolder",
                  marginBottom: "18px",
                  color: "#858D9D",
                }}
              >
                {this.props.title}
              </div>
            </div>
            <div>{this.props.children}</div>
          </div>
        </div>,
        this.el
      )
    );
  }
}

export default Modal2;
