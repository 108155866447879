import React from 'react';
import Table, { Th, Tr, Td } from '../../../components/Table';
import Marketplace from './marketplace';
import Button from '../../../components/Button';
import { searchPermission } from '../../../utils/global_functions';

const shopNames = ['shopify', 'woocommerce', 'prestashop', 'magento'];

const Marketplaces = ({
  user, shops, handleSelect, showRate, deleteShop,
}) => (
  <Table>
    <thead>
      <Tr>
        <Th>Integración</Th>
        <Th>Cuenta</Th>
        <Th>Estatus</Th>
        <Th />
        <Th>Devolver Tarifas</Th>
        <Th>Tarifa</Th>
        <Th />

      </Tr>
    </thead>
    <tbody>
      {shops.map((shop, index) => {
        if (shop.marketplace !== 'api') {
          return (
            <Marketplace
              key={`marketplace-${index}`}
              icon={shop.marketplace}
              shop={shop}
              functions={{
                handleSelect,
                showRate,
                deleteShop,
              }}
              user={user}
            />
          );
        }
        return null;
      })}


      <Button type="link" onClick={() => handleSelect('marketplace')}>
        + Añadir Marketplace
      </Button>
    </tbody>
  </Table>
);

export default Marketplaces;
