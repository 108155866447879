import React from "react";
import { Row, Column } from "hedron";
import Menu from "../../components/Menu";
import Text, { Title } from "../../components/Text";
import Iframe from "./iframe";

export default class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: "/forms/report.html",
    };
  }
  render() {
    return (
      <div>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: "right" }} fluid>
            <Menu />
          </Column>
        </Row>
        <Row divisions={20}>
          <Column xs={18} fluid>
            <Title type="titleCard">Reportes</Title>
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={18} fluid>
            <Iframe source={this.state.src} />
          </Column>
        </Row>
        <Column xs={1} className="show-for-small-only" fluid />
      </div>
    );
  }
}
