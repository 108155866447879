// @flow
import React from "react";
import axios from "axios";
import { Row, Column } from "hedron";
import isEmail from "validator/lib/isEmail";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import Dropdown from "../Dropdown";
import Header from "../Header";
import Text, { Title } from "../Text";
import Input, { HelpText } from "../Input";
import Filter from "../Filter";
import { Tr, Td } from "../Table";
import Button from "../Button";
import EmpyState from "../EmptyState";
import Message from "../Message";
import WrapperSideBar from "../WrapperSideBar";
import {
  ContentAdapter,
  BottomContainer,
} from "../WrapperSideBar/styledObjects";
import origin_icon from "../../utils/icons/origin_gray.svg";
import destination_icon from "../../utils/icons/destination_gray.svg";
import edit_icon from "../../utils/icons/edit.svg";
import delete_icon from "../../utils/icons/delete.svg";
import detail_eye_icon from "../../utils/icons/detail-eye.svg";
import addresses_icon from "../static/Icon/addressesActive.svg";
import addresses_icon_gray from "../static/Icon/addresses.svg";
import NewTable from "../NewTable";
import SidebarTitleMenu from "../WrapperSideBar/extraComponents/SidebarTitleMenu";
import {
  shorten,
  searchPermission,
  formatPhone,
} from "../../utils/global_functions";
import {
  ADDRESS_DETAILS,
  NEW_ADDRESS,
  EDIT_ADDRESS,
} from "../../modules/CollapsibleBar/sideBarTypes";

type Props = {
  createAddress: (address: any) => Promise<any>,
  editAddress: (newAddress: any, id: any, _showLoader: any) => Promise<any>,
  deleteAddress: (id: any, _showLoader?: boolean) => Promise<any>,
  savePrimaryAddress: (address: any, _showLoader?: boolean) => Promise<any>,
  getPrimaryAddress: (object_type: any, _showLoader?: boolean) => Promise<any>,
  deletePrimaryAddress: (
    object_type: any,
    _showLoader?: boolean
  ) => Promise<any>,
  goToAddress: (id: number) => void,
  goToAddressList: (id: number) => void,
  hideMessage: () => void,
  toggleSideBar: (
    open: any,
    title?: string,
    type?: string,
    _callback?: Function
  ) => void,
  clearSidebar: () => void,
  getCountries: () => Promise<any>,
};

type State = {
  selected: string[],
  filter: string,
  filters: string[],
  address: string,
  clearSelectedRows: boolean,
  selectedCountry?: string,
  selectedCountryTxt?: string,
  selectedTypeAddress: Boolean,
};

export default class AddressesList extends React.Component<Props, State> {
  state: State = {
    selected: [],
    filter: "",
    filters: [],
    clearSelectedRows: false,
    selectedCountry: null,
    selectedCountryTxt: null,
    address: null,
    typeAddressError: "",
    selectedTypeAddress: null,
  };

  constructor(props: Props) {
    super(props);
    let address = null;
    if (props.match.params.id) {
      address = props.addressesById[props.match.params.id];
      this._openSideBar(true, address.alias, ADDRESS_DETAILS);
    }
    this.setState({
      address,
    });
  }

  openAddressReducer() {
    window.open(
      "https://blogger.mienvio.mx/2021/04/necesitas-ayuda-abreviando/"
    );
  }

  toggleDropdown() {
    this.setState({ dropdown: !this.state.dropdown });
  }

  getDefaultAddresses = () => {
    this.setState({ defaultOrigin: null, defaultDestination: null });
    this.props.getPrimaryAddress("PRIMARY").then((result) => {
      if (result) {
        this.setState({
          defaultOrigin: result.object_id,
        });
      }
    });
    this.props.getPrimaryAddress("DEFAULT_TO").then((result) => {
      if (result) {
        this.setState({
          defaultDestination: result.object_id,
        });
      }
    });
  };

  setAsDefaultAddress(address) {
    this.props
      .savePrimaryAddress(address)
      .then(() => {
        this.onCloseSidebar();
        this.getDefaultAddresses();
      })
      .catch((err) => {
        console.log(err);
      });
    if (address.object_type === "PRIMARY") {
      this.setState({ originDefault: address.object_id });
    } else if (address.object_type === "DEFAULT_TO") {
      this.setState({ destinationDefault: address.object_id });
    }
  }

  _openSideBar = (
    stateSidebar = false,
    sidebarTitle = "",
    sidebarType = ""
  ) => {
    this.props.toggleSideBar(stateSidebar, sidebarTitle, sidebarType);
  };

  handleChangeDropdown = (value) => {
    this.setState({
      selectedCountry: value,
      country_code: this.props.countries[value].code,
    });
  };

  checkAddressesStored = () => {
    return this.props.addresses.results.filter(function (element) {
      return element.origin_destiny == false;
    }).length;
  };

  handleChangeTypeAddress = (value) => {
    let checkAddressStored = this.checkAddressesStored();

    this.setState({
      selectedTypeAddress: value,
      origin_destiny: value,
    });
  };

  _renderTypeAddress = () => {
    return ["Origen", "Destino"].map((type, index) => (
      <Text key={type}>{type}</Text>
    ));
  };

  _renderCountries = () =>
    this.props.countries
      ? this.props.countries.map((country) => (
          <Text key={country.object_id}>{country.name}</Text>
        ))
      : null;

  _renderSidebarContent = () => {
    let disabledAddress = false;
    if (!this.state.origin_destiny && this.state.address) {
      disabledAddress = true;
    }
    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value:
          this.state[name] && name === "phone"
            ? formatPhone(this.state[name])
            : this.state[name],
        error: this.state[`${name}Error`],
      }),
    };

    const addressFields = (
      <div>
        <Input
          type="text"
          {...mainProps.generate("alias")}
          required
          placeholder="Warehouse nte"
          label="Alias"
        />
        <Text type="microHeader" className="microheader">
          Datos de contacto de la dirección
        </Text>
        <Input
          type="text"
          {...mainProps.generate("name")}
          required
          placeholder="María Pérez"
          label="Nombre del titular"
        />
        <Row>
          <Column xs={6} fluid>
            <Input
              type="text"
              {...mainProps.generate("email")}
              required
              placeholder="maria@tucorreo.com"
              label="Email del titular"
            />
          </Column>
          <Column xs={6} fluid>
            <Input
              type="text"
              {...mainProps.generate("phone")}
              required
              placeholder="(442) 123 12 45"
              label="Teléfono de contacto"
            />
          </Column>
        </Row>
        {!this.state.address ? (
          <Dropdown
            required
            error={this.state.typeAddressError}
            label="Tipo de Direccion"
            options={this._renderTypeAddress()}
            style={{ marginTop: "1px" }}
            placeholder={
              <Text className="dropdown-placeholder">
                Seleccina Tipo de Direccion
              </Text>
            }
            selected={this.state.selectedTypeAddress}
            handleChange={this.handleChangeTypeAddress}
          />
        ) : (
          <Input
            type="text"
            value={!this.state.address.origin_destiny ? "Origen" : "Destino"}
            maxLength="35"
            label="Tipo Direccion"
            disabled={true}
          />
        )}

        <Text type="microHeader" className="microheader">
          Detalles de dirección
        </Text>
        <Dropdown
          required
          label="País"
          options={this._renderCountries()}
          style={{ marginTop: "29px" }}
          placeholder={
            <Text className="dropdown-placeholder">Buscar por alias</Text>
          }
          selected={this.state.selectedCountry}
          handleChange={this.handleChangeDropdown}
        />
        <Row divisions={24}>
          <Column xs={10} fluid>
            <Input
              type="text"
              {...mainProps.generate("street2")}
              maxLength="35"
              required
              placeholder="Independencia"
              label="Colonia"
              disabled={disabledAddress}
            />
          </Column>
          <Column xs={5} fluid style={{ paddingLeft: "16px" }}>
            <Input
              type="text"
              {...mainProps.generate("zipcode")}
              required
              disabled={!this.state.country_code}
              placeholder="76158"
              label="C.P."
              disabled={disabledAddress}
            />
          </Column>
          <Column xs={9} fluid style={{ paddingLeft: "16px" }}>
            <Input
              type="text"
              disabled
              onChange={() => {}}
              required
              label="Ciudad y Estado"
              value={this.state.location}
            />
          </Column>
        </Row>
        <Input
          type="text"
          {...mainProps.generate("street")}
          maxLength="35"
          required
          placeholder="Av. Miguel Hidalgo, 876. Int. 29"
          label="Calle y número"
          disabled={disabledAddress}
          help={
            this.state.streetError ? (
              <p>this.state.streetError</p>
            ) : (
              <p>
                ¿Necesitas ayuda abreviando?
                <a className="link" onClick={this.openAddressReducer}>
                  &nbsp;Presiona aquí
                </a>
              </p>
            )
          }
        />
        <Input
          type="text"
          {...mainProps.generate("reference")}
          maxLength="32"
          required
          placeholder="Casa blanca con portón negro"
          label="Referencias de la dirección"
          help={
            this.state.referenceError ? "" : "No ingreses datos de la dirección"
          }
          disabled={disabledAddress}
        />
      </div>
    );
    switch (this.props.sidebar.type) {
      case ADDRESS_DETAILS:
        return (
          <div>
            <div className="gray-row">
              <img alt="" height="12px" src={detail_eye_icon} /> &nbsp;
              <Text type="label"> Detalle </Text>
            </div>
            <ContentAdapter>
              <div className="contentAdapter">
                {this.state.address &&
                  this.addressArray(this.state.address)
                    .filter((x) => x)
                    .map((item) => (
                      <Row className="detail-row">
                        <Column fluid>
                          <Text type="bodyDetail">{item}</Text>
                        </Column>
                      </Row>
                    ))}
              </div>
            </ContentAdapter>
          </div>
        );
      case NEW_ADDRESS:
        return (
          <ContentAdapter>
            {addressFields}
            <div className="bottomContainer">
              <BottomContainer>
                <Row divisions={20}>
                  <Column
                    xs={9}
                    xsShift={1}
                    style={{ textAlign: "left" }}
                    fluid
                  />
                  <Column
                    xs={8}
                    xsShift={1}
                    style={{ textAlign: "right" }}
                    fluid
                  >
                    <div>
                      <Button
                        initialLoader
                        className="state-btn"
                        onClick={() => this.saveAddress("create", null)}
                      >
                        Guardar
                      </Button>
                    </div>
                  </Column>
                  <Column
                    xs={1}
                    xsShift={1}
                    style={{ textAlign: "right" }}
                    fluid
                  />
                </Row>
              </BottomContainer>
            </div>
          </ContentAdapter>
        );
      case EDIT_ADDRESS:
        return (
          <ContentAdapter>
            {addressFields}
            <div className="bottomContainer">
              <BottomContainer>
                <Row divisions={20}>
                  <Column
                    xs={9}
                    xsShift={1}
                    style={{ textAlign: "left" }}
                    fluid
                  />
                  <Column
                    xs={8}
                    xsShift={1}
                    style={{ textAlign: "right" }}
                    fluid
                  >
                    <div>
                      <Button
                        initialLoader
                        className="state-btn"
                        onClick={() =>
                          this.saveAddress("edit", this.state.address.object_id)
                        }
                      >
                        Guardar
                      </Button>
                    </div>
                  </Column>
                  <Column
                    xs={1}
                    xsShift={1}
                    style={{ textAlign: "right" }}
                    fluid
                  />
                </Row>
              </BottomContainer>
            </div>
          </ContentAdapter>
        );
      default:
        return <div />;
    }
  };

  setDefaultPrimary = () => {
    const { address } = this.state;
    address.object_type = "PRIMARY";
    this.setState({ address });
    this.setAsDefaultAddress(this.state.address);
  };

  setDefaultTo = () => {
    const { address } = this.state;
    address.object_type = "DEFAULT_TO";
    this.setState({ address });
    this.setAsDefaultAddress(this.state.address);
  };

  _deletePrimaryAddress = () => {
    this.props.deletePrimaryAddress("PRIMARY");
  };

  _deleteDefaultToAddress = () => {
    this.props.deletePrimaryAddress("DEFAULT_TO");
  };

  _renderSidebarTitleExtraContent = () => {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    const addressOptions = [];

    if (
      this.state &&
      this.state.address &&
      this.state.address.origin_destiny === false &&
      this.state.address.object_id != this.state.defaultOrigin
    ) {
      addressOptions.push({
        function: this.setDefaultPrimary,
        label: "Marcar como orígen default",
        image: origin_icon,
      });
    }

    if (
      this.state &&
      this.state.address &&
      this.state.address.origin_destiny === true &&
      this.state.address.object_id != this.state.defaultDestination
    ) {
      addressOptions.push({
        function: this.setDefaultTo,
        label: "Marcar como destino default",
        image: destination_icon,
      });
    }

    if (searchPermission("direcciones", "editar", permissions)) {
      addressOptions.push({
        function: this.toggleEditAddress,
        label: "Editar dirección",
        image: edit_icon,
      });
    }
    if (searchPermission("direcciones", "eliminar", permissions)) {
      if (
        this.state &&
        this.state.address &&
        this.state.address.origin_destiny === true
      ) {
        addressOptions.push({
          function: this.deleteAddress,
          label: "Eliminar dirección",
          image: delete_icon,
        });
      }
    }

    if (
      this.state &&
      this.state.address &&
      this.state.address.object_id === this.state.defaultOrigin
    ) {
      /*addressOptions[0] = {
        label: <Text className="defaultAddress">Ya no es orígen default</Text>,
        image: origin_icon,
        function: this._deletePrimaryAddress,
      };
      */

      addressOptions.push({
        function: this._deletePrimaryAddress,
        label: <Text className="defaultAddress">Ya no es orígen default</Text>,
        image: origin_icon,
      });
    }
    if (
      this.state &&
      this.state.address &&
      this.state.address.object_id === this.state.defaultDestination
    ) {
      /* addressOptions[1] = {
        label: <Text className="defaultAddress">Ya no es destino default</Text>,
        image: destination_icon,
        function: this._deleteDefaultToAddress,
      };
      */
      addressOptions.push({
        function: this._deleteDefaultToAddress,
        label: <Text className="defaultAddress">Ya no es destino default</Text>,
        image: destination_icon,
      });
    }

    switch (this.props.sidebar.type) {
      case ADDRESS_DETAILS:
        return (
          <SidebarTitleMenu
            linksToRender={addressOptions}
            nextButton={{
              trigger: () => this.nextAddress(),
              disabled: !this.hasNextAddress(),
            }}
            prevButton={{
              trigger: () => this.prevAddress(),
              disabled: !this.hasPrevAddress(),
            }}
          />
        );
      default:
        return null;
    }
  };

  UNSAFE_componentWillMount() {
    this.getDefaultAddresses();
    this.props.getCountries();
    if (
      this.props.user.customer_type === "enterprise" ||
      this.props.user.customer_type === "basic" ||
      this.props.user.customer_type === "subsided_customer"
    ) {
      this.setState({ canCreate: true, canDelete: true, canUpdate: true });
    } else {
      this.props.user.permissions.forEach((element) => {
        if (element.resource === "addresses") {
          switch (element.action) {
            case "create":
              this.setState({ canCreate: true });
              break;
            case "edit":
              this.setState({ canUpdate: true });
              break;
            case "delete":
              this.setState({ canDelete: true });
              break;
            default:
              break;
          }
        }
      });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    let { address } = this.state;
    if (
      !props.sidebar.open &&
      props.match.params.id &&
      (!address || address.id !== props.match.params.id)
    ) {
      address = props.addressesById[props.match.params.id];
      this.setState({ address, hideIcon: false });
      this._openSideBar(true, address.alias, ADDRESS_DETAILS);
    }
  }

  hasNextAddress = () => {
    let currentIndex = this.getCurrentAddressIndex();

    return --currentIndex >= 0;
  };

  hasPrevAddress = () => {
    let currentIndex = this.getCurrentAddressIndex();

    return ++currentIndex < this.props.addresses.total_count;
  };

  getCurrentAddressIndex = () => {
    const { addresses } = this.props;
    const { address } = this.state;
    const currentAddress = address;
    const currentElement = addresses.results.find(
      (element) =>
        element &&
        currentAddress &&
        element.object_id === currentAddress.object_id
    );
    return addresses.results.indexOf(currentElement);
  };

  handleAddressChange = (currentIndex) => {
    const address = this.props.addresses.results[currentIndex];
    this.props.goToAddress(address.object_id);
    this.setState({ address, hideIcon: false }, () => {
      this._openSideBar(true, address.alias, ADDRESS_DETAILS);
    });
  };

  nextAddress = () => {
    let currentIndex = this.getCurrentAddressIndex();

    if (--currentIndex < 0) {
      return -1;
    }
    this.handleAddressChange(currentIndex);
  };

  prevAddress = () => {
    let currentIndex = this.getCurrentAddressIndex();

    if (++currentIndex >= this.props.addresses.total_count) {
      return -1;
    }
    this.handleAddressChange(currentIndex);
  };

  onCloseSidebar = () => {
    this.props.goToAddressList();
    this.props.toggleSideBar(false);
    this.props.clearSidebar();
    this.props.getCountries();
    this.setState({ address: null, hideIcon: true });
    this.setState({
      alias: null,
      name: null,
      email: null,
      phone: null,
      street: null,
      street2: null,
      reference: null,
      city: null,
      state: null,
      zipcode: null,
      selectedCountry: null,
      country_code: null,
      origin_destiny: null,
      selectedTypeAddress: null,
    });
  };

  toggleCreateAddress = () => {
    this.setState(
      { hideIcon: true },
      this._openSideBar(true, "Nueva dirección", NEW_ADDRESS)
    );
  };

  toggleEditAddress = () => {
    const { address } = this.state;
    let country;
    let countryCode;
    this.props.countries.forEach((element, index) => {
      if (element.name === address.country) {
        country = index;
        countryCode = element.code;
      }
    });
    axios
      .get(`/api/zipcodes/${address.zipcode}?country=${countryCode}`)
      .then((response) => {
        this.setState({
          location: `${response.data.municipality}, ${response.data.state.name}`,
          zipcodeError: "",
        });
      })
      .catch(() => {
        this.setState({
          location: "",
          zipcodeError: "C.P. inválido",
        });
      });

    this.setState({
      alias: address.alias,
      name: address.name,
      email: address.email,
      phone: address.phone,
      street: address.street,
      street2: address.street2,
      reference: address.reference,
      city: address.city,
      state: address.state,
      zipcode: address.zipcode,
      selectedCountry: country,
      country_code: countryCode,
      selectedTypeAddress: address.origin_destiny === false ? 0 : 1,
      origin_destiny: address.origin_destiny,
    });

    this.setState(
      { hideIcon: true },
      this._openSideBar(true, "Editar dirección", EDIT_ADDRESS)
    );
  };

  saveAddress(type, id) {
    let errors = 0;
    let aliasError = "";
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let streetError = "";
    let street2Error = "";
    let referenceError = "";
    let zipcodeError = "";
    let typeAddressError = "";
    let checkAddress = this.checkAddressesStored();

    if (this.state.selectedTypeAddress == null) {
      errors++;
      typeAddressError = "Este campo es requerido";
    }

    if (!this.state.alias) {
      errors++;
      aliasError = "Este campo es requerido";
    }

    if (!this.state.name) {
      errors++;
      nameError = "Este campo es requerido";
    }

    if (!this.state.email) {
      errors++;
      emailError = "Este campo es requerido";
    } else if (!isEmail(this.state.email)) {
      errors++;
      emailError = "Correo electrónico inválido";
    }

    if (isNaN(this.state.phone) || this.state.phone.length !== 10) {
      errors++;
      phoneError = "Número de teléfono inválido";
    }

    if (!this.state.street) {
      errors++;
      streetError = "Este campo es requerido";
    } else if (this.state.street.length > 35) {
      errors++;
      streetError = "Máximo 35 caracteres";
    }

    if (!this.state.street2) {
      errors++;
      street2Error = "Este campo es requerido";
    } else if (this.state.street2.length > 35) {
      errors++;
      street2Error = "Máximo 35 caracteres";
    }

    if (this.state.reference?.length >= 32) {
      errors++;
      referenceError = "Máximo 32 caracteres";
    }

    if (
      isNaN(this.state.zipcode) ||
      this.state.zipcode.length !== 5 ||
      this.state.location === ""
    ) {
      errors++;
      zipcodeError = "C.P. inválido";
    }
    this.setState({
      aliasError,
      nameError,
      emailError,
      phoneError,
      streetError,
      referenceError,
      street2Error,
      zipcodeError,
      typeAddressError,
    });

    if (errors > 0) {
      return;
    }
    if (type === "create") {
      this.props.createAddress(this.state).then(() => {
        this.onCloseSidebar();
      });
      return;
    }
    if (type === "createDefault") {
      const from = this.state;
      this.props.createAddress(from).then(() => {
        this.onCloseSidebar();
      });
    }
    this.props.editAddress(this.state, id).then(() => {
      this.onCloseSidebar();
    });
  }

  ModalAlertCheckAddress = () => {
    const maxAddresses = this.props.user.address_from_limit
      ? this.props.user.address_from_limit
      : 4;

    Swal.fire({
      title: `Solo ${maxAddresses} direcciones de origen`,
      html: `Si deseas guardar más direcciones de origen, comunícate con soporte`,
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      width: "42rem",
    }).then((result) => {});
  };

  ModalAlert = () => {
    Swal.fire({
      title: "Direcciones de Origen",
      html: `No es posible eliminar direcciones de origen`,
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      width: "42rem",
    }).then((result) => {});
  };

  checkAddresses = () => {
    return this.state.selected.filter(function (element) {
      return element.origin_destiny == false;
    }).length;
  };

  deleteAddresses = () => {
    let checkAddress = this.checkAddresses();

    if (checkAddress == 0) {
      this.state.selected.forEach((element) => {
        this.props.deleteAddress(element.object_id);
      });
    } else {
      this.ModalAlert();
    }

    this.setState({ selected: [] });
  };

  deleteAddress = () => {
    this.props.deleteAddress(this.state.address.object_id);
    this.onCloseSidebar();
  };

  selectAll = (value) => {
    let selected = [];
    if (value.length > 0) {
      const { addresses } = this.props;
      selected = addresses.results.map((address) =>
        address.object_id.toString()
      );
    }
    this.setState({ selected });
  };

  select = (selected) => {
    this.setState({ selected });
  };

  renderEmptyState = () => {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    return (
      <EmpyState name="addresses">
        <Title className="state-title">Registra una nueva dirección</Title>
        <Text>
          Evita tareas repetitivas y registra las direcciones que usas
          frecuentemente, por ejemplo, la dirección de donde salen todos tus
          paquetes.
        </Text>
        {searchPermission("direcciones", "editar", permissions) && (
          <Button
            initialLoader
            className="state-btn"
            onClick={() => this.toggleCreateAddress()}
          >
            Nueva dirección
          </Button>
        )}
      </EmpyState>
    );
  };

  handleFilterChange = (filter) => {
    this.setState({ filter });
  };

  handleKeyPress = ({ key }) => {
    if (key === "Enter") {
      const filters = [...this.state.filters, this.state.filter];
      this.setState({
        filters,
        filter: "",
      });
    }
  };

  handleChange(value, key) {
    if (key === "zipcode" && !isNaN(value) && value.length === 5) {
      axios
        .get(`/api/zipcodes/${value}?country=${this.state.country_code}`)
        .then((response) => {
          this.setState({
            location: `${response.data.municipality}, ${response.data.state.name}`,
            zipcodeError: "",
          });
        })
        .catch(() => {
          this.setState({
            location: "",
            zipcodeError: "C.P. inválido",
          });
        });
    } else if (key === "zipcode") {
      this.setState({ location: "" });
    } else if (key === "phone") {
      value = value.replace(/\D/g, "");
      if (value.length > 10) return;
    } else if (key === "reference") {
      const referenceError =
        value.length >= 35
          ? "No escribas parte de la dirección aquí, esta información no aparecerá en la guía"
          : "";
      this.setState({ referenceError });
    }
    this.setState({ [key]: value });
  }

  onClose = (index) => {
    const { filters } = this.state;
    filters.splice(index, 1);
    this.setState({ filters });
  };

  generateSkeletonRow = (cols) => (
    <Tr>
      <Td>
        <Skeleton width={20} />
      </Td>
      <Td>
        <Skeleton width={100} />
      </Td>
      <Td>
        <Skeleton width={70} />
      </Td>
      <Td>
        <Skeleton width={80} />
      </Td>
      <Td>
        <Skeleton width={80} />
      </Td>
      <Td>
        <Skeleton width={80} />
      </Td>
      <Td>
        <Skeleton width={60} />
      </Td>
      <Td>
        <Skeleton width={60} />
      </Td>
      <Td>
        <Skeleton width={60} />
      </Td>
    </Tr>
  );

  clearSelectedRows = () => {
    this.setState({
      selected: [],
      clearSelectedRows: !this.state.clearSelectedRows,
    });
  };

  renderTablev2 = () => {
    const { goToAddress } = this.props;
    const results = this.props.addresses.results.filter((address) =>
      this.state.filters.every((filter) => {
        const re = new RegExp(filter, "i");
        return (
          re.test(address.name) ||
          re.test(address.alias) ||
          re.test(address.street) ||
          re.test(address.street2) ||
          re.test(address.zipcode) ||
          re.test(address.phone)
        );
      })
    );
    const columns = [
      {
        name: "Alias",
        selector: "alias",
        cell: (row) => shorten(row.alias, 15),
      },
      {
        name: "Nombre",
        selector: "name",
      },
      {
        name: "Tipo direccion",
        selector: "origin_destiny",
        cell: (row) => (
          <div>
            <p>{row.origin_destiny ? "Destino" : "Origen"}</p>
          </div>
        ),
      },
      {
        name: "Correo electrónico",
        selector: "email",
        cell: (row) => shorten(row.email, 15),
      },
      {
        name: "Teléfono",
        selector: "phone",
      },
      {
        name: "Calle y número",
        selector: "street",
        cell: (row) => shorten(row.street, 15),
      },
      {
        name: "Colonia",
        selector: "street2",
        cell: (row) => shorten(row.street2, 15),
      },
      {
        name: "Ciudad y estado",
        selector: "city",
        cell: (row) => shorten(row.state, 15),
      },
      {
        name: "CP",
        selector: "zipcode",
      },
    ];
    const filters = this.state.filters.map((filter, index) => (
      <Filter
        icon={addresses_icon_gray}
        style={{ marginLeft: "0px" }}
        key={index}
        onClose={() => this.onClose(index)}
      >
        {filter}
      </Filter>
    ));
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    return (
      <div>
        <Text>Filtrar por</Text>
        <Row>
          <Column xs={12} md={3} fluid>
            <Input
              parentClassName="filter-input"
              type="text"
              value={this.state.filter}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleFilterChange}
              placeholder="Palabra clave..."
            />
          </Column>
        </Row>
        {filters}
        <NewTable
          // hideForSmall={true}
          selectableRows
          shipping={false}
          columns={columns}
          data={results}
          onRowClicked={(a) => {
            goToAddress(a.object_id);
          }}
          onTableUpdate={(table) => {
            this.setState({
              selected: table.selectedRows.map((address) => address),
            });
          }}
          clearSelectedRows={this.state.clearSelectedRows}
          noDataComponent={<div />}
          subHeader={
            this.state.selected.length === 0
              ? ""
              : searchPermission("direcciones", "eliminar", permissions) && (
                  <Button
                    className="action-btn btn-subheader-space"
                    type="table"
                    onClick={() => {
                      this.clearSelectedRows();
                      this.deleteAddresses();
                    }}
                    disabled={this.state.paymentDisabled}
                  >
                    Eliminar
                  </Button>
                )
          }
        />
      </div>
    );
  };

  addressArray(address) {
    return [
      address.name,
      address.email,
      address.phone.charAt(0) == "(" && address.phone.charAt(4) == ")"
        ? address.phone
        : formatPhone(address.phone),
      address.street,
      address.street2,
      address.reference,
      `${address.city}, ${address.state} CP. ${address.zipcode}`,
    ];
  }

  render() {
    const { message, hideMessage, addresses } = this.props;
    if (
      message.show &&
      (message.messageType === "error" || message.messageType === "success")
    ) {
      setTimeout(hideMessage, 5000);
    }
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }
    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    return (
      <div>
        <WrapperSideBar
          handleClose={this.onCloseSidebar}
          title_more={this._renderSidebarTitleExtraContent()}
          icon={!this.state.hideIcon && addresses_icon}
        >
          {this._renderSidebarContent()}
        </WrapperSideBar>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Header>
              <Row divisions={18}>
                <Column xs={6} fluid>
                  <Title type="titleCard">Direcciones</Title>
                </Column>
                <Column
                  xs={7}
                  xsShift={5}
                  style={{
                    textAlign: "right",
                  }}
                  fluid
                >
                  {searchPermission("direcciones", "editar", permissions) && (
                    <Button
                      initialLoader
                      className="header-btn last"
                      onClick={() => this.toggleCreateAddress()}
                    >
                      Nueva dirección
                    </Button>
                  )}
                </Column>
              </Row>
            </Header>
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            {message.show ? (
              <Message
                color={message.messageType}
                onClose={this.props.hideMessage}
              >
                {message.text}
              </Message>
            ) : null}
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            {!addresses.results || addresses.results.length === 0
              ? this.renderEmptyState()
              : this.renderTablev2()}
          </Column>
          <Column xs={1} fluid />
        </Row>
      </div>
    );
  }
}
