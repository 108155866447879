import React from 'react';
import axios from 'axios';
import { boundMethod } from 'autobind-decorator';
import styled from 'styled-components';

import { Row, Column } from 'hedron';
import { ToastContainer, toast } from 'react-toastify';
import Selector from './Selector';
import Packages from './Options/Packages';
import Shipments from './Options/Shipments';

import Addresses from './Options/Addresses';
import Invoices from './Options/Invoices';
import Cards from './Options/Cards';
import Quotations from './Options/Quotations';
import Config from '../AccountSetupConfig';
import './style.css';

// comit
export default class AccountDataVisualization extends React.Component {
  state={
    selected: null,
  }

  @boundMethod
  onClickElement(selected) {
    this.setState({ selected });
  }

  @boundMethod
  closeElement() {
    this.setState({ selected: null });
  }

  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
  }

  renderContent() {
    switch (this.state.selected) {
    case null:
      return (
        <Selector onClick={this.onClickElement} />
      );
      break;
    case 'shipments':
      return (
        <Shipments id={this.props.id} notify={this.notify} closeElement={this.closeElement} />
      );
      break;
    case 'packages':
      return (
        <Packages id={this.props.id} notify={this.notify} closeElement={this.closeElement} />
      );
      break;
    case 'invoices':
      return (
        <Invoices id={this.props.id} notify={this.notify} closeElement={this.closeElement} />
      );
      break;
    case 'addresses':
      return (
        <Addresses id={this.props.id} notify={this.notify} closeElement={this.closeElement} />
      );
      break;
    case 'payment':
      return (
        <Cards id={this.props.id} notify={this.notify} closeElement={this.closeElement} />
      );
      break;
    case 'quotations':
      return (
        <Quotations id={this.props.id} notify={this.notify} closeElement={this.closeElement} />
      );
      break;
    case 'config':
      return (
        <Config id={this.props.id} notify={this.notify} closeElement={this.closeElement} />
      );
      break;
    
    default:
      break;
    }
  }

  notify = (message, type) => {
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  render() {
    return (
      <div>
        <ToastContainer />
        {/* <EmptyState /> */}
        <div className="container-accounts-visualization">
          {this.renderContent()}

        </div>
      </div>
    );
  }
}
