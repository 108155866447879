import React from "react";

const Iframe = ({ source }) => {
  if (!source) {
    return <div>Loading...</div>;
  }

  const src = source;
  return (
    <iframe
      src={src}
      style={{ border: "0", width: "100%", height: "680px" }}
    ></iframe>
  );
};

export default Iframe;
