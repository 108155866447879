import React from 'react';

import eye from '../../../../utils/icons/eye.svg';

import './style.css';


const Account = ({
  onClick, regionId, id, email,
}) => (
  <div onClick={() => onClick(id, regionId, email)} className="account-region-container">
    {email}
    <img src={eye} alt="" />
  </div>
);
export default Account;
