import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Column } from 'hedron';
import { ContentAdapter, BottomContainer } from '../../WrapperSideBar/styledObjects';
import { createPackage } from '../../../modules/packages';
import { toggle as toggleSideBar } from '../../../modules/CollapsibleBar/actions';
import '../style.css';
import Text from '../../Text';
import Button from '../../Button';

import Dropdown from '../../Dropdown';
import SelectableBox from '../../SelectableBox';
import { Label } from '../../Input';

class Impresion extends React.Component {
  state = {
    selectedIndex: -1,
    selected: null,
    data: [],
    isLoading: false,
    error: '',
  };

  componentWillMount() {
    if (this.props.user && this.props.user.dataPrint) {
      const result = [];
      for (const i in this.props.user.dataPrint) {
        result.push([i, this.props.user.dataPrint[i]]);
      }
      this.setState({ data: result });
    }


    if (this.props.preset) {
      for (let i = 0; i < this.props.packages.results.length; i++) {
        if (this.props.preset == this.props.packages.results[i].object_id) {
          this.setState({
            packageSelectedIndex: i,
            packageSelected: this.props.packages.results[i],
          });
          break;
        }
      }
    }
  }


  handleDropdown = (event) => {
    this.setState({ selectedIndex: event, selected: this.state.data[event][1] });
  }


  save = () => {
    if (this.state.selectedIndex !== -1) {
      this.setState({ error: '', isLoading: true });
      return axios
        .post('/api/users/config/print-default', { config_print: this.state.data[this.state.selectedIndex][0] })
        .then((response) => {
          this.setState({ isLoading: false });
          this.props.getAutomationInfo();
          this.props.toggleSideBar(false);
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          dispatch(showError('Error al guardar la configuración'));
        });
    }
    this.setState({ error: 'Campo obligatorio' });
  };

  generateDropdownOptions = () => {
    const result = [];
    for (const i in this.props.user.dataPrint) {
      result.push([i, this.props.user.dataPrint[i]]);
    }
    return result.map((element, index) => (
      <Row key={element[0]} className="card-dropdown-option">
        <Column fluid>
          <Text className="title-selected">
            <b>{element[1]}</b>
          </Text>
        </Column>
      </Row>
    ));
  };


  render() {
    return (
      <ContentAdapter className="top-space create-package-form">
        <Row>
          <Column>
            <Label>
              <u>Medidas Actuales:</u>
              {' '}
              {this.props.user.config_print}
            </Label>
            <Dropdown
              handleChange={value => this.handleDropdown(value)}
              options={this.generateDropdownOptions()}
              multiple={false}
              error={this.state.error}
              selected={this.state.selectedIndex}
              selectedTitle={
                this.state.selected ? this.state.selected : 'Selecciona la nueva medida...'
              }
            />
          </Column>
        </Row>
        <BottomContainer>
          <Row divisions={20}>
            <Column style={{ textAlign: 'right' }} fluid>
              <Button
                className=""
                onClick={() => this.save()}
                loading={this.state.isLoading}
              >
                Guardar
              </Button>
            </Column>
          </Row>
        </BottomContainer>
      </ContentAdapter>
    );
  }
}

const mapStateToProps = state => ({
  packages: state.packages,
  user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    createPackage,
    toggleSideBar,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Impresion);
