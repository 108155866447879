// @flow
import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Redirect } from 'react-router-dom';
import { boundMethod } from 'autobind-decorator';
import Cookies from 'universal-cookie';

import GoogleLogin from 'react-google-login';
import { ToastContainer, toast } from 'react-toastify';
import { loginUserOperational } from '../../modules/user';
import { showMessage, hideMessage } from '../../modules/message';
import { showError } from '../../modules/error';

import {hideLoader} from '../../modules/loader';
import Text from '../Text';
import Button from '../Button';
import Input from '../Input';
import Logo from '../static/Logo';
import Message from '../Message';

import './index.css';
import LoginContainer from './container';

import type { MessageType } from '../../constants/customTypes';

type DefaultProps = {
  message: MessageType;
};

export type Props = {
  ...DefaultProps,
  loginOperational: () => void,
  hideMessage: () => void,
  goToSignup: () => void,
  goToRequestPassword: () => void,
};

type State = {
  email: string,
  emailError: string,
  password: string,
  passwordError: string,
};

class LoginOperational extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    message: {
      messageType: '',
      show: false,
      text: '',
    },
  };

  state: State = {
    email: '',
    emailError: '',
    password: '',
    passwordError: '',
  };

  @boundMethod
  isLoggedIn() {
    if (localStorage.getItem('token')) {
      return true;
    }
    return false;
  }


  componentWillMount(){
    this.props.hideLoader();
  }
  notify = () => {
    toast.error('Error al iniciar sesión', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };




     checkPin() {
        if(!this.state.pin){
           this.props.showError('Es necesario el PIN');  
        } else {
           this.props.loginUserOperational(this.state.email, this.state.password, this.state.pin);
        }
    }


    render() {
      const {
        message,
        hideMessage, goToSignup, goToRequestPassword,
      } = this.props;

      const { email, password, pin } = this.state;

      if (this.isLoggedIn()) {
        return <Redirect to="/" />;
      }
      if (
        message.show
      && (message.messageType === 'error' || message.messageType === 'success')
      ) {
        setTimeout(hideMessage, 5000);
      }
      return (
        <LoginContainer>
          <ToastContainer />
          <Logo />
          <br />
          {message.show ? (
            <Message
              color={message.messageType}
              onClose={hideMessage}
            >
              {message.text}
            </Message>
          ) : null}

          <br />
          <Input
            type="text"
            onChange={(email: string) => {
              this.setState({ email });
            }}
            value={email}
            required
            placeholder="micorreo@gmail.com"
            label="Correo electrónico"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.checkPin();
              }
            }}
          />

          <Input
            type="number"
            onChange={(pin: string) => {
              this.setState({ pin });
            }}
            value={pin}
            required
            placeholder="*******"
            label="Pin"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.checkPin();
              }
            }}
          />


          <Input
            type="password"
            onChange={(password: string) => {
              this.setState({ password });
            }}
            value={password}
            required
            placeholder="*******"
            label="Contraseña"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.checkPin();
              }
            }}
          />


          <Button
            className="login-btn"
            onClick={() => {
                this.checkPin();
            }}
          >
          Ingresar
          </Button>

        </LoginContainer>
      );
    }
}

const mapStateToProps = state => ({
  message: state.message,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    goRoot: () => push('/'),
    goIntegrations: () => push('/'),
    loginUserOperational,
    hideMessage,
    hideLoader,
    showError,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginOperational);
