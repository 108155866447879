import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import Text from "../Text";

const avatarColors = ["#BB1A67", "#700f3d", " #e43d8d"];

const Avatar = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  color: #fff;
  border-radius: 100px;
  margin-right: 8px;
  padding: 5px;
  font-size: 16px;
  text-transform: uppercase;
  background: ${avatarColors[Math.floor(Math.random() * avatarColors.length)]};
`;

class Profile extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if(this.props.user) {
      return (
        <div className="profile">
          <Avatar>
            {`${
              this.props.user.first_name
                ? this.props.user.first_name.charAt(0)
                : ""
            }${
              this.props.user.last_name
                ? this.props.user.last_name.charAt(0)
                : ""
            }`}
          </Avatar>
          <Text>
            {this.props.user.first_name} {this.props.user.last_name}
          </Text>
        </div>
      );
    }
    <div className="profile"/>
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
