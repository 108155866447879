import React from 'react';
import { push, goBack } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Column } from 'hedron';
import { Route, Switch } from 'react-router-dom';

import { getUserInvoices } from '../../modules/Invoices/actions';
import Menu from '../../components/Menu';
import InvoiceList from '../../components/InvoiceList';
import InvoiceDetail from '../../components/InvoiceDetail';

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiced: false
    };
    this.props.getUserInvoices('false');
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.match.isExact && !this.props.match.isExact) {
  //     this.resetForms();
  //   }
  //   if (nextProps.location.pathname.indexOf('editar') > -1) {
  //     this.resetForms();
  //     if (nextProps.location.state.address) {
  //       const address = nextProps.location.state.address;
  //       this.setState({
  //         alias: address.alias,
  //         name: address.name,
  //         email: address.email,
  //         phone: address.phone,
  //         reference: address.reference,
  //         street: address.street,
  //         street2: address.street2,
  //         zipcode: address.zipcode,
  //       });
  //     } else {
  //       let address = null;
  //       const id = nextProps.location.pathname.split('/')[3];
  //       const results = this.props.addresses.results;
  //       results.forEach((a) => {
  //         if (a.object_id.toString() === id) {
  //           address = a;
  //           return;
  //         }
  //       });
  //       this.setState({
  //         alias: address.alias,
  //         name: address.name,
  //         email: address.email,
  //         phone: address.phone,
  //         reference: address.reference,
  //         street: address.street,
  //         street2: address.street2,
  //         zipcode: address.zipcode,
  //       });
  //     }
  //   }
  // }

  render() {
    return (
      <div>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: 'right' }} fluid>
            <Menu />
          </Column>
        </Row>
        <Switch>
          <Route path={`${this.props.match.url}/:id`} component={InvoiceDetail} />
          <Route exact path={`${this.props.match.url}`} component={InvoiceList} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  getUserInvoices,
  goToInvoiceList: () => push('/facturas'),
  goToInvoice: id => push(`/facturas/${id}`),
  goBack: () => goBack()
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoices);
