import React from 'react';
import axios from 'axios';
import { Row, Column } from 'hedron';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Text, { Title, P } from '../../components/Text';
import ShipmentItem from './ShipmentItem';
import './style.css';

const duumyData = [
  {
    id: '123234',
    origin: {
      name: 'Alan Abundis',
      email: 'alan@mienvio.mx',
      phone: '4427476410',
      street: 'calle',
      street2: 'colonia',
      municipality: 'Queretaro',
      state: 'Queretaro',
      country: 'MX',
    },
    destiny: {
      name: 'Alan Abundis',
      email: 'alan@mienvio.mx',
      phone: '4427476410',
      street: 'calle',
      street2: 'colonia',
      municipality: 'Queretaro',
      state: 'Queretaro',
      country: 'MX',
    },
    package: {
      dimensions: {
        height: 20,
        width: 20,
        length: 20,
      },
      weight: 3,
    },
    items: [
      {
        name: 'item',
        dimensions: {
          height: 20,
          width: 20,
          length: 20,
        },
        weight: 3,
      },
      {
        name: 'item',
        dimensions: {
          height: 20,
          width: 20,
          length: 20,
        },
        weight: 3,
      },
      {
        name: 'item',
        dimensions: {
          height: 20,
          width: 20,
          length: 20,
        },
        weight: 3,
      },
    ],
  },
  {
    id: '123234',
    origin: {
      name: 'Alan Abundis',
      email: 'alan@mienvio.mx',
      phone: '4427476410',
      street: 'calle',
      street2: 'colonia',
      municipality: 'Queretaro',
      state: 'Queretaro',
      country: 'MX',
    },
    destiny: {
      name: 'Alan Abundis',
      email: 'alan@mienvio.mx',
      phone: '4427476410',
      street: 'calle',
      street2: 'colonia',
      municipality: 'Queretaro',
      state: 'Queretaro',
      country: 'MX',
    },
    package: {
      dimensions: {
        height: 20,
        width: 20,
        length: 20,
      },
      weight: 3,
    },
    items: [
      {
        name: 'item',
        dimensions: {
          height: 20,
          width: 20,
          length: 20,
        },
        weight: 3,
      },
      {
        name: 'item',
        dimensions: {
          height: 20,
          width: 20,
          length: 20,
        },
        weight: 3,
      },
      {
        name: 'item',
        dimensions: {
          height: 20,
          width: 20,
          length: 20,
        },
        weight: 3,
      },
    ],
  },
  {
    id: '123234',
    origin: {
      name: 'Alan Abundis',
      email: 'alan@mienvio.mx',
      phone: '4427476410',
      street: 'calle',
      street2: 'colonia',
      municipality: 'Queretaro',
      state: 'Queretaro',
      country: 'MX',
    },
    destiny: {
      name: 'Alan Abundis',
      email: 'alan@mienvio.mx',
      phone: '4427476410',
      street: 'calle',
      street2: 'colonia',
      municipality: 'Queretaro',
      state: 'Queretaro',
      country: 'MX',
    },
    package: {
      dimensions: {
        height: 20,
        width: 20,
        length: 20,
      },
      weight: 3,
    },
    items: [
      {
        name: 'item',
        dimensions: {
          height: 20,
          width: 20,
          length: 20,
        },
        weight: 3,
      },
      {
        name: 'item',
        dimensions: {
          height: 20,
          width: 20,
          length: 20,
        },
        weight: 3,
      },
      {
        name: 'item',
        dimensions: {
          height: 20,
          width: 20,
          length: 20,
        },
        weight: 3,
      },
    ],
  },
  {
    id: '123234',
    origin: {
      name: 'Alan Abundis',
      email: 'alan@mienvio.mx',
      phone: '4427476410',
      street: 'calle',
      street2: 'colonia',
      municipality: 'Queretaro',
      state: 'Queretaro',
      country: 'MX',
    },
    destiny: {
      name: 'Alan Abundis',
      email: 'alan@mienvio.mx',
      phone: '4427476410',
      street: 'calle',
      street2: 'colonia',
      municipality: 'Queretaro',
      state: 'Queretaro',
      country: 'MX',
    },
    package: {
      dimensions: {
        height: 20,
        width: 20,
        length: 20,
      },
      weight: 3,
    },
    items: [
      {
        name: 'item',
        dimensions: {
          height: 20,
          width: 20,
          length: 20,
        },
        weight: 3,
      },
      {
        name: 'item',
        dimensions: {
          height: 20,
          width: 20,
          length: 20,
        },
        weight: 3,
      },
      {
        name: 'item',
        dimensions: {
          height: 20,
          width: 20,
          length: 20,
        },
        weight: 3,
      },
    ],
  },
];
class shipmentsReady extends React.Component {
  componentWillMount() {
  }

  render() {
    return (
      <div>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: 'right' }} fluid>
            <Menu />
          </Column>
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Header>
              <Row divisions={18}>
                <Column xs={7} fluid>
                  <Title type="titleCard">Ordenes a Empacar</Title>
                </Column>
              </Row>
            </Header>
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={17} fluid>
            {duumyData.map(element => <ShipmentItem data={element} />)}
          </Column>
          <Column xs={1} fluid />
        </Row>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  sidebar: state.sidebar,
  message: state.message,
  user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(shipmentsReady);
