import React from "react";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggle as toggleSidebar } from "../../../modules/CollapsibleBar/actions";
import Dropzone from "../../Dropzone";
import { Row, Column } from "hedron";
import {
  ContentAdapter,
  BottomContainer,
} from "../../WrapperSideBar/styledObjects";
import { CheckboxGroup, Checkbox } from "../../Checkbox";
import Text from "../../Text";
import Input, { Required, Label } from "../../Input";
import { SimpleCheckbox } from "../../Checkbox/simple_checkbox";
import "../style.css";
import Button from "../../Button";
import { getBase64 } from "../../../utils/global_functions";
import Skeleton from "react-loading-skeleton";
import {
  createStorefront,
  updateStorefront,
} from "../../../modules/automatization/storefronts";

class StorefrontConfig extends React.Component {
  state = {
    id: false,
    send_tracking: false,
    custom_tracking_email: false,
    name: "",
    nameError: "",
    email: "",
    emailError: "",
    logo: false,
    logo_url: "",
    isLoading: true,
  };

  componentWillMount() {
    axios
      .get("/api/storefronts")
      .then((r) => {
        if (r.data)
          this.setState({
            isLoading: false,
            send_tracking: r.data.storefronts[0].send_tracking,
            custom_tracking_email: r.data.storefronts[0]?.custom_tracking_email,
            name: r.data.storefronts[0].name,
            email: r.data.storefronts[0].email,
            logo_url: r.data.storefronts[0].logo_url,
            id: r.data.storefronts[0].id,
          });
        else
          this.setState({
            isLoading: false,
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false, creating: true });
      });
  }

  handleCheckboxChange = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleChange = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length == 1)
      getBase64(acceptedFiles[0]).then((image) => {
        this.setState({
          logo: image,
          logo_url: image,
        });
      });
  };

  onDelete = () => {
    this.setState({
      logo: false,
      logo_url: false,
    });
  };

  save = () => {
    this.setState({ isLoading: true });
    const payload = {
      name: this.state.name,
      email: this.state.email,
      logo: this.state.logo ? this.state.logo.split(",")[1] : "", // To remove metadata
      send_tracking: this.state.send_tracking,
      custom_tracking_email: this.state.custom_tracking_email,
    };
    if (this.state.id > 0) {
      this.props.updateStorefront(this.state.id, payload).then(() => {
        this.props.update();
        this.props.toggleSidebar();
      });
    } else {
      this.props.createStorefront(payload).then(() => {
        this.props.update();
        this.props.toggleSidebar();
      });
    }
  };

  showSkeletons = () => {
    return (
      <div style={{ marginTop: "1.5rem" }}>
        <div style={{ marginBottom: "1.5rem" }}>
          <Skeleton />
        </div>
        <div style={{ marginBottom: "1.5rem" }}>
          <Skeleton height={40} />
        </div>
        <div style={{ marginBottom: "1.5rem" }}>
          <Skeleton />
        </div>
        <div style={{ marginBottom: "1.5rem" }}>
          <Skeleton height={180} />
        </div>
        <div style={{ marginBottom: "1.5rem" }}>
          <Skeleton height={32} />
        </div>
        <div style={{ marginBottom: "1.5rem" }}>
          <Skeleton height={32} />
        </div>
      </div>
    );
  };

  showForm = () => {
    const mainProps = {
      generateCheckbox: (name) => {
        return {
          onChange: (value) => {
            this.handleCheckboxChange(name);
          },
          checked: this.state[name],
          id: name,
        };
      },
      generate: (name) => {
        return {
          onChange: (value) => {
            this.handleChange(value, name);
          },
          value: this.state[name],
          error: this.state[`${name}Error`],
        };
      },
    };
    return (
      <Row>
        {/* <Column fluid>
                    <SimpleCheckbox
                        title="Enviar tracking a mis clientes"
                        {...mainProps.generateCheckbox('send_tracking')}
                    />
                    <Label>Enviar mails con el seguimiento del envío a mis clientes (Correo electronico de destino). Cada que un nuevo envio es recolectado.</Label>
                    <SimpleCheckbox
                        title="Personalizar correo de seguimiento"
                        {...mainProps.generateCheckbox('custom_tracking_email')}
                    />
                </Column>*/}
        <Column fluid>
          <Dropzone
            required
            label="Logotipo"
            accept="image/png,image/jpg,image/jpeg"
            imageSrc={this.state.logo_url}
            onDrop={this.onDrop}
            onDelete={this.onDelete}
          />

          <Input
            type="text"
            {...mainProps.generate("name")}
            required
            placeholder="Tony Delfino"
            label="Nombre de la tienda"
            help="Este texto se utiliza para el cuerpo de  texto del correo. Por ejemplo tu compra de www.tienda.mx ya esta en camino"
          />
          <Input
            type="text"
            {...mainProps.generate("email")}
            required
            placeholder="ayuda@delfino.mx"
            label="Mail de atención al cliente de tu tienda"
            help="Este texto se utiliza para el cuerpo de  Utiliza tu correo de soporte, a donde tus clientes te puedan escribir para verificar cualquier error con el envío de su compratexto del correo. Por ejemplo tu compra de www.tienda.mx ya esta en camino"
          />
        </Column>
      </Row>
    );
  };

  render() {
    return (
      <ContentAdapter className="storefront-editor">
        {this.state.isLoading ? this.showSkeletons() : this.showForm()}
        <Text style={{ marginTop: "1rem", fontSize: "11px" }}>
          Ver ejemplo de tracking{" "}
          <Button
            type="link"
            onClick={() => (window.location.href = "/tracking/storefront")}
          >
            <i className="ion-android-open" />
          </Button>
        </Text>
        <BottomContainer>
          <Row divisions={20}>
            <Column style={{ textAlign: "right" }} fluid>
              <Button
                className=""
                onClick={() => this.save()}
                loading={this.state.isLoading}
              >
                Guardar
              </Button>
            </Column>
          </Row>
        </BottomContainer>
      </ContentAdapter>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSidebar,
      createStorefront,
      updateStorefront,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StorefrontConfig);
