import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import shipments from './shipments/reducers';
import user from './user';
import addresses from './addresses/reducers';
import loader from './loader';
import message from './message';
import zipCodes from './zipCodes';
import invoices from './Invoices';
import shipmentsCSV from './ShipmentCSV';
// import error from "./error";
import cards from './cards';
import integrations from './integrations';
import rates from './rates';
import packages from './packages';
import newLabel from './newLabel';
import newShipment from './sidebarOrder';
import sidebar from './CollapsibleBar';
import quickQuote from './QuickQuote';
import dashboard from './dashboard';
import transactions from './transactions';

export default combineReducers({
  routing: routerReducer,
  shipments,
  user,
  addresses,
  invoices,
  loader,
  message,
  zipCodes,
  cards,
  rates,
  integrations,
  newLabel,
  packages,
  sidebar,
  quickQuote,
  newShipment,
  dashboard,
  shipmentsCSV,
  transactions,
});
