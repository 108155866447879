import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";

import { getUser } from "../../modules/user";

import Text from "../Text";
import colors from "../../utils/colors";

const avatarColors = ["#BB1A67", "#700f3d", " #e43d8d"];

const StyledMenu = styled.div`
  margin-right: 24px;
  position: relative;
  margin-top: 19px;
  * {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media screen and (max-width: 39.9375em) {
    display: none;
  }
`;

const StyledInformation = styled.i`
  color: ${colors.gray.charcoal};
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
`;

// const StyledBell = styled.i`
//   color: ${colors.gray.creamy_gray};
//   margin-right: 20px;
//   font-size: 20px;
// `;

const Avatar = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  color: #fff;
  border-radius: 100px;
  margin-right: 8px;
  padding: 5px;
  font-size: 16px;
  text-transform: uppercase;
  background: ${avatarColors[Math.floor(Math.random() * avatarColors.length)]};
`;

const I = styled.i`
  cursor: pointer;
  font-size: 20px;
  margin-top: 2px;
  margin-left: 5px;
`;

const Dropdown = styled.div`
  position: absolute;
  width: 163px;
  bottom: -48px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
    0 0 12px 7px rgba(208, 208, 208, 0.48);
  right: 0;
  cursor: pointer;
  z-index: 10;
`;

const DropdownHelp = styled.div`
  position: absolute;
  width: 163px;
  bottom: -94x;
  height: 84px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
    0 0 12px 7px rgba(208, 208, 208, 0.48);
  right: 20px;
  cursor: pointer;
  z-index: 100;
`;

const UserLink = styled.div`
  width: 100%;
  height: 42px;
  line-height: 42px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 12px;
  &:hover {
    background: #e2e2e2;
  }
`;

const LogoutLink = UserLink.extend`
  background: ${colors.gray.snow};
`;
const LogoutIcon = styled.i`
  float: right;
  color: ${colors.gray.pigeon_gray};
  margin-right: 12px;
`;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      dropdownHelp: false,
    };
    this.props.getUser();
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleDropdownHelp = this.toggleDropdownHelp.bind(this);
  }

  toggleDropdown() {
    this.setState({ dropdown: !this.state.dropdown });
  }

  toggleDropdownHelp() {
    this.setState({ dropdownHelp: !this.state.dropdownHelp });
  }

  logout() {
    const cookies = new Cookies();
    cookies.remove("active_session");
    localStorage.clear();
    window.location = "/login";
  }

  render() {
    if (this.props.user) {
      return (
        <div>
          <StyledMenu>
            <span style={{ position: "relative" }}>
              <StyledInformation
                className="ion-help-circled"
                onClick={() => this.toggleDropdownHelp()}
              />
              {this.state.dropdownHelp ? (
                <DropdownHelp>
                  <UserLink
                    onClick={() =>
                      window.open(
                        "https://blogger.mienvio.mx/centro-ayuda/guia-de-inicio/",
                        "blank"
                      )
                    }
                  >
                    <Text>Guía de inicio</Text>
                  </UserLink>
                  <UserLink
                    onClick={() =>
                      window.open("https://blogger.mienvio.mx/", "blank")
                    }
                  >
                    <Text>Centro de ayuda</Text>
                  </UserLink>
                </DropdownHelp>
              ) : null}
            </span>
            <span
              onClick={() => this.toggleDropdown()}
              style={{ cursor: "pointer" }}
            >
              <Avatar>
                {`${
                  this.props.user.first_name
                    ? this.props.user.first_name.charAt(0)
                    : ""
                }${
                  this.props.user.last_name
                    ? this.props.user.last_name.charAt(0)
                    : ""
                }`}
              </Avatar>
              <Text>
                {this.props.user.first_name} {this.props.user.last_name}{" "}
                <I className="ion-ios-arrow-down" />
              </Text>
            </span>
            {this.state.dropdown ? (
              <Dropdown>
                <LogoutLink onClick={() => this.logout()}>
                  <Text>Cerrar sesión</Text>
                  <LogoutIcon className="ion-android-exit" />
                </LogoutLink>
              </Dropdown>
            ) : null}
          </StyledMenu>
        </div>
      );
    }
    return <StyledMenu />;
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
