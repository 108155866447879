import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Column, Row } from "hedron";
import axios from "axios";
import moment from "moment/min/moment-with-locales";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Shipment from "./shipment";
import Button from "../../Button";
import Text from "../../Text";
import { CHECKOUT_DETAILS } from "../../../modules/CollapsibleBar/sideBarTypes";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../../modules/CollapsibleBar/actions";
import { clearSidebar } from "../../../modules/sidebarOrder/actions";
import { showMessage, hideMessage } from "../../../modules/message";
import { getAllUserShipments } from "../../../modules/shipments/actions";

class CheckoutDetails extends Component {
  intervalID = 0;

  state = {
    topClass: "ion-chevron-up",
    downClass: "ion-chevron-down",
    isHeaderOpen: true,
    loading: false,
    selected: [],
    shipments: [],
  };

  componentWillMount(): void {
    this.setState({
      selected: [],
    });
  }

  updateAllShipments = async () => {
    await this.props.getAllUserShipments();
  };

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentDidMount(): void {
    if (this.props.invoice.purchase) {
      if (this.props.invoice.purchase.shipments) {
        this.props.invoice.purchase.shipments.map((ship) => {
          const arrayTmp = ship.label == null ? [] : [ship.object_id];
          this.setState({
            shipments: [
              ...this.state.shipments,
              { object_id: ship.object_id, label: ship.label },
            ],
            selected: [...this.state.selected, ...arrayTmp],
          });
        });

        this.setState({
          shipments: this.props.invoice.purchase.shipments,
        });

        this.intervalID = setInterval(this._reloadLabels, 10000);
      }
    }
  }

  hideHeader = () => {
    this.setState({ isHeaderOpen: !this.state.isHeaderOpen });
  };

  _handleOnChange = (value) => {
    this.setState({
      selected: value,
    });
  };

  _reloadLabels = () => {
    if (
      this.props.sidebar.type == "CHECKOUT_DETAILS" &&
      this.props.sidebar.open
    ) {
      if (this.props.invoice.purchase) {
        this.state.shipments.map((ship, index) => {
          axios
            .get(`/api/shipments/${ship.object_id}`)
            .then((response) => {
              this._updateLabelByShipment(
                response.data.shipment.label,
                response.data.shipment.status,
                index
              );

              if (
                this.state.shipments.length == 1 &&
                response.data.shipment.status === "MANUAL_LABEL"
              ) {
                setInterval(this.props.toggleSideBar(false), 90000);
                this.props.showMessage(
                  "success",
                  "La guía tardará en generarse más de lo esperado. En unos minutos te la enviaremos por correo."
                );
              }

              this.updateAllShipments();
            })
            .catch((error) => {
              console.log(error);
            });
        });
        clearInterval(this.intervalID);
      }
    }
  };

  _updateLabelByShipment = (label, status, index) => {
    const tmpShipments = this.state.shipments;
    tmpShipments[index].label = label;
    tmpShipments[index].status_label = status;
    this.setState({
      shipments: tmpShipments,
    });
  };

  downloadGuides = () => {
    if (this.state.selected.length > 0) {
      const { shipments } = this.state;
      const labels = [];
      shipments.forEach((shipment) => {
        this.state.selected.forEach((se) => {
          if (Number(shipment.object_id) === Number(se)) {
            labels.push(shipment.label.label_url);
          }
        });
      });

      let url;
      const zip = new JSZip();

      while ((url = labels.pop())) {
        const url_name = url.split("/");
        const filename = url_name[url_name.length - 1];

        const promise = fetch(url)
          .then((response) => {
            if (response.status === 200 || response.status === 0) {
              return Promise.resolve(response.blob());
            }
            return Promise.reject(new Error(response.statusText));
          })
          .catch((e) => {});

        if (filename.includes(".pdf") && url.includes("amazonaws")) {
          /* prevent errors from dropdox and other sources */
          zip.file(filename, promise, { base64: true });
        } else {
          window.open(url);
        }
      }

      if (zip.file(/\s*/).length > 0) {
        /* is there at least one file to zip? */
        zip
          .generateAsync({ type: "blob" })
          .then((content) => {
            const today = moment(Date.now()).format("DD-MM-YYYY_hh.mm.ss");
            saveAs(content, `guias_${today}.zip`);
          })
          .catch((e) => {});
      }
    }
  };

  _getLabel = (ship) => {
    const result = this.state.shipments.find(
      (shipment) => shipment.object_id == ship
    );
    return result ? result.label : null;
  };

  render() {
    return (
      <div className="checkout-details">
        <div className="border-wrapper">
          <div className="next-steps">
            <div className="title-header">
              Siguientes pasos:
              <Button
                type="link"
                className="btn-hide-header"
                onClick={this.hideHeader}
              >
                {this.state.isHeaderOpen ? "Ocultar" : "Mostrar"}
                <i
                  className={
                    this.state.isHeaderOpen
                      ? this.state.topClass
                      : this.state.downClass
                  }
                />
              </Button>
            </div>
            {this.state.isHeaderOpen && (
              <div>
                <Text>1. Descarga e imprime las guías.</Text>
                <Text>2. Pega las guías en tus paquetes.</Text>
                <Text>
                  3. Programa tu recolección o entrega directo en la paquetería.
                </Text>
                <Text>4. Haz seguimiento del paquete.</Text>
              </div>
            )}
          </div>
        </div>
        <div className="margin-wrapper">
          <Text className="title-orders">Descarga las guías compradas</Text>
          {this.props.invoice.purchase && (
            <div className="list-orders">
              {this.props.invoice.purchase.shipments.map((shipment) => (
                <Shipment
                  key={shipment.object_id}
                  model={shipment}
                  handleOnChange={this._handleOnChange}
                  label={this._getLabel(shipment.object_id)}
                  selected={this.state.selected}
                  shipmentsList={this.state.shipments}
                />
              ))}
            </div>
          )}
          <Row>
            <Column xs={6} fluid />
            <Column xs={6} style={{ textAlign: "right" }} fluid>
              {this.state.selected.length > 0 ? (
                <Button className="btn-send" onClick={this.downloadGuides}>
                  {`Descargar (${this.state.selected.length}) guías`}
                </Button>
              ) : (
                <Button
                  className="btn-send"
                  onClick={() => {}}
                  type="disabled_table"
                >
                  {"Descargar (0) guías"}
                </Button>
              )}
            </Column>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoices,
  sidebar: state.sidebar,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSideBar,
      changeSidebarTitle,
      clearSidebar,
      showMessage,
      hideMessage,
      getAllUserShipments,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDetails);
