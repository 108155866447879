import React from 'react';
import Iframe from 'react-iframe';
import moment from 'moment/min/moment-with-locales';
import NewTable from '../../../components/NewTable';
import { shorten } from '../../../utils/global_functions';

const columns = [
  {
    name: 'N° Orden',
    selector: 'id',
    sortable: true,
    cell: row => (
      <div>
        <p>
          #
          {row.object_id}
        </p>
      </div>
    ),
  },
  {
    name: 'Fecha',
    selector: 'date',
    cell: (row) => {
      const shipmentDate = moment(row.created_at.split(' ')[0]);
      const today = new Date();
      const timeDiff = Math.abs(today - shipmentDate);
      const daysDiff = Math.floor(timeDiff / (3600 * 24 * 1000));
      return (
        <div>
          <p>{shipmentDate.format('DD-MM-YYYY')}</p>
          <p className="minitext">
            {daysDiff < 1 ? 'hoy' : `hace ${daysDiff} ${daysDiff > 1 ? 'días' : 'día'}`}
          </p>
        </div>
      );
    },
  },
  {
    name: 'Destinatario',
    selector: 'name',
    cell: row => shorten(row.address_to.name, 16),
  },
  {
    name: 'Destino',
    selector: 'address_to',
    cell: (row) => {
      const street = row.address_to.street.split('|');
      return (
        <div>
          <p>{shorten(street[0], 16)}</p>
          <p className="minitext">{street[1]}</p>
        </div>
      );
    },
  },
  {
    name: 'Total',
    selector: 'id',
    sortable: false,
    cell: row => (
      <div>
        <p>
          $
          {parseFloat(row.declared_value).toFixed(2)}
        </p>
      </div>
    ),
  },
];

const ExpandedSection = ({ data }) => {
  if (data) {
    return (
      <div className="container-subIframe">
        <Iframe
          url={data.label && data.label.label_url}
          className="iframe"
          display="initial"
          position="relative"
        />
      </div>
    );
  }
  return <div />;
};
const GuideModal = ({data}) => (
  <div>
    <NewTable
      expandableRows
      expandableRowsComponent={<ExpandedSection />}
      shipping={false}
      columns={columns}
      data={data}
    />
  </div>
);

export default GuideModal;
