import React from 'react';
import { Column, Row } from 'hedron';
import Input from '../../../components/Input';
import Text, { P }from '../../../components/Text';
import Button from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';

const Package = ({
  manual,
  onChangeHeight,
  height,
  heightError,
  onChangeWidth,
  width,
  widthError,
  length,
  lengthError,
  onChangeLenght,
  onChangeWeight,
  weight,
  weightError,
  packages,
  pack,
  selectedPack,
  selectedTitle,
  handleChange,
  handleFilterChange,
  changeManual,
  context,
  error,
  filter,
  description,
}) => (
  <div>
    {manual ? (
      <div>
        <Row>
          <Column xs={12} md={12} className="no-padding-left">
           <Text type="microHeader" className="package-title">
           <strong>Descripcion</strong> : {description} 
           </Text>
            <Text type="microHeader" className="package-title">
              Dimensiones
            </Text>
            <Row>
              <Column xs={3} fluid>
                <Input
                  label="Alto"
                  placeholder="30"
                  suffix="x"
                  type="number"
                  onChange={onChangeHeight}
                  value={height}
                  error={heightError}
                  required
                />
              </Column>
              <Column xs={3} fluid>
                <Input
                  label="Ancho"
                  placeholder="30"
                  suffix="x"
                  type="number"
                  onChange={onChangeWidth}
                  value={width}
                  error={widthError}
                  required
                />
              </Column>
              <Column xs={3} fluid>
                <Input
                  label="Largo"
                  placeholder="30"
                  suffix="cm"
                  type="number"
                  onChange={onChangeLenght}
                  value={length}
                  error={lengthError}
                  required
                />
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <Column xs={3} fluid>
            <Input
              label="Peso"
              placeholder="30"
              suffix="kg"
              type="number"
              onChange={onChangeWeight}
              value={weight}
              error={weightError}
              required
            />
          </Column>
        </Row>
        <Button type="link" onClick={() => changeManual(context)}>
          Ver empaques guardados
        </Button>
      </div>
    ) : (
      <div>
        <Text type="textTable" className="package-title">
          {height && width && length && 
            `Las medidas actuales son: ${height} x ${width} x ${length} cm`
          }
        </Text>
        <Dropdown
          options={packages}
          placeholder={<Text className="dropdown-placeholder">Buscar por alias</Text>}
          selected={selectedPack}
          selectedTitle={pack ? pack.alias : null}
          filter
          error={error}
          filterValue={filter}
          filterPlaceholder="Buscar por alias"
          handleChange={handleChange}
          handleFilterChange={handleFilterChange}
        />
        <Row>
          <Column xs={3} fluid>
            <Input
              label="Peso"
              placeholder="30"
              suffix="kg"
              type="number"
              onChange={onChangeWeight}
              value={weight}
              error={weightError}
              required
            />
          </Column>
        </Row>
        <Button type="link" onClick={() => changeManual(context)}>
          Agregar medidas manualmente
        </Button>
      </div>
    )}
  </div>
);
export default Package;
