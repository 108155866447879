import React from 'react';
import { Step } from '../Tabs';

const TabStep = props => (
  <Step
    selected={props.selected}
    icon_status={props.icon_status}
    value={props.value}
    shakeClass={props.shakeClass && props.shakeClass }
  >
    {props.children}
  </Step>
);

export default TabStep;
