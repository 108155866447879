type State = {
  +step?: number,
  +stepTitles?: Array<any>,
  +open: boolean,
  +title?: string
};

export const initialState: State = {
  step: 0,
  stepTitles: [],
  open: false,
  title: '',
  addresses: {},
  fromSelectIdAddress: null,
  fromAddress: null,
  toSelectIdAddress: null,
  toAddress: null,
  loading: false,
  isQuickQuote: false,
  packages: {},
  selectedIdPackage: null,
  selectedPackage: null,
  carrier: null,
  carrierId: null,
  errorRates: null,
  loadingCost: false,
  loadingZipCode: false,
  oldRate: null,
  oldOrigin: null,
  oldDestiny: null,
  oldDimensions: {
    length: '',
    width: '',
    height: ''
  },
  oldWeight: '',
  isEditing: false,
  object_id: null,
  marketplace: null,
  shop_id: null,
  manualFrom: false,
  manualTo: false,
  manualPackage: false,
  isDevolution: false,
  stepError: {
    from: '',
    to: '',
    package: ''
  },
  data: {
    from: {
      alias: '',
      aliasError: '',
      name: '',
      nameError: '',
      email: '',
      emailError: '',
      phone: '',
      phoneError: '',
      country: null,
      countryError: '',
      neighborhood: '',
      neighborhoodError: '',
      zipcode: '',
      zipcodeError: '',
      city: '',
      cityError: '',
      street: '',
      streetError: '',
      reference: '',
      referenceError: '',
      saveAddress: false
    },
    to: {
      alias: '',
      aliasError: '',
      name: '',
      nameError: '',
      email: '',
      emailError: '',
      phone: '',
      phoneError: '',
      country: null,
      countryError: '',
      neighborhood: '',
      neighborhoodError: '',
      zipcode: '',
      zipcodeError: '',
      city: '',
      cityError: '',
      street: '',
      streetError: '',
      reference: '',
      referenceError: '',
      saveAddress: false
    },
    package: {
      alias: '',
      aliasError: '',
      packageType: null,
      packageTypeError: '',
      dimensions: {
        length: '',
        width: '',
        height: ''
      },
      dimensionsError: '',
      weight: '',
      weightError: '',
      volumetricWeight: '',
      description: '',
      descriptionError: '',
      insuredAmount: '',
      insuredAmountError: '',
      haveInsurance: null,
      cost: '',
      savePackage: false
    },
    carrier: {
      shipment: null
    }
  },
  countries: [],
  commercialInvoice: {
    invoice_no: '',
    purchase_order: '',
    payment_terms: '',
    incoterm: '',
    incoterm_example: '',
    export_reason: '',
    special_instructions: '',
    declaration_statements: '',
    packages: '',
    from_tax: '',
    to_tax: ''
  },
  openInvoice: false
};
