import React from 'react';
import styledProps from 'styled-props';
import styled from 'styled-components';
import colors from '../../utils/colors';
import Text from '../Text';
import './index.css';

const sizes = {
  default: `calc(100% - 32px)`,
  full: '100%'
};

const textAlign = {
  left: 'left',
  right: 'right',
  center: 'center'
};

const StyledTh = styled.th`
  padding: 18px 0;
  text-align: ${styledProps(textAlign, 'textAlign')};
`;
StyledTh.defaultProps = {
  textAlign: 'left'
};
const border = {
  default: `1px solid ${colors.primary.whitesmoke}`,
  noBorder: 'none'
};
const StyledTr = styled.tr`
  td,
  th {
    border-bottom: ${styledProps(border, 'border')};
  }
`;
const StyledTd = styled.td`
  height: 56px;
  max-height: 56px;
  box-sizing: border-box;
  padding: 0;
  white-space: nowrap;
  width: 1px;
  text-align: ${styledProps(textAlign, 'textAlign')};
`;
StyledTd.defaultProps = {
  textAlign: 'left'
};
const StyledTable = styled.table`
  margin: 24px 0px;
  width: ${styledProps(sizes, 'size')};
  margin-top: 6px;
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
`;
StyledTable.defaultProps = {
  size: 'default'
};

const formatString = text => {
  if (typeof text === 'string' && text.length > 15) {
    return `${text.substring(0, 15)}...`;
  }
  return text;
};

const Th = props => (
  <StyledTh textAlign={props.textAlign} colSpan={props.colSpan}>
    {props.checkbox ? (
      props.children
    ) : (
      <Text type="label" className={props.pClassName}>
        {props.children}
      </Text>
    )}
  </StyledTh>
);

const Tr = props => (
  <StyledTr
    className={props.className}
    onClick={props.onClick}
    border={props.noBorder ? 'noBorder' : 'default'}
  >
    {props.children}
  </StyledTr>
);

const Td = props => (
  <StyledTd
    textAlign={props.textAlign}
    className={props.className}
    onClick={props.onClick}
    style={props.style}
  >
    {props.checkbox ? (
      props.children
    ) : (
      <Text type="textTable" style={props.pStyle}>
        {props.noFormat ? props.children : formatString(props.children)}
      </Text>
    )}
  </StyledTd>
);

class Table extends React.Component {
  render() {
    return (
      <div style={this.props.style}>
        <StyledTable size={this.props.size}>{this.props.children}</StyledTable>
      </div>
    );
  }
}

export default Table;
export { Th, Tr, Td };
