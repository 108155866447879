import axios from "axios";
import type { Dispatch as ReduxDispatch } from "redux";
import {
  TOGGLE,
  SET_STEP_TITLES,
  SET_STEP,
  PREVIOUS_STEP,
  NEXT_STEP,
  SET_VALUE,
  SET_ERROR,
  UNSET_ERROR,
  SET_ICON,
  GET_ADDRESSES_SIDEBAR,
  FINISH_LOAD_ADDRESSES,
  INIT_LOAD_ADDRESSES,
  SELECT_ADDRESS_FROM,
  SELECT_ID_ADDRESS,
  CLEAR_ADDRESS_FROM,
  SELECT_ADDRESS_TO,
  CLEAR_ADDRESS_TO,
  GET_PACKAGES_SIDEBAR,
  SELECT_PACKAGE,
  CLEAR_PACKAGE,
  CLEAR_SIDEBAR,
  ERROR_GETTING_RATES,
  INIT_GETTING_RATES,
  FINISH_GETTING_RATES,
  CHANGE_LOADING_COST,
  SEND_INFO_DUPLICATE_ORDER,
  SEND_INFO_EDIT_ORDER,
  SET_OLD_INFO,
  GENERATE_LABEL_MARKETPLACE,
  SET_ICON_ANY,
  IS_LOADING_ZIPCODE,
  CHANGE_MANUAL_INFO,
  CHANGE_LOADING_SIDEBAR,
  CHANGE_STEP_ERROR,
  OPEN_COMMERCIAL_INVOICE,
  CLOSE_COMMERCIAL_INVOICE,
  COMMERCIAL_INVOICE,
  GET_COUNTRIES_SIDEBAR_ORDER,
  SET_OLD_RATE,
  SEND_INFO_DEVOLUTION,
} from "./actionsTypes";
import { showError } from "../error";
import type { Address } from "../addresses";
import type { Package } from "../packages";
import { showMessage } from "../message";
import { RECEIVED_RATES } from "../rates";

const addressesUrl = "/api/addresses";
const commercialInvoiceUrl = "api/international/invoice";
const packagesUrl = "/api/packages";
const shipmetsUrl = "/api/shipments";
const countriesUrl = "/api/international/countries";

type Action_RECEIVED_RATES = { type: "RECEIVED_RATES", payload: Rates };

type Dispatch = ReduxDispatch<Action>;

type AddressesInfo = {
  total_count: number,
  total_pages: number,
  current_page: number,
  results: Array<Address>,
};

type PackageInfo = {
  total_count: number,
  total_pages: number,
  current_page: number,
  results: Array<Package>,
};

export const toggle = (open: boolean, title?: string) => ({
  type: TOGGLE,
  payload: {
    open,
    title: title || "",
  },
});

export const setStepTitles = (stepTitles: Array<any>) => ({
  type: SET_STEP_TITLES,
  payload: stepTitles,
});

export const setStep = (step: number) => ({ type: SET_STEP, payload: step });

export const nextStep = () => ({ type: NEXT_STEP });

export const previousStep = () => ({ type: PREVIOUS_STEP });

export const setValue = (name: string, value: string) => ({
  type: SET_VALUE,
  payload: {
    name,
    value,
  },
});

export const setError = (field: string, error: string) => ({
  type: SET_ERROR,
  payload: {
    field,
    error,
  },
});
export const setOldRate = (id) => ({
  type: SET_OLD_RATE,
  id,
});

export const unsetError = (field: string) => ({
  type: UNSET_ERROR,
  payload: {
    field,
  },
});

export const setIcon = (icon: string) => ({
  type: SET_ICON,
  payload: {
    icon,
  },
});

export const setIconAny = (icon: string, step) => ({
  type: SET_ICON_ANY,
  payload: {
    icon,
    step,
  },
});

export const setUserAddresses = (addresses: AddressesInfo) => ({
  type: GET_ADDRESSES_SIDEBAR,
  payload: addresses,
});

export const selectAddress = (id, address) => ({
  type: SELECT_ADDRESS_FROM,
  payload: { id, address },
});

export const clearSelectAddressFrom = () => ({
  type: CLEAR_ADDRESS_FROM,
});

export const selectAddressTo = (id, address) => ({
  type: SELECT_ADDRESS_TO,
  payload: { id, address },
});

export const clearSelectAddressTo = () => ({
  type: CLEAR_ADDRESS_TO,
});

export const setUserPackages = (packages: PackageInfo) => ({
  type: GET_PACKAGES_SIDEBAR,
  payload: packages,
});

export const selectPackage = (id, pack) => ({
  type: SELECT_PACKAGE,
  payload: { id, pack },
});

export const clearPackage = () => ({
  type: CLEAR_PACKAGE,
});

export const clearSidebar = () => ({
  type: CLEAR_SIDEBAR,
});

export const sendDataInvoice = (data) => (dispatch: Dispatch) =>
  axios
    .post("api/international/invoice", data)
    .then((res) => {})
    .catch((err) => {
      dispatch(showError(err.response.data.error));
    });

const getPresetAddress = (data) => (dispatch) => {
  if (data.results) {
    const idIndexPrimary = data.results.findIndex(
      (address) => address.object_type == "PRIMARY" && address.active
    );
    const idIndexDefaultTo = data.results.findIndex(
      (address) => address.object_type == "DEFAULT_TO" && address.active
    );
    if (idIndexPrimary >= 0) {
      setTimeout(() => {
        dispatch(selectAddress(idIndexPrimary, data.results[idIndexPrimary]));
        try {
          dispatch(setIconAny("completed", 0));
        } catch (e) {
          console.log(e);
        }
      }, 50);
    }
    if (idIndexDefaultTo >= 0) {
      setTimeout(() => {
        dispatch(
          selectAddressTo(idIndexDefaultTo, data.results[idIndexDefaultTo])
        );
        try {
          dispatch(setIconAny("completed", 1));
        } catch (e) {
          console.log(e);
        }
      }, 50);
    }
  }
};

export const getUserAllAddresses = (preset) => (dispatch: Dispatch) =>
  axios
    .get(`${addressesUrl}?page=1&limit=1000000000`)
    .then((response) => {
      dispatch(setUserAddresses(response.data));
      dispatch(getPresetAddress(response.data));
    })
    .catch((err) => {
      dispatch(showError(err.response.data.error));
    });

export const getUserAllPackages = () => (dispatch: Dispatch) =>
  axios
    .get(`${packagesUrl}?page=1&limit=1000000000`)
    .then((response) => {
      dispatch(setUserPackages(response.data));
    })
    .catch((err) => {
      dispatch(showError(err.response.data.error));
    });

export const createInvoice = (invoice: CommercialInvoice) => (
  dispatch: Dispatch
) =>
  axios
    .post(commercialInvoiceUrl, {
      date: this.state.data.date,
      declaration_statements: this.state.data.declarations,
      from_address: this.props.sidebar.fromAddress.object_id,
      incoterm: this.state.data.incoterm,
      incoterm_example: this.state.data.incoterm_example,
      special_instructions: this.state.data.instructions,
      invoice_no: this.state.data.invoice,
      payment_terms: this.state.data.payment,
      purchase_order: this.state.data.purchase,
      export_reason: this.state.data.reason,
      sold_address: this.props.sidebar.toAddress.object_id,
      to_address: this.props.sidebar.toAddress.object_id,
      from_tax: this.state.data.fromId,
      to_tax: this.state.data.toId,
    })
    .then((response) => {
      dispatch(showMessage("success", "Factura Comercial Generada"));
    })
    .catch((err) => {
      dispatch(showError(err.response.data.error));
    });

export const createAddress = (address: Address, to) => (dispatch: Dispatch) =>
  axios
    .post(addressesUrl, {
      object_type: "FROMTO",
      name: address.name,
      street: address.street,
      street2: address.street2,
      zipcode: address.zipcode,
      email: address.email,
      phone: address.phone,
      alias: address.alias,
      reference: address.reference,
      country: address.country_code,
      origin_destiny: address.origin_destiny,
    })
    .then((response) => {
      dispatch(showMessage("success", "Dirección creada"));
      if (to) {
        dispatch(
          selectAddressTo(
            response.data.address.object_id,
            response.data.address
          )
        );
      } else {
        dispatch(
          selectAddress(response.data.address.object_id, response.data.address)
        );
      }
    })
    .catch((err) => {
      dispatch(showError(err.response.data.error));
    });

export const createPackage = (pack: Package) => (dispatch: Dispatch) =>
  axios
    .post(packagesUrl, {
      ...pack,
    })
    .then((response) => {
      dispatch(showMessage("success", "Empaque creado"));
    })
    .catch((err) => {
      dispatch(showError(err.response.data.error));
    });

export const createRates = (
  countryfrom: string,
  countryto: string,
  from: string,
  to: string,
  weight: number,
  length: number,
  width: number,
  height: number
) => (dispatch: Dispatch) => {
  dispatch({
    type: INIT_GETTING_RATES,
  });
  return axios
    .post(shipmetsUrl, {
      object_purpose: "QUOTE",
      zipcode_from: from,
      zipcode_to: to,
      country_from: countryfrom,
      country_to: countryto,
      weight,
      source_type: "web_portal",
      length,
      width,
      height,
    })
    .then((response) => {
      const dimensions = {
        length,
        width,
        height,
      };
      dispatch(
        fetchRates(
          response.data.shipment.object_id,
          from,
          to,
          dimensions,
          weight
        )
      );
    })
    .catch((err) => {
      dispatch({
        type: ERROR_GETTING_RATES,
      });
      dispatch(showError(err.response.data.error));
    });
};

export const fetchRates = (
  id: string,
  from: string,
  to: string,
  dimensions,
  weight
) => (dispatch: Dispatch) =>
  axios
    .get(`${shipmetsUrl}/${id}/rates?limit=1000000`)
    .then((response) => {
      if (!Array.isArray(response.data.results)) {
        dispatch(
          receivedRates({
            total_count: response.data.total_count,
            total_pages: response.data.total_pages,
            current_page: response.data.current_page,
            next_page_url: response.data.next_page_url,
            prev_page_url: response.data.prev_page_url,
            results: Object.values(response.data.results),
          })
        );
      } else {
        dispatch(receivedRates(response.data));
      }
      dispatch(setOldInfo(from, to, dimensions, weight));
      dispatch({
        type: FINISH_GETTING_RATES,
      });
    })
    .catch((err) => {
      dispatch({
        type: ERROR_GETTING_RATES,
      });
      if (err.response) dispatch(showError(err.response.data.error));
    });

const receivedRates = (rates: Rates): Action_RECEIVED_RATES => ({
  type: RECEIVED_RATES,
  payload: rates,
});

export const updateCostLoading = (status: boolean) => ({
  type: CHANGE_LOADING_COST,
  payload: status,
});

export const createDuplicateOrder = (model) => ({
  type: SEND_INFO_DUPLICATE_ORDER,
  payload: model,
});

export const createDevolution = (model) => ({
  type: SEND_INFO_DEVOLUTION,
  payload: model,
});

export const editExistingOrder = (model) => ({
  type: SEND_INFO_EDIT_ORDER,
  payload: model,
});

export const setOldInfo = (origin, destiny, dimensions, weight) => ({
  type: SET_OLD_INFO,
  payload: {
    origin,
    destiny,
    dimensions,
    weight,
  },
});

export const generateLabel = (model, shipment, isManualEditing = false) => ({
  type: GENERATE_LABEL_MARKETPLACE,
  payload: shipment
    ? {
        model,
        shipment,
        isManualEditing,
      }
    : {
        model,
        isManualEditing,
      },
});

export const isLoadingZipcode = (isLoading) => ({
  type: IS_LOADING_ZIPCODE,
  payload: isLoading,
});

export const changeLoading = (loading) => ({
  type: CHANGE_LOADING_SIDEBAR,
  loading,
});

export const changeManual = (type, status) => ({
  type: CHANGE_MANUAL_INFO,
  payload: { type, status },
});

export const changeStepError = (step, error) => ({
  type: CHANGE_STEP_ERROR,
  payload: {
    step,
    error,
  },
});

export const setCountries = (data) => ({
  type: GET_COUNTRIES_SIDEBAR_ORDER,
  payload: data,
});

export const getCountries = () => (dispatch: Dispatch) =>
  axios
    .get(countriesUrl)
    .then((response) => {
      dispatch(setCountries(response.data.results));
      const mex = response.data.results.findIndex(
        (country) => country.code == "MX"
      );
      if (mex >= 0) dispatch(setValue("from_country", mex));
    })
    .catch((error) => {
      if (error.response) dispatch(showError(error.response.data.error));
    });

export const openInvoice = () => ({
  type: OPEN_COMMERCIAL_INVOICE,
});

export const closeInvoice = () => ({
  type: CLOSE_COMMERCIAL_INVOICE,
});

export const commercialInvoice = (com_invoice) => ({
  type: COMMERCIAL_INVOICE,
  com_invoice,
});
