import axios from 'axios';
import { showLoader, hideLoader } from '../loader';
import { showMessage } from '../message';
import { showError } from '../error';

export const addressesUrlMarket = '/api/addresses/marketplace';
export const addressesUrl = '/api/addresses';



export const getAddresses = id => axios
  .get(addressesUrlMarket + '/' + id)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));


export const createAddress = (address, id) => axios  
    .post(addressesUrlMarket + '/'+ id, {
      object_type: 'FROMTO',
      name: address.name,
      street: address.street,
      street2: address.street2,
      zipcode: address.zipcode,
      email: address.email,
      phone: address.phone,
      alias: address.alias,
      reference: address.reference,
      country: address.country_code
    })
    .then(response => ({ type: 'Success', response }))
    .catch(err => ({ type: 'Error', response: err }));


export const editAddress = (address, id, id_user) => axios 
    .put(addressesUrl + '/' + id+ '/marketplace/' + id_user, {
      object_type: 'FROMTO',
      name: address.name,
      street: address.street,
      street2: address.street2,
      zipcode: address.zipcode,
      email: address.email,
      phone: address.phone,
      alias: address.alias,
      reference: address.reference,
      country: address.country_code
    })
    .then(response => ({ type: 'Success', response }))
    .catch(err => ({ type: 'Error', response: err }));


export const deleteAddress = (id) => axios
    .delete(addressesUrl + '/' + id)
    .then(response => ({ type: 'Success', response }))
    .catch(err => ({ type: 'Error', response: err }));


