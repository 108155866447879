// @flow
import React from "react";
import logo from "./index.svg";

type Props = {
  className?: string,
  isPrime?: boolean,
};

const Logo = ({ className, plan }: Props) => (
  <div className={className}>
    <img src={logo} alt="logo" />
    {/* {plan && plan === 'free'
      ? <span className="prime">Free</span>
      : plan === 'entry'
      ? <span className="prime">Entry</span>
      : plan === 'advance'
        ? <span className="prime">Advance</span>
        : plan === 'professional'
          ? <span className="prime">Professional</span>
          : plan === 'enterprise' && <span className="prime">Enterprise</span>} */}
    <span className="prime">{plan === "Tarifa Pública" ? "" : plan}</span>
  </div>
);

Logo.defaultProps = {
  className: "",
  isPrime: false,
};

export default Logo;
