import React from "react";
import Button from "../../Button";
import { DropdownConfig, ConfigLink } from "../../ShipmentsList/index";
import Text from "../../Text";
import "./style.css";

class ThreeDotsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.close) this.setState({ open: false });
  }

  toggleOpen() {
    this.setState({ open: !this.state.open });
  }

  render() {
    return (
      <div className="wrapper">
        <div className="stm-more-parent-container">
          <Button
            type="link"
            className="stm-more-button"
            onClick={() => this.toggleOpen()}
          >
            <i className="ion-ios-more" />
          </Button>
          <DropdownConfig
            className={`dd-stm-opts ${this.state.open ? "open" : ""}`}
          >
            {this.props.linksToRender.map((element, index) => (
              <ConfigLink
                key={index}
                onClick={element.function ? element.function : () => {}}
              >
                <a
                  href={element.link ? element.link : "javascript:void(0)"}
                  target={element.link ? "_blank" : "_self"}
                >
                  <Text type="textTable" style={{ verticalAlign: "middle" }}>
                    {element.label}
                    {element.icon && <i className={element.icon} />}
                    {element.image && <img src={element.image} />}
                  </Text>
                </a>
              </ConfigLink>
            ))}
          </DropdownConfig>
        </div>
      </div>
    );
  }
}
export default ThreeDotsDropdown;
