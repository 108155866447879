// @flow

import { SHOW_MESSAGE } from './message';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

type Loader = {
  show: boolean,
  kind: ?string,
  visible: boolean,
  param1: ?string
};

type State = {
  +loader: Loader
};

type Action = {
  +type: string,
  +kind: ?string,
  +visible: ?boolean,
  +createdType: ?string
};

const initialState: State = {
  loader: {
    show: false,
    visible: false,
    kind: null
  }
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        param1: action.createdType,
        kind: action.kind,
        visible: action.visible,
        show: true
      };
    case SHOW_MESSAGE:
      if (action.messageType === 'error') {
        return {
          kind: null,
          visible: false,
          show: false
        };
      }
      return state;
    case HIDE_LOADER:
      return {
        kind: null,
        visible: false,
        show: false
      };
    default:
      return state;
  }
};

export const showLoader = (kind?: string, param1?: string, visible?: boolean = true) => {
  return {
    type: SHOW_LOADER,
    kind,
    param1,
    visible
  };
};

export const hideLoader = () => {
  return {
    type: HIDE_LOADER
  };
};
