import React from 'react';
import colors from '../../utils/colors';
import './style.css';

const Filter = (props, context) => (
  <label className={`condition ${props.noMarginFilter && 'no-margin'}`}>
    {props.icon && <img src={props.icon} />}
    {props.children}
    <i className="ion-android-close" onClick={props.onClose} />
  </label>
);

export default Filter;
