import React from 'react';
import axios from 'axios';
import { boundMethod } from 'autobind-decorator';
import styled from 'styled-components';

import { Row, Column } from 'hedron';
import { ToastContainer, toast } from 'react-toastify';

import Quotations from './Options/Quotations';
import './style.css';

// comit
export default class AccountQuotesVisualization extends React.Component {
  state = {
    id: null,
    selected: null,
  }

  @boundMethod
  onClickElement(selected) {
    this.setState({ selected });
  }

  @boundMethod
  closeElement() {
    this.setState({ selected: null });
  }

  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
  }

  renderContent() {
    return (
      <Quotations
        id={this.props.id} notify={this.notify}
        closeElement={this.closeElement}
        toProcess={this.props.toProcess}
        renderSelectedList={() => this.props.renderSelectedList()}
        onClickQuote={(e, quote) => this.props.onClickQuote(e, quote)} />
    );
  }

  notify = (message, type) => {
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  render() {
    return (
      <div>
        <ToastContainer />
        {/* <EmptyState /> */}
        <div className="container-accounts-visualization">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}
