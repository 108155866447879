import axios from 'axios';
import { showLoader, hideLoader } from '../loader';
import { showMessage } from '../message';
import { showError } from '../error';
import { GET } from './types';

export const addressesUrl = '/api/addresses';

export const getUserAddresses = (page, zipCode) => {
  return dispatch => {
    return axios
      .get(`${addressesUrl}?page=${page}&zipcode=${zipCode}&limit=1000000`)
      .then(response => {
        dispatch(setUserAddresses(response.data));
        dispatch(hideLoader());
      })
      .catch(err => dispatch(showError(err.response.data.error)));
  };
};

export const setUserAddresses = addresses => {
  return {
    type: GET,
    addresses
  };
};

export const getUserAllAddresses = () => {
  return dispatch => {
    return axios
      .get(`${addressesUrl}?page=1&limit=1000000000`)
      .then(response => {
        dispatch(setUserAddresses(response.data));
        dispatch(hideLoader());
      })
      .catch(err => dispatch(showError("Error al obtener las direcciones")));
  };
};

export const createAddress = address => dispatch => {
  dispatch(showLoader('addresses'));
  return axios
    .post(addressesUrl, {
      object_type: 'FROMTO',
      name: address.name,
      street: address.street,
      street2: address.street2,
      zipcode: address.zipcode,
      email: address.email,
      phone: address.phone,
      alias: address.alias,
      reference: address.reference,
      country: address.country_code,
      origin_destiny: address.origin_destiny,
    })
    .then(response => {
      dispatch(getUserAllAddresses());
      dispatch(showMessage('success', 'Dirección creada'));
    })
    .catch(err => dispatch(showError(err.response.data.error)));
};

export const editAddress = (newAddress, id, _showLoader) => dispatch => {
  _showLoader && dispatch(showLoader('addresses'));
  return axios
    .put(addressesUrl + '/' + id, {
      object_type: newAddress.object_type,
      name: newAddress.name,
      street: newAddress.street,
      street2: newAddress.street2,
      zipcode: newAddress.zipcode,
      email: newAddress.email,
      phone: newAddress.phone,
      alias: newAddress.alias,
      reference: newAddress.reference,
      country: newAddress.country_code,
      origin_destiny: newAddress.origin_destiny,
    })
    .then(response => {
      dispatch(getUserAllAddresses());
      dispatch(showMessage('success', 'Cambios guardados'));
    })
    .catch(err => dispatch(showError(err.response.data.error)));
};

export const deleteAddress = (id, _showLoader = true) => dispatch => {
  _showLoader && dispatch(showLoader('addresses'));
  return axios
    .delete(`${addressesUrl}/${id}`)
    .then(response => {
      dispatch(showMessage('success', 'Dirección eliminada'));
      dispatch(getUserAllAddresses());
    })
    .catch(err => dispatch(showError(err.response.data.error)));
};

export const savePrimaryAddress = (address, _showLoader = true) => dispatch => {
  _showLoader && dispatch(showLoader('addresses'));
  return axios
    .get(`${addressesUrl}?type=${address.object_type}`)
    .then(response => {
      const result = response.data.results && response.data.results[0];
      if (result && result.object_id != address.object_id) {
        //delete previous default address
        result.object_type = 'FROMTO';
        dispatch(editAddress(result, result.object_id, _showLoader));
      }
      if (address.object_id) {
        //previously created FROMTO address
        return dispatch(editAddress(address, address.object_id, _showLoader));
      } else {
        //never created address
        return dispatch(createPrimaryAddress(address, _showLoader));
      }
    })
    .catch(err => dispatch(showError(err.response.data.error)));
};

export const getPrimaryAddress = (object_type, _showLoader = true) => dispatch => {
  _showLoader && dispatch(showLoader('addresses'));
  return axios
    .get(`${addressesUrl}?type=${object_type}`)
    .then(response => {
      const result = response.data.results && response.data.results[0];
      return result;
    })
    .catch(err => dispatch(showError(err.response.data.error)));
};

export const createPrimaryAddress = (address, _showLoader = true) => dispatch => {
  _showLoader && dispatch(showLoader('addresses'));
  return axios
    .post(addressesUrl, {
      object_type: address.object_type ? address.object_type : 'PRIMARY',
      name: address.name,
      street: address.street,
      street2: address.street2,
      zipcode: address.zipcode,
      email: address.email,
      phone: address.phone,
      alias: address.alias,
      reference: address.reference,
      origin_destiny: address.origin_destiny,
    })
    .then(response => dispatch(getUserAllAddresses()))
    .catch(err => dispatch(showError("Ocurrio un error al eliminar tu dirección")));
};

export const deletePrimaryAddress = (object_type, _showLoader = true) => dispatch => {
  _showLoader && dispatch(showLoader('addresses'));
  return axios
    .get(`${addressesUrl}?type=${object_type}`)
    .then(response => {
      const address = response.data.results[0];
      address.active = true;
      dispatch(activateDefaultAddress(address, _showLoader));
      address.object_type = 'FROMTO';
      dispatch(editAddress(address, address.object_id, _showLoader));
    })
    .catch(err => dispatch(showError(err.response.data.error)));
};

export const activateDefaultAddress = (address, _showLoader = true) => dispatch => {
  _showLoader && dispatch(showLoader('addresses'));
  return axios
    .post('api/profile/activateDefaultAddress', {
      address_type: address.object_type,
      activate: address.active
    })
    .then(response => {
      dispatch(getUserAllAddresses());
    })
    .catch(err => dispatch(showError(err.response.data.error)));
};
