// @flow

import axios from 'axios';
import type { Dispatch as ReduxDispatch } from 'redux';

type State = {
  +from?: any
};

type Action = {
  +type: string,
  +from?: any
};

type Dispatch = ReduxDispatch<Action>;

const initialState: State = {
  from: null
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};
