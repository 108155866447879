// @flow

import axios from "axios";
// import { push } from 'react-router-redux';
import type { Dispatch as ReduxDispatch } from "redux";
import type { ZipCode } from "./zipCodes";
import { showLoader, hideLoader } from "./loader";
import { showError } from "./error";

export const shipmetsUrl = "/api/shipments";
export const RECEIVED_RATES = "RECEIVED_RATES";
export const RATE_VALUE = "RATE_VALUE";
export const CLEAR_RATES = "CLEAR_RATES";

type Rates = {
  current_page: number,
  next_page_url: ?string,
  prev_page_url: ?string,
  results: Array<Rate>,
  total_count: number,
  total_pages: number,
  selected: ?Rate,
};

export type Rate = {
  object_id: number,
  amount: number,
  servicelevel: string,
  duration_terms: string,
  days: number,
  trackable: ?boolean,
  collect_home: ?boolean,
  provider: string,
  provider_img: string,
  extended_zone: ?boolean,
};

type State = Rates | null;

type Action_RECEIVED_RATES = { type: "RECEIVED_RATES", payload: Rates };
type Action_RATE_VALUE = { type: "RATE_VALUE", value: Rate };

type Action = Action_RECEIVED_RATES | Action_RATE_VALUE;

type Dispatch = ReduxDispatch<Action>;

const initialState: State = null;

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case RECEIVED_RATES:
      return {
        ...state,
        current_page: action.payload.current_page,
        next_page_url: action.payload.next_page_url,
        prev_page_url: action.payload.prev_page_url,
        results: action.payload.results,
        total_count: action.payload.total_count,
        total_pages: action.payload.total_pages,
      };
    case CLEAR_RATES:
      return {
        ...state,
        current_page: null,
        next_page_url: null,
        prev_page_url: null,
        results: [],
        total_count: null,
        total_pages: null,
      };
    case RATE_VALUE:
      return {
        ...state,
        selected: action.value,
      };
    default:
      return state;
  }
};

export const createRates =
  (
    from: string,
    to: string,
    weight: number,
    length: number,
    width: number,
    height: number
  ) =>
  (dispatch: Dispatch) => {
    dispatch(showLoader("rates"));
    return axios
      .post(shipmetsUrl, {
        object_purpose: "QUOTE",
        zipcode_from: from,
        zipcode_to: to,
        weight,
        source_type: "web_portal",
        length,
        width,
        height,
      })
      .then((response) => {
        dispatch(fetchRates(response.data.shipment.object_id));
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(showError(err.response.data.error));
      });
  };

export const fetchRates = (id: string) => (dispatch: Dispatch) =>
  axios
    .get(`${shipmetsUrl}/${id}/rates?limit=1000000`)
    .then((response) => {
      if (!Array.isArray(response.data.results)) {
        dispatch(
          receivedRates({
            total_count: response.data.total_count,
            total_pages: response.data.total_pages,
            current_page: response.data.current_page,
            next_page_url: response.data.next_page_url,
            prev_page_url: response.data.prev_page_url,
            results: Object.values(response.data.results),
          })
        );
      } else {
        dispatch(receivedRates(response.data));
      }
      dispatch(hideLoader());
      // dispatch(push('/generarCotizacion'))
    })
    .catch((err) => {
      console.log(err);
      if (err.response) dispatch(showError(err.response.data.error));
    });

export const rateChange = (rate: Rate): Action_RATE_VALUE => ({
  type: RATE_VALUE,
  value: rate,
});

const receivedRates = (rates: Rates): Action_RECEIVED_RATES => {
  return {
    type: RECEIVED_RATES,
    payload: rates,
  };
};

export const clearRates = (rates: Rates): Action_RECEIVED_RATES => {
  return {
    type: CLEAR_RATES,
  };
};
