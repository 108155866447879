// @flow
import type { Dispatch as ReduxDispatch } from 'redux';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

type Message = {
  show: boolean,
  messageType: string,
  text?: string
};

type State = Message | null;

type Action = {
  +type: string,
  +messageType?: string,
  +text?: string
};

type Dispatch = ReduxDispatch<Action>;

const initialState: State = {
  show: false,
  messageType: '',
  text: ''
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        show: true,
        messageType: action.messageType,
        text: action.text
      };
    case HIDE_MESSAGE:
      return {
        show: false,
        messageType: '',
        text: ''
      };
    default:
      return state;
  }
};

export const showMessage = (
  messageType: 'success' | 'error' | 'info' | 'danger',
  text: ?string
) => {
  return {
    type: SHOW_MESSAGE,
    messageType,
    text
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
