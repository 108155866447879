import React from 'react';
import { push } from 'react-router-redux';
import valid from 'card-validator';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Column } from 'hedron';
import { Route, Switch } from 'react-router-dom';

import { getUserCards, createCard } from '../../modules/cards';
import { hideMessage } from '../../modules/message';

import Menu from '../../components/Menu';
import CardsList from '../../components/CardsList';
import Text, { Title } from '../../components/Text';
import Button from '../../components/Button';
import EmptyState from '../../components/EmptyState';
import Create from '../../components/Create';
import Input from '../../components/Input';
import Message from '../../components/Message';
import { searchPermission } from '../../utils/global_functions';

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: '',
      numberError: '',
      expiration: '',
      expirationError: '',
      name: '',
      nameError: '',
      cvc: '',
      cvcError: ''
    };
    this.props.getUserCards();
    this.saveCard = this.saveCard.bind(this);
    this.resetForms = this.resetForms.bind(this);
  }

  componentWillMount() {
    this.props.hideMessage();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.isExact && !this.props.match.isExact) {
      this.resetForms();
    }
    if (
      (nextProps.location.pathname.indexOf('crear') > -1 ||
        nextProps.location.pathname.indexOf('editar') > -1) &&
      nextProps.location.pathname !== this.props.location.pathname
    ) {
      this.resetForms();
      this.props.hideMessage();
    }

    if (
      (nextProps.location.pathname.indexOf('crear') > -1 ||
        nextProps.location.pathname.indexOf('editar') > -1) &&
      nextProps.location.pathname === this.props.location.pathname &&
      nextProps.message &&
      nextProps.message.messageType === 'success'
    ) {
      this.props.goToCardsList();
    }
  }

  resetForms() {
    this.setState({
      number: '',
      numberError: '',
      expiration: '',
      expirationError: '',
      name: '',
      nameError: '',
      cvc: '',
      cvcError: ''
    });
  }

  handleChange(value, name) {
    switch (name) {
      case 'name':
        this.setState({
          nameError: value === '' ? 'Este campo es requerido' : ''
        });
        break;
      case 'number':
        if (value.length > 16) return;
        let number = valid.number(value);
        let numberError = !number.isPotentiallyValid
          ? value === ''
            ? 'Este campo es requerido'
            : 'Número de tarjeta inválido'
          : number.card && number.card.isAmex
          ? 'Por el momento no soportamos Amex, intente otra tarjeta'
          : '';
        this.setState({ numberError });
        break;
      case 'expiration':
        let expiration = valid.expirationDate(value);
        let val = value.replace('/', '');
        if (!isNaN(val) && val.length <= 4) {
          if (val.length >= 2) {
            val = val.replace(/(\d{2})/, '$1/');
            if (this.state.expiration === val) {
              val = val.replace('/', '');
            }
          }
          value = val;
        } else if (val.length > 4) {
          val = `${val.substring(0, 2)}/${val.substring(2, 4)}`;
          value = val;
        }
        let expirationError =
          !expiration.isValid || value.length !== 5 || value.indexOf('/') !== 2
            ? value === ''
              ? 'Este campo es requerido'
              : 'Fecha de vencimiento inválida'
            : '';
        this.setState({ expirationError });
        break;
      case 'cvc':
        let cvc = valid.cvv(value);
        let cvcError = !cvc.isValid
          ? value === ''
            ? 'Este campo es requerido'
            : 'CVC inválido'
          : '';
        this.setState({ cvcError });
        break;
      default:
        break;
    }
    if (
      (name !== 'name' && name !== 'expiration' && !isNaN(value)) ||
      name === 'name' ||
      name === 'expiration'
    ) {
      this.setState({ [name]: value });
    }
  }

  saveCard(type) {
    const errors = {
      required: 'Este campo es requerido'
    };
    let nameError = this.state.name === '' ? errors.required : '';
    let number = valid.number(this.state.number);
    let numberError = !number.isValid
      ? this.state.number === ''
        ? errors.required
        : 'Número de tarjeta inválido'
      : number.card && number.card.isAmex
      ? 'Por el momento no soportamos Amex, intente otra tarjeta'
      : '';
    let expiration = valid.expirationDate(this.state.expiration);
    let expirationError =
      !expiration.isValid ||
      this.state.expiration.length !== 5 ||
      this.state.expiration.indexOf('/') !== 2
        ? this.state.expiration === ''
          ? errors.required
          : 'Fecha de vencimiento inválida'
        : '';
    let cvc = valid.cvv(this.state.cvc);
    let cvcError = !cvc.isValid ? (this.state.cvc === '' ? errors.required : 'CVC inválido') : '';
    if (nameError || numberError || cvcError || expirationError) {
      return this.setState({
        nameError,
        numberError,
        cvcError,
        expirationError
      });
    }
    if (type === 'create') {
      let expirationDate = this.state.expiration.split('/');
      const card = {
        object: 'card',
        exp_month: expirationDate[0],
        exp_year: expirationDate[1],
        number: this.state.number,
        name: this.state.name,
        cvc: this.state.cvc
      };
      this.props.createCard(card, 'card');
    }
  }

  renderEmptyState() {
    const {
      customer_type, plan, actions, role, operational_user,
    } = user;
  
    const permissions = [];
    if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
      operational_user.operational_role.actions.map(element => permissions.push(element));
    }
  
    if (plan && plan.features) {
      plan.features.map(element => permissions.push(element));
    }
    if (actions) {
      actions.map(element => permissions.push(element));
    }
    return (
      
      <EmptyState name="payment">
        <Title className="state-title">Registrar una tarjeta</Title>
        <Text>
          Al guardar una tarjeta en el sistema, vas a lograr hacer el pago de tus guías de forma más
          rapida y así tambien te ahorras ingresar tus datos, en cada ocasion.
        </Text>
        {searchPermission('tarjetas', 'editar', permissions) && ( 

        <Button className="state-btn" onClick={() => {}}>
          Registrar tarjeta
        </Button>
        )}
      </EmptyState>
    
    );
  }

  render() {
    if (
      this.props.message.show &&
      (this.props.message.messageType === 'error' || this.props.message.messageType === 'success')
    ) {
      setTimeout(this.props.hideMessage, 5000);
    }
    const mainProps = {
      generate: name => {
        return {
          onChange: value => {
            this.handleChange(value, name);
          },
          value: this.state[name],
          error: this.state[`${name}Error`]
        };
      }
    };
    const cardFields = (
      <Row>
        <Column xs={7} fluid>
          <Input
            type="text"
            {...mainProps.generate('number')}
            required
            placeholder="1234 4567 7891 1234"
            label="Número de tarjeta"
          />
        </Column>
        <Column xs={5} fluid style={{ paddingLeft: '16px' }}>
          <Input
            type="text"
            {...mainProps.generate('expiration')}
            required
            placeholder="09/20"
            label="Fecha de expiración"
          />
        </Column>
        <Column xs={7} fluid>
          <Input
            type="text"
            {...mainProps.generate('name')}
            required
            placeholder="Juan Pérez"
            label="Titular de la tarjeta"
          />
        </Column>
        <Column xs={5} fluid style={{ paddingLeft: '16px' }}>
          <Input
            type="password"
            {...mainProps.generate('cvc')}
            required
            placeholder=""
            label="CVC"
          />
        </Column>
      </Row>
    );
    return (
      <div>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: 'right' }} fluid>
            <Menu />
          </Column>
        </Row>
        <Switch>
          <Route exact path={`${this.props.match.url}`} component={CardsList} />
          <Route
            path={`${this.props.match.url}/crear`}
            render={props => (
              <Create {...props} goBack={this.props.goToCardsList}>
                {this.props.message.show ? (
                  <Message color={this.props.message.messageType} onClose={this.props.hideMessage}>
                    {this.props.message.text}
                  </Message>
                ) : null}
                <Title>Registro de tarjeta</Title>
                <Text type="helpTextModal">
                  Toda tu información es guardada de forma segura en nuestros servidores, ésta es
                  encriptada con los mejores algoritmos de seguridad.
                </Text>
                {cardFields}
                <Button onClick={() => this.saveCard('create', null)} className="create-btn">
                  Registrar
                </Button>
              </Create>
            )}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cards: state.cards,
  message: state.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserCards,
      createCard,
      hideMessage,
      goToCardsList: () => push('/metodos-pago'),
      changePage: () => push('/about-us')
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment);
