export const INIT_CSV_STEPS = "INIT_CSV_STEPS";
export const SET_STEP_CSV = "SET_STEP_CSV";
export const PREVIOUS_STEP_CSV = "PREVIOUS_STEP_CSV";
export const NEXT_STEP_CSV = "NEXT_STEP_CSV";
export const SET_VALUE_CSV = "SET_VALUE_CSV";
export const SET_ERROR_CSV = "SET_ERROR_CSV";
export const UNSET_ERROR_CSV = "UNSET_ERROR_CSV";
export const SET_ICON_CSV = "SET_ICON_CSV";
export const FILL_DATA_CSV = "FILL_DATA_CSV";
export const SET_STEP_SEND_DATA = "SET_STEP_SEND_DATA";
