import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import { Row, Column } from 'hedron';
import Skeleton from 'react-loading-skeleton';
import Waypoint from 'react-waypoint';
import Loader from 'react-loaders';
import Text, { Title } from '../Text';
import EmpyState from '../EmptyState';
import NewTable from '../NewTable';
import { shorten, numberWithCommas } from '../../utils/global_functions';
import colors from '../../utils/colors';
import NewBadge from '../Badge/insuranceBadge';

import '../QuotationList/style.css';
import { MarketplaceBadge } from '../Badge';
import { getAllUserQuotes, getAllUserShipments } from '../../modules/shipments/actions';
import { toggle as toggleSideBar } from '../../modules/CollapsibleBar/actions';
import { showMessage } from '../../modules/message';
import { showLoader, hideLoader } from '../../modules/loader';
import { showError } from '../../modules/error';
import eye from './eye.svg';
import { boundMethod } from 'autobind-decorator';
import { CSVLink } from 'react-csv';
import './style.css';


class QuotationsFullList extends React.Component {
  state = {
    selectedConcept: [],
    rates: [],
    parcels: ['Todos', 'Chazki', 'Blueexpress', 'Shippify'],
    page: 1,
    showWaypoint: false,
    showEmpty: false,
    showlist: false,
    btLoading: false,
    data: [],
    showLoader: false,
    filter: null,
    loadingMore: false,
    showWaypoint: true,
  };

  _openSideBar = (stateSidebar = false, sidebarTitle = '', sidebarType = '') => {
    this.props.toggleSideBar(stateSidebar, sidebarTitle, sidebarType);
  };

  renderParcel = () => this.state.parcels.map((type, index) => (
    <Row key={type} className="card-dropdown-option">
      <Column xs={6} fluid>
        <Text>{type}</Text>
      </Column>
    </Row>
  ));

  @boundMethod
  _loadMore() {
    this.setState({ loadingMore: !this.state.loadingMore, showWaypoint: false });
    const { filter, page } = this.state;
    this.setState({ page: page + 1 }, () => {
      let url = '';
      switch (filter.type) {
      case null:
        url = `/api/quotes/filter/to-age?page=${this.state.page}`;
        break;
      case 'carrier':
        url = `/api/quotes/filter/provider/${filter.value}?page=${this.state.page}`;
        break;
      case 'country':
        url = `/api/quotes/filter/country?country=${filter.value}&page=${this.state.page}`;
        break;
      case 'date':
        url = `/api/quotes/filter/range-date?${filter.value}&page=${this.state.page}`;
        break;
      case 'quote':
        url = `/api/quotes/filter/quote-id?purchase_id=${filter.value}&page=${this.state.page}`;
        break;
      default:
        break;
      }
      axios
        .get(url, {})
        .then((r) => {
          this.setState({ loadingMore: !this.state.loadingMore });
          switch (r.status) {
          case 200:

            const tempdata = [];
            const { data } = this.state;
            for (const i in r.data) {
              tempdata.push([i, r.data[i]]);
            }
            const finalData = data.concat(tempdata);
            if (this.state.data.length !== finalData.length) {
              this.setState({ showWaypoint: true });
            }
            this.setState({ data: finalData });
            break;
          default:
            break;
          }
        })
        .catch((err) => {
          this.setState({ loadingMore: !this.state.loadingMore });
          this.props.hideLoader();
          this.props.showError(err.response.data.error);
        });
    });
  }

  getCoutes(filter) {
    this.setState({ showLoader: !this.state.showLoader });
    let url = '';
    switch (filter.type) {
    case null:
      url = `/api/quotes/filter/to-age?page=${this.state.page}`;
      break;
    case 'carrier':
      url = `/api/quotes/filter/provider/${filter.value}?page=${this.state.page}`;
      break;
    case 'country':
      url = `/api/quotes/filter/country?country=${filter.value}&page=${this.state.page}`;
      break;
    case 'date':
      url = `/api/quotes/filter/range-date?${filter.value}&page=${this.state.page}`;
      break;
    case 'quote':
      url = `/api/quotes/filter/quote-id?purchase_id=${filter.value}&page=${this.state.page}`;
      break;
    default:
      break;
    }
    axios
      .get(url, {})
      .then((r) => {
        switch (r.status) {
        case 200:
          this.setState({ showLoader: !this.state.showLoader });
          const data = [];
          for (const i in r.data) {
            data.push([i, r.data[i]]);
          }
          this.setState({ data });
          break;
        default:
          break;
        }
      })
      .catch((err) => {
        this.setState({ showLoader: !this.state.showLoader });
        this.props.hideLoader();
        this.props.showError(err.response.data.error);
      });
  }

  handleConceptDrop = (selected) => {
    this.setState({ selectedConcept: selected });
    if (selected === 0) {
      this.setState({ showWaypoint: true });
      // this.loadRates();
    } else {
      this.loadRatesWFilter(this.state.parcels[selected]);
    }
  };

  processQuote(id) {
    this.setState({
      btLoading: true,
    });
    this.props.showLoader(null, null, false);
    axios
      .post(`/api/quotes/${id}/purchases`, {})
      .then((r) => {
        switch (r.status) {
        case 201:
          this.props.showMessage('success', 'Cotizacón procesada exitosamente');
          this.props.hideLoader();
          this.props.getAllUserShipments();
          this.setState({
            btLoading: false,
          });
          break;
        default:
          break;
        }
      })
      .catch((err) => {
        console.log(err);
        this.props.hideLoader();
        this.props.showError(err.response.data.error);
      });
  }

  UNSAFE_componentWillMount() {
    // this.loadRates();
    this.props.getAllUserQuotes();
    // this.getCoutes({ type: 'all' });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.filter !== nextProps.filter) {
      this.setState({ filter: nextProps.filter, page: 1 }, () => {
        this.getCoutes(nextProps.filter);
      });
    }
  }

  loadRates() {
    this.setState({
      showEmpty: false,
    });
    axios.get('/api/accounts').then((r) => {
      switch (r.status) {
      case 200:
        if (r.data.accounts.length > 0) {
          this.setState({
            showEmpty: false,
            showlist: true,
            showLoader: false,
          });
          this.setState({ rates: r.data.accounts, page: this.state.page + 1 });
        } else {
          this.setState({
            showEmpty: true,
            showlist: false,
            showLoader: false,
            showWaypoint: false,
          });
        }

        break;
      default:
        this.setState({
          showEmpty: true,
          showlist: false,
          showLoader: false,
          showWaypoint: false,
        });
        break;
      }
    });
  }

  @boundMethod
  renderCSVData(row) {
    const csvData = [];

    csvData.push(['Cotización:', row[0].toString()]);
    csvData.push(['N° de Orden', 'Carrier', 'N° de Guía', 'URL de Guía', 'Costo', 'Destinatario', 'País']);
    row[1].forEach((element) => {
      this.props.countries.forEach((country) => {
        if (country.currency === element.currency) {
          csvData.push([element.id, element.provider_name, element.tracking_number, element.label_url, element.purchase_amount, element.to_name, country.code]);
        }
      });
    });
    return csvData;
  }


  @boundMethod
  renderCSVDataGral(data) {
    if (data && data.length !== 0) {
      const csvData = [];
      data.forEach((element) => {
        csvData.push(['Cotización:', element[0].toString()]);
        csvData.push(['N° de Orden', 'Carrier', 'N° de Guía', 'URL de Guía', 'Costo', 'Destinatario', 'País']);
        element[1].forEach((item) => {
          this.props.countries.forEach((country) => {
            if (country.currency === item.currency) {
              csvData.push([item.id, item.provider_name, item.tracking_number, item.label_url, item.purchase_amount, item.to_name, country.code]);
            }
          });
        });
      });

      return csvData;
    }
  }

  badgeLabel = (status) => {
    let label = ''
    let type = ''
    if(status) {
      switch (status) {
        case 'QUOTE':
          label = 'Cotizado'
          type = 'correct'
          break;
        case 'PURCHASE':
          label = 'Guía comprada'
          type = 'correct'
          break;
        case 'INCIDENCIA':
          label = 'Incidencia'
          type = 'warning'
          break;
        case 'ENTREGADO':
          label = 'Entregado'
          type = 'correct'
          break;
        case 'LABEL_CREATED':
          label = 'Guía creada'
          type = 'correct'
          break;
        case 'MANUAL_LABEL':
          label = 'Manual'
          type = 'warning'
          break;
        case 'MONTHLY_PAYMENT_PENDING':
          label = 'Pago mensual pendiente'
          type = 'error'
          break;
        case 'GUIA_ENVIADA':
          label = 'Guía enviada'
          type = 'correct'
          break;
        case 'TRANSITO':
          label = 'En camino'
          type = 'correct'
          break;
        case 'CANCELLED':
        case 'CANCELADO':
          label = 'Cancelado'
          type = 'error'
          break;
      }
    }
    return {
      type: type,
      label: label
    }
  }

  render() {
    const ExpandedSection = ({ data }) => {
      if (data[1] && data[1].length > 0) {
        return (
          <div className="container-subtable">
            <NewTable
              subHeader={`Cotización #${data[0]}`}
              className="no-min-height"
              expandableRowsComponent={<ExpandedSection2 />}
              onRowClicked={ship => this.props.shipmentDetails(ship, this.props.context)}
              expandableRows
              shipping={false}
              columns={columnsExpanded}
              data={data[1]}
            />
          </div>
        );
      }
      return <div />;
    };
    const ExpandedSection2 = ({ data }) => {
      if (data.items) {
        return (
          <div className="container-subtable">
            <NewTable
              subHeader="Items"
              className="no-min-height"
              shipping={false}
              columns={columns}
              data={JSON.parse(data.items)}
            />
          </div>
        );
      }
      return <div />;
    };
    const col = [
      {
        name: 'Cotizacion',
        selector: 'quote_id',
        sortable: true,
        cell: (row) => {
          if (row[1][0].status === 'MANUAL_LABEL') {
            return (
              <div>
                <p className="error-quote">
                  #
                  {row[0]}
                  (Error al procesar)
                </p>
              </div>
            );
          }
          return (
            <div>
              <p>
                  #
                {row[0]}
              </p>
            </div>
          );
        },
      },
      {
        name: 'N° Ordenes',
        selector: 'date',
        cell: row => (row[1].length),

      },
      {
        name: 'Estatus',
        selector: 'status',
        center: true,
        cell: (row) => {
          const badge = this.badgeLabel(row[1][0].status)
          return <NewBadge type={badge.type}>{badge.label}</NewBadge>
        }
      },
      {
        name: 'Vista Individual',
        selector: 'id',
        center: true,
        ignoreRowClick: true,
        cell: row => (<img onClick={() => this.props.singleViewQuote(row[0])} style={{ height: '17px', width: '17px' }} alt="" src={eye} />),

      },
      {
        name: 'CSV',
        selector: 'id',
        center: true,
        ignoreRowClick: true,
        cell: row => (
          <CSVLink
            className="link-btn-quotes"
            data={this.renderCSVData(row)}
            filename={`Cotización${row[0]}.csv`}
            target="_blank"
          >
          Descargar CSV
          </CSVLink>
        ),

      },
    ];
    const columnsExpanded = [
      {
        name: 'N° Orden',
        selector: 'id',
        sortable: true,
        cell: row => (
          <div>
            <p>
              #
              {row.id}
            </p>
            <MarketplaceBadge color={row.marketplace !== 'manual' ? row.marketplace : 'default'} />
          </div>
        ),
      },
      {
        name: 'Fecha',
        selector: 'date',
        cell: (row) => {
          const shipment_date = moment(row.date.split(' ')[0]);
          const today = new Date();
          const timeDiff = Math.abs(today - shipment_date);
          const daysDiff = Math.floor(timeDiff / (3600 * 24 * 1000));
          return (
            <div>
              <p>{shipment_date.format('DD-MM-YYYY')}</p>
              <p className="minitext">
                {daysDiff < 1 ? 'hoy' : `hace ${daysDiff} ${daysDiff > 1 ? 'días' : 'día'}`}
              </p>
            </div>
          );
        },
      },
      {
        name: 'Destinatario',
        selector: 'name',
        cell: row => shorten(row.to_name, 16),
      },
      {
        name: 'Destino',
        selector: 'address_to',
        cell: (row) => {
          const street = row.to_street.split('|');
          return (
            <div>
              <p>{shorten(street[0], 16)}</p>
              <p className="minitext">{street[1]}</p>
            </div>
          );
        },
      },
      {
        name: 'Servicio',
        selector: 'user_id',
        sortable: false,
        cell: row => (
          <div>
            <p>{row.provider_name}</p>
            <p className="minitext">{row.service_level}</p>
          </div>
        ),
      },
      {
        name: 'Total',
        selector: 'id',
        sortable: false,
        cell: row => (
          <div>
            <p>{`$${numberWithCommas(parseFloat(row.amount).toFixed(2))} ${row.currency}`}</p>
          </div>
        ),
      },
    ];
    const columns = [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
      },
      {
        name: 'Altura',
        selector: 'height',
        sortable: false,
        cell: row => (
          <div>
            <p>
              {row.height}
              {' '}
                cm
            </p>
          </div>
        ),
      },
      {
        name: 'Anchura',
        selector: 'width',
        sortable: false,
        cell: row => (
          <div>
            <p>
              {row.width}
              {' '}
                cm
            </p>
          </div>
        ),
      },
      {
        name: 'Longitud',
        selector: 'length',
        sortable: false,
        cell: row => (
          <div>
            <p>
              {row.length}
              {' '}
                cm
            </p>
          </div>
        ),
      },
      {
        name: 'Peso',
        selector: 'weight',
        sortable: false,
        cell: row => (
          <div>
            <p>
              {row.weight}
              {' '}
                kg
            </p>
          </div>
        ),
      },
    ];
    return (
      <div>
        {
          this.state.data && this.state.data.length !== 0
          && (
            <CSVLink
              className="link-btn-quotes-pink"
              data={this.renderCSVDataGral(this.state.data)}
              filename="Cotizaciónes.csv"
              target="_blank"
            >
          Descargar CSV General
            </CSVLink>
          )
        }

        {this.state.showLoader || this.state.filter === null ? <Skeleton count={7} height={25} />
          : (
            <div>
              <NewTable
                expandableRows
                expandableRowsComponent={<ExpandedSection />}
                shipping={false}
                columns={col}
                defaultSortField='quote_id'
                data={this.state.data}
              />
              <div className="center-waypoint">
                {this.state.loadingMore  ? <Loader color={colors.primary.mienvio_pink} type="ball-pulse" active />
                  : this.state.showWaypoint && <Waypoint key={this.state.page} onEnter={this._loadMore} />
                }
              </div>


            </div>

          )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  quotes: state.shipments.quotes,
  countries: state.newShipment.countries,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getAllUserQuotes,
    getAllUserShipments,
    toggleSideBar,
    showMessage,
    showError,
    showLoader,
    hideLoader,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuotationsFullList);
