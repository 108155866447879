// @flow
import React from 'react';
import { Row, Column } from 'hedron';

import Text from '../../components/Text';

type Props = {
  pack: any,
};

const PackageOption = ({ pack }: Props) => (
  <Row className="card-dropdown-option">
    <Column xs={6} fluid>
      <Text type="label" style={{ fontWeight: 'bold' }}>
        {pack.alias}
      </Text>
    </Column>
    <Column xs={6} fluid>
      <Text type="helpTextModal" style={{ textAlign: 'right' }}>
        {`Disponibles: ${pack.quantity} pzs`}
      </Text>
    </Column>
    <Column xs={12} fluid>
      <Text type="label" style={{ fontWeight: '400', color: '#686868' }}>
        {pack.object_type_code === 'box' ? 'Caja' : 'Sobre'}
      </Text>
    </Column>
    <Column xs={12} fluid>
      <Text type="label" style={{ fontWeight: '400' }}>
        {`${pack.length} x ${pack.width} x ${pack.height} cm`}
      </Text>
    </Column>
  </Row>
);

export { PackageOption };
