import axios from 'axios';
import {
  TOGGLE_QUICK_QUOTE,
  SET_ERROR_QUICK_QUOTE,
  SET_VALUE_QUICK_QUOTE,
  UNSET_ERROR_QUICK_QUOTE,
  CLEAR_QUICK_QUOTE,
  CHANGE_LOADING_QUICK_QUOTE,
  SEND_INFO_NEW_ORDER,
  CLEAR_RATES,
} from './actionsTypes';
import { RECEIVED_RATES } from '../rates';
import { showError } from '../error';
import { setOldInfo } from '../sidebarOrder/actions';

const shipmetsUrl = '/api/shipments';

export const toggle = (open, title) => ({
  type: TOGGLE_QUICK_QUOTE,
  payload: {
    open,
    title: title || '',
  },
});

export const setValueQuickQ = (name: string, value: string) => ({
  type: SET_VALUE_QUICK_QUOTE,
  payload: {
    name,
    value,
  },
});

export const setErrorQuickQ = (field: string, error: string) => ({
  type: SET_ERROR_QUICK_QUOTE,
  payload: {
    field,
    error,
  },
});

export const unsetErrorQuickQ = (field: string) => ({
  type: UNSET_ERROR_QUICK_QUOTE,
  payload: {
    field,
  },
});

export const clearQuickQuote = () => ({
  type: CLEAR_QUICK_QUOTE,
});

export const changeLoadingStatus = loading => ({
  type: CHANGE_LOADING_QUICK_QUOTE,
  payload: loading,
});

const clearRates = () => ({
  type: CLEAR_RATES,
});

export const createRates = (
  from: string,
  to: string,
  weight: number,
  length: number,
  width: number,
  height: number,
  countryfrom: string,
  countryto: string,
) => (dispatch: Dispatch) => {
  dispatch(clearRates());
  dispatch(changeLoadingStatus(true));
  return axios
    .post(shipmetsUrl, {
      object_purpose: 'QUOTE',
      zipcode_from: from,
      zipcode_to: to,
      country_from: countryfrom,
      country_to: countryto,
      weight,
      source_type: 'web_portal',
      length,
      width,
      height,
    })
    .then((response) => {
      const dimensions = {
        length,
        width,
        height,
      };
      dispatch(fetchRates(response.data.shipment.object_id, from, to, dimensions, weight));
    })
    .catch((err) => {
      dispatch(changeLoadingStatus(false));
      dispatch(showError('Error al obtener las tarifas'));
    });
};

const fetchRates = (id: string, from: string, to: string, dimensions, weight) => (dispatch: Dispatch) => axios
  .get(`${shipmetsUrl}/${id}/rates?limit=1000000`)
  .then((response) => {
    if (!Array.isArray(response.data.results)) {
      dispatch(receivedRates({
        total_count: response.data.total_count,
        total_pages: response.data.total_pages,
        current_page: response.data.current_page,
        next_page_url: response.data.next_page_url,
        prev_page_url: response.data.prev_page_url,
        results: Object.values(response.data.results),
      }));
    } else {
      dispatch(receivedRates(response.data));
    }

    dispatch(setOldInfo(from, to, dimensions, weight));
    dispatch(changeLoadingStatus(false));
  })
  .catch((err) => {
    dispatch(changeLoadingStatus(false));
    if (err.response) dispatch(showError(err.response.data.error));
  });

const receivedRates = rates => ({
  type: RECEIVED_RATES,
  payload: rates,
});

export const sendInfoOrder = model => ({
  type: SEND_INFO_NEW_ORDER,
  payload: model,
});
