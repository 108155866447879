import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Redirect } from "react-router-dom";
import { errors } from "../../utils/helpers";

import { resetPassword } from "../../modules/user";
import { hideMessage, showMessage } from "../../modules/message";

import Text from "../Text";
import Button from "../Button";
import Message from "../Message";
import Logo from "../static/Logo";
import Input from "../Input";

import LoginContainer, { Line } from "../Login/container";

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordError: "",
      password_confirmation: "",
      password_confirmationError: "",
    };
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePasswordConfirmation =
      this.handlePasswordConfirmation.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.getUrlParameter = this.getUrlParameter.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handlePassword(password) {
    this.setState({
      password,
      passwordError: errors("password", password),
    });
  }

  handlePasswordConfirmation(password_confirmation) {
    this.setState({
      password_confirmation,
      password_confirmationError:
        this.state.password !== password_confirmation
          ? "La contraseña no coincide"
          : "",
    });
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.resetPassword();
    }
  }

  isLoggedIn() {
    if (localStorage.getItem("token")) {
      return true;
    }
    return false;
  }

  getUrlParameter(name) {
    const aux = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + aux + "=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  resetPassword() {
    const p = this.state.password;
    const pp = this.state.password_confirmation;
    if (pp === p && p.length >= 6) {
      const tokenForm = this.getUrlParameter("token");
      const emailForm = this.getUrlParameter("email");
      if (tokenForm && emailForm)
        this.props.resetPassword(tokenForm, emailForm, p, pp);
      else this.props.showMessage("error", "Token o email no válidos.");
    } else {
      this.handlePassword(this.state.password);
      this.handlePasswordConfirmation(this.state.password_confirmation);
    }
  }

  hideMessage() {
    this.props.hideMessage();
  }

  render() {
    const messageSuccess =
      "Contraseña cambiada con éxito. Ya puedes iniciar sesión con la nueva contraseña.";
    if (this.isLoggedIn()) {
      return <Redirect to="/" />;
    }
    if (
      this.props.message.show &&
      this.props.message.messageType === "success" &&
      this.props.message.text === messageSuccess
    ) {
      setTimeout(this.props.goToLogin, 2000);
    } else if (
      this.props.message.show &&
      (this.props.message.messageType === "error" ||
        this.props.message.messageType === "success")
    ) {
      setTimeout(this.props.hideMessage, 5000);
    }
    return (
      <LoginContainer>
        {this.props.message.show ? (
          <Message
            color={this.props.message.messageType}
            onClose={this.props.hideMessage}
          >
            {this.props.message.text}
          </Message>
        ) : null}
        <div
          style={{ cursor: "pointer" }}
          onClick={() => this.props.goToLogin()}
        >
          <Logo />
        </div>
        <div style={{ marginTop: "30px", marginBottom: "16px" }}>
          <Text type="textTable">Ingresa la nueva contraseña</Text>
        </div>
        <Line />
        <Input
          type="password"
          onChange={this.handlePassword}
          value={this.state.password}
          required
          error={this.state.passwordError}
          placeholder="********"
          label="Nueva contraseña"
          onKeyPress={this.handleKeyPress}
        />
        <Input
          type="password"
          onChange={this.handlePasswordConfirmation}
          value={this.state.password_confirmation}
          required
          error={this.state.password_confirmationError}
          placeholder="********"
          label="Repetir contraseña"
          onKeyPress={this.handleKeyPress}
        />
        <Button className="login-btn" onClick={() => this.resetPassword()}>
          Confirmar
        </Button>
      </LoginContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToLogin: () => push("/login"),
      resetPassword,
      showMessage,
      hideMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
