import React from "react";
import { Label, Required } from "../Input";
import completedIcon from "./imgs/icon-completed.svg";
import IncompletedIcon from "./imgs/icon-unfinished.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import Text from "../Text";
import colors from "../../utils/colors";

const styleDivProcess = {
  display: "flex",
  alignItems: "flex-start",
  margin: "1.5em 0",
  flexWrap: "wrap",
};

const styleTextProcess = {
  margin: "0 0 0 1em",
};

const ProcessFile = ({ process, current, countOrders, maxOrders }) => {
  return (
    <div className="process-file">
      <Label className="title-process">Estamos procesando tus órdenes</Label>
      <div className="list-tasks">
        <div style={styleDivProcess}>
          {process.load ? (
            <img src={completedIcon} />
          ) : (
            <div className="wrapper-div">
              {current == 0 ? (
                <div className="rotating rot-left">
                  <i className="ion-android-sync" />
                </div>
              ) : (
                <img src={IncompletedIcon} />
              )}
            </div>
          )}
          <Text style={styleTextProcess}>Leyendo el archivo CSV</Text>
        </div>
        <div style={styleDivProcess}>
          {process.export ? (
            <img src={completedIcon} />
          ) : (
            <div className="wrapper-div">
              {current == 1 ? (
                <div className="rotating rot-left">
                  <i className="ion-android-sync" />
                </div>
              ) : (
                <img src={IncompletedIcon} />
              )}
            </div>
          )}
          <Text style={styleTextProcess}>Exportando filas</Text>
        </div>
        <div style={styleDivProcess}>
          {process.process ? (
            <img src={completedIcon} />
          ) : (
            <div className="wrapper-div">
              {current == 2 ? (
                <div className="rotating rot-left">
                  <i className="ion-android-sync" />
                </div>
              ) : (
                <img src={IncompletedIcon} />
              )}
            </div>
          )}

          <div style={{ width: "80%" }}>
            <Text style={styleTextProcess}>
              Procesando {countOrders > maxOrders ? maxOrders : countOrders} de{" "}
              {maxOrders}
            </Text>

            <div
              style={{
                ...styleTextProcess,
                marginTop: "13px",
                marginBottom: "0",
              }}
            >
              <ProgressBar
                height="10px"
                completed={(((countOrders - 20) / maxOrders) * 100).toFixed(2)}
                bgColor={colors.primary.mienvio_pink}
                isLabelVisible={false}
                labelAlignment="center"
              />
            </div>
          </div>
        </div>
        <div style={styleDivProcess}>
          {process.finish ? (
            <img src={completedIcon} />
          ) : (
            <div className="wrapper-div">
              {current == 3 ? (
                <div className="rotating rot-left">
                  <i className="ion-android-sync" />
                </div>
              ) : (
                <img src={IncompletedIcon} />
              )}
            </div>
          )}
          <Text style={styleTextProcess}>Listo</Text>
        </div>
      </div>
      <Label>
        Este proceso puede tomar unos minutos, si necesitas hacer algo más
        puedes abrir otra pestaña de mienvío. Solamente no cierres esta pestaña.
      </Label>
    </div>
  );
};

export default ProcessFile;
