import React from 'react';
import Loupe from './loupe.svg';
import './index.css';
import Button from '../../../components/Button';

const REPORT = 'REPORT';

const emptyState = (props, context) => (
  <div className="container-empty-accounts">
    <div className="right-button-sccounts">
      <Button
        className="header-btn last"
        onClick={() => { props.props.toggleSideBar(true, 'Generar Reporte', REPORT); }}
      >
        Generar Reporte
      </Button>
    </div>
    <img src={Loupe} alt="" />
    <p className="title">
        Selecciona una cuenta para
      <br />
        examinar y editar su
      <br />
        configuración

    </p>

  </div>

);
export default emptyState;
