import React from "react";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import { boundMethod } from "autobind-decorator";
import { toggle as toggleSidebar } from "../../../modules/CollapsibleBar/actions";
import Dropzone from "../../Dropzone";
import Dropdown from "../../Dropdown";
import {
  ContentAdapter,
  BottomContainer,
} from "../../WrapperSideBar/styledObjects";
import { CheckboxGroup, Checkbox } from "../../Checkbox";
import Text from "../../Text";
import Input, { Required, Label } from "../../Input";
import { SimpleCheckbox } from "../../Checkbox/simple_checkbox";
import "../style.css";
import Button from "../../Button";
import { getBase64 } from "../../../utils/global_functions";
import Skeleton from "react-loading-skeleton";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import isEmail from "validator/lib/isEmail";
import fetch from "fetch-base64";
import invoices from "../../AutomationIcon/invoices.svg";
import { RadioGroup, Radio } from "../../Radio";
import Message from "../../Message";

class MailConfig extends React.Component {
  state = {
    id: false,
    send_tracking: false,
    custom_tracking_email: false,
    subject: "",
    subjectError: "",
    mail: "",
    mailError: "",
    text: "",
    textError: "",
    email: "",
    emailError: "",
    logo: false,
    logo_url: "",
    logoOld: "",
    isLoading: false,
    selectedIndex: -1,
    selected: null,
    errorDropdown: "",
    displayColorPicker: false,
    color: "#EB2C88",
    opt: [],
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    facebookError: "",
    twitterError: "",
    instagramError: "",
    youtubeError: "",
    html: "",
    type: 0,
    htmlSrc: "",
  };

  componentWillMount() {
    let opt = [];
    if (!this.props.mail) {
      if (this.props.brands && this.props.brands.length !== 0) {
        let label = true;
        let tracking = true;
        let delivered = true;
        let deliveredDay = true;
        this.props.brands.forEach((element) => {
          switch (element.mail) {
            case "LABEL_CREATED":
              label = false;
              break;
            case "ON_TRANSIT":
              tracking = false;
              break;
            case "DELIVERED":
              delivered = false;
              break;
            case "DELIVERY_DAY":
              deliveredDay = false;
              break;
            default:
              break;
          }
        });
        if (label) {
          opt.push({
            key: "LABEL_CREATED",
            name: "Generación de Guía",
          });
        }
        if (tracking) {
          opt.push({
            key: "ON_TRANSIT",
            name: "Seguimiento",
          });
        }
        if (delivered) {
          opt.push({
            key: "DELIVERED",
            name: "Entrega",
          });
        }
        if (deliveredDay) {
          opt.push({
            key: "DELIVERY_DAY",
            name: "Dia de entrega",
          });
        }
        this.setState({ opt }, () => {
          if (this.props.mailType) {
            this.setState({
              selectedIndex: this.state.opt.findIndex(
                (e) => e.key === this.props.mailType
              ),
              selected: this.state.opt.filter(
                (e) => e.key === this.props.mailType
              )[0].name,
            });
          }
        });
      } else {
        opt = [
          {
            key: "LABEL_CREATED",
            name: "Generación de Guía",
          },
          {
            key: "ON_TRANSIT",
            name: "Seguimiento",
          },
          {
            key: "DELIVERED",
            name: "Entrega",
          },
          {
            key: "DELIVERY_DAY",
            name: "Dia de entrega",
          },
        ];
        this.setState({ opt }, () => {
          if (this.props.mailType) {
            this.setState({
              selectedIndex: this.state.opt.findIndex(
                (e) => e.key === this.props.mailType
              ),
              selected: this.state.opt.filter(
                (e) => e.key === this.props.mailType
              )[0].name,
            });
          }
        });
      }
    } else {
      opt = [
        {
          key: "LABEL_CREATED",
          name: "Generación de Guía",
        },
        {
          key: "ON_TRANSIT",
          name: "Seguimiento",
        },
        {
          key: "DELIVERED",
          name: "Entrega",
        },
        {
          key: "DELIVERY_DAY",
          name: "Dia de entrega",
        },
      ];
      this.setState({ opt }, () => {
        if (this.props.mailType) {
          this.setState({
            selectedIndex: this.state.opt.findIndex(
              (e) => e.key === this.props.mailType
            ),
            selected: this.state.opt.filter(
              (e) => e.key === this.props.mailType
            )[0].name,
          });
        }
      });
    }

    if (this.props.mail) {
      this.setState({
        isLoading: true,
      });
      axios
        .get(`/api/brands/${this.props.mail}`)
        .then((r) => {
          if (r.data) {
            if (r.data.brand.template_url) {
              this.setState({ type: 1 });
            }
            this.setState({
              isLoading: false,
              logo_url: r.data.brand.url_logo,
              id: r.data.brand.id,
              email: r.data.brand.sender,
              subject: r.data.brand.subject,
              text: r.data.brand.major_text,
              color: r.data.brand.color_state,
              logo: r.data.brand.url_logo,
              logoOld: r.data.brand.url_logo,
              facebook: r.data.brand.facebook,
              twitter: r.data.brand.twitter,
              instagram: r.data.brand.instagram,
              youtube: r.data.brand.youtube,
              htmlSrc: r.data.brand.template_url,
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false, creating: true });
        });
    }
  }
  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChangeColor = (color) => {
    this.setState({ color: color.hex });
  };

  handleCheckboxChange = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleChange = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length == 1) {
      getBase64(acceptedFiles[0]).then((image) => {
        this.setState({
          logo: image,
          logo_url: image,
        });
      });
    }
  };

  onDelete = () => {
    this.setState({
      logo: false,
      logo_url: false,
    });
  };

  getBase64HTML = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (function (theFile) {
        return function (e) {
          var binaryData = e.target.result;
          //Converting Binary Data to base 64
          var base64String = window.btoa(binaryData);
          //showing file converted to base64
          resolve(base64String);
        };
      })(file);
      reader.readAsBinaryString(file);

      reader.onerror = (error) => reject(error);
    });

  @boundMethod
  onDropHtml(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length == 1) {
      var htmlurl = URL.createObjectURL(acceptedFiles[0]);
      this.setState(() => ({
        htmlSrc: htmlurl,
      }));

      this.getBase64HTML(acceptedFiles[0]).then((html) => {
        this.setState(() => ({
          html,
        }));
      });
    }
  }

  onDeleteHtml = () => {
    this.setState({
      html: null,
      htmlSrc: null,
    });
  };

  isValidURL = (url) => {
    const pattern =
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (pattern.test(url)) {
      return true;
    }
    return false;
  };

  validateFields() {
    let flag = true;
    if (!this.state.email || this.state.email === "") {
      this.setState({ emailError: "Campo obligatorio" });
      flag = false;
    } else if (isEmail(this.state.email)) {
      this.setState({ emailError: "" });
    } else {
      this.setState({ emailError: "Correo invalido" });
    }
    if (!this.state.subject || this.state.subject === "") {
      this.setState({ subjectError: "Campo obligatorio" });
      flag = false;
    } else {
      this.setState({ subjectError: "" });
    }
    if (!this.state.text || this.state.text === "") {
      this.setState({ textError: "Campo obligatorio" });
      flag = false;
    } else {
      this.setState({ textError: "" });
    }
    if (!this.state.logo) {
      if (!this.state.logo_url) {
        flag = false;
      }
    }

    if (!this.state.facebook || this.state.facebook === "") {
      this.setState({ facebookError: "" });
    } else if (this.isValidURL(this.state.facebook)) {
      this.setState({ facebookError: "" });
    } else {
      flag = false;
      this.setState({ facebookError: "Url incorrecta" });
    }

    if (!this.state.twitter || this.state.twitter === "") {
      this.setState({ twitterError: "" });
    } else if (this.isValidURL(this.state.twitter)) {
      this.setState({ twitterError: "" });
    } else {
      flag = false;
      this.setState({ twitterError: "Url incorrecta" });
    }

    if (!this.state.instagram || this.state.instagram === "") {
      this.setState({ instagramError: "" });
    } else if (this.isValidURL(this.state.instagram)) {
      this.setState({ instagramError: "" });
    } else {
      flag = false;
      this.setState({ instagramError: "Url incorrecta" });
    }

    if (!this.state.youtube || this.state.youtube === "") {
      this.setState({ youtubeError: "" });
    } else if (this.isValidURL(this.state.youtube)) {
      this.setState({ youtubeError: "" });
    } else {
      flag = false;
      this.setState({ youtubeError: "Url incorrecta" });
    }

    return flag;
  }

  onchangeCheckBox = (value) => {
    this.setState({ type: value });
  };

  save = () => {
    const {
      html,
      logoOld,
      email,
      subject,
      text,
      logo,
      color,
      opt,
      facebook,
      twitter,
      instagram,
      youtube,
      selectedIndex,
      id,
      type,
    } = this.state;

    if (type === 1) {
      this.setState({ isLoading: true });

      const payload = {
        sender: email,
        subject,
        major_text: text,
        color_state: color,
        title_state: opt[selectedIndex].name,
        mail: opt[selectedIndex].key,
        facebook,
        instagram,
        twitter,
        youtube,
      };

      if (logoOld != logo) {
        payload.logo = logo ? logo.split(",")[1] : "";
      }

      if (html) {
        payload.template_url = html;
      }

      if (id) {
        axios
          .put(`/api/brands/${id}`, payload)
          .then(({ statusText }) => {
            if (statusText === "Created") {
              this.setState({
                isLoading: false,
                messageResponse: {
                  show: true,
                  text: "Plantilla de correo actualizada correctamente",
                  color: "success",
                },
              });
              setTimeout(() => {
                this.props.update();
                this.props.toggleSidebar();
              }, 600);
            }
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
              messageResponse: {
                show: true,
                text: "Error al actualizar, intenta más tarde. Si el problema persiste contacta a soporte.",
                color: "error",
              },
            });
          });
      } else {
        if (!payload.template_url) {
          return this.setState({
            isLoading: false,
            messageResponse: {
              show: true,
              text: "Selecciona un template HTML para continuar",
              color: "error",
            },
          });
        }
        axios
          .post("/api/brands", payload)
          .then(({ statusText }) => {
            if (statusText === "Created") {
              this.setState({
                isLoading: false,
                messageResponse: {
                  show: true,
                  text: "Plantilla de correo creada correctamente",
                  color: "success",
                },
              });
              setTimeout(() => {
                this.props.update();
                this.props.toggleSidebar();
              }, 600);
            }
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
              messageResponse: {
                show: true,
                text: "Error al actualizar, intenta más tarde. Si el problema persiste contacta a soporte.",
                color: "error",
              },
            });
          });
        // .then((resp) => {
        //   console.log(resp);
        //   this.props.update();
        //   // this.props.toggleSidebar();
        // });x
      }
    } else if (this.validateFields()) {
      this.setState({ isLoading: true });

      const payload = {
        sender: email,
        subject,
        major_text: text,
        color_state: color,
        title_state: opt[selectedIndex].name,
        mail: opt[selectedIndex].key,
        facebook,
        instagram,
        twitter,
        youtube,
      };

      if (logoOld != logo) {
        payload.logo = logo ? logo.split(",")[1] : "";
      }

      payload.template_url = null;
      // if (html) {
      //   payload.template_url = html;
      // }

      if (id) {
        axios
          .put(`/api/brands/${id}`, payload)
          .then(({ statusText }) => {
            if (statusText === "Created") {
              this.setState({
                isLoading: false,
                messageResponse: {
                  show: true,
                  text: "Plantilla de correo actualizada correctamente",
                  color: "success",
                },
              });
              setTimeout(() => {
                this.props.update();
                this.props.toggleSidebar();
              }, 600);
            }
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
              messageResponse: {
                show: true,
                text: "Error al actualizar, intenta más tarde. Si el problema persiste contacta a soporte.",
                color: "error",
              },
            });
          });
      } else {
        axios
          .post("/api/brands", payload)
          .then(({ statusText }) => {
            if (statusText === "Created") {
              this.setState({
                isLoading: false,
                messageResponse: {
                  show: true,
                  text: "Plantilla de correo actualizada correctamente",
                  color: "success",
                },
              });
              setTimeout(() => {
                this.props.update();
                this.props.toggleSidebar();
              }, 600);
            }
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
              messageResponse: {
                show: true,
                text: "Error al actualizar, intenta más tarde. Si el problema persiste contacta a soporte.",
                color: "error",
              },
            });
          });
      }
    }
  };

  showSkeletons = () => (
    <div style={{ marginTop: "1.5rem" }}>
      <div style={{ marginBottom: "1.5rem" }}>
        <Skeleton />
      </div>
      <div style={{ marginBottom: "1.5rem" }}>
        <Skeleton height={40} />
      </div>
      <div style={{ marginBottom: "1.5rem" }}>
        <Skeleton />
      </div>
      <div style={{ marginBottom: "1.5rem" }}>
        <Skeleton height={180} />
      </div>
      <div style={{ marginBottom: "1.5rem" }}>
        <Skeleton height={32} />
      </div>
      <div style={{ marginBottom: "1.5rem" }}>
        <Skeleton height={32} />
      </div>
    </div>
  );

  showForm = () => {
    const mainProps = {
      generateCheckbox: (name) => ({
        onChange: (value) => {
          this.handleCheckboxChange(name);
        },
        checked: this.state[name],
        id: name,
      }),
      generate: (name) => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value: this.state[name],
        error: this.state[`${name}Error`],
      }),
    };
    const styles = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `${this.state.color}`,
        },
        swatch: {
          padding: "5px",
          marginLeft: "15px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });
    return (
      <Row>
        <Column fluid>
          {!this.props.mail && (
            <Dropdown
              handleChange={(value) => this.handleDropdown(value)}
              options={this.generateDropdownOptions()}
              multiple={false}
              error={this.state.errorDropdown}
              selected={this.state.selectedIndex}
              selectedTitle={
                this.state.selected
                  ? this.state.selected
                  : "Selecciona el correo a configurar"
              }
            />
          )}
          {this.state.messageResponse?.show && (
            <div style={{ marginTop: "1em" }}>
              <Message
                onClose={() => this.props?.toggleSidebar()}
                color={this.state.messageResponse?.color}
                children={this.state.messageResponse?.text}
              />
            </div>
          )}
          {this.state.selectedIndex !== -1 && (
            <div>
              <RadioGroup
                className="no-margin-checkbox"
                selected={this.state.type}
                onChange={this.onchangeCheckBox}
              >
                <Radio
                  value={0}
                  text="Personalizar Correo"
                  containerClass="custom-size"
                  className="no-margin-left"
                />
                <Radio
                  value={1}
                  text="Subir HTML"
                  containerClass="custom-size max"
                />
              </RadioGroup>
              <br />
              {this.state.type === 0 ? (
                <div>
                  <div className="container-picker">
                    <Label>Color principal del correo</Label>
                    <div style={styles.swatch} onClick={this.handleClick}>
                      <div style={styles.color} />
                    </div>
                  </div>

                  {this.state.displayColorPicker ? (
                    <div style={styles.popover}>
                      <div style={styles.cover} onClick={this.handleClose} />
                      <SketchPicker
                        color={this.state.color}
                        onChange={this.handleChangeColor}
                      />
                    </div>
                  ) : null}
                  <Dropzone
                    required
                    label="Logotipo"
                    accept="image/png,image/jpg,image/jpeg"
                    imageSrc={this.state.logo_url}
                    onDrop={this.onDrop}
                    onDelete={this.onDelete}
                  />
                  <Input
                    type="text"
                    {...mainProps.generate("email")}
                    required
                    placeholder="hola@mienvio.mx"
                    label="Remitente"
                    help="Este es el correo del que llegaran los correos de seguimiento."
                  />
                  <Input
                    type="text"
                    {...mainProps.generate("subject")}
                    required
                    placeholder="Seguimiento..."
                    label="Asunto"
                    help="Este es el sunto del que llegaran los correos de seguimiento."
                  />
                  <Input
                    type="text"
                    {...mainProps.generate("text")}
                    required
                    placeholder="En camino"
                    label="Texto Principal"
                    help="Este texto se utiliza para el cuerpo de texto del correo. Por ejemplo tu compra de www.tienda.mx ya esta en camino"
                  />

                  <Input
                    type="text"
                    {...mainProps.generate("facebook")}
                    placeholder="Facebook"
                    label="Redes Sociales"
                  />
                  <Input
                    type="text"
                    style={{ marginTop: "-30px" }}
                    {...mainProps.generate("twitter")}
                    placeholder="Twitter"
                  />
                  <Input
                    type="text"
                    style={{ marginTop: "-30px" }}
                    {...mainProps.generate("instagram")}
                    placeholder="Instagram"
                  />
                  <Input
                    type="text"
                    style={{ marginTop: "-30px" }}
                    {...mainProps.generate("youtube")}
                    placeholder="Youtube"
                  />
                </div>
              ) : (
                <div>
                  <Input
                    type="text"
                    {...mainProps.generate("email")}
                    required
                    placeholder="hola@mienvio.mx"
                    label="Remitente"
                    help="Este es el correo del que llegaran los correos de seguimiento."
                  />
                  <Input
                    type="text"
                    {...mainProps.generate("subject")}
                    required
                    placeholder="Seguimiento..."
                    label="Asunto"
                    help="Este es el sunto del que llegaran los correos de seguimiento."
                  />
                  <Dropzone
                    label="Subir template personalizado"
                    accept="text/html"
                    onDrop={this.onDropHtml}
                    onDelete={this.onDeleteHtml}
                  />

                  <iframe
                    src={this.state.htmlSrc}
                    style={{ width: "100%", height: 400 }}
                    frameBorder="0"
                    alt="preview"
                    title="preview"
                  />
                </div>
              )}
            </div>
          )}
        </Column>
      </Row>
    );
  };

  handleDropdown = (event) => {
    this.setState({
      selectedIndex: event,
      selected: this.state.opt[event].name,
    });
  };

  generateDropdownOptions = () =>
    this.state.opt.map((element) => (
      <Row key={element.key}>
        <Column fluid>
          <Text>{element.name}</Text>
        </Column>
      </Row>
    ));

  render() {
    return (
      <ContentAdapter className="storefront-editor">
        {this.state.isLoading ? this.showSkeletons() : this.showForm()}
        {this.state.selectedIndex !== -1 && this.state.type === 0 && (
          <Text style={{ marginTop: "1rem", fontSize: "11px" }}>
            Ver ejemplo de correo{" "}
            <Button
              type="link"
              onClick={() =>
                window.open("https://www.mienvio.mx/img/tracking.jpg")
              }
            >
              <i className="ion-android-open" />
            </Button>
          </Text>
        )}
        <BottomContainer>
          <Row divisions={20}>
            <Column style={{ textAlign: "right" }} fluid>
              <Button
                className=""
                onClick={() => this.save()}
                loading={this.state.isLoading}
              >
                Guardar
              </Button>
            </Column>
          </Row>
        </BottomContainer>
      </ContentAdapter>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSidebar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MailConfig);
