// @flow

import axios from 'axios';
import type { Dispatch as ReduxDispatch } from 'redux';


export const createRegion = (
  name,
  countries,
) => axios
  .post('api/regions', {
    name,
    countries,
  })
  .then(response => (response))
  .catch(err => (err));

export const updateRegion = (
  id,
  name,
  countries,
) => axios
  .put(`api/regions/${id}`, {
    name,
    countries,
  })
  .then(response => (response))
  .catch(err => (err));

export const getRegions = () => axios
  .get('api/regions')
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));

export const getManagerRegions = id => axios
  .get(`api/manager/${id}/regions`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));


export const getSingleRegion = id => axios
  .get(`api/regions/${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));

export const deleteRegion = id => axios
  .delete(`api/regions/${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));
