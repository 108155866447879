import React from 'react';
import styled from 'styled-components';
import styledProps from 'styled-props';
import colors from '../../utils/colors';
import AutomationIcon from '../AutomationIcon/index';
import { Row, Column } from 'hedron';
import { Line } from './styledObjects';

const StyledMaxiButton = styled.div`
  background: #fff;
  width: 100%;
  border: 2px solid #cad2df;
  border-radius: 3px;
  padding: 0.5rem;
  cursor: pointer;
  transition: 200ms;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  box-sizing: border-box;

  &:hover {
    background: #eee;
  }

  div {
    padding: 0;
  }

  p {
    color: #778092;
    font-size: 13px;
    font-weight: bold;
    margin: 0;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Maxibutton = props => (
  <div>
    <div onClick={props.onClick}>
      <StyledMaxiButton>
        <Row divisions={10}>
          <Column xs={1} className="icon-container">
            <AutomationIcon icon={props.icon} />
          </Column>
          <Column xs={9} fluid>
            <p>{props.text}</p>
          </Column>
        </Row>
        {props.children}
      </StyledMaxiButton>
    </div>
    {!props.last && <Line />}
  </div>
);

export default Maxibutton;
