import React from 'react';
import styledProps from 'styled-props';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import State from '../static/State';
import './index.css';

const margin = {
  default: '146px',
  tabs: '104px'
};
const StyledDiv = styled.div`
  margin-top: ${styledProps(margin, 'type')};
  margin-bottom: 146px;
  text-align: center;
  float: left;
  line-height: 200px;
  height: 200px;

  @media screen and (max-width: 39.9375em) {
    margin-top: 40px;
    margin-bottom: 200px;
  }
`;

StyledDiv.defaultProps = {
  type: 'default'
};

const StateContent = styled.div`
  vertical-align: middle;
  display: inline-block;
  max-width: 45%;
  text-align: left;
  margin-left: 32px;
  line-height: 0px;
`;

const EmptyState = props => (
  <StyledDiv type={props.type}>
    <State name={props.name} />
    <StateContent>{props.children}</StateContent>
  </StyledDiv>
);

export default EmptyState;
