import React from 'react';
import styled from 'styled-components';
import styledProps from 'styled-props';
import colors from '../../utils/colors';

const sizes = {
  default: '303px',
  full: '100%'
};

const paddings = {
  default: '26px 16px',
  nopadding: '26px 0px'
};
const boxShadows = {
  default: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
  nopadding: 'none'
};

const StyledCard = styled.div`
  background: ${colors.secondary.white};
  box-shadow: ${styledProps(boxShadows, 'padding')};
  border-radius: 3px;
  outline: none;
  min-height: 100px;
  height: auto;
  width: ${styledProps(sizes, 'size')};
  padding: ${styledProps(paddings, 'padding')};
  box-sizing: border-box;
  overflow-x: auto;
  position: relative;

  @media screen and (max-width: 39.9375em) {
    padding-top: 4px;
    padding-bottom: 4px;
    border-color: white;
    border-style: inset;
    border-width: 0px 10px 0px 0px;

    h1[type='titleScreen'] + p[type='bodyDetail'] {
      display: none;
    }
  }
`;
StyledCard.defaultProps = {
  size: 'default',
  padding: 'default'
};

const StyledDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.gray.sky_gray};
  margin-top: 26px;
  margin-bottom: 26px;
  padding: 0 !important;
  display: block;
`;

const Card = props => (
  <StyledCard
    ref={props.innerRef}
    size={props.size}
    style={props.style}
    className={`card ${props.className && props.className}`}
    padding={props.padding}
    onClick={props.onClick ? props.onClick : null}
  >
    {props.children}
  </StyledCard>
);

const Divider = props => <StyledDivider style={props.style} className={props.className} />;

export default Card;
export { Divider };
