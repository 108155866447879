import axios from 'axios';

export const getQuotes = id => axios
  .get(`api/quotes/marketplace/${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));

export const getQuoteById = (id, quote_id) => axios
  .get(`api/quotes/marketplace/${id}?search=${quote_id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));
