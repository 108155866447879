import { initialState } from './initialState';
import { GO_TO_PAY } from './actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
    case GO_TO_PAY:
      return {
        ...state,
        dashboardToCheckout: action.payload.dashboardToCheckout,
        payload: action.payload.payload
      };
    default:
      return state;
  }
};
