import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import styledProps from "styled-props";
import colors from "../../utils/colors";

import Text from "../Text";
import Button from "../Button";

const icons = {
  success: "ion-checkmark",
  info: "ion-ios-information",
  error: "ion-android-warning",
  warning: "ion-flash",
};

const backgrounds = {
  success: "#6cbd14",
  info: "#6ea6e8",
  error: "#db0c25",
  warning: "#f5b023",
};

const iconsBackgrounds = {
  success: "#4e890d",
  info: "#007acc",
  error: "#930617",
  warning: "#bb881f",
};

const StyledMessage = styled.div`
  background: ${styledProps(backgrounds, "color")};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  outline: none;
  width: auto;
  box-sizing: border-box;
  overflow-x: auto;
  height: auto;
  color: #fff;
  margin-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
  cursor: pointer;
`;

StyledMessage.defaultProps = {
  color: "success",
};

const StyledX = styled.span`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const I = styled.i`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 21px;
`;

const IDiv = styled.div`
  background-color: ${styledProps(iconsBackgrounds, "background")};
  vertical-align: middle;
  height: 100%;
  position: absolute;
  width: 45px;
  display: inline-block;
`;

const MessageContent = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-left: 56px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
`;

const Message = (props) =>
  props.color === "error" ||
  props.color === "success" ||
  props.color === "info" ||
  props.color === "warning" ? (
    <StyledMessage
      onClick={props.onClick}
      color={props.color}
      style={props.style}
      className={props.className}
    >
      <IDiv background={props.color}>
        <I className={icons[props.color]} />
      </IDiv>
      <MessageContent>
        {props.showClose && (
          <StyledX
            className="ion-close"
            onClick={() => {
              props.onClose();
            }}
          />
        )}
        <Text type="labelEdit" style={{ color: "white" }}>
          {props.children}
        </Text>
      </MessageContent>
    </StyledMessage>
  ) : (
    <StyledMessage
      onClick={props.onClick}
      color={props.color}
      style={props.style}
      className={props.className}
    >
      <Text style={{ color: "white" }}>{props.children}</Text>
    </StyledMessage>
  );

Message.defaultProps = {
  onClick: () => {},
  onClose: () => {},
  showClose: true,
};

Message.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  style: PropTypes.any,
  showClose: PropTypes.bool,
};

export default Message;
