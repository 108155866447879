import React from "react";
import styled from "styled-components";
import colors from "../../utils/colors";
import { Title } from "../Text";

const StyledModal = styled.div`
  z-index: 1000;
  background: ${colors.secondary.white};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  right: 0;
  min-height: 100%;
  padding-bottom: 80px;
`;

const Nav = styled.div`
  width: 100%;
  height: 77px;
  position: relative;
  max-height: 77px;
  box-sizing: border-box;
  text-align: center;
  h1 {
    margin: 0;
    line-height: 77px;
  }
`;

const Content = styled.div`
  min-width: 365px;
  width: 365px;
  margin-left: auto;
  margin-right: auto;
`;

const ContentExpanded = styled.div`
  min-width: 365px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 26px;
`;

const Action = styled.div`
  position: absolute;
  line-height: 77px;
  display: inline-block;
  cursor: pointer;
  left: 31px;

  @media screen and (max-width: 39.9375em) {
    left: 0.5rem;
    top: 18px;
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    text-align: center;

    i {
      margin: 0 !important;
      color: #778092;
      font-size: 23px;
    }

    h1 {
      display: none !important;
    }
  }

  i {
    margin-right: 11px;
  }
  h1 {
    margin: 0;
    display: inline-block;
  }
`;

const Modal = (props, context) => (
  <StyledModal className={props.className ? props.className : ""}>
    <Nav className={props.className ? props.className : ""}>
      <Action onClick={props.action}>
        <i className="ion-close-round" />
        <Title>{props.actionText}</Title>
      </Action>
      {props.title ? <Title type="titleCard">{props.title}</Title> : null}
    </Nav>
    {props.expanded ? (
      <ContentExpanded className={props.classContentExpanded}>
        {props.children}
      </ContentExpanded>
    ) : (
      <Content className={props.classContent}>{props.children}</Content>
    )}
  </StyledModal>
);

export default Modal;
export { Nav, Action };
