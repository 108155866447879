// @flow
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Redirect } from "react-router-dom";
import { errors } from "../../utils/helpers";

import { requestPasswordChange } from "../../modules/user";
import { hideMessage } from "../../modules/message";

import Text from "../Text";
import Button from "../Button";
import Message from "../Message";
import Logo from "../static/Logo";
import Input from "../Input";
import LoginContainer, { Line } from "../Login/container";
import { emailRegex } from "../../utils/global_functions";

type DefaultProps = {
  message: {
    messageType: string,
    show: boolean,
    text: string,
  },
};

export type Props = {
  ...DefaultProps,
  goToLogin: () => void,
  requestPasswordChange: (email: string) => void,
  hideMessage: () => void,
  hideMessage: Function,
};

type State = {
  email: string,
  emailError: string,
};

class PasswordRequest extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    message: {
      messageType: "",
      show: false,
      text: "",
    },
  };

  state: State = {
    email: "",
    emailError: "",
  };

  constructor(props) {
    super(props);
    this.getUrlParameter = this.getUrlParameter.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  isLoggedIn() {
    return !!localStorage.getItem("token");
  }

  isValidEmail(email) {
    return emailRegex.test(email);
  }

  getUrlParameter(name) {
    const aux = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + aux + "=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  renderContent() {
    const { requestPasswordChange } = this.props;
    const { email, emailError } = this.state;

    const emailForm = this.getUrlParameter("email");

    const content = !emailForm ? (
      <div>
        <div style={{ marginBottom: "16px", marginTop: "32.1px" }}>
          <Text type="textTable">
            ¿Olvidaste tu contraseña? No hay por que preocuparse, a todos nos ha
            pasado. Ingresa tu correo electrónico y te enviaremos instrucciones
            para establecer una nueva contraseña.
          </Text>
        </div>
        <Line />
        <Input
          type="text"
          onChange={(email: string) => {
            this.setState({
              email,
              emailError: errors("email", email),
            });
          }}
          value={email}
          required
          error={emailError}
          placeholder="micorreo@gmail.com"
          label="Correo electrónico"
          onKeyPress={(event) => {
            if (event.key === "Enter" && this.isValidEmail(email)) {
              requestPasswordChange(email);
            }
          }}
          style={{ width: "100%" }}
        />
        <Button
          className="login-btn"
          onClick={() => {
            if (this.isValidEmail(email)) {
              requestPasswordChange(email);
            }
          }}
        >
          Recuperar contraseña
        </Button>
      </div>
    ) : (
      <div>
        <div style={{ marginBottom: "16px", marginTop: "32.1px" }}>
          <Text type="textTable">Recuperar tu contraseña</Text>
        </div>
        <Line />
        <div style={{ marginTop: "24px", marginBottom: "16px" }}>
          <Text type="titleAuxiliarInfo">Revisa tu correo electrónico</Text>
        </div>
        <div style={{ marginBottom: "11px" }}>
          <Text type="bodyDetail">
            Te enviamos un correo con un link para que puedas resetear tu
            contraseña.
          </Text>
        </div>
        <Button
          className="login-btn"
          onClick={() => {
            requestPasswordChange(emailForm);
          }}
        >
          Reenviar correo
        </Button>
      </div>
    );

    return content;
  }

  render() {
    const { message, hideMessage, goToLogin } = this.props;

    if (this.isLoggedIn()) {
      return <Redirect to="/" />;
    }
    if (
      message.show &&
      (message.messageType === "error" || message.messageType === "success")
    ) {
      setTimeout(hideMessage, 8000);
    }

    return (
      <LoginContainer>
        {message.show ? (
          <Message color={message.messageType} onClose={hideMessage}>
            {message.text}
          </Message>
        ) : null}
        <div style={{ cursor: "pointer" }} onClick={goToLogin}>
          <Logo />
        </div>
        <br />
        {this.renderContent()}
      </LoginContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToLogin: () => push("/login"),
      requestPasswordChange,
      hideMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRequest);
