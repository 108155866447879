type State = {
  +main?: object,
  +title?: string
};

export const initialState: State = {
  main: {
    isValid: false,
    country: 'México',
    countryError: '',
    idCountry: null,
    origin: '',
    originError: '',
    destiny: '',
    destinyError: '',
    dimensions: {
      length: '',
      width: '',
      height: ''
    },
    dimensionsError: '',
    weight: '',
    weightError: '',
    volumetricWeight: ''
  },
  loading: false,
  services: {
    list: null,
    service: null,
    haveInsurance: false,
    cost: ''
  }
};
