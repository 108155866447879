// @flow
import axios from 'axios';
import type {
  Dispatch as ReduxDispatch
} from 'redux';
import {
  push
} from 'react-router-redux';
import Scriptly from 'scriptly';
import {
  showLoader,
  hideLoader
} from './loader';
import {
  showError
} from './error';
import {
  showMessage
} from './message';
import Swal from 'sweetalert2';


export const cardsUrl = '/api/cards';

export const SET_USER_CARDS = 'SET_USER_CARDS';

export const STRIPE_PUBLIC = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
  'pk_test_lroL1tij4uijiCpr4hhwBZzh' :
  process.env.REACT_APP_STRIPE;
//export const STRIPE_PUBLIC = 'pk_test_lroL1tij4uijiCpr4hhwBZzh';

type Card = {
  object_id ? : string,
  brand ? : string,
  last4: string,
  name: string,
  alias: string,
  exp_moth: number,
  exp_year: number,
  funding ? : string
};

type CreateCardObject = {
  object: 'card',
  exp_month: string,
  exp_year: string,
  number: string,
  name: string,
  cvc: string
};

type CardsInfo = {
  results: Array < Card >
};

type State = {
  +cards ? : CardsInfo | null
};

type Action = {
  +type: string,
  +cards ? : CardsInfo
};

type Dispatch = ReduxDispatch < Action > ;

const initialState: State = {
  results: [],
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case SET_USER_CARDS:
      return action.cards;
    default:
      return state;
  }
};

export const getUserCards = (hidden: boolean = false) => (dispatch: Dispatch) => {
  if (!hidden) dispatch(showLoader('cards'));
  return axios
    .get(cardsUrl)
    .then((response) => {
      dispatch(setUserCards(response.data));
      if (!hidden) dispatch(hideLoader());
    })
    .catch((err) => {
      dispatch(hideLoader());
      dispatch(showError('Error al obtener las tarjetas'));
    });
};

export const setUserCards = (cards: CardsInfo) => ({
  type: SET_USER_CARDS,
  cards,
});

export const getFree = (email, action) => (dispatch: Dispatch) => {
  dispatch(push('/verify', {
    mail: email,
    action: action
  }));
};

const planName = {
  5: "Entry",
  2: "Advance",
  3: "Professional",
  4: "Enterprise"
}

export const createPlan = (card_id, plan_id, billing, email, action) => (dispatch: Dispatch) => {
  return axios
    .post('/api/subscription/create', {
      card_id: card_id,
      plan_id: plan_id,
      billing: false,
    })
    .then((response) => {
      if (response.status == 200 || response.status == 201) {
        Swal.fire({
          title: 'Gracias!',
          text: plan_id === 1 ? 'La activación de tu plan Free se ha realizado exitosamente' : 'El pago de tu plan ' + planName[plan_id] + ' fue realizado exitosamente',
          icon: 'success',
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.value) {
            if (action == "edit") {
              dispatch(push('/'));
            } else {
              dispatch(push('/verify', {
                mail: email,
                action: action
              }));
            }
          }
        });
      }
    })
    .catch((err) => {
      // the user has already an existing plan so change it
      if (err.response.status === 409) {
        dispatch(changePlan(plan_id, 'El pago de tu plan ' + planName[plan_id] + ' fue realizado exitosamente'));
      } else {
        dispatch(hideLoader());
        dispatch(showError('Error al Asociar el Plan, Intente mas tarde'));
      }
    });
};

export const changePlan = (plan_id, text) => (dispatch: Dispatch) => {
  return axios
    .post('/api/subscription/change', {
      new_plan_id: plan_id
    })
    .then((response) => {
      if (response.status == 200 || response.status == 201 || response.status == 204) {
        Swal.fire({
          title: 'Gracias!',
          text: text,
          icon: 'success',
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          dispatch(push('/'));
        });
      }
    })
    .catch((err) => {
      dispatch(hideLoader());
      dispatch(showError('Error al Asociar el Plan, Intente mas tarde'));
    });
};

// Create a new plan (from free to a payment plan)
export const createCardPlan = (
    card: CreateCardObject,
    alias: string,
    plan_id,
    billing,
    email,
    action,
    invoice,
    hideMessage ? : boolean,
    callback ? : () => mixed,
  ) => (dispatch: Dispatch) => Scriptly.loadJavascript('https://js.stripe.com/v2/')
  .then(() => {
    return new Promise(function(resolve, reject) {
      window.Stripe.setPublishableKey(STRIPE_PUBLIC);
      window.Stripe.card.createToken(card, (status, response) => {
        return axios
          .post(cardsUrl, {
            token: response.id,
            invoice_default: true,
            alias: "Tarjeta para plan",
          })
          .then((res) => {
            if (hideMessage) return;
            if (invoice === false || billing == true) {
              dispatch(createPlan(res.data.stripe_id, plan_id, billing, email, action))
                .then((res) => {
                  resolve(response)
                })
            } else {
              let planName = {
                5: "Entry",
                2: "Advance",
                3: "Professional",
                4: "Enterprise"
              }
              Swal.fire({
                title: 'Gracias!',
                text: 'El pago de tu plan ' + planName[plan_id] + ' fue realizado exitosamente',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false,
              }).then((result) => {
                if (result.value) {
                  resolve(response)
                  dispatch(push(`/invoice-plan/${res.data.stripe_id}/${plan_id}/${action}`))
                }
              });
            }
          }).catch((err) => {
            resolve(response)
            if(err.response.data.message) {
              dispatch(showError(err.response.data.message))
            } else {
              dispatch(showError('Error al asociar el plan, por favor contactar a soporte'))
            }
          })
      })
    }).catch((err) => {
      dispatch(showError('Error al asociar el plan, por favor contactar a soporte'))
    });
  })

export const createCard = (
    card: CreateCardObject,
    alias: string,
    hideMessage ? : boolean,
    callback ? : () => mixed,
  ) => (dispatch: Dispatch) => Scriptly.loadJavascript('https://js.stripe.com/v2/')
  .then(() => {
    window.Stripe.setPublishableKey(STRIPE_PUBLIC);
    window.Stripe.card.createToken(card, (status, response) => {
      if (response.error) {
        const errorMessages = {
          incorrect_number: 'El número de la tarjeta es incorrecto',
          invalid_number: 'El número de la tarjeta es inválido',
          invalid_expiry_month: 'El mes de expiración de la tarjeta es inválido',
          invalid_expiry_year: 'El año de expiración de la tarjeta es inválido',
          invalid_cvc: 'El código de seguridad es inválido',
          expired_card: 'La tarjeta ha expirado',
          incorrect_cvc: 'El código de seguridad es incorrecto',
          incorrect_zip: 'El código postal de la tarjeta es inválido',
          card_declined: 'La tarjeta fue declinada',
          processing_error: 'Ocurrió un error procesando su tarjeta',
          rate_limit: 'Espere un momento y vuelva intentarlo de nuevo',
          generic_decline: 'La tarjeta fue declinada, contacta a tu banco',
          insufficient_funds: 'Tarjeta con fondos insuficientes, intenta con otra tarjeta',
          fraudulent: 'La transacción fue declinada por tu banco',
        };
        dispatch(showError(errorMessages[response.error.code]));
      } else {
        return axios
          .post(cardsUrl, {
            token: response.id,
            alias,
          })
          .then((res) => {
            dispatch(getUserCards());
            if (hideMessage) return;
            dispatch(
              showMessage(
                'success',
                'Tarjeta creada. Ahora espera un momento mientras obtenemos tus métodos de pago.',
              ),
            );
          })
          .catch((err) => {
            dispatch(showError(err.response.data.message));
          });
      }
    });
  })
  .catch((err) => {
    if (err.response.data.error.type === 'external_provider_error') {
      const errorMessages = {
        incorrect_number: 'El número de la tarjeta es incorrecto',
        invalid_number: 'El número de la tarjeta es inválido',
        invalid_expiry_month: 'El mes de expiración de la tarjeta es inválido',
        invalid_expiry_year: 'El año de expiración de la tarjeta es inválido',
        invalid_cvc: 'El código de seguridad es inválido',
        expired_card: 'La tarjeta ha expirado',
        incorrect_cvc: 'El código de seguridad es incorrecto',
        incorrect_zip: 'El código postal de la tarjeta es inválido',
        card_declined: 'La tarjeta fue declinada',
        processing_error: 'Ocurrió un error procesando su tarjeta',
        rate_limit: 'Espere un momento y vuelva intentarlo de nuevo',
        generic_decline: 'La tarjeta fue declinada, contacta a tu banco',
        insufficient_funds: 'Tarjeta con fondos insuficientes, intenta con otra tarjeta',
        fraudulent: 'La transacción fue declinada por tu banco',
      };
      dispatch(showError(errorMessages[err.response.data.error.subtype]));
    } else {
      dispatch(showError(err.response.data.error));
    }
  });

export const deleteCard = (id: number) => (dispatch: Dispatch) => {
  dispatch(showLoader('basic'));
  return axios
    .delete(`${cardsUrl}/${id}`)
    .then((response) => {
      dispatch(getUserCards());
      dispatch(hideLoader());
      dispatch(showMessage('success', 'Tarjeta eliminada'));
    })
    .catch((err) => {
      dispatch(hideLoader());
      dispatch(showError(err.response.data.error));
    });
};

export const createPlanBilling = (card_id, plan_id, email, action) => (dispatch: Dispatch) => {
  axios
    .post('/api/subscription/create', {
      card_id: card_id,
      plan_id: plan_id,
      billing: false,
    })
    .then((response) => {
      dispatch(hideLoader());

      if (response.status == 200 || response.status == 201) {
        if (action === "edit") {
          dispatch(push('/'));
        } else {
          dispatch(push('/verify', {
            mail: email,
            action: action
          }));
        }
      }
    })
    .catch((err) => {
      // the user has already an existing plan so change it
      if (err.response.status === 409) {
        dispatch(changePlan(plan_id));
      } else {
        dispatch(hideLoader());
        dispatch(showError('Error al Asociar el Plan, Intente mas tarde'));
      }
    });
};

export const cancelPlan = () => (dispatch: Dispatch) => {
  return axios
    .delete('/api/subscription', {
    })
    .then((response) => {
      if (response.status == 200 || response.status == 201 || response.status == 204) {
        Swal.fire({
          title: 'Gracias!',
          text: 'El cambio de tu plan al plan Free fue realizado exitosamente',
          icon: 'success',
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          dispatch(push('/'));
        });
      }
    })
    .catch((err) => {
      dispatch(hideLoader());
      dispatch(showError('Error al Asociar el Plan, Intente mas tarde'));
    });
};
