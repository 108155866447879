import React from 'react';
import axios from 'axios';
import Button from "../../components/Button";
import WrapperSideBar from "../../components/WrapperSideBar";
import { BottomContainer, ContentAdapter } from "../../components/WrapperSideBar/styledObjects";
import Input, { HelpText, Label, Required } from "../../components/Input";
import { Column, Row } from "hedron";
import { bindActionCreators } from "redux";
import { changeTitle as changeSidebarTitle, toggle as toggleSideBar } from "../../modules/CollapsibleBar/actions";
import { connect } from "react-redux";
import { saveCalendarProviders } from "../../modules/calendarProviders";
import { toast } from "react-toastify";
import Text from "../../components/Text";
import Dropdown from "../../components/Dropdown";

const CALENDAR_PROVIDER = 'CALENDAR_PROVIDER';


class Provider extends React.Component {
    state = {
        data: [],
        lu_i: null,
        lu_f: null,
        ma_i: null,
        ma_f: null,
        mi_i: null,
        mi_f: null,
        ju_i: null,
        ju_f: null,
        vi_i: null,
        vi_f: null,
        sa_i: null,
        sa_f: null,
        do_i: null,
        do_f: null,
        provider_id: null,
        calendar_provider_selected: null,
        service_id: 0,
        calendar_estandar: null,
        calendar_express: null,
    }

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        this.getServices();
    }

    getServices = () => {
        axios.get('api/services_providers')
            .then((response) => {
                this.setState({ data: response.data });
            })
            .catch(error => console.log(error));
    };

    fillCalendar = calendar => {

        if (calendar !== null) {

            this.setState({
                lu_i: calendar['lu_i'],
                lu_f: calendar['lu_f'],
                ma_i: calendar['ma_i'],
                ma_f: calendar['ma_f'],
                mi_i: calendar['mi_i'],
                mi_f: calendar['mi_f'],
                ju_i: calendar['ju_i'],
                ju_f: calendar['ju_f'],
                vi_i: calendar['vi_i'],
                vi_f: calendar['vi_f'],
                sa_i: calendar['sa_i'],
                sa_f: calendar['sa_f'],
                do_i: calendar['do_i'],
                do_f: calendar['do_f'],
            })

        } else {
            this.setState({
                lu_i: '00:00',
                lu_f: '00:00',
                ma_i: '00:00',
                ma_f: '00:00',
                mi_i: '00:00',
                mi_f: '00:00',
                ju_i: '00:00',
                ju_f: '00:00',
                vi_i: '00:00',
                vi_f: '00:00',
                sa_i: '00:00',
                sa_f: '00:00',
                do_i: '00:00',
                do_f: '00:00',
            })
        }
    }

    renderServices = () => {
        var dataProvider = this.state.data.filter(provider => provider.id == this.state.provider_id);
        var servicesProvider = dataProvider.map(provider => provider.services);
        var services = []


        servicesProvider.every((element, index) => {
            element.forEach((service) => {
                services.push(service.level)
            })
        });

        return services.map((service, index) => (<Text key={service}>{service}</Text>));
    }

    handleChangeDropdownService = (selectedOption) => {
        this.setState({ service_id: selectedOption })

        if (selectedOption === 0) {
            this.fillCalendar(this.state.calendar_estandar);
        }

        if (selectedOption === 1) {
            this.fillCalendar(this.state.calendar_express);
        }
    }

    renderCarriers = data => data.map(element => (
        <div className="provider">
            <p>{element.name}</p>
            <div className="services-content">
                {element.has_calendar && <p>Calendario Ya configurado</p>}

                <Button
                    className="header-btn last"
                    onClick={
                        () => {
                            this.props.toggleSideBar(true, 'Visualizar Calendario', CALENDAR_PROVIDER);
                            this.setState({ provider_id: element.id, service_id: 0 });

                            if (element.has_calendar) {

                                var calendarEstandar = element.calendar.filter((calendar) => {
                                    return calendar.service.level == 'estandar' || calendar.service.level == 'nextday'
                                })

                                if (calendarEstandar.length == 1) {
                                    this.setState({ calendar_estandar: calendarEstandar[0] });
                                    this.fillCalendar(calendarEstandar[0]);
                                }

                                var calendarExpress = element.calendar.filter((calendar) => {
                                    return calendar.service.level == 'express' || calendar.service.level == 'sameday'
                                })

                                if (calendarExpress.length == 1) {
                                    this.setState({ calendar_express: calendarExpress[0] });
                                }
                            } else {
                                this.fillCalendar(null);
                            }
                        }
                    }
                >
                    Visualizar Calendario
                </Button>
            </div>


        </div >
    ));

    handleOnChange(name, value) {
        this.setState({
            [name]: value
        });
    }

    validateFields(region) {
        let flag = true;
        const {
            lu_i,
            lu_f,
            ma_i,
            ma_f,
            mi_i,
            mi_f,
            ju_i,
            ju_f,
            vi_i,
            vi_f,
            sa_i,
            sa_f,
            do_i,
            do_f,
            provider_id
        } = this.state;

        return flag;
    }

    notify = (message, type) => {
        if (type === 'success') {
            toast.success(message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.error(message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    saveCalendar() {
        const {
            lu_i,
            lu_f,
            ma_i,
            ma_f,
            mi_i,
            mi_f,
            ju_i,
            ju_f,
            vi_i,
            vi_f,
            sa_i,
            sa_f,
            do_i,
            do_f,
            provider_id,
            service_id
        } = this.state;
        if (this.validateFields(true)) {
            saveCalendarProviders(
                lu_i,
                lu_f,
                ma_i,
                ma_f,
                mi_i,
                mi_f,
                ju_i,
                ju_f,
                vi_i,
                vi_f,
                sa_i,
                sa_f,
                do_i,
                do_f,
                provider_id,
                service_id
            ).then((res) => {
                if (res.response.status === 201) {
                    this.notify('Calendario de Activación automático creado exitósamente', 'success');
                    this.props.toggleSideBar(false);

                    this.setState({ reloadAccounts: true }, () => {
                        this.setState({ reloadAccounts: false });
                    });
                    this.getServices();
                    this.renderCarriers(this.state.data)
                } else {
                    this.notify('Error al crear el Calendario de Activación automático', 'error');
                    this.props.toggleSideBar(false);

                }
            });
        }
    }

    _renderSidebarContent = () => {

        const {
            service_id
        } = this.state;

        return (
            <ContentAdapter>
                <HelpText>
                    Indique los horarios en los que estará disponible la paquetería. si desea que la paquetería esté
                    disponible durante todo un día, seleccione 12:00am en el inicio y final del día.
                </HelpText>
                <Dropdown
                    required
                    label="Seleccione El nivel de servicio"
                    options={this.renderServices()}
                    style={{ marginTop: '12px' }}
                    placeholder={<Text className="dropdown-placeholder">Servicio</Text>}
                    selected={service_id}
                    handleChange={(value) => this.handleChangeDropdownService(value)}
                />
                <Row divisions={20}>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('lu_i', value)}
                            type="time"
                            required
                            value={this.state.lu_i}
                            placeholder="00:00"
                            label="Lunes Inicia"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('lu_f', value)}
                            type="time"
                            required
                            value={this.state.lu_f}
                            placeholder="00:00"
                            label="Lunes Termina"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('ma_i', value)}
                            type="time"
                            required
                            value={this.state.ma_i}
                            placeholder="00:00"
                            label="Martes Inicia"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('ma_f', value)}
                            type="time"
                            required
                            value={this.state.ma_f}
                            placeholder="00:00"
                            label="Martes Termina"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('mi_i', value)}
                            type="time"
                            required
                            value={this.state.mi_i}
                            placeholder="00:00"
                            label="Miércoles Inicia"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('mi_f', value)}
                            type="time"
                            required
                            value={this.state.mi_f}
                            placeholder="00:00"
                            label="Miércoles Termina"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('ju_i', value)}
                            type="time"
                            required
                            value={this.state.ju_i}
                            placeholder="00:00"
                            label="Jueves Inicia"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('ju_f', value)}
                            type="time"
                            required
                            value={this.state.ju_f}
                            placeholder="00:00"
                            label="Jueves Termina"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('vi_i', value)}
                            type="time"
                            required
                            value={this.state.vi_i}
                            placeholder="00:00"
                            label="Viernes Inicia"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('vi_f', value)}
                            type="time"
                            required
                            value={this.state.vi_f}
                            placeholder="00:00"
                            label="Viernes Termina"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('sa_i', value)}
                            type="time"
                            required
                            value={this.state.sa_i}
                            placeholder="00:00"
                            label="Sábado Inicia"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('sa_f', value)}
                            type="time"
                            required
                            value={this.state.sa_f}
                            placeholder="00:00"
                            label="Sábado Termina"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('do_i', value)}
                            type="time"
                            required
                            value={this.state.do_i}
                            placeholder="00:00"
                            label="Domingo Inicia"

                        />
                    </Column>
                    <Column xs={10} >
                        <Input
                            onChange={value => this.handleOnChange('do_f', value)}
                            type="time"
                            required
                            value={this.state.do_f}
                            placeholder="00:00"
                            label="Domingo Termina"

                        />
                    </Column>
                </Row>
                <br />



                <BottomContainer>
                    <Row divisions={20}>
                        <Column style={{ textAlign: 'right' }} fluid>
                            <Button
                                loading={this.state.setLoading}
                                onClick={() => this.saveCalendar()}
                                className=""
                            >
                                Guardar
                            </Button>
                        </Column>
                        <Column
                            xs={1}
                            xsShift={1}
                            style={{ textAlign: 'right' }}
                            fluid
                        />
                    </Row>
                </BottomContainer>
            </ContentAdapter>
        );

    };


    render() {
        return (
            <div>
                <WrapperSideBar handleClose={this.onCloseSidebar}>
                    {this.state.loadingSideBar
                        ? this._renderSideBarLoader()
                        : this._renderSidebarContent()}
                </WrapperSideBar>
                <p className="title">Paqueterias</p>
                {this.renderCarriers(this.state.data)}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sidebar: state.sidebar,
    countries: state.newShipment.countries,
    user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        toggleSideBar,
        changeSidebarTitle,
    },
    dispatch,
);


export default connect(mapStateToProps, mapDispatchToProps)(Provider);
