import React from "react";
import axios from "axios";
import styled from "styled-components";

import { push } from "react-router-redux";

import { boundMethod } from "autobind-decorator";
import { Row, Column } from "hedron";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Skeleton from "react-loading-skeleton";
import { toggle as toggleSideBar } from "../../modules/CollapsibleBar/actions";

import { hideMessage, showMessage } from "../../modules/message";
import { NEW_INTEGRATION } from "../../modules/CollapsibleBar/sideBarTypes";
import book from "./icons/book.svg";
import ThreeDotsDropdown from "../Dropdown/ThreeDotsDropdown/index";

import Text, { Title } from "../Text";
import Switch from "../Switch";
import DownArrow from "./icons/down-arrow.svg";
import Dropdown from "../Dropdown";
import Table, { Th, Tr, Td } from "../Table";
import Input from "../Input";
import { BottomContainer } from "../WrapperSideBar/styledObjects";
import WrapperSideBar from "../WrapperSideBar";
import shopify from "../../containers/integrations/marketplaces/shopify.png";
import woocommerce from "../../containers/integrations/marketplaces/woocommerce.png";
import prestashop from "../../containers/integrations/marketplaces/prestashop.png";
import magento from "../../containers/integrations/marketplaces/magento.png";

import FedEx from "../../containers/integrations/carriers/fedex.png";
import Delete from "./icons/delete.svg";
import Estafeta from "../../containers/integrations/carriers/estafeta.png";
import UPS from "../../containers/integrations/carriers/ups.png";
import Button from "../Button";
import Redpack from "../../containers/integrations/carriers/redpack.png";
import Chazki from "../../containers/integrations/carriers/chazki.png";
import BlueExpress from "../../containers/integrations/carriers/blueexpress.png";
import Shippify from "../../containers/integrations/carriers/shippify.png";
import Olva from "../../containers/integrations/carriers/olva.svg";
import Guatex from "../../containers/integrations/carriers/guatex.svg";
import eSameDay from "../../containers/integrations/carriers/esameday.svg";
import Servientrega from "../../containers/integrations/carriers/servientrega.svg";
import Urbano from "../../containers/integrations/carriers/urbano.svg";
import Paquetexpress from "../../containers/integrations/carriers/paqueteexpress.svg";
import Bicimensajero from "../../containers/integrations/carriers/bicimensajero.svg";
import laterminal from "../../containers/integrations/carriers/laterminal.png";
import savarexpress from "../../containers/integrations/carriers/savarexpress.png";
import uenvios from "../../containers/integrations/carriers/uenvios.png";
import fruno from "../../containers/integrations/carriers/fruno.png";
import {
  getShops,
  deleteShop,
  getWoocommerceOauth,
  createMagento,
  getShopifyOauth,
  createPrestashop,
} from "../../modules/marketplaceAccount/shops";
import {
  getCarriers,
  updateCarrierStatus,
} from "../../modules/marketplaceAccount/carriers";
import edit_icon from "../../utils/icons/edit.svg";
import close_icon from "../WrapperSideBar/img/close.svg";
import {
  getUserIntegrations,
  createWebhook,
  deleteWebhook,
  deleteCarrier,
  storeCarrierCredentials,
  toggleCarrierStatus,
} from "../../modules/integrations";

import "./style.css";

const shopIcons = {
  shopify,
  woocommerce,
  prestashop,
  magento,
};
const carrierIcons = {
  Guatex,
  Servientrega,
  Urbano,
  Olva,
  BlueExpress,
  Shippify,
  Chazki,
  UPS,
  Redpack,
  FedEx,
  Estafeta,
  eSameDay,
  Paquetexpress,
  Bicimensajero,
  laterminal,
  savarexpress,
  uenvios,
  fruno,
};

const carrierNames = [
  "Guatex",
  "Servientrega",
  "Urbano",
  "Olva",
  "BlueExpress",
  "Shippify",
  "Chazki",
  "UPS",
  "Redpack",
  "FedEx",
  "Estafeta",
  "eSameDay",
  "Paquetexpress",
  "Bicimensajero",
  "Terminal Express",
  "Savar Express",
  "U Envios",
  "Fruno",
];

const StyledConnected = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  display: inline-block;
  background: #00ee7a;
  margin-left: 6px;
`;

const StyledDisconnected = StyledConnected.extend`
  background: #d8d8d8;
`;

class AccountSetupConfig extends React.Component {
  state: State = {
    alias: "",
    aliasError: "",
    name: "",
    nameError: "",
    email: "",
    emailError: "",
    phone: "",
    phoneError: "",
    street: "",
    streetError: "",
    street2: "",
    street2Error: "",
    reference: "",
    referenceError: "",
    zipcode: "",
    zipcodeError: "",
    location: "",
    openMarketplace: true,
    openCarriers: true,
    openAPI: true,
    dataShops: [],
    dataCarriers: [],
    loading: false,
    platforms: ["shopify", "woocommerce", "prestashop", "magento"],
    selectedPlatform: "marketplace",
    selectedPlatformItem: "",
    selected: null,
  };

  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.id) {
      this.getShopsData();
      this.getCarriersData();
    } else {
      this.props.closeElement();
    }
  }

  @boundMethod
  getShopsData() {
    this.setState({ loadingShops: true });
    getShops(this.props.id).then((response) => {
      this.setState({ loadingShops: false });
      if (response.type === "Success") {
        this.setState({ dataShops: response.response.data });
      } else {
        this.props.notify("Error al obtener la información", "error");
      }
    });
  }

  @boundMethod
  resetState() {
    this.setState({
      selected: null,
      selectedPlatform: "marketplace",
      selectedPlatformItem: "",
      url: "",
      urlError: "",
      name: "",
      nameError: "",
      webhookError: "",
      webhook: "",
      key: "",
      keyError: "",
      api_key: "",
      api_keyError: "",
      secret: "",
      secretError: "",
      consumerKey: "",
      consumerKeyError: "",
      consumerSecret: "",
      consumerSecretError: "",
      id_token: "",
      id_tokenError: "",
      codigo_usuario: "",
      codigo_usuarioError: "",
      codigo_persona: "",
      codigo_personaError: "",
      estafeta_customer_number: "",
      estafeta_customer_numberError: "",
      estafeta_login: "",
      estafeta_loginError: "",
      estafeta_password: "",
      estafeta_passwordError: "",
      estafeta_suscriber_id: "",
      estafeta_suscriber_idError: "",
      estafeta_frecuencias_userid: "",
      estafeta_frecuencias_useridError: "",
      estafeta_frecuencias_username: "",
      estafeta_frecuencias_usernameError: "",
      estafeta_frecuencias_password: "",
      estafeta_frecuencias_passwordError: "",
      estafeta_costo_reexpedicion: "",
      estafeta_costo_reexpedicionError: "",
      estafeta_tracking_suscriber_id: "",
      estafeta_tracking_suscriber_idError: "",
      estafeta_tracking_username: "",
      estafeta_tracking_usernameError: "",
      estafeta_tracking_password: "",
      estafeta_tracking_passwordError: "",
      redpack_user: "",
      redpack_userError: "",
      redpack_sufix: "",
      redpack_sufixError: "",
      ups_id: "",
      ups_idError: "",
      ups_password: "",
      ups_passwordError: "",
      ups_numero_licencia: "",
      ups_numero_licenciaError: "",
      ups_numero_cuenta: "",
      ups_numero_cuentaError: "",
      fedex_api_key: "",
      fedex_api_keyError: "",
      fedex_password: "",
      fedex_passwordError: "",
      fedex_numero_licencia: "",
      fedex_numero_licenciaError: "",
      fedex_numero_medida: "",
      fedex_numero_medidaError: "",
      olva_ruc_seller: "",
      olva_ruc_sellerError: "",
      olva_cod_cliente_ruc_dni: "",
      olva_cod_cliente_ruc_dniError: "",
      guatex_user: "",
      guatex_userError: "",
      guatex_password: "",
      guatex_passwordError: "",
      guatex_codigo_cobro: "",
      guatex_codigo_cobroError: "",
      servientrega_user: "",
      servientrega_userError: "",
      servientrega_password: "",
      servientrega_passwordError: "",
      servientrega_codigo_facturacion: "",
      servientrega_codigo_facturacionError: "",
      urbano_user: "",
      urbano_userError: "",
      urbano_password: "",
      urbano_passwordError: "",
      urbano_id_contrato: "",
      urbano_id_contratoError: "",
      secret: "",
      secretError: "",
      isCreating: false,
      carrierToView: null,
      shopToEditRate: null,
      default_address_id: null,
      default_address_idError: "",
      rateMinPrice: false,
      rateType: null,
      selectedRateCarrier: "",
      selectedRateService: "",
      selectedRateCarrierText: null,
      selectedRateServiceText: null,
      servicesObjects: [],
      rateCarrierError: "",
      rateProviderError: "",
      newGuidesState: false,
      updatesState: false,
      detailOpen: false,
      returnRatesList: 1,
    });
  }

  @boundMethod
  saveIntegration() {
    let errors = 0;
    let nameError = "";
    let urlError = "";
    let keyError = "";
    const secretError = "";
    const consumerKeyError = "";
    const consumerSecretError = "";
    let webhookError = "";

    this.setState({ isCreating: true });
    this.resetState();
    if (this.state.selectedPlatform === "shopify") {
      if (!this.state.url) {
        return this.setState({
          isCreating: false,
          urlError: "Este campo es requerido",
        });
      }
      const store_domain = this.state.url;
      const store_url = this.state.url;

      getShopifyOauth(store_url, store_domain, this.props.id).then(
        (response) => {
          this.setState({ loadingShops: false });
          if (response.type === "Success") {
            window.location.assign("https://" + response.response.data.url);
          } else {
            this.props.notify("Error al conectar con woocommerce", "error");
          }
        }
      );
      toggleSideBar(false);
    } else if (this.state.selectedPlatform === "woocommerce") {
      if (!this.state.url) {
        errors++;
        urlError = "Este campo es requerido";
      }
      if (!this.state.name) {
        errors++;
        nameError = "Este campo es requerido";
      }
      if (errors > 0) {
        return this.setState({
          isCreating: false,
          nameError,
          urlError,
        });
      }

      getWoocommerceOauth(this.state.url, this.state.name, this.props.id).then(
        (response) => {
          this.setState({ loadingShops: false });
          if (response.type === "Success") {
            window.location.assign("https://" + response.response.data.url);
          } else {
            this.props.notify("Error al conectar con woocommerce", "error");
          }
        }
      );

      toggleSideBar(false);
    } else if (this.state.selectedPlatform === "prestashop") {
      if (!this.state.url) {
        errors++;
        urlError = "Este campo es requerido";
      }
      if (!this.state.name) {
        errors++;
        nameError = "Este campo es requerido";
      }
      if (!this.state.key) {
        errors++;
        keyError = "Este campo es requerido";
      }
      if (errors > 0) {
        return this.setState({
          isCreating: false,
          nameError,
          urlError,
          keyError,
        });
      }
      createPrestashop(
        this.state.name,
        this.state.url,
        this.state.key,
        this.props.id
      ).then((response) => {
        this.setState({ loadingShops: false });

        if (response.type !== "Success") {
          this.props.notify("Error al conectar con prestashop", "error");
        }
      });

      this.resetState();
    } else if (this.state.selectedPlatform === "magento") {
      if (!this.state.url) {
        errors++;
        urlError = "Este campo es requerido";
      }
      if (!this.state.name) {
        errors++;
        nameError = "Este campo es requerido";
      }
      if (!this.state.key) {
        errors++;
        keyError = "Este campo es requerido";
      }
      if (errors > 0) {
        return this.setState({
          isCreating: false,
          nameError,
          urlError,
          keyError,
        });
      }

      createMagento(
        this.state.name,
        this.state.url,
        this.state.key,
        this.props.id
      ).then((response) => {
        this.setState({ loadingShops: false });
        // console.log(response);
        if (response.type === "Success") {
        } else {
          this.props.notify("Error al conectar con magento", "error");
        }
      });

      this.resetState();
    } else if (this.state.selected === "webhook") {
      if (!this.state.webhook) {
        errors++;
        webhookError = "Este campo es requerido";
      }
      if (errors > 0) {
        this.setState({ webhookError });
      } else {
        createWebhook(
          this.state.webhook,
          this.state.newGuidesState,
          this.state.updatesState
        ).then(() => {
          this.returnIntegrations();
          this.setState({ isCreating: false });
        });
      }
    }
  }

  cleanURLWebhook() {
    const { webhook } = this.state;
    const newWebhook = webhook.replace(
      /(http?:\/\/|https?:\/\/)?(www\.)?/,
      "https://"
    );
    this.setState({ webhook: newWebhook }, this.validateURLWebhook);
  }

  @boundMethod
  cleanURLShopify() {
    const { url } = this.state;
    let newUrl = url.replace(/(https?:\/\/)?(www\.)?/, "");
    newUrl = newUrl.replace(/\.com.*/, ".com");
    this.setState({ url: newUrl }, this.validateURLShopify);
  }

  @boundMethod
  validateURLShopify() {
    const { url } = this.state;
    const regex = /^(\w+\W*)+\.{1}myshopify.com$/;
    if (!regex.test(url)) {
      return this.setState({
        urlError:
          "La URL debe seguir el formato: mitienda.myshopify.com sin “/” al final",
      });
    }
    localStorage.removeItem("shopify_store");
    this.saveIntegration();
  }

  @boundMethod
  validateURLWebhook() {
    const { webhook } = this.state;
    const regex = new RegExp("^(http|https)://", "i");
    if (!regex.test(webhook)) {
      return this.setState({
        webhookError: "La URL debe seguir el formato: https://miurlvalida.com",
      });
    }
    this.saveIntegration();
  }

  @boundMethod
  getCarriersData() {
    this.setState({ loadingCarriers: true });
    getCarriers(this.props.id).then((response) => {
      this.setState({ loadingCarriers: false });
      if (response.type === "Success") {
        this.setState({ dataCarriers: response.response.data });
      } else {
        this.props.notify("Error al obtener la información", "error");
      }
    });
  }

  toggleAddMarketplace() {
    this.setState({ selected: "marketplace" });
  }

  handleStatusChangeCarrier(marketplaceId, id, active) {
    updateCarrierStatus(marketplaceId, id, active).then((response) => {
      if (response.type === "Success") {
        this.getCarriersData();
      } else {
        this.props.notify("Error al actualizar el status", "error");
      }
    });
  }

  handleDeleteMarketplace(marketplaceId, id) {
    deleteShop(marketplaceId, id).then((response) => {
      if (response.type === "Success") {
        this.getShopsData();
      } else {
        this.props.notify("Error al actualizar el status", "error");
      }
    });
  }

  @boundMethod
  renderPlatformsOptions() {
    const { platforms } = this.state;
    return platforms.map((element, index) => (
      <Row key={`platform-${index}`}>
        <Column fluid style={{ marginBottom: "4px" }}>
          <Text>{element}</Text>
        </Column>
      </Row>
    ));
  }

  @boundMethod
  handlePlatformChange(val) {
    this.setState({
      selectedPlatformItem: val,
      selectedPlatform: this.state.platforms[val],
    });
  }

  handleChange(value: any, key: string) {
    this.setState({ [key]: value });
  }

  @boundMethod
  showConnectService() {
    const { toggleSideBar } = this.props;

    const {
      carrierToView,
      selected,
      selectedPlatform,
      shopToEditRate,
      isCreating,
      newGuidesState,
      updatesState,
      rateMinPrice,
      selectedRateCarrier,
      rateCarrierError,
      rateProviderError,
      selectedPlatformItem,
      selectedRateService,
    } = this.state;
    let carrierKeys = [];
    let credentials: {};

    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value: this.state[name],
        error: this.state[`${name}Error`],
      }),
    };

    if (selected === "view_account") {
      credentials = JSON.parse(carrierToView.credentials);
      delete credentials.user_id;
      carrierKeys = Object.keys(credentials);
    }

    const titles = {
      shopify: "URL de tu tienda Shopify",
      woocommerce: "Conectar con Woo Commerce",
      prestashop: "Conectar con Prestashop",
      magento: "Conectar con Magento",
      webhook: "Crear un nuevo webhook",
      marketplace: `Conectar MarketPlace${
        selectedPlatform !== "marketplace" ? `(${selectedPlatform})` : ""
      }`,
      view_account: `Credenciales - ${
        carrierToView ? jsUcfirst(carrierToView.name) : ""
      }`,
      rate: `Tipo de Tarifa - ${
        shopToEditRate ? jsUcfirst(shopToEditRate.marketplace) : ""
      } - ${
        shopToEditRate
          ? shopToEditRate.marketplace === "API"
            ? shopToEditRate.name
            : jsUcfirst(shopToEditRate.name)
          : ""
      }`,
    };
    const integrationColors = {
      shopify: "#95BE46",
      woocommerce: "#A85693",
      prestashop: "#2C0A47",
      magento: "#FF5700",
      webhook: "#FFFFFF",
      view_account: "#FFFFFF",
      marketplace: "",
    };
    const articles = {
      shopify:
        "https://blogger.mienvio.mx/2020/04/12/como-conectar-tu-tienda-de-shopify-con-mienvio/",
      woocommerce:
        "https://blogger.mienvio.mx/2020/04/12/como-conectar-una-tienda-de-woocommerce-con-mienvio/",
      prestashop:
        "https://blogger.mienvio.mx/2020/04/13/como-conectar-tu-tienda-de-prestashop-con-mienvio/",
      magento:
        "https://blogger.mienvio.mx/2020/02/27/como-conectar-tu-tienda-de-magento-con-mienvio/",
      webhook: "",
      view_account: "",
      rate: "",
      marketplace: "",
    };
    const tutorials = {
      shopify: "",
      woocommerce: "",
      prestashop: "",
      magento: "",
      webhook: "",

      view_account: "",
      rate: "",
      marketplace: "",
    };
    const fields = {
      shopify: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("url")}
            placeholder="MI_TIENDA.myshopify.com"
            required
            label="URL de tu tienda Shopify"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.cleanURLShopify}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      woocommerce: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("url")}
            placeholder="http://miTienda.com"
            required
            label="URL de la tienda"
          />
          <Input
            type="text"
            {...mainProps.generate("name")}
            placeholder="El nombre de mi tienda"
            required
            label="Nombre de la tienda"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveIntegration}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      prestashop: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("name")}
            placeholder="El nombre de mi tienda"
            required
            label="Nombre de la tienda"
          />
          <Input
            type="text"
            {...mainProps.generate("url")}
            placeholder="http://miTienda.com"
            required
            label="URL de la tienda"
          />
          <Input
            type="text"
            {...mainProps.generate("key")}
            placeholder="AJDJIA2931203JVASDIADFA"
            required
            label="API key"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveIntegration}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      magento: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("name")}
            placeholder="El nombre de mi tienda"
            required
            label="Nombre de la tienda"
          />
          <Input
            type="text"
            {...mainProps.generate("url")}
            placeholder="http://miTienda.com"
            required
            label="URL de la tienda"
          />
          <Input
            type="text"
            {...mainProps.generate("key")}
            placeholder="09imadiiamsi1202"
            required
            label="API key"
          />
          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.saveIntegration}
              className="create-btn"
              loading={isCreating}
            >
              Conectar
            </Button>
          </BottomContainer>
        </div>
      ),
      webhook: (
        <div>
          <Input
            type="text"
            {...mainProps.generate("webhook")}
            placeholder="https://ejemplowebhook.com"
            required
            label="URL"
          />
          <div className="switch-container">
            <Text>Nuevas Guias</Text>
            <div />
            <Switch
              checked={newGuidesState}
              disabled={false}
              handleChange={this.handleSwitchWebhookNewGuides}
            />
          </div>
          <div className="switch-container">
            <Text>Actualizaciones</Text>
            <div />
            <Switch
              checked={updatesState}
              disabled={false}
              handleChange={this.handleSwitchWebhookUpdates}
            />
          </div>

          <BottomContainer style={{ justifyContent: "flex-end" }}>
            <Button
              onClick={this.cleanURLWebhook}
              className="create-btn"
              loading={isCreating}
            >
              Crear
            </Button>
          </BottomContainer>
        </div>
      ),
    };

    toggleSideBar(true, titles[selected], NEW_INTEGRATION);
    return (
      <div className="integrations-container">
        <div
          className="marketplace-color"
          style={{ backgroundColor: integrationColors[selectedPlatform] }}
        />
        {selected === "marketplace" && (
          <Column fluid>
            <Dropdown
              label="Plataforma"
              handleChange={this.handlePlatformChange}
              placeholder={
                <Text className="dropdown-placeholder">Plataforma</Text>
              }
              selected={selectedPlatformItem}
              selectedTitle=""
              options={this.renderPlatformsOptions()}
              error={rateCarrierError}
            />
          </Column>
        )}

        {(tutorials[selectedPlatform] !== "" ||
          articles[selectedPlatform] !== "") && (
          <Column fluid>
            <Text type="microHeader">
              ¿Necesitas ayuda para conectar tu tienda?
            </Text>
          </Column>
        )}

        {articles[selectedPlatform] !== "" && (
          <Column fluid>
            <a
              href={articles[selectedPlatform]}
              target="blank"
              className="help-link"
            >
              <Button type="link">
                <img src={book} /> Artículo de ayuda
              </Button>
            </a>
          </Column>
        )}

        {tutorials[selectedPlatform] !== "" && (
          <Column fluid>
            <a
              href={tutorials[selectedPlatform]}
              target="blank"
              className="help-link"
            >
              <Button type="link">
                <img src={play} /> Videotutorial
              </Button>
            </a>
          </Column>
        )}
        <Column fluid className="fields-container">
          {fields[selectedPlatform]}
        </Column>
        <Column fluid className="fields-container">
          {fields[selected]}
        </Column>
      </div>
    );
  }

  renderCarriers(dataCarriers) {
    const data = carrierNames.map((carrierName, index) => {
      let flag = false;
      let active = false;
      let id = null;
      dataCarriers.forEach((element) => {
        if (element.name === carrierName) {
          flag = true;
          active = element.active;
          id = element.id;
        }
      });
      return (
        <Tr>
          <Td style={{ cursor: this.props.carrier ? "pointer" : "default" }}>
            <img
              src={
                carrierName === "Terminal Express"
                  ? carrierIcons["laterminal"]
                  : carrierIcons[carrierName]
              }
              alt={this.props.carrierName}
              style={{ maxHeight: "24px" }}
            />
          </Td>
          <Td style={{ cursor: this.props.carrier ? "pointer" : "default" }}>
            {carrierName}
          </Td>
          <Td style={{ cursor: this.props.carrier ? "pointer" : "default" }}>
            {flag ? (
              <div>
                <StyledConnected /> Conectado
              </div>
            ) : (
              <div>
                <StyledDisconnected /> Sin conexión
              </div>
            )}
          </Td>
          <Td style={{ paddingTop: ".5rem" }}>
            {this.state.isLoading ? (
              <Loader
                className="loader-filters"
                type="Oval"
                color="#2576DA"
                height="16"
                width="16"
              />
            ) : (
              <Switch
                checked={active}
                disabled={!flag}
                handleChange={() =>
                  this.handleStatusChangeCarrier(this.props.id, id, !active)
                }
              />
            )}
          </Td>
        </Tr>
      );
    });
    return data;
  }

  render() {
    const options = {
      marketplace: [
        {
          function: () => this.toggleAddMarketplace(),
          label: "Añadir Marketplace",
          image: edit_icon,
        },
      ],
    };
    const { selected, currentTab } = this.state;
    return (
      <div>
        <WrapperSideBar handleClose={this.resetState} isIntegration>
          {selected !== null ? this.showConnectService() : null}
        </WrapperSideBar>
        {/* <EmptyState /> */}
        <div className="container-accounts">
          <div className="header-container">
            <img
              onClick={() => this.props.closeElement()}
              src={close_icon}
              alt=""
              className="close-icon"
            />
          </div>

          <div className="section-header">
            <p className="subtitle-section">Marketplaces</p>
            <ThreeDotsDropdown linksToRender={options.marketplace} />
            <img
              onClick={() =>
                this.setState({ openMarketplace: !this.state.openMarketplace })
              }
              src={DownArrow}
              alt=""
            />
          </div>
          {this.state.loadingShops ? (
            <Skeleton />
          ) : (
            <div
              className={`table-container ${
                this.state.openMarketplace && "open"
              }`}
            >
              {this.state.openMarketplace && (
                <Table size="full">
                  <thead>
                    <Tr>
                      <Th>Plataforma</Th>
                      <Th>Cuenta</Th>
                      <Th>Estatus</Th>
                      <Th>Tarifa</Th>
                      <Th>Desconectar</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {this.state.dataShops.map((element) => (
                      <Tr>
                        <Td>
                          <img
                            src={shopIcons[element.marketplace]}
                            alt="shopName"
                            width="70"
                          />
                        </Td>
                        <Td>{element.name}</Td>
                        <Td>
                          {element ? (
                            <div>
                              <StyledConnected /> Conectado
                            </div>
                          ) : (
                            <div>
                              <StyledDisconnected /> Sin conexión
                            </div>
                          )}
                        </Td>
                        <Td>
                          <div>
                            {element.default_service_level || "Costo más bajo"}
                          </div>
                        </Td>
                        <Td>
                          <div className="container-delete">
                            <img
                              alt=""
                              src={Delete}
                              onClick={() =>
                                this.handleDeleteMarketplace(
                                  this.props.id,
                                  element.object_id
                                )
                              }
                            />
                          </div>
                        </Td>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          )}
          <div className="card-container-divisor" />
          <div className="section-header">
            <p className="subtitle-section">Paqueterías</p>
            <img
              onClick={() =>
                this.setState({ openCarriers: !this.state.openCarriers })
              }
              src={DownArrow}
              alt=""
            />
          </div>
          {this.state.loadingCarriers ? (
            <Skeleton />
          ) : (
            <div
              className={`table-container ${this.state.openCarriers && "open"}`}
            >
              {this.state.openCarriers && (
                <Table size="full">
                  <thead>
                    <Tr>
                      <Th>Carrier</Th>
                      <Th>Cuenta</Th>
                      <Th>Estatus</Th>
                      <Th>Activo</Th>
                    </Tr>
                  </thead>
                  <tbody>{this.renderCarriers(this.state.dataCarriers)}</tbody>
                </Table>
              )}
            </div>
          )}

          <div className="card-container-divisor" />
          <div className="section-header">
            <p className="subtitle-section">API</p>
            <img
              onClick={() => this.setState({ openAPI: !this.state.openAPI })}
              src={DownArrow}
              alt=""
            />
          </div>
          <div className={`table-container  ${this.state.openAPI && "open"}`}>
            {this.state.openAPI && (
              <Table size="full">
                <thead>
                  <Tr>
                    <Th>API key</Th>
                    <Th>Tarifa</Th>
                  </Tr>
                </thead>
                <tbody>
                  <Tr>
                    <Td>
                      <Text style={{ fontSize: "14px" }}>
                        asdfghjk754zxchjk8765jd
                      </Text>
                    </Td>
                    <Td>
                      <Text style={{ fontSize: "14px" }}>Costo mas bajo</Text>
                    </Td>
                  </Tr>
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ integrations, message, user, addresses }) => ({
  message,
  user,
  addresses,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePage: () => push("/about-us"),
      hideMessage,
      showMessage,
      toggleSideBar,
      returnIntegrations: () => push("/integraciones"),
      deleteShop,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetupConfig);
