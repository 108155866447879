import React, { Children } from 'react';
import PropTypes from 'prop-types';

import styledProps from 'styled-props';
import styled from 'styled-components';
import colors from '../../utils/colors';

const background = {
  default: colors.secondary.white,
  selected: colors.state.blue,
  disabled: colors.state.silver
};
const border = {
  default: colors.gray.earth_stone,
  selected: colors.state.blue,
  disabled: colors.state.silver
};
const borderFocus = {
  default: `2px solid ${colors.state.blue}`,
  selected: `2px solid ${colors.state.blue}`,
  disabled: `1px solid ${colors.state.silver}`
};
const lHFocus = {
  default: '16px',
  selected: '16px',
  disabled: '18px'
};
const StyledRadio = styled.div`
  width: 18px;
  height: 18px;
  max-width: 18px;
  border-radius: 100px;
  cursor: pointer;
  max-height: 18px;
  margin: 0 12px;
  margin-bottom: -3.5px;
  background: ${styledProps(background, 'background')};
  border: 1px solid ${styledProps(border, 'border')};
  display: inline-block;
  box-sizing: border-box;
  color: ${colors.secondary.white};
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  &:focus,
  &:active {
    border: ${styledProps(borderFocus, 'border')};
    line-height: ${styledProps(lHFocus, 'border')};
  }
`;
StyledRadio.defaultProps = {
  border: 'default',
  background: 'default',
  borderFocus: 'default',
  lHFocus: 'default'
};

const StyledLabel = styled.label`
  color: ${colors.gray.charcoal};
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 2.19%;
  padding: 0.25rem 0;
  display: inline-block;
  cursor: pointer;
`;

const SecondaryLabel = styled.label`
  color: #848484;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  padding: 0.25rem 0;
  display: inline-block;
  cursor: pointer;
  margin-left: 40px;
`;

const RadioContainer = styled.div`
  cursor: pointer;
  margin: 12px 0px;
`;

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected
    };
    this.handleChange = this.handleChange.bind(this);
  }

  getChildContext() {
    return {
      handleChange: this.handleChange,
      selected:
        typeof this.props.selected !== 'undefined' ? this.props.selected : this.state.selected
    };
  }

  handleChange(value) {
    this.props.onChange(value);
    if (!this.props.selected) {
      this.setState({ selected: value });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selected) {
      this.setState({ selected: nextProps.selected });
    }
  }

  render() {
    return <div style={this.props.style}>{Children.toArray(this.props.children)}</div>;
  }
}

RadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  children: (props, propName, componentName) => {
    if (props.children.length) {
      let error = false;
      props.children.forEach(child => {
        if (child.type !== Radio) {
          error = true;
          return;
        }
      });
      if (error) {
        return new Error('RadioGroup only accepts Radio as its children');
      }
    } else {
      if (props.children.type !== Radio) {
        return new Error('RadioGroup only accepts Radio as its children');
      }
    }
  }
};

RadioGroup.childContextTypes = {
  handleChange: PropTypes.func,
  selected: PropTypes.any
};

const Radio = (props, context) => (
  <RadioContainer
    className={props.containerClass}
    style={props.style}
    onClick={() => (!props.disabled ? context.handleChange(props.value) : null)}
  >
    {(context.selected === null && props.defaultChecked) || context.selected === props.value ? (
      <StyledRadio
        className={props.className}
        background={props.disabled ? 'disabled' : 'selected'}
        border={props.disabled ? 'disabled' : 'selected'}
      >
        <i className="ion-checkmark" />
      </StyledRadio>
    ) : props.disabled ? (
      <StyledRadio className={props.className} background="disabled" border="disabled" />
    ) : (
      <StyledRadio className={props.className} />
    )}
    {props.text && <StyledLabel>{props.text}</StyledLabel>}
    {props.secondaryText && <SecondaryLabel>{props.secondaryText}</SecondaryLabel>}
  </RadioContainer>
);

Radio.contextTypes = {
  handleChange: PropTypes.func,
  selected: PropTypes.any
};

export { Radio, RadioGroup };
