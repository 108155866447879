import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { boundMethod } from 'autobind-decorator';
import { Row, Column } from 'hedron';
import Text, { Title, P } from '../../Text';
import Maxibutton from '../../Maxibutton/index';
import { existInJsonArray } from '../../../utils/global_functions';

class Selector extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
  }

  render() {
    const {
      customer_type, plan, actions, role, operational_user
    } = this.props.user;

    const permissions = [];
    if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
      operational_user.operational_role.actions.map(element => permissions.push(element));
    }
    if (plan && plan.features) {
     
      plan.features.map(element => permissions.push(element));
    }
    if (actions) {
      actions.map(element => permissions.push(element));
    }
    return (
      <div>
        {existInJsonArray('envios', 'leer', permissions)
        && (
          <div>
            <Text type="label">Envios</Text>
            <br />
            <Maxibutton
              onClick={() => this.props.onClick('shipments')}
              icon="shipments"
              text="Visualiza y gestiona las listas de envios, informacion detallada y tracking."
            />
          </div>
        )
        }
        {existInJsonArray('cotizaciones', 'leer', permissions)
        && (
          <div>
            <Text type="label">Cotizaciones</Text>
            <br />
            <Maxibutton
              onClick={() => this.props.onClick('quotations')}
              icon="quotations"
              text="Visualiza, gestiona y procesa las listas de cotizaciones."
            />
          </div>
        )}
        {existInJsonArray('empaques', 'leer', permissions)
        && (
          <div>
            <Text type="label">Empaques</Text>
            <br />
            <Maxibutton
              onClick={() => this.props.onClick('packages')}
              icon="packages"
              text="Visualiza y gestiona las listas de empaques."
            />
          </div>
        )}
        {existInJsonArray('direcciones', 'leer', permissions)
        && (
          <div>
            <Text type="label">Direcciones</Text>
            <br />
            <Maxibutton
              onClick={() => this.props.onClick('addresses')}
              icon="addresses"
              text="Visualiza y gestiona las listas de direcciones."
            />
          </div>
        )}
        {existInJsonArray('tarjetas', 'leer', permissions)
        && (
          <div>
            <Text type="label">Metodos de Pago</Text>
            <br />
            <Maxibutton
              onClick={() => this.props.onClick('payment')}
              icon="payment"
              text="Visualiza y gestiona tus listas de tarjetas."
            />
          </div>
        )}
        {existInJsonArray('facturas', 'leer', permissions)
        && (
          <div>
            <Text type="label">Facturas</Text>
            <br />
            <Maxibutton
              onClick={() => this.props.onClick('invoices')}
              icon="invoices"
              text="Visualiza, gestiona y crea facturas de tus compras."
            />
          </div>
        )}
        <div>
          <Text type="label">Configuración</Text>
          <br />
          <Maxibutton
            onClick={() => this.props.onClick('config')}
            icon="config"
            text="Visualiza y gestiona la configuraación de Marketplaces, Paqueterias y API"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
  },
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Selector);
