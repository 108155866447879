import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import { Row, Column } from "hedron";
import isEmail from "validator/lib/isEmail";
import Less from "../../CreateLabel/common/lessAddresses";
import More from "../../CreateLabel/common/moreAddresses";
import { toggle as toggleSideBar } from "../../../modules/CollapsibleBar/actions";
import {
  getUserAllAddresses,
  selectAddress,
} from "../../../modules/sidebarOrder/actions";
import { savePrimaryAddress } from "../../../modules/addresses/actions";
import Text from "../../Text";
import { EmptyView } from "../../CreateLabel/common/loadingViews";
import Button from "../../Button";
import {
  BottomContainer,
  ContentAdapter,
} from "../../WrapperSideBar/styledObjects";
import Address from "../../CreateLabel/common/address";
import { formatPhone, clearPhone } from "../../../utils/global_functions";
import { validateAddress, phoneValidation } from "../../../utils/validate";

const noZipcode = "No existe el código";
const DESTINATION = "DESTINATION";
const ORIGIN = "ORIGIN";
const EDIT_ORIGIN = "EDIT_ORIGIN";
const EDIT_DESTINATION = "EDIT_DESTINATION";

class AutomationAddresses extends React.Component {
  state = {
    defaultAddress: {
      alias: "",
      name: "",
      nameError: "",
      email: "",
      emailError: "",
      phone: "",
      phoneError: "",
      country: "",
      countryError: "",
      neighborhood: "",
      neighborhoodError: "",
      zipcode: "",
      zipcodeError: "",
      city: "",
      cityError: "",
      street: "",
      streetError: "",
      reference: "",
      referenceError: "",
    },
    address: {
      alias: "",
      name: "",
      nameError: "",
      email: "",
      emailError: "",
      phone: "",
      phoneError: "",
      country: "México",
      countryError: "",
      street2: "",
      street2Error: "",
      zipcode: "",
      zipcodeError: "",
      city: "",
      cityError: "",
      street: "",
      streetError: "",
      reference: "",
      referenceError: "",
      saveAddress: false,
      is_open: false,
      title: "",
      origin_destiny:
        this.props.type === "EDIT_ORIGIN" || this.props.type === "ORIGIN"
          ? false
          : true,
    },
    manualAddress: false,
    filterAddress: "",
    selAddress: null,
    loading: true,
    addresses: {
      total_count: 0,
      results: [],
    },
  };

  componentWillMount() {
    this.props.getUserAllAddresses().then(() => {
      const tempAddresses = [];
      if (this.props.type === "EDIT_ORIGIN" || this.props.type === "ORIGIN") {
        this.props.addresses.results.forEach((element) => {
          if (element.country === "Mexico" && element.origin_destiny == false) {
            tempAddresses.push(element);
          }
        });
        this.setState({
          addresses: {
            total_count: tempAddresses.length,
            results: tempAddresses,
          },
        });
      } else {
        this.props.addresses.results.forEach((element) => {
          if (element.origin_destiny == true) {
            tempAddresses.push(element);
          }
        });
        this.setState({
          addresses: {
            total_count: tempAddresses.length,
            results: tempAddresses,
          },
        });
      }
      if (this.props.addresses.total_count === 0) {
        this.setState({
          manualAddress: true,
        });
      }
      this.setState({ loading: false });
      const { address } = this.props;

      if (address.object_id) {
        address.phone = formatPhone(address.phone);
        this.setState({ address, selAddress: address });
      }
    });

    //console.log(this.state.address.origin_destiny);
  }

  _getFilterAddressesFrom = () =>
    this.state.filterAddress.length > 0
      ? this.state.addresses.results.filter((address) =>
          address.alias
            .toLowerCase()
            .includes(this.state.filterAddress.toLowerCase())
        )
      : this.state.addresses.results;

  _generateOptions = () =>
    this.state.addresses.results != undefined
      ? Object.values(this._getFilterAddressesFrom()).map((address) => (
          <Row
            key={address.object_id}
            className={`card-dropdown-option ${this._renderSelectedClass(
              address.object_id
            )}`}
          >
            <i className="ion-checkmark-round" />
            <Column fluid>
              <Text className="title-selected">
                <b>{address.alias}</b>
              </Text>
            </Column>
            <Column fluid>
              <Text>{address.name}</Text>
            </Column>
            <Column fluid>
              <Text>
                {address.email}. {formatPhone(address.phone)}.
              </Text>
            </Column>
            <Column fluid>
              <Text>{address.street}</Text>
            </Column>
            {address.reference && (
              <Column fluid>
                <Text>{address.reference}</Text>
              </Column>
            )}
            <Column fluid>
              <Text>{address.street2}</Text>
            </Column>
            <Column fluid>
              <Text>C.P. {address.zipcode}</Text>
            </Column>
          </Row>
        ))
      : [];

  _renderSelectedClass = (id) =>
    this.state.selAddress != null
      ? id == this.state.selAddress.object_id
        ? "more-address-selected"
        : ""
      : "";

  cleanPhone = (phone) => clearPhone(phone).split(" ").join("");

  validate = (address) => {
    const validation = validateAddress(address);
    this.setState({ address: validation[1] });

    return validation[0];
  };

  // overwritten method, this method is used when you only have the zipcode,
  // it detects most but not all cases
  zipcodeValidation = (zipcode) => !(isNaN(zipcode) || zipcode.length !== 5);

  setDefaultPrimary = () => {
    const address = this.state.manualAddress
      ? this.state.address
      : this.state.selAddress;
    if (this.validate(address)) {
      const defaultOrigin = this.setDefault("PRIMARY", address);
      this.setState({ loading: true, defaultOrigin });
    }
  };

  setDefaultTo = () => {
    const address = this.state.manualAddress
      ? this.state.address
      : this.state.selAddress;
    if (this.validate(address)) {
      const defaultDestination = this.setDefault("DEFAULT_TO", address);
      this.setState({ loading: true, defaultDestination });
    }
  };

  setDefault = (type, address) => {
    address.object_type = type;
    address.phone = this.cleanPhone(address.phone);
    this.setState({ selAddress: null });
    this.setAsDefaultAddress(address);
    return address;
  };

  setAsDefaultAddress(address) {
    this.props
      .savePrimaryAddress(address, false)
      .then((result) => {
        this.props.toggleSideBar(false, "", "", this.props.update);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  insertPhoneFormat(phone, name) {
    phone = this.cleanPhone(phone);
    const r = formatPhone(phone);
    this.setValue(name, r);
  }

  _manualAddress = () => {
    this.setState({ manualAddress: !this.state.manualAddress });
  };

  _onChangeAddressesMore = (id) => {
    this.setState({ selAddress: this._getFilterAddressesFrom()[id] });
  };

  _onChangeAddressesLess = (e) => {
    this.props.selectAddress(
      e.target.name,
      this.state.addresses.results[e.target.name]
    );
    this.setState({ selAddress: this.state.addresses.results[e.target.name] });
  };

  _renderAddress = () => {
    if (this.state.addresses.total_count == 0 || this.state.manualAddress) {
      return (
        <Address
          model={this._generateAddressObject()}
          hasAddress={this.state.addresses.total_count > 0}
          cancelManualfn={this._manualAddress}
        />
      );
    }
    return this.state.addresses.total_count < 4 ? (
      <Less
        addresses={this.state.addresses.results}
        onChangefn={this._onChangeAddressesLess}
        newAddressFn={this._manualAddress}
        isChecked={(id) =>
          this.state.selAddress ? id == this.state.selAddress.object_id : false
        }
      />
    ) : (
      <More
        addresses={this._generateOptions()}
        selectedAddressFrom={this.state.selAddress}
        filterFrom={this.state.filterAddress}
        handleAddressChange={this._onChangeAddressesMore}
        handleFilterChange={(value) => {
          this.setState({ filterAddress: value });
        }}
        newAddressFn={this._manualAddress}
        address={this.state.selAddress}
        typeAddress={
          this.props.type === "EDIT_ORIGIN" || this.props.type === "ORIGIN"
            ? "Origen"
            : "Destino"
        }
      />
    );
  };

  handleBlur = (value, name, required) => {
    if (required && !value) {
      this.setError(name, "Este campo es requerido");
    } else if (name === "email" && !isEmail(value)) {
      this.setError(name, "Correo electrónico inválido");
    } else if (name === "phone" && !phoneValidation()) {
      this.setError(name, "Número de teléfono inválido");
    } else if (name === "zipcode" && !this.zipcodeValidation(value)) {
      this.setError(name, "C.P. inválido");
    } else {
      this.setError(name);
    }
  };

  setError = (key, val = "") => {
    this.setState({
      address: {
        ...this.state.address,
        [`${key}Error`]: val,
      },
    });
  };

  generateProps = (name, params) => ({
    onChange: (value) => {
      const val =
        params.type === "radio" || params.type === "checkbox"
          ? value.target.value
          : value;
      this.handleChange(val, name);
    },
    onBlur: (value) => {
      const val =
        params.type === "radio" || params.type === "checkbox"
          ? value.target.value
          : value;
      this.handleBlur(val, name, params.required);
    },
    name: name || "",
    value: this.state.address[name],
    error: this.state.address[`${name}Error`],
    type: params.type ? params.type : "text",
    required: params.required ? params.required : false,
    placeholder: params.placeholder ? params.placeholder : "",
    label: params.label ? params.label : "",
    help: params.help ? params.help : "",
    maxLength: params.maxLength ? params.maxLength : null,
    disabled: params.disabled ? params.disabled : null,
    cornerNote: params.cornerNote ? params.cornerNote : null,
    icon: params.icon ? params.icon : null,
    prefix: params.prefix ? params.prefix : null,
    className: name ? `cl-${name}` : "",
    parentClassName: params.parentClassName ? params.parentClassName : null,
  });

  handleChange = (val, key) => {
    if (key === "zipcode" && !isNaN(val) && val.length === 5) {
      axios
        .get(`/api/zipcodes/${val}`)
        .then(async (response) => {
          await this.setValue(
            "city",
            `${
              response.data.municipality
                ? response.data.municipality
                : response.data.neighborhood
            },
              ${response.data.state.name}`
          );
        })
        .catch(async () => {
          await this.setValue("city", noZipcode);
        });
    }
    if (key === "phone") {
      formatPhone(val, key);
      return;
    }
    this.setValue(key, val);
  };

  setValue = (key, val = "") =>
    this.setState({
      address: {
        ...this.state.address,
        [key]: val,
      },
    });

  _generateAddressObject = () => ({
    alias: {
      ...this.generateProps("alias", {
        required: true,
        placeholder: "Bodega Huimilpan",
        label: "Alias",
        maxLength: 255,
      }),
    },

    name: {
      ...this.generateProps("name", {
        required: true,
        placeholder: "Pedro Gómez",
        label: "Nombre de quien envía",
        maxLength: 80,
      }),
    },
    email: {
      ...this.generateProps("email", {
        required: true,
        placeholder: "pedro@tucorreo.com",
        label: "Email de quien envía",
        maxLength: 255,
      }),
    },
    phone: {
      ...this.generateProps("phone", {
        required: true,
        placeholder: "(442) 123 12 99",
        label: "Teléfono de contacto",
      }),
    },
    country: {
      ...this.generateProps("country", {
        required: true,
        disabled: true,
        placeholder: "México",
        label: "País",
        maxLength: 100,
        type: "from",
      }),
    },
    neighborhood: {
      ...this.generateProps("street2", {
        required: true,
        placeholder: "Peñitas",
        label: "Colonia",
        maxLength: 35,
        disabled: this.props.type != "EDIT_ORIGIN" ? false : true,
      }),
    },
    zipcode: {
      ...this.generateProps("zipcode", {
        required: true,
        placeholder: "76158",
        label: "C.P.",
        maxLength: 5,
        disabled: this.props.type != "EDIT_ORIGIN" ? false : true,
      }),
    },
    city: {
      ...this.generateProps("city", {
        required: true,
        disabled: true,
        placeholder: "Querétaro, Qro.",
        label: "Ciudad y Estado",
        maxLength: 100,
      }),
    },
    street: {
      ...this.generateProps("street", {
        required: true,
        maxLength: 35,
        disabled: this.props.type != "EDIT_ORIGIN" ? false : true,
        placeholder: "Av. Real de Carretas #34",
        label: "Calle y número",
        help: [
          "¿Necesitas ayuda abreviando? Presiona ",
          <a
            key={0}
            href="https://blogger.mienvio.mx/2021/04/necesitas-ayuda-abreviando/"
            target="_blank"
            tabIndex="-1"
          >
            aqui
          </a>,
        ],
      }),
    },
    reference: {
      ...this.generateProps("reference", {
        maxLength: 32,
        placeholder: "Frente al mini super",
        label: "Referencias de la dirección de origen",
        help: "No ingreses datos de la dirección",
        disabled: this.props.type != "EDIT_ORIGIN" ? false : true,
      }),
    },
  });

  render() {
    return (
      <ContentAdapter>
        <div className="address-cont">
          <div>
            {this.props.loading || this.state.loading ? (
              <EmptyView />
            ) : (
              this._renderAddress()
            )}
          </div>
          <BottomContainer>
            <Row divisions={20} className="nl_bottom-btns">
              <Column xs={9} xsShift={1} style={{ textAlign: "left" }} fluid />
              <Column xs={8} xsShift={1} style={{ textAlign: "right" }} fluid>
                <div>
                  <Button
                    onClick={() => {
                      this.props.type === ORIGIN ||
                      this.props.type == EDIT_ORIGIN
                        ? this.setDefaultPrimary()
                        : this.setDefaultTo();
                    }}
                  >
                    Guardar
                  </Button>
                </div>
              </Column>
              <Column xs={1} xsShift={1} style={{ textAlign: "right" }} fluid />
            </Row>
          </BottomContainer>
        </div>
      </ContentAdapter>
    );
  }
}

const mapStateToProps = (state) => ({
  addresses: state.newShipment.addresses,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserAllAddresses,
      selectAddress,
      savePrimaryAddress,
      toggleSideBar,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutomationAddresses);
