import React from 'react';
import { Row, Column } from 'hedron';

import Text, { Title, P } from '../../../components/Text';
import Button from '../../../components/Button';
import EmpyState from '../../../components/EmptyState';
import { searchPermission } from '../../../utils/global_functions';

export default class emptyState extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
  }

  render() {
    const {
      customer_type, plan, actions, role, operational_user
    } = this.props.userState;
    const permissions = [];
    if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
      operational_user.operational_role.actions.map(element => permissions.push(element));
    }

    if (plan && plan.features) {
      plan.features.map(element => permissions.push(element));
    }
    if (actions) {
      actions.map(element => permissions.push(element));
    }
    return (
      <div>
        <EmpyState name="roles">
          <Title className="state-title">Aun no creas ningun Rol</Title>
          <Text>
            Crea roles para gestionar permisos y crear subcuentas de operacón y visualización.
          </Text>
          {searchPermission('roles', 'editar', permissions) && (
          <Button className="state-btn" onClick={this.props.onClick}>
              Añadir Rol
          </Button>
           )}
        </EmpyState>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  sidebar: state.sidebar,
  user: state.user,
});