import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as Sentry from '@sentry/browser';
import { Row, Column } from 'hedron';
import mienvioPink from '../../utils/icons/mienvioPink.svg';
import error from '../../utils/icons/error.svg';
import Button from '../Button';
import './index.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  goHome() {
    this.props.goToHome();
    window.location.reload();
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Row className="error-container">
          <Column sm={5} smShift={7} style={{ textAlign: 'right' }} fluid>
            <img className="logo" src={mienvioPink} />
          </Column>
          <Column sm={12}>
            <Row>
              <Column sm={10} md={5} mdShift={1} lg={3} lgShift={2}>
                <div className="container-info">
                  <img src={error} />
                </div>
              </Column>
              <Column sm={12} md={6} lg={5}>
                <div className="container-info">
                  <p>
                Lo sentimos, la pagina a la que tratas de acceder esta rota
                    <br />
                o en mantenimiento. Actualmente estamos tratando de
                    <br />
                solucionar este problema
                  </p>
                  <div>
                    <Button
                      type="primary"
                      className="error-btn"
                      onClick={() => this.goHome()}
                      initialLoader
                    >
                      Volver al Inicio
                    </Button>
                    <Button
                      type="secondary"
                      className="error-btn margin"
                      onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
                      initialLoader
                    >
                      Reportar Error
                    </Button>
                  </div>
                </div>

              </Column>
            </Row>


          </Column>

        </Row>

      );
    }

    return children;
  }
}
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    goToHome: () => push(''),
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorBoundary);
