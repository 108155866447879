import React from 'react';
import { RadioGroup, Radio } from '../Radio';
import Dropdown from '../Dropdown';
import Input, { Label, Required } from '../Input';
import Text from '../Text';
import { Row, Column } from 'hedron';

const Address = ({ type, id, name, phone, email, address, country, transaction, wrapperClass }) => (
  <div className={`form-address-invoice ${wrapperClass}`}>
    <Row>
      <Column xs={4} className="min-padding-bottom no-padding-top">
        <Input {...name} />
      </Column>
      <Column xs={4} className="min-padding-bottom no-padding-top">
        <Input {...phone} />
      </Column>
      <Column xs={4} className="no-padding-top no-padding-bottom">
        <Input {...email} />
      </Column>
    </Row>
    <Row>
      
      <Column xs={5} className="no-padding-top no-padding-bottom">
        <Input {...address} />
      </Column>
      <Column xs={3} className="no-padding-top no-padding-bottom">
        <Input {...country} />
      </Column>
    </Row>
    {type == 'from' && (
      <Row>
        {transaction && (
          <Column className="min-padding-top">
            <Label style={{ marginTop: '16px', marginBottom: '15px' }}>
              Parties to transaction
              <Required status={transaction.value == null ? 'error' : 'default'} />
            </Label>
            <RadioGroup
              className="no-margin-checkbox"
              selected={transaction.value}
              onChange={transaction.onChange}
            >
              <Radio
                value={0}
                text="Related (Vinculado)"
                containerClass="custom-size"
                className="no-margin-left"
              />
              <Radio value={1} text="Non-related (No vinculado)" containerClass="custom-size" />
            </RadioGroup>
          </Column>
        )}
      </Row>
    )}
  </div>
);

export default Address;
