import React from "react";
import { Label } from "../Input";
import Text from "../Text";

const ConfirmFile = ({ resume = {}, changeIcon = () => {} }) => (
  <div className="confirm-file">
    {changeIcon()}

    {resume?.incomplete ? (
      <div>
        <Label className="title-confirm">
          Algo salió mal durante el proceso. Intenta más tarde <br /> <br /> Si
          el error persiste, contacta a soporte.
        </Label>
        <br />
        <div className="error-list">
          {resume.errors.map((file, index) => (
            <>
              <Text
                key={index}
              >{`Fila ${file.order_id}:  ${file.message}.`}</Text>
              <br />
            </>
          ))}
        </div>
        {/* <Text>
          Algo salió mal durante el proceso. Intenta más tarde <br /> <br /> Si
          el error persiste, contacta a soporte.
        </Text> */}
      </div>
    ) : (
      <>
        <Label className="title-confirm">
          ¡Terminamos de procesar tu archivo!
        </Label>
        <Text className="title">Estos son los resultados:</Text>
        <div className="resume">
          <Text>Número de filas procesadas → {resume.count_errors}</Text>
          <Text>Órdenes creadas exitosamente → {resume.count_success}</Text>
        </div>
        {resume.errors?.length > 0 && (
          <>
            <Text className="title">
              Estos son los errores que se produjeron:
            </Text>
            <div className="error-list">
              {resume.errors.map((file, index) => (
                <Text key={index}>{`Fila ${file.order_id}: ${errorTypes(
                  file.message
                )}.`}</Text>
              ))}
            </div>
          </>
        )}
      </>
    )}
  </div>
);

export default ConfirmFile;

const errorTypes = (er) => {
  switch (er) {
    case "from zip code is invalid":
      return "Código postal de origen inválido";
    case "to zip code is invalid":
      return "Código postal de destino inválido";
    case "can not create address to":
      return "No se puede crear la dirección de destino";
    case "can not create address from":
      return "No se puede crear la dirección de origen";
    case "can not create shipment":
      return "No se puede crear el envío";
    case "this shipment have not valid rate":
      return "Tarifa inválida";
    case "unknown error":
      return "Error desconocido";
    default:
      return "Error desconocido";
  }
};
