import React from "react";
import styledProps from "styled-props";
import styled from "styled-components";
import colors from "../../utils/colors";

const labels = {
  default: "manual",
  massive: "Carga Masiva",
  shopify: "Shopify",
  magento: "Magento",
  woocommerce: "Woocommerce",
  prestashop: "PrestaShop",
  epages: "Epages",
  tiendanube: "Tiendanube",
};

const StyledBadge = styled.div`
  font-size: 11px;
  color: ${colors.gray.charcoal};
  text-align: center;
  border-radius: 100px;
  background: ${colors.gray.sky_gray};
  height: 24px;
  padding: 5px 8px;
  box-sizing: border-box;
  display: inline-block;
  line-height: 14px;
`;
const background = {
  pending: colors.state.ambar,
  complete: colors.state.green,
};
const Dot = styled.div`
  width: 9px;
  height: 9px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 100px;
  background: ${styledProps(background, "status")};
`;
const marketplaceBackground = {
  default: colors.primary.mienvio_pink,
  massive: "#714DF6",
  manual: colors.primary.mienvio_pink,
  shopify: colors.marketplaces.shopify,
  magento: colors.marketplaces.magento,
  woocommerce: colors.marketplaces.woocommerce,
  prestashop: colors.marketplaces.prestashop,
  epages: colors.marketplaces.epages,
  tiendanube: colors.marketplaces.tiendanube,
};
const VerticalLine = styled.div`
  width: 4px;
  height: 23px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  background-color: ${styledProps(marketplaceBackground, "color")};
`;

const Bullet = styled.div`
  height: 8px;
  width: 8px;
  display: inline-block;
  margin-right: 4px;
  background-color: ${styledProps(marketplaceBackground, "color")};
  border-radius: 100px;
`;

VerticalLine.defaultProps = {
  color: "default",
};
const Badge = (props, context) => (
  <StyledBadge>
    <Dot status={props.status} />
    {props.children}
  </StyledBadge>
);

const MarketplaceBadge = (props, context) => (
  <div>
    <Bullet style={props.style} color={props.color} />
    <p className="minitext">{labels[props.color]}</p>
  </div>
);

export default Badge;
export { MarketplaceBadge };
