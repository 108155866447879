// @flow
import * as React from 'react';
import { Row, Column } from 'hedron';
import styled from 'styled-components';
import boxLogin from './boxLogin.svg';

import './index.css';

export const Line = styled.div`
  width: 100%;
  border: solid 1px #f3f3f3;
`;

export type Props = {
  children?: React.Node,
};

const LoginContainer = ({ children }: Props) => (
  <div style={{ width: '100%', height: '100%', background: '#fff' }}>
    <Row divisions={24}>
      <Column className="login-left-pane" xs={24} md={12} fluid>
        <div className="login-form-div">{children}</div>
      </Column>
      <Column className="login-right-pane" xs={24} md={12} fluid>
        <img src={boxLogin} />
      </Column>
    </Row>
  </div>
);

export default LoginContainer;
