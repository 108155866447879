import { GET } from './types';

const initialState = {
  addresses: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET:
      return action.addresses;
    default:
      return state;
  }
};
