import React from 'react';
import { connect } from 'react-redux';

import Text, { Title } from '../../Text';
import Button from '../../Button';
import { DropdownConfig, ConfigLink } from '../../ShipmentsList/index';
import { toggle } from '../../../modules/CollapsibleBar/actions';
import { clearSidebar } from '../../../modules/sidebarOrder/actions';
import { bindActionCreators } from 'redux';
import up_arrow from '../../../utils/icons/up-arrow.svg';
import down_arrow from '../../../utils/icons/down-arrow.svg';
import './style.css';
import ThreeDotsDropdown from '../../Dropdown/ThreeDotsDropdown/index';

class SidebarTitleMenu extends React.Component {
  state = {
    isMenuOpen: false,
    object: {}
  };

  render() {
    return (
      <div className="sidebar-title-menu">
        <ThreeDotsDropdown linksToRender={this.props.linksToRender} />
        {(this.props.nextButton && this.props.prevButton) &&
          <div className="stm-extra-controls">
            <Button
              disabled={this.props.nextButton.disabled}
              type="link"
              className="stm-pagination"
              onClick={() => this.props.nextButton.trigger()}
            >
              <img src={up_arrow} />
            </Button>
            <Button
              disabled={this.props.prevButton.disabled}
              type="link"
              className="stm-pagination"
              onClick={() => this.props.prevButton.trigger()}
            >
              <img src={down_arrow} />
            </Button>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearSidebar
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarTitleMenu);
