import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const EmptyView = props => (
  <div>
    <div className="skeleton-parent" style={{ fontSize: 100, lineHeight: 0.2 }}>
      <h1 className="no-margin">{<Skeleton />}</h1>
      <h1 className="no-margin">{<Skeleton />}</h1>
      <h1 className="no-margin">{<Skeleton />}</h1>
    </div>
  </div>
);