import React from "react";
import styledProps from "styled-props";
import styled from "styled-components";
import colors from "../../utils/colors";
import Text from "../Text";

const borderLeft = {
  default: `4px solid ${colors.gray.creamy_gray}`,
  last: "4px solid transparent",
};
const StyledStep = styled.div`
  border-left: ${styledProps(borderLeft, "position")};
  padding-left: 32px;
  position: relative;
  padding-bottom: 40px;
`;

const background = {
  default: colors.secondary.white,
  complete: colors.state.spring_green,
  incident: colors.state.yellow_orange,
};
const border = {
  default: colors.gray.sky_gray,
  complete: colors.state.spring_green,
  incident: colors.state.yellow_orange,
};
const Dot = styled.div`
  position: absolute;
  left: -10px;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 100px;
  border: 2px solid ${styledProps(border, "status")};
  background: ${styledProps(background, "status")};
`;
Dot.defaultProps = {
  status: "default",
};

const Link = styled.div`
  margin-top: 4px;
  margin-bottom: -16px;
  cursor: pointer;
`;

const Step = (props, context) => {
  return (
    <StyledStep
      position={props.last ? "last" : "default"}
      className={props.className}
      style={props.style}
    >
      <Dot
        status={
          props.done ? "complete" : props.incident ? "incident" : "default"
        }
      />
      <Text
        style={{
          display: "block",
          marginBottom: "8px",
          color: colors.gray.thunder_sky,
        }}
        type="labelAuxiliar"
      >
        {props.title}
      </Text>
      <div>{props.children}</div>
      {props.link ? <Link>Ver más</Link> : null}
    </StyledStep>
  );
};

class Tracking extends React.Component {
  render() {
    return <div style={this.props.style}>{this.props.children}</div>;
  }
}

export default Tracking;
export { Step };
