import React from 'react';
import {
  Background,
  Box,
  Bigbox,
  Content,
  CloseIcon,
  Title,
  TitleLine,
  ContentAdapter,
  Spacer
} from './styledObjects';
import { toggle } from '../../modules/CollapsibleBar/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.css';

class WrapperSideBar extends React.Component {
  _onClose = () => {
    if (this.props.handleClose != null) this.props.handleClose();
    this.props.toggle(false);
  };

  render() {
    return (
      <div>
        <Background onClick={this._onClose} className={this.props.sidebar.open ? 'csb-open' : ''} />
        {this.props.isBigBox ? (
          <Bigbox className={this.props.sidebar.open ? 'csb-open' : 'no-display'}>
            <div className="sidebar-title-wrapper">
              <CloseIcon onClick={this._onClose} />
              <Title titleMargin={0}>
                {this.props.icon && <img src={this.props.icon} className="title-icon" />}
                {this.props.sidebar.title} {this.props.title_more}
              </Title>
              <TitleLine />
            </div>
            <Content className={`sidebar-content`}>
              {this.props.children}
              {!this.props.isIntegration && <Spacer />}
            </Content>
          </Bigbox>
        ) : (
          <Box className={this.props.sidebar.open ? 'csb-open' : ''}>
            <div className="sidebar-title-wrapper">
              <CloseIcon onClick={this._onClose} />
              <Title titleMargin={0}>
                {this.props.icon && <img src={this.props.icon} className="title-icon" />}
                {this.props.sidebar.title} {this.props.title_more}
              </Title>
              <TitleLine />
            </div>
            <Content className={`sidebar-content`}>
              {this.props.children}
              {!this.props.isIntegration && <Spacer />}
            </Content>
          </Box>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sidebar: state.sidebar
});

const mapDispatchToProps = dispatch => bindActionCreators({ toggle }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrapperSideBar);
