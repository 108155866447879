import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { boundMethod } from "autobind-decorator";

import axios from "axios";
import { Row, Column } from "hedron";
import Moment from "react-moment";
import Skeleton from "react-loading-skeleton";
import Waypoint from "react-waypoint";
import Loader from "react-loaders";
import styled from "styled-components";
import Select from "react-select";
import Dropdown from "../Dropdown";
import Text, { Title } from "../Text";
import EmpyState from "./EmptyState";
import Button from "../Button";
import NewTable from "../NewTable";
import colors from "../../utils/colors";
import Card, { Divider } from "../Card";
import ThreeDotsDropdown from "../Dropdown/ThreeDotsDropdown/index";
import edit_icon from "../../utils/icons/edit.svg";
import delete_icon from "../../utils/icons/delete.svg";
import WrapperSideBar from "../WrapperSideBar";
import {
  ContentAdapter,
  BottomContainer,
} from "../WrapperSideBar/styledObjects";
import Input, { Label, Required, HelpText } from "../Input";
import { getRegions, getManagerRegions } from "../../modules/regions";
import {
  getAccounts,
  getMasterCountries,
  getManagerCountries,
} from "../../modules/marketplaces";
import "./style.scss";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../modules/CollapsibleBar/actions";
import SweetAlert from "sweetalert2-react";
import { searchPermission } from "../../utils/global_functions";

const NEW_ROL = "NEW_ROL";
const ROL_EDIT = "ROL_EDIT";

class RolesList extends React.Component {
  state = {
    showEmpty: true,
    show: false,
    showDenied: false,
    showSuccess: false,
    actions: [],
    roles: [],
    name: "",
    description: "",
    nameError: "",
    descriptionError: "",
    detailRol: "",
    permisosValue: [],
    permisosError: "",
    selectedPermisos: "",
    selectedRegion: null,
    selectedRegionIndex: null,
    selectedAccount: null,
    selectedAccountIndex: null,
    regionsData: [],
    accountsData: [],
    emptyRoles: "",
    selectedRegionIndexAdd: null,
    selectedRegionErrorAdd: "",
    selectedRegionAdd: null,
    selectedAccountIndexAdd: null,
    selectedAccountErrorAdd: "",
    selectedAccountAdd: null,
    accountsDataAdd: [],
  };

  componentWillMount() {
    this.loadActions();
    if (
      this.props.user.role === "master" ||
      this.props.user.role === "manager" ||
      this.props.user.plan.name === "enterprise" ||
      this.props.user.plan.name === "operativo"
    ) {
      this.getRegionsData();
    } else {
      this.loadRoles(this.props.user.object_id);
    }
  }

  getRegionsData() {
    this.setState({ loading: true });
    if (
      this.props.user.role === "master" ||
      (this.props.user.plan && this.props.user.plan.name === 'enterprise')
    ) {
      getMasterCountries().then((response) => {
        if (response.type === "Success" && response.response.status === 200) {
          if (response.response.data.length === 0) {
            this.setState({ emptyRegions: true });
          } else {
            this.setState({
              emptyRegions: false,
              regionsData: response.response.data,
            });
            this.handleChangeDropdownRegion(0);
          }
        } else {
          this.setState({});
        }
      });
    } else {
      getManagerCountries(this.props.user.object_id).then((response) => {
        if (response.type === "Success" && response.response.status === 200) {
          if (response.response.data.length === 0) {
            this.setState({ emptyRegions: true });
          } else {
            this.setState({
              emptyRegions: false,
              regionsData: response.response.data,
            });
            this.handleChangeDropdownRegion(0);
          }
        } else {
          this.setState({});
        }
      });
    }
  }

  getMarketplacesData(region) {
    this.setState({ loadingData: true });
    getAccounts(region).then((response) => {
      if (response.type === "Success" && response.response.status === 200) {
        if (response.response.data.length === 0) {
          this.setState({
            loadingData: false,
            emptyAccounts: true,
            accountsData: [],
          });
        } else {
          this.setState({
            loadingData: false,
            emptyAccounts: false,
            accountsData: response.response.data,
          });
          this.handleChangeDropdownAccount(0);
        }
      } else {
        this.setState({
          loadingData: false,
          emptyAccounts: true,
          accountsData: [],
        });
      }
    });
  }

  getMarketplacesDataAdd(region) {
    this.setState({ loadingData: true, accountsDataAdd: [] });
    getAccounts(region).then((response) => {
      if (response.type === "Success" && response.response.status === 200) {
        if (response.response.data.length !== 0) {
          this.setState({
            loadingData: false,
            emptyAccounts: false,
            accountsDataAdd: response.response.data,
          });
        }
      } else {
        this.props.notify("Error al obtener la información", "error");
        this.setState({
          loadingData: false,
          emptyAccounts: true,
          accountsData: [],
        });
      }
    });
  }

  loadRoles(id) {
    this.setState({ loading: true });
    axios.get(`/api/operational_roles/${id}`).then((r) => {
      this.setState({ loading: false });
      if (r.status == 200) {
        if (r.data.length === 0) {
          this.setState({ emptyRoles: true });
        } else {
          this.setState({ roles: r.data, emptyRoles: false });
        }
      }
    });
  }

  loadActions() {
    axios.get("/api/actions").then((r) => {
      if (r.status == 200) {
        const actionsOptions = [];

        r.data.forEach((element) => {
          actionsOptions.push({
            value: element.id,
            label: `${element.name} - ${element.value}`,
          });
        });

        this.setState({ actions: actionsOptions });
      }
    });
  }

  @boundMethod
  addRole() {
    if (this.props.user.role === "regional") {
      if (this.validateFields("regional")) {
        axios
          .post(`api/operational_roles/${this.state.selectedAccountAdd.id}`, {
            name: this.state.name,
            description: this.state.description,
            actions: this.state.permisosValue.map((element) => element.value),
          })
          .then((res) => {
            if (res.status == 200) {
              this.props.toggleSideBar(false);
              this.clearSb();
              if (this.state.selectedAccount) {
                this.loadRoles(this.state.selectedAccount.id);
              }
              this.clearFileds();
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.request.status == 422) {
              this.props.toggleSideBar(false);
            }
          });
      }
    } else if (this.props.user.role === "customer") {
      if (this.validateFields("customer")) {
        axios
          .post(`api/operational_roles/${this.props.user.object_id}`, {
            name: this.state.name,
            description: this.state.description,
            actions: this.state.permisosValue.map((element) => element.value),
          })
          .then((res) => {
            if (res.status == 200) {
              this.props.toggleSideBar(false);
              this.clearSb();
              this.loadRoles(this.props.user.object_id);
              this.clearFileds();
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.request.status == 422) {
              this.props.toggleSideBar(false);
            }
          });
      }
    } else if (this.validateFields("master")) {
      axios
        .post(`api/operational_roles/${this.state.selectedAccountAdd.id}`, {
          name: this.state.name,
          description: this.state.description,
          actions: this.state.permisosValue.map((element) => element.value),
        })
        .then((res) => {
          if (res.status == 200) {
            this.props.toggleSideBar(false);
            this.clearSb();
            if (this.state.selectedAccount) {
              this.loadRoles(this.state.selectedAccount.id);
            }
            this.clearFileds();
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.request.status == 422) {
            this.props.toggleSideBar(false);
          }
        });
    }
  }

  @boundMethod
  deleteRole() {
    axios
      .delete(`/api/operational_roles/13560/${this.state.detailRol.id}`)
      .then((r) => {
        this.props.toggleSideBar(false);
        if (this.props.user.role === "customer") {
          this.loadRoles(this.props.user.object_id);
        } else {
          this.loadRoles(this.state.selectedAccount.id);
        }

        this.clearFileds();
        this.setState({
          show: false,
          showSuccess: true,
        });
      });
  }

  @boundMethod
  updateRole() {
    if (this.validateFields()) {
      axios
        .put(`/api/operational_roles/13560/${this.state.detailRol.id}`, {
          name: this.state.name,
          description: this.state.description,
          actions: [6, 7, 8, 9, 10],
        })
        .then((res) => {
          if (res.status == 200) {
            this.props.toggleSideBar(false);
            this.loadRoles();
            this.clearFileds();
          }
        })
        .catch((error) => {
          if (error.request.status == 422) {
            this.props.toggleSideBar(false);
            this.clearFileds();
          }
        });
    }
  }

  validateFields(type) {
    const errorMsgs = {
      required: "Este campo es requerido",
    };

    let flag = true;

    if (this.state.name == "") {
      flag = false;
      this.setState({ nameError: errorMsgs.required });
    } else {
      this.setState({ nameError: "" });
    }

    if (this.state.description == "") {
      flag = false;
      this.setState({ descriptionError: errorMsgs.required });
    } else {
      this.setState({ descriptionError: "" });
    }

    if (this.state.permisosValue.length == 0) {
      flag = false;
      this.setState({ permisosError: errorMsgs.required });
    } else {
      this.setState({ permisosError: "" });
    }

    if (type === "master" || this.props.user.plan.name === "enterprise" || this.props.user.plan.name === "operativo") {
      if (!this.state.selectedRegionAdd) {
        flag = false;
        this.setState({ selectedRegionErrorAdd: errorMsgs.required });
      } else {
        this.setState({ selectedRegionErrorAdd: "" });
      }
      if (!this.state.selectedAccountAdd) {
        flag = false;
        this.setState({ selectedAccountErrorAdd: errorMsgs.required });
      } else {
        this.setState({ selectedAccountErrorAdd: "" });
      }
    } else if (type === "regional") {
      if (!this.state.selectedAccountAdd) {
        flag = false;
        this.setState({ selectedAccountErrorAdd: errorMsgs.required });
      } else {
        this.setState({ selectedAccountErrorAdd: "" });
      }
    }

    return flag;
  }

  clearFileds = () => {
    this.setState({
      detailOpen: false,
      name: "",
      description: "",
      nameError: "",
      descriptionError: "",
      detailRol: "",
      show: false,
      showDenied: false,
      showSuccess: false,
      permisosValue: [],
      permisosError: "",
    });
  };

  _renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
      case NEW_ROL:
        return <div>{this.renderSidebarContentAdd()}</div>;
      case ROL_EDIT:
        return <div>{this.renderSidebarContentAdd()}</div>;
      default:
        return <div />;
    }
  };

  toggleEditRol(detailRol) {
    this.props.toggleSideBar(true, "Editar Rol", ROL_EDIT);
    this.setState({
      detailRol,
    });
  }

  toggleDeleteRol(detailRol) {
    this.setState({
      show: true,
      showDenied: false,
      showSuccess: false,
      detailRol,
    });
  }

  getLinksToRender = (detailRol) => {
    const {
      customer_type,
      plan,
      actions,
      role,
      operational_user,
    } = this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    const Options = [];

    if (searchPermission("roles", "editar", permissions)) {
      Options.push({
        function: () => this.toggleEditRol(detailRol),
        label: "Editar rol",
        image: edit_icon,
      });
    }

    if (searchPermission("roles", "eliminar", permissions)) {
      Options.push({
        function: () => this.toggleDeleteRol(detailRol),
        label: "Eliminar rol",
        image: delete_icon,
      });
    }

    return Options;
  };

  handlePermisosDrop = (selectedOption) => {
    this.setState({
      selectedPermisos: selectedOption.value,
      permisosValue: selectedOption,
    });
  };

  renderSidebarContentAdd = () => {
    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.setState({ [name]: value });
        },
        value: this.state[name],
        error: this.state[`${name}Error`],
      }),
    };

    const editFields = (
      <div>
        {this.props.user.role !== "customer" && (
          <div>
            {this.props.user.role === "regional" ? (
              <Input
                onChange={(value) => this.handleOnChange("name", value)}
                type="text"
                required
                value={this.props.user.first_name}
                placeholder=""
                label="Región"
                disabled
              />
            ) : (
              <Dropdown
                required
                label="Selecciona un País"
                options={this.renderRegions()}
                style={{ marginTop: "12px" }}
                placeholder={<Text className="dropdown-placeholder">País</Text>}
                selected={this.state.selectedRegionIndexAdd}
                handleChange={this.handleChangeDropdownRegionAdd}
                error={this.state.selectedRegionErrorAdd}
              />
            )}
            <Dropdown
              disabled={
                !!(
                  this.selectedRegionAdd &&
                  this.state.accountsDataAdd.length > 0
                )
              }
              down
              required
              label="Selecciona una Cuenta"
              options={this.renderAccountsAdd()}
              style={{ marginTop: "12px" }}
              placeholder={<Text className="dropdown-placeholder">Cuenta</Text>}
              selected={this.state.selectedAccountIndexAdd}
              handleChange={this.handleChangeDropdownAccountAdd}
              error={this.state.selectedAccountErrorAdd}
            />
          </div>
        )}
        <Input
          {...mainProps.generate("name")}
          type="text"
          placeholder="Nombre"
          label="Nombre"
        />

        <div>
          <Label style={{ marginTop: "16px" }}>
            Permisos <Required />
          </Label>

          <Select
            isMulti
            value={this.state.permisosValue}
            onChange={this.handlePermisosDrop}
            noOptionsMessage={() => "Sin Opciones"}
            isSearchable
            placeholder="Permisos"
            closeMenuOnSelect={false}
            options={this.state.actions}
            className={`search-select accounts ${this.state.permisosError != "" && "error"
              }`}
          />
          {this.state.permisosError != "" && (
            <HelpText>Este campo es requerido</HelpText>
          )}
        </div>

        <Input
          {...mainProps.generate("description")}
          type="text"
          required
          placeholder="Descripcion"
          label="Descripcion "
        />
      </div>
    );
    return (
      <div>
        <ContentAdapter>
          {editFields}
          <BottomContainer>
            <Row divisions={20}>
              <Column style={{ textAlign: "right" }} fluid>
                <Button
                  loading={this.state.setLoading}
                  onClick={() => this.addRole()}
                  className=""
                >
                  Guardar
                </Button>
              </Column>
              <Column xs={1} xsShift={1} style={{ textAlign: "right" }} fluid />
            </Row>
          </BottomContainer>
        </ContentAdapter>
      </div>
    );
  };

  renderSidebarContentEdit = () => {
    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.setState({ [name]: value });
        },
        value: this.state[name],
        error: this.state[`${name}Error`],
      }),
    };

    const editFields = (
      <div>
        <Dropdown
          required
          label="Selecciona una Región"
          options={this.renderRegions()}
          style={{ marginTop: "12px" }}
          placeholder={<Text className="dropdown-placeholder">Región</Text>}
          selected={selectedRegionIndex}
          handleChange={this.handleChangeDropdown}
          error={selectedRegionError}
        />

        <Input
          {...mainProps.generate("name")}
          type="text"
          placeholder="Nombre"
          label="Nombre"
        />

        <div>
          <Label style={{ marginTop: "16px" }}>
            Permisos <Required />
          </Label>
          <Select
            isMulti
            noOptionsMessage={() => "Sin Opciones"}
            isSearchable
            placeholder="Seleccionar Permisos"
            closeMenuOnSelect={false}
            options={this.state.actions}
            className="search-select accounts"
          />
        </div>

        <Input
          {...mainProps.generate("description")}
          type="text"
          required
          placeholder="Descripcion"
          label="Descripcion "
        />
      </div>
    );
    return (
      <div>
        <ContentAdapter>
          {editFields}
          <BottomContainer>
            <Row divisions={20}>
              <Column style={{ textAlign: "right" }} fluid>
                <Button
                  loading={this.state.setLoading}
                  onClick={() => this.updateRole(this)}
                  className=""
                >
                  Guardar
                </Button>
              </Column>
              <Column xs={1} xsShift={1} style={{ textAlign: "right" }} fluid />
            </Row>
          </BottomContainer>
        </ContentAdapter>
      </div>
    );
  };

  clearSb() {
    this.setState({
      name: "",
      nameError: "",
      description: "",
      descriptionError: "",
      permisosError: "",
      permisosValue: null,
      selectedAccountAdd: null,
      selectedAccountErrorAdd: "",
      selectedRegionAdd: null,
      selectedRegionErrorAdd: "",
      selectedAccountIndexAdd: null,
      selectedRegionIndexAdd: null,
    });
  }

  handleChangeDropdownRegion = (selectedOption) => {
    if (this.state.regionsData[selectedOption]) {
      this.setState({
        selectedRegion: this.state.regionsData[selectedOption],
        selectedRegionIndex: selectedOption,
      });
      this.getMarketplacesData(this.state.regionsData[selectedOption].id);
    }
  };

  handleChangeDropdownRegionAdd = (selectedOption) => {
    this.setState({
      selectedRegionAdd: this.state.regionsData[selectedOption],
      selectedRegionIndexAdd: selectedOption,
    });
    this.getMarketplacesDataAdd(this.state.regionsData[selectedOption].id);
  };

  handleChangeDropdownAccount = (selectedOption) => {
    if (this.state.accountsData[selectedOption]) {
      this.loadRoles(this.state.accountsData[selectedOption].id);
      this.setState({
        selectedAccount: this.state.accountsData[selectedOption],
        selectedAccountIndex: selectedOption,
      });
    }
  };

  handleChangeDropdownAccountAdd = (selectedOption) => {
    this.setState({
      selectedAccountAdd: this.state.accountsDataAdd[selectedOption],
      selectedAccountIndexAdd: selectedOption,
    });
  };

  renderRegions = () =>
    this.state.regionsData
      ? this.state.regionsData.map((region) => (
        <Text key={region.id}>{region.name}</Text>
      ))
      : null;

  renderAccounts = () =>
    this.state.accountsData
      ? this.state.accountsData.map((account) => (
        <Text key={account.id}>{account.email}</Text>
      ))
      : null;

  renderAccountsAdd = () =>
    this.state.accountsDataAdd
      ? this.state.accountsDataAdd.map((account) => (
        <Text key={account.id}>{account.email}</Text>
      ))
      : null;

  renderLoader() {
    return (
      <div>
        <Row divisions={20}>
          <Column xs={17} md={7} fluid className="summary-microbox">
            <Skeleton height={150} />
          </Column>

          <Column xs={1} fluid />
          <Column xs={17} md={7} fluid className="summary-microbox">
            <Skeleton height={150} />
          </Column>
        </Row>
        <br />
        <Row divisions={20}>
          <Column xs={17} md={7} fluid className="summary-microbox">
            <Skeleton height={150} />
          </Column>

          <Column xs={1} fluid />
          <Column xs={17} md={7} fluid className="summary-microbox">
            <Skeleton height={150} />
          </Column>
        </Row>
        <br />
        <Row divisions={20}>
          <Column xs={17} md={7} fluid className="summary-microbox">
            <Skeleton height={150} />
          </Column>

          <Column xs={1} fluid />
          <Column xs={17} md={7} fluid className="summary-microbox">
            <Skeleton height={150} />
          </Column>
        </Row>
      </div>
    );
  }

  render() {
    return (
      <div>
        <SweetAlert
          show={this.state.show}
          title="¿Deseas eliminar el Rol?"
          showCancelButton
          onConfirm={() => this.deleteRole(this)}
          onCancel={() => {
            this.setState({
              show: false,
              showSuccess: false,
            });
          }}
          onClose={() => console.log("close")}
        />

        <SweetAlert
          show={this.state.showSuccess}
          title="Rol eliminado exitosamente"
          onOutsideClick={() =>
            this.setState({
              show: false,
              showSuccess: false,
            })
          }
        />
        <WrapperSideBar handleClose={() => this.clearSb()}>
          {this._renderSidebarContent()}
        </WrapperSideBar>
        {this.props.user.role !== "customer" ? (
          <div>
            <p>
              <strong>Filtros</strong>
            </p>
            <div className="filters-container-roles">
              <div className="drop-roles">
                {this.props.user.role === "regional" ? (
                  <Input
                    onChange={(value) => this.handleOnChange("name", value)}
                    type="text"
                    value={this.props.user.first_name}
                    placeholder=""
                    disabled
                  />
                ) : (
                  <Dropdown
                    options={this.renderRegions()}
                    style={{ marginTop: "17px" }}
                    placeholder={
                      <Text className="dropdown-placeholder">País</Text>
                    }
                    selected={this.state.selectedRegionIndex}
                    handleChange={this.handleChangeDropdownRegion}
                  />
                )}
              </div>
              <div className="drop-roles">
                <Dropdown
                  disabled={
                    !!(
                      this.selectedRegion && this.state.accountsData.length > 0
                    )
                  }
                  options={this.renderAccounts()}
                  style={{ marginTop: "12px" }}
                  placeholder={
                    <Text className="dropdown-placeholder">Cuenta</Text>
                  }
                  selected={this.state.selectedAccountIndex}
                  handleChange={this.handleChangeDropdownAccount}
                />
              </div>
            </div>
          </div>
        ) : (
          (this.props.user.plan.name === "enterprise" || this.props.user.plan.name === "operativo") && (
            <div>
              <p>
                <strong>Filtros</strong>
              </p>
              <div className="filters-container-roles">
                <div className="drop-roles">
                  {this.props.user.role === "regional" ? (
                    <Input
                      onChange={(value) => this.handleOnChange("name", value)}
                      type="text"
                      value={this.props.user.first_name}
                      placeholder=""
                      disabled
                    />
                  ) : (
                    <Dropdown
                      options={this.renderRegions()}
                      style={{ marginTop: "17px" }}
                      placeholder={
                        <Text className="dropdown-placeholder">País</Text>
                      }
                      selected={this.state.selectedRegionIndex}
                      handleChange={this.handleChangeDropdownRegion}
                    />
                  )}
                </div>
                <div className="drop-roles">
                  <Dropdown
                    disabled={
                      !!(
                        this.selectedRegion &&
                        this.state.accountsData.length > 0
                      )
                    }
                    options={this.renderAccounts()}
                    style={{ marginTop: "12px" }}
                    placeholder={
                      <Text className="dropdown-placeholder">Cuenta</Text>
                    }
                    selected={this.state.selectedAccountIndex}
                    handleChange={this.handleChangeDropdownAccount}
                  />
                </div>
              </div>
            </div>
          )
        )}

        <Row divisions={29}>
          {this.state.loading ? (
            this.renderLoader()
          ) : this.state.emptyRoles ? (
            <EmpyState
              userState={this.props.user}
              onClick={() => {
                this.props.toggleSideBar(true, "Añadir Rol", NEW_ROL);
              }}
            />
          ) : (
            this.state.roles.map((item, key) => (
              <React.Fragment>
                <Column xs={27} md={5} fluid className="summary-microbox">
                  <Card className="region-card-container" size="full">
                    <div>
                      <strong>{item.name}</strong>
                      <span style={{ textAlign: "right" }} />
                      <ThreeDotsDropdown
                        linksToRender={this.getLinksToRender(item)}
                      />
                    </div>
                    <p>
                      <strong>Permisos</strong>
                    </p>
                    {item.actions.map((action, keyAction) => (
                      <div>
                        <p className="country-text-region">
                          - {action.name}-{action.value}
                        </p>
                      </div>
                    ))}
                    <p>
                      <strong>Descripción</strong>
                    </p>
                    <p className="country-text-region">{item.description}</p>
                  </Card>
                </Column>

                <Column xs={1} fluid />
              </React.Fragment>
            ))
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSideBar,
      changeSidebarTitle,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RolesList);
