import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { boundMethod } from "autobind-decorator";
import { Row, Column } from "hedron";
import Skeleton from "react-loading-skeleton";
import Loader from "react-loader-spinner";
import ItemAccount from "./ItemAccount";
import SimpleDropdown from "../Dropdown/SimpleDropdown/index";
import { getAccounts } from "../../modules/marketplaces";

import "./style.css";
import "./style.scss";

const colores = [
  "#7DBF90",
  "#A27DBF",
  "#7DB7BF",
  "#BF817D",
  "#FAF19E",
  "#A09EFA",
  "#FA9ED5",
  "#FA9E9E",
  "#6EC05A",
  "#736B79",
];

class AccountsManagment extends React.Component {
  state = {
    selectedRegion: null,
    emptyAccounts: false,
    loadingAccounts: false,
    accountsData: null,
  };

  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
    this.setState({ loadingAccounts: true });
    if (this.props.user.role === "regional") {
      this.getMarketplacesData(this.props.user.object_id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reload) {
      if (this.props.user.role === "regional") {
        this.getMarketplacesData(this.props.user.object_id);
      } else {
        if (this.state.selectedRegion) {
          this.getMarketplacesData(this.state.selectedRegion.value);
        }
      }
    }
  }

  getMarketplacesData(region) {
    this.setState({ loadingAccounts: true });
    getAccounts(region).then((response) => {
      if (response.type === "Success" && response.response.status === 200) {
        if (response.response.data.length === 0) {
          this.setState({
            loadingAccounts: false,
            emptyAccounts: true,
            accountsData: null,
          });
        } else {
          const data = response.response.data.map((element) => ({
            id: element.id,
            first_name: element.first_name,
            last_name: element.last_name,
            email: element.email,
            color: colores[Math.floor(Math.random() * 9)],
          }));
          this.setState({
            loadingAccounts: false,
            emptyAccounts: false,
            accountsData: data,
          });
        }
      } else {
        this.props.notify("Error al obtener la información", "error");
        this.setState({
          loadingAccounts: false,
          emptyAccounts: true,
          accountsData: null,
        });
      }
    });
  }

  renderItems(dataItems) {
    if (dataItems) {
      return dataItems.map((element, index) => {
        if (this.props.selectedUser === element.id) {
          return (
            <ItemAccount
              key={`item-user${element.id}-${index}`}
              selectUser={this.props.selectUser}
              selected
              data={element}
              hide
              color={element.color}
            />
          );
        }
        return (
          <ItemAccount
            key={`item-user${element.id}-${index}`}
            selectUser={this.props.selectUser}
            data={element}
            hide
            color={element.color}
          />
        );
      });
    }
    return <div />;
  }

  @boundMethod
  onChangeDropdown(data) {
    this.getMarketplacesData(data.value);
    this.setState({ selectedRegion: data });
    this.props.clearSelectedUser();

    if (this.props.clearSelectedQuotes !== undefined && this.props.clearSelectedQuotes !== null) {
      this.props.clearSelectedQuotes();
    }
  }

  renderLoader = () => (
    <div className="center-loader">
      <Loader
        type="TailSpin"
        color="#EB2C88"
        height={35}
        width={35}
        timeout={3000}
      />
    </div>
  );

  renderEmpty = () => <div>Sin cuentas :()</div>;

  renderCountries(countries) {
    const options = [];
    if (countries.length !== 0 && !this.state.selectedRegion) {
      this.setState({
        selectedRegion: { value: countries[0].id, label: countries[0].name },
      });
      this.getMarketplacesData(countries[0].id);
    }
    countries.forEach((element) => {
      options.push({ value: element.id, label: element.name });
    });
    return options;
  }

  render() {
    const { user } = this.props;
    return (
      <div className="card-container-accounts">
        <div className="title-container">
          <div className="title-container-row">
            <p className="title-card">Cuentas</p>
            {user.role === "regional" ? (
              <div className="dropdown-accounts">{user.first_name}</div>
            ) : (
              <SimpleDropdown
                disabled={user.role === "regional"}
                value={this.state.selectedRegion}
                onChange={this.onChangeDropdown}
                className="dropdown-accounts"
                linksToRender={this.renderCountries(this.props.countries)}
                placeholder="Región"
              />
            )}
          </div>
          <div className="card-container-divisor" />
        </div>

        {this.state.loadingAccounts
          ? this.renderLoader()
          : this.state.emptyAccounts
            ? this.renderEmpty()
            : this.renderItems(this.state.accountsData)}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsManagment);
