import React from 'react';
import SelectableBox from '../../SelectableBox';
import { Column, Row } from 'hedron';
import Text from '../../Text';
import Button from '../../Button';

const lessPackages = props => (
  <Row>
    <Column xs={12} fluid>
      <Text type="microHeader" className="min-padding">
        Empaque
    </Text>
      {Object.values(props.packages).map((pack, index) => (
        <SelectableBox
          key={pack.object_id}
          name={index}
          required={true}
          type={'radio'}
          value={pack.object_id}
          onChange={props.onChangefn}
          isChecked={props.isChecked(pack.object_id)}
        >
          <Row>
            <Column xs={8} className="left-package" style={{ padding: '5px' }}>
              <Text type="P" className="title">
                {pack.object_type}
              </Text>
              <Text type="P">{`${pack.height} x ${pack.length} x ${pack.width} cm`}</Text>
            </Column>
            <Column xs={4} className="text-right" style={{ padding: '5px' }}>
              <Text type="P">{pack.object_type_code == 'box' ? '100 pz.' : '20 pz.'}</Text>
            </Column>
          </Row>
        </SelectableBox>
      ))}
      <Button type="link" className="edit-btn-left" onClick={props.newPackage}>
        Ingresar medidas manualmente
    </Button>
    </Column>
  </Row>
);

export default lessPackages;
