import React from "react";
import ReactDOM from "react-dom";
import { boundMethod } from "autobind-decorator";
import { push } from "react-router-redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Scriptly from "scriptly";
import valid from "card-validator";
import axios from "axios";
import PaypalBtn from "react-paypal-checkout";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column, BreakpointProvider } from "hedron";
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import Loader from "react-loaders";
import ModalResponsive from "react-responsive-modal";
import {
  StyledOption,
  I,
  Img,
  Order,
  CardOption,
  Td1,
  Td2,
  Td3,
  Td4,
  CardTab,
} from "./styled-components.js";
import colors from "../../utils/colors";
import { showMessage, hideMessage } from "../../modules/message";
import { getUserCards, createCard, STRIPE_PUBLIC } from "../../modules/cards";
import { changeDashboardGlobalStatus } from "../../modules/dashboard/actions";
import { showError } from "../../modules/error";
import { getUser, getProfileFinance } from "../../modules/user";
import Tabs, { Tab } from "../Tabs";
import Text from "../Text";
import Modal from "../Modal";
import Button from "../Button";
import Input from "../Input";
import Message from "../Message";
import { Checkbox, CheckboxGroup } from "../Checkbox";

import visaWhite from "./visaWhite.svg";
import visa from "./visa.svg";
import mastercard from "./mastercard.svg";
import arrowDown from "./arrow-down.svg";
import house from "./house.svg";
import lock from "./lock.svg";
import deliveryBox from "./delivery-box.svg";
import overweightIcon from "./shipment-weight-kg.svg";
import pin from "./pin.svg";
import priceTag from "./price-tag.svg";
import "./index.css";
import { loadInvoice } from "../../modules/Invoices/actions";
import {
  shorten,
  sendFBEvent,
  numberWithCommas,
} from "../../utils/global_functions";

import credit from "./icons/credit.svg";
import creditActive from "./icons/creditActive.svg";
import wallet from "./icons/wallet.svg";
import walletActive from "./icons/walletActive.svg";
import paypalIcon from "./icons/paypal.svg";
import paypalActive from "./icons/paypalActive.svg";
import securityIcon from "./icons/security.svg";
import protectIcon from "./icons/protect.svg";

import { RadioGroup, Radio } from "../Radio";
import "../../utils/index.css";
import WalletMessage from "./wallet_message";
import "./transitions.css";
import { SimpleCheckbox } from "../Checkbox/simple_checkbox.js";
import styles from "./index.module.scss";

const env =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "sandbox"
    : "production";

const TermsNConditions = ({ checked, onChange, isCredit = false }) => (
  <div className={styles.termsNConditionsContainer}>
    <SimpleCheckbox
      title={
        <>
          He leído y acepto los&nbsp;
          <br />
          <a href="https://www.mienvio.mx/terms.html" target="_blank">
            términos de servicio
          </a>
        </>
      }
      checked={checked}
      onChange={onChange}
      className={styles.checkbox}
    />
    {isCredit ? (
      <div className={styles.americanExpressContainer}>
        <Text className={"helpTextModal " + styles.americanExpress}>
          *No aceptamos pagos con tarjeta American Express
        </Text>
      </div>
    ) : null}
  </div>
);
const TermsNConditionsSmall = ({ checked, onChange }) => (
  <SimpleCheckbox
    className="checkbox-small"
    title={
      <>
        He leído y acepto los&nbsp;
        <br />
        <a href="https://www.mienvio.mx/terms.html" target="_blank">
          términos de servicio
        </a>
      </>
    }
    checked={checked}
    onChange={onChange}
  />
);

const tncError = "Acepta nuestros términos de servicio";

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    const { getProfileFinance, getUserCards } = this.props;
    this.state = {
      paymentType: null,
      env,
      client: {
        sandbox:
          "AYvp77azBDEr9piuntf2HBYbeSy7zPq6rKiLgQcZEm8phqkJNzKoTNy-7zTWpWP0tYs0DW14WreOAEeq",
        production:
          "AXizEIC0tdv78IQdhDt2cpyjwEBmqFxhBVqhobFjWtxh-pZ_Q_Z8TUGPAK6Udak_kSUxmSWKUgLFuTdV",
      },
      commit: true,
      options: [],
      selected: null,
      number: "",
      numberError: "",
      expiration: "",
      expirationError: "",
      name: "",
      nameError: "",
      cvc: "",
      cvcError: "",
      loadingBtn: false,
      total: null,
      collapsedShipmentsIndexes: [],
      selectedPaymentType: 0,
      savedCard: 0,
      saveAsNewCard: 0,
      overweightPayment: "limit",
      totalOverweight: 0,
      totalOverweightError: "",
      shakeClass: "",
      toolTipOpen: false,
      walletDropdown: false,
      accept_tnc: false,
      showModal: false,
    };
    getUserCards();
    getProfileFinance();
  }

  componentWillReceiveProps(nextProps) {
    try {
      this.preselectPayment();
      if (!this.state.savedCard) {
        const cookies = new Cookies();
        const savedCard =
          nextProps.cards.results && nextProps.cards.results.length > 0 ? 0 : 1;
        this.setState({ savedCard });

        const cards_object_ids = [];
        if (nextProps.cards.results) {
          nextProps.cards.results.map((item) =>
            cards_object_ids.push(item.object_id)
          );
        }
        if (
          cookies.get("last_card") &&
          cards_object_ids.indexOf(cookies.get("last_card")) !== -1
        ) {
          this.setState({ selected: cookies.get("last_card") });
        }

        if (nextProps.cards.results && nextProps.cards.results.length === 1) {
          this.setState({ selected: nextProps.cards.results[0].object_id });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  componentDidMount(): void {
    this.checkOverweightUser();
    if (
      !this.props.shipmentsObject.shipments &&
      !this.props.shipmentsObject.charges
    ) {
      this.props.goToDashboard();
    } else {
      const script = document.createElement("script");
      let calculateOverweight = 0;

      script.src = "https://www.googletagmanager.com/gtag/js?id=AW-827928603";
      script.async = true;

      document.body.appendChild(script);
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      this.gtag("js", new Date());

      this.gtag("config", "AW-827928603");

      if (!this.props.shipmentsObject.shipments) {
        this.setState({
          titleForm: "Pago sobrepeso",
        });
      }

      if (
        this.props.shipmentsObject.charges &&
        this.props.shipmentsObject.charges.overweight
      ) {
        calculateOverweight =
          this.props.shipmentsObject.charges.overweight.total > 200
            ? this.props.shipmentsObject.charges.overweight.total / 2
            : this.props.shipmentsObject.charges.overweight.total;
      }
      if (this.props.shipmentsObject) {
        if (!this.props.shipmentsObject.shipments) {
          this.setState({
            overweightPayment: "custom",
            totalOverweight: parseFloat(calculateOverweight).toFixed(2),
          });
        }
      }

      if (this.props.user.customer_type === "subsided_customer") {
        this.setState({ selectedPaymentType: 2 });
      }
    }
  }

  gtag() {
    window.dataLayer.push(arguments);
  }

  gtag_report_conversion(transaction_id) {
    this.gtag("event", "conversion", {
      send_to: "AW-827928603/zd_GCJXP9qcBEJvg5IoD",
      transaction_id,
    });
  }

  setStateAsync = (state) =>
    new Promise((resolve) => {
      this.setState(state, resolve);
    });

  removeShipment = (index) => {
    this.props.removeCheckoutShipment(index);
  };

  handleCollapseShipment = (shipmentIndex) => {
    const { collapsedShipmentsIndexes } = this.state;
    const index = collapsedShipmentsIndexes.indexOf(shipmentIndex);
    if (index > -1) {
      collapsedShipmentsIndexes.splice(index, 1);
    } else {
      collapsedShipmentsIndexes.push(shipmentIndex);
    }
    this.setState(collapsedShipmentsIndexes);
  };

  handleTabChange(selectedPaymentType) {
    this.setState({ selectedPaymentType });
  }

  handleCardTabChange(savedCard) {
    this.setState({ savedCard });
  }

  handleSaveAsNewCardChange(saveAsNewCard) {
    saveAsNewCard = this.state.saveAsNewCard === 0 ? 1 : 0;
    this.setState({ saveAsNewCard });
  }

  toggleWalletDropdown = (walletDropdown) => {
    this.setState({ walletDropdown });
  };

  renderShipments = () => {
    try {
      const orders = this.props.shipmentsObject.shipments.map(
        (shipment, index) => {
          const shipmentObject = this.props.shipments.purchase.filter(
            (s) => Number(s.id) === Number(shipment.id)
          )[0];
          shipmentObject.from = shipmentObject.from_street.split("|");
          shipmentObject.to = shipmentObject.to_street.split("|");
          const total = shipment.subtotal + shipment.insurance_cost;
          return (
            <Order
              className={styles.order + " order"}
              key={shipment.id}
              collapsed={
                !this.state.collapsedShipmentsIndexes.includes(shipment.id)
              }
            >
              <ReactCSSTransitionGroup
                transitionName="collapsible"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={1}
              >
                {this.state.collapsedShipmentsIndexes.includes(shipment.id) ? (
                  <table
                    style={{ width: "100%", tableLayout: "fixed" }}
                    className="textTable"
                    key={`${shipment.id}1`}
                  >
                    <tr>
                      <Td1 style={{ verticalAlign: "bottom" }}>
                        {" "}
                        <Img src={house} />{" "}
                      </Td1>
                      <Td2 style={{ verticalAlign: "bottom" }}>
                        {" "}
                        {shipmentObject.from_name}{" "}
                      </Td2>
                      <Td3 style={{ verticalAlign: "bottom" }}>
                        <I
                          className="ion-ios-arrow-up"
                          onClick={() =>
                            this.handleCollapseShipment(shipment.id)
                          }
                        />
                      </Td3>
                      <Td4 style={{ verticalAlign: "bottom" }}>
                        <Text
                          className="textTable"
                          style={{ textAlign: "right" }}
                        >
                          {/* <Button
                          type="link"
                          className="checkout-remove-shipment delete"
                          onClick={() => this.removeShipment(index)}
                          style={{ width: '119px' }}
                        >
                          Borrar de compra
                        </Button> */}
                        </Text>
                      </Td4>
                    </tr>
                    <tr>
                      <Td1>
                        {" "}
                        <Img
                          src={arrowDown}
                          style={{ marginLeft: "5px" }}
                        />{" "}
                      </Td1>
                      <Td2 style={{ verticalAlign: "top" }}>
                        {shipmentObject.from[0]}
                        {shipmentObject.from[1]}
                      </Td2>
                    </tr>
                    <tr>
                      <Td1>
                        {" "}
                        <Img src={pin} style={{ marginLeft: "2px" }} />{" "}
                      </Td1>
                      <Td2> {shipmentObject.to_name} </Td2>
                    </tr>
                    <tr>
                      <Td1 />
                      <Td2>
                        {shipmentObject.to[0]}
                        {shipmentObject.to[1]}
                      </Td2>
                    </tr>
                    <tr>
                      <Td1>
                        <Img src={deliveryBox} />
                      </Td1>
                      <Td2>
                        <b>
                          {shipmentObject.provider_name}{" "}
                          {shipmentObject.service_level}
                        </b>
                      </Td2>
                      <Td3 />
                      <Td4 style={{ paddingRight: "32px" }}>
                        <p style={{ textAlign: "right" }}>
                          {`$${numberWithCommas(
                            parseFloat(shipment.subtotal).toFixed(2)
                          )} ${this.props.shipmentsObject.currency || ""}`}
                        </p>
                      </Td4>
                    </tr>
                    {shipment.insurance && (
                      <tr>
                        <Td1>
                          <Img src={lock} />
                        </Td1>
                        <Td2>
                          <b> Seguro</b>
                        </Td2>
                        <Td3 />
                        <Td4 style={{ paddingRight: "32px" }}>
                          <p style={{ textAlign: "right" }}>
                            {`$${numberWithCommas(
                              parseFloat(shipment.insurance_cost).toFixed(2)
                            )} ${this.props.shipmentsObject.currency || ""}`}
                          </p>
                        </Td4>
                      </tr>
                    )}
                  </table>
                ) : (
                  <table
                    style={{ width: "100%", tableLayout: "fixed" }}
                    className="textTable"
                    key={`${shipment.id}2`}
                  >
                    <tbody>
                      <tr>
                        <Td1 style={{ verticalAlign: "bottom" }}>
                          {" "}
                          <Img src={pin} />{" "}
                        </Td1>
                        <Td2 style={{ verticalAlign: "bottom" }}>
                          {" "}
                          {shorten(shipmentObject.to_name, 11)},{" "}
                          {shorten(shipmentObject.to[0], 19)}
                        </Td2>
                        <Td3 style={{ verticalAlign: "bottom" }}>
                          <I
                            className="ion-ios-arrow-down"
                            style={{ verticalAlign: "top" }}
                            onClick={() =>
                              this.handleCollapseShipment(shipment.id)
                            }
                          />
                        </Td3>
                        <Td4
                          style={{ verticalAlign: "bottom" }}
                          className={styles.deleteContainer}
                        >
                          {
                            <Button
                              type="link"
                              className={
                                "checkout-remove-shipment delete " +
                                styles.delete
                              }
                              onClick={() => this.removeShipment(index)}
                              style={{ width: "119px" }}
                            >
                              Borrar
                            </Button>
                          }
                        </Td4>
                      </tr>
                      <tr>
                        <Td1 />
                        <Td2>{shipmentObject.to[1]}</Td2>
                      </tr>
                      <tr>
                        <Td1>
                          <Img src={deliveryBox} />
                        </Td1>
                        <Td2>
                          <b>
                            {shipmentObject.provider_name}{" "}
                            {shipmentObject.service_level}
                            {
                              // shipment.rate.extended_zone ? ': Zona extendida' : ''
                            }
                            {shipment.insurance_cost ? " + Seguro" : ""}
                          </b>
                        </Td2>
                        <Td3 />
                        <Td4>
                          <p style={{ textAlign: "right" }}>
                            ${parseFloat(total).toFixed(2)}{" "}
                          </p>
                        </Td4>
                      </tr>
                    </tbody>
                  </table>
                )}
              </ReactCSSTransitionGroup>
            </Order>
          );
        }
      );

      return (
        <ReactCSSTransitionGroup
          transitionName="deleteOrder"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {orders}
        </ReactCSSTransitionGroup>
      );
    } catch (e) {
      this.props.goToDashboard();
    }
  };

  renderShipmentsSmall = () => {
    try {
      const orders = this.props.shipmentsObject.shipments.map(
        (shipment, index) => {
          const shipmentObject = this.props.shipments.purchase.filter(
            (s) => Number(s.id) === Number(shipment.id)
          )[0];
          shipmentObject.from = shipmentObject.from_street.split("|");
          shipmentObject.to = shipmentObject.to_street.split("|");
          const total = shipment.subtotal + shipment.insurance_cost;
          return (
            <Order
              className="order"
              key={shipment.id}
              collapsed={
                !this.state.collapsedShipmentsIndexes.includes(shipment.id)
              }
            >
              <ReactCSSTransitionGroup
                transitionName="collapsible"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={1}
              >
                {this.state.collapsedShipmentsIndexes.includes(shipment.id) ? (
                  <Text className="textTable" key={`${shipment.id}1`}>
                    <table style={{ width: "100%", tableLayout: "fixed" }}>
                      <tr>
                        <Td1 style={{ verticalAlign: "top" }}>Remitente</Td1>
                        <Td2 style={{ verticalAlign: "bottom" }}>
                          {" "}
                          {shipmentObject.from_name}{" "}
                        </Td2>
                        <Td3 style={{ verticalAlign: "bottom" }}>
                          <I
                            className="ion-ios-arrow-up"
                            onClick={() =>
                              this.handleCollapseShipment(shipment.id)
                            }
                          />
                        </Td3>
                      </tr>
                      <tr>
                        <Td1 style={{ verticalAlign: "top" }}>Origen</Td1>
                        <Td2 style={{ verticalAlign: "top" }}>
                          {shipmentObject.from[0]}
                          {shipmentObject.from[1]}
                        </Td2>
                      </tr>
                      <tr>
                        <Td1 style={{ verticalAlign: "top" }}>Destinatario</Td1>
                        <Td2> {shipmentObject.to_name} </Td2>
                      </tr>
                      <tr>
                        <Td1 style={{ verticalAlign: "top" }}>Destino</Td1>
                        <Td2>
                          {shipmentObject.to[0]}
                          {shipmentObject.to[1]}
                        </Td2>
                      </tr>
                      <tr>
                        <Td1 style={{ verticalAlign: "bottom" }}>Servicio</Td1>
                        <Td2 style={{ verticalAlign: "bottom" }}>
                          <b>
                            {shipmentObject.provider_name}{" "}
                            {shipmentObject.service_level}
                          </b>
                        </Td2>
                        <Td3
                          style={{
                            paddingRight: "32px",
                            verticalAlign: "bottom",
                          }}
                        >
                          <p style={{ textAlign: "right" }}>
                            {`$${numberWithCommas(
                              parseFloat(shipment.subtotal).toFixed(2)
                            )} ${this.props.shipmentsObject.currency || ""}`}
                          </p>
                        </Td3>
                      </tr>
                      {shipment.insurance_cost && (
                        <tr>
                          <Td1>
                            <b> Seguro</b>
                          </Td1>
                          <Td2 />
                          <Td3 style={{ paddingRight: "32px" }}>
                            <p style={{ textAlign: "right" }}>
                              {`$${numberWithCommas(
                                parseFloat(shipment.insurance_cost).toFixed(2)
                              )} ${this.props.shipmentsObject.currency || ""}`}
                            </p>
                          </Td3>
                        </tr>
                      )}
                    </table>
                  </Text>
                ) : (
                  <Text className="textTable" key={`${shipment.id}2`}>
                    <table style={{ width: "100%", tableLayout: "fixed" }}>
                      <tr>
                        <Td1 style={{ verticalAlign: "top" }}>Origen</Td1>
                        <Td2 style={{ verticalAlign: "bottom" }}>
                          {" "}
                          {shorten(shipmentObject.to_name, 11)},{" "}
                          {shorten(shipmentObject.to[0], 19)}
                        </Td2>
                        <Td3 style={{ verticalAlign: "bottom" }}>
                          <I
                            className="ion-ios-arrow-down"
                            style={{ verticalAlign: "top" }}
                            onClick={() =>
                              this.handleCollapseShipment(shipment.id)
                            }
                          />
                        </Td3>
                      </tr>
                      <tr>
                        <Td1 />
                        <Td2>{shipmentObject.to[1]}</Td2>
                      </tr>
                      <tr>
                        <Td1 style={{ verticalAlign: "bottom" }}>Servicio</Td1>
                        <Td2>
                          <b>
                            {shipmentObject.provider_name}{" "}
                            {shipmentObject.service_level}
                            {
                              // shipment.rate.extended_zone ? ': Zona extendida' : ''
                            }
                            {shipment.insurance_cost ? " + Seguro" : ""}
                          </b>
                        </Td2>
                        <Td3>
                          <p style={{ textAlign: "right" }}>
                            ${parseFloat(total).toFixed(2)}{" "}
                          </p>
                        </Td3>
                      </tr>
                    </table>
                  </Text>
                )}
              </ReactCSSTransitionGroup>
            </Order>
          );
        }
      );

      return (
        <ReactCSSTransitionGroup
          transitionName="deleteOrder"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {orders}
        </ReactCSSTransitionGroup>
      );
    } catch (e) {
      this.props.goToDashboard();
    }
  };

  handleSelectedPaymentTypeChange = (selectedPaymentType) => {
    // this.props.getUser();
    this.setState({ selectedPaymentType });
  };

  handleCardChange = (index) => {
    this.setState({ selected: index });
  };

  payment = (data, actions) =>
    actions.payment.create({
      transactions: [
        {
          amount: {
            total: parseFloat(this.getTotalPayment()).toFixed(2),
            currency: "MXN",
          },
        },
      ],
    });

  onAuthorize = (data, actions) => {
    const shipments = this.props.shipmentsObject.shipments.map((el) => el.id);
    actions.payment.execute().then((paymentData) => {
      const model =
        this.props.isOverweight || this.props.overweightOnly
          ? {
              shipments: !this.props.overweightOnly ? shipments : null,
              source_type: "web_portal",
              charge_type: this.props.overweightOnly
                ? "OVERWEIGHT"
                : "SHIPMENTS_AND_OVERWEIGHT",
              payment: {
                provider: "paypal",
                id: paymentData.id,
                redirect_urls: {
                  executed: `${window.location}/?success=true`,
                  canceled: `${window.location}/?close=true`,
                },
              },
              overweight_payment: parseFloat(
                this._getOverweightToPay()
              ).toFixed(2),
            }
          : {
              shipments,
              source_type: "web_portal",
              payment: {
                provider: "paypal",
                id: paymentData.id,
                redirect_urls: {
                  executed: `${window.location}/?success=true`,
                  canceled: `${window.location}/?close=true`,
                },
              },
              overweight_payment: parseFloat(
                this._getOverweightToPay()
              ).toFixed(2),
            };
      axios
        .post("/api/purchases", model)
        .then(async (response) => {
          await this.props.loadInvoice(response.data);
          this.props.showMessage("success", "Pago realizado exitosamente");
          this.props.goBack();
        })
        .catch((err) => {});
    });
  };

  preselectPayment = () => {
    const cookies = new Cookies();

    if (
      cookies.get("last_payment") &&
      this.state.selectedPaymentType === null
    ) {
      if (cookies.get("last_payment") <= 2) {
        this.handleSelectedPaymentTypeChange(cookies.get("last_payment"));
      }
    }
  };

  saveCard() {
    const expirationDate = this.state.expiration.split("/");
    const card = {
      object: "card",
      exp_month: expirationDate[0],
      exp_year: expirationDate[1],
      number: this.state.number,
      name: this.state.name,
      cvc: this.state.cvc,
    };
    return this.props.createCard(card, "card", true);
  }

  isCardValid() {
    const errors = {
      required: "Este campo es requerido",
    };
    const nameError = this.state.name === "" ? errors.required : "";
    const number = valid.number(this.state.number);
    const numberError =
      !number.isValid || number.card.isAmex
        ? this.state.number === ""
          ? errors.required
          : "Número de tarjeta inválido"
        : "";
    const expiration = valid.expirationDate(this.state.expiration);
    const expirationError =
      !expiration.isValid ||
      this.state.expiration.length !== 5 ||
      this.state.expiration.indexOf("/") !== 2
        ? this.state.expiration === ""
          ? errors.required
          : "Fecha de vencimiento inválida"
        : "";
    const cvc = valid.cvv(this.state.cvc);
    const cvcError = !cvc.isValid
      ? this.state.cvc === ""
        ? errors.required
        : "CVC inválido"
      : "";
    if (nameError || numberError || cvcError || expirationError) {
      this.setState({
        nameError,
        numberError,
        cvcError,
        expirationError,
        loadingBtn: false,
      });
      return false;
    }
    return true;
  }

  pay() {
    const cookies = new Cookies();
    this.setState({ loadingBtn: true });

    const shipments = this.props.shipmentsObject.shipments
      ? this.props.shipmentsObject.shipments.map((el) => el.id)
      : null;

    if (this.state.selectedPaymentType === 0 && this.state.savedCard === 0) {
      cookies.set("last_payment", "0", { path: "/" });
      cookies.set("last_card", this.state.selected, { path: "/" });
      /* Tarjeta ya guardada */
      const model =
        this.props.isOverweight || this.props.overweightOnly
          ? {
              shipments: !this.props.overweightOnly ? shipments : null,
              source_type: "web_portal",
              charge_type: this.props.overweightOnly
                ? "OVERWEIGHT"
                : "SHIPMENTS_AND_OVERWEIGHT",
              payment: {
                provider: "stripe",
                card_id: this.state.selected,
              },
              overweight_payment: parseFloat(
                this._getOverweightToPay()
              ).toFixed(2),
            }
          : {
              shipments,
              source_type: "web_portal",
              payment: {
                provider: "stripe",
                card_id: this.state.selected,
              },
              overweight_payment: parseFloat(
                this._getOverweightToPay()
              ).toFixed(2),
            };
      axios
        .post("/api/purchases", model)
        .then(async (response) => {
          await this.props.loadInvoice(response.data);
          this.props.showMessage("success", "Pago realizado exitosamente");

          if (process.env.NODE_ENV === "production") {
            sendFBEvent("Purchase");
            this.gtag_report_conversion(response.data.purchase.payment_id);
          }

          this.setState({ loadingBtn: false });
          this.props.goBack();
        })
        .catch((err) => {
          this.setState({ loadingBtn: false });
          this.translateErrors(err.response.data.errors);
        });
    } else if (this.state.selectedPaymentType === 0) {
      cookies.set("last_payment", "0", { path: "/" });
      if (this.isCardValid()) {
        if (this.state.saveAsNewCard === 1) {
          this.saveCard();
        }
        const expirationDate = this.state.expiration.split("/");
        const card = {
          object: "card",
          exp_month: expirationDate[0],
          exp_year: expirationDate[1],
          number: this.state.number,
          name: this.state.name,
          cvc: this.state.cvc,
        };
        return Scriptly.loadJavascript("https://js.stripe.com/v2/").then(() => {
          window.Stripe.setPublishableKey(STRIPE_PUBLIC);
          window.Stripe.card.createToken(card, (status, response) => {
            const token = response.id;
            const model =
              this.props.isOverweight || this.props.overweightOnly
                ? {
                    shipments: !this.props.overweightOnly ? shipments : null,
                    source_type: "web_portal",
                    charge_type: this.props.overweightOnly
                      ? "OVERWEIGHT"
                      : "SHIPMENTS_AND_OVERWEIGHT",
                    payment: {
                      provider: "stripe",
                      token,
                    },
                    overweight_payment: parseFloat(
                      this._getOverweightToPay()
                    ).toFixed(2),
                  }
                : {
                    shipments,
                    source_type: "web_portal",
                    payment: {
                      provider: "stripe",
                      token,
                    },
                    overweight_payment: parseFloat(
                      this._getOverweightToPay()
                    ).toFixed(2),
                  };

            axios
              .post("/api/purchases", model)
              .then(async (response) => {
                if (process.env.NODE_ENV === "production") {
                  sendFBEvent("Purchase");
                  this.gtag_report_conversion(
                    response.data.purchase.payment_id
                  );
                }
                await this.props.loadInvoice(response.data);
                this.props.showMessage(
                  "success",
                  "Pago realizado exitosamente"
                );
                this.setState({ loadingBtn: false });
                this.props.goBack();
              })
              .catch((err) => {
                this.setState({ loadingBtn: false });
                this.props.showError(err.response.data.errors);
              });
          });
        });
      }
    } else if (
      this.state.selectedPaymentType === 2 ||
      this.props.user.customer_type === "subsided_customer"
    ) {
      cookies.set("last_payment", "2", { path: "/" });
      /* Pago con wallet */
      const model =
        this.props.isOverweight || this.props.overweightOnly
          ? {
              charge_type: this.props.overweightOnly
                ? "OVERWEIGHT"
                : "SHIPMENTS_AND_OVERWEIGHT",
              shipments: !this.props.overweightOnly ? shipments : null,
              overweight_payment: parseFloat(
                this._getOverweightToPay()
              ).toFixed(2),
              source_type: "web_portal",
              payment: {
                provider: "wallet",
              },
            }
          : {
              shipments,
              overweight_payment: parseFloat(
                this._getOverweightToPay()
              ).toFixed(2),
              source_type: "web_portal",
              payment: {
                provider: "wallet",
              },
            };
      axios
        .post("/api/purchases", model)
        .then(async (response) => {
          if (process.env.NODE_ENV === "production") {
            sendFBEvent("Purchase");
            this.gtag_report_conversion(response.data.purchase.payment_id);
          }
          await this.props.loadInvoice(response.data);
          this.props.showMessage("success", "Pago realizado exitosamente");
          this.setState({ loadingBtn: false });
          this.props.goBack();
        })
        .catch((err) => {
          this.setState({ loadingBtn: false });
          this.props.showError(err.response.data.errors);
        });
    } else {
      /* Paypal */
      cookies.set("last_payment", "1", { path: "/" });
    }
  }

  translateErrors = (m) => {
    switch (m) {
      case "Your card was declined. This transaction requires authentication.":
        return this.props.showError(
          "Transacción rechazada. Comunicate con tu banco para revisar el caso"
        );
      default:
        return this.props.showError(
          "Error al procesar la compra. Revisa los datos de la tarjeta y si cuentas con fondos suficientes."
        );
    }
  };

  wrapperPay = async () => {
    // return this.props.showError("Error de prueba");
    if (
      this.props.shipmentsObject.charges &&
      this.props.shipmentsObject.charges.overweight
    ) {
      if (this.state.overweightPayment == "custom") {
        const minimium = this._calculateMinimium();
        await this.setStateAsync({ shakeClass: "" });
        if (
          this.state.totalOverweight < minimium ||
          this.state.totalOverweight >
            this.props.shipmentsObject.charges.overweight.total
        ) {
          await this.setStateAsync({
            shakeClass: "animated shake",
            toolTipOpen: true,
          });
        } else this.pay();
      } else this.pay();
    } else {
      if (
        this.state.selectedPaymentType === 2 ||
        this.props.user.customer_type === "subsided_customer"
      ) {
        this.setState({ loadingBtn: true });
        this.props.getProfileFinance().then(() => {
          this.pay();
        });
      } else {
        this.pay();
      }
    }
  };

  _closeToolTip = () => {
    setTimeout(() => {
      this.setState({ toolTipOpen: false });
    }, 3000);
  };

  renderPayButton() {
    return (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {this.state.loadingBtn ? (
          <div style={{ textAlign: "center", verticalAlign: "middle" }}>
            <Text className="helpTextModal" style={{ marginTop: "25px" }}>
              Procesando pago
            </Text>
            <Loader type="ball-pulse" color={colors.primary.mienvio_pink} />
          </div>
        ) : (
          <div>
            <a data-tip data-for="payment_button_message">
              <Button
                className={this.state.shakeClass}
                style={{
                  float: "right",
                  minHeight: "80px",
                  minWidth: "200px",
                }}
                onClick={() => this.wrapperPay()}
              >
                {`Pagar $${numberWithCommas(
                  parseFloat(this.getTotalPayment()).toFixed(2)
                )} ${this.props.shipmentsObject.currency || ""}`}
              </Button>
            </a>
            {this.state.totalOverweightError.length > 0 && (
              <ReactTooltip id="payment_button_message">
                <span> {this.state.totalOverweightError} </span>
              </ReactTooltip>
            )}
          </div>
        )}
      </div>
    );
  }

  renderPayButtonSmall() {
    return (
      <div>
        {this.state.loadingBtn ? (
          <div style={{ textAlign: "center", verticalAlign: "middle" }}>
            <Text className="helpTextModal" style={{ marginTop: "25px" }}>
              Procesando pago
            </Text>
            <Loader type="ball-pulse" color={colors.primary.mienvio_pink} />
          </div>
        ) : (
          <div>
            <a data-tip data-for="payment_button_message">
              <Button
                className={this.state.shakeClass}
                style={{
                  margin: "10px 5px 10px",
                  float: "right",
                  minHeight: "40px",
                }}
                onClick={() => this.wrapperPay()}
              >
                {`Pagar $${numberWithCommas(
                  parseFloat(this.getTotalPayment()).toFixed(2)
                )} ${this.props.shipmentsObject.currency || ""}`}
              </Button>
            </a>
            {this.state.totalOverweightError.length > 0 && (
              <ReactTooltip id="payment_button_message">
                <span> {this.state.totalOverweightError} </span>
              </ReactTooltip>
            )}
          </div>
        )}
      </div>
    );
  }

  renderDisabledButton = (tooltipMessage, custom = false) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: custom ? "0px" : "80px",
      }}
    >
      <a data-tip data-for="disabled_button_message">
        <Button
          style={{
            margin: custom ? "auto" : "20px 42px 20px",
            float: "right",
            minHeight: "80px",
            minWidth: "200px",
          }}
          type="disabled_table"
        >
          {`Pagar $${numberWithCommas(
            parseFloat(this.getTotalPayment()).toFixed(2)
          )} ${this.props.shipmentsObject.currency || ""}`}
        </Button>
      </a>
      <ReactTooltip id="disabled_button_message">
        <span> {tooltipMessage} </span>
      </ReactTooltip>
    </div>
  );

  renderDisabledButtonSmall = (tooltipMessage, custom) => (
    <div>
      <a data-tip data-for="disabled_button_message">
        <Button
          style={{
            float: "right",
            minWidth: "230px%",
            minHeight: "50px",
          }}
          type="disabled_table"
        >
          {`Pagar $${numberWithCommas(
            parseFloat(this.getTotalPayment()).toFixed(2)
          )} ${this.props.shipmentsObject.currency || ""}`}
        </Button>
      </a>
      <ReactTooltip id="disabled_button_message">
        <span> {tooltipMessage} </span>
      </ReactTooltip>
    </div>
  );

  renderWalletMessage = () => (
    <div>
      <div style={{ padding: "0% 42px 14px 42px" }}>
        <div className={"saldo " + styles.balanceContainer}>
          <h1 className={styles.title}>Saldo actual</h1>
          <Text
            className={"saldo-title right total " + styles.balance}
            type="sidebarInfoTitle"
          >
            {this.props.user && this.props.user.wallet_balance && (
              <>
                $ {parseFloat(this.props.user.wallet_balance ?? 0).toFixed(2)}
              </>
            )}
          </Text>
        </div>
        <Text className={styles.walletSubtitle}>
          Monto mínimo de recarga $100.00 MXN
        </Text>
        <br />
        <Text className={styles.walletTitle}>¿Cómo recargar mi Wallet?</Text>
        <br />
        <Text className={styles.walletText}>
          Mienvío Wallet es nuestro monedero electrónico que te permite comprar
          tus envíos de una manera fácil y con tu información segura.
          <br />
          <br />
          <h1
            className={styles.title}
            style={{ marginBottom: 0, textAlign: "Left" }}
          >
            Realiza una transferencia bancaria a tu cuenta clabe asignada.
          </h1>
          Nombre del beneficiario: MIENVIO DE MEXICO S.A.P.I. DE C.V.
          <br />
          Banco: STP
          <br />
          CLABE: {this.props.user && this.props.user.stp_clabe}
          <br />
          *Tu CLABE es única y solamente podrás usarla para recargar saldo a tu
          Wallet Mienvío.
          <br />
          *Si tienes más de un usuario en Mienvío, considera que esta clabe es
          intransferible.
          <br />
          <br />
          Tu saldo se reflejará en tu cuenta de inmediato. Si realizaste una
          recarga y el monto no se ve reflejado en tu cuenta después de 1 hora,
          escribe a nuestro equipo de soporte para abrir tu{" "}
          <a
            className={styles.walletLink}
            href="https://api.whatsapp.com/send?phone=+5214423783099&text=Hola,%20quiero%20levantar%20mi%20ticket%20de%20aclaraci%C3%B3n%20de%20recarga%20Wallet"
            target="_blank"
          >
            ticket de aclaración.
          </a>{" "}
          &nbsp;
          <br />
          <br />
          Contáctanos si tienes dudas o problemas al generar una recarga de
          saldo.
        </Text>
      </div>
      <TermsNConditions
        checked={this.state.accept_tnc}
        onChange={this.handleAcceptTnc}
      />
    </div>
  );

  renderWalletMessageSmall = () => (
    <div>
      <div style={{ padding: "0% 12px 14px 12px" }}>
        <div className="saldo">
          <Text className="saldo-title" type="sidebarInfoTitle">
            Saldo actual
          </Text>
          <Text className="saldo-title right total" type="sidebarInfoTitle">
            ${parseFloat(this.props.user.wallet_balance).toFixed(2)}
          </Text>
        </div>
        <WalletMessage
          user={this.props.user ?? {}}
          open={this.state.walletDropdown}
          onClick={() => this.toggleWalletDropdown(!this.state.walletDropdown)}
        />
      </div>
    </div>
  );

  renderCardFields() {
    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value: this.state[name],
        error: this.state[`${name}Error`],
      }),
    };
    return (
      <div style={{ padding: "0% 42px 0% 42px" }}>
        <Row>
          <Column xs={12} fluid>
            <CheckboxGroup
              type="table"
              onChange={(selected) => this.handleSaveAsNewCardChange(selected)}
            >
              <Checkbox
                styleText={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#697d91",
                }}
                style={{ marginTop: "0px" }}
                checkboxClass="no-margin-left"
                value="1"
                text="Guardar tarjeta"
              />
            </CheckboxGroup>
          </Column>
          <Column xs={12} md={6} fluid>
            <Input
              type="text"
              {...mainProps.generate("number")}
              required
              placeholder="1234 4567 7891 1234"
              label="Número de tarjeta"
            />
          </Column>
          <Column xs={6} md={3} fluid style={{ paddingLeft: "14px" }}>
            <Input
              type="text"
              {...mainProps.generate("expiration")}
              required
              placeholder="09/18"
              label="Expiración"
            />
          </Column>
          <Column xs={6} md={3} fluid style={{ paddingLeft: "24px" }}>
            <Input
              type="password"
              name="cvc"
              {...mainProps.generate("cvc")}
              required
              placeholder="000"
              label="CVC"
              autoComplete="new-password"
            />
          </Column>

          <Column xs={12} style={{ marginBottom: "12px" }} fluid>
            <Input
              type="text"
              {...mainProps.generate("name")}
              required
              placeholder="Escribe el nombre como aparece"
              label="Titular de la tarjeta"
            />
          </Column>
        </Row>
      </div>
    );
  }

  renderCardFieldsSmall() {
    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value: this.state[name],
        error: this.state[`${name}Error`],
      }),
    };
    return (
      <div style={{ padding: "0% 12px 0% 12px" }}>
        <Row>
          <Column xs={12} fluid>
            <CheckboxGroup
              type="table"
              onChange={(selected) => this.handleSaveAsNewCardChange(selected)}
            >
              <Checkbox
                styleText={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#697d91",
                }}
                style={{ marginTop: "0px" }}
                checkboxClass="no-margin-left"
                value="1"
                text="Guardar tarjeta"
              />
            </CheckboxGroup>
          </Column>
          <Column xs={12} md={6} fluid>
            <Input
              type="text"
              {...mainProps.generate("number")}
              required
              placeholder="1234 4567 7891 1234"
              label="Número de tarjeta"
            />
          </Column>
          <Column xs={6} md={3} fluid>
            <Input
              type="text"
              {...mainProps.generate("expiration")}
              required
              placeholder="09/18"
              label="Expiración"
            />
          </Column>
          <Column xs={6} md={3} fluid style={{ paddingLeft: "24px" }}>
            <Input
              type="password"
              name="cvc"
              {...mainProps.generate("cvc")}
              required
              placeholder="000"
              label="CVC"
              autoComplete="new-password"
            />
          </Column>

          <Column xs={12} style={{ marginBottom: "12px" }} fluid>
            <Input
              type="text"
              {...mainProps.generate("name")}
              required
              placeholder="Escribe el nombre como aparece"
              label="Titular de la tarjeta"
            />
          </Column>
        </Row>
      </div>
    );
  }

  handleChange = (value, name) => {
    switch (name) {
      case "name":
        this.setState({
          nameError: value === "" ? "Este campo es requerido" : "",
        });
        break;
      case "number":
        if (value.length > 16) return;
        const number = valid.number(value);
        const numberError = !number.isPotentiallyValid
          ? value === ""
            ? "Este campo es requerido"
            : "Número de tarjeta inválido"
          : number.card && number.card.isAmex
          ? "Por el momento no soportamos Amex, intente otra tarjeta"
          : "";
        this.setState({
          newCard: {
            ...this.state.newCard,
            numberError,
          },
        });
        break;
      case "expiration": {
        const expiration = valid.expirationDate(this.state.expiration);
        let val = value.replace("/", "");
        if (!isNaN(val) && val.length <= 4) {
          if (val.length >= 2) {
            val = val.replace(/(\d{2})/, "$1/");
            if (this.state.expiration === val) {
              val = val.replace("/", "");
            }
          }
          value = val;
        }
        const expirationError =
          !expiration.isPotentiallyValid ||
          value.length !== 5 ||
          value.indexOf("/") !== 2
            ? value === ""
              ? "Este campo es requerido"
              : "Fecha de vencimiento inválida"
            : "";
        this.setState({ expirationError });
        break;
      }
      case "cvc": {
        const cvc = valid.cvv(value);
        const cvcError = !cvc.isValid
          ? value === ""
            ? "Este campo es requerido"
            : "CVC inválido"
          : "";
        this.setState({ cvcError });
        break;
      }
      default:
        break;
    }
    if (
      (name !== "name" && name !== "expiration" && !isNaN(value)) ||
      name === "name" ||
      name === "expiration"
    ) {
      this.setState({ [name]: value });
    }
  };

  _handleRemoveOverweights = () => {
    this.props.removeOverweight();
  };

  _renderDiscountRow = () => {
    if (
      typeof this.props.shipmentsObject.discount === "number" &&
      this.props.shipmentsObject.discount !== 0
    ) {
      return (
        <Row style={{ marginBottom: "16px" }}>
          <Column xs={9} fluid>
            <Img src={priceTag} />
            <Text className="textTable">Descuentos</Text>
          </Column>
          <Column xs={3} fluid style={{ textAlign: "right" }}>
            <Text className="textTable" style={{ textAlign: "right" }}>
              ${parseFloat(this.props.shipmentsObject.discount).toFixed(2)}
            </Text>
          </Column>
        </Row>
      );
    }
    if (
      this.props.shipmentsObject.coupons &&
      !this.props.isOverweight &&
      !this.props.overweightOnly
    ) {
      return (
        <Row style={{ marginBottom: "16px" }}>
          <Column xs={6} fluid>
            <div data-tip data-for="no-coupons-overweight">
              <Img src={priceTag} />
              <Text className="textTable">Descuentos</Text>
            </div>
            <ReactTooltip id="no-coupons-overweight" place="top">
              <span>
                Al liquidar tus sobrepesos, tu descuento se reactivara
              </span>
            </ReactTooltip>
          </Column>
          <Column xs={6} fluid style={{ textAlign: "right" }}>
            <Text className="textTable" style={{ textAlign: "right" }}>
              Inactivo por sobrepesos
            </Text>
          </Column>
        </Row>
      );
    }
  };

  ChangeOverWeightType = (value) => {
    this.setState({
      overweightPayment: value,
      totalOverweight:
        value == "custom"
          ? parseFloat(
              this.props.shipmentsObject.charges.overweight.total
            ).toFixed(2)
          : 0,
      totalOverweightError: "",
    });
  };

  _calculateMinimium = () =>
    this.props.shipmentsObject.charges.overweight.total < 100
      ? this.props.shipmentsObject.charges.overweight.total
      : 100;

  _calculateMax = (amount) =>
    amount > this.props.shipmentsObject.charges.overweight.total
      ? this.props.shipmentsObject.charges.overweight.total
      : amount;

  onChangeOverweightAmount = (value) => {
    const minimium = this._calculateMinimium();
    this.setState({
      totalOverweightError:
        value < minimium
          ? `El monto mínimo es ${parseFloat(minimium).toFixed(2)} MN`
          : value >
            parseFloat(
              this.props.shipmentsObject.charges.overweight.total
            ).toFixed(2)
          ? "Monto mayor al total"
          : "",
      totalOverweight: value,
    });
  };

  getTotalPayment = () => {
    if (
      this.props.shipmentsObject.charges &&
      this.props.shipmentsObject.charges.overweight &&
      this.props.shipmentsObject.shipments
    ) {
      return this.state.overweightPayment == "limit"
        ? +this.props.shipmentsObject.total
        : +(
            this.props.shipmentsObject.total -
            this._calculateMax(this.props.shipmentsObject.overweight_payment)
          ) + +this.state.totalOverweight;
    }

    if (this.props.shipmentsObject.shipments)
      return this.props.shipmentsObject.total;
    if (this.props.shipmentsObject.charges.overweight) {
      return this.state.overweightPayment == "custom"
        ? this.state.totalOverweight
        : this.props.shipmentsObject.total;
    }
  };

  _getOverweightToPay = () =>
    this.props.shipmentsObject.charges &&
    this.props.shipmentsObject &&
    this.props.shipmentsObject.charges.overweight
      ? this.state.overweightPayment == "limit"
        ? +this.props.shipmentsObject.overweight_payment
        : +this.state.totalOverweight
      : 0;

  renderPaypalButton = () => {
    const { client, env, commit, overweightPayment, totalOverweight } =
      this.state;
    const { shipmentsObject } = this.props;
    const btn = (
      <PaypalBtn
        style={{
          label: "pay",
          size: "large",
          color: "blue",
          shape: "rect",
          height: 55,
        }}
        onSuccess={(payment) => {
          let shipments;
          if (!this.props.overweightOnly) {
            shipments = this.props.shipmentsObject.shipments.map((el) => el.id);
          }
          const model = this.props.overweightOnly
            ? {
                shipments: !this.props.overweightOnly ? shipments : null,
                source_type: "web_portal",
                charge_type: this.props.overweightOnly
                  ? "OVERWEIGHT"
                  : "SHIPMENTS_AND_OVERWEIGHT",
                payment: {
                  provider: "paypal",
                  id: payment.paymentID,
                  redirect_urls: {
                    executed: `${window.location}/?success=true`,
                    canceled: `${window.location}/?close=true`,
                  },
                },
                overweight_payment: parseFloat(
                  this._getOverweightToPay()
                ).toFixed(2),
              }
            : {
                shipments,
                source_type: "web_portal",
                payment: {
                  provider: "paypal",
                  id: payment.paymentID,
                  redirect_urls: {
                    executed: `${window.location}/?success=true`,
                    canceled: `${window.location}/?close=true`,
                  },
                },
                overweight_payment: parseFloat(
                  this._getOverweightToPay()
                ).toFixed(2),
              };
          axios
            .post("/api/purchases", model)
            .then(async (response) => {
              await this.props.loadInvoice(response.data);
              this.props.showMessage("success", "Pago realizado exitosamente");
              this.props.goBack();
            })
            .catch((err) => {});
        }}
        env={env}
        client={this.state.client}
        currency={"MXN"}
        total={parseFloat(this.getTotalPayment()).toFixed(2)}
      />
    );
    if (!shipmentsObject.charges) return btn;
    if (!shipmentsObject.charges.overweight) return btn;
    const minimium = this._calculateMinimium();
    if (overweightPayment == "limit") return btn;
    if (parseFloat(totalOverweight) < minimium) {
      return this.renderDisabledButton(
        `El monto minimo es ${parseFloat(minimium).toFixed(2)} MN`,
        true
      );
    }
    if (
      parseFloat(totalOverweight) >
      shipmentsObject.charges.overweight.total.toFixed(2)
    ) {
      return this.renderDisabledButton(
        "El a pagar es mayor que el total",
        true
      );
    }
    return btn;
  };

  handleAcceptTnc = () => {
    this.setState({
      ...this.state,
      accept_tnc: !this.state.accept_tnc,
    });
  };

  checkOverweightUser() {
    axios
      .get("api/profile/has-over-weights")
      .then((response) => {
        this.setState({
          canBuy: response.data.can_buy,
        });
      })
      .catch((error) => {});
  }

  canPay() {
    if (!this.props.overweightOnly) {
      return this.state.canBuy;
    }
    return true;
  }

  renderPayOldCard(small) {
    const { selected, accept_tnc } = this.state;
    const { user } = this.props;
    if (selected) {
      if (this.canPay()) {
        if (user.fiscal_address) {
          if (accept_tnc) {
            if (small) {
              return this.renderPayButtonSmall();
            }
            return this.renderPayButton();
          }
          if (small) {
            return this.renderDisabledButtonSmall(tncError);
          }
          return this.renderDisabledButton(tncError);
        }
        if (small) {
          return this.renderDisabledButtonSmall(
            "Completa tu información fiscal"
          );
        }
        return this.renderDisabledButton("Completa tu información fiscal");
      }
      if (small) {
        return this.renderDisabledButtonSmall("Realiza tu pago de sobrepeso");
      }
      return this.renderDisabledButton("Realiza tu pago de sobrepeso");
    }
    if (small) {
      return this.renderDisabledButtonSmall(
        "Selecciona una de las tarjetas registradas"
      );
    }
    return this.renderDisabledButton(
      "Selecciona una de las tarjetas registradas"
    );
  }

  rendePayNewCard(small) {
    const { number, name, cvc, expiration, accept_tnc } = this.state;
    const { user } = this.props;
    if (number && name && cvc && expiration && user) {
      if (this.canPay()) {
        if (user.fiscal_address) {
          if (accept_tnc) {
            if (small) {
              return this.renderPayButtonSmall();
            }
            return this.renderPayButton();
          }
          if (small) {
            return this.renderDisabledButtonSmall(tncError);
          }
          return this.renderDisabledButton(tncError);
        }
        if (small) {
          return this.renderDisabledButtonSmall(
            "Completa tu información fiscal"
          );
        }
        return this.renderDisabledButton("Completa tu información fiscal");
      }
      if (small) {
        return this.renderDisabledButtonSmall("Realiza tu pago de sobrepeso");
      }
      return this.renderDisabledButton("Realiza tu pago de sobrepeso");
    }
    if (small) {
      return this.renderDisabledButtonSmall("Llena todos los campos");
    }
    return this.renderDisabledButton("Llena todos los campos");
  }

  rendePayWallet(small) {
    const { accept_tnc } = this.state;
    const { user } = this.props;
    if (this.canPay()) {
      if (this.props.user.wallet_balance >= this.getTotalPayment()) {
        if (user.fiscal_address) {
          if (accept_tnc) {
            if (small) {
              return this.renderPayButtonSmall();
            }
            return this.renderPayButton();
          }
          if (small) {
            return this.renderDisabledButtonSmall(tncError);
          }
          return this.renderDisabledButton(tncError);
        }
        if (small) {
          return this.renderDisabledButtonSmall(
            "Completa tu información fiscal"
          );
        }
        return this.renderDisabledButton("Completa tu información fiscal");
      }
      if (small) {
        return this.renderDisabledButtonSmall("Saldo insuficiente en wallet");
      }
      return this.renderDisabledButton("Saldo insuficiente en wallet");
    }
    if (small) {
      return this.renderDisabledButtonSmall("Realiza tu pago de sobrepeso");
    }
    return this.renderDisabledButton("Realiza tu pago de sobrepeso");
  }

  renderPayPaypal(small) {
    //const PayPalButton = paypal.Button.driver("react", { React, ReactDOM });
    const { accept_tnc } = this.state;
    const { user } = this.props;
    if (this.canPay()) {
      if (user.fiscal_address) {
        return this.renderPaypalButton();
        if (small) {
          return this.renderDisabledButtonSmall(tncError);
        }
        return this.renderDisabledButton(tncError);
      }
      if (small) {
        return this.renderDisabledButtonSmall("Completa tu información fiscal");
      }
      return this.renderDisabledButton("Completa tu información fiscal");
    }
    if (small) {
      return this.renderDisabledButtonSmall("Realiza tu pago de sobrepeso");
    }
    return this.renderDisabledButton("Realiza tu pago de sobrepeso");
  }

  // Renders the different payment methods available (credit, paypal and wallet)
  _renderPaymentMethods = (onlyWallet = false) => {
    let paymentTypes = {};
    if (this.props.user.methodPayments) {
      let hasOnlyWallet = true;
      this.props.user.methodPayments.map((item, i) => {
        if (item === "credit") {
          hasOnlyWallet = false;
          paymentTypes[0] = {
            id: 0,
            name: "Tarjeta de Crédito o Débito",
            icon: credit,
            iconActive: creditActive,
          };
        }
        if (item === "paypal") {
          hasOnlyWallet = false;
          paymentTypes[1] = {
            id: 1,
            name: "Paypal",
            icon: paypalIcon,
            iconActive: paypalActive,
          };
        }
        if (item === "wallet") {
          paymentTypes[2] = {
            id: 2,
            name: "Mienvío Wallet",
            icon: wallet,
            iconActive: walletActive,
          };
        }
      });
      onlyWallet = hasOnlyWallet;
    } else {
      onlyWallet = true;
    }
    if (onlyWallet) {
      paymentTypes = {
        2: {
          id: 2,
          name: "Mienvío Wallet",
          icon: wallet,
          iconActive: walletActive,
        },
      };
      if (this.state.selectedPaymentType != 2) {
        this.handleTabChange(2);
      }
    }
    return (
      <Row
        fluid="true"
        className={styles.paymentIconsContainer}
        style={onlyWallet ? { justifyContent: "center" } : {}}
      >
        {Object.keys(paymentTypes).map((payment) => {
          return (
            <Column
              xs={4}
              key={payment}
              className={
                styles.column +
                " " +
                (this.state.selectedPaymentType == payment ? styles.active : "")
              }
            >
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  className={styles.paymentRectangle}
                  onClick={() => this.handleTabChange(paymentTypes[payment].id)}
                >
                  <img
                    src={
                      this.state.selectedPaymentType == payment
                        ? paymentTypes[payment].iconActive
                        : paymentTypes[payment].icon
                    }
                    alt={payment.name}
                    className={styles.image}
                  />
                </Button>
                <Text className={styles.paymentName}>
                  {paymentTypes[payment].name}
                </Text>
              </div>
            </Column>
          );
        })}
      </Row>
    );
  };

  render() {
    const { isAccountDisabled } = this.props;
    const numberOfShipments = !this.props.overweightOnly
      ? this.props.shipmentsObject.shipments
        ? this.props.shipmentsObject.shipments.length
        : 0
      : 0;

    if (
      (!this.props.shipmentsObject.shipments ||
        this.props.shipmentsObject.shipments.length === 0) &&
      this.props.overweightOnly != true
    ) {
      this.props.goBack();
      return null;
    }

    let options = [];
    let optionsMobile = [];
    if (
      this.props.cards.results &&
      this.props.cards.results.length > 0 &&
      options.length === 0
    ) {
      options = this.props.cards.results.map((card, index) => (
        <CardOption
          key={index}
          style={{ margin: "0 42px" }}
          className={styles.cardOptions}
        >
          <Row
            divisions={24}
            key={card.object_id}
            className={
              `card-dropdown-option card-dropdown-option-checkout ${
                this.state.selected == card.object_id
                  ? "active-selected"
                  : "inactive-selected"
              }` +
              " " +
              styles.cardItem
            }
            onClick={() => this.handleCardChange(card.object_id)}
          >
            <Column xs={2} fluid className={styles.cardLogo}>
              {card.brand === "Visa" &&
              this.state.selected != card.object_id ? (
                <img
                  src={visaWhite}
                  width="30"
                  alt="Visa"
                  style={{ paddingLeft: "12px" }}
                />
              ) : card.brand === "Visa" &&
                this.state.selected == card.object_id ? (
                <img
                  src={visa}
                  width="30"
                  alt="Visa"
                  style={{
                    paddingLeft: "12px",
                    marginBottom: "4px",
                    marginTop: "4px",
                  }}
                />
              ) : (
                <img
                  src={mastercard}
                  width="30"
                  alt="Mastercard"
                  style={{ paddingLeft: "12px" }}
                />
              )}
            </Column>
            <Column xs={9} fluid className={styles.cardName}>
              <Text
                style={{
                  fontSize: "12px",
                  paddingLeft: "24px",
                  fontWeight: "300",
                  color: `${
                    this.state.selected == card.object_id ? "white" : "black"
                  }`,
                  margin: 0,
                }}
              >
                {shorten(card.name, 15)}
              </Text>
            </Column>
            <Column xs={9} fluid className={styles.cardName}>
              <Text
                style={{
                  fontSize: "12px",
                  color: `${
                    this.state.selected == card.object_id ? "white" : "black"
                  }`,
                  margin: 0,
                }}
              >
                <span className="hide-for-small-only">•••• •••• ••••</span>{" "}
                {card.last4}
              </Text>
            </Column>
            <Column xs={4} fluid className={styles.cardName}>
              <Text
                style={{
                  fontSize: "12px",
                  color: `${
                    this.state.selected == card.object_id ? "white" : "black"
                  }`,
                  margin: 0,
                }}
              >
                {card.exp_month.toString().length === 2
                  ? card.exp_month
                  : `0${card.exp_month.toString()}`}
                /{card.exp_year.toString().substring(2, 4)}
              </Text>
              {this.state.selected == card.object_id && (
                <i
                  className="ion-checkmark"
                  style={{ marginLeft: "10px", color: "white" }}
                />
              )}
            </Column>
          </Row>
        </CardOption>
      ));
      optionsMobile = this.props.cards.results.map((card, index) => (
        <CardOption key={index} style={{ margin: "20px 12px", height: "36px" }}>
          <Row
            divisions={24}
            key={card.object_id}
            className={`card-dropdown-option card-dropdown-option-checkout-small ${
              this.state.selected == card.object_id
                ? "active-selected"
                : "inactive-selected"
            }`}
            onClick={() => this.handleCardChange(card.object_id)}
          >
            <Column xs={2} fluid>
              {card.brand === "Visa" &&
              this.state.selected != card.object_id ? (
                <img
                  src={visaWhite}
                  width="30"
                  alt="Visa"
                  style={{ paddingLeft: "12px" }}
                />
              ) : card.brand === "Visa" &&
                this.state.selected == card.object_id ? (
                <img
                  src={visa}
                  width="30"
                  alt="Visa"
                  style={{
                    paddingLeft: "12px",
                    marginBottom: "4px",
                    marginTop: "4px",
                  }}
                />
              ) : (
                <img
                  src={mastercard}
                  width="30"
                  alt="Mastercard"
                  style={{ paddingLeft: "12px" }}
                />
              )}
            </Column>
            <Column xs={9} fluid>
              <Text
                style={{
                  fontSize: "12px",
                  paddingLeft: "24px",
                  fontWeight: "300",
                  color: `${
                    this.state.selected == card.object_id ? "white" : "black"
                  }`,
                }}
              >
                {shorten(card.name, 15)}
              </Text>
            </Column>
            <Column xs={7} fluid>
              <Text
                style={{
                  fontSize: "12px",
                  color: `${
                    this.state.selected == card.object_id ? "white" : "black"
                  }`,
                }}
              >
                <span className="hide-for-small-only">•••• •••• ••••</span>{" "}
                {card.last4}
              </Text>
            </Column>
            <Column xs={6} fluid>
              <Text
                style={{
                  fontSize: "12px",
                  color: `${
                    this.state.selected == card.object_id ? "white" : "black"
                  }`,
                }}
              >
                {card.exp_month.toString().length === 2
                  ? card.exp_month
                  : `0${card.exp_month.toString()}`}
                /{card.exp_year.toString().substring(2, 4)}
              </Text>
              {this.state.selected == card.object_id && (
                <i
                  className="ion-checkmark"
                  style={{ height: "7px", marginLeft: "10px", color: "white" }}
                />
              )}
            </Column>
          </Row>
        </CardOption>
      ));
    }
    return (
      <div>
        <Row
          divisions={14}
          style={{
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            zIndex: 10000,
            top: "3rem",
            left: 0,
            position: "absolute",
          }}
        >
          <Column
            lg={8}
            m={14}
            s={14}
            xs={14}
            // className="hide-for-small-only"
          >
            {this.props.message.show && (
              <Message
                key={1}
                style={{
                  marginBottom: "0px",
                  marginTop: "24px",
                  width: "100%",
                }}
                color={this.props.message.messageType}
                onClose={this.props.hideMessage}
              >
                {this.props.message.text}
              </Message>
            )}
          </Column>
        </Row>
        <ModalResponsive
          classNames={{ modal: "modal-checkout" }}
          open={this.state.showModal}
          onClose={this.onCloseModal}
        >
          <div className="checkout-main-container">
            <Row style={{ marginBottom: "24px", paddingTop: "42px" }}>
              <Column xs={10} fluid>
                <Text className="titleCardSmall">Resumen de compra</Text>
              </Column>
              <Column xs={2} fluid>
                <Text
                  type="helpTextModal"
                  style={{ verticalAlign: "bottom", float: "right" }}
                >
                  {this.props.overweightOnly
                    ? "Sobrepesos"
                    : `${numberOfShipments} ${
                        numberOfShipments > 1 ? "guías" : "guía"
                      }`}
                </Text>
              </Column>
            </Row>
            {!this.props.overweightOnly && (
              <div>
                <Row style={{ marginBottom: "20px" }}>
                  <Column xs={10} fluid>
                    <Text className="label">Detalles de guía</Text>
                  </Column>
                  <Column xs={2} fluid>
                    <Text className="label" style={{ float: "right" }}>
                      Total
                    </Text>
                  </Column>
                </Row>

                <div className="checkout-render-shipments-container">
                  <div className="checkout-render-shipments">
                    {this.renderShipmentsSmall()}
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                boxShadow: "0 -5px 5px -5px rgba(0,0,0,0.10)",
                paddingTop: "16px",
              }}
            >
              {this.props.shipmentsObject.charges &&
                this.props.shipmentsObject.charges.overweight && (
                  <div>
                    <Row style={{ marginBottom: "16px" }}>
                      <Column xs={8} fluid>
                        <Text type="textTable" className="title-overweight">
                          {`Adeudo de sobrepesos: ${parseFloat(
                            this.props.shipmentsObject.charges.overweight.total
                          ).toFixed(2)} MXN`}
                        </Text>
                      </Column>
                    </Row>
                    <RadioGroup
                      selected={this.state.overweightPayment}
                      onChange={this.ChangeOverWeightType}
                    >
                      <Row style={{ marginBottom: "16px" }}>
                        <Column xs={9} fluid>
                          <Radio
                            type="form"
                            value="custom"
                            text="Abono personalizado"
                            containerClass="rate-radio-container"
                            className="insurance-radio"
                            secondaryText={`Monto minimo de abono $${parseFloat(
                              this._calculateMinimium()
                            ).toFixed(2)} MXN`}
                          />
                        </Column>
                        <Column xs={3} fluid style={{ textAlign: "right" }}>
                          <Input
                            type="number"
                            onChange={this.onChangeOverweightAmount}
                            value={this.state.totalOverweight}
                            error={this.state.totalOverweightError}
                            disabled={this.state.overweightPayment != "custom"}
                            placeholder="Total"
                            prefix="$"
                            style={{ margin: 0 }}
                            parentClassName="no-margin"
                            customInput
                          />
                        </Column>
                      </Row>
                      {this.props.shipmentsObject.shipments && (
                        <Row style={{ marginBottom: "16px" }}>
                          <Column xs={9} fluid>
                            <Radio
                              defaultChecked
                              type="form"
                              value="limit"
                              text="Abono automatico"
                              containerClass="rate-radio-container"
                              className="insurance-radio"
                              secondaryText="Un 10% extra sobre tu compra se abona a tu adeudo "
                            />
                          </Column>
                          <Column xs={3} fluid style={{ textAlign: "right" }}>
                            <Text
                              style={{ fontSize: "14px", textAlign: "right" }}
                            >
                              $
                              {parseFloat(
                                this._calculateMax(
                                  this.props.shipmentsObject.overweight_payment
                                )
                              ).toFixed(2)}
                            </Text>
                          </Column>
                        </Row>
                      )}
                    </RadioGroup>
                  </div>
                )}
              {this._renderDiscountRow()}
              <Row style={{ verticalAlign: "bottom", paddingTop: "14px" }}>
                <Column xs={9} fluid>
                  <Text className="label">Total</Text>
                </Column>
                <Column xs={3} fluid style={{ textAlign: "right" }}>
                  <Text
                    className="label"
                    style={{ fontSize: "14px", textAlign: "right" }}
                  >
                    ${parseFloat(this.getTotalPayment()).toFixed(2)}
                  </Text>
                </Column>
              </Row>
            </div>
          </div>
        </ModalResponsive>
        <Row divisions={20}>
          <Column fluid className={styles.checkoutContainer}>
            <Modal
              action={() => this.props.goBack()}
              actionText={this.props.titleBack ? this.props.titleBack : ""}
              classContent={"checkout-content " + styles.checkoutContent}
              className={styles.modal}
            >
              <BreakpointProvider
                breakpoints={{ lg: 1392 }}
                className="container-big-checkout"
              >
                <Row
                  divisions={14}
                  style={{ flexDirection: "column", alignItems: "center" }}
                >
                  <Column
                    lg={8}
                    m={14}
                    s={14}
                    xs={14}
                    className="hide-for-small-only"
                  >
                    <div className={styles.checkoutMainContainer}>
                      <Row
                        style={{
                          marginBottom: "35px",
                          paddingTop: "42px",
                          alignItems: "flex-end",
                        }}
                      >
                        <Column xs={10} fluid>
                          <Text
                            className="titleForm"
                            className={styles.checkoutTitleForm}
                          >
                            {this.props.overweightOnly
                              ? "Pago sobrepeso"
                              : "Resumen de compra"}
                          </Text>
                        </Column>
                        <Column xs={2} fluid>
                          <Text
                            type="helpTextModal"
                            style={{ verticalAlign: "bottom", float: "right" }}
                            className={styles.numberGuides}
                          >
                            {this.props.overweightOnly
                              ? "Sobrepesos"
                              : `${numberOfShipments} ${
                                  numberOfShipments > 1 ? "guías" : "guía"
                                }`}
                          </Text>
                        </Column>
                      </Row>
                      {!this.props.overweightOnly && (
                        <div>
                          <Row style={{ marginBottom: "20px" }}>
                            <Column xs={10} fluid>
                              <Text
                                className="label"
                                className={styles.subtitle}
                              >
                                Detalles de guía
                              </Text>
                            </Column>
                            <Column xs={2} fluid>
                              <Text
                                className="label"
                                style={{ float: "right" }}
                                className={styles.subtitle}
                              >
                                Total
                              </Text>
                            </Column>
                          </Row>

                          <div
                            className={
                              styles.checkoutRenderShipmentsContainer +
                              " checkout-render-shipments-container"
                            }
                          >
                            <div
                              className={
                                styles.checkoutRenderShipments +
                                " checkout-render-shipments"
                              }
                            >
                              {this.renderShipments()}
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        style={{
                          boxShadow: "0 -5px 5px -5px rgba(0,0,0,0.10)",
                        }}
                      >
                        {this.props.shipmentsObject.charges &&
                          this.props.shipmentsObject.charges.overweight && (
                            <div>
                              <Row style={{ marginBottom: "16px" }}>
                                <Column xs={8} fluid>
                                  <Img src={overweightIcon} />
                                  <Text
                                    type="textTable"
                                    className="title-overweight"
                                  >
                                    {`Adeudo de sobrepesos: ${parseFloat(
                                      this.props.shipmentsObject.charges
                                        .overweight.total
                                    ).toFixed(2)} MXN`}
                                  </Text>
                                </Column>
                              </Row>
                              <RadioGroup
                                selected={this.state.overweightPayment}
                                onChange={this.ChangeOverWeightType}
                              >
                                {!this.props.shipmentsObject.shipments && (
                                  <Row style={{ marginBottom: "16px" }}>
                                    <Column xs={9} fluid>
                                      <Radio
                                        type="form"
                                        value="custom"
                                        text="Abono personalizado"
                                        containerClass="rate-radio-container"
                                        className="insurance-radio"
                                        secondaryText={
                                          this.props.shipmentsObject.charges
                                            .overweight.total > 200
                                            ? "Se cobrará automáticamente el 50% de tu adeudo en sobrepeso."
                                            : "Se cobrará automáticamente el 100% de tu adeudo en sobrepeso."
                                        }
                                      />
                                    </Column>
                                    <Column
                                      xs={3}
                                      fluid
                                      style={{ textAlign: "right" }}
                                    >
                                      <Input
                                        type="number"
                                        onChange={this.onChangeOverweightAmount}
                                        value={this.state.totalOverweight}
                                        error={this.state.totalOverweightError}
                                        disabled={true}
                                        placeholder="Total"
                                        prefix="$"
                                        style={{ margin: 0 }}
                                        parentClassName="no-margin"
                                        customInput
                                      />
                                    </Column>
                                  </Row>
                                )}
                                {this.props.shipmentsObject.shipments && (
                                  <Row style={{ marginBottom: "16px" }}>
                                    <Column xs={9} fluid>
                                      <Radio
                                        defaultChecked
                                        type="form"
                                        value="limit"
                                        text="Abono automatico"
                                        containerClass="rate-radio-container"
                                        className="insurance-radio"
                                        secondaryText={
                                          this.props.shipmentsObject.charges
                                            .overweight.total > 200
                                            ? "Se cobrará automáticamente el 50% de tu adeudo en sobrepeso."
                                            : "Se cobrará automáticamente el 100% de tu adeudo en sobrepeso."
                                        }
                                      />
                                    </Column>
                                    <Column
                                      xs={3}
                                      fluid
                                      style={{ textAlign: "right" }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: "14px",
                                          textAlign: "right",
                                        }}
                                      >
                                        $
                                        {parseFloat(
                                          this._calculateMax(
                                            this.props.shipmentsObject
                                              .overweight_payment
                                          )
                                        ).toFixed(2)}
                                      </Text>
                                    </Column>
                                  </Row>
                                )}
                              </RadioGroup>
                            </div>
                          )}
                        {this._renderDiscountRow()}
                        <Row
                          style={{
                            verticalAlign: "bottom",
                            paddingTop: "43px",
                          }}
                        >
                          <Column xs={9} fluid>
                            <Text className="label" className={styles.total}>
                              TOTAL
                            </Text>
                          </Column>
                          <Column xs={3} fluid style={{ textAlign: "right" }}>
                            <Text
                              className="label"
                              style={{ fontSize: "1em", textAlign: "right" }}
                              className={styles.total}
                            >
                              ${parseFloat(this.getTotalPayment()).toFixed(2)}
                            </Text>
                          </Column>
                        </Row>
                      </div>
                    </div>
                  </Column>
                  {!isAccountDisabled ? (
                    <Column
                      lg={8}
                      m={14}
                      s={14}
                      xs={14}
                      className="hide-for-small-only"
                    >
                      {this.props.user && !this.props.user.fiscal_address && (
                        <Message
                          onClick={() => this.props.goToProfile()}
                          color="info"
                          onClose={this.props.hideMessage}
                        >
                          Para poder comprar es necesario agregar tu información
                          fiscal en la sección de Configuración.
                        </Message>
                      )}
                      {!this.props.overweightOnly &&
                        this.props.user.overweight_amount >
                          this.props.user.max_overweight_amount && (
                          <Message
                            onClick={() => {
                              const {
                                changeDashboardGlobalStatus,
                                goToDashboardCheckout,
                              } = this.props;
                              const payload = {
                                charge_type: "OVERWEIGHT",
                              };
                              changeDashboardGlobalStatus(true, payload);
                              goToDashboardCheckout();
                            }}
                            color="info"
                            onClose={this.props.hideMessage}
                          >
                            Para poder comprar es necesario que pagues tu monto
                            pendiente de sobrepesos
                          </Message>
                        )}
                      <div>
                        <Row style={{ marginBottom: "8px" }}>
                          <Column fluid style={{ textAlign: "center" }}>
                            <h1 className="titleForm" className={styles.title}>
                              Elige tu método de pago
                            </h1>
                          </Column>
                        </Row>
                        <Row style={{ marginBottom: "24px" }}>
                          {this._renderPaymentMethods()}
                          {/* {this.props.message.show && (
                            <Message
                              key={1}
                              style={{
                                marginBottom: "0px",
                                marginTop: "24px",
                                width: "100%",
                              }}
                              color={this.props.message.messageType}
                              onClose={this.props.hideMessage}
                            >
                              {this.props.message.text}
                            </Message>
                          )} */}
                        </Row>

                        {this.state.selectedPaymentType === 0 &&
                        options.length > 0 &&
                        this.state.savedCard === 0 ? (
                          <div className={styles.cardContainer}>
                            <div className={styles.card}>
                              <CardTab key={1} numberCards={options.length}>
                                <ReactCSSTransitionGroup
                                  transitionName="cards"
                                  transitionEnter
                                  transitionLeave
                                  transitionEnterTimeout={600}
                                  transitionLeaveTimeout={20}
                                >
                                  <div key={1}>
                                    {options}
                                    <Button
                                      type="link"
                                      onClick={() =>
                                        this.handleCardTabChange(1)
                                      }
                                      style={{
                                        margin: "4px 0px 24px",
                                        padding: "0% 42px",
                                      }}
                                    >
                                      Pagar con otra tarjeta
                                    </Button>
                                    <TermsNConditions
                                      checked={this.state.accept_tnc}
                                      onChange={this.handleAcceptTnc}
                                      isCredit={true}
                                    />
                                    <br />
                                  </div>
                                </ReactCSSTransitionGroup>
                              </CardTab>
                            </div>
                            {this.renderPayOldCard()}
                          </div>
                        ) : this.state.selectedPaymentType === 0 ? (
                          <div className={styles.cardContainer}>
                            <div className={styles.card}>
                              <CardTab
                                key={1}
                                style={{ overflow: "hidden" }}
                                numberCards={options.length}
                              >
                                <ReactCSSTransitionGroup
                                  transitionName="fields"
                                  transitionEnter
                                  transitionLeave
                                  transitionEnterTimeout={600}
                                  transitionLeaveTimeout={20}
                                >
                                  <div key={2}>
                                    {this.renderCardFields()}
                                    {options.length > 0 && (
                                      <Button
                                        type="link"
                                        onClick={() =>
                                          this.handleCardTabChange(0)
                                        }
                                        style={{
                                          margin: "4px 0px 24px",
                                          padding: "0% 42px",
                                        }}
                                      >
                                        Ver tarjetas guardadas
                                      </Button>
                                    )}
                                    <TermsNConditions
                                      checked={this.state.accept_tnc}
                                      onChange={this.handleAcceptTnc}
                                      isCredit={true}
                                    />
                                  </div>
                                </ReactCSSTransitionGroup>
                              </CardTab>
                            </div>
                            {this.rendePayNewCard()}
                          </div>
                        ) : this.state.selectedPaymentType === 1 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "100px",
                            }}
                          >
                            {this.renderPayPaypal()}
                          </div>
                        ) : (
                          this.state.selectedPaymentType === 2 && (
                            <div className={styles.cardContainer}>
                              <div className={styles.card}>
                                {this.renderWalletMessage()}
                              </div>
                              {this.rendePayWallet()}
                            </div>
                          )
                        )}
                      </div>
                    </Column>
                  ) : (
                    <h3 className="hide-for-small-only">
                      Esta cuenta tiene temporalmente desactivada la opción de
                      pagos.
                    </h3>
                  )}
                  <Column
                    xs={14}
                    className="hide-for-large"
                    style={{ marginTop: "-25px" }}
                    fluid
                  >
                    {this.props.user.customer_type !== "subsided_customer"
                      ? this._renderPaymentMethods()
                      : this._renderPaymentMethods(true)}

                    {/* <ReactCSSTransitionGroup
                      transitionName="showError"
                      transitionEnterTimeout={300}
                      transitionLeaveTimeout={300}
                      style={{ width: "100%" }}
                    >
                      {this.props.message.show && (
                        <Message
                          key={1}
                          style={{
                            marginBottom: "0px",
                            marginTop: "24px",
                            width: "100%",
                          }}
                          color={this.props.message.messageType}
                          onClose={this.props.hideMessage}
                        >
                          {this.props.message.text}
                        </Message>
                      )}
                    </ReactCSSTransitionGroup> */}

                    {this.state.selectedPaymentType === 0 &&
                    optionsMobile.length > 0 &&
                    this.state.savedCard === 0 ? (
                      <CardTab key={1} numberCards={optionsMobile.length}>
                        <ReactCSSTransitionGroup
                          transitionName="cards"
                          transitionEnter
                          transitionLeave
                          transitionEnterTimeout={600}
                          transitionLeaveTimeout={20}
                        >
                          <div key={1}>
                            {optionsMobile}
                            <Button
                              type="link"
                              onClick={() => this.handleCardTabChange(1)}
                              style={{
                                margin: "21px 12px, position: relative",
                                float: "right",
                              }}
                            >
                              Pagar con otra tarjeta
                            </Button>
                          </div>
                        </ReactCSSTransitionGroup>
                      </CardTab>
                    ) : this.state.selectedPaymentType === 0 ? (
                      <CardTab
                        key={1}
                        style={{ overflow: "hidden" }}
                        numberCards={options.length}
                      >
                        <ReactCSSTransitionGroup
                          transitionName="fields"
                          transitionEnter
                          transitionLeave
                          transitionEnterTimeout={600}
                          transitionLeaveTimeout={20}
                        >
                          <div key={2}>
                            {this.renderCardFieldsSmall()}
                            {options.length > 0 && (
                              <Button
                                type="link"
                                onClick={() => this.handleCardTabChange(0)}
                                style={{
                                  margin: "4px 0px 24px",
                                  padding: "0% 12px",
                                }}
                              >
                                Ver tarjetas guardadas
                              </Button>
                            )}
                          </div>
                        </ReactCSSTransitionGroup>
                      </CardTab>
                    ) : this.state.selectedPaymentType === 1 ? (
                      <div>
                        <Text
                          className="helpTextModal"
                          style={{ padding: "0% 12px 24px 12px" }}
                        >
                          Al presionar el boton, paypal abrira una ventana
                          emergente. Asegurate de permitir popups provenientes
                          de app2.mienvio.mx
                        </Text>
                      </div>
                    ) : (
                      this.state.selectedPaymentType === 2 && (
                        <div>{this.renderWalletMessageSmall()}</div>
                      )
                    )}
                  </Column>
                  <div className="hide-for-large" style={{ width: "100%" }}>
                    <Row
                      style={{
                        marginLeft: "12px",
                        marginRight: "12px",
                        marginBottom: "5px",
                        paddingTop: "22px",
                      }}
                    >
                      <Column xs={10} fluid>
                        <Text className="titleCardSmall">
                          Resumen de compra
                        </Text>
                      </Column>
                      <Column xs={2} fluid>
                        <Text
                          className="helpTextModal"
                          style={{ verticalAlign: "bottom", float: "right" }}
                        >
                          {this.props.overweightOnly
                            ? "Sobrepesos"
                            : `${numberOfShipments} ${
                                numberOfShipments > 1 ? "guías" : "guía"
                              }`}
                        </Text>
                      </Column>
                    </Row>
                    <Row
                      style={{
                        marginLeft: "12px",
                        marginRight: "12px",
                        marginBottom: "5px",
                        paddingTop: "12px",
                      }}
                    >
                      <Column xs={7} fluid>
                        <Button
                          onClick={() => this.setState({ showModal: true })}
                          type="link"
                          style={{ margin: "0px" }}
                        >
                          Ver detalles <i className="ion-arrow-right-c" />
                        </Button>
                      </Column>
                      <Column xs={5} fluid />
                    </Row>
                    {this._renderDiscountRow()}
                    <Row
                      style={{
                        verticalAlign: "bottom",
                        paddingTop: "12px",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    >
                      <Column xs={10} fluid>
                        <Text className="label">Total</Text>
                      </Column>
                      <Column xs={2} fluid style={{ textAlign: "right" }}>
                        <Text
                          className="label"
                          style={{ fontSize: "14px", textAlign: "right" }}
                        >
                          ${parseFloat(this.getTotalPayment()).toFixed(2)}
                        </Text>
                      </Column>
                    </Row>
                  </div>
                  <div className="container-button-terms hide-for-large">
                    <TermsNConditionsSmall
                      checked={this.state.accept_tnc}
                      onChange={this.handleAcceptTnc}
                    />
                    {this.state.selectedPaymentType === 0 &&
                    optionsMobile.length > 0 &&
                    this.state.savedCard === 0
                      ? this.renderPayOldCard(true)
                      : this.state.selectedPaymentType === 0
                      ? this.rendePayNewCard(true)
                      : this.state.selectedPaymentType === 1
                      ? this.renderPayPaypal(true)
                      : this.state.selectedPaymentType === 2 &&
                        this.rendePayWallet(true)}
                  </div>
                  <Row className={styles.informationContainer}>
                    <Column className={styles.information}>
                      <img src={protectIcon} alt="protección" />
                      <Text className={styles.title}>
                        Tu información esta segura
                      </Text>
                      <Text className={styles.text}>
                        No usaremos tus datos personales para objetivos de
                        marketing o similares.
                      </Text>
                    </Column>
                    <Column className={styles.information}>
                      <img src={securityIcon} alt="seguridad" />
                      <Text className={styles.title}>Pagos seguros</Text>
                      <Text className={styles.text}>
                        Toda tu información esta encriptada y es trasnsimitida a
                        traves de protocolos de alta seguridad, ¡Puedes confiar
                        en nosotros!.
                      </Text>
                    </Column>
                  </Row>
                </Row>
              </BreakpointProvider>
            </Modal>
          </Column>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cards: state.cards,
  user: state.user,
  isAccountDisabled: state.user.disabled,
  message: state.message,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showMessage,
      hideMessage,
      getUserCards,
      getUser,
      getProfileFinance,
      showError,
      createCard,
      loadInvoice,
      goToDashboard: () => push("/"),
      goToProfile: () => push("/perfil"),
      goToDashboardCheckout: () => push("/envios"),
      changeDashboardGlobalStatus,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
