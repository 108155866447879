import React from "react";
import axios from "axios";
import { push } from "react-router-redux";
import valid from "card-validator";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import { Route, Switch } from "react-router-dom";
import isEmail from "validator/lib/isEmail";

import {
  changePassword,
  editUserInfo,
  editFiscalInfo,
} from "../../modules/user";
import { hideMessage } from "../../modules/message";

import Menu from "../../components/Menu";
import Text, { Title } from "../../components/Text";
import Button from "../../components/Button";
import EmptyState from "../../components/EmptyState";
import Create from "../../components/Create";
import Input from "../../components/Input";
import ProfileInfo from "../../components/ProfileInfo";
import ShipmentSetup from "../../components/ShipmentSetup";
import { Divider } from "../../components/Card";
import Message from "../../components/Message";
import { emailRegex, formatPhone } from "../../utils/global_functions";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordError: "",
      passwordNew: "",
      passwordNewError: "",
      passwordConfirmation: "",
      passwordConfirmationError: "",
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      email: "",
      emailError: "",
      companyName: "",
      companyNameError: "",
      rfc: "",
      rfcError: "",
      phone: "",
      phoneError: "",
      street: "",
      streetError: "",
      street2: "",
      street2Error: "",
      zipcode: "",
      zipcodeError: "",
      location: "",
    };
    this.resetForms = this.resetForms.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.savePassword = this.savePassword.bind(this);
    this.saveContactInfo = this.saveContactInfo.bind(this);
    this.saveFiscalInfo = this.saveFiscalInfo.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname.indexOf("editar") > -1) {
      this.resetForms();
      if (
        nextProps.user.first_name &&
        nextProps.user.last_name &&
        nextProps.user.email
      ) {
        this.setState({
          firstName: nextProps.user.first_name,
          lastName: nextProps.user.last_name,
          email: nextProps.user.email,
          phone: nextProps.user.phone,
        });
      }
      if (nextProps.user.fiscal_address) {
        const { fiscal_address } = nextProps.user;
        axios
          .get(`/api/zipcodes/${fiscal_address.zipcode}`)
          .then((response) => {
            this.setState({
              location: `${response.data.municipality}, ${response.data.state.name}`,
            });
          });

        this.setState({
          companyName: fiscal_address.name,
          rfc: fiscal_address.rfc,
          phone: fiscal_address.phone,
          street: fiscal_address.street,
          street2: fiscal_address.street2,
          zipcode: fiscal_address.zipcode,
        });
      }
    }
    if (
      nextProps.location.pathname.indexOf("editar") > -1 &&
      nextProps.location.pathname !== this.props.location.pathname
    ) {
      this.resetForms();
      this.props.hideMessage();
    }
    if (
      nextProps.location.pathname.indexOf("editar") > -1 &&
      nextProps.location.pathname === this.props.location.pathname &&
      nextProps.message.messageType &&
      nextProps.message.messageType === "success"
    ) {
      this.props.goToProfile();
    }
  }

  handleChange(value, key) {
    if (key === "zipcode" && !isNaN(value) && value.length === 5) {
      axios
        .get(`/api/zipcodes/${value}`)
        .then((response) => {
          this.setState({
            location: `${response.data.municipality}, ${response.data.state.name}`,
            zipcodeError: "",
          });
        })
        .catch(() => {
          this.setState({
            location: "",
            zipcodeError: "Código postal inválido",
          });
        });
    } else if (key === "zipcode") {
      this.setState({ location: "" });
    } else if (key === "phone") {
      value = value.replace(/\D/g, "");
      if (value.length > 10) return;
    } else if (key === "email" && !emailRegex.test(value)) {
      this.setState({
        emailError: "El correo no es válido",
      });
    } else if (key === "email" && emailRegex.test(value)) {
      this.setState({
        emailError: "",
      });
    }
    this.setState({ [key]: value });
  }

  handleRedirect() {
    localStorage.clear();
    window.location = "/contrasena";
  }

  resetForms() {
    this.setState({
      password: "",
      passwordError: "",
      passwordNew: "",
      passwordNewError: "",
      passwordConfirmation: "",
      passwordConfirmationError: "",
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      email: "",
      emailError: "",
      companyName: "",
      companyNameError: "",
      rfc: "",
      rfcError: "",
      phone: "",
      phoneError: "",
      street: "",
      streetError: "",
      street2: "",
      street2Error: "",
      zipcode: "",
      zipcodeError: "",
      location: "",
    });
  }

  saveContactInfo() {
    let { firstName } = this.state;
    let { lastName } = this.state;
    let { phone } = this.state;
    const { email } = this.state;
    const { password } = this.state;
    const emailError = "";
    const errors = 0;
    if (firstName === "") {
      firstName = this.props.user.first_name;
    }
    if (lastName === "") {
      lastName = this.props.user.last_name;
    }
    if (phone === "") {
      phone = this.props.user.phone;
    }

    if (email !== this.props.user.email) {
      // check password
      if (password == "") {
        return this.setState({
          passwordError: "Este campo es requerido",
        });
      }
      if (password.length < 6) {
        return this.setState({
          passwordError: "Debe de contener al menos 6 caracteres",
        });
      }
      return this.props.editUserInfo(
        firstName,
        lastName,
        phone,
        email,
        password
      );
    }

    this.props.editUserInfo(firstName, lastName, phone);
  }

  savePassword() {
    const { password } = this.state;
    let passwordError = "";
    const { passwordNew } = this.state;
    let passwordNewError = "";
    const { passwordConfirmation } = this.state;
    let passwordConfirmationError = "";
    let errors = 0;
    if (password === "") {
      errors++;
      passwordError = "Este campo es requerido";
    } else if (password.length < 6) {
      errors++;
      passwordError = "Debe contener al menos 6 caracteres";
    }

    if (passwordNew === "") {
      errors++;
      passwordNewError = "Este campo es requerido";
    } else if (passwordNew.length < 6) {
      errors++;
      passwordNewError = "Debe contener al menos 6 caracteres";
    } else if (passwordNew === password) {
      errors++;
      passwordNewError =
        "Las contraseña nueva no puede ser igual a la contraseña actual";
    }

    if (passwordConfirmation === "") {
      errors++;
      passwordConfirmationError = "Este campo es requerido";
    } else if (passwordConfirmation !== passwordNew) {
      errors++;
      passwordConfirmationError = "Las dos contraseñas no coinciden";
    }

    if (errors > 0) {
      return this.setState({
        passwordError,
        passwordNewError,
        passwordConfirmationError,
      });
    }
    this.props
      .changePassword(password, passwordNew, passwordConfirmation)
      .then(() =>
        this.setState({
          passwordError,
          passwordNewError,
          passwordConfirmationError,
        })
      );
  }

  saveFiscalInfo() {
    let errors = 0;
    const regexrfc =
      /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/;
    let companyNameError = "";
    let rfcError;
    let phoneError = "";
    let streetError = "";
    let street2Error = "";
    let zipcodeError = "";

    if (!this.state.companyName && !this.props.user.fiscal_address) {
      errors++;
      companyNameError = "Este campo es requerido";
    }

    if (!this.state.rfc && !this.props.user.fiscal_address) {
      errors++;
      rfcError = "Este campo es requerido";
    } else if (this.state.rfc && !regexrfc.test(this.state.rfc)) {
      errors++;
      rfcError = "RFC inválido";
    }

    if (!this.state.phone && !this.props.user.fiscal_address) {
      errors++;
      phoneError = "Este campo es requerido";
    } else if (
      this.state.phone &&
      (isNaN(this.state.phone) || this.state.phone.length !== 10)
    ) {
      errors++;
      phoneError = "Número de teléfono inválido";
    }

    if (!this.state.street && !this.props.user.fiscal_address) {
      errors++;
      streetError = "Este campo es requerido";
    } else if (this.state.street.length > 35) {
      errors++;
      streetError = "Máximo 35 caracteres";
    }

    if (!this.state.street2 && !this.props.user.fiscal_address) {
      errors++;
      street2Error = "Este campo es requerido";
    }

    if (!this.state.zipcode && !this.props.user.fiscal_address) {
      errors++;
      zipcodeError = "Este campo es requerido";
    } else if (
      this.state.zipcode &&
      (isNaN(this.state.zipcode) || this.state.zipcode.length !== 5)
    ) {
      errors++;
      zipcodeError = "Código postal inválido";
    } else if (this.state.location === "" && this.state.zipcode !== "") {
      errors++;
      zipcodeError = "Código postal inválido";
    } else if (this.state.location === "" && !this.props.user.fiscal_address) {
      errors++;
      zipcodeError = "Código postal inválido";
    }
    this.setState({
      companyNameError,
      rfcError,
      streetError,
      street2Error,
      phoneError,
      zipcodeError,
    });
    if (errors > 0) {
      return;
    }
    if (this.props.user.fiscal_address) {
      const address = {
        name: this.state.companyName
          ? this.state.companyName
          : this.props.user.fiscal_address.name,
        rfc: this.state.rfc
          ? this.state.rfc
          : this.props.user.fiscal_address.rfc,
        phone: this.state.phone
          ? this.state.phone
          : this.props.user.fiscal_address.phone,
        street: this.state.street
          ? this.state.street
          : this.props.user.fiscal_address.street,
        street2: this.state.street2
          ? this.state.street2
          : this.props.user.fiscal_address.street2,
        zipcode: this.state.zipcode
          ? this.state.zipcode
          : this.props.user.fiscal_address.zipcode,
      };
      return this.props.editFiscalInfo(
        address,
        this.props.user.fiscal_address.object_id
      );
    }
    const address = {
      name: this.state.companyName,
      rfc: this.state.rfc,
      phone: this.state.phone,
      street: this.state.street,
      street2: this.state.street2,
      zipcode: this.state.zipcode,
    };
    this.props.editFiscalInfo(address);
  }

  render() {
    if (
      this.props.message.show &&
      (this.props.message.messageType === "error" ||
        this.props.message.messageType === "success")
    ) {
      setTimeout(this.props.hideMessage, 5000);
    }
    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value:
          this.state[name] && name === "phone"
            ? formatPhone(this.state[name])
            : this.state[name],
        error: this.state[`${name}Error`],
      }),
    };
    const editContactFields = (
      <div>
        <Row>
          <Column xs={6} fluid style={{ paddingRight: "8px" }}>
            <Input
              type="text"
              {...mainProps.generate("firstName")}
              required
              placeholder={this.props.user.first_name}
              label="Nombre(s)"
            />
          </Column>
          <Column xs={6} fluid style={{ paddingLeft: "8px" }}>
            <Input
              type="text"
              {...mainProps.generate("lastName")}
              required
              placeholder={this.props.user.last_name}
              label="Apellidos"
            />
          </Column>
        </Row>
        <Input
          type="text"
          {...mainProps.generate("email")}
          required
          disabled
          placeholder={this.props.user.email}
          label="Correo electrónico"
        />
        <Input
          type="text"
          {...mainProps.generate("phone")}
          required
          placeholder={this.props.user.phone}
          label="Teléfono"
        />
        {this.props.user.email !== this.state.email && (
          <Row>
            <Input
              type="password"
              {...mainProps.generate("password")}
              label="Ingresa contraseña, para cambiar correo"
              placeholder="••••••••••••"
            />
          </Row>
        )}
      </div>
    );
    const editPasswordFields = (
      <div>
        <Input
          type="password"
          {...mainProps.generate("password")}
          required
          label="Contraseña actual"
        />
        <Divider />
        <Input
          type="password"
          {...mainProps.generate("passwordNew")}
          required
          label="Nueva contraseña"
        />
        <Input
          type="password"
          {...mainProps.generate("passwordConfirmation")}
          required
          label="Repite tu nueva contraseña"
        />
      </div>
    );
    const editFiscalFields = (
      <div>
        <Row>
          <Column xs={5} fluid>
            <Input
              type="text"
              {...mainProps.generate("companyName")}
              required
              placeholder={
                this.props.user.fiscal_address
                  ? this.props.user.fiscal_address.name
                  : "Nombre Titular o Empresa"
              }
              label="Titular"
            />
            <Input
              type="text"
              {...mainProps.generate("rfc")}
              required
              placeholder={
                this.props.user.fiscal_address
                  ? this.props.user.fiscal_address.rfc
                  : "XXXX000000XXX"
              }
              label="RFC"
            />
            <Input
              type="text"
              {...mainProps.generate("phone")}
              required
              placeholder={
                this.props.user.fiscal_address
                  ? this.props.user.fiscal_address.phone
                  : "(442) 123 12 45"
              }
              label="Teléfono"
            />
          </Column>
          <Column xs={1} fluid />
          <Column xs={6} fluid>
            <Input
              type="text"
              {...mainProps.generate("street")}
              maxLength="35"
              required
              placeholder={
                this.props.user.fiscal_address
                  ? this.props.user.fiscal_address.street
                  : "Av. Felipe Angeles, 511. Int. J-501."
              }
              label="Calle y número"
            />
            <Input
              type="text"
              {...mainProps.generate("street2")}
              required
              placeholder={
                this.props.user.fiscal_address
                  ? this.props.user.fiscal_address.street2
                  : "Los pedregales"
              }
              label="Colonia"
            />
            <Row>
              <Column xs={4} fluid>
                <Input
                  type="text"
                  {...mainProps.generate("zipcode")}
                  required
                  placeholder={
                    this.props.user.fiscal_address
                      ? this.props.user.fiscal_address.zipcode
                      : "76158"
                  }
                  label="Código Postal"
                />
              </Column>
              <Column xs={8} fluid style={{ paddingLeft: "16px" }}>
                <Input
                  type="text"
                  disabled
                  onChange={() => this.saveFiscalInfo()}
                  required
                  label="Estado y Ciudad"
                  value={this.state.location}
                />
              </Column>
            </Row>
          </Column>
        </Row>
      </div>
    );
    return (
      <div>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: "right" }} fluid>
            <Menu />
          </Column>
        </Row>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}`}
            component={ProfileInfo}
          />
          <Route
            path={`${this.props.match.url}/editar/contacto`}
            render={(props) => (
              <Create {...props} goBack={this.props.goToProfile}>
                {this.props.message.show ? (
                  <Message
                    color={this.props.message.messageType}
                    onClose={this.props.hideMessage}
                  >
                    {this.props.message.text}
                  </Message>
                ) : null}
                <Title>Edita tu información de cuenta</Title>
                <Text type="helpTextModal">
                  Ayúdanos a personalizar la atención dentro de nuestra
                  plataforma con tu nombre completo. Tu correo electrónico es
                  usado para iniciar sesión.
                </Text>
                {editContactFields}
                <Button
                  onClick={() => this.saveContactInfo()}
                  className="create-btn"
                >
                  Aplicar Cambios
                </Button>
              </Create>
            )}
          />
          <Route
            path={`${this.props.match.url}/editar/contrasena`}
            render={(props) => (
              <Create {...props} goBack={this.props.goToProfile}>
                {this.props.message.show ? (
                  <Message
                    color={this.props.message.messageType}
                    onClose={this.props.hideMessage}
                  >
                    {this.props.message.text}
                  </Message>
                ) : null}
                <Title>Edita tu contraseña</Title>
                <Text type="helpTextModal">
                  Cambiar tu contraseña cada cierto periodo de tiempo ayuda a
                  mantener tu cuenta aun más segura, aquí solo podras editar tu
                  contraseña. Si haz olvidado tu contraseña{" "}
                  <a
                    href="#"
                    style={{ color: "#007acc" }}
                    onClick={() => this.handleRedirect()}
                  >
                    haz click aquí
                  </a>{" "}
                  y te ayudaremos a crear una nueva
                </Text>
                {editPasswordFields}
                <Button
                  onClick={() => this.savePassword()}
                  className="create-btn"
                >
                  Aplicar Cambios
                </Button>
              </Create>
            )}
          />
          <Route
            path={`${this.props.match.url}/editar/fiscal`}
            render={(props) => (
              <Create expanded {...props} goBack={this.props.goToProfile}>
                {this.props.message.show ? (
                  <Message
                    color={this.props.message.messageType}
                    onClose={this.props.hideMessage}
                  >
                    {this.props.message.text}
                  </Message>
                ) : null}
                <Title>Edita tu información fiscal</Title>
                <Text type="helpTextModal">
                  La información fiscal que proporciones aquí, será utilizada en
                  el momento de realizar tus facturas.
                </Text>
                {editFiscalFields}
                <Button
                  onClick={() => this.saveFiscalInfo()}
                  className="create-btn"
                >
                  Aplicar Cambios
                </Button>
              </Create>
            )}
          />
          <Route
            exact
            path={`${this.props.match.url}/ajustes-envios`}
            component={ShipmentSetup}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  message: state.message,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToEditProfile: () => push("/perfil/editar/contacto"),
      goToProfile: () => push("/perfil"),
      goToShipmentSetUp: () => push("/perfil/ajustes-envios"),
      changePassword,
      editUserInfo,
      editFiscalInfo,
      hideMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
