import React from "react";
import { Column, Row } from "hedron";
import Skeleton from "react-loading-skeleton";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

import Text, { P } from "../../Text";
import SelectableBox from "../../SelectableBox";
import {
  startDiscountPromoDate,
  finishDiscountPromoDate,
  discountPromoCarriers,
  extendedZonePricing,
} from "../../../constants";
import {
  jsUcfirst,
  isTodayBetweenDates,
} from "../../../utils/global_functions";
import Badge from "../../Badge/insuranceBadge";
import { RadioGroup, Radio } from "../../Radio";
import ReactTooltip from "react-tooltip";
import colors from "../../../utils/colors";

const RenderBadge = (status) => {
  switch (status) {
    case null:
      return <Badge type="warning">Envío no protegido</Badge>;
    case 1:
      return <Badge type="correct">Envío protegido</Badge>;
    case 0:
      return <Badge type="error">Envío no protegido</Badge>;
    default:
      return null;
  }
};

const Service = (props) => (
  <div>
    {Object.values(props.rates).map((service, index) => {
      const promoCarrierObject = discountPromoCarriers.find(
        (carrier) => carrier.name === service.provider
      );
      return (
        <SelectableBox
          key={index}
          value={service.object_id}
          type="radio"
          name="carrier_shipmentType"
          onChange={props.handleRadioChange}
          // cornerNote
          cornerNote={
            service.extended_zone || service.exceed_dimensions_amount > 0
          }
          provider={service.provider}
          isChecked={props.isChecked(service.object_id)}
        >
          <Row>
            <Column style={{ padding: 0 }} xs={3} className="">
              <div
                className="rate-sidebar"
                style={{
                  backgroundImage: `url(https://production.mienvio.mx/${service.provider_img})`,
                }}
              />
            </Column>
            <Column style={{ padding: 0 }} xs={6} className="">
              <Text type="P">
                {!isTodayBetweenDates(
                  startDiscountPromoDate,
                  finishDiscountPromoDate
                ) &&
                promoCarrierObject &&
                !props.isPrime
                  ? jsUcfirst(`${service.servicelevel}`)
                  : jsUcfirst(service.servicelevel)}
              </Text>
              <br />
              {service.product_id && (
                <span style={{ color: colors.gray.shadow, fontSize: "11px" }}>
                  ID de producto: <b>{service.product_id}</b>
                </span>
              )}

              {/* TIEMPO DE ENTREGA */}

              {(!service.delivery_frequency || !service.is_ocurre) && (
                <div
                  className="small-gray"
                  dangerouslySetInnerHTML={{
                    __html: service.duration_terms,
                  }}
                />
              )}

              {/* AVISO PARA "OCURRE" */}

              {service.is_ocurre && (
                <div
                  className="small-gray"
                  style={{
                    color: colors.gray.shadow,
                    fontSize: "11px",
                    position: "relative",
                    margin: "0.2em 0",
                  }}
                >
                  <span
                    data-tip
                    data-for={"service-ocurre-" + index}
                    dangerouslySetInnerHTML={{ __html: "Servicio ocurre" }}
                  />
                </div>
              )}

              {service.days &&
                service.delivery_frequency &&
                (service.delivery_frequency.indexOf("/") > -1 ? (
                  <div className="small-gray">
                    <span
                      style={{
                        color: colors.gray.shadow,
                        fontSize: "11px",
                      }}
                    >
                      Entrega estimada:{" "}
                      {moment().add(service.days, "days").format("DD MMMM")}
                    </span>
                  </div>
                ) : (
                  <div className="small-gray">
                    <span
                      style={{
                        color: colors.gray.shadow,
                        fontSize: "11px",
                      }}
                    >
                      Frecuencia de entrega:{" "}
                      {service.delivery_frequency.replace(",X,", ",Mi,")}
                    </span>
                  </div>
                ))}
            </Column>

            {service.is_ocurre && (
              <ReactTooltip id={"service-ocurre-" + index}>
                <span>
                  Entrega a domicilio no disponible, el paquete <br /> llegará
                  directo a la oficina de la paquetería.
                </span>
              </ReactTooltip>
            )}

            {service.extended_zone || service.exceed_dimensions_amount > 0 ? (
              <ReactTooltip id={"extended-zone-tooltip-" + index}>
                <span>
                  Añadimos cargos extras, selecciona el servicio para ver los
                  detalles
                </span>
              </ReactTooltip>
            ) : null}
            <Column
              style={{ padding: 0 }}
              xs={3}
              className="text-right rate-price"
            >
              {service.extended_zone && (
                <div
                  data-tip
                  data-for={"extended-zone-tooltip-" + index}
                  style={{
                    marginLeft: "0.5em",
                    position: "absolute",
                    right: "0.5em",
                    top: "0.5em",
                    fontSize: "1em",
                    backgroundColor: "#f3c624",
                    color: "white",
                    borderRadius: "50%",
                    width: "0.9em",
                    height: "0.9em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ fontSize: "0.9em" }}>!</span>
                </div>
              )}

              {/*
                !isTodayBetweenDates(startDiscountPromoDate, finishDiscountPromoDate)
                && promoCarrierObject
                && !props.isPrime
                && (
                  <Text type="P" className="bold">
                    <strike className="red-discount">{`$${((parseFloat(service.amount)) / (1 - promoCarrierObject.percentage)).toFixed(2)}`}</strike>
                  </Text>
                )
              */}

              <Text type="P" className="bold">
                {`$${parseFloat(service.amount).toFixed(2)}`}
              </Text>
            </Column>
          </Row>
          {props.isChecked(service.object_id) && (
            <div>
              {service.extended_zone || service.exceed_dimensions_amount > 0 ? (
                <Text type="microHeader" className="min-margin border-top">
                  Cargos extra por cobro de:
                </Text>
              ) : null}
              {service.extended_zone || service.exceed_dimensions_amount > 0 ? (
                <Row>
                  <Column xs={12} className="left-service top-index">
                    {service.extended_zone && (
                      <div className="small-gray">
                        <span
                          style={{
                            color: colors.gray.shadow,
                            fontSize: "11px",
                          }}
                        >
                          {`Zona extendida + $${service.extended_zone_amount}`}
                        </span>
                      </div>
                    )}
                    {service.exceed_dimensions_amount > 0 && (
                      <div className="small-gray">
                        <span
                          style={{
                            color: colors.gray.shadow,
                            fontSize: "11px",
                          }}
                        >
                          {`Exceso de dimensiones + $${service.exceed_dimensions_amount}`}
                        </span>
                      </div>
                    )}
                  </Column>
                </Row>
              ) : null}
            </div>
          )}
          {!props.isQuickQuote && (
            <div>
              {props.isChecked(service.object_id) &&
                service.provider != "Chazki" &&
                service.provider !== "DHL" && (
                  <div>
                    <Text type="microHeader" className="min-margin border-top">
                      Servicios adicionales
                    </Text>
                    <Row>
                      <Column xs={7} className="left-service top-index">
                        {props.insuranceCost.length > 0 ? (
                          <div>
                            <label>{`Asegurar por $${props.insuranceCost}`}</label>
                            <div className="radio-wrapper">
                              <RadioGroup
                                className="no-margin-checkbox"
                                selected={props.haveInsurance}
                                onChange={props.handleInsurance}
                              >
                                <Radio
                                  value={0}
                                  text="No"
                                  containerClass="custom-size"
                                  className="no-margin-left"
                                />
                                <Radio
                                  value={1}
                                  text="Si"
                                  containerClass="custom-size"
                                />
                              </RadioGroup>
                            </div>
                          </div>
                        ) : (
                          <p>
                            <Skeleton />
                          </p>
                        )}
                      </Column>
                      <Column xs={5} className="left-service top-index">
                        {props.insuranceCost.length > 0 &&
                          RenderBadge(props.haveInsurance)}
                      </Column>

                      {props.haveInsurance ? (
                        <Column
                          xs={12}
                          className="left-service top-index padding-top-null"
                        >
                          <Text type="microHeader" style={{ color: "#697d91" }}>
                            Antes de continuar, verifica si tu envío es
                            asegurable
                          </Text>
                          <Text
                            onClick={() => {
                              window.open(
                                "https://blogger.mienvio.mx/2021/02/cuales-son-los-articulos-que-no-puedo-enviar-por-paqueteria-articulos-prohibidos/",
                                "_blank"
                              );
                            }}
                            style={{ color: "#2576DA", marginTop: 0 }}
                            type="microHeader"
                          >
                            Consultalo aquí
                          </Text>
                        </Column>
                      ) : null}
                    </Row>
                  </div>
                )}
            </div>
          )}
        </SelectableBox>
      );
    })}
  </div>
);

const mapStateToProps = (state) => ({
  isPrime: state.user.prime,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Service);
