import React, { Component } from 'react';
import Switch from 'react-switch';

const SwitchWrapper = props => (
  <Switch
    disabled={props.disabled}
    checked={props.checked}
    onChange={props.handleChange}
    onColor="#87BDFF"
    onHandleColor="#2576DA"
    handleDiameter={16}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={12}
    width={24}
    className="react-switch"
  />
);

export default SwitchWrapper;
