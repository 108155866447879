// @flow
import React from 'react';
import axios from 'axios';
import { boundMethod } from 'autobind-decorator';
import { Row, Column } from 'hedron';
import { Route, Switch } from 'react-router-dom';
import './index.css';

import Menu from '../../components/Menu';
import AddressesList from '../../components/AddressesList';
import Create from '../../components/Create';
import Text, { Title } from '../../components/Text';
import Input from '../../components/Input';
import Button from '../../components/Button';

type Props = {
  match: any,
  location: {
    pathname: any,
    state: any,
  },
  addresses: {
    results: {
      alias: string,
      name: string,
      email: string,
      phone: string,
      reference: string,
      street: string,
      street2: string,
      zipcode: string,
    }[],
  },

  goToAddressList: () => void,
  goToAddress: () => void,
  goToDefaultConfig: () => void,
  goBack: () => void,
  getUserAllAddresses: () => Promise<any>,
  createAddress: (address: string) => Promise<any>,
  editAddress: (newAddress: string, id: string, _showLoader: string) => Promise<any>,
  hideMessage: () => void,
}

type State = {
  alias: string,
  aliasError: string,
  name: string,
  nameError: string,
  email: string,
  emailError: string,
  phone: string,
  phoneError: string,
  street: string,
  streetError: string,
  street2: string,
  street2Error: string,
  reference: string,
  referenceError: string,
  zipcode: string,
  zipcodeError: string,
  location: string,
}

export default class Addresses extends React.Component<Props, State> {
  state: State = {
    alias: '',
    aliasError: '',
    name: '',
    nameError: '',
    email: '',
    emailError: '',
    phone: '',
    phoneError: '',
    street: '',
    streetError: '',
    street2: '',
    street2Error: '',
    reference: '',
    referenceError: '',
    zipcode: '',
    zipcodeError: '',
    location: '',
  }

  constructor(props: Props) {
    super(props);
    const { getUserAllAddresses } = this.props;
    getUserAllAddresses(true);
  }

  componentWillMount() {
    const { hideMessage } = this.props;
    hideMessage();
  }

  componentWillReceiveProps(nextProps: Props) {
    const {
      location, hideMessage, match, addresses,
    } = this.props;
    const { location: nextLocation } = nextProps;

    if (
      (nextLocation.pathname.indexOf('crear') > -1
        || nextLocation.pathname.indexOf('editar') > -1)
      && nextLocation.pathname !== location.pathname
    ) {
      this.resetForms();
      hideMessage();
    }
    if (nextProps.match.isExact && !match.isExact) {
      this.resetForms();
    }
    if (nextLocation.pathname.indexOf('editar') > -1) {
      this.resetForms();
      if (nextLocation.state.address) {
        const { address } = nextLocation.state;
        axios.get(`/api/zipcodes/${address.zipcode}`).then((response) => {
          this.setState({
            location: `${response.data.municipality}, ${response.data.state.name}`,
          });
        });
        this.setState({
          alias: address.alias,
          name: address.name,
          email: address.email,
          phone: address.phone,
          reference: address.reference,
          street: address.street,
          street2: address.street2,
          zipcode: address.zipcode,
        });
      } else {
        let address = null;
        const id = nextLocation.pathname.split('/')[3];
        const { results } = addresses;
        results.forEach((a) => {
          if (a.object_id.toString() === id) {
            address = a;
          }
        });
        this.setState({
          alias: address.alias,
          name: address.name,
          email: address.email,
          phone: address.phone,
          reference: address.reference,
          street: address.street,
          street2: address.street2,
          zipcode: address.zipcode,
        });
      }
    }
  }

  @boundMethod
  handleChange(value, key) {
    if (key === 'zipcode' && !isNaN(value) && value.length === 5) {
      axios
        .get(`/api/zipcodes/${value}`)
        .then((response) => {
          this.setState({
            location: `${response.data.municipality}, ${response.data.state.name}`,
            zipcodeError: '',
          });
        })
        .catch(() => {
          this.setState({
            location: '',
            zipcodeError: 'Código postal inválido',
          });
        });
    } else if (key === 'zipcode') {
      this.setState({ location: '' });
    } else if (key === 'phone') {
      value = value.replace(/\D/g, '');
      if (value.length > 10) return;
    } else if (key === 'reference') {
      const referenceError = value.length >= 35
        ? 'No escribas parte de la dirección aquí, esta información no aparecerá en la guía'
        : '';
      this.setState({ referenceError });
    }
    this.setState({ [key]: value });
  }

  @boundMethod
  formatPhone(phone: string) {
    const mask = '(XXX) XXX XX XX';
    const s = `${phone}`;
    let r = '';
    for (let i = 0, is = 0; i < mask.length && is < s.length; i += 1) {
      r += mask.charAt(i) === 'X' ? s.charAt(is += 1) : mask.charAt(i);
    }
    return r;
  }

  @boundMethod
  resetForms() {
    this.setState({
      alias: '',
      aliasError: '',
      name: '',
      nameError: '',
      email: '',
      emailError: '',
      phone: '',
      phoneError: '',
      street: '',
      streetError: '',
      street2: '',
      street2Error: '',
      reference: '',
      referenceError: '',
      zipcode: '',
      zipcodeError: '',
      location: '',
    });
  }

  render() {
    const { match, goToDefaultConfig } = this.props;
    const { location } = this.state;
    const mainProps = {
      generate: name => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value: name === 'phone' ? this.formatPhone(this.state[name]) : this.state[name],
        error: this.state[`${name}Error`],
      }),
    };

    return (
      <div>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: 'right' }} fluid>
            <Menu />
          </Column>
        </Row>
        <Switch>
          <Route
            path={`${match.url}/crearDefault`}
            render={props => (
              <Create {...props} goBack={goToDefaultConfig}>
                <Title>Registra una nueva dirección</Title>
                <Text type="helpTextModal">
                  Esta dirección será agregada a tu libreta de direcciones para facilitar tu compra
                </Text>
                <div>
                  <Input
                    type="text"
                    {...mainProps.generate('alias')}
                    required
                    placeholder="Warehouse nte"
                    label="Alias"
                  />
                  <Input
                    type="text"
                    {...mainProps.generate('name')}
                    required
                    placeholder="María Pérez"
                    label="Nombre"
                  />
                  <Input
                    type="text"
                    {...mainProps.generate('email')}
                    required
                    placeholder="maria@tucorreo.com"
                    label="Correo electrónico"
                  />
                  <Input
                    type="text"
                    {...mainProps.generate('phone')}
                    required
                    placeholder="(442) 123 12 45"
                    label="Teléfono"
                  />
                  <Input
                    type="text"
                    {...mainProps.generate('street')}
                    maxLength="35"
                    required
                    placeholder="Av. Miguel Hidalgo, 876. Int. 29"
                    label="Calle y número"
                  />
                  <Input
                    type="text"
                    {...mainProps.generate('reference')}
                    maxLength="35"
                    placeholder="Casa blanca con portón negro"
                    label="Referencias"
                  />
                  <Input
                    type="text"
                    {...mainProps.generate('street2')}
                    maxLength="35"
                    required
                    placeholder="Independencia"
                    label="Colonia"
                  />
                  <Row>
                    <Column xs={4} fluid>
                      <Input
                        type="text"
                        {...mainProps.generate('zipcode')}
                        required
                        placeholder="76158"
                        label="Código Postal"
                      />
                    </Column>
                    <Column xs={8} fluid style={{ paddingLeft: '16px' }}>
                      <Input
                        type="text"
                        disabled
                        onChange={() => {}}
                        required
                        label="Estado y Ciudad"
                        value={location}
                      />
                    </Column>
                  </Row>
                </div>
                <Button
                  onClick={() => this.saveAddress('createDefault', null)}
                  className="create-btn"
                >
                  Guardar
                </Button>
              </Create>
            )}
          />
          <Route path={`${match.url}/:id`} component={AddressesList} />
          <Route exact path={`${match.url}`} component={AddressesList} />
        </Switch>
      </div>
    );
  }
}
