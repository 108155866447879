import destinatario from "./icons/destinatario.svg";
import destino from "./icons/destino.svg";
import fecha from "./icons/fecha.svg";
import marketplace from "./icons/marketplace.svg";
import order from "./icons/order.svg";
import origen from "./icons/origen.svg";
import remitente from "./icons/remitente.svg";
import servicio from "./icons/servicio.svg";
import sobrepeso from "./icons/sobrepeso.svg";
import total from "./icons/total.svg";
import internal from "./icons/internal.svg";
import shopify from "./icons/shopify.svg";
import status from "./icons/estatus.svg";
import tracking from "./icons/tracking.svg";
import box from "./icons/box.svg";

export const SHOPIFY_ORDER_TYPE = "SHOPIFY_ORDER_TYPE";
export const SHOPIFY_ORDER = "SHOPIFY_ORDER";
export const INTERNALID = "INTERNALID";
export const PRODUCT_ID = "PRODUCT_ID";
export const ORDER_TYPE = "ORDER_TYPE";
export const TRACKING_NUMBER = "TRACKING_NUMBER";
export const ORDER = "ORDER";
export const DATE = "DATE";
export const PURCHASE_DATE = "PURCHASE_DATE";
export const TO_NAME = "TO_NAME";
export const DESTINATION = "DESTINATION";
export const SERVICE = "SERVICE";
export const AMOUNT = "AMOUNT";
export const ORIGIN = "ORIGIN";
export const FROM_NAME = "FROM_NAME";
export const MARKETPLACE = "MARKETPLACE";
export const OVERWEIGHT = "OVERWEIGHT";
export const STATUS = "STATUS";

export const options = {
  SERVICE: [
    // "FedEx",
    "Chazki",
    "Redpack",
    "Estafeta",
    "99 Minutos",
    "Flecha Amarilla",
    "DHL",
  ],
  SHOPIFY_ORDER_TYPE: ["Pendiente", "Pagada"],
  ORDER_TYPE: ["Manual"],
  MARKETPLACE: [
    "Manual",
    "Shopify",
    "Magento",
    "PrestaShop",
    "WooCommerce",
    "Epages",
    "Tiendanube",
  ],
  STATUS: ["Pendiente recoleccion", "Incidencia", "En camino"],
};

export const titles = {
  SHOPIFY_ORDER_TYPE: "Estatus Shopify",
  SHOPIFY_ORDER: "Orden Marketplace",
  INTERNALID: "Id Interno",
  PRODUCT_ID: "Id de Producto",
  ORDER_TYPE: "Tipo de Orden",
  TRACKING_NUMBER: "Nº de Guía",
  ORDER: "N° Orden",
  DATE: "Fecha",
  PURCHASE_DATE: "Fecha de compra",
  TO_NAME: "Destinatario",
  DESTINATION: "Destino",
  SERVICE: "Servicio",
  AMOUNT: "Total",
  ORIGIN: "Origen",
  FROM_NAME: "Remitente",
  MARKETPLACE: "Tipo de Orden",
  OVERWEIGHT: "Sobrepeso",
  STATUS: "Estatus",
};

export const columnName = {
  SHOPIFY_ORDER: "shopify_order_id",
  INTERNALID: "massive_load_id",
  PRODUCT_ID: "product_id",
  SHOPIFY_ORDER_TYPE: "shopify_status",
  ORDER_TYPE: "purchase_status_id",
  TRACKING_NUMBER: "tracking_number",
  ORDER: "id",
  DATE: "date",
  PURCHASE_DATE: "purchase_date",
  TO_NAME: "to_name",
  DESTINATION: "to_street",
  SERVICE: "provider_name",
  AMOUNT: "amount",
  ORIGIN: "from_street",
  FROM_NAME: "from_name",
  MARKETPLACE: "marketplace",
  OVERWEIGHT: "has_overweight",
  STATUS: "status",
};

export const conditions = {
  equal: " es ",
  not_equal: " no es ",
  has: " contiene ",
  not_has: " no contiene ",
  starts_with: " empieza con ",
  ends_with: " termina con ",
  more_than: " mayor que ",
  less_than: " menor que ",
  ow: "Con sobrepeso ",
  not_ow: "Sin sobrepeso ",
  between: " entre ",
  before: " antes de ",
  after: " después de ",
};

export const icons = {
  SHOPIFY_ORDER: order,
  INTERNALID: internal,
  PRODUCT_ID: box,
  SHOPIFY_ORDER_TYPE: shopify,
  ORDER_TYPE: order,
  TRACKING_NUMBER: tracking,
  ORDER: order,
  DATE: fecha,
  PURCHASE_DATE: fecha,
  TO_NAME: destinatario,
  DESTINATION: destino,
  SERVICE: servicio,
  AMOUNT: total,
  ORIGIN: origen,
  FROM_NAME: remitente,
  MARKETPLACE: marketplace,
  OVERWEIGHT: sobrepeso,
  STATUS: status,
};

export const conditionToOperator = {
  equal: "=",
  not_equal: "<>",
  has: "ILIKE",
  not_has: "NOT ILIKE",
  starts_with: "ILIKE",
  ends_with: "ILIKE",
  more_than: ">",
  less_than: "<",
  between: "BETWEEN",
  before: "<",
  after: ">",
};

export const filterConditions = {
  ORDER: [
    { label: "Es", id: "equal" },
    { label: "No es", id: "not_equal" },
  ],
  SHOPIFY_ORDER: [{ label: "Es", id: "equal" }],
  INTERNALID: [{ label: "Es", id: "equal" }],
  PRODUCT_ID: [{ label: "Es", id: "equal" }],
  TRACKING_NUMBER: [{ label: "Es", id: "equal" }],
  ORDER_TYPE: [
    {
      label: "Es",
      id: "equal",
      placeholder: "Selecciona el tipo",
      options: options[ORDER_TYPE],
    },
  ],
  SHOPIFY_ORDER_TYPE: [
    {
      label: "Es",
      id: "equal",
      placeholder: "Selecciona el estatus",
      options: options[SHOPIFY_ORDER_TYPE],
    },
  ],
  DATE: [
    { label: "Rango de fechas", id: "between", dateRange: true },
    { label: "Antes de", id: "before", date: true },
    { label: "Después de", id: "after", date: true },
  ],
  PURCHASE_DATE: [
    { label: "Rango de fechas y hora", id: "between", dateRangeHour: true },
    { label: "Antes de", id: "before", date: true },
    { label: "Después de", id: "after", date: true },
  ],
  TO_NAME: [
    { label: "Es", id: "equal" },
    { label: "No es", id: "not_equal" },
    { label: "Contiene", id: "has" },
    { label: "No contiene", id: "not_has" },
  ],
  DESTINATION: [
    { label: "Contiene", id: "has" },
    { label: "No contiene", id: "not_has" },
  ],
  SERVICE: [
    {
      label: "Es",
      id: "equal",
      placeholder: "Selecciona servicio",
      options: options[SERVICE],
    },
    {
      label: "No es",
      id: "not_equal",
      placeholder: "Selecciona servicio",
      options: options[SERVICE],
    },
    { label: "Contiene", id: "has" },
    { label: "No contiene", id: "not_has" },
  ],
  AMOUNT: [
    { label: "Es", id: "equal" },
    { label: "Es mayor a", id: "more_than" },
    { label: "Es menor a", id: "less_than" },
  ],
  ORIGIN: [
    { label: "Contiene", id: "has" },
    { label: "No contiene", id: "not_has" },
  ],
  FROM_NAME: [
    { label: "Es", id: "starts_with" },
    { label: "No es", id: "not_equal" },
    { label: "Contiene", id: "has" },
    { label: "No contiene", id: "not_has" },
  ],
  MARKETPLACE: [
    {
      label: "Proviene de",
      id: "equal",
      placeholder: "Marketplace",
      options: options[MARKETPLACE],
    },
    {
      label: "No proviene de",
      id: "not_equal",
      placeholder: "Marketplace",
      options: options[MARKETPLACE],
    },
  ],
  OVERWEIGHT: [
    { label: "Con sobrepeso", id: "equal", type: "radio" },
    { label: "Sin sobrepeso", id: "not_equal", type: "radio" },
  ],
  STATUS: [
    {
      label: "Es",
      id: "equal",
      placeholder: "Selecciona el Estatus",
      options: options[STATUS],
    },
  ],
};
