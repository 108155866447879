import React from 'react';
import { Row, Column } from 'hedron';
import boxLogin from './boxLogin.svg';
import styled from 'styled-components';

import './index.css';

export const Line = styled.div`
  width: 100%;
  border: solid 1px #f3f3f3;
`;

class LoginContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div style={{ width: '100%', height: '100%', background: '#fff' }}>
                <Row divisions={24}>
                    <Column className="login-left-pane" xs={24} md={12} fluid>
                        <div className="login-form-div">
                            {this.props.children}
                        </div>
                    </Column>
                    <Column className="login-right-pane" xs={24} md={12} fluid>
                        <img src={boxLogin} />
                    </Column>
                </Row>
            </div>
        );
    }
}

export default LoginContainer
