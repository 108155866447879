// @flow
import React from "react";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Redirect } from "react-router-dom";
import { boundMethod } from "autobind-decorator";
import Cookies from "universal-cookie";
import { Row, Column } from "hedron";

import GoogleLogin from "react-google-login";
import { ToastContainer, toast } from "react-toastify";
import { login, getUrlParameter } from "../../modules/user";
import { hideMessage } from "../../modules/message";
import { hideLoader } from "../../modules/loader";
import Text from "../Text";
import Button from "../Button";
import Input from "../Input";
import Logo from "../static/Logo";
import Message from "../Message";
import sliceGreen from "./img/green-slice.svg";
import slicePink from "./img/slice-pink.png";
import "./index.css";
import LoginContainer from "./container";

import type { MessageType } from "../../constants/customTypes";

type DefaultProps = {
  message: MessageType,
};

export type Props = {
  ...DefaultProps,
  login: () => void,
  hideMessage: () => void,
  goToSignup: () => void,
  goToRequestPassword: () => void,
};

type State = {
  email: string,
  emailError: string,
  password: string,
  passwordError: string,
};

class Login extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    message: {
      messageType: "",
      show: false,
      text: "",
    },
  };

  state: State = {
    email: "",
    emailError: "",
    password: "",
    passwordError: "",
  };

  @boundMethod
  isLoggedIn() {
    if (localStorage.getItem("token")) {
      return true;
    }
    return false;
  }

  responseGoogle = (response) => {
    const { email, givenName, familyName, googleId } = response.profileObj;
    this.loginGoogle(email, googleId, givenName, familyName);
  };

  responseGoogleError = (response) => {
    // this.notify();
  };

  componentWillMount() {
    this.props.hideLoader();
  }

  notify = () => {
    toast.error("Error al iniciar sesión", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  getUrlParameter = (name: string) => {
    const aux = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${aux}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  redirectLogin() {
    const { getUrlParameter } = this;
    if (getUrlParameter("shop")) {
      localStorage.setItem("shopify_store", getUrlParameter("shop"));
      window.location = "/integraciones";
    } else {
      window.location = "/";
    }
  }

  loginGoogle(email, google_auth_id, givenName, familyName) {
    const { goRegisterGoogle } = this.props;
    const context = this;
    axios
      .post("/api/auth", {
        email,
        google_auth_id,
      })
      .then((response) => {
        const cookies = new Cookies();
        cookies.set("active_session", "true", { path: "/" });
        const token: string =
          response && response.headers ? response.headers["access-token"] : "";
        localStorage.setItem("token", token);
        context.redirectLogin();
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors[0] ===
            "La autenticación con Google no es válida."
        ) {
          goRegisterGoogle(email, givenName, familyName, google_auth_id);
        }
      });
  }

  render() {
    const {
      message,
      hideMessage,
      goToSignup,
      goToRequestPassword,
      login,
      goToOperationalLogin,
    } = this.props;

    const { email, password } = this.state;

    if (this.isLoggedIn()) {
      return <Redirect to="/" />;
    }
    if (
      message.show &&
      (message.messageType === "error" || message.messageType === "success")
    ) {
      setTimeout(hideMessage, 5000);
    }
    return (
      <div className="cont-login">
        <ToastContainer />
        <div className="login-card">
          <Row className="row">
            <Column xs={12} md={6} fluid>
              <div className="container-inputs">
                <Logo />
                <br />
                {message.show ? (
                  <Message color={message.messageType} onClose={hideMessage}>
                    {message.text}
                  </Message>
                ) : null}
                <Input
                  type="text"
                  onChange={(email: string) => {
                    this.setState({ email });
                  }}
                  value={email}
                  required
                  placeholder="micorreo@gmail.com"
                  label="Correo electrónico"
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      login(email, password);
                    }
                  }}
                />
                <Input
                  type="password"
                  onChange={(password: string) => {
                    this.setState({ password });
                  }}
                  value={password}
                  required
                  placeholder="*******"
                  label="Contraseña"
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      login(email, password);
                    }
                  }}
                />
                <Button
                  className="forgot-link"
                  type="link"
                  onClick={() => goToRequestPassword()}
                >
                  Olvidé mi contraseña
                </Button>
                <Button
                  className="login-btn-login"
                  onClick={() => {
                    login(email, password);
                  }}
                >
                  Ingresar
                </Button>
              </div>
            </Column>
            <Column xs={12} md={6} fluid>
              <div className="right">
                <p className="title">Bienvenido</p>
                <p className="subtitle">
                  Ahorra tiempo y dinero optimizando
                  <br /> tu logística
                </p>
                <div
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send?phone=5214423783099&text=hola,%20bienvenido%20a%20mienvio.mx%20%C2%BFc%C3%B3mo%20podemos%20ayudarte",
                      "blank"
                    )
                  }
                  className="register"
                >
                  <p>Rastrear Paquete</p>
                </div>
                <p className="question">¿Nuevo en Mienvío?</p>
                <div onClick={() => goToSignup()} className="register">
                  <p>Registrate aquí</p>
                </div>
                <Button
                  className="forgot-link left-link"
                  type="link"
                  onClick={() => goToOperationalLogin()}
                >
                  Login de usuarios operacionales
                </Button>
                <img className="green" src={sliceGreen} />
                <img className="pink" src={slicePink} />
              </div>
            </Column>
          </Row>
        </div>
        {/* <LoginContainer>
          <ToastContainer />
          <Logo />
          <br />
          {message.show ? (
            <Message
              color={message.messageType}
              onClose={hideMessage}
            >
              {message.text}
            </Message>
          ) : null}
          <Text type="textTable">¿Nuevo en mienvío? </Text>
          <Button
            style={{ marginLeft: '8px' }}
            type="link"
            onClick={() => goToSignup()}
          >
          Crea tu cuenta aquí
          </Button>
          <br />
          <Input
            type="text"
            onChange={(email: string) => {
              this.setState({ email });
            }}
            value={email}
            required
            placeholder="micorreo@gmail.com"
            label="Correo electrónico"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                login(email, password);
              }
            }}
          />
          <Input
            type="password"
            onChange={(password: string) => {
              this.setState({ password });
            }}
            value={password}
            required
            placeholder="*******"
            label="Contraseña"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                login(email, password);
              }
            }}
          />
          <Button
            className="forgot-link"
            type="link"
            onClick={() => goToRequestPassword()}
          >
          Olvidé mi contraseña
          </Button>
          <Button
            className="login-btn"
            onClick={() => {
              login(email, password);
            }}
          >
          Ingresar
          </Button>
          <GoogleLogin
            clientId="84702782983-r5ssv8ugsihjgbe27fu0n7q5t36cd5ju.apps.googleusercontent.com"
            buttonText="Iniciar Sesión con Google"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogleError}
            cookiePolicy="single_host_origin"
            className="btn-google"
          />
        </LoginContainer> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToSignup: (main: string) => push(`/registrar${window.location.search}`),
      goToRequestPassword: () => push("/contrasena"),
      goToOperationalLogin: () => push("/login-operational"),
      goRegisterGoogle: (email, name, lastname, googleId) =>
        push("/registrar", {
          email,
          name,
          lastname,
          googleId,
        }),
      goRoot: () => push("/"),
      goIntegrations: () => push("/"),
      login,
      getUrlParameter,
      hideMessage,
      hideLoader,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
