import axios from 'axios';

export const getPackages = id => axios
  .get(`api/packages/marketplace/${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));


export const createPackages = (pack, id) => axios
  .post(`api/packages/marketplace/${id}`, {
    object_type_code: pack.type,
    object_status_code: 'available',
    alias: pack.alias,
    description: pack.description,
    provider: null,
    height: pack.height,
    width: pack.width,
    length: pack.length,
    weight: pack.weight,
  })
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));


export const editPackages = (pack, id, id_user) => axios
  .put(`${'api/packages' + '/'}${id}/marketplace/${id_user}`, {
    object_type_code: pack.type,
    object_status_code: 'available',
    alias: pack.alias,
    description: pack.description,
    provider: null,
    height: pack.height,
    width: pack.width,
    length: pack.length,
    weight: pack.weight,
  })
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));


export const deletePackages = id => axios
  .delete(`${'api/packages' + '/'}${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));
