import React from "react";
import axios from "axios";
import { Row, Column } from "hedron";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DateRange } from "react-date-range";
import * as rdrLocales from "react-date-range/src/locale";
import Button from "../../components/Button";
import Text, { Title } from "../../components/Text";
import Header from "../../components/Header";
import "./style.scss";
import { ToastContainer, toast } from "react-toastify";
import NewBadge from "../../components/Badge/insuranceBadge";
import AsyncSelect from 'react-select/async';
import {
  getCountries,
} from "../../modules/sidebarOrder/actions";
import "./style.css";
import Message from "../../components/Message";
import DataTable from 'react-data-table-component';
import { API_GLOBAL_OPERATIONXCB, BASEURL } from "../../constants";

const today = new Date();
const tomorrow = new Date();

class StatusReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      isAdmin: false,
      actionDownload: '#',
      nameFile: '',
      dataShipment: null,
      dataShipments: [],
      optionsUser: [],
      errorUser: false,
      btnLoading: false,
      carriers: [],
      searchRange: false,
      selectionRange: {
        selection: {
          startDate: today,
          endDate: tomorrow,
          key: "selection",
        },
      },
    };

    this.searchShipments = this.searchShipments.bind(this);
    // this.loadOptions = this.loadOptions.bind(this);
    this.handleChangeDataShipment = this.handleChangeDataShipment.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const isAdmin = false;
    const token = this.props.match.params.token
    await this.props.getCountries();

    if (token == '') {
      return <Redirect to="/login" />
    }

    // const tokenaxios = `Bearer ${API_GLOBAL_OPERATIONXCB}`;
    const tokenaxios = `Bearer ${token}`;
    axios.defaults.headers.common.Authorization = tokenaxios;

    this.searchShipments();

    if (!tokenaxios) {
      toast.error("El token de usuario no existe", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }

  }

  handleChangeDataShipment(event) {
    this.setState({
      dataShipment: event.target.value
    });
  }

  /* getCarriers() {
    const token = location.search.slice(1).split("&")[0].split("=")[1];
    if (!token) return;
    const tokenaxios = `Bearer ${token}`;
    axios.defaults.headers.common.Authorization = tokenaxios;
    axios
      .get("/api/providers")
      .then((response) => {
        if (response.data && response.data.length !== 0) {
          this.setState({ carriers: response.data });
        }
      })
      .catch((error) => { });
  } */

  searchShipments() {
    const context = this;

    if (this.state.openDate) {
      this.setState({ openDate: !this.state.openDate });
    }

    this.setState({
      dataShipments: [],
      actionDownload: '#',
      pending: true,
    });


    let start = null
    let end = null
    let dataShipments = [];
    const dataShipment = this.state.dataShipment;

    if (this.state.searchRange) {
      start = this.formatDate(this.state.selectionRange.selection.startDate);
      end = this.formatDate(this.state.selectionRange.selection.endDate);
    }

    axios
      .post(`/api/report_by_substatus`, {
        fecha_inicial: start,
        fecha_final: end,
        data_shipment: dataShipment,
        id_users: this.state.optionsUser,
        country: this.state.country
      })
      .then((response) => {

        dataShipments = response.data.data
        console.log('response ::', dataShipments);
        this.setState({
          dataShipments,
          searchRange: false,
          pending: false,
          actionDownload: response.data.file,
          nameFile: response.data.name
        });
        if (dataShipments.length === 0) {
          toast.info("Sin registros para la busqueda", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .catch((error) => {
        toast.info("Sin registros para la busqueda", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
  }

  formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join("-");
  }

  badgeLabel = (status) => {
    let label = "";
    let type = "";
    if (status) {
      switch (status) {
        case "INCIDENCIA":
          label = "Incidencia";
          type = "error";
          break;
        case "MANUAL_LABEL":
          label = "Manual";
          type = "warning";
          break;
        default:
          label = status
          type = "correct";
          break;
      }
    }
    return {
      type: type,
      label: label,
    };
  };

  downloadCSV() { }

  render() {

    const { dataShipments, btnLoading, errorUser, pending, actionDownload, nameFile } = this.state;
    const { message, hideMessage } = this.props;

    if (errorUser) {
      return (
        <div className="white-bg">
          <ToastContainer />
        </div>
      );
    }

    const columnsExpanded = [
      {
        name: 'Fecha de orden',
        selector: 'fecha_orden',
        cell: row => row.fecha_orden,
      },
      {
        name: 'Pais ',
        selector: 'pais',
        sortable: true,
        cell: row => row.pais,
      },
      {
        name: 'Nivel 1',
        selector: 'nivel1',
        cell: row => <div>{row.nivel1}</div>,
      },
      {
        name: 'Nivel 2',
        selector: 'nivel2',
        cell: row => <div>{row.nivel1}</div>,
      },
      {
        name: 'Nivel 3',
        selector: 'nivel3',
        cell: row => <div>{row.nivel3}</div>,
      },
      {
        name: 'Cuenta',
        selector: 'cuenta',
        cell: row => <div>{row.cuenta}</div>,
      },
      {
        name: 'Orden',
        selector: 'orden_tienda',
        cell: row => row.orden_tienda,
      },
      {
        name: 'Cotizacion',
        selector: 'num_cotizacion',
        cell: row => row.num_cotizacion,
      },
      {
        name: 'Guia',
        selector: 'guia_despacho',
        cell: row => row.guia_despacho,
      },
      {
        name: 'Servicio',
        selector: 'servicio',
        sortable: false,
        cell: row => (
          <div>
            <p>{row.paqueteria}</p>
            <p className="minitext">{row.servicio}</p>
          </div>
        )
      },
      {
        name: 'Estado',
        selector: 'estado',
        sortable: false,
        cell: row => {
          const badge = this.badgeLabel(row.estado)
          return <NewBadge type={badge.type}>{badge.label}</NewBadge>
        }
      },
      {
        name: 'Subestado',
        selector: 'subestado',
        sortable: false,
        cell: row => {
          const badge = this.badgeLabel(row.subestado)
          return <NewBadge type={badge.type}>{badge.label}</NewBadge>
        }
      },
      {
        name: 'Fecha de evento',
        selector: 'fecha_evento',
        sortable: true,
        cell: (row) => row.fecha_evento,
      }
    ];

    const paginationTranslate = {
      rowsPerPageText: 'Filas por Pagina',
      rangeSeparatorText: 'de',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Todos'
    };

    const styleInput = {
      width: "100%",
      maxWidth: "100%",
      fontFamily: "Muli",
      height: "36px",
      maxHeight: "36px",
      boxSizing: "border-box",
      fontSize: "16px",
      fontWeight: "400",
      background: "transparent",
      color: "#4A4A4A",
      display: "inline-block",
      border: "1.5px solid #CAD2DF",
      borderRadius: "2px",
      padding: "8px",
      paddingRight: "8px",
      paddingLeft: "8px",
      margin: "12px 0"
    };

    // handle selection
    const handleChange = value => {
      let idUsers = value.map((item) => {
        return item.id;
      });

      this.setState({ optionsUser: idUsers });
    }

    const handleChangeCountry = (value) => {
      this.setState({ country: value.code });
    }

    const loadOptionsCountry = async (input) => {
      return this.props.sidebarOrder.countries
    }

    const loadOptions = (inputValue) => {
      let responseF = fetch(
        `${BASEURL}api/users/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + API_GLOBAL_OPERATIONXCB
        },
        body: JSON.stringify({ needle_user: inputValue })
      }).then((res) => {
        let response = res.json();
        return response;
      });

      return responseF;
    };

    return (
      <div style={{ background: "#FFF" }}>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Header>
              <Row divisions={18}>
                <Column xs={4} fluid>
                  <Title type="titleCard">Reporte de envíos</Title>
                </Column>
              </Row>
            </Header>
            <Row>
              <Column xs={12} md={4} fluid>
                <input placeholder="Cotizacion u Orden o Guia" type="text" style={styleInput} onChange={this.handleChangeDataShipment} />
              </Column>
              {this.props.match.params.token == API_GLOBAL_OPERATIONXCB && (
                <Column xs={12} md={4} fluid>
                  <AsyncSelect
                    cacheOptions
                    placeholder={"Cuenta o email"}
                    defaultOptions
                    isMulti
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e.id}
                    loadOptions={loadOptions}
                    onChange={handleChange}
                  />
                </Column>
              )}

              {/* <Column xs={12} md={4} fluid>
                <input placeholder="Proveedor" type="text" style={styleInput} />
              </Column> */}
              <Column xs={12} md={4} fluid>
                <div className="btn-date" onClick={() => this.setState({ openDate: !this.state.openDate })} style={{ margin: "12px 0" }}>
                  Rango de Fechas
                </div>
                {this.state.openDate && (<div className="container-date-range">
                  <DateRange
                    ranges={[this.state.selectionRange.selection]}
                    onChange={(value) => this.setState({ selectionRange: value, searchRange: true })}
                    color="#2576DA"
                    locale={rdrLocales.es}
                  />
                </div>
                )}
              </Column>
              <Column xs={12} md={4} fluid>
                <AsyncSelect
                      placeholder={"País"}
                      defaultOptions
                      getOptionLabel={e => e.name}
                      getOptionValue={e => e.object_id}
                      loadOptions={loadOptionsCountry}
                      onChange={handleChangeCountry}
                  />
              </Column>
              <Column xs={12} md={4} fluid>
                <Button
                  className="no-margin"
                  loading={btnLoading}
                  onClick={this.searchShipments}>
                  Buscar
                </Button>
              </Column>
              <Column xs={12} md={4} fluid>
                {((actionDownload == '#') ? '' : <a class="gXSgsR" href={actionDownload} download={nameFile}>Descargar Excel</a>)}
              </Column>
            </Row>
            <Row divisions={20}>
              <Column xs={1} fluid />
              <Column xs={18} fluid>
                {message.show && (
                  <Message color={message.messageType} onClose={hideMessage}>
                    {message.text}
                  </Message>
                )}
              </Column>
              <Column xs={1} fluid />
            </Row>
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid></Column>
          <Column xs={18} fluid>
            <DataTable
              columns={columnsExpanded}
              data={dataShipments}
              progressPending={pending}
              progressComponent={'Cargando datos...'}
              pagination
              paginationComponentOptions={paginationTranslate}
            />
          </Column>
          <Column xs={1} fluid></Column>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
  user: state.user,
  sidebarOrder: state.newShipment,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCountries,
      gotLogIn: () => push("/login"),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StatusReport);