import React from "react";
import styledProps from "styled-props";
import PropTypes from "prop-types";
import { Row, Column } from "hedron";
import styled from "styled-components";
import colors from "../../utils/colors";
import Text from "../Text";
import "./style.css";

const status = {
  error: colors.state.tomato,
  default: colors.newcolors.light_gray,
  disabled: colors.gray.snow,
};

const statusFocus = {
  error: colors.state.tomato,
  default: colors.state.blue,
  disabled: colors.gray.snow,
};

const background = {
  default: "transparent",
  error: "transparent",
  disabled: colors.gray.snow,
};

const padding = {
  default: "8px",
  maxLength: "34px",
};

const paddingLeft = {
  default: "8px",
  icon: "31px",
};

const margin = {
  default: "12px",
  maxLength: "0.75rem",
};

const WholeInput = styled.div`
  width: 100%;
  position: relative !important;
`;

const StyledInput = styled.input`
  outline: none !important;
  width: 100%;
  max-width: 100%;
  font-family: "Muli";
  height: 36px;
  max-height: 36px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  background: ${styledProps(background, "status")};
  color: ${colors.gray.charcoal};
  display: inline-block;
  border: 1.5px solid ${styledProps(status, "status")};
  border-radius: 2px;
  padding: 8px;
  padding-right: ${styledProps(padding, "padding")};
  padding-left: ${styledProps(paddingLeft, "paddingLeft")};
  margin: 12px 0;
  transition: 200ms;
  &.hasSuffix {
    width: calc(80% - 0.5rem);
    margin-right: 0.5rem;
  }
  &::placeholder {
    color: ${colors.gray.placeholder};
  }
  &:focus {
    border-color: ${styledProps(statusFocus, "status")};
    background-color: ${colors.secondary.ultralight_blue};
  }
`;
StyledInput.defaultProps = {
  status: "default",
  padding: "default",
  paddingLeft: "default",
};

const InputContainer = styled.div`
  margin-top: 12px;
  position: relative !important;

  label {
    text-algin: left;
  }
`;

const requiredBackground = {
  default: colors.state.silver,
  focus: colors.state.blue,
  error: colors.state.tomato,
};

const Required = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 100px;
  margin-bottom: 3px;
  background: ${styledProps(requiredBackground, "status")};
  display: inline-block;
  margin-left: 4px;
  transition: 200ms;
`;
Required.defaultProps = {
  status: "default",
};

const Label = (props) => (
  <Text
    type="label"
    style={{
      fontWeight: "bold",
      color: colors.gray.input_icon,
      ...props.style,
    }}
    className={props.className}
  >
    {props.children}
  </Text>
);

const HelpText = (props) => (
  <Text type="helpTextModal" className={props.className} style={props.style}>
    {props.children}
  </Text>
);

const lengthColor = {
  default: colors.gray.creamy_gray,
  error: colors.state.tomato,
};

const StyledLengthText = styled.div`
  font-weight: 600;
  width: auto;
  max-width: 100%;
  display: inline-block !important;
  position: absolute !important;
  right: 0.5rem !important;
  top: 2rem !important;
  text-align: right;
  box-sizing: border-box;
  font-size: 12px;
  letter-spacing: 0.2625px;
  margin: 0;
  line-height: 36px;
  z-index: 1;
  color: ${styledProps(lengthColor, "status")};
  background: transparent;
`;
StyledLengthText.defaultProps = {
  status: "default",
};

const StyledI = styled.div`
  font-size: 18px;
  color: ${colors.gray.input_icon};
  position: absolute;
  bottom: 20px;
  left: 8px;
`;

const StyledPrefix = styled.div`
  font-size: 18px;
  color: ${colors.gray.input_icon};
  display: inline-block;
  position: absolute;
  bottom: 20px;
  left: 8px;
  font-size: 16px;
`;

const StyledSuffix = styled.div`
  font-size: 18px;
  width: 20%;
  color: ${colors.gray.input_icon};
  display: inline-block;
  bottom: 20px;
  right: 8px;
  font-size: 16px;
`;

const LengthText = (props) => (
  <StyledLengthText status={props.status}>{props.children}</StyledLengthText>
);

const I = (props) => (
  <StyledI>
    <i className={props.className} />
  </StyledI>
);

const Prefix = (props) => (
  <StyledPrefix className={props.className}>{props.children}</StyledPrefix>
);
const Suffix = (props) => (
  <StyledSuffix className={props.className}>{props.children}</StyledSuffix>
);

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      max: parseInt(props.maxLength, 10),
      maxError: false,
      len: 0,
    };
  }
  componentDidMount(): void {
    if (this.props.value) {
      this.setState({
        len: this.props.value.length,
        maxError: this.props.value.length > this.state.max,
      });
    }
    if (this.props.focus) {
      //this.nameInput.focus();
    }
  }

  handleFocus = (e, key) => {
    this.setState({ focus: true });
    try {
      this.props.onFocus(e.target.value);
    } catch (e) {
      // console.log("Not 'onFocus' function on this field");
    }
  };

  handleBlur = (e, key) => {
    this.setState({ focus: false });
    try {
      this.props.onBlur(e.target.value);
    } catch (e) {
      // console.log("Not 'onBlur' function on this field");
    }
  };

  handleChange = (e, key) => {
    if (this.state.max) {
      let len = e.target.value.length;
      if (len > this.state.max) {
        this.setState({ maxError: true });
      }
      this.setState({ len });
    } else if (this.props.dimensions) {
      return this.props.onChange(e.target.value, key);
    }
    this.props.onChange(e.target.value);
  };

  render() {
    const props = this.props;
    if (this.props.dimensions) {
      return (
        <InputContainer
          style={props.containerStyle}
          className={props.parentClassName}
        >
          <Label>{props.label}</Label>
          {props.required ? (
            <Required
              status={
                props.error ? "error" : this.state.focus ? "focus" : "default"
              }
            />
          ) : null}
          {this.state.max ? (
            <LengthText
              status={this.state.len > this.state.max ? "error" : "default"}
            >
              {this.state.max - this.state.len}
            </LengthText>
          ) : null}
          <Row>
            <Column xs={3} fluid>
              <StyledInput
                required={props.required}
                autoFocus={this.props.focus ? true : false}
                onKeyPress={props.onKeyPress}
                onChange={(e) => this.handleChange(e, "length")}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                disabled={props.disabled}
                status={
                  props.error
                    ? "error"
                    : props.disabled
                    ? "disabled"
                    : "default"
                }
                padding={this.state.max ? "maxLength" : "default"}
                style={props.style}
                className={props.className}
                type={props.type}
                placeholder={props.placeholder}
                value={props.value ? props.value.length : ""}
                autoComplete={props.autoComplete ? props.autoComplete : ""}
              />
            </Column>
            <Column
              xs={1}
              fluid
              style={{ height: "55px", position: "relative" }}
            >
              <Text
                className={`dimensions-times ${
                  props.textClass && props.textClass
                }`}
              >
                x
              </Text>
            </Column>
            <Column xs={3} fluid>
              <StyledInput
                required={props.required}
                autoFocus={this.props.focus ? true : false}
                onKeyPress={props.onKeyPress}
                onChange={(e) => this.handleChange(e, "width")}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                disabled={props.disabled}
                status={
                  props.error
                    ? "error"
                    : props.disabled
                    ? "disabled"
                    : "default"
                }
                padding={this.state.max ? "maxLength" : "default"}
                style={props.style}
                className={props.className}
                type={props.type}
                placeholder={props.placeholder}
                value={props.value ? props.value.width : ""}
                autoComplete={props.autoComplete ? props.autoComplete : ""}
              />
            </Column>
            <Column
              xs={1}
              fluid
              style={{ height: "55px", position: "relative" }}
            >
              <Text
                className={`dimensions-times ${
                  props.textClass && props.textClass
                }`}
              >
                x
              </Text>
            </Column>
            <Column xs={3} fluid>
              <StyledInput
                required={props.required}
                autoFocus={this.props.focus ? true : false}
                onKeyPress={props.onKeyPress}
                onChange={(e) => this.handleChange(e, "height")}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                disabled={props.disabled}
                status={
                  props.error
                    ? "error"
                    : props.disabled
                    ? "disabled"
                    : "default"
                }
                padding={this.state.max ? "maxLength" : "default"}
                style={props.style}
                className={props.className}
                type={props.type}
                placeholder={props.placeholder}
                value={props.value ? props.value.height : ""}
                autoComplete={props.autoComplete ? props.autoComplete : ""}
              />
            </Column>
            <Column
              xs={1}
              fluid
              style={{ height: "55px", position: "relative" }}
            >
              <Text
                className={`dimensions-times ${
                  props.measureClass && props.measureClass
                }`}
              >
                cm
              </Text>
            </Column>
          </Row>
          {props.error || this.state.len > this.state.max ? (
            <HelpText>
              {props.error
                ? props.error
                : `Máximo ${this.state.max} caracteres`}
            </HelpText>
          ) : props.help ? (
            <HelpText>{props.help}</HelpText>
          ) : null}
        </InputContainer>
      );
    }
    if (this.props.costos) {
      return (
        <InputContainer
          style={props.containerStyle}
          className={props.parentClassName}
        >
          <Label>{props.label}</Label>
          {props.required ? (
            <Required
              status={
                props.error ? "error" : this.state.focus ? "focus" : "default"
              }
            />
          ) : null}
          {this.state.max ? (
            <LengthText
              status={this.state.len > this.state.max ? "error" : "default"}
            >
              {this.state.max - this.state.len}
            </LengthText>
          ) : null}
          <Row>
            <Column xs={5} fluid>
              <StyledInput
                required={props.required}
                autoFocus={this.props.focus ? true : false}
                onKeyPress={props.onKeyPress}
                onChange={(e) => this.handleChange(e, "length")}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                disabled={props.disabled}
                status={
                  props.error
                    ? "error"
                    : props.disabled
                    ? "disabled"
                    : "default"
                }
                padding={this.state.max ? "maxLength" : "default"}
                style={props.style}
                className={props.className}
                type={props.type}
                placeholder={"0.00"}
                value={props.value ? props.value.length : ""}
                autoComplete={props.autoComplete ? props.autoComplete : ""}
              />
            </Column>
            <Column
              xs={1}
              fluid
              style={{ height: "55px", position: "relative" }}
            >
              <Text
                className={`dimensions-times ${
                  props.textClass && props.textClass
                }`}
              >
                -
              </Text>
            </Column>
            <Column xs={5} fluid>
              <StyledInput
                required={props.required}
                autoFocus={this.props.focus ? true : false}
                onKeyPress={props.onKeyPress}
                onChange={(e) => this.handleChange(e, "width")}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                disabled={props.disabled}
                status={
                  props.error
                    ? "error"
                    : props.disabled
                    ? "disabled"
                    : "default"
                }
                padding={this.state.max ? "maxLength" : "default"}
                style={props.style}
                className={props.className}
                type={props.type}
                placeholder={"100.00"}
                value={props.value ? props.value.width : ""}
                autoComplete={props.autoComplete ? props.autoComplete : ""}
              />
            </Column>
          </Row>
          {props.error || this.state.len > this.state.max ? (
            <HelpText>
              {props.error
                ? props.error
                : `Máximo ${this.state.max} caracteres`}
            </HelpText>
          ) : props.help ? (
            <HelpText>{props.help}</HelpText>
          ) : null}
        </InputContainer>
      );
    }
    return (
      <InputContainer className={props.parentClassName}>
        {props.customInput ? (
          <div>{props.label && <Label>{props.label}</Label>}</div>
        ) : (
          <Label>{props.label}</Label>
        )}
        {props.required ? (
          <Required
            status={
              props.error ? "error" : this.state.focus ? "focus" : "default"
            }
          />
        ) : null}
        {this.state.max ? (
          <LengthText
            status={this.state.len > this.state.max ? "error" : "default"}
          >
            {this.state.max - this.state.len}
          </LengthText>
        ) : null}
        <WholeInput>
          {this.props.icon ? (
            <I className={`custom-input-icon ${this.props.icon}`} />
          ) : null}
          {this.props.prefix ? (
            <Prefix
              className={`custom-input-icon ${
                !props.label && "no-label-prefix"
              } ${props.tableInput && "table-custom-input"}`}
            >
              {this.props.prefix}
            </Prefix>
          ) : null}
          <StyledInput
            required={props.required}
            autoFocus={this.props.focus ? true : false}
            ref={(input) => {
              this.nameInput = input;
            }}
            paddingLeft={
              this.props.icon || this.props.prefix ? "icon" : "default"
            }
            onKeyPress={props.onKeyPress}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            disabled={props.disabled}
            status={
              props.error
                ? "error"
                : this.props.value && this.props.value.length > this.state.max
                ? "error"
                : props.disabled
                ? "disabled"
                : "default"
            }
            padding={this.state.max ? "maxLength" : "default"}
            style={props.style}
            className={`${props.className && props.className} ${
              this.props.suffix && "hasSuffix"
            }`}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            autoComplete={props.autoComplete ? props.autoComplete : ""}
          />
          {this.props.suffix ? <Suffix>{this.props.suffix}</Suffix> : null}
        </WholeInput>
        <HelpText
          className={"animated-help-text " + (props.error ? "open" : "")}
        >
          {props.error}
        </HelpText>
        <HelpText
          className={
            "animated-help-text " +
            (props.help && this.state.focus ? "open" : "")
          }
        >
          {props.help}
        </HelpText>
        <HelpText
          className={
            "animated-help-text " +
            (this.state.len > this.state.max ? "open" : "")
          }
        >{`Máximo ${this.state.max} caracteres`}</HelpText>
      </InputContainer>
    );
  }
}

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  autoComplete: PropTypes.string,
};

export default Input;
export { Label, HelpText, Required };
