export const TOGGLE = 'TOGGLE';
export const SET_STEP_TITLES = 'SET_STEP_TITLES';
export const SET_STEP = 'SET_STEP';
export const SET_ICON_ANY = 'SET_ICON_ANY';
export const PREVIOUS_STEP = 'PREVIOUS_STEP';
export const NEXT_STEP = 'NEXT_STEP';
export const SET_VALUE = 'SET_VALUE';
export const SET_ERROR = 'SET_ERROR';
export const UNSET_ERROR = 'UNSET_ERROR';
export const SET_ICON = 'SET_ICON';
export const GET_ADDRESSES_SIDEBAR = 'GET_ADDRESSES_SIDEBAR';
export const INIT_LOAD_ADDRESSES = 'INIT_LOAD_ADDRESSES';
export const FINISH_LOAD_ADDRESSES = 'FINISH_LOAD_ADDRESSES';
export const SELECT_ADDRESS_FROM = 'SELECT_ADDRESS_FROM';
export const CLEAR_ADDRESS_FROM = 'CLEAR_ADDRESS_FROM';
export const SELECT_ADDRESS_TO = 'SELECT_ADDRESS_TO';
export const CLEAR_ADDRESS_TO = 'CLEAR_ADDRESS_TO';
export const GET_PACKAGES_SIDEBAR = 'GET_PACKAGES_SIDEBAR';
export const SELECT_PACKAGE = 'SELECT_PACKAGE';
export const CLEAR_PACKAGE = 'CLEAR_PACKAGE';
export const CLEAR_SIDEBAR = 'CLEAR_SIDEBAR';
export const INIT_GETTING_RATES = 'INIT_GETTING_RATES';
export const FINISH_GETTING_RATES = 'FINISH_GETTING_RATES';
export const ERROR_GETTING_RATES = 'ERROR_GETTING_RATES';
export const CHANGE_LOADING_COST = 'CHANGE_LOADING_COST';
export const SEND_INFO_DUPLICATE_ORDER = 'SEND_INFO_DUPLICATE_ORDER';
export const SEND_INFO_EDIT_ORDER = 'SEND_INFO_EDIT_ORDER';
export const SET_OLD_INFO = 'SET_OLD_INFO';
export const SET_OLD_RATE = 'SET_OLD_RATE';
export const GENERATE_LABEL_MARKETPLACE = 'GENERATE_LABEL_MARKETPLACE';
export const IS_LOADING_ZIPCODE = 'IS_LOADING_ZIPCODE';
export const CHANGE_MANUAL_INFO = 'CHANGE_MANUAL_INFO';
export const CHANGE_LOADING_SIDEBAR = 'CHANGE_LOADING_SIDEBAR';
export const CHANGE_STEP_ERROR = 'CHANGE_STEP_ERROR';
export const OPEN_COMMERCIAL_INVOICE = 'OPEN_COMMERCIAL_INVOICE';
export const CLOSE_COMMERCIAL_INVOICE = 'CLOSE_COMMERCIAL_INVOICE';
export const COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE';
export const GET_COUNTRIES_SIDEBAR_ORDER = 'GET_COUNTRIES_SIDEBAR_ORDER';
export const SEND_INFO_DEVOLUTION = 'SEND_INFO_DEVOLUTION';
