import React from 'react';
import Td from './tdInput';
import { Tr } from '../Table';

const Package = ({
  no,
  units,
  unitMeasure,
  description,
  tariff,
  country,
  value,
  total,
  onDelete,
  totalPackages
}) => (
  <Tr>
    <Td model={no} disabled={true} />
    <Td model={units} />
    <Td model={unitMeasure} />
    <Td model={description} />
    <Td model={tariff} />
    <Td model={country} />
    <Td model={value} />
    <Td model={total} />
    {totalPackages > 1 && <Td last onDelete={onDelete} />}
  </Tr>
);

export default Package;
