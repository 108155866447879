import React from "react";
import axios from "axios";
import * as rdrLocales from "react-date-range/src/locale";
import styled from "styled-components";
import { boundMethod } from "autobind-decorator";
import { Row, Column } from "hedron";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DateRange } from "react-date-range";
import moment from "moment/min/moment-with-locales";
import Filter from "../../Filter";
import Button, { ActionButtonGroup } from "../../ButtonGroup";
import Text, { Title, P } from "../../Text";
import Input, { Label, Required, HelpText } from "../../Input";
import Tabs, { Tab } from "../../Tabs";
import Card, { Divider } from "../../Card";
import NewBadge from "../../Badge/insuranceBadge.js";
import Badge, { MarketplaceBadge } from "../../Badge";
import EmpyState from "../../EmptyState";
import NewTable from "../../NewTable";
import packageIcon from "./icons/packages.svg";
import close_icon from "../../WrapperSideBar/img/close.svg";
import {
  jsUcfirst,
  numberWithCommas,
  getVolumetricWeight,
} from "../../../utils/global_functions";
import SidebarTitleMenu from "../../WrapperSideBar/extraComponents/SidebarTitleMenu";
import from_icon from "./icons/origen_icon.svg";
import to_icon from "./icons/destino_icon.svg";
import package_icon from "./icons/paquete_icon.svg";
import tracking_icon from "./icons/rastreo_icon.svg";
import provider_icon from "./icons/servicio_icon.svg";
import substract_icon from "./icons/substract_icon.svg";
import "../../../utils/index.css";
import { BASEURL, CANCEL_STATUS_KEY } from "../../../constants/index";
import Arrow from "../../../containers/quotations/Filters/left-arrow.svg";
import Calendar from "../../../containers/quotations/Filters/calendar.svg";
import Contract from "../../../containers/quotations/Filters/contract.svg";
import Map from "../../../containers/quotations/Filters/placeholder.svg";
import Carrier from "../../../containers/quotations/Filters/trolley.svg";

import "./shipments.css";

import Dropdown from "../../Dropdown";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import WrapperSideBar from "../../WrapperSideBar";
import {
  ContentAdapter,
  BottomContainer,
  Spacer,
} from "../../WrapperSideBar/styledObjects";
import Skeleton from "react-loading-skeleton";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../../modules/CollapsibleBar/actions";
import {
  NEW_PACKAGE,
  PACKAGE_DETAILS,
  EDIT_ADDRESS,
  QUICK_QUOTE_TYPE,
  ORDER_DETAILS,
  NEW_SHIPMENT,
  NEW_INTEGRATION,
  CHECKOUT_DETAILS,
  SHIPMENTS_BY_CSV,
} from "../../../modules/CollapsibleBar/sideBarTypes";
import colors from "../../../utils/colors";
import Select from "react-select";
import ThreeDotsDropdown from "../../Dropdown/ThreeDotsDropdown/index";
import edit_icon from "../../../utils/icons/edit.svg";
import delete_icon from "../../../utils/icons/delete.svg";
import { showLoader, hideLoader } from "../../../modules/loader";
import { showMessage, hideMessage } from "../../../modules/message";
import Message from "../../Message";
import { getAllServices } from "../../../axios/services";
import { CheckboxGroup, Checkbox } from "../../Checkbox";
import Switch from "../../Switch";
import Alert from "../../Alert";
import Filters from "./Filters";
import {
  getShipments,
  getShipmentsDetails,
  getShipmentsFilter,
} from "../../../modules/marketplaceAccount/shipments";
import DetailedOrder from "../../ShipmentsList/details/detailed-order";
import CancelModal from "../../ShipmentsList/common/CancelModal";

export const purposesWithTracking = [
  "LABEL_CREATED",
  "ORDER_CREATED",
  "PICKED_UP",
  "TRANSITO",
  "OUT_FOR_DELIVERY",
  "ENTREGADO",
  "RETORNO_EN_PROCESO",
  "CANCELADO",
  "DELIVERY",
  "PURCHASED",
  "GUIA_ENVIADA",
  "LABEL_CREATED|GUIA_ENVIADA",
  "INCIDENCIA",
];

const StyledRate = styled.div`
  padding: 16px 12px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${colors.gray.earth_stone};
  margin-bottom: 16px;
`;

const StyledEmpty = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 5px;
  background: #e4e4e4;
  margin-bottom: 8px;
`;

const I = styled.i`
  cursor: pointer;
  width: 10px;
  height: 5px;
`;

const Collapsible = styled.div`
  cursor: pointer;
  border-left: 4px solid ${colors.gray.creamy_gray};
  padding-left: 37px;
`;

const manual_label = "MANUAL_LABEL";

const today = new Date();
const tomorrow = new Date();


class Shipments extends React.Component {
  state = {
    currentTab: 0,
    filtersOpen: false,
    selectedFilter: null,
    selectionRange: {
      selection: {
        startDate: today,
        endDate: tomorrow,
        key: "selection",
      },
    },
    selectedCountry: null,
    selectedCountryError: "",
    selectedCarrierError: "",
    selectedCountryFlag: false,
    selectedCarrier: null,
    selectedCarrierFlag: false,
    setedFilter: null,
    inputError: "",
    inputValue: null,
    showCancelModal: false
  };

  constructor(props) {
    super(props);
  }

  getCarriers() {
    axios
      .get("/api/providers")
      .then((response) => {
        if (response.data && response.data.length !== 0) {
          this.setState({ carriers: response.data });
        }
      })
      .catch((error) => {});
  }

  _renderCountries = () =>
    this.props.sidebarOrder.countries
      ? this.props.sidebarOrder.countries.map((country) => (
          <Text key={country.object_id}>{country.name}</Text>
        ))
      : null;

  renderCarriers = () =>
    this.state.carriers
      ? this.state.carriers.map((carrier) => (
          <Text key={carrier.id}>{carrier.name}</Text>
        ))
      : null;

  _renderSidebarTitleExtraContent = () => {
    const packOptions = [];

    packOptions.push({
      function: this.toggleEditPackage,
      label: "Editar Envio",
      image: edit_icon,
    });

    packOptions.push({
      function: this.deletePackage,
      label: "Eliminar Envio",
      image: delete_icon,
    });

    switch (this.props.sidebar.type) {
      case PACKAGE_DETAILS:
        return <SidebarTitleMenu linksToRender={packOptions} />;
      default:
        return null;
    }
  };

  _renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
      case ORDER_DETAILS:
        return <ContentAdapter> {this.renderSideBarDetails()}</ContentAdapter>;
      default:
        return <div />;
    }
  };

  formatPhone(phone) {
    const mask = "(XXX) XXX XX XX";
    const s = `${phone}`;
    let r = "";
    for (let i = 0, is = 0; i < mask.length && is < s.length; i++) {
      r += mask.charAt(i) === "X" ? s.charAt(is++) : mask.charAt(i);
    }
    return r;
  }

  @boundMethod
  onCancelOrder(status){
    this.setState({showCancelModal: false}); 
    this.props.toggleSideBar(false);
    this.loadShipments();
  }

  renderSideBarDetails() {
    return (
      <div className="detailed-order-container">
        <CancelModal
          shipmentId={this.state.order.details.object_id} 
          showModal={this.state.showCancelModal} 
          onCloseModal={this.onCancelOrder}>
        </CancelModal>
        {this.state.hasLabel && this.state.order.details.label ? (
          <div className="section-separator adjust-top">
            <img src={tracking_icon} /> Rastreo
            <Button
              type="link"
              className="right-link"
              onClick={this.toggleShareMenu}
            >
              Compartir <i className="ion-android-share-alt" />
            </Button>
            <div
              className={`dd-container ${
                this.state.shareTrackingLink ? "open" : ""
              }`}
            >
              <Row>
                <Column xs={12} fluid>
                  <Text type="microHeader">
                    Copia el link y comparte el rastreo en tiempo real
                  </Text>
                </Column>
                <Column xs={8} fluid>
                  <Input
                    onChange={() => {}}
                    disabled
                    value={`${BASEURL}tracking/${
                      this.state.order.details.label.tracking_number
                    }/${this.state.order.details.rate.provider.replace(
                      /\s/g,
                      ""
                    )}`}
                  />
                </Column>
                <Column xs={4} fluid>
                  <CopyToClipboard
                    text={`${BASEURL}tracking/${this.state.order.details.label.tracking_number}/${this.state.order.details.rate.provider}`}
                    onCopy={() => this.setState({ copyLabel: "Link copiado" })}
                  >
                    <Button type="link">{this.state.copyLabel}</Button>
                  </CopyToClipboard>
                </Column>
                <Column xs={12} fluid>
                  <Text type="microHeader">
                    O consulta el tracking en la pagina de la paqueteria
                  </Text>
                </Column>
                <Column xs={8} fluid>
                  <Input
                    onChange={() => {}}
                    disabled
                    value={`${this.state.order.details.label.tracking_url}`}
                  />
                </Column>
                <Column xs={4} fluid>
                  <CopyToClipboard
                    text={`${this.state.order.details.label.tracking_url}`}
                    onCopy={() => this.setState({ copyLabel2: "Link copiado" })}
                  >
                    <Button type="link">{this.state.copyLabel2}</Button>
                  </CopyToClipboard>
                </Column>
              </Row>
            </div>
          </div>
        ) : (
          <div className="section-separator adjust-top">
            <img src={from_icon} /> Origen
          </div>
        )}
        {this.state.hasOrders ? (
          <div className={this.state.hasLabel ? "indented-address passed" : ""}>
            {this.state.hasLabel && <Text type="microHeader">Origen</Text>}
            <P>{this.state.order.details.address_from.name}</P>
            <P>{this.state.order.details.address_from.email}</P>
            <P>{this.state.order.details.address_from.phone}</P>
            <P>{this.state.order.details.address_from.street}</P>
            <P>{this.state.order.details.address_from.street2}</P>
            <P>{this.state.order.details.address_from.reference}</P>
            <P>
              {`${this.state.order.details.address_from.city}, ${this.state.order.details.address_from.state}, ${this.state.order.details.address_from.country}`}
            </P>
          </div>
        ) : (
          <Skeleton count={6} />
        )}

        {this.state.hasLabel && (
          <div className="indented-address passed">
            <Text type="microHeader">Guía generada</Text>
            {this.state.order.details.label && (
              <P>#{this.state.order.details.label.tracking_number}</P>
            )}
          </div>
        )}

        {this.state.hasLabel && (
          <div
            className={`indented-address ${
              this.state.eventsOpen || this.state.beenDelivered ? "passed" : ""
            }`}
          >
            <Text type="microHeader">Recolectado/Escaneado</Text>
            <a
              href="https://blogger.mienvio.mx/centro-ayuda/recoleccion-mienvio/"
              target="_blank"
            >
              <Button type="small_blue">Agendar recolección</Button>
            </a>
          </div>
        )}

        {this.state.order.events.length > 0 && (
          <div className="seeMore">
            <div className="dot" />
            <div className="dot" />
            <div className="dot" />
            <Button
              type="link"
              onClick={() => {
                this.setState({ eventsOpen: !this.state.eventsOpen });
              }}
            >
              Ver todas las actualizaciones{" "}
              <i
                className={
                  this.state.eventsOpen ? "ion-arrow-up-b" : "ion-arrow-down-b"
                }
              />
            </Button>
          </div>
        )}

        <div
          className={`events-container ${this.state.eventsOpen ? "open" : ""}`}
        >
          {this.state.order.events.length > 0 &&
            this.props.events.map((event, index) => (
              <div
                key={index}
                className={`indented-address ${
                  event.code == "incident" ? event.code : "passed"
                } `}
              >
                <Text type="microHeader">{`${event.date} | ${event.time}`}</Text>
                <P>{event.description}</P>
              </div>
            ))}
        </div>

        {!this.state.hasLabel && (
          <div className="section-separator">
            <img src={to_icon} /> Destino
          </div>
        )}

        {this.state.hasOrders ? (
          <div
            className={`${this.state.hasLabel ? "indented-address" : ""} ${
              this.state.beenDelivered ? "passed" : ""
            } without-track`}
          >
            {this.state.hasLabel ? (
              <Text type="microHeader">Destino</Text>
            ) : null}
            <P>{this.state.order.details.address_to.name}</P>
            <P>{this.state.order.details.address_to.email}</P>
            <P>{this.state.order.details.address_to.phone}</P>
            <P>{this.state.order.details.address_to.street}</P>
            <P>{this.state.order.details.address_to.street2}</P>
            <P>{this.state.order.details.address_to.reference}</P>
            <P>
              {`${this.state.order.details.address_to.city}, ${this.state.order.details.address_to.state}, ${this.state.order.details.address_to.country}`}
            </P>
          </div>
        ) : (
          <Skeleton count={6} />
        )}
        <div className="section-separator">
          <img src={package_icon} /> Paquete
        </div>
        {this.state.order.details.overweight_charge && (
          <Row>
            <Column className="helper-vol-text" xs={12} fluid>
              <p className="left">
                Paquete con sobrepeso registrado:
                {this.state.order.details.weight_sent -
                  this.props.order.details.weight}
                kg - ${this.state.order.details.overweight_charge}{" "}
              </p>
              <a
                href={this.state.order.details.label.tracking_url}
                target="_blank"
                className="right"
                type="link"
              >
                <i className="ion-android-open" />
              </a>
            </Column>
          </Row>
        )}
        <Row>
          <Column xs={12} fluid>
            {this.state.hasOrders ? (
              <div>
                <Text type="microHeader">Dimensiones</Text>
                <P>
                  {this.state.order.details.height} x
                  {this.state.order.details.width} x
                  {this.state.order.details.length}
                </P>
              </div>
            ) : (
              <Skeleton count={2} width="60%" />
            )}
          </Column>
          <Column xs={4} fluid>
            {this.state.hasOrders ? (
              <div>
                <Text type="microHeader">Peso</Text>
                <Text type="inputFilled" className="weight-number">
                  {this.state.order.details.weight}
                </Text>
              </div>
            ) : (
              <Skeleton count={2} width="60%" />
            )}
          </Column>
          <Column xs={4} fluid>
            {this.state.hasOrders ? (
              <div>
                <Text type="microHeader">Peso Volumétrico</Text>
                <Text type="inputFilled" className="weight-number">
                  {getVolumetricWeight(
                    this.state.order.details.height,
                    this.state.order.details.width,
                    this.state.order.details.length
                  )}
                </Text>
              </div>
            ) : (
              <Skeleton count={2} width="72%" />
            )}
          </Column>
          <Column xs={4} fluid>
            {this.state.hasOrders ? (
              this.state.order.details.weight_sent && (
                <div>
                  <Text type="microHeader">Peso Enviado</Text>
                  <Text type="inputFilled">
                    {this.state.order.details.weight_sent}
                  </Text>
                </div>
              )
            ) : (
              <Skeleton count={2} width="60%" />
            )}
          </Column>
          {!this.state.order.details.insurance && this.state.hasOrders && (
            <div>
              <Text type="microHeader">Información del seguro</Text>
              <P>Sin asegurar</P>
            </div>
          )}
          <Column xs={12} fluid>
            {this.state.hasOrders ? (
              <div>
                <Text type="microHeader">Contenido del paquete</Text>
                <P>{this.state.order.details.description}</P>
              </div>
            ) : (
              <Skeleton count={2} width="65%" />
            )}
          </Column>
          <Column xs={12} fluid>
            {this.state.hasOrders ? (
              <div>
                <Text type="microHeader">Valor del paquete</Text>
                <P>{`$${numberWithCommas(
                  parseFloat(this.state.order.details.declared_value).toFixed(2)
                )} ${this.state.order.details.currency}`}</P>
              </div>
            ) : (
              <Skeleton count={2} width="65%" />
            )}
          </Column>
        </Row>
        <div className="section-separator">
          <img src={provider_icon} /> Servicio
        </div>
        <Row>
          <Column xs={1} fluid>
            {this.state.hasOrders ? (
              this.state.order.details.rate ? (
                <img
                  src={`https://production.mienvio.mx${this.state.order.details.rate.provider_img}`}
                  width="32"
                />
              ) : (
                <P>No disponible</P>
              )
            ) : (
              <Skeleton circle height={32} width={32} />
            )}
          </Column>
          <Column xs={1} fluid />
          <Column xs={10} fluid>
            {this.state.hasOrders ? (
              this.state.order.details.rate && (
                <div>
                  <P>{this.state.order.details.rate.provider}</P>
                  <P>{this.state.order.details.rate.duration_terms}</P>
                  <P>
                    <b>{`$${numberWithCommas(
                      parseFloat(this.state.order.details.rate.amount).toFixed(
                        2
                      )
                    )} ${this.state.order.details.rate.currency}`}</b>
                  </P>
                  {!this.state.hasLabel ? (
                    this.state.hasOrders &&
                    this.state.order.details.insurance ? (
                      <Button type="link" onClick={this._editInsurance}>
                        <img
                          src={substract_icon}
                          style={{ height: "14px", marginRight: "4px" }}
                        />{" "}
                        Envío asegurado por $
                        {parseFloat(
                          this.state.order.details.insurance.cost
                        ).toFixed(2)}
                      </Button>
                    ) : this.state.order.name_purchase &&
                      this.state.order.name_purchase === "QUOTE" ? (
                      <div />
                    ) : this.state.order.name_purchase ===
                      "MONTHLY_PAYMENT_PENDING" ? (
                      <div />
                    ) : (
                      <Button type="link" onClick={this._editInsurance}>
                        + Agregar seguro
                      </Button>
                    )
                  ) : null}
                </div>
              )
            ) : (
              <Skeleton count={3} width="65%" />
            )}
          </Column>
        </Row>
        <Spacer />
        <BottomContainer>
          {this.state.hasOrders && (
            <Row>
              <Column xs={6} className="text-left" fluid>
                <br />
                {
                  // TODO
                  // this.state.hasLabel
                  // ? <Button type="link" onClick={ () => alert("Not working yet") }>Agregar a descarga</Button>
                  // : <Button type="link" onClick={ () => alert("Not working yet") }>Agregar a pago</Button>
                }
              </Column>
              {
              CANCEL_STATUS_KEY.includes(this.state.order.details.object_purpose)? <Text>Orden Cancelada</Text> : 
              this.state.order.name_purchase &&
              this.state.order.name_purchase === "QUOTE" ? (
                <div />
              ) : this.state.order.name_purchase ===
                "MONTHLY_PAYMENT_PENDING" ? (
                <div />
              ) : (
                <Column xs={6} className="text-right" fluid>
                  {this.state.hasLabel ? (
                    this.state.order.details.label ? (
                      
                        <Button
                          onClick={() =>
                            window.open(
                              this.state.order.details.label.label_url,
                              "blank"
                            )
                          }
                        >
                          Descargar Guía
                        </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          alert(
                            "Tu guía presenta problemas. Comunícate con nuestro equipo de soporte."
                          )
                        }
                      >
                        Descargar Guía
                      </Button>
                    )
                  ) : (
                    <div>
                      {
                        !this.state.isManualLabel &&  
                        <Button
                          onClick={() =>
                            this.props.goToPayment(
                              this.state.order.details.object_id
                            )
                          }
                        >
                          Pagar
                        </Button>
                      }
                    </div>
                  )}
                </Column>
              )}
            </Row>
          )}
        </BottomContainer>
      </div>
    );
  }

  @boundMethod
  handleDropDownCountryChange(selectedCountry) {
    this.setState({ selectedCountry, selectedCountryFlag: true });
  }

  @boundMethod
  handleDropDownCarrierChange(selectedCarrier) {
    this.setState({ selectedCarrier, selectedCarrierFlag: true });
  }

  setFilter() {
    const {
      selectedFilter,
      inputValue,
      selectionRange,
      selectedCountry,
      selectedCountryFlag,
      selectedCarrier,
      selectedCarrierFlag,
      carriers,
    } = this.state;
    const { countries } = this.props.sidebarOrder;

    switch (selectedFilter) {
      case "Cliente":
        if (inputValue) {
          this.setState({
            setedFilter: inputValue,
            filtersOpen: false,
            inputValue: null,
          });
          this.filterData({ type: "client", value: inputValue });
        } else {
          this.setState({ inputError: "Campo Obligatiorio" });
        }
        break;
      case "Cotización":
        if (inputValue) {
          this.setState({
            setedFilter: inputValue,
            filtersOpen: false,
            inputValue: null,
          });
          this.filterData({ type: "quote", value: inputValue });
          this.loadShipmentsFilter(`?filters[]=purchase_id|=|${inputValue}`);
        } else {
          this.setState({ inputError: "Campo Obligatiorio" });
        }
        break;
      case "Carrier":
        if (selectedCarrierFlag) {
          this.setState({
            setedFilter: carriers[selectedCarrier].name,
            filtersOpen: false,
            inputValue: null,
          });
          this.filterData({
            type: "carrier",
            value: carriers[selectedCarrier].name,
          });
          this.loadShipmentsFilter(
            `?filters[]=provider_name%7C%3D%7C${carriers[selectedCarrier].name}`
          );
        } else {
          this.setState({ selectedCarrierError: "Campo Obligatiorio" });
        }
        break;
      case "Rango de Fechas":
        var day1 = null;
        var day1formated = null;
        var dd = selectionRange.selection.startDate.getDate();
        var mm = selectionRange.selection.startDate.getMonth() + 1; // January is 0!

        var yyyy = selectionRange.selection.startDate.getFullYear();
        if (dd < 10) {
          dd = `0${dd}`;
        }
        if (mm < 10) {
          mm = `0${mm}`;
        }
        var day1 = `${dd}/${mm}/${yyyy}`;
        var day1formated = `${yyyy}-${mm}-${dd}`;
        var day2 = null;
        var day2formated = null;
        var dd = selectionRange.selection.endDate.getDate();
        var mm = selectionRange.selection.endDate.getMonth() + 1; // January is 0!

        var yyyy = selectionRange.selection.endDate.getFullYear();
        if (dd < 10) {
          dd = `0${dd}`;
        }
        if (mm < 10) {
          mm = `0${mm}`;
        }
        var day2 = `${dd}/${mm}/${yyyy}`;
        var day2formated = `${yyyy}-${mm}-${dd}`;

        this.loadShipmentsFilter(
          `?filters[]=date%7CBETWEEN%7C${day1formated},${day2formated}`
        );
        this.setState({
          setedFilter: `${day1} - ${day2}`,
          filtersOpen: false,
          inputValue: null,
        });
        this.filterData({
          type: "date",
          value: `start_date=${day1formated}&end_date=${day2formated}`,
        });

        break;
      case "País":
        if (selectedCountryFlag) {
          this.setState({
            setedFilter: countries[selectedCountry].name,
            filtersOpen: false,
            inputValue: null,
          });
          this.filterData({
            type: "country",
            value: countries[selectedCountry].currency,
          });
          this.loadShipmentsFilter(
            `?filters[]=country_to|=|${countries[selectedCountry].name}`
          );
        } else {
          this.setState({ selectedCountryError: "Campo Obligatiorio" });
        }
        break;
      default:
        break;
    }
  }

  getTotal() {
    let total = 0;
    total += parseFloat(this.state.shipment.rate.amount);
    if (this.state.shipment.insurance) {
      total += parseFloat(this.state.shipment.insurance.cost);
    }
    return parseFloat(total).toFixed(2);
  }

  @boundMethod
  loadShipments() {
    if (this.props.id) {
      this.setState({ loading: true });
      getShipments(this.props.id).then((response) => {
        this.setState({ loading: false });
        if (response.type === "Success") {
          // console.log(response);
          this.setState({
            non_payed: response.response.data.shipments.non_payed,
            payed: response.response.data.shipments.payed,
          });
        } else {
          this.props.notify("Error al obtener la información", "error");
        }
      });
    } else {
      this.props.closeElement();
    }
  }

  loadShipmentsFilter(filter) {
    this.setState({ loading: true });
    getShipmentsFilter(this.props.id, filter).then((response) => {
      this.setState({ loading: false });
      if (response.type === "Success") {
        // console.log(response);
        this.setState({
          non_payed: response.response.data.shipments.non_payed,
          payed: response.response.data.shipments.payed,
        });
      } else {
        this.props.notify("Error al obtener la información", "error");
      }
    });
  }

  @boundMethod
  viewShipmentDetails(shipmentDetail) {
    const shipment_id = shipmentDetail.id;

    getShipmentsDetails(shipment_id).then((response) => {
      if (response.type === "Success") {
        const { shipment } = response.response.data;
        this.setState({ shipment }, () => {
          // console.log(this.state.shipment);
        });

        const relatedOrders = {
          nextOrder: response.response.data.next_shipment_id
            ? response.response.data.next_shipment_id.id
            : 0,
          prevOrder: response.response.data.previous_shipment_id
            ? response.response.data.previous_shipment_id.id
            : 0,
        };
        this.setState({
          order: {
            name_purchase: "example",
            details: shipment,
            events: [],
            nextOrder: relatedOrders.nextOrder,
            prevOrder: relatedOrders.prevOrder,
          },
          hasOrders:
            typeof shipment === "object" && Object.keys(shipment).length,
          hasLabel: purposesWithTracking.indexOf(shipment.object_purpose) > -1,
          eventsOpen: false,
          beenDelivered: shipment.object_purpose == "ENTREGADO",
          isManualLabel: shipment.object_purpose == manual_label,
        });

        this.props.toggleSideBar(
          true,
          `Consultando órden #"${shipment_id}"`,
          ORDER_DETAILS
        );
      } else {
        this.props.notify("Error al obtener la información", "error");
      }
    });
  }

  componentWillMount() {
    this.loadShipments();
    this.getCarriers();
  }

  toogleFilters() {
    this.setState({ filtersOpen: !this.state.filtersOpen });
  }

  badgeLabel(status, hasLabel = false) {
    // console.log(status);
    switch (status) {
      case "PURCHASE":
        return { label: "Guía sin pago", type: "warning" };
      case "PICKED_UP":
        return { label: "Recolectado", type: "correct" };
      case "TRANSITO":
        return { label: "En camino", type: "correct" };
      case "OUT_FOR_DELIVERY":
        return { label: "En ruta entrega", type: "correct" };
      case "ENTREGADO":
        return { label: "Entregado", type: "correct" };
      case "INCIDENCIA":
        return { label: "Incidencia", type: "warning" };
      case "RETORNO_EN_PROCESO": // returned
        return { label: "Retornado", type: "warning" };
      case "CANCELADO":
        return { label: "Cancelado", type: "error" };
      case "CANCELADO_RUTA":
        return { label: "Cancelado en Ruta", type: "error" };
      case "CANCELADO_BODEGA":
        return { label: "Cancelado en Bodega", type: "error" };
      case "CANCELADO_TIEMPO":
        return { label: "Cancelado por Tiempo", type: "error" };
      default:
        return hasLabel
          ? { label: "Guía generada", type: "correct" }
          : { label: "Generando guía", type: "warning" };
    }
  }

  selectedItem(selectedFilter) {
    this.setState({ selectedFilter });
  }

  @boundMethod
  filterData(filter) {
    this.setState({ filter });
  }

  handleInput = (inputValue) => {
    this.setState({ inputValue });
  };

  render() {
    const { currentTab } = this.state;

    const options = [
      {
        function: () => this.toggleAddShipment(),
        label: "Añadir Envio",
        image: edit_icon,
      },
    ];

    const columns = [
      {
        name: "N° Orden",
        selector: "orden",
        sortable: true,
        cell: (row) => (
          <div>
            <p>#{row.id}</p>
            <MarketplaceBadge
              color={row.marketplace !== "manual" ? row.marketplace : "default"}
            />
          </div>
        ),
      },
      {
        name: "Fecha Alta",
        selector: "fecha",
        sortable: true,
        cell: (row) => {
          const shipment_date = moment(row.date.split(" ")[0]);
          const today = new Date();
          const timeDiff = Math.abs(today - shipment_date);
          const daysDiff = Math.floor(timeDiff / (3600 * 24 * 1000));
          return (
            <div>
              <p>{shipment_date.format("DD-MM-YYYY")}</p>
              <p className="minitext">
                {daysDiff < 1
                  ? "hoy"
                  : `hace ${daysDiff} ${daysDiff > 1 ? "días" : "día"}`}
              </p>
            </div>
          );
        },
      },
      {
        name: "Destinatario",
        selector: "destinatario",
        sortable: true,
        cell: (row) => row.to_name,
      },
      {
        name: "Destino",
        selector: "destino",
        sortable: true,
        cell: (row) => {
          const street = row.to_street.split("|");
          return (
            <div>
              <p>{street[0]}</p>
              <p className="minitext">{street[1]}</p>
            </div>
          );
        },
      },
      {
        name: "Servicio",
        selector: "servicio",
        sortable: true,
        cell: (row) => (
          <div>
            <p>{row.provider_name ? row.provider_name : "-"}</p>
            <p className="minitext">
              {row.service_level ? jsUcfirst(row.service_level) : "-"}
            </p>
          </div>
        ),
      },
      {
        name: "Estatus",
        selector: "estatus",
        sortable: true,
        cell: (row) => {
          const badge = this.badgeLabel(row.status, row.tracking_number);
          return <NewBadge type={badge.type}>{badge.label}</NewBadge>;
        },
      },
      {
        name: "Total",
        selector: "total",
        sortable: true,
        cell: (row) =>
          row.amount ? `$${parseFloat(row.amount).toFixed(2)}` : "-",
      },
    ];
    const {
      selectedCarrierError,
      selectedCarrier,
      setedFilter,
      selectedFilter,
      filtersOpen,
      selectionRange,
      selectedCountry,
      selectedCountryError,
      inputValue,
      inputError,
    } = this.state;
    return (
      <div>
        <WrapperSideBar
          handleClose={this.onCloseSidebar}
          title_more={this._renderSidebarTitleExtraContent()}
        >
          {this._renderSidebarContent()}
        </WrapperSideBar>

        <div className="header-data-visualization">
          <img src={packageIcon} alt="" className="selected-icon" />
          <p className="title-header-data-visualization">Envios</p>
          <ThreeDotsDropdown linksToRender={options} />
          <img
            onClick={() => this.props.closeElement()}
            src={close_icon}
            alt=""
            className="close-icon"
          />
        </div>

        {(() => {
          if (this.state.loading) {
            return (
              <div className="container-loading">
                {" "}
                <Skeleton height={30} count={6} />{" "}
              </div>
            );
          }
          return (
            <Tabs
              id="shimpentsMaster"
              right
              selected={currentTab}
              onChange={(selected) => this.setState({ currentTab: selected })}
            >
              <Tab>Sin guía</Tab>
              <Tab>Con guía</Tab>
            </Tabs>
          );
        })()}

        {!this.state.loading && currentTab === 0 && (
          <NewTable
            selectableRows
            shipping
            columns={columns}
            data={this.state.non_payed}
            onRowClicked={this.viewShipmentDetails}
            noDataComponent={<div />}
            subHeader={
              <div>
                {setedFilter ? (
                  <Filter
                    noMarginFilter
                    onClose={() => {
                      this.loadShipments();
                      this.setState({
                        setedFilter: null,
                        selectedFilter: null,
                        selectedCountry: null,
                        selectedCountryFlag: false,
                        selectedCountryError: "",
                        selectionRange: {
                          selection: {
                            startDate: today,
                            endDate: tomorrow,
                            key: "selection",
                          },
                        },
                      });
                      this.filterData({ type: null, value: null });
                    }}
                  >
                    {selectedFilter} - {setedFilter}
                  </Filter>
                ) : (
                  <Button
                    className="action-btn"
                    type="link"
                    onClick={() => this.toogleFilters()}
                  >
                    Añadir Filtro +
                  </Button>
                )}
                {filtersOpen && (
                  <div style={{ position: "relative" }}>
                    <div
                      className={`container-filters ${filtersOpen && "open"} ${
                        selectedFilter && "selected-item"
                      }`}
                    >
                      {!selectedFilter ? (
                        <div>
                          <div
                            onClick={() => this.selectedItem("Rango de Fechas")}
                            className="filter-item"
                          >
                            <img
                              src={Calendar}
                              alt=""
                              style={{
                                width: "12px",
                                height: "12px",
                                marginRight: "5px",
                              }}
                            />
                            Rango de Fechas
                          </div>
                          <div
                            onClick={() => this.selectedItem("País")}
                            className="filter-item"
                          >
                            <img
                              src={Map}
                              alt=""
                              style={{
                                width: "12px",
                                height: "12px",
                                marginRight: "5px",
                              }}
                            />
                            País
                          </div>
                          {/* <div onClick={() => this.selectedItem('Cliente')} className="filter-item">
                        Cliente
                    </div> */}
                          <div
                            onClick={() => this.selectedItem("Cotización")}
                            className="filter-item"
                          >
                            <img
                              src={Contract}
                              alt=""
                              style={{
                                width: "12px",
                                height: "12px",
                                marginRight: "5px",
                              }}
                            />
                            Cotización
                          </div>
                          <div
                            onClick={() => this.selectedItem("Carrier")}
                            className="filter-item"
                          >
                            <img
                              src={Carrier}
                              alt=""
                              style={{
                                width: "12px",
                                height: "12px",
                                marginRight: "5px",
                              }}
                            />
                            Carrier
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div
                            onClick={() =>
                              this.setState({ selectedFilter: null })
                            }
                            className="filter-item selected"
                          >
                            <img
                              src={Arrow}
                              style={{ height: "12px", width: "12px" }}
                              alt=""
                            />
                            {selectedFilter}
                          </div>
                          {selectedFilter === "Rango de Fechas" ? (
                            <div>
                              <DateRange
                                ranges={[selectionRange.selection]}
                                onChange={(value) =>
                                  this.setState({ selectionRange: value })
                                }
                                color="#2576DA"
                                locale={rdrLocales.es}
                              />
                            </div>
                          ) : (
                            <div className="container-selected">
                              {selectedFilter === "País" ? (
                                <div className="filter-dropdown-quotes">
                                  <Dropdown
                                    error={selectedCountryError}
                                    options={this._renderCountries()}
                                    handleChange={
                                      this.handleDropDownCountryChange
                                    }
                                    selected={selectedCountry}
                                    placeholder={
                                      <Text className="dropdown-placeholder">
                                        Selecciona un país
                                      </Text>
                                    }
                                  />
                                </div>
                              ) : selectedFilter === "Carrier" ? (
                                <div className="filter-dropdown-quotes">
                                  <Dropdown
                                    error={selectedCarrierError}
                                    options={this.renderCarriers()}
                                    handleChange={
                                      this.handleDropDownCarrierChange
                                    }
                                    selected={selectedCarrier}
                                    placeholder={
                                      <Text className="dropdown-placeholder">
                                        Paqueteria
                                      </Text>
                                    }
                                  />
                                </div>
                              ) : (
                                <Input
                                  error={inputError}
                                  value={inputValue}
                                  onChange={this.handleInput}
                                  className="filter-input"
                                  type="text"
                                  placeholder={`${
                                    selectedFilter === "Cliente"
                                      ? "Juan Perez"
                                      : selectedFilter === "Cotización"
                                      ? "100023"
                                      : "Estafeta"
                                  }`}
                                />
                              )}
                            </div>
                          )}
                          <div className="btn-add-filter">
                            <Button
                              onClick={() => this.setFilter()}
                              type="link"
                            >
                              Añadir
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            }
          />
        )}

        {!this.state.loading && currentTab === 1 && (
          <NewTable
            selectableRows
            shipping={false}
            columns={columns}
            data={this.state.payed}
            onRowClicked={this.viewShipmentDetails}
            noDataComponent={<div />}
            subHeader={
              <div>
                {setedFilter ? (
                  <Filter
                    noMarginFilter
                    onClose={() => {
                      this.loadShipments();
                      this.setState({
                        setedFilter: null,
                        selectedFilter: null,
                        selectedCountry: null,
                        selectedCountryFlag: false,
                        selectedCountryError: "",
                        selectionRange: {
                          selection: {
                            startDate: today,
                            endDate: tomorrow,
                            key: "selection",
                          },
                        },
                      });
                      this.filterData({ type: null, value: null });
                    }}
                  >
                    {selectedFilter} - {setedFilter}
                  </Filter>
                ) : (
                  <Button
                    className="action-btn"
                    type="link"
                    onClick={() => this.toogleFilters()}
                  >
                    Añadir Filtro +
                  </Button>
                )}
                {filtersOpen && (
                  <div style={{ position: "relative" }}>
                    <div
                      className={`container-filters ${filtersOpen && "open"} ${
                        selectedFilter && "selected-item"
                      }`}
                    >
                      {!selectedFilter ? (
                        <div>
                          <div
                            onClick={() => this.selectedItem("Rango de Fechas")}
                            className="filter-item"
                          >
                            <img
                              src={Calendar}
                              alt=""
                              style={{
                                width: "12px",
                                height: "12px",
                                marginRight: "5px",
                              }}
                            />
                            Rango de Fechas
                          </div>
                          <div
                            onClick={() => this.selectedItem("País")}
                            className="filter-item"
                          >
                            <img
                              src={Map}
                              alt=""
                              style={{
                                width: "12px",
                                height: "12px",
                                marginRight: "5px",
                              }}
                            />
                            País
                          </div>
                          {/* <div onClick={() => this.selectedItem('Cliente')} className="filter-item">
                        Cliente
                    </div> */}
                          <div
                            onClick={() => this.selectedItem("Cotización")}
                            className="filter-item"
                          >
                            <img
                              src={Contract}
                              alt=""
                              style={{
                                width: "12px",
                                height: "12px",
                                marginRight: "5px",
                              }}
                            />
                            Cotización
                          </div>
                          <div
                            onClick={() => this.selectedItem("Carrier")}
                            className="filter-item"
                          >
                            <img
                              src={Carrier}
                              alt=""
                              style={{
                                width: "12px",
                                height: "12px",
                                marginRight: "5px",
                              }}
                            />
                            Carrier
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div
                            onClick={() =>
                              this.setState({ selectedFilter: null })
                            }
                            className="filter-item selected"
                          >
                            <img
                              src={Arrow}
                              style={{ height: "12px", width: "12px" }}
                              alt=""
                            />
                            {selectedFilter}
                          </div>
                          {selectedFilter === "Rango de Fechas" ? (
                            <div>
                              <DateRange
                                ranges={[selectionRange.selection]}
                                onChange={(value) =>
                                  this.setState({ selectionRange: value })
                                }
                                color="#2576DA"
                                locale={rdrLocales.es}
                              />
                            </div>
                          ) : (
                            <div className="container-selected">
                              {selectedFilter === "País" ? (
                                <div className="filter-dropdown-quotes">
                                  <Dropdown
                                    error={selectedCountryError}
                                    options={this._renderCountries()}
                                    handleChange={
                                      this.handleDropDownCountryChange
                                    }
                                    selected={selectedCountry}
                                    placeholder={
                                      <Text className="dropdown-placeholder">
                                        Selecciona un país
                                      </Text>
                                    }
                                  />
                                </div>
                              ) : selectedFilter === "Carrier" ? (
                                <div className="filter-dropdown-quotes">
                                  <Dropdown
                                    error={selectedCarrierError}
                                    options={this.renderCarriers()}
                                    handleChange={
                                      this.handleDropDownCarrierChange
                                    }
                                    selected={selectedCarrier}
                                    placeholder={
                                      <Text className="dropdown-placeholder">
                                        Paqueteria
                                      </Text>
                                    }
                                  />
                                </div>
                              ) : (
                                <Input
                                  error={inputError}
                                  value={inputValue}
                                  onChange={this.handleInput}
                                  className="filter-input"
                                  type="text"
                                  placeholder={`${
                                    selectedFilter === "Cliente"
                                      ? "Juan Perez"
                                      : selectedFilter === "Cotización"
                                      ? "100023"
                                      : "Estafeta"
                                  }`}
                                />
                              )}
                            </div>
                          )}
                          <div className="btn-add-filter">
                            <Button
                              onClick={() => this.setFilter()}
                              type="link"
                            >
                              Añadir
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            }
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  message: state.message,
  integrations: state.integrations,
  sidebarOrder: state.newShipment,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSideBar,
      changeSidebarTitle,
      showMessage,
      hideMessage,
      showLoader,
      hideLoader,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Shipments);
