import React from "react";
import axios from "axios";
import { showError } from "../../modules/error";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";

import { emailRegex, formatPhone } from "../../utils/global_functions";
import { editMonthlyInvoice } from "../../modules/user";
import { cancelPlan } from "../../modules/plans";
import {
  changePassword,
  editUserInfo,
  editFiscalInfo,
  storeShipmentResponsable,
} from "../../modules/user";
import { toggle as toggleSideBar } from "../../modules/CollapsibleBar/actions";
import { errors } from "../../utils/helpers";

import ReactTooltip from "react-tooltip";
import { Row, Column } from "hedron";
import Text, { P } from "../Text";
import Input from "../Input";
import { Checkbox, CheckboxGroup } from "../Checkbox";
import Dropdown from "../Dropdown";
import Button from "../Button";
import { Divider } from "../../components/Card";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { BottomContainer } from "../WrapperSideBar/styledObjects";
import {
  ACCOUNT_CONFIGURATION,
  PASSWORD_CONFIGURATION,
  INVOICE_CONFIGURATION,
  SHIPMENTS_RESPONSABLE,
} from "../../modules/CollapsibleBar/sideBarTypes";

import styles from "./index.module.scss";

const periodOptions = [
  "Hace 1 día",
  "Últimos 2 días",
  "Últimos 3 días",
  "Últimos 4 días",
  "Últimos 5 días",
  "Últimos 15 días",
  "Últimos 30 días",
  "Todos los envíos",
];

class AccountInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // states to handle information change
      password: "",
      passwordError: "",
      passwordNew: "",
      passwordNewError: "",
      passwordConfirmation: "",
      passwordConfirmationError: "",
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      email: "",
      emailError: "",
      companyName: "",
      companyNameError: "",
      rfc: "",
      rfcError: "",
      phone: "",
      phoneError: "",
      street: "",
      streetError: "",
      street2: "",
      street2Error: "",
      zipcode: "",
      zipcodeError: "",
      location: "",
      selectedPeriodError: "",
      selectedPeriod: "",
      isRfcDuplicated: false,
      shipmentResponsableName: "",
      shipmentResponsableNameError: "",
      shipmentResponsableEmail: "",
      shipmentResponsableEmailError: "",
      shipmentResponsablePhone: "",
      shipmentResponsablePhoneError: "",
    };
    this.saveContactInfo = this.saveContactInfo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetForms = this.resetForms.bind(this);
    this.savePassword = this.savePassword.bind(this);
    this.saveFiscalInfo = this.saveFiscalInfo.bind(this);
    this.toggleModalAndCloseSideBar =
      this.toggleModalAndCloseSideBar.bind(this);
    this.verifyRfc = this.verifyRfc.bind(this);
    this.saveShipmentResponsable = this.saveShipmentResponsable.bind(this);
  }

  // reset the form and states
  resetForms = () => {
    this.setState({
      password: "",
      passwordError: "",
      passwordNew: "",
      passwordNewError: "",
      passwordConfirmation: "",
      passwordConfirmationError: "",
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      email: "",
      emailError: "",
      companyName: "",
      companyNameError: "",
      rfc: "",
      rfcError: "",
      phone: "",
      phoneError: "",
      street: "",
      streetError: "",
      street2: "",
      street2Error: "",
      zipcode: "",
      zipcodeError: "",
      location: "",
      editAccount: false,
      editPassword: false,
      editInvoiceInformation: false,
      isRfcDuplicated: false,
      loadingBtn: false,
      shipmentResponsableName: this.props.user.shipments_responsable
        ? this.props.user.shipments_responsable.name
        : "",
      shipmentResponsableNameError: "",
      shipmentResponsableEmail: this.props.user.shipments_responsable
        ? this.props.user.shipments_responsable.email
        : "",
      shipmentResponsableEmailError: "",
      shipmentResponsablePhone: this.props.user.shipments_responsable
        ? this.props.user.shipments_responsable.phone
        : "",
      shipmentResponsablePhoneError: "",
    });
  };

  componentWillReceiveProps(nextProps) {
    this.resetForms();
    this.setState({
      selectedPeriod:
        nextProps.user.settings.shipments_purchases_list === "1"
          ? 0
          : nextProps.user.settings.shipments_purchases_list === "2"
          ? 1
          : nextProps.user.settings.shipments_purchases_list === "3"
          ? 2
          : nextProps.user.settings.shipments_purchases_list === "4"
          ? 3
          : nextProps.user.settings.shipments_purchases_list === "5"
          ? 4
          : nextProps.user.settings.shipments_purchases_list === "15"
          ? 5
          : nextProps.user.settings.shipments_purchases_list === "30"
          ? 6
          : 7,
    });
  }

  // save the state of the contact information
  saveContactInfo = async () => {
    let { firstName } = this.state;
    let { lastName } = this.state;
    let { phone } = this.state;
    const { email } = this.state;
    const emailError = "";

    if (firstName === "") {
      firstName = this.props.user.first_name;
    }
    if (lastName === "") {
      lastName = this.props.user.last_name;
    }
    if (phone === "") {
      phone = this.props.user.phone;
    }
    await this.setState({
      phoneError: errors("phone", phone),
    });
    if (this.state.phoneError === "") {
      this.props.editUserInfo(firstName, lastName, phone).then((response) => {
        this.resetForms();
        this.props.toggleSideBar(false);
      });
    }
  };

  // save the state of the password
  savePassword = () => {
    const { password } = this.state;
    let passwordError = "";
    const { passwordNew } = this.state;
    let passwordNewError = "";
    const { passwordConfirmation } = this.state;
    let passwordConfirmationError = "";
    let errors = 0;
    if (password === "") {
      errors++;
      passwordError = "Este campo es requerido";
    } else if (password.length < 6) {
      errors++;
      passwordError = "Debe contener al menos 6 caracteres";
    }

    if (passwordNew === "") {
      errors++;
      passwordNewError = "Este campo es requerido";
    } else if (passwordNew.length < 6) {
      errors++;
      passwordNewError = "Debe contener al menos 6 caracteres";
    } else if (passwordNew === password) {
      errors++;
      passwordNewError =
        "Las contraseña nueva no puede ser igual a la contraseña actual";
    }

    if (passwordConfirmation === "") {
      errors++;
      passwordConfirmationError = "Este campo es requerido";
    } else if (passwordConfirmation !== passwordNew) {
      errors++;
      passwordConfirmationError = "Las dos contraseñas no coinciden";
    }

    if (errors > 0) {
      return this.setState({
        passwordError,
        passwordNewError,
        passwordConfirmationError,
      });
    }
    this.props
      .changePassword(password, passwordNew, passwordConfirmation)
      .then(() =>
        this.setState({
          passwordError,
          passwordNewError,
          passwordConfirmationError,
        })
      )
      .then((response) => {
        this.resetForms();
        this.props.toggleSideBar(false);
      });
  };

  // map the changes in input to the appropiate state
  handleChange = (value, key) => {
    if (key === "zipcode" && !isNaN(value) && value.length === 5) {
      axios
        .get(`/api/zipcodes/${value}`)
        .then((response) => {
          this.setState({
            location: `${response.data.municipality}, ${response.data.state.name}`,
            zipcodeError: "",
          });
        })
        .catch(() => {
          this.setState({
            location: "",
            zipcodeError: "Código postal inválido",
          });
        });
    } else if (key === "zipcode") {
      this.setState({ location: "" });
    } else if (key === "phone" || key === "shipmentResponsablePhone") {
      value = value.replace(/\D/g, "");
      if (value.length > 10) return;
    } else if (
      (key === "email" || key === "shipmentResponsableEmail") &&
      !emailRegex.test(value)
    ) {
      this.setState({
        [`${key}Error`]: "El correo no es válido",
      });
    } else if (
      (key === "email" || key === "shipmentResponsableEmail") &&
      emailRegex.test(value)
    ) {
      this.setState({
        [`${key}Error`]: "",
      });
    }
    this.setState({ [key]: value });
  };

  verifyRfc = async () => {
    let hasError = false;
    const regexrfc =
      /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/;
    if (this.state.rfc) {
      if (regexrfc.test(this.state.rfc)) {
        this.setState({ loadingBtn: true });
        await axios
          .post("api/users/validaterfc", { rfc: this.state.rfc })
          .then((response) => {
            this.setState({ loadingBtn: false });
            if (!response.data.isUnique) {
              hasError = true;
              this.setState({
                rfcError: (
                  <p
                    onClick={this.toggleModalAndCloseSideBar}
                    style={{
                      margin: 0,
                      cursor: "pointer",
                      color: "#2576DA",
                      fontSize: "12px",
                    }}
                  >
                    Aprobación de RFC duplicado
                  </p>
                ),
              });
            } else {
              this.setState({ rfcError: "" });
            }
          })
          .catch((error) => {
            this.setState({ loadingBtn: false });
            console.log(error);
          });
      } else {
        this.setState({
          rfcError: "RFC invalido, revisa los datos ingresados",
        });
      }
    }
    return hasError;
  };

  verifyRfcNinja = async () => {
    let hasError = false;
    this.setState({ loadingBtn: true });
    await axios
      .post("api/users/validate-only-rfc", { rfc: this.state.rfc })
      .then((response) => {
        this.setState({ loadingBtn: false });
        if (!response.data.isValid) {
          this.setState({
            rfcError: "RFC invalido, revisa los datos ingresados",
          });
          hasError = true;
        } else {
          this.setState({ rfcError: "" });
        }
      })
      .catch((error) => {
        this.setState({ loadingBtn: false });
        console.log(error);
      });
    return hasError;
  };
  checkDisabled() {
    let errors = 0;
    const regexrfc =
      /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/;
    let companyNameError = "";
    let rfcError;
    let phoneError = "";
    let streetError = "";
    let street2Error = "";
    let zipcodeError = "";

    if (
      this.state.companyName === "" &&
      this.state.rfc === "" &&
      this.state.phone === "" &&
      this.state.street === "" &&
      this.state.street2 === "" &&
      this.state.zipcode === ""
    ) {
      return true;
    }
    if (
      this.state.companyNameError !== "" ||
      this.state.rfcError !== "" ||
      this.state.phoneError !== "" ||
      this.state.streetError !== "" ||
      this.state.street2Error !== "" ||
      this.state.zipcodeError !== ""
    ) {
      return true;
    }
    return false;
  }
  // map the changes in the invoice form to the appropiate state and post them
  saveFiscalInfo = async () => {
    let errors = 0;
    const regexrfc =
      /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/;
    let companyNameError = "";
    let rfcError;
    let phoneError = "";
    let streetError = "";
    let street2Error = "";
    let zipcodeError = "";
    let hasRfcError = false;
    if (await this.verifyRfc()) {
      hasRfcError = true;
    } else {
      hasRfcError = await this.verifyRfcNinja();
    }
    if (!this.state.companyName && !this.props.user.fiscal_address) {
      errors++;
      companyNameError = "Este campo es requerido";
    }

    if (!this.state.rfc && !this.props.user.fiscal_address) {
      errors++;
      rfcError = "Este campo es requerido";
    } else if (this.state.rfc && !regexrfc.test(this.state.rfc)) {
      errors++;
      rfcError = "RFC invalido, revisa los datos ingresados";
    } else if (this.state.rfc && hasRfcError) {
      rfcError = "RFC invalido, revisa los datos ingresados";
      errors++;
    }

    if (!this.state.phone && !this.props.user.fiscal_address) {
      errors++;
      phoneError = "Este campo es requerido";
    } else if (
      this.state.phone &&
      (isNaN(this.state.phone) || this.state.phone.length !== 10)
    ) {
      errors++;
      phoneError = "Número de teléfono inválido";
    }

    if (!this.state.street && !this.props.user.fiscal_address) {
      errors++;
      streetError = "Este campo es requerido";
    } else if (this.state.street.length > 35) {
      errors++;
      streetError = "Máximo 35 caracteres";
    }

    if (!this.state.street2 && !this.props.user.fiscal_address) {
      errors++;
      street2Error = "Este campo es requerido";
    }

    if (!this.state.zipcode && !this.props.user.fiscal_address) {
      errors++;
      zipcodeError = "Este campo es requerido";
    } else if (
      this.state.zipcode &&
      (isNaN(this.state.zipcode) || this.state.zipcode.length !== 5)
    ) {
      errors++;
      zipcodeError = "Código postal inválido";
    } else if (this.state.location === "" && this.state.zipcode !== "") {
      errors++;
      zipcodeError = "Código postal inválido";
    } else if (this.state.location === "" && !this.props.user.fiscal_address) {
      errors++;
      zipcodeError = "Código postal inválido";
    }
    this.setState({
      companyNameError,
      rfcError,
      streetError,
      street2Error,
      phoneError,
      zipcodeError,
    });
    if (errors > 0) {
      return;
    }
    if (this.props.user.fiscal_address) {
      const address = {
        name: this.state.companyName
          ? this.state.companyName
          : this.props.user.fiscal_address.name,
        rfc: this.state.rfc
          ? this.state.rfc
          : this.props.user.fiscal_address.rfc,
        phone: this.state.phone
          ? this.state.phone
          : this.props.user.fiscal_address.phone,
        street: this.state.street
          ? this.state.street
          : this.props.user.fiscal_address.street,
        street2: this.state.street2
          ? this.state.street2
          : this.props.user.fiscal_address.street2,
        zipcode: this.state.zipcode
          ? this.state.zipcode
          : this.props.user.fiscal_address.zipcode,
      };
      return this.props
        .editFiscalInfo(address, this.props.user.fiscal_address.object_id)
        .then((response) => {
          this.resetForms();
          this.props.toggleSideBar(false);
        });
    }
    const address = {
      name: this.state.companyName,
      rfc: this.state.rfc,
      phone: this.state.phone,
      street: this.state.street,
      street2: this.state.street2,
      zipcode: this.state.zipcode,
    };
    this.props.editFiscalInfo(address).then((response) => {
      this.resetForms();
      this.props.toggleSideBar(false);
    });
  };

  toggleModalAndCloseSideBar() {
    this.props.toggleSideBar(false);
    this.props.toggleModal();
  }

  toggleModalAndCloseSideBar() {
    this.props.toggleSideBar(false);
    this.props.toggleModal();
  }

  saveShipmentResponsable = () => {
    let errors = 0;
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    if (
      !this.state.shipmentResponsableName ||
      this.state.shipmentResponsableName.trim() === ""
    ) {
      errors++;
      nameError = "Este campo es requerido";
    }
    if (
      !this.state.shipmentResponsableEmail ||
      this.state.shipmentResponsableEmail.trim() === ""
    ) {
      errors++;
      emailError = "Este campo es requerido";
    }
    if (
      !this.state.shipmentResponsablePhone ||
      this.state.shipmentResponsablePhone.trim() === ""
    ) {
      errors++;
      phoneError = "Este campo es requerido";
    } else if (this.state.shipmentResponsablePhone.length !== 10) {
      errors++;
      phoneError = "Teléfono inválido";
    }

    if (errors > 0) {
      return this.setState({
        shipmentResponsableNameError: nameError,
        shipmentResponsableEmailError: emailError,
        shipmentResponsablePhoneError: phoneError,
      });
    }

    this.props
      .storeShipmentResponsable(
        this.state.shipmentResponsableName,
        this.state.shipmentResponsableEmail,
        this.state.shipmentResponsablePhone
      )
      .then((response) => {
        this.resetForms();
        this.props.toggleSideBar(false);
      });
  };

  render() {
    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value:
          this.state[name] &&
          (name === "phone" || name === "shipmentResponsablePhone")
            ? formatPhone(this.state[name])
            : this.state[name],
        error: this.state[`${name}Error`],
      }),
    };
    if (this.props.user.user === null) {
      return <div />;
    }
    switch (this.props.sidebar.type) {
      case ACCOUNT_CONFIGURATION:
        return (
          <div
            className="detailed-account-container"
            style={{
              textAlign: "left",
            }}
          >
            <div className="section-separator adjust-top">
              <Row>Cuenta</Row>
            </div>
            <div className={styles.userForm}>
              <Text className={styles.helpText} type="helpTextModal">
                Ayúdanos a personalizar la atención dentro de nuestra plataforma
                con tu nombre completo. Tu correo electrónico es usado para
                iniciar sesión.
              </Text>
              <Input
                type="text"
                {...mainProps.generate("firstName")}
                required="required"
                placeholder={
                  this.props.user.object_id
                    ? `${this.props.user.first_name}`
                    : ""
                }
                label="Nombre(s)"
              />
              <Input
                type="text"
                {...mainProps.generate("lastName")}
                required="required"
                placeholder={
                  this.props.user.object_id
                    ? `${this.props.user.last_name}`
                    : ""
                }
                label="Apellidos"
              />
              <Input
                type="text"
                {...mainProps.generate("email")}
                required="required"
                placeholder={this.props.user.email}
                disabled="disabled"
                label="Correo electrónico"
              />
              <Input
                type="text"
                {...mainProps.generate("phone")}
                required="required"
                placeholder={
                  this.props.user.phone.charAt(0) === "(" &&
                  this.props.user.phone.charAt(4) === ")"
                    ? this.props.user.phone
                    : formatPhone(this.props.user.phone)
                }
                label="Teléfono"
              />
              <BottomContainer style={{ justifyContent: "flex-end" }}>
                <Button
                  className="state-btn"
                  onClick={() => this.saveContactInfo()}
                >
                  Guardar Cambios
                </Button>
              </BottomContainer>
            </div>
          </div>
        );
      case PASSWORD_CONFIGURATION:
        return (
          <div
            className="detailed-account-container"
            style={{
              textAlign: "left",
            }}
          >
            <div className="section-separator adjust-top">
              <Row>Contraseña</Row>
            </div>
            <div className={styles.userForm}>
              <Text className={styles.helpText} type="helpTextModal">
                Cambiar tu contraseña cada cierto periodo de tiempo ayuda a
                mantener tu cuenta aun más segura, aquí solo podras editar tu
                contraseña. Si haz olvidado tu contraseña
              </Text>
              <Input
                type="password"
                {...mainProps.generate("password")}
                required
                label="Contraseña actual"
              />
              <Divider />
              <Input
                type="password"
                {...mainProps.generate("passwordNew")}
                required
                label="Nueva contraseña"
              />
              <Input
                type="password"
                {...mainProps.generate("passwordConfirmation")}
                required
                label="Repite tu nueva contraseña"
              />
              <BottomContainer style={{ justifyContent: "flex-end" }}>
                <Button
                  className="state-btn"
                  onClick={() => this.savePassword()}
                >
                  Guardar Cambios
                </Button>
              </BottomContainer>
            </div>
          </div>
        );
      case INVOICE_CONFIGURATION:
        return (
          <div
            className="detailed-account-container"
            style={{
              textAlign: "left",
            }}
          >
            <div className="section-separator  adjust-top">
              <Row>Información fiscal</Row>
            </div>
            <div className={styles.userForm}>
              <Text className={styles.helpText} type="helpTextModal">
                La información fiscal que proporciones aquí, será utilizada en
                el momento de realizar tus facturas.
              </Text>
              <Input
                type="text"
                {...mainProps.generate("companyName")}
                required
                disabled={
                  this.props.user.fiscal_address &&
                  this.props.user.fiscal_address.name
                }
                placeholder={
                  this.props.user.fiscal_address
                    ? this.props.user.fiscal_address.name
                    : this.state.editInvoiceInformation
                    ? "Nombre Titular o Empresa"
                    : ""
                }
                label="Razón Social"
              />
              <Input
                type="text"
                {...mainProps.generate("rfc")}
                required
                onBlur={(e) => {
                  this.verifyRfc();
                }}
                disabled={
                  this.props.user.fiscal_address &&
                  this.props.user.fiscal_address.rfc
                }
                placeholder={
                  this.props.user.fiscal_address
                    ? this.props.user.fiscal_address.rfc
                    : this.state.editInvoiceInformation
                    ? "XXXX000000XXX"
                    : ""
                }
                label="RFC"
              />
              <Input
                type="text"
                {...mainProps.generate("phone")}
                required
                placeholder={
                  this.props.user.fiscal_address
                    ? this.props.user.fiscal_address.phone.charAt(0) === "(" &&
                      this.props.user.fiscal_address.charAt(4) === ")"
                      ? this.props.user.fiscal_address.phone
                      : formatPhone(this.props.user.fiscal_address.phone)
                    : this.state.editInvoiceInformation
                    ? "(442) 123 12 45"
                    : ""
                }
                label="Teléfono"
              />
              <Input
                type="text"
                maxLength="35"
                required
                {...mainProps.generate("street")}
                placeholder={
                  this.props.user.fiscal_address
                    ? this.props.user.fiscal_address.street
                    : this.state.editInvoiceInformation
                    ? "Av. Felipe Angeles, 511. Int. J-501."
                    : ""
                }
                label="Calle y número"
              />
              <Input
                type="text"
                {...mainProps.generate("street2")}
                required
                placeholder={
                  this.props.user.fiscal_address
                    ? this.props.user.fiscal_address.street2
                    : this.state.editInvoiceInformation
                    ? "Los pedregales"
                    : ""
                }
                label="Colonia"
              />
              <Input
                type="text"
                {...mainProps.generate("zipcode")}
                required
                placeholder={
                  this.props.user.fiscal_address
                    ? this.props.user.fiscal_address.zipcode
                    : this.state.editInvoiceInformation
                    ? "76158"
                    : ""
                }
                label="Código Postal"
              />
              <Input
                type="text"
                required
                disabled
                placeholder={
                  this.props.user.fiscal_address
                    ? this.props.user.fiscal_address.city +
                      ", " +
                      this.props.user.fiscal_address.state
                    : ""
                }
                onChange={() => this.saveFiscalInfo()}
                label="Estado y Ciudad"
                value={this.state.location}
              />
              <BottomContainer style={{ justifyContent: "flex-end" }}>
                <Button
                  style={{
                    color: this.checkDisabled() && "#CAD2DF",
                    border: this.checkDisabled() && "1px solid #CAD2DF",
                    background: this.checkDisabled() && "#F2F2F2",
                    pointerEvents: this.checkDisabled() && "none",
                  }}
                  loading={this.state.loadingBtn}
                  className="state-btn"
                  onClick={() => this.saveFiscalInfo()}
                >
                  Guardar Cambios
                </Button>
              </BottomContainer>
            </div>
          </div>
        );
      case SHIPMENTS_RESPONSABLE:
        return (
          <div
            className="detailed-account-container"
            style={{
              textAlign: "left",
            }}
          >
            <div className="section-separator  adjust-top">
              <Row>Información fiscal</Row>
            </div>
            <div className={styles.userForm}>
              <Text className={styles.helpText} type="helpTextModal">
                Bríndanos la información de contacto del responsable de envíos
                de tu empresa, esto nos ayudará a brindarte una mejor atención.
              </Text>
              <Input
                type="text"
                {...mainProps.generate("shipmentResponsableName")}
                required
                placeholder={
                  this.props.user.shipments_responsable
                    ? this.props.user.shipments_responsable.name
                    : ""
                }
                label="Nombre"
              />
              <Input
                type="text"
                {...mainProps.generate("shipmentResponsableEmail")}
                required
                placeholder={
                  this.props.user.shipments_responsable
                    ? this.props.user.shipments_responsable.email
                    : ""
                }
                label="Email"
              />
              <Input
                type="text"
                {...mainProps.generate("shipmentResponsablePhone")}
                required
                placeholder={
                  this.props.user.shipments_responsable
                    ? formatPhone(this.props.user.shipments_responsable.phone)
                    : ""
                }
                label="Teléfono"
              />
              <BottomContainer style={{ justifyContent: "flex-end" }}>
                <Button
                  className="state-btn"
                  onClick={() => this.saveShipmentResponsable()}
                >
                  Guardar Cambios
                </Button>
              </BottomContainer>
            </div>
          </div>
        );
    }

    return <div />;
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToChangePlan: () => push("/select-plan/edit"),
      editMonthlyInvoice,
      editUserInfo,
      changePassword,
      editFiscalInfo,
      toggleSideBar,
      cancelPlan,
      storeShipmentResponsable,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(AccountInformation);
