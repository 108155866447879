import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { showMessage, hideMessage } from "../../modules/message";
import { showLoader, hideLoader } from "../../modules/loader";
import { changeDashboardGlobalStatus } from "../../modules/dashboard/actions";
import {
  getAllUserShipments,
  getAllUserQuotes,
} from "../../modules/shipments/actions";
import { getUserAllAddresses } from "../../modules/addresses/actions";
import { getCountries } from "../../modules/sidebarOrder/actions";
import { getUserAllPackages } from "../../modules/packages";

import Dashboard from "./DashboardRender";

const mapStateToProps = (state) => ({
  user: state.user,
  cards: state.cards,
  shipments: state.shipments,
  isEnterprise: state.user.customer_type === "enterprise",
  message: state.message,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showMessage,
      hideMessage,
      showLoader,
      hideLoader,
      goToShipmentsList: () => push("/envios"),
      goToPaymentMethod: () => push("/metodos-pago"),
      goToDashboardCheckout: () => push("/dashboard/checkout"),
      changeDashboardGlobalStatus,
      getAllUserShipments,
      getAllUserQuotes,
      getUserAllAddresses,
      getUserAllPackages,
      getCountries,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
