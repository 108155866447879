import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import Loader from "react-loader-spinner";
import Moment from "react-moment";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar, DateRange } from "react-date-range";
import DatePicker from "react-datepicker";
import * as rdrLocales from "react-date-range/src/locale";
import { CSVLink } from "react-csv";
import {
  appendFilter,
  deleteFilter,
  clearFilters,
} from "../../../modules/shipments/actions";
import Button from "../../Button";
import { DropdownConfigV2 as DropdownConfig, ConfigLink } from "../index";
import Text from "../../Text";
import Filter from "../../Filter";
import { searchPermission } from "../../../utils/global_functions";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import "react-datepicker/dist/react-datepicker.css";
import {
  SHOPIFY_ORDER_TYPE,
  SHOPIFY_ORDER,
  INTERNALID,
  ORDER_TYPE,
  ORDER,
  DATE,
  TO_NAME,
  DESTINATION,
  SERVICE,
  AMOUNT,
  ORIGIN,
  FROM_NAME,
  MARKETPLACE,
  PRODUCT_ID,
  OVERWEIGHT,
  titles,
  conditions,
  icons,
  conditionToOperator,
  filterConditions,
  options,
  columnName,
  STATUS,
  PURCHASE_DATE,
  TRACKING_NUMBER,
} from "./filtersResources";

import "../style.css";
import Input from "../../Input";
import Dropdown from "../../Dropdown";

const today = new Date();
const tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);

const selectionRange = {
  selection: {
    startDate: today,
    endDate: tomorrow,
    key: "selection",
  },
};

class AllFilters extends React.Component {
  state = {
    visible: false,
    selected: false,
    selectedOption: [],
    selectedOptionCheck: [],
    openCondition: false,
    conditionFilterValue: "",
    value: [new Date(), new Date()],
    singleDate: today,
    selectionRange: {
      selection: {
        startDate: today,
        endDate: tomorrow,
        key: "selection",
      },
    },
  };

  setInitialState = (visible = false) =>
    this.setState({
      visible,
      selected: false,
      openCondition: false,
      conditionFilterValue: "",
    });

  linksToRender = [
    {
      label: titles[STATUS],
      image: icons[STATUS],
      function: () => this.select(STATUS, titles[STATUS]),
    },
    {
      label: titles[ORDER],
      image: icons[ORDER],
      function: () => this.select(ORDER, titles[ORDER]),
    },
    {
      label: titles[INTERNALID],
      image: icons[INTERNALID],
      function: () => this.select(INTERNALID, titles[INTERNALID]),
    },
    {
      label: titles[PRODUCT_ID],
      image: icons[PRODUCT_ID],
      function: () => this.select(PRODUCT_ID, titles[PRODUCT_ID]),
    },
    {
      label: titles[TRACKING_NUMBER],
      image: icons[TRACKING_NUMBER],
      function: () => this.select(TRACKING_NUMBER, titles[TRACKING_NUMBER]),
    },
    {
      label: titles[DATE],
      image: icons[DATE],
      function: () => this.select(DATE, titles[DATE]),
    },
    {
      label: titles[PURCHASE_DATE],
      image: icons[PURCHASE_DATE],
      function: () => this.select(PURCHASE_DATE, titles[PURCHASE_DATE]),
    },
    {
      label: titles[TO_NAME],
      image: icons[TO_NAME],
      function: () => this.select(TO_NAME, titles[TO_NAME]),
    },
    {
      label: titles[DESTINATION],
      image: icons[DESTINATION],
      function: () => this.select(DESTINATION, titles[DESTINATION]),
    },
    {
      label: titles[SERVICE],
      image: icons[SERVICE],
      function: () => this.select(SERVICE, titles[SERVICE]),
    },
    {
      label: titles[SHOPIFY_ORDER],
      image: icons[SHOPIFY_ORDER],
      function: () => this.select(SHOPIFY_ORDER, titles[SHOPIFY_ORDER]),
    },
    {
      label: titles[SHOPIFY_ORDER_TYPE],
      image: icons[SHOPIFY_ORDER_TYPE],
      function: () =>
        this.select(SHOPIFY_ORDER_TYPE, titles[SHOPIFY_ORDER_TYPE]),
    },
    {
      label: titles[AMOUNT],
      image: icons[AMOUNT],
      function: () => this.select(AMOUNT, titles[AMOUNT]),
    },
    {
      label: titles[ORIGIN],
      image: icons[ORIGIN],
      function: () => this.select(ORIGIN, titles[ORIGIN]),
    },
    {
      label: titles[FROM_NAME],
      image: icons[FROM_NAME],
      function: () => this.select(FROM_NAME, titles[FROM_NAME]),
    },
    {
      label: titles[MARKETPLACE],
      image: icons[MARKETPLACE],
      function: () => this.select(MARKETPLACE, titles[MARKETPLACE]),
    },
    {
      label: titles[OVERWEIGHT],
      image: icons[OVERWEIGHT],
      function: () => this.select(OVERWEIGHT, titles[OVERWEIGHT]),
    },
  ];

  openFilters = () => {
    this.setState({ visible: !this.state.visible, selected: false });
  };

  select = (selected, title) => {
    this.setState({
      selected,
      selectedOption: [...this.state.selectedOption, title],
    });
  };

  openCondition = (id) => {
    this.setState({
      openCondition: id,
      conditionFilterValue: this.state.selected == OVERWEIGHT ? "1" : "",
      singleDate: today,
      selectionRange,
    });
  };

  checkTab(option, tab, element) {
    if (this.state.selectedOptionCheck.indexOf(element.label) > -1) {
      return true;
    }

    if (option == 0 && tab == 0) {
      return true;
    }

    if (option == 13 && tab == 0) {
      return true;
    }

    return false;
  }

  handleChange = (value) => {
    if (this.state.selected != DATE && this.state.selected != PURCHASE_DATE) {
      return this.setState({ conditionFilterValue: value });
    }
    if (this.state.openCondition != "between") {
      return this.setState({ singleDate: value });
    }
    if (this.state.selected === PURCHASE_DATE) {
      return this.setState({ selectionRange: value, value });
    }
    return this.setState({ selectionRange: value });
  };

  getSqlValue = (column, conditional, value) => {
    if (column == DATE) {
      if (conditional == "between") {
        return [
          value.selection.startDate.toISOString().split("T")[0],
          value.selection.endDate.toISOString().split("T")[0],
        ];
      }
      return value.toISOString().split("T")[0];
    }
    if (column == PURCHASE_DATE) {
      if (conditional == "between") {
        return [
          moment(value[0]).format("YYYY-MM-DD HH:mm:ss"),
          moment(value[1]).format("YYYY-MM-DD HH:mm:ss"),
        ];
      }
      return value.toISOString().split("T")[0];
    }
    if (column == SERVICE && ["equal", "not_equal"].indexOf(conditional) > -1) {
      return options[column][value];
    }
    if (column == ORDER_TYPE && ["equal"].indexOf(conditional) > -1) {
      return options[column][value] === "Manual" ? "32" : "0";
    }
    if (column == SHOPIFY_ORDER_TYPE && ["equal"].indexOf(conditional) > -1) {
      return options[column][value] === "Pendiente" ? "pending" : "paid";
    }
    if (column == STATUS && ["equal"].indexOf(conditional) > -1) {
      switch (options[column][value]) {
        case "Pendiente recoleccion":
          return "LABEL_CREATED";
        case "Incidencia":
        case "INCIDENCIA":
          return "CANCELADO";
        case "En camino":
          return "TRANSITO";
        default:
          return "LABEL_CREATED";
      }
    }
    if (column == MARKETPLACE) {
      return options[column][value].toLowerCase();
    }
    switch (conditional) {
      case "has":
      case "not_has":
        return `%${value}%`;
      case "starts_with":
        return `${value}%`;
      case "ends_with":
        return `%${value}`;
      default:
        return value;
    }
  };

  componentDidMount() {
    if (this.props.uncollected) {
      this.props.appendFilter({
        field: "STATUS",
        condition: "equal",
        value: 0,
        query: [
          columnName["STATUS"],
          conditionToOperator["equal"],
          this.getSqlValue("STATUS", "equal", 0),
        ],
      });
      this.props.updateShipments(["status|=|LABEL_CREATED"]);
    }
  }

  removeDiacritics(word) {
    var diacritics = [
      [/[\300-\306]/g, "A"],
      [/[\340-\346]/g, "a"],
      [/[\310-\313]/g, "E"],
      [/[\350-\353]/g, "e"],
      [/[\314-\317]/g, "I"],
      [/[\354-\357]/g, "i"],
      [/[\322-\330]/g, "O"],
      [/[\362-\370]/g, "o"],
      [/[\331-\334]/g, "U"],
      [/[\371-\374]/g, "u"],
      [/[\321]/g, "N"],
      [/[\361]/g, "n"],
      [/[\307]/g, "C"],
      [/[\347]/g, "c"],
    ];
    var s = word;
    for (var i = 0; i < diacritics.length; i++) {
      s = s.replace(diacritics[i][0], diacritics[i][1]);
    }
    return s;
  }

  addFilter = async () => {
    this.setState({ selectedOptionCheck: this.state.selectedOption });
    let value;
    if (this.state.selected != DATE && this.state.selected != PURCHASE_DATE) {
      value = this.removeDiacritics(
        this.state.conditionFilterValue.toString()
      ).toLowerCase();
    } else if (this.state.openCondition != "between") {
      value = this.state.singleDate;
    } else {
      value = this.state.selectionRange;
    }

    await this.props.appendFilter({
      field: this.state.selected,
      condition: this.state.openCondition,
      value,
      query: [
        columnName[this.state.selected],
        conditionToOperator[this.state.openCondition],
        this.getSqlValue(this.state.selected, this.state.openCondition, value),
      ],
    });
    const final_filters = [];
    let param = "";
    this.props.shipments.filters.forEach((e) => {
      if (
        e.query[0] === "shopify_order_id" ||
        e.query[0] === "shopify_status"
      ) {
        if (param === "") {
          param += `${e.query[0]}${e.query[1]}${e.query[2]}`;
        } else {
          param += `&${e.query[0]}${e.query[1]}${e.query[2]}`;
        }
      } else {
        final_filters.push(`${e.query[0]}|${e.query[1]}|${e.query[2]}`);
      }
    });

    this.props.updateShipments(final_filters, param);
    this.setInitialState(false);
  };

  renderOptions(options) {
    return options.map((t) => (
      <Row key={t} className="card-dropdown-option">
        <Column xs={6} fluid>
          <Text>{t}</Text>
        </Column>
      </Row>
    ));
  }

  renderFilter = () => {
    if (this.state.selected) {
      return (
        <ul>
          {filterConditions[this.state.selected].map((item) => (
            <li
              key={item.id}
              className={`${
                this.state.openCondition == item.id ? "selected" : ""
              }`}
            >
              <Text onClick={() => this.openCondition(item.id)}>
                {item.label}
              </Text>
              <div
                className={`input-container ${
                  this.state.openCondition == item.id ? "open" : ""
                }`}
              >
                {!item.date &&
                  !item.dateRange &&
                  !item.dateRangeHour &&
                  item.type != "radio" &&
                  (item.options ? (
                    <Dropdown
                      placeholder={item.placeholder}
                      options={this.renderOptions(item.options)}
                      handleChange={this.handleChange}
                      selected={this.state.conditionFilterValue}
                    />
                  ) : (
                    <Input
                      label=""
                      type="text"
                      placeholder={item.label}
                      className=""
                      onChange={this.handleChange}
                      value={this.state.conditionFilterValue}
                    />
                  ))}

                {item.date && (
                  <Calendar
                    date={this.state.singleDate}
                    onChange={this.handleChange}
                    color="#2576DA"
                    locale={rdrLocales.es}
                  />
                )}

                {item.dateRange && (
                  <DateRange
                    ranges={[this.state.selectionRange.selection]}
                    onChange={this.handleChange}
                    color="#2576DA"
                    locale={rdrLocales.es}
                  />
                )}
                {item.dateRangeHour && (
                  <DateTimeRangePicker
                    onChange={this.handleChange}
                    disableClock
                    value={this.state.value}
                  />
                )}
              </div>
            </li>
          ))}
        </ul>
      );
    }
  };

  deleteFilterUncollected = async () => {
    this.props.renderUncollected();
    await this.props.deleteFilter(0);
    this.props.updateShipments(
      this.props.shipments.filters.map(
        (e) => `${e.query[0]}|${e.query[1]}|${e.query[2]}`
      )
    );
  };

  deleteFilter = async (index, e) => {
    await this.props.deleteFilter(index);
    this.props.updateShipments(
      this.props.shipments.filters.map(
        (e) => `${e.query[0]}|${e.query[1]}|${e.query[2]}`
      )
    );
  };

  renderDelete() {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    if (searchPermission("envios", "eliminar", permissions)) {
      return (
        <Button
          className="action-btn"
          type="table"
          onClick={() => this.props.deleteShipments()}
          disabled={this.state.paymentDisabled}
        >
          Eliminar
        </Button>
      );
    }
    return <div />;
  }

  removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  deleteFilter = async (index, e) => {
    this.removeItemAll(this.state.selectedOptionCheck, titles[e.field]);
    this.removeItemAll(this.state.selectedOption, titles[e.field]);
    await this.props.deleteFilter(index);
    this.props.updateShipments(
      this.props.shipments.filters.map(
        (e) => `${e.query[0]}|${e.query[1]}|${e.query[2]}`
      )
    );
  };

  render() {
    const {
      shipments,
      selected,
      goToPayment,
      generateGuides,
      duplicateShipments,
      deleteShipments,
      downloadGuides,
      downloadGuidesDocs,
      csvData,
      completeOrders,
      completeOrdersFunc,
      canPayShipments,
      uncollected,
      renderUncollected,
      downloadGuidesPDF,
    } = this.props;
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }

    return (
      <div>
        <div className="conditions-container">
          {shipments &&
            shipments.filters &&
            shipments.filters.map((e, i) => (
              <label key={i} className="condition">
                <img src={icons[e.field]} />
                {e.field == OVERWEIGHT &&
                  (e.condition == "equal" ? "Con " : "Sin ")}
                {titles[e.field]}
                {e.field != OVERWEIGHT && conditions[e.condition]}
                {e.field == DATE ? (
                  e.condition == "between" ? (
                    <div style={{ display: "inline" }}>
                      <Moment
                        date={e.value.selection.startDate}
                        format="DD-MMM-YY"
                        local="es"
                      />{" "}
                      y
                      <Moment
                        date={e.value.selection.endDate}
                        format="DD-MMM-YY"
                        local="es"
                      />
                    </div>
                  ) : (
                    <Moment date={e.value} format="DD-MMM-YY" local="es" />
                  )
                ) : e.field == PURCHASE_DATE ? (
                  e.condition == "between" ? (
                    <div style={{ display: "inline" }}>
                      <Moment
                        date={e.value[0]}
                        format="YYYY-MM-DD HH:mm:ss"
                        local="es"
                      />{" "}
                      y
                      <Moment
                        date={e.value[1]}
                        format="YYYY-MM-DD HH:mm:ss"
                        local="es"
                      />
                    </div>
                  ) : (
                    <Moment date={e.value} format="DD-MMM-YY" local="es" />
                  )
                ) : (e.field == SERVICE ||
                    e.field == ORDER_TYPE ||
                    e.field == SHOPIFY_ORDER_TYPE ||
                    e.field == MARKETPLACE ||
                    e.field == STATUS) &&
                  ["equal", "not_equal"].indexOf(e.condition) > -1 ? (
                  options[e.field][e.value]
                ) : e.field == OVERWEIGHT ? (
                  ""
                ) : (
                  e.value
                )}

                <i
                  className="ion-android-close"
                  onClick={() => this.deleteFilter(i, e)}
                />
              </label>
            ))}

          {uncollected && (
            <label className="condition">
              Pendiente De recoleccion{" "}
              <i
                className="ion-android-close"
                onClick={() => this.deleteFilterUncollected()}
              />
            </label>
          )}

          <div className="add-filter-button-container" style={{ zIndex: 100 }}>
            <Button
              className={`add-filter-button ${
                shipments.appendingFilter ? "appending-filter" : ""
              }`}
              type="link"
              onClick={() => this.openFilters()}
            >
              Añadir filtro +
            </Button>
            <Loader
              className="loader-filters"
              type="Oval"
              color="#2576DA"
              height="16"
              width="16"
            />
            <DropdownConfig
              className={`filter-dd ${this.state.visible && "open"} ${
                this.state.selected && "selected"
              } ${this.state.selected == DATE && "filter-date"} ${
                this.state.selected == PURCHASE_DATE && "filter-date"
              }`}
            >
              {this.linksToRender.map((element, index) => {
                if (this.checkTab(index, this.props.selectedType, element)) {
                  return null;
                } else {
                  return (
                    <ConfigLink
                      className="config-link"
                      key={index}
                      onClick={element.function ? element.function : () => {}}
                    >
                      <a
                        href={
                          element.link ? element.link : "javascript:void(0)"
                        }
                        target={element.link ? "_blank" : "_self"}
                      >
                        <Text
                          type="textTable"
                          style={{ verticalAlign: "middle" }}
                        >
                          {element.image && (
                            <img
                              src={element.image}
                              style={{ marginRight: "4px" }}
                            />
                          )}
                          {element.label}
                        </Text>
                      </a>
                    </ConfigLink>
                  );
                }
              })}
              <div className="filter-container">
                <Text
                  type="textTable"
                  className="filter-title"
                  onClick={() => this.setInitialState(true)}
                >
                  <img src={icons[this.state.selected]} />
                  {titles[this.state.selected]}
                </Text>
                <div className="fc-adapter">{this.renderFilter()}</div>
                <Button
                  type="link"
                  className="add-filter"
                  onClick={this.addFilter}
                >
                  Agregar filtro
                </Button>
              </div>
            </DropdownConfig>
          </div>
        </div>
        <div className={`actions-btns ${selected.length > 0 && "open"}`}>
          {this.props.user.has_credit && !completeOrders && !downloadGuides && (
            <Button
              className="action-btn margin"
              type="table"
              onClick={() => generateGuides(this.props.selected)}
              disabled={this.state.paymentDisabled}
            >
              Generar Guías
            </Button>
          )}
          {!this.props.user.has_credit &&
            canPayShipments &&
            !completeOrders &&
            searchPermission("envios", "editar", permissions) &&
            this.props.selectedType !== 3 &&
            !this.props.user.methodPayments?.includes("none") && (
              <Button
                className="action-btn margin"
                type="table"
                onClick={() => goToPayment()}
                disabled={this.state.paymentDisabled}
              >
                Pagar
              </Button>
            )}
          {!completeOrders &&
            searchPermission("envios", "editar", permissions) &&
            this.props.selectedType !== 3 && (
              <Button
                className="action-btn margin"
                type="table"
                onClick={() => duplicateShipments()}
                disabled={this.state.paymentDisabled}
              >
                Duplicar
              </Button>
            )}
          {!completeOrders &&
            searchPermission("envios", "eliminar", permissions) &&
            this.props.selectedType !== 3 &&
            this.props.selectedType !== 2 &&
            this.props.selectedType !== 1 && (
              <Button
                className="action-btn margin"
                type="table"
                onClick={() => deleteShipments()}
                disabled={this.state.paymentDisabled}
              >
                Eliminar
              </Button>
            )}
          {downloadGuides && (
            <Button
              className="action-btn margin"
              type="table"
              onClick={() => downloadGuidesDocs()}
              disabled={this.state.paymentDisabled}
            >
              Descargar Guias
            </Button>
          )}
          {downloadGuides && (
            <Button
              className="action-btn margin"
              type="table"
              onClick={() => downloadGuidesPDF()}
              disabled={this.state.paymentDisabled}
            >
              Descargar PDF Unico
            </Button>
          )}
          {/* {selected && selected.length > 0 && !this.props.loadingCsv && (
            <Button
              className="btn-gray-download"
              data={csvData}
              onClick={this.openCSVFilters}
              target="_blank"
            >
              Descargar CSV
            </Button>
          )} */}
          {completeOrders && searchPermission("envios", "editar", permissions) && (
            <Button
              className="action-btn margin"
              type="table"
              onClick={() => completeOrdersFunc()}
              disabled={this.state.paymentDisabled}
            >
              Completar
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  shipments: state.shipments,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      appendFilter,
      deleteFilter,
      clearFilters,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AllFilters);
