import React from "react";
import { DateRange } from "react-date-range";
import * as rdrLocales from "react-date-range/src/locale";
import { boundMethod } from "autobind-decorator";
import Arrow from "./left-arrow.svg";
import Calendar from "./calendar.svg";
import clienteImg from "./cliente.svg";
import paqueteriaImg from "./paqueteria.svg";
import dimensionesImg from "./dimensiones.svg";
import costosImg from "./costo.svg";
import pesoImg from "./peso.svg";
import Input from "../../../../components/Input";
import Text from "../../../../components/Text";
import Dropdown from "../../../../components/Dropdown";
import Filter from "../../../../components/Filter";
import Button, { ActionButtonGroup } from "../../../../components/ButtonGroup";

import "./style.css";

const today = new Date();
const tomorrow = new Date();
class Filters extends React.Component {
  state = {
    filtersOpen: false,
    selectedFilter: null,
    selectionRange: {
      selection: {
        startDate: today,
        endDate: tomorrow,
        key: "selection"
      }
    },
    selectedCountry: null,
    selectedCountryError: "",
    selectedCountryFlag: false,
    setedFilter: null,
    inputError: "",
    inputValue: null
  };

  setFilter() {
    const {
      selectedFilter,
      inputValue,
      selectionRange,
      selectedCountry,
      selectedCountryFlag
    } = this.state;
    const { countries } = this.props;
    switch (selectedFilter) {
      case "Peso":
        if (inputValue) {
          this.setState({
            setedFilter: inputValue,
            filtersOpen: false,
            inputValue: null
          });
          this.props.filterData({ type: "quote", value: inputValue });
        } else {
          this.setState({ inputError: "Campo Obligatiorio" });
        }
        break;
      case "Costo":
        if (inputValue) {
          this.setState({
            setedFilter: inputValue,
            filtersOpen: false,
            inputValue: null
          });
          this.props.filterData({ type: "quote", value: inputValue });
        } else {
          this.setState({ inputError: "Campo Obligatiorio" });
        }
        break;
      case "Id de cliente":
        if (inputValue) {
          this.setState({
            setedFilter: inputValue,
            filtersOpen: false,
            inputValue: null
          });
          this.props.filterData({ type: "carrier", value: inputValue });
        } else {
          this.setState({ inputError: "Campo Obligatiorio" });
        }
        break;
      case "Dimensiones":
        if (inputValue) {
          this.setState({
            setedFilter: inputValue,
            filtersOpen: false,
            inputValue: null
          });
          this.props.filterData({ type: "carrier", value: inputValue });
        } else {
          this.setState({ inputError: "Campo Obligatiorio" });
        }
        break;
      case "Rango de Fechas":
        var day1 = null;
        var day1formated = null;
        var dd = selectionRange.selection.startDate.getDate();
        var mm = selectionRange.selection.startDate.getMonth() + 1; // January is 0!

        var yyyy = selectionRange.selection.startDate.getFullYear();
        if (dd < 10) {
          dd = `0${dd}`;
        }
        if (mm < 10) {
          mm = `0${mm}`;
        }
        var day1 = `${dd}/${mm}/${yyyy}`;
        var day1formated = `${yyyy}-${mm}-${dd}`;
        var day2 = null;
        var day2formated = null;
        var dd = selectionRange.selection.endDate.getDate();
        var mm = selectionRange.selection.endDate.getMonth() + 1; // January is 0!

        var yyyy = selectionRange.selection.endDate.getFullYear();
        if (dd < 10) {
          dd = `0${dd}`;
        }
        if (mm < 10) {
          mm = `0${mm}`;
        }
        var day2 = `${dd}/${mm}/${yyyy}`;
        var day2formated = `${yyyy}-${mm}-${dd}`;
        this.setState({
          setedFilter: `${day1} - ${day2}`,
          filtersOpen: false,
          inputValue: null
        });
        this.props.filterData({
          type: "date",
          value: `start_date=${day1formated}&end_date=${day2formated}`
        });

        break;
      case "Paqueteria":
        if (selectedCountryFlag) {
          this.setState({
            setedFilter: countries[selectedCountry].name,
            filtersOpen: false,
            inputValue: null
          });
          this.props.filterData({
            type: "country",
            value: countries[selectedCountry].currency
          });
        } else {
          this.setState({ selectedCountryError: "Campo Obligatiorio" });
        }
        break;
      default:
        break;
    }
  }

  handleInput = inputValue => {
    this.setState({ inputValue });
  };

  toogleFilters() {
    this.setState({ filtersOpen: !this.state.filtersOpen });
  }

  selectedItem(selectedFilter) {
    this.setState({ selectedFilter });
  }

  @boundMethod
  handleDropDownCountryChange(selectedCountry) {
    this.setState({ selectedCountry, selectedCountryFlag: true });
  }

  _renderCarriers = () =>
    this.props.countries
      ? this.props.countries.map(country => (
          <Text key={country.object_id}>{country.name}</Text>
        ))
      : null;

  render() {
    const {
      setedFilter,
      selectedFilter,
      filtersOpen,
      selectionRange,
      selectedCountry,
      selectedCountryError,
      inputValue,
      inputError
    } = this.state;
    return (
      <div>
        {setedFilter ? (
          <Filter
            noMarginFilter={this.props.noMarginFilter}
            onClose={() => {
              this.setState({
                setedFilter: null,
                selectedFilter: null,
                selectedCountry: null,
                selectedCountryFlag: false,
                selectedCountryError: ""
              });
              this.props.filterData({ type: null, value: null });
            }}
          >
            {selectedFilter} - {setedFilter}
          </Filter>
        ) : (
          <Button onClick={() => this.toogleFilters()} type="link">
            + Añadir Filtro
          </Button>
        )}

        {filtersOpen && (
          <div style={{ position: "relative" }}>
            <div
              className={`container-filters ${filtersOpen &&
                "open"} ${selectedFilter && "selected-item"}`}
            >
              {!selectedFilter ? (
                <div>
                  <div
                    onClick={() => this.selectedItem("Rango de Fechas")}
                    className="filter-item"
                  >
                    <img
                      src={Calendar}
                      alt=""
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "5px"
                      }}
                    />
                    Rango de Fechas
                  </div>
                  <div
                    onClick={() => this.selectedItem("Id de Cliente")}
                    className="filter-item"
                  >
                    <img
                      src={clienteImg}
                      alt=""
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "5px"
                      }}
                    />
                    Id de Cliente
                  </div>
                  <div
                    onClick={() => this.selectedItem("Paqueteria")}
                    className="filter-item"
                  >
                    <img
                      src={paqueteriaImg}
                      alt=""
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "5px"
                      }}
                    />
                    Paqueteria
                  </div>
                  {/* <div onClick={() => this.selectedItem('Cliente')} className="filter-item">
                        Cliente
                    </div> */}
                  <div
                    onClick={() => this.selectedItem("Dimensiones")}
                    className="filter-item"
                  >
                    <img
                      src={dimensionesImg}
                      alt=""
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "5px"
                      }}
                    />
                    Dimensiones
                  </div>

                  <div
                    onClick={() => this.selectedItem("Costo")}
                    className="filter-item"
                  >
                    <img
                      src={costosImg}
                      alt=""
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "5px"
                      }}
                    />
                    Costos
                  </div>

                  <div
                    onClick={() => this.selectedItem("Peso")}
                    className="filter-item"
                  >
                    <img
                      src={pesoImg}
                      alt=""
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: "5px"
                      }}
                    />
                    Peso
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    onClick={() => this.setState({ selectedFilter: null })}
                    className="filter-item selected"
                  >
                    <img
                      src={Arrow}
                      style={{ height: "12px", width: "12px" }}
                      alt=""
                    />
                    {selectedFilter}
                  </div>

                  {selectedFilter === "Rango de Fechas" && (
                    <div>
                      <DateRange
                        ranges={[selectionRange.selection]}
                        onChange={value =>
                          this.setState({ selectionRange: value })
                        }
                        color="#2576DA"
                        locale={rdrLocales.es}
                      />
                    </div>
                  )}

                  {selectedFilter === "Paqueteria" && (
                    <div className="container-selected">
                      <div className="filter-dropdown-quotes">
                        <Dropdown
                          error={selectedCountryError}
                          options={this._renderCarriers()}
                          handleChange={this.handleDropDownCountryChange}
                          selected={selectedCountry}
                          placeholder={
                            <Text className="dropdown-placeholder">
                              Paqueteria
                            </Text>
                          }
                        />
                      </div>
                    </div>
                  )}

                  {selectedFilter === "Id de Cliente" && (
                    <div className="container-selected">
                      <Input
                        className="filter-input"
                        type="text"
                        placeholder={"Id de Cliente"}
                      />
                    </div>
                  )}
                  {selectedFilter === "Peso" && (
                    <div className="container-selected">
                      <Input
                        className="filter-input"
                        type="text"
                        placeholder={"Peso"}
                      />
                    </div>
                  )}
                  {selectedFilter === "Costo" && (
                    <div className="container-selected">
                      <Input
                        type="number"
                        textClass="span-text"
                        measureClass="measureClass"
                        placeholder="30"
                        costos
                      />
                    </div>
                  )}
                  {selectedFilter === "Dimensiones" && (
                    <div className="container-selected">
                      <Input
                        type="number"
                        textClass="span-text"
                        measureClass="measureClass"
                        placeholder="30"
                        dimensions
                      />
                    </div>
                  )}

                  <div className="btn-add-filter">
                    <Button onClick={() => this.setFilter()} type="link">
                      Añadir
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Filters;
