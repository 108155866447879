import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip } from 'react-tippy';
import ProgressBar from '../ProgressBar';
import Tabs, { Tab, Step } from '../Tabs';
import { setStep } from '../../modules/sidebarOrder/actions';
import { Content } from '../WrapperSideBar/styledObjects';
import TabStep from '../WrapperSideBar/tabStep';

class WrapperSideBar extends React.Component {
  state = {
    shakeClass: '',
    errorToolTip: 'Completar la información de',
    toolTipOpen: false,
  };

  setStateAsync = state => new Promise((resolve) => {
    this.setState(state, resolve);
  });

  _isShipmentCompleted = () => this.props.sidebar.stepTitles[0].status == 'completed'
    && this.props.sidebar.stepTitles[1].status == 'completed'
    && this.props.sidebar.stepTitles[2].status == 'completed';

  getProgress = (titles) => {
    let result = 0;
    const total = titles.length;
    if (total == 0) return 0;
    titles.map((title) => {
      if (title.status == 'completed') result++;
    });
    return (result / total) * 100;
  };

  _handleOnChange = async (selected) => {
    await this.setStateAsync({ shakeClass: '' });
    if (selected == 3) {
      if (this._isShipmentCompleted()) this.props.setStep(selected);
      else this.setState({ shakeClass: 'animated shake', toolTipOpen: true });
    } else this.props.setStep(selected);
  };

  _closeToolTip = () => {
    setTimeout(() => {
      this.setState({ toolTipOpen: false });
    }, 3000);
  };

  _getStepError = () => {
    if (this.props.sidebar.stepTitles.length > 0) {
      if (this.props.sidebar.stepTitles[0].status != 'completed') {
        return this.props.sidebar.stepError.from != ''
          ? this.props.sidebar.stepError.from
          : 'Completar la información de Origen';
      }
      if (this.props.sidebar.stepTitles[1].status != 'completed') {
        return this.props.sidebar.stepError.to != ''
          ? this.props.sidebar.stepError.to
          : 'Completar la información de Destíno';
      }
      if (this.props.sidebar.stepTitles[2].status != 'completed') {
        return this.props.sidebar.stepError.package != ''
          ? this.props.sidebar.stepError.package
          : 'Completar la información de Paquete';
      }
      if (this.props.sidebar.stepTitles[3].status != 'completed') { return 'Error al seleccionar el servicio disponible'; }
    } else return '';
  };

  render() {
    return (
      <div>
        <ProgressBar fill={this.getProgress(this.props.sidebar.stepTitles)} />
        <Tooltip
          title={this._getStepError()}
          position="bottom"
          trigger="manual"
          distance={10}
          delay={5}
          onShown={this._closeToolTip}
          open={this.state.toolTipOpen}
        >
          <Tabs
            id="nl_steps"
            className="nl_steps csb-adjust-width"
            sliderClassName="steps_slider"
            selected={this.props.sidebar.step}
            isNewShipment
            right
            onChange={this._handleOnChange}
          >
            {this.props.sidebar.stepTitles.map((step, index) => (
              <TabStep
                key={index}
                selected={this.props.sidebar.step - 1}
                icon_status={step.status}
                shakeClass={step.label == 'Servicio' ? this.state.shakeClass : ''}
              >
                {step.label}
              </TabStep>
            ))}
          </Tabs>
        </Tooltip>
        {this.props.children}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  sidebar: state.newShipment,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setStep,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrapperSideBar);
