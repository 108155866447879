import React from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { timingSafeEqual } from "crypto";
import { Row, Column } from "hedron";
import { HorizontalBar, Bar, Line } from "react-chartjs-2";
import Swal from "sweetalert2";
import SweetAlert from "sweetalert2-react";
import { Route, Switch, Redirect } from "react-router-dom";
import Menu from "../../components/Menu";
import Text, { Title } from "../../components/Text";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import Card, { Divider } from "../../components/Card";
import Table, { Th, Tr, Td } from "../../components/Table";
import "./style.css";
import question_mark from "./icons/question-circle.svg";
import colors from "../../utils/colors";
import { hexToRGBA, searchPermission } from "../../utils/global_functions";
import {
  shipmentsAnalytics,
  shipmentsTotal,
} from "../../axios/services/routes";
import { CSVLink } from "react-csv";
import * as firebase from "firebase";
import Modal from "react-responsive-modal";
import Message from "../../components/Message";
import { S_IFREG } from "constants";

const periodOptions = [
  "Últimos 7 días",
  "Últimos 14 días",
  "Últimos 21 días",
  "Últimos 30 días",
];

const providers = ["Estafeta", "FedEx", "Redpack", "UPS"];

const providers_statuses = {
  available: "Operando correctamente",
  warning: "Servicio degradado",
  error: "Error en el servicio",
  default: "Consultando...",
};
const today = new Date();
const dates = [
  new Date().setDate(today.getDate() - 7),
  new Date().setDate(today.getDate() - 14),
  new Date().setDate(today.getDate() - 21),
  new Date().setDate(today.getDate() - 30),
];

export default class Dashboard extends React.Component {
  state = {
    generalSummary: {
      filter: 0,
      collected: 0,
      scanned: 0,
      on_the_way: 0,
      incidents: 0,
      delivered: 0,
    },
    audit: {
      filter: 3,
      generated: 0,
      used: 0,
      avg_price: "0.00",
      avg_weight: "0.00",
    },
    packages: {
      filter: 3,
    },
    providers: [],
    carriers: [],
    overweight: {
      has_ow: false,
      showing_details: true,
      total_count: 0,
      total_kgs: 0,
    },
    storedPackages: {},
    unstoredPackages: {},
    audit_download: null,
    today: null,
    label_graphic_data: {
      labels: [],
      datasets: [],
    },
    label_graphic_data_download: [],
    showLabelGraphic: false,
    estafeta: {
      tracking: 0,
      labels: 0,
      rates: 0,
    },
    open: true,
    showModal: false,
    showMessageDhl: false,
    renderMessageDhl: false,
  };

  componentDidMount() {
    let today = new Date();
    const { hideLoader, showLoader } = this.props;
    this.getCarriers();

    this.getDashboardData();
    // hideLoader();
    const dd = today.getDate().toString().padStart(2, "0");
    const mm = (today.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();

    let prevDat = new Date();
    prevDat.setDate(prevDat.getDate() - 30);
    const dd2 = prevDat.getDate().toString().padStart(2, "0");
    const mm2 = (prevDat.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
    const yyyy2 = prevDat.getFullYear();

    today = `${yyyy}-${mm}-${dd}`;
    prevDat = `${yyyy2}-${mm2}-${dd2}`;
    this.setState({ today });
    this.getLabelsData(prevDat, today);
  }

  ModalInfo = () => {
    Swal.fire({
      title: "Recargas Wallet en automático ",
      html: `<div style="text-align: left; padding: 0 80px; margin-bottom: 10px;"><br>
      Estimado usuario Mienvío, <br><br>
      A partir del <b>Jueves 01 de Abril 2021</b> deberás recargar tu saldo Wallet de manera automática; sin llenar un formulario, ni esperar una verificación de nuestra parte. 
      <br><br>Esto será posible a través de una <b>nueva cuenta clabe</b> personal, que generamos con <b>STP</b>, para cada uno de nuestros clientes. Solo tienes que dar de alta está nueva cuenta STP en tu banco y transferir el monto que desees agregar a tu Wallet Mienvío. 
      <br><br>Nuevos beneficios:
      <br><br><p style="display: list-item; margin: 0px;">El monto minimo de recarga es de $100.00 MXN.</p>
      <br><p style="display: list-item; margin: 0px;">Podrás facturar en automático el monto de tu recarga.</p>
      <br><p style="display: list-item; margin: 0px;">Tu saldo se reflejará en tu cuenta de inmediato.</p>
      <br><br>Podrás ver tu cuenta clabe STP en el apartado de “Métodos de pago”, en la pestaña “Wallet”
      <br><br>Para cualquier duda consulta nuestros centros de atención al cliente o bien a tu asesor de ventas. 
      <br><br>En Mienvío estamos para servirte. 
      <br><br></div>`,
      icon: false,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      width: "42rem",
    }).then((result) => {});
  };

  renderRedirectUnconllected = () => {
    this.props.history.push("/envios?filters=sinrecoleccion");
    // window.location = "/envios?filters=sinrecoleccion";
  };

  listenFirebase(carriers) {
    carriers.forEach((element) => {
      firebase
        .database()
        .ref(`/carriers/${element.low_name}/tracking`)
        .on("value", (snapshot) => {
          this.setState((prevState) => ({
            [element.low_name]: {
              ...prevState[element.low_name],
              tracking: snapshot.node_.value_,
            },
          }));
        });
      firebase
        .database()
        .ref(`/carriers/${element.low_name}/labels`)
        .on("value", (snapshot) => {
          this.setState((prevState) => ({
            [element.low_name]: {
              ...prevState[element.low_name],
              labels: snapshot.node_.value_,
            },
          }));
        });
      firebase
        .database()
        .ref(`/carriers/${element.low_name}/rates`)
        .on("value", (snapshot) => {
          this.setState((prevState) => ({
            [element.low_name]: {
              ...prevState[element.low_name],
              rates: snapshot.node_.value_,
            },
          }));
        });
    });
  }

  getCarriers() {
    axios
      .get("/api/providers")
      .then((response) => {
        if (response.data && response.data.length !== 0) {
          const carriers = response.data.slice(1, response.data.length);
          this.setState({ carriers });
          this.listenFirebase(carriers);
        }
      })
      .catch((error) => {});
  }

  getLabelsData = (start_date, end_date) => {
    axios
      .get(`api/labels/analytics?start_date=${start_date}&end_date=${end_date}`)
      .then((response) => {
        const dataHeaders = [];
        const dataElements = [];
        const tempLabelDataDownload = [];
        tempLabelDataDownload.push(["Fecha", "Total"]);
        for (const i in response.data) {
          dataHeaders.push(i);
          dataElements.push(response.data[i].labels_generated);
          tempLabelDataDownload.push([
            `${i}`,
            `${response.data[i].labels_generated}`,
          ]);
        }
        const data = {
          labels: dataHeaders,
          datasets: [
            {
              label: "Guias Generadas",
              fill: false,
              lineTension: 0.1,
              backgroundColor: "rgba(235,44,136,0.4)",
              borderColor: "rgba(235,44,136,1)",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(235,44,136,1)",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(235,44,136,1)",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: dataElements,
            },
          ],
        };
        this.setState({
          label_graphic_data: data,
          label_graphic_data_download: tempLabelDataDownload,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDashboardData = () => {
    const todayDate = new Date();
    const todayStr = todayDate.toISOString().split("T")[0];
    const params = {
      params: {
        tracking_start_date: new Date(dates[this.state.generalSummary.filter])
          .toISOString()
          .split("T")[0],
        tracking_end_date: todayStr,
        audit_start_date: new Date(dates[this.state.audit.filter])
          .toISOString()
          .split("T")[0],
        audit_end_date: todayStr,
        package_start_date: new Date(dates[this.state.packages.filter])
          .toISOString()
          .split("T")[0],
        package_end_date: todayStr,
      },
    };

    // axios
    //   .get(shipmentsWithoutCollectedTotal)
    //   .then((response) => {
    //     const total_shipments = response.data;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    axios
      .get(shipmentsTotal)
      .then((response) => {
        const total_shipments = response.data;
        this.setState({
          total_shipments_created: total_shipments.total_shipments_created,
          total_available_shipments: total_shipments.available_shipments,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(shipmentsAnalytics, params)
      .then((response) => {
        const dashboard = response.data;
        const audit_download_temp = [];
        audit_download_temp.push([
          "Guias generadas",
          "Guias usadas",
          "Precio promedio de envio",
          "Peso promedio de envio",
        ]);
        audit_download_temp.push([
          dashboard.audit.generated,
          dashboard.audit.used,
          dashboard.audit.avg_price,
          dashboard.audit.avg_weight,
        ]);
        this.setState({
          ...this.state,
          withoutLabel: dashboard.without_label,
          readyToPay: dashboard.ready_to_pay,
          readyToPay_ids: dashboard.ready_to_pay_ids
            ? dashboard.ready_to_pay_ids
            : [],
          waitingCollection: dashboard.waiting_collection,
          generalSummary: {
            ...this.state.generalSummary,
            collected: dashboard.general_summary.collected,
            scanned: dashboard.general_summary.scanned,
            on_the_way: dashboard.general_summary.on_the_way,
            incidents: dashboard.general_summary.incidents,
            delivered: dashboard.general_summary.delivered,
          },
          audit_download: audit_download_temp,
          audit: {
            ...this.state.audit,
            generated: dashboard.audit.generated,
            used: dashboard.audit.used,
            avg_price: dashboard.audit.avg_price.toFixed(2),
            avg_weight: dashboard.audit.avg_weight.toFixed(2),
          },
          overweight: {
            ...this.state.overweight,
            has_ow: dashboard.overweight.total_amount > 0,
            total_count: dashboard.overweight.total_count
              ? dashboard.overweight.total_count
              : 0,
            total_amount: dashboard.overweight.total_amount
              ? dashboard.overweight.total_amount
              : 0,
            total_kgs: dashboard.overweight.total_kgs
              ? dashboard.overweight.total_kgs
              : 0,
            detailedOverweights: dashboard.overweight.detailed_overweights
              ? dashboard.overweight.detailed_overweights
              : 0,
          },
          storedPackages: {
            labels:
              dashboard.packages.stored.length > 0
                ? dashboard.packages.stored.map((p) => p.alias)
                : [],
            datasets: [
              {
                label: "Empaques registrados",
                data:
                  dashboard.packages.stored.length > 0
                    ? dashboard.packages.stored.map((p) => p.times_used)
                    : [],
                backgroundColor: hexToRGBA(colors.primary.mienvio_pink, 0.8),
              },
            ],
          },
          unstoredPackages: {
            labels:
              dashboard.packages.unstored.length > 0
                ? dashboard.packages.unstored.map(
                    (p) => `${p.length}cm x ${p.width}cm x ${p.height}cm`
                  )
                : [],
            datasets: [
              {
                label: "Empaques sin registrar",
                data:
                  dashboard.packages.unstored.length > 0
                    ? dashboard.packages.unstored.map((p) => p.times_used)
                    : [],
                backgroundColor: hexToRGBA(colors.secondary.royal_blue, 0.8),
              },
            ],
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  goToPayment = async (type) => {
    const { changeDashboardGlobalStatus, goToDashboardCheckout } = this.props;

    let payload = {};
    if (type == "shipments") {
      payload = {
        shipments: this.state.readyToPay_ids,
        charge_type: "SHIPMENTS_AND_OVERWEIGHT",
      };
    } else {
      payload = {
        charge_type: "OVERWEIGHT",
      };
    }
    await changeDashboardGlobalStatus(true, payload);
    goToDashboardCheckout();
  };

  handleFilterChange = (section, val) => {
    if (section === "audit") {
      let prevDat = new Date();
      switch (val) {
        case 0:
          prevDat.setDate(prevDat.getDate() - 7);
          break;
        case 1:
          prevDat.setDate(prevDat.getDate() - 14);
          break;
        case 2:
          prevDat.setDate(prevDat.getDate() - 21);
          break;
        case 3:
          prevDat.setDate(prevDat.getDate() - 30);
          break;
        default:
          prevDat.setDate(prevDat.getDate() - 30);
          break;
      }
      const dd2 = prevDat.getDate().toString().padStart(2, "0");
      const mm2 = (prevDat.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
      const yyyy2 = prevDat.getFullYear();
      prevDat = `${yyyy2}-${mm2}-${dd2}`;
      this.getLabelsData(prevDat, this.state.today);
    }
    this.setState({ [section]: { filter: val } }, this.getDashboardData);
  };

  renderDataDownload(data, data2) {
    const newData = [];
    data.forEach((element) => {
      newData.push(element);
    });
    data2.forEach((element) => {
      newData.push(element);
    });

    return newData;
  }

  toggleOwDetails = () => {
    this.setState({
      ...this.state,
      overweight: {
        ...this.state.overweight,
        showing_details: !this.state.overweight.showing_details,
      },
    });
  };

  renderCarriers = (carriers, section) => {
    const module = carriers.length % 3;
    const total = Math.trunc(carriers.length / 3);
    let firstTotal = 0;
    let secondTotal = 0;
    let thirdTotal = 0;
    if (module == 2) {
      firstTotal = total + 1;
      secondTotal = total + 1;
      thirdTotal = total;
    } else if (module == 1) {
      firstTotal = total + 1;
      secondTotal = total;
      thirdTotal = total;
    } else {
      firstTotal = total;
      secondTotal = total;
      thirdTotal = total;
    }
    const begin =
      section === 0 ? 0 : section === 1 ? firstTotal : firstTotal + secondTotal;
    const end =
      section === 0
        ? firstTotal
        : section === 1
        ? firstTotal + secondTotal
        : carriers.length;
    return carriers.slice(begin, end).map((element, id) => (
      <Tr key={id}>
        {/* <Td>{element.name}</Td> */}
        <Td>{element.name === "FedEx" ? "FDX" : element.name}</Td>
        {this.state[element.low_name] ? (
          <Td>
            <Tr>
              <Td>
                <span
                  className={`${
                    this.state[element.low_name].tracking === 0
                      ? "status-label available"
                      : this.state[element.low_name].tracking === 1
                      ? "status-label warning"
                      : "status-label error"
                  }`}
                >
                  Tracking:{" "}
                  {this.state[element.low_name].tracking === 0
                    ? "Operando correctamente"
                    : this.state[element.low_name].tracking === 1
                    ? "Operacion intermitente"
                    : "Sin Operación"}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <span
                  className={`status-label ${
                    this.state[element.low_name].labels === 0
                      ? "available"
                      : this.state[element.low_name].labels === 1
                      ? "warning"
                      : "error"
                  }`}
                >
                  Guias:{" "}
                  {this.state[element.low_name].labels === 0
                    ? "Operando correctamente"
                    : this.state[element.low_name].labels === 1
                    ? "Operacion intermitente"
                    : "Sin Operación"}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <span
                  className={`status-label ${
                    this.state[element.low_name].rates === 0
                      ? "available"
                      : this.state[element.low_name].rates === 1
                      ? "warning"
                      : "error"
                  }`}
                >
                  Tarifas:{" "}
                  {this.state[element.low_name].rates === 0
                    ? "Operando correctamente"
                    : this.state[element.low_name].rates === 1
                    ? "Operacion intermitente"
                    : "Sin Operación"}
                </span>
              </Td>
            </Tr>
          </Td>
        ) : (
          <Td>
            <Tr>
              <Td>
                <span className="status-label">Tracking: Cargando...</span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <span className="status-label">Guias: Cargando...</span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <span className="status-label">Tarifas: Cargando...</span>
              </Td>
            </Tr>
          </Td>
        )}
      </Tr>
    ));
  };

  renderWalletCard() {
    try {
      if (this.props.user && this.props.user.methodPayments) {
        if (this.props.user.methodPayments.indexOf("none") > -1) {
          return <div />;
        } else {
          return (
            <Card size="full">
              <h5>Saldo en Wallet</h5>
              {this.props.user.wallet_balance ? (
                <h1>{`$${this.props.user.wallet_balance.toFixed(2)}`}</h1>
              ) : (
                <h1>{`$${parseFloat("0").toFixed(2)}`}</h1>
              )}
              <Divider className="hr" />
              <Button type="link" onClick={this.props.goToPaymentMethod}>
                Recargar Saldo <i className="ion-arrow-right-c" />
              </Button>
            </Card>
          );
        }
      } else {
        return (
          <Card size="full">
            <h5>Saldo en Wallet</h5>
            {this.props.user.wallet_balance ? (
              <h1>{`$${this.props.user.wallet_balance.toFixed(2)}`}</h1>
            ) : (
              <h1>{`$${parseFloat("0").toFixed(2)}`}</h1>
            )}
            <Divider className="hr" />
            <Button type="link" onClick={this.props.goToPaymentMethod}>
              Recargar Saldo <i className="ion-arrow-right-c" />
            </Button>
          </Card>
        );
      }
    } catch (error) {
      return (
        <Card size="full">
          <h5>Saldo en Wallet</h5>
          {this.props.user.wallet_balance ? (
            <h1>{`$${this.props.user.wallet_balance.toFixed(2)}`}</h1>
          ) : (
            <h1>{`$${parseFloat("0").toFixed(2)}`}</h1>
          )}
          <Divider className="hr" />
          <Button type="link" onClick={this.props.goToPaymentMethod}>
            Recargar Saldo <i className="ion-arrow-right-c" />
          </Button>
        </Card>
      );
    }
  }

  renderMessageDhl() {
    if (
      this.props.user &&
      this.props.user.wallet_balance &&
      this.state.renderMessageDhl
    ) {
      this.setState({ renderMessageDhl: false });
      return (
        <SweetAlert
          show={this.state.showMessageDhl}
          icon="success"
          title="Estimado usuario"
          text="Como parte de nuestra mejora continua y el compromiso de mejorar para tí, te informamos que el próximo Miércoles 22 de Diciembre, tendrémos una ventana de mantenimiento de 5:00 a 5:15 horas, por lo que la plataforma no estará disponible. Toma tus precauciones!"
          onConfirm={() => this.setState({ showMessageDhl: false })}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const { goToShipmentsList, isEnterprise, goToShipmentsListRecollection } =
      this.props;
    const {
      storedPackages,
      unstoredPackages,
      withoutLabel,
      readyToPay,
      waitingCollection,
      providers: stateProviders,
      overweight,
      generalSummary,
      audit,
      packages,
      audit_download,
      label_graphic_data_download,
      today,
      total_shipments_created,
      total_available_shipments,
      total_shipments_without_collected,
    } = this.state;
    const { customer_type, plan, actions } = this.props.user;

    const permissions = [];

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    return (
      <div>
        {this.renderMessageDhl()}
        <Modal
          classNames={{ modal: "modal-status" }}
          open={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <Row divisions={40} className="dashboard-cards-container">
            <Column xs={1} fluid />
            <Column xs={38} fluid>
              <div
                size="full"
                className={`apis-container ${this.state.open && "open"}`}
              >
                <h5>Estatus de las paqueterias</h5>
                {this.state.open && (
                  <Row divisions={18}>
                    <Column xs={18} md={6}>
                      <Table>
                        <Tr>
                          <Th>Servicio</Th>
                          <Th>Estatus</Th>
                        </Tr>
                        {this.renderCarriers(this.state.carriers, 0)}
                      </Table>
                    </Column>
                    <Column xs={18} md={6}>
                      <Table>
                        <Tr>
                          <Th>Servicio</Th>
                          <Th>Estatus</Th>
                        </Tr>
                        {this.renderCarriers(this.state.carriers, 1)}
                      </Table>
                    </Column>
                    <Column xs={18} md={6}>
                      <Table>
                        <Tr>
                          <Th>Servicio</Th>
                          <Th>Estatus</Th>
                        </Tr>
                        {this.renderCarriers(this.state.carriers, 2)}
                      </Table>
                    </Column>
                  </Row>
                )}
              </div>
            </Column>
            <Column xs={1} fluid />
          </Row>
        </Modal>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: "right" }} fluid>
            <Menu />
          </Column>
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Title type="titleCard">Dashboard</Title>
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={40} className="dashboard-cards-container">
          <Column xs={1} fluid />
          <Column xs={38} md={12} fluid>
            <Card size="full">
              <h5>Órdenes con datos faltantes</h5>
              {withoutLabel >= 0 ? (
                <h1>{`${withoutLabel} órdenes`}</h1>
              ) : (
                <Skeleton height={30} />
              )}
              <Divider className="hr" />
              <Button type="link" onClick={goToShipmentsList}>
                Ver órdenes <i className="ion-arrow-right-c" />
              </Button>
            </Card>
            {this.renderWalletCard()}
            <Card size="full">
              <h5>Guías listas para pagar</h5>
              {readyToPay >= 0 ? (
                <h1>{`${readyToPay} guías`}</h1>
              ) : (
                <Skeleton height={30} />
              )}
              <Divider className="hr" />
              {readyToPay >= 0 && (
                <Button
                  type="link"
                  onClick={() => this.goToPayment("shipments")}
                >
                  Pagar guías <i className="ion-arrow-right-c" />
                </Button>
              )}
            </Card>
            <Card size="full">
              <h5>Órdenes esperando recolección</h5>
              {waitingCollection >= 0 ? (
                <h1>{`${waitingCollection} órdenes`}</h1>
              ) : (
                <Skeleton height={30} />
              )}
              <Divider className="hr" />
              <Button type="link" onClick={this.renderRedirectUnconllected}>
                Ir a Ordenes <i className="ion-arrow-right-c" />
              </Button>
              <Divider className="hr" />
              <a
                href="https://blogger.mienvio.mx/centro-ayuda/recoleccion-mienvio/"
                target="_blank"
              >
                <Button type="link">¿Cómo agendar recolecciones?</Button>
              </a>
            </Card>
            {searchPermission("health_checker", "leer", permissions) && (
              <Card size="full">
                <h5>Estatus de las paqueterias</h5>
                <Divider className="hr" />
                <Button
                  type="link"
                  onClick={() => this.setState({ showModal: true })}
                >
                  Desplegar estatus <i className="ion-arrow-right-c" />
                </Button>
              </Card>
            )}
          </Column>
          <Column xs={1} className="show-for-small-only" fluid />
          <Column xs={1} fluid />
          <Column xs={38} md={25} fluid>
            <Card size="full" className="overweight-container">
              <h5>Resumen de Envíos </h5>
              <Row divisions={19}>
                <Column xs={19} md={4} fluid>
                  <Text type="microHeader">Total Envíos creados</Text>
                  {total_shipments_created > 0 ? (
                    <h4>{`${total_shipments_created}`}</h4>
                  ) : (
                    <h4>0</h4>
                  )}
                </Column>
                <Column xs={1} fluid />
                <Column xs={19} md={4} fluid>
                  <Text type="microHeader">Total Envíos disponibles</Text>
                  {total_available_shipments > 0 ? (
                    <h4>{`${total_available_shipments}`}</h4>
                  ) : (
                    <h4>0</h4>
                  )}
                </Column>
                <Column xs={1} fluid />
              </Row>
            </Card>

            <Card size="full" className="overweight-container">
              <h5>
                Sobrepesos histórico{" "}
                <a
                  href="https://blogger.mienvio.mx/2021/04/como-puedo-verificar-los-sobrepesos-de-mis-envios/"
                  target="_blank"
                >
                  <img src={question_mark} />
                </a>
              </h5>
              <Row divisions={19}>
                <Column xs={19} md={4} fluid>
                  <Text type="microHeader">Envíos con sobrepesos</Text>
                  {overweight.total_count >= 0 ? (
                    <h4>{`${overweight.total_count} envíos`}</h4>
                  ) : (
                    <Skeleton />
                  )}
                </Column>
                <Column xs={1} fluid />
                <Column xs={19} md={4} fluid>
                  <Text type="microHeader">Sobrepesos totales en kg</Text>
                  {overweight.total_kgs >= 0 ? (
                    <h4>{`${overweight.total_kgs} kg`}</h4>
                  ) : (
                    <Skeleton />
                  )}
                </Column>
                <Column xs={1} fluid />
                <Column xs={19} md={4} fluid>
                  <Text type="microHeader">Deuda por sobre peso</Text>
                  {overweight.total_amount > 0 ? (
                    <h4 style={{ color: "#E00700" }}>
                      {`-$${overweight.total_amount
                        .toFixed(2)
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} MXN`}
                    </h4>
                  ) : (
                    <h4>{"$0.00 MXN"}</h4>
                  )}
                </Column>
                <Column xs={1} fluid />
                <Column
                  xs={19}
                  md={4}
                  fluid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  {overweight.has_ow && (
                    <Button
                      className="hide-for-small-only"
                      type="link"
                      onClick={() => this.goToPayment("overweight")}
                      style={{ marginBottom: "1.5rem" }}
                    >
                      Liquidar saldo <i className="ion-arrow-right-c" />
                    </Button>
                  )}
                </Column>
              </Row>
              {overweight.has_ow && (
                <div>
                  <Row className="ow-details-container">
                    <Column
                      className={`ow-details mienvio-table-container ${
                        overweight.showing_details && "open"
                      }`}
                      fluid
                    >
                      <table id="ow-details">
                        <thead>
                          <tr>
                            <th>N° Orden</th>
                            <th className="hide-for-small-only">Carrier</th>
                            <th className="hide-for-small-only">N° Guía</th>
                            <th>Kg declarados</th>
                            <th>Kg enviados</th>
                            <th style={{ color: "#E00700" }}>
                              Adeudo generado
                            </th>
                            <th className="hide-for-small-only" />
                          </tr>
                        </thead>
                        <tbody>
                          {overweight.detailedOverweights &&
                            overweight.detailedOverweights.map((element) => (
                              <tr key={element.id}>
                                <td>{element.shipment_id}</td>
                                <td className="hide-for-small-only">
                                  {element.carrier}
                                </td>
                                <td className="hide-for-small-only">
                                  {element.tracking_number}
                                </td>
                                <td>{element.weight}</td>
                                <td>{element.weight_sent}</td>
                                <td>{element.amount}</td>
                                <td className="hide-for-small-only">
                                  <a
                                    target="_blank"
                                    href={`https://app.mienvio.mx/tracking/${element.tracking_number}/${element.carrier}`}
                                  >
                                    <i className="ion-android-open" />
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </Column>
                  </Row>
                  <div>
                    <Divider className="hr" />
                    <Button
                      type="link"
                      onClick={() => this.goToPayment("overweight")}
                    >
                      Liquidar saldo <i className="ion-arrow-right-c" />
                    </Button>
                  </div>
                </div>
              )}
            </Card>

            <Card size="full">
              <h5>Tracking general</h5>
              <Row divisions={23}>
                <Column xs={22} md={7} fluid>
                  <Dropdown
                    handleChange={(v) =>
                      this.handleFilterChange("generalSummary", v)
                    }
                    options={periodOptions}
                    selected={generalSummary.filter}
                    placeholder={
                      <Text className="dropdown-placeholder">
                        Selecciona un periodo
                      </Text>
                    }
                  />
                </Column>
                <Column xs={1} md={16} fluid />
              </Row>
              <Row divisions={29}>
                <Column xs={27} md={5} fluid className="summary-microbox">
                  <Text type="microHeader">Recolectadas</Text>
                  {generalSummary.collected > -1 ? (
                    <h1>{generalSummary.collected}</h1>
                  ) : (
                    <Skeleton height={30} />
                  )}
                </Column>
                <Column xs={1} fluid />
                <Column xs={27} md={5} fluid className="summary-microbox">
                  <Text type="microHeader">Escaneadas</Text>
                  {generalSummary.scanned > -1 ? (
                    <h1>{generalSummary.scanned}</h1>
                  ) : (
                    <Skeleton height={30} />
                  )}
                </Column>
                <Column xs={1} fluid />
                <Column xs={27} md={5} fluid className="summary-microbox">
                  <Text type="microHeader">En camino</Text>
                  {generalSummary.on_the_way > -1 ? (
                    <h1>{generalSummary.on_the_way}</h1>
                  ) : (
                    <Skeleton height={30} />
                  )}
                </Column>
                <Column xs={1} fluid />
                <Column xs={27} md={5} fluid className="summary-microbox">
                  <Text type="microHeader">Con incidencias</Text>
                  {generalSummary.incidents > -1 ? (
                    <h1>{generalSummary.incidents}</h1>
                  ) : (
                    <Skeleton height={30} />
                  )}
                </Column>
                <Column xs={1} fluid />
                <Column xs={27} md={5} fluid className="summary-microbox">
                  <Text type="microHeader">Entregadas</Text>
                  {generalSummary.delivered > -1 ? (
                    <h1>{generalSummary.delivered}</h1>
                  ) : (
                    <Skeleton height={30} />
                  )}
                </Column>
                <Column xs={1} className="show-for-small-only" fluid />
              </Row>
            </Card>
            <Card size="full">
              <Row divisions={23}>
                <Column xs={22} md={7} fluid>
                  <h5>Auditoría de guías</h5>
                </Column>
                <Column md={6} fluid />
                <Column xs={22} md={7} fluid>
                  {audit_download &&
                    label_graphic_data_download &&
                    audit_download.length > 0 &&
                    label_graphic_data_download.length > 0 && (
                      <CSVLink
                        className="btn-download-csv"
                        filename={`AuditoriaGuias${today}.csv`}
                        data={this.renderDataDownload(
                          label_graphic_data_download,
                          audit_download
                        )}
                      >
                        Descargar CSV
                      </CSVLink>
                    )}
                </Column>

                <Column xs={1} md={16} fluid />
              </Row>

              <Row divisions={23}>
                <Column xs={22} md={7} fluid>
                  <Dropdown
                    handleChange={(v) => this.handleFilterChange("audit", v)}
                    options={periodOptions}
                    selected={audit.filter}
                    placeholder={
                      <Text className="dropdown-placeholder">
                        Selecciona un periodo
                      </Text>
                    }
                  />
                </Column>

                <Column xs={1} md={16} fluid />
              </Row>
              <Row divisions={23}>
                <Column xs={21} md={5} fluid className="summary-microbox">
                  <Text type="microHeader">Guías generadas</Text>
                  {audit.generated > -1 ? (
                    <h1>{audit.generated}</h1>
                  ) : (
                    <Skeleton height={30} />
                  )}
                </Column>
                <Column xs={1} fluid />
                <Column xs={21} md={5} fluid className="summary-microbox">
                  <Text type="microHeader">Guías utilizadas</Text>
                  {audit.used > -1 ? (
                    <h1>{audit.used}</h1>
                  ) : (
                    <Skeleton height={30} />
                  )}
                </Column>
                <Column xs={1} fluid />
                <Column xs={21} md={5} fluid className="summary-microbox">
                  <Text type="microHeader">Precio promedio de envío</Text>
                  {audit.avg_price > -1 ? (
                    <h1>{audit.avg_price}</h1>
                  ) : (
                    <Skeleton height={30} />
                  )}
                </Column>
                <Column xs={1} fluid />
                <Column xs={21} md={5} fluid className="summary-microbox">
                  <Text type="microHeader">Peso promedio de envío</Text>
                  {audit.avg_weight > -1 ? (
                    <h1>{audit.avg_weight}</h1>
                  ) : (
                    <Skeleton height={30} />
                  )}
                </Column>
                <Column xs={1} fluid className="show-for-small-only" />
              </Row>
              <div
                className={`label-graphic-container ${
                  this.state.showLabelGraphic && "open"
                }`}
              >
                {this.state.showLabelGraphic && (
                  <Line data={this.state.label_graphic_data} />
                )}
              </div>
              <div className="btn-desglose">
                {this.state.showLabelGraphic ? (
                  <Button
                    type="link"
                    onClick={() =>
                      this.setState({
                        showLabelGraphic: !this.state.showLabelGraphic,
                      })
                    }
                  >
                    Ocultar Desglose <i className="ion-arrow-up-c" />
                  </Button>
                ) : (
                  <Button
                    type="link"
                    onClick={() =>
                      this.setState({
                        showLabelGraphic: !this.state.showLabelGraphic,
                      })
                    }
                  >
                    Mostrar Desglose <i className="ion-arrow-down-c" />
                  </Button>
                )}
              </div>
            </Card>
            {/*
              isEnterprise
                ? (
                  <Card size="full">
                    <h5>Estadísticas de Empaques</h5>
                    <Row divisions={23}>
                      <Column xs={22} md={7} fluid>
                        <Dropdown
                          handleChange={v => this.handleFilterChange('packages', v)}
                          options={periodOptions}
                          selected={packages.filter}
                          placeholder={
                            <Text className="dropdown-placeholder">Selecciona un periodo</Text>
                          }
                        />
                      </Column>
                      <Column xs={1} md={16} fluid />
                    </Row>
                    <Row divisions={12}>
                      <Column xs={12} md={12} fluid>
                        <Bar
                          data={storedPackages}
                          options={{
                            scales: {
                              yAxes: [{
                                ticks: {
                                  beginAtZero: true,
                                },
                              }],
                            },
                          }}
                        />
                      </Column>
                      <Column xs={12} md={12} fluid>
                        <HorizontalBar
                          data={unstoredPackages}
                          options={{
                            scales: {
                              yAxes: [{
                                ticks: {
                                  beginAtZero: true,
                                },
                              }],
                            },
                          }}
                        />
                      </Column>
                    </Row>
                  </Card>
                )
                : null
            */}
          </Column>
          <Column xs={1} className="show-for-small-only" fluid />
        </Row>
      </div>
    );
  }
}
