import React from 'react';
import Card from '../../../components/Card';
import ThreeDotsDropdown from '../../../components/Dropdown/ThreeDotsDropdown/index';

import edit_icon from '../../../utils/icons/edit.svg';
import delete_icon from '../../../utils/icons/delete.svg';
import downArrow from '../../../utils/icons/downArrow.svg';
import Account from './account';
import { searchPermission } from '../../../utils/global_functions';

import './style.css';


const Region = ({
  user, onClick, data, toggleEditRol, toggleDeleteRegion,
}) => {
  let options = [];

  const {
    customer_type, plan, actions, role, operational_user
  } = user;

  const permissions = [];
  if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
    operational_user.operational_role.actions.map(element => permissions.push(element));
  }

  if (plan && plan.features) {
    plan.features.map(element => permissions.push(element));
  }
  if (actions) {
    actions.map(element => permissions.push(element));
  }
  if (searchPermission('regiones', 'editar', permissions)) {
    options = [
      {
        function: () => toggleEditRol(data.id),
        label: 'Editar región',
        image: edit_icon,
      },
      {
        function: () => toggleDeleteRegion(data.id),
        label: 'Eliminar región',
        image: delete_icon,
      },
    ];
  }


  return (
    <Card size="full" className="region-card-container">
      <div className="top-region-container">
        <p>
          <strong>{data.name}</strong>
        </p>
        <div className="threedots-region">
          <ThreeDotsDropdown
            linksToRender={options}
          />
        </div>
      </div>

      <p>
        <strong>Paises</strong>
      </p>
      {data.countries && data.countries.map(element => (
        <p className="country-text-region">
        -
          {' '}
          {element.name}
        </p>
      ))}
      <p>
        <strong>Cuentas</strong>
      </p>
      {data.users && data.users.map(element => (
        <Account regionId={data.id} onClick={onClick} email={element.email} id={element.id} />
      ))}
      <div className="region-arrow-container">
        <img src={downArrow} alt="" />
      </div>

    </Card>
  );
};
export default Region;
