import React from "react";
import styled from "styled-components";
import styledProps from "styled-props";
import { Row, Column } from "hedron";
import DatePicker from "react-datepicker";

import Text from "../Text";
import { RadioGroup, Radio } from "../Radio";
import { CheckboxGroup, Checkbox } from "../Checkbox";
import Input, { Label, Required, HelpText } from "../Input";
import colors from "../../utils/colors";
import { randomString } from "../../utils/global_functions";

import "./style.css";

import "react-datepicker/dist/react-datepicker.css";

const Arrow = styled.i`
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.gray.dropdown_arrow};
`;

const Icon = styled.i`
  font-size: 15px;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.gray.input_icon};
`;

const borderRadius = {
  default: "2px",
  open: "2px 2px 0px 0px",
};

const StyledDropdown = styled.div`
  width: 100%;
  border: 1.5px solid ${colors.newcolors.light_gray};
  border-radius: ${styledProps(borderRadius, "type")};
  padding: 0px 8px;
  box-sizing: border-box;
  position: relative;
  margin-top: 8px;
  margin-bottom: 1rem;
  height: 36px;
  z-index: ${(props) => (props.down ? 9 : props.down2 ? 8 : 10)};
  background-color: white;

  &[type="open"] {
    background-color: ${colors.secondary.ultralight_blue};
    border: 1.5px solid ${colors.secondary.royal_blue};
  }

  &[type="error"] {
    border: 1.5px solid ${colors.state.tomato};
  }

  > [type] {
    > section {
      > div {
        padding: 8px 0 !important;
        color: #666666 !important;
      }
    }
  }

  .dropdown-display-text {
    line-height: 32px;
    color: ${colors.gray.charcoal};
    min-height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      margin-bottom: 0px !important;
    }
  }

  .dropdown-option {
    background-color: white;
    transition: 200ms;

    &:hover {
      background-color: #f8f8fc;
    }
  }
`;

export const StyledList = styled.div`
  position: absolute;
  background: ${(props) => (props.primary ? "palevioletred" : "white")};
  box-sizing: border-box;
  padding-bottom: 8px;
  width: calc(100% + 2px);
  left: -1px;
  border: none;
  border-radius: 2px 2px 6px 6px;
  z-index: 10;
  overflow: auto;
`;

class Dropdown extends React.Component {
  state = {
    open: false,
    selected: this.props.selected ? this.props.selected : -1,
    filterValue: "",
    openAfterChange: this.props.multiple ? true : false,
    identifier: "",
    down: "down",
  };

  componentWillMount() {
    this.setState({ identifier: randomString() });
  }

  simulateHandleChange = (value, multiple) => {
    let newVal;
    if (multiple) {
      if (this.props.selected.indexOf(value) < 0) {
        // not in array
        newVal = this.props.selected;
        newVal.push(value);
      } else {
        // in array
        newVal = this.props.selected.filter((e) => e != value);
      }
    } else {
      // not multiple
      newVal = value;
    }

    this.handleChange(newVal);
  };

  handleChange = (value) => {
    this.props.handleChange(value);
    setTimeout(() => {
      this.setState({ open: this.state.openAfterChange });
    }, 180);
  };

  renderOptions = () => {
    return this.props.options.map((option, index) => {
      return (
        <div key={index}>
          {!this.props.filter ? (
            <Row
              className={`${
                this.props.selected === index ? "selected-option" : ""
              } dropdown-option with-check`}
            >
              <Column xs={1} fluid>
                {this.props.multiple ? ( // multiple
                  <CheckboxGroup
                    style={{ position: "relative", height: "100%" }}
                    type="table"
                    className="no-margin-checkbox"
                    defaultChecked={
                      this.props.selected
                        ? this.props.selected
                        : this.props.placeholder
                        ? null
                        : 0
                    }
                    onChange={this.handleChange}
                  >
                    <Checkbox
                      value={index}
                      containerClass="rate-radio-container"
                      className="check-in-dd"
                    />
                  </CheckboxGroup>
                ) : (
                  // not multiple
                  <RadioGroup
                    style={{ display: "none" }}
                    selected={
                      this.props.selected
                        ? this.props.selected
                        : this.props.placeholder
                        ? null
                        : 0
                    }
                    onChange={this.handleChange}
                  >
                    <Radio
                      value={index}
                      containerClass="rate-radio-container"
                      className="check-in-dd"
                    />
                  </RadioGroup>
                )}
              </Column>
              <Column
                className="dd-label"
                xs={this.props.multiple ? 11 : 12}
                fluid
                onClick={() =>
                  this.simulateHandleChange(index, this.props.multiple)
                }
              >
                <Text>{option}</Text>
              </Column>
            </Row>
          ) : null}

          {
            // Unique selection & filter
            this.props.filter && (
              <Row
                key={index}
                className={`${
                  this.props.selected === index ? "selected-option" : ""
                } dropdown-option`}
              >
                <Column
                  className={`${
                    this.props.filter && "dropdown-filter-option"
                  } `}
                  xs={12}
                  fluid
                  onClick={() => this.simulateHandleChange(index, false)}
                >
                  {option}
                </Column>
              </Row>
            )
          }
        </div>
      );
    });
  };

  toggleOpen() {
    if (this.props.disabled) return 0;

    this.setState({ open: !this.state.open });
  }

  render() {
    const displayText = this.props.selectedTitle
      ? this.props.selectedTitle
      : this.props.selected !== null &&
        this.props.selected !== "" &&
        !Array.isArray(this.props.selected)
      ? this.props.options[this.props.selected]
      : this.props.placeholder
      ? this.props.placeholder
      : this.props.options[0];
    return (
      <div
        id={`dropdown-${this.state.identifier}`}
        className={`${this.props.className} dropdown-container`}
        style={this.props.style}
      >
        <Label>{this.props.label}</Label>
        {this.props.required ? (
          <Required
            status={
              this.props.error
                ? "error"
                : this.state.focus
                ? "focus"
                : "default"
            }
          />
        ) : null}
        {this.props.down2 ? (
          <StyledDropdown
            down2
            type={
              this.state.open ? "open" : this.props.error ? "error" : "default"
            }
          >
            <Row onClick={() => this.toggleOpen()}>
              <Column
                xs={11}
                fluid
                style={{ paddingLeft: this.props.icon ? "14px" : "0px" }}
              >
                {this.props.icon && <Icon className={this.props.icon} />}
                <div
                  className={`dropdown-display-text`}
                  style={this.props.disabled ? { color: "gray" } : {}}
                >
                  {displayText}
                </div>
              </Column>
              <Column xs={1} fluid style={{ position: "relative" }}>
                <Arrow
                  className={
                    this.state.open
                      ? "ion-android-arrow-dropup"
                      : "ion-android-arrow-dropdown"
                  }
                  onClick={() => this.toggleOpen()}
                />
              </Column>
            </Row>
            <StyledList
              className={`dropdown-content  ${this.props.listClassName} ${
                this.state.open ? "dropdown-open" : ""
              }`}
            >
              {this.props.filter ? (
                <Input
                  className="dropdown-filter"
                  type="text"
                  icon="ion-android-search"
                  value={this.props.filterValue}
                  parentClassName="dropdown-filter-container"
                  onChange={this.props.handleFilterChange}
                  placeholder={this.props.filterPlaceholder}
                />
              ) : null}
              {this.renderOptions()}
            </StyledList>
          </StyledDropdown>
        ) : this.props.down ? (
          <StyledDropdown
            down
            type={
              this.state.open ? "open" : this.props.error ? "error" : "default"
            }
          >
            <Row onClick={() => this.toggleOpen()}>
              <Column
                xs={11}
                fluid
                style={{ paddingLeft: this.props.icon ? "14px" : "0px" }}
              >
                {this.props.icon && <Icon className={this.props.icon} />}
                <div
                  className={`dropdown-display-text`}
                  style={this.props.disabled ? { color: "gray" } : {}}
                >
                  {displayText}
                </div>
              </Column>
              <Column xs={1} fluid style={{ position: "relative" }}>
                <Arrow
                  className={
                    this.state.open
                      ? "ion-android-arrow-dropup"
                      : "ion-android-arrow-dropdown"
                  }
                  onClick={() => this.toggleOpen()}
                />
              </Column>
            </Row>
            <StyledList
              className={`dropdown-content  ${this.props.listClassName} ${
                this.state.open ? "dropdown-open" : ""
              }`}
            >
              {this.props.filter ? (
                <Input
                  className="dropdown-filter"
                  type="text"
                  icon="ion-android-search"
                  value={this.props.filterValue}
                  parentClassName="dropdown-filter-container"
                  onChange={this.props.handleFilterChange}
                  placeholder={this.props.filterPlaceholder}
                />
              ) : null}
              {this.renderOptions()}
            </StyledList>
          </StyledDropdown>
        ) : (
          <StyledDropdown
            type={
              this.state.open ? "open" : this.props.error ? "error" : "default"
            }
          >
            <Row onClick={() => this.toggleOpen()}>
              <Column
                xs={11}
                fluid
                style={{ paddingLeft: this.props.icon ? "14px" : "0px" }}
              >
                {this.props.icon && <Icon className={this.props.icon} />}
                <div className={`dropdown-display-text`}>{displayText}</div>
              </Column>
              <Column xs={1} fluid style={{ position: "relative" }}>
                <Arrow
                  className={`${
                    this.state.open
                      ? "ion-android-arrow-dropup"
                      : "ion-android-arrow-dropdown"
                  } ${this.props.disable && "no-click"}`}
                  onClick={() => this.toggleOpen()}
                />
              </Column>
            </Row>
            <StyledList
              className={`dropdown-content  ${this.props.listClassName} ${
                this.state.open ? "dropdown-open" : ""
              }`}
            >
              {this.props.filter ? (
                <Input
                  className="dropdown-filter"
                  type="text"
                  icon="ion-android-search"
                  value={this.props.filterValue}
                  parentClassName="dropdown-filter-container"
                  onChange={this.props.handleFilterChange}
                  placeholder={this.props.filterPlaceholder}
                />
              ) : null}
              {this.renderOptions()}
            </StyledList>
          </StyledDropdown>
        )}
        {this.props.error ? (
          <HelpText>{this.props.error}</HelpText>
        ) : this.props.help ? (
          <HelpText>{this.props.help}</HelpText>
        ) : null}
      </div>
    );
  }
}

export default Dropdown;
