import React from "react";
import styledProps from "styled-props";
import PropTypes from "prop-types";
import styled from "styled-components";
import colors from "../../utils/colors";
import Text from "../Text";
import "./index.css";
import sizeMe, { SizeMe } from "react-sizeme";

// Icons for steps
import icon_alert from "./icon-alert.svg";
import icon_completed from "./icon-completed.svg";
import icon_unfinished from "./icon-unfinished.svg";

let icons = {
  alert: icon_alert,
  completed: icon_completed,
  unfinished: icon_unfinished,
};

const width = {
  default: "78%",
  full: "100%",
};
const StyledTabs = styled.ul`
  padding: 0px;
  border-bottom: 1px solid ${colors.state.silver};
  box-sizing: border-box;
  width: ${styledProps(width, "size")};
  overflow: hidden;
  position: relative;
`;
StyledTabs.defaultProps = {
  size: "default",
};

const StyledTab = styled.li`
  display: inline-block;
  cursor: pointer;
  padding: 12px 28px;
`;

const StyledStep = styled.li`
  cursor: pointer;
  padding: 12px 8px;
  box-sizing: border-box;
  width: auto;
  text-align: center;
`;

StyledTab.defaultProps = {
  status: "default",
};

const StyledRightTab = StyledTab.extend`
  display: inline-block;
  float: right;
  padding: 12px 20px;
`;

const Slider = styled.div`
  display: inline-block;
  height: 4px;
  bottom: 0;
  position: absolute;
  z-index: 4;
  transition: all 0.25s linear;
  background-color: ${colors.secondary.cobalt};
  position: absolute !important;
`;

const Tab = (props, context) =>
  props.right ? (
    <SizeMe
      render={({ size: { width } }) => (
        <StyledRightTab
          onClick={() => context.handleChange(props.value, width, "right")}
          status={props.value === props.selected ? "selected" : "default"}
          className={props.className}
          style={
            props.hide == "yes"
              ? { visibility: "hidden", display: "none" }
              : { visibility: "visible" }
          }
        >
          <Text
            className={
              props.value === props.selected ? "tab-text-selected" : "tab-text"
            }
          >
            {props.children}
          </Text>
        </StyledRightTab>
      )}
    />
  ) : (
    <SizeMe
      render={({ size: { width } }) => (
        <StyledTab
          onClick={() => context.handleChange(props.value, width, "left")}
          status={props.value === props.selected ? "selected" : "default"}
          className={props.className}
          style={
            props.hide == "yes"
              ? { visibility: "hidden", display: "none" }
              : { visibility: "visible" }
          }
        >
          <Text
            className={
              props.value === props.selected ? "tab-text-selected" : "tab-text"
            }
          >
            {props.children}
          </Text>
        </StyledTab>
      )}
    />
  );

const Step = (props, context) => (
  <SizeMe
    render={({ size: { width } }) => (
      <StyledStep
        onClick={() => context.handleStepChange(props.value, width, "left")}
        status={props.value === props.selected ? "selected" : "default"}
        className={`${props.className ? props.className : ""} nl_step ${
          props.shakeClass && props.shakeClass
        }`}
      >
        <Text
          className={`${
            props.value === props.selected ? "tab-text-selected" : "tab-text"
          }`}
        >
          <img src={icons[props.icon_status]} /> {props.children}
        </Text>
      </StyledStep>
    )}
  />
);

Tab.contextTypes = {
  handleChange: PropTypes.func,
};

Step.contextTypes = {
  handleStepChange: PropTypes.func,
};

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected ? this.props.selected : 0,
      slider: {
        left: 0,
        width: 120,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.setPositionSlider = this.setPositionSlider.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
  }

  setPositionSlider() {
    try {
      this.setState({
        slider: {
          left:
            document.querySelector(`#${this.props.id} .tab-text-selected`)
              .parentElement.offsetLeft + 8,
          width:
            document.querySelector(`#${this.props.id} .tab-text-selected`)
              .parentElement.clientWidth - 16,
        },
      });
    } catch {
      // console.log(`Slider error: ${e}`);
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setPositionSlider();
    }, 10);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.invoiceList || this.props.shipmentCSV)
      this.setState({
        selected: nextProps.selected,
      });
    setTimeout(() => {
      this.setPositionSlider();
    }, 10);
  }

  handleStepChange(selected, width, align) {
    this.props.onChange(selected);
    if (!this.props.shipmentCSV) this.setState({ selected });

    setTimeout(() => {
      this.setPositionSlider();
    }, 20);
  }

  handleChange(selected, width, align) {
    if (selected !== this.state.selected) {
      this.props.onChange(selected);
    }
    this.setState({ selected });

    setTimeout(() => {
      this.setPositionSlider();
    }, 10);
  }

  getChildContext() {
    return {
      handleChange: this.handleChange,
      handleStepChange: this.handleStepChange,
    };
  }

  render() {
    return (
      <StyledTabs
        id={this.props.id}
        size={this.props.right ? "full" : "default"}
        className={this.props.className}
      >
        <Slider
          className={this.props.sliderclassName && this.props.sliderclassName}
          style={{
            left: `${this.state.slider.left}px`,
            width: `${this.state.slider.width}px`,
          }}
        />
        {React.Children.map(this.props.children, (child, index) => {
          return React.cloneElement(child, {
            value: index,
            selected: this.props.selected || this.state.selected,
          });
        })}
      </StyledTabs>
    );
  }
}

Tabs.childContextTypes = {
  handleChange: PropTypes.func,
  handleStepChange: PropTypes.func,
};

export default sizeMe()(Tabs);
export { Tab, Step };
