import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { boundMethod } from "autobind-decorator";
import { Row, Column } from "hedron";
import Skeleton from "react-loading-skeleton";
import close_icon from "../../WrapperSideBar/img/close.svg";
import packageIcon from "./icons/packages.svg";
import NewTable from "../../NewTable";
import {
  getPackages,
  createPackages,
  editPackages,
  deletePackages,
} from "../../../modules/marketplaceAccount/packages";
import origin_icon from "../../../utils/icons/origin_gray.svg";
import destination_icon from "../../../utils/icons/destination_gray.svg";
import edit_icon from "../../../utils/icons/edit.svg";
import delete_icon from "../../../utils/icons/delete.svg";
import detail_eye from "../../../utils/icons/detail-eye.svg";
import addresses_icon from "../../static/Icon/addressesActive.svg";
import addresses_icon_gray from "../../static/Icon/addresses.svg";
import box_icon from "../../../utils/icons/box.svg";
import packet_icon from "../../../utils/icons/envelope.svg";
import { jsUcfirst } from "../../../utils/global_functions";

import {
  PACKAGE_DETAILS,
  NEW_PACKAGE,
  EDIT_PACKAGE,
} from "../../../modules/CollapsibleBar/sideBarTypes";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../../modules/CollapsibleBar/actions";
import SidebarTitleMenu from "../../WrapperSideBar/extraComponents/SidebarTitleMenu";
import ThreeDotsDropdown from "../../Dropdown/ThreeDotsDropdown/index";
import WrapperSideBar from "../../WrapperSideBar";
import {
  ContentAdapter,
  BottomContainer,
} from "../../WrapperSideBar/styledObjects";
import Button from "../../Button";
import Dropdown from "../../Dropdown";
import Input, { Label, Required, HelpText } from "../../Input";
import colors from "../../../utils/colors";
import lowerCase from "lower-case";
import SelectableBox from "../../SelectableBox";
import Text, { Title, P } from "../../Text";
import SweetAlert from "sweetalert2-react";

const columns = [
  {
    name: "Alias",
    selector: "alias",
  },
  {
    name: "Tipo",
    selector: "object_type",
  },
  {
    name: "Medidas",
    selector: "height",
    cell: (row) => `${row.length} x ${row.width} x ${row.height} cm`,
  },
  {
    name: "Estatus",
    selector: "object_status",
  },
];

class Packages extends React.Component {
  state = {
    showAlertSuccess: false,
    showAlert: false,
    alias: "",
    object_id: "",
    alias: "",
    aliasError: "",
    type: "",
    typeError: "",
    length: "",
    width: "",
    height: "",
    lengthError: "",
    widthError: "",
    heightError: "",
    dimensionsError: "",
    description: "",
    descriptionError: "",
    weight: "",
    weightError: "",
    data: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      showAlertSuccess: false,
      showAlert: false,
      alias: "",
      aliasError: "",
      objectTypeCode: null,
      objectTypeCodeError: "",
      objectStatusCode: null,
      objectStatusCodeError: "",
      height: "",
      width: "",
      length: "",
      lengthError: "",
      widthError: "",
      heightError: "",
      quantity: "",
      quantityError: "",
    };
  }

  onCloseSidebar = () => {
    this.props.toggleSideBar(false);

    this.setState({
      object_id: "",
      alias: "",
      aliasError: "",
      type: "",
      typeError: "",
      length: "",
      width: "",
      height: "",
      lengthError: "",
      widthError: "",
      heightError: "",
      dimensionsError: "",
      description: "",
      descriptionError: "",
      weight: "",
      weightError: "",
      showAlertSuccess: false,
      showAlert: false,
    });
  };

  toggleEditPackage = () => {
    this.props.toggleSideBar(true, "Editar Paquete", NEW_PACKAGE);

    this.setState({ hideIcon: true });
  };

  toggleAddPackage() {
    this.props.toggleSideBar(true, "Agregar empaque", NEW_PACKAGE);
  }

  @boundMethod
  viewPackageDetails(pack) {
    // console.log(pack);
    this.setState({
      object_id: pack.object_id,
      alias: pack.alias,
      type: pack.object_type_code,
      height: pack.height,
      width: pack.width,
      length: pack.length,
      description: pack.description,
      weight: pack.weight,
    });

    this.props.toggleSideBar(
      true,
      `Empaque "${
        pack.alias.length > 15 ? pack.alias.slice(0, 15) + "..." : pack.alias
      }"`,
      PACKAGE_DETAILS
    );
  }

  loadPackages() {
    if (this.props.id) {
      this.setState({ loading: true });
      getPackages(this.props.id).then((response) => {
        this.setState({ loading: false });
        if (response.type === "Success") {
          this.setState({ data: response.response.data });
        } else {
          this.props.notify("Error al obtener la información", "error");
        }
      });
    } else {
      this.props.closeElement();
    }
  }

  componentWillMount() {
    this.loadPackages();
  }

  @boundMethod
  deletePackage() {
    deletePackages(this.state.object_id).then((response) => {
      this.loadPackages();
      this.onCloseSidebar();
    });
  }

  savePackage(type) {
    const errors = {
      required: "Este campo es requerido",
      options: "Selecciona una opción",
    };

    let widthError = "";
    let dimensionsError = "";
    const aliasError = !this.state.alias ? errors.required : "";
    const typeError =
      ["box", "envelope"].indexOf(this.state.type) < 0 ? errors.options : "";

    if (!this.state.height || !this.state.length || !this.state.width) {
      widthError = "Todas las dimensiones son requeridas";
    } else if (
      isNaN(this.state.height) ||
      isNaN(this.state.length) ||
      isNaN(this.state.width)
    ) {
      widthError = "Todas las dimensiones deben tener valores númericos";
    } else if (
      this.state.height > 170 ||
      this.state.length > 170 ||
      this.state.width > 170
    ) {
      widthError = "Todas las dimensiones deben ser menores a 170cm";
    } else if (
      Number(this.state.height) +
        Number(this.state.length) +
        Number(this.state.width) >=
      360
    ) {
      widthError = "La suma de las dimensiones debe de ser menor a 360cm";
    }

    const weightError = isNaN(this.state.weight)
      ? "Todas las dimensiones deben tener valores númericos"
      : "";

    const insuredAmountError =
      typeof this.state.insuredAmount === "undefined"
        ? ""
        : isNaN(this.state.insuredAmount)
        ? "El monto debe ser un valor numérico"
        : "";

    const isValid = !(
      aliasError ||
      typeError ||
      widthError ||
      weightError ||
      insuredAmountError
    );

    this.setState({
      aliasError: aliasError,
      typeError: typeError,
      dimensionsError: dimensionsError,
      weightError: weightError,
      widthError: widthError,
      insuredAmountError: insuredAmountError,
    });

    // console.log(isValid);
    // console.log(this.state.alias);
    // console.log(this.state.type);
    // console.log(this.state.height);
    // console.log(this.state.length);
    // console.log(this.state.width);
    // console.log(this.state.description);
    // console.log(this.state.weight);

    if (type === "create" && isValid === true) {
      createPackages(this.state, this.props.id).then((response) => {
        this.loadPackages();
        this.onCloseSidebar();
      });
    }

    if (type === "edit" && isValid === true) {
      editPackages(this.state, this.state.object_id, this.props.id).then(
        (response) => {
          this.loadPackages();
          this.onCloseSidebar();
        }
      );
    }
  }

  renderType() {
    return ["Caja", "Sobre"].map((t) => (
      <Row key={t} className="card-dropdown-option">
        <Column xs={6} fluid>
          <Text>{t}</Text>
        </Column>
      </Row>
    ));
  }

  handleChange = (value, key) => {
    this.setState({ [key]: value });
  };

  _renderSidebarTitleExtraContent = () => {
    const packOptions = [];

    packOptions.push({
      function: this.toggleEditPackage,
      label: "Editar Empaque",
      image: edit_icon,
    });

    packOptions.push({
      function: this.deletePackage,
      label: "Eliminar Empaque",
      image: delete_icon,
    });

    switch (this.props.sidebar.type) {
      case PACKAGE_DETAILS:
        return <SidebarTitleMenu linksToRender={packOptions} />;
      default:
        return null;
    }
  };

  _renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
      case NEW_PACKAGE:
        const mainProps = {
          generate: (name) => ({
            onChange: (value) => {
              this.handleChange(value, name);
            },
            value: this.state[name],
            error: this.state[`${name}Error`],
          }),
        };

        return (
          <ContentAdapter className="create-package-form">
            <Row>
              <Column fluid>
                <Input
                  type="text"
                  {...mainProps.generate("alias")}
                  required
                  placeholder="Sobre amarillo"
                  label="Alias"
                />
              </Column>
              <Column fluid>
                <Text type="microHeader">Información del empaque</Text>
              </Column>
              <Column fluid>
                <Label>Tipo</Label>
                <Required status={this.state.typeError ? "error" : "default"} />
              </Column>
              <Column className="boxes-options" fluid>
                <SelectableBox
                  type="radio"
                  name="type"
                  className="type-select"
                  value="caja"
                  isChecked={this.state.type == "box"}
                  onChange={() => this.handleChange("box", "type")}
                >
                  <img src={box_icon} />
                  Caja
                </SelectableBox>
                <SelectableBox
                  type="radio"
                  name="type"
                  className="type-select"
                  value="sobre"
                  isChecked={this.state.type == "envelope"}
                  onChange={() => this.handleChange("envelope", "type")}
                >
                  <img src={packet_icon} />
                  Sobre
                </SelectableBox>
              </Column>
              <Column fluid>
                <HelpText
                  className={
                    "animated-help-text " + (this.state.typeError ? "open" : "")
                  }
                >
                  {this.state.typeError}
                </HelpText>
              </Column>
              <Column xs={3} fluid>
                <Input
                  type="text"
                  placeholder="30"
                  label="Dimensiones"
                  suffix="x"
                  {...mainProps.generate("length")}
                />
              </Column>
              <Column xs={3} fluid>
                <Input
                  type="text"
                  placeholder="30"
                  label=""
                  suffix="x"
                  {...mainProps.generate("width")}
                />
              </Column>
              <Column xs={3} fluid>
                <Input
                  type="text"
                  placeholder="30"
                  label=""
                  {...mainProps.generate("height")}
                  suffix="cm"
                />
              </Column>

              <Column fluid>
                <Text type="microHeader">Predeterminar peso y contenido</Text>
                <Text type="microDescription">
                  Esta información es opcional, te recomendamos llenarla sólo en
                  el caso de que estos datos se repitan constantemente en tus
                  envíos.
                </Text>
              </Column>
              <Column xs={6} fluid style={{ paddingRight: "8px" }}>
                <Input
                  type="text"
                  placeholder="Playeras serigrafiadas"
                  label="Descripción del contenido"
                  {...mainProps.generate("description")}
                />
              </Column>
              <Column xs={3} fluid>
                <Input
                  suffix="kg"
                  type="text"
                  placeholder="100.0"
                  label="Peso"
                  {...mainProps.generate("weight")}
                />
              </Column>
            </Row>
            <BottomContainer>
              <Row divisions={20}>
                <Column style={{ textAlign: "right" }} fluid>
                  <Button
                    className=""
                    onClick={() =>
                      this.savePackage(this.state.object_id ? "edit" : "create")
                    }
                    loading={this.state.isLoading}
                  >
                    Guardar
                  </Button>
                </Column>
                <Column
                  xs={1}
                  xsShift={1}
                  style={{ textAlign: "right" }}
                  fluid
                />
              </Row>
            </BottomContainer>
          </ContentAdapter>
        );

      case PACKAGE_DETAILS:
        let pack = this.state;

        return (
          <ContentAdapter className="detailed-package">
            <div className="section-separator adjust-top">
              <img src={detail_eye} /> Detalle de empaque
            </div>
            <Row>
              <Column fluid>
                <Text type="sidebarInfoTitle">Alias</Text>
                <P>{pack.alias}</P>
              </Column>
              <Column fluid>
                <Text type="sidebarInfoTitle">Tipo</Text>
                <P>{pack.type === "box" ? "Caja" : "Sobre"}</P>
              </Column>
              <Column fluid>
                <Text type="sidebarInfoTitle">Dimensiones</Text>
                <P>{`${pack.length} x ${pack.width} x ${pack.height} cm`}</P>
              </Column>
              {pack.description && (
                <div>
                  <Column fluid>
                    <Text type="microHeader">Peso y contenido</Text>
                  </Column>
                  <Column fluid>
                    <Text type="sidebarInfoTitle">Contenido del Paquete</Text>
                    <P>{pack.description}</P>
                  </Column>
                </div>
              )}
              {pack.weight && (
                <Column fluid>
                  <Text type="sidebarInfoTitle">Peso</Text>
                  <P>{pack.weight}</P>
                </Column>
              )}
            </Row>
          </ContentAdapter>
        );

      default:
        return <div />;
    }
  };

  render() {
    const options = [
      {
        function: () => this.toggleAddPackage(),
        label: "Añadir Empaque",
        image: edit_icon,
      },
    ];
    return (
      <div>
        <WrapperSideBar
          handleClose={this.onCloseSidebar}
          title_more={this._renderSidebarTitleExtraContent()}
          icon={!this.state.hideIcon && addresses_icon}
        >
          {this._renderSidebarContent()}
        </WrapperSideBar>

        <div className="header-data-visualization">
          <img src={packageIcon} alt="" className="selected-icon" />
          <p className="title-header-data-visualization">Empaques</p>
          <ThreeDotsDropdown linksToRender={options} />
          <img
            onClick={() => this.props.closeElement()}
            src={close_icon}
            alt=""
            className="close-icon"
          />
        </div>
        {this.state.loading ? (
          <div className="container-loading">
            <Skeleton height={30} count={6} />
          </div>
        ) : (
          <NewTable
            // hideForSmall={true}
            selectableRows
            shipping={false}
            columns={columns}
            data={this.state.data}
            onRowClicked={this.viewPackageDetails}
            noDataComponent={<div />}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  packages: state.packages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSideBar,
      changeSidebarTitle,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
