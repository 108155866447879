import React from 'react';
import styled from 'styled-components';
import './index.css';

const StyledHeader = styled.div`
  margin-bottom: 32px;
  margin-top: 40px;
  height: 40px;

  @media screen and (max-width: 39.9375em) {
    margin-top: 1rem;
  }

  * {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const Header = props => (
  <StyledHeader style={props.style} className={props.className}>
    {props.children}
  </StyledHeader>
);

export default Header;
