import React from "react";
import { Column, Row } from "hedron";
import Text, { P } from "../../../components/Text";
import FromIcon from "../../../components/ShipmentsList/details/icons/origen_icon.svg";
import ToIcon from "../../../components/ShipmentsList/details/icons/destino_icon.svg";
import PackageIcon from "../../../components/ShipmentsList/details/icons/paquete_icon.svg";
import servicio_icon from "../../../components/ShipmentsList/details/icons/servicio_icon.svg";
import {
  getVolumetricWeight,
  numberWithCommas,
} from "../../../utils/global_functions";
import "./index.css";

const DetailedOrder = ({ order }) => (
  <div className="detailed-order-container">
    <div className="section-separator adjust-top">
      <img src={FromIcon} alt="" />
      Origen
    </div>
    <P>
      <strong>Alias:</strong> {order.details.address_from.alias}
    </P>
    <P>{order.details.address_from.name}</P>
    <P>{order.details.address_from.email}</P>
    <P>{order.details.address_from.phone}</P>
    <P>{order.details.address_from.street}</P>
    <P>{order.details.address_from.street2}</P>
    <P>{order.details.address_from.reference}</P>
    <P>
      {`${
        order.details.address_to.level_1 && order.details.address_from.level_1
      }, ${order.details.address_from.city}, ${
        order.details.address_from.state
      }, ${order.details.address_from.country}`}
    </P>
    <div className="section-separator">
      <img src={ToIcon} alt="" />
      Destino
    </div>
    <P>{order.details.address_to.name}</P>
    <P>{order.details.address_to.email}</P>
    <P>{order.details.address_to.phone}</P>
    <P>{order.details.address_to.street}</P>
    <P>{order.details.address_to.street2}</P>
    <P>{order.details.address_to.reference}</P>
    <P>
      {`${
        order.details.address_to.level_1 && order.details.address_to.level_1
      }, ${order.details.address_to.city}, ${order.details.address_to.state}, ${
        order.details.address_to.country
      }`}
    </P>
    <div className="section-separator">
      <img src={PackageIcon} alt="" />
      Paquete
    </div>
    {order.details.overweight_charge && (
      <Row>
        <Column className="helper-vol-text" xs={12} fluid>
          <p className="left">
            Paquete con sobrepeso registrado:
            {order.details.weight_sent - order.details.weight}
            kg - ${order.details.overweight_charge}{" "}
          </p>
          <a
            href={order.details.label.tracking_url}
            target="blank"
            className="right"
            type="link"
          >
            <i className="ion-android-open" />
          </a>
        </Column>
      </Row>
    )}
    <Row>
      <Column xs={12} fluid>
        <div>
          <Text type="microHeader">Dimensiones</Text>
          <P>
            {`${order.details.height} x ${order.details.width} x ${order.details.length}`}
          </P>
        </div>
      </Column>
      <Column xs={4} fluid>
        <div>
          <Text type="microHeader">Peso</Text>
          <Text type="inputFilled" className="weight-number">
            {order.details.weight}
            kg
          </Text>
        </div>
      </Column>
      <Column xs={4} fluid>
        <div>
          <Text type="microHeader">Peso Volumétrico</Text>
          <Text type="inputFilled" className="weight-number">
            {getVolumetricWeight(
              order.details.height,
              order.details.width,
              order.details.length
            )}
            kg
          </Text>
        </div>
      </Column>
      <Column xs={4} fluid>
        {order.details.weight_sent && (
          <div>
            <Text type="microHeader">Peso Enviado</Text>
            <Text type="inputFilled">
              {order.details.weight_sent}
              kg
            </Text>
          </div>
        )}
      </Column>
      {!order.details.insurance ? (
        <div>
          <Text type="microHeader">Información del seguro</Text>
          <P>Sin asegurar</P>
        </div>
      ) : (
        <div>
          <Text type="microHeader">Información del seguro</Text>
          <P>
            <strong>Costo del seguro:</strong> $
            {numberWithCommas(
              parseFloat(order.details.insurance.cost).toFixed(2)
            )}{" "}
            {order.details.currency}
          </P>
        </div>
      )}
      <Column xs={12} fluid>
        <div>
          <Text type="microHeader">Contenido del paquete</Text>
          <P>{order.details.description}</P>
        </div>
      </Column>
      <Column xs={12} fluid>
        <div>
          <Text type="microHeader">Valor del paquete</Text>
          <P>
            {`$${numberWithCommas(
              parseFloat(order.details.declared_value).toFixed(2)
            )} ${order.details.currency}`}
          </P>
        </div>
      </Column>
    </Row>
    <div className="section-separator">
      <img src={PackageIcon} alt="" />
      Items
    </div>
    {order.details.items &&
      JSON.parse(order.details.items).map((element, index) => (
        <Row className="item-separator">
          <Column xs={12} fluid>
            <Text type="microHeaderBig">
              {index + 1}
              .- Item #{element.id}
            </Text>
          </Column>
          <Column xs={6} fluid>
            <div>
              <Text type="microHeader">Dimensiones</Text>
              <P>
                {`${element.height} x ${element.width} x ${element.length} cm`}
              </P>
            </div>
          </Column>
          <Column xs={6} fluid>
            <div>
              <Text type="microHeader">Peso</Text>
              <Text type="inputFilled" className="weight-number">
                {element.weight}
                kg
              </Text>
            </div>
          </Column>
        </Row>
      ))}
    <div className="section-separator adjust-top">
      <img src={servicio_icon} alt="" />
      Servicio
    </div>
    <Row>
      <Column xs={1} fluid>
        <img
          src={
            order.details.rate
              ? `https://production.mienvio.mx/${order.details.rate.provider_img}`
              : ""
          }
          width="32"
          alt=""
        />
      </Column>
      <Column xs={1} fluid />
      <Column xs={10} fluid>
        {order.details.rate ? (
          <div>
            <P>{order.details.rate.provider}</P>
            <P>{order.details.rate.duration_terms}</P>
            <P>
              <b>
                {`$${numberWithCommas(
                  parseFloat(order.details.rate.amount).toFixed(2)
                )} ${order.details.rate.currency}`}
              </b>
            </P>
          </div>
        ) : null}
      </Column>
    </Row>
  </div>
);
export default DetailedOrder;
