import React from "react";
import { Row, Column } from "hedron";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { boundMethod } from "autobind-decorator";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import AccountSetupConfig from "../../components/AccountSetupConfig";
import AccountsManagment from "../../components/AccountsManagment";
import Input, { Label, Required, HelpText } from "../../components/Input";
import Text, { Title, P } from "../../components/Text";
import WrapperSideBar from "../../components/WrapperSideBar";
import {
  ContentAdapter,
  BottomContainer,
} from "../../components/WrapperSideBar/styledObjects";
import Button from "../../components/Button";
import EmpyState from "./emptyState";
import Region from "./region";
import "react-toastify/dist/ReactToastify.css";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../modules/CollapsibleBar/actions";
import Dropdown from "../../components/Dropdown";
import {
  createRegion,
  getRegions,
  getSingleRegion,
  updateRegion,
  deleteRegion,
} from "../../modules/regions";
import { Checkbox, CheckboxGroup } from "../../components/Checkbox";
import { getMasterCountries } from "../../modules/marketplaces";

const NEW_REGION = "NEW_REGION";
const EDIT_REGION = "EDIT_REGION";
const optionsPermisos = [
  { value: "shipments-create", label: "Contador" },
  { value: "shipments-read", label: "Operador de Piso" },
];
class Regions extends React.Component {
  state = {
    empty: false,
    selectedCountry: [],
    countryError: false,
    name: "",
    nameError: "",
    mail: "",
    mailError: "",
    password: "",
    passwordError: "",
    passwordConfirm: "",
    passwordConfirmError: "",
    loading: false,
    regions: [],
    regionEdit: null,
    selectedChecked: [],
    countries: [],
  };

  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
    this.props.toggleSideBar(false);
  }

  componentDidMount() {
    this.getRegionsData();
    this.getCountries();
  }

  clearSb() {
    this.setState({
      name: "",
      mail: "",
      password: "",
      passwordConfirm: "",
      selectedCountry: [],
      nameError: "",
      mailError: "",
      passwordError: "",
      passwordConfirmError: "",
      selectedCountryError: [],
    });
  }

  select = (value) => {
    this.setState({ selectedChecked: value });
  };

  getCountries() {
    getMasterCountries().then(({ type, response }) => {
      if (type == "Success") {
        const respData = response.data;
        const countries = [];

        respData.forEach((ele) => {
          // buscar en countries
          let exist = !!countries.find((countrie) => countrie.id === ele.id);

          if (!exist) {
            countries.push(ele);
          }
        });
        this.setState({
          countries,
        });
      } else {
        this.setState({
          countries: [],
        });
      }
    });
  }

  getRegionsData() {
    this.setState({ loading: true });
    getRegions().then((response) => {
      if (response.type === "Success" && response.response.status === 200) {
        if (response.response.data.length === 0) {
          this.setState({ empty: true, loading: false });
        } else {
          this.setState({
            empty: false,
            regions: response.response.data,
            loading: false,
          });
        }
      } else {
        this.notify("Error al obtener la información", "error");
        this.setState({ loading: false });
      }
    });
  }

  @boundMethod
  toggleEditRol(id) {
    getSingleRegion(id).then((response) => {
      if (response.type === "Success" && response.response.status === 200) {
        const selectedCountryData = response.response.data.countries.map(
          (element) => ({ label: element.name, value: element.id })
        );
        this.setState({
          name: response.response.data.name,
          selectedCountry: selectedCountryData,
          regionEdit: id,
        });
      } else {
        this.notify("Error al obtener la información", "error");
        this.setState({ loading: false });
        this.props.toggleSideBar(false);
      }
    });
    this.props.toggleSideBar(true, `Editar Region ${id}`, EDIT_REGION);
  }

  @boundMethod
  toggleDeleteRegion(id) {
    deleteRegion(id).then((response) => {
      if (response.type === "Success" && response.response.status === 200) {
        this.notify("Region eliminada exitosamente", "success");
        this.props.toggleSideBar(false);
        this.clearSb();
        this.getRegionsData();
      } else {
        this.notify("Error al obtener la información", "error");
        this.setState({ loading: false });
        this.props.toggleSideBar(false);
      }
    });
  }

  renderRegions = (data) => {
    const datareturn = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i + 1]) {
        datareturn.push(
          <Row divisions={20}>
            <Column xs={17} md={7} fluid className="summary-microbox">
              <Region
                user={this.props.user}
                toggleDeleteRegion={this.toggleDeleteRegion}
                onClick={this.openAccount}
                toggleEditRol={this.toggleEditRol}
                data={data[i]}
              />
            </Column>

            <Column xs={1} fluid />
            <Column xs={17} md={7} fluid className="summary-microbox">
              <Region
                user={this.props.user}
                toggleDeleteRegion={this.toggleDeleteRegion}
                onClick={this.openAccount}
                toggleEditRol={this.toggleEditRol}
                data={data[i + 1]}
              />
            </Column>
          </Row>
        );
        i++;
      } else {
        datareturn.push(
          <Row divisions={20}>
            <Column xs={17} md={7} fluid className="summary-microbox">
              <Region
                user={this.props.user}
                toggleDeleteRegion={this.toggleDeleteRegion}
                onClick={this.openAccount}
                toggleEditRol={this.toggleEditRol}
                data={data[i]}
              />
            </Column>
          </Row>
        );
      }
    }
    return datareturn;
  };

  _renderCountries = (countries) => {
    const co = this.state.countries
      ? this.state.countries.map((country) => ({
          value: country.id,
          label: country.name,
        }))
      : null;

    return co;
  };

  handleChangeDrop = (selectedCountry) => {
    this.setState({ selectedCountry });
  };

  @boundMethod
  openAccount(id, regionId, email) {}

  _renderSidebarContent = () => {
    const { type } = this.props.sidebar;
    const {
      name,
      nameError,
      mail,
      mailError,
      password,
      passwordError,
      passwordConfirm,
      passwordConfirmError,
      selectedChecked,
    } = this.state;
    switch (type) {
      case NEW_REGION:
        return (
          <ContentAdapter className="top-space">
            <Label style={{ marginTop: "16px" }}>
              Selecciona un País(es)
              <Required />
            </Label>
            <Select
              isMulti
              value={this.state.selectedCountry}
              onChange={this.handleChangeDrop}
              noOptionsMessage={() => "Sin Opciones"}
              isSearchable
              placeholder="País(es)"
              closeMenuOnSelect={false}
              options={this._renderCountries()}
              className={`search-select accounts ${
                this.state.countryError != "" && "error"
              }`}
            />
            <br />
            <Input
              onChange={(value) => this.handleOnChange("name", value)}
              type="text"
              required
              value={name}
              error={nameError}
              placeholder="Region Centro/Sur"
              label="Nombre"
            />
            <BottomContainer>
              <Row divisions={20}>
                <Column style={{ textAlign: "right" }} fluid>
                  <Button
                    loading={this.state.setLoading}
                    onClick={() => this.addRegion()}
                    className=""
                  >
                    Guardar
                  </Button>
                </Column>
                <Column
                  xs={1}
                  xsShift={1}
                  style={{ textAlign: "right" }}
                  fluid
                />
              </Row>
            </BottomContainer>
          </ContentAdapter>
        );
      case EDIT_REGION:
        return (
          <ContentAdapter className="top-space">
            <Label style={{ marginTop: "16px" }}>
              Selecciona un País(es)
              <Required />
            </Label>
            <Select
              isMulti
              value={this.state.selectedCountry}
              onChange={this.handleChangeDrop}
              noOptionsMessage={() => "Sin Opciones"}
              isSearchable
              placeholder="País(es)"
              closeMenuOnSelect={false}
              options={this._renderCountries()}
              className={`search-select accounts ${
                this.state.countryError != "" && "error"
              }`}
            />
            <Input
              onChange={(value) => this.handleOnChange("name", value)}
              type="text"
              required
              value={name}
              error={nameError}
              placeholder="Region Centro/Sur"
              label="Nombre"
            />
            <BottomContainer>
              <Row divisions={20}>
                <Column style={{ textAlign: "right" }} fluid>
                  <Button
                    loading={this.state.setLoading}
                    onClick={() => this.regionUpdate()}
                    className=""
                  >
                    Guardar
                  </Button>
                </Column>
                <Column
                  xs={1}
                  xsShift={1}
                  style={{ textAlign: "right" }}
                  fluid
                />
              </Row>
            </BottomContainer>
          </ContentAdapter>
        );
      default:
        return <div />;
    }
  };

  handleOnChange(type, value) {
    switch (type) {
      case "name":
        this.setState({ name: value });
        break;
      case "mail":
        this.setState({ mail: value });
        break;
      case "password":
        this.setState({ password: value });
        break;
      case "passwordConfirm":
        this.setState({ passwordConfirm: value });
        break;

      default:
        break;
    }
  }

  addRegion() {
    const { name, selectedCountry } = this.state;
    if (this.validateFields()) {
      const countries = selectedCountry.map((element) => element.value);

      createRegion(name, countries).then((res) => {
        if (res.status === 200) {
          this.notify("Region creada exitosamente", "success");
          this.props.toggleSideBar(false);
          this.clearSb();
          this.getRegionsData();
        } else {
          this.notify("Error al crear región", "error");
          this.props.toggleSideBar(false);
          this.clearSb();
        }
      });
    }
  }

  regionUpdate() {
    const { name, selectedCountry, regionEdit } = this.state;
    if (this.validateFields()) {
      const countries = selectedCountry.map((element) => element.value);

      updateRegion(regionEdit, name, countries).then((res) => {
        if (res.status === 200) {
          this.notify("Region actualizada exitosamente", "success");
          this.props.toggleSideBar(false);
          this.clearSb();
          this.getRegionsData();
        } else {
          this.notify("Error al actualizar región", "error");
          this.props.toggleSideBar(false);
          this.clearSb();
        }
      });
    }
  }

  notify = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  validateFields() {
    let flag = true;
    const { name, selectedCountry } = this.state;
    if (name === "") {
      flag = false;
      this.setState({ nameError: "Campo obligatorio" });
    } else {
      this.setState({ nameError: "" });
    }
    if (selectedCountry.length === 0) {
      flag = false;
      this.setState({ countryError: true });
    } else {
      this.setState({ countryError: false });
    }
    return flag;
  }

  renderLoader() {
    return (
      <div>
        <Row divisions={20}>
          <Column xs={17} md={7} fluid className="summary-microbox">
            <Skeleton height={150} />
          </Column>

          <Column xs={1} fluid />
          <Column xs={17} md={7} fluid className="summary-microbox">
            <Skeleton height={150} />
          </Column>
        </Row>
        <br />
        <Row divisions={20}>
          <Column xs={17} md={7} fluid className="summary-microbox">
            <Skeleton height={150} />
          </Column>

          <Column xs={1} fluid />
          <Column xs={17} md={7} fluid className="summary-microbox">
            <Skeleton height={150} />
          </Column>
        </Row>
        <br />
        <Row divisions={20}>
          <Column xs={17} md={7} fluid className="summary-microbox">
            <Skeleton height={150} />
          </Column>

          <Column xs={1} fluid />
          <Column xs={17} md={7} fluid className="summary-microbox">
            <Skeleton height={150} />
          </Column>
        </Row>
      </div>
    );
  }

  render() {
    const { empty, loading } = this.state;
    const { toggleSideBar } = this.props;
    return (
      <div>
        <ToastContainer />
        <WrapperSideBar handleClose={() => this.clearSb()}>
          {this._renderSidebarContent()}
        </WrapperSideBar>
        {loading ? (
          this.renderLoader()
        ) : empty ? (
          <EmpyState
            user={this.props.user}
            onClick={() => {
              toggleSideBar(true, "Añadir Región", NEW_REGION);
            }}
            name="region"
          />
        ) : (
          this.renderRegions(this.state.regions)
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  countries: state.newShipment.countries,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSideBar,
      changeSidebarTitle,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Regions);
