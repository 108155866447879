import React from "react";
import { Column, Row } from "hedron";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Text from "../Text";
import "./index.css";
import "./transitions.css";
import { I } from "./styled-components.js";
import walletStyles from "../Checkout/index.module.scss";
// import walletStyles from "../Checkbox";

const WalletMessage = (props) => (
  <div className="helper-vol-text box" style={props.style}>
    <Text type="labelAuxiliar" className="box-title left">
      ¿Cómo recargar mi Wallet?
    </Text>
    <I
      className={
        props.open
          ? "ion-ios-arrow-up right icon"
          : "ion-ios-arrow-down right icon"
      }
      onClick={props.onClick}
    />
    <br />
    <ReactCSSTransitionGroup
      transitionName="toggleWallet"
      transitionAppear={true}
      transitionAppearTimeout={100}
      transitionEnterTimeout={600}
      transitionLeaveTimeout={600}
    >
      {props.open && (
        <div>
          <Text className={walletStyles.walletText}>
            Mienvío Wallet es nuestro monedero electrónico que te permite
            comprar tus envíos de una manera fácil y con tu información segura.
            <br />
            <br />
            <h1
              className={walletStyles.title}
              style={{ marginBottom: 0, textAlign: "Left" }}
            >
              Realiza una transferencia bancaria a tu cuenta clabe asignada.
            </h1>
            Nombre del beneficiario: MIENVIO DE MEXICO S.A.P.I. DE C.V.
            <br />
            Banco: <b>STP</b>
            <br />
            CLABE: <b> {props.user && props.user.stp_clabe} </b>
            <br />
            *Tu CLABE es única y solamente podrás usarla para recargar saldo a
            tu Wallet Mienvío.
            <br />
            *Si tienes más de un usuario en Mienvío, considera que esta clabe es
            intransferible.
            <br />
            <br />
            Tu saldo se reflejará en tu cuenta de inmediato. Si realizaste una
            recarga y el monto no se ve reflejado en tu cuenta después de 1
            hora, escribe a nuestro equipo de soporte para abrir tu{" "}
            <a
              className={walletStyles.walletLink}
              href="https://api.whatsapp.com/send?phone=+5214423783099&text=Hola,%20quiero%20levantar%20mi%20ticket%20de%20aclaraci%C3%B3n%20de%20recarga%20Wallet"
              target="_blank"
            >
              ticket de aclaración.
            </a>{" "}
            &nbsp;
            <br />
            <br />
            Contáctanos si tienes dudas o problemas al generar una recarga de
            saldo.
          </Text>
          <br />
        </div>
      )}
    </ReactCSSTransitionGroup>
  </div>
);

export default WalletMessage;
