import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { toggle as toggleSideBar } from '../../modules/CollapsibleBar/actions';

import IntegrationsRender from './IntegrationsRender';

import {
  getUserIntegrations,
  createWebhook,
  deleteWebhook,
  getShopifyOauth,
  getWoocommerceOauth,
  createPrestashop,
  createMagento,
  deleteShop,
  deleteCarrier,
  storeCarrierCredentials,
  toggleCarrierStatus,
} from '../../modules/integrations';

import { hideMessage, showMessage } from '../../modules/message';

const mapStateToProps = ({
  integrations, message, user, addresses,
}) => ({
  shops: integrations.shops,
  webhooks: integrations.webhooks,
  carriers: integrations.carriers,
  createdType: integrations.createdType,
  message,
  user,
  addresses,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    changePage: () => push('/about-us'),
    getUserIntegrations,
    createWebhook,
    deleteWebhook,
    getShopifyOauth,
    getWoocommerceOauth,
    createPrestashop,
    hideMessage,
    showMessage,
    createMagento,
    toggleSideBar,
    storeCarrierCredentials,
    deleteCarrier,
    returnIntegrations: () => push('/integraciones'),
    deleteShop,
    toggleCarrierStatus,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationsRender);
