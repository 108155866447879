import React from 'react';
import { Row, Column } from 'hedron';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Provider from "./provider";
import {BottomContainer, ContentAdapter} from "../../components/WrapperSideBar/styledObjects";
import {Radio, RadioGroup} from "../../components/Radio";
import Select from "react-select";
import Input, {HelpText, Label, Required} from "../../components/Input";
import {DateRange} from "react-date-range";
import * as rdrLocales from "react-date-range/dist/locale";
import Dropdown from "../../components/Dropdown";
import Text from "../../components/Text";
import Button from "../../components/Button";

class MasterConfigCalendar extends React.Component {
    state = {
        selectedProvider: null,
        type: 0,
    };

    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Row divisions={20}>
                <Column xs={6} fluid>
                    <div className="card-container-accounts">
                        <div className="title-container">
                            <div className="title-container-row">
                                <p className="title-card">Activación Automática</p>
                            </div>
                        </div>
                        <div className="card-container-divisor" />
                        <div className="item-master-config">

                            <p>Activación Automática de Disponibilidad</p>
                        </div>
                    </div>
                </Column>
                <Column xs={1} fluid />
                <Column xs={13} fluid>
                    <Provider  />
                </Column>
            </Row>
        );
    }
}
const mapStateToProps = state => ({
    sidebar: state.sidebar,
    countries: state.newShipment.countries,
    user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
    },
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MasterConfigCalendar);
