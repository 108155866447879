import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import {
  getUserAllAddresses, createAddress, editAddress,
} from '../../modules/addresses/actions';
import { hideMessage } from '../../modules/message';
import Addresses from './AddressesRender';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getUserAllAddresses,
  createAddress,
  editAddress,
  goToAddressList: () => push('/direcciones'),
  goToAddress: id => push(`/direcciones/${id}`),
  goToDefaultConfig: state => push({
    pathname: '/envios/default/from',
    state: { from: state },
  }),
  goBack: () => goBack(),
  hideMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Addresses);
