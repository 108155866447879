import React from "react";
import { AXIOS_BASEURL } from "../../constants";
import colors from "../../utils/colors";
import Dropzone from "../Dropzone";
import Text from "../Text";
import "./index.scss";

const Upload = (props) => (
  <div className="upload-csv">
    {/* <div className="section-separator adjust-top upload-title">
      Quiero saber más de esta funcionalidad
      <div className="link-up">
        <a href="http://localhost:3000/envios" target="_blank">
          <i className="ion-android-open" />
        </a>
      </div>
    </div> */}
    <Dropzone
      name="drop-csvfile"
      label="Archivo CSV"
      accept="text/csv,.csv"
      onDrop={props.onDrop}
      onDelete={props.onDelete}
      multiple={false}
      out_error={props.error}
      onClick={(evt) => evt.preventDefault()}
    />
    <br />

    <div className="label-upload-csv-cont">
      <a
        href={`${AXIOS_BASEURL}assets/templates/mienvio_CSV.csv`}
        target="_blank"
        className="edit-btn-left more-btn btn-download-file"
      >
        Descarga nuestra plantilla CSV
      </a>
      <br />
      <br />

      <Text type="textTable" className="label-upload-csv title">
        ¡ALTO! Antes de iniciar considera estos lineamientos: <br />
      </Text>
      <ul className="label-upload-csv">
        <li className="label-upload-csv-li">
          <Text type="textTable" className="label-upload-csv">
            1. Cada campo de la plantilla tiene un limite de caracteres.
          </Text>
        </li>
        <li className="label-upload-csv-li">
          <Text type="textTable" className="label-upload-csv">
            2. Solo podrás elegir una paquetería y un tipo de servicio para cada
            orden.
          </Text>
        </li>{" "}
      </ul>
      <Text type="textTable" className="label-upload-csv">
        Te compartimos una plantilla Guía con ejemplos de como debes llenar cada
        campo para crear tus ordenes exitosamente.
      </Text>

      <a
        href={`${AXIOS_BASEURL}assets/templates/ejemplo_mienvio_CSV.csv`}
        target="_blank"
        className="edit-btn-left more-btn btn-download-file"
      >
        Guía para mi archivo CSV
      </a>
    </div>
  </div>
);

export default Upload;
