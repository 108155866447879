// @flow

import type from 'redux';
import { SHOW_MESSAGE } from './message';

type ErrorObject = {
  type: string,
  message: string,
  status_code: number,
  params?: {}
};

export const showError = (msg?: ?string, error?: ?ErrorObject) => {
  let messageError = '';
  if(typeof msg === 'string' || msg instanceof String)  {
    messageError = msg;
  } else {
    try {
      switch (msg.status_code) {
        // unauthorized
        case 401:
        messageError = 'Las credenciales de acceso proporcionadas son inválidas (api token).';
        break;
        // unprocessable_payment
        case 402:
        messageError = 'El pago fue declinado por el proveedor de pagos.';
        break;
        // access_denied
        case 403:
        messageError = 'No tiene permiso para utilizar el recurso solicitado.';
        break;
        // not_found
        case 404:
        messageError = 'El recurso solicitado no existe.';
        break;
        // unprocessable_entity
        case 422:
        messageError = 'La solicitud no puede ser procesada porque los datos son inválidos.';
        break;
        // internal error
        case 500:
        messageError = 'Ocurrió un error interno, intente más tarde.';
        break;
        // external_provider_error
        case 503:
        messageError = 'Ocurrió un error con el sistema de algún proveedor y la solicitud no puede ser procesada, intente más tarde.';
        break;
        default:
        messageError = msg.message
        break;
      }
    } catch(err) {
      if(msg && msg.message) {
        messageError = msg.message;
      } else {
        messageError = 'La solicitud no puede ser procesada.'
      }
    }
  }

  return {
    type: SHOW_MESSAGE,
    messageType: 'error',
    text: messageError,
  };
};
