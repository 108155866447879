import styled from 'styled-components';

export const DropdownDiv = styled.div`
  position: absolute;
  width: 163px;
  bottom: -90px;
  height: 84px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 12px 7px rgba(208, 208, 208, 0.48);
  right: 0;
  cursor: pointer;
`;

export const I = styled.i`
  cursor: pointer;
  width: 10px;
  height: 5px;
`;

export const LabelStyled = styled.label`
  font-size: 11px;
  color: #858d9d;
  img {
    padding-right: 5px;
  }
`;

export const LabelStyledDetail = styled.label`
  font-size: 16px;
  color: #858d9d;
  img {
    padding-right: 8px;
  }
`;
