export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

// export const STRIPE_PUBLIC = 'pk_live_nQ7gEHv9CqpG9P80cww0vvrR';
export const STRIPE_PUBLIC = 'pk_test_lroL1tij4uijiCpr4hhwBZzh';

export const INTERCOM_APP_ID = 'x9y2a9m7';

export const FACEBOOK_PIXEL_ID = '222652714940174';
