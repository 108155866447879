// @flow
import React, { useState } from 'react';
import axios from 'axios';
import { Row, Column } from 'hedron';
import es from 'date-fns/locale/es';
import SweetAlert from 'sweetalert2-react';
import Modal from 'react-responsive-modal';
import Text, { Title, P } from '../../../components/Text';
import Check from './check.svg';
import Warning from './warning.svg';
import { Button } from './Button';
import { default as Button2 } from '../../../components/Button';
import VistaIndividual from './vista-individual.png';
import VistaPaso from './vista-paso.png';
import './style.css';

export default class ShipmentItem extends React.Component {
    state = {
      show: false,
      open: false,
    };

    onOpenModal = () => {
      this.setState({ open: true });
    };

    onCloseModal = () => {
      this.setState({ open: false });
    };

    render() {
      const { data } = this.props;
      const { open } = this.state;
      return (
        <div className="shipment-item-container">
          <SweetAlert
            show={this.state.show}
            title="Completado"
            icon="success"
            onConfirm={() => this.setState({ show: false })}
          />
          <Modal center={true} open={open} onClose={this.onCloseModal}>
            <Title type="titleCardSmall">
              Reportar Incidencia - #
              {data.id}
            </Title>
            <div className="input-container-shipmentsReady">
              <textarea placeholder="¿Cual es el problema?" align="top" className="input-incident" type="text" name="name" />
            </div>
            <div className="button-modal-container">
              <Button2
                initialLoader
                onClick={() => this.onCloseModal()}
              >
              Reportar
              </Button2>
            </div>

          </Modal>
          <Row
            divisions={20}
            alignItems="center"
            justifyContent="center"
          >
            <Column fluid xs={1} />
            <Column fluid xs={19} md={9}>
              <Title type="titleCardSmall">
                  #
                {data.id}
              </Title>

            </Column>
            <Column fluid xs={19} md={10}>
              <div className="btns-container">
                <Button onClick={() => { this.setState({ open: true }); }} color="warning" icon={Warning} />
                <Button onClick={() => { this.setState({ show: true }); }} color="success" icon={Check}>Completado</Button>
              </div>
            </Column>
            <Column fluid xs={1} />
            <div className="divisor" />
          </Row>
          <Row
            divisions={12}
          >
            <Column fluid xs={13} md={4}>
              <div className="container-line margin">
                <div className="dot" />
                <p className="section-title">Origen</p>
                <p className="section-content">{data.origin.name}</p>
                <p className="section-content">{data.origin.email}</p>
                <p className="section-content">{data.origin.phone}</p>
                <p className="section-content">{data.origin.street}</p>
                <p className="section-content">{data.origin.street2}</p>
                <p className="section-content">
                  {data.origin.municipality}
                  ,
                  {' '}
                  {data.origin.state}
                  ,
                  {' '}
                  {data.origin.country}
                </p>
              </div>
              <div className="container-line no-border">
                <div className="dot" />
                <p className="section-title">Destino</p>
                <p className="section-content">{data.destiny.name}</p>
                <p className="section-content">{data.destiny.email}</p>
                <p className="section-content">{data.destiny.phone}</p>
                <p className="section-content">{data.destiny.street}</p>
                <p className="section-content">{data.destiny.street2}</p>
                <p className="section-content">
                  {data.destiny.municipality}
                  ,
                  {' '}
                  {data.destiny.state}
                  ,
                  {' '}
                  {data.destiny.country}
                </p>
              </div>
            </Column>
            <Column fluid xs={13} md={4}>
              <div className="container-line margin no-border no-left">
                <p className="section-title">Paquete</p>
                <p className="section-subtitle">Dimensiones</p>
                <p className="section-content">
                  {data.package.dimensions.height}
                  {' '}
                  x
                  {' '}
                  {data.package.dimensions.width}
                  {' '}
                  x
                  {' '}
                  {data.package.dimensions.length}
                  {' '}
                  cm
                </p>
                <p className="section-subtitle">Dimensiones</p>
                <p className="section-content">
                  {data.package.weight}
                  {' '}
                  kg
                </p>
              </div>
              <div className="container-line no-border no-left">
                <p className="section-title">Items</p>
                {data.items.map(element => (
                  <div>
                    <p className="section-subtitle">{element.name}</p>
                    <Row
                      divisions={12}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Column fluid xs={6}>
                        <p className="section-content">Dimensiones</p>
                        <p className="section-content">
                          {element.dimensions.height}
                          {' '}
                  x
                          {' '}
                          {element.dimensions.width}
                          {' '}
                  x
                          {' '}
                          {element.dimensions.length}
                          {' '}
                  cm

                        </p>
                      </Column>
                      <Column fluid xs={6}>
                        <p className="section-content">Peso</p>
                        <p className="section-content">
                          {element.weight}
                          {' '}
                  kg
                        </p>
                      </Column>
                    </Row>
                  </div>
                ))}
              </div>
            </Column>
            <Column fluid xs={13} md={4}>
              <div className="container-line margin no-border no-left">
                <p className="section-title">Empaquetado</p>
                <p className="section-subtitle">Vista individual</p>
                <img src={VistaIndividual} />
              </div>
              <div className="container-line margin no-border no-left">
                <p className="section-subtitle">Vista paso a paso</p>
                <img src={VistaPaso} />
              </div>
            </Column>
          </Row>
        </div>
      );
    }
}
