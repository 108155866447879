import React from 'react';
import DataTable from 'react-data-table-component';
import PropTypes from 'prop-types';
import './style.css';
import sortIconSrc from './icon/sort-icon-down.svg';
import Dropdown from '../Dropdown';

const SortIcon = props => <img src={sortIconSrc} style={{ marginRight: '4px' }} />;

const customTheme = {
  // title: {
  //     fontSize: '22px',
  //     fontColor: '#FFFFFF',
  //     backgroundColor: '#363640',
  // },
  // contextMenu: {
  //     backgroundColor: '#E91E63',
  //     fontColor: '#FFFFFF',
  // },
  // header: {
  //     fontSize: '12px',
  //     fontColor: '#FFFFFF',
  //     backgroundColor: '#363640',
  // },
  rows: {
    //     fontColor: '#FFFFFF',
    //     backgroundColor: '#363640',
    //     borderColor: 'rgba(255, 255, 255, .12)',
    borderWidth: '2px',
    //     hoverFontColor: 'black',
    //     hoverBackgroundColor: 'rgba(0, 0, 0, .24)',
  },
  cells: {
    // padding: '72px',
    // paddingTop: '1.25rem',
    // paddingBottom: '1.25rem',
  },
  // pagination: {
  //     fontSize: '13px',
  //     fontColor: '#FFFFFF',
  //     backgroundColor: '#363640',
  //     buttonFontColor: '#FFFFFF',
  //     buttonHoverBackground: 'rgba(255, 255, 255, .12)',
  // },
  // expander: {
  //     fontColor: '#FFFFFF',
  //     backgroundColor: '#363640',
  // },
};

class NewTable extends React.Component {
  state = {};

  onTableUpdate = (data) => {
    this.props.onTableUpdate && this.props.onTableUpdate(data);
  };

  onRowClicked = (data) => {
    this.props.onRowClicked && this.props.onRowClicked(data);
  };

  render() {
    return (
      <div
        className={`newtable ${this.props.shipping && 'padding'}  ${this.props.hideForSmall
          && 'hide-for-small-only'} ${this.props.marginTop}`}
      >
        <DataTable
          defaultSortAsc={this.props.defaultSortAsc}
          sortIcon={<SortIcon />}
          title={this.props.title}
          columns={this.props.columns}
          grow={this.props.grow}
          center={this.props.center}
          data={this.props.data}
          conditionalRowStyles={this.props.conditionalRowStyles}
          subHeader={!!this.props.subHeader}
          subHeaderAlign="left"
          className={this.props.className}
          clearSelectedRows={this.props.clearSelectedRows}
          subHeaderComponent={<div>{this.props.subHeader}</div>}
          keyField="id"
          onTableUpdate={this.onTableUpdate}
          onRowClicked={this.onRowClicked}
          pointerOnHover
          selectableRows={this.props.selectableRows ? this.props.selectableRows : false} // add for checkbox selection
          expandableRows={this.props.expandableRows ? this.props.expandableRows : false}
          customTheme={customTheme}
          noDataComponent={this.props.noDataComponent}
          expandableRowsComponent={this.props.expandableRowsComponent}
          defaultSortField={this.props.defaultSortField ? this.props.defaultSortField : null}
        />
      </div>
    );
  }
}

NewTable.propTypes = {
  columns: PropTypes.array.isRequired,
};

export default NewTable;
