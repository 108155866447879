import React from 'react';
import styled from 'styled-components';

import Table, { Th, Tr, Td } from '../../../components/Table';
import Button from '../../../components/Button';

import shopify from './shopify.png';
import woocommerce from './woocommerce.png';
import prestashop from './prestashop.png';
import magento from './magento.png';
import epages from './epages.png';
import tiendanube from './tiendanube.png';
import './style.css';
import { searchPermission } from '../../../utils/global_functions';

const shopIcons = {
  shopify,
  woocommerce,
  prestashop,
  magento,
  epages,
  tiendanube,
};

const StyledConnected = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  display: inline-block;
  background: #00ee7a;
  margin-left: 6px;
`;

const StyledDisconnected = StyledConnected.extend`
  background: #d8d8d8;
`;

const Marketplace = ({
  icon, shopName, functions, shop, user,
}) => {
  const {
    customer_type, plan, actions, role, operational_user,
  } = user;

  const permissions = [];
  if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
    operational_user.operational_role.actions.map(element => permissions.push(element));
  }

  if (plan && plan.features) {
    plan.features.map(element => permissions.push(element));
  }
  if (actions) {
    actions.map(element => permissions.push(element));
  }
  return (
    <Tr>
      <Td>
        <img src={shopIcons[icon]} alt={shopName} width="70" />
      </Td>
      <Td>{shop ? shop.name : '-'}</Td>
      <Td>
        {shop ? (
          <div>
            <StyledConnected />
            {' '}
            Conectado
          </div>
        ) : (
          <div>
            <StyledDisconnected />
            {' '}
            Sin conexión
          </div>
        )}
      </Td>

      {searchPermission('marketplaces', 'eliminar', permissions) ? (
        <Td>

          {shop ? (
            <Button type="link" onClick={() => functions.deleteShop(shop.object_id)}>
            Desconectar
            </Button>
          ) : (
            <Button type="link" onClick={() => functions.handleSelect(icon)}>
            Conectar
            </Button>
          )}
        </Td>
      ) : (
        <Td />
      )}


      {icon === 'magento'
        ? (
          <Td>
            <div className="text-return-rates">
              {
                shop && (shop.retrieve_all_rates ? 'Si' : 'No')
              }
            </div>
          </Td>
        )
        : (
          <Td>
            <div className="text-return-rates" />
          </Td>
        )
      }
      {icon === 'magento'
        ? (
          <Td>
            <div>
              {
                shop && (shop.default_service_level || 'Costo más bajo')
              }
            </div>
          </Td>
        )
        : (
          <Td>
            <div className="text-return-rates" />
          </Td>
        )}
      {icon === 'magento'
        ? (
          <Td>
            <Button
              disabled={!shop}
              type="link"
              onClick={() => functions.showRate(shop)}
            >
          Editar
            </Button>
          </Td>
        )
        : (
          <Td>
            <div className="text-return-rates" />
          </Td>
        )}


    </Tr>
  );
};

export default Marketplace;
