import React from "react";
import axios from "axios";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";

import Card, { Divider } from "../../../components/Card";
import Table, { Th, Tr, Td } from "../../../components/Table";
import { getActiveCarriers } from '../../../modules/integrations';

import Carrier from "./carrier";

const carrierNames = [
  "estafeta",
  "redpack",
  "ups",
  "paquetexpress",
  "fedex",
  "minutos",
  "chazki",
  "shippify",
  "blueexpress",
  "olva",
  "guatex",
  "servientrega",
  "urbano",
  "yovoy",
  "pap",
  "dostavista",
  "starken",
  "kangou",
  "bicimensajero",
  "costa_rica",
  "flechaamarilla",
  "urbanochile",
  "esameday",
  "servientregapa",
  "laterminal",
  "savarexpress",
  "uenvios",
  "fruno",
  "distribucionxcb",
  "servientregaconflete",
  "mybox",
  "fastlogicsa",
];

export const carriersFieldsLabels = {
  api_key: "API Key",
  key: "Key",
  secret: "Secret",
  id_token: "ID Token",
  codigo_usuario: "Código de usuario",
  cuenta_usuario: "Cuenta de Usuario",
  codigo_persona: "Código de Persona",
};

const Carriers = (props) => {
  return(
  <Table>
    <thead>
      <Tr>
        <Th>Carrier</Th>
        <Th>Cuenta</Th>
        <Th>Estatus</Th>
        <Th>Activo</Th>
        <Th />
        <Th>Estandar</Th>
        <Th>Express</Th>
      </Tr>
    </thead>
    <tbody>
      {props.activeCarriers.map((carrier, index) => (
        <Carrier
          icon={carrier.low_name}
          carrier={props.carriers[carrier.low_name]}
          provider={carrier}
          functions={{
            handleSelect: props.handleSelect,
            deleteCarrier: props.deleteCarrier,
            showCarrier: props.showCarrier,
            changeStatus: props.changeStatus,
            storeCarrierCredentials: props.storeCarrierCredentials
          }}
        />
      ))}
    </tbody>
  </Table>
)};

const mapStateToProps = (state) => ({
  carriers: state.integrations.carriers,
  activeCarriers: state.integrations.activeCarriers
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changePage: () => push("/about-us"),
      getActiveCarriers
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Carriers);
