import axios from 'axios';

export const getCarriers = id => axios
  .get(`api/users/carriers/marketplace/${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));

export const updateCarrierStatus = (marketplaceId, id, active) => axios
  .put(`api/users/carriers/${id}/marketplace/${marketplaceId}`, { active })
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));
