// @flow
import React from 'react';
import styledProps from 'styled-props';
import styled from 'styled-components';
import Loader from 'react-loaders';
import colors from '../../utils/colors';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StyledList } from '../Dropdown';
import 'loaders.css/loaders.min.css';
import './style.css';

const backgrounds = {
  blue: colors.primary.mienvio_blue,
  primary: colors.primary.mienvio_blue,
  secondary: 'transparent',
  link: 'transparent',
  table: colors.gray.table_button,
  icon: 'transparent',
  nav: 'transparent',
  disabled_table: colors.gray.disabled,
  small_blue: colors.secondary.royal_blue,
  default: 'white'
};

const hoverBackgrounds = {
  blue: colors.primary.dark_blue,
  primary: colors.secondary.dark_blue,
  secondary: 'transparent',
  link: 'transparent',
  table: colors.secondary.medium_gray,
  icon: 'transparent',
  nav: 'transparent',
  disabled_table: colors.gray.disabled,
  small_blue: '#19539A',
  default: 'white'
};

const hoverBorders = {
  primary: 'transparent',
  secondary: colors.secondary.dark_blue,
  link: colors.secondary.medium_blue,
  table: colors.secondary.medium_gray,
  icon: colors.secondary.dark_pink,
  nav: 'transparent',
  disabled_table: colors.gray.disabled,
  small_blue: 'transparent',
  default: 'white'
};

const hoverFonts = {
  primary: colors.secondary.white,
  secondary: colors.secondary.dark_blue,
  link: colors.secondary.medium_blue,
  table: colors.gray.solid_black,
  icon: colors.secondary.dark_pink,
  nav: colors.gray.earth_stone,
  disabled_table: colors.gray.pigeon_gray,
  small_blue: colors.secondary.white,
  default: 'white'
};

const borders = {
  primary: 'none',
  secondary: `1.5px solid ${colors.primary.mienvio_blue}`,
  link: 'none',
  table: `1.5px solid #CAD2DF`,
  icon: `1.5px solid ${colors.primary.mienvio_pink}`,
  nav: 'none',
  disabled_table: `1.5px solid ${colors.gray.disabled}`,
  small_blue: 'none',
  default: 'white'
};

const textColors = {
  primary: colors.secondary.white,
  secondary: colors.primary.mienvio_blue,
  link: colors.secondary.royal_blue,
  table: colors.gray.solid_black,
  icon: colors.primary.mienvio_pink,
  nav: colors.gray.solid_black,
  disabled_table: colors.gray.pigeon_gray,
  small_blue: colors.secondary.white,
  default: 'white'
};

const borderRadius = {
  primary: '6px',
  secondary: '6px',
  link: '0px',
  table: '2px',
  icon: '6px',
  nav: '6px',
  disabled_table: '6px',
  small_blue: '2px',
  default: 'white'
};

const paddings = {
  primary: '12px 16px',
  secondary: '12px 16px',
  link: '0px 0px',
  table: '8px 16px',
  icon: '6px 16px',
  nav: '6px 18px',
  disabled_table: '8px 16px',
  small_blue: '4px 8px',
  default: 'white'
};

const fontSizes = {
  primary: '14px',
  secondary: '14px',
  link: '13px',
  table: '14px',
  icon: '17px',
  nav: '19px',
  disabled_table: '14px',
  small_blue: '14px',
  default: 'white'
};
const fontWeights = {
  primary: '600',
  secondary: '600',
  link: '600',
  table: '600',
  icon: '600',
  nav: '600',
  disabled_table: '600',
  small_blue: 'lighter',
  default: 'white'
};

const widths = {
  normal: 'auto',
  block: 'calc(100%)'
};

const margins = {
  primary: ' 12px 24px',
  secondary: ' 12px 24px',
  link: ' 12px 24px',
  table: '0px',
  icon: '12px 24px',
  nav: ' 12px 24px',
  disabled_table: '0px',
  small_blue: '4px 0',
  default: 'white'
};

const Dropdown = StyledList.extend`
  margin-top: 9px !important;
  padding-bottom: 0px;
  ul {
    list-style-type: none;
    padding: 0;
    li {
      border-bottom: 1px solid ${colors.gray.input};
      color: ${colors.newcolors.input};
      font-weight: 700;
      text-align: center;
      padding: 8px 0px;
      cursor: pointer;
      &:hover {
        background: ${colors.gray.sky_gray};
      }
    }
    li:last-child {
      border-bottom: none;
    }
  }
`;

const DropdownTop = Dropdown.extend`
  margin-top: 0px;
  bottom: 36px;
`;

const StyledButton = styled.button`
  background: ${styledProps(backgrounds, 'type')};
  border: ${styledProps(borders, 'type')};
  color: ${styledProps(textColors, 'type')};
  border-radius: ${styledProps(borderRadius, 'type')};
  padding: ${styledProps(paddings, 'type')};
  transition: background-color 0.05s ease-in-out;
  font-size: ${styledProps(fontSizes, 'type')};
  font-weight: ${styledProps(fontWeights, 'type')};
  display: inline-block;
  letter-spacing: 0.44px;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  margin: ${styledProps(margins, 'type')};
  width: ${styledProps(widths, 'size')};
  font-family: Muli;
  &:hover {
    background: ${styledProps(hoverBackgrounds, 'type')};
    border-color: ${styledProps(hoverBorders, 'type')};
    color: ${styledProps(hoverFonts, 'type')};
  }
`;

const StyledButtonLeft = StyledButton.extend`
  background: ${styledProps(backgrounds, 'type')};
  margin-right: 0 !important;
  border-radius: 6px 0px 0px 6px;
  text-align: right;
  padding-right: 8px !important;
`;

const StyledButtonRight = StyledButton.extend`
  position: relative;
  margin-left: 0 !important;
  border-radius: 0px 6px 6px 0px;
  padding: 12px 9px;
  background: ${styledProps(hoverBackgrounds, 'type')};
  border-color: ${styledProps(hoverBorders, 'type')};
`;

const Icon = styled.i`
  font-size: 15px;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.gray.input_icon};
`;

StyledButton.defaultProps = {
  type: 'primary',
  size: 'normal'
};

type Props = {
  className?: string,
  type?: string,
  size?: string,
  onClick: (...args: Array<any>) => any,
  style?: Object,
  children: any,
  disabled: boolean,
  loader: any,
  loading?: boolean,
  tip: boolean,
  forr?: string
};

const Button = (props: Props) => (
  <StyledButton
    className={props.className}
    type={props.disabled ? `disabled_${props.type ? props.type : 'default'}` : props.type}
    size={props.size}
    onClick={props.onClick}
    style={props.style}
    data-tip={props.tip}
    data-for={props.forr}
    initialLoader={props.initialLoader}
    disabled={props.disabled || props.loader.show || props.loading}
  >
    {(props.loader.show || props.loading) && !props.initialLoader ? (
      <Loader type="ball-pulse" />
    ) : (
      props.children
    )}
  </StyledButton>
);

class ActionButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  renderOptions = options => {
    return options.map((option, index) => (
      <li
        onClick={() => {
          this.toggleOpen();
          option.onClick();
        }}
        key={index}
      >
        {option.text}
      </li>
    ));
  };

  render() {
    const { props } = this;
    return (
      <div className="span-action-btn">
        <StyledButtonLeft
          className={props.className}
          type={props.disabled ? `disabled_${props.type ? props.type : 'default'}` : props.type}
          size={props.size}
          onClick={props.onClick}
          style={props.style}
          data-tip={props.tip}
          data-for={props.forr}
          initialLoader={props.initialLoader}
          disabled={props.disabled || props.loader.show || props.loading}
        >
          {(props.loader.show || props.loading) && !props.initialLoader ? (
            <Loader type="ball-pulse" />
          ) : (
            props.children
          )}
        </StyledButtonLeft>
        <StyledButtonRight
          className={`${props.className} ${this.state.open ? 'open-button' : ''}`}
          type={props.disabled ? `disabled_${props.type ? props.type : 'default'}` : props.type}
          size={props.size}
          onClick={() => this.toggleOpen()}
          style={props.style}
          data-tip={props.tip}
          data-for={props.forr}
          initialLoader={props.initialLoader}
          disabled={props.disabled || props.loader.show || props.loading}
        >
          <i className="ion-arrow-down-b" />
        </StyledButtonRight>
        {this.state.open && !props.top && (
          <Dropdown className="action-btn-options">
            <ul>{this.renderOptions(props.options || [])}</ul>
          </Dropdown>
        )}
        {this.state.open && props.top && (
          <DropdownTop>
            <ul>{props.options}</ul>
          </DropdownTop>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loader: state.loader
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Button);

const ActionButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionButtonComponent);

export { ActionButton };
