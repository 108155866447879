import axios from 'axios';

export const getShops = id => axios
  .get(`api/shops/marketplace/${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));

export const deleteShop = (marketplaceId, id) => axios
  .delete(`api/shops/${id}`)
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));

export const getWoocommerceOauth = (store_url, store_name, id) =>  axios
    .get(
      `api/woocommerce/oauth/marketplace/${id}?store_url=${store_url}&store_name=${store_name}&return_url=${
        window.location.href
      }`, { crossdomain: true })
   .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));



export const createMagento = (name, url, api_key, id) => axios  
  .post(`api/magento/oauth/marketplace/${id}`, {
    name: name,
    url: url,
    api_key: api_key,
  })
  .then(response => ({ type: 'Success', response }))
  .catch(err => ({ type: 'Error', response: err }));


export const getShopifyOauth = (store_url, store_domain, id)  => axios    
    .get(`/api/shopify/oauth/marketplace/${id}?store_url=${store_url}&store_domain=${store_domain}`)
    .then(response => ({ type: 'Success', response }))
    .catch(err => ({ type: 'Error', response: err }));


export const createPrestashop = (name, url, api_key, id) => axios 
    .post('api/prestashop/auth/marketplace/'+id, {
      name,
      url,
      api_key,
    })
    .then(response => ({ type: 'Success', response }))
    .catch(err => ({ type: 'Error', response: err }));





