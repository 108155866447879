const url = process.env.REACT_APP_URL;

export const AXIOS_BASEURL = url;
export const BASEURL = url;

export const APP_URL =
  process.env.NODE_ENV === "development"
    ? "https://dggs7n5nuju1w.cloudfront.net"
    : "https://dp8zgyt45artn.cloudfront.net";

// const test = true;
// const localUrl = test
//   ? "https://dev-sandbox.mienvio.mx/" // test = true
//   : "https://production.mienvio.mx/"; // test = false

// export const AXIOS_BASEURL =
//   process.env.NODE_ENV === "development"
//     ? localUrl
//     : process.env.REACT_APP_AXIOS_URL;
// export const APP_URL =
//   process.env.NODE_ENV === "development"
//     ? "https://dev.mienvio.mx/"
//     : "https://app2.mienvio.mx/";
// export const BASEURL =
//   process.env.NODE_ENV === "development"
//     ? localUrl
//     : process.env.REACT_APP_AXIOS_URL;

export const isDiscountPromoActive = false;
export const startDiscountPromoDate = new Date(2019, 8, 2, 0, 0, 0);
export const finishDiscountPromoDate = new Date(2019, 8, 16, 0, 0, 0);
export const discountPromoCarriers = [
  {
    name: "Redpack",
    percentage: 0.1,
  },
];

export const carriers = [
  "Estafeta",
  "RedPack",
  "Ups",
  "Fedex",
  "Chazki",
  "BlueExpress",
  "Shippify",
];
export const platformsType = [
  "shopify",
  "woocommerce",
  "prestashop",
  "magento",
];

export const SENTRY_CONFIG = {
  dsn: "https://969d26a4a0c44b3bae79a79e0f39af62@sentry.io/1510860",
  release: "mienvio-frontsite@1.15.0",
};

export const extendedZonePricing = {
  Redpack: "$ 122.00",
  Estafeta: "$ 138.00",
  FedEx: "$ 206.00",
  DHL: "$ 169.00",
};

export const API_GLOBAL_OPERATIONXCB = "hyRSTMPAcqQdHe9LZmbiSYCjT2j17k1PSXlyU1z72Nvdx8vEXN2cz1ahort2";
export const API_GLOBAL_OPERATIONXCB_SAND = "0V6ddGyrFQ8oRk4Va4uzlPsAU5fPIdxkTJWywGsphEqirC9Rh5HGplOwgRoo";

export const CANCEL_STATUS = ['Cancelado en Bodega', 'Cancelado en Ruta', 'Cancelado por Tiempo'];
export const CANCEL_STATUS_KEY = ['CANCELADO_BODEGA', 'CANCELADO_RUTA', 'CANCELADO_TIEMPO'];
