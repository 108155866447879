type State = {
  +download: boolean,
  +purchase?: Object,
  +shipments?: Array<Object>
};

export const initialState: State = {
  invoices: {},
  loading: false,
  sending: false,
  timing: false,
  creating: false,
  invoiceCurrent: null,
  purchaseCurrent: null,
  tab: 0,
  download: false,
  purchase: null,
  error: null
};