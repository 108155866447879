import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import styled from "styled-components";
import { push } from "react-router-redux";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import Moment from "react-moment";
import Tabs, { Tab } from "../Tabs";
import Header from "../Header";
import Text, { Title } from "../Text";
import Input from "../Input";
import Filter from "../Filter";
import paypal_img from "./Icons/PayPal_logo.svg";
import card_placeholder from "./Icons/mastercard.svg";
import calendar_disable from "./Icons/calendar-disable.svg";
import hourglas from "./Icons/hourglass-1.svg";
import checkIcon from "./Icons/notes-check.svg";
import Table, { Th, Tr, Td } from "../Table";
import Button from "../Button";
import Card from "../Card";
import EmpyState from "../EmptyState";
import "moment-timezone";
import InvoiceDetail from "./Details";
import {
  ContentAdapter,
  BottomContainer,
} from "../WrapperSideBar/styledObjects";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../modules/CollapsibleBar/actions";
import { LabelStyled, LabelStyledDetail, I } from "./styledObjects";
import locationIcon from "./Icons/location.png";
import WrapperSideBar from "../WrapperSideBar";
import "./invoice.css";
import Dropdown from "../Dropdown";

import {
  numberWithCommas,
  searchPermission,
} from "../../utils/global_functions";

import wallet from "../CardsList/icons/wallet.svg";

import Message from "../Message";
import {
  getUserInvoices,
  createInvoice,
  createInvoices,
  createWalletInvoice,
  createAddWalletInvoice,
  resendInvoice,
} from "../../modules/Invoices/actions";
import { INVOICE_DETAILS } from "../../modules/CollapsibleBar/sideBarTypes";
import SidebarTitleMenu from "../WrapperSideBar/extraComponents/SidebarTitleMenu";
import { showMessage, hideMessage } from "../../modules/message";
import edit_icon from "../../utils/icons/edit.svg";
import error_icon from "./Icons/error-icon.svg";

class InvoiceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      filter: "",
      filters: [],
      invoiced: false,
      dateFilter: {
        icon: "ion-android-arrow-dropdown",
        order: true,
      },
      concepts: [],
      selectedConcept: [],
      selectedDate: [],
      invoice: null,
      next: null,
      old: null,
    };
    this.generateTable = this.generateTable.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.select = this.select.bind(this);
    this.renderEmptyState = this.renderEmptyState.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.getInvoices = this.getInvoices.bind(this);
    this.generateInvoice = this.generateInvoice.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getDaysOfMonth = this.getDaysOfMonth.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.props.getUserInvoices(this.props.tab == 1 ? "true" : "false");
  }

  componentWillMount(): void {
    this.props.toggleSideBar(false);
    this.setState({ invoice: null, next: null, old: null });
    if (
      this.props.user.customer_type == "enterprise" ||
      this.props.user.customer_type == "basic" ||
      this.props.user.customer_type == "subsided_customer"
    ) {
      this.setState({
        canCreate: true,
        canDelete: true,
        canUpdate: true,
        canGenerate: true,
      });
    } else {
      this.props.user.permissions.forEach((element) => {
        if (element.resource == "purchases") {
          switch (element.action) {
            case "create":
              this.setState({ canCreate: true });
              break;
            case "edit":
              this.setState({ canUpdate: true });
              break;
            case "delete":
              this.setState({ canDelete: true });
              break;
            default:
              break;
          }
        }
      });
      this.props.user.permissions.forEach((element) => {
        if (element.resource == "invoices") {
          switch (element.action) {
            case "create":
              this.setState({ canGenerate: true });
              break;
            default:
              break;
          }
        }
      });
    }
  }

  renderConcept = () =>
    ["Paquete", "Recarga"].map((type, index) => (
      <Row key={index} className="card-dropdown-option">
        <Column xs={12} fluid>
          <Text>{type}</Text>
        </Column>
      </Row>
    ));

  renderDate = () =>
    ["La semana pasada", "El último mes", "Los últimos tres meses"].map(
      (type, index) => (
        <Row key={index} className="card-dropdown-option date-option">
          <Column xs={12} fluid>
            <Text>{type}</Text>
          </Column>
        </Row>
      )
    );

  getDaysOfMonth(date) {
    return new Date(date.getYear(), date.getMonth(), 0).getDate();
  }

  toggleDropdown(invoices) {
    let isInv;
    if (this.props.tab == 1) isInv = "true";
    else isInv = "false";
    if (this.state.dateFilter.order) {
      this.setState({
        dateFilter: {
          icon: "ion-android-arrow-dropup",
          order: false,
        },
      });
      this.props.getUserInvoices(isInv, "asc");
    } else {
      this.setState({
        dateFilter: {
          icon: "ion-android-arrow-dropdown",
          order: true,
        },
      });
      this.props.getUserInvoices(isInv, "desc");
    }
  }

  _goToInvoice = (invoiceId) => {
    const index = this.props.invoices.results.findIndex(
      (x) => x.object_id == invoiceId
    );
    this.setState({
      invoice: this.props.invoices.results[index],
      next: index + 1,
      old: index - 1,
    });
    if (this.props.invoices.results[index].type === "DEPOSIT") {
      this.props.toggleSideBar(true, `Depósito #${invoiceId}`, INVOICE_DETAILS);
    } else {
      this.props.toggleSideBar(true, `Compra #${invoiceId}`, INVOICE_DETAILS);
    }
  };

  _handleResendInvoice = (id, object_id) => {
    this.setState({
      invoiceCurrent: object_id,
    });
    this.downloadInvoice(id, object_id);
  };

  generateTable(invoices) {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    const currDate = new Date();
    return invoices.map((invoice, index) => {
      let iva;
      if (invoice.type) {
        if (invoice.type === "CLIENT_ADVANCE_PAYMENT") {
          iva = (invoice.amount * 0.16).toFixed(2);
        } else {
          iva = 0;
        }
      } else {
        iva = ((invoice.amount / 1.16) * 0.16).toFixed(2);
      }

      // const total = (invoice.amount - iva).toFixed(2);
      const total = parseFloat(invoice.amount).toFixed(2);
      const currency = invoice.currency || "";
      // invouce date
      const invoiceDate = new Date(invoice.created_at);
      // const diff = parseInt((currDate - iD) / (1000 * 60 * 60 * 24));
      const totalDaysOnMonth = this.getDaysOfMonth(invoiceDate);
      const today = new Date();
      const remainingDays = totalDaysOnMonth - today.getDate();
      // let canBeInvoiced = true;
      let tooltip = null;

      //If you want to deploy for specific date
      var dateFrom = "01/04/2021";
      var dateTo = "30/04/2021";
      var dateCheck = moment(invoiceDate).format("DD/MM/YYYY");

      var d1 = dateFrom.split("/");
      var d2 = dateTo.split("/");
      var c = dateCheck.split("/");

      var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]); // -1 because months are from 0 to 11
      var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
      var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

      if (invoice.invoice_id) {
        if (
          this.props.sending &&
          invoice.object_id == this.props.invoiceCurrent
        ) {
          tooltip = (
            <div style={{ textAlign: "center" }} className="label-item-loading">
              <LabelStyled>
                <div className="rotating">
                  <i className="ion-android-sync" />
                </div>
                <span>Enviando</span>
              </LabelStyled>
            </div>
          );
        } else if (
          this.props.timing &&
          invoice.object_id == this.props.invoiceCurrent
        ) {
          tooltip = (
            <div style={{ textAlign: "center" }} className="label-item-loading">
              <LabelStyled>
                <img height="12px" width="10px" src={checkIcon} />
                Factura enviada
              </LabelStyled>
            </div>
          );
        } else if (this.props.hasError == invoice.object_id) {
          tooltip = (
            <div style={{ textAlign: "center" }} className="label-item-loading">
              <LabelStyled>
                <img height="12px" width="10px" src={error_icon} />
                Error al enviar
              </LabelStyled>
            </div>
          );
        } else if (searchPermission("facturas", "editar", permissions)) {
          tooltip = (
            <Button
              style={{ margin: "0px" }}
              className="address-delete"
              type="link"
              onClick={() =>
                this._handleResendInvoice(invoice.invoice_id, invoice.object_id)
              }
            >
              Reenviar factura
            </Button>
          );
        }
      } else if (
        currDate - invoiceDate >
        1000 /* ms */ *
          60 /* s */ *
          60 /* min */ *
          24 /* h */ *
          30 /* days */ *
          1 /* months */
      ) {
        // canBeInvoiced = false;
        if (invoice.payment_provider !== "mienvio_wallet") {
          tooltip = (
            <div style={{ textAlign: "center" }} className="label-item">
              <LabelStyled
                data-tip
                data-for={`no-invoice${invoice.object_id}`}
                data-place="top"
              >
                <img height="12px" width="10px" src={calendar_disable} />
                Factura vencida
              </LabelStyled>
              <ReactTooltip id={`no-invoice${invoice.object_id}`} place="top">
                <span>Período de facturación para esta compra terminado</span>
              </ReactTooltip>
            </div>
          );
        }
      } else {
        // TODO: check the correct step to show this message
        if (invoice.payment_provider === "mienvio_wallet") {
          // if (searchPermission("facturas", "editar", permissions)) {
          //   tooltip = (
          //     <div style={{ textAlign: "center" }} className="label-item">
          //       <Button
          //         style={{ margin: "0px" }}
          //         className="address-delete"
          //         disabled={!this.props.user.fiscal_address}
          //         type="link"
          //         onClick={() => {
          //           window.open(
          //             "https://form.jotform.co/mienvio/facturacion",
          //             "_blank"
          //           );
          //         }}
          //       >
          //         Generar factura
          //       </Button>
          //     </div>
          //   );
          // }
        } else if (invoice.object_status == "PENDING") {
          tooltip = (
            <div style={{ textAlign: "center" }} className="label-item">
              <LabelStyled
                data-tip
                data-for={`no-invoice${invoice.object_id}`}
                data-place="top"
              >
                <img height="12px" width="10px" src={hourglas} />
                Generando orden
              </LabelStyled>
              <ReactTooltip id={`no-invoice${invoice.object_id}`} place="top">
                <span> Intenta facturar más tarde</span>
              </ReactTooltip>
            </div>
          );
        } else if (
          this.props.creating &&
          invoice.object_id == this.props.purchaseCurrent
        ) {
          tooltip = (
            <div style={{ textAlign: "center" }} className="label-item-loading">
              <LabelStyled>
                <div className="rotating">
                  <i className="ion-android-sync" />
                </div>
                <span>Generando</span>
              </LabelStyled>
            </div>
          );
        } else if (this.props.hasError == invoice.object_id) {
          tooltip = (
            <div style={{ textAlign: "center" }} className="label-item-loading">
              <LabelStyled>
                <img height="12px" width="10px" src={error_icon} />
                Error al generar
              </LabelStyled>
            </div>
          );
        } else if (invoice.type === "CLIENT_ADVANCE_PAYMENT") {
          if (searchPermission("facturas", "editar", permissions)) {
            tooltip = (
              <Button
                style={{ margin: "0px" }}
                className="address-delete"
                disabled={!this.props.user.fiscal_address}
                type="link"
                onClick={() => this.generateAddWalletInvoice(invoice.object_id)}
              >
                Generar factura
              </Button>
            );
          }
        } else if (searchPermission("facturas", "editar", permissions)) {
          tooltip = (
            <Button
              style={{ margin: "0px" }}
              className="address-delete"
              disabled={!this.props.user.fiscal_address}
              type="link"
              onClick={() => this.generateInvoice(invoice.object_id)}
            >
              Generar factura
            </Button>
          );
        }
      }
      if (this.state.selectedConcept.length > 0) {
        if (
          invoice.type === "CLIENT_ADVANCE_PAYMENT" &&
          this.state.selectedConcept.includes(1)
        ) {
          if (
            invoice.payment_provider !== "mienvio_credit" &&
            invoice.payment_provider !== "credit"
          ) {
            return (
              <Tr key={invoice.object_id} className="clickable-element">
                {/* <Td checkbox>
                  { canBeInvoiced && !this.state.invoiced ? (<CheckboxGroup
                    defaultChecked={this.state.selected}
                    type="table"
                    onChange={value => this.select(value)}
                  >
                    <Checkbox value={invoice.object_id.toString()} />
                  </CheckboxGroup>) : ''}
                </Td> */}
                <Td
                  onClick={() =>
                    invoice ? this._goToInvoice(invoice.object_id) : ""
                  }
                >
                  #{invoice.object_id}
                </Td>
                <Td
                  onClick={() =>
                    invoice ? this._goToInvoice(invoice.object_id) : ""
                  }
                >
                  <Moment
                    date={invoice.created_at}
                    local="es"
                    format="DD-MMM-YYYY"
                  />
                </Td>
                {invoice.type ? (
                  invoice.type === "CLIENT_ADVANCE_PAYMENT" ? (
                    <Td
                      onClick={() =>
                        invoice ? this._goToInvoice(invoice.object_id) : ""
                      }
                    >
                      {"Recarga"}
                    </Td>
                  ) : (
                    <Td
                      onClick={() =>
                        invoice ? this._goToInvoice(invoice.object_id) : ""
                      }
                    >
                      {"Deposito"}
                    </Td>
                  )
                ) : (
                  <Td
                    onClick={() =>
                      invoice ? this._goToInvoice(invoice.object_id) : ""
                    }
                  >
                    {"Paquete"}
                  </Td>
                )}

                <Td
                  onClick={() =>
                    invoice ? this._goToInvoice(invoice.object_id) : ""
                  }
                  noFormat
                >
                  {
                    invoice.payment_provider ? (
                      invoice.payment_provider === "stripe" ? (
                        <p>
                          <img
                            height="12px"
                            width="10px"
                            style={{ marginRight: "5px" }}
                            src={card_placeholder}
                          />
                          {invoice.last_4
                            ? invoice.last_4
                            : "Tarjeta de crédito"}
                        </p>
                      ) : invoice.payment_provider === "mienvio_wallet" ? (
                        <p>
                          <img height="12px" width="10px" src={wallet} /> Wallet
                        </p>
                      ) : invoice.payment_provider === "mienvio_credit" ? (
                        <p>
                          <img height="12px" width="10px" src={wallet} />{" "}
                          Credito
                        </p>
                      ) : (
                        <p>
                          <img height="12px" width="10px" src={paypal_img} />{" "}
                          Paypal
                        </p>
                      )
                    ) : (
                      <p>
                        <img height="12px" width="10px" src={wallet} /> Wallet
                      </p>
                    )

                    /* invoice.payment_provider === 'stripe' ? (
                    <p>
                      <img
                        height="12px"
                        width="10px"
                        style={{ marginRight: '5px' }}
                        src={card_placeholder}
                      />
                      {invoice.last_4 ? invoice.last_4 : 'Tarjeta de crédito'}
                    </p>
                  ) :
                    invoice.payment_provider=='Wallet' ? (
                      <p>
                        <img height="12px" width="10px" src={paypal_img} /> Wallet
                      </p>
                    ) :
                    (
                      <p>
                        <img height="12px" width="10px" src={paypal_img} /> Paypal
                      </p>
                    ) */
                  }
                </Td>

                <Td
                  onClick={() =>
                    invoice ? this._goToInvoice(invoice.object_id) : ""
                  }
                >
                  {`$${numberWithCommas(iva)} ${currency}`}
                </Td>
                <Td
                  onClick={() =>
                    invoice ? this._goToInvoice(invoice.object_id) : ""
                  }
                >
                  {`$${numberWithCommas(total)} ${currency}`}
                </Td>
                <Td>{tooltip}</Td>
              </Tr>
            );
          }
        }
      } else {
        if (
          invoice.payment_provider !== "mienvio_credit" &&
          invoice.payment_provider !== "credit"
        ) {
          return (
            <Tr key={invoice.object_id} className="clickable-element">
              {/* <Td checkbox>
                { canBeInvoiced && !this.state.invoiced ? (<CheckboxGroup
                  defaultChecked={this.state.selected}
                  type="table"
                  onChange={value => this.select(value)}
                >
                  <Checkbox value={invoice.object_id.toString()} />
                </CheckboxGroup>) : ''}
              </Td> */}
              <Td
                onClick={() =>
                  invoice ? this._goToInvoice(invoice.object_id) : ""
                }
              >
                #{invoice.object_id}
              </Td>
              <Td
                onClick={() =>
                  invoice ? this._goToInvoice(invoice.object_id) : ""
                }
              >
                <Moment
                  date={invoice.created_at}
                  local="es"
                  format="DD-MMM-YYYY"
                />
              </Td>
              {invoice.type ? (
                invoice.type === "CLIENT_ADVANCE_PAYMENT" ? (
                  <Td
                    onClick={() =>
                      invoice ? this._goToInvoice(invoice.object_id) : ""
                    }
                  >
                    {"Recarga"}
                  </Td>
                ) : (
                  <Td
                    onClick={() =>
                      invoice ? this._goToInvoice(invoice.object_id) : ""
                    }
                  >
                    {"Deposito"}
                  </Td>
                )
              ) : (
                <Td
                  onClick={() =>
                    invoice ? this._goToInvoice(invoice.object_id) : ""
                  }
                >
                  {"Paquete"}
                </Td>
              )}

              <Td
                onClick={() =>
                  invoice ? this._goToInvoice(invoice.object_id) : ""
                }
                noFormat
              >
                {
                  invoice.payment_provider ? (
                    invoice.payment_provider === "stripe" ? (
                      <p>
                        <img
                          height="12px"
                          width="10px"
                          style={{ marginRight: "5px" }}
                          src={card_placeholder}
                        />
                        {invoice.last_4 ? invoice.last_4 : "Tarjeta de crédito"}
                      </p>
                    ) : invoice.payment_provider === "mienvio_wallet" ? (
                      <p>
                        <img height="12px" width="10px" src={wallet} /> Wallet
                      </p>
                    ) : invoice.payment_provider === "mienvio_credit" ? (
                      <p>
                        <img height="12px" width="10px" src={wallet} /> Credito
                      </p>
                    ) : (
                      <p>
                        <img height="12px" width="10px" src={paypal_img} />{" "}
                        Paypal
                      </p>
                    )
                  ) : (
                    <p>
                      <img height="12px" width="10px" src={wallet} /> Wallet
                    </p>
                  )

                  /* invoice.payment_provider === 'stripe' ? (
                  <p>
                    <img
                      height="12px"
                      width="10px"
                      style={{ marginRight: '5px' }}
                      src={card_placeholder}
                    />
                    {invoice.last_4 ? invoice.last_4 : 'Tarjeta de crédito'}
                  </p>
                ) :
                  invoice.payment_provider=='Wallet' ? (
                    <p>
                      <img height="12px" width="10px" src={paypal_img} /> Wallet
                    </p>
                  ) :
                  (
                    <p>
                      <img height="12px" width="10px" src={paypal_img} /> Paypal
                    </p>
                  ) */
                }
              </Td>

              <Td
                onClick={() =>
                  invoice ? this._goToInvoice(invoice.object_id) : ""
                }
              >
                {`$${numberWithCommas(iva)} ${currency}`}
              </Td>
              <Td
                onClick={() =>
                  invoice ? this._goToInvoice(invoice.object_id) : ""
                }
              >
                {`$${numberWithCommas(total)} ${currency}`}
              </Td>
              <Td>{tooltip}</Td>
            </Tr>
          );
        }
      }
    });
  }

  getInvoices(isInvoiced) {
    if (isInvoiced == 0) {
      this.setState({
        invoiced: false,
        selected: [],
        filter: "",
        filters: [],
        concepts: [],
        selectedConcept: [],
        selectedDate: [],
        invoice: null,
        next: null,
        old: null,
      });
      this.props.getUserInvoices("false", "desc");
    } else if (isInvoiced == 1) {
      this.setState({
        invoiced: true,
        selected: [],
        filter: "",
        filters: [],
        concepts: [],
        selectedConcept: [],
        selectedDate: [],
        invoice: null,
        next: null,
        old: null,
      });
      this.props.getUserInvoices("true", "desc");
    }
  }

  selectAll(value) {
    if (value.length > 0) {
      const selected = [];
      this.props.invoices.results.forEach((invoice) => {
        selected.push(invoice.object_id.toString());
      });
      this.setState({ selected });
      return;
    }
    this.setState({ selected: [] });
  }

  select(value) {
    this.setState({ selected: value });
  }

  renderEmptyState() {
    return (
      <EmpyState name="invoices">
        <Title className="state-title">Todavía no generas facturas</Title>
        <Text>
          Parece que no has realizado ninguna compra todavía. Cuando compras
          guías o pagas por el envío de tus insumos, aquí van a estar tus
          facturas (por generar y las ya generadas) listas para descargar.
        </Text>
      </EmpyState>
    );
  }

  handleFilterChange(value) {
    this.setState({ filter: value });
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      const { filters } = this.state;
      filters.push(this.state.filter);
      this.setState({
        filters,
        filter: "",
      });
    }
  }

  onClose(index) {
    const { filters } = this.state;
    filters.splice(index, 1);
    this.setState({ filters });
  }

  async generateInvoice(purchaseId) {
    const data = {
      name: this.props.user.fiscal_address.name,
      email: this.props.user.email,
      phone: this.props.user.fiscal_address.phone,
      rfc: this.props.user.fiscal_address.rfc,
    };
    await this.props.createInvoice(data, purchaseId, this.props.user.email);
    this.setState({
      invoiced: true,
      selected: [],
      filter: "",
      filters: [],
      concepts: [],
      selectedConcept: [],
      selectedDate: [],
      invoice: null,
      next: null,
      old: null,
    });
  }

  async generateWalletInvoice(purchaseId) {
    const data = {
      name: this.props.user.fiscal_address.name,
      email: this.props.user.email,
      phone: this.props.user.fiscal_address.phone,
      rfc: this.props.user.fiscal_address.rfc,
    };
    await this.props.createWalletInvoice(
      data,
      purchaseId,
      this.props.user.email
    );
    this.setState({
      invoiced: true,
      selected: [],
      filter: "",
      filters: [],
      concepts: [],
      selectedConcept: [],
      selectedDate: [],
      invoice: null,
      next: null,
      old: null,
    });
  }

  async generateAddWalletInvoice(purchaseId) {
    const data = {
      name: this.props.user.fiscal_address.name,
      email: this.props.user.email,
      phone: this.props.user.fiscal_address.phone,
      rfc: this.props.user.fiscal_address.rfc,
    };
    await this.props.createAddWalletInvoice(
      data,
      purchaseId,
      this.props.user.email
    );
    this.setState({
      invoiced: true,
      selected: [],
      filter: "",
      filters: [],
      concepts: [],
      selectedConcept: [],
      selectedDate: [],
      invoice: null,
      next: null,
      old: null,
    });
  }

  downloadInvoice = async (invoice_id, object_id) => {
    await this.props.resendInvoice(
      invoice_id,
      object_id,
      this.props.user.email
    );
    this.setState({
      invoiceCurrent: null,
    });
  };

  handleConceptDrop = (selected) => {
    this.setState({ selectedConcept: selected });
  };

  handleDateDrop = (selected) => {
    this.setState({ selectedDate: selected });
  };

  renderTable() {
    const results = this.props.invoices.results.filter((invoice) => {
      let found = 0;
      // define iva and total
      const iva = ((invoice.amount / 1.16) * 0.16).toFixed(2);
      // let total = (invoice.amount - iva).toFixed(2);
      const total = parseFloat(invoice.amount).toFixed(2);
      let payment = "";

      if (invoice.payment_provider) {
        if (invoice.payment_provider === "stripe")
          payment = "Tarjeta de crédito";
        else payment = "Paypal";
      } else {
        payment = "Wallet";
      }

      if (this.state.filters.length === 0) return true;
      this.state.filters.forEach((filter) => {
        const re = new RegExp(filter, "i");
        if (
          re.test(invoice.object_id) ||
          re.test(
            this.state.invoiced ? invoice.invoiced_at : invoice.created_at
          ) ||
          re.test(iva) ||
          re.test(total) ||
          re.test(payment)
        ) {
          found += 1;
        }
      });
      return found === this.state.filters.length;
    });

    const filters = this.state.filters.map((filter, index) => (
      <Filter
        style={{ marginLeft: "0px" }}
        key={index}
        onClose={() => this.onClose(index)}
      >
        {filter}
      </Filter>
    ));

    const dateDropdown = (
      <span onClick={() => this.toggleDropdown(results)}>
        Fecha <I className={this.state.dateFilter.icon} />
      </span>
    );
    return (
      <div>
        <Text>Filtrar por</Text>
        <Row>
          <Column xs={12} md={4} fluid>
            <Input
              icon="ion-android-search"
              parentClassName="filter-input"
              type="text"
              value={this.state.filter}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleFilterChange}
              placeholder="Buscar palabra clave…"
            />
          </Column>
          <Column xs={1} fluid />
          <Column xs={12} md={3} fluid>
            <Dropdown
              listClassName="filter-drop"
              className="filter-dropdown"
              icon="ion-android-calendar"
              placeholder={
                <Text className="dropdown-placeholder dp-service">Fecha</Text>
              }
              options={this.renderDate()}
              handleChange={this.handleDateDrop}
              selected={this.state.selectedDate}
            />
          </Column>
          <Column xs={1} fluid />
          <Column xs={12} md={3} fluid>
            <Dropdown
              multiple
              listClassName="filter-drop"
              className="filter-dropdown"
              placeholder={
                <Text className="dropdown-placeholder dp-service">
                  Concepto
                </Text>
              }
              options={this.renderConcept()}
              handleChange={this.handleConceptDrop}
              selected={this.state.selectedConcept}
            />
          </Column>
          {/* <Column xs={2} xsShift={0.5} fluid>
            <Input icon="ion-ios-arrow-down" parentClassName="filter-input" type="text" value={this.state.filter}
              onKeyPress={this.handleKeyPress} onChange={this.handleFilterChange}
              placeholder="Fecha" />
          </Column>
          <Column xs={2} xsShift={0.5} fluid>
            <Dropdown multiple listClassName="filter-drop" className="filter-dropdown" placeholder={(
              <Text className="dropdown-placeholder">Fecha</Text>
            )} options={'asd'}
            />
          </Column>
          <Column xs={2} xsShift={0.5} fluid >
            <Input icon="ion-ios-arrow-down" parentClassName="filter-input" type="text" value={this.state.filter}
              onKeyPress={this.handleKeyPress} onChange={this.handleFilterChange}
              placeholder="Concepto" />
          </Column> */}
        </Row>
        {filters}
        <Table size="full">
          <thead>
            <Tr noBorder>
              {/* <Th checkbox>
                <Button type="table" className="checkbox-header-table">
                  <CheckboxGroup
                    onChange={value => {
                      this.selectAll(value);
                    }}
                    type="table"
                  >
                    <Checkbox value="all" />
                  </CheckboxGroup>
                </Button>
              </Th> */}
              <Th>
                {this.state.selected.length === 0 ? (
                  "ID de compra"
                ) : !this.state.invoiced && this.props.user.fiscal_address ? (
                  <Button
                    className="action-btn"
                    type="table"
                    onClick={() => this.generateInvoice()}
                  >
                    Facturar
                  </Button>
                ) : this.props.user.fiscal_address && this.state.invoiced ? (
                  <Button
                    className="action-btn"
                    type="table"
                    onClick={() => this.downloadInvoice()}
                  >
                    Descargar
                  </Button>
                ) : (
                  ""
                )}
              </Th>
              <Th>{this.state.selected.length === 0 ? dateDropdown : null}</Th>
              <Th>{this.state.selected.length === 0 ? "Concepto" : null}</Th>
              <Th>
                {this.state.selected.length === 0 ? "Método de Pago" : null}
              </Th>

              <Th>{this.state.selected.length === 0 ? "IVA 16%" : null}</Th>
              <Th>{this.state.selected.length === 0 ? "Total" : null}</Th>
            </Tr>
          </thead>
          <tbody>{results ? this.generateTable(results) : null}</tbody>
        </Table>
      </div>
    );
  }

  _changeInvoice = (index) => {
    if (index >= 0 && index < this.props.invoices.results.length) {
      this.props.changeSidebarTitle(
        `Orden #${this.props.invoices.results[index].object_id}`
      );
      this.setState({
        invoice: this.props.invoices.results[index],
        next: index + 1,
        old: index - 1,
      });
    }
  };

  renderDetailsTable = () =>
    this.state.invoice ? (
      this.state.invoice.shipments ? (
        this.state.invoice.shipments.map((invoice, index) => {
          const unitPrice = invoice.rate
            ? (invoice.rate.amount / 1.16).toFixed(2)
            : 0.0;
          return (
            <Tr key={invoice.object_id}>
              <Td>{index + 1}</Td>
              <Td>#{invoice.object_id}</Td>
              <Td noFormat>
                {invoice.rate
                  ? ` ${invoice.rate.provider} (${invoice.rate.duration_terms}) ${invoice.weight}-kg`
                  : "no info"}
              </Td>
              <Td>${invoice.rate.amount.toFixed(2)}</Td>
              <Td>
                {invoice.insurance && invoice.insurance.cost
                  ? `$${invoice.insurance.cost.toFixed(2)}`
                  : "No"}
              </Td>
              <Td>
                {invoice.insurance && invoice.insurance.cost
                  ? `$${(invoice.rate.amount + invoice.insurance.cost).toFixed(
                      2
                    )}`
                  : `$${invoice.rate.amount.toFixed(2)}`}
              </Td>
            </Tr>
          );
        })
      ) : (
        <div />
      )
    ) : (
      <div />
    );

  _renderButton = () => {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    let button = <div />;
    if (this.state.invoice) {
      const currDate = new Date();
      const invoiceDate = new Date(this.state.invoice.created_at);
      if (this.state.invoice.invoice_id) {
        button = (
          <Button
            loading={this.props.sending}
            className="edit-btn-left more-btn"
            onClick={() => {
              this._handleResendInvoice(
                this.state.invoice.invoice_id,
                this.state.invoice.object_id
              );
            }}
          >
            {`Reenviar a ${this.props.user.email}`}
          </Button>
        );
      } else if (
        currDate - invoiceDate >
        1000 /* ms */ *
          60 /* s */ *
          60 /* min */ *
          24 /* h */ *
          30 /* days */ *
          1 /* months */
      ) {
        if (this.state.invoice.payment_provider !== "mienvio_wallet") {
          button = (
            <div
              style={{ textAlign: "right", marginTop: "7px" }}
              className="label-item"
            >
              <LabelStyledDetail
                data-tip
                data-for={`no-invoice-det${this.state.invoice.object_id}`}
                data-place="top"
              >
                <img height="16px" width="16px" src={calendar_disable} />
                Factura vencida
              </LabelStyledDetail>
              <ReactTooltip
                id={`no-invoice-det${this.state.invoice.object_id}`}
                place="top"
              >
                <span>Período de facturación para esta compra terminado</span>
              </ReactTooltip>
            </div>
          );
        }
      } else if (this.props.creating) {
        button = (
          <div
            style={{ textAlign: "right", marginTop: "7px" }}
            className="label-item-loading"
          >
            <LabelStyledDetail>
              <div className="rotating">
                <i className="ion-android-sync" />
              </div>
              <span>Generando</span>
            </LabelStyledDetail>
          </div>
        );
      } else if (this.state.canGenerate) {
        if (searchPermission("facturas", "editar", permissions)) {
          button = (
            <Button
              className="edit-btn-left more-btn"
              onClick={() =>
                this.state.invoice.type === "CLIENT_ADVANCE_PAYMENT"
                  ? this.generateAddWalletInvoice(this.state.invoice.object_id)
                  : this.generateInvoice(this.state.invoice.object_id)
              }
            >
              Generar factura
            </Button>
          );
        }
      }
    }
    return button;
  };

  _renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
      case INVOICE_DETAILS:
        return (
          <ContentAdapter>
            <InvoiceDetail
              user={this.props.user}
              invoice={this.state.invoice}
              renderTable={this.renderDetailsTable}
            />
            <BottomContainer>
              <Row>
                <Column xs={6} style={{ textAlign: "right" }} fluid>
                  {this._renderButton()}
                </Column>
              </Row>
            </BottomContainer>
          </ContentAdapter>
        );
      default:
        return <div />;
    }
  };

  _renderSidebarExtraContent = () => {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    let linksToRender = [];

    if (this.state.invoice) {
      if (this.state.invoice.type === "DEPOSIT") {
        return <div />;
      }
      return (
        <SidebarTitleMenu
          linksToRender={linksToRender}
          nextButton={{
            trigger: () => {
              this._changeInvoice(this.state.old);
            },
          }}
          prevButton={{
            trigger: () => {
              this._changeInvoice(this.state.next);
            },
          }}
        />
      );
    }
  };

  render() {
    let messageUser = "";
    if (this.props.user !== null && !this.props.user.fiscal_address) {
      messageUser = (
        <Message color="info" onClose={this.props.hideMessage}>
          Para poder generar facturas es necesario agregar tu información fiscal
          en la sección de Configuración.
        </Message>
      );
    } else {
      messageUser = "";
    }

    if (
      this.props.message.show &&
      (this.props.message.messageType === "error" ||
        this.props.message.messageType === "success")
    ) {
      setTimeout(this.props.hideMessage, 5000);
    }
    return (
      <div>
        <WrapperSideBar
          icon={locationIcon}
          title_more={this._renderSidebarExtraContent()}
          isBigBox
        >
          {this._renderSidebarContent()}
        </WrapperSideBar>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Header>
              <Row divisions={18}>
                <Column xs={6} fluid>
                  <Title type="titleCard">Facturas</Title>
                </Column>
              </Row>
            </Header>
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            {messageUser}
            {this.props.message.show ? (
              <Message
                color={this.props.message.messageType}
                onClose={this.props.hideMessage}
              >
                {this.props.message.text}
              </Message>
            ) : null}
            <Tabs
              id="invoices"
              right
              selected={this.props.tab}
              onChange={(selected) => this.getInvoices(selected)}
              invoiceList
            >
              <Tab>Sin generar</Tab>
              <Tab>Generadas</Tab>
            </Tabs>
            <br />
            {!this.props.invoices.results ||
            this.props.invoices.results.length === 0
              ? this.renderEmptyState()
              : this.renderTable()}
          </Column>
          <Column xs={1} fluid />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoices: state.invoices.invoices,
  user: state.user,
  message: state.message,
  tab: state.invoices.tab,
  loading: state.invoices.loading,
  sending: state.invoices.sending,
  timing: state.invoices.timing,
  creating: state.invoices.creating,
  hasError: state.invoices.error,
  invoiceCurrent: state.invoices.invoiceCurrent,
  purchaseCurrent: state.invoices.purchaseCurrent,
  sidebar: state.sidebar,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideMessage,
      getUserInvoices,
      createInvoice,
      createInvoices,
      createWalletInvoice,
      createAddWalletInvoice,
      resendInvoice,
      showMessage,
      toggleSideBar,
      changeSidebarTitle,
      goToInvoice: (id) => push(`/facturas/${id}`),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
