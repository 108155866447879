import React from 'react';
import axios from 'axios';
import Service from './Service';
import { AXIOS_BASEURL } from '../../../constants/index';
import './style.css';

class Time extends React.Component {
  state = {
    data: [],
  }

  componentDidMount() {
    this.getServices();
  }

  getServices = () => {
    axios.get('api/services_providers_sla')
      .then((response) => {
        response.data.shift();
        this.setState({ data: response.data });
      })
      .catch(error => console.log(error));
  };

  renderCarriers = data => data.map(element => (
    <div className="provider">
      <img src={`${AXIOS_BASEURL}${element.image_url}`} />
      <div className="services-content">
        <p>Servicios:</p>
        {element.services.map(e => (
          <Service provider_id={e.id} duration_terms={e.duration_terms} level={e.level} currency={e.currency} />

        ))}
      </div>


    </div>
  ))

  render() {
    return (
      <div>
        <p className="title">SLA - Paqueterias</p>
        {this.renderCarriers(this.state.data)}
      </div>
    );
  }
}
export default Time;
