import React from 'react';
import axios from 'axios';
import { boundMethod } from 'autobind-decorator';
import '../style.css';

export default class Service extends React.Component {
    state = {
      disabled: true,
      value: '',
    }

    componentDidMount() {
      this.setState({ value: this.props.duration_terms });
    }

    @boundMethod
    handleClick() {
      const { disabled, value } = this.state;
      const { provider_id } = this.props;
      if (!disabled) {
        axios.put(`api/services/${provider_id}`, { duration_terms: value })
          .then((response) => {
          })
          .catch((error) => {});
      }
      this.setState({ disabled: !disabled });
    }

    getCountryFromCurrency = (currency) => {
      switch(currency) {
        case 'MXN':
          return 'México'
        case 'CRC':
          return 'Costa Rica'
        case 'CLP':
          return 'Chile'
        case 'Q':
          return 'Guatemala'
        case 'PEN':
          return 'Perú'
        case 'COP':
          return 'Colombia'
        case 'USD':
          return 'El Salvador'
        default:
          return ''
      }
    }


    render() {
      return (
        <div className="service-container">
          <p ser>{this.props.level} {this.getCountryFromCurrency(this.props.currency)}</p>
          <div className="content">
            <p>Tiempo duración de Entrega</p>
            <input onChange={(value) => { this.setState({ value: value.target.value }); }} value={this.state.value} disabled={!!this.state.disabled} className={`${this.state.disabled && 'disabled'}`} />
            <p className="btn" onClick={() => this.handleClick()}>{this.state.disabled ? 'Editar' : 'Guardar'}</p>
          </div>
        </div>
      );
    }
}
