// @flow
import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Redirect } from 'react-router-dom';
import { boundMethod } from 'autobind-decorator';
import Cookies from 'universal-cookie';
import { Row, Column } from 'hedron';
import valid from 'card-validator';
import Card from '../../components/Card';
import { ToastContainer, toast } from 'react-toastify';
import { login, getUrlParameter } from '../../modules/user';
import { hideMessage } from '../../modules/message';
import { hideLoader } from '../../modules/loader';
import Text from '../Text';
import Button from '../Button';
import Input from '../Input';
import Logo from '../static/Logo';
import Message from '../Message';
import styles from './index.module.scss';
import { Checkbox, CheckboxGroup } from '../../components/Checkbox';
import { cancelPlan, createPlan } from '../../modules/cards';

import type { MessageType } from '../../constants/customTypes';


type State = {
  email: string,
  emailError: string,
  password: string,
  passwordError: string,
};




class SelectPlan extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    message: {
      messageType: '',
      show: false,
      text: '',
    },
  };

   constructor(props) {
     super(props);
     this.renderPlans = this.renderPlans.bind(this);
   }




  state: State = {
      number: '',
      numberError: '',
      expiration: '',
      expirationError: '',
      name: '',
      nameError: '',
      cvc: '',
      cvcError: '',
      plan: 'hola',
      plans: null,
      loading: false,
    };

  componentDidMount() {

    const { action } = this.props.match.params;
    axios.get("/api/plans").then((r) => {
      if (r.status == 200) {
        this.setState({ plans: r.data });
      }
    });

    document.getElementsByClassName('overflow-content')[0].style.backgroundColor = 'white';



     this.setState({
      action: action,
      email: this.props.user.email,
      plan_type: (this.props.user.plan) ? this.props.user.plan.name : null,
    });
  }


  capitalizeFirstLetter(string) {
    if(string){
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return false;
  }



  isLoggedIn() {
    if (localStorage.getItem('token')) {
      const token = `Bearer ${localStorage.getItem('token')}`;
      axios.defaults.headers.common.Authorization = token;
      return true;
    }
    return false;
  }


  checkPlan(name,  plan_id){

    const email = this.props.user.email;
    const action = this.state.action;


    if(name == "Free"){
      this.setState({loading: true})
      if(action === 'edit') {
        this.props.cancelPlan()
        .then((res) => this.setState({loading:false}))
      } else {
        this.props.createPlan('', plan_id, false, email, action)
        .then((res) => this.setState({loading:false}))
      }
    } else {
        this.props.goToPayment(plan_id, action);
    }




  }

  renderPlans() {
   if(this.state.plans){
    this.state.plans.sort((a,b) => a.price - b.price);

    const plan_name = (this.state.plan_type)? this.state.plan_type : '' ;
    const type = this.capitalizeFirstLetter(plan_name);

    const planInformation = {
      0: {
          'information': 'Ahorra tiempo en la gestión de tus envíos y haz crecer tu negocio.',
          'processing': 'Procesamientos ilimitados',
      },
      1: {
        'information': '¡Da el gran salto! Comienza a optimizar tus costos operativos.',
        'processing': '150 procesamientos / mes',
      },
      2: {
        'information': 'Mejora la experiencia de tus usuarios y aumenta tus ventas.',
        'processing': '300 procesamientos / mes',
      },
      3: {
        'information': 'Combina tu propio contrato de paquetería con nuestra plataforma.',
        'processing': '1000 procesamientos / mes',
      },
      4: {
        'information': 'Empodera tu operación con todo tu equipo y nuestros expertos.',
        'processing': '5000 procesamientos / mes',
      }
    }

    return this.state.plans.map((plan, id)=> {
      return (
          <div key={id} className={styles.containerInputs}  style={{ textAlign: 'center'}}>
            <Card size="full" className={(id === 2 ? styles.cardWhite : styles.cardNormal) + " " + styles.card}>
              <div>
                <p className={(id === 2 ? styles.pink : styles.blue) + " " + styles.titlePlan}>{plan.name}</p>
                <p className={styles.price} style={{ color: 'black', marginLeft: 'unset', marginTop: '5px'}}>
                  {plan.price === '0' ? 'GRATIS' : "$ " + plan.price}
                  {plan.price === '0' ? null : <span className={styles.perMonth}>/mes</span>}
                </p>
                <p className={styles.currency}>Monto en {plan.currency}</p>
              </div>
              <div className={styles.information}>
                <p>{planInformation[id].information}</p>
              </div>
              <div className={styles.processing}>
                <div className={styles.grey}>
                  <p>{planInformation[id].processing}</p>
                </div>
                {plan.price === '0' ? (<span className={styles.extra}>Costo de envio 10% más caro</span>) : null}
              </div>
              <div className={styles.buttonContainer}>
                {
                  type == this.capitalizeFirstLetter(plan.name) && this.state.action != "new"   ?
                  (
                  <p className="title" style={{ color: 'black', marginLeft: 'unset', marginTop: '23px'}}>
                    Plan Actual
                  </p>
                  )
                  :
                  (
                  <Button
                  loading={id == 0 ? this.state.loading : null}
                  style={{backgroundColor: '#5D4FEB', width: '80%', margin: '0'}}
                  onClick={() => this.checkPlan(plan.name, plan.id)}
                  disabled={this.state.plan_type == plan.name ? true : false}
                  >
                    Obtener plan
                  </Button>
                  )
                }
              </div>
            </Card>
          </div>
       );
     });
   }
 }


  planFree() {
     window.location = '/verify';
  }


  isLoggedIn() {
    if (localStorage.getItem('token')) {
      const token = `Bearer ${localStorage.getItem('token')}`;
      axios.defaults.headers.common.Authorization = token;
      return true;
    }
    return false;
  }



    render() {
      if (!this.isLoggedIn()) {
        return <Redirect to="/login" />;
      }
      if (
        this.props.message.show
        && (this.props.message.messageType === 'error' || this.props.message.messageType === 'success')
      ) {
        setTimeout(this.props.hideMessage, 5000);
      }


      return (
        <div className={styles.plansContainer}>
          <Logo/>
          <Row divisions={20}>
            <Column xs={1} fluid />
              <Column xs={18} fluid>
              {this.props.message.show ? (
                <Message color={this.props.message.messageType} onClose={this.props.hideMessage}>{this.props.message.text}</Message>
              ) : null}
              </Column>
            <Column xs={1} fluid />
          </Row>
          <div className={styles.mainContainer}>
            <div>
              <p className={styles.title}>Selecciona tu Plan</p>
            </div>
            <Row className={styles.row}>
                {this.renderPlans()}
            </Row>
            <a href="https://www.mienvio.mx/comparativo-planes.html" className="link">
              <div className={styles.buttonComparison}>
                  Ver comparativo de planes
              </div>
              </a>
          </div>
          <p className={styles.iva}>El precio de nuestros planes no incluye IVA, se añadirá al procesar el pago de tu suscripción</p>
        </div>
      );
    }
}

const mapStateToProps = state => ({
  message: state.message,
  user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    goToPayment: (plan, action) => push(`/payment-plan/${plan}/false/${action}`),
    goToVerify: (mail, action) => push(`/verify`,  {mail: mail, action: action}),
    hideMessage,
    hideLoader,
    cancelPlan,
    createPlan,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectPlan);
