// @flow
import axios from 'axios';
import type { Dispatch as ReduxDispatch } from 'redux';

import { allServices } from './routes';

const getAllServices = (onSuccess: Function, onError: Function = () => {}) => (
  axios
    .get(allServices)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    })
);

export {
  getAllServices,
};
