import React from 'react';

import eye from '../../../../utils/icons/eye.svg';

import './style.css';


const Region = ({
  name,
}) => (
  <div className="account-region-container-zone">
    - {name}
  </div>
);
export default Region;
