import { validEmail } from "./global_functions";

const px2rem = (px) => `${px / 16}rem`;

const colors = {
  main: "#fe027c",
  gray: "#4a4a4a",
  grayHelper: "#F2F2F2",
  grayText: "#525252",
  lightGray: "#D5D4D4",
  placeholder: "#9B9B9B",
  blue: "#4b90e2",
  red: "#f7263f",
  green: "#7bbb5e",
};

const styles = {
  progress: {
    primary: "#BAB8B8",
    secondary: colors.main,
  },
  steps: {
    size: {
      primary: "30px",
      secondary: "40px",
    },
    margin: {
      primary: "5px",
      secondary: "0px",
    },
  },
  rate: {
    border: {
      primary: "transparent",
      selected: colors.green,
    },
  },
  radio: {
    background: {
      unselected: "#fff",
      selected: colors.main,
    },
    size: {
      large: "30px",
      small: "20px",
    },
    font: {
      large: "22px",
      small: "18px",
    },
    margin: {
      large: "-7px",
      small: "-3px",
    },
  },
  tip: {
    status: {
      normal: colors.placeholder,
      error: colors.red,
    },
  },
};

const validations = {
  specialChars: function (str) {
    return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
  },
  numbers: function (str) {
    return !/\d/g.test(str);
  },
  noSpecialOrNumbers: function (str) {
    return (
      !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str) && !/\d/g.test(str)
    );
  },
  url: function (str) {
    return /^((https|http)(:\/\/))?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(
      str
    );
  },
};

const errorMessages = {
  required: "Favor de llenar campo",
  specialOrNumbers: "No se permiten números ni caracteres especiales",
  email: "Correo electrónico inválido",
  maxLength: "Máximo 35 caracteres",
  phone: "Número de teléfono inválido",
  url: "URL inválido",
  business: "No se permiten caracteres especiales",
};

const re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validate = (kind, value) => {
  if (kind === "email") {
    return re.test(value);
  } else if (
    kind === "name" ||
    kind === "references" ||
    kind === "description" ||
    kind === "packageCost"
  ) {
    // empty
    return value !== "";
  } else if (kind == "address" || kind == "suburb") {
    return value !== "" && value.length < 36;
  } else if (kind == "phone") {
    return value.length === 10;
  }
};

const errors = (kind, value) => {
  if (kind == "name")
    return value === ""
      ? errorMessages.required
      : validations.noSpecialOrNumbers(value)
      ? ""
      : errorMessages.specialOrNumbers;
  if (kind == "email")
    return validEmail(value)
      ? ""
      : value === ""
      ? errorMessages.required
      : errorMessages.email;
  if (kind == "address" || kind == "suburb")
    return value === ""
      ? errorMessages.required
      : value.length < 36
      ? ""
      : errorMessages.maxLength;
  if (kind == "phone")
    return value === ""
      ? errorMessages.required
      : value.length >= 10
      ? ""
      : errorMessages.phone;
  if (kind == "references" || kind == "description" || kind == "packageCost")
    return value === "" ? errorMessages.required : "";
  if (kind == "password")
    return value === ""
      ? errorMessages.required
      : value.length <= 5
      ? "Al menos 6 carácteres."
      : "";
  if (kind == "url")
    return value === ""
      ? errorMessages.required
      : validations.url(value)
      ? ""
      : errorMessages.url;
  if (kind == "business")
    return value === ""
      ? errorMessages.required
      : validations.specialChars(value)
      ? ""
      : errorMessages.business;
};

export { px2rem, colors, styles, validate, errors };
