// @flow
import { initialState } from './initialState';
import { State, Action } from './types';
import {
  LOAD_INVOICE_INFORMATION,
  SET_USER_INVOICES,
  CHANGE_TAB,
  IS_LOADING_INVOICE,
  IS_SENDING_INVOICE,
  IS_TIMING_INVOICE,
  IS_CREATING_INVOICE,
  HAS_ERROR_INVOICE
} from './actionsTypes';

export default (state: State = initialState, action) => {
  switch (action.type) {
    case LOAD_INVOICE_INFORMATION:
      return {
        ...state,
        purchase: action.payload.purchase
      };
    case SET_USER_INVOICES:
      return {
        ...state,
        invoices: action.invoices
      };
    case CHANGE_TAB:
      return {
        ...state,
        tab: action.tab
      };
    case IS_LOADING_INVOICE:
      return {
        ...state,
        loading: action.loading
      };
    case IS_SENDING_INVOICE:
      return {
        ...state,
        sending: action.sending,
        invoiceCurrent: action.sending ? action.id : state.invoiceCurrent
      };
    case IS_TIMING_INVOICE:
      return {
        ...state,
        timing: action.timing,
        invoiceCurrent: action.timing ? state.invoiceCurrent : null
      };
    case IS_CREATING_INVOICE:
      return {
        ...state,
        creating: action.creating,
        purchaseCurrent: action.creating ? action.id : null
      };
    case HAS_ERROR_INVOICE:
      return {
        ...state,
        error: action.id
      };
    default:
      return state;
  }
};
