import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  createShipment,
  getAllUserShipments,
  searchAndAddShipment,
} from "../../modules/shipments/actions";
import { getUserAllPackages } from "../../modules/packages";
import { savePrimaryAddress } from "../../modules/addresses/actions";
import { createRates } from "../../modules/rates";
import { showMessage, hideMessage } from "../../modules/message";
import { showLoader, hideLoader } from "../../modules/loader";
import { setCreatedType } from "../../modules/integrations";

import Shipments from "./ShipmentsRender";

const mapStateToProps = (state) => ({
  shipments: state.shipments,
  addresses: state.addresses,
  rates: state.rates,
  message: state.message,
  user: state.user,
  packages: state.packages,
  createdType: state.integrations.createdType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToShipmentsList: () => push("/envios"),
      goToPayment: () => push("/checkout"),
      goToCreateShipment: () => push("/envios/crear"),
      goToShipmentSetUp: () => push("/perfil/ajustes-envios"),
      createRates,
      savePrimaryAddress,
      createShipment,
      hideMessage,
      showMessage,
      getAllUserShipments,
      searchAndAddShipment,
      showLoader,
      setCreatedType,
      getUserAllPackages,
      hideLoader,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Shipments);
