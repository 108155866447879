import React from "react";
import Button from "../../Button";
import { ConfigLink } from "../../ShipmentsList/index";
import Text from "../../Text";
import "../ThreeDotsDropdown/style.css";
import Arrow from "./downArrowBlack.svg";
import styled from "styled-components";

const DropdownContainer = styled.div`
  position: relative;
  float: left;
  background: #fff;
`;

const DropdownConfig = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border-radius: 3px;

  cursor: pointer;
  z-index: 10;
  width: max-content;
  height: 0;
  overflow: hidden;

  &.open {
    height: auto;
    overflow: visible;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
      0 0 12px 7px rgba(208, 208, 208, 0.48);
  }

  &.dropdown-tabs {
    position: absolute;
    top: calc(100% - 20px);
    right: 0;
    left: auto;

    > div {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
`;

class SimpleDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.close) {
      this.setState({ open: false });
    }
  }

  toggleOpen() {
    this.setState({ open: !this.state.open });
  }

  render() {
    return (
      <DropdownContainer className="stm-more-parent-container">
        <div
          onClick={() => this.toggleOpen()}
          className="simple-dropdown-container"
        >
          <p>
            {this.props.value ? this.props.value.label : this.props.placeholder}
          </p>
          <img src={Arrow} alt="" />
        </div>
        <DropdownConfig
          className={`dd-stm-opts ${this.state.open ? "open" : ""}`}
        >
          {this.props.linksToRender.map((element, index) => (
            <ConfigLink
              key={index}
              onClick={() => {
                this.setState({ open: false });
                this.props.onChange(element);
              }}
            >
              <a
                href={element.link ? element.link : "javascript:void(0)"}
                target={element.link ? "_blank" : "_self"}
              >
                <Text type="textTable" style={{ verticalAlign: "middle" }}>
                  {element.label}
                  {element.icon && <i className={element.icon} />}
                  {element.image && <img src={element.image} />}
                </Text>
              </a>
            </ConfigLink>
          ))}
        </DropdownConfig>
      </DropdownContainer>
    );
  }
}
export default SimpleDropdown;
