import {
  INIT_CSV_STEPS,
  SET_STEP_CSV,
  SET_ICON_CSV,
  PREVIOUS_STEP_CSV,
  NEXT_STEP_CSV,
  FILL_DATA_CSV,
  SET_STEP_SEND_DATA,
} from "./actionsTypes";
import axios from "axios";

const shipmentsUrl = "/api/shipments/multiple";

export const initSteps = () => ({
  type: INIT_CSV_STEPS,
});

export const setStepCSV = (step: number) => ({ type: SET_STEP_CSV, step });

export const setIconCSV = (icon: string, step: number = null) => ({
  type: SET_ICON_CSV,
  payload: {
    icon,
    step,
  },
});

export const fillData = (data) => ({
  type: FILL_DATA_CSV,
  payload: data,
});

export const nextStepCSV = () => ({ type: NEXT_STEP_CSV });

export const previousStepCSV = () => ({ type: PREVIOUS_STEP_CSV });

export const createRatesCSV =
  (
    orders: Array,
    finishCallback = (a) => {
      console.log(a);
    }
  ) =>
  (dispatch: Dispatch) => {
    console.log(orders);
    const total = orders.length - 1;

    let shortOrders = [];
    let postion = 0; //=> 19

    while (postion <= total) {
      shortOrders.push(orders.slice(postion, postion + 20));

      postion += 20;
    }

    let x = 0;

    let csvResponse = {
      count_errors: 0,
      count_success: 0,
      errors: [],
    };

    const proccress = (i) => {
      console.log("i", i);
      if (i < shortOrders.length) {
        dispatch({
          type: SET_STEP_SEND_DATA,
          countOrders: 20 * (i + 1),
          maxOrders: orders.length,
        });

        proccessGuides(shortOrders[i])
          .then((res) => {
            csvResponse.count_errors += res.count_errors;
            csvResponse.count_success += res.count_success;
            csvResponse.errors = [...csvResponse.errors, ...res.errors];
          })
          .then(() => {
            x++;
          })
          .then(() => {
            proccress(x);
          });
      } else {
        fillData(csvResponse);
        finishCallback(csvResponse);
      }
    };

    return proccress(x);
  };

const proccessGuides = (orders) => {
  return axios
    .post(shipmentsUrl, { orders })
    .then((response) => response.data)
    .catch(() => {
      return {
        count_errors: 0,
        count_success: 0,
        errors: [],
      };
    });
};

function later(orders) {
  return new Promise(function (resolve) {
    setTimeout(
      resolve({
        success: true,
        created: orders.length,
      }),
      20000
    );
  });
}
