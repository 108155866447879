const colors = {
  primary: {
    mienvio_blue: "#28285a",
    mienvio_pink: "#EB2C88",
    whitesmoke: "#F3F3F3",
  },
  secondary: {
    white: "#FFFFFF",
    gray: "#ffffff",
    dark_pink: "#CC2676",
    royal_blue: "#2576DA",
    dark_blue: "#141433",
    medium_blue: "#2576DA",
    light_gray: "#F7F7F7",
    medium_gray: "#E3E3E3",
    cobalt: "#1285CC",
    ultralight_blue: "#f8f8fc",
  },
  state: {
    light_pink: "#FFF4F9",
    silver: "#D8D8D8",
    blue: "#2576DA",
    ambar: "#F5CD23",
    green: "#7ED321",
    spring_green: "#68DD88",
    cherry: "#D0021B",
    tomato: "#CC162D",
    blue_vapor: "#C9D4E0",
    yellow_orange: "#FCBB3D",
    valid_green: "#00BC71",
  },
  gray: {
    dropdown_arrow: "#3E3E3E",
    snow: "#F2F2F2",
    creamy_gray: "#D8D5D5",
    sky_gray: "#DEDEDE",
    pigeon_gray: "#b3b3b3",
    thunder_sky: "#969696",
    earth_stone: "#979797",
    shadow: "#767676",
    black_smoke: "#696969",
    charcoal: "#4A4A4A",
    solid_black: "#000000",
    disabled: "#ededed",
    placeholder: "#BCC2C7",
    table_button: "#ECEFF4",
    input_icon: "#778092",
  },
  marketplaces: {
    shopify: "#95be46",
    magento: "#ff5700",
    prestashop: "#2c0a47",
    woocommerce: "#a55692",
    epages: "#C50000",
    tiendanube: "#2c3357",
  },
  newcolors: {
    light_gray: "#CAD2DF",
    input: "#778092",
    mini_title: "#858D9D",
    micro_header: "#BCC2C7",
    micro_description: "#848484",
  },
};

export default colors;
