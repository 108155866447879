// @flow
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import { push } from "react-router-redux";
import { Redirect } from "react-router-dom";
import { errors } from "../../utils/helpers";

import { signUp } from "../../modules/user";
import { hideMessage } from "../../modules/message";

import { formatPhone, validEmail } from "../../utils/global_functions";

import Text from "../Text";
import Button from "../Button";
import Logo from "../static/Logo";
import Input from "../Input";
import Dropdown from "../Dropdown";
import Message from "../Message";
import sliceGreen from "./img/green-slice.svg";
import slicePink from "./img/slice-pink.png";
import logo from "./img/mienvio-light.svg";

import "./index.css";
import LoginContainer, { Line } from "../Login/container";

type DefaultProps = {
  message: {
    messageType: string,
    show: boolean,
    text: string,
  },
};

export type Props = {
  ...DefaultProps,
  hideMessage: () => void,
  gotLogIn: () => void,
  signUp: (
    firstName: string,
    lastName: string,
    businessName: string,
    email: string,
    phone: string,
    referredCode: string,
    url?: string,
    password: string,
    referred_from: string,
    google_auth_id: string,
    positionId: number,
    shipmentsId: number,
    challengeId: number
  ) => void,
};

type State = {
  firstName: string,
  lastName: string,
  businessName: string,
  email: string,
  phone: string,
  url: string,
  password: string,
  firstNameError: string,
  lastNameError: string,
  businessNameError: string,
  referredCodeError: string,
  emailError: string,
  phoneError: string,
  passwordError: string,
  urlError: string,
  positionId: number,
  shipmentsId: number,
  challengeId: number,
};

const challengeOptions = [
  "Automatizar tareas",
  "Abrir nuevos mercados",
  "Centralizar mi logística en una sola plataforma",
  "Mejorar nuestros costos operativos",
  "Integrar múltiples paqueterías",
  "Mejorar el tracking de mis envíos",
  "Tercerizar resolución de incidencias (Paquetes atrasados o perdidos)",
];

const positionOptions = [
  "Compras",
  "Desarrollador o Implementador de Software",
  "Desarrollador de Negocio",
  "Dueño / Director",
  "Ejecutivo Comercial",
  "Gerente de Innovación",
  "Generte de Operación",
  "Gerente de Tecnología",
  "Otro",
];

const shipmentsOptions = [
  "0 - 10",
  "11 - 50",
  "51 - 100",
  "101 - 300",
  "301 - 500",
  "501 - 1000",
  "Mas de 1000",
];

class SignUp extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    message: {
      messageType: "",
      show: false,
      text: "",
    },
  };

  state: State = {
    firstName: "",
    lastName: "",
    businessName: "",
    email: "",
    phone: "",
    url: "",
    password: "",
    referredCode: "",
    referredCodeError: "",
    firstNameError: "",
    lastNameError: "",
    businessNameError: "",
    emailError: "",
    phoneError: "",
    passwordError: "",
    urlError: "",
    googleId: null,
    register_source: "none",
    positionId: 0,
    shipmentsId: 0,
    challengeId: 0,
  };

  constructor(props) {
    super(props);
    this.register = this.register.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.gtag = this.gtag.bind(this);
    this.gtag_report_conversion = this.gtag_report_conversion.bind(this);
  }

  componentDidMount() {
    const source = location.search.slice(1).split("&")[0].split("=")[1];
    if (source) {
      this.setState({ register_source: source });
    }
    const script = document.createElement("script");

    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-827928603";
    script.async = true;

    document.body.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    this.gtag("js", new Date());

    this.gtag("config", "AW-827928603");
    if (this.props.location.state) {
      const { email, name, lastname, googleId } = this.props.location.state;

      this.setState({
        firstName: name,
        lastName: lastname,
        email,
        googleId,
      });
    }
  }

  gtag() {
    window.dataLayer.push(arguments);
  }

  gtag_report_conversion() {
    this.gtag("event", "conversion", {
      send_to: "AW-827928603/7zzsCJuo5KEBEJvg5IoD",
    });
    return false;
  }

  handleFirstName(firstName) {
    this.setState({
      firstName,
      firstNameError: errors("name", firstName),
    });
  }

  handleLastName(lastName) {
    this.setState({
      lastName,
      lastNameError: errors("name", lastName),
    });
  }

  handleBusinessName(businessName) {
    this.setState({
      businessName,
      businessNameError: errors("business", businessName),
    });
  }

  handleEmail = (email) => {
    this.setState({
      email,
      emailError: errors("email", email),
    });
  };

  handleURL(url) {
    this.setState({
      url,
      urlError: errors("url", url),
    });
  }

  handlePassword(password) {
    this.setState({
      password,
      passwordError: errors("password", password),
    });
  }

  handleKeyPress(event) {
    if (event.key === "Enter" && this.isUserDataComplete()) {
      this.register();
    }
  }

  isLoggedIn() {
    return !!localStorage.getItem("token");
  }

  isUserDataComplete() {
    const { firstName, lastName, businessName, email, password, phone } =
      this.state;

    return (
      firstName !== "" &&
      lastName !== "" &&
      businessName !== "" &&
      validEmail(email) &&
      password.length >= 6 &&
      phone.length == 10
    );
  }

  handlePhone(phone) {
    const value = phone.replace(/\D/g, "").substring(0, 10);
    this.setState({
      phone: value,
      phoneError: errors("phone", value),
    });
  }

  register() {
    const { signUp } = this.props;
    const {
      firstName,
      lastName,
      businessName,
      email,
      phone,
      password,
      url,
      referredCode,
      googleId,
      register_source,
      positionId,
      shipmentsId,
      challengeId,
    } = this.state;

    if (this.isUserDataComplete()) {
      if (process.env.NODE_ENV === "production") {
        this.gtag_report_conversion();
      }
      if (googleId) {
        signUp(
          firstName,
          lastName,
          businessName,
          email,
          phone,
          password,
          url,
          referredCode,
          googleId,
          register_source,
          positionId,
          shipmentsId,
          challengeId
        );
      } else {
        signUp(
          firstName,
          lastName,
          businessName,
          email,
          phone,
          password,
          url,
          referredCode,
          null,
          register_source,
          positionId,
          shipmentsId,
          challengeId
        );
      }
    } else {
      this.handleFirstName(firstName);
      this.handleLastName(lastName);
      this.handleBusinessName(businessName);
      this.handleEmail(email);
      this.handlePhone(phone);
      this.handleURL(url);
      this.handlePassword(password);
    }
  }

  handleSelectChange = (section, val) => {
    this.setState({ [section]: val });
  };

  render() {
    const { message, hideMessage, gotLogIn } = this.props;

    const {
      firstName,
      lastName,
      businessName,
      email,
      phone,
      url,
      password,
      firstNameError,
      lastNameError,
      businessNameError,
      emailError,
      phoneError,
      passwordError,
      referredCode,
      referredCodeError,
      positionId,
      shipmentsId,
      challengeId,
    } = this.state;

    if (this.isLoggedIn()) {
      return <Redirect to="/" />;
    }
    if (
      message.show &&
      (message.messageType === "error" || message.messageType === "success")
    ) {
      setTimeout(hideMessage, 8000);
    }
    return (
      <div className="cont-login">
        <div className="register-card">
          <Row className="row">
            <Column xs={12} md={6} fluid>
              <div className="container-inputs">
                <p className="title-register">Crea tu cuenta</p>
                <br />
                {message.show ? (
                  <Message color={message.messageType} onClose={hideMessage}>
                    {message.text}
                  </Message>
                ) : null}
                <Row>
                  <Column xs={5} fluid>
                    <Input
                      type="text"
                      onChange={(firstName: string) => {
                        this.setState({
                          firstName,
                          firstNameError: errors("name", firstName),
                        });
                      }}
                      value={firstName}
                      error={firstNameError}
                      required
                      placeholder="Juan"
                      parentClassName="input-register"
                      label="Nombre(s)"
                      onKeyPress={this.handleKeyPress}
                    />
                  </Column>
                  <Column xs={6} fluid xsShift={1}>
                    <Input
                      type="text"
                      onChange={(lastName: string) => {
                        this.setState({
                          lastName,
                          lastNameError: errors("name", lastName),
                        });
                      }}
                      parentClassName="input-register"
                      error={lastNameError}
                      value={lastName}
                      required
                      placeholder="Pérez"
                      label="Apellidos"
                      onKeyPress={this.handleKeyPress}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column xs={5} fluid>
                    <Input
                      parentClassName="input-register"
                      type="text"
                      onChange={(phone: string) => {
                        const value = phone.replace(/\D/g, "").substring(0, 10);
                        this.setState({
                          phone: value,
                          phoneError: errors("phone", value),
                        });
                      }}
                      value={formatPhone(phone)}
                      required
                      error={phoneError}
                      placeholder="442 111 22 33"
                      label="Teléfono"
                      onKeyPress={this.handleKeyPress}
                    />
                  </Column>
                  <Column xs={6} fluid xsShift={1}>
                    <Input
                      parentClassName="input-register"
                      type="text"
                      onChange={this.handleEmail}
                      value={email}
                      required
                      error={emailError}
                      placeholder="micorreo@gmail.com"
                      label="Correo electrónico"
                      onKeyPress={this.handleKeyPress}
                    />
                  </Column>
                </Row>
                <Input
                  parentClassName="input-register"
                  type="password"
                  autoComplete="new-password"
                  onChange={(password: string) => {
                    this.setState({
                      password,
                      passwordError: errors("password", password),
                    });
                  }}
                  value={password}
                  required
                  error={passwordError}
                  placeholder="*******"
                  label="Contraseña"
                  onKeyPress={this.handleKeyPress}
                />
                <Row style={{ zIndex: 12, position: "relative" }}>
                  <Column xs={5} fluid>
                    <Input
                      type="text"
                      onChange={(businessName: string) => {
                        this.setState({
                          businessName,
                          businessNameError: errors("business", businessName),
                        });
                      }}
                      value={businessName}
                      error={businessNameError}
                      required
                      placeholder="Mi Tienda"
                      parentClassName="input-register"
                      label="Empresa"
                      onKeyPress={this.handleKeyPress}
                    />
                  </Column>
                  <Column xs={6} fluid xsShift={1}>
                    <Dropdown
                      handleChange={(v) =>
                        this.handleSelectChange("shipmentsId", v)
                      }
                      required
                      label="Envíos que realizas al mes"
                      options={shipmentsOptions}
                      selected={shipmentsId}
                    />
                  </Column>
                </Row>
                <Input
                  parentClassName="input-register"
                  type="text"
                  onChange={(url: string) => {
                    this.setState({
                      url,
                      urlError: errors("url", url),
                    });
                  }}
                  value={url}
                  error=""
                  placeholder="www.mitienda.com"
                  label="Página web o red social, de tu tienda en línea"
                  onKeyPress={this.handleKeyPress}
                />
                <Dropdown
                  style={{ zIndex: 11, position: "relative" }}
                  handleChange={(v) => this.handleSelectChange("positionId", v)}
                  required
                  label="Cargo actual"
                  options={positionOptions}
                  selected={positionId}
                />
                <Dropdown
                  handleChange={(v) =>
                    this.handleSelectChange("challengeId", v)
                  }
                  required
                  label="¿Cuál es el principal reto que necesitas solucionar?"
                  options={challengeOptions}
                  selected={challengeId}
                />
                <br />
                <Text type="textTable">
                  Al crear una cuenta aceptas los{" "}
                  <a
                    target="_blanck"
                    href="https://www.mienvio.mx/terms.html"
                    className="register-links"
                  >
                    términos de servicio
                  </a>{" "}
                  y{" "}
                  <a
                    target="_blanck"
                    href="https://www.mienvio.mx/privacy.html"
                    className="register-links"
                  >
                    políticas de privacidad.
                  </a>
                </Text>
                <br />

                <Button className="login-btn-login" onClick={this.register}>
                  Crear cuenta
                </Button>
              </div>
            </Column>
            <Column xs={12} md={6} fluid>
              <div className="right">
                <img src={logo} alt="" className="title" />
                <p className="subtitle">
                  Toma el control de tu logística, decide
                  <br /> asertivamente y optimiza tiempos.
                  <br /> La plataforma que todo gran negocio
                  <br /> utiliza para gestionar su logística.
                </p>
                <p className="question">¿Ya tienes cuenta?</p>
                <div onClick={() => this.props.gotLogIn()} className="register">
                  <p>Ingresa aquí</p>
                </div>
                <img className="green" src={sliceGreen} />
                <img className="pink" src={slicePink} />
              </div>
            </Column>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideMessage,
      gotLogIn: () => push(`/login${window.location.search}`),
      signUp,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
