import React from "react";
import styledProps from "styled-props";
import styled from "styled-components";
import colors from "../../utils/colors";

const textColors = {
  labelAuxiliar: colors.gray.thunder_sky,
  titleAuxiliarInfo: colors.gray.charcoal,
  bodyDetail: colors.gray.charcoal,
  titleCard: colors.gray.solid_black,
  titleCardSm: colors.gray.solid_black,
  inputFilled: colors.gray.charcoal,
  titleForm: colors.gray.charcoal,
  helpTextModal: colors.gray.shadow,
  label: colors.gray.charcoal,
  labelEdit: colors.secondary.cobalt,
  textSidebarLink: colors.gray.charcoal,
  textSidebarLinkSelected: colors.gray.charcoal,
  textTable: colors.gray.charcoal,
  trackingDetail: colors.gray.black_smoke,
  titleModalInput: colors.gray.charcoal,
  titleScreen: colors.gray.solid_black,
  microHeader: colors.newcolors.micro_header,
  microHeaderBig: colors.newcolors.micro_header,
  microDescription: colors.newcolors.micro_description,
  sidebarInfoTitle: colors.newcolors.input,
  P: colors.gray.charcoal,
};

const weights = {
  labelAuxiliar: 700,
  titleAuxiliarInfo: 600,
  bodyDetail: 400,
  titleCard: 600,
  titleCardSm: 600,
  titleCardSmall: 400,
  inputFilled: 600,
  titleForm: 600,
  helpTextModal: 400,
  label: 800,
  labelEdit: 600,
  textSidebarLink: 400,
  textSidebarLinkSelected: 700,
  textTable: 400,
  trackingDetail: 400,
  titleModalInput: 600,
  titleScreen: 600,
  microHeader: 600,
  microHeaderBig: 600,
  microDescription: 300,
  sidebarInfoTitle: 500,
  P: "auto",
};

const fontSizes = {
  labelAuxiliar: "13px",
  titleAuxiliarInfo: "24px",
  bodyDetail: "16px",
  titleCard: "28px",
  titleCardSmall: "18px",
  titleCardSm: "25px",
  inputFilled: "16px",
  titleForm: "24px",
  helpTextModal: "11px",
  label: "13px",
  labelEdit: "13px",
  textSidebarLink: "15px",
  textSidebarLinkSelected: "16px",
  textTable: "14px",
  trackingDetail: "16px",
  titleModalInput: "24px",
  titleScreen: "24px",
  microHeader: "11px",
  microHeaderBig: "13px",
  microDescription: "11px",
  sidebarInfoTitle: "12px",
  P: "12px",
};

const lineHeights = {
  labelAuxiliar: "16px",
  titleAuxiliarInfo: "30px",
  bodyDetail: "20px",
  titleCard: "35px",
  inputFilled: "20px",
  titleForm: "30px",
  helpTextModal: "14px",
  label: "16px",
  labelEdit: "16px",
  textSidebarLink: "19px",
  textSidebarLinkSelected: "20px",
  textTable: "18px",
  trackingDetail: "20px",
  titleModalInput: "30px",
  titleScreen: "30px",
  microHeader: "14px",
  microHeaderBig: "14px",
  microDescription: "16px",
  sidebarInfoTitle: "auto",
  P: "auto",
};

const letterSpacings = {
  labelAuxiliar: "0px",
  titleAuxiliarInfo: "0.88px",
  bodyDetail: "0.5px",
  titleCard: "0.88px",
  inputFilled: "0.35px",
  titleForm: "0.6px",
  helpTextModal: "0.35px",
  label: "0px",
  labelEdit: "0.41px",
  textSidebarLink: "0.75px",
  textSidebarLinkSelected: "0px",
  textTable: "0px",
  trackingDetail: "0.5px",
  titleModalInput: "0.6px",
  titleScreen: "0.75px",
  microHeader: "0.35px",
  microHeaderBig: "0.35px",
  microDescription: "0.35px",
  sidebarInfoTitle: "0.35px",
  P: "auto",
};

const marginTop = {
  labelAuxiliar: "0rem",
  titleAuxiliarInfo: "0rem",
  bodyDetail: "0rem",
  titleCard: "0rem",
  inputFilled: "0rem",
  titleForm: "0rem",
  helpTextModal: "0rem",
  label: "0rem",
  labelEdit: "0rem",
  textSidebarLink: "0rem",
  textSidebarLinkSelected: "0rem",
  textTable: "0rem",
  trackingDetail: "0rem",
  titleModalInput: "0rem",
  titleScreen: "0rem",
  microHeader: "2rem",
  microHeaderBig: "2rem",
  microDescription: "0",
  sidebarInfoTitle: "1rem",
  P: "auto",
};

const marginBottom = {
  labelAuxiliar: "auto",
  titleAuxiliarInfo: "auto",
  bodyDetail: "auto",
  titleCard: "auto",
  inputFilled: "auto",
  titleForm: "auto",
  helpTextModal: "auto",
  label: "auto",
  labelEdit: "auto",
  textSidebarLink: "auto",
  textSidebarLinkSelected: "auto",
  textTable: "auto",
  trackingDetail: "auto",
  titleModalInput: "auto",
  titleScreen: "auto",
  microHeader: "auto",
  microHeaderBig: "auto",
  microDescription: "auto",
  sidebarInfoTitle: "0.5rem",
  P: "auto",
};

const P = styled.p`
  color: ${styledProps(textColors, "type")};
  font-weight: ${styledProps(weights, "type")};
  font-size: ${styledProps(fontSizes, "type")};
  line-height: ${styledProps(lineHeights, "type")};
  letter-spacing: ${styledProps(letterSpacings, "type")};
  margin: 0;
  margin-top: ${styledProps(marginTop, "type")};
  margin-bottom: ${styledProps(marginBottom, "type")};
  display: inline-block;

  &[type="microHeader"] {
    display: block;
  }

  &.display-block {
    display: block !important;
  }

  @media screen and (max-width: 39.9375em) {
    &[type="helpTextModal"] {
      font-size: 1rem;
      line-height: 1.35rem;
      letter-spacing: 0;
    }
  }
`;

const H1 = styled.h1`
  color: ${styledProps(textColors, "type")};
  font-weight: ${styledProps(weights, "type")};
  font-size: ${styledProps(fontSizes, "type")};
  line-height: ${styledProps(lineHeights, "type")};
  letter-spacing: ${styledProps(letterSpacings, "type")};
  margin: 8px 0;

  span.annotation {
    font-size: 8pt;
    position: relative;
    top: -4px;
    margin-left: 10px;
    padding: 4px 6px;
    background-color: ${colors.secondary.ultralight_blue};
    border-radius: 100px;
    color: ${colors.gray.charcoal};
  }
`;

P.defaultProps = {
  type: "bodyDetail",
};

H1.defaultProps = {
  type: "titleScreen",
};

class Text extends React.Component {
  render = () => (
    <P
      style={this.props.style}
      type={this.props.type}
      onClick={this.props.onClick}
      className={`${this.props.className ? this.props.className : ""} ${
        this.props.block ? "display-block" : ""
      }`}
    >
      {this.props.children}
    </P>
  );
}

class Title extends React.Component {
  render = () => (
    <H1
      style={this.props.style}
      type={this.props.type}
      className={this.props.className}
      onClick={this.props.onClick}
    >
      {this.props.children}
      {this.props.annotation && (
        <span className="annotation">{this.props.annotation}</span>
      )}
    </H1>
  );
}

export default Text;
export { Title, H1, P };
