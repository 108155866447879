import React from 'react';
import { shorten } from '../../../utils/global_functions';

const ItemAccount = ({
  selected, color, hide, data, selectUser,
}) => (
  <div onClick={() => selectUser(data.id)} className={`card-item-accounts ${selected && 'selected'}`}>
    <div style={{ backgroundColor: color }} className="initials">
      {data.first_name.charAt(0)}
      {data.last_name.charAt(0)}
    </div>
    <div className="name-container">
      <p>
        {shorten(`${data.first_name} ${data.last_name}`, 18)}
      </p>
      <p>{shorten(data.email, 20)}</p>
    </div>
    {/* <img src={} alt="" /> */}
  </div>
);
export default ItemAccount;
