import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { showLoader, hideLoader } from "../loader";
import { showError } from "../error";
import { showMessage } from "../message";
import { sendDataInvoice } from "../sidebarOrder/actions";
import * as types from "./types";
import { fi } from "date-fns/locale";

// --- use "from" to be able to see the origin of request
export const getAllUserShipments =
  (limit = 0, page = 1, filters = [], param, loader = "packages", from) =>
  (dispatch) => {
    const map = { 0: 15, 1: 50, 2: 100, 3: 300 };
    limit = map[limit];
    dispatch(showLoader(loader));

    return axios
      .get(`/api/shipmentsPurchases?${param ?? ""}`, {
        params: {
          page,
          order: "desc",
          limit: limit,
          filters,
        },
      })
      .then((response) => {
        dispatch(setAllUserShipments(response.data));
        // dispatch(getAllUserQuotes());
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        if (err.response && err.response.data) {
          if (err.response.data.message && err.response.data.message !== "") {
            dispatch(showError(err.response.data.message));
          } else if (
            err.response.data.error &&
            err.response.data.error !== ""
          ) {
            dispatch(showError(err.response.data.error));
          } else {
            dispatch(showError("Error al obtener los envíos"));
          }
        } else {
          dispatch(showError("Error al obtener los envíos"));
        }
      });
  };

export const searchAndAddShipment = (id) => async (dispatch, getState) => {
  const { shipments } = getState();

  const exist = shipments.purchase.find(
    (item) => Number(item.id) === Number(id)
  );

  if (!exist) {
    return axios
      .get(`/api/shipmentsPurchases`, { params: { filters: [`id|=|${id}`] } })
      .then(({ data }) => {
        dispatch(
          setUserShipmentsPurchase([
            ...shipments.purchase,
            ...data.shipments.non_payed,
          ])
        );
      });
  }
  return true;
};

export const getMarketplaceShipments =
  (page = 1, limit = 0, filters = [], displayLoader = false) =>
  (dispatch) => {
    const map = { 0: 15, 1: 50, 2: 100 };
    limit = map[limit];
    if (displayLoader) {
      dispatch(showLoader("shipments"));
    }
    return axios
      .get("/api/marketplaces/orders", {
        params: {
          page,
          order: "desc",
          limit: limit,
          filters,
        },
      })
      .then((response) => {
        if (displayLoader) {
          dispatch(hideLoader());
        }
        return response.data.orders;
      })
      .catch((err) => {
        try {
          dispatch(showError(err.response.data.error));
        } catch (e) {
          dispatch(showError("Error al obtener los envíos"));
        }
      });
  };

export const getAllUserQuotes = () => (dispatch) => {
  // dispatch(showLoader(null, null, false));
  return axios
    .get("/api/quotes")
    .then((response) => {
      const result = [];
      for (const i in response.data.quotes) {
        result.push([i, response.data.quotes[i]]);
      }
      dispatch(setAllUserQuotes(result));
      dispatch(hideLoader());
    })
    .catch((err) => {
      dispatch(hideLoader());
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al obtener los envíos"));
        }
      } else {
        dispatch(showError("Error al obtener los envíos"));
      }
    });
};

export const getAllUserQuotesUnauthorized = (id) => (dispatch) => {
  // dispatch(showLoader(null, null, false));
  return axios
    .get(`/api/quotes/${id}`)
    .then((response) => {
      const result = [
        [response.data.purchase.object_id, response.data.purchase.shipments],
      ];

      dispatch(setAllUserQuotes(result));
      dispatch(hideLoader());
    })
    .catch((err) => {
      dispatch(hideLoader());
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al obtener los envíos"));
        }
      } else {
        dispatch(showError("Error al obtener los envíos"));
      }
    });
};

export const getAllUserQuotesToken = (api_token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${api_token}`,
    },
  };
  return (dispatch) => {
    // dispatch(showLoader(null, null, false));
    return axios
      .get("/api/quotes", config)
      .then((response) => {
        const result = [];

        for (const i in response.data.quotes) {
          result.push([i, response.data.quotes[i]]);
        }
        dispatch(setAllUserQuotes(result));
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        if (err.response && err.response.data) {
          if (err.response.data.message && err.response.data.message !== "") {
            dispatch(showError(err.response.data.message));
          } else if (
            err.response.data.error &&
            err.response.data.error !== ""
          ) {
            dispatch(showError(err.response.data.error));
          } else {
            dispatch(showError("Error al obtener los envíos"));
          }
        } else {
          dispatch(showError("Error al obtener los envíos"));
        }
      });
  };
};

export const getAndAppendAllUserShipments =
  (limit, page = 1, filters = []) =>
  (dispatch) => {
    const map = { 0: 15, 1: 50, 2: 100 };
    limit = map[limit];
    return axios
      .get("/api/shipmentsPurchases", {
        params: {
          page,
          order: "desc",
          limit: limit,
          filters,
        },
      })
      .then((response) => {
        dispatch(appendAllUserShipments(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.message && err.response.data.message !== "") {
            dispatch(showError(err.response.data.message));
          } else if (
            err.response.data.error &&
            err.response.data.error !== ""
          ) {
            dispatch(showError(err.response.data.error));
          } else {
            dispatch(showError("Error al obtener los envíos"));
          }
        } else {
          dispatch(showError("Error al obtener los envíos"));
        }
      });
  };

export const getUserShipments =
  (page = 1) =>
  (dispatch) => {
    dispatch(showLoader("shipments"));
    return axios
      .get(`/api/purchases?page=${page}&order=desc&limit=1000000`)
      .then((response) => {
        dispatch(setUserShipments(response.data));
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        if (err.response && err.response.data) {
          if (err.response.data.message && err.response.data.message !== "") {
            dispatch(showError(err.response.data.message));
          } else if (
            err.response.data.error &&
            err.response.data.error !== ""
          ) {
            dispatch(showError(err.response.data.error));
          } else {
            dispatch(showError("Error al obtener los envíos"));
          }
        } else {
          dispatch(showError("Error al obtener los envíos"));
        }
      });
  };

export const getUserOrders =
  (page = "1") =>
  (dispatch) => {
    dispatch(showLoader("shipments"));
    return axios
      .get("/api/orders")
      .then((response) => {
        dispatch(setUserOrders(response.data));
        dispatch(hideLoader());
      })
      .catch((err) => {
        dispatch(hideLoader());
        if (err.response && err.response.data) {
          if (err.response.data.message && err.response.data.message !== "") {
            dispatch(showError(err.response.data.message));
          } else if (
            err.response.data.error &&
            err.response.data.error !== ""
          ) {
            dispatch(showError(err.response.data.error));
          } else {
            dispatch(showError("Error al obtener las órdenes"));
          }
        } else {
          dispatch(showError("Error al obtener las órdenes"));
        }
      });
  };

export const completeUserOrders = (data) => (dispatch) => {
  dispatch(showLoader("shipments"));
  return axios
    .post("/api/shipments/orders/complete", data)
    .then((response) => {
      dispatch(getAllUserShipments());
    })
    .catch((err) => {
      dispatch(hideLoader());
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al completar la órden"));
        }
      } else {
        dispatch(showError("Error al completar la órden"));
      }
    });
};

export const getUserShipmentsPurchase = () => (dispatch) => {
  dispatch(showLoader("shipments"));
  return axios
    .get("/api/shipments?&order=desc&limit=1000000&status=PURCHASE")
    .then((response) => {
      dispatch(setUserShipmentsPurchase(response.data));
      dispatch(hideLoader());
    })
    .catch((err) => {
      dispatch(hideLoader());
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al obtener los envios"));
        }
      } else {
        dispatch(showError("Error al obtener los envios"));
      }
    });
};

export const setUserShipments = (shipments) => ({
  type: types.GET,
  shipments,
});

export const setAllUserShipments = (shipments) => ({
  type: types.GET_ALL,
  shipments,
});
export const setAllUserQuotes = (quotes) => ({
  type: types.GET_ALL_QUOTES,
  quotes,
});

export const setUserMaxShipments = (flag) => ({
  type: types.SHOW_SHIPMENTS_MODAL,
  flag,
});

export const appendAllUserShipments = (shipments) => ({
  type: types.APPEND_ALL,
  shipments,
});

export const setUserOrders = (shipments) => ({
  type: types.GET_ORDER,
  shipments,
});

export const setUserShipmentsPurchase = (shipments) => ({
  type: types.GET_PURCHASE,
  shipments,
});

const createAddressFrom = (from) =>
  axios.post("/api/addresses", {
    object_type: "PURCHASE",
    name: from.name,
    street: from.street,
    street2: from.street2,
    zipcode: from.zipcode,
    email: from.email,
    phone: from.phone,
    reference: from.reference,
    country: from.country,
  });

const createAddressTo = (to) =>
  axios.post("/api/addresses", {
    object_type: "PURCHASE",
    name: to.name,
    street: to.street,
    street2: to.street2,
    zipcode: to.zipcode,
    email: to.email,
    phone: to.phone,
    reference: to.reference,
    country: to.country,
  });

export const createShipment =
  (
    devolution,
    from,
    to,
    shipment,
    declared_value,
    rate,
    insurance,
    order,
    dataInvoice,
    is_extend
  ) =>
  (dispatch) => {
    dispatch(showLoader("shipments"));
    axios
      .all([createAddressFrom(from), createAddressTo(to)])
      .then(
        axios.spread((address_from, address_to) => {
          axios
            .post("/api/shipments", {
              object_purpose: "PURCHASE",
              address_from: address_from.data.address.object_id,
              address_to: address_to.data.address.object_id,
              package_id: shipment.packageId,
              weight: shipment.weight,
              length: shipment.length,
              source_type: "web_portal",
              height: shipment.height,
              description: shipment.description,
              width: shipment.width,
              declared_value: parseFloat(declared_value),
              rate,
              insurance,
              order,
              is_extend,
              devolution: !!devolution,
            })
            .then((response) => {
              dataInvoice.label_id = response.data.shipment.object_id;
              if (dataInvoice.incoterm_example != "") {
                dispatch(sendDataInvoice(dataInvoice));
              }
              dispatch(setCreated(response.data.shipment.object_id));
              dispatch(showMessage("success", "Envío creado exitosamente"));
              dispatch(getAllUserShipments());
              dispatch(hideLoader());
            })
            .catch((err) => {
              if (err.response.status === 406) {
                Swal.fire({
                  title: "Límite de envíos",
                  text: "Llegaste al límite de envíos del plan. Actualiza tu plan y continua generando tus órdenes",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Actualizar Plan",
                  cancelButtonText: "Cancelar",
                }).then((result) => {
                  if (result.value) {
                    dispatch(setUserMaxShipments(true));
                  }
                });
              }
              dispatch(hideLoader());
            });
        })
      )
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.message && err.response.data.message !== "") {
            dispatch(showError(err.response.data.message));
          } else if (
            err.response.data.error &&
            err.response.data.error !== ""
          ) {
            dispatch(showError(err.response.data.error));
          } else {
            dispatch(showError("Error al crear el envío"));
          }
        } else {
          dispatch(showError("Error al crear el envío"));
        }
      });
  };

export const editShipment =
  (id, from, to, shipment, insurance, rate, declared_value = 0) =>
  (dispatch) => {
    dispatch(showLoader("shipments"));
    axios
      .all([createAddressFrom(from), createAddressTo(to)])
      .then(
        axios.spread((address_from, address_to) => {
          axios
            .put(`/api/shipments/${id}`, {
              address_from: address_from.data.address.object_id,
              address_to: address_to.data.address.object_id,
              weight: shipment.weight,
              length: shipment.length,
              height: shipment.height,
              description: shipment.description,
              width: shipment.width,
              declared_value: parseFloat(declared_value),
              insurance,
              rate,
            })
            .then((response) => {
              dispatch(setCreated(response.data.shipment.object_id));
              dispatch(showMessage("success", "Envío editado exitosamente"));
              dispatch(getAllUserShipments());
              dispatch(hideLoader());
            })
            .catch((err) => {
              if (err.response && err.response.data) {
                if (
                  err.response.data.message &&
                  err.response.data.message !== ""
                ) {
                  dispatch(showError(err.response.data.message));
                } else if (
                  err.response.data.error &&
                  err.response.data.error !== ""
                ) {
                  dispatch(showError(err.response.data.error));
                } else {
                  dispatch(showError("Error al editar el pedido"));
                }
              } else {
                dispatch(showError("Error al editar el pedido"));
              }
              dispatch(hideLoader());
            });
        })
      )
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.message && err.response.data.message !== "") {
            dispatch(showError(err.response.data.message));
          } else if (
            err.response.data.error &&
            err.response.data.error !== ""
          ) {
            dispatch(showError(err.response.data.error));
          } else {
            dispatch(showError("Error al editar el pedido"));
          }
        } else {
          dispatch(showError("Error al editar el pedido"));
        }
      });
  };

export const duplicateShipments = (templates_ids) => (dispatch) => {
  dispatch(showLoader("shipments"));
  axios
    .post("/api/shipments", {
      templates_ids,
    })
    .then((response) => {
      dispatch(showMessage("success", "Envíos duplicados exitosamente"));
      dispatch(getAllUserShipments());
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al duplicar los pedidos"));
        }
      } else {
        dispatch(showError("Error al duplicar los pedidos"));
      }
      dispatch(hideLoader());
    });
};

export const deleteShipment = (id) => (dispatch) =>
  axios
    .delete(`/api/shipments/${id}`)
    .then((response) => {
      dispatch(showMessage("success", "Envío eliminado"));
      dispatch(getAllUserShipments());
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al eleminar el pedido"));
        }
      } else {
        dispatch(showError("Error al eliminar el pedido"));
      }
    });

export const deleteShipments = (shipments) => (dispatch) =>
  axios
    .post("/api/shipments/delete", {
      ids: shipments,
    })
    .then((response) => {
      dispatch(showMessage("success", "Envíos eliminados"));
      dispatch(getAllUserShipments());
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al eliminar los pedidos"));
        }
      } else {
        dispatch(showError("Error al eliminar los pedidos"));
      }
    });

export const mergeShipmentsPDF = (shipments) => (dispatch) =>
  axios
    .post("/api/labels/download-multiple-merge", {
      shipments: shipments,
    })
    .then((response) => {
      window.open(response.data.multi_label, "Menvio PDF Unico");
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al Generar el Archivo"));
        }
      } else {
        dispatch(showError("Error al Generar el Archivo"));
      }
    });

export const editShipmentRate = (id, rate) => (dispatch) => {
  dispatch(showLoader("basic"));
  return axios
    .put(`/api/shipments/${id}`, {
      rate,
    })
    .then((response) => {
      dispatch(hideLoader());
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al editar el pedido"));
        }
      } else {
        dispatch(showError("Error al editar el pedido"));
      }
    });
};

export const editShipmentDescription = (id, description) => (dispatch) => {
  dispatch(showLoader("basic"));
  return axios
    .put(`/api/shipments/${id}`, {
      description,
    })
    .then((response) => {
      dispatch(hideLoader());
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al editar el pedido"));
        }
      } else {
        dispatch(showError("Error al editar el pedido"));
      }
    });
};

export const editShipmentDimensions =
  (id, length, width, height, weight) => (dispatch) => {
    dispatch(showLoader("basic"));
    return axios
      .put(`/api/shipments/${id}`, {
        length,
        width,
        height,
        weight,
      })
      .then((response) => {
        dispatch(hideLoader());
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.message && err.response.data.message !== "") {
            dispatch(showError(err.response.data.message));
          } else if (
            err.response.data.error &&
            err.response.data.error !== ""
          ) {
            dispatch(showError(err.response.data.error));
          } else {
            dispatch(showError("Error al editar el pedido"));
          }
        } else {
          dispatch(showError("Error al editar el pedido"));
        }
      });
  };

export const editShipmentInsurance = (id, insurance) => (dispatch) => {
  dispatch(showLoader("basic"));
  return axios
    .put(`/api/shipments/${id}`, {
      insurance,
    })
    .then((response) => {
      dispatch(hideLoader());
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al editar el pedido"));
        }
      } else {
        dispatch(showError("Error al editar el pedido"));
      }
    });
};

export const chageShipmentStatus = (shipmentId ,status) => (dispatch) => {
  dispatch(showLoader("basic"));
  return axios
    .post(`api/status/label/manual`, {
      "shipment_id": shipmentId,
      "status": status
  })
    .then((response) => {
      dispatch(hideLoader());
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al editar la orden"));
        }
      } else {
        dispatch(showError("Error al editar la orden"));
      }
    });
};

export const cancelOrder = (shipment_id) => (dispatch) => {
  dispatch(showLoader('basic'))
  return axios.post('api/quotes/label/cancel', {
      shipment_id
    })
    .then(reponse => {
      dispatch(hideLoader())
    })
    .catch(err => {
      if (err.response && err.response.data) {
        if (err.response.data.message && err.response.data.message !== "") {
          dispatch(showError(err.response.data.message));
        } else if (err.response.data.error && err.response.data.error !== "") {
          dispatch(showError(err.response.data.error));
        } else {
          dispatch(showError("Error al cancelar la orden"));
        }
      } else {
        dispatch(showError("Error al cancelar la orden"));
      }
    })
}

export const setCreated = (createdId) => ({
  type: types.SET_CREATED,
  createdId,
});

export const setFromAddress = (fromaddress) => ({
  type: types.SET_FROM_ADDRESS,
  fromaddress,
});

export const setToAddress = (toaddress) => ({
  type: types.SET_TO_ADDRESS,
  toaddress,
});

export const resetCreated = () => ({
  type: types.CLEAR_CREATED,
});

export const appendFilter = (filter) => ({
  type: types.APPEND_FILTER,
  filter,
});

export const deleteFilter = (index) => ({
  type: types.DELETE_FILTER,
  index,
});

export const clearFilters = () => ({
  type: types.CLEAR_FILTERS,
});
