import { createSelector } from 'reselect';
import moment from 'moment';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';

const addressesSelector = state => (state.addresses && state.addresses.results) || [];
const byId = createSelector(
  addressesSelector,
  addresses => {
    const result = {};
    addresses.forEach(address => {
      result[address.object_id] = address;
    });
    return result;
  }
);

export { addressesSelector, byId };
