import React from "react";
import axios from "axios";
// import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import styled from "styled-components";
import { Row, Column } from "hedron";
import { Route, Switch } from "react-router-dom";
import { push, goBack } from "react-router-redux";
import isEmail from "validator/lib/isEmail";
import colors from "../../utils/colors";

import {
  getUserAllPackages,
  createPackage,
  editPackage,
} from "../../modules/packages";
import { hideMessage } from "../../modules/message";

import Menu from "../../components/Menu";
import PackagesList from "../../components/PackagesList";
import Create from "../../components/Create";
import Text, { Title } from "../../components/Text";
import Input, { Label, Required, HelpText } from "../../components/Input";
import Button from "../../components/Button";
import { RadioGroup, Radio } from "../../components/Radio";
import Dropdown from "../../components/Dropdown";

import "./index.css";

const StyledOption = styled.div`
  padding: 16px 12px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${colors.gray.earth_stone};
  margin-bottom: 16px;
  box-sizing: border-box;
`;

class Packages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alias: "",
      aliasError: "",
      objectTypeCode: null,
      objectTypeCodeError: "",
      objectStatusCode: null,
      objectStatusCodeError: "",
      dimensions: {
        height: "",
        width: "",
        length: "",
      },
      quantity: "",
      quantityError: "",
      provider: null,
    };
    this.props.getUserAllPackages();
    this.handleChange = this.handleChange.bind(this);
    this.resetForms = this.resetForms.bind(this);
    this.savePackage = this.savePackage.bind(this);
  }

  handleChange(value, key) {
    if (key === "length" || key === "width" || key === "height") {
      const dimensions = { ...this.state.dimensions };
      dimensions[key] = value;
      return this.setState({ dimensions });
    }
    this.setState({ [key]: value });
  }

  resetForms() {
    this.setState({
      alias: "",
      aliasError: "",
      objectTypeCode: null,
      objectTypeCodeError: "",
      objectStatusCode: null,
      objectStatusCodeError: "",
      dimensions: {
        height: "",
        width: "",
        length: "",
      },
      dimensionsError: "",
      quantity: null,
      quantityError: "",
      provider: null,
    });
  }

  savePackage(type, id) {
    let errors = 0;
    let Error = "";
    let objectTypeCodeError = "";
    let objectStatusCodeError = "";
    let dimensionsError = "";
    let quantityError = "";

    if (!this.state.alias) {
      errors++;
      aliasError = "Este campo es requerido";
    }

    if (this.state.objectTypeCode === null) {
      errors++;
      objectTypeCodeError = "Este campo es requerido";
    }

    // if (this.state.objectStatusCode === null) {
    //   errors++;
    //   objectStatusCodeError = 'Este campo es requerido';
    // }

    if (
      !this.state.dimensions.height ||
      !this.state.dimensions.length ||
      !this.state.dimensions.width
    ) {
      errors++;
      dimensionsError = "Todas las dimensiones son requeridas";
    } else if (
      isNaN(this.state.dimensions.height) ||
      isNaN(this.state.dimensions.length) ||
      isNaN(this.state.dimensions.width)
    ) {
      errors++;
      dimensionsError = "Todas las dimensiones deben tener valores númericos";
    } else if (
      this.state.dimensions.height > 170 ||
      this.state.dimensions.length > 170 ||
      this.state.dimensions.width > 170
    ) {
      errors++;
      dimensionsError = "Todas las dimensiones deben ser menores a 170cm";
    } else if (
      Number(this.state.dimensions.height) +
        Number(this.state.dimensions.length) +
        Number(this.state.dimensions.width) >=
      360
    ) {
      errors++;
      dimensionsError = "La suma de las dimensiones debe de ser menor a 360cm";
    }

    // if (!this.state.quantity) {
    //   errors++;
    //   quantityError = 'Este campo es requerido';
    // } else if (!Number.isInteger(Number(this.state.quantity))) {
    //   errors++;
    //   quantityError = 'No puede contener decimales';
    // }

    this.setState({
      aliasError,
      objectStatusCodeError,
      objectTypeCodeError,
      quantityError,
      dimensionsError,
    });
    if (errors > 0) {
      return;
    }
    const pack = {
      object_type_code: ["box", "envelope"][this.state.objectTypeCode],
      object_status_code: ["available", "out_of_stock", "requested"][
        this.state.objectStatusCode
      ],
      alias: this.state.alias,
      provider: ["DHL", "FedEx", "Redpack", "Estafeta"][this.state.provider],
      height: this.state.dimensions.height,
      width: this.state.dimensions.width,
      length: this.state.dimensions.length,
      quantity: this.state.quantity,
    };
    if (type === "create") {
      this.props.createPackage(pack).then(() => {
        this.props.goToPackagesList();
      });
      return;
    }
    this.props.editPackage(pack, id).then(() => {
      this.props.goToPackagesList();
    });
  }

  componentWillMount() {
    this.props.hideMessage();
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.location.pathname.indexOf("crear") > -1 ||
        nextProps.location.pathname.indexOf("editar") > -1) &&
      nextProps.location.pathname !== this.props.location.pathname
    ) {
      this.resetForms();
      this.props.hideMessage();
    }
    if (nextProps.match.isExact && !this.props.match.isExact) {
      this.resetForms();
    }
    if (
      nextProps.location.pathname.indexOf("editar") > -1 &&
      this.props.packages.results
    ) {
      this.resetForms();
      let pack = null;
      const id = nextProps.location.pathname.split("/")[3];
      const results = this.props.packages.results;
      results.forEach((p) => {
        if (p.object_id.toString() === id) {
          pack = p;
          return;
        }
      });
      this.setState({
        alias: pack.alias,
        objectStatusCode: ["available", "out_of_stock", "requested"].indexOf(
          pack.object_status_code
        ),
        objectTypeCode: ["box", "envelope"].indexOf(pack.object_type_code),
        dimensions: {
          height: pack.height,
          width: pack.width,
          length: pack.length,
        },
        quantity: pack.quantity,
        provider: pack.provider
          ? ["DHL", "FedEx", "Redpack", "Estafeta"].indexOf(pack.provider)
          : null,
      });
    }
  }

  renderType() {
    return ["Caja", "Sobre"].map((t) => (
      <Row key={t} className="card-dropdown-option">
        <Column xs={6} fluid>
          <Text>{t}</Text>
        </Column>
      </Row>
    ));
  }

  renderProvider() {
    return ["DHL", "FedEx", "Redpack", "Estafeta"].map((t) => (
      <Row key={t} className="card-dropdown-option">
        <Column xs={6} fluid>
          <Text>{t}</Text>
        </Column>
      </Row>
    ));
  }

  renderStatus() {
    return ["Disponible", "Agotado", "En camino"].map((t) => (
      <Row key={t} className="card-dropdown-option">
        <Column xs={6} fluid>
          <Text>{t}</Text>
        </Column>
      </Row>
    ));
  }

  render() {
    const mainProps = {
      generate: (name) => {
        return {
          onChange: (value, key) => {
            this.handleChange(value, key ? key : name);
          },
          value: this.state[name],
          error: this.state[`${name}Error`],
        };
      },
    };
    const packageFields = (
      <div>
        <Input
          type="text"
          {...mainProps.generate("alias")}
          required
          placeholder="Caja Chica"
          label="Alias"
        />
        <Label style={{ marginTop: "16px" }}>
          Tipo{" "}
          <Required
            status={this.state.objectTypeCodeError ? "error" : "default"}
          />
        </Label>
        <Dropdown
          options={this.renderType()}
          style={{ marginTop: "12px" }}
          placeholder={
            <Row className="card-dropdown-option">
              <Column xs={6} fluid>
                <Text style={{ color: colors.gray.placeholder }}>
                  Elige un tipo
                </Text>
              </Column>
            </Row>
          }
          selected={this.state.objectTypeCode}
          handleChange={(value) => this.handleChange(value, "objectTypeCode")}
        />
        {this.state.objectTypeCodeError ? (
          <Row>
            <Column
              xs={12}
              fluid
              style={{
                paddingBottom: "12px",
                marginBottom: "-12px",
              }}
            >
              <HelpText>{this.state.objectTypeCodeError}</HelpText>
            </Column>
          </Row>
        ) : null}
        <Input
          type="text"
          {...mainProps.generate("dimensions")}
          required
          placeholder="30.0"
          label="Dimensiones (largo x ancho x altura)"
          dimensions
        />
        {/* <Label style={{marginTop: '16px'}}>Carrier (opcional)</Label> */}
        {/* <Dropdown */}
        {/*   options={this.renderProvider()} */}
        {/*   style={{marginTop: '12px'}} */}
        {/*   placeholder={ */}
        {/*     <Row className="card-dropdown-option"> */}
        {/*       <Column xs={6} fluid> */}
        {/*         <Text style={{color: colors.gray.placeholder}}> */}
        {/*           Elige un carrier */}
        {/*         </Text> */}
        {/*       </Column> */}
        {/*     </Row> */}
        {/*   } */}
        {/*   selected={this.state.provider} */}
        {/*   handleChange={value => this.handleChange(value, 'provider')} */}
        {/* /> */}
        {this.state.objectStatusCodeError ? (
          <Row>
            <Column
              xs={12}
              fluid
              style={{
                paddingBottom: "12px",
                marginBottom: "-12px",
              }}
            >
              <HelpText>{this.state.objectStatusCodeError}</HelpText>
            </Column>
          </Row>
        ) : null}
      </div>
    );
    return (
      <div>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: "right" }} fluid>
            <Menu />
          </Column>
        </Row>
        <Switch>
          <Route
            path={`${this.props.match.url}/crear`}
            render={(props) => (
              <Create {...props} goBack={this.props.goToPackagesList}>
                <Title>Registra tu nuevo empaque</Title>
                {packageFields}
                <Button
                  onClick={() => this.savePackage("create", null)}
                  className="create-btn"
                >
                  Registrar
                </Button>
              </Create>
            )}
          />
          <Route
            path={`${this.props.match.url}/editar/:id`}
            render={(props) => (
              <Create
                {...props}
                titleBack="Volver a empaques"
                goBack={this.props.goToPackagesList}
              >
                <Title>Editar empaque</Title>
                {packageFields}
                <Button
                  onClick={() =>
                    this.savePackage("edit", props.match.params.id)
                  }
                  className="create-btn"
                >
                  Aplicar cambios
                </Button>
                <Button
                  onClick={this.props.goToPackagesList}
                  className="create-btn"
                  type="link"
                >
                  Cancelar
                </Button>
              </Create>
            )}
          />
          <Route
            exact
            path={`${this.props.match.url}`}
            component={PackagesList}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  packages: state.packages,
});

const mapDispatchToProps = {
  goToPackagesList: () => push("/empaques"),
  getUserAllPackages,
  createPackage,
  editPackage,
  hideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
