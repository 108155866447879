import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Button from '../Button';
import './style.css';
import nofile_icon from './icons/cloud-upload.svg';
import file_icon from './icons/data-file-check.svg';
import { Label, Required, HelpText } from '../Input';

class DropzoneWrapper extends React.Component {
  state = {
    has_file: false,
    dropzone_error: '',
    file_label: 'Arrastra un archivo aquí'
  };

  componentWillMount() {
    this.setState({
      has_file: this.props.imageSrc != undefined && this.props.imageSrc != '' ? true : false
    });
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.props.onDrop && this.props.onDrop(acceptedFiles, rejectedFiles);

    if (rejectedFiles.length > 0)
      return this.setState({
        has_file: false,
        dropzone_error: 'El formato del archivo no es aceptado',
        file_label: 'Arrastra un archivo aquí'
      });

    if (acceptedFiles.length > 0)
      this.setState({
        has_file: true,
        dropzone_error: '',
        file_label:
          acceptedFiles.length == 1 ? acceptedFiles[0].name : acceptedFiles.length + ' archivos'
      });
  };

  onFileDialogCancel = () => {
    this.props.onCancel && this.props.onCancel();
  };

  deleteUploadedFiles = () => {
    this.props.onDelete && this.props.onDelete();
    this.setState({
      has_file: false,
      dropzone_error: '',
      file_label: 'Arrastra un archivo aquí'
    });
  };

  render() {
    return (
      <div>
        <Label>{this.props.label}</Label>
        {this.props.required && (
          <Required
            status={this.state.has_file ? 'focus' : this.state.dropzone_error ? 'error' : 'default'}
          />
        )}
        <div className="dropzone-parent-container">
          <div
            className={`del-button ${this.state.has_file && 'visible'}`}
            onClick={this.deleteUploadedFiles}
          />
          {this.props.imageSrc ? (
            <img className="image-preview" src={this.props.imageSrc} />
          ) : (
            <Dropzone
              accept={this.props.accept}
              onDrop={this.onDrop}
              onFileDialogCancel={this.onFileDialogCancel}
              multiple={this.props.multiple ? this.props.multiple : false}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="dropzone-upload">
                  <input {...getInputProps()} />
                  <img src={this.state.has_file ? file_icon : nofile_icon} />
                  <p>{this.state.file_label}</p>
                  <p>o</p>
                  <Button type="secondary" className="quote-btn" onClick={() => {}} initialLoader>
                    Elige un archivo
                  </Button>
                </div>
              )}
            </Dropzone>
          )}
          <HelpText className={'animated-help-text ' + (this.state.dropzone_error && 'open')}>
            {this.state.dropzone_error}
          </HelpText>
          <HelpText className={`animated-help-text ${this.props.out_error && 'open'}`}>
            {this.props.out_error}
          </HelpText>
        </div>
      </div>
    );
  }
}

export default DropzoneWrapper;
