import colors from '../../utils/colors';
import React from 'react';
import styled from 'styled-components';

import close_icon from './img/close.svg';

const csbContentPadding = 1;

export const Background = styled.div`
  background: ${colors.gray.solid_black};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  right: 0;
  min-height: 100%;
  opacity: 0;
  z-index: -1;
  transition: 300ms;
  &.csb-open {
    opacity: 0.5;
    z-index: 1000;
  }
`;

const bigbox_width = '75vh';
const invocice_width = '115vh';

export const Box = styled.div`
  z-index: 1001;
  background: ${colors.secondary.white};
  position: absolute;
  top: 0;
  right: -448px;
  width: 448px;
  height: 100vh;
  min-height: 100px;
  max-width: 100vw;
  vertical-align: middle;
  transition: 300ms;
  box-sizing: border-box;

  &.csb-open {
    right: 0 !important;

    .csb-bottom-container {
      right: inherit !important;
      transform: translateX(-${csbContentPadding}rem);
      z-index: 1002;

      button[type='link'] {
        padding-top: 10px;
      }
    }
  }

  &.invoice-bigbox {
    width: ${invocice_width} !important;
    right: -${invocice_width};

    .csb-bottom-container {
      width: ${invocice_width} !important;
      right: -${invocice_width};
    }
  }

  .csb-bottom-container {
    right: -448px;
    background-color: #f3f3f3;
    border-top: 1px solid #d9dfe8;
    height: 64px;
    bottom: 0;
    width: 448px;
    max-width: 100vw;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    transition: 200ms;
    box-sizing: border-box;

    section {
      width: 100%;
    }

    button {
      margin: 0;
    }
  }

  .sidebar-title-wrapper {
    position: relative;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .title-icon {
      max-height: 16px;
      margin-right: 8px;
    }
  }

  button[type='link'] {
    margin-left: 0 !important;
  }
`;

export const Bigbox = styled.div`
  z-index: 1001;
  background: ${colors.secondary.white};
  position: absolute;
  top: 0;
  right: -448px;
  width: 75vh;
  height: 100vh;
  min-height: 100px;
  max-width: 100vw;
  vertical-align: middle;
  transition: 300ms;
  box-sizing: border-box;

  &.csb-open {
    right: 0;

    .csb-bottom-container {
      right: inherit;
      transform: translateX(-${csbContentPadding}rem);
      z-index: 1002;

      button[type='link'] {
        padding-top: 10px;
      }
    }
  }

  .csb-bottom-container {
    right: -448px;
    background-color: #f3f3f3;
    border-top: 1px solid #d9dfe8;
    height: 64px;
    bottom: 0;
    width: 75vh;
    max-width: 100vw;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    transition: 200ms;
    box-sizing: border-box;

    section {
      width: 100%;
    }

    button {
      margin: 0;
    }
  }

  .sidebar-title-wrapper {
    position: relative;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .title-icon {
      max-height: 16px;
      margin-right: 8px;
    }
  }

  button[type='link'] {
    margin-left: 0 !important;
  }
`;

export const ContentAdapter = props => (
  <div className={`csb-content-adapter ${props.className ? props.className : ''}`}>
    {props.children}
  </div>
);

export const BottomContainer = props => (
  <div className={`csb-bottom-container ${props.className ? props.className : ''}`} style={props.style}>
    {props.children}
  </div>
);

export const BigBottomContainer = props => (
  <div className={`csb-bottom-container ${props.className ? props.className : ''}`}>
    {props.children}
  </div>
);

export const Content = styled.div`
  width: 100%;
  margin: 0;
  display: inline-block;
  height: calc(100vh - 64px);
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none !important;
  }
  .csb-content-adapter {
    padding-left: ${csbContentPadding}rem;
    padding-right: ${csbContentPadding}rem;

    &.top-space {
      padding-top: 0.75rem;
    }

    .section-separator,
    .csb-adjust-width {
      width: calc(100% + ${csbContentPadding * 2}rem);
      margin-left: -${csbContentPadding}rem;
    }

    .csb-spacer {
      position: relative;
      display: block;
      width: 100%;
      height: 12px;
    }
  }
  .section-separator {
    background-color: #f3f3f3;
    color: #303746;
    position: relative;
    padding: 0.5rem 1rem;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.8rem;
    font-weight: bolder;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    z-index: 5;
    &.adjust-top {
      margin-top: 0;
    }
    img {
      height: 12px;
    }
    .right-link {
      margin: 0;
      float: right;
      position: relative;
    }
    i.icon-right {
      float: right;
    }
    .dd-container {
      position: absolute;
      right: 0;
      top: calc(100% + 4px);
      width: 280px;
      max-height: 0;
      box-shadow: none;
      border-radius: 4px;
      height: auto;
      transition: 200ms;
      overflow: hidden;
      background-color: white;
      &.open {
        max-height: 220px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 12px 7px rgba(208, 208, 208, 0.48);
      }
      > section > div {
        padding: 0 0.5rem;
        [type='microHeader'] {
          color: #778092;
        }
        button[type='link'] {
          margin: 0;
          font-size: 8pt;
          padding-top: 1.5rem;
        }
      }
    }
  }
`;

export const Spacer = styled.div`
  position: relative;
  width: 100%;
  height: 64px;
  display: block;
`;

export const Title = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  font-family: Muli;
  font-size: 14px;
  font-weight: bolder;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  padding: calc(1rem + 5px) 0;
  box-sizing: border-box;
  float: right;
  color: ${colors.newcolors.mini_title};
  text-align: left;
`;

export const CloseIcon = styled.div`
  height: 16px;
  width: 16px;
  background-size: cover;
  background-position: center;
  background-image: url(${close_icon});
  margin-left: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
`;

export const TitleLine = styled.div`
  position: absolute;
  background-color: ${colors.newcolors.light_gray};
  bottom: 0px;
  left: 0;
  height: 1px;
  width: 100%;
  display: block;
`;

export const Separator = styled.div`
  background-color: ${colors.newcolors.light_gray};
  bottom: 0px;
  left: 0;
  height: 1px;
  width: 100%;
  display: block;
`;
