import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { boundMethod } from "autobind-decorator";

import { Row, Column } from "hedron";
import Skeleton from "react-loading-skeleton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BASEURL } from "../../../constants/index";
import { showMessage, hideMessage } from "../../../modules/message";
import { loadInvoice } from "../../../modules/Invoices/actions";

import ReactTooltip from "react-tooltip";

import {
  getVolumetricWeight,
  numberWithCommas,
  searchPermission,
} from "../../../utils/global_functions";
import Text, { P } from "../../Text";

import "../style.css";
import { NEW_SHIPMENT } from "../../../modules/CollapsibleBar/sideBarTypes";

import from_icon from "./icons/origen_icon.svg";
import to_icon from "./icons/destino_icon.svg";
import package_icon from "./icons/paquete_icon.svg";
import tracking_icon from "./icons/rastreo_icon.svg";
import provider_icon from "./icons/servicio_icon.svg";
import substract_icon from "./icons/substract_icon.svg";
import detail_eye_icon from "./icons/detail-eye.svg";
import Button from "../../Button";
import Input from "../../Input";
import { purposesWithTracking } from "../index";
import { BottomContainer, Spacer } from "../../WrapperSideBar/styledObjects";

import {
  editExistingOrder,
  setStep,
} from "../../../modules/sidebarOrder/actions";
import { toggle } from "../../../modules/CollapsibleBar/actions";
import { changeDashboardGlobalStatus } from "../../../modules/dashboard/actions";
import "../../../utils/index.css";
import "./index.css";
import colors from "../../../utils/colors";
import moment from "moment";

import es from "date-fns/locale/es";
import Message from "../../Message";
moment.locale("es");

const manual_label = "MANUAL_LABEL";

class DetailedOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventsOpen: false,
      hasOrders: false,
      hasLabel: false,
      isManualLabel: false,
      shareTrackingLink: false,
      copyLabel: "Copiar link",
      copyLabel2: "Copiar link",
      beenDelivered: false,
      isLocal: false,
      agended: false,
      isOwner: false,
      incidences: null,
    };
  }

  async componentWillReceiveProps(newProps) {
    this.setState({
      hasOrders:
        typeof newProps.order.details === "object" &&
        Object.keys(newProps.order.details).length,
      hasLabel:
        purposesWithTracking.indexOf(newProps.order.details.object_purpose) >
        -1,
      eventsOpen: false,
      id: newProps.order.details.object_id,
      beenDelivered: newProps.order.details.object_purpose === "ENTREGADO",
      isManualLabel: newProps.order.details.object_purpose === manual_label,
      isLocal:
        newProps.order.details.rate && newProps.order.details.rate.provider
          ? newProps.order.details.rate.provider === "99 Minutos" ||
            newProps.order.details.rate.provider === "Chazki"
          : false,
      isOwner:
        newProps.order.details.rate &&
        newProps.order.details.rate.owner_contract
          ? newProps.order.details.rate.owner_contract
          : "",
      agended:
        newProps.order.details.object_purpose !== "LABEL_CREATED" &&
        newProps.order.details.object_purpose !== "ORDER_CREATED",
    });
    await axios
      .get(`/api/shipment/incidences/${newProps.order.details.object_id}`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ incidences: response.data });
        }
      });
  }

  toggleShareMenu = () => {
    this.setState({ shareTrackingLink: !this.state.shareTrackingLink });
    setTimeout(() => this.setState({ copyLabel: "Copiar link" }), 200);
  };

  _editInsurance = () => {
    this.props.editExistingOrder(this.props.order);
    this.props.toggle(
      true,
      `Editar órden #${this.props.order.details.object_id}`,
      NEW_SHIPMENT
    );
    this.props.setStep(2);
  };

  @boundMethod
  processOrder() {
    const model = {
      shipments: [this.state.id],
      source_type: "web_portal",
      charge_type: "SHIPMENTS_AND_OVERWEIGHT",
      overweight_payment: "0.00",
      payment: {
        provider: "owner_contract",
      },
    };
    axios
      .post("/api/purchases", model)
      .then(async (response) => {
        this.props.loadInvoice(response.data);
        this.props.showMessage("success", "Pago realizado exitosamente");

        this.setState({ loadingBtn: false });
        this.props.executeShipment();
      })
      .catch((err) => {
        this.setState({ loadingBtn: false });
      });
  }

  @boundMethod
  processOrderCredit() {
    const model = {
      shipments: [this.state.id],
      source_type: "web_portal",
      charge_type: "SHIPMENTS_AND_OVERWEIGHT",
      overweight_payment: "0.00",
    };
    axios
      .post("/api/purchases", model)
      .then(async (response) => {
        this.props.loadInvoice(response.data);
        this.props.showMessage("success", "Pago realizado exitosamente");

        this.setState({ loadingBtn: false });
        this.props.executeShipment();
      })
      .catch((err) => {
        this.setState({ loadingBtn: false });
      });
  }

  render() {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const {
      label,
      rate,
      address_from,
      address_to,
      overweight_charge,
      weight,
      height,
      length,
      width,
      weight_sent,
      insurance,
      description,
      declared_value,
      currency,
      object_id,
      updated_at,
      object_purpose,
    } = this.props.order.details;

    const {
      hasOrders,
      hasLabel,
      isManualLabel,
      beenDelivered,
      eventsOpen,
      copyLabel,
      copyLabel2,
      shareTrackingLink,
      isLocal,
      agended,
    } = this.state;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    return (
      <div className="detailed-order-container">
        {hasLabel && label ? (
          <div className="section-separator adjust-top">
            <img src={tracking_icon} /> Rastreo
            <Button
              type="link"
              className="right-link"
              onClick={this.toggleShareMenu}
            >
              Compartir <i className="ion-android-share-alt" />
            </Button>
            <div className={`dd-container ${shareTrackingLink ? "open" : ""}`}>
              <Row>
                <Column xs={12} fluid>
                  <Text type="microHeader">
                    Copia el link y comparte el rastreo en tiempo real
                  </Text>
                </Column>
                <Column xs={8} fluid>
                  <Input
                    onChange={() => {}}
                    disabled
                    value={`${BASEURL}tracking/${label.tracking_number}/${rate.provider}`}
                  />
                </Column>
                <Column xs={4} fluid>
                  <CopyToClipboard
                    text={`${BASEURL}tracking/${label.tracking_number}/${rate.provider}`}
                    onCopy={() => this.setState({ copyLabel: "Link copiado" })}
                  >
                    <Button type="link">{copyLabel}</Button>
                  </CopyToClipboard>
                </Column>
                <Column xs={12} fluid>
                  <Text type="microHeader">
                    O consulta el tracking en la pagina de la paqueteria
                  </Text>
                </Column>
                <Column xs={8} fluid>
                  <Input
                    onChange={() => {}}
                    disabled
                    value={`${label.tracking_url}`}
                  />
                </Column>
                <Column xs={4} fluid>
                  <CopyToClipboard
                    text={`${label.tracking_url}`}
                    onCopy={() => {
                      window.open(label.tracking_url);
                      this.setState({ copyLabel2: "Link copiado" });
                    }}
                  >
                    <Button type="link">{copyLabel2}</Button>
                  </CopyToClipboard>
                </Column>
              </Row>
            </div>
          </div>
        ) : (
          <div className="section-separator adjust-top">
            <img src={from_icon} /> Origen
          </div>
        )}
        {hasOrders ? (
          <div className={hasLabel ? "indented-address passed" : ""}>
            {hasLabel && <Text type="microHeader">Origen</Text>}
            <P>{address_from.name}</P>
            <P>{address_from.email}</P>
            <P>{address_from.phone}</P>
            <P>{address_from.street}</P>
            <P>{address_from.street2}</P>
            <P>{address_from.reference}</P>
            <P>{address_from.zipcode}</P>
            <P>
              {`${address_from.city}, ${address_from.state}, ${address_from.country}`}
            </P>
          </div>
        ) : (
          <Skeleton count={6} />
        )}

        {hasLabel && (
          <div className="indented-address passed">
            <Text type="microHeader">Guía generada</Text>
            {label && <P>#{label.tracking_number}</P>}
          </div>
        )}

        {hasLabel && (
          <div
            className={`indented-address ${
              eventsOpen || beenDelivered ? "passed" : ""
            }`}
          >
            <Text type="microHeader">Recolectado/Escaneado</Text>
            <a
              href="https://blogger.mienvio.mx/centro-ayuda/recoleccion-mienvio/"
              target="_blank"
            >
              <Button type="small_blue">
                {isLocal
                  ? "Recolección agendada automaticamente"
                  : agended
                  ? "Recolección Agendada"
                  : "Agendar recolección"}
              </Button>
            </a>
          </div>
        )}

        {this.props.events.length > 0 && (
          <div className="seeMore">
            <div className="dot" />
            <div className="dot" />
            <div className="dot" />
            <Button
              type="link"
              onClick={() => {
                this.setState({ eventsOpen: !eventsOpen });
              }}
            >
              {eventsOpen
                ? "Ocultar actualizaciones"
                : "Ver todas las actualizaciones"}
              <i
                className={eventsOpen ? "ion-arrow-up-b" : "ion-arrow-down-b"}
              />
            </Button>
          </div>
        )}

        <div
          className={`events-container ${eventsOpen ? "open" : ""}`}
          style={{
            maxHeight: eventsOpen ? 75 * this.props.events.length + 200 : 0,
          }}
        >
          {this.props.events.length > 0 &&
            this.props.events.map((event, index) => (
              <div
                key={index}
                className={`indented-address ${
                  event.code == "incident" || event.is_in_return_process
                    ? "incident"
                    : "passed"
                } `}
              >
                <Text type="microHeader">{`${event.date} | ${event.time}`}</Text>
                <P>
                  {event.is_in_return_process ? "Devolución: " : ""}
                  {event.description}
                </P>
              </div>
            ))}
        </div>

        {!hasLabel && (
          <div className="section-separator">
            <img src={to_icon} /> Destino
          </div>
        )}

        {hasOrders ? (
          <div
            className={`${hasLabel ? "indented-address" : ""} ${
              beenDelivered ? "passed" : ""
            } without-track`}
          >
            {hasLabel ? <Text type="microHeader">Destino</Text> : null}
            <P>{address_to.name}</P>
            <P>{address_to.email}</P>
            <P>{address_to.phone}</P>
            <P>{address_to.street}</P>
            <P>{address_to.street2}</P>
            <P>{address_to.reference}</P>
            <P>{address_to.zipcode}</P>
            <P>
              {`${address_to.city}, ${address_to.state}, ${address_to.country}`}
            </P>
          </div>
        ) : (
          <Skeleton count={6} />
        )}
        <div className="section-separator">
          <img src={package_icon} /> Paquete
        </div>
        {overweight_charge && (
          <Row>
            <Column className="helper-vol-text" xs={12} fluid>
              <p className="left">
                Paquete con sobrepeso registrado:
                {weight_sent - weight}
                kg - ${overweight_charge}{" "}
              </p>
              <a
                href={label.tracking_url}
                target="_blank"
                className="right"
                type="link"
              >
                <i className="ion-android-open" />
              </a>
            </Column>
          </Row>
        )}
        <Row>
          <Column xs={12} fluid>
            {hasOrders ? (
              <div>
                <Text type="microHeader">Dimensiones</Text>
                <P>{`${height} x ${width} x ${length}`}</P>
              </div>
            ) : (
              <Skeleton count={2} width="60%" />
            )}
          </Column>
          <Column xs={4} fluid>
            {hasOrders ? (
              <div>
                <Text type="microHeader">Peso</Text>
                <Text type="inputFilled" className="weight-number">
                  {weight}
                </Text>
              </div>
            ) : (
              <Skeleton count={2} width="60%" />
            )}
          </Column>
          <Column xs={4} fluid>
            {hasOrders ? (
              <div>
                <Text type="microHeader">Peso Volumétrico</Text>
                <Text type="inputFilled" className="weight-number">
                  {getVolumetricWeight(height, width, length)}
                </Text>
              </div>
            ) : (
              <Skeleton count={2} width="72%" />
            )}
          </Column>
          <Column xs={4} fluid>
            {hasOrders ? (
              weight_sent && (
                <div>
                  <Text type="microHeader">Peso Enviado</Text>
                  <Text type="inputFilled">{weight_sent}</Text>
                </div>
              )
            ) : (
              <Skeleton count={2} width="60%" />
            )}
          </Column>
          {!insurance && hasOrders && (
            <div>
              <Text type="microHeader">Información del seguro</Text>
              <P>Sin asegurar</P>
            </div>
          )}
          <Column xs={12} fluid>
            {hasOrders ? (
              <div>
                <Text type="microHeader">Contenido del paquete</Text>
                <P>{description}</P>
              </div>
            ) : (
              <Skeleton count={2} width="65%" />
            )}
          </Column>
          <Column xs={12} fluid>
            {hasOrders ? (
              <div>
                <Text type="microHeader">Valor del paquete</Text>
                <P>
                  {`$${numberWithCommas(
                    parseFloat(declared_value).toFixed(2)
                  )} ${currency}`}
                </P>
              </div>
            ) : (
              <Skeleton count={2} width="65%" />
            )}
          </Column>

          {hasOrders && insurance && (
            <Column xs={12} fluid>
              {insurance?.insurance_folio && (
                <div>
                  <Text type="microHeader">Póliza de seguro</Text>
                  <P>{`${insurance?.insurance_folio}`}</P>
                </div>
              )}
            </Column>
          )}
        </Row>
        <div className="section-separator">
          <img src={provider_icon} /> Servicio
        </div>
        <Row>
          <Column xs={1} fluid>
            {hasOrders ? (
              rate ? (
                <img
                  src={`https://production.mienvio.mx${rate.provider_img}`}
                  width="32"
                />
              ) : (
                <P>No disponible</P>
              )
            ) : (
              <Skeleton circle height={32} width={32} />
            )}
          </Column>
          <Column xs={1} fluid />
          <Column xs={10} fluid>
            {hasOrders ? (
              rate && (
                <div>
                  {/* <P>{rate.provider}</P> */}
                  <P>{rate.provider === "FedEx" ? "FDX" : rate.provider}</P>
                  <P>{rate.duration_terms}</P>

                  {rate.days &&
                    updated_at &&
                    label &&
                    object_purpose !== "ENTREGADO" && (
                      <P
                        style={{
                          marginBottom: "0.5em",
                        }}
                      >
                        Entrega estimada:{" "}
                        {moment().isBefore(moment(updated_at))
                          ? moment(updated_at)
                              .add(rate.days, "days")
                              .format("DD MMMM")
                          : moment().add(rate.days, "days").format("DD MMMM")}
                      </P>
                    )}

                  {rate.is_ocurre && (
                    <P
                      style={{
                        marginBottom: "0.5em",
                      }}
                    >
                      Servicio ocurre
                    </P>
                  )}

                  {!insurance ? (
                    <P>
                      <b>
                        {`$${numberWithCommas(
                          parseFloat(rate.amount).toFixed(2)
                        )} ${rate.currency}`}
                      </b>
                    </P>
                  ) : (
                    <P>
                      <b>
                        {`$${numberWithCommas(
                          parseFloat(rate.amount + insurance.cost).toFixed(2)
                        )} ${rate.currency}`}
                      </b>
                    </P>
                  )}

                  {!hasLabel ? (
                    hasOrders && insurance ? (
                      <Button type="link" onClick={this._editInsurance}>
                        <img
                          src={substract_icon}
                          style={{ height: "14px", marginRight: "4px" }}
                        />{" "}
                        Envío asegurado por $
                        {parseFloat(insurance.cost).toFixed(2)}
                      </Button>
                    ) : this.props.order.name_purchase &&
                      this.props.order.name_purchase === "QUOTE" ? (
                      <div />
                    ) : this.props.order.name_purchase ===
                      "MONTHLY_PAYMENT_PENDING" ? (
                      <div />
                    ) : (
                      searchPermission("envios", "editar", permissions) && (
                        <Button type="link" onClick={this._editInsurance}>
                          + Agregar seguro
                        </Button>
                      )
                    )
                  ) : (
                    hasOrders &&
                    insurance && (
                      <div className="details-price">
                        <Text type="microHeaderBig">
                          Guía:
                          <span>
                            {`$${numberWithCommas(
                              parseFloat(rate.amount).toFixed(2)
                            )} ${rate.currency}`}
                          </span>
                        </Text>
                        <Text type="microHeaderBig">
                          Seguro:
                          <span>${parseFloat(insurance.cost).toFixed(2)}</span>
                        </Text>
                      </div>
                    )
                  )}
                </div>
              )
            ) : (
              <Skeleton count={3} width="65%" />
            )}
          </Column>

          {object_purpose && label && object_purpose !== "ENTREGADO" && (
            <Column xs={12} style={{ padding: 0, marginTop: "1rem" }}>
              <Message color="warning" showClose={false}>
                Fecha de entrega aproximada y sujeta a cambios. Los tiempos de
                entrega dependen totalmente de la paquetería seleccionada.
              </Message>
            </Column>
          )}
        </Row>
        <div className="section-separator">
          <img src={detail_eye_icon} /> Incidencias
        </div>
        {this.state.incidences ? (
          <Row>
            {this.state.incidences.length > 0 ? (
              this.state.incidences.map((element) => (
                <div key={element.id}>
                  <Text type="microHeader">
                    Incidencia con fecha: {element.created_at}
                  </Text>
                  <P>{element.message}</P>
                </div>
              ))
            ) : (
              <P>No hay ninguna incidencia</P>
            )}
          </Row>
        ) : (
          <Skeleton count={1} />
        )}
        <Spacer />
        <BottomContainer>
          {hasOrders && (
            <Row>
              <Column xs={6} className="text-left" fluid>
                <br />
                {
                  // TODO
                  // hasLabel
                  // ? <Button type="link" onClick={ () => alert("Not working yet") }>Agregar a descarga</Button>
                  // : <Button type="link" onClick={ () => alert("Not working yet") }>Agregar a pago</Button>
                }
              </Column>
              <Column xs={6} className="text-right" fluid>
                {this.state.isOwner && !hasLabel ? (
                  <Button onClick={() => this.processOrder()}>Procesar</Button>
                ) : this.props.user.has_credit && !hasLabel ? (
                  <Button onClick={() => this.processOrderCredit()}>
                    Generar Guía
                  </Button>
                ) : hasLabel ? (
                  label ? (
                    <Button
                      onClick={() => window.open(label.label_url, "blank")}
                    >
                      Descargar Guía
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        alert(
                          "Tu guía presenta problemas. Comunícate con nuestro equipo de soporte."
                        )
                      }
                    >
                      Descargar Guía
                    </Button>
                  )
                ) : (
                  <div>
                    {!isManualLabel &&
                      searchPermission("envios", "editar", permissions) &&
                      !this.props.user.methodPayments.includes("none") && (
                        <Button
                          onClick={() => this.props.goToPayment(object_id)}
                        >
                          Pagar
                        </Button>
                      )}
                  </div>
                )}
              </Column>
            </Row>
          )}
        </BottomContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editExistingOrder,
      toggle,
      setStep,
      changeDashboardGlobalStatus,
      goToDashboardCheckout: () => push("/envios"),
      showMessage,
      hideMessage,
      loadInvoice,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DetailedOrder);
