import React, { useState, useMemo, useEffect } from "react";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import Button from "../../Button";
import { Column, Row } from "hedron";
import Select from "react-select";
import styled from "styled-components";
import { Calendar, DateRange, DateRangePicker } from "react-date-range";
import axios from "axios";
import { CSVLink } from "react-csv";
import DatePicker, { registerLocale } from "react-datepicker";

import {
  BottomContainer,
  ContentAdapter,
} from "../../WrapperSideBar/styledObjects";
import Input, { Label, Required } from "../../Input";
import { Radio, RadioGroup } from "../../Radio";
import moment from "moment";
import colors from "../../../utils/colors";
import Message from "../../Message";

export default function ModalFiltersCSV() {
  const [isLoading, setIsLoading] = useState(false);
  const [shipmentsData, setShipmentsData] = useState(null);
  const [fileName, setFileName] = useState("");
  const [message, setMessage] = useState({});
  // const [startDate, setStartDate] = useState(new Date());
  // ID
  const [productId, setProductId] = useState("");
  // PROVIDER
  const [selectedProvider, setSelectedProvider] = useState(null);
  // DATES
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    initialDates();
  }, []);

  useEffect(() => {
    hideMessage();
    setShipmentsData(null);
    setIsLoading(false);
  }, [productId, selectedProvider, startDate, endDate]);

  const initialDates = () => {
    function changeDays(fecha, dias) {
      fecha.setDate(fecha.getDate() + dias);
      return fecha;
    }

    const final = new Date();
    let current = new Date();
    const start = changeDays(current, -7);
    setStartDate(start);
    setEndDate(final);
  };

  const getFile = () => {
    hideMessage();
    setIsLoading(true);

    let body = {
      start_date: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
    };

    if (productId !== "") {
      body.product_id = productId;
    }

    if (selectedProvider !== null) {
      if (selectedProvider.value > 0) {
        body.provider = selectedProvider?.label;
      }
    }

    let newFileName = "reporte_envios_";

    newFileName += Date.now().toString().substr(-5);

    setFileName(newFileName);

    // return setIsLoading(false);
    axios
      .post(`/api/shipmentsPurchases/purchases_report`, body)
      .then(({ data, status }) => {
        if (status !== 200) throw new Error();

        const formatedList = data.map((item) => {
          return {
            ...item,
            to_address: item.to_address.replace(/(\r\n|\n|\r)/gm, ""),
            statusText: statusText(item.status),
            massive_load_id: isNaN(item.massive_load_id)
              ? ""
              : item.massive_load_id,
          };
        });

        setShipmentsData(formatedList);
        showMessage(true, formatedList.length);
      })
      .catch(() => showMessage())
      .finally(() => {
        setIsLoading(false);
      });
  };

  const showMessage = (success, files) => {
    if (success) {
      let text = `Se encontraron ${files} envíos.`;
      text += files > 0 ? ` Da click en descargar` : "";
      return setMessage({
        text: text,
        show: true,
        color: files > 0 ? "success" : "warning",
      });
    }
    setMessage({
      text: "Error al generar el archivo",
      show: true,
      color: "error",
    });
  };

  const hideMessage = () => setMessage({});

  const cleanFilters = () => {
    hideMessage();
    setIsLoading(false);
    setShipmentsData(null);
    setSelectedProvider(null);
    initialDates();
    setProductId("");
  };

  const cleanDateFilters = () => {};

  const availableServices = [
    { value: 0, label: "Sin Filtro" },
    { value: 1, label: "FedEx" },
    { value: 2, label: "Chazki" },
    { value: 3, label: "Redpack" },
    { value: 4, label: "Estafeta" },
    { value: 5, label: "99 Minutos" },
    { value: 6, label: "Flecha Amarilla" },
    { value: 7, label: "DHL" },
  ];

  const headers = useMemo(() => {
    let headers = [
      { label: "# Orden", key: "order_id" },
      { label: "Id interno", key: "massive_load_id" },
      { label: "Fecha", key: "date" },
      { label: "Destinatario", key: "receiver" },
      { label: "Destino", key: "to_address" },
      { label: "Servicio", key: "service" },
      { label: "Estatus", key: "statusText" },
      { label: "Total", key: "total" },
      { label: "Contenido", key: "content" },
      { label: "Nº Guía", key: "tracking_number" },
      { label: "Traking URL", key: "tracking_url" },
    ];

    if (shipmentsData?.shipmentsData) {
      headers.push({ label: "Id de producto", key: "product_id" });
    }

    return headers;
  }, [shipmentsData]);

  return (
    <ContentAdapter>
      <div
        style={{
          marginTop: "12px",
          marginBottom: "16px",
          borderBottom: "1px solid #D8D8D8",
          paddingBottom: "12px",
        }}
      >
        <Label>
          Rango de fechas y hora de compra <Required />{" "}
        </Label>

        <BoxDateTime>
          <Label>Fecha inicial:</Label>

          <DatePicker
            selected={startDate}
            onChange={setStartDate}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            locale="es"
          />
        </BoxDateTime>
        <BoxDateTime>
          <Label>Fecha final:</Label>

          <DatePicker
            selected={endDate}
            onChange={setEndDate}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            locale="es"
          />
        </BoxDateTime>
      </div>
      <Input
        label="ID de producto"
        placeholder="Id de producto"
        value={productId}
        onChange={setProductId}
        type="number"
      />
      <div style={{ marginTop: "12px" }}>
        <LabelContainer>
          <Label>Servicio</Label>
        </LabelContainer>

        <div style={{ marginTop: "12px" }}>
          <Select
            onChange={setSelectedProvider}
            options={availableServices}
            placeholder="Selecciona un servicio"
            value={selectedProvider}
          />
        </div>
      </div>
      <br />
      {message.show && (
        <Message onClose={hideMessage} color={message.color}>
          {" "}
          {message.text}
        </Message>
      )}
      <BottomContainer>
        <Column xs={6}>
          <Button onClick={cleanFilters} type="table">
            Limpiar
          </Button>
        </Column>
        <Column xs={6} style={{ textAlign: "right" }} fluid>
          {shipmentsData !== null ? (
            <DownloadBtn onClick={hideMessage}>
              <CSVLink
                headers={headers}
                className="btn-download-csv"
                filename={fileName}
                data={shipmentsData}
              >
                Descargar CSV
              </CSVLink>
            </DownloadBtn>
          ) : (
            <Button loading={isLoading} onClick={getFile}>
              Generar
            </Button>
          )}
        </Column>
      </BottomContainer>
    </ContentAdapter>
  );
}

const DownloadBtn = styled.div`
  & .btn-download-csv {
    color: white;
    background-color: ${colors.primary.mienvio_pink}
    padding: 12px 16px;
    border-radius: 6px;
  }
`;

const BoxDateTime = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & p {
    margin: 0;
  }

  & input {
    margin-left: 1.5em;
    outline: none !important;
    padding: 1em;
    border: 1.5px solid #cad2df;
    width: 100%;
    font-family: "Muli";
    height: 36px;
    max-height: 36px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    border-radius: 2px;

    &:focus {
      border-color: ${colors.state.blue};
      background-color: ${colors.secondary.ultralight_blue};
    }
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const statusText = (status) => {
  switch (status) {
    case "PURCHASE":
      return "Guía sin pago";
    case "LABEL_CREATED":
      return "Guía generada";
    case "PICKED_UP":
      return "Recolectado";
    case "OUT_FOR_DELIVERY":
      return "En ruta de entrega";
    case "TRANSITO":
      return "En transito";
    case "RETORNO_EN_PROCESO":
      return "Retorno en proceso";
    case "CANCELADO":
      return "Cancelado";
    case "INCIDENCIA":
      return "Incidencia";
    default:
      return status;
  }
};
