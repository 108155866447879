import React from 'react';
import icon_origin from './origin.svg';
import icon_destination from './destination.svg';
import icon_ensurance from './ensurance.svg';
import icon_carrier from './carrier.svg';
import icon_package from './package.svg';
import icon_storefront from './storefront.svg';
import printer_icon from './printer.svg';
import email from './email.svg';
import shipments from './shipments.svg';
import quotations from './quotations.svg';
import packages from './packages.svg';
import addresses from './addresses.svg';
import invoices from './invoices.svg';
import payment from './payment.svg';
import config from './config.svg';

import './style.css';

const icons = {
  origin: icon_origin,
  destination: icon_destination,
  ensurance: icon_ensurance,
  carrier: icon_carrier,
  package: icon_package,
  storefront: icon_storefront,
  printer_icon,
  email,
  shipments,
  quotations,
  packages,
  addresses,
  invoices,
  payment,
  config
};

const AutomationIcon = props => (
  <div className="automation-icon-container">
    <img src={icons[props.icon]} />
  </div>
);

export default AutomationIcon;
