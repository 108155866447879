import { initialState } from './initialState';
import { TOGGLE_SIDEBAR, CHANGE_SIDEBAR_TITLE } from './actionsTypes';

export default (state = initialState, action) => {
  let open, title, type;
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      action.payload._callback();
      return {
        ...state,
        open: action.payload.open,
        title: action.payload.title,
        type: action.payload.type
      };
    case CHANGE_SIDEBAR_TITLE:
      return {
        ...state,
        title: action.payload.title
      };
    default:
      return state;
  }
};
