import React from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

let today = new Date();
let tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);

class Sandbox extends React.Component {
  state = {
    selectionRange: {
      selection: {
        startDate: today,
        endDate: tomorrow,
        key: "selection",
      },
    },
  };

  handleSelect = (ranges) => {
    this.setState({
      selectionRange: ranges,
    });
  };

  render() {
    return (
      <DateRange
        ranges={[this.state.selectionRange.selection]}
        onChange={this.handleSelect}
      />
    );
  }
}

export default Sandbox;
