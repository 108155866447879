import { createSelector } from "reselect";
import moment from "moment";
import compact from "lodash/compact";
import uniq from "lodash/uniq";

const purchaseSelector = (state) => state.shipments.purchase;
const payedSelector = (state) => state.shipments.payed;
const ordersSelector = (state) => state.shipments.orders;

export const unpayedShipmentsSelector = createSelector(
  purchaseSelector,
  ordersSelector,
  (shipments = [], orders = []) =>
    [...shipments, ...orders]
      .sort((a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at)))
      .reverse()
);

export const incompleteShipmentsSelector = createSelector(
  unpayedShipmentsSelector,
  (shipments) => shipments.filter((s) => !s.rate)
);

export const completeShipmentsSelector = createSelector(
  unpayedShipmentsSelector,
  (shipments) => shipments.filter((s) => s.rate)
);

export const shipmentsProvidersSelector = createSelector(
  unpayedShipmentsSelector,
  (shipments) => {
    return uniq(
      compact(
        shipments.map((s) => {
          if (!s.rate) return;
          return `${s.rate.provider} ${s.rate.servicelevel}`;
        })
      )
    ).sort();
  }
);
