import * as types from "./types";

const parseOrders = (orders) =>
  orders.map((o) => {
    const newOrders = {
      id: o.object_id,
      status: "PURCHASE",
      date: o.created_at,
      from_name: null,
      from_street: "|",
      to_name: `${o.shipping.first_name} ${o.shipping.last_name}`,
      to_street: `${o.shipping.street} ${o.shipping.street2}|C.P. ${o.shipping.zipcode} ${o.shipping.city} ${o.shipping.state}`,
      amount: null,
      provider_name: null,
      service_level: null,
      tracking_number: null,
      marketplace: o.marketplace,
      has_overweight: 0,
      legacy: o,
    };

    return newOrders;
  });

export const initialState = {
  payed: [],
  purchase: [],
  orders: [],
  created: null,
  addressto: null,
  addressfrom: null,
  archived: [],
  filters: [],
  appendingFilter: false,
  quotes: [],
  shipmentsModal: false,
};

const shipments = (state = initialState, action) => {
  let filters;
  switch (action.type) {
    case types.GET:
      return {
        ...state,
        payed: action.shipments,
      };
    case types.GET_PURCHASE:
      return {
        ...state,
        purchase: action.shipments,
      };
    case types.GET_ORDER:
      return {
        ...state,
        orders: action.shipments,
      };
    case types.APPEND_ALL:
      return {
        ...state,
        payed: action.shipments.shipments.payed,
        purchase: action.shipments.shipments.non_payed,
        archived: action.shipments.shipments.archived,
        orders: parseOrders(action.shipments.shipments.orders),
        appendingFilter: false,
      };
    case types.GET_ALL:
      return {
        ...state,
        payed: action.shipments.shipments.payed,
        purchase: action.shipments.shipments.non_payed,
        archived: action.shipments.shipments.archived,
        orders: parseOrders(action.shipments.shipments.orders),
        appendingFilter: false,
      };
    case types.GET_ALL_QUOTES:
      return {
        ...state,
        quotes: action.quotes,
      };
    case types.SET_CREATED:
      return {
        ...state,
        created: action.createdId,
      };
    case types.CLEAR_CREATED:
      return {
        ...state,
        created: null,
      };
    case types.SET_FROM_ADDRESS:
      return {
        ...state,
        addressfrom: action.fromaddress,
      };

    case types.SET_TO_ADDRESS:
      return {
        ...state,
        addressto: action.toaddress,
      };
    case types.APPEND_FILTER:
      filters = state.filters || [];
      filters.push(action.filter);
      return {
        ...state,
        filters,
        appendingFilter: true,
      };
    case types.DELETE_FILTER:
      filters = state.filters;
      filters.splice(action.index, 1);
      return {
        ...state,
        filters,
        appendingFilter: true,
      };
    case types.CLEAR_FILTERS:
      return {
        ...state,
        filters: [],
        appendingFilter: true,
      };
    case types.SHOW_SHIPMENTS_MODAL:
      return {
        ...state,
        shipmentsModal: action.flag,
      };
    default:
      return state;
  }
};

export default shipments;
