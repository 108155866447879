// @flow
import * as React from "react";
import axios from "axios";
import { Row, Column } from "hedron";
import { Route, Switch } from "react-router-dom";
import capitalize from "lodash/capitalize";
import isEmail from "validator/lib/isEmail";
import Swal from "sweetalert2";
import Message from "../../components/Message";
import Menu from "../../components/Menu";
import Text, { Title } from "../../components/Text";
import Button from "../../components/Button";
import EmptyState from "../../components/EmptyState";
import ShipmentsList from "../../components/ShipmentsList";
import ShipmentDetail from "../../components/ShipmentDetail";
import Create from "../../components/Create";
import Checkout from "../../components/Checkout";
import { CheckboxGroup, Checkbox } from "../../components/Checkbox";
import { RadioGroup, Radio } from "../../components/Radio";

import { StyledRate, StyledDot } from "./styled-components";
import { PackageOption } from "./PackageOption";
import ShipmentFields from "./ShipmentFields";
import type {
  MessageType,
  CustomAddressType,
  DimensionType,
} from "../../constants/customTypes";
import { formatPhone } from "../../utils/global_functions";

const initialState = {
  address: {},
  cost: "",
  location: "",
  shipmentsToPay: [],
  selectedType: 0,
  shipmentsToPayObject: {},
  step: 0,
  nextSteps: 0,
  nextStepsShow: false,
  selectedAddressFrom: null,
  selectedAddressTo: null,
  selectedAddressFromTitle: null,
  selectedAddressToTitle: null,
  selectedPackage: null,
  selectedPackageTitle: null,
  order: null,
  filterTo: "",
  filterFrom: "",
  filterPackage: "",
  from: {
    name: "",
    nameError: "",
    email: "",
    emailError: "",
    phone: "",
    phoneError: "",
    street: "",
    streetError: "",
    street2: "",
    street2Error: "",
    reference: "",
    referenceError: "",
    zipcode: "",
    zipcodeError: "",
    location: "",
  },
  to: {
    name: "",
    nameError: "",
    email: "",
    emailError: "",
    phone: "",
    phoneError: "",
    street: "",
    streetError: "",
    street2: "",
    street2Error: "",
    reference: "",
    referenceError: "",
    zipcode: "",
    zipcodeError: "",
    location: "",
  },
  dimensions: {
    length: "",
    width: "",
    height: "",
  },
  dimensionsError: "",
  volumetricWeightError: "",
  weight: "",
  weightError: "",
  providersFilter: [],
  speedFilter: [],
  rate: {},
  description: "",
  descriptionError: "",
  insuredAmount: "",
  insuredAmountError: "",
  holder: "",
  holderError: "",
  email: "",
  emailError: "",
  insuranceSelected: "1",
  isOverweight: true,
  sendingNew: false,
};

type Props = {
  shipments: any,
  addresses: any,
  rates: {
    results: {
      provider: string,
      duration_terms: string,
      object_id: string,
      provider_img: string,
      servicelevel: string,
      amount: string,
      extended_zone: string,
      servicelevel: string,
      duration_terms: string,
    }[],
  },
  message: MessageType,
  user: string,
  packages: any,
  createdType: string,
  location: any,
  user: any,

  goToShipmentsList: () => void,
  goToPayment: () => void,
  goToCreateShipment: () => void,
  goToShipmentSetUp: () => void,
  showMessage: (type: string, mesage: string) => void,
  hideMessage: () => void,
  showLoader: (type: string) => void,
  setCreatedType: (type?: any) => void,
  getAllUserShipments: () => Promise<any>,
  getUserAllPackages: () => void,
  createRates: (
    fromZipcode: string,
    toZipcode: string,
    weight: number,
    length: number,
    width: number,
    height: number
  ) => Promise<any>,
  savePrimaryAddress: (from: CustomAddressType) => Promise<any>,
  createShipment: (
    from: CustomAddressType,
    to: CustomAddressType,
    shipment: any,
    rate: any,
    untrackedProp?: any,
    order: any
  ) => void,
};

type State = {
  step: number,
  nextSteps: number,
  nextStepsShow: boolean,
  filterTo: string,
  filterFrom: string,
  filterPackage: string,
  selectedType: number,
  nextStepsShow: boolean,
  isOverweight: boolean,
  location: string,
  sendingNew: boolean,
  shipmentsToPayObject: any,
  address: CustomAddressType,
  from: CustomAddressType,
  to: CustomAddressType,
  rate: any,
  insuranceSelected: string,
  weight: number,
  weightError: string,
  volumetricWeightError: string,
  dimensions: DimensionType,
  dimensionsError: string,
  order?: string,
  description: string,
  descriptionError: string,
  insuredAmount: string,
  insuredAmountError: string,
  holder: string,
  holderError: string,
  email: string,
  emailError: string,
  cost: string,
  providersFilter: string[],
  speedFilter: string[],
  shipmentsToPay: number[],
  selectedPackage?: string,
  selectedPackageTitle?: React.Node,

  selectedAddressFrom?: number,
  selectedAddressFromTitle?: React.Node,

  selectedAddressTo?: number,
  selectedAddressToTitle?: React.Node,
};

export default class Shipments extends React.Component<Props, State> {
  state: State = initialState;

  componentWillMount() {
    const {
      createdType,
      shipments,
      showMessage,
      setCreatedType,
      hideMessage,
      hideLoader,
    } = this.props;
    hideLoader();
    if (createdType) {
      const orders = shipments.orders.filter(
        (order) => order.marketplace === createdType
      );
      if (orders.length > 0) {
        showMessage(
          "info",
          `Tienes ${orders.length} ordenes nuevas de ${createdType}`
        );
      }
      setCreatedType(null);
      return;
    }
    hideMessage();

    const params = this.props.location.search.replace("?", "").split("&");
    this.getMarkeplaceOrder(params);
  }

  componentWillReceiveProps(nextProps: Props) {
    const {
      location: nextLocation,
      user: nextUser,
      message: nextMessage,
    } = nextProps;
    const { location, hideMessage, goToShipmentsList, getAllUserShipments } =
      this.props;

    if (
      nextLocation.pathname.includes("crear") &&
      nextLocation.pathname !== location.pathname
    ) {
      if (nextUser.primary_address) {
        this.setToPrimaryAddress(nextUser.primary_address);
      } else {
        this.resetForms();
      }
      hideMessage();
    }
    if (
      (nextLocation.pathname.includes("crear") ||
        nextLocation.pathname.includes("checkout") ||
        nextLocation.pathname.includes("envios/crear")) &&
      nextLocation.pathname === location.pathname &&
      nextMessage.messageType &&
      nextMessage.messageType === "success"
    ) {
      goToShipmentsList();
      this.resetForms();
      if (nextLocation.pathname.includes("checkout")) {
        return this.setState({ nextStepsShow: true, nextSteps: 0 });
      }
      getAllUserShipments();
    }

    if (
      (nextLocation.pathname.includes("crear") ||
        nextLocation.pathname.includes("checkout") ||
        nextLocation.pathname.includes("envios/crear")) &&
      nextLocation.pathname !== location.pathname &&
      nextMessage.messageType &&
      nextMessage.messageType === "success"
    ) {
      hideMessage();
      this.resetForms();
    }

    if (nextLocation.pathname === "/envios/default/from") {
      if (nextLocation.state) {
        const { from } = nextLocation.state;
        if (from) {
          this.handleChange(from.zipcode, "zipcode");
          this.setState({
            selectedAddressFromTitle: (
              <Text className="address-dropdown-title">
                <b>{from.alias}</b>
              </Text>
            ),
            from,
          });
        }
      } else if (nextUser.primarzy_address) {
        this.setToPrimaryAddress(nextUser.primary_address);
      }
    }
  }

  setToPrimaryAddress = (address: any) => {
    setTimeout(() => {
      this.setState({
        selectedAddressFromTitle: (
          <Text className="address-dropdown-title">
            <b>{address.alias}</b>
          </Text>
        ),
        from: {
          name: address.name,
          email: address.email,
          phone: address.phone,
          street: address.street,
          street2: address.street2,
          reference: address.reference,
          zipcode: address.zipcode,
          location: address.location,
        },
      });
      this.handleChange(address.zipcode, "zipcode");
    }, 200);
  };

  changeSelectedType = (selectedType) => {
    this.setState({ selectedType });
  };

  getMarkeplaceOrder = (params) => {
    if (params[0].length === 0) {
      return;
    }

    const body = {};

    params.map((item) => {
      const p = item.split("=");

      body[p[0]] = p[1];
    });

    axios
      .post(`api/tiendanube/purchase`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then(({ data }) => {
        if (!data.data.is_payed) {
          this.props.searchAndAddShipment(data.data.order_id).then((resp) => {
            this.goToPayment([data.data.order_id]);
          });
        } else {
          this.props.showMessage("success", "La orden ya fue pagada.");
        }
      })
      .catch((e) => {
        this.props.showMessage("error", "Orden no encontrada");
      });
  };

  goToPayment = (ids) => {
    const { hideMessage, showLoader, goToPayment } = this.props;

    hideMessage();
    showLoader("basic");

    this.setState({ sendingNew: true });
    axios
      .post("/api/purchases/total", {
        shipments: ids,
        charge_type: "SHIPMENTS_AND_OVERWEIGHT",
      })
      .then((response) => {
        this.setState(
          {
            shipmentsToPay: ids,
            shipmentsToPayObject: response.data,
            isOverweight: true,
            sendingNew: false,
          },
          () => {
            goToPayment();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  generateLabel = (order) => {
    const { goToCreateShipment } = this.props;
    const { to } = this.state;

    this.setState({ order: order.order });
    goToCreateShipment();
    if (order.address) {
      to.name = order.address.name ? order.address.name : "";
      to.zipcode = order.address.zipcode ? order.address.zipcode : "";
      to.street = order.address.street ? order.address.street : "";
      to.street2 = order.address.street2 ? order.address.street2 : "";
      to.phone = order.address.phone ? order.address.phone : "";
      to.email = order.address.email ? order.address.email : "";
      to.reference = order.address.reference ? order.address.reference : "";
      setTimeout(() => {
        this.setState({ to });
      }, 1000);
    }
  };

  removeCheckoutShipment = (index: number) => {
    const { goToPayment } = this.props;
    const { shipmentsToPay } = this.state;

    if (shipmentsToPay.length < 2) {
      return this.setState({ shipmentsToPay: [], shipmentsToPayObject: {} });
    }
    shipmentsToPay.splice(index, 1);
    this.setState({ sendingNew: true });
    axios
      .post("/api/purchases/total", {
        shipments: shipmentsToPay,
        charge_type: "SHIPMENTS_AND_OVERWEIGHT",
      })
      .then((response) => {
        this.setState(
          {
            shipmentsToPay,
            shipmentsToPayObject: response.data,
            isOverweight: true,
            sendingNew: false,
          },
          () => {
            goToPayment();
          }
        );
      })
      .catch((err) => {
        this.setState({ sendingNew: false });
      });
  };

  removeOverweight = () => {
    const { goToPayment } = this.props;
    const { shipmentsToPay } = this.state;

    this.setState({ sendingNew: true });
    axios
      .post("/api/purchases/total", {
        shipments: shipmentsToPay,
      })
      .then((response) => {
        this.setState(
          {
            shipmentsToPay,
            shipmentsToPayObject: response.data,
            isOverweight: false,
            sendingNew: false,
          },
          () => {
            goToPayment();
          }
        );
      })
      .catch((err) => {
        this.setState({ sendingNew: false });
      });
  };

  resetForms = () => {
    this.setState(initialState);
  };

  handleChange = (value, key) => {
    const { step, dimensions, rate } = this.state;

    if (key === "zipcode" && !isNaN(value) && value.length === 5) {
      axios
        .get(`/api/zipcodes/${value}`)
        .then((response) => {
          const address = {
            ...this.state[step === 1 ? "to" : "from"],
          };
          address.location = `${
            response.data.municipality
              ? response.data.municipality
              : response.data.neighborhood
          }, ${response.data.state.name}`;
          address.zipcodeError = "";
          this.setState({ [step === 1 ? "to" : "from"]: address });
        })
        .catch(() => {
          const address = {
            ...this.state[step === 1 ? "to" : "from"],
          };
          address.location = "";
          address.zipcodeError = "Código postal inválido";
          this.setState({ [step === 1 ? "to" : "from"]: address });
        });
    } else if (key === "zipcode") {
      this.setState({ location: "" });
    } else if (key === "length" || key === "width" || key === "height") {
      const newDimensions = { ...dimensions };
      newDimensions[key] = value;
      return this.setState({ dimensions: newDimensions });
    } else if (key === "phone") {
      value = value.replace(/\D/g, "");
      if (value.length > 10) return;
    } else if (key === "reference") {
      const keyStep = step === 0 ? "from" : "to";
      const address = this.state[keyStep];
      const referenceError =
        value.length >= 35
          ? "No escribas parte de la dirección aquí, esta información no aparecerá en la guía"
          : "";
      address.referenceError = referenceError;
      this.setState({ address });
    }
    if (step < 2) {
      const address = { ...this.state[step === 1 ? "to" : "from"] };
      address[key] = value;
      return this.setState({ [step === 1 ? "to" : "from"]: address });
    }
    if (key === "insuredAmount") {
      /* let cost = 58;
      let mult = value * 0.0174;
      if (mult >= 58) {
        cost = mult;
      }
      cost = parseFloat(cost).toFixed(2);
      this.setState({cost});
      */
      this.calculateInsuranceCost(rate.provider, value, rate.object_id);
    }
    this.setState({ [key]: value });
  };

  calculateInsuranceCost = (provider, insuredAmount, rate_id) => {
    rate_id = rate_id ? `/${rate_id}` : null;
    let cost;

    if (insuredAmount === "") {
      cost = "";
      this.setState({ cost });
    } else {
      axios
        .get(`/api/shipments/insurances/${insuredAmount}/${provider}`)
        .then((response) => {
          cost = response.data.insurance_cost;
          cost = parseFloat(cost).toFixed(2);
          this.setState({ cost });
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
    }
  };

  formatPhone = (phone: string) => {
    phone = Number(phone);
    if (isNaN(phone) || !phone) return "";
    const mask = "(XXX) XXX XX XX";
    const s = `${phone}`;
    let r = "";
    for (let i = 0, is = 0; i < mask.length && is < s.length; i += 1) {
      r += mask.charAt(i) === "X" ? s.charAt((is += 1)) : mask.charAt(i);
    }
    return r;
  };

  renderEmptyState = () => (
    <EmptyState name="marketplaces">
      <Title className="state-title">Conecta tus marketplaces</Title>
      <Text>
        Ayudanos a ayudarte, manten todo tu manejo de logistica en mienvío. Al
        conectar tus marketplaces nostros sincronizamos automaticamente tus
        ordenes. Recuerda que tambien puedes generar envíos independientes
      </Text>
      <Button className="state-btn" onClick={() => {}}>
        Conecta un marketplace
      </Button>
    </EmptyState>
  );

  handleProvidersChange = (providersFilter) => {
    this.setState({ providersFilter });
  };

  handleSpeedChange = (speedFilter) => {
    this.setState({ speedFilter });
  };

  handleRateChange = (value) => {
    const { rates } = this.props;

    rates.results.forEach((rate) => {
      if (Number(value) === Number(rate.object_id)) {
        this.setState({ rate });
      }
    });
  };

  handleInsuranceChange = (insuranceSelected) => {
    this.setState({ insuranceSelected });
  };

  saveDefaultAddress = () => {
    const { savePrimaryAddress, goToShipmentSetUp } = this.props;
    const { from } = this.state;

    savePrimaryAddress(from)
      .then(() => {
        goToShipmentSetUp();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  generateRatesProviders = () => {
    const { rates } = this.props;
    const { providersFilter } = this.state;
    const seen = [];
    const checkboxes = [];

    rates.results.forEach((rate) => {
      let found = false;
      seen.forEach((str) => {
        if (str === rate.provider) {
          found = true;
        }
      });
      if (found) return;
      seen.push(rate.provider);
      checkboxes.push(
        <Checkbox
          key={rate.provider}
          value={rate.provider}
          text={rate.provider}
          checkboxClass="rates-checkbox"
        />
      );
    });
    return (
      <CheckboxGroup
        type="form"
        defaultChecked={providersFilter}
        onChange={this.handleProvidersChange}
      >
        {checkboxes}
      </CheckboxGroup>
    );
  };

  generateRatesSpeed = () => {
    const { rates } = this.props;
    const { speedFilter } = this.state;
    const seen = [];
    const checkboxes = [];

    rates.results.forEach((rate) => {
      let found = false;
      seen.forEach((str) => {
        if (str === rate.duration_terms) {
          found = true;
        }
      });
      if (found) return;
      seen.push(rate.duration_terms);
      checkboxes.push(
        <Checkbox
          key={rate.duration_terms}
          value={rate.duration_terms}
          text={
            rate.servicelevel === "priority" ? "Priority" : rate.duration_terms
          }
          checkboxClass="rates-checkbox"
        />
      );
    });
    return (
      <CheckboxGroup
        type="form"
        defaultChecked={speedFilter}
        onChange={this.handleSpeedChange}
      >
        {checkboxes}
      </CheckboxGroup>
    );
  };

  generateRates = () => {
    const { rates: ratesProps } = this.props;
    const { providersFilter, speedFilter, rate } = this.state;

    let rates = ratesProps.results;
    const ratesProviderFiltered = rates.filter((rateProvider) => {
      if (providersFilter.length === 0) return true;
      let found = false;
      providersFilter.forEach((provider) => {
        if (provider === rateProvider.provider) found = true;
      });
      return found;
    });
    const ratesSpeedFiltered = ratesProviderFiltered.filter((rateSpeed) => {
      if (speedFilter.length === 0) return true;
      let found = false;
      speedFilter.forEach((speed) => {
        if (speed === rateSpeed.duration_terms) found = true;
      });
      return found;
    });
    rates = ratesSpeedFiltered;
    return rates.map<React.Node>((filteredRate) => (
      <StyledRate
        key={filteredRate.object_id}
        onClick={() => this.handleRateChange(filteredRate.object_id)}
      >
        <Row divisions={24}>
          <Column xs={2} fluid>
            <RadioGroup
              style={{ position: "relative", height: "100%" }}
              selected={rate.object_id}
              onChange={this.handleRateChange}
            >
              <Radio
                value={filteredRate.object_id}
                containerClass="rate-radio-container"
                className="rate-radio"
              />
            </RadioGroup>
          </Column>
          <Column xs={4} fluid style={{ position: "relative" }}>
            <img
              alt="provider"
              className="rate-img"
              src={`https://production.mienvio.mx/${filteredRate.provider_img}`}
            />
          </Column>
          <Column xs={18} fluid className="rate-content">
            <Row style={{ marginBottom: "6px" }}>
              <Column
                fluid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text>{capitalize(filteredRate.servicelevel)}</Text>
                <Text>
                  ${parseFloat(filteredRate.amount).toFixed(2)}
                  {(filteredRate.extended_zone ||
                    filteredRate.servicelevel === "priority") && <StyledDot />}
                </Text>
              </Column>
            </Row>
            <Row>
              <Column
                fluid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text>
                  {filteredRate.servicelevel === "priority"
                    ? filteredRate.duration_terms
                    : `${filteredRate.duration_terms} habiles`}
                </Text>
                {filteredRate.extended_zone && (
                  <Text style={{ display: "block" }} type="helpTextModal">
                    zona extendida
                  </Text>
                )}
              </Column>
            </Row>
            {filteredRate.servicelevel === "priority" && (
              <Row>
                <Column fluid>
                  <Text type="helpTextModal">
                    sin recolección, se entrega en sucursal antes 2 p.m.
                  </Text>
                </Column>
              </Row>
            )}
          </Column>
        </Row>
      </StyledRate>
    ));
  };

  saveAddress = () => {
    const { getUserAllPackages } = this.props;
    const { step, to } = this.state;
    const key = step === 0 ? "from" : "to";
    let errors = 0;
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let streetError = "";
    let street2Error = "";
    let referenceError = "";
    let zipcodeError = "";

    if (!this.state[key].name) {
      errors += 1;
      nameError = "Este campo es requerido";
    }

    if (!this.state[key].email) {
      errors += 1;
      emailError = "Este campo es requerido";
    } else if (!isEmail(this.state[key].email)) {
      errors += 1;
      emailError = "Correo electrónico inválido";
    }

    if (
      !this.state[key].phone ||
      isNaN(this.state[key].phone) ||
      this.state[key].phone.length !== 10
    ) {
      errors += 1;
      phoneError = "Número de teléfono inválido";
    }

    if (!this.state[key].street) {
      errors += 1;
      streetError = "Este campo es requerido";
    } else if (this.state[key].street.length > 35) {
      errors += 1;
      streetError = "Máximo 35 caracteres";
    }

    if (!this.state[key].street2) {
      errors += 1;
      street2Error = "Este campo es requerido";
    } else if (this.state[key].street2.length > 35) {
      errors += 1;
      street2Error = "Máximo 35 caracteres";
    }

    if (this.state[key].reference && this.state[key].reference.length > 32) {
      errors += 1;
      referenceError = "Máximo 32 caracteres";
    }

    if (
      isNaN(this.state[key].zipcode) ||
      this.state[key].zipcode.length !== 5 ||
      this.state[key].location === ""
    ) {
      errors += 1;
      zipcodeError = "Código postal inválido";
    }
    const address = this.state[key];
    address.nameError = nameError;
    address.emailError = emailError;
    address.phoneError = phoneError;
    address.streetError = streetError;
    address.referenceError = referenceError;
    address.street2Error = street2Error;
    address.zipcodeError = zipcodeError;
    this.setState({
      address,
    });
    if (errors > 0) {
      return;
    }
    const newStep = step + 1;
    if (newStep === 2) {
      getUserAllPackages();
    }
    this.setState(
      {
        step: newStep,
      },
      () => {
        if (step === 1) {
          if (to.zipcode) {
            this.handleChange(to.zipcode, "zipcode");
          }
        }
      }
    );
  };

  saveDimensions = () => {
    const { createRates } = this.props;
    const { dimensions, weight, from, to } = this.state;
    let errors = 0;
    let dimensionsError = "";
    let weightError = "";
    let volumetricWeightError = "";

    if (!dimensions.height || !dimensions.length || !dimensions.width) {
      errors += 1;
      dimensionsError = "Todas las dimensiones son requeridas";
    } else if (
      isNaN(dimensions.height) ||
      isNaN(dimensions.length) ||
      isNaN(dimensions.width)
    ) {
      errors += 1;
      dimensionsError = "Todas las dimensiones deben tener valores númericos";
    } else if (
      dimensions.height > 170 ||
      dimensions.length > 170 ||
      dimensions.width > 170
    ) {
      errors += 1;
      dimensionsError = "Todas las dimensiones deben ser menores a 170cm";
    } else if (
      Number(dimensions.height) +
        Number(dimensions.length) +
        Number(dimensions.width) >=
      360
    ) {
      errors += 1;
      dimensionsError = "La suma de las dimensiones debe de ser menor a 360cm";
    }

    if (!weight) {
      errors += 1;
      weightError = "El peso es requerido";
    } else if (isNaN(weight)) {
      errors += 1;
      weightError = "El peso solo puede contener valores numéricos";
    } else if (weight > 190) {
      errors += 1;
      weightError = "El peso no puede ser mayor a 190kg";
    }
    if (
      dimensions.length &&
      dimensions.height &&
      dimensions.width &&
      (1.0 *
        parseFloat(dimensions.length) *
        parseFloat(dimensions.width) *
        parseFloat(dimensions.height)) /
        5000.0 >
        190
    ) {
      errors += 1;
      volumetricWeightError = "El peso volumétrico no puede ser mayor a 190kg";
    }
    this.setState({
      dimensionsError,
      weightError,
      volumetricWeightError,
    });
    if (errors > 0) {
      return;
    }

    createRates(
      from.zipcode,
      to.zipcode,
      weight,
      dimensions.length,
      dimensions.width,
      dimensions.height
    ).then(() => {
      this.setState({
        step: 3,
      });
    });
  };

  saveShipment = () => {};

  handleNextClick = () => {
    const { showMessage, hideMessage, createShipment } = this.props;
    const {
      step,
      from,
      to,
      rate,
      description,
      insuranceSelected,
      weight,
      dimensions,
      order,
      insuredAmount,
      holder,
      email,
      cost,
    } = this.state;

    if (step < 2) {
      if (step === 1 && from.zipcode === to.zipcode) {
        showMessage(
          "info",
          "El código postal de origen y destino es el mismo."
        );
        setTimeout(hideMessage, 5000);
      }
      return this.saveAddress();
    }
    if (step === 2) {
      return this.saveDimensions();
    }
    if (step === 3) {
      if (rate && rate.provider) {
        this.setState({ step: 4 });
      }
    } else if (step === 4) {
      if (!description) {
        return this.setState({
          descriptionError: "Este campo es requerido",
        });
      }
      if (description.length > 30) {
        return this.setState({
          descriptionError: "Máximo 30 caracteres",
        });
      }
      this.setState({
        step: 5,
      });
    } else if (insuranceSelected === "1") {
      const shipment = {
        weight,
        height: dimensions.height,
        length: dimensions.length,
        width: dimensions.width,
        description,
      };
      createShipment(from, to, shipment, rate.object_id, null, order);
    } else {
      let errors = 0;
      let insuredAmountError = "";
      let holderError = "";
      let emailError = "";
      if (!insuredAmount) {
        errors += 1;
        insuredAmountError = "Este campo es requerido";
      } else if (isNaN(insuredAmount)) {
        errors += 1;
        insuredAmountError = "Este campo debe de ser numérico";
      }

      if (!holder) {
        errors += 1;
        holderError = "Este campo es requerido";
      }

      if (!email) {
        errors += 1;
        emailError = "Este campo es requerido";
      } else if (!isEmail(email)) {
        errors += 1;
        emailError = "Correo electrónico inválido";
      }

      if (errors > 0) {
        return this.setState({
          insuredAmountError,
          holderError,
          emailError,
        });
      }
      const shipment = {
        weight,
        height: dimensions.height,
        length: dimensions.length,
        width: dimensions.width,
        description,
      };
      const insurance = {
        holder,
        email,
        product: description,
        insured_amount: insuredAmount,
        amount: insuredAmount,
        cost,
      };
      createShipment(from, to, shipment, rate.object_id, insurance, order);
    }
  };

  handlePreviousClick = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  beautifyPhone = (phone) => {
    if (!phone || phone === "null" || isNaN(phone)) return "";
    const mask = "(XXX) XXX XX XX";
    const s = `${phone}`;
    let r = "";
    for (let i = 0, is = 0; i < mask.length && is < s.length; i += 1) {
      r += mask.charAt(i) === "X" ? s.charAt((is += 1)) : mask.charAt(i);
    }
    return r;
  };

  handleFilterChange = (value) => {
    const { step } = this.state;

    if (step === 1) {
      return this.setState({
        filterTo: value,
      });
    }
    return this.setState({
      filterFrom: value,
    });
  };

  handlePackageFilterChange = (value) =>
    this.setState({
      filterPackage: value,
    });

  handleAddressChange = (index) => {
    const { addresses: addressesProps } = this.props;
    const { step, filterTo, filterFrom } = this.state;

    let addresses = addressesProps.results;
    if (step === 1 && filterTo) {
      addresses = addresses.filter((address) => {
        const re = new RegExp(filterTo, "i");
        return re.test(address.alias);
      });
    } else if (step === 0 && filterFrom) {
      addresses = addresses.filter((address) => {
        const re = new RegExp(filterFrom, "i");
        return re.test(address.alias);
      });
    }
    const { alias } = addresses[index];
    let address = null;
    axios
      .get(`/api/zipcodes/${addresses[index].zipcode}`)
      .then((response) => {
        address = { ...this.state[step === 1 ? "to" : "from"] };
        address.location = `${
          response.data.municipality
            ? response.data.municipality
            : response.data.neighborhood
        }, ${response.data.state.name}`;
        address.zipcodeError = "";
        this.setState({ [step === 1 ? "to" : "from"]: address });
      })
      .catch(() => {
        address = { ...this.state[step === 1 ? "to" : "from"] };
        address.location = "";
        this.setState({ [step === 1 ? "to" : "from"]: address });
      });
    if (step === 1) {
      return this.setState({
        selectedAddressTo: index,
        selectedAddressToTitle: (
          <Text className="address-dropdown-title">
            <b>{alias}</b>
          </Text>
        ),
        to: addresses[index],
      });
    }
    this.setState({
      selectedAddressFrom: index,
      selectedAddressFromTitle: (
        <Text className="address-dropdown-title">
          <b>{alias}</b>
        </Text>
      ),
      from: addresses[index],
    });
  };

  goToNextStep = () => {
    const { getAllUserShipments, goToShipmentsList } = this.props;
    const { nextSteps } = this.state;

    if (nextSteps === 4) {
      return this.setState({ nextStepsShow: false, nextSteps: 0 }, () => {
        getAllUserShipments().then(() => {
          goToShipmentsList();
        });
      });
    }
    return this.setState({ nextSteps: nextSteps + 1 });
  };

  handlePackageChange = (pack) => {
    const { packages } = this.props;
    const packagesResults = packages;

    this.setState({
      selectedPackage: pack,
      dimensions: {
        height: packagesResults[pack].height,
        width: packagesResults[pack].width,
        length: packagesResults[pack].length,
      },
      selectedPackageTitle: (
        <Text className="address-dropdown-title">
          <b>{packagesResults[pack].alias}</b>
        </Text>
      ),
    });
  };

  isDefaultAddressIncomplete = () => {
    const { from } = this.state;
    return !(
      from.name &&
      from.email &&
      from.phone &&
      from.street &&
      from.location &&
      from.street2 &&
      from.location &&
      from.zipcode
    );
  };

  render() {
    const {
      addresses: addressesFromProps,
      packages,
      goToShipmentsList,
      goToShipmentSetUp,
      shipments,
      message,
      hideMessage,
    } = this.props;

    const {
      step,
      filterTo,
      filterFrom,
      filterPackage,
      selectedType,
      nextStepsShow,
      isOverweight,
      sendingNew,
      shipmentsToPayObject,
      from,
    } = this.state;
    let options = [];
    let packageOptions = [];

    if (addressesFromProps.results) {
      let addresses = addressesFromProps.results;
      if (step === 1 && filterTo) {
        addresses = addresses.filter((address) => {
          const re = new RegExp(filterTo, "i");
          return re.test(address.alias);
        });
      } else if (step === 0 && filterFrom) {
        addresses = addresses.filter((address) => {
          const re = new RegExp(filterFrom, "i");
          return re.test(address.alias);
        });
      }
      options = addresses.map((address) => (
        <Row key={address.object_id} className="card-dropdown-option">
          <Column fluid>
            <Text>
              <b>{address.alias}</b>
            </Text>
          </Column>
          <Column fluid>
            <Text>{address.name}</Text>
          </Column>
          <Column fluid>
            <Text>
              {`${address.email}. ${this.beautifyPhone(address.phone)}.`}
            </Text>
          </Column>
          <Column fluid>
            <Text>{address.street}</Text>
          </Column>
          {address.reference && (
            <Column fluid>
              <Text>{address.reference}</Text>
            </Column>
          )}
          <Column fluid>
            <Text>{address.street2}</Text>
          </Column>
          <Column fluid>
            <Text>C.P. {address.zipcode}</Text>
          </Column>
        </Row>
      ));
    }
    if (packages.results) {
      let packagesResults = packages.results;
      packagesResults = packagesResults.filter((p) => {
        const re = new RegExp(filterPackage, "i");
        return re.test(p.alias);
      });
      packageOptions = packagesResults.map((pack) => (
        <PackageOption key={pack.object_id} pack={pack} />
      ));
    }
    const mainProps = {
      generate: (name) => ({
        onChange: (value, key) => {
          this.handleChange(value, key || name);
        },
        value:
          step >= 2
            ? this.state[name]
            : name === "phone"
            ? formatPhone(this.state[step === 1 ? "to" : "from"][name])
            : this.state[step === 1 ? "to" : "from"][name],
        error:
          step >= 2
            ? this.state[`${name}Error`]
            : this.state[step === 1 ? "to" : "from"][`${name}Error`],
      }),
    };
    const createTitles = [
      "¿Desde dónde envías?",
      "¿A dónde envías?",
      "¿Cómo es el paquete que envías?",
      "Seleccionar servicio de paquetería",
      "¿Qué estás enviando?",
      "Asegura tu envío*",
    ];
    return (
      <div>
        <Row divisions={20}>
          <Column xs={12} fluid />
          <Column xs={8} xsShift={12} style={{ textAlign: "right" }} fluid>
            <Menu />
          </Column>
        </Row>
        <Switch>
          <Route
            exact
            path="/envios"
            render={(props) => (
              <ShipmentsList
                {...props}
                selectedType={selectedType}
                changeSelectedType={this.changeSelectedType}
                goToPayment={this.goToPayment}
                generateLabel={this.generateLabel}
                invoiceDetails={nextStepsShow}
              />
            )}
          />
          <Route
            path="/checkout"
            render={(props) => (
              <Checkout
                {...props}
                goBack={goToShipmentsList}
                shipmentsObject={shipmentsToPayObject}
                shipments={shipments}
                removeCheckoutShipment={this.removeCheckoutShipment}
                isOverweight={isOverweight}
                removeOverweight={this.removeOverweight}
                sendingNew={sendingNew}
              />
            )}
          />
          <Route
            path="/envios/crear"
            render={(props) => (
              <Create
                {...props}
                goBack={goToShipmentsList}
                modalTitle="Creación de guías"
                classContent={step === 3 ? "rates-content" : ""}
              >
                {message.show && (
                  <Message color={message.messageType} onClose={hideMessage}>
                    <Text type="labelEdit" style={{ color: "white" }}>
                      {message.text}
                    </Text>
                  </Message>
                )}
                <Title>{createTitles[step]}</Title>
                <ShipmentFields
                  index={step}
                  mainProps={mainProps}
                  options={options}
                  packageOptions={packageOptions}
                  {...this.props}
                  {...this.state}
                  {...this}
                />
                <Button
                  onClick={() => this.handleNextClick()}
                  className="create-btn"
                >
                  Siguiente
                </Button>
                {step > 0 && (
                  <Button
                    onClick={() => this.handlePreviousClick()}
                    type="link"
                    className="create-btn"
                  >
                    Anterior
                  </Button>
                )}
              </Create>
            )}
          />
          <Route
            path="/envios/default/from"
            render={(props) => (
              <Create {...props} goBack={goToShipmentSetUp}>
                <Text type="titleModalInput" className="detail-modal">
                  Dirección origen default
                </Text>
                <ShipmentFields
                  index={6}
                  mainProps={mainProps}
                  options={options}
                  packageOptions={packageOptions}
                  {...this.props}
                  {...this.state}
                  {...this}
                />
                <Row>
                  <Column fluid>
                    {from.alias && (
                      <Text className="detail-text" type="helpTextModal">
                        Dirección seleccionada
                      </Text>
                    )}
                  </Column>
                  <Column fluid>
                    {from.alias && (
                      <Text
                        className="detail-text"
                        type="textSidebarLinkSelected"
                        style={{ marginTop: "8px" }}
                      >
                        {from.alias}
                      </Text>
                    )}
                  </Column>
                  <Column fluid>
                    {from.name && (
                      <Text className="detail-text" type="bodyDetail">
                        {from.name}
                      </Text>
                    )}
                  </Column>
                  <Column fluid>
                    {from.email && (
                      <Text className="detail-text" type="bodyDetail">
                        {from.email}
                      </Text>
                    )}
                  </Column>
                  <Column fluid>
                    {from.phone && (
                      <Text className="detail-text" type="bodyDetail">
                        {this.formatPhone(from.phone)}
                      </Text>
                    )}
                  </Column>
                  <Column fluid>
                    {from.street && (
                      <Text className="detail-text" type="bodyDetail">
                        {from.street}
                      </Text>
                    )}
                  </Column>
                  <Column fluid>
                    {from.reference && (
                      <Text className="detail-text" type="bodyDetail">
                        {from.reference}
                      </Text>
                    )}
                  </Column>
                  <Column fluid>
                    {from.street2 && (
                      <Text className="detail-text" type="bodyDetail">
                        Col. {from.street2}
                      </Text>
                    )}
                  </Column>
                  <Column fluid>
                    {from.location && (
                      <Text className="detail-text" type="bodyDetail">
                        {from.location}. C.P. {from.zipcode}
                      </Text>
                    )}
                  </Column>
                </Row>

                {this.isDefaultAddressIncomplete() ? (
                  <Button
                    style={{ marginTop: "14px" }}
                    type="disabled_table"
                    className="create-btn"
                  >
                    Guardar
                  </Button>
                ) : (
                  <Button
                    style={{ marginTop: "14px" }}
                    onClick={() => this.saveDefaultAddress()}
                    className="create-btn"
                  >
                    Guardar
                  </Button>
                )}
              </Create>
            )}
          />
          <Route
            path="/envios/:id"
            render={(props) => (
              <ShipmentDetail {...props} goToPayment={this.goToPayment} />
            )}
          />
        </Switch>
      </div>
    );
  }
}
