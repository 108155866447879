import React from 'react';
import PropTypes from 'prop-types';
import './insuranceBadge.css';

const Badge = ({ type, children , className}) => <div className={`insurance-badge ${type} ${className}`}>{children}</div>;

Badge.propTypes = {
  type: PropTypes.oneOf(['warning', 'correct', 'error']).isRequired
};

export default Badge;
