// @flow
import React from "react";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Redirect } from "react-router-dom";
import { boundMethod } from "autobind-decorator";
import Cookies from "universal-cookie";
import { Row, Column } from "hedron";
import valid from "card-validator";
import { emailRegex, formatPhone } from "../../utils/global_functions";
import { editFiscalInfo } from "../../modules/user";

import { ToastContainer, toast } from "react-toastify";
import { login, getUrlParameter } from "../../modules/user";
import { hideMessage } from "../../modules/message";
import { hideLoader } from "../../modules/loader";
import Text from "../Text";
import Button from "../Button";
import Input from "../Input";
import Logo from "../static/Logo";
import Message from "../Message";
import styles from "./index.module.scss";
import { Checkbox, CheckboxGroup } from "../../components/Checkbox";
import Swal from "sweetalert2";
import Profile from "../Profile";

import { createPlanBilling } from "../../modules/cards";

import type { MessageType } from "../../constants/customTypes";

type DefaultProps = {
  message: MessageType,
};

export type Props = {
  ...DefaultProps,
  login: () => void,
  hideMessage: () => void,
  goToSignup: () => void,
  goToRequestPassword: () => void,
};

type State = {
  email: string,
  emailError: string,
  password: string,
  passwordError: string,
};

class InvoicePlan extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    message: {
      messageType: "",
      show: false,
      text: "",
    },
  };

  state: State = {
    password: "",
    passwordError: "",
    passwordNew: "",
    passwordNewError: "",
    passwordConfirmation: "",
    passwordConfirmationError: "",
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    email: "",
    emailError: "",
    companyName: "",
    companyNameError: "",
    rfc: "",
    rfcError: "",
    phone: "",
    phoneError: "",
    street: "",
    streetError: "",
    street2: "",
    street2Error: "",
    zipcode: "",
    zipcodeError: "",
    location: "",
  };

  componentDidMount() {
    const { card_id, plan, action } = this.props.match.params;

    this.setState({ card_id: card_id, plan: plan, action: action });
  }

  componentWillMount() {
    this.props.hideLoader();
  }

  notify = () => {
    toast.error("Error al iniciar sesión", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  returnBack() {
    this.props.goToPayment(this.state.plan, "false", this.state.action);
  }

  saveFiscalInfo() {
    let errors = 0;
    const regexrfc =
      /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/;
    let companyNameError = "";
    let rfcError;
    let phoneError = "";
    let streetError = "";
    let street2Error = "";
    let zipcodeError = "";

    if (!this.state.companyName) {
      errors++;
      companyNameError = "Este campo es requerido";
    }

    if (!this.state.rfc) {
      errors++;
      rfcError = "Este campo es requerido";
    } else if (this.state.rfc && !regexrfc.test(this.state.rfc)) {
      errors++;
      rfcError = "RFC inválido";
    }

    if (!this.state.phone) {
      errors++;
      phoneError = "Este campo es requerido";
    } else if (
      this.state.phone &&
      (isNaN(this.state.phone) || this.state.phone.length !== 10)
    ) {
      errors++;
      phoneError = "Número de teléfono inválido";
    }

    if (!this.state.street) {
      errors++;
      streetError = "Este campo es requerido";
    } else if (this.state.street.length > 32) {
      errors++;
      streetError = "Máximo 32 caracteres";
    }

    if (!this.state.street2) {
      errors++;
      street2Error = "Este campo es requerido";
    }

    if (!this.state.zipcode) {
      errors++;
      zipcodeError = "Este campo es requerido";
    } else if (
      this.state.zipcode &&
      (isNaN(this.state.zipcode) || this.state.zipcode.length !== 5)
    ) {
      errors++;
      zipcodeError = "Código postal inválido";
    } else if (this.state.location === "") {
      errors++;
      zipcodeError = "Código postal inválido";
    } else if (this.state.location === "") {
      errors++;
      zipcodeError = "Código postal inválido";
    }
    this.setState({
      companyNameError,
      rfcError,
      streetError,
      street2Error,
      phoneError,
      zipcodeError,
    });
    if (errors > 0) {
      return;
    }

    const address = {
      name: this.state.companyName,
      rfc: this.state.rfc,
      phone: this.state.phone,
      street: this.state.street,
      street2: this.state.street2,
      zipcode: this.state.zipcode,
    };

    this.props.editFiscalInfo(address);

    Swal.fire({
      title: "Tu factura sera enviada a tu correo electronico",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        const email = this.props.user.email;
        const card_id = this.state.card_id;
        const plan_id = parseInt(this.state.plan);
        const action = this.state.action;
        this.props.createPlanBilling(card_id, plan_id, email, action);
      }
    });
  }

  handleChange(value, key) {
    if (key === "zipcode" && !isNaN(value) && value.length === 5) {
      axios
        .get(`/api/zipcodes/${value}`)
        .then((response) => {
          this.setState({
            location: `${response.data.municipality}, ${response.data.state.name}`,
            zipcodeError: "",
          });
        })
        .catch(() => {
          this.setState({
            location: "",
            zipcodeError: "Código postal inválido",
          });
        });
    } else if (key === "zipcode") {
      this.setState({ location: "" });
    } else if (key === "phone") {
      value = value.replace(/\D/g, "");
      if (value.length > 10) return;
    } else if (key === "email" && !emailRegex.test(value)) {
      this.setState({ emailError: "El correo no es válido" });
    } else if (key === "email" && emailRegex.test(value)) {
      this.setState({ emailError: "" });
    }
    this.setState({ [key]: value });
  }

  isLoggedIn() {
    if (localStorage.getItem("token")) {
      const token = `Bearer ${localStorage.getItem("token")}`;
      axios.defaults.headers.common.Authorization = token;
      return true;
    }
    return false;
  }

  render() {
    if (!this.isLoggedIn()) {
      return <Redirect to="/login" />;
    }

    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value:
          this.state[name] && name === "phone"
            ? formatPhone(this.state[name])
            : this.state[name],
        error: this.state[`${name}Error`],
      }),
    };

    const message = "";

    return (
      <div className={styles.contInvoice}>
        <ToastContainer />
        <div className={styles.invoiceCard}>
          <Row className={styles.row}>
            <Logo />
            <Profile />
          </Row>
          <Row className={styles.row + " " + styles.content}>
            <Column xs={12} md={6} fluid="fluid" className={styles.col}>
              <div className={styles.containerInputs}>
                {message.show ? (
                  <Message color={message.messageType} onClose={hideMessage}>
                    {message.text}
                  </Message>
                ) : null}
                <Input
                  type="text"
                  {...mainProps.generate("companyName")}
                  required="required"
                  placeholder="Nombre Titular o Empresa"
                  label="Titular"
                />
                <Input
                  type="text"
                  {...mainProps.generate("rfc")}
                  required="required"
                  placeholder="XXXX000000XXX"
                  label="RFC"
                />
                <Input
                  type="text"
                  {...mainProps.generate("phone")}
                  required="required"
                  placeholder="(442) 123 12 45"
                  label="Teléfono"
                />
              </div>
            </Column>
            <Column xs={12} md={6} fluid="fluid" className={styles.col}>
              <div className={styles.containerInputs}>
                <Input
                  type="text"
                  {...mainProps.generate("street")}
                  maxLength="35"
                  required="required"
                  placeholder="Av. Felipe Angeles, 511. Int. J-501."
                  label="Calle y número"
                />
                <Input
                  type="text"
                  {...mainProps.generate("street2")}
                  required="required"
                  placeholder="Los pedregales"
                  label="Colonia"
                />
                <Row>
                  <Column xs={4} fluid="fluid">
                    <Input
                      type="text"
                      {...mainProps.generate("zipcode")}
                      required="required"
                      placeholder="76158"
                      label="Código Postal"
                    />
                  </Column>
                  <Column
                    xs={8}
                    fluid="fluid"
                    style={{
                      paddingLeft: "16px",
                    }}
                  >
                    <Input
                      type="text"
                      disabled="disabled"
                      onChange={() => this.saveFiscalInfo()}
                      required="required"
                      label="Estado y Ciudad"
                      value={this.state.location}
                    />
                  </Column>
                </Row>
              </div>
            </Column>
            <Row className={styles.rowButton}>
              <Button
                onClick={() => this.saveFiscalInfo()}
                className="create-btn"
              >
                Guardar Direccion
              </Button>
            </Row>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPayment: (plan, invoice, action) =>
        push(`/payment-plan/${plan}/${invoice}/${action}`),
      hideMessage,
      hideLoader,
      editFiscalInfo,
      createPlanBilling,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePlan);
