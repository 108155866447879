import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Column } from 'hedron';

// import Text, {Title} from '../Text';
import Modal from '../Modal';

class Create extends React.Component {
  render() {
    return (
      <div>
        <Row divisions={20}>
          <Column fluid>
            <Modal
              expanded={this.props.expanded}
              action={() => this.props.goBack()}
              actionText={this.props.titleBack ? this.props.titleBack : 'Cancelar'}
              title={this.props.modalTitle}
              classContent={this.props.classContent}
            >
              {this.props.children}
            </Modal>
          </Column>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Create);
