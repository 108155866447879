import React from 'react';
import { useState } from 'react';
import { cancelOrder } from '../../../modules/shipments/actions';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { boundMethod } from "autobind-decorator";
import { Row, Column } from "hedron";
import Modal from 'react-responsive-modal';
import Text, { Title } from '../../Text';
import Button from "../../Button";
import Dropdown from "../../Dropdown";
import styled from 'styled-components';
import package_icon from "./icons/box.svg";
import { CANCEL_STATUS, CANCEL_STATUS_KEY } from '../../../constants';


class CancelModal extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      cancelStatus: 0,
    }
    this.availableCancelStatus = CANCEL_STATUS;
    this.parseStatusName = CANCEL_STATUS_KEY;
  }

  @boundMethod
  changeStatusFromShipment(e){
    this.props.cancelOrder(this.props.shipmentId).then(()=>{
      this.props.onCloseModal();
    })
  }

  @boundMethod
  changeStatusCancel(e){
    this.setState({cancelStatus: e});
  }

  render(){

    const customStyle = {'overlay': {zIndex: 10000}};
    
    const Container = styled.div`
    position: relative;
    width: 100%;
    `;

    return (
    <Modal
      classNames={{ modal: 'modal' }}
      open={this.props.showModal}
      onClose={this.props.onCloseModal}
      styles={customStyle}
    >
      <Container>
        <Row fluid>
          <Title>
            ¿Estas seguro que desea cancelar está orden <img src={package_icon}/> ?  
          </Title>
        </Row>
        <Row>
          <Column>
            <Button onClick={this.changeStatusFromShipment}>
              Si
            </Button>

            <Button style={{ margin: 0, backgroundColor:'red'}} onClick={this.props.onCloseModal}>
              No
            </Button>
          </Column>
        </Row>
      </Container>
    </Modal>
  );
  }

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cancelOrder
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CancelModal);