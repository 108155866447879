type State = {
  +open: boolean,
  +title?: string,
  +type?: string,
  _callback?: mixed
};

export const initialState: State = {
  open: true,
  title: '',
  type: '',
  _callback: () => {}
};
