import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  createDuplicateOrder,
  editExistingOrder,
  clearSidebar,
  setStepTitles,
  createDevolution,
} from "../../../modules/sidebarOrder/actions";
import { toggle } from "../../../modules/CollapsibleBar/actions";
import SidebarTitleMenu from "../../WrapperSideBar/extraComponents/SidebarTitleMenu";
import { purposesWithTracking } from "../index";

import {
  NEW_SHIPMENT,
  EDIT_SHIPMENT,
} from "../../../modules/CollapsibleBar/sideBarTypes";
import { deleteShipments } from "../../../modules/shipments/actions";

import edit_icon from "../../../utils/icons/edit.svg";
import delete_icon from "../../../utils/icons/delete.svg";
import duplicate_icon from "../../../utils/icons/duplicate.svg";
import { searchPermission } from "../../../utils/global_functions";

import "../style.css";

class DetailedOrderMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      hasOrders: false,
      order: {},
      hasTracking: false,
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      hasOrders:
        typeof newProps.order === "object" &&
        Object.keys(newProps.order).length &&
        newProps.order.details != "loading",
      order: newProps.order,
      hasTracking:
        purposesWithTracking.indexOf(newProps.order.details.object_purpose) >
        -1,
    });
  }

  _createDuplicateOrder = async () => {
    await this.props.clearSidebar();
    await this.props.setStepTitles([
      {
        label: "Origen",
        status: "unfinished",
      },
      {
        label: "Destino",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Paquete",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Servicio",
        status: "unfinished",
        forwardClickable: false,
      },
    ]);
    this.props.createDuplicateOrder(this.props.order);
    this.props.toggle(true, "Creación de guías", NEW_SHIPMENT);
  };

  _createDevolution = async () => {
    await this.props.clearSidebar();
    await this.props.setStepTitles([
      {
        label: "Origen",
        status: "unfinished",
      },
      {
        label: "Destino",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Paquete",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Servicio",
        status: "unfinished",
        forwardClickable: false,
      },
    ]);
    this.props.createDevolution(this.props.order);
    this.props.toggle(true, "Creación de guías", NEW_SHIPMENT);
  };

  _editExistingOrder = async () => {
    await this.props.clearSidebar();
    await this.props.setStepTitles([
      {
        label: "Origen",
        status: "unfinished",
      },
      {
        label: "Destino",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Paquete",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Servicio",
        status: "unfinished",
        forwardClickable: false,
      },
    ]);
    this.props.editExistingOrder(this.props.order);
    this.props.toggle(
      true,
      `Editar órden #${this.props.order.details.object_id}`,
      NEW_SHIPMENT
    );
  };

  _editOrder = async () => {
    await this.props.clearSidebar();
    await this.props.setStepTitles([
      {
        label: "Origen",
        status: "unfinished",
      },
      {
        label: "Destino",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Paquete",
        status: "unfinished",
        forwardClickable: false,
      },
      {
        label: "Servicio",
        status: "unfinished",
        forwardClickable: false,
      },
    ]);
    this.props.editExistingOrder(this.props.order);
    this.props.toggle(
      true,
      `Editar órden #${this.props.order.details.object_id}`,
      NEW_SHIPMENT
    );
  };

  // _deleteShipment = () => {
  //   this.props
  //     .deleteShipments([this.state.order.details.object_id])
  //     .then(() => {
  //       this.props.toggle(false);
  //     });
  // };

  render() {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    let withLabel = [];

    if (searchPermission("envios", "editar", permissions)) {
      withLabel = [
        {
          link: "http://bitly.com/mienvio-incidencias",
          label: "Reportar Incidencia",
          icon: "ion-android-open",
        },
        {
          link: "https://bit.ly/2pw0qVS",
          label: "Cancelar Guía",
          icon: "ion-android-open",
        },
      ];
    }

    const withoutLabel = [];
    if (searchPermission("envios", "editar", permissions)) {
      withLabel.unshift(
        {
          label: "Duplicar y editar",
          image: duplicate_icon,
          function: this._createDuplicateOrder,
        },
        {
          label: "Generar guía para devolución",
          image: duplicate_icon,
          function: this._createDevolution,
        }
      );
      withoutLabel.push(
        {
          label: "Editar",
          image: edit_icon,
          function: this._editExistingOrder,
        },
        {
          label: "Duplicar y editar",
          image: duplicate_icon,
          function: this._createDuplicateOrder,
        },
        {
          label: "Generar guía para devolución",
          image: duplicate_icon,
          function: this._createDevolution,
        }
      );
    }
    // if (searchPermission("envios", "eliminar", permissions)) {
    //   withoutLabel.push({
    //     label: "Eliminar órden",
    //     image: delete_icon,
    //     function: this._deleteShipment,
    //   });
    // }
    const cotizationLabel = [
      {
        label: "Editar",
        image: edit_icon,
        function: this._editOrder,
      },
    ];

    const linksToRender = this.state.hasTracking
      ? withLabel
      : this.props.cotization
      ? cotizationLabel
      : withoutLabel;

    return this.state.order.name_purchase &&
      this.state.order.name_purchase === "MONTHLY_PAYMENT_PENDING" ? (
      <div />
    ) : (
      this.state.hasOrders && (
        <SidebarTitleMenu
          linksToRender={linksToRender}
          nextButton={{
            trigger: () =>
              this.props.changeOrderFunction(this.props.order.nextOrder),
            disabled: !this.props.order.nextOrder,
          }}
          prevButton={{
            trigger: () =>
              this.props.changeOrderFunction(this.props.order.prevOrder),
            disabled: !this.props.order.prevOrder,
          }}
        />
      )
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createDuplicateOrder,
      editExistingOrder,
      toggle,
      deleteShipments,
      clearSidebar,
      setStepTitles,
      createDevolution,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DetailedOrderMenu);
