import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Column } from 'hedron';
import { push } from 'react-router-redux';
import { deletePackages, createPackage, editPackage } from '../../modules/packages';
import { hideMessage } from '../../modules/message';
import lowerCase from 'lower-case';
import box from '../PackagesList/icons/box.svg';
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle
} from '../../modules/CollapsibleBar/actions';
import { ContentAdapter, BottomContainer } from '../WrapperSideBar/styledObjects';
import Header from '../Header';
import Text, { Title, P } from '../Text';
import Input, { HelpText, Label, Required } from '../Input';
import Filter from '../Filter';
import Table, { Th, Tr, Td } from '../Table';
import { Checkbox, CheckboxGroup } from '../Checkbox';
import Button from '../Button';
import Card from '../Card';
import EmpyState from '../EmptyState';
import Message from '../Message';
import WrapperSideBar from '../WrapperSideBar';
import SelectableBox from '../SelectableBox';
import { NEW_PACKAGE, PACKAGE_DETAILS } from '../../modules/CollapsibleBar/sideBarTypes';
import './style.css';
import detail_eye from './icons/detail-eye.svg';
import box_icon from './icons/box.svg';
import packet_icon from './icons/envelope.svg';
import { jsUcfirst } from '../../utils/global_functions';
import DetailedPackageMenu from './details/detailed-package-menu';
import NewTable from '../NewTable';

import { validatePackage, translatePackage } from '../../utils/validate';
import { searchPermission } from '../../utils/global_functions';

class PackagesList extends React.Component {
  state = {
    selected: [],
    filter: '',
    filters: [],
    newPackage: {
      id: '',
      alias: '',
      aliasError: '',
      type: '',
      typeError: '',
      dimensions: {
        length: '',
        width: '',
        height: ''
      },
      dimensionsError: '',
      description: '',
      descriptionError: '',
      weight: '',
      weightError: '',
      isLoading: false,
      insuredAmount: ''
    }
  };

  componentWillMount() {
    this.props.toggleSideBar(false);
    this.updatePackagesInState(this.props);
    if (this.props.user.customer_type == 'enterprise' || this.props.user.customer_type == 'basic' || this.props.user.customer_type == 'subsided_customer') {
      this.setState({ canCreate: true, canDelete: true, canUpdate: true });
    } else {
      if(this.props.user.permissions !== undefined){
        this.props.user.permissions.forEach(element => {
          if (element.resource == 'packages') {
            switch (element.action) {
              case 'create':
                this.setState({ canCreate: true });
                break;
              case 'edit':
                this.setState({ canUpdate: true });
                break;
              case 'delete':
                this.setState({ canDelete: true });
                break;
              default:
                break;
            }
          }
        });
      }

    }
  }

  componentWillReceiveProps(new_Props) {
    let current_packages = [];
    this.updatePackagesInState(new_Props);
  }

  updatePackagesInState = new_props => {
    let current_packages = [];
    if (new_props.packages.results) {
      new_props.packages.results.forEach((element, index) => {
        current_packages.push(this.translateObjectForm(element, index));
      });
      this.setState({ current_packages: current_packages });
    }
  };

  translateObjectForm = (obj, index = null) => {
    const new_obj = {
      id: obj.object_id,
      alias: obj.alias,
      type: lowerCase(obj.object_type),
      dimensions: {
        length: obj.length,
        width: obj.width,
        height: obj.height
      },
      description: obj.description ? obj.description : '',
      weight: obj.weight ? obj.weight : '',
      isLoading: false,
      insuredAmount: obj.insured_amount == 0.00 ? '' : obj.insured_amount
    };

    if (index > -1) new_obj['index'] = index;

    return new_obj;
  };

  generateTable = packages => {
    return packages.map((p, index) => {
      return (
        <Tr key={index}>
          <Td className="clickable" checkbox>
            <CheckboxGroup
              defaultChecked={this.state.selected}
              type="table"
              onChange={value => this.select(value)}
            >
              <Checkbox value={p.object_id.toString()} />
            </CheckboxGroup>
          </Td>
          <Td className="clickable" onClick={() => this.viewPackageDetails(index)}>
            {p.alias}
          </Td>
          <Td className="clickable" onClick={() => this.viewPackageDetails(index)}>
            {p.object_type}
          </Td>
          <Td className="clickable" onClick={() => this.viewPackageDetails(index)}>
            {p.length} x {p.width} x {p.height} cm
          </Td>
          <Td className="clickable" onClick={() => this.viewPackageDetails(index)}>
            {p.object_status}
          </Td>

          <Td>
            <Button type="link" onClick={() => this.goToEditPackage(index)}>
              Editar
            </Button>
          </Td>

        </Tr>
      );
    });
  };

  deletePackages = () => {
    const ids = this.state.selected;
    this.props.deletePackages(ids);
    this.setState({ selected: [] });
  };

  selectAll = value => {
    if (value.length > 0) {
      let selected = [];
      this.props.packages.results.forEach(pack => {
        selected.push(pack.object_id.toString());
      });
      this.setState({ selected });
      return;
    }
    this.setState({ selected: [] });
  };

  select = value => {
    this.setState({ selected: value });
  };

  renderEmptyState = () => {
    const {
      customer_type, plan, actions, role, operational_user
    } = this.props.user;

    const permissions = [];
    if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
      operational_user.operational_role.actions.map(element => permissions.push(element));
    }

    if (plan && plan.features) {
      plan.features.map(element => permissions.push(element));
    }
    if (actions) {
      actions.map(element => permissions.push(element));
    }
    return (
      <EmpyState name="packages">
        <Title className="state-title">Crea un empaque</Title>
        <Text>
          Registrando empaques mantienes toda tu operación logística más ordenada y te ayuda a
          generar guías de envío mucho más rápido
        </Text>
        {searchPermission('empaques', 'editar', permissions) && (
          <Button className="state-btn" onClick={() => this.goToCreatePackage()}>
            Agregar empaque
          </Button>
        )}
      </EmpyState>
    );
  };

  handleFilterChange = value => {
    this.setState({ filter: value });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      let filters = this.state.filters;
      filters.push(this.state.filter);
      this.setState({
        filters,
        filter: ''
      });
    }
  };

  onClose = index => {
    let filters = this.state.filters;
    filters.splice(index, 1);
    this.setState({ filters });
  };
  renderTableV2() {
    const {
      customer_type, plan, actions, role, operational_user
    } = this.props.user;

    const permissions = [];
    if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
      operational_user.operational_role.actions.map(element => permissions.push(element));
    }

    if (plan && plan.features) {
      plan.features.map(element => permissions.push(element));
    }
    if (actions) {
      actions.map(element => permissions.push(element));
    }
    const results = this.props.packages.results.filter(p => {
      let found = 0;
      if (this.state.filters.length === 0) return true;
      this.state.filters.forEach(filter => {
        const re = new RegExp(filter, 'i');
        if (
          re.test(p.alias) ||
          re.test(p.object_type) ||
          re.test(p.object_status) ||
          re.test(p.height) ||
          re.test(p.width) ||
          re.test(p.quantity) ||
          re.test(p.length)
        ) {
          found += 1;
        }
      });
      return found === this.state.filters.length;
    });
    const columns = [
      {
        name: 'Alias',
        selector: 'alias'
      },
      {
        name: 'Tipo',
        selector: 'object_type'
      },
      {
        name: 'Medidas',
        selector: 'height',
        cell: row => row.length + ' x ' + row.width + ' x ' + row.height + ' cm'
      },
      {
        name: 'Estatus',
        selector: 'object_status'
      }
    ];
    if (searchPermission('empaques', 'editar', permissions)) {
      columns.push({
        button: true,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: row => (
          <Button type="link" onClick={() => this.goToEditPackage(row)}>
            Editar
          </Button>
        )
      });
    }
    const filters = this.state.filters.map((filter, index) => {
      return (
        <Filter
          icon={box_icon}
          style={{ marginLeft: '0px' }}
          key={index}
          onClose={() => this.onClose(index)}
        >
          {filter}
        </Filter>
      );
    });
    return (
      <div>
        <Text>Filtrar por</Text>
        <Row>
          <Column xs={12} md={3} fluid>
            <Input
              parentClassName="filter-input"
              type="text"
              value={this.state.filter}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleFilterChange}
              placeholder="Palabra clave..."
            />
          </Column>
        </Row>
        {filters}
        <NewTable
          //hideForSmall={true}
          selectableRows={true}
          title={'Empaques'}
          shipping={false}
          columns={columns}
          data={results}
          onRowClicked={this.viewPackageDetails}
          onTableUpdate={table => {
            this.setState({ selected: table.selectedRows.map(packs => packs.object_id) });
          }}
          clearSelectedRows={this.state.clearSelectedRows}
          noDataComponent={<div />}
          subHeader={
            this.state.selected.length === 0
              ? ''
              : searchPermission('empaques', 'eliminar', permissions) && (
                  <Button
                    className="action-btn btn-subheader-space"
                    type="table"
                    onClick={() => this.deletePackages()}
                    disabled={this.state.paymentDisabled}
                  >
                    Eliminar
                  </Button>
                )
          }
        />
      </div>
    );
  }
  renderTable = () => {
    const results = this.props.packages.results.filter(p => {
      let found = 0;
      if (this.state.filters.length === 0) return true;
      this.state.filters.forEach(filter => {
        const re = new RegExp(filter, 'i');
        if (
          re.test(p.alias) ||
          re.test(p.object_type) ||
          re.test(p.object_status) ||
          re.test(p.height) ||
          re.test(p.width) ||
          re.test(p.quantity) ||
          re.test(p.length)
        ) {
          found += 1;
        }
      });
      return found === this.state.filters.length;
    });

    const filters = this.state.filters.map((filter, index) => {
      return (
        <Filter style={{ marginLeft: '0px' }} key={index} onClose={() => this.onClose(index)}>
          {filter}
        </Filter>
      );
    });
    return (
      <div>
        <Text>Filtrar por</Text>
        <Row>
          <Column xs={12} md={3} fluid>
            <Input
              parentClassName="filter-input"
              type="text"
              value={this.state.filter}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleFilterChange}
              placeholder="Palabra clave..."
            />
          </Column>
        </Row>
        {filters}
        <Table>
          <thead>
            <Tr noBorder>
              <Th checkbox>
                <Button type="table" className="checkbox-header-table">
                  <CheckboxGroup
                    onChange={value => {
                      this.selectAll(value);
                    }}
                    type="table"
                  >
                    <Checkbox value="all" />
                  </CheckboxGroup>
                </Button>
              </Th>
              <Th>
                {this.state.selected.length === 0 ? (
                  'Alias'
                ) : (
                  <Button className="action-btn" type="table" onClick={() => this.deletePackages()}>
                    Eliminar
                  </Button>
                )}
              </Th>
              <Th>{this.state.selected.length === 0 ? 'Tipo' : null}</Th>
              <Th>{this.state.selected.length === 0 ? 'Medidas' : null}</Th>
              <Th>{this.state.selected.length === 0 ? 'Estatus' : null}</Th>
              <Th>{this.state.selected.length === 0 ? '' : null}</Th>
            </Tr>
          </thead>
          <tbody>{results ? this.generateTable(results) : null}</tbody>
        </Table>
      </div>
    );
  };

  goToCreatePackage = () => {
    this.resetForms();
    this.props.toggleSideBar(true, 'Nuevo empaque', NEW_PACKAGE);
  };
  goToEditPackage = (p, duplicate = false) => {
    const object_id = p.object_id ? p.object_id : p.id;
    const pack = this.state.current_packages.find(x => x.id === object_id);
    this.resetForms();
    this.props.toggleSideBar(
      true,
      `${duplicate ? 'Duplicando' : 'Editando'} empaque "${
        pack.alias.length > 15 ? pack.alias.slice(0, 15) + '...' : pack.alias
      }"`,
      NEW_PACKAGE
    );

    if (duplicate) pack['id'] = null;

    this.setState({
      ...this.state,
      newPackage: pack
    });
  };

  viewPackageDetails = (p, rowinfo) => {
    const pack = this.state.current_packages.find(x => x.id === p.object_id);
    this.props.toggleSideBar(
      true,
      `Empaque "${pack.alias.length > 15 ? pack.alias.slice(0, 15) + '...' : pack.alias}"`,
      PACKAGE_DETAILS
    );
    this.setState({
      ...this.state,
      newPackage: pack
    });
  };

  deleteSinglePackage = pack => {
    this.props.toggleSideBar(false);
    this.props.deletePackages([pack.id], true);
  };

  resetForms = () => {
    this.setState({
      newPackage: {
        id: '',
        alias: '',
        aliasError: '',
        type: '',
        typeError: '',
        dimensions: {
          length: '',
          width: '',
          height: ''
        },
        dimensionsError: '',
        description: '',
        descriptionError: '',
        weight: '',
        weightError: '',
        isLoading: false,
        insuredAmount: ''
      }
    });
  };

  _onChangeDimensions = (value, key) => {
    this.setState({
      newPackage: {
        ...this.state.newPackage,
        dimensions: {
          ...this.state.newPackage.dimensions,
          [key]: value
        }
      }
    });
  };

  handleChange = (value, key) => {
    this.setState({
      newPackage: {
        ...this.state.newPackage,
        [key]: value
      }
    });
  };

  async savePackage(type) {
    let validate = validatePackage(this.state.newPackage);

    this.setState({
      newPackage: {
        ...validate[1],
        isLoading: validate[0]
      }
    });

    if (!validate[0]) {
      // Not valid
      return;
    }

    const pack = translatePackage(this.state.newPackage);

    if (type === 'create') {
      await this.props.createPackage(pack).then(() => {
        this.resetForms();
        this.props.toggleSideBar(false);
      });
    } else if (type === 'edit') {
      await this.props.editPackage(pack, this.state.newPackage.id).then(() => {
        this.resetForms();
        this.props.toggleSideBar(false);
      });
    }
  }

  _renderSidebarExtraContent = () => {
    switch (this.props.sidebar.type) {
      case NEW_PACKAGE:
        return <div />;

      case PACKAGE_DETAILS:
        return (
          <DetailedPackageMenu
            edit={this.state.canUpdate}
            delete={this.state.canDelete}
            editCurrentPackageFunction={() => this.goToEditPackage(this.state.newPackage)}
            duplicateCurrentPackageFunction={() =>
              this.goToEditPackage(this.state.newPackage, true)
            }
            deleteCurrentPackageFunction={() => this.deleteSinglePackage(this.state.newPackage)}
            changePackageFunction={this.viewPackageDetails}
            indexControl={{
              current: this.state.newPackage.index,
              max: this.state.current_packages.length
            }}
          />
        );
      default:
        return <div />;
    }
  };
  _renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
      case NEW_PACKAGE:
        const mainProps = {
          generate: name => {
            return {
              onChange: value => {
                this.handleChange(value, name);
              },
              value: this.state.newPackage[name],
              error: this.state.newPackage[`${name}Error`]
            };
          }
        };
        return (
          <ContentAdapter className="create-package-form">
            <Row>
              <Column fluid>
                <Input
                  type="text"
                  {...mainProps.generate('alias')}
                  required
                  placeholder="Sobre amarillo"
                  label="Alias"
                />
              </Column>
              <Column fluid>
                <Text type="microHeader">Información del empaque</Text>
              </Column>
              <Column fluid>
                <Label>Tipo</Label>
                <Required status={this.state.newPackage.typeError ? 'error' : 'default'} />
              </Column>
              <Column className="boxes-options" fluid>
                <SelectableBox
                  type="radio"
                  name="type"
                  className="type-select"
                  value="caja"
                  isChecked={this.state.newPackage.type == 'caja'}
                  onChange={() => this.handleChange('caja', 'type')}
                >
                  <img src={box_icon} />
                  Caja
                </SelectableBox>
                <SelectableBox
                  type="radio"
                  name="type"
                  className="type-select"
                  value="sobre"
                  isChecked={this.state.newPackage.type == 'sobre'}
                  onChange={() => this.handleChange('sobre', 'type')}
                >
                  <img src={packet_icon} />
                  Sobre
                </SelectableBox>
              </Column>
              <Column fluid>
                <HelpText
                  className={
                    'animated-help-text ' + (this.state.newPackage.typeError ? 'open' : '')
                  }
                >
                  {this.state.newPackage.typeError}
                </HelpText>
              </Column>
              <Column xs={9} fluid>
                <Input
                  type="text"
                  textClass="span-text"
                  measureClass="measureClass"
                  value={this.state.newPackage.dimensions}
                  error={this.state.newPackage.dimensionsError}
                  required
                  onChange={this._onChangeDimensions}
                  placeholder="30"
                  label="Dimensiones"
                  dimensions
                />
              </Column>
              <Column fluid>
                <Text type="microHeader">Predeterminar peso y contenido</Text>
                <Text type="microDescription">
                  Esta información es opcional, te recomendamos llenarla sólo en el caso de que
                  estos datos se repitan constantemente en tus envíos.
                </Text>
              </Column>
              <Column xs={6} fluid style={{ paddingRight: '8px' }}>
                <Input
                  type="text"
                  maxLength='30'
                  placeholder="Playeras serigrafiadas"
                  label="Descripción del contenido"
                  {...mainProps.generate('description')}
                />
              </Column>
              <Column xs={3} fluid>
                <Input
                  suffix="kg"
                  type="text"
                  placeholder="100.0"
                  label="Peso"
                  {...mainProps.generate('weight')}
                />
              </Column>
              <Column fluid>
                <Input
                  prefix="$"
                  type="number"
                  placeholder="1500.00"
                  label="Valor del paquete"
                  {...mainProps.generate('insuredAmount')}
                />
              </Column>
            </Row>
            <BottomContainer>
              <Row divisions={20}>
                <Column style={{ textAlign: 'right' }} fluid>
                  <Button
                    className=""
                    onClick={() => this.savePackage(this.state.newPackage.id ? 'edit' : 'create')}
                    loading={this.state.newPackage.isLoading}
                  >
                    Guardar
                  </Button>
                </Column>
                <Column xs={1} xsShift={1} style={{ textAlign: 'right' }} fluid />
              </Row>
            </BottomContainer>
          </ContentAdapter>
        );

      case PACKAGE_DETAILS:
        let pack = this.state.newPackage;
        return (
          <ContentAdapter className="detailed-package">
            <div className="section-separator adjust-top">
              <img src={detail_eye} /> Detalle de empaque
            </div>
            <Row>
              <Column fluid>
                <Text type="sidebarInfoTitle">Alias</Text>
                <P>{pack.alias}</P>
              </Column>
              <Column fluid>
                <Text type="sidebarInfoTitle">Tipo</Text>
                <P>{jsUcfirst(pack.type)}</P>
              </Column>
              <Column fluid>
                <Text type="sidebarInfoTitle">Dimensiones</Text>
                <P>{`${pack.dimensions.length} x ${pack.dimensions.width} x ${
                  pack.dimensions.height
                } cm`}</P>
              </Column>
              {pack.description && (
                <div>
                  <Column fluid>
                    <Text type="microHeader">Peso y contenido</Text>
                  </Column>
                  <Column fluid>
                    <Text type="sidebarInfoTitle">Contenido del Paquete</Text>
                    <P>{pack.description}</P>
                  </Column>
                </div>
              )}
              {pack.weight && (
                <Column fluid>
                  <Text type="sidebarInfoTitle">Peso</Text>
                  <P>{pack.weight}</P>
                </Column>
              )}
              {pack.insuredAmount && (
                <Column fluid>
                  <Text type="sidebarInfoTitle">Valor del Paquete</Text>
                  <P>{pack.insuredAmount}</P>
                </Column>
              )}
            </Row>
          </ContentAdapter>
        );
      default:
        return <div />;
    }
  };

  render() {
    if (
      this.props.message.show &&
      (this.props.message.messageType === 'error' || this.props.message.messageType === 'success')
    ) {
      setTimeout(this.props.hideMessage, 5000);
    }
    const {
      customer_type, plan, actions, role, operational_user
    } = this.props.user;

    const permissions = [];
    if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
      operational_user.operational_role.actions.map(element => permissions.push(element));
    }

    if (plan && plan.features) {
      plan.features.map(element => permissions.push(element));
    }
    if (actions) {
      actions.map(element => permissions.push(element));
    }
    return (
      <div>
        <WrapperSideBar
          icon={
            this.state.newPackage.id && this.props.sidebar.type == PACKAGE_DETAILS
              ? this.state.newPackage.type == 'caja'
                ? box_icon
                : packet_icon
              : null
          }
          title_more={this._renderSidebarExtraContent()}
        >
          {this._renderSidebarContent()}
        </WrapperSideBar>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Header>
              <Row divisions={18}>
                <Column xs={6} fluid>
                  <Title type="titleCard">Empaques</Title>
                </Column>
                <Column
                  xs={7}
                  xsShift={5}
                  style={{
                    textAlign: 'right'
                  }}
                  fluid
                >
                  {searchPermission('empaques', 'editar', permissions) && (
                    <Button className="header-btn last" onClick={() => this.goToCreatePackage()}>
                      Nuevo empaque
                    </Button>
                  )}
                </Column>
              </Row>
            </Header>
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            {this.props.message.show ? (
              <Message color={this.props.message.messageType} onClose={this.props.hideMessage}>
                {this.props.message.text}
              </Message>
            ) : null}
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            {!this.props.packages.results || this.props.packages.results.length === 0
              ? this.renderEmptyState()
              : this.renderTableV2()}
          </Column>
          <Column xs={1} fluid />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  packages: state.packages,
  message: state.message,
  sidebar: state.sidebar,
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deletePackages,
      createPackage,
      editPackage,
      toggleSideBar,
      changeSidebarTitle,
      goToCreatePackage: () => push('/empaques/crear'),
      goToEditPackage: id => push(`/empaques/editar/${id}`),
      hideMessage
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackagesList);
