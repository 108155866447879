import React from "react";
import { Row, Column, element } from "hedron";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { boundMethod } from "autobind-decorator";
import axios from "axios";

import Skeleton from "react-loading-skeleton";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { DateRange } from "react-date-range";
import * as rdrLocales from "react-date-range/src/locale";
import AccountSetupConfig from "../../components/AccountSetupConfig";
import AccountsManagment from "../../components/AccountsManagment";
import AccountDataVisualization from "../../components/AccountDataVisualization";
import Button from "../../components/Button";

import Input, { Label, Required, HelpText } from "../../components/Input";
import Text, { Title, P } from "../../components/Text";
import WrapperSideBar from "../../components/WrapperSideBar";
import {
  ContentAdapter,
  BottomContainer,
} from "../../components/WrapperSideBar/styledObjects";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../modules/CollapsibleBar/actions";
import Dropdown from "../../components/Dropdown";
import EmptyState from "./EmptyState";
import { getRegions, getManagerRegions } from "../../modules/regions";
import {
  createAccount,
  getMasterCountries,
  getManagerCountries,
  getAccounts,
} from "../../modules/marketplaces";

import { RadioGroup, Radio } from "../../components/Radio";

import "./style.css";
import { searchPermission } from "../../utils/global_functions";

const REPORT = "REPORT";
const NEW_ACCOUNT = "NEW_ACCOUNT";
const today = new Date();
const tomorrow = new Date();

class MarketplaceAccount extends React.Component {
  state = {
    selectedRegion: null,
    selectedRegionError: "",
    selectedRegionIndex: null,
    name: "",
    nameError: "",
    mail: "",
    mailError: "",
    password: "",
    passwordError: "",
    passwordConfirm: "",
    passwordConfirmError: "",
    phone: "",
    phoneError: "",
    lastName: "",
    lastNameError: "",
    selectedUser: null,
    loadingSideBar: false,
    regionsData: [],
    countriesData: [],
    emptyRegions: true,
    reloadAccounts: false,
    selectedCountry: null,
    selectedCountryError: "",
    selectedCountryIndex: null,
    optionsAccounts: [],
    optionsCountries: [],
    selectionRange: {
      selection: {
        startDate: today,
        endDate: tomorrow,
        key: "selection",
      },
    },
    valueCuentas: [],
    valuePaises: [],
    errorAccounts: false,
    type: 0,
    startDate: null,
    endDate: null
  };

  constructor(props: Props) {
    super(props);
  }

  componentWillMount() {
    this.props.toggleSideBar(false);
  }

  componentDidMount() {
    this.getCountriesData();
    this.getRegionsData();
  }

  getCountriesData() {
    if (
      this.props.user.role === "master" ||
      (this.props.user.plan && this.props.user.plan.name === "enterprise") ||
      (this.props.user.plan && this.props.user.plan.name === "operativo")
    ) {
      axios
        .get("api/regions")
        .then(({ data }) => {
          // evitamos regiones que tienen varios paises "Todas las regiones"
          const contries = data
            .filter((countr) => countr.countries.length === 1)
            .map((ele) => ele.countries[0]);

          this.setState({ countriesData: contries });
        })
        .catch((e) => {
          console.log("Cou", e);
          this.notify("Error al obtener la información", "error");
        });

      // getMasterCountries().then((response) => {
      //   console.log(response);
      //   if (response.type === "Success" && response.response.status === 200) {
      //     this.setState({ countriesData: response.response.data });
      //   } else {
      //     this.notify("Error al obtener la información", "error");
      //   }
      // });
    } else {
      getManagerCountries(this.props.user.object_id).then((response) => {
        if (response.type === "Success" && response.response.status === 200) {
          this.setState({ countriesData: response.response.data });
        } else {
          this.notify("Error al obtener la información", "error");
        }
      });
    }
  }

  getRegionsData() {
    if (
      this.props.user.role === "master" ||
      (this.props.user.plan && this.props.user.plan.name === "enterprise") ||
      (this.props.user.plan && this.props.user.plan.name === "operativo")
    ) {
      getRegions().then((response) => {
        if (response.type === "Success" && response.response.status === 200) {
          this.setState({ regionsData: response.response.data });
        } else {
          this.notify("Error al obtener la información", "error");
        }
      });
    } else {
      getManagerRegions(this.props.user.object_id).then((response) => {
        if (response.type === "Success" && response.response.status === 200) {
          this.setState({ regionsData: response.response.data });
        } else {
          this.notify("Error al obtener la información", "error");
        }
      });
    }
  }

  handleChangeDropdown = (selectedOption) => {
    this.setState({
      selectedRegion: this.state.regionsData[selectedOption],
      selectedRegionIndex: selectedOption,
    });
  };

  handleChangeDropdownCountry = (selectedOption) => {
    this.getMarketplacesData(this.state.countriesData[selectedOption].id);
    this.setState({
      selectedCountry: this.state.countriesData[selectedOption],
      selectedCountryIndex: selectedOption,
    });
  };

  handleOnChange(type, value) {
    switch (type) {
      case "name":
        this.setState({ name: value });
        break;
      case "mail":
        this.setState({ mail: value });
        break;
      case "password":
        this.setState({ password: value });
        break;
      case "phone":
        this.setState({ phone: value });
        break;
      case "lastName":
        this.setState({ lastName: value });
        break;
      case "passwordConfirm":
        this.setState({ passwordConfirm: value });
        break;
      default:
        break;
    }
  }

  clearSb() {
    this.setState({
      name: "",
      lastName: "",
      phone: "",
      mail: "",
      password: "",
      passwordConfirm: "",
      selectedRegion: null,
      selectedRegionIndex: null,
      nameError: "",
      mailError: "",
      phoneError: "",
      lastNameError: "",
      passwordError: "",
      passwordConfirmError: "",
      selectedCountryError: [],
    });
  }

  renderRegions = () =>
    this.state.regionsData
      ? this.state.regionsData.map((region) => (
        <Text key={region.id}>{region.name}</Text>
      ))
      : null;

  renderCountries = () =>
    this.state.countriesData
      ? this.state.countriesData.map((country) => (
        <Text key={country.id}>{country.name}</Text>
      ))
      : null;

  renderCountriesMulti = () => {
    const tempArray = [];
    this.state.countriesData.map((element) => {
      tempArray.push({ value: element.id, label: element.name });
    });
    return tempArray;
  };

  _renderSideBarLoader = () => (
    <ContentAdapter>
      <Skeleton style={{ marginTop: 20 }} height={250} />
    </ContentAdapter>
  );

  validateFields(region) {
    let flag = true;
    const {
      name,
      lastName,
      mail,
      password,
      passwordConfirm,
      phone,
      selectedRegionIndex,
    } = this.state;
    if (name === "") {
      flag = false;
      this.setState({ nameError: "Campo obligatorio" });
    } else {
      this.setState({ nameError: "" });
    }
    if (lastName === "") {
      flag = false;
      this.setState({ lastNameError: "Campo obligatorio" });
    } else {
      this.setState({ lastNameError: "" });
    }
    if (mail === "") {
      flag = false;
      this.setState({ mailError: "Campo obligatorio" });
    } else {
      this.setState({ mailError: "" });
    }
    if (phone === "") {
      flag = false;
      this.setState({ phoneError: "Campo obligatorio" });
    } else {
      this.setState({ phoneError: "" });
    }
    if (password === "") {
      flag = false;
      this.setState({ passwordError: "Campo obligatorio" });
    } else {
      this.setState({ passwordError: "" });
    }
    if (passwordConfirm === "") {
      flag = false;
      this.setState({ passwordConfirmError: "Campo obligatorio" });
    } else {
      this.setState({ passwordConfirmError: "" });
    }
    if (passwordConfirm !== password) {
      flag = false;
      this.setState({ passwordConfirmError: "Las constraseñas no coinciden" });
    } else {
      this.setState({ passwordConfirmError: "" });
    }
    if (!region) {
      if (selectedRegionIndex === null) {
        flag = false;
        this.setState({ selectedRegionError: "Campo obligatorio" });
      } else {
        this.setState({ selectedRegionError: "" });
      }
    }

    return flag;
  }

  notify = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  addMarketplace() {
    const {
      name,
      lastName,
      mail,
      password,
      passwordConfirm,
      phone,
      selectedRegion,
    } = this.state;
    if (this.props.user.role === "regional") {
      if (this.validateFields(true)) {
        createAccount(
          this.props.user.object_id,
          name,
          lastName,
          mail,
          phone,
          password,
          passwordConfirm
        ).then((res) => {
          if (res.response.status === 200) {
            this.notify("Cuenta creada exitosamente", "success");
            this.props.toggleSideBar(false);
            this.clearSb();
            this.setState({ reloadAccounts: true }, () => {
              this.setState({ reloadAccounts: false });
            });
          } else {
            this.notify("Error al crear cuenta", "error");
            this.props.toggleSideBar(false);
            this.clearSb();
          }
        });
      }
    } else if (this.validateFields(false)) {
      createAccount(
        selectedRegion.id,
        name,
        lastName,
        mail,
        phone,
        password,
        passwordConfirm
      ).then((res) => {
        if (res.response.status === 200) {
          this.notify("Cuenta creada exitosamente", "success");
          this.props.toggleSideBar(false);
          this.clearSb();
          this.setState({ reloadAccounts: true }, () => {
            this.setState({ reloadAccounts: false });
          });
        } else {
          this.notify("Error al crear cuenta", "error");
          this.props.toggleSideBar(false);
          this.clearSb();
        }
      });
    }
  }

  validateFieldsReport() {
    let flag = true;
    if (this.state.selectedCountry) {
      this.setState({ selectedCountryError: "" });
    } else {
      flag = false;
      this.setState({ selectedCountryError: "Campo Obligatorio" });
    }
    if (this.state.valueCuentas.length === 0) {
      flag = false;
      this.setState({ errorAccounts: true });
    } else {
      this.setState({ errorAccounts: false });
    }
    return flag;
  }

  formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join("-");
  }

  generateReport() {
    if (this.state.type === 0) {
      if (this.state.valuePaises.length !== 0) {
        const tempArray = [];
        this.state.valuePaises.forEach((element, index) => {
          setTimeout(
            () =>
              getAccounts(element.value).then((response) => {
                if (
                  response.type === "Success" &&
                  response.response.status === 200
                ) {
                  response.response.data.forEach((account) => {
                    tempArray.push(account.id);
                  });
                  if (index === this.state.valuePaises.length - 1) {
                    const start = this.formatDate(
                      this.state.selectionRange.selection.startDate
                    );
                    const end = this.formatDate(
                      this.state.selectionRange.selection.endDate
                    );
                    axios
                      .post("api/report_by_date", {
                        fecha_inicial: start,
                        fecha_final: end,
                        ids: tempArray,
                        countries: this.state.valuePaises.map((x)=>x.value)
                      })
                      .then((r) => {
                        switch (r.status) {
                          case 200:
                            r.data.files.forEach((element) => {
                              const a = document.createElement("a");
                              a.href = element.file;
                              a.download = element.name;
                              document.body.appendChild(a);
                              a.click();
                              a.remove();
                            });
                            this.props.toggleSideBar(false);
                            this.setState({
                              valueCuentas: [],
                              selectedCountry: null,
                              selectedCountryIndex: null,
                            });
                            break;
                          default:
                            hideLoader();
                            showError("Error al procesar cotizacion");
                            break;
                        }
                      });
                  }
                }
              }),
            3000
          );
        });
      }
    } else if (this.state.type === 1 && this.validateFieldsReport()) {
      const start = this.formatDate(
        this.state.selectionRange.selection.startDate
      );
      const end = this.formatDate(this.state.selectionRange.selection.endDate);
      const ids = this.state.valueCuentas.map((element) => element.value);
      const country = this.state.selectedCountry.name;
      axios
        .post("api/report_by_date", {
          fecha_inicial: start,
          fecha_final: end,
          ids,
          country
        })
        .then((r) => {
          switch (r.status) {
            case 200:
              r.data.files.forEach((element) => {
                console.log(element)
                const a = document.createElement("a");
                a.href = element.file;
                a.download = element.name;
                document.body.appendChild(a);
                a.click();
                a.remove();
              });
              this.props.toggleSideBar(false);
              this.setState({
                valueCuentas: [],
                selectedCountry: null,
                selectedCountryIndex: null,
              });
              break;
            default:
              hideLoader();
              showError("Error al generar reporte");
              break;
          }
        });
    } else {
      const startDate = this.formatDate(this.state.selectionRange.selection.startDate)
      const endDate = this.formatDate(this.state.selectionRange.selection.endDate)
      const country = this.state.selectedCountry.name

      axios.post('api/consiliation-report', {
        startDate,
        endDate,
        country
      }).then(response => {
        switch (response.status) {
          case 200:
            const name = response.data.name
            const file = response.data.file
            const a = document.createElement("a");
            a.href = file
            a.download = name
            document.body.appendChild(a)
            a.click()
            a.remove()

            this.props.toggleSideBar(false);
            this.setState({
              valueCuentas: [],
              selectedCountry: null,
              selectedCountryIndex: null,
            });
            break;
          default:
            showError("Error al generar reporte");
            break;
        }
      }).catch(() => {
        //showError("Error al generar reporte");
      })
    }

  }

  _renderSidebarContent = () => {
    const { type } = this.props.sidebar;
    const { user } = this.props;
    const { plan, operational_user, actions } = user;
    const {
      selectedRegion,
      selectedRegionError,
      name,
      nameError,
      lastName,
      lastNameError,
      mail,
      mailError,
      password,
      passwordError,
      passwordConfirm,
      passwordConfirmError,
      phone,
      phoneError,
      selectedRegionIndex,
      selectedCountryError,
      selectedCountryIndex,
      optionsAccounts,
    } = this.state;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }

    switch (type) {
      case NEW_ACCOUNT:
        return (
          <ContentAdapter>
            {user.role === "regional" ? (
              <Input
                onChange={(value) => this.handleOnChange("name", value)}
                type="text"
                required
                value={user.first_name}
                placeholder=""
                label="Región"
                disabled
              />
            ) : (
              <Dropdown
                required
                label="Selecciona una Región"
                options={this.renderRegions()}
                style={{ marginTop: "12px" }}
                placeholder={
                  <Text className="dropdown-placeholder">Región</Text>
                }
                selected={selectedRegionIndex}
                handleChange={this.handleChangeDropdown}
                error={selectedRegionError}
              />
            )}

            <HelpText>
              La región determina las paqueterias y el tipo de tarifas que te
              retornara la plataforma
            </HelpText>
            <Input
              onChange={(value) => this.handleOnChange("name", value)}
              type="text"
              required
              value={name}
              error={nameError}
              placeholder=""
              label="Nombre"
            />
            <Input
              onChange={(value) => this.handleOnChange("lastName", value)}
              type="text"
              required
              value={lastName}
              error={lastNameError}
              placeholder="Pérez"
              label="Apellidos"
            />
            <Input
              onChange={(value) => this.handleOnChange("mail", value)}
              type="mail"
              required
              value={mail}
              error={mailError}
              placeholder="regionsur@mienvio.mx"
              label="Correo"
            />
            <Input
              onChange={(value) => this.handleOnChange("phone", value)}
              type="text"
              required
              value={phone}
              error={phoneError}
              placeholder="4242424242"
              label="Telefóno"
            />
            <Input
              onChange={(value) => this.handleOnChange("password", value)}
              type="password"
              required
              value={password}
              error={passwordError}
              placeholder="****************"
              label="Contraseña"
            />
            <Input
              onChange={(value) =>
                this.handleOnChange("passwordConfirm", value)
              }
              type="password"
              required
              value={passwordConfirm}
              error={passwordConfirmError}
              placeholder="****************"
              label="Confirmar Contraseña"
            />
            <BottomContainer>
              <Row divisions={20}>
                <Column style={{ textAlign: "right" }} fluid>
                  <Button
                    loading={this.state.setLoading}
                    onClick={() => this.addMarketplace()}
                    className=""
                  >
                    Guardar
                  </Button>
                </Column>
                <Column
                  xs={1}
                  xsShift={1}
                  style={{ textAlign: "right" }}
                  fluid
                />
              </Row>
            </BottomContainer>
          </ContentAdapter>
        );
      case REPORT:
        return (
          <ContentAdapter>
            <RadioGroup
              style={{ marginTop: "2rem" }}
              className="no-margin-checkbox"
              selected={this.state.type}
              onChange={(value) => this.setState({ type: value })}
            >
              <Radio
                value={0}
                text="Reporte por Pais(es)"
                containerClass="custom-size"
                className="no-margin-left"
              />
              <Radio
                value={1}
                text="Reporte por Cuenta(s)"
                containerClass="custom-size max"
              />
              {searchPermission("conciliar", "leer", permissions)
                ? <Radio
                  value={2}
                  text="Reporte de conciliacion"
                  containerClass="custom-size max"
                />
                : <div />}
            </RadioGroup>
            <br />
            {this.state.type === 0 ? (
              <div>
                <Select
                  isMulti
                  noOptionsMessage={() => "Sin Opciones"}
                  isSearchable
                  style={{ marginTop: "1rem" }}
                  placeholder="Seleccionar Paises"
                  closeMenuOnSelect={false}
                  options={this.renderCountriesMulti()}
                  className="search-select accounts"
                  value={this.state.valuePaises}
                  onChange={(selectedOption) => {
                    this.setState({
                      valuePaises: selectedOption,
                    });
                  }}
                />
                {this.state.valuePaises.length !== 0 && (
                  <div>
                    <Label style={{ marginTop: "16px" }}>
                      Rango de Fechas <Required />
                    </Label>
                    <div className="container-daterange">
                      <DateRange
                        ranges={[this.state.selectionRange.selection]}
                        onChange={(value) =>
                          this.setState({ selectionRange: value })
                        }
                        color="#2576DA"
                        locale={rdrLocales.es}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : this.state.type === 1 ? (
              <div>
                {user.role === "regional" ? (
                  <Input
                    onChange={(value) => this.handleOnChange("name", value)}
                    type="text"
                    required
                    value={user.first_name}
                    placeholder=""
                    label="Región"
                    disabled
                  />
                ) : (
                  <Dropdown
                    required
                    label="Selecciona una Pais"
                    options={this.renderCountries()}
                    style={{ marginTop: "12px" }}
                    placeholder={
                      <Text className="dropdown-placeholder">País</Text>
                    }
                    selected={selectedCountryIndex}
                    handleChange={this.handleChangeDropdownCountry}
                    error={selectedCountryError}
                  />
                )}
                {this.state.selectedCountryIndex !== null && (
                  <div>
                    <Label style={{ marginTop: "16px" }}>
                      Cuenta(s) <Required />
                    </Label>
                    <Select
                      isMulti
                      noOptionsMessage={() => "Sin Opciones"}
                      isSearchable
                      placeholder="Seleccionar Cuentas"
                      closeMenuOnSelect={false}
                      options={optionsAccounts}
                      className="search-select accounts"
                      value={this.state.valueCuentas}
                      onChange={(selectedOption) => {
                        this.setState({
                          valueCuentas: selectedOption,
                        });
                      }}
                    />
                    {this.state.errorAccounts && (
                      <HelpText>Campo Obligatorio</HelpText>
                    )}

                    {this.state.valueCuentas.length !== 0 && (
                      <div>
                        <Label style={{ marginTop: "16px" }}>
                          Rango de Fechas <Required />
                        </Label>
                        <div className="container-daterange">
                          <DateRange
                            ranges={[this.state.selectionRange.selection]}
                            onChange={(value) =>
                              this.setState({ selectionRange: value })
                            }
                            color="#2576DA"
                            locale={rdrLocales.es}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <Dropdown
                  required
                  label="Selecciona una Pais"
                  options={this.renderCountries()}
                  style={{ marginTop: "12px" }}
                  placeholder={
                    <Text className="dropdown-placeholder">País</Text>
                  }
                  selected={selectedCountryIndex}
                  handleChange={this.handleChangeDropdownCountry}
                  error={selectedCountryError}
                />
                {this.state.selectedCountryIndex !== null && (
                  <div>
                    <Label style={{ marginTop: "16px" }}>
                      Rango de Fechas <Required />
                    </Label>
                    <div className="container-daterange">
                      <DateRange
                        ranges={[this.state.selectionRange.selection]}
                        onChange={(value) =>
                          this.setState({ selectionRange: value })
                        }
                        color="#2576DA"
                        locale={rdrLocales.es}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            <BottomContainer>
              <Row divisions={20}>
                <Column style={{ textAlign: "right" }} fluid>
                  <Button
                    loading={this.state.setLoading}
                    onClick={() => this.generateReport()}
                    className=""
                  >
                    Generar
                  </Button>
                </Column>
                <Column
                  xs={1}
                  xsShift={1}
                  style={{ textAlign: "right" }}
                  fluid
                />
              </Row>
            </BottomContainer>
          </ContentAdapter>
        );
      default:
        return <div />;
    }
  };

  getMarketplacesData(region) {
    getAccounts(region).then((response) => {
      if (response.type === "Success" && response.response.status === 200) {
        const tempArray = [];
        response.response.data.map((element) => {
          tempArray.push({ value: element.id, label: element.email });
        });
        this.setState({ optionsAccounts: tempArray });
      }
    });
  }

  @boundMethod
  selectUser(id) {
    this.setState({ selectedUser: id });
  }

  @boundMethod
  clearSelectedUser() {
    this.setState({ selectedUser: null });
  }

  notify = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  render() {
    return (
      <div>
        <ToastContainer />
        <WrapperSideBar handleClose={this.onCloseSidebar}>
          {this.state.loadingSideBar
            ? this._renderSideBarLoader()
            : this._renderSidebarContent()}
        </WrapperSideBar>
        <Row divisions={20}>
          <Column xs={6} fluid>
            <AccountsManagment
              countries={this.state.countriesData}
              reload={this.state.reloadAccounts}
              clearSelectedUser={this.clearSelectedUser}
              notify={this.notify}
              selectUser={this.selectUser}
              selectedUser={this.state.selectedUser}
            />
          </Column>
          <Column xs={1} fluid />
          <Column xs={13} fluid>
            {this.state.selectedUser ? (
              <AccountDataVisualization id={this.state.selectedUser} />
            ) : (
              <EmptyState props={this.props} />
            )}
          </Column>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  countries: state.newShipment.countries,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSideBar,
      changeSidebarTitle,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MarketplaceAccount);
