import Axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import Button from "../../components/Button";
import Input, { Label, Required, HelpText } from "../../components/Input";
import Logo from "../../components/static/Logo";
import { ToastContainer, toast } from "react-toastify";

import {
  clearPhone,
  formatPhone,
  validEmail,
  validPhone,
  validText,
  validUrl,
} from "../../utils/global_functions";
import { ReactComponent as Dots } from "./dots.svg";
import styles from "./style.module.scss";

const initialState = {
  data: {
    phone: { value: "", error: "" },
    first_name: { value: "", error: "" },
    last_name: { value: "", error: "" },
    url_site: { value: "", error: "" },
    email: { value: "", error: "" },
    tag: { value: "", error: "" },
  },
};

export default function LeadsInternalForm() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ ...initialState.data });
  const [toSendaData, setToSendData] = useState(null);

  useEffect(() => {
    const sendData = {};
    Object.keys(data).forEach((el) => {
      sendData[el] = data[el].value;
    });
    setToSendData(sendData);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (validForm()) {
      Axios.post(`api/prospectuses`, toSendaData)
        .then((resp) => {
          setData({ ...initialState.data });
          setToSendData(null);
          toast.success("Lead registrado correctamente", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        })
        .catch((e) => {
          console.log("e", e);
          toast.error("Error: Es probable que el lead ya este registrado", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const validForm = () => {
    const requiredVal = "Campo obligatorio";

    const validateData = {};

    Object.keys(data).forEach((el) => {
      validateData[el] = { ...data[el], error: createError(el, data) };
    });

    // data with errors
    setData({ ...validateData });

    // looking for errors an return value to continue
    const hasError = Object.keys(validateData).some(
      (e) => validateData[e].error
    );

    return !hasError;
  };

  const handleTagChange = (e) => {
    setData({ ...data, tag: { ...data.tag, value: e.value } });
  };

  const generateProps = (name) => {
    return {
      value:
        name === "phone" ? formatPhone(data[name].value) : data[name].value,
      error: data[name].error,
      //   value: data[name],
      onChange: (val) =>
        name === "phone"
          ? setData({
              ...data,
              [name]: { ...data[name], value: clearPhone(val) },
            })
          : setData({ ...data, [name]: { ...data[name], value: val } }),
    };
  };

  const tagOptions = useMemo(
    () => [
      { value: "9", label: "Redes sociales" },
      { value: "11", label: "Prospección" },
    ],
    []
  );

  return (
    <div className={styles.leadsPage}>
      <ToastContainer />
      <BackgroundPage />
      <div className={styles.logoContainer}>
        <Logo className={styles.logo} />
      </div>
      <div className={styles.card}>
        <h1 className={styles.title}>¡Crea un nuevo prospecto!</h1>
        <form onSubmit={handleSubmit}>
          <div className={styles.colInput}>
            <div className={styles.rowInput}>
              <Input
                {...generateProps("first_name")}
                type="text"
                label="Nombre(s)"
                required
              />
            </div>
            <div className={styles.rowInput}>
              <Input
                {...generateProps("last_name")}
                type="text"
                label="Apellido(s)"
                required
              />
            </div>
          </div>

          <Input
            {...generateProps("url_site")}
            type="url"
            label="Página web o de red social de tu tienda en línea"
          />

          <Input
            {...generateProps("email")}
            type="email"
            label="Correo electrónico"
            required
          />

          <Input
            {...generateProps("phone")}
            type="tel"
            label="Teléfono"
            required
          />
          <Label className={styles.tagLabel}>
            Tag
            <Required status={data.tag.error ? "error" : "default"} />
          </Label>

          <Select
            required
            isMulti={false}
            onChange={handleTagChange}
            noOptionsMessage={() => "Sin Opciones"}
            isSearchable
            placeholder="Seleccionar Tag"
            closeMenuOnSelect={true}
            options={tagOptions}
            className="search-select accounts"
          />

          {data.tag.error.length > 0 ? (
            <div style={{ marginTop: "0.9em" }}>
              <HelpText color="tomato">{data.tag.error}</HelpText>
            </div>
          ) : null}

          <Button type="primary" disabled={loading} className={styles.btnSend}>
            Crear lead
          </Button>
        </form>
      </div>
    </div>
  );
}

const BackgroundPage = () => (
  <div className={styles.backgroundPage}>
    <Dots className={styles.dots} />
    <div className={styles.triangle}>
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style={{ width: "100%", height: "100%" }}
      >
        <path
          d="M-1.41,121.88 C250.84,88.31 249.15,86.34 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
          style={{ stroke: "none", fill: "rgba(164, 181, 255, 0.1)" }}
        ></path>
      </svg>
    </div>
  </div>
);

const createError = (k, data) => {
  if (k === "last_name" || k === "first_name") {
    return data[k].value.length === 0 ? translationsErrrors(k) : "";
  }

  if (k === "phone") {
    return validPhone(data[k].value) ? "" : translationsErrrors(k);
  }

  if (k === "email") {
    return validEmail(data[k].value) ? "" : translationsErrrors(k);
  }

  if (k === "tag") {
    return data[k].value.length === 0 ? "Selecciona un Tag" : "";
  }

  if (k === "url_site") {
    if (data[k].value.length > 0) {
      return validUrl(data[k].value) ? "" : "Selecciona un Tag";
    }
    // return data[k].value.length === 0 ? "Selecciona un Tag" : "";
  }

  return "";
};

const translationsErrrors = (e) => {
  switch (e) {
    case "phone":
      return "Formato de teléfono inválido. Solo se admiten números sin clave internacional";
    case "email":
      return "Formato de correo inválido";
    case "last_name":
      return "Ingresa un apellido válido";
    case "first_name":
      return "Ingresa un nombre válido ";
    default:
      return null;
  }
};
