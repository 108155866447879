import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import { resendMail } from '../../modules/user';
import Button from '../Button';
import Logo from '../static/Logo';
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import LoginContainer from './container';

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);
  }

  handleEmail(email, action) {
    if(email){
      this.setState({ email, action });
    }


    if(action == "edit"){
       window.location = '/';
    }

  }

  handlePassword(password) {
    this.setState({ password });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.login();
    }
  }




  isLoggedIn() {

    if (localStorage.getItem('token')) {
      return true;
    }
    return false;
  }

  login() {
    this.props.login(this.state.email, this.state.password);
  }

  hideMessage() {
    this.props.hideMessage();
  }

  sendMail(email) {
    resendMail(email).then((response) => {
      try {
        this.notify(response.data.message, 'success');
      } catch(error) {
        this.notify('Email enviado exitosamente', 'success');
      }
    });
  }

  notify = (message, type) => {
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  openMailProvider(string) {
    if (/@gmail\.com$/.test(string)) {
      window.open('http://gmail.com', '_blank');
    } else if (/@outlook\.com$/.test(string)) {
      window.open('https://outlook.live.com/mail/', '_blank');
    } else if (/@hotmail\.com$/.test(string)) {
      window.open('https://outlook.live.com/mail/', '_blank');
    } else if (/@yahoo\.com$/.test(string)) {
      window.open('https://e1.mail.yahoo.com/', '_blank');
    } else {
      window.open('https://outlook.live.com/mail/', '_blank');
    }
  }

  render() {
    const { location } = this.props;
    return (
      <LoginContainer>
        <ToastContainer />
        <Logo />

        <br />

        <p className="big-tittle">Sólo falta un paso</p>
        <br />
        <p className="big-subtitle">
          Antes de continuar necesitamos verificar tu dirección de correo electrónico
        </p>
        <br />
        <p className="content-small">
          Se ha enviado un mensaje a:
          <br />
          <strong>{location.state && location.state.mail}</strong>
          <br />
          <br />
          Por favor, comprueba tu correo electrónico y haz clic en el vínculo del mensaje para
          completar la verificación de la dirección de correo electrónico
          <br />
          <br />
          ¿No has recibido nuestro mensaje?
          {' '}
          <u onClick={() => {
            if (location.state) {
              this.sendMail(location.state.mail);
            }
          }}
          >
          Da clic aquí
            {' '}

          </u>
        </p>
        <Button
          className="login-btn"
          onClick={() => {
            window.location = '/';
          }}
        >
          Ir a Panel
        </Button>
      </LoginContainer>
    );
  }
}

const mapStateToProps = state => ({ user: state.user });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyEmail);
