import styled from 'styled-components';
import colors from '../../utils/colors';

export const StyledOption = styled.div`
  padding: 16px 12px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${colors.gray.earth_stone};
  margin-bottom: 16px;
  box-sizing: border-box;
`;

export const I = styled.i`
  cursor: pointer;
  font-size: 20px;
  margin-top: 2px;
  margin-left: 5px;
`;

export const Img = styled.img`
  margin-right: 9px;
`;

export const Order = styled.div`
padding: 12px 18px 0px 18px;
border-bottom: solid 1px #eceff4;

@media screen and (min-width: 40em) {
  width: 543px;
}
&:hover {
  background: #f0f3f5;
  .delete{
    opacity:1;
  }
}

.delete{
  opacity:0;
}

.collapsible-enter {
  opacity: 0.5;
  height: ${props => props.collapsed ? '188px' : '124px'}
}

.collapsible-enter.collapsible-enter-active {
  opacity: 1;
  transition: height 150ms ease-in, opacity 300ms ease-in;
  height: ${props => props.collapsed ? '124px' : '188px'}
}


.collapsible-leave {
  opacity: 1;
  height: ${props => props.collapsed ? '124px' : '188px'}
}

.collapsible-leave.collapsible-leave-active {
  opacity: 0;
  transition: all 1ms ease-in;
  height: ${props => props.collapsed ? '188px' : '124px'}
}
`;

export const CardOption = styled.div`
  transition: 200ms;
  &:hover {
    background: #f0f3f5;
  }
}
`;

export const Td1 = styled.td`
  @media screen and (min-width: 40em) {
    width: 20px;
  }
`;

export const Td2 = styled.td`
  @media screen and (min-width: 40em) {
    width: 310px;
  }
`;

export const Td3 = styled.td`
  @media screen and (min-width: 40em) {
    width: 38px;
  }
`;

export const Td4 = styled.td`
  @media screen and (min-width: 40em) {
    width: 115px;
  }
`;

export const CardTab = styled.div`
  .fields-enter {
    opacity: 0;
    height:  ${props => props.numberCards * 51 + 41}px;
  }

  .fields-enter.fields-enter-active {
    opacity: 1;
    height: 247px;
    transition: height 300ms ease-in, opacity 600ms ease-in;
  }

  .fields-leave {
    opacity: 1;
    height: 247px;
  }

  .fields-leave.fields-leave-active {
    opacity: 0;
    transition: all 20ms ease-in;
    height:  ${props => props.numberCards * 51 + 41}px;
  }

  .cards-enter {
    opacity: 0;
    height: 247px;
  }

  .cards-enter.cards-enter-active {
    opacity: 1;
    height:  ${props => props.numberCards * 51 + 41}px;
    transition: height 300ms ease-in, opacity 600ms ease-in;
  }

  .cards-leave {
    opacity: 1;
    height:  ${props => props.numberCards * 51 + 41}px;
  }

  .cards-leave.cards-leave-active {
    opacity: 0;
    height: 247px;
    transition: all 20ms ease-in;
  }
`;
