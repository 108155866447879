import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import {
  getPrimaryAddress,
  savePrimaryAddress,
  createAddress,
  editAddress,
  deleteAddress,
  deletePrimaryAddress
} from "../../modules/addresses/actions";
import * as addressesSelectors from "../../modules/addresses/selectors";
import { hideMessage } from "../../modules/message";
import { toggle as toggleSideBar } from "../../modules/CollapsibleBar/actions";
import { clearSidebar, getCountries } from "../../modules/sidebarOrder/actions";
import "./style.css";

import AddressesList from "./AddressesListRender";

const mapStateToProps = state => ({
  addresses: state.addresses,
  message: state.message,
  loader: state.loader,
  sidebar: state.sidebar,
  addressesById: addressesSelectors.byId(state),
  user: state.user,
  countries: state.newShipment.countries
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createAddress,
      editAddress,
      deleteAddress,
      savePrimaryAddress,
      getPrimaryAddress,
      deletePrimaryAddress,
      goToAddress: id => push(`/direcciones/${id}`),
      goToAddressList: id => push("/direcciones"),
      hideMessage,
      toggleSideBar,
      clearSidebar,
      getCountries
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddressesList);
