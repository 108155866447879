import React from 'react';
import Text from '../../Text';
import Dropdown from '../../Dropdown';
import { Column, Row } from 'hedron';
import Button from '../../Button';

const MorePackages = props => (
  <Row>
    <Column xs={12} fluid>
      <div className="more-component">

        <Text type="microHeader" style={{ marginBottom: '8px' }}>
          Empaque
    </Text>
        <Dropdown
          options={props.packages}
          placeholder={<Text className="dropdown-placeholder">Buscar por alias</Text>}
          selected={props.selectedPack}
          selectedTitle={props.pack ? props.pack.alias : null}
          filter
          filterValue={props.filter}
          filterPlaceholder="Buscar por alias"
          handleChange={props.handleChange}
          handleFilterChange={props.handleFilterChange}
        />
        <Button type="link" className="edit-btn-left more-btn" onClick={props.newPackage}>
          Ingresar medidas manualmente
    </Button>

      </div>
    </Column>
  </Row>
);

export default MorePackages;
