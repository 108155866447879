import React from "react";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import { push } from "react-router-redux";
import { toggle as toggleSideBar } from "../../modules/CollapsibleBar/actions";
import Text, { Title, P } from "../Text";
import Button from "../Button";
import Card from "../Card";
import {
  getUserWithLoader,
  getUser,
  getDataPrint,
  getProfileExtra,
} from "../../modules/user";
import Alert from "../Alert";
import {
  getPrimaryAddress,
  deletePrimaryAddress,
  activateDefaultAddress,
} from "../../modules/addresses/actions";

import WrapperSideBar from "../WrapperSideBar/index";
import Maxibutton from "../Maxibutton/index";
import "./style.css";
import {
  BottomContainer,
  ContentAdapter,
} from "../WrapperSideBar/styledObjects";
import { Line } from "../Maxibutton/styledObjects";
import Address from "../CreateLabel/common/address";
import { EmptyView } from "../CreateLabel/common/loadingViews";
import Less from "../CreateLabel/common/lessAddresses";
import More from "../CreateLabel/common/moreAddresses";
import Dropzone from "../Dropzone";
import { DropdownConfig, ConfigLink } from "../ShipmentsList/index";
import ThreeDotsDropdown from "../Dropdown/ThreeDotsDropdown/index";
import edit_icon from "../../utils/icons/edit.svg";
import { formatPhone, searchPermission } from "../../utils/global_functions";
import eraser_icon from "../../utils/icons/eraser.svg";
import email from "../../utils/icons/email.svg";
import power_icon from "../../utils/icons/smart-house-power.svg";
import detail_eye from "../../utils/icons/detail-eye.svg";
import imgDefault from "../../utils/icons/data-file-check.svg";

import StorefrontConfig from "./automation_components/storefront";
import MailConfig from "./automation_components/mails";
import Impresion from "./automation_components/impresion";
import AutomationPackage from "./automation_components/packages";
import AutomationAddresses from "./automation_components/addresses";
import { SimpleCheckbox } from "../Checkbox/simple_checkbox";

import Skeleton from "react-loading-skeleton";
import Message from "../Message";
import { hideMessage } from "../../modules/message";

const noZipcode = "No existe el código";
const ORIGIN = "ORIGIN";
const DESTINATION = "DESTINATION";
const PACKAGE = "PACKAGE";
const CARRIER = "CARRIER";
const INSURANCE = "INSURANCE";
const STOREFRONT = "STOREFRONT";
const EDIT_ORIGIN = "EDIT_ORIGIN";
const EDIT_DESTINATION = "EDIT_DESTINATION";
const IMPRESION = "IMPRESION";
const MAIL = "MAIL";
const LABEL = "LABEL";
const TRACKING = "TRACKING";
const DELIVERY = "DELIVERY";
const DELIVERY_DAY = "DELIVERY_DAY";

const titles = {
  ORIGIN: "Origen",
  DESTINATION: "Destino",
  PACKAGE: "Contenido del Paquete",
  CARRIER: "Paquetería",
  INSURANCE: "Seguro",
  STOREFRONT: "Storefront",
  IMPRESION: "Tamaño de Impresión",
  MAIL: "Personalización de Correo",
  LABEL: "Editar correo generacion de guía",
  TRACKING: "Editar correo seguimiento",
  DELIVERY: "Editar correo entrega",
  DELIVERY_DAY: "Editar correo dia de entrega",
};

class ShipmentSetup extends React.Component {
  state = {
    defaultOrigin: "loading",
    defaultDestination: "loading",
    defaultPackage: "loading",
    defaultStorefront: "loading",
    delete_alert: false,
    sidebar: {
      title: "",
    },
    address: {
      alias: "",
      aliasError: "",
      name: "",
      nameError: "",
      email: "",
      emailError: "",
      phone: "",
      phoneError: "",
      country: "México",
      countryError: "",
      street2: "",
      street2Error: "",
      zipcode: "",
      zipcodeError: "",
      city: "",
      cityError: "",
      street: "",
      streetError: "",
      reference: "",
      referenceError: "",
      saveAddress: false,
      is_open: false,
      title: "",
    },
    manualAddress: false,
    filterAddress: "",
    selAddress: null,
    brands: null,
    mailid: null,
    mailType: null,
    custom_tracking_email: "",
    name_tracking: "",
    email_tracking: "",
    logo_url_tracking: "",
  };

  componentWillMount() {
    this.getAutomationInfo();

    this.props.toggleSideBar(false);
  }

  getStoreFrontInfo = () => {
    axios
      .get("/api/storefronts")
      .then((r) => {
        if (r.data)
          this.setState({
            custom_tracking_email: r.data.storefronts[0]?.custom_tracking_email,
            name_tracking: r.data.storefronts[0]?.name,
            email_tracking: r.data.storefronts[0]?.email,
            logo_url_tracking: r.data.storefronts[0]?.logo_url,
          });
        else
          this.setState({
            isLoading: false,
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false, creating: true });
      });
  };

  getAutomationInfo = () => {
    this.setState({
      defaultOrigin: "loading",
      defaultDestination: "loading",
      defaultPackage: "loading",
      defaultStorefront: "loading",
      mailid: null,
      mailType: null,
    });
    axios
      .get("/api/brands")
      .then((r) => {
        if (r.data.brands && r.data.brands.length !== 0) {
          this.setState({ brands: r.data.brands });
        } else {
          this.setState({ brands: null });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.props.getProfileExtra().then((result) => {
      this.props.getDataPrint();
      this.setState({
        defaultOrigin: result?.primary_address,
        defaultDestination: result?.default_to_address,
        defaultPackage:
          result?.default_package?.length > 0
            ? result?.default_package[0]
            : null,
        defaultStorefront: result?.default_storefront
          ? result.default_storefront
          : "none",
      });
    });

    this.getStoreFrontInfo();
  };

  setStateAsync = (state) =>
    new Promise((resolve) => {
      this.setState(state, resolve);
    });

  toggleSidebar(title = "", id, type) {
    if (id) {
      this.setState({ mailid: id });
    }
    if (type) {
      this.setState({ mailType: type });
    }
    if (title == ORIGIN || title == DESTINATION) {
      this.clearAddress();
    }
    //  this.getAddresses(title);
    this.props.toggleSideBar(true, titles[title], title);
  }

  toggleEditOriginAddress = () => {
    this.setState(
      { address: this.state.defaultOrigin },
      this.toggleSidebar(EDIT_ORIGIN)
    );
  };

  toggleEditDestinationAddress = () => {
    this.setState(
      { address: this.state.defaultDestination },
      this.toggleSidebar(EDIT_DESTINATION)
    );
  };

  clearAddress = () => {
    const address = {
      alias: "",
      name: "",
      email: "",
      phone: "",
      country: "México",
      street2: "",
      zipcode: "",
      city: "",
      street: "",
      reference: "",
      saveAddress: false,
      is_open: false,
      title: "",
    };
    this.setState({ address });
  };

  deactivateOriginConfiguration = () => {
    const address = this.state.defaultOrigin;
    address.active = false;
    this.props.activateDefaultAddress(address, false);
  };

  deactivateDestinationConfiguration = () => {
    const address = this.state.defaultDestination;
    address.active = false;
    this.props.activateDefaultAddress(address, false);
  };

  activateOriginConfiguration = () => {
    const address = this.state.defaultOrigin;
    address.active = true;
    this.props.activateDefaultAddress(address, false);
  };

  activateDestinationConfiguration = () => {
    const address = this.state.defaultDestination;
    address.active = true;
    this.props.activateDefaultAddress(address, false);
  };

  _deletePrimaryAddress = () => {
    this.props.deletePrimaryAddress("PRIMARY", false).then((result) => {
      this.setState({ defaultOrigin: null });
    });
  };

  _deleteDefaultToAddress = () => {
    this.props.deletePrimaryAddress("DEFAULT_TO", false).then((result) => {
      this.setState({ defaultDestination: null });
    });
  };

  deleteBrand = (id) => {
    axios
      .delete(`/api/brands/${id}`)
      .then(({ statusText }) => {
        this.getAutomationInfo();
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          messageResponse: {
            show: true,
            text: "Error al eliminar, intenta más tarde. Si el problema persiste contacta a soporte.",
            color: "error",
          },
        });
      });
  };

  getLinksToRender = (type, edit, id, typeMail) => {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }

    switch (type) {
      case ORIGIN:
        if (searchPermission("automatizaciones", "editar", permissions)) {
          return [
            {
              function: this.toggleEditOriginAddress,
              label: "Editar dirección",
              image: edit_icon,
            },
            {
              function: this.state.defaultOrigin.active
                ? this.deactivateOriginConfiguration
                : this.activateOriginConfiguration,
              label: this.state.defaultOrigin.active
                ? "Desactivar configuración"
                : "Activar configuración",
              image: power_icon,
            },
            {
              function: this._deletePrimaryAddress,
              label: "Limpiar configuración",
              image: eraser_icon,
            },
          ];
        }
      case DESTINATION:
        if (searchPermission("automatizaciones", "editar", permissions)) {
          return [
            {
              function: this.toggleEditDestinationAddress,
              label: "Editar dirección",
              image: edit_icon,
            },
            {
              function: this.state.defaultDestination.active
                ? this.deactivateDestinationConfiguration
                : this.activateDestinationConfiguration,
              label: this.state.defaultDestination.active
                ? "Desactivar configuración"
                : "Activar configuración",
              image: power_icon,
            },
            {
              function: this._deleteDefaultToAddress,
              label: "Limpiar configuración",
              image: eraser_icon,
            },
          ];
        }
      case PACKAGE:
        if (searchPermission("automatizaciones", "editar", permissions)) {
          return [
            {
              function: () => this.toggleSidebar(PACKAGE),
              label: "Editar empaque",
              image: edit_icon,
            },
          ];
        }
      case STOREFRONT:
        if (searchPermission("automatizaciones", "editar", permissions)) {
          return [
            {
              function: () => this.toggleSidebar(STOREFRONT),
              label: "Editar Storefront",
              image: edit_icon,
            },
            {
              function: () => (window.location.href = "/tracking/storefront"),
              label: "Previsualizar configuración",
              image: detail_eye,
            },
          ];
        }
      case LABEL:
        if (searchPermission("automatizaciones", "editar", permissions)) {
          if (edit) {
            return [
              {
                function: () => this.toggleSidebar(LABEL, id, "LABEL_CREATED"),
                label: "Editar correo generacion de guía",
                image: edit_icon,
              },
              {
                function: () => this.deleteBrand(id),
                label: "Eliminar configuración correo generacion de guía",
                image: eraser_icon,
              },
            ];
          }

          return [
            {
              function: () => this.toggleSidebar(LABEL, null, "LABEL_CREATED"),
              label: "Configurar correo generacion de guía",
              image: power_icon,
            },
          ];
        }
      case TRACKING:
        if (searchPermission("automatizaciones", "editar", permissions)) {
          return [
            {
              function: () => this.toggleSidebar(TRACKING, id, "ON_TRANSIT"),
              label: "Editar correo seguimiento",
              image: edit_icon,
            },
            {
              function: () => this.deleteBrand(id),
              label: "Eliminar configuración correo seguimiento",
              image: eraser_icon,
            },
          ];
          return [
            {
              function: () => this.toggleSidebar(TRACKING, null, "ON_TRANSIT"),
              label: "Configurar correo seguimiento",
              image: power_icon,
            },
          ];
        }

      case DELIVERY:
        if (searchPermission("automatizaciones", "editar", permissions)) {
          return [
            {
              function: () => this.toggleSidebar(DELIVERY, id, "DELIVERED"),
              label: "Editar correo entrega",
              image: edit_icon,
            },
            {
              function: () => this.deleteBrand(id),
              label: "Eliminar configuración correo de entrega",
              image: eraser_icon,
            },
          ];

          return [
            {
              function: () => this.toggleSidebar(DELIVERY, null, "DELIVERED"),
              label: "Configurar correo entrega",
              image: power_icon,
            },
          ];
        }

      case DELIVERY_DAY:
        return [
          {
            function: () =>
              this.toggleSidebar(DELIVERY_DAY, id, "DELIVERY_DAY"),
            label: "Editar correo dia de entrega",
            image: edit_icon,
          },
          {
            function: () => this.deleteBrand(id),
            label: "Eliminar configuración correo dia de entrega",
            image: eraser_icon,
          },
        ];
        return [
          {
            function: () =>
              this.toggleSidebar(DELIVERY_DAY, null, "DELIVERY_DAY"),
            label: "Configurar correo dia de entrega",
            image: power_icon,
          },
        ];

      default:
        return [];
    }
  };

  _renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
      case ORIGIN:
      case DESTINATION:
      case EDIT_ORIGIN:
      case EDIT_DESTINATION:
        return (
          <AutomationAddresses
            address={this.state.address}
            update={this.getAutomationInfo}
            type={this.props.sidebar.type}
          />
        );
      case PACKAGE:
        return (
          <AutomationPackage
            update={this.getAutomationInfo}
            preset={
              this.state.defaultPackage
                ? this.state.defaultPackage.object_id
                : null
            }
          />
        );
      case CARRIER:
        return (
          <ContentAdapter>
            <h1>{titles[CARRIER]}</h1>
          </ContentAdapter>
        );
      case INSURANCE:
        return (
          <ContentAdapter>
            <h1>{titles[INSURANCE]}</h1>
          </ContentAdapter>
        );
      case STOREFRONT:
        return <StorefrontConfig update={this.getAutomationInfo} />;
      case MAIL:
        return (
          <MailConfig
            brands={this.state.brands}
            update={this.getAutomationInfo}
            mail={null}
            mailType={null}
          />
        );
      case IMPRESION:
        return (
          <Impresion
            update={this.getAutomationInfo}
            toggleSideBar={this.props.toggleSideBar}
            getAutomationInfo={this.getAutomationInfo}
          />
        );
      case LABEL:
        return (
          <MailConfig
            brands={this.state.brands}
            update={this.getAutomationInfo}
            mail={this.state.mailid}
            mailType={this.state.mailType}
          />
        );
      case TRACKING:
        return (
          <MailConfig
            brands={this.state.brands}
            update={this.getAutomationInfo}
            mail={this.state.mailid}
            mailType={this.state.mailType}
          />
        );
      case DELIVERY:
        return (
          <MailConfig
            brands={this.state.brands}
            update={this.getAutomationInfo}
            mail={this.state.mailid}
            mailType={this.state.mailType}
          />
        );
      case DELIVERY_DAY:
        return (
          <MailConfig
            brands={this.state.brands}
            update={this.getAutomationInfo}
            mail={this.state.mailid}
            mailType={this.state.mailType}
          />
        );
      default:
        return null;
    }
  };

  addressArray = (address) => [
    address.name,
    address.email,
    formatPhone(address.phone),
    address.street,
    address.street2,
    address.reference,
    `${address.city}, ${address.state ? address.state : ""} CP. ${
      address.zipcode
    }`,
  ];

  renderBrands(data) {
    let { label, tracking, delivery, deliveredDay } = false;
    let { labelData, trackingData, deliveryData, deliveredDayData } = data[0];
    let { labelId, trackingId, deliveryId, deliveredDayId } = data[0];
    let { labelType, trackingType, deliveryType, deliveredDayType } = data[0];

    // console.log("0", labelData);

    data.map((element) => {
      switch (element.mail) {
        case "ON_TRANSIT":
          tracking = true;
          trackingId = element.id;
          trackingType = element.mail;
          trackingData = element;
          break;
        case "LABEL_CREATED":
          label = true;
          labelId = element.id;
          labelType = element.mail;
          labelData = element;
          break;
        case "DELIVERED":
          delivery = true;
          deliveryId = element.id;
          deliveryType = element.mail;
          deliveryData = element;
          break;
        case "DELIVERY_DAY":
          deliveredDay = true;
          deliveredDayId = element.id;
          deliveredDayType = element.mail;
          deliveredDayData = element;
          break;
        default:
          break;
      }
    });

    // console.log("map", labelData.template_url);

    return (
      <div className="container-mails-config">
        <div>
          <Text type="label" className="left">
            Generación de Guía
          </Text>
          <ThreeDotsDropdown
            linksToRender={this.getLinksToRender(
              LABEL,
              label,
              labelId,
              labelType
            )}
          />
        </div>
        <br />
        {label && (
          <div>
            <Row className="detail-row">
              <Column fluid>
                <div
                  className="color-bar-mail"
                  style={{ backgroundColor: `${labelData.color_state}` }}
                />
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">Titulo: {labelData.title_state}</Text>
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">Remitente: {labelData.sender}</Text>
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">Asunto: {labelData.subject}</Text>
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">
                  Texto Principal:{" "}
                  {labelData.template_url ? (
                    <b>Configuraste un template personalizado HTML</b>
                  ) : (
                    labelData.major_text
                  )}
                </Text>
              </Column>
            </Row>
          </div>
        )}
        <br />
        <div>
          <Text type="label" className="left">
            Seguimiento
          </Text>
          <ThreeDotsDropdown
            linksToRender={this.getLinksToRender(
              TRACKING,
              tracking,
              trackingId,
              trackingType
            )}
          />
        </div>
        <br />
        {tracking && (
          <div>
            <Row className="detail-row">
              <Column fluid>
                <div
                  className="color-bar-mail"
                  style={{ backgroundColor: `${trackingData.color_state}` }}
                />
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">
                  Titulo: {trackingData.title_state}
                </Text>
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">Remitente: {trackingData.sender}</Text>
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">Asunto: {trackingData.subject}</Text>
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">
                  Texto Principal:{" "}
                  {trackingData.template_url ? (
                    <b>Configuraste un template personalizado HTML</b>
                  ) : (
                    trackingData.major_text
                  )}
                </Text>
              </Column>
            </Row>
          </div>
        )}
        <br />
        <div>
          <Text type="label" className="left">
            Entrega
          </Text>
          <ThreeDotsDropdown
            linksToRender={this.getLinksToRender(
              DELIVERY,
              delivery,
              deliveryId,
              deliveryType
            )}
          />
        </div>
        <br />
        {delivery && (
          <div>
            <Row className="detail-row">
              <Column fluid>
                <div
                  className="color-bar-mail"
                  style={{ backgroundColor: `${deliveryData.color_state}` }}
                />
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">
                  Titulo: {deliveryData.title_state}
                </Text>
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">Remitente: {deliveryData.sender}</Text>
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">Asunto: {deliveryData.subject}</Text>
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">
                  Texto Principal:{" "}
                  {deliveryData.template_url ? (
                    <b>Configuraste un template personalizado HTML</b>
                  ) : (
                    deliveryData.major_text
                  )}
                </Text>
              </Column>
            </Row>
          </div>
        )}
        <br />
        <div>
          <Text type="label" className="left">
            Dia de entrega
          </Text>
          <ThreeDotsDropdown
            linksToRender={this.getLinksToRender(
              DELIVERY_DAY,
              deliveredDay,
              deliveredDayId,
              deliveredDayType
            )}
          />
        </div>
        <br />
        {deliveredDay && (
          <div>
            <Row className="detail-row">
              <Column fluid>
                <div
                  className="color-bar-mail"
                  style={{ backgroundColor: `${deliveredDayData.color_state}` }}
                />
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">
                  Titulo: {deliveredDayData.title_state}
                </Text>
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">
                  Remitente: {deliveredDayData.sender}
                </Text>
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">
                  Asunto: {deliveredDayData.subject}
                </Text>
              </Column>
            </Row>
            <Row className="detail-row">
              <Column fluid>
                <Text type="bodyDetail">
                  Texto Principal:{" "}
                  {deliveredDayData.template_url ? (
                    <b>Configuraste un template personalizado HTML</b>
                  ) : (
                    deliveredDayData.major_text
                  )}
                </Text>
              </Column>
            </Row>
          </div>
        )}
        <br />
      </div>
    );
  }

  render() {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    return (
      <div>
        <WrapperSideBar handleClose={this.getAutomationInfo}>
          {this._renderSidebarContent()}
        </WrapperSideBar>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column md={8} xs={18} fluid>
            <Title type="titleCard">Ajustes de Automatización</Title>
            <br />
            <Text type="helpTextModal">
              Configuración para el pre-llenado de nuevos envíos o envíos que
              importamos de tus integraciones.
            </Text>
          </Column>
          <Column xs={11} fluid />
        </Row>
        <br />
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={9} fluid>
            {this.props.message.show ? (
              <Message
                color={this.props.message.messageType}
                onClose={this.props.hideMessage}
              >
                {this.props.message.text}
              </Message>
            ) : null}
          </Column>
          <Column xs={10} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column md={12} xs={18} fluid>
            <Row>
              {searchPermission(
                "configuracion_origen",
                "leer",
                permissions
              ) && (
                <Column fluid>
                  {typeof this.state.defaultOrigin === "string" ||
                  typeof this.state.defaultOrigin === "undefined" ||
                  !this.state.defaultOrigin ? (
                    <div>
                      <Text type="label">Origen</Text>
                      <br />
                      {this.state.defaultOrigin == "loading" ? (
                        <div
                          style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                        >
                          <Skeleton height={52} />
                        </div>
                      ) : (
                        <Maxibutton
                          onClick={() => this.toggleSidebar(ORIGIN)}
                          icon="origin"
                          text="Da de alta o selecciona una dirección default de origen para tus nuevos envíos u órdenes importadas del marketplace."
                        />
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="title-container">
                        <Text type="label" className="left">
                          Origen
                        </Text>
                        <ThreeDotsDropdown
                          linksToRender={this.getLinksToRender(ORIGIN)}
                        />
                        {!this.state.defaultOrigin.active && (
                          <Text type="sidebarInfoTitle" className="right">
                            Configuración desactivada
                          </Text>
                        )}
                      </div>
                      <Text
                        type="labelAuxiliar"
                        className={
                          !this.state.defaultOrigin.active
                            ? "inactive-address alias"
                            : "alias"
                        }
                      >
                        {this.state.defaultOrigin.alias}
                      </Text>
                      {this.addressArray(this.state.defaultOrigin)
                        .filter((item) => item)
                        .map((item) => (
                          <Row key={item} className="detail-row">
                            <Column fluid>
                              <Text
                                type="bodyDetail"
                                className={
                                  !this.state.defaultOrigin.active &&
                                  "inactive-address"
                                }
                              >
                                {item}
                              </Text>
                            </Column>
                          </Row>
                        ))}
                      <Line />
                    </div>
                  )}
                </Column>
              )}
              {searchPermission(
                "configuracion_destino",
                "leer",
                permissions
              ) && (
                <Column fluid>
                  {typeof this.state.defaultDestination === "string" ||
                  typeof this.state.defaultDestination === "undefined" ||
                  !this.state.defaultDestination ? (
                    <div>
                      <Text type="label">Destino</Text>
                      <br />
                      {this.state.defaultDestination == "loading" ? (
                        <div
                          style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                        >
                          <Skeleton height={52} />
                        </div>
                      ) : (
                        <Maxibutton
                          onClick={() => this.toggleSidebar(DESTINATION)}
                          icon="destination"
                          text="Da de alta o selecciona una dirección default destino para pre-llenar nuevos envíos u órdenes importadas del marketplace."
                        />
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="title-container">
                        <Text type="label" className="left">
                          Destino
                        </Text>
                        <ThreeDotsDropdown
                          linksToRender={this.getLinksToRender(DESTINATION)}
                        />
                        {!this.state.defaultDestination.active && (
                          <Text type="sidebarInfoTitle" className="right">
                            Configuración desactivada
                          </Text>
                        )}
                      </div>
                      <Text
                        type="labelAuxiliar"
                        className={
                          !this.state.defaultDestination.active
                            ? "inactive-address alias"
                            : "alias"
                        }
                      >
                        {this.state.defaultDestination.alias}
                      </Text>
                      {this.addressArray(this.state.defaultDestination)
                        .filter((item) => item)
                        .map((item) => (
                          <Row key={item} className="detail-row">
                            <Column fluid>
                              <Text
                                type="bodyDetail"
                                className={
                                  !this.state.defaultDestination.active &&
                                  "inactive-address"
                                }
                              >
                                {item}
                              </Text>
                            </Column>
                          </Row>
                        ))}
                      <Line />
                    </div>
                  )}
                </Column>
              )}

              {searchPermission(
                "configuracion_empaque",
                "leer",
                permissions
              ) && (
                <Column fluid>
                  {typeof this.state.defaultPackage === "string" ||
                  this.state.defaultPackage === null ? (
                    <div>
                      <Text type="label">Contenido del paquete</Text>
                      <br />
                      {this.state.defaultPackage == "loading" ? (
                        <div
                          style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                        >
                          <Skeleton height={52} />
                        </div>
                      ) : (
                        <Maxibutton
                          onClick={() => this.toggleSidebar(PACKAGE)}
                          icon="package"
                          text="Configura la información frecuente de los paquetes que envías."
                        />
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="title-container">
                        <Text block type="label" className="left">
                          Paquete
                        </Text>
                        <ThreeDotsDropdown
                          linksToRender={this.getLinksToRender(PACKAGE)}
                        />
                      </div>
                      <Text block type="labelAuxiliar" className="alias">
                        {this.state.defaultPackage.alias}
                      </Text>
                      <Row>
                        <Column fluid>
                          <Text block type="sidebarInfoTitle">
                            Dimensiones
                          </Text>
                          <P block>
                            {`${this.state.defaultPackage.length} x ${this.state.defaultPackage.width} x ${this.state.defaultPackage.height} cm`}
                          </P>
                        </Column>
                        {this.state.defaultPackage.weight && (
                          <Column fluid>
                            <Text block type="sidebarInfoTitle">
                              Peso
                            </Text>
                            <P block>{this.state.defaultPackage.weight} kg</P>
                          </Column>
                        )}
                        {this.state.defaultPackage.description && (
                          <Column fluid>
                            <Text block type="sidebarInfoTitle">
                              Contenido del Paquete
                            </Text>
                            <P block>{this.state.defaultPackage.description}</P>
                          </Column>
                        )}
                        {this.state.defaultPackage.insured_amount && (
                          <Column fluid>
                            <Text block type="sidebarInfoTitle">
                              Valor del Paquete
                            </Text>
                            <P block>
                              {this.state.defaultPackage.insured_amount}
                            </P>
                          </Column>
                        )}
                      </Row>
                      <Line />
                    </div>
                  )}
                </Column>
              )}
              {searchPermission(
                "configuracion_tracking",
                "leer",
                permissions
              ) && (
                <Column fluid>
                  {typeof this.state.defaultStorefront === "string" ||
                  typeof this.state.defaultStorefront === "undefined" ||
                  this.state.defaultStorefront === null ? (
                    <div>
                      <Text type="label">Personalización de tracking</Text>
                      <br />
                      {this.state.defaultStorefront == "loading" ? (
                        <div
                          style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                        >
                          <Skeleton height={52} />
                        </div>
                      ) : (
                        <Maxibutton
                          onClick={() => this.toggleSidebar(STOREFRONT)}
                          icon="storefront"
                          text="¿Quieres tener un link the tracking personalizado? Configura eso y más aquí."
                          last
                        />
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="title-container">
                        <Text block type="label" className="left">
                          Personalización de tracking
                        </Text>
                        <ThreeDotsDropdown
                          linksToRender={this.getLinksToRender(STOREFRONT)}
                        />
                      </div>
                      <Column fluid>
                        {this.state.defaultStorefront && (
                          <div>
                            <Column fluid>
                              <Text block type="sidebarInfoTitle">
                                Nombre de la tienda
                              </Text>
                              <P block>{this.state.defaultStorefront.name}</P>
                            </Column>
                            <Column fluid>
                              <Text block type="sidebarInfoTitle">
                                Mail de atención al cliente de tu tienda
                              </Text>
                              <P block>{this.state.defaultStorefront.email}</P>
                            </Column>
                            <Column fluid>
                              <Text block type="sidebarInfoTitle">
                                Logo de tu tienda
                              </Text>
                              <img
                                src={
                                  this.state.defaultStorefront.logo_url
                                    ? this.state.defaultStorefront.logo_url
                                    : imgDefault
                                }
                                alt=""
                                className="selected-icon"
                                width="100"
                              />
                            </Column>
                          </div>
                        )}
                        <br />
                      </Column>
                    </div>
                  )}
                  <Line />
                </Column>
              )}
              {searchPermission("configuracion_email", "leer", permissions) && (
                <Column fluid>
                  {this.state.defaultDestination == "loading" ? (
                    <div style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
                      <Skeleton height={52} />
                    </div>
                  ) : this.state.brands ? (
                    <div>
                      <div className="title-container">
                        {this.renderBrands(this.state.brands)}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Text type="label">Personalización de correo</Text>
                      <br />
                      <Maxibutton
                        onClick={() => this.toggleSidebar(MAIL)}
                        icon="email"
                        text="¿Quieres tener un correo de tracking personalizado? Configura eso y mas aquí."
                      />
                    </div>
                  )}
                </Column>
              )}
              {searchPermission(
                "configuracion_impresion",
                "leer",
                permissions
              ) && (
                <Column fluid>
                  {
                    <div>
                      <Text type="label">Tamaño de Impresión</Text>
                      <br />
                      {this.state.defaultDestination == "loading" ? (
                        <div
                          style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                        >
                          <Skeleton height={52} />
                        </div>
                      ) : (
                        <Maxibutton
                          onClick={() => this.toggleSidebar(IMPRESION)}
                          icon="printer_icon"
                          text="Selecciona el tamaño con el que deseas que se generen tus guías."
                        />
                      )}
                    </div>
                  }
                </Column>
              )}
            </Row>
          </Column>
          <Column md={4} xs={1} />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  message: state.message,
  sidebar: state.sidebar,
  addresses: state.newShipment.addresses,
  loading: state.newShipment.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToEditProfile: () => push("/perfil/editar/contacto"),
      goToEditPassword: () => push("/perfil/editar/contrasena"),
      goToEditFiscal: () => push("/perfil/editar/fiscal"),
      getUserWithLoader,
      activateDefaultAddress,
      getPrimaryAddress,
      deletePrimaryAddress,
      toggleSideBar,
      getUser,
      getProfileExtra,
      getDataPrint,
      hideMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentSetup);
