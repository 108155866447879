export const ORDER_DETAILS = "ORDER_DETAILS";
export const QUICK_QUOTE_TYPE = "QUICK_QUOTE";
export const NEW_SHIPMENT = "NEW_SHIPMENT";
export const NEW_INTEGRATION = "NEW_INTEGRATION";
export const ADDRESS_DETAILS = "ADDRESS_DETAILS";
export const NEW_ADDRESS = "NEW_ADDRESS";
export const EDIT_ADDRESS = "EDIT_ADDRESS";
export const NEW_PAYMENT_METHOD = "NEW_PAYMENT_METHOD";
export const NEW_PACKAGE = "NEW_PACKAGE";
export const PACKAGE_DETAILS = "PACKAGE_DETAILS";
export const CHECKOUT_DETAILS = "CHECKOUT_DETAILS";
export const INVOICE_DETAILS = "INVOICE_DETAILS";
export const AUTOMATION = "AUTOMATION";
export const SHIPMENTS_BY_CSV = "SHIPMENTS_BY_CSV";
export const EDIT_SHIPMENT = "EDIT_SHIPMENT";
export const EDIT_PACKAGE = "EDIT_PACKAGE";
export const ACCOUNT_CONFIGURATION = "ACCOUNT_CONFIGURATION";
export const PASSWORD_CONFIGURATION = "PASSWORD_CONFIGURATION";
export const INVOICE_CONFIGURATION = "INVOICE_CONFIGURATION";
export const SHIPMENTS_RESPONSABLE = "SHIPMENTS_RESPONSABLE";
export const SHIPMENTS_FILTERS_CSV = "SHIPMENTS_FILTERS_CSV";
export const SHOW_SELECTED_QUOTES = "SHOW_SELECTED_QUOTES"
