import React from "react";
import Text from "../../Text";
import Dropdown from "../../Dropdown";
import { Column, Row } from "hedron";
import Button from "../../Button";
import { formatPhone } from "../../../utils/global_functions";
import { connect } from "react-redux";

const checkAddresses = () => {
  return props.addresses.filter(function (element) {
    return element.origin_destiny == false;
  }).length;
};

const MoreAddresses = (props) => (
  <div className="more-component">
    <Text type="microHeader" style={{ marginBottom: "8px", marginTop: "8px" }}>
      Direcciones guardadas
    </Text>
    <Dropdown
      options={props.addresses}
      placeholder={
        <Text className="dropdown-placeholder">Buscar por alias</Text>
      }
      selected={props.selectedAddressFrom}
      selectedTitle={props.address ? props.address.alias : null}
      filter
      filterValue={props.filterFrom}
      filterPlaceholder="Buscar por alias"
      handleChange={props.handleAddressChange}
      handleFilterChange={props.handleFilterChange}
    />
    {props.address && (
      <Row>
        <Column xs={12} md={12} className="text-left-more">
          <Text type="P">{props.address.name}</Text>
          <Text type="P">{props.address.email}</Text>
          <Text type="P">
            {props.address.phone.charAt(0) == "(" &&
            props.address.phone.charAt(4) == ")"
              ? props.address.phone
              : formatPhone(props.address.phone)}
          </Text>
          <Text type="P">{props.address.street}</Text>
          <Text type="P">{props.address.reference}</Text>
          <Text type="P">Col. {props.address.street2}</Text>
          <Text type="P">{`${props.address.city}, ${props.address.state}, C.P. ${props.address.zipcode}`}</Text>
        </Column>
      </Row>
    )}

    {props.typeAddress == "Origen" && (
      <Button
        type="link"
        className="edit-btn-left more-btn"
        onClick={props.newAddressFn}
      >
        Ingresar una dirección manualmente
      </Button>
    )}

    {props.typeAddress == "Destino" && (
      <Button
        type="link"
        className="edit-btn-left more-btn"
        onClick={props.newAddressFn}
      >
        Ingresar una dirección manualmente
      </Button>
    )}
  </div>
);

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(MoreAddresses);
