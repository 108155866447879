import React from "react";
import { Column, Row } from "hedron";
import Input from "../Input";
import Text from "../Text";
import arrow from "./img/arrow-cotizador.svg";
import { Img } from "../Checkout/styled-components";
import questionIcon from "./img/question-icon.svg";
import Dropdown from "../Dropdown";

const QuoteForm = (props) => (
  <div>
    <Input {...props.model.country} />
    <Dropdown
      required
      label="País Destino"
      disable={props.model.countryTo.disable}
      options={props.model.countryTo.data}
      style={{ marginTop: "12px" }}
      placeholder={
        <Text className="dropdown-placeholder">Selecciona un país</Text>
      }
      selected={props.model.countryTo.selected}
      handleChange={props.model.countryTo.handleChange}
    />
    <Row>
      <Column xs={12} md={5} fluid>
        <Input {...props.model.Origin} />
      </Column>
      <Column xs={12} md={2} className="quick-arrow">
        <Img src={arrow} />
      </Column>
      <Column xs={12} md={5} fluid>
        <Input {...props.model.Destiny} />
      </Column>
    </Row>
    <Text type="microHeader" className="min-padding">
      Información del paquete
    </Text>
    <Row>
      <Column xs={10} md={7} fluid>
        <Input
          type="number"
          textClass="span-text"
          measureClass="measureClass"
          onChange={props.model.dimensions.onChange}
          value={props.model.dimensions.value}
          error={props.model.dimensions.error}
          disabled={props.model.dimensions.disabled}
          onFocus={props.model.dimensions.onFocus}
          onBlur={props.model.dimensions.onBlur}
          required
          placeholder="30"
          label="Dimensiones"
          dimensions
        />
      </Column>
    </Row>
    <Row>
      <Column xs={3} fluid>
        <Input {...props.model.weight} />
      </Column>
      <Column xs={1} fluid style={{ height: "95px", position: "relative" }}>
        <Text className="volumetric-weight-helper span-text">kg.</Text>
      </Column>
    </Row>
    {props.showMsg && (
      <div className="helper-vol-text">
        {`Basaremos tu cotización en el peso volumétrico (${props.model.volumetricWeight} kg)`}
        <a
          className="question-icon"
          href="https://blogger.mienvio.mx/2021/04/que-es-el-peso-real-y-el-peso-volumetrico/"
          target="_blank"
        >
          <Img src={questionIcon} />
        </a>
      </div>
    )}
  </div>
);

export default QuoteForm;
