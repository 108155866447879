import React, { Children } from "react";
import { Checkbox, CheckboxGroup } from "../../Checkbox";
import Dropdown from "../../Dropdown";
import Input, { Label, Required } from "../../Input";
import Text from "../../Text";
import { Column, Row } from "hedron";
import Button from "../../Button";

const Address = (props) => {
  return (
    <div className="nl-form">
      {props.model.saveAddress ? (
        <div>
          <label className="top-save-label">
            <input
              className="top-save-input"
              type="checkbox"
              checked={props.model.saveAddress.isChecked}
              onChange={props.model.saveAddress.onChange}
              value={props.model.saveAddress.name}
            />
            {props.model.saveAddress.text_info}
          </label>
          {props.model.saveAddress.isChecked && (
            <Input type="text" {...props.model.alias} />
          )}
        </div>
      ) : (
        <Input type="text" {...props.model.alias} />
      )}
      {props.model.country.type === "from" ? (
        <Text
          type="microHeader"
          className={props.hasAddress ? "min-padding" : ""}
        >
          Datos de contacto de quien envía
        </Text>
      ) : (
        <Text
          type="microHeader"
          className={props.hasAddress ? "min-padding" : ""}
        >
          Datos de contacto de quien recibe
        </Text>
      )}

      <Input {...props.model.name} />
      <Row>
        <Column xs={12} md={6} fluid style={{ paddingRight: "8px" }}>
          <Input {...props.model.email} />
        </Column>
        <Column xs={12} md={6} fluid style={{ paddingLeft: "8px" }}>
          <Input {...props.model.phone} />
        </Column>
      </Row>
      <Text
        type="microHeader"
        className={props.hasAddress ? "min-padding" : ""}
      >
        Detalles de dirección
      </Text>

      {props.model.country.type === "from" ? (
        <Input {...props.model.country} />
      ) : (
        <div>
          <Dropdown
            required
            label="País"
            disable={props.model.country.disable}
            options={props.model.country.data}
            style={{ marginTop: "12px" }}
            placeholder={
              <Text className="dropdown-placeholder">Selecciona un país</Text>
            }
            selected={props.model.country.selected}
            handleChange={props.model.country.handleChange}
          />
          {props.model.country.selected != 0 && (
            <Text
              type="microHeader"
              className={props.hasAddress ? "min-padding" : ""}
            >
              Las direcciones internacionales se guardan Automaticamente
            </Text>
          )}
          <Button
            type="link"
            className="edit-btn-left more-btn"
            onClick={props.international}
          >
            Envios Internacionales a otros países
          </Button>
        </div>
      )}

      <Row>
        <Column xs={12} md={4} fluid style={{ paddingRight: "4px" }}>
          <Input {...props.model.neighborhood} />
        </Column>
        <Column
          xs={12}
          md={4}
          fluid
          style={{ paddingLeft: "4px", paddingRight: "4px" }}
        >
          <Input {...props.model.zipcode} />
        </Column>
        <Column xs={12} md={4} fluid style={{ paddingLeft: "4px" }}>
          <Input {...props.model.city} />
        </Column>
      </Row>
      <Input {...props.model.street} />
      <Input {...props.model.reference} />
      {props.hasAddress && (
        <Button
          type="link"
          className="edit-btn-left more-btn"
          onClick={props.cancelManualfn}
        >
          Direcciones guardadas
        </Button>
      )}
    </div>
  );
};

export default Address;
