import React from "react";
import { Column, Row } from "hedron";
import Input from "../../Input";
import Text from "../../Text";
import { Img } from "../../Checkout/styled-components";
import questionIcon from "../../QuickQuote/img/question-icon.svg";
import commercialInvocieImg from "../images/common-file-text-add.svg";
import openInvoiceImg from "../images/open_window_link.svg";
import Button from "../../Button";

const Package = (props) => (
  <div>
    <Row>
      <Column xs={12} md={12} fluid>
        {props.children}
      </Column>
    </Row>
    <Row>
      <Column xs={3} style={{ padding: 0 }}>
        <Row>
          <Column xs={8} fluid>
            <Input {...props.model.weight} />
          </Column>
          <Column xs={4} fluid style={{ height: "95px", position: "relative" }}>
            <Text className="volumetric-weight-helper span-text">kg.</Text>
          </Column>
        </Row>
      </Column>
    </Row>
    {props.isInternational ? (
      <div className="is-international-wrapper">
        <Text type="microHeader" className="min-padding">
          Aranceles
        </Text>
        <Text type="P" className="info-text">
          Si necesitas ayuda consulta nuestros articulos informativos
        </Text>
        <div className="helper-vol-text">
          <Img src={commercialInvocieImg} className="img-left" />
          {props.commercialInvoice.incoterm_example != ""
            ? `Factura comercial llenada`
            : "Necesitas llenar tu factura comercial"}
          {props.commercialInvoice ? (
            <Button
              type="link"
              className="edit-text"
              onClick={props.fillCommercialInvoice}
            >
              Editar
            </Button>
          ) : (
            <a
              className="img-right img-function"
              onClick={props.fillCommercialInvoice}
            >
              <Img src={openInvoiceImg} />
            </a>
          )}
        </div>
      </div>
    ) : (
      <div />
    )}
    {props.showMsg && (
      <div className="helper-vol-text">
        {`Basaremos tu cotización en el peso volumétrico (${props.model.volumetricWeight} kg)`}
        <a
          className="question-icon"
          href="https://blogger.mienvio.mx/2021/04/que-es-el-peso-real-y-el-peso-volumetrico/"
          target="_blank"
        >
          <Img src={questionIcon} />
        </a>
      </div>
    )}
    <Text type="microHeader" className="min-padding">
      ¿Qué estás enviando?
    </Text>
    {props.isInternational ? (
      <Text type="P" className="info-text">
        Asegurate de que el valor del paquete y el total de la factura comercial
        coincidan.
      </Text>
    ) : (
      <div />
    )}
    <Row>
      <Column xs={12} md={7} fluid style={{ paddingRight: "12px" }}>
        <Input {...props.model.description} />
      </Column>
      <Column xs={12} md={5} fluid>
        <Input {...props.model.insuredAmount} />
      </Column>
    </Row>
  </div>
);

export default Package;
