// @flow

import axios from 'axios';
import {
  showMessage
} from '../message';
import {
  showError
} from '../error';
import type {
  Dispatch as ReduxDispatch
} from 'redux';

export const createStorefront = (payload) => {
  return (dispatch: Dispatch) => {
    return axios
      .post('/api/storefronts', payload)
      .then(response => {
        dispatch(showMessage('success', 'Información actualizada exitosamente'));
      })
      .catch(err => {
        const dict = {
          "Some parameters are invalid, check your request": 'Algunos parámetros son inválidos, verifique su respuestas',
        }
        let message = dict[err.response.data.error.message]
        if (message !== '') {
          dispatch(showError(message))
        } else {
          dispatch(showError('Error al crear la personalización de tracking'))
        }
      })
  }
}

export const updateStorefront = (id, payload) => {
  return (dispatch: Dispatch) => {
    return axios
      .put(`/api/storefronts/${id}`, payload)
      .then(response => {
        dispatch(showMessage('success', 'Información actualizada exitosamente'));
      })
      .catch(err => {
        const dict = {
          "Some parameters are invalid, check your request": 'Algunos parámetros son inválidos, verifique su respuestas',
        }
        let message = dict[err.response.data.error.message]
        if (message !== '') {
          dispatch(showError(message))
        } else {
          dispatch(showError('Error al crear la personalización de tracking'))
        }
      })
  }
}
