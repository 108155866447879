import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import { Row, Column } from 'hedron';
import Skeleton from 'react-loading-skeleton';
import Text, { Title } from '../Text';
import EmpyState from '../EmptyState';
import NewTable from '../NewTable';
import { shorten, numberWithCommas } from '../../utils/global_functions';

import './style.css';
import { MarketplaceBadge } from '../Badge';
import { getAllUserQuotes, getAllUserShipments } from '../../modules/shipments/actions';
import { toggle as toggleSideBar } from '../../modules/CollapsibleBar/actions';
import { showMessage } from '../../modules/message';
import { showLoader, hideLoader } from '../../modules/loader';
import { showError } from '../../modules/error';
import { CSVLink } from 'react-csv';
import { boundMethod } from 'autobind-decorator';
import NewBadge from '../Badge/insuranceBadge';

class QuotationList extends React.Component {
  state = {
    selectedConcept: [],
    rates: [],
    parcels: ['Todos', 'Chazki', 'Blueexpress', 'Shippify'],
    page: 1,
    showWaypoint: false,
    showEmpty: false,
    showlist: false,
    btLoading: false,
  };

  _openSideBar = (stateSidebar = false, sidebarTitle = '', sidebarType = '') => {
    this.props.toggleSideBar(stateSidebar, sidebarTitle, sidebarType);
  };

  renderParcel = () => this.state.parcels.map((type, index) => (
    <Row key={type} className="card-dropdown-option">
      <Column xs={6} fluid>
        <Text>{type}</Text>
      </Column>
    </Row>
  ));

  handleConceptDrop = (selected) => {
    this.setState({ selectedConcept: selected });
    if (selected === 0) {
      this.setState({ showWaypoint: true });
      // this.loadRates();
    } else {
      this.loadRatesWFilter(this.state.parcels[selected]);
    }
  };

  badgeLabel = (status) => {
    let label = ''
    let type = ''
    if(status) {
      switch (status) {
        case 'QUOTE':
          label = 'Cotizado'
          type = 'correct'
          break;
        case 'PURCHASE':
          label = 'Cotizada'
          type = 'correct'
          break;
        case 'INCIDENCIA':
          label = 'Incidencia'
          type = 'warning'
          break;
        case 'ENTREGADO':
          label = 'Entregado'
          type = 'correct'
          break;
        case 'LABEL_CREATED':
          label = 'Guía creada'
          type = 'correct'
          break;
        case 'MANUAL_LABEL':
          label = 'Manual'
          type = 'warning'
          break;
        case 'MONTHLY_PAYMENT_PENDING':
          label = 'Pago mensual pendiente'
          type = 'error'
          break;
        case 'GUIA_ENVIADA':
          label = 'Guía enviada'
          type = 'correct'
          break;
        case 'TRANSITO':
          label = 'En camino'
          type = 'correct'
          break;
        case 'CANCELLED':
        case 'CANCELADO':
          label = 'Cancelado'
          type = 'error'
          break;
      }
    }
    return {
      type: type,
      label: label
    }
  }

  processQuote(id) {
    this.setState({
      btLoading: true,
    });
    this.props.showLoader(null, null, false);
    axios
      .post(`/api/quotes/${id}/purchases`, {})
      .then((r) => {
        switch (r.status) {
        case 201:
          this.props.showMessage('success', 'Cotizacón procesada exitosamente');
          this.props.hideLoader();
          this.props.getAllUserShipments();
          this.setState({
            btLoading: false,
          });
          break;
        default:
          break;
        }
      })
      .catch((err) => {
        console.log(err);
        this.props.hideLoader();
        this.props.showError(err.response.data.error);
      });
  }

  UNSAFE_componentWillMount() {
    // this.loadRates();
    //this.props.getAllUserQuotes();
  }

  loadRates() {
    this.setState({
      showEmpty: false,
    });
    axios.get('/api/accounts').then((r) => {
      switch (r.status) {
      case 200:
        if (r.data.accounts.length > 0) {
          this.setState({
            showEmpty: false,
            showlist: true,
            showLoader: false,
          });
          this.setState({ rates: r.data.accounts, page: this.state.page + 1 });
        } else {
          this.setState({
            showEmpty: true,
            showlist: false,
            showLoader: false,
            showWaypoint: false,
          });
        }

        break;
      default:
        this.setState({
          showEmpty: true,
          showlist: false,
          showLoader: false,
          showWaypoint: false,
        });
        break;
      }
    });
  }

  @boundMethod
  renderCSVData(row, id) {
    const csvData = [];

    csvData.push(['Cotización:', id]);
    csvData.push(['N° de Orden', 'Carrier', 'N° de Guía', 'URL de Guía', 'Costo', 'Destinatario', 'País']);
    row.forEach((element) => {
      this.props.countries.forEach((country) => {
        if (country.currency === element.currency) {
          csvData.push([element.id, element.provider_name, element.tracking_number, element.label_url, element.purchase_amount, element.to_name, country.code]);
        }
      });
    });
    return csvData;
  }

  render() {
    const columnsExpanded = [
      {
        name: 'N° Orden',
        selector: 'id',
        sortable: true,
        cell: row => (
          <div>
            <p>
    #
              {row.id}
            </p>
            <MarketplaceBadge color={row.marketplace !== 'manual' ? row.marketplace : 'default'} />
          </div>
        ),
      },
      {
        name: 'Fecha',
        selector: 'date',
        cell: (row) => {
          let shipment_date;
          if(row.date){
             shipment_date = moment(row.date.split(' ')[0]);
          }else{
             shipment_date = moment(row.created_at.split(' ')[0]);
          }
          const today = new Date();
          const timeDiff = Math.abs(today - shipment_date);
          const daysDiff = Math.floor(timeDiff / (3600 * 24 * 1000));
          return (
            <div>
              <p>{shipment_date.format('DD-MM-YYYY')}</p>
              <p className="minitext">
                {daysDiff < 1 ? 'hoy' : `hace ${daysDiff} ${daysDiff > 1 ? 'días' : 'día'}`}
              </p>
            </div>
          );
        },
      },
      {
        name: 'Destinatario',
        selector: 'name',
        cell: row => row.to_name ? shorten(row.to_name, 16) : shorten(row.address_to.name, 16),
      },
      {
        name: 'Destino',
        selector: 'address_to',
        cell: (row) => {
          if(row.to_street){
            const street = row.to_street.split('|');
            return (
              <div>
                <p>{shorten(street[0], 16)}</p>
                <p className="minitext">{street[1]}</p>
              </div>
            );
          }else{
            return (
              <div>
                <p>{shorten(row.address_to.street)}</p>
                <p className="minitext">{row.address_to.street2}</p>
              </div>
            );
          }
        },
      },
      {
        name: 'Servicio',
        selector: 'user_id',
        sortable: false,
        cell: row => row.provider_name ?  (
          <div>
            <p>{row.provider_name}</p>
            <p className="minitext">{row.service_level}</p>
          </div>
        ) : (
          <div>
            <p>{row.rate.provider}</p>
            <p className="minitext">{row.rate.servicelevel}</p>
          </div>
        ),
      },
      {
        name: 'Estatus',
        selector: 'status',
        sortable: false,
        cell: row => {
          const badge = this.badgeLabel(row.status)
          return <NewBadge type={badge.type}>{badge.label}</NewBadge>
        },
      },
      {
        name: 'Total',
        selector: 'id',
        sortable: false,
        cell: row => (
          <div>
            <p>{`$${numberWithCommas(parseFloat(row.amount).toFixed(2))} ${row.currency}`}</p>
          </div>
        ),
      },
    ];
    const ExpandedSection = ({ data }) => {
      if (data.items) {
        return (
          <div className="container-subtable">
            <NewTable
              subHeader="Items"
              className="no-min-height"
              shipping={false}
              columns={columns}
              data={JSON.parse(data.items)}
            />
          </div>
        );
      }
      return <div />;
    };
    const columns = [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
      },
      {
        name: 'Altura',
        selector: 'height',
        sortable: false,
        cell: row => (
          <div>
            <p>
              {row.height}
              {' '}
cm
            </p>
          </div>
        ),
      },
      {
        name: 'Anchura',
        selector: 'width',
        sortable: false,
        cell: row => (
          <div>
            <p>
              {row.width}
              {' '}
cm
            </p>
          </div>
        ),
      },
      {
        name: 'Longitud',
        selector: 'length',
        sortable: false,
        cell: row => (
          <div>
            <p>
              {row.length}
              {' '}
cm
            </p>
          </div>
        ),
      },
      {
        name: 'Peso',
        selector: 'weight',
        sortable: false,
        cell: row => (
          <div>
            <p>
              {row.weight}
              {' '}
kg
            </p>
          </div>
        ),
      },
    ];
    return (
      <div>
        {this.props.quotes.length !== 0 ? (
          this.props.dataQuotation.length !== 0 ? (
            <div>
              <Title className={`state-title margin-top-title ${this.props.dataQuotation[0].status === 'MANUAL_LABEL' && 'red'}`}>
                Cotización #
                {this.props.searchId}
                {this.props.dataQuotation[0].status === 'MANUAL_LABEL' && '(Error al procesar)'}
                <CSVLink
                  className="link-btn-quotes margin"
                  data={this.renderCSVData(this.props.dataQuotation, this.props.searchId)}
                  filename={`Cotización${this.props.searchId}.csv`}
                  target="_blank"
                >
                  Descargar CSV
                </CSVLink>
              </Title>
              <NewTable
                expandableRows
                expandableRowsComponent={<ExpandedSection />}
                onRowClicked={ship => this.props.shipmentDetails(ship, this.props.context)}
                shipping={false}
                columns={columnsExpanded}
                data={this.props.dataQuotation}
              />
            </div>
          ) : (
            <EmpyState name="invoices">
              <Title className="state-title">Busca to cotización por ID</Title>
              <Text>
                Busca tu cotización para poder revisar grandes cantidades de envíos y procesarlos en
                conjunto.
              </Text>
            </EmpyState>
          )
        ) : (
          <EmpyState name="invoices">
            <Title className="state-title">Todavía no generas cotizaciones</Title>
            <Text>
              Crear cotizaciones te permite agrupar grandes cantidades de envíos para revisarlos y
              procesarlos en conjunto.
            </Text>
          </EmpyState>
        )}
        {this.state.showLoader && <Skeleton count={7} height={25} />}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  quotes: state.shipments.quotes,
  countries: state.newShipment.countries,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getAllUserQuotes,
    getAllUserShipments,
    toggleSideBar,
    showMessage,
    showError,
    showLoader,
    hideLoader,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuotationList);
