// @flow
import React from "react";
import { Row, Column } from "hedron";

import Text from "../../components/Text";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import { Divider } from "../../components/Card";
import Input, { Label, HelpText } from "../../components/Input";
import { RadioGroup, Radio } from "../../components/Radio";

import { StyledRate } from "./styled-components";

type Props = {
  index: number,
  addresses: any,
  options: string,
  selectedAddressFrom: string,
  selectedAddressFromTitle: string,
  filterFrom: string,
  handleAddressChange: string,
  handleFilterChange: string,
  mainProps: any,
  from: any,
  to: any,
  packages: any,
  packageOptions: string,
  selectedPackage: string,
  filterPackage: string,
  handlePackageFilterChange: string,
  handlePackageChange: string,
  selectedPackageTitle: string,
  weightError: string,
  dimensions: any,
  volumetricWeightError: string,
  rates: string,
  insuranceSelected: string,
  selectedAddressTo: string,
  selectedAddressToTitle: string,
  filterTo: string,

  generateRatesProviders: () => void,
  generateRatesSpeed: () => void,
  generateRates: () => void,
  handleInsuranceChange: Function,
};

const ShipmentFields = ({
  index,
  addresses,
  options,
  selectedAddressFrom,
  selectedAddressFromTitle,
  filterFrom,
  handleAddressChange,
  handleFilterChange,
  mainProps,
  from,
  to,
  packages,
  packageOptions,
  selectedPackage,
  filterPackage,
  handlePackageFilterChange,
  handlePackageChange,
  selectedPackageTitle,
  weightError,
  dimensions,
  volumetricWeightError,
  rates,
  generateRatesProviders,
  generateRatesSpeed,
  generateRates,
  insuranceSelected,
  handleInsuranceChange,
  selectedAddressTo,
  selectedAddressToTitle,
  filterTo,
}: Props) =>
  index === 0 ? (
    <div>
      {addresses.results && addresses.results.length > 0 && (
        <div>
          <Text
            type="microHeader"
            style={{ marginBottom: "8px", marginTop: "8px" }}
          >
            Direcciones guardadas
          </Text>
          <Dropdown
            options={options}
            placeholder={
              <Text className="dropdown-placeholder">Buscar por alias</Text>
            }
            selected={selectedAddressFrom}
            selectedTitle={selectedAddressFromTitle}
            filter
            filterValue={filterFrom}
            filterPlaceholder="Buscar por alias"
            handleChange={handleAddressChange}
            handleFilterChange={handleFilterChange}
          />
          <Divider className="divider-address" />
          <Text type="helpTextModal">O llena la siguiente información</Text>
        </div>
      )}
      <Input
        type="text"
        {...mainProps.generate("name")}
        required
        placeholder="Pedro Gómez"
        label="Nombre completo de quien envía."
      />
      <Input
        type="text"
        {...mainProps.generate("email")}
        required
        placeholder="pedro@tucorreo.com"
        label="Correo electrónico de quien envía"
      />
      <Input
        type="text"
        {...mainProps.generate("phone")}
        required
        placeholder="(442) 123 12 45"
        label="Teléfono de contacto"
      />
      <Input
        type="text"
        {...mainProps.generate("street")}
        maxLength="35"
        required
        placeholder="Av. Josefa Ortiz, 309. Int. J-45."
        label="Dirección de origen"
      />
      <Input
        type="text"
        {...mainProps.generate("reference")}
        maxLength="35"
        placeholder="Casa amarilla"
        label="Referencias origen (opcional)"
      />
      <Input
        type="text"
        {...mainProps.generate("street2")}
        maxLength="35"
        required
        placeholder="Independencia"
        label="Colonia"
      />
      <Row>
        <Column xs={4} fluid>
          <Input
            type="text"
            {...mainProps.generate("zipcode")}
            required
            placeholder="76158"
            label="Código postal"
          />
        </Column>
        <Column xs={8} fluid style={{ paddingLeft: "16px" }}>
          <Input
            type="text"
            disabled
            onChange={() => {}}
            required
            label="Estado y Ciudad"
            value={from.location}
          />
        </Column>
      </Row>
    </div>
  ) : index === 1 ? (
    <div>
      {addresses.results && addresses.results.length > 0 && (
        <div>
          <Text
            type="microHeader"
            style={{ marginBottom: "8px", marginTop: "8px" }}
          >
            Direcciones guardadas
          </Text>
          <Dropdown
            options={options}
            placeholder={
              <Text className="dropdown-placeholder">
                Seleccionar dirección
              </Text>
            }
            selected={selectedAddressTo}
            selectedTitle={selectedAddressToTitle}
            filter
            filterValue={filterTo}
            filterPlaceholder="Buscar por alias"
            handleChange={handleAddressChange}
            handleFilterChange={handleFilterChange}
          />
          <Divider className="divider-address" />
          <Text type="helpTextModal">O llena la siguiente información</Text>
        </div>
      )}
      <Input
        type="text"
        {...mainProps.generate("name")}
        required
        placeholder="Juan Pérez"
        label="Nombre completo de quien recibe"
      />
      <Input
        type="text"
        {...mainProps.generate("email")}
        required
        placeholder="tunombre@tucorreo.com"
        label="Correo electrónico de quien recibe"
      />
      <Input
        type="text"
        {...mainProps.generate("phone")}
        required
        placeholder="(442) 123 45 67"
        label="Teléfono de contact"
      />
      <Input
        type="text"
        {...mainProps.generate("street")}
        maxLength="35"
        required
        placeholder="Av. Morelos, 1511. Int. 21"
        label="Dirección destino"
      />
      <Input
        type="text"
        {...mainProps.generate("reference")}
        maxLength="35"
        placeholder="Casa rosa con portón café"
        label="Referencias (opcional)"
      />
      <Input
        type="text"
        {...mainProps.generate("street2")}
        required
        placeholder="Independencia"
        label="Colonia"
      />
      <Row>
        <Column xs={4} fluid>
          <Input
            type="text"
            {...mainProps.generate("zipcode")}
            required
            placeholder="76158"
            label="Código postal"
          />
        </Column>
        <Column xs={8} fluid style={{ paddingLeft: "16px" }}>
          <Input
            type="text"
            disabled
            onChange={() => {}}
            required
            label="Estado y Ciudad"
            value={to.location}
          />
        </Column>
      </Row>
    </div>
  ) : index === 2 ? (
    <div>
      {packages.results && packages.results.length > 0 && (
        <div>
          <Text type="helpTextModal" style={{ marginBottom: "8px" }}>
            Elige un empaque de tus registros
          </Text>
          <Dropdown
            options={packageOptions}
            placeholder={
              <Text className="dropdown-placeholder">Seleccionar empaque</Text>
            }
            selected={selectedPackage}
            filter
            filterValue={filterPackage}
            filterPlaceholder="Buscar por alias"
            handleFilterChange={handlePackageFilterChange}
            handleChange={handlePackageChange}
            selectedTitle={selectedPackageTitle}
          />
          <Divider className="divider-address" />
          <Text type="helpTextModal">O llena la siguiente información</Text>
        </div>
      )}
      <Input
        type="text"
        {...mainProps.generate("dimensions")}
        required
        placeholder="30.0"
        label="Dimensiones (largo x ancho x altura)"
        dimensions
      />
      <Row>
        <Column xs={3} fluid>
          <Input
            type="text"
            {...mainProps.generate("weight")}
            required
            placeholder="10.0"
            label="Peso"
            weight
          />
        </Column>
        <Column xs={1} fluid style={{ height: "95px", position: "relative" }}>
          <Text className="volumetric-weight-helper">kg.</Text>
        </Column>
        <Column
          xs={12}
          fluid
          style={{
            paddingBottom: "12px",
            marginTop: "-12px",
            marginBottom: "12px",
          }}
        >
          <HelpText style={{ marginTop: "0px" }}>{weightError}</HelpText>
        </Column>
      </Row>
      <Row className="volumetric-weight-text">
        <Label>Peso volumétrico</Label>
        <Text type="helpTextModal">
          Si tu paquete tiene un peso volumétrico mayor al peso real, tomaremos
          el primero para calcular tu tarifa.
        </Text>
      </Row>
      <Row className="volumetric-weight">
        <Column xs={3} fluid>
          <Input
            type="text"
            onChange={() => {}}
            value={
              dimensions.length && dimensions.height && dimensions.width
                ? (
                    (1.0 *
                      parseFloat(dimensions.length) *
                      parseFloat(dimensions.width) *
                      parseFloat(dimensions.height)) /
                    5000.0
                  ).toString()
                : ""
            }
            error={volumetricWeightError}
            disabled
            placeholder="100.0"
            weight
          />
        </Column>
        <Column xs={1} fluid style={{ height: "95px", position: "relative" }}>
          <Text className="weight-helper">kg.</Text>
        </Column>
        <Column
          xs={12}
          fluid
          style={{
            paddingBottom: "12px",
            marginTop: "-12px",
            marginBottom: "12px",
          }}
        >
          <HelpText style={{ marginTop: "0px" }}>
            {volumetricWeightError}
          </HelpText>
        </Column>
      </Row>
    </div>
  ) : index === 3 ? (
    <div>
      <Text type="helpTextModal" className="rates-help">
        Asegúrate de revisar los horarios de servicio de cada proveedor, para
        que asi puedas hacer llegar tu envío a tiempo y no tengas
        inconvenientes. *Todas las guías requieren impresión
      </Text>
      <Row divisions={20}>
        <Column xs={6} fluid>
          <Label>Proveedores</Label>
          {rates && generateRatesProviders()}
          <Label>Velocidad de envío</Label>
          {rates && generateRatesSpeed()}
        </Column>
        <Column xs={14} fluid>
          {rates && generateRates()}
        </Column>
      </Row>
    </div>
  ) : index === 4 ? (
    <div>
      <Text type="helpTextModal">
        Al proveer esta información nos ayudas a ofrecerte un mejor servicio.
        Por ejemplo, en caso de extravío, esta información ayuda al proveedor a
        localizar más pronto tu paquete.
      </Text>
      <Input
        type="text"
        {...mainProps.generate("description")}
        maxLength={30}
        required
        placeholder="Artículos deportivos"
        label="Descripción del contenido"
      />
    </div>
  ) : index === 5 ? (
    <div>
      <Text type="helpTextModal">
        Las paqueterías cubren hasta $2,000.00 MXN, su garantía es limitada ( no
        cubre robos ni riegos de tránsito). Por esa razón te recomendamos
        adquirir nuestro seguro adicional. Puedes calcular su costo a
        continuación:
      </Text>
      <p style={{ fontSize: "11px", marginBottom: "0px" }}>
        *Las guías aseguradas pueden tardar en generarse hasta 12 hrs. laborales
      </p>
      <Row>
        <Column xs={5} fluid>
          <Input
            type="text"
            {...mainProps.generate("insuredAmount")}
            required
            placeholder="1500.50"
            label="Valor del paquete"
          />
        </Column>
        <Column xs={7} fluid />
        <Column fluid>
          <Input
            type="text"
            {...mainProps.generate("holder")}
            required
            placeholder="Juan Pérez"
            label="Responsable del paquete"
          />
        </Column>
        <Column fluid>
          <Input
            type="text"
            {...mainProps.generate("email")}
            required
            placeholder="tunombre@tucorreo.com"
            label="Correo de contacto"
          />
        </Column>
        <Column xs={5} fluid>
          <Input
            type="text"
            {...mainProps.generate("cost")}
            required
            placeholder="0.00"
            label="Costo del seguro"
            disabled
          />
        </Column>
        <Column xs={7} fluid />
        <StyledRate style={{ marginTop: "24px" }}>
          <RadioGroup
            selected={insuranceSelected}
            onChange={handleInsuranceChange}
          >
            <Radio
              defaultChecked
              type="form"
              value="1"
              text="No deseo adquirir el seguro adicional"
              containerClass="rate-radio-container"
              className="insurance-radio"
            />
          </RadioGroup>
        </StyledRate>
        <StyledRate>
          <RadioGroup
            selected={insuranceSelected}
            onChange={handleInsuranceChange}
          >
            <Radio
              type="form"
              value="2"
              text="Adquirir seguro"
              containerClass="rate-radio-container"
              className="insurance-radio"
            />
          </RadioGroup>
        </StyledRate>
      </Row>
    </div>
  ) : (
    <div>
      {addresses.results && addresses.results.length > 0 ? (
        <div>
          <Text
            type="microHeader"
            style={{ marginBottom: "8px", marginTop: "8px" }}
          >
            Direcciones guardadas
          </Text>
          <Dropdown
            className="filter-dropdown"
            options={options}
            placeholder={
              <Text className="dropdown-placeholder">Buscar por alias</Text>
            }
            selected={selectedAddressFrom}
            selectedTitle={selectedAddressFromTitle}
            filter
            filterValue={filterFrom}
            filterPlaceholder="Buscar por alias"
            handleChange={handleAddressChange}
            handleFilterChange={handleFilterChange}
          />
          <Button
            style={{
              marginLeft: "0px",
              marginBottom: "6px",
              marginTop: "16px",
            }}
            type="link"
            onClick={() => (window.location = "/direcciones/crearDefault")}
          >
            O da de alta una nueva dirección
          </Button>
          <Divider className="divider-detail" />
        </div>
      ) : (
        <div>
          <Text type="helpTextModal" style={{ marginBottom: "4px" }}>
            No cuentas con direcciones en tu libreta de direcciones.
          </Text>
          <Button
            style={{ marginLeft: "0px", marginBottom: "16px" }}
            type="link"
            onClick={() => (window.location = "/direcciones/crearDefault")}
          >
            Da de alta una nueva dirección
          </Button>
          <Divider className="divider-detail" />
        </div>
      )}
    </div>
  );

export default ShipmentFields;
