import React from "react";
import { Column, Row } from "hedron";
import { Tooltip } from "react-tippy";
import { Checkbox, CheckboxGroup } from "../../Checkbox";
import { Img } from "../../Checkout/styled-components";
import arrow from "./icons/arrow-cotizador.svg";
import fileIcon from "./icons/Shipping label.svg";
import { showMessage, hideMessage } from "../../../modules/message";
import Message from "../../Message";

const Shipment = (props) => {
  return (
    <div>
      <div className="order">
        <Row>
          <Column xs={1} fluid>
            <CheckboxGroup
              defaultChecked={props.selected}
              type="table"
              onChange={props.handleOnChange}
            >
              <Checkbox
                value={props.model.object_id}
                className="check-order"
                disabled={props.label == null}
              />
            </CheckboxGroup>
          </Column>
          <Column xs={1} fluid>
            <div
              className="img-invoice"
              style={{
                backgroundImage: `url(https://production.mienvio.mx/${props.model.rate.provider_img})`,
              }}
            />
          </Column>
          <Column xs={2} className="img-label" fluid>
            <label>{props.model.rate.provider}</label>
          </Column>
          <Column xs={3} className="order-content" fluid>
            <label>{`Orden #${props.model.object_id}`}</label>
          </Column>
          <Column xs={4} className="order-content" fluid>
            <label>{props.model.address_from.zipcode}</label>
            <Img src={arrow} />
            <label>{props.model.address_to.zipcode}</label>
          </Column>
          <Column xs={1} className="order-content order-icon" fluid>
            {props.label ? (
              <Img src={fileIcon} />
            ) : (
              <Tooltip title="Estamos generando esta guía" position="top">
                <div className="rotating">
                  <i className="ion-android-sync" />
                </div>
              </Tooltip>
            )}
          </Column>
        </Row>
      </div>

      <div>
        <Row>
          <Column xs={12} fluid>
            {props.shipmentsList.find(
              (shipment) =>
                ((shipment.object_id == props.model.object_id &&
                  shipment.status_label === "MANUAL_LABEL") ||
                  shipment.insurance !== null) &&
                shipment.rate.provider !== "Redpack"
            ) ? (
              <Message color="warning" onClose={hideMessage}>
                La guía de tu Orden #{props.model.object_id} tardará en
                liberarse más de lo esperado. En unos minutos recarga tu pagina
                para obtener tu guía. Recuerda que las guías con estatus
                “Manual” (paquetes asegurados) se liberan dentro de nuestro
                horario laboral de Lunes a Viernes de 9:00 am a 6:00 pm.
              </Message>
            ) : null}
          </Column>
        </Row>
      </div>
    </div>
  );
};

export default Shipment;
/* insurance:
cost: 36
email: "hubprueba@gmail.com"
holder: "Hub prueba"
insured_amount: 2000
product: "Cables" */
