import React from "react";
import { extendedZonePricing } from "../../constants";

import check_icon from "./check_icon.svg";
import "./style.css";

const SelectableBox = (props) => {
  return (
    <label className={`selectable_box ${props.className}`}>
      <input
        className="invisible-checkbox"
        type={props.type}
        name={props.name}
        checked={props.isChecked}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
      />
      <div className="active">
        <div className="blueborder" />
        <img src={check_icon} className="check-icon" />
        {props.cornerNote && (
          <div className="cornernote">Incluye cargos extra</div>
        )}
      </div>
      {props.children}
      {props.extrablock ? (
        <div className="extra-block">{props.extrablock}</div>
      ) : (
        ""
      )}
    </label>
  );
};

export default SelectableBox;
