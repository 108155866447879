// @flow
import React from "react";
import axios from "axios";
import styled from "styled-components";
import { boundMethod } from "autobind-decorator";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Page } from "hedron";
import browser from "browser-detect";
import Cookies from "universal-cookie";

import Countdown from "react-countdown-now";
import Auth from "./containers/auth";
import PublicTracking from "./containers/public-tracking";
import NotFound from "./components/NotFound";
import { Login } from "./components/Login";
import { LoginOperational } from "./components/LoginOperational";
import { PaymentPlan } from "./components/PaymentPlan";
import { InvoicePlan } from "./components/InvoicePlan";
import { SelectPlan } from "./components/SelectPlan";
import VerifyEmail from "./components/VerifyEmail";
import Loader from "./components/Loader";
import PasswordRequest from "./components/PasswordRequest";
import PasswordReset from "./components/PasswordReset";
import Register from "./components/Register";
import Quotations from "./containers/quotations";
import StatusReport from "./containers/statusReport";
import Banner from "./components/Banner";
import Text from "./components/Text";
import colors from "./utils/colors";

import {
  AXIOS_BASEURL,
  startDiscountPromoDate,
  finishDiscountPromoDate,
} from "./constants";
import { isTodayBetweenDates } from "./utils/global_functions";

import "./index.css";
import "./vendor/ionicons.min.css";
import ErrorBoundary from "./components/ErrorBoundary";
import HttpsRedirect from "react-https-redirect";

import * as firebase from "firebase";
import LeadsInternalForm from "./containers/LeadInternalForm";

// commit trigger
axios.defaults.baseURL = AXIOS_BASEURL;

const result = browser();
const cookies = new Cookies();

const UserLink = styled.div`
  &:hover {
    color: #e2e2e2;
  }
  cursor: pointer;
  color: ${colors.secondary.white};
  display: inline-block;
`;

const cookieName = [
  "banner_unsupported_mobile",
  "banner_unsupported_browser",
  "banner_unsupported_version",
  "temporary_message",
];

export type Props = {
  loader: {
    show: boolean,
    visible: boolean,
    kind: any,
  },
  createdType?: string,
};

export type State = {
  bannerError?: number,
};

class App extends React.Component<Props, State> {
  state: State = {
    bannerError: "",
  };

  constructor(props: Props) {
    super(props);

    let bannerError;
    if (result.mobile && !cookies.get(cookieName[0])) {
      bannerError = 1;
    } else if (
      result.name !== "chrome" &&
      result.name !== "firefox" &&
      !cookies.get(cookieName[1])
    ) {
      bannerError = 2;
    } else if (
      result.name === "chrome" &&
      result.versionNumber < 49 &&
      !cookies.get(cookieName[2])
    ) {
      bannerError = 3;
    } else if (
      result.name === "firefox" &&
      result.versionNumber < 61 &&
      !cookies.get(cookieName[3])
    ) {
      bannerError = 4;
    }

    this.state = { bannerError };
    if (
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname === "/api/auth/verifyEmail"
    ) {
      axios
        .get(`${this.props.location.pathname}${this.props.location.search}`)
        .then((r) => { });
    }
    // Your web app's Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyBY3AvvP4pMUQkQL3KMEC8ayHgEJS6vxfw",
      authDomain: "mienvio-e6669.firebaseapp.com",
      databaseURL: "https://mienvio-e6669.firebaseio.com",
      projectId: "mienvio-e6669",
      storageBucket: "mienvio-e6669.appspot.com",
      messagingSenderId: "348852735603",
      appId: "1:348852735603:web:4533d62e808cd9d6b6a406",
      measurementId: "G-MD55K5TKEE",
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    // console.log(firebase);
    // window.location.replace("https://dev.mienvio.mx/");
  }

  @boundMethod
  closeBanner(id) {
    if (!!id && id !== "") {
      cookies.set(cookieName[id], "1", { path: "/" });
      this.setState({ bannerError: "" });
    }
  }

  @boundMethod
  renderCountdownContent({ days, hours, minutes, seconds, completed }) {
    return !completed ? (
      <span>
        <strong>
          {`Tiempo restante: ${days} días, ${hours} horas, ${minutes} minutos, ${seconds} segundos.`}
        </strong>
      </span>
    ) : null;
  }

  @boundMethod
  renderBanner() {
    const { bannerError } = this.state;
    if (bannerError) {
      switch (bannerError) {
        case 1:
          return (
            <Text
              type="helpTextModal"
              style={{ color: colors.secondary.white }}
            >
              😧 mienvío aun no esta optimizado para exploradores móviles. Para
              obtener la mejor experiencia utiliza Google Chrome desde una
              computadora.
            </Text>
          );
        case 2:
          return (
            <Text
              type="helpTextModal"
              style={{ color: colors.secondary.white }}
            >
              {`😧 mienvío aun no esta optimizado para ${result.name} ${result.version}. Para obtener la mejor experiencia utiliza Google Chrome,&nbsp;`}
              <UserLink
                onClick={() =>
                  window.open("https://www.google.com/chrome/", "blank")
                }
              >
                descárgalo aquí.
              </UserLink>
            </Text>
          );
        case 3:
          return (
            <Text
              type="helpTextModal"
              style={{ color: colors.secondary.white }}
            >
              😨 Parece que tu explorador web no esta actualizado, para utilizar
              mienvío y por la seguridad de tus datos &nbsp;
              <UserLink
                onClick={() =>
                  window.open(
                    "https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=es-419",
                    "blank"
                  )
                }
              >
                actualízalo aquí.
              </UserLink>
            </Text>
          );
        case 4:
          return (
            <Text
              type="helpTextModal"
              style={{ color: colors.secondary.white }}
            >
              😨 Parece que tu explorador web no esta actualizado, para utilizar
              mienvío y por la seguridad de tus datos &nbsp;
              <UserLink
                onClick={() =>
                  window.open(
                    "https://www.mozilla.org/en-US/firefox/new/",
                    "blank"
                  )
                }
              >
                actualízalo aquí.
              </UserLink>
            </Text>
          );
        default:
          return null;
      }
    }
    return null;
  }

  @boundMethod
  renderPage() {
    const { bannerError } = this.state;
    const { loader, createdType, isPrime } = this.props;

    return (
      <Page
        className="overflow-content main-overflow-content"
        style={{ position: "relative" }}
      >
        {loader.show && loader.visible ? (
          <Loader type={loader.kind} createdType={createdType} />
        ) : null}
        {bannerError && (
          <Banner action={() => this.closeBanner(bannerError)}>
            {this.renderBanner()}
          </Banner>
        )}
        {isTodayBetweenDates(startDiscountPromoDate, finishDiscountPromoDate) &&
          !isPrime && (
            <Banner
              hideIcon
              action={() => this.closeBanner(this.state.bannerError)}
            >
              <Text
                type="helpTextModal"
                style={{ color: colors.secondary.white }}
              >
                <strong>🔥40% DE DESCUENTO ENVÍOS REDPACK🔥</strong> Envía hoy
                mismo y aprovecha esta increíble oferta. Válido hasta el{" "}
                <strong>15 de Septiembre 2019📆</strong>
                <br />
                <Countdown
                  date={finishDiscountPromoDate}
                  intervalDelay={0}
                  zeroPadTime={2}
                  precision={3}
                  renderer={this.renderCountdownContent}
                />
              </Text>
            </Banner>
          )}

        <Switch>
          <Route path="/login-operational" component={LoginOperational} />
          <Route path="/login" component={Login} />
          <Route path="/verify" component={VerifyEmail} />
          <Route path="/registrar" component={Register} />
          <Route path="/contrasena" component={PasswordRequest} />
          <Route path="/password/reset" component={PasswordReset} />
          {/* <Route
            path="/payment-plan/:plan/:invoice/:action"
            component={PaymentPlan}
          /> */}
          <Route
            path="/invoice-plan/:card_id/:plan/:action"
            component={InvoicePlan}
          />
          {/* <Route path="/select-plan/:action" component={SelectPlan} /> */}
          {!localStorage.getItem("token") && (
            <Route path="/quotations" component={Quotations} />
          )}

          {!localStorage.getItem("token") && (
            <Route path="/status-report/:token" component={StatusReport} />
          )}

          <Route
            path="/tracking/:trackingNumber/:carrier"
            component={PublicTracking}
          />
          <Route exact path="/LD-internal-form" component={LeadsInternalForm} />
          <Route path="/tracking/storefront" component={PublicTracking} />
          <Route path="/" component={Auth} />
          <Route component={NotFound} />
        </Switch>
      </Page>
    );
  }

  render() {
    return process.env.NODE_ENV === "production" ? (
      <ErrorBoundary>{this.renderPage()}</ErrorBoundary>
    ) : (
      this.renderPage()
    );
  }
}

const mapStateToProps = (state) => ({
  routing: state.routing,
  loader: state.loader,
  createdType: state.integrations.createdType,
  isPrime: state.user.prime,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
