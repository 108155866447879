import React from 'react';
import { DateRange } from 'react-date-range';
import * as rdrLocales from 'react-date-range/src/locale';
import { boundMethod } from 'autobind-decorator';
import Button from '../../../components/Button';
import Arrow from './left-arrow.svg';
import Calendar from './calendar.svg';
import Contract from './contract.svg';
import Map from './placeholder.svg';
import Carrier from './trolley.svg';
import Input from '../../../components/Input';
import Text from '../../../components/Text';
import Dropdown from '../../../components/Dropdown';
import Filter from '../../../components/Filter';
import './style.css';

const today = new Date();
const tomorrow = new Date();
class Filters extends React.Component {
  state = {
    filtersOpen: false,
    selectedFilter: null,
    selectionRange: {
      selection: {
        startDate: today,
        endDate: tomorrow,
        key: 'selection',
      },
    },
    selectedCountry: null,
    selectedCountryError: '',
    selectedCarrierError: '',
    selectedCountryFlag: false,
    selectedCarrier: null,
    selectedCarrierFlag: false,
    setedFilter: null,
    inputError: '',
    inputValue: null,
  }

  setFilter() {
    const {
      selectedFilter, inputValue, selectionRange, selectedCountry, selectedCountryFlag, selectedCarrier, selectedCarrierFlag,
    } = this.state;
    const { countries, carriers } = this.props;
    switch (selectedFilter) {
    case 'Cliente':
      if (inputValue) {
        this.setState({ setedFilter: inputValue, filtersOpen: false, inputValue: null });
        this.props.filterData({ type: 'client', value: inputValue });
      } else {
        this.setState({ inputError: 'Campo Obligatiorio' });
      }
      break;
    case 'Cotización':
      if (inputValue) {
        this.setState({ setedFilter: inputValue, filtersOpen: false, inputValue: null });
        this.props.filterData({ type: 'quote', value: inputValue });
      } else {
        this.setState({ inputError: 'Campo Obligatiorio' });
      }
      break;
    case 'Carrier':
      if (selectedCarrierFlag) {
        this.setState({ setedFilter: carriers[selectedCarrier].name, filtersOpen: false, inputValue: null });
        this.props.filterData({ type: 'carrier', value: carriers[selectedCarrier].name });
      } else {
        this.setState({ selectedCarrierError: 'Campo Obligatiorio' });
      }
      break;
    case 'Rango de Fechas':
      var day1 = null;
      var day1formated = null;
      var dd = selectionRange.selection.startDate.getDate();
      var mm = selectionRange.selection.startDate.getMonth() + 1; // January is 0!

      var yyyy = selectionRange.selection.startDate.getFullYear();
      if (dd < 10) {
        dd = `0${dd}`;
      }
      if (mm < 10) {
        mm = `0${mm}`;
      }
      var day1 = `${dd}/${mm}/${yyyy}`;
      var day1formated = `${yyyy}-${mm}-${dd}`;
      var day2 = null;
      var day2formated = null;
      var dd = selectionRange.selection.endDate.getDate();
      var mm = selectionRange.selection.endDate.getMonth() + 1; // January is 0!

      var yyyy = selectionRange.selection.endDate.getFullYear();
      if (dd < 10) {
        dd = `0${dd}`;
      }
      if (mm < 10) {
        mm = `0${mm}`;
      }
      var day2 = `${dd}/${mm}/${yyyy}`;
      var day2formated = `${yyyy}-${mm}-${dd}`;
      this.setState({ setedFilter: `${day1} - ${day2}`, filtersOpen: false, inputValue: null });
      this.props.filterData({ type: 'date', value: `start_date=${day1formated}&end_date=${day2formated}` });

      break;
    case 'País':
      if (selectedCountryFlag) {
        this.setState({ setedFilter: countries[selectedCountry].name, filtersOpen: false, inputValue: null });
        this.props.filterData({ type: 'country', value: countries[selectedCountry].currency });
      } else {
        this.setState({ selectedCountryError: 'Campo Obligatiorio' });
      }
      break;
    default:
      break;
    }
  }

  handleInput = (inputValue) => {
    this.setState({ inputValue });
  };

  toogleFilters() {
    this.setState({ filtersOpen: !this.state.filtersOpen });
  }

  selectedItem(selectedFilter) {
    this.setState({ selectedFilter });
  }

  @boundMethod
  handleDropDownCountryChange(selectedCountry) {
    this.setState({ selectedCountry, selectedCountryFlag: true });
  }

  @boundMethod
  handleDropDownCarrierChange(selectedCarrier) {
    this.setState({ selectedCarrier, selectedCarrierFlag: true });
  }

  _renderCountries = () => (this.props.countries
    ? this.props.countries.map(country => (
      <Text key={country.object_id}>{country.name}</Text>
    ))
    : null);

  renderCarriers = () => (this.props.carriers
    ? this.props.carriers.map(carrier => (
      <Text key={carrier.id}>{carrier.name}</Text>
    ))
    : null);

  render() {
    const {
      selectedCarrierError, selectedCarrier, setedFilter, selectedFilter, filtersOpen, selectionRange, selectedCountry, selectedCountryError, inputValue, inputError,
    } = this.state;
    return (
      <div>
        {setedFilter ? (
          <Filter
            noMarginFilter={this.props.noMarginFilter}
            onClose={() => {
              this.setState({
                setedFilter: null, selectedFilter: null, selectedCountry: null, selectedCountryFlag: false, selectedCountryError: '',
              });
              this.props.filterData({ type: null, value: null });
            }}
          >
            {selectedFilter}
            {' '}
              -
            {' '}
            {setedFilter}
          </Filter>
        ) : <Button onClick={() => this.toogleFilters()} type="link">+ Añadir Filtro</Button>}

        {filtersOpen && (
          <div style={{ position: 'relative' }}>
            <div className={`container-filters ${filtersOpen && 'open'} ${selectedFilter && 'selected-item'}`}>
              {!selectedFilter
                ? (
                  <div>
                    <div onClick={() => this.selectedItem('Rango de Fechas')} className="filter-item">
                      <img src={Calendar} alt="" style={{ width: '12px', height: '12px', marginRight: '5px' }} />
                        Rango de Fechas
                    </div>
                    <div onClick={() => this.selectedItem('País')} className="filter-item">
                      <img src={Map} alt="" style={{ width: '12px', height: '12px', marginRight: '5px' }} />
                        País
                    </div>
                    {/* <div onClick={() => this.selectedItem('Cliente')} className="filter-item">
                        Cliente
                    </div> */}
                    <div onClick={() => this.selectedItem('Cotización')} className="filter-item">
                      <img src={Contract} alt="" style={{ width: '12px', height: '12px', marginRight: '5px' }} />
                        Cotización
                    </div>
                    <div onClick={() => this.selectedItem('Carrier')} className="filter-item">
                      <img src={Carrier} alt="" style={{ width: '12px', height: '12px', marginRight: '5px' }} />
                        Carrier
                    </div>
                  </div>
                )

                : (
                  <div>
                    <div onClick={() => this.setState({ selectedFilter: null })} className="filter-item selected">
                      <img src={Arrow} style={{ height: '12px', width: '12px' }} alt="" />
                      {selectedFilter}
                    </div>
                    {selectedFilter === 'Rango de Fechas' ? (
                      <div>
                        <DateRange
                          ranges={[selectionRange.selection]}
                          onChange={value => this.setState({ selectionRange: value })}
                          color="#2576DA"
                          locale={rdrLocales.es}
                        />
                      </div>
                    )
                      : (
                        <div className="container-selected">
                          {selectedFilter === 'País' ? (
                            <div className="filter-dropdown-quotes">
                              <Dropdown
                                error={selectedCountryError}
                                options={this._renderCountries()}
                                handleChange={this.handleDropDownCountryChange}
                                selected={selectedCountry}
                                placeholder={<Text className="dropdown-placeholder">Selecciona un país</Text>}
                              />
                            </div>
                          ) : selectedFilter === 'Carrier'
                            ? (
                              <div className="filter-dropdown-quotes">
                                <Dropdown
                                  error={selectedCarrierError}
                                  options={this.renderCarriers()}
                                  handleChange={this.handleDropDownCarrierChange}
                                  selected={selectedCarrier}
                                  placeholder={<Text className="dropdown-placeholder">Paqueteria</Text>}
                                />
                              </div>
                            )
                            : <Input error={inputError} value={inputValue} onChange={this.handleInput} className="filter-input" type="text" placeholder={`${selectedFilter === 'Cliente' ? 'Juan Perez' : selectedFilter === 'Cotización' ? '100023' : 'Estafeta'}`} />}

                        </div>
                      )
                    }
                    <div className="btn-add-filter">
                      <Button onClick={() => this.setFilter()} type="link">Añadir</Button>
                    </div>
                  </div>

                )

              }
            </div>
          </div>
        )}
      </div>


    );
  }
}
export default Filters;
