// @flow
import React from 'react';
import axios from 'axios';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {Redirect} from 'react-router-dom';
import {boundMethod} from 'autobind-decorator';
import Cookies from 'universal-cookie';
import {Row, Column} from 'hedron';
import valid from 'card-validator';
import Card from '../../components/Card';
import {createCardPlan} from '../../modules/cards';
import Scriptly from 'scriptly';
import {ToastContainer, toast} from 'react-toastify';
import {showMessage, hideMessage} from '../../modules/message';
import {hideLoader} from '../../modules/loader';
import Text from '../Text';
import Button from '../Button';
import Input from '../Input';
import Logo from '../static/Logo';
import Message from '../Message';
import Profile from '../Profile';
import styles from './index.module.scss';
import Visa from './img/visa.png';
import Mastercard from './img/mastercard.png';
import {Checkbox, CheckboxGroup} from '../../components/Checkbox';
import {showError} from '../../modules/error';

import type {MessageType}
from '../../constants/customTypes';

type DefaultProps = {
  message: MessageType;
};

export type Props = {
  ...DefaultProps,
  hideMessage: () => void
};

type State = {
  email: string,
  emailError: string,
  password: string,
  passwordError: string
};

class PlanPayment extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    message: {
      messageType: '',
      show: false,
      text: ''
    }
  };

  state: State = {
    number: '',
    numberError: '',
    expiration: '',
    expirationError: '',
    name: '',
    nameError: '',
    cvc: '',
    cvcError: '',
    loading: false
  };

  notify = () => {
    toast.error('Error al iniciar sesión', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  };

  handleSaveAsNewCardChange(saveAsNewCard) {
    saveAsNewCard = this.state.saveAsNewCard === 0
      ? 1
      : 0;
    this.setState({saveAsNewCard});
  }

  componentDidMount() {
    const {plan, invoice, action} = this.props.match.params;

    axios.get("/api/plans").then((r) => {
      if (r.status == 200) {
        this.setState({plans: r.data});
      }
    });

    this.setState({invoice: false, plan_id: plan, action: action});

    this.resetForms();

  }

  isLoggedIn() {
    if (localStorage.getItem('token')) {
      const token = `Bearer ${localStorage.getItem('token')}`;
      axios.defaults.headers.common.Authorization = token;
      return true;
    }
    return false;
  }

  checkFree() {
    if (this.state.plans) {

      const planObject = this.state.plans.find(plan => plan.id == this.state.plan_id);
      if (planObject.name == 'free') {
        return true;
      }

      return false;
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderPlan() {
    if (this.state.plans) {
      const planObject = this.state.plans.find(plan => plan.id == this.state.plan_id);
      return (<Column xs={12} md={6} fluid>
        <div className={styles.right + " " + styles.marginTop} style={{
            backgroundColor: 'white'
          }}>
          <div className={styles.containerInputs} style={{
              textAlign: 'center'
            }}>
            <Card size="full" className={styles.card}>
              <div className={styles.cardContent}>
                <p className={styles.title}>Plan {this.capitalizeFirstLetter(planObject.name)}</p>
                <p className={styles.price}>
                  $ {planObject.total_price}
                  <span className={styles.perMonth} style={{
                      fontSize: '15px',
                      fontWeight: 'normal'
                    }}>/mes</span>
                </p>
                <p className={styles.usd}>Monto en USD</p>
                <p className={styles.iva}>IVA inlcuido</p>
                <a href="https://www.mienvio.mx/comparativo-planes.html" target="_blank" className={styles.link}>Ver comparativo de planes</a>
              </div>
              <div className={styles.gray}>
                <Button loading={this.state.loading} onClick={() => this.saveCard('create', null)}>
                  Pagar ${planObject.total_price}
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </Column>);

    }

  }

  resetForms() {
    this.setState({
      number: '',
      numberError: '',
      expiration: '',
      expirationError: '',
      name: '',
      nameError: '',
      cvc: '',
      cvcError: '',
      loading: false
    });
  }

  handleChange(value, name) {
    switch (name) {
      case 'name':
        this.setState({
          nameError: value === ''
            ? 'Este campo es requerido'
            : ''
        });
        break;
      case 'number':
        if (value.length > 16)
          return;
        let number = valid.number(value);
        let numberError = !number.isPotentiallyValid
          ? value === ''
            ? 'Este campo es requerido'
            : 'Número de tarjeta inválido'
          : number.card && number.card.isAmex
            ? 'Por el momento no soportamos Amex, intente otra tarjeta'
            : '';
        this.setState({numberError});
        break;
      case 'expiration':
        let expiration = valid.expirationDate(value);
        let val = value.replace('/', '');
        if (!isNaN(val) && val.length <= 4) {
          if (val.length >= 2) {
            val = val.replace(/(\d{2})/, '$1/');
            if (this.state.expiration === val) {
              val = val.replace('/', '');
            }
          }
          value = val;
        } else if (val.length > 4) {
          val = `${val.substring(0, 2)}/${val.substring(2, 4)}`;
          value = val;
        }
        let expirationError = !expiration.isValid || value.length !== 5 || value.indexOf('/') !== 2
          ? value === ''
            ? 'Este campo es requerido'
            : 'Fecha de vencimiento inválida'
          : '';
        this.setState({expirationError});
        break;
      case 'cvc':
        let cvc = valid.cvv(value);
        let cvcError = !cvc.isValid
          ? value === ''
            ? 'Este campo es requerido'
            : 'CVC inválido'
          : '';
        this.setState({cvcError});
        break;
      default:
        break;
    }
    if ((name !== 'name' && name !== 'expiration' && !isNaN(value)) || name === 'name' || name === 'expiration') {
      this.setState({[name]: value});
    }
  }

  async saveCard(type) {
    this.setState({loading: true})

    const errors = {
      required: 'Este campo es requerido'
    };
    let nameError = this.state.name === ''
      ? errors.required
      : '';
    let number = valid.number(this.state.number);
    let numberError = !number.isValid
      ? this.state.number === ''
        ? errors.required
        : 'Número de tarjeta inválido'
      : number.card && number.card.isAmex
        ? 'Por el momento no soportamos Amex, intente otra tarjeta'
        : '';
    let expiration = valid.expirationDate(this.state.expiration);
    let expirationError = !expiration.isValid || this.state.expiration.length !== 5 || this.state.expiration.indexOf('/') !== 2
      ? this.state.expiration === ''
        ? errors.required
        : 'Fecha de vencimiento inválida'
      : '';
    let cvc = valid.cvv(this.state.cvc);
    let cvcError = !cvc.isValid
      ? (
        this.state.cvc === ''
        ? errors.required
        : 'CVC inválido')
      : '';
    if (nameError || numberError || cvcError || expirationError) {
      this.setState({loading: false})
      return this.setState({nameError, numberError, cvcError, expirationError});
    }
    if (type === 'create') {
      let expirationDate = this.state.expiration.split('/');

      const card = {
        object: 'card',
        exp_month: expirationDate[0],
        exp_year: expirationDate[1],
        number: this.state.number,
        name: this.state.name,
        cvc: this.state.cvc
      };

      const email = this.props.user.email;
      const planObject = this.state.plans.find(plan => plan.id == this.state.plan_id);
      const id = parseInt(this.state.plan_id);
      const action = this.state.action;
      const invoice = this.state.invoice;
      let billing = true;
      if (this.props.user.fiscal_address === null) {
        billing = false;
      }

      await this.props.createCardPlan(card, 'card', id, billing, email, action, invoice).then((res) => this.setState({loading: false}))
    }
  }

  checkFiscalAddress() {
    this.setState({
      invoice: !this.state.invoice
    });
  }

  render() {
    const {message, hideMessage} = this.props;

    if (this.checkFree()) {
      return <Redirect to="/verify"/>;
    }

    if (!this.isLoggedIn()) {
      return <Redirect to="/login"/>;
    }

    const mainProps = {
      generate: name => ({
        onChange: (value) => {
          this.handleChange(value, name);
        },
        value: this.state[name],
        error: this.state[`${name}Error`]
      })
    };

    if (message.show && (message.messageType === 'error' || message.messageType === 'success')) {
      setTimeout(hideMessage, 5000);
    }

    return (<div className={styles.contPlan}>
      <ToastContainer/>
      <div className={styles.cardLogin}>
        <Row className={styles.row}>
          <Logo/>
          <Profile/>
        </Row>
        <Row className={styles.row + " " + styles.content}>
          <Column xs={12} md={6} fluid="fluid">
            <div className={styles.containerInputs}>

              {
                message.show
                  ? (<Message color={message.messageType} onClose={hideMessage}>
                    {message.text}
                  </Message>)
                  : null
              }

              <p className={styles.title}>Método de Pago</p>
              <Input type="text" {...mainProps.generate('name')} required="required" placeholder="Escribe el nombre como aparece" label="Titular de la tarjeta"/>
              <Input type="text" {...mainProps.generate('number')} required="required" placeholder="1234 4567 7891 1234" label="Número de tarjeta"/>
              <div className="container-cards">
                <img src={Visa}/>
                <img className={styles.mastercard} src={Mastercard}/>
              </div>

              <Row>
                <Column xs={6} md={3} fluid="fluid" style={{
                    paddingLeft: '0px'
                  }} className={styles.expiration}>
                  <Input type="text" {...mainProps.generate('expiration')} required="required" placeholder="09/18" label="Expiración" autocomplete="new-password"/>
                </Column>
                <Column xs={6} md={3} fluid="fluid" style={{
                    paddingLeft: '24px'
                  }}>
                  <Input type="password" name="cvc" {...mainProps.generate('cvc')} required="required" placeholder="000" label="CVC" autoComplete="new-password"/>
                </Column>
              </Row>
              <CheckboxGroup type="table" onChange={() => this.checkFiscalAddress()}>
                <Checkbox styleText={{
                    fontSize: '12px',
                    fontWeight: '600',
                    color: '#697d91'
                  }} style={{
                    marginTop: '20px'
                  }} checkboxClass="no-margin-left" value="1" text="Facturar Compra"/>
              </CheckboxGroup>
            </div>

          </Column>
          {this.renderPlan()}
        </Row>
      </div>

    </div>);
  }
}

const mapStateToProps = state => ({message: state.message, user: state.user});

const mapDispatchToProps = dispatch => bindActionCreators({
  goToInvoice: (plan, action) => push(`/invoice-plan/${plan}/${action}`),
  createCardPlan,
  hideMessage,
  hideLoader,
  showError,
}, dispatch,);

export default connect(mapStateToProps, mapDispatchToProps,)(PlanPayment);
