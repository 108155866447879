import styled from 'styled-components';

export const Line = styled.div`
    display: block;
    height: 1px;
    width: 100%;
    background-color: #dedede;
    margin-top: 1rem;
    margin-bottom: .5rem;
    box-sizing: border-box;
`;
