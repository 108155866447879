import React from 'react';

export const SimpleCheckbox = props => (
  <label className={`${props.className ? props.className : 'simple-checkbox'} ${(props.readonly) && 'disabled'}`}>
    <input
      {...props}
      disabled={props.readonly}
      type="checkbox"
    />
    <div className="checkbox" />
    <p>{props.title}</p>
  </label>
);
