
import React from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { boundMethod } from 'autobind-decorator';
import { Row, Column } from 'hedron';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment/min/moment-with-locales';
import NewTable from '../../NewTable';
import { MarketplaceBadge } from '../../Badge';
import eye from '../../QuotationsFullList/eye.svg';
import { shorten, numberWithCommas, searchPermission } from '../../../utils/global_functions';
import { getQuotes, getQuoteById } from '../../../modules/marketplaceAccount/quotations';
import Input from '../../Input';
import Button from '../../Button';
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from '../../../modules/CollapsibleBar/actions';
import WrapperSideBar from '../../WrapperSideBar';
import { ContentAdapter, BottomContainer } from '../../WrapperSideBar/styledObjects';

import '../style.css';
import { ORDER_DETAILS, SHOW_SELECTED_QUOTES } from '../../../modules/CollapsibleBar/sideBarTypes';
import DetailedOrder from '../../../containers/quotations/DetailedOrder';
import DateFilter from './DateFilter';
import CancelModal from '../../ShipmentsList/common/CancelModal';

const STATUS_WITH_LABELS = ['LABEL_CREATED', 'TRANSITO', 'OCURRE', 'ENTREGADO']

const columnsExpanded = [
  {
    name: 'N° Orden',
    selector: 'id',
    sortable: true,
    cell: row => (
      <div>
        <p>
          #
          {row.id}
        </p>
        <MarketplaceBadge color={row.marketplace !== 'manual' ? row.marketplace : 'default'} />
      </div>
    ),
  },
  {
    name: 'Fecha',
    selector: 'date',
    cell: (row) => {
      const shipment_date = moment(row.date.split(' ')[0]);
      const today = new Date();
      const timeDiff = Math.abs(today - shipment_date);
      const daysDiff = Math.floor(timeDiff / (3600 * 24 * 1000));
      return (
        <div>
          <p>{shipment_date.format('DD-MM-YYYY')}</p>
          <p className="minitext">
            {daysDiff < 1 ? 'hoy' : `hace ${daysDiff} ${daysDiff > 1 ? 'días' : 'día'}`}
          </p>
        </div>
      );
    },
  },
  {
    name: 'Destinatario',
    selector: 'name',
    cell: row => shorten(row.to_name, 16),
  },
  {
    name: 'Destino',
    selector: 'address_to',
    cell: (row) => {
      const street = row.to_street.split('|');
      return (
        <div>
          <p>{shorten(street[0], 16)}</p>
          <p className="minitext">{street[1]}</p>
        </div>
      );
    },
  },
  {
    name: 'Servicio',
    selector: 'user_id',
    sortable: false,
    cell: row => (
      <div>
        <p>{row.provider_name}</p>
        <p className="minitext">{row.service_level}</p>
      </div>
    ),
  },
  {
    name: 'Total',
    selector: 'id',
    sortable: false,
    cell: row => (
      <div>
        <p>{`$${numberWithCommas(parseFloat(row.amount).toFixed(2))} ${row.currency}`}</p>
      </div>
    ),
  },
];
const columns = [
  {
    name: 'Id',
    selector: 'id',
    sortable: true,
  },
  {
    name: 'Altura',
    selector: 'height',
    sortable: false,
    cell: row => (
      <div>
        <p>
          {row.height}
          {' '}
          cm
        </p>
      </div>
    ),
  },
  {
    name: 'Anchura',
    selector: 'width',
    sortable: false,
    cell: row => (
      <div>
        <p>
          {row.width}
          {' '}
          cm
        </p>
      </div>
    ),
  },
  {
    name: 'Longitud',
    selector: 'length',
    sortable: false,
    cell: row => (
      <div>
        <p>
          {row.length}
          {' '}
          cm
        </p>
      </div>
    ),
  },
  {
    name: 'Peso',
    selector: 'weight',
    sortable: false,
    cell: row => (
      <div>
        <p>
          {row.weight}
          {' '}
          kg
        </p>
      </div>
    ),
  },
];

const ExpandedSection = ({ data }) => {
  if (data[1] && data[1].length > 0) {
    return (
      <div className="container-subtable">
        <NewTable
          subHeader={`Cotización #${data[0]}`}
          className="no-min-height"
          expandableRowsComponent={<ExpandedSection2 />}
          expandableRows
          shipping={false}
          columns={columnsExpanded}
          data={data[1]}
        />
      </div>
    );
  }
  return <div />;
};
const ExpandedSection2 = ({ data }) => {
  if (data.items) {
    return (
      <div className="container-subtable">
        <NewTable
          subHeader="Items"
          className="no-min-height"
          shipping={false}
          columns={columns}
          data={JSON.parse(data.items)}
        />
      </div>
    );
  }
  return <div />;
};


class Quotations extends React.Component {
  state = {
    data: [],
    stored: [],
    loading: false,
    searchId: null,
    btnLoading: false,
    selectedDate: null,
  }

  constructor(props: Props) {
    super(props);
  }

  handleSearchChange = (value) => {
    this.setState({ searchId: value });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (this.state.searchId && this.state.searchId !== '') {
        this.setState({ loading: true });
        getQuoteById(this.props.id, this.state.searchId).then((response) => {
          this.setState({ loading: false });
          if (response.type === 'Success') {
            const result = [];
            for (const i in response.response.data) {
              result.push([i, response.response.data[i]]);
            }
            this.setState({ data: result });
          } else {
            this.props.notify('Error al obtener la información', 'error');
          }
        });
      } else {
        this.setState({ loading: true });
        getQuotes(this.props.id).then((response) => {
          this.setState({ loading: false });
          if (response.type === 'Success') {
            const result = [];
            for (const i in response.response.data) {
              result.push([i, response.response.data[i]]);
            }
            this.setState({ data: result });
          } else {
            this.props.notify('Error al obtener la información', 'error');
          }
        });
      }
    }
  };

  componentWillMount() {
    this.searchQuotes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.searchQuotes();
    }
  }

  // Search quotes from api when update the user
  searchQuotes() {
    if (this.props.id) {
      this.setState({ loading: true, data: [], stored: [] });
      getQuotes(this.props.id).then((response) => {
        if (response.type === 'Success') {
          const result = [];
          for (const i in response.response.data) {
            result.push([i, response.response.data[i]]);
          }
          this.setState({ loading: false, data: result, stored: result });
        } else {
          this.setState({ loading: false });
          this.props.notify('Error al obtener la información', 'error');
        }
      });
    }
  }

  @boundMethod
  searchPress() {
    if (this.state.searchId && this.state.searchId !== '') {
      this.setState({ loading: true });
      getQuoteById(this.props.id, this.state.searchId).then((response) => {
        this.setState({ loading: false });
        if (response.type === 'Success') {
          const result = [];
          for (const i in response.response.data) {
            result.push([i, response.response.data[i]]);
          }
          this.setState({ data: result });
        } else {
          this.props.notify('Error al obtener la información', 'error');
        }
      });
    } else {
      this.setState({ loading: true });
      getQuotes(this.props.id).then((response) => {
        this.setState({ loading: false });
        if (response.type === 'Success') {
          const result = [];
          for (const i in response.response.data) {
            result.push([i, response.response.data[i]]);
          }
          this.setState({ data: result });
        } else {
          this.props.notify('Error al obtener la información', 'error');
        }
      });
    }
  }

  @boundMethod
  filterDates(date) {
    const dateString = date.toISOString().split('T')[0]
    const filteredQuotes = this.state.stored.filter(quote => quote[1][0].date.includes(dateString))

    this.setState({ selectedDate: date, data: filteredQuotes })

  }

  @boundMethod
  clearFilterDate() {
    this.setState({ selectedDate: null, data: [...this.state.stored] })
  }

  openSideBar = (stateSidebar = false, sidebarTitle = '', sidebarType = '') => {
    this.props.toggleSideBar(stateSidebar, sidebarTitle, sidebarType);
  };

  getOrderMetadata = (order) => {
    const relatedOrders = {
      nextOrder: 0,
      prevOrder: 0,
    };
    this.setState({
      order: {
        details: order.shipment,
        events: [],
        nextOrder: relatedOrders.nextOrder,
        prevOrder: relatedOrders.prevOrder,
      },
      detailOpen: true,
      loadingOrder: false,
    });
    this.props.changeSidebarTitle(`Orden #${order.shipment.object_id}`);
  };

  shipmentDetails(shipment) {
    if (shipment[1][0]) {
      this.setState({ loadingOrder: true, selectedId: shipment[0] });
      this.openSideBar(true, `Consultando órden #${shipment[1][0].id}`, ORDER_DETAILS);
      axios
        .get(`/api/shipments/${shipment[1][0].id}`)
        .then((response) => {
          this.getOrderMetadata(response.data);
        })
        .catch((error) => {
        });
    }
  }

  renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
      case ORDER_DETAILS:
        return <div>{this.renderSideBarDetails()}</div>;
      case SHOW_SELECTED_QUOTES:
        return <div>{this.props.renderSelectedList()}</div>;
      default:
        return <div />;
    }
  };

  // Set as checked a quote in "toProcess" object's lists while change user
  checkSelectedQuotes(id) {
    if (this.props.toProcess[this.props.id] === undefined) return false
    return this.props.toProcess[this.props.id].find(e => e[0] === id) !== undefined;
  }

  @boundMethod
  getQuote(id, guide) {
    this.setState({
      loadingGuide: true,
    });
    this.props.toggleSideBar(false);
    const context = this;
    if (guide) {
      axios
        .get(`/api/quotes/${id}`)
        .then((r) => {
          switch (r.status) {
            case 200:
              var countLabels = 0;
              var error = false;
              r.data.purchase.shipments.forEach((element) => {
                if (element.status === 'PURCHASE') {
                  countLabels++;
                }
              });
              if (countLabels === r.data.purchase.shipments.length) {
                this.getQuote(id, true);
              }
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          this.setState({
            loadingGuide: false,
          });
        });
    } else {
      setTimeout(() => {
        axios
          .get(`/api/quotes/${id}`)
          .then((r) => {
            switch (r.status) {
              case 200:
                var countLabels = 0;
                r.data.purchase.shipments.forEach((element) => {
                  if (element.status === 'PURCHASE') {
                    countLabels++;
                  }
                });
                if (countLabels === r.data.purchase.shipments.length) {
                  context.getQuote(id, true);
                } else {
                  context.setState({
                    guides: r.data.purchase.shipments,
                    selectedIdGuide: id,
                  });
                  context.downloadGuides(context);
                }

                break;
              default:
                break;
            }
          })
          .catch((err) => {
            context.props.hideLoader();
            context.props.showError('Error al obtener la(s) ordenes');
            context.setState({
              loadingGuide: false,
            });
          });
      }, 5000);
    }
  }

  renderSideBarDetails() {
    const {
      customer_type, plan, actions, role, operational_user,
    } = this.props.user;

    const permissions = [];
    if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
      operational_user.operational_role.actions.map(element => permissions.push(element));
    }

    if (plan && plan.features) {
      plan.features.map(element => permissions.push(element));
    }
    if (actions) {
      actions.map(element => permissions.push(element));
    }
    const { dataQuotation, selectedId, btnLoading } = this.state;
    return (
      <div>
        {!this.state.loadingOrder ? (
          <ContentAdapter>
            <CancelModal
              shipmentId={this.state.order.details.object_id} 
              quoteId={this.state.order.details}
              showModal={this.state.showCancelModal} 
              onCloseModal={this.onCancelOrder}>
            </CancelModal>
            <DetailedOrder
              order={this.state.order}
              // goToPayment={this.goToPaymentUnique}
              events={[]}
            />
            <BottomContainer>
              <Row divisions={20}>
                {searchPermission('cotizaciones', 'editar', permissions) && (
                  <Column style={{ textAlign: 'right' }} fluid>

                    {this.state.order && this.state.order.shipment && this.state.order.shipment.status === 'LABEL_CREATED' ? (
                      <footer>
                        <Button
                          loading={btnLoading}
                          className="no-margin"
                          onClick={() => this.getQuote(selectedId, true)}
                        >
                          Ver Guias
                        </Button>
                      </footer>

                    ) : <div></div>
                    }

                  </Column>
                )}
                <Column xs={1} xsShift={1} style={{ textAlign: 'right' }} fluid />
              </Row>
            </BottomContainer>
          </ContentAdapter>
        ) : (
          <ContentAdapter>
            <div className="margin-top-title" />
            <Skeleton height={140} />
            <div className="margin-top-title" />
            <Skeleton height={140} />
            <div className="margin-top-title" />
            <Skeleton height={140} />
          </ContentAdapter>
        )}
      </div>
    );
  }

  @boundMethod
  onCancelOrder(){
    this.setState({showCancelModal: false}); 
    this.props.toggleSideBar(false);
  }

  onCloseSb = () => {
    const { clearPackage } = this.props;
    this.setState({
      order: {
        events: [],
        details: {
          weight: '',
          height: '',
          length: '',
          width: '',
          weightError: '',
          heightError: '',
          lengthError: '',
          widthError: '',
          declared_value: '',
          insuredAmount: '',
        },
      },
      loadingOrder: true,
      detailOpen: false,
      selectedId: null,
    });
  };

  render() {
    const col = [
      {
        name: 'Cotizacion',
        selector: 'id',
        cell: (row) => {
          if (row[1][0].status === 'MANUAL_LABEL') {
            return (
              <div>
                <p className="error-quote">
                  #
                  {row[0]}
                  <br />
                  (Error al procesar)
                </p>
              </div>
            );
          }

          if (STATUS_WITH_LABELS.includes(row[1][0].status)) {
            return (
              <div>
                <p className="generated-label">
                  #
                  {row[0]} <br />
                  <label className=''>Guía creada</label>
                </p>
              </div>
            );
          }
          return (
            <div>
              <p>
                #
                {row[0]}
              </p>
            </div>
          );
        },
      },
      {
        name: 'N° Ordenes',
        selector: 'date',
        cell: row => (row[1].length),

      },
      {
        name: 'Ver Detalle',
        selector: 'id',
        center: true,
        ignoreRowClick: true,
        cell: row => (<img alt="" src={eye} style={{ height: '17px', width: '17px' }} onClick={() => this.shipmentDetails(row)} />),

      },
      {
        name: 'Procesar',
        selector: 'id',
        center: true,
        ignoreRowClick: true,
        cell: row => {
          if (STATUS_WITH_LABELS.includes(row[1][0].status)) {
            return (<input type='checkbox'
              onChange={(e) => this.props.onClickQuote(e, row)}
              defaultChecked={this.checkSelectedQuotes(row[0])}
              value={undefined} disabled='true' />)
          }
          return (<input type='checkbox'
            onChange={(e) => this.props.onClickQuote(e, row)}
            defaultChecked={this.checkSelectedQuotes(row[0])}
            value={undefined} />)
        },
      }
    ];
    return (
      <div>
        <WrapperSideBar
          handleClose={this.onCloseSb}
        >
          {this.renderSidebarContent()}
        </WrapperSideBar>
        {this.state.loading
          ? (
            <div className="container-loading">
              <Skeleton height={30} count={6} />
            </div>
          )
          : (
            <div>
              <div className="container-input-quotes">
                <div className='g-col-6'>
                  <DateFilter
                    selectedDate={this.state.selectedDate}
                    clearFilter={this.clearFilterDate}
                    placeholder="Filtrar por fecha"
                    filterDates={this.filterDates} />
                </div>
                <Input
                  parentClassName="filter-input"
                  type="text"
                  value={this.state.searchId}
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handleSearchChange}
                  placeholder="Id de Cotización u Orden"
                />
                <Button
                  className="no-margin"
                  loading={this.state.btnLoading}
                  onClick={() => this.searchPress()}
                >
                  Buscar
                </Button>
              </div>

              <NewTable
                expandableRows
                expandableRowsComponent={<ExpandedSection />}
                shipping={false}
                columns={col}
                defaultSortAsc={false}
                noTableHead
                data={this.state.data}
              />
            </div>

          )
        }
      </div>
    );
  }
}
const mapStateToProps = state => ({
  sidebarOrder: state.newShipment,
  user: state.user,
  sidebar: state.sidebar,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    toggleSideBar,
    changeSidebarTitle,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Quotations);
