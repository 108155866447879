import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { boundMethod } from "autobind-decorator";

import axios from "axios";
import { Row, Column } from "hedron";
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";
import Skeleton from "react-loading-skeleton";
import Waypoint from "react-waypoint";
import Loader from "react-loaders";
import { Tooltip } from "react-tippy";
import styled from "styled-components";
import Dropdown from "../Dropdown";
import Text, { Title, P } from "../Text";
import Badge from "../Badge/insuranceBadge";
import EmpyState from "../EmptyState";
import Button from "../Button";
import NewTable from "../NewTable";
import colors from "../../utils/colors";
import "./style.scss";
import WrapperSideBar from "../WrapperSideBar";
import {
  ContentAdapter,
  BottomContainer,
} from "../WrapperSideBar/styledObjects";
import Input, { Label, Required, HelpText } from "../Input";
import Select from "react-select";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../modules/CollapsibleBar/actions";
import ThreeDotsDropdown from "../Dropdown/ThreeDotsDropdown/index";
import edit_icon from "../../utils/icons/edit.svg";
import delete_icon from "../../utils/icons/delete.svg";
import { getRegions } from "../../modules/regions";
import {
  getAccounts,
  getMasterCountries,
  getManagerCountries,
} from "../../modules/marketplaces";
import { searchPermission } from "../../utils/global_functions";
import { ToastContainer, toast } from "react-toastify";

const LoaderContainer = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  margin: 10px 0;
`;
const StyledInformation = styled.i`
  color: ${colors.gray.charcoal};
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
`;

const NEW_SUBACCOUNT = "NEW_SUBACCOUNT";
const ACCOUNT_DETAILS = "ACCOUNT_DETAILS";
const ACCOUNT_EDIT = "ACCOUNT_EDIT";

class SubAccountsList extends React.Component {
  state = {
    detailOpen: false,
    accounts: [],
    detailAccount: "",
    roles: [],
    pin: "",
    password: "",
    rol: "",
    pinError: "",
    passwordError: "",
    rolError: "",
    selectedRoles: "",
    valueRoles: [],
    selectedRegion: null,
    selectedRegionIndex: null,
    selectedAccount: null,
    selectedAccountIndex: null,
    regionsData: [],
    accountsData: [],
    emptyRoles: "",
    selectedRegionIndexAdd: null,
    selectedRegionErrorAdd: "",
    selectedRegionAdd: null,
    selectedAccountIndexAdd: null,
    selectedAccountErrorAdd: "",
    selectedAccountAdd: null,
    accountsDataAdd: [],
  };

  componentWillMount() {
    this.props.toggleSideBar(false);
    if (
      this.props.user.role === "master" ||
      this.props.user.role === "manager" ||
      this.props.user.plan.name === "enterprise" ||
      this.props.user.plan.name === "operativo"
    ) {
      this.getRegionsData();
    } else {
      this.loadRoles(this.props.user.object_id);
      this.loadAccounts(this.props.user.object_id);
    }
  }

  getRegionsData() {
    this.setState({ loadingData: true });
    if (
      this.props.user.role === "master" ||
      (this.props.user.plan && this.props.user.plan.name === "enterprise") ||
      (this.props.user.plan && this.props.user.plan.name === "operativo")
    ) {
      getMasterCountries().then((response) => {
        if (response.type === "Success" && response.response.status === 200) {
          if (response.response.data.length === 0) {
            this.setState({ emptyRegions: true, loadingData: false });
          } else {
            this.setState({
              emptyRegions: false,
              regionsData: response.response.data,
              loadingData: false,
            });
            this.handleChangeDropdownRegion(0);
          }
        } else {
          this.notify("Error al obtener la información", "error");
          this.setState({ loadingData: false });
        }
      });
    } else {
      getManagerCountries(this.props.user.object_id).then((response) => {
        if (response.type === "Success" && response.response.status === 200) {
          if (response.response.data.length === 0) {
            this.setState({ emptyRegions: true, loadingData: false });
          } else {
            this.setState({
              emptyRegions: false,
              regionsData: response.response.data,
              loadingData: false,
            });
            this.handleChangeDropdownRegion(0);
          }
        } else {
          this.notify("Error al obtener la información", "error");
          this.setState({ loadingData: false });
        }
      });
    }
  }

  getMarketplacesData(region) {
    this.setState({ loadingData: true });
    getAccounts(region).then((response) => {
      if (response.type === "Success" && response.response.status === 200) {
        if (response.response.data.length === 0) {
          this.setState({
            loadingData: false,
            emptyAccounts: true,
            accountsData: [],
          });
        } else {
          this.setState({
            loadingData: false,
            emptyAccounts: false,
            accountsData: response.response.data,
          });
          this.handleChangeDropdownAccount(0);
        }
      } else {
        this.notify("Error al obtener la información", "error");
        this.setState({
          loadingData: false,
          emptyAccounts: true,
          accountsData: [],
        });
      }
    });
  }

  getMarketplacesDataAdd(region) {
    this.setState({ loadingData: true });
    getAccounts(region).then((response) => {
      if (response.type === "Success" && response.response.status === 200) {
        if (response.response.data.length !== 0) {
          this.setState({
            loadingData: false,
            emptyAccounts: false,
            accountsDataAdd: response.response.data,
          });
        }
      } else {
        this.notify("Error al obtener la información", "error");
        this.setState({
          loadingData: false,
          emptyAccounts: true,
          accountsData: [],
        });
      }
    });
  }

  load() {
    this.loadAccounts();
  }

  @boundMethod
  addAccount() {
    if (this.props.user.role === "regional") {
      if (this.validateFields("regional")) {
        axios
          .post(`api/operational_users/${this.state.selectedAccountAdd.id}`, {
            pin: this.state.pin,
            password: this.state.password,
            operational_role_id: this.state.selectedRoles,
          })
          .then((res) => {
            if (res.status === 200) {
              this.props.toggleSideBar(false);
              this.loadAccounts(this.state.selectedAccount.id);
              this.clearFileds();
            }
          })
          .catch((error) => {
            if (error.request.status == 422) {
              this.props.toggleSideBar(false);
            }
          });
      }
    } else if (this.props.user.role === "customer") {
      if (this.validateFields("customer")) {
        axios
          .post(`api/operational_users/${this.props.user.object_id}`, {
            pin: this.state.pin,
            password: this.state.password,
            operational_role_id: this.state.selectedRoles,
          })
          .then((res) => {
            if (res.status === 200) {
              this.props.toggleSideBar(false);
              this.loadAccounts(this.props.user.object_id);
              this.clearFileds();
            }
          })
          .catch((error) => {
            if (error.request.status == 422) {
              this.props.toggleSideBar(false);
            }
          });
      }
    } else if (this.validateFields("master")) {
      axios
        .post(`api/operational_users/${this.state.selectedAccountAdd.id}`, {
          pin: this.state.pin,
          password: this.state.password,
          operational_role_id: this.state.selectedRoles,
        })
        .then((res) => {
          if (res.status === 200) {
            this.props.toggleSideBar(false);
            this.loadAccounts(this.state.selectedAccount.id);
            this.clearFileds();
          }
        })
        .catch((error) => {
          if (error.request.status == 422) {
            this.props.toggleSideBar(false);
          }
        });
    }
  }

  updateAccount(context) {
    if (this.validateFields()) {
      axios
        .put(`/api/operational_users/13560/${context.state.detailAccount.id}`, {
          pin: this.state.pin,
          password: this.state.password,
          operational_role_id: this.state.selectedRoles,
        })
        .then((res) => {
          if (res.status == 200) {
            context.props.toggleSideBar(false);
            this.load();
            this.clearFileds();
          }
        })
        .catch((error) => {
          if (error.request.status == 422) {
            context.props.toggleSideBar(false);
            this.clearFileds();
          }
        });
    }
  }

  deleteAccount(context) {
    axios
      .delete(`/api/operational_users/13560/${context.state.detailAccount.id}`)
      .then((r) => {
        context.props.toggleSideBar(false);
        this.load();
        this.clearFileds();
      });
  }

  loadAccounts(id) {
    axios.get(`/api/operational_users/${id}`).then((r) => {
      if (r.status == 200) {
        this.props.totalSubAccounts(r.data.length);
        this.setState({ accounts: r.data });
      }
    });
  }

  loadRoles(id) {
    axios.get(`/api/operational_roles/${id}`).then((r) => {
      if (r.status == 200) {
        const rolesOptions = [];

        r.data.forEach((element) => {
          rolesOptions.push({
            value: element.id,
            label: element.name,
          });
        });

        this.setState({ roles: rolesOptions });
      }
    });
  }

  handleRoles = (selectedOption) => {
    this.setState({
      selectedRoles: selectedOption.value,
      valueRoles: selectedOption,
    });
  };

  validateFields(type) {
    const errorMsgs = {
      required: "Este campo es requerido",
    };

    let flag = true;

    if (this.state.pin == "") {
      flag = false;
      this.setState({ pinError: errorMsgs.required });
    }

    if (this.state.password == "") {
      flag = false;
      this.setState({ passwordError: errorMsgs.required });
    }

    if (this.state.valueRoles.length == 0) {
      flag = false;
      this.setState({ rolError: errorMsgs.required });
    } else {
      this.setState({ rolError: "" });
    }

    if (type === "master" || this.props.user.plan.name === "enterprise" || this.props.user.plan.name === "operativo") {
      if (!this.state.selectedAccountAdd) {
        flag = false;
        this.setState({ selectedAccountErrorAdd: errorMsgs.required });
      } else {
        this.setState({ selectedAccountErrorAdd: "" });
      }
      if (!this.state.selectedRegionAdd) {
        flag = false;
        this.setState({ selectedRegionErrorAdd: errorMsgs.required });
      } else {
        this.setState({ selectedRegionErrorAdd: "" });
      }
    } else if (type === "regional") {
      if (!this.state.selectedAccountAdd) {
        flag = false;
        this.setState({ selectedAccountErrorAdd: errorMsgs.required });
      } else {
        this.setState({ selectedAccountErrorAdd: "" });
      }
    }
    return flag;
  }

  clearFileds = () => {
    this.setState({
      detailOpen: false,
      pin: "",
      password: "",
      rol: "",
      pinError: "",
      password: "",
      rolError: "",
      selectedRoles: "",
      valueRoles: [],
    });
  };

  _renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
      case NEW_SUBACCOUNT:
        return <div>{this.renderSidebarContentAdd()}</div>;
      case ACCOUNT_DETAILS:
        return <div>{this.renderSidebarDetail()}</div>;
      case ACCOUNT_EDIT:
        return <div>{this.renderSidebarEdit()}</div>;
      default:
        return <div />;
    }
  };

  _renderSidebarTitleExtraContent = () => {
    const accountOptions = [];
    const {
      customer_type,
      plan,
      actions,
      role,
      operational_user,
    } = this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }

    if (searchPermission("subcuentas", "editar", permissions)) {
      accountOptions.push({
        function: () => this.toggleEdit(this),
        label: "Editar cuenta",
        image: edit_icon,
      });
    }

    if (searchPermission("subcuentas", "eliminar", permissions)) {
      accountOptions.push({
        function: () => this.deleteAccount(this),
        label: "Eliminar cuenta",
        image: delete_icon,
      });
    }

    if (this.state.detailOpen) {
      return <ThreeDotsDropdown linksToRender={accountOptions} />;
    }
    return <div />;
  };

  @boundMethod
  toggleDetail(account) {
    this.setState({
      detailOpen: true,
      detailAccount: account,
    });
    this.props.toggleSideBar(true, "Detalle de la Cuenta", ACCOUNT_DETAILS);
  }

  @boundMethod
  toggleEdit(context) {
    this.state.roles.forEach((element) => {
      if (element.value === this.state.detailAccount.operational_role.id) {
        this.setState({
          selectedRoles: this.state.detailAccount.operational_role.id,
          valueRoles: {
            value: this.state.detailAccount.operational_role.id,
            label: this.state.detailAccount.operational_role.name,
          },
        });
      }
    });
    this.setState(
      {
        pin: this.state.detailAccount.pin,
        password: this.state.detailAccount.password,
        detailOpen: false,
      },
      () => {
        this.props.toggleSideBar(true, "Editar Cuenta", ACCOUNT_EDIT);
      }
    );
  }

  renderSidebarContentAdd = () => {
    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.setState({ [name]: value });
        },
        value: this.state[name],
        error: this.state[`${name}Error`],
      }),
    };

    const addFields = (
      <div>
        {this.props.user.role !== "customer" && (
          <div>
            {this.props.user.role === "regional" ? (
              <Input
                onChange={(value) => this.handleOnChange("name", value)}
                type="text"
                required
                value={this.props.user.first_name}
                placeholder=""
                label="Región"
                disabled
              />
            ) : (
              <Dropdown
                required
                label="Selecciona un País"
                options={this.renderRegions()}
                style={{ marginTop: "12px" }}
                placeholder={<Text className="dropdown-placeholder">País</Text>}
                selected={this.state.selectedRegionIndexAdd}
                handleChange={this.handleChangeDropdownRegionAdd}
                error={this.state.selectedRegionErrorAdd}
              />
            )}
            <Dropdown
              disabled={
                !!(
                  this.selectedRegionAdd &&
                  this.state.accountsDataAdd.length > 0
                )
              }
              down
              required
              label="Selecciona una Cuenta"
              options={this.renderAccountsAdd()}
              style={{ marginTop: "12px" }}
              placeholder={<Text className="dropdown-placeholder">Cuenta</Text>}
              selected={this.state.selectedAccountIndexAdd}
              handleChange={this.handleChangeDropdownAccountAdd}
              error={this.state.selectedAccountErrorAdd}
            />
          </div>
        )}

        <Input
          {...mainProps.generate("pin")}
          type="text"
          placeholder="Pin"
          label={
            <p>
              Pin{" "}
              <Tooltip
                arrow
                html={
                  <p style={{ margin: 0, fontSize: 10, textAlign: "start" }}>
                    <strong>Identificador de tu subcuenta</strong> <br />
                    Usa de 6 a 12 letras, números
                    <br />y caracteres especiales.
                    <br />
                    Mézclalos y usa mayúsculas.{" "}
                  </p>
                }
                title="Identificador de tu subcuenta "
                position="right"
              >
                <StyledInformation className="ion-help-circled" />
              </Tooltip>
            </p>
          }
        />

        <Input
          {...mainProps.generate("password")}
          type="text"
          required
          placeholder="Contraseña"
          label="Contraseña"
        />

        <div>
          <Label style={{ marginTop: "16px" }}>
            Roles <Required />
          </Label>
          <Select
            placeholder="Seleccionar Rol"
            closeMenuOnSelect={false}
            options={this.state.roles}
            value={this.state.valueRoles}
            onChange={this.handleRoles}
            className={`search-select accounts ${this.state.rolError != "" && "error"
              }`}
          />
          {this.state.rolError != "" && (
            <HelpText>Este campo es requerido</HelpText>
          )}
        </div>
      </div>
    );
    return (
      <div>
        <ContentAdapter>
          {addFields}

          <BottomContainer>
            <Row divisions={20}>
              <Column style={{ textAlign: "right" }} fluid>
                <Button
                  loading={this.state.setLoading}
                  className=""
                  onClick={() => this.addAccount()}
                >
                  Guardar
                </Button>
              </Column>
              <Column xs={1} xsShift={1} style={{ textAlign: "right" }} fluid />
            </Row>
          </BottomContainer>
        </ContentAdapter>
      </div>
    );
  };

  renderSidebarEdit = () => {
    const mainProps = {
      generate: (name) => ({
        onChange: (value) => {
          this.setState({ [name]: value });
        },
        value: this.state[name],
        error: this.state[`${name}Error`],
      }),
    };

    const editFields = (
      <div>
        <Input
          type="text"
          {...mainProps.generate("pin")}
          placeholder="Pin"
          label={
            <p>
              Pin{" "}
              <Tooltip
                arrow
                html={
                  <p style={{ margin: 0, fontSize: 10, textAlign: "start" }}>
                    <strong>Identificador de tu subcuenta</strong> <br />
                    Usa de 6 a 12 letras, números
                    <br />y caracteres especiales.
                    <br />
                    Mézclalos y usa mayúsculas.{" "}
                  </p>
                }
                title="Identificador de tu subcuenta "
                position="right"
              >
                <StyledInformation className="ion-help-circled" />
              </Tooltip>
            </p>
          }
        />

        <Input
          type="text"
          {...mainProps.generate("password")}
          required
          placeholder="Contraseña"
          label="Contraseña"
        />

        <div>
          <Label style={{ marginTop: "16px" }}>
            Roles <Required />
          </Label>
          <Select
            placeholder="Seleccionar Rol"
            closeMenuOnSelect={false}
            onChange={this.handleRoles}
            options={this.state.roles}
            value={this.state.valueRoles}
            className={`search-select accounts ${this.state.rolError != "" && "error"
              }`}
          />
          {this.state.rolError != "" && (
            <HelpText>Este campo es requerido</HelpText>
          )}
        </div>
      </div>
    );
    return (
      <div>
        <ContentAdapter>
          {editFields}

          <BottomContainer>
            <Row divisions={20}>
              <Column style={{ textAlign: "right" }} fluid>
                <Button
                  loading={this.state.setLoading}
                  onClick={() => this.updateAccount(this)}
                  className=""
                >
                  Guardar
                </Button>
              </Column>
              <Column xs={1} xsShift={1} style={{ textAlign: "right" }} fluid />
            </Row>
          </BottomContainer>
        </ContentAdapter>
      </div>
    );
  };

  renderSidebarDetail = () => {
    const detailFields = (
      <div>
        <ContentAdapter className="detailed-package">
          <Row>
            <Column fluid>
              <Text type="sidebarInfoTitle">Pin</Text>
              <P>{this.state.detailAccount.pin}</P>
            </Column>
            <Column fluid>
              <Text type="sidebarInfoTitle">Contraseña</Text>
              <P>{this.state.detailAccount.password}</P>
            </Column>
            <Column fluid>
              <Text type="sidebarInfoTitle">Rol</Text>
              <P>{this.state.detailAccount.operational_role.name}</P>
            </Column>
            <Column fluid>
              <Text type="sidebarInfoTitle">Permisos</Text>
              <P>
                {this.state.detailAccount.operational_role.actions.map(
                  (element, index) => (
                    <Badge type="correct" className="top">
                      {element.name}
                    </Badge>
                  )
                )}
              </P>
            </Column>
          </Row>
        </ContentAdapter>
      </div>
    );
    return (
      <div>
        <ContentAdapter>{detailFields}</ContentAdapter>
      </div>
    );
  };

  handleChangeDropdownRegion = (selectedOption) => {
    if (this.state.regionsData[selectedOption]) {
      this.setState({
        selectedRegion: this.state.regionsData[selectedOption],
        selectedRegionIndex: selectedOption,
      });
      this.getMarketplacesData(this.state.regionsData[selectedOption].id);
    }
  };

  handleChangeDropdownRegionAdd = (selectedOption) => {
    this.setState({
      selectedRegionAdd: this.state.regionsData[selectedOption],
      selectedRegionIndexAdd: selectedOption,
    });
    this.getMarketplacesDataAdd(this.state.regionsData[selectedOption].id);
  };

  handleChangeDropdownAccount = (selectedOption) => {
    if (this.state.accountsData[selectedOption]) {
      this.loadAccounts(this.state.accountsData[selectedOption].id);
      this.setState({
        selectedAccount: this.state.accountsData[selectedOption],
        selectedAccountIndex: selectedOption,
      });
    }
  };

  handleChangeDropdownAccountAdd = (selectedOption) => {
    this.loadRoles(this.state.accountsDataAdd[selectedOption].id);
    this.setState({
      selectedAccountAdd: this.state.accountsDataAdd[selectedOption],
      selectedAccountIndexAdd: selectedOption,
    });
  };

  renderRegions = () =>
    this.state.regionsData
      ? this.state.regionsData.map((region) => (
        <Text key={region.id}>{region.name}</Text>
      ))
      : null;

  renderAccounts = () =>
    this.state.accountsData
      ? this.state.accountsData.map((account) => (
        <Text key={account.id}>{account.email}</Text>
      ))
      : null;

  renderAccountsAdd = () =>
    this.state.accountsDataAdd
      ? this.state.accountsDataAdd.map((account) => (
        <Text key={account.id}>{account.email}</Text>
      ))
      : null;

  notify = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  render() {
    const columns = [
      {
        name: "Pin",
        selector: "pin",
        sortable: true,
      },
      {
        name: "Fecha Alta",
        selector: "created_at",
        sortable: true,
        cell: (row) => {
          const shipment_date = moment(row.created_at.split(" ")[0]);
          const today = new Date();
          const timeDiff = Math.abs(today - shipment_date);
          const daysDiff = Math.floor(timeDiff / (3600 * 24 * 1000));
          return (
            <div>
              <p>{shipment_date.format("DD-MM-YYYY")}</p>
              <p className="minitext">
                {daysDiff < 1
                  ? "hoy"
                  : `hace ${daysDiff} ${daysDiff > 1 ? "días" : "día"}`}
              </p>
            </div>
          );
        },
      },
      {
        name: "Rol",
        selector: "operational_role",
        sortable: true,
        cell: (row) => (
          <div>
            <p>{row.operational_role.name}</p>
          </div>
        ),
      },
      {
        name: "Descripción",
        selector: "operational_role",
        sortable: true,
        cell: (row) => (
          <div>
            <p>{row.operational_role.description}</p>
          </div>
        ),
      },
    ];

    return (
      <div>
        <ToastContainer />
        <WrapperSideBar
          handleClose={this.clearFileds}
          title_more={this._renderSidebarTitleExtraContent()}
        >
          {this._renderSidebarContent()}
        </WrapperSideBar>
        {this.props.user.role !== "customer" ? (
          <div>
            <p>
              <strong>Filtros</strong>
            </p>
            <div className="filters-container-roles">
              <div className="drop-roles">
                {this.props.user.role === "regional" ? (
                  <Input
                    onChange={(value) => this.handleOnChange("name", value)}
                    type="text"
                    value={this.props.user.first_name}
                    placeholder=""
                    disabled
                  />
                ) : (
                  <Dropdown
                    options={this.renderRegions()}
                    style={{ marginTop: "12px" }}
                    placeholder={
                      <Text className="dropdown-placeholder">País</Text>
                    }
                    selected={this.state.selectedRegionIndex}
                    handleChange={this.handleChangeDropdownRegion}
                  />
                )}
              </div>
              <div className="drop-roles">
                <Dropdown
                  disabled={
                    !!(
                      this.selectedRegion && this.state.accountsData.length > 0
                    )
                  }
                  options={this.renderAccounts()}
                  style={{ marginTop: "12px" }}
                  placeholder={
                    <Text className="dropdown-placeholder">Cuenta</Text>
                  }
                  selected={this.state.selectedAccountIndex}
                  handleChange={this.handleChangeDropdownAccount}
                />
              </div>
            </div>
          </div>
        ) : (
          (this.props.user.plan.name === "enterprise" || this.props.user.plan.name === "operativo") && (
            <div>
              <p>
                <strong>Filtros</strong>
              </p>
              <div className="filters-container-roles">
                <div className="drop-roles">
                  {this.props.user.role === "regional" ? (
                    <Input
                      onChange={(value) => this.handleOnChange("name", value)}
                      type="text"
                      value={this.props.user.first_name}
                      placeholder=""
                      disabled
                    />
                  ) : (
                    <Dropdown
                      options={this.renderRegions()}
                      style={{ marginTop: "12px" }}
                      placeholder={
                        <Text className="dropdown-placeholder">País</Text>
                      }
                      selected={this.state.selectedRegionIndex}
                      handleChange={this.handleChangeDropdownRegion}
                    />
                  )}
                </div>
                <div className="drop-roles">
                  <Dropdown
                    disabled={
                      !!(
                        this.selectedRegion &&
                        this.state.accountsData.length > 0
                      )
                    }
                    options={this.renderAccounts()}
                    style={{ marginTop: "12px" }}
                    placeholder={
                      <Text className="dropdown-placeholder">Cuenta</Text>
                    }
                    selected={this.state.selectedAccountIndex}
                    handleChange={this.handleChangeDropdownAccount}
                  />
                </div>
              </div>
            </div>
          )
        )}
        <div className="subacounts-list-container">
          <NewTable
            marginTop="margin-topHeader"
            onRowClicked={this.toggleDetail}
            shipping={false}
            columns={columns}
            data={this.state.accounts}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleSideBar,
      changeSidebarTitle,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SubAccountsList);
