import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Column } from "hedron";
import styled from "styled-components";
import valid from "card-validator";
import Waypoint from "react-waypoint";
import WalletMessage from "../Checkout/wallet_message";
import { deleteCard, createCard } from "../../modules/cards";
import { hideMessage } from "../../modules/message";
import {
  toggle as toggleSideBar,
  changeTitle as changeSidebarTitle,
} from "../../modules/CollapsibleBar/actions";
import Header from "../Header";
import Input from "../Input";
import Filter from "../Filter";
import Tabs, { Tab, Step } from "../Tabs";
import Text, { Title } from "../Text";
import Table, { Th, Tr, Td } from "../Table";
import { Checkbox, CheckboxGroup } from "../Checkbox";
import Button from "../Button";
import Card from "../Card";
import EmptyState from "../EmptyState";
import Message from "../Message";
import WrapperSideBar from "../WrapperSideBar";
import {
  ContentAdapter,
  BottomContainer,
} from "../WrapperSideBar/styledObjects";
import NewTable from "../NewTable";

import { NEW_PAYMENT_METHOD } from "../../modules/CollapsibleBar/sideBarTypes";
import card_placeholder from "./icons/card.svg";
import wallet from "./icons/wallet.svg";
import { getWalletTransactions } from "../../modules/transactions/actions";
import "./index.css";
import moment from "moment/min/moment-with-locales";
import Loader from "react-loaders";
import "loaders.css/loaders.min.css";
import colors from "../../utils/colors";
import { searchPermission } from "../../utils/global_functions";

import { CSVLink } from "react-csv";

import walletStyles from "../Checkout/index.module.scss";

const StyledDot = styled.div`
  display: inline-block;
  height: 5px;
  width: 5px;
  margin-bottom: 2px;
  background: #4a4a4a;
  margin-right: 6px;
  border-radius: 100px;
`;

export const transaction_types = {
  DEPOSIT: "Depósito",
  REFUNDING: "Reembolso por cancelación",
  INDEMNIZATION_MIENVIO: "Indemnización mienvío",
  OVERWEIGHT_CHARGE: "Sobrepeso",
  INDEMNIZATION: "Indemnización",
  PURCHASE_CHARGE: "Cargo por compra",
  INTERNAL_DEPOSIT: "Envío interno",
  BONIFICATION: "Bonificación",
  COMPENSATION: "Compensación",
  INDEMNIZATION_CURRIER: "Indemnización paquetería",
  CLIENT_ADVANCE_PAYMENT: "Recarga Wallet",
  DECLINED: "Declinado - Recarga Wallet",
};

export const transaction_breakdown_types = {
  overweight: "Sobrepesos",
  label: "Guías",
};

class CardsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      filter: "",
      filters: [],
      newCard: {
        number: "",
        numberError: "",
        name: "",
        nameError: "",
        expiration: "",
        expirationError: "",
        cvc: "",
        cvcError: "",
        isSaving: false,
      },
      currentTab: 0,
      walletDropdown: true,
      page: 1,
      clearSelectedRows: false,
      transactionsDownload: null,
    };
  }

  componentWillMount() {
    this.setState({ loading: true }, () => {
      this.props.getWalletTransactions(1).then(() => {
        const transactions = this.props.transactions.data;
        const transactionsDownload = [];
        transactionsDownload.push([
          "ID de Movimiento",
          "Tipo de Movimiento",
          "Fecha de Movimiento",
          "Movimiento",
        ]);
        transactions.data.forEach((element) => {
          transactionsDownload.push([
            element.id,
            transaction_types[element.type],
            element.created_at,
            `$${parseFloat(element.amount).toFixed(2)}`,
          ]);
        });
        this.setState({ loading: false, transactions, transactionsDownload });
      });
    });
    if (
      this.props.user.customer_type == "enterprise" ||
      this.props.user.customer_type == "basic" ||
      this.props.user.customer_type == "subsided_customer"
    ) {
      this.setState({ canCreate: true, canDelete: true, canUpdate: true });
    } else {
      if (this.props.user.permissions) {
        this.props.user.permissions.forEach((element) => {
          if (element.resource == "cards") {
            switch (element.action) {
              case "create":
                this.setState({ canCreate: true });
                break;
              case "edit":
                this.setState({ canUpdate: true });
                break;
              case "delete":
                this.setState({ canDelete: true });
                break;
              default:
                break;
            }
          }
        });
      } else {
        this.setState({ canCreate: true, canDelete: true, canUpdate: true });
      }
    }
    this.props.toggleSideBar(false);
  }

  generateTable = (cards) =>
    cards.map((card, index) => (
      <Tr key={card.object_id}>
        <Td checkbox>
          <CheckboxGroup
            defaultChecked={this.state.selected}
            type="table"
            onChange={(value) => this.select(value)}
          >
            <Checkbox value={card.object_id.toString()} />
          </CheckboxGroup>
        </Td>
        <Td>
          {card.brand === "Visa" ? (
            <img
              style={{ marginRight: "23px" }}
              src="http://app.mienvio.mx/media/cards/visa.png"
              width="30"
            />
          ) : (
            <img
              style={{ marginRight: "23px" }}
              src="http://app.mienvio.mx/media/cards/mastercard.png"
              width="30"
            />
          )}
          <StyledDot />
          <StyledDot />
          <StyledDot />
          <StyledDot />
          <StyledDot />
          <StyledDot />
          <StyledDot />
          <StyledDot />
          {card.last4}
        </Td>
        <Td>{card.name}</Td>
        <Td>
          {card.exp_month.toString().length === 2
            ? card.exp_month
            : `0${card.exp_month}`}
          /{card.exp_year}
        </Td>
      </Tr>
    ));

  deleteCards = () => {
    this.state.selected.forEach((id, index) => {
      this.props.deleteCard(id);
    });
    this.setState({ selected: [] });
  };

  selectAll = (value) => {
    if (value.length > 0) {
      const selected = [];
      this.props.cards.results.forEach((card) => {
        selected.push(card.object_id.toString());
      });
      this.setState({ selected });
      return;
    }
    this.setState({ selected: [] });
  };

  select = (value) => {
    this.setState({ selected: value });
  };

  renderEmptyState = () => {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    return (
      <EmptyState name="payment">
        <Title className="state-title">Registrar una tarjeta</Title>
        <Text>
          Al guardar una tarjeta en el sistema, el pago de tus guías será de
          forma más rápida, además, no tendrás que ingresar tus datos en cada
          ocasión.
        </Text>
        {searchPermission("tarjetas", "editar", permissions) && (
          <Button className="state-btn" onClick={() => this.goToCreateCard()}>
            Registrar tarjeta
          </Button>
        )}
      </EmptyState>
    );
  };

  toggleWalletDropdown = (walletDropdown) => {
    this.setState({ walletDropdown });
  };

  handleTabChange(selected) {
    const currentTab = selected === 0 ? 1 : 0;
    this.setState({ currentTab });
  }

  handleFilterChange = (value) => {
    this.setState({ filter: value });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const { filters } = this.state;
      filters.push(this.state.filter);
      this.setState({
        filters,
        filter: "",
      });
    }
  };

  onClose = (index) => {
    const { filters } = this.state;
    filters.splice(index, 1);
    this.setState({ filters });
  };

  clearSelectedRows = () => {
    this.setState({
      selected: [],
      clearSelectedRows: !this.state.clearSelectedRows,
    });
  };

  shorten(str, len) {
    if (!str) return str;
    if (str.length <= len) return str;
    return `${str.substr(0, len)}...`;
  }

  renderTableV2 = () => {
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    const results = this.props.cards.results.filter((card) => {
      let found = 0;
      if (this.state.filters.length === 0) return true;
      this.state.filters.forEach((filter) => {
        const expiration = `${
          card.exp_month.toString().length === 2
            ? card.exp_month
            : `0${card.exp_month}`
        }/${card.exp_year}`;
        const re = new RegExp(filter, "i");
        if (
          re.test(card.name) ||
          re.test(card.alias) ||
          re.test(expiration) ||
          re.test(card.last4)
        ) {
          found += 1;
        }
      });
      return found === this.state.filters.length;
    });
    const columns = [
      {
        name: "Número de tarjeta",
        selector: "alias",
        cell: (row) => (
          <div>
            {row.brand === "Visa" ? (
              <img
                style={{ marginRight: "23px" }}
                src="http://app.mienvio.mx/media/cards/visa.png"
                width="30"
              />
            ) : (
              <img
                style={{ marginRight: "23px" }}
                src="http://app.mienvio.mx/media/cards/mastercard.png"
                width="30"
              />
            )}
            <StyledDot />
            <StyledDot />
            <StyledDot />
            <StyledDot />
            <StyledDot />
            <StyledDot />
            <StyledDot />
            <StyledDot />
            {row.last4}
          </div>
        ),
      },
      {
        name: "",
        selector: "",
      },
      {
        name: "Titular",
        selector: "name",
      },
      {
        name: "Vencimiento",
        selector: "exp_month",
        cell: (row) => (
          <div>
            {row.exp_month.toString().length === 2
              ? row.exp_month
              : `0${row.exp_month}`}
            /{row.exp_year}
          </div>
        ),
      },
    ];
    const filters = this.state.filters.map((filter, index) => (
      <Filter
        icon={card_placeholder}
        style={{ marginLeft: "0px" }}
        key={index}
        onClose={() => this.onClose(index)}
      >
        {filter}
      </Filter>
    ));
    return (
      <div>
        <Text>Filtrar por</Text>
        <Row>
          <Column xs={12} md={3} fluid>
            <Input
              parentClassName="filter-input"
              type="text"
              value={this.state.filter}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleFilterChange}
              placeholder="Palabra clave..."
            />
          </Column>
        </Row>
        {filters}
        <NewTable
          // hideForSmall={true}
          selectableRows
          shipping={false}
          columns={columns}
          data={results}
          onTableUpdate={(table) => {
            this.setState({
              selected: table.selectedRows.map((cards) => cards.object_id),
            });
          }}
          clearSelectedRows={this.state.clearSelectedRows}
          noDataComponent={<div />}
          subHeader={
            this.state.selected.length === 0
              ? ""
              : searchPermission("tarjetas", "editar", permissions) && (
                  <Button
                    className="action-btn btn-subheader-space"
                    type="table"
                    onClick={() => {
                      this.clearSelectedRows();
                      this.deleteCards();
                    }}
                    disabled={this.state.paymentDisabled}
                  >
                    Eliminar
                  </Button>
                )
          }
        />
      </div>
    );
  };

  renderTable = () => {
    const results = this.props.cards.results.filter((card) => {
      let found = 0;
      if (this.state.filters.length === 0) return true;
      this.state.filters.forEach((filter) => {
        const expiration = `${
          card.exp_month.toString().length === 2
            ? card.exp_month
            : `0${card.exp_month}`
        }/${card.exp_year}`;
        const re = new RegExp(filter, "i");
        if (
          re.test(card.name) ||
          re.test(card.alias) ||
          re.test(expiration) ||
          re.test(card.last4)
        ) {
          found += 1;
        }
      });
      return found === this.state.filters.length;
    });

    const filters = this.state.filters.map((filter, index) => (
      <Filter
        style={{ marginLeft: "0px" }}
        key={index}
        onClose={() => this.onClose(index)}
      >
        {filter}
      </Filter>
    ));
    return (
      <div>
        <Text>Filtrar por</Text>
        <Row>
          <Column xs={12} md={3} fluid>
            <Input
              parentClassName="filter-input"
              type="text"
              value={this.state.filter}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleFilterChange}
              placeholder="Palabra clave..."
            />
          </Column>
        </Row>
        {filters}
        <Table>
          <thead>
            {this.state.selected.length === 0 ? (
              <Tr noBorder>
                <Th checkbox>
                  <Button type="table" className="checkbox-header-table">
                    <CheckboxGroup
                      onChange={(value) => {
                        this.selectAll(value);
                      }}
                      type="table"
                    >
                      <Checkbox value="all" />
                    </CheckboxGroup>
                  </Button>
                </Th>
                <Th>Número de tarjeta</Th>
                <Th>Titular</Th>
                <Th>Vencimiento</Th>
              </Tr>
            ) : (
              <Tr noBorder>
                <Th checkbox>
                  <Button type="table" className="checkbox-header-table">
                    <CheckboxGroup
                      onChange={(value) => {
                        this.selectAll(value);
                      }}
                      type="table"
                    >
                      <Checkbox value="all" />
                    </CheckboxGroup>
                  </Button>
                </Th>
                <Th colSpan={3}>
                  <div className="buttons-table">
                    <Button type="table" onClick={() => this.deleteCards()}>
                      Eliminar
                    </Button>
                  </div>
                </Th>
              </Tr>
            )}
          </thead>
          <tbody>{results ? this.generateTable(results) : null}</tbody>
        </Table>
      </div>
    );
  };

  goToCreateCard = () => {
    this.resetForms();
    this.props.toggleSideBar(true, "Nueva tarjeta", NEW_PAYMENT_METHOD);
  };

  goToDeposit = () => {
    window.open("https://form.jotform.com/200895684198067", "_blank");
  };

  resetForms = () => {
    this.setState({
      newCard: {
        number: "",
        numberError: "",
        expiration: "",
        expirationError: "",
        name: "",
        nameError: "",
        cvc: "",
        cvcError: "",
        isLoading: false,
      },
    });
  };

  handleChange = (value, name) => {
    switch (name) {
      case "name":
        this.setState({
          newCard: {
            ...this.state.newCard,
            nameError: value === "" ? "Este campo es requerido" : "",
          },
        });
        break;
      case "number":
        if (value.length > 16) return;
        const number = valid.number(value);
        const numberError = !number.isPotentiallyValid
          ? value === ""
            ? "Este campo es requerido"
            : "Número de tarjeta inválido"
          : number.card && number.card.isAmex
          ? "Por el momento no soportamos Amex, intente otra tarjeta"
          : "";
        this.setState({
          newCard: {
            ...this.state.newCard,
            numberError,
          },
        });
        break;
      case "expiration":
        const expiration = valid.expirationDate(value);
        let val = value.replace("/", "");
        if (!isNaN(val) && val.length <= 4) {
          if (val.length >= 2) {
            val = val.replace(/(\d{2})/, "$1/");
            if (this.state.expiration === val) {
              val = val.replace("/", "");
            }
          }
          value = val;
        } else if (val.length > 4) {
          val = `${val.substring(0, 2)}/${val.substring(2, 4)}`;
          value = val;
        }
        const expirationError =
          !expiration.isValid || value.length !== 5 || value.indexOf("/") !== 2
            ? value === ""
              ? "Este campo es requerido"
              : "Fecha de vencimiento inválida"
            : "";
        this.setState({
          newCard: {
            ...this.state.newCard,
            expirationError,
          },
        });
        break;
      case "cvc":
        const cvc = valid.cvv(value);
        const cvcError = !cvc.isValid
          ? value === ""
            ? "Este campo es requerido"
            : "CVC inválido"
          : "";
        this.setState({
          newCard: {
            ...this.state.newCard,
            cvcError,
          },
        });
        break;
      default:
        break;
    }
    this.setState({
      newCard: {
        ...this.state.newCard,
        [name]: value,
      },
    });
  };

  async saveCard(type) {
    const errors = {
      required: "Este campo es requerido",
    };
    const nameError = this.state.newCard.name === "" ? errors.required : "";
    const number = valid.number(this.state.newCard.number);
    const numberError = !number.isValid
      ? this.state.newCard.number === ""
        ? errors.required
        : "Número de tarjeta inválido"
      : number.card && number.card.isAmex
      ? "Por el momento no soportamos Amex, intente otra tarjeta"
      : "";
    const expiration = valid.expirationDate(this.state.newCard.expiration);
    const expirationError =
      !expiration.isValid ||
      this.state.newCard.expiration.length !== 5 ||
      this.state.newCard.expiration.indexOf("/") !== 2
        ? this.state.newCard.expiration === ""
          ? errors.required
          : "Fecha de vencimiento inválida"
        : "";
    const cvc = valid.cvv(this.state.newCard.cvc);
    const cvcError = !cvc.isValid
      ? this.state.newCard.cvc === ""
        ? errors.required
        : "CVC inválido"
      : "";
    if (nameError || numberError || cvcError || expirationError) {
      return this.setState({
        newCard: {
          ...this.state.newCard,
          nameError,
          numberError,
          cvcError,
          expirationError,
        },
      });
    }

    this.setState({
      newCard: {
        ...this.state.newCard,
        isLoading: true,
      },
    });

    if (type === "create") {
      const expirationDate = this.state.newCard.expiration.split("/");
      const card = {
        object: "card",
        exp_month: expirationDate[0],
        exp_year: expirationDate[1],
        number: this.state.newCard.number,
        name: this.state.newCard.name,
        cvc: this.state.newCard.cvc,
      };

      await this.props.createCard(card, "card");

      this.resetForms();
      this.props.toggleSideBar(false);
    }
  }

  _renderSidebarContent = () => {
    switch (this.props.sidebar.type) {
      default:
        const mainProps = {
          generate: (name) => ({
            onChange: (value) => {
              this.handleChange(value, name);
            },
            value: this.state.newCard[name],
            error: this.state.newCard[`${name}Error`],
          }),
        };
        return (
          <ContentAdapter>
            <Row>
              <Column xs={7} fluid>
                <Input
                  type="text"
                  {...mainProps.generate("number")}
                  required
                  placeholder="1234 4567 7891 1234"
                  label="Número de tarjeta"
                />
              </Column>
              <Column xs={5} fluid style={{ paddingLeft: "16px" }}>
                <Input
                  type="text"
                  {...mainProps.generate("expiration")}
                  required
                  placeholder="09/20"
                  label="Fecha de expiración"
                />
              </Column>
              <Column xs={7} fluid>
                <Input
                  type="text"
                  {...mainProps.generate("name")}
                  required
                  placeholder="Raul Pérez Pérez"
                  label="Nombre del titular"
                  help="Tal como aparece en la tarjeta"
                />
              </Column>
              <Column xs={5} fluid style={{ paddingLeft: "16px" }}>
                <Input
                  type="password"
                  {...mainProps.generate("cvc")}
                  required
                  placeholder="123"
                  label="CVC"
                  help="Son los 3 dígitos al reverso"
                />
              </Column>
            </Row>
            <Row />
            <BottomContainer>
              <Row divisions={20}>
                <Column style={{ textAlign: "right" }} fluid>
                  <Button
                    className=""
                    onClick={() => this.saveCard("create")}
                    loading={this.state.newCard.isLoading}
                  >
                    Guardar
                  </Button>
                </Column>
                <Column
                  xs={1}
                  xsShift={1}
                  style={{ textAlign: "right" }}
                  fluid
                />
              </Row>
            </BottomContainer>
          </ContentAdapter>
        );
    }
  };

  _loadMore = () => {
    if (this.props.transactions.data.last_page <= this.state.page) return;
    this.setState({ loading: true, page: this.state.page + 1 }, () => {
      this.props.getWalletTransactions(this.state.page).then(() => {
        const { transactions, transactionsDownload } = this.state;
        if (transactions.data) {
          transactions.data = transactions.data.concat(
            this.props.transactions.data.data
          );
        }
        if (transactionsDownload) {
          transactions.data.forEach((element) => {
            transactionsDownload.push([
              element.id,
              transaction_types[element.type],
              element.created_at,
              `$${parseFloat(element.amount).toFixed(2)}`,
            ]);
          });
        }
        this.setState({ loading: false, transactions, transactionsDownload });
      });
    });
  };

  getTotalOverweightCharges = (charges) => {
    let total = 0;
    if (charges) {
      charges.forEach((charge) => {
        if (charge.concept === "overweight") {
          total += parseFloat(charge.amount);
        }
      });
    }
    return total * -1;
  };

  renderOverweight = (transaction) =>
    transaction.purchase &&
    transaction.purchase.charges &&
    transaction.purchase.charges.map(
      (charge, id) =>
        charge.concept === "overweight" && (
          <Row key={id}>
            <Column xs={6} fluid>
              <Text type="microDescription" className="detail-row">
                {transaction_breakdown_types[charge.concept]}
              </Text>
            </Column>
            <Column xs={6} fluid className="right">
              <Text type="microDescription" className="detail-row">
                {this.formatMoney(charge.amount * -1)}
              </Text>
            </Column>
          </Row>
        )
    );

  formatMoney = (amount) => {
    amount = parseFloat(amount).toFixed(2);
    if (amount < 0) {
      return `- $${parseFloat(Math.abs(amount)).toFixed(2)} MXN`;
    }
    return `+ $${parseFloat(amount).toFixed(2)} MXN`;
  };

  renderTransactions = () => (
    <div className="wallet-section">
      <Text type="sidebarInfoTitle" className="title">
        Movimientos
      </Text>
      {this.state.transactionsDownload && (
        <CSVLink
          className="btn-download-csv"
          filename="HistorialMovimientos.csv"
          data={this.state.transactionsDownload}
        >
          Descargar CSV
        </CSVLink>
      )}
      {this.state.transactions &&
        this.state.transactions.data &&
        this.state.transactions.data.map((transaction, id) => (
          <div key={id}>
            <Row
              style={{ borderBottom: "1px solid #DEDEDE", padding: "8px 0px" }}
            >
              <Column xs={7} fluid>
                <Row>
                  <Column xs={4} fluid>
                    <Text type="label" className="transaction-row">
                      {transaction_types[transaction.type]}
                    </Text>
                  </Column>
                  <Column xs={4} fluid className="center">
                    <Text type="label" className="transaction-row">
                      {moment(transaction.created_at).format("DD/MM/YYYY")}
                    </Text>
                  </Column>
                  <Column xs={4} fluid className="right">
                    <Text type="label" className="transaction-row">
                      {this.formatMoney(transaction.amount)}
                    </Text>
                  </Column>
                </Row>

                {transaction.type === "PURCHASE_CHARGE" && (
                  <div>
                    {this.renderOverweight(transaction)}
                    <Row>
                      <Column xs={6} fluid>
                        <Text type="microDescription" className="detail-row">
                          Guías{" "}
                        </Text>
                      </Column>
                      {transaction.amount &&
                        transaction.purchase &&
                        transaction.purchase.charges && (
                          <Column xs={6} fluid className="right">
                            <Text
                              type="microDescription"
                              className="detail-row"
                            >
                              {this.formatMoney(
                                transaction.amount -
                                  this.getTotalOverweightCharges(
                                    transaction.purchase.charges
                                  )
                              )}
                            </Text>
                            <br />
                          </Column>
                        )}
                    </Row>
                  </div>
                )}
              </Column>
              <Column xs={5} fluid />
            </Row>
          </div>
        ))}

      {!this.state.loading && this.state.transactions.total > 0 && (
        <Waypoint key={this.state.page} onEnter={this._loadMore} />
      )}

      {this.state.loading && (
        <div className="loader-container">
          <Loader
            color={colors.primary.mienvio_pink}
            type="ball-pulse"
            active
          />
        </div>
      )}
    </div>
  );

  renderWallet = () => (
    <div className={walletStyles.cardContainer}>
      <div className={walletStyles.card}>
        <div className={"saldo " + walletStyles.balanceContainer}>
          <h1 className={walletStyles.title} style={{ marginBottom: 0 }}>
            Saldo actual
          </h1>
          {this.props.user && (
            <Text
              className={"saldo-title right total " + walletStyles.balance}
              type="sidebarInfoTitle"
            >
              ${parseFloat(this.props.user.wallet_balance).toFixed(2)}
            </Text>
          )}
        </div>

        <Text className={walletStyles.walletSubtitle}>
          Monto mínimo de recarga $100.00 MXN
        </Text>
        <br />
        <Text className={walletStyles.walletTitle}>
          ¿Cómo recargar mi Wallet?
        </Text>
        <br />
        <Text className={walletStyles.walletText}>
          Mienvío Wallet es nuestro monedero electrónico que te permite comprar
          tus envíos de una manera fácil y con tu información segura.
          <br />
          <br />
          <h1
            className={walletStyles.title}
            style={{ marginBottom: 0, textAlign: "Left" }}
          >
            Realiza una transferencia bancaria a tu cuenta clabe asignada.
          </h1>
          Nombre del beneficiario: MIENVIO DE MEXICO S.A.P.I. DE C.V.
          <br />
          Banco: <b>STP</b>
          <br />
          CLABE:{" "}
          <b>
            {this.props.user ? this.props.user.stp_clabe : "***************"}
          </b>
          <br />
          *Tu CLABE es única y solamente podrás usarla para recargar saldo a tu
          Wallet Mienvío.
          <br />
          *Si tienes más de un usuario en Mienvío, considera que esta clabe es
          intransferible.
          <br />
          <br />
          Tu saldo se reflejará en tu cuenta de inmediato. Si realizaste una
          recarga y el monto no se ve reflejado en tu cuenta después de 1 hora,
          escribe a nuestro equipo de soporte para abrir tu{" "}
          <a
            className={walletStyles.walletLink}
            href="https://api.whatsapp.com/send?phone=+5214423783099&text=Hola,%20quiero%20levantar%20mi%20ticket%20de%20aclaraci%C3%B3n%20de%20recarga%20Wallet"
            target="_blank"
          >
            ticket de aclaración.
          </a>{" "}
          &nbsp;
          <br />
          <br />
          Contáctanos si tienes dudas o problemas al generar una recarga de
          saldo.
        </Text>
      </div>
    </div>
  );

  render() {
    if (
      this.props.message.show &&
      (this.props.message.messageType === "error" ||
        this.props.message.messageType === "success")
    ) {
      setTimeout(this.props.hideMessage, 5000);
    }
    const { customer_type, plan, actions, role, operational_user } =
      this.props.user;

    const permissions = [];
    if (
      operational_user &&
      operational_user.operational_role &&
      operational_user.operational_role.actions
    ) {
      operational_user.operational_role.actions.map((element) =>
        permissions.push(element)
      );
    }

    if (plan && plan.features) {
      plan.features.map((element) => permissions.push(element));
    }
    if (actions) {
      actions.map((element) => permissions.push(element));
    }
    return (
      <div>
        <WrapperSideBar icon={card_placeholder}>
          {this._renderSidebarContent()}
        </WrapperSideBar>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Header>
              <Row divisions={13}>
                <Column xs={7} fluid>
                  <Title type="titleCard">Métodos de pago</Title>
                </Column>
                <Column
                  xs={5}
                  xsShift={1}
                  style={{
                    textAlign: "right",
                  }}
                  fluid
                >
                  {searchPermission("tarjetas", "editar", permissions) && (
                    <Button
                      className="header-btn last"
                      onClick={() =>
                        this.state.currentTab === 0
                          ? window.open(
                              "https://api.whatsapp.com/send?phone=+5214423783099&text=Hola,%20quiero%20levantar%20mi%20ticket%20de%20aclaraci%C3%B3n%20de%20recarga%20Wallet"
                            )
                          : this.goToCreateCard()
                      }
                    >
                      {this.state.currentTab === 0
                        ? "Solicitar aclaración"
                        : "Registrar tarjeta"}
                    </Button>
                  )}
                </Column>
              </Row>
            </Header>
          </Column>
          <Column xs={1} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            {this.props.message.show ? (
              <Message
                color={this.props.message.messageType}
                onClose={this.props.hideMessage}
              >
                {this.props.message.text}
              </Message>
            ) : null}
          </Column>
          <Column xs={8} fluid />
        </Row>
        <Row divisions={20}>
          <Column xs={1} fluid />
          <Column xs={18} fluid>
            <Tabs
              id="order_type"
              selected={this.state.currentTab}
              onChange={(selected) =>
                this.handleTabChange(this.state.currentTab)
              }
              right
            >
              <Tab>Wallet</Tab>
              {this.props.user.methodPayments &&
              this.props.user.methodPayments.includes("credit") ? (
                <Tab>Tarjetas</Tab>
              ) : (
                <div />
              )}
            </Tabs>
            {this.state.currentTab === 0 ? (
              <div>
                {this.renderWallet()}
                <div className="division" />
                {this.renderTransactions()}
              </div>
            ) : !this.props.cards.results ||
              this.props.cards.results.length === 0 ? (
              this.renderEmptyState()
            ) : (
              this.renderTableV2()
            )}
          </Column>
          <Column xs={1} fluid />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cards: state.cards,
  message: state.message,
  sidebar: state.sidebar,
  user: state.user,
  transactions: state.transactions,
  loader: state.loader,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteCard,
      hideMessage,
      toggleSideBar,
      changeSidebarTitle,
      createCard,
      getWalletTransactions,
      // goToCard: id => push(`/metodos-pago/${id}`)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CardsList);
