import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Column } from 'hedron';
import { push } from 'react-router-redux';
import styled from 'styled-components';

import colors from '../../utils/colors';
import Text, { Title } from '../Text';
import Button from '../Button';
import background from './background.jpg';
import image from './image.png';

const StyledTitle = styled.h1`
  color: ${colors.gray.charcoal};
  font-weight: 900;
  font-size: 64px;
  line-height: 80px;
  margin: 8px 0;
  margin-bottom: 24px;
`;

const NotFoundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: url(${background}) no-repeat center center fixed;
  background-size: cover;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const Img = styled.img`
  position: absolute;
  max-width: 416px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const NotFound = props => (
  <NotFoundContainer>
    <Row divisions={24}>
      <Column fluid xs={9} xsShift={3}>
        <Container>
          <Content>
            <StyledTitle>Oops</StyledTitle>
            <Title>Lo sentimos, la página que buscas no existe.</Title>
            <Text>Error: 404</Text>
            <br />
            <Button style={{ marginLeft: '0px', marginTop: '80px' }} onClick={() => props.goHome()}>
              Regresar a Inicio
            </Button>
          </Content>
        </Container>
      </Column>
      <Column fluid xs={12}>
        <Container>
          <Img src={image} />
        </Container>
      </Column>
    </Row>
  </NotFoundContainer>
);

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goHome: () => push('/')
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFound);
