import React from 'react';
import styled from 'styled-components';
import colors from '../../utils/colors';
import { Title } from '../Text';
import basicLoader from './generic_loader.svg';
import integrationsLoader from './integrations_loader.svg';
import invoicesLoader from './invoices_loader.svg';
import addressesBook from './AddressLoader/book.svg';
import addressesPaper from './AddressLoader/paper.svg';
import shipmentsBox from './ShipmentsLoader/box.svg';
import shipmentsTruck from './ShipmentsLoader/truck.svg';
import cardLoader from './CardsLoader/card.svg';
import dotLoader from './CardsLoader/dot.svg';
import lockLoader from './CardsLoader/lock.svg';

import './index.css';

const Checkmark = props => (
  <div className="oval" id={props.id}>
    <div className="checkmark">
      <i className="ion-checkmark" />
    </div>
  </div>
);

const CreatingIntegrations = props => (
  <div>
    <TextBox id="creating_integrations-0">
      {' '}
      <Title>
        <Checkmark id="checkmark-0" />
        Obteniendo permisos de {props.param1}
      </Title>
    </TextBox>
    <TextBox id="creating_integrations-1">
      {' '}
      <Title>
        <Checkmark id="checkmark-1" />
        Buscando ordenes pagadas
      </Title>
    </TextBox>
    <TextBox id="creating_integrations-2">
      {' '}
      <Title>
        <Checkmark id="checkmark-2" />
        Importando ordenes pagadas
      </Title>
    </TextBox>
    <TextBox id="creating_integrations-3">
      {' '}
      <Title>
        <Checkmark id="checkmark-3" />
        Todo listo
      </Title>
    </TextBox>
  </div>
);

const TextBox = styled.div`
  text-align: left;
  opacity: 0;
  margin-bottom: 26px;
`;

const kinds = {
  shipments: {
    images: [shipmentsBox, shipmentsBox, shipmentsBox, shipmentsBox, shipmentsTruck],
    title: 'Espera un momento estamos obteniendo tus envíos',
    children: []
  },
  addresses: {
    images: [addressesPaper, addressesPaper, addressesPaper, addressesBook],
    title: 'Espera un momento estamos recuperando tus direcciones',
    children: []
  },
  invoices: {
    images: [invoicesLoader, invoicesLoader, invoicesLoader, invoicesLoader],
    title: 'Espera un momento estamos recuperando tus facturas',
    children: []
  },
  packages: {
    images: [basicLoader],
    title: 'Espera un momento estamos obteniendo tus empaques',
    children: []
  },
  password: {
    images: [basicLoader],
    title: '',
    children: []
  },
  basic: {
    images: [basicLoader],
    title: 'Cargando ...',
    children: []
  },
  rates: {
    images: [basicLoader],
    title: 'Espera un momento estamos obteniendo los mejores precios para tu paquete',
    children: []
  },
  cards: {
    images: [lockLoader, dotLoader, dotLoader, dotLoader, dotLoader, dotLoader, cardLoader],
    title: 'Espera un momento estamos obteniendo tus métodos de pago',
    children: []
  },
  integrations: {
    images: [integrationsLoader, integrationsLoader, integrationsLoader],
    title: 'Estamos recuperando la información de tus integraciones',
    children: []
  },
  creating_integrations: {
    images: [],
    title: '',
    children: [<CreatingIntegrations />]
  }
};

const StyledLoader = styled.div`
  z-index: 10000;
  background: ${colors.secondary.white};
  position: fixed;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const Content = styled.div`
  position: absolute;
  z-index: 600;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

// const img = styled.img`
//   width: 103px;
//   height: 140px;
//   object-fit: contain;
// `;

const Loader = (props, context) => (
  <StyledLoader>
    <Content>
      <div>
        {React.Children.map(kinds[props.type].children, (child, index) => {
          return React.cloneElement(child, {
            param1: props.createdType
          });
        })}
      </div>
      {kinds[props.type].images &&
        kinds[props.type].images.map((image, i) => {
          return (
            <img
              alt="Loader"
              src={image}
              key={i}
              id={props.type + '-' + i}
              className={props.type}
            />
          );
        })}
      <div style={{ marginTop: '35px' }}>
        <Title>{kinds[props.type].title}</Title>
      </div>
    </Content>
  </StyledLoader>
);

export default Loader;
