import React from 'react';
import { connect } from 'react-redux';
import { clearSidebar, setStepTitles } from '../../../modules/sidebarOrder/actions';
import { toggle } from '../../../modules/CollapsibleBar/actions';
import SidebarTitleMenu from '../../WrapperSideBar/extraComponents/SidebarTitleMenu';
import { bindActionCreators } from 'redux';

import edit_icon from '../../../utils/icons/edit.svg';
import delete_icon from '../../../utils/icons/delete.svg';
import duplicate_icon from '../../../utils/icons/duplicate.svg';
import { searchPermission } from '../../../utils/global_functions';

import '../style.css';

class DetailedPackageMenu extends React.Component {
  state = {
    isMenuOpen: false,
    package: {}
  };

  componentWillReceiveProps(newProps) {
    this.setState({
      package: newProps.package
    });
  }

  render() {
    const {
      customer_type, plan, actions, role, operational_user
    } = this.props.user;

    const permissions = [];
    if (operational_user && operational_user.operational_role && operational_user.operational_role.actions) {
      operational_user.operational_role.actions.map(element => permissions.push(element));
    }

    if (plan && plan.features) {
      plan.features.map(element => permissions.push(element));
    }
    if (actions) {
      actions.map(element => permissions.push(element));
    }
    const linksToRender = [];
    if (searchPermission('empaques', 'editar', permissions)) {
      linksToRender.push(
        {
          label: 'Editar',
          image: edit_icon,
          function: this.props.editCurrentPackageFunction
        },
        {
          label: 'Duplicar y editar',
          image: duplicate_icon,
          function: this.props.duplicateCurrentPackageFunction
        }
      );
    }
    if (searchPermission('empaques', 'eliminar', permissions)) {
      linksToRender.push({
        label: 'Eliminar empaque',
        image: delete_icon,
        function: this.props.deleteCurrentPackageFunction
      });
    }
    return (
      <SidebarTitleMenu
        linksToRender={linksToRender}
        nextButton={{
          trigger: () => this.props.changePackageFunction(this.props.indexControl.current - 1),
          disabled: this.props.indexControl.current - 1 >= 0 ? false : true
        }}
        prevButton={{
          trigger: () => this.props.changePackageFunction(this.props.indexControl.current + 1),
          disabled: this.props.indexControl.current + 1 < this.props.indexControl.max ? false : true
        }}
      />
    );
  }
}

const mapStateToProps = state => ({ user: state.user });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggle,
      clearSidebar,
      setStepTitles
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailedPackageMenu);
