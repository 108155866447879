import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import * as Sentry from '@sentry/browser';

import store, { history } from './store';
import App from './App';

import './index.css';
import { unregister } from './registerServiceWorker';
import { SENTRY_CONFIG } from './constants';

const target = document.querySelector('#root');
if (process.env.NODE_ENV === 'production') {
  Sentry.init(SENTRY_CONFIG);
}

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <App />
      </div>
    </ConnectedRouter>
  </Provider>,
  target,
);
unregister();
